import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormSaver} from '../../../../shared/service/core/form-saver.service';
import {ForsiReportKeyPipe} from '../../../../shared/pipe/forsi-report-key.pipe';
import {ForsiReportKeyShortPipe} from '../../../../shared/pipe/forsi-report-key-short.pipe';
import {ClientStateDetails} from '../../..';
import {NgForm} from '@angular/forms';
import {DraftEditService} from '../../draft-edit.service';
import {BootstrapGrowlService} from '../../../../shared/ui/ngx-bootstrap-growl';
import {PrincipalService} from '../../../../shared';
import {LcActionMenuItem} from '../../../../shared/ui/bottombar/lc-action-menu.model';
import {ContextMenu, ContextMenuItem} from '../../../../shared/ui/context-menu/context-menu.model';
import {AttachmentUseCase} from '../../../../shared/model/attachment-use-case.model';
import {TextLibraryTypeEnum} from '../../../../shared/service/text-library-type.enum';
import {AttachmentService} from '../../../../shared/ui/attachment/attachment.service';
import {DraftService} from '../../../service/draft.service';
import {UIUtils} from '../../../../shared/utils/ui-utils';
import {CompanyDetailsDTO} from '../../../dto/company-details-dto.model';
import {combineLatest, of, Subject} from 'rxjs';
import {ClientStateDifferService} from '../../checkopslag/client-state-differ.service';
import {AgreementService} from '../../../../shared/service/agreement.service';
import {DraftEditQapterFlowComponent} from '../../draft-edit-qapter-flow.component';
import {mergeMap, takeUntil} from 'rxjs/operators';
import {ClientNavigationService} from '../../../../shared/service/client-navigation.service';
import {AssessmentConfigurationService} from '../../../../shared/service/assessment-configuration.service';
import {AssessmentConfigurationDTO} from '../../../../shared/dto/assessment-configuration-dto.model';
import {QapterPrepareModeService} from '../../../../shared/service/qapter-prepare-mode.service';
import {ReportCategory} from '../../../../shared/model/report-category.model';
import {StringUtils} from '../../../../shared/utils/string-utils';
import {isNullOrUndefined} from '../../../../shared/utils/object-utils';
import {CopyDraftModalService} from '../../../service/copy-draft.modal.service';
import {LcPopupService} from '../../../../shared/modals/lc-popup.service';

/**
 * Is an Autotaks Flow One component (H, Tx and Sx)
 */
@Component({
  selector: 'lc-taks-autotaks-flow-one',
  templateUrl: './taks-autotaks-flow-one.component.html',
  styleUrls: [
    '../../../draft.scss', '../../draft-edit.scss', './../taks-autotaks.scss'
  ],
  providers: [FormSaver, ClientStateDifferService]
})
export class TaksAutotaksFlowOneComponent extends DraftEditQapterFlowComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  companyDetails: CompanyDetailsDTO[];
  isAgreementsLoading = true;
  @ViewChild('f', {static: true}) public form: NgForm;
  attachmentUseCase = AttachmentUseCase.Draft;
  textLibraryType = TextLibraryTypeEnum.BESKED;
  assessmentConfiguration: AssessmentConfigurationDTO;

  constructor(principal: PrincipalService,
              private agreementService: AgreementService,
              private assessmentConfigurationService: AssessmentConfigurationService,
              clientStateService: DraftService,
              draftEditService: DraftEditService,
              reportKeyPipe: ForsiReportKeyPipe,
              reportKeyShortPipe: ForsiReportKeyShortPipe,
              formSaver: FormSaver,
              attachmentService: AttachmentService,
              qapterPrepareModeService: QapterPrepareModeService,
              router: Router,
              route: ActivatedRoute,
              bootstrapGrowlService: BootstrapGrowlService,
              el: ElementRef,
              clientNavigationService: ClientNavigationService,
              copyDraftModalService: CopyDraftModalService,
              popupService: LcPopupService) {
    super(formSaver, reportKeyPipe, reportKeyShortPipe, clientStateService, draftEditService, principal,
      qapterPrepareModeService, router, route, bootstrapGrowlService, el, clientNavigationService,
      copyDraftModalService, popupService
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    //specific flow initialization goes here

    this.route.params.pipe(
      mergeMap(params => this.draftEditService.getClientStateObservable(params.token)),
      takeUntil(this.unsubscribe$)).subscribe(cs => {
      const fragment = this.route.snapshot.fragment;
      const repairType = ReportCategory.autotaksRepairType(cs.schema);
      const brandObs = this.draftEditService.getBrands$(repairType);
      const companyDetailsObs = this.draftEditService.getCompanyDetailsObservable();
      const configurationObs = this.assessmentConfigurationService.getConfiguration();
      combineLatest([brandObs, of(cs), companyDetailsObs, configurationObs])
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(([brands, clientStateDetails, companyDetails, assessmentConfiguration]) => {
          this.initializeWithClientState(clientStateDetails);
          this.updateWithBrands(brands);
          this.companyDetails = companyDetails;
          this.assessmentConfiguration = assessmentConfiguration;
          this.isLoading = false;
          this.possibleNavigateToFragmentUrlPart(fragment);
        }, error => {
          console.log('TaksAutotaksComponent error', error);
          this.router.navigate(['/draft']);
        });
    });
  }

  initializeWithClientState(clientStateDetails: ClientStateDetails): void {
    super.initializeWithClientState(clientStateDetails);
    this.getMessages();
  }

  setupContextMenu(): void {
    this.contextMenu = new ContextMenu();
    const uiUtils = new UIUtils();
    this.contextMenu.addItem(new ContextMenuItem('fa-vcard-o', 'Stamdata', 'masterdata', () => {
      uiUtils.scrollToElementId(['masterdata']);
    }, () => !this.formSaver.isFormsValid(['masterData', 'conditionRules', 'draftDamagesDates', 'forsikringInfo', 'skadelidteInfo', 'leasingselskab'])));

    this.contextMenu.addItem(new ContextMenuItem('fa-handshake-o', 'Prisaftaler', 'agreements', () => {
      uiUtils.scrollToElementId(['agreements', 'assessment-method']);
    }, () => !(this.isAgreementsLoading || this.formSaver.isFormsValid(['agreements', 'assessmentMethod']))));

    this.contextMenu.addItem(new ContextMenuItem('fa-car', 'Skader', 'damages', () => {
      uiUtils.scrollToElementId(['damages', 'damagedescription']);
    }, () => {
      const qapterDataProvided = this.details.onepadDataValid;
      const zoneDataProvided = this.details.damageDescription.zoneIsValid();
      return !(this.formSaver.isFormsValid(['damages', 'damagedescription']) && qapterDataProvided && zoneDataProvided);
    }));

    this.contextMenu.addItem(new ContextMenuItem('fa-database', 'Værdi', 'valuation', () => {
      uiUtils.scrollToElementId(['valuation']);
    }, () => !this.formSaver.isFormsValid(['valuation'])));

    this.contextMenu.addItem(new ContextMenuItem('fa-image', 'Bilag', 'attachments', () => {
      uiUtils.scrollToElementId(['attachments']);
    }));

    this.contextMenu.addItem(new ContextMenuItem('fa-archive', 'Diverse', 'misc', () => {
      uiUtils.scrollToElementId(['misc']);
    }, () => !this.formSaver.isFormValid('intern-info')));
  }

  createPoliceMenuItem(): LcActionMenuItem {
    const policyMenuItem = new LcActionMenuItem('fa-id-card-o', () => {
        this.draftEditService.scrollPosition = window.pageYOffset;
        this.router.navigate(['police'], {relativeTo: this.route});
      }, 'Policeopslag',
      () => this.draftEditService.saving || this.isMissingCompanySelection()
    );
    policyMenuItem.disabledReason = 'Der mangler at blive angivet et selskab';
    return policyMenuItem;
  }

  private isMissingCompanySelection(): boolean {
    return !isNullOrUndefined(this.details) && StringUtils.isEmpty(this.details.selskab);
  }

  setupDraftActionMenu(): void {
    if (this.clientNavigationService.getPreviousLocation() === '/search') {
      this.draftMenu.addItem(this.createBackMenuItem());
    }
    this.draftMenu.addItem(this.createCopyDraftMenuItem());
    this.draftMenu.addItem(this.createNewReportTypeMenuItem());
    this.draftMenu.addItem(this.createPoliceMenuItem());
    this.draftMenu.addItem(this.createCheckOpslagMenuItem(this.agreementService.getPriceAgreementsDeviatesSubject(), this.unsubscribe$));
    this.draftMenu.addItem(this.createDmrLookupMenuItem());
    this.draftMenu.addItem(this.createQapterMenuItem());
    this.draftMenu.addItem(this.createCalculateActionMenu());
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
