import {Component} from '@angular/core';

@Component({
  selector: 'lc-cookie-text',
  templateUrl: './cookie-text.component.html'

})
export class CookieTextComponent {
  constructor() {
  }
}
