import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {combineLatest, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {
  CoreNonStandardPositionDTO,
  ExportNonStandardPositionDTO,
  NonStandardPositionDTO
} from '../../shared/dto/non-standard-position/non-standard-position-dto.model';
import {
  NonStandardPositionRepairMethodDTO
} from '../../shared/dto/non-standard-position/non-standard-position-repair-method-dto.model';
import {DataTableDirective} from 'angular-datatables';
import {NonStandardPositionsService} from '../../shared/service/NonStandardPositions.service';
import {BootstrapAlertType, BootstrapGrowlService} from '../../shared/ui/ngx-bootstrap-growl';
import {ADTSettings} from 'angular-datatables/src/models/settings';
import {LcDeclarativeDataTable} from '../../shared/lc-declarative-data-table';
import {CrudPrivateNonStandardPositionPopupComponent} from './crud/crud-private-non-standard-position-popup.component';
import {LoadingSpinnerUtil} from '../../shared/utils/loading-spinner-util';
import {PrincipalService} from '../../shared';
import EnvironmentUtils from '../../shared/utils/environment-utils';
import {
  ImportPrivateNonStandardPositionPopupComponent
} from './import/import-private-non-standard-position-popup.component';

@Component({
  templateUrl: './private-non-standard-positions.component.html',
  selector: 'lc-private-non-standard-positions',
  providers: [LoadingSpinnerUtil]
})
export class PrivateNonStandardPositionsComponent implements OnInit, AfterViewInit, OnDestroy {
  private unsubscribe$ = new Subject<ADTSettings>();
  repairMethods: NonStandardPositionRepairMethodDTO[];
  selectedNSP: NonStandardPositionDTO[] = [];

  @ViewChild('createdAt') createdAt: TemplateRef<any>;
  @ViewChild('nspActions') nspActions: TemplateRef<any>;
  @ViewChild('repairMethod') repairMethod: TemplateRef<any>;

  @ViewChild(DataTableDirective)
  dataTableDirective: DataTableDirective;
  dataTable = new LcDeclarativeDataTable(this.unsubscribe$);

  constructor(private nonStandardPositionsService: NonStandardPositionsService,
              protected modalService: NgbModal,
              public spinnerUtil: LoadingSpinnerUtil,
              private bootstrapGrowlService: BootstrapGrowlService,
              public principal: PrincipalService) {
  }

  ngOnInit(): void {
    this.loadData();
  }

  ngAfterViewInit(): void {
    const dtOptions = {
      columns: [
        {title: 'Beskrivelse', data: 'description'},
        {title: 'Arbejdsenheder', data: 'workingUnits'},
        {title: 'NFG - Ny For Gammel', data: 'newForOld'},
        {title: 'Beløb', data: 'price'},
        {title: 'Reservedel', data: 'sparePartDescription'},
        {title: 'Reparationsart', data: 'repairMethod', ngTemplateRef: {ref: this.repairMethod}},
        {title: 'Gruppe', data: 'group'},
        {title: 'Oprettet dato', data: 'createdAt', ngTemplateRef: {ref: this.createdAt}},
        {title: '', data: null, defaultContent: '', className: 'table-cell', ngTemplateRef: {ref: this.nspActions}, searchable: false, orderable: false},
      ],
      order: [[0, 'desc']],
      data: []
    };

    this.dataTable.init(this.dataTableDirective, dtOptions);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  refresh(): void {
    this.selectedNSP = [];
    this.dataTable.refresh(() => this.loadData())
  }

  private loadData(): void {
    const nonStandardPositions$ = this.nonStandardPositionsService.getPrivateNonStandardPositions();
    const repairMethods$ = this.nonStandardPositionsService.getNonStandardPositionRepairMethods();
    this.spinnerUtil.startLoading();
    combineLatest([nonStandardPositions$, repairMethods$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: ([nonStandardPositions, repairMethods]) => {
          this.repairMethods = repairMethods;
          this.spinnerUtil.stopLoading();
          this.dataTable.setData(nonStandardPositions);
        },
        error: () => {
          this.spinnerUtil.stopLoading();
        }
      });
  }

  public getRepairMethodDescription(repairMethodIdentifier: string): string {
    const index = this.repairMethods.findIndex(b => b.identifier === repairMethodIdentifier);
    return index > -1 ? this.repairMethods[index].identifier + ' - ' + this.repairMethods[index].description : repairMethodIdentifier;
  }

  public openCreateNonStandardPosition(): void {
    const modalRef = this.modalService.open(CrudPrivateNonStandardPositionPopupComponent, {size: 'lg'});
    const instance = modalRef.componentInstance as CrudPrivateNonStandardPositionPopupComponent;
    instance.repairMethods = this.repairMethods;
    instance.onConfirm
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((nonStandardPosition: NonStandardPositionDTO) => {
        this.nonStandardPositionsService.createPrivateNonStandardPosition(nonStandardPosition)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((nonStandardPosition: NonStandardPositionDTO) => {
            const data = this.dataTable.getData();
            data.push(nonStandardPosition);
            this.dataTable.refresh(() => this.dataTable.setData(data))
            this.bootstrapGrowlService.addAlert('1000 nummer oprettet', BootstrapAlertType.SUCCESS, 5000);
          });
      });
  }

  public openUpdateNonStandardPosition(nonStandardPositionDTO: NonStandardPositionDTO): void {
    const modalRef = this.modalService.open(CrudPrivateNonStandardPositionPopupComponent, {size: 'lg'});
    const instance = modalRef.componentInstance as CrudPrivateNonStandardPositionPopupComponent;
    instance.nonStandardPosition = Object.assign(new NonStandardPositionDTO(), nonStandardPositionDTO);
    instance.repairMethods = this.repairMethods;
    instance.onConfirm
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((nonStandardPosition: NonStandardPositionDTO) => {
        this.nonStandardPositionsService.updatePrivateNonStandardPosition(nonStandardPosition)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((nonStandardPosition: NonStandardPositionDTO) => {
            const data = this.dataTable.getData();
            const index = data.findIndex(nsp => nsp.id === nonStandardPosition.id)
            if (index > -1) {
              data[index] = nonStandardPosition;
            }
            this.dataTable.refresh(() => this.dataTable.setData(data))
            this.bootstrapGrowlService.addAlert('1000 nummer opdateret', BootstrapAlertType.SUCCESS, 5000);
          });
      });
  }

  deleteNonStandardPosition(nonStandardPosition: NonStandardPositionDTO): void {
    this.nonStandardPositionsService.deletePrivateNonStandardPosition(nonStandardPosition.itemId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        const data = this.dataTable.getData();
        this.dataTable.refresh(() => this.dataTable.setData(data.filter(b => b.id !== nonStandardPosition.id)))
        this.bootstrapGrowlService.addAlert('1000 nummer slettet', BootstrapAlertType.SUCCESS, 5000);
      });
  }

  isSelected(nsp: NonStandardPositionDTO): boolean {
    return this.selectedNSP.indexOf(nsp, 0) > -1;
  }

  toggleSelected(nsp: NonStandardPositionDTO): void {
    const index = this.selectedNSP.indexOf(nsp, 0);
    if (index > -1) {
      this.selectedNSP.splice(index, 1);
    } else {
      this.selectedNSP.push(nsp);
    }
  }

  toggleAllSelected(): void {
    if (this.selectedNSP.length > 0) {
      this.selectedNSP = []
    } else {
      const data = this.dataTable.getData();
      this.selectedNSP = [];
      data.forEach(nsp => this.selectedNSP.push(nsp))
    }
  }

  downloadNonStandardPositions(): void {
    let env = 'Udvikling';
    if (EnvironmentUtils.isProduction()) {
      env = 'Produktion'
    } else if (EnvironmentUtils.isTest()) {
      env = 'Test'
    }
    const coreNSP = this.selectedNSP.map(nsp => ({
      type: nsp.type,
      description: nsp.description,
      workingUnits: nsp.workingUnits,
      newForOld: nsp.newForOld,
      price: nsp.price,
      sparePartDescription: nsp.sparePartDescription,
      repairMethod: nsp.repairMethod,
      group: nsp.group
    }));
    const data = {
      env: env,
      sysTime: new Date().getTime(),
      atKlientNr: this.principal.getCurrentPrincipal().atKlientNr,
      username: this.principal.getUsername(),
      positions: coreNSP
    } as ExportNonStandardPositionDTO;

    const dataBlob = new Blob([JSON.stringify(data)], {type: 'application/json'});
    const url = window.URL.createObjectURL(dataBlob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'non-standard-positions-' + data.env.toLowerCase() + '-' + data.atKlientNr + '-' + data.username + '-' + data.sysTime + '.json';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  uploadNonStandardPositions($event): void {
    if ($event && $event.target && $event.target.files) {
      const file: File = $event.target.files[0];
      if (file) {
        const reader = new FileReader()
        reader.onload = (event) => { // called once readAsDataURL is completed
          const importData = JSON.parse(reader.result.toString());
          this.nonStandardPositionsService.getPrivateNonStandardPositions().pipe(takeUntil(this.unsubscribe$))
            .subscribe({
              next: (nonStandardPositions) => {
                const modalRef = this.modalService.open(ImportPrivateNonStandardPositionPopupComponent, {size: 'lg'});
                const instance = modalRef.componentInstance as ImportPrivateNonStandardPositionPopupComponent;
                instance.init(nonStandardPositions, importData)

                instance.onConfirm
                  .pipe(takeUntil(this.unsubscribe$))
                  .subscribe((selectedPositions: CoreNonStandardPositionDTO[]) => {
                    this.nonStandardPositionsService.importPrivateNonStandardPosition(selectedPositions)
                      .pipe(takeUntil(this.unsubscribe$))
                      .subscribe((nonStandardPositions: NonStandardPositionDTO[]) => {
                        const data = this.dataTable.getData();
                        data.push(...nonStandardPositions);
                        this.dataTable.refresh(() => this.dataTable.setData(data))
                        const numberString = nonStandardPositions.length === 1 ? 'nummer' : 'numre';
                        this.bootstrapGrowlService.addAlert(`1000 ${numberString} importeret`, BootstrapAlertType.SUCCESS, 5000);
                      });
                  })
              }
            });
        }
        reader.readAsText(file); // read file as data url
      }
      $event.target.value = null;
    }
  }
}
