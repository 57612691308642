<div class="modal-header pre-size header-style" style="text-align: center">
    <h3 class="text-truncate">
        {{title}}
    </h3>
    <div aria-label="Close" data-dismiss="modal" class="btn-close clickable" (click)="cancel()"></div>
</div>

<div class="modal-body" *ngIf="textLibraryType">
    <lc-text-library [type]="textLibraryType"
                     [editable]="editable"
                     [textLibraryService]="textLibraryService"
                     [reportType]="reportType"
                     (selectedText)="handleSelectedText($event)"></lc-text-library>
</div>
