import {Component, OnDestroy, OnInit} from '@angular/core';
import {SettingsService} from '../../../shared/service/settings.service';
import {BroadcastDTO} from '../../../shared/dto/broadcast-dto.model';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'lc-settings-broadcast',
  templateUrl: './settings.broadcast.component.html',
  styleUrls: ['../../settings.scss']
})
export class SettingsBroadcastComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  broadcasts: BroadcastDTO[];

  constructor(private settingsService: SettingsService) {
  }

  ngOnInit(): void {
    this.settingsService.getAllBroadcasts()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(broadcasts => {
        this.broadcasts = broadcasts;
      });
  }

  deleteBroadcast(broadcast: BroadcastDTO): void {
    this.settingsService.deleteBroadcast(broadcast.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        const broadcastIndex = this.broadcasts.indexOf(broadcast);
        this.broadcasts.splice(broadcastIndex, 1);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
