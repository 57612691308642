<ng-container *ngIf="!b2cInProgress">
<form (ngSubmit)="login()" autocomplete="off">
    <div class="row">
        <div class="col">
            <div class="pb-3">
                <label for="username">BrugerId</label><a *ngIf="!!loginFlow" class="pull-right clickable"
                                                         style="margin-top: 0.1rem;" (click)="login(true)">Skift
                BrugerId</a>
                <input type="text" autocapitalize="none" autocorrect="off" class="form-control"
                       #usernameInput
                       name="username" id="username" autocomplete="username"
                       [(ngModel)]="username" [disabled]="!!loginFlow">

                <div class="pt-1 ps-1" *ngIf="loginFlow === 'B2C'">
                    <label>Send kode via</label>
                    <div class="ms-3">
                        <div class="form-check">
                            <input class="form-check-input " type="radio" name="inlineRadioOptions" id="smsCallRadio" value="SMS_CALL" [(ngModel)]="b2cFlow">
                            <label class="form-check-label" for="smsCallRadio">SMS/Opkald</label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="emailRadio" value="EMAIL" [(ngModel)]="b2cFlow">
                            <label class="form-check-label" for="emailRadio">Email</label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="appRadio" value="APP" [(ngModel)]="b2cFlow">
                            <label class="form-check-label" for="appRadio">Authenticator app</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="pb-3" [ngClass]="{'d-none': loginFlow !== 'LOCAL'}">
                <label for="password"> Kodeord</label><a class="pull-right clickable" style="margin-top: 0.1rem;"
                                                         (click)="onChangePasswordClicked()">Skift kodeord</a>
                <input type="password" class="form-control" #passwordInput autocorrect="off" autocomplete="current-password"
                       name="password"
                       id="password" [(ngModel)]="password">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <button type="submit" class="btn btn-primary pull-right">
                <lc-spinner-text [spin]="isLoading"
                                 text="{{loginFlow === 'LOCAL' ? 'Log ind' : 'Videre'}}"></lc-spinner-text>
            </button>
        </div>
    </div>

    <div class="row" *ngIf="$capslockEnabled | async">
        <div class="col">
            <div class="alert alert-warning p-2 mt-3 mb-0">
                Bemærk at Caps Lock er slået til
            </div>
        </div>
    </div>
    <div class="row" *ngIf="authenticationMessage">
        <div class="col">
            <div class="alert alert-danger p-2 mt-3 mb-0">
                {{authenticationMessage}}
                <div *ngIf="providerInfoMessage">
                    {{providerInfoMessage}}
                </div>
                <div *ngIf="showGeneralProviderInfo">
                    Kontakt din autotaks udbyder<br/>
                    Se <a
                    href="http://www.forsikringogpension.dk/virksomheder/autotaks/udbydere-og-support/Sider/udbydere-og-support.aspx">Forsikring
                    og Pension</a>
                </div>
            </div>
        </div>
    </div>
</form>
</ng-container>
<div class="text-center text-primary pt-2 fs-5" *ngIf="b2cInProgress">
    <lc-spinner-text [spin]="b2cInProgress" ></lc-spinner-text>
    <span>{{b2cStatus === 'logout' ? 'Logout' : 'Login'}} er igang</span>
</div>
