import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ClientStateListViewDTO} from '../../../draft/dto/client-state-list-view-dto.model';

@Component({
  templateUrl: './new-drafts-popup.component.html',
})
export class NewDraftsPopupComponent {
  @Input() clientStateList: ClientStateListViewDTO[];

  constructor(private activeModal: NgbActiveModal) {
  }

  public close(): void {
    this.activeModal.dismiss();
  }

  getReportKey(clientState: ClientStateListViewDTO): string {
    return clientState.taksRapportKey || clientState.proformaOrdreNummer;
  }
}
