<div class="text-center" *ngIf="isLoading">
    <i class="fa fa-circle-o-notch fa-spin fop-page-spinner"></i>
</div>
<div *ngIf="!isLoading" class="no-focus" tabindex="0" [lcAutofocus]>
    <div class="card">
        <div class="card-body">
            <h4 class="card-title">Support</h4>
            <div class="row">
                <div class="col-md-12 text-center">
                    <span *lcHasAnyAuthority="'ROLE_VK'">Du kan få hjælp til Autotaks/Forsi.dk hos din udbyder,
                        kontakt:<br />{{providerInfo()}}</span>
                    <span *lcHasAnyAuthority="['ROLE_Taks', 'ROLE_SagsB']">Du kan få hjælp til Autotaks/Forsi.dk hos din
                        Autotaksansvarlige i din organisation.</span>
                </div>
            </div>
        </div>
    </div>
    <div>&nbsp;</div>
    <div *lcHasAnyAuthority="'ROLE_Taks'" class="card">
        <div class="card-body">
            <h4 class="card-title">Bruger</h4>
            <div>
                <div class="row pb-3">
                    <label class="col-4 col-form-label" for="taksNr">Taks Nr</label>
                    <div class="col-md-4 col-8">
                        <input class="form-control" id="taksNr" value="{{principalModel.atKlientNr}}" disabled>
                    </div>
                </div>

                <div class="row pb-3">
                    <label class="col-4 col-form-label" for="brugerId">Bruger ID</label>
                    <div class="col-md-4 col-8">
                        <input class="form-control" id="brugerId" value="{{principalModel.userName}}" disabled>
                    </div>
                </div>

                <div class="row pb-3">
                    <label class="col-4 col-form-label" for="brugerId">Navn</label>
                    <div class="col-md-4 col-8">
                        <input class="form-control" id="name" value="{{principalModel.personName}}" disabled>
                    </div>
                </div>

                <div class="row pb-3">
                    <label class="col-4 col-form-label" for="brugerId">Selskab</label>
                    <div class="col-md-4 col-8">
                        <input class="form-control" id="selskab"
                            value="{{principalModel.orgNr}} - {{principalModel.organizationName}}" disabled>
                    </div>
                </div>

            </div>
        </div>
        <div class="row mb-3">
            <div class="col-12 text-center">
                Følgende data er registreret på din brugeradgang.
                <ng-container *ngIf="showReportError()">
                    <br>
                    Rettelser skal indrapporteres via linket <a href="{{getSupportUrl()}}"
                        target="_blank">"Fejlindberetning" <span class="fa fa-lg fa-exclamation-triangle"></span></a>
                </ng-container>
            </div>
        </div>
    </div>

    <div *lcHasAnyAuthority="'ROLE_VK'" class="card">
        <div class="card-body">
            <h4 class="card-title">Bruger</h4>
            <div class="row pb-3">
                <label class="col-4 col-form-label" for="vkNr">Værksted Nr</label>
                <div class="col-md-4 col-8">
                    <input class="form-control" id="vkNr" value="{{principalModel.atKlientNr}}" disabled />
                </div>
            </div>

            <div class="row pb-3">
                <label class="col-form-label col-4" for="vkBrugerId">Bruger ID</label>
                <div class="col-md-4 col-8">
                    <input class="form-control" id="vkBrugerId" value="{{principalModel.userName}}" disabled>
                </div>
            </div>

            <div class="row pb-3">
                <label class="col-form-label col-4" for="vkName">Navn</label>
                <div class="col-md-4 col-8">
                    <input class="form-control" id="vkName" value="{{principalModel.organizationName}}" disabled>
                </div>
            </div>

            <div class="row pb-3">
                <label class="col-form-label col-4" for="vkAddress1">Adresse</label>
                <div class="col-md-4 col-8">
                    <input class="form-control" id="vkAddress1" value="{{principalModel.vkStam?.adresse1}}" disabled>
                </div>
            </div>

            <div class="row pb-3">
                <label class="col-form-label col-4" for="vkAddress2">&nbsp;</label>
                <div class="col-md-4 col-8">
                    <input class="form-control" id="vkAddress2" value="{{principalModel.vkStam?.adresse2}}" disabled>
                </div>
            </div>

            <div class="row pb-3">
                <label class="col-form-label col-4" for="brugerId">Postnr</label>
                <div class="col-md-1 col-2">
                    <input class="form-control" id="vkZipCode" value="{{principalModel.vkStam?.postnr}}" disabled>
                </div>
                <label class="col-form-label col-2 col-md-1" for="brugerId">By</label>
                <div class="col-4 col-md-2">
                    <input class="form-control" id="vkCity" value="{{principalModel.vkStam?.bynavn}}" disabled />
                </div>
            </div>
            <div class="row pb-3">
                <label class="col-form-label col-4" for="vkEmail">Email</label>
                <div class="col-md-4 col-8">
                    <input class="form-control" id="vkEmail" value="{{principalModel.vkStam?.email}}" disabled>
                </div>
            </div>
            <div class="row pb-3">
                <label class="col-form-label col-4" for="vkCvr">CVR</label>
                <div class="col-md-4 col-8">
                    <input class="form-control" id="vkCvr" value="{{principalModel.vkStam?.cvrNr}}" disabled>
                </div>
            </div>
            <div class="row pb-3">
                <label class="col-form-label col-4" for="vkBankRegNumber">Konto</label>
                <div class="col-3 col-md-1 pe-0">
                    <input class="form-control" id="vkBankRegNumber" value="{{principalModel.vkStam?.regnr}}" disabled>
                </div>
                <div class="col-5 col-md-3 ps-0">
                    <input class=" form-control" id="vkBankAccountNumber" value="{{principalModel.vkStam?.kontonr}}" disabled />
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-12 text-center">
            Følgende data er registreret på din brugeradgang.
            <ng-container *ngIf="showReportError()">
                <br>
                Rettelser skal indrapporteres via linket <a href="{{getSupportUrl()}}"
                    target="_blank">"Fejlindberetning" <span class="fa fa-lg fa-exclamation-triangle"></span></a>
            </ng-container>
        </div>
    </div>
</div>