import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {PotentialLinkedReportDTO} from '../../../shared/dto/potential-linked-report-dto.model';
import {DraftService} from '../../service/draft.service';
import {CreateLinkedReportDTO} from '../../../shared/model/create-linked-report-dto.model';
import {Router} from '@angular/router';
import {combineLatest, Subject} from 'rxjs';
import {ClientStateTaksatorIdentifier} from '../../dto/client-state-taksator-identifier.model';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'lc-create-linked-report-from-draft',
  templateUrl: './create-linked-report-from-draft.component.html',
  styleUrls: ['create-linked-report-from-draft.scss']
})
export class CreateLinkedReportFromDraftComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  @Input() taksatorClientState: ClientStateTaksatorIdentifier;
  isLoading = true;
  error = false;
  ongoingRequest = false;
  potentialLinkedReportTypes: PotentialLinkedReportDTO[];
  calculated = false;

  constructor(private draftService: DraftService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.draftService.calculationExists(this.taksatorClientState.token);
    combineLatest(
      [this.draftService.calculationExists(this.taksatorClientState.token),
        this.draftService.getPotentialLinkedReports(this.taksatorClientState.token)]
    ).pipe(takeUntil(this.unsubscribe$)).subscribe(([calculationExists, potentialLinkedReportTypes]) => {
      this.calculated = calculationExists;
      this.potentialLinkedReportTypes = potentialLinkedReportTypes;
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      this.error = true;
    });
  }

  handlePotentialLinkedReportSelected(potentialLinkedReport: PotentialLinkedReportDTO): void {
    const createLinkedReportDTO = new CreateLinkedReportDTO();
    createLinkedReportDTO.type = potentialLinkedReport.type;
    this.ongoingRequest = true;
    this.draftService.createLinkedReport(this.taksatorClientState.token, createLinkedReportDTO).pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      this.router.navigate(['draft', 'edit', value.clientStateToken]);
      this.ongoingRequest = false;
    }, error => {
      console.log('Failed to create linked report', error);
      this.ongoingRequest = false;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
