import {Injectable} from '@angular/core';
import {RapportInfoDTO, ReportDTO} from '..';
import {Observable, Subject} from 'rxjs';
import {ReportDiffResult, ReportDiffResultDto} from '../dto/report-diff-result-dto.model';
import {ClientStateReferenceDTO} from '../model/report-copy-result.dto';
import {HttpClient, HttpParams} from '@angular/common/http';
import {TransientReportRepository} from '../../shared/repository/transient.report.repository';
import {ReportSearchResult} from '../model/report-search-result.model';
import {DamagesNoteDTO} from '../../shared/dto/damages-note-dto.model';
import {map, tap} from 'rxjs/operators';
import {PotentialLinkedReportDTO} from '../../shared/dto/potential-linked-report-dto.model';
import {CreateLinkedReportDTO} from '../../shared/model/create-linked-report-dto.model';
import {LinkedReportDTO} from '../../shared/model/linked-report-dto.model';
import {CarSaleDTO} from '../../shared/dto/carsale-dto.model';
import {CreateCarSaleDTO} from '../../shared/dto/create-carsale-dto.model';
import {ConnectedDraftsDTO} from '../model/connected-drafts-dto.model';
import {DeliveryNoteReceiverType} from '../../shared/ui/delivery-note/model/delivery-note-receiver-type.enum';
import {DeliveryNoteTemplateDataDTO} from '../../shared/ui/delivery-note/model/delivery-note-template-data-dto.model';
import {
  DeliveryNoteReceiverTemplateDataDTO
} from '../../shared/ui/delivery-note/model/delivery-note-receiver-template-data-dto.model';
import {CreateDeliveryNoteDTO} from '../../shared/ui/delivery-note/model/create-delivery-note-dto.model';
import {DeliveryNoteDTO} from '../../shared/ui/delivery-note/model/delivery-note-dto.model';
import {VKTilbudStatusDTO} from '../../shared/dto/vktilbud-status-dto.model';
import {DeliveryNoteDataProvider} from '../../shared/service/delivery-note-data-provider';
import {RapportType} from '../../shared/model/rapport-type.model';
import {AttachDeliveryNoteDTO} from '../../shared/ui/delivery-note/model/attach-delivery-note-dto.model';
import {PrintDeliveryNoteDTO} from '../../shared/ui/delivery-note/model/print-delivery-note-dto.model';
import {RapportListDTO} from '../model/report-list-dto.model';
import {StringUtils} from '../../shared/utils/string-utils';
import {PrintSettingsDTO} from '../../shared/dto/print-settings-dto.model';
import {MessageDTO} from '../../shared/dto/message-dto.model';
import {CreateMessageDTO} from '../../shared/ui/message/dto/create-message-dto.model';
import {PolicyDTO} from '../../shared/dto/policy-dto.model';
import {Cacheable} from 'ts-cacheable';
import {AttachmentDTO} from '../../shared/dto/attachment-dto.model';
import {AttachmentSummaryDTO} from '../../shared/dto/attachment-summary-dto.model';
import {TemporaryAttachmentApprovalModel} from '../../shared/model/temporary-attachment-approval.model';
import {BreadcrumbService} from '../../shared/ui/breadcrumb/breadcrumb.service';
import {UpdateReportAcceptStatusDTO} from '../model/update-report-accept-status-dto.model';
import {UserDTO} from '../../shared/dto/user-dto.model';

const cacheBuster$ = new Subject<void>();
const maxAge = 2000;

@Injectable()
export class ReportService implements DeliveryNoteDataProvider {
  private reportUrl = 'report';
  private searchReportsUrl = 'report/search';
  private rapportinfoUrl = 'report/rapportinfo';
  private diffUrl = 'report/diff';

  private same(first: RapportListDTO, second: RapportListDTO): boolean {
    const proforma1 = !StringUtils.isEmpty(first.vkReportKey) ? first.vkReportKey.trim() : null;
    const proforma2 = !StringUtils.isEmpty(second.vkReportKey) ? second.vkReportKey.trim() : null;

    if (proforma1 !== proforma2) {
      return false;
    }

    return first.taksatorReportId === second.taksatorReportId;
  }

  constructor(private http: HttpClient, private transientReportRepository: TransientReportRepository,
              private breadcrumbService: BreadcrumbService) {
  }

  clearCache(): void {
    cacheBuster$.next();
  }

  @Cacheable({cacheBusterObserver: cacheBuster$, maxAge: maxAge})
  getReport(reportId: number): Observable<ReportDTO> {
    return this.http.get<ReportDTO>(this.reportUrl + '/' + reportId).pipe(
      tap(reportDTO => {
        //we fill in the breadcrumb context each time we request a report by its reportid
        this.breadcrumbService.setReportKey({reportKey: reportDTO.key, reportId: reportDTO.id});
      })
    );
  }

  getVKTilbudStatus(reportId: number): Observable<VKTilbudStatusDTO> {
    return this.http.get<VKTilbudStatusDTO>(this.reportUrl + '/' + reportId + '/vktilbudstatus');
  }

  getReportByKey(reportKey: string): Observable<ReportDTO> {
    const params = new HttpParams().set('reportKey', reportKey);
    return this.http.get<ReportDTO>(this.reportUrl, {params});
  }

  recreateMissingClientStateFromReport(reportId: number): Observable<ClientStateReferenceDTO> {
    return this.http.post<ClientStateReferenceDTO>(this.reportUrl + '/' + reportId + '/draft', null);
  }

  findReports(vkReportKey: string, taksatorReportKey: string, regNr: string, dato: string, selskab: string, state: string, vin: string): Observable<ReportSearchResult> {
    const httpParams = new HttpParams()
      .set('vkReportKey', vkReportKey ? vkReportKey.toUpperCase() : '')
      .set('taksatorReportKey', taksatorReportKey ? taksatorReportKey.toUpperCase() : '')
      .set('regNr', regNr ? regNr.toUpperCase() : '')
      .set('dato', dato ? dato.toUpperCase() : '')
      .set('selskab', selskab ? selskab.toUpperCase() : '')
      .set('state', state ? state : '')
      .set('vin', vin ? vin : '');

    return this.http.get<RapportListDTO[]>(this.searchReportsUrl, {observe: 'response', params: httpParams})
      .pipe(map(response => {
        const result = new ReportSearchResult();
        result.limitReached = response.headers.get('max-report-search-limit-reached') !== null;
        result.searchLimit = response.headers.get('max-report-search-limit-reached');
        result.rapportDTOs = response.body;
        return result;
      }));
  }

  findAllReports(): Observable<RapportListDTO[]> {
    return this.http.get<RapportListDTO[]>(this.reportUrl)
      .pipe(map(response => {
        //merge result with possible transient RapportDTO's
        //if performance is an issue, we could try to move this operation to an async operation
        const transientLivingReports = this.transientReportRepository.livingObjects();
        const transientReportsToMerge = transientLivingReports.filter(transientReport => {
          for (const reportFromServer of response) {
            if (this.same(transientReport, reportFromServer)) {
              //already in list - no reason to continue to look
              console.log('transient report is already in list');
              return false;
            }
          }
          return true;
        });
        //concatenate possible transient objects
        console.log('Merging all reports with transient living objects:', transientReportsToMerge.length);
        return transientReportsToMerge.concat(response);
      }));
  }

  getReportInfo(vkWidePk: string, taksatorWidePk: string): Observable<RapportInfoDTO> {
    const params = new HttpParams()
      .set('vkWidePk', vkWidePk ? vkWidePk : '')
      .set('taksatorWidePk', taksatorWidePk ? taksatorWidePk : '');
    return this.http.get<RapportInfoDTO>(this.rapportinfoUrl, {params: params});
  }

  diff(vkWidePk: string, taksWidePk: string): Observable<ReportDiffResult> {
    return this.http.get<ReportDiffResultDto>(this.diffUrl + '/' + vkWidePk + '/' + taksWidePk)
      .pipe(map(response => {
        const result = new ReportDiffResult();
        result.addSection('t1', vkWidePk, taksWidePk, 'Kontrolblad', response.kontrolblad);
        result.addSection('t2', vkWidePk, taksWidePk, 'Forside', response.forside);
        result.addSection('t3', vkWidePk, taksWidePk, 'Arbejdsbeskrivelse', response.arbejdsbeskrivelse);
        result.addSection('t4', vkWidePk, taksWidePk, 'Reservedele', response.reservedele);
        result.addSection('t5', vkWidePk, taksWidePk, 'Lak', response.lak);
        return result;
      }));
  }

  public copyReport(reportKey: string, copyBeregningsdata: boolean, copyBilag: boolean, changeReportTypeForCopy: boolean, copyToNextAvailable9000Number: boolean): Observable<ClientStateReferenceDTO> {
    const params = new HttpParams()
      .set('copyBeregningsdata', copyBeregningsdata.toString())
      .set('copyBilag', copyBilag.toString())
      .set('changeReportTypeForCopy', changeReportTypeForCopy.toString())
      .set('copyToNextAvailable9000Number', copyToNextAvailable9000Number.toString());
    return this.http.get<ClientStateReferenceDTO>(this.reportUrl + '/' + reportKey + '/copy', {params: params});
  }

  getDamagesNote(reportId: number): Observable<DamagesNoteDTO> {
    return this.http.get<DamagesNoteDTO>(this.reportUrl + '/' + reportId + '/damagesnote');
  }

  updateDamagesNote(reportId: number, text: string): Observable<DamagesNoteDTO> {
    const damagesNote = new DamagesNoteDTO();
    damagesNote.text = text;
    return this.http.put<DamagesNoteDTO>(this.reportUrl + '/' + reportId + '/damagesnote', damagesNote);
  }

  @Cacheable({cacheBusterObserver: cacheBuster$, maxAge: maxAge})
  public getPotentialLinkedReports(reportId: number): Observable<PotentialLinkedReportDTO[]> {
    return this.http.get<PotentialLinkedReportDTO[]>(this.getPotentialLinkedReportsUrl(reportId));
  }

  public createLinkedReport(reportId: number, createLinkedReport: CreateLinkedReportDTO): Observable<LinkedReportDTO> {
    return this.http.post<LinkedReportDTO>(this.getPotentialLinkedReportsUrl(reportId) + createLinkedReport.type, createLinkedReport);
  }

  private getPotentialLinkedReportsUrl(reportId: number): string {
    return this.reportUrl + '/' + reportId + '/linkedreport/'
  }

  getCarSales(list: RapportListDTO[]): Observable<CarSaleDTO[]> {
    return this.http.post<CarSaleDTO[]>(this.reportUrl + '/carsale', list.map(t => t.id));
  }

  createCarSale(reportId: number, createCarSaleDTO: CreateCarSaleDTO): Observable<CarSaleDTO> {
    return this.http.post<CarSaleDTO>(this.reportUrl + '/' + reportId + '/carsale', createCarSaleDTO);
  }

  deleteCarSale(reportId: number): Observable<any> {
    return this.http.delete(this.reportUrl + '/' + reportId + '/carsale');
  }

  hasDraftOfTypeHorT(reportId: number): Observable<ConnectedDraftsDTO> {
    return this.http.get<ConnectedDraftsDTO>(this.reportUrl + '/' + reportId + '/has-draft-of-type-H-or-T');
  }

  getReportType(identifier: any): Observable<RapportType> {
    return this.getReport(identifier).pipe(map(value => RapportType.extractFrom(value.key)));
  }

  public getDeliveryNoteTemplateData(reportId: any): Observable<DeliveryNoteTemplateDataDTO> {
    return this.http.get<DeliveryNoteTemplateDataDTO>(this.reportUrl + '/' + reportId + '/deliverynote/templatedata');
  }

  public getDeliveryNoteTemplateDataByReceiverType(reportId: any, type: DeliveryNoteReceiverType): Observable<DeliveryNoteReceiverTemplateDataDTO> {
    return this.http.get<DeliveryNoteReceiverTemplateDataDTO>(this.reportUrl + '/' + reportId + '/deliverynote/receiver/' + type + '/templatedata');
  }

  public generateDeliveryNote(reportId: any, createDeliveryNote: CreateDeliveryNoteDTO): Observable<DeliveryNoteDTO> {
    return this.http.post<DeliveryNoteDTO>(this.reportUrl + '/' + reportId + '/deliverynote', createDeliveryNote);
  }

  attachDeliveryNote(reportId: any, deliveryNote: DeliveryNoteDTO): Observable<AttachDeliveryNoteDTO> {
    return this.http.post<AttachDeliveryNoteDTO>(this.reportUrl + '/' + reportId + '/deliverynote/attach', deliveryNote);
  }

  printDeliveryNote(reportId: any, deliveryNote: DeliveryNoteDTO): Observable<PrintDeliveryNoteDTO> {
    return this.http.post<PrintDeliveryNoteDTO>(this.reportUrl + '/' + reportId + '/deliverynote/print', deliveryNote);
  }

  getDeliveryNotePrintUrl(reportId: any, printId: number, fileName: string): string {
    return this.reportUrl + '/' + reportId + '/deliverynote/print/' + printId + '/' + fileName;
  }

  getPrintSettings(reportKey: string): Observable<PrintSettingsDTO> {
    return this.http.get<PrintSettingsDTO>(this.reportUrl + '/' + encodeURIComponent(reportKey) + '/print/settings');
  }

  getCarDetails(reportId: number): any {
    return this.http.get<any>(this.reportUrl + '/' + reportId + '/car-details');
  }

  getMessages(reportId: number): Observable<MessageDTO[]> {
    return this.http.get<MessageDTO[]>(this.reportUrl + '/' + reportId + '/messages');
  }

  sendMessage(reportId: number, createMessageDTO: CreateMessageDTO): Observable<MessageDTO> {
    return this.http.post<MessageDTO>(this.reportUrl + '/' + reportId + '/messages', createMessageDTO);
  }

  getPolicy(reportId: number): Observable<PolicyDTO> {
    return this.http.get<PolicyDTO>(this.reportUrl + '/' + reportId + '/policy');
  }

  @Cacheable({cacheBusterObserver: cacheBuster$, maxAge: maxAge})
  getAttachments(reportKey: string): Observable<AttachmentDTO[]> {
    return this.http.get<AttachmentDTO[]>(this.reportUrl + '/' + reportKey.trim() + '/attachments');
  }

  @Cacheable({cacheBusterObserver: cacheBuster$, maxAge: maxAge})
  getAttachmentSummary(reportKey: string): Observable<AttachmentSummaryDTO> {
    return this.http.get<AttachmentSummaryDTO>(this.reportUrl + '/' + reportKey.trim() + '/attachments/summary');
  }

  public approveAttachments(reportKey: string, approvalModel: TemporaryAttachmentApprovalModel): Observable<AttachmentDTO[]> {
    const url = this.reportUrl + '/' + reportKey.trim() + '/attachments';
    return this.http.put<AttachmentDTO[]>(url, approvalModel.temporaryAttachmentsToApprove, {headers: {'Content-Type': 'application/json', Accept: 'application/json'}});
  }

  updateReportAcceptStatus(reportId: number, reportAcceptStatus: UpdateReportAcceptStatusDTO): Observable<void> {
    return this.http.put<void>(this.reportUrl + '/' + reportId + '/report-accept-status', reportAcceptStatus);
  }

  getWorkshopResponsible(reportId: number): Observable<UserDTO> {
    return this.http.get<UserDTO>(this.reportUrl + '/' + reportId + '/workshop-responsible');
  }

  getWorkshopResponsibleByKey(reportKey: string): Observable<UserDTO> {
    const params = new HttpParams().set('reportKey', reportKey);
    return this.http.get<UserDTO>(this.reportUrl + '/workshop-responsible', {params});
  }

  hasAutoflexBookings(reportKey: string): Observable<boolean> {
    return this.http.get<boolean>(this.reportUrl + '/' + reportKey + '/has-autoflex-bookings');
  }

}
