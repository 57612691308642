export enum AcknowledgeEnum {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED'
}
export class SparePartBookResponseItemDTO {
  sparePartSupplierId: number;
  supplierReference: string;
  acknowledge: AcknowledgeEnum;
}
export class SparePartBookResponseDTO {
  sparePartList: SparePartBookResponseItemDTO[];
}
