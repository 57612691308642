<div class="damagesNoteMaxWidth">
    <label for="editDamageNoteText">Rediger skadenotat</label>
    <span class="float-end">
        <button class="btn btn-sm btn-primary ms-2" (click)="showTextLibrary()">Tekstbibliotek</button>
        <lc-org-text-library-button *lcHasAnyAuthority="'ROLE_Taks'" class="ms-2" [libraryType]="libraryType" [reportType]="reportType" (selectedText)="didSelectTextFromLibrary($event)"></lc-org-text-library-button>
    </span>
    <div class="pb-3 mt-2">
        <textarea [(ngModel)]="damagesNoteText" id="editDamageNoteText" name="editDamageNoteText"
                  class="form-control post-it" rows="8"></textarea>
        <button type="button" class="btn btn-sm btn-primary float-end mt-1" (click)="saveText()">
            Gem
        </button>
    </div>
    <strong>Må ikke indeholde personoplysninger</strong>
    <p>Du skal selv gemme ændringer i dette felt, også efter brug at tekstbibliotek.</p>
</div>
