export class BusinessToBusinessDTO {
  reportKey: string;
  vkNrLak: string;
  vkNrCabplan: string;
  vkNameLak: string;
  vkNameCabplan: string;
}

export class IntegrationsDel {
  public static LAK: 'LAK';
  public static TIDSPLANLAEGNING: 'TIDSPLANLAEGNING';
}

export class BusinessToBusinessIntegrationResultDTO {
  integrationWentOk: boolean;
  message: string;
}
