import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DamageZoneDTO} from 'app/shared';
import {BootstrapAlertType, BootstrapGrowlService} from 'app/shared/ui/ngx-bootstrap-growl';
import {RequisitionService} from 'app/shared/service/requisition.service';
import {RequisitionDTO} from 'app/shared/dto/requisition-dto.model';
import {RequisitionDetailDTO} from 'app/shared/dto/requisition-detail.dto';
import {Router} from '@angular/router';
import {OpslagService} from 'app/shared/service/opslag.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  templateUrl: './requisition.component.html',
  styleUrls: [
    'requisition.scss'
  ],
  providers: [RequisitionService]
})
export class RequisitionComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  searchRequisition = '';
  searchingRequisition: boolean;
  isCreating: boolean;
  searchRequisitionSelskab = '';
  requisition: RequisitionDTO;

  forsikringstager: Person;
  skadelidte: Person;
  forsikringstagerVehicle: Vehicle;
  skadelidteVehicle: Vehicle;
  vehicle: Vehicle;
  remarks: Remarks;
  info: Info;

  constructor(private activeModal: NgbActiveModal,
              private router: Router,
              private requisitionService: RequisitionService,
              private opslagService: OpslagService,
              private bootstrapGrowlService: BootstrapGrowlService) {
    this.reset();
  }

  ngOnInit(): void {

  }

  public reset(): void {
    this.isCreating = false;
    this.requisition = null;
    this.forsikringstager = new Person();
    this.skadelidte = new Person();
    this.remarks = new Remarks();
    this.forsikringstagerVehicle = new Vehicle();
    this.skadelidteVehicle = new Vehicle();
    this.info = new Info();
  }

  public cancel(): void {
    this.activeModal.dismiss('cancel');
  }

  public newSearch(): void {
    this.searchRequisition = '';
    this.searchRequisitionSelskab = '';
    this.reset();
  }

  public create(): void {
    this.isCreating = true;
    this.requisitionService.createDraftFromRequisition(this.requisition.selskab.code.toUpperCase(), this.requisition.skadeNr).pipe(takeUntil(this.unsubscribe$)).subscribe(clientState => {
      this.router.navigate(['/draft/edit/', clientState.token]);
      this.isCreating = false;
      this.cancel();
    }, error => {
      this.isCreating = false;
    });
  }

  doRequisitionLookup(): void {
    this.reset();
    this.searchingRequisition = true;
    this.requisitionService.getRequisition(this.searchRequisitionSelskab.toUpperCase(), this.searchRequisition).pipe(takeUntil(this.unsubscribe$)).subscribe(requisition => {
      this.searchingRequisition = false;

      if (!requisition) {
        this.bootstrapGrowlService.addAlert('Rekvisition ikke fundet', BootstrapAlertType.WARNING, 3000);
        return;
      }

      if (!requisition.details) {
        this.bootstrapGrowlService.addAlert('Rekvisition fundet men mangler detaljer', BootstrapAlertType.WARNING, 3000);
        return;
      }

      this.requisition = requisition;
      this.requisition.details.forEach(detail => {
        switch (detail.key) {
          case 'forsikringstager.regNr':
            this.forsikringstager.regNr = detail.value;
            this.forsikringstagerVehicle.regNr = detail.value;
            break;
          case 'forsikringstager.navn':
            this.forsikringstager.navn = detail.value;
            break;
          case 'forsikringstager.address.line1':
            this.forsikringstager.adresse1 = detail.value;
            break;
          case 'forsikringstager.address.line2':
            this.forsikringstager.adresse2 = detail.value;
            break;
          case 'forsikringstager.postalcode':
            this.forsikringstager.postNr = detail.value;
            break;
          case 'forsikringstager.city':
            this.forsikringstager.by = detail.value;
            break;
          case 'forsikringstager.telefonnummer':
          case 'forsikringstager.mobilnummer': // To be able to read phone number from requisitions made before changing mobile to phone
            this.forsikringstager.telefon = detail.value;
            break;
          case 'forsikringstager.email':
            this.forsikringstager.email = detail.value;
            break;

          case 'skadelidte.regNr':
            this.skadelidte.regNr = detail.value;
            this.skadelidteVehicle.regNr = detail.value;
            break;
          case 'skadelidte.navn':
            this.skadelidte.navn = detail.value;
            break;
          case 'skadelidte.address.line1':
            this.skadelidte.adresse1 = detail.value;
            break;
          case 'skadelidte.address.line2':
            this.skadelidte.adresse2 = detail.value;
            break;
          case 'skadelidte.postalcode':
            this.skadelidte.postNr = detail.value;
            break;
          case 'skadelidte.city':
            this.skadelidte.by = detail.value;
            break;
          case 'skadelidte.telefonnummer':
          case 'skadelidte.mobilnummer': // To be able to read phone number from requisitions made before changing mobile to phone
            this.skadelidte.telefon = detail.value;
            break;
          case 'skadelidte.email':
            this.skadelidte.email = detail.value;
            break;

          case 'bemaerkninger':
            this.remarks.lines.push(detail);
            break;

          case 'forsikringstager.nummerplade.type':
            this.forsikringstagerVehicle.nummerpladetype = detail.value;
            break;
          case 'forsikringstager.stelnr':
            this.forsikringstagerVehicle.stelNr = detail.value;
            break;
          case 'forsikringstager.aargang':
            this.forsikringstagerVehicle.aargang = detail.value;
            break;
          case 'forsikringstager.fabrikattekst':
            this.forsikringstagerVehicle.fabrikat = detail.value;
            break;
          case 'forsikringstager.kmstand':
            this.forsikringstagerVehicle.kmstand = detail.value;
            break;

          case 'skadelidte.nummerplade.type':
            this.skadelidteVehicle.nummerpladetype = detail.value;
            break;
          case 'skadelidte.stelnr':
            this.skadelidteVehicle.stelNr = detail.value;
            break;
          case 'skadelidte.aargang':
            this.skadelidteVehicle.aargang = detail.value;
            break;
          case 'skadelidte.fabrikattekst':
            this.skadelidteVehicle.fabrikat = detail.value;
            break;
          case 'skadelidte.kmstand':
            this.skadelidteVehicle.kmstand = detail.value;
            break;

          case 'skadenummer':
            this.info.skadeNr = detail.value;
            break;
          case 'forsikringstager.skadenummer':
            this.info.skadeNr = detail.value;
            break;
          case 'forsikringstager.policenr':
            this.info.policenummer = detail.value;
            break;
          case 'skadetype':
            this.info.skadetype = detail.value;
            break;
          case 'skadedato':
            this.info.skadedato = detail.value;
            break;
          case 'zone':
            this.info.zoner = this.getZoneNames(detail.value);
            break;
          case 'sagsbehandler':
            this.info.sagsbehandler = detail.value;
            break;
        }
      });

      this.info.selskabskode = requisition.selskab.code;

      // choose vehicle to show in "Damaged vehicle section"
      this.vehicle = this.skadelidte.navn ? this.skadelidteVehicle : this.forsikringstagerVehicle;

      // sort remarks
      this.remarks.lines.sort((a: RequisitionDetailDTO, b: RequisitionDetailDTO): number => {
        if (a.keyListPosition > b.keyListPosition) {
          return 1;
        }
        if (a.keyListPosition < b.keyListPosition) {
          return -1;
        }
        return 0;
      });

      // get By
      if (this.forsikringstager.postNr) {
        this.opslagService.getPostNr(Number(this.forsikringstager.postNr)).pipe(takeUntil(this.unsubscribe$)).subscribe(postNr => {
          if (postNr) {
            this.forsikringstager.by = postNr.navn;
          }
        })
      }

      if (this.skadelidte.postNr) {
        this.opslagService.getPostNr(Number(this.skadelidte.postNr)).pipe(takeUntil(this.unsubscribe$)).subscribe(postNr => {
          if (postNr) {
            this.skadelidte.by = postNr.navn;
          }
        })
      }

    }, error => {
      this.reset();
    });
  }

  private getZoneNames(zonesString: string): string {
    if (!zonesString) {
      return '';
    }

    const result = [];
    const zones = zonesString.split(',');
    if (zones) {
      zones.forEach(zone => {
        const damageZone = DamageZoneDTO.valueOf(zone);
        if (damageZone) {
          result.push(damageZone.getShortName())
        }
      })
    }

    return result.join(',');
  }

  searchRequisitionKeyUp(event): void {
    if (event.key === 'Enter') {
      // do not submit form
      event.preventDefault();
      this.doRequisitionLookup();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}

class Person {
  regNr: string;
  navn: string;
  adresse1: string;
  adresse2: string;
  postNr: string;
  by: string;
  telefon: string;
  email: string;
}

class Remarks {
  lines: RequisitionDetailDTO[] = [];
}

class Vehicle {
  regNr: string;
  nummerpladetype: string;
  stelNr: string;
  aargang: string;
  fabrikat: string;
  kmstand: string;
}

class Info {
  selskabskode: string;
  skadeNr: string;
  policenummer: string;
  skadetype: string;
  skadedato: string;
  zoner: string;
  sagsbehandler: string;
}
