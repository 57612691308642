import {Component, Input} from '@angular/core';
import {InfoEntryModel} from './info-entry.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {InfoPopupComponent} from './info-popup.component';

@Component({
  selector: 'lc-info-button',
  templateUrl: './info-button.component.html'
})
export class InfoButtonComponent {

  @Input('header') header: string;
  @Input('info') infoEntries: InfoEntryModel[];

  constructor(private modalService: NgbModal) {
  }

  public showInfo(): void {
    const modalRef = this.modalService.open(InfoPopupComponent);
    modalRef.componentInstance.header = this.header;
    modalRef.componentInstance.infoEntries = this.infoEntries;
  }
}
