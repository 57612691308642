export class ReportDiffSectionContent {
  widePk: string;
  content: string;
}

export class ReportDiffSection {
  hasChanges: boolean;
  id: string;
  title: string;
  left: ReportDiffSectionContent;
  right: ReportDiffSectionContent;
}

export class ReportDiffResult {
  sections: ReportDiffSection[];

  constructor() {
    this.sections = [];
  }

  addSimpleSection(id: string, title: string, dto: ReportDiffSectionResultDTO): void {
    const section = new ReportDiffSection();
    const left = new ReportDiffSectionContent();
    const right = new ReportDiffSectionContent();
    //sort the keys by report type
    const keys = Object.keys(dto.contents).sort((a, b) => {
      let result = 0;
      if (a.startsWith('RP')) {
        result = -1;
      } else if (b.startsWith('RP')) {
        result = 1;
      } else {
        result = a.localeCompare(b);
      }
      return result;
    });
    left.content = dto.contents[keys[0].trim()];
    left.widePk = keys[0];
    right.content = dto.contents[keys[1].trim()];
    right.widePk = keys[1];
    section.title = title;
    section.id = id;
    section.left = left;
    section.right = right;
    section.hasChanges = dto.hasChanges;
    this.sections.push(section);
  }

  addSection(id: string, leftKey: string, rightKey: string, title: string, dto: ReportDiffSectionResultDTO): void {
    const section = new ReportDiffSection();
    const left = new ReportDiffSectionContent();
    const right = new ReportDiffSectionContent();
    left.content = dto.contents[leftKey.trim()];
    left.widePk = leftKey;
    right.content = dto.contents[rightKey.trim()];
    right.widePk = rightKey;
    section.title = title;
    section.id = id;
    section.left = left;
    section.right = right;
    section.hasChanges = dto.hasChanges;
    this.sections.push(section);
  }
}

export class ReportDiffSectionResultDTO {
  hasChanges: boolean;
  contents: {};
}

export class ReportDiffResultDto {
  kontrolblad: ReportDiffSectionResultDTO;
  forside: ReportDiffSectionResultDTO;
  arbejdsbeskrivelse: ReportDiffSectionResultDTO;
  reservedele: ReportDiffSectionResultDTO;
  lak: ReportDiffSectionResultDTO;
}


