import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {CookieComponent, errorRoute} from './';
import {SidebarComponent} from './navigation/sidebar/sidebar.component';
import {CommonModule} from '@angular/common';
import {NgbCollapseModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../shared/shared.module';
import {TopbarComponent} from './navigation/topbar/topbar.component';
import {B2CSignupModule} from './b2c-signup-flow/B2CSignup.module';

const LAYOUT_ROUTES = [
  ...errorRoute
];

@NgModule({
  imports: [
    RouterModule.forRoot(LAYOUT_ROUTES, {useHash: true}),
    CommonModule,
    NgbCollapseModule,
    SharedModule,
    B2CSignupModule
  ],
  declarations: [
    SidebarComponent,
    TopbarComponent,
    CookieComponent
  ],
  exports: [
    RouterModule,
    SidebarComponent,
    TopbarComponent,
    CookieComponent
  ]
})
export class LayoutRoutingModule {
}
