import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {HomeComponent} from './';
import {HOME_ROUTES} from 'app/home/home.route';
import {SharedModule} from 'app/shared/shared.module';
import {ChangePasswordModalComponent} from './ui/login/changepassword/change-password-modal.component';
import {SharedCommonModule} from '../shared/shared-common.module';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {B2cActivationComponent} from './b2c-activation/b2c-activation.component';

@NgModule({
  imports: [
    SharedModule,
    BrowserModule,
    FormsModule,
    SharedCommonModule,
    RouterModule.forRoot(HOME_ROUTES, {useHash: true})
  ],
  declarations: [
    HomeComponent,
    ChangePasswordModalComponent,
    B2cActivationComponent
  ],
  exports: [
    ChangePasswordModalComponent
  ],
  providers: [
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class HomeModule {
}
