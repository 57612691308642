import {Component, OnInit} from '@angular/core';
import {ForsiReportKeyShortPipe} from '../../../shared/pipe/forsi-report-key-short.pipe';
import {ForsiReportKeyPipe} from '../../../shared/pipe/forsi-report-key.pipe';
import {CheckReportViewModel} from './check-report.view-model';

@Component({
  templateUrl: './check-report.component.html',
  styleUrls: ['./check-report.scss'],
  providers: [CheckReportViewModel, ForsiReportKeyPipe, ForsiReportKeyShortPipe]
})
export class CheckReportComponent implements OnInit {

  constructor(public viewmodel: CheckReportViewModel) {
  }

  ngOnInit(): void {
  }
}
