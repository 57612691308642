<div class="modal-header pre-size header-style" style="text-align: center">
    <h3 class="text-truncate">
        Konfiguration af udskrift
    </h3>
    <div aria-label="Close" data-dismiss="modal" class="btn-close" type="button" (click)="cancel()"></div>
</div>

<div class="modal-content">
    <div class="modal-body modal-body-style">
        <div class="container-fluid">
            <div class="text-center" *ngIf="isLoading">
                <i class="fa fa-circle-o-notch fa-spin fop-page-spinner"></i>
            </div>
            <div *ngIf="!isLoading" class="no-focus">
                <h4 class="pt-2">Inkluderede sektioner</h4>
                <div class="row">
                    <div class="ms-4">
                        <form>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" name="sektionH3"
                                       [(ngModel)]="printLayout.sektionH3"/>
                                <label class="form-check-label">Kontrolblad</label>
                            </div>

                            <div class="form-check">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="checkbox" name="sektionH1"
                                           [(ngModel)]="printLayout.sektionH1"> Forside
                                </label>
                            </div>

                            <div class="form-check">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="checkbox" name="sektionH2"
                                           [(ngModel)]="printLayout.sektionH2"> Arbejdsbeskrivelse
                                </label>
                            </div>

                            <div class="form-check">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="checkbox" name="sektionH"
                                           [(ngModel)]="printLayout.sektionH"> Reservedele
                                </label>
                            </div>

                            <div class="form-check">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="checkbox" name="sektionH5"
                                           [(ngModel)]="printLayout.sektionH5"> Lak
                                </label>
                            </div>

                            <div class="form-check">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="checkbox" name="sektionH4"
                                           [(ngModel)]="printLayout.sektionH4"> Infoblad
                                </label>
                            </div>

                            <div class="form-check mt-3">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="checkbox" name="gdpr"
                                           [(ngModel)]="isGDPRPrint" (change)="onGdprChanged()">GDPR - version
                                </label>
                            </div>
                            <div class="form-check ps-5">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="checkbox" name="inclRegNr"
                                           [(ngModel)]="inclRegNr" (change)="onInclRegNrChanged()">Udskriv reg.nr.
                                </label>
                            </div>
                        </form>
                    </div>
                </div>

                <div class="pt-5 text-center">
                    <button class="btn btn-primary col-10" (click)="print(false)">
                        <lc-spinner-text [spin]="isPrinting"
                                         text="Udskriv {{isDraft ? 'kladde' : 'rapport'}}"></lc-spinner-text>
                    </button>
                </div>
                <div class="pt-2 text-center" *ngIf="!isTaksatorUserPrintingVkReport">
                    <button class="btn btn-primary col-10" (click)="print(true)">
                        <lc-spinner-text [spin]="isPrinting" text="Udskriv kundens kopi"></lc-spinner-text>
                    </button>
                </div>
                <div class="pt-2 text-center" *ngIf="!isDraft && !isTaksatorUserPrintingVkReport && !isVk">
                    <button class="btn btn-primary col-10" (click)="printSag()">
                        <lc-spinner-text [spin]="isPrinting" text="Print sag"></lc-spinner-text>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

