export class FunctionConfigurationDTO {
  startLoebeNr: number;
  stortaksSelected: boolean;
  autotaksSelected: boolean;
  glastaksSelected: boolean;
  mctaksSelected: boolean;
  camptaksSelected: boolean;
  skavutaksSelected: boolean;
  importId: string;
}
