import {Observable, Subject} from 'rxjs';
import {VideoRequestDTO, VideoRequestState} from './model/video-request-dto.model';
import {filter, map} from 'rxjs/operators';
import {isNullOrUndefined} from '../../../utils/object-utils';
import {Injectable} from '@angular/core';
import {VideoRequestResponseState} from './model/video-request-state.model';

/**
 * Capable of observing video request changes
 */
@Injectable()
export class VideoRequestObserverService {
  private videoRequest$: Subject<VideoRequestDTO> = new Subject<VideoRequestDTO>();

  private static decideVideoRequestResponseState(videoRequest: VideoRequestDTO): VideoRequestResponseState {
    let result: VideoRequestResponseState;
    if (!isNullOrUndefined(videoRequest)) {
      switch (videoRequest.state) {
        case VideoRequestState.OPEN:
          const isUploaded = !isNullOrUndefined(videoRequest.uploadedAt) && videoRequest.uploadedAt > videoRequest.requestedAt;
          result = isUploaded ? VideoRequestResponseState.RESPONDED : VideoRequestResponseState.REQUESTED;
          break;
        case VideoRequestState.CLOSED:
          result = VideoRequestResponseState.NO_REQUEST;
          break;
        default:
          throw new Error(`Unknown VideoRequest state[${videoRequest.state}]`);
      }
    } else {
      result = result = VideoRequestResponseState.NO_REQUEST;
    }
    return result;
  }

  next(videoRequest: VideoRequestDTO): void {
    if (!isNullOrUndefined(videoRequest)) {
      this.videoRequest$.next(videoRequest);
    }
  }

  public reportResponseState$(reportId: number): Observable<VideoRequestResponseState> {
    return this.all$().pipe(
      filter(videoRequest => videoRequest.reportId === reportId),
      map(v => VideoRequestObserverService.decideVideoRequestResponseState(v)));
  }

  public clientStateResponseState$(clientStateId: number): Observable<VideoRequestResponseState> {
    return this.all$().pipe(
      filter(videoRequest => videoRequest.clientStateId === clientStateId),
      map(v => VideoRequestObserverService.decideVideoRequestResponseState(v)));
  }

  public all$(): Observable<VideoRequestDTO> {
    return this.videoRequest$.pipe(
      filter(videoRequest => !isNullOrUndefined(videoRequest))
    );
  }
}
