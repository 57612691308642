import {SkavuDetailsDTO} from '../dto/skavu/skavu-details-dto.model';
import {ClientStateDTO} from '..';
import {SkavuPositionValue} from './skavu-position-value.model';
import {CarDetails} from './flowtwo-car-details.model';
import {ReportCategory} from '../../shared/model/report-category.model';

export class SkavuDetails {
  takseringsart: string;

  //depending of the actual rapporttype, this field will store different data (yikes!)
  //This is bevause the ReportDocumentMap.vogn.type content is different from flow to flow...
  commontype: string;

  vognart: string;
  angivetFabriat: string;
  modelkode: string;
  model: string;
  fabrikat: string;
  positionValues: SkavuPositionValue[];
  unspecifiedSaleryAmount: number;
  unspecifiedLicensplatesAmount: number;
  unspecifiedSparepartsAmount: number;
  ekstraudstyr: string;

  get customValues(): SkavuPositionValue[] {
    return this.positionValues.filter(p => p.nr === '99999');
  }

  static fromDTO(details: ClientStateDTO, dto: SkavuDetailsDTO): SkavuDetails {
    const skavu = new SkavuDetails();
    if (dto) {
      skavu.angivetFabriat = dto.angivetFabriat;
      skavu.modelkode = dto.modelkode;
      skavu.takseringsart = dto.takseringsart;
      skavu.vognart = dto.vognart;

      const currentReportType = ReportCategory.getReportCategory(details.schema);
      if (ReportCategory.SKAVUTAKS.equals(currentReportType)) {
        skavu.commontype = dto.vogntype;
      } else if (currentReportType.isEqualToAny([ReportCategory.CAMPTAKS, ReportCategory.MCTAKS, ReportCategory.STORTAKS])) {
        switch (dto.takseringsart) {
          case 'D':
            skavu.commontype = dto.camptakstype;
            break;
          case 'L':
            skavu.commontype = dto.vogntype;
            break;
          case 'M':
            skavu.commontype = dto.mcTaksType;
            break;
        }
      }

      skavu.model = dto.model;
      skavu.fabrikat = details.fabrikat;
      skavu.positionValues = SkavuPositionValue.fromDTOs(dto.positionValues);
      skavu.unspecifiedLicensplatesAmount = dto.unspecifiedLicensplatesAmount;
      skavu.unspecifiedSaleryAmount = dto.unspecifiedSaleryAmount;
      skavu.unspecifiedSparepartsAmount = dto.unspecifiedSparepartsAmount;
      skavu.ekstraudstyr = dto.ekstraudstyr;
    }
    return skavu;
  }

  public getCarDetails(): CarDetails {
    const result = new CarDetails();
    result.fabrikatKode = this.fabrikat;
    result.fabrikatText = this.fabrikat;
    result.modelKode = this.modelkode;
    result.modelText = this.model;
    return result;
  }

  toDTO(detailsDto: ClientStateDTO): SkavuDetailsDTO {
    const dto = new SkavuDetailsDTO();
    dto.angivetFabriat = this.angivetFabriat;
    dto.modelkode = this.modelkode;
    dto.takseringsart = this.takseringsart;
    dto.vognart = this.vognart;

    const currentReportType = ReportCategory.getReportCategory(detailsDto.schema);
    if (ReportCategory.SKAVUTAKS.equals(currentReportType)) {
      dto.vogntype = this.commontype;
    } else if (currentReportType.isEqualToAny([ReportCategory.CAMPTAKS, ReportCategory.MCTAKS, ReportCategory.STORTAKS])) {
      switch (dto.takseringsart) {
        case 'D':
          dto.camptakstype = this.commontype;
          break;
        case 'L':
          dto.vogntype = this.commontype;
          break;
        case 'M':
          dto.mcTaksType = this.commontype;
          break;
      }
    }

    dto.model = this.model;
    dto.unspecifiedLicensplatesAmount = this.unspecifiedLicensplatesAmount;
    dto.unspecifiedSaleryAmount = this.unspecifiedSaleryAmount;
    dto.unspecifiedSparepartsAmount = this.unspecifiedSparepartsAmount;
    dto.ekstraudstyr = this.ekstraudstyr;
    detailsDto.fabrikat = this.fabrikat;
    detailsDto.skavuDetails = dto;
    if (this.positionValues) {
      dto.positionValues = this.positionValues
        .filter(pv => pv.checked)
        .map(pv => pv.toDTO());
    }
    return dto;
  }

  isUnspecifiedValid(): boolean {
    if (this.unspecifiedSparepartsAmount || this.unspecifiedLicensplatesAmount || this.unspecifiedSaleryAmount) {
      return true;
    }
    return false;
  }

}
