import {TakserFormDTO} from '../../shared/dto/takser-form-dto.model';
import {SkadeKodeDTO} from '../../shared/dto/skade-kode-dto.model';
import {CompanyDTO} from '../../shared/dto/company-dto.model';
import {AssessorOrgDTO} from '../../shared/dto/assessor-org-dto.model';

export class CompanyDetailsDTO extends CompanyDTO {
  skadekoder: SkadeKodeDTO[];
  takserformer: TakserFormDTO[];
  defaultAssessorOrg: AssessorOrgDTO;
}
