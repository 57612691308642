import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ProductRecycleVinLookupModel} from './product-recycle-vin-lookup.model';
import {FabrikatDTO} from '../shared/makes/dto/fabrikat-dto.model';
import {QapterPrepareModeDTO} from '../shared/dto/qapter-prepare-mode-dto.model';
import {ProductRecycleCalculationModel} from './product-recycle-calculation.model';

@Injectable()
export class ProductRecycleService {
  constructor(private http: HttpClient) {
  }

  vinLookup(sessionId: string, token: string, vin: string): Observable<ProductRecycleVinLookupModel> {
    return this.http.get<ProductRecycleVinLookupModel>(`product-recycle/${sessionId}/vin-lookup/${encodeURIComponent(vin)}`, {headers: this.getHeaders(token)});
  }

  getMakes(sessionId: string, token: string): Observable<FabrikatDTO[]> {
    return this.http.get<FabrikatDTO[]>(`product-recycle/${sessionId}/makes`, {headers: this.getHeaders(token)});
  }

  private getHeaders(token: string): HttpHeaders {
    return new HttpHeaders().set('Authorization',  'Bearer ' + token);
  }

  prepareQapter(sessionId: string, token: string, makeCode: string, modelCode: string, submodelCode: string, modelOptions: string): Observable<QapterPrepareModeDTO> {
    const possibleSubModelOptions = submodelCode ? `&submodelClassCode=${submodelCode}` : ''
    const possibleModelOptions = modelOptions ? `&modelOptions=${modelOptions}` : ''
    return this.http.get<QapterPrepareModeDTO>(`product-recycle/${sessionId}/prepare/qapter?makeClassCode=${makeCode}&modelCode=${modelCode}${possibleSubModelOptions}${possibleModelOptions}`, {headers: this.getHeaders(token)});
  }

  calculate(sessionId: string, token: string): Observable<ProductRecycleCalculationModel> {
    return this.http.get<ProductRecycleCalculationModel>(`product-recycle/${sessionId}/calculation`, {headers: this.getHeaders(token)});
  }
}
