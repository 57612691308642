import {Route} from '@angular/router';

import {RequisitionComponent} from './';
import {UserRouteAccessService} from 'app/shared/service/auth/user-route-access.service';
import {AppRole} from '../../service/auth/app-roles.model';

export const REQUISITION_ROUTE: Route = {
  path: 'requisition/search',
  component: RequisitionComponent,
  data: {
    authorities: [AppRole.VK],
    pageTitle: 'Rekvisition'
  },
  canActivate: [UserRouteAccessService]
};
