import {StringUtils} from './string-utils';
import {FixMaxLineLength} from '../model/fix-max-line-length.model';

export class TextAreaUtils {
  /**
   * Will update the HTMLTextAreaElement accordingly, however you should use the return value
   * to update the specific model in your component.ts file
   */
  public static fixMaxLineLength(textArea: HTMLTextAreaElement, maxTextLineLength: number, text: string, textAreaElementId: string): FixMaxLineLength {
    const selectionStart = textArea ? textArea.selectionStart : 0;
    const selectionEnd = textArea ? textArea.selectionEnd : 0;
    const activeEl: HTMLElement = (document.activeElement instanceof HTMLElement) ? document.activeElement : null;
    const isActive = activeEl && activeEl.id === textAreaElementId;

    const fixMaxLineLength = new FixMaxLineLength();
    if (text) {
      const lines = text.split('\n');
      const newLines: string[] = [];
      lines.forEach((line) => {
        StringUtils.splitStringAtIntervalWord(line, maxTextLineLength).forEach((newLine) => {
          newLines.push(newLine);
        });
      });

      const newText = newLines.join('\n');
      fixMaxLineLength.text = newText;
      fixMaxLineLength.linesCount = newLines.length;

      if (text !== newText && textArea) {
        textArea.value = newText;
        if (isActive) {
          textArea.setSelectionRange(selectionStart, selectionEnd);
        }
      }
    } else {
      //Set the text to the same as input in order to prevent a "dirty" formSaver
      fixMaxLineLength.text = text;
    }

    return fixMaxLineLength;
  }

  public static getMessageClass(linesLimit: number, linesCount: number): string {
    return (linesLimit - (linesCount || 0) > -1) ? '' : 'text-danger';
  }

  public static getMessage(linesLimit: number, linesCount: number): string {
    const linesLeft = linesLimit - (linesCount || 0);
    if (linesLeft > -1) {
      return linesLeft + ' linie' + (linesLeft !== 1 ? 'r' : '') + ' tilbage.'
    } else {
      return 'Der er maksimalt plads til ' + linesLimit + ' linier - Slet venligst nogen.'
    }
  }
}
