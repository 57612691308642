import {Injectable} from '@angular/core';
import {PowerbiConf} from './model/powerbi.conf.model';
import {Observable} from 'rxjs';
import {EmbedUrlDTO} from './dto/embed-url.dto.model';
import {HttpClient} from '@angular/common/http';
import {ReportidDTO} from './dto/reportid.dto.model';
import {AccessTokenDTO} from './dto/access-token.dto.model';
import {AutostatRoleDTO} from './dto/autostat-role.dto.model';

@Injectable()
export class StatisticService {

  configuration = new PowerbiConf();

  powerbiUrl = '';

  constructor(private http: HttpClient) {
  }

  public getPowerBiConf(): any {
    return this.configuration.getConf();
  }

  getEmbedUrlForRole(role: string): Observable<EmbedUrlDTO> {
    return this.http.get<EmbedUrlDTO>(this.powerbiUrl + 'embedurl/' + role);
  }

  getReportIDForRole(role: string): Observable<ReportidDTO> {
    return this.http.get<ReportidDTO>(this.powerbiUrl + 'reportid/' + role);
  }

  getAccessTokenForRoleAndUser(role: string, user: string): Observable<AccessTokenDTO> {
    return this.http.get<AccessTokenDTO>(this.powerbiUrl + user + '/accesstoken/' + role);
  }

  getEmbedUrl(): Observable<EmbedUrlDTO> {
    return this.http.get<EmbedUrlDTO>(this.powerbiUrl + 'embedurl');
  }

  getReportID(): Observable<ReportidDTO> {
    return this.http.get<ReportidDTO>(this.powerbiUrl + 'reportid');
  }

  getAccessToken(): Observable<AccessTokenDTO> {
    return this.http.get<AccessTokenDTO>(this.powerbiUrl + 'accesstoken');
  }

  getRoles(): Observable<AutostatRoleDTO[]> {
    return this.http.get<AutostatRoleDTO[]>(this.powerbiUrl + 'roles');
  }

}
