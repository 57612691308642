import {ReportService} from '../service/report.service';
import {LcActionMenu, LcActionMenuItem} from '../../shared/ui/bottombar/lc-action-menu.model';
import {CombinedPrintModel} from '../../shared/ui/combined-print-viewer/combined-print.model';
import {ReportKey} from '../../search/reportkey.model';
import {ContextMenu, ContextMenuItem} from '../../shared/ui/context-menu/context-menu.model';
import {UIUtils} from '../../shared/utils/ui-utils';
import {RapportInfoDTO, ReportDTO} from '..';
import {AttachmentSummaryDTO} from '../../shared/dto/attachment-summary-dto.model';
import {combineLatest, Observable, of} from 'rxjs';
import {AttachmentUseCase} from '../../shared/model/attachment-use-case.model';
import {MessageDTO} from '../../shared/dto/message-dto.model';
import {ReportCategory} from '../../shared/model/report-category.model';
import {StringUtils} from '../../shared/utils/string-utils';
import {PrintSettingsDTO} from '../../shared/dto/print-settings-dto.model';
import {PolicyDTO} from '../../shared/dto/policy-dto.model';
import {catchError, takeUntil} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {isNullOrUndefined} from '../../shared/utils/object-utils';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ReportAcceptStatus} from '../model/report-accept-status';
import {ReportAcceptRequestedPopupComponent} from './report-accept-requested-popup/report-accept-requested-popup.component';
import {UserType} from '../../shared/dto/user-type.model';
import {VideoRequestObserverService} from '../../shared/ui/attachment/video-request/video-request-observer.service';
import {VideoRequestResponseState} from '../../shared/ui/attachment/video-request/model/video-request-state.model';
import {PrincipalService} from '../../shared';
import {UserDTO} from '../../shared/dto/user-dto.model';

class PrintTabModel extends CombinedPrintModel {
  rappNr: string;
  rapportType: string;
  canCopy: boolean;

  static emptyModel(): PrintTabModel {
    return new PrintTabModel(null, null, null, false);
  }

  constructor(rappNr: string, rapportType: string, print: string, canCopy: boolean) {
    super(print);
    this.rappNr = rappNr;
    this.rapportType = rapportType;
    this.canCopy = canCopy;
  }
}

export class ReportViewerViewModel {
  loading = true;
  bottomMenu: LcActionMenu;
  contextMenu: ContextMenu;
  menuIconClass: string;
  deliveryNoteCallBack: Function;
  navigateBackCallback: Function;
  copyCallback: Function;
  diffCallback: Function;
  qapterCallback: Function;
  newReportCallback: Function;
  reportAcceptResponseCallback: Function;
  navigateToCarDetailsCallback: Function;
  hasDiff: boolean;
  vkPrintTab = PrintTabModel.emptyModel();
  taksPrintTab = PrintTabModel.emptyModel();
  activeTab: PrintTabModel;
  report: ReportDTO;
  attachmentSummary: AttachmentSummaryDTO;
  attachmentUseCase = AttachmentUseCase.Report;
  messages: MessageDTO[];
  policy: PolicyDTO;
  qapterReportWidePk: string;
  canDiff = false;
  reportInfo: RapportInfoDTO;
  vkReportPrintSettings: PrintSettingsDTO;
  taksReportPrintSettings: PrintSettingsDTO;
  activePrintSettings: PrintSettingsDTO;
  missingVideoRequest = false;
  workshopResponsible: UserDTO;

  constructor(private reportService: ReportService,
              private videoRequestObserverService: VideoRequestObserverService,
              private modalService: NgbModal,
              private principalService: PrincipalService,
              private isTaksator: boolean,
              private unsubscribe$: Observable<void>) {
    this.setupReportActionMenu();
  }

  public initTabs(report: ReportDTO, vkWidePk: string, taksatorWidePk: string): void {
    this.report = report;
    this.setupObservables(vkWidePk, taksatorWidePk);
    this.setupContextMenu();
    setTimeout(() => {
      this.contextMenu.updateMenuHighlights(null);
    });
  }

  private setupContextMenu(): void {
    this.contextMenu = new ContextMenu();
    const uiUtils = new UIUtils();
    this.contextMenu.addItem(new ContextMenuItem('fa-print', 'Print', 'report-print', (item) => {
      uiUtils.scrollToElementId(['report-print'], false);
    }));
    if (this.isTaksator) {
      this.contextMenu.addItem(new ContextMenuItem('fa-id-card-o', 'Police', 'report-police', (item) => {
        uiUtils.scrollToElementId(['report-police'], false);
      }));
    }
    this.contextMenu.addItem(new ContextMenuItem('fa-image', 'Bilag', 'report-attachments', (item) => {
      uiUtils.scrollToElementId(['report-attachments'], false);
    }, () => this.missingVideoRequest));
    this.contextMenu.addItem(new ContextMenuItem('fa-envelope-o', 'Beskeder', 'report-messages', (item) => {
      uiUtils.scrollToElementId(['report-messages'], false);
    }));
    this.contextMenu.addItem(new ContextMenuItem('fa-archive', 'Diverse', 'misc', () => {
      uiUtils.scrollToElementId(['misc']);
    }));
  }

  private setupReportActionMenu(): void {
    this.bottomMenu = new LcActionMenu();
    this.bottomMenu.addItem(new LcActionMenuItem('fa-arrow-left', () => {
      if (!isNullOrUndefined(this.navigateBackCallback)) {
        this.navigateBackCallback();
      }
    }, 'Tilbage'));

    if (this.isTaksator) {
      const deliveryNoteMenuItem = new LcActionMenuItem('fa fa-tag', () => {
        if (!isNullOrUndefined(this.deliveryNoteCallBack)) {
          this.deliveryNoteCallBack();
        }
      }, 'Følgeseddel');
      deliveryNoteMenuItem.hideCallback = () => !this.taksPrintTab.hasData;
      deliveryNoteMenuItem.disabledCallback = () => this.loading || !this.taksPrintTab.hasData;
      this.bottomMenu.addItem(deliveryNoteMenuItem);
    }

    const copyMenuItem = new LcActionMenuItem('fa-copy', () => {
        if (!isNullOrUndefined(this.copyCallback)) {
          this.copyCallback();
        }
      }, 'Kopier',
      () => this.loading || !this.activeTab.canCopy,
      () => this.loading ? '' : !this.activeTab.canCopy ? 'Denne rapporttype kan ikke kopieres' : ''
    );
    this.bottomMenu.addItem(copyMenuItem);

    if (this.isTaksator) {
      const newReportMenuItem = new LcActionMenuItem('fa-plus', () => {
        if (!isNullOrUndefined(this.newReportCallback)) {
          this.newReportCallback();
        }
      }, 'Ny Rapporttype');
      newReportMenuItem.hideCallback = () => !this.taksPrintTab.hasData;
      newReportMenuItem.disableMenuItem = () => this.loading;
      this.bottomMenu.addItem(newReportMenuItem);
    }

    const carDetailsMenuItem = new LcActionMenuItem('fa-cloud-download', () => {
      if (!isNullOrUndefined(this.navigateToCarDetailsCallback)) {
        this.navigateToCarDetailsCallback();
      }
    }, 'Bil detaljer');
    this.bottomMenu.addItem(carDetailsMenuItem);

    const diffMenuItem = new LcActionMenuItem('fa-exchange', () => {
      if (!isNullOrUndefined(this.diffCallback)) {
        this.diffCallback();
      }
    }, 'Sammenlign');
    diffMenuItem.disableMenuItem = () => this.loading || !this.hasDiff || !this.canDiff;
    diffMenuItem.hideCallback = () => !this.taksPrintTab.hasData;
    this.bottomMenu.addItem(diffMenuItem);

    const qapterMenuItem = new LcActionMenuItem('lc-qapter', () => {
      if (!isNullOrUndefined(this.qapterCallback)) {
        this.qapterCallback(qapterMenuItem, this.activeTab.rappNr);
      }
    }, 'apter');
    qapterMenuItem.setImageUrl('content/images/qapter-logo.png');
    qapterMenuItem.showMenuItem = () => !this.loading && this.showQapterButton();
    this.bottomMenu.addItem(qapterMenuItem);

    const reportAcceptMenuItem = new LcActionMenuItem('fa-question-circle-o', () => {
      if (!isNullOrUndefined(this.reportAcceptResponseCallback)) {
        this.reportAcceptResponseCallback();
      }
    }, 'Svar taksator');
    reportAcceptMenuItem.disableMenuItem = () => this.loading;
    reportAcceptMenuItem.hideCallback = () => {
      const reportAcceptRequested = this.reportInfo && this.reportInfo.reportAccept === ReportAcceptStatus.REQUESTED;
      return this.isTaksator || !reportAcceptRequested;
    };
    reportAcceptMenuItem.buttonClass = 'btn-warning';
    this.bottomMenu.addItem(reportAcceptMenuItem);
  }

  showQapterButton(): boolean {
    return this.activeTab && this.activeTab.hasData && ReportCategory.usesQapter(this.activeTab.rapportType);
  }

  private setupObservables(vkWidePk: string, taksatorWidePk: string): void {
    this.loading = true;
    const reportInfoObs = this.reportService.getReportInfo(vkWidePk, taksatorWidePk);
    const attachmentSummaryObs = this.reportService.getAttachmentSummary(this.getAttachmentReportKey());
    const messagesObs = this.reportService.getMessages(this.report.id);
    const workshopResponsibleObs = this.reportService.getWorkshopResponsible(this.report.id);
    const existingPoliceForReport = this.isTaksator ? this.reportService.getPolicy(this.report.id)
      .pipe(catchError(error => {
          // ignore error if no policy was found
          if (error instanceof HttpErrorResponse && error.status !== 404) {
            throw error;
          }
          return of(null);
        }),
      ).pipe(catchError(e => of(null))) : of(null);
    const vkReportPrintSettings = StringUtils.isNotEmpty(vkWidePk) ? this.reportService.getPrintSettings(vkWidePk) : of(null);
    const taksReportPrintSettings = StringUtils.isNotEmpty(taksatorWidePk) ? this.reportService.getPrintSettings(taksatorWidePk) : of(null);

    combineLatest([reportInfoObs, attachmentSummaryObs, messagesObs, existingPoliceForReport, vkReportPrintSettings, taksReportPrintSettings,workshopResponsibleObs]).pipe(
     takeUntil(this.unsubscribe$)
    ).subscribe(([reportInfoObs, attachmentSummaryObs, messagesObs, existingPoliceForReport, vkReportPrintSettings, taksReportPrintSettings, workshopResponsibleObs]) => {
        this.reportInfo = reportInfoObs;
        this.attachmentSummary = attachmentSummaryObs;
        this.messages = messagesObs;
        this.workshopResponsible = workshopResponsibleObs;

        if (existingPoliceForReport != null) {
          this.policy = existingPoliceForReport;
        }

        if (vkReportPrintSettings != null) {
          this.vkReportPrintSettings = vkReportPrintSettings;
          this.activePrintSettings = vkReportPrintSettings;
        }

        if (taksReportPrintSettings != null) {
          this.taksReportPrintSettings = taksReportPrintSettings;
          if (this.activePrintSettings == null) {
            this.activePrintSettings = taksReportPrintSettings;
          }
        }

        if (this.reportInfo.taksatorRapportPrint) {
          const reportType = ReportKey.fromReportWidePk(taksatorWidePk).reportType;
          this.canDiff = ReportCategory.canDiff(reportType);
          this.taksPrintTab = new PrintTabModel(taksatorWidePk, this.reportInfo.taksRappType, this.reportInfo.taksatorRapportPrint, this.reportInfo.taksRappCanCopy);
        }

        if (this.reportInfo.vkRapportPrint) {
          this.vkPrintTab = new PrintTabModel(vkWidePk, this.reportInfo.vkRappType, this.reportInfo.vkRapportPrint, this.reportInfo.vkRappCanCopy);
          this.hasDiff = this.taksPrintTab.hasData && this.reportInfo.vkBeloebInOere !== this.reportInfo.taksBeloebInOere;
        }

        //present popup regarding status on report acceptance
        const reportAccept = this.reportInfo.reportAccept;
        if (reportAccept && reportAccept !== ReportAcceptStatus.ACCEPTED) {
          const modalRef = this.modalService.open(ReportAcceptRequestedPopupComponent);
          const componentInstance = modalRef.componentInstance as ReportAcceptRequestedPopupComponent;
          componentInstance.userType = this.isTaksator ? UserType.TAKS : UserType.VK;
          componentInstance.acceptStatus = reportAccept;
        }
      }, error => {
        console.log(error);
      }, () => {
        this.loading = false;
      });

    this.videoRequestObserverService.reportResponseState$(this.report.id).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(responseState => {
      this.missingVideoRequest = responseState === VideoRequestResponseState.REQUESTED;
    });
  }

  selectPrint(printModel: CombinedPrintModel): void {
    const printTabModel = this.toPrintTab(printModel);
    this.activeTab = printTabModel;
    this.qapterReportWidePk = printTabModel.rappNr;
  }

  toPrintTab(printModel: CombinedPrintModel): PrintTabModel {
    if (printModel === this.vkPrintTab) {
      this.activePrintSettings = this.vkReportPrintSettings;
      return this.vkPrintTab;
    } else {
      if (printModel === this.taksPrintTab) {
        this.activePrintSettings = this.taksReportPrintSettings;
        return this.taksPrintTab;
      }
    }

    return null;
  }

  getReportKey(): string {
    return isNullOrUndefined(this.report) ? '' : this.report.key;
  }

  loadMessages(): void {
    this.reportService.getMessages(this.report.id).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(messages => {
      this.messages = messages;
    });
  }

  getAttachmentReportKey(): string {
    let reportKey = this.report.key;
    if (this.principalService.isVK() && StringUtils.isNotEmpty(this.report.vkReportKey)) {
      reportKey = this.report.vkReportKey;
    }
    return reportKey;
  }
}
