import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MsalBroadcastService, MsalService} from '@azure/msal-angular';
import {InteractionStatus, RedirectRequest} from '@azure/msal-browser';
import {OAuthAuthenticationService} from '../../shared/o-auth/forsi/o-auth-authentication-service';
import {B2CConfigurationService} from '../../shared/service/b2c-configuration.service';

@Component({
  templateUrl: './b2c-activation.component.html',
  styleUrls: ['../home.scss']
})
export class B2cActivationComponent implements OnInit, OnDestroy {
  private readonly b2cResetInFlowKey = 'b2cResetInFlow';
  private unsubscribe$ = new Subject<void>();
  private b2cResetInFlow = false;

  constructor(
    private oAuthAuthenticationService: OAuthAuthenticationService,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private b2CConfigurationService: B2CConfigurationService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    const sessionResetInFlow = sessionStorage.getItem(this.b2cResetInFlowKey);
    this.b2cResetInFlow = (!!sessionResetInFlow && sessionResetInFlow === 'true');
    this.msalBroadcastService.inProgress$
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe((status) => {
        console.log('msalBroadcastService.inProgress$ status: ' + status + ', b2cResetInFlow: ' + this.b2cResetInFlow);
        if (this.b2cResetInFlow && status === InteractionStatus.None) {
          this.setB2CResetInFlow(false)
          this.router.navigate(['']);
        }
      })

    const email = this.route.snapshot.queryParams.email;
    const activationId = this.route.snapshot.queryParams.activationId;
    this.oAuthAuthenticationService.activateB2C(email, activationId).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(activated => {
      if (activated) {
        setTimeout(() => {
          this.setB2CResetInFlow(true);
          const resetPasswordFlowRequest: RedirectRequest = {
            authority: this.b2CConfigurationService.flowAuthorities.resetPassword,
            scopes: this.b2CConfigurationService.flowAuthorities.scopes,
            loginHint: email
          };
          this.authService.loginRedirect(resetPasswordFlowRequest);
        }, 2000)
      }
    });
  }

  private setB2CResetInFlow(inFlow: boolean) {
    if (inFlow) {
      sessionStorage.setItem(this.b2cResetInFlowKey, 'true');
    } else {
      sessionStorage.removeItem(this.b2cResetInFlowKey);
    }
    this.b2cResetInFlow = inFlow;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
