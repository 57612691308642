import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, Subject, throwError} from 'rxjs';
import {DraftService} from '../service/draft.service';
import {ClientStateDetails} from '../model/client-state-details.model';
import {CompanyDetailsDTO} from '../dto/company-details-dto.model';
import {FabrikatDTO} from '../../shared/makes/dto/fabrikat-dto.model';
import {catchError, map, mergeMap, shareReplay, tap} from 'rxjs/operators';
import {ClientStateWarningDTO} from '../dto/client-state-warning-dto.model';
import {SkadeKodeDTO} from '../../shared/dto/skade-kode-dto.model';
import {TakserFormDTO} from '../../shared/dto/takser-form-dto.model';
import {DeliveryNoteDataProvider} from '../../shared/service/delivery-note-data-provider';
import {CreateDeliveryNoteDTO} from '../../shared/ui/delivery-note/model/create-delivery-note-dto.model';
import {DeliveryNoteDTO} from '../../shared/ui/delivery-note/model/delivery-note-dto.model';
import {DeliveryNoteTemplateDataDTO} from '../../shared/ui/delivery-note/model/delivery-note-template-data-dto.model';
import {DeliveryNoteReceiverType} from '../../shared/ui/delivery-note/model/delivery-note-receiver-type.enum';
import {
  DeliveryNoteReceiverTemplateDataDTO
} from '../../shared/ui/delivery-note/model/delivery-note-receiver-template-data-dto.model';
import {RapportType} from '../../shared/model/rapport-type.model';
import {AttachDeliveryNoteDTO} from '../../shared/ui/delivery-note/model/attach-delivery-note-dto.model';
import {PrintDeliveryNoteDTO} from '../../shared/ui/delivery-note/model/print-delivery-note-dto.model';
import {Router} from '@angular/router';
import {AttachmentSummaryDTO} from '../../shared/dto/attachment-summary-dto.model';
import {BreadcrumbService} from '../../shared/ui/breadcrumb/breadcrumb.service';
import {isNullOrUndefined} from '../../shared/utils/object-utils';
import {RepairType} from '../../shared/model/repair-type.model';
import {ReportCategory} from '../../shared/model/report-category.model';
import {MakeService} from '../../shared/makes/service/make.service';

@Injectable()
export class DraftEditService implements DeliveryNoteDataProvider {
  public attachmentSummary: AttachmentSummaryDTO;
  private token: string;
  public scrollPosition = 0;
  private _clientState: Observable<ClientStateDetails>;
  private _companyDetails: Observable<CompanyDetailsDTO[]>;
  private _brands: Observable<FabrikatDTO[]>;
  warning: BehaviorSubject<ClientStateWarningDTO> = new BehaviorSubject<ClientStateWarningDTO | null>(null);
  saving: boolean;
  /**
   * Emits the saved client state details every time the form finishes saving.
   * This is useful if you need to do something AFTER the save completes instead of on property change.
   * The emitted value will NOT be the same as the client state details mapped to the views (passed down through components using @Input)
   * ONLY use this if you need to update a component based on data that MUST be persisted in the backend!
   */
  clientStateSaved: Subject<ClientStateDetails> = new Subject<ClientStateDetails>();
  private navigateToUponApproval = 'draft';

  constructor(private draftService: DraftService,
              private makeService: MakeService,
              private breadcrumbService: BreadcrumbService,
              protected router: Router) {
    const extras = router.getCurrentNavigation().extras;
    this.attachmentSummary = extras && extras.state ? extras.state.attachmentSummary : null;
  }

  public getToken(): string {
    return this.token;
  }

  public refreshClientStateWarning(): void {
    this.draftService.getClientStateWarning(this.token).subscribe(
      warning => {
        this.warning.next(warning);
      });
  }

  public getCompanyDetailsObservable(): Observable<CompanyDetailsDTO[]> {
    if (!this._companyDetails) {
      this._companyDetails = this.draftService.getCompanyDetails(this.token).pipe(
        map(items => items.sort((a, b) => a.name < b.name ? -1 : 1)),
        map(items => items.map(item => {
          item.skadekoder = item.skadekoder.map(
            skadekode => Object.assign(new SkadeKodeDTO(), skadekode)
          );
          item.takserformer = item.takserformer.map(t => Object.assign(new TakserFormDTO(), t));
          return item;
        })),
        shareReplay(1)
      );
    }
    return this._companyDetails;
  }

  public getBrands$(repairType?: RepairType): Observable<FabrikatDTO[]> {
    if (!this._brands) {
      this._brands = this.makeService.findAllBrands(repairType).pipe(
        shareReplay(1)
      );
    }
    return this._brands;
  }

  public getBrand(fabrikatKode: string, repairType?: RepairType): Observable<FabrikatDTO> {
    return this.getBrands$(repairType).pipe(
      map(brands => brands.find(b => b.kode.trim() === fabrikatKode.trim()))
    )
  }

  public save(): Observable<ClientStateDetails> {
    if (this.saving) {
      throw new Error('Saving the draft is already in progress');
    }
    
    this.saving = true;
    if (this._clientState) {
      return this._clientState.pipe(
        mergeMap(cs => this.draftService.saveAndMap(cs).pipe(
            tap(() => {
              this.saving = false;
            }),
            catchError((err) => {
              this.saving = false;
              throw err;
            })
          )));
    } else {
      return throwError(() => 'No clientstate present to save');
    }
  }

  public getClientStateObservable(token: string): Observable<ClientStateDetails> {
    if (this.token !== token || !this._clientState) {
      this.clearClientState();
      this.token = token;
      this._clientState = this.draftService.getClientStateObs(token).pipe(
        tap(cs => {
          this.breadcrumbService.setClientStateReportKey(cs);
        }),
        mergeMap(cs => {
          if (isNullOrUndefined(cs.vehicle) || isNullOrUndefined(cs.vehicle.fabrikat) || isNullOrUndefined(cs.vehicle.fabrikat.kode)) {
            return of(cs);
          } else {
            const possibleSpecificRepairType = ReportCategory.autotaksRepairType(cs.schema);
            return this.getBrand(cs.vehicle.fabrikat.kode, possibleSpecificRepairType).pipe(
              map(fabrikat => {
                if (!isNullOrUndefined(fabrikat)) {
                  cs.vehicle.fabrikat = fabrikat;
                  const model = cs.vehicle.model ? cs.vehicle.fabrikat.modeller.find(m => m.kode === cs.vehicle.model.kode) : null;
                  if (!isNullOrUndefined(model)) {
                    cs.vehicle.model = model;
                  }
                }
                return cs;
              })
            );
          }
        }),
        shareReplay(1)
      );
    }
    return this._clientState;
  }

  public getRefreshedClientStateObservable(token: string): Observable<ClientStateDetails> {
    return this.draftService.refresh(token).pipe(
      tap(() => this.clearClientState()),
      mergeMap((t) => this.getClientStateObservable(token))
    );
  }

  private clearClientState(): void {
    this.token = null;
    if (this._clientState) {
      //send complete to all possible listeners
      this._clientState = null;
    }
  }

  public getDeliveryNoteTemplateData(token: any): Observable<DeliveryNoteTemplateDataDTO> {
    return this.draftService.getDeliveryNoteTemplateData(token);
  }

  public getDeliveryNoteTemplateDataByReceiverType(token: any, type: DeliveryNoteReceiverType): Observable<DeliveryNoteReceiverTemplateDataDTO> {
    return this.draftService.getDeliveryNoteTemplateDataByReceiverType(token, type);
  }

  public generateDeliveryNote(token: any, createDeliveryNote: CreateDeliveryNoteDTO): Observable<DeliveryNoteDTO> {
    return this.draftService.generateDeliveryNote(token, createDeliveryNote);
  }

  getReportType(identifier: any): Observable<RapportType> {
    return this._clientState.pipe(map(cs => RapportType.extractFrom(cs.taksRapportKey)))
  }

  attachDeliveryNote(token: any, print: DeliveryNoteDTO): Observable<AttachDeliveryNoteDTO> {
    // Invalidate the attachmentSummary that is cached on the route /draft/edit by DraftEditWrapperComponent
    this.attachmentSummary = null;
    return this.draftService.attachDeliveryNote(token, print);
  }

  printDeliveryNote(token: any, print: DeliveryNoteDTO): Observable<PrintDeliveryNoteDTO> {
    return this.draftService.printDeliveryNote(token, print);
  }

  getDeliveryNotePrintUrl(token: any, printId: number, fileName: string): string {
    return 'clientstate/' + token + '/deliverynote/print/' + printId + '/' + fileName;
  }

  getNavigateToUponApproval(): string {
    return this.navigateToUponApproval;
  }

  setNavigateToUponApproval(value: string): void {
    this.navigateToUponApproval = value;
  }
}
