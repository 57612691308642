import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({name: 'unsafeHtml'})
export class UnsafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer){}

  transform(html, unsafe?) {
    return unsafe ? this.sanitizer.bypassSecurityTrustHtml(html) : html;
  }
}
