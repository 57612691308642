<fieldset ngForm="f" #f="ngForm">
    <h2>Skadelidte</h2>
    <div class="card">
        <div class="card-body">
            <div class="row pb-3">
                <div class="col-form-label col-4">
                    <label for="skadelidteregnr">Reg. nr</label>
                </div>
                <div class="col">
                    <input [(ngModel)]="details.skadelidte.regNr" class="form-control text-uppercase"
                           type="text"
                           id="skadelidteregnr" name="regnr"
                           minlength=1 maxlength=10 size="10"
                           lcInputMask="regNr"
                           [readonly]="true"/></div>
            </div>
            <div class="row pb-3">
                <div class="col-form-label col-4">
                    <label for="skadelidtenavn">Navn</label>
                </div>
                <div class="col">
                    <input class="form-control text-uppercase" [(ngModel)]="details.skadelidte.navn"
                           type="text"
                           id="skadelidtenavn" name="navn"
                           minlength=1 maxlength="35" [readonly]="!details.ansvarsskade"
                           [required]="details.ansvarsskade"/></div>
            </div>
            <div class="row pb-3">
                <div class="col-form-label col-4">
                    <label for="skadelidteadresse1">Adresse</label>
                </div>
                <div class="col">
                    <input class="form-control text-uppercase"
                           [(ngModel)]="details.skadelidte.addressLine1"
                           type="text" id="skadelidteadresse1" name="adresse1"
                           minlength=1 maxlength="35" [readonly]="!details.ansvarsskade"
                    /></div>
            </div>
            <div class="row pb-3">
                <div class="col-form-label col-4">&nbsp;</div>
                <div class="col">
                    <div class="col">
                        <input class="form-control text-uppercase"
                               [(ngModel)]="details.skadelidte.addressLine2"
                               type="text" id="skadelidteadresse2" name="adresse2"
                               minlength=1 maxlength="35" [readonly]="!details.ansvarsskade"
                        /></div>
                </div>
            </div>
            <div class="row pb-3">
                <div class="col-form-label col-4">
                    <label for="skadelidtepostnr">Postnr</label>
                </div>
                <div class="col">
                    <input class="form-control" [(ngModel)]="details.skadelidte.postNr"
                           type="tel"
                           id="skadelidtepostnr" name="postnr"
                           minlength=1 size="4" maxlength="4" minlength="3" lcInputMask="int4"
                           (change)="postNrChanged()"
                           [readonly]="!details.ansvarsskade"/></div>
            </div>
            <div class="row pb-3">
                <div class="col-form-label col-4">
                    <label for="skadelidteby">By</label>
                </div>
                <div class="col">
                    <input class="form-control" [(ngModel)]="by" [readonly]="true" type="text" id="skadelidteby"
                           name="by"
                           minlength=1/></div>
            </div>
            <div class="row pb-3">
                <div class="col-form-label col-4">
                    <label for="skadelidtetelefon">Telefon</label>
                </div>
                <div class="col">
                    <input class="form-control"
                           [(ngModel)]="details.skadelidte.telefonNr" type=tel
                           id="skadelidtetelefon" name="telefon"
                           size="8" maxlength="8" minlength="6" lcInputMask="int8" [readonly]="!details.ansvarsskade"
                    /></div>
            </div>
        </div>
    </div>
</fieldset>
