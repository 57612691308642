import {Injectable, OnDestroy} from '@angular/core';
import {NgForm} from '@angular/forms';
import {StringUtils} from '../../utils/string-utils';
import {Observable} from 'rxjs';
import {isNullOrUndefined} from '../../utils/object-utils';

export interface SaveFormListener {
  saveForm(): Observable<string>;

  getData(): string;
}

@Injectable({
  providedIn: 'root',
})
export class FormSaver implements OnDestroy {

  private DELAY_BETWEEN_SAVES = 5000;
  private interval;
  public busy = true;
  private formDataAtLastFailedSave: string;
  private data: string;
  private saveFormListener: SaveFormListener;
  private formsByKey: { [key: string]: NgForm } = {};

  private initialized = false;

  public init(saveFormListener: SaveFormListener): void {
    this.saveFormListener = saveFormListener;
    this.interval = setInterval(this.tick.bind(this), this.DELAY_BETWEEN_SAVES);
    this.data = this.saveFormListener.getData();
    this.busy = false;
    this.formDataAtLastFailedSave = null;
    this.initialized = true;
  }

  public registerForm(formIdentifier: string, theForm: NgForm): void {
    this.formsByKey[formIdentifier] = theForm;
  }

  public isFormsValid(formIdentifiers: string[]): boolean {
    for (const id of formIdentifiers) {
      const result = this.isFormValid(id);
      if (!result) {
        return result;
      }
    }
    return true;
  }

  public isFormValid(formIdentifier: string): boolean {
    const ngForm = this.formsByKey[formIdentifier];
    if (isNullOrUndefined(ngForm)) {
      throw new Error('Asking for validity of unregistered form \'' + formIdentifier + '\'');
    }
    return ngForm.valid;
  }

  /**
   * True if the form as data that failed save, AND that data is not changed since last try.
   *
   * @returns {boolean}
   */
  public isInError(): boolean {
    const formData = this.saveFormListener.getData();
    return StringUtils.isNotEmpty(this.formDataAtLastFailedSave) &&
      (formData.localeCompare(this.formDataAtLastFailedSave) === 0);
  }

  public isDirty(): boolean {
    if (!this.initialized) {
      return false;
    }
    return (this.saveFormListener.getData().localeCompare(this.data) !== 0);
  }

  public isValid(): boolean {
    if (!this.initialized) {
      return false;
    }

    const keys = Object.keys(this.formsByKey);
    for (const key of keys) {
      const childForm = this.formsByKey[key];
      if (!childForm.valid) {
        return false;
      }
    }
    return true;
  }

  ngOnDestroy(): void {
    if (!this.initialized) {
      return;
    }

    this.busy = true;
    clearInterval(this.interval);
    if (this.isDirty()) {
      this.busy = false;
      this.save((wasBusy: boolean, didSave: boolean) => {
        //succeeding silently
      }, () => {
        //failing silently
      });
    }
  }

  public setData(dataToSave: string): void {
    this.data = dataToSave;
  }

  public setFailed(dataThatFailedToSave: string): void {
    this.formDataAtLastFailedSave = dataThatFailedToSave;
  }

  public resetFailed(): void {
    this.formDataAtLastFailedSave = null;
  }

  public pause(): void {
    this.busy = true;
  }

  public unpause(): void {
    this.busy = false;
  }

  private tick(): void {
    if (!this.initialized || this.isInError()) {
      return;
    }
    this.save((wasBusy: boolean, didSave: boolean) => {
      //succeeding silently
    }, () => {
      //failing silently
    });
  }

  public save(succeededCallback: (wasBusy: boolean, didSave: boolean) => void, errorCallback: () => void): void {
    if (!this.initialized) {
      errorCallback();
      throw new Error('Calling save on an uninitialized formsaver! That is a no go!');
    }
    if (this.busy) {
      succeededCallback(this.busy, false);
      return;
    }
    if (this.isDirty()) {
      this.resetFailed();
      this.busy = true;
      const dataToSave = this.saveFormListener.getData();
      this.saveFormListener.saveForm().subscribe(() => {
        this.setData(dataToSave);
        this.busy = false;
        succeededCallback(this.busy, true);
      }, () => {
        this.setFailed(dataToSave);
        this.busy = false;
        errorCallback();
      })
    } else {
      this.busy = false;
      succeededCallback(this.busy, false);
    }
  }
}
