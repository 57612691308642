<lc-bottombar [menu]="viewmodel.draftMenu" [iconClass]="viewmodel.menuIconClass"
              [title]="viewmodel.menuBottomTitle"
              [shortTitle]="viewmodel.menuBottomShortTitle"></lc-bottombar>
<div class="text-center" *ngIf="viewmodel.loading">
    <i class="fa fa-circle-o-notch fa-spin fop-page-spinner"></i>
</div>

<div class="row viewer-top-bar" id="print-header">
    <div class="col-md-12" *ngIf="!viewmodel.loading">
        <ng-container *ngIf="this.printSettings.allowPrinting">
            <button class="pull-right icon-btn mt-2 clickable" (click)="showPrintPopup()">
                <i class="fa fa-print fa-2x"></i>
            </button>
        </ng-container>
        <ng-container *ngIf="!this.printSettings.allowPrinting">
            <button class="pull-right icon-btn mt-2" [title]="'Du kan ikke printe denne kladde'" disabled>
                <i class="fa fa-print fa-2x"></i>
            </button>
        </ng-container>
        <nav class="nav nav-tabs lc-tabs">
            <button [disabled]="!viewmodel.vkPrintTap.hasData" (click)="didClickVkTap()"
                    [ngClass]="{'active' : viewmodel.vkPrintTap.active}" class="text-sm-center nav-link clickable">
                Værkstedsrapport
            </button>
            <button [disabled]="!viewmodel.taksPrintTap.hasData" (click)="didClickTaksTap()"
                    [ngClass]="{'active' : viewmodel.taksPrintTap.active}"
                    class="text-sm-center nav-link clickable">Taksatorrapport
            </button>
        </nav>
    </div>
</div>
<br/><br/><br/>
<div *ngIf="!viewmodel.loading">
    <div class="row" *ngIf="viewmodel.vkPrintTap.active">
        <div class="col-md-12 pt-3 pb-3">
            <lc-print-viewer [print]="viewmodel.vkPrintTap.print"></lc-print-viewer>
        </div>
    </div>
    <div class="row" *ngIf="viewmodel.taksPrintTap.active">
        <div class="col-md-12 pt-3 pb-3">
            <lc-print-viewer [print]="viewmodel.taksPrintTap.print"></lc-print-viewer>
        </div>
    </div>
    <div class="row dynamic-padding-bottom">
        <!-- Room for floating action buttons -->
        <div class="col-md-12"></div>
    </div>
</div>
