import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ClientStateDetails} from '../../../model/client-state-details.model';
import {Subject} from 'rxjs';
import {FabrikatDTO} from '../../../../shared/makes/dto/fabrikat-dto.model';
import {FormSaver} from '../../../../shared/service/core/form-saver.service';
import {NgForm} from '@angular/forms';
import {Validatable} from '../validatable.interface';
import {ModelDTO} from '../../../../shared/makes/dto/model-dto.model';

@Component({
  selector: 'lc-draft-damages',
  templateUrl: './draft-damages.component.html',
  styleUrls: ['../../draft-common.scss']
})
export class DraftDamagesComponent implements Validatable, OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  qapterActivated: boolean;

  @Input() formId: string;
  @Input() details: ClientStateDetails;
  @Input() fantomBrands: FabrikatDTO[];
  @ViewChild('f', {static: true}) public cform: NgForm;

  get fantomModels(): ModelDTO[] {
    if (this.details.vehicle.fantomFabrikat) {
      return this.details.vehicle.fantomFabrikat.fantomModels;
    }
    return [];
  }

  constructor(public formSaver: FormSaver) {
  }

  ngOnInit(): void {
    this.formSaver.registerForm(this.formId, this.cform);
  }

  showFantomSelection(): boolean {
    return !this.details.vehicle.isModelQapterWildcard() && (this.details.vehicle.isGeneralFabrikat() || this.details.vehicle.isGeneralModel());
  }

  qapterButtonClicked(url: string): void {
    this.qapterActivated = true;
    window.location.href = url;
  }

  isValid(): boolean {
    if (!this.details) {
      return false;
    } else {
      return this.cform.form.valid && this.details.onepadDataValid;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  phantomVehicleMakeChanged(): void {
    this.details.vehicle.fantomModel = null;
  }
}
