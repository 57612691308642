/**
 * Remember to update the UserType Pipe with a a human readable description of the type
 */
export enum UserType {
  ADMINONLY= 'ADMINONLY',
  AFTALEADMINONLY = 'AFTALEADMINONLY',
  SAGSB = 'SAGSB',
  STAT = 'STAT',
  TAKS = 'TAKS',
  VK = 'VK',
  KONFIGURATOR = 'KONFIGURATOR',
  INTEGRATION = 'INTEGRATION'
}
