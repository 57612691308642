import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CustomaftaleDTO} from '../dto/customaftale-dto.model';
import {HttpClient} from '@angular/common/http';
import {Customaftale} from '../model/customaftale.model';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomaftaleService {
  private url = 'customaftale';

  constructor(private http: HttpClient) {
  }

  getAftaler(vknr: number): Observable<CustomaftaleDTO[]> {
    return this.http.get<CustomaftaleDTO[]>(this.url + '/' + vknr)
      .pipe(map(aftaleList => aftaleList.map(aftaleDto => new Customaftale(aftaleDto))));

  }
}
