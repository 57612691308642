import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {TaksorgDTO} from '../dto/taksorg-dto.model';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {DateUtils} from '../utils/date-utils';
import {CompanyDTO} from '../dto/company-dto.model';

@Injectable({
  providedIn: 'root',
})
export class SelskabService {
  private rootUrl = 'selskab';

  constructor(private http: HttpClient) {
  }

  getTaksOrgs(): Observable<TaksorgDTO[]> {
    return this.http.get<TaksorgDTO[]>(this.rootUrl + '/taksorgs').pipe(map(response => response.map(taksOrgJson => Object.assign(new TaksorgDTO(), taksOrgJson))
    ));
  }
  
  getSuggestedInsuranceCompany(vinOrRegNr: string, date: NgbDateStruct): Observable<CompanyDTO> {
    let params = new HttpParams();
    params = params.append('vinOrRegNr', vinOrRegNr)
    params = params.append('atTime', DateUtils.ngbDateStructToISO8601Date(date))
    return this.http.get<CompanyDTO>(`${this.rootUrl}/suggested-insurance-company`, {params: params});
  }
}
