import {Directive, forwardRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';
import {isNullOrUndefined} from '../utils/object-utils';

@Directive({
  selector: '[lcPosDropdownValidate][formControlName],[lcPosDropdownValidate][formControl],[lcPosDropdownValidate][ngModel]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => PositionsDropdownValidatorDirective), multi: true}
  ]
})

export class PositionsDropdownValidatorDirective implements Validator, OnChanges {

  @Input() lcPosDropdownValidate: boolean;

  private _onChange: () => void;

  static appendErrors(current: ValidationErrors, toAppend: ValidationErrors): ValidationErrors {
    let result;
    if (isNullOrUndefined(toAppend)) {
      result = current;
    } else {
      if (isNullOrUndefined(current)) {
        result = toAppend;
      } else {
        result = Object.assign({}, current, toAppend)
      }
    }
    return result;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('lcPosDropdownValidate' in changes) {
      if (this._onChange) {
        this._onChange();
      }
    }
  }

  registerOnValidatorChange(fn: () => void): void {
    this._onChange = fn;
  }

  validate(control: AbstractControl): ValidationErrors {
    let res = null;
    if (!this.lcPosDropdownValidate) {
      return res = PositionsDropdownValidatorDirective.appendErrors(res, {LcPositionsDropDown: 'No positions have been chosen'});
    }
    return res;
  }
}
