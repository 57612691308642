import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ClientStateDetails} from '../../../..';
import {FormSaver} from '../../../../../shared/service/core/form-saver.service';
import {NgForm} from '@angular/forms';
import {OpslagService} from '../../../../../shared/service/opslag.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {isNullOrUndefined} from '../../../../../shared/utils/object-utils';

@Component({
  selector: 'lc-adhoc-vk-details',
  templateUrl: './draft-adhoc-vk-details.component.html',
  styleUrls: [
    '../../../draft-common.scss'
  ]
})
export class DraftAdhocVkDetailsComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  @ViewChild('f', {static: true}) public cform: NgForm;
  @Input() formId: string;
  @Input() details: ClientStateDetails;
  by: string;

  constructor(private formSaver: FormSaver,
              private opslagService: OpslagService) {
  }

  ngOnInit(): void {
    this.postNrChanged();
    this.formSaver.registerForm(this.formId, this.cform);
  }

  isNameRequired(): boolean {
    const isTotalForsvundet = ClientStateDetails.TOTALFORSVUNDET_SKADETYPE === this.details.damageDescription.skadeType;
    return !isTotalForsvundet && isNullOrUndefined(this.details.vknr);
  }

  postNrChanged(): void {
    const postalcode = this.details.adhocVkDetails.postalcode;
    if (!isNullOrUndefined(postalcode)) {
      this.opslagService.getPostNr(Number(postalcode))
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(postNr => {
          this.by = postNr ? postNr.navn : '';
        }, () => {
          this.by = '';
        });
    } else {
      this.by = '';
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
