import {CarDetailsProviderService} from '../../../shared/ui/car-details/car-details-provider.service';
import {Observable} from 'rxjs';
import {ReportService} from '../../service/report.service';

export class ReportCarDetailsProviderService implements CarDetailsProviderService {
  private reportService: ReportService;
  private readonly reportId: number;

  constructor(reportService: ReportService, reportId: number) {
    this.reportService = reportService;
    this.reportId = reportId;
  }

  getCarDetails(): Observable<any> {
    return this.reportService.getCarDetails(this.reportId);
  }
}
