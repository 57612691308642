import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {WorkshopUserDTO} from '../model/workshop-user-dto.model';
import {Injectable} from '@angular/core';

@Injectable()
export class WorkshopUserService {
  private rootUrl = 'workshop/user';
  constructor(private http: HttpClient) {
  }

  public getAll(): Observable<WorkshopUserDTO[]> {
    return this.http.get<WorkshopUserDTO[]>(this.rootUrl);
  }
}
