<lc-qapter-start [startQapter$]="startQapter$"></lc-qapter-start>
<div class="forsi-home w-100 h-100">
    <div class="forsi-home-overlay w-100 h-100">
        <div class="center-box container">
            <div class="border border-primary rounded-1 border-opacity-25 p-4 mx-5">
                <div class="row mb-4 text-center">
                    <div class="mx-auto">
                        <img src="/content/images/Autotaks_logo.svg" style="width: 10rem;">
                    </div>

                </div>
                <div class="row mb-4 text-center">
                    <h2>Recycle / OE-number for Autoflex</h2>
                    <div class="text-center">{{formattedSessionTimeLeft$ | async}}</div>
                </div>
                <div class="row mb-4 text-center" *ngIf="fetchingVehicleInformation">
                    <span>Henter information om køretøjet
                    <i class="fa fa-refresh fa-2x"
                       [ngClass]="{'fa-spin': fetchingVehicleInformation, 'fa-spin-stop-ie-hack': !fetchingVehicleInformation}"></i>
                    </span>
                </div>
                <div class="card mb-4" *ngIf="!fetchingVehicleInformation">
                    <div class="card-header" *ngIf="!calculation">Køretøj</div>
                    <div class="card-header" *ngIf="!!calculation">Du har fundet reservedelsinformationer for</div>
                    <div class="card-body">
                        <div class="row" *ngIf="!!vehicleInformation?.make || !!calculation?.make">
                            <div class="col">
                                <div class="row mb-2">
                                    <div class="col-4">Mærke</div>
                                    <div class="col" *ngIf="!!vehicleInformation">{{vehicleInformation.make}}</div>
                                    <div class="col" *ngIf="!!calculation">{{calculation.make}}</div>
                                </div>
                                <div class="row mb-2" *ngIf="!!vehicleInformation?.model || !!calculation">
                                    <div class="col-4">Model</div>
                                    <div class="col" *ngIf="!!vehicleInformation">{{vehicleInformation.model}}</div>
                                    <div class="col" *ngIf="!!calculation">{{calculation.model}}</div>
                                </div>
                                <div class="row mb-2 align-items-center"
                                     *ngIf="!!vehicleInformation && !vehicleInformation.model">
                                    <div class="col-4">Angiv model</div>
                                    <div class="col">
                                        <lc-autocomplete
                                                id="model" name="model"
                                                [(ngModel)]="selectedModel"
                                                [items]="selectableModels"
                                                exactKey="kode" itemText="navn"
                                                required
                                        ></lc-autocomplete>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-4">Stelnummer</div>
                                    <div class="col" *ngIf="!!vehicleInformation">{{vehicleInformation.vin}}</div>
                                    <div class="col" *ngIf="!!calculation">{{calculation.vin}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row"
                             *ngIf="!fetchingVehicleInformation && !!vehicleInformation && !vehicleInformation.make">
                            <div class="col">
                                <div class="row mb-4">
                                    <h4>Mærke og model information ikke tilgængelig</h4>
                                    <a>Angiv venligst herunder:</a>
                                </div>
                                <div class="row mb-2 align-items-center">
                                    <div class="col-4">Mærke</div>
                                    <div class="col">
                                        <lc-autocomplete
                                                [(ngModel)]="selectedMake"
                                                id="fantomFabrikat"
                                                name="fantomFabrikat"
                                                [items]="selectableMakes" exactKey="kode" itemText="navn"
                                                required
                                                (ngModelChange)="makeSelectionChanged()"
                                        >
                                        </lc-autocomplete>
                                    </div>
                                </div>
                                <div class="row align-items-center">
                                    <div class="col-4">Model</div>
                                    <div class="col">
                                        <lc-autocomplete
                                                id="fantomModel" name="fantomModel"
                                                [(ngModel)]="selectedModel"
                                                [items]="selectableModels"
                                                exactKey="kode" itemText="navn"
                                                required
                                                [disabled]="!selectedMake"
                                        >
                                        </lc-autocomplete>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-4" *ngIf="!!calculation">
                    <div class="card-header">
                        Følgende dele er valgt for lagerføring
                    </div>
                    <div class="card-body">
                        <div *ngFor="let part of calculation.parts">{{part}}</div>
                    </div>
                </div>
                <div class="row" *ngIf="!fetchingVehicleInformation">
                    <div class="col-12">
                        <button type="button" class="btn btn-primary qapter-button float-end" (click)="startQapter()"
                                [disabled]="!qapterEnabled()" *ngIf="!!vin$.getValue()">
                            <lc-spinner-image [spin]="spin" imgsrc="content/images/qapter-logo.png">
                                <span class="itemText">apter</span>
                            </lc-spinner-image>
                        </button>
                        <button type="button" class="btn btn-primary float-end" *ngIf="!!calculation" (click)="endSession()">Send<span *ngIf="callbackUrl"> og Returner</span></button>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="text-center pt-4">
                            <a *ngIf="showReportError()" href="https://itsupport.fogp.dk/app/submit-ticket?entityTypeId=281&ticketTypeId=108" target="_blank"
                               class="py-2 px-3 align-middle d-inline-block">Support</a>
                            <div class="vr align-middle"></div>
                            <a href="https://www.linkedin.com/company/autotaks" target="_blank" class="py-2 px-3 align-middle d-inline-block">Nyheder</a>
                            <div class="vr align-middle"></div>
                            <a href="https://www.forsikringogpension.dk/services/autotaks/udbydere-og-support/" target="_blank"
                               class="py-2 px-3 align-middle d-inline-block">Udbydere</a>
                            <div class="vr align-middle"></div>
                            <a href="https://www.forsikringogpension.dk/services/autotaks/" target="_blank"
                               class="py-2 px-3 align-middle d-inline-block">Autotaks.dk</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

