<div class="row viewer-top-bar" id="print-header">
    <div class="col-md-12">
        <ng-container *ngIf="allowPrinting">
            <button class="pull-right icon-btn mt-2 clickable" (click)="showPrintPopup()">
                <i class="fa fa-print fa-2x"></i>
            </button>
        </ng-container>
        <ng-container *ngIf="!allowPrinting">
            <button class="pull-right icon-btn mt-2" [title]="'Du kan ikke printe denne rapport'" disabled>
                <i class="fa fa-print fa-2x"></i>
            </button>
        </ng-container>
        <nav class="nav nav-tabs lc-tabs">
            <button [disabled]="!vkPrintModel.hasData" (click)="didClickTab(vkPrintModel)"
                    [ngClass]="{'active' : isActive(vkPrintModel)}"
                    class="text-sm-center nav-link clickable">Værkstedsrapport
            </button>
            <button [disabled]="!taksPrintModel.hasData" (click)="didClickTab(taksPrintModel)"
                    [ngClass]="{'active' : isActive(taksPrintModel)}"
                    class="text-sm-center nav-link clickable">Taksatorrapport
            </button>
        </nav>
    </div>
</div>
<br/><br/><br/>
<div id="print-body">
    <div class="row" *ngIf="isActive(vkPrintModel)">
        <div class="col-md-12 pt-3 pb-3">
            <lc-print-viewer [print]="vkPrintModel.print"></lc-print-viewer>
        </div>
    </div>
    <div class="row" *ngIf="isActive(taksPrintModel)">
        <div class="col-md-12 pt-3 pb-3">
            <lc-print-viewer [print]="taksPrintModel.print"></lc-print-viewer>
        </div>
    </div>
</div>
