import {Injectable} from '@angular/core';
import {DeliveryNoteTemplateDataDTO} from './model/delivery-note-template-data-dto.model';
import {DeliveryNoteReceiverTemplateDataDTO} from './model/delivery-note-receiver-template-data-dto.model';
import {BehaviorSubject, Observable} from 'rxjs';
import {DeliveryNoteReceiverType} from './model/delivery-note-receiver-type.enum';
import {DeliveryNoteTemplateOptions} from './model/delivery-note-template-options.model';
import {CreateDeliveryNoteDTO} from './model/create-delivery-note-dto.model';
import {DeliveryNoteDTO} from './model/delivery-note-dto.model';
import {RapportType} from '../../model/rapport-type.model';
import {DeliveryNoteDataProvider} from '../../service/delivery-note-data-provider';
import {AttachDeliveryNoteDTO} from './model/attach-delivery-note-dto.model';
import {PrintDeliveryNoteDTO} from './model/print-delivery-note-dto.model';
import URLBuilder from '../../utils/url-builder';

@Injectable()
export class DeliveryNoteStateService {
  private urlBuilder = new URLBuilder();
  private identifier: any;
  private deliveryNoteDataProvider: DeliveryNoteDataProvider;
  private _deliveryNoteTemplateData: BehaviorSubject<DeliveryNoteTemplateDataDTO> = new BehaviorSubject(null);
  private _deliveryNoteReceiverTemplateData: BehaviorSubject<DeliveryNoteReceiverTemplateDataDTO> = new BehaviorSubject(new DeliveryNoteReceiverTemplateDataDTO());
  private _deliveryNoteTemplateOptions: BehaviorSubject<DeliveryNoteTemplateOptions> = new BehaviorSubject(null);
  private _reportType: BehaviorSubject<RapportType> = new BehaviorSubject(null);
  public remarks: string;
  public printId: number;
  public attachedDeliveryNote: string;

  constructor() {
  }

  initializeData(identifier: any, deliveryNoteDataProvider: DeliveryNoteDataProvider): void {
    this.identifier = identifier;
    this.deliveryNoteDataProvider = deliveryNoteDataProvider;
    this.deliveryNoteDataProvider.getDeliveryNoteTemplateData(identifier)
      .subscribe(deliveryNoteTemplateData => this._deliveryNoteTemplateData.next(deliveryNoteTemplateData));
    this.deliveryNoteDataProvider.getReportType(identifier)
      .subscribe(reportType => {
        this._reportType.next(reportType)
      });
  }

  public getReportType(): BehaviorSubject<RapportType> {
    return this._reportType;
  }

  public getDeliveryNoteTemplateData(): BehaviorSubject<DeliveryNoteTemplateDataDTO> {
    return this._deliveryNoteTemplateData;
  }

  public getDeliveryNoteTemplateOptions(): BehaviorSubject<DeliveryNoteTemplateOptions> {
    return this._deliveryNoteTemplateOptions;
  }

  public updateDeliveryNoteTemplateOptions(deliveryNoteTemplateOptions: DeliveryNoteTemplateOptions): void {
    this._deliveryNoteTemplateOptions.next(deliveryNoteTemplateOptions);
  }

  public refreshDeliveryNoteReceiverTemplateDataByReceiverType(type: DeliveryNoteReceiverType): void {
    this.deliveryNoteDataProvider.getDeliveryNoteTemplateDataByReceiverType(this.identifier, type)
      .subscribe(deliveryNoteReceiverTemplateData => this._deliveryNoteReceiverTemplateData.next(deliveryNoteReceiverTemplateData));
  }

  public getDeliveryNoteReceiverTemplateData(): BehaviorSubject<DeliveryNoteReceiverTemplateDataDTO> {
    return this._deliveryNoteReceiverTemplateData;
  }

  public updateDeliveryNoteReceiverTemplateData(deliveryNoteReceiverTemplateData: DeliveryNoteReceiverTemplateDataDTO): void {
    this._deliveryNoteReceiverTemplateData.next(deliveryNoteReceiverTemplateData);
  }

  generateDeliveryNote(createDeliveryNote: CreateDeliveryNoteDTO): Observable<DeliveryNoteDTO> {
    return this.deliveryNoteDataProvider.generateDeliveryNote(this.identifier, createDeliveryNote);
  }

  attachDeliveryNote(deliveryNote: DeliveryNoteDTO): Observable<AttachDeliveryNoteDTO> {
    return this.deliveryNoteDataProvider.attachDeliveryNote(this.identifier, deliveryNote);
  }

  printDeliveryNote(deliveryNote: DeliveryNoteDTO): Observable<PrintDeliveryNoteDTO> {
    return this.deliveryNoteDataProvider.printDeliveryNote(this.identifier, deliveryNote);
  }

  showDeliveryNotePrint(printId: number, fileName: string): void {
    window.open(this.urlBuilder.getBaseApiUrl() + this.deliveryNoteDataProvider.getDeliveryNotePrintUrl(this.identifier, printId, fileName), '_blank');
  }

}
