<fieldset ngForm="f" #f="ngForm">
    <div class="card">
        <div class="card-body">
            <div class="row pb-3">
                <div class="col-form-label col-4">
                    <label for="nrpladebeloeb">Nummerplader</label>
                </div>
                <div class="col">
                    <input [(ngModel)]="details.nrpladeBeloeb" class="form-control" type="tel" id="nrpladebeloeb"
                        name="nrpladebeloeb" lcInputMask="int4" size="4" />
                </div>
            </div>

            <div class="row pb-3">
                <div class="col-form-label col-4">
                    <label for="synsgebyr">Synsgebyr</label>
                </div>
                <div class="col">
                    <input [(ngModel)]="details.synsgebyr" class="form-control col-8" type="tel" id="synsgebyr"
                        name="synsgebyr" lcInputMask="int4" size="4" />
                </div>
            </div>
        </div>
    </div>
</fieldset>