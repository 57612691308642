import {RouterModule, Routes} from '@angular/router';
import {UserRouteAccessService} from '../shared';
import {AppRole} from '../shared/service/auth/app-roles.model';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

export const OFFER_ROUTE: Routes = [
  {
    path: 'offer',
    loadChildren: () => import('./offer.module').then(m => m.OfferModule),
    data: {
      authorities: [AppRole.TAKS],
      pageTitle: 'Indkomne tilbud',
      breadcrumb: 'Indkomne tilbud'
    },
    canActivate: [UserRouteAccessService]
  }
];

/**
 * Routing module enabling lazyloading (through the loadChildren function) of the entire sysadmin module.
 */
@NgModule({
  imports: [
    RouterModule.forChild(OFFER_ROUTE)
  ],
  declarations: [],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class OfferRoutingModule {

}
