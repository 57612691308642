<div class="row autoflex-part-list" [hidden]="noAutoflexPart$ | async">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                Autoflexdele
            </div>
            <div class="card-block p-1 py-2">
                <table class="table table-hover mb-0">
                    <thead>
                    <tr>
                        <th class="ps-0">Stand</th>
                        <th class="ps-0">Leverandør</th>
                        <th class="ps-0">Alternativ tekst</th>
                        <th class="ps-0">Resnr.</th>
                        <th class="ps-0">Alt. pris</th>
                        <th class="ps-0">Leveringsbetingelser</th>
                    </tr>
                    </thead>
                    <ng-container *ngFor="let autoflexRow of autoflexParts$ | async">
                        <tr>
                            <td>{{autoflexRow.state | autoflexState}}</td>
                            <td>{{autoflexRow.thirdPartySupplier}}</td>
                            <td>{{autoflexRow.supplierDescription}}</td>
                            <td>{{autoflexRow.supplierReference}}</td>
                            <td class="text-end pe-3">{{autoflexRow.price | number: '1.2-2'}}</td>
                            <td>{{autoflexRow | autoflexDeliveryTerm}}</td>
                        </tr>
                    </ng-container>
                </table>
            </div>
        </div>
    </div>
</div>
