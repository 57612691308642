import {Component, Input, OnChanges, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ClientStateDetails} from '../../../model/client-state-details.model';
import {OpslagService} from '../../../../shared/service/opslag.service';
import {FormSaver} from '../../../../shared/service/core/form-saver.service';
import {NgForm} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'lc-draft-skadelidte-info',
  templateUrl: './draft-skadelidte-info.component.html',
  styleUrls: ['../../draft-common.scss']
})
export class DraftSkadelidteInfoComponent implements OnInit, OnChanges, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  @ViewChild('f', {static: true}) public cform: NgForm;
  @Input() formId: string;
  @Input() details: ClientStateDetails;
  by: string;

  constructor(private opslagService: OpslagService,
              protected formSaver: FormSaver) {
  }

  ngOnChanges(): void {
    if (!this.details.ansvarsskade) {
      this.by = '';
    }
  }

  ngOnInit(): void {
    this.postNrChanged();
    this.formSaver.registerForm(this.formId, this.cform);
  }

  postNrChanged(): void {
    if (this.details.skadelidte.postNr) {
      this.opslagService.getPostNr(Number(this.details.skadelidte.postNr))
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(postNr => {
          if (postNr) {
            this.by = postNr.navn;
          }
        }, () => {
          this.by = '';
        });
    } else {
      this.by = '';
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
