import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {SettingsService} from '../../service/settings.service';
import {BroadcastDTO} from '../../dto/broadcast-dto.model';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  templateUrl: './new-broadcasts-popup.component.html',
})
export class NewBroadcastsPopupComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  broadcasts: BroadcastDTO[];

  constructor(private activeModal: NgbActiveModal, private settingsService: SettingsService) {
  }

  ngOnInit(): void {
    this.settingsService.getAllBroadcasts()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(broadcasts => {
        this.broadcasts = broadcasts;
      });
  }

  deleteBroadcast(broadcast: BroadcastDTO): void {
    this.settingsService.deleteBroadcast(broadcast.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        const broadcastIndex = this.broadcasts.indexOf(broadcast);
        this.broadcasts.splice(broadcastIndex, 1);
      });
  }

  cancel(): void {
    this.activeModal.dismiss('cancel');
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
