import {Routes} from '@angular/router';

import {DraftCreateComponent, DraftEditComponent, DraftListComponent} from './';
import {UserRouteAccessService} from '../shared';
import {DraftCalculateComponent} from './edit/calculate/draft-calculate.component';
import {DraftAutoflexComponent} from './edit/autoflex/draft-autoflex.component';
import {DraftEditWrapperComponent} from './edit/draft-edit-wrapper.component';
import {DraftPoliceComponent} from './edit/police/draft-police.component';
import {CheckOpslagComponent} from './edit/checkopslag/check-opslag.component';
import {DraftApproveComponent} from './edit/approve/draft-approve.component';
import {NewReportTypeFromDraftComponent} from './new-report-type/new-report-type-from-draft.component';
import {CheckReportComponent} from './edit/checkrapport/check-report.component';
import {DraftTakeoverComponent} from './takeover/draft-takeover.component';
import {EditDeliveryNoteComponent} from '../shared/ui/delivery-note/edit/edit-delivery-note.component';
import {ShowDeliveryNoteComponent} from '../shared/ui/delivery-note/show/show-delivery-note.component';
import {DeliveryNoteDraftWrapperComponent} from '../shared/ui/delivery-note/delivery-note-draft-wrapper.component';
import {DraftDiffComponent} from './edit/diff/draft-diff.component';
import {AppRole} from '../shared/service/auth/app-roles.model';
import {DraftCarDetailsComponent} from './edit/car-details/draft-car-details.component';
import {DraftSparePartSupplierComponent} from './edit/spare-part-supplier/draft-spare-part-supplier.component';

export const DRAFT_ROUTES: Routes = [
  {
    path: 'create',
    component: DraftCreateComponent,
    data: {
      authorities: [AppRole.VK, AppRole.TAKS],
      pageTitle: 'Ny sag',
      breadcrumb: 'Opret ny sag'
    },
    canActivate: [UserRouteAccessService],
    children: [
      {
        path: 'car-details/:licensePlate',
        component: DraftCarDetailsComponent,
        data: {
          authorities: [AppRole.TAKS, AppRole.VK],
          pageTitle: 'Bil detaljer',
          breadcrumb: 'Bil detaljer'
        },
        canActivate: [UserRouteAccessService]
      }
    ]
  },
  {
    path: 'draft',
    data: {
      authorities: [AppRole.VK, AppRole.TAKS],
      breadcrumb: 'Igangværende'
    },
    canActivate: [UserRouteAccessService],
    children: [
      {
        path: '',
        component: DraftListComponent,
        data: {
          authorities: [AppRole.VK, AppRole.TAKS],
          pageTitle: 'Igangværende sager'
        },
        canActivate: [UserRouteAccessService],
      },
      {
        path: 'edit/:token',
        data: {
          authorities: [AppRole.VK, AppRole.TAKS],
          breadcrumb: '$ctx.CLIENTSTATE_REPORT_KEY:token'
        },
        component: DraftEditWrapperComponent,
        canActivate: [UserRouteAccessService],
        children: [
          {
            path: '',
            component: DraftEditComponent,
            data: {
              authorities: [AppRole.VK, AppRole.TAKS],
              pageTitle: 'Rediger Igangværende sag'
            },
            canActivate: [UserRouteAccessService],
          },
          {
            path: 'autoflex',
            component: DraftAutoflexComponent,
            data: {
              authorities: [AppRole.VK, AppRole.TAKS],
              pageTitle: 'Autoflex',
              breadcrumb: 'Autoflex'
            },
            canActivate: [UserRouteAccessService]
          },
          {
            path: 'spare-part-supplier',
            component: DraftSparePartSupplierComponent,
            data: {
              authorities: [AppRole.VK, AppRole.TAKS],
              pageTitle: 'Autoflex V2',
              breadcrumb: 'Autoflex V2'
            },
            canActivate: [UserRouteAccessService]
          },
          {
            path: 'calculate',
            component: DraftCalculateComponent,
            data: {
              authorities: [AppRole.VK, AppRole.TAKS],
              pageTitle: 'Godkend rapport',
              breadcrumb: 'Beregning'
            },
            canActivate: [UserRouteAccessService],
          },
          {
            path: 'diff',
            component: DraftDiffComponent,
            data: {
              authorities: [AppRole.TAKS],
              pageTitle: 'Sammenlign',
              breadcrumb: 'Sammenlign'
            },
            canActivate: [UserRouteAccessService]
          },
          {
            path: 'approve',
            component: DraftApproveComponent,
            data: {
              authorities: [AppRole.VK, AppRole.TAKS],
              pageTitle: 'Godkend rapport',
              breadcrumb: 'Godkend rapport'
            },
            canActivate: [UserRouteAccessService],
          },
          {
            path: 'checkreport',
            component: CheckReportComponent,
            data: {
              authorities: [AppRole.VK, AppRole.TAKS],
              pageTitle: 'Check rapport',
              breadcrumb: 'Check rapport'
            },
            canActivate: [UserRouteAccessService]
          },
          {
            path: 'police',
            component: DraftPoliceComponent,
            data: {
              authorities: [AppRole.TAKS],
              pageTitle: 'Policeopslag',
              breadcrumb: 'Policeopslag'
            },
            canActivate: [UserRouteAccessService]
          },
          {
            path: 'car-details/:licensePlate',
            component: DraftCarDetailsComponent,
            data: {
              authorities: [AppRole.TAKS, AppRole.VK],
              pageTitle: 'Bil detaljer',
              breadcrumb: 'Bil detaljer'
            },
            canActivate: [UserRouteAccessService]
          },
          {
            path: 'check-opslag',
            component: CheckOpslagComponent,
            data: {
              authorities: [AppRole.TAKS],
              pageTitle: 'Check opslag',
              breadcrumb: 'Check opslag'
            },
            canActivate: [UserRouteAccessService]
          },
          {
            path: 'deliverynote',
            component: DeliveryNoteDraftWrapperComponent,
            data: {
              authorities: [AppRole.TAKS],
              breadcrumb: 'Rediger følgeseddel'
            },
            canActivate: [UserRouteAccessService],
            children: [
              {
                path: '',
                component: EditDeliveryNoteComponent,
                data: {
                  authorities: [AppRole.TAKS],
                  pageTitle: 'Rediger følgeseddel'
                },
                canActivate: [UserRouteAccessService]
              },
              {
                path: 'show',
                component: ShowDeliveryNoteComponent,
                data: {
                  authorities: [AppRole.TAKS],
                  pageTitle: 'Vis følgeseddel',
                  breadcrumb: 'Vis'
                },
                canActivate: [UserRouteAccessService]
              }
            ]
          },
          {
            path: 'new',
            component: NewReportTypeFromDraftComponent,
            data: {
              authorities: [AppRole.VK, AppRole.TAKS],
              pageTitle: 'Ny Rapporttype',
              breadcrumb: 'Ny rapporttype'
            },
            canActivate: [UserRouteAccessService],
          }
        ]
      },
      {
        path: 'takeover/:token',
        component: DraftTakeoverComponent,
        data: {
          authorities: [AppRole.TAKS],
          pageTitle: 'Overtag kladde',
          breadcrumb: 'Overtag kladde'
        },
        canActivate: [UserRouteAccessService]
      }
    ]
  }
];
