<div class="modal-header pre-size header-style" style="text-align: center">
    <h3 class="text-truncate">
        Nye indkomne kladder
    </h3>
    <button aria-label="Close" data-dismiss="modal" class="btn-close" type="button" (click)="close()"></button>
</div>

<div class="modal-content">
    <div class="modal-body modal-body-style">

        <div class="table-responsive overflow-hide">
            <table class="table table-hover table-borderless">
                <thead>
                <tr>
                    <th class="text-start">
                        Selskab
                    </th>
                    <th class="text-start">
                        Rapport
                    </th>
                    <th class="text-start">
                        Reg nr.
                    </th>
                    <th class="">
                        Model
                    </th>
                    <th class="">
                        Beløb
                    </th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let line of clientStateList">
                    <tr>
                        <td>{{line.selskab}}</td>
                        <td>{{getReportKey(line) | lcForsiReportKey}}</td>
                        <td>{{line.regNr}}</td>
                        <td>{{line.vehicleText}}</td>
                        <td>{{line.beloeb}}</td>
                    </tr>
                </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div class="modal-footer">
    <div class="pull-right">
        <button class="btn btn-lg btn-primary" (click)="close()">Ok</button>
    </div>
</div>
