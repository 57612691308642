import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {OAuthClientCredentialsDTO} from './model/o-auth-client-credentials-dto.model';
import {OAuthGrantType} from './model/o-auth-grant-type.model';
import {OAuthScope} from './model/o-auth-scope.model';
import {OAuthLoginFlowDTO} from './model/o-auth-login-flow-dto.model';

/**
 * The actual api towards oauth/token service.
 */
@Injectable({providedIn: 'root'})
export class OAuthApiService {

  private rootUrl = 'oauth'

  private headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    Accept: 'application/json'
  });

  private static getFormEncodedGrantTypeClientCredentialsBody(username: string, password: string): string {
    return `grant_type=${OAuthGrantType.CLIENT_CREDENTIALS}&client_id=${username}&client_secret=${encodeURIComponent(password)}&scope=${OAuthScope.FORSI}`;
  }

  private static getFormEncodedGrantTypeRefreshTokenBody(): string {
    //relying on the refresh_token is stored in a cookie relative to the environment ('/' and '/eap'), hence we do not provide it explicitly here
    return `grant_type=${OAuthGrantType.REFRESH_TOKEN}&scope=${OAuthScope.FORSI}`;
  }

  constructor(private http: HttpClient) {
  }

  public authenticate(username: string, password: string): Observable<OAuthClientCredentialsDTO> {
    return this.http.post<OAuthClientCredentialsDTO>(`${this.rootUrl}/token?login`, OAuthApiService.getFormEncodedGrantTypeClientCredentialsBody(username, password), {headers: this.headers});
  }

  public authenticateB2C(): Observable<OAuthClientCredentialsDTO> {
    return this.http.post<OAuthClientCredentialsDTO>(`${this.rootUrl}/b2c`, null);
  }

  public refreshAccessToken(): Observable<OAuthClientCredentialsDTO> {
    return this.http.post<OAuthClientCredentialsDTO>(`${this.rootUrl}/token`, OAuthApiService.getFormEncodedGrantTypeRefreshTokenBody(), {headers: this.headers});
  }

  public removeAuthentication(): Observable<void> {
    return this.http.delete<void>(`${this.rootUrl}/token`);
  }

  getLoginFlow(username: string): Observable<OAuthLoginFlowDTO> {
    return this.http.get<OAuthLoginFlowDTO>(`${this.rootUrl}/flow?username=${username}`);
  }
  
  activateB2C(email: string, activationId: string): Observable<boolean> {
    const userB2CLoginDTO = {
      email: email ,
      activationId: activationId
    };
    return this.http.put<boolean>(`${this.rootUrl}/flow/b2c-user/activate`, userB2CLoginDTO);
  }
}
