import {CarSaleDTO} from '../dto/carsale-dto.model';
import {RapportType} from './rapport-type.model';

export class CarSaleList {

  carSales = {};

  public setCarSaleList(carSales: {}): void {
    this.carSales = carSales;
  }

  getCarSale(taksRapportKey: string): CarSaleDTO {
    const identifier = this.getReportKeyWithoutType(taksRapportKey.trim());
    if (this.carSaleSupportedByReportType(taksRapportKey)) {
      return this.carSales[identifier];
    }
    return null;
  }

  hasCarSale(taksRapportKey: string): boolean {
    const identifier = this.getReportKeyWithoutType(taksRapportKey.trim());
    return this.carSales[identifier] && this.carSaleSupportedByReportType(taksRapportKey);
  }

  addToCarSaleList(carSaleDTO: CarSaleDTO): void {
    this.carSales[carSaleDTO.identifier] = carSaleDTO;
  }

  removeFromCarSaleList(carSaleDTO: CarSaleDTO): void {
    this.carSales[carSaleDTO.identifier] = null;
  }

  getReportKeyWithoutType(taksRapportKey: string): string {
    return taksRapportKey.trim().substring(0, 13);
  }

  isCarSaleDeprecated(taksRapportKey: string, updatedAt: number): boolean {
    const carSale = this.getCarSale(taksRapportKey.trim());
    return carSale && (carSale.updatedAt.valueOf() < updatedAt);
  }

  carSaleSupportedByReportType(taksRapportKey: string): boolean {
    return RapportType.extractFrom(taksRapportKey.trim()).isOfAny([RapportType.H, RapportType.T]);
  }
}
