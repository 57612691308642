import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BaseAttachmentComponent} from '../base-attachment.component';
import {AttachmentService} from '../attachment.service';
import {PrincipalService} from '../../..';
import {BootstrapAlertType, BootstrapGrowlService} from '../../ngx-bootstrap-growl';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable, Subject} from 'rxjs';
import {AttachmentDTO} from '../../../dto/attachment-dto.model';
import {takeUntil} from 'rxjs/operators';
import {FileUploader} from 'ng2-file-upload';
import {StatusMessageDTO} from '../../../dto/status-message-dto.model';
import {ReportService} from '../../../../report/service/report.service';
import {TemporaryAttachmentApprovalModel} from '../../../model/temporary-attachment-approval.model';
import {isNullOrUndefined} from '../../../utils/object-utils';
import {LcPopupService} from '../../../modals/lc-popup.service';

@Component({
  selector: 'lc-report-attachment',
  templateUrl: './report-attachment.component.html',
  styleUrls: [
    '../attachment.component.scss'
  ]
})
export class ReportAttachmentComponent extends BaseAttachmentComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  @Input() workshopReportId: number;

  /**
   * Tells whether or not videoRequest in general is a usecase in the scenario the component is being used.
   * If true, we test to see if all other requirements for the report and current user is met. Default the value is false.
   */
  @Input() videoRequestUseCase = false;
  public renderWorkshopVideoRequestComponent = false;

  @Output() readyForApproval = new EventEmitter<TemporaryAttachmentApprovalModel>();

  constructor(attachmentService: AttachmentService,
              principal: PrincipalService,
              modalService: NgbModal,
              private reportService: ReportService,
              private bootstrapGrowlService: BootstrapGrowlService,
              popupService: LcPopupService) {
    super(attachmentService, principal, modalService, popupService);
  }

  ngOnInit(): void {
    const wasInitiatedByWorkshop = !!this.workshopReportId;
    this.renderWorkshopVideoRequestComponent = this.videoRequestUseCase && this.principal.isVK() && wasInitiatedByWorkshop;
  }

  protected getAttachmentsObs(): Observable<AttachmentDTO[]> {
    return this.reportService.getAttachments(this.reportKey).pipe(takeUntil(this.unsubscribe$));
  }

  public isDeletable(attachment: AttachmentDTO): boolean {
    return false;
  }

  protected prepareFileUploader(): FileUploader {
    if (this.readonly) {
      return null;
    }
    let attachmentsForApproval: TemporaryAttachmentApprovalModel;
    const attachmentPath = 'report/' + this.reportKey + '/attachments';
    const fileUploader = new FileUploader(this.getFileUploadOptions(attachmentPath));

    fileUploader.onAfterAddingAll = () => {
      fileUploader.uploadAll();
      attachmentsForApproval = new TemporaryAttachmentApprovalModel(this.workshopReportId, this.reportKey, this.getUploadQueue());
      attachmentsForApproval.approved.pipe(takeUntil(this.unsubscribe$)).subscribe((attachment) => this.updateLocally(attachment));
      this.readyForApproval.emit(attachmentsForApproval);
    };

    fileUploader.onWhenAddingFileFailed = (item, filter, options) => {
      let message = item.name + ' blev ikke uploadet';
      switch (filter.name) {
        case 'fileSize':
          message += ' - Må ikke være større end 15 MB!';
          break;
        default:
          message += ' - Der skete en uventet fejl!';
      }
      this.bootstrapGrowlService.addAlert(message, BootstrapAlertType.DANGER);
      if (attachmentsForApproval != null) {
        attachmentsForApproval.onTemporaryAttachmentError(item.name);
      }
    };

    fileUploader.onCompleteAll = () => {
      this.onAttachmentChanged.emit();
      if (attachmentsForApproval != null) {
        attachmentsForApproval.onAllTemporaryAttachmentsLoaded();
      }
    };

    fileUploader.onSuccessItem = (item, response) => {
      if (attachmentsForApproval != null) {
        attachmentsForApproval.onTemporaryAttachmentLoaded(JSON.parse(response), item);
      }
      this.reportService.clearCache();
    };

    fileUploader.onErrorItem = (item, response, status) => {
      if (status === 413 && !isNullOrUndefined(response)) {
        const statusMessage: StatusMessageDTO = JSON.parse(response);
        this.bootstrapGrowlService.addAlert('Det var ikke muligt at gemme ' + item.file.name + '! ' + statusMessage.message, BootstrapAlertType.WARNING);
      } else {
        this.bootstrapGrowlService.addAlert('Det var ikke muligt at gemme ' + item.file.name + '!', BootstrapAlertType.DANGER);
      }
      if (attachmentsForApproval != null) {
        attachmentsForApproval.onTemporaryAttachmentError(item.file.name);
      }
    }
    return fileUploader;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
