export enum ApprovePossibilityType {
  REQUESTING_CONTACT = 'REQUESTING_CONTACT',
  MULTIPLE_DAMAGES = 'MULTIPLE_DAMAGES',
  NONE = 'NONE'
}

export class ApprovalChoiceDTO {
  approvePossibilityType: ApprovePossibilityType;
  approvePossibilityText: string;
}
