import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BootstrapGrowlService} from 'app/shared/ui/ngx-bootstrap-growl/index';
import {SettingsService} from '../../../shared/service/settings.service';
import {DeliveryNoteConfigurationDTO} from 'app/settings/model/delivery-note-configuration-dto.model';
import {NgForm} from '@angular/forms';
import {BootstrapAlertType} from 'app/shared/ui/ngx-bootstrap-growl/bootstrap-alert-type.enum';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  templateUrl: './settings.deliverynote.component.html',
  styleUrls: [
    '../../settings.scss'
  ],
  selector: 'lc-settings-deliverynote'
})
export class SettingsDeliverynoteComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  isLoading: boolean;
  deliveryNoteConfiguration: DeliveryNoteConfigurationDTO = new DeliveryNoteConfigurationDTO();

  @ViewChild('f') public form: NgForm;

  constructor(private settingsService: SettingsService,
              private bootstrapGrowlService: BootstrapGrowlService) {
  }

  ngOnInit(): void {
    this.reset();
  }

  reset(): void {
    this.isLoading = true;
    this.settingsService.getDeliveryNoteConfiguration()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(deliveryNoteConfiguration => {
        this.deliveryNoteConfiguration = deliveryNoteConfiguration;
        this.isLoading = false;
      });
  }

  saveDeliveryNoteConfiguration(): void {
    this.isLoading = true;
    this.settingsService.saveDeliveryNoteConfiguration(this.deliveryNoteConfiguration)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(deliveryNoteConfiguration => {
        this.deliveryNoteConfiguration = deliveryNoteConfiguration;
        this.isLoading = false;
        this.bootstrapGrowlService.addAlert('Følgeseddel opdateret', BootstrapAlertType.SUCCESS);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
