import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TextLibraryTypeEnum} from '../../service/text-library-type.enum';
import {TextLibraryPopupComponent} from '../../modals/text-library/text-library-popup.component';
import {UserTextLibraryService} from '../../service/user-text-library.service';
import {RapportType} from '../../model/rapport-type.model';

@Component({
  selector: 'lc-damages-note',
  templateUrl: './damages-note.component.html',
  styleUrls: ['./damages-note.scss']
})
export class DamagesNoteComponent {
  @Input() damagesNoteText: string;
  @Input() reportType: RapportType;

  @Output() damagesNoteTextUpdatedEmitter = new EventEmitter<string>();
  libraryType = TextLibraryTypeEnum.SKADENOTAT;

  constructor(private modalService: NgbModal,
              private userTextLibraryService: UserTextLibraryService) {
  }

  showTextLibrary(): void {
    const modalRef = this.modalService.open(TextLibraryPopupComponent, {size: 'lg', windowClass: 'lc-fullscreen'});
    const popUpComponent = modalRef.componentInstance as TextLibraryPopupComponent;
    popUpComponent.title = 'Skadenotat Tekstbibliotek';
    popUpComponent.textLibraryType = this.libraryType;
    popUpComponent.textLibraryService = this.userTextLibraryService;
    modalRef.result.then((text: string) => {
      if (text) {
        this.didSelectTextFromLibrary(text);
      }
    }).catch(() => {
      // Necessary to avoid "Uncaught error Error: Uncaught (in promise)" even though we do not use the returned value.
    });
  }

  didSelectTextFromLibrary(text: string) {
    this.damagesNoteText = this.damagesNoteText ? this.damagesNoteText + text : text;
  }

  saveText(): void {
    this.damagesNoteTextUpdatedEmitter.emit(this.damagesNoteText);
  }
}
