<div class="spare-part-match">
    <lc-bottombar [menu]="bottomMenu"
                  [title]="'I alt: ' + (viewModel.getTotalSavings() | currency:'DKK')"></lc-bottombar>

    <h2>{{getHeading()}}</h2>
    <h3>Leverandører</h3>
    <div class="row">
        <div class="col supplier-status">
            <ng-template #supplierStatus let-item>
                <span *ngIf="isRunning(item.id)" class="badge p2 me-1 text-bg-info">
                    {{item.name}}<i class="ms-1 fa fa-refresh fa-spin"></i>
                </span>
                <span *ngIf="isSuccess(item.id)" class="badge p2 me-1 bg-success">{{item.name}}</span>
                <div *ngIf="isFailed(item.id)" class="d-inline-flex">
                    <ng-template #popContent>
                        <button class="btn-close pull-right" aria-label="Close" (click)="statusPop.close()"></button>
                        <div>{{item.name}}</div>
                        <div>{{item.email}}</div>
                        <div>{{item.phoneNumber}}</div>
                        <div class="d-flex justify-content-between mt-2">
                            <button type="button" class="btn btn-primary me-2" (click)="copyToClipboard(item.email)">Kopier email</button>
                            <button type="button" class="btn btn-primary" (click)="sendEmail(item.email)">Send email</button>
                        </div>
                    </ng-template>
                    <span class="badge p2 me-1 text-bg-warning"
                          [popoverTitle]="getErrorMessage(item.id)" [ngbPopover]="popContent" triggers="mouseenter"
                          [autoClose]="'outside'" #statusPop="ngbPopover"
                    >
                        {{ item.name }}<i class="ms-1 fa fa-refresh clickable" (click)="refreshForSupplier(item)"></i>
                    </span>
                </div>
            </ng-template>
            <ng-container *ngFor="let item of sparePartSuppliers;template: supplierStatus"></ng-container>
        </div>
    </div>
    <div *ngIf="!anyRunning() && viewModel.sparePartsUnfiltered.length === 0">
        <div class="row">
            <div class="col-md-12">
                <h3>Ingen reservedele</h3>
                <div>Der er ikke autoflex reservedele til denne skade</div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">&nbsp;</div>
        </div>
    </div>
    <div *ngIf="viewModel.sparePartsUnfiltered.length > 0">
        <div class="row mb-2">
            <div class="col-md-12">
                <h3>Reservedele</h3>
            </div>
        </div>

        <div class="row d-block no-focus m-0 mb-2">
            <div id="filter" class="card" [class.enabled]="viewModel.isFilterEnabled()" [class.original-offering]="viewModel.readonly">
                <div class="card-header" (click)="viewModel.expandFilter()">
                    <span>{{viewModel.filterText()}}</span>
                    <a class="float-end"><i id="filterButton" class="fa fa-2x fa-filter m-0 p-0" width="20px"></i></a>
                </div>
                <div class="card-block" *ngIf="viewModel.filterExpanded">
                    <div class="row m-0">
                        <div class="col-12 mb-1 mt-1">Stand</div>
                        <div *ngFor="let state of viewModel.getFilterableStates()"
                             class="col-sm-3 col-md-2 filter-item" [class.clickable]="viewModel.isStateIncluded(state)" [class.filter-item-disabled]="!viewModel.isStateIncluded(state)"
                             (click)="viewModel.clickStateFilter(state)">
                            <i class="m-2 fa fa-square-o fa-2x select-icon"
                               [ngClass]="{'fa-check-square-o': viewModel.isStateFiltered(state), 'fa-square-o': !viewModel.isStateFiltered(state)}"></i>
                            <span>{{state | autoflexState }}</span>
                        </div>
                    </div>
                    <hr class="m-0 p-0">
                    <div class="row m-0">
                        <div class="col-6 mb-1 mt-1 filter-item" [class.filter-item-disabled]="!viewModel.isYearAndKmApplicable()" [class.clickable]="viewModel.isYearAndKmApplicable()" (click)="viewModel.clickYearFilter()">
                            <i class="m-2 fa fa-square-o fa-2x select-icon"
                               [ngClass]="{'fa-check-square-o': !viewModel.yearFilterOptions.disabled, 'fa-square-o': viewModel.yearFilterOptions.disabled}"></i>
                            <span>Årgang +/-</span>
                        </div>
                        <div class="col-6 filter-item" [class.filter-item-disabled]="!viewModel.isYearAndKmApplicable()" [class.clickable]="viewModel.isYearAndKmApplicable()" (click)="viewModel.clickKmFilter()">
                            <i class="m-2 fa fa-square-o fa-2x select-icon"
                               [ngClass]="{'fa-check-square-o': !viewModel.kmFilterOptions.disabled, 'fa-square-o': viewModel.kmFilterOptions.disabled}"></i>
                            <span>Kilometer +/-</span>
                        </div>
                    </div>
                    <div class="row m-0 mb-2">
                        <div class="col-4">
                            <ngx-slider [(value)]="viewModel.yearFilterValue" id="yearFilterSlider" [options]="viewModel.yearFilterOptions" (valueChange)="viewModel.filterYearAndKilometer()"></ngx-slider>
                        </div>
                        <div class="col-2"></div>
                        <div class="col-4">
                            <ngx-slider [(value)]="viewModel.kmFilterValue" id="kmFilterSlider" [options]="viewModel.kmFilterOptions" (valueChange)="viewModel.filterYearAndKilometer()"></ngx-slider>
                        </div>
                    </div>
                    <hr class="m-0 p-0">
                    <div class="row m-0">
                        <div class="col-12 mb-1 mt-1">Udbyder</div>
                        <div *ngFor="let supplier of viewModel.getFilterableSuppliers()"
                             class="col-sm-3 col-md-2 filter-item" [class.clickable]="viewModel.isSupplierIncluded(supplier)" [class.filter-item-disabled]="!viewModel.isSupplierIncluded(supplier)"
                             (click)="viewModel.clickSupplierFilter(supplier)">
                            <i class="m-2 fa fa-square-o fa-2x select-icon"
                               [ngClass]="{'fa-check-square-o': viewModel.isSupplierFiltered(supplier), 'fa-square-o': !viewModel.isSupplierFiltered(supplier)}"></i>
                            <span>{{supplier}}</span>
                        </div>
                    </div>
                    <hr class="m-0 p-0">
                    <div class="row m-0">
                        <div class="col-12 mb-1 mt-1">Leverandør</div>
                        <div *ngFor="let thirdParty of viewModel.getFilterableThirdParties()"
                             class="col-sm-3 col-md-2 filter-item" [class.clickable]="viewModel.isThirdPartyIncluded(thirdParty)" [class.filter-item-disabled]="!viewModel.isThirdPartyIncluded(thirdParty)"
                             (click)="viewModel.clickThirdPartyFilter(thirdParty)">
                            <i class="m-2 fa fa-square-o fa-2x select-icon"
                               [ngClass]="{'fa-check-square-o': viewModel.isThirdPartyFiltered(thirdParty), 'fa-square-o': !viewModel.isThirdPartyFiltered(thirdParty)}"></i>
                            <span>{{thirdParty}}</span>
                        </div>
                    </div>
                    <hr class="m-0 p-0">
                    <div class="row m-0">
                        <div class="col-12 mb-1 mt-1">Kvalitet</div>
                        <div *ngFor="let quality of viewModel.getFilterableQualities()"
                             class="col-sm-3 col-md-2 filter-item" [class.clickable]="viewModel.isQualityIncluded(quality)" [class.filter-item-disabled]="!viewModel.isQualityIncluded(quality)"
                             (click)="viewModel.clickQualityFilter(quality)">
                            <i class="m-2 fa fa-square-o fa-2x select-icon"
                               [ngClass]="{'fa-check-square-o': viewModel.isQualityFiltered(quality), 'fa-square-o': !viewModel.isQualityFiltered(quality)}"></i>
                            <span>{{quality}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row d-none d-md-block no-focus">
            <div class="col-md-12">
                <div class="table-responsive overflow-y-scroll parts-table">
                    <table class="table table-hover">
                        <thead>
                        <tr>
                            <th class="text-start d-none d-sm-table-cell"></th>
                            <th class="text-start d-none d-sm-table-cell"></th>
                            <th class="text-start d-none d-sm-table-cell clickable"
                                (click)="viewModel.sortBy('guideNumber')">
                                <i class="fa" [ngClass]="viewModel.getSortClass('guideNumber')"></i>
                                Ledenr
                            </th>
                            <th class="text-start d-none d-sm-table-cell clickable"
                                (click)="viewModel.sortBy('description')">
                                <i class="fa" [ngClass]="viewModel.getSortClass('description')"></i>
                                Autotaks tekst
                            </th>
                            <th class="text-start d-none d-sm-table-cell clickable"
                                (click)="viewModel.sortBy('oemNumber')">
                                <i class="fa" [ngClass]="viewModel.getSortClass('oemNumber')"></i>
                                OE nr.
                            </th>
                            <th class="text-start d-none d-sm-table-cell clickable"
                                (click)="viewModel.sortBy('indicativePrice')">
                                <i class="fa" [ngClass]="viewModel.getSortClass('indicativePrice')"></i>
                                Vejl. pris
                            </th>
                            <th class="text-start d-none d-sm-table-cell clickable"
                                (click)="viewModel.sortBy('supplierName')">
                                <i class="fa" [ngClass]="viewModel.getSortClass('supplierName')"></i>
                                Udbyder
                            </th>
                            <th class="text-start d-none d-sm-table-cell clickable"
                                (click)="viewModel.sortBy('supplierDescription')">
                                <i class="fa" [ngClass]="viewModel.getSortClass('supplierDescription')"></i>
                                Alternativ tekst
                            </th>
                            <th class="text-start d-none d-sm-table-cell clickable" (click)="viewModel.sortBy('price')">
                                <i class="fa" [ngClass]="viewModel.getSortClass('price')"></i>
                                Alt. pris
                            </th>
                            <th class="text-start d-none d-sm-table-cell clickable"
                                (click)="viewModel.sortBy('supplierReference')">
                                <i class="fa" [ngClass]="viewModel.getSortClass('supplierReference')"></i>
                                Resnr.
                            </th>
                            <th class="text-start d-none d-sm-table-cell clickable" (click)="viewModel.sortBy('state')">
                                <i class="fa" [ngClass]="viewModel.getSortClass('state')"></i>
                                Stand
                            </th>
                            <th class="text-start d-none d-sm-table-cell clickable"
                                (click)="viewModel.sortBy('stateAndQuality')">
                                <i class="fa" [ngClass]="viewModel.getSortClass('stateAndQuality')"></i>
                                Kvalitet
                            </th>
                            <th class="text-start d-none d-sm-table-cell clickable" (click)="viewModel.sortBy('km')">
                                <i class="fa" [ngClass]="viewModel.getSortClass('km')"></i>
                                km
                            </th>
                            <th class="text-start d-none d-sm-table-cell clickable" (click)="viewModel.sortBy('year')">
                                <i class="fa" [ngClass]="viewModel.getSortClass('year')"></i>
                                Årgang
                            </th>
                            <th class="text-start d-none d-sm-table-cell clickable" (click)="viewModel.sortBy('color')">
                                <i class="fa" [ngClass]="viewModel.getSortClass('color')"></i>
                                Farve
                            </th>
                            <th class="text-start d-none d-sm-table-cell clickable"
                                (click)="viewModel.sortBy('thirdPartySupplier')">
                                <i class="fa" [ngClass]="viewModel.getSortClass('thirdPartySupplier')"></i>
                                Leverandør
                            </th>
                            <th class="text-start d-none d-sm-table-cell clickable" (click)="viewModel.sortBy('make')">
                                <i class="fa" [ngClass]="viewModel.getSortClass('make')"></i>
                                Fabrikant
                            </th>
                            <th class="text-start d-none d-sm-table-cell clickable"
                                (click)="viewModel.sortBy('deliveryTerms')">
                                <i class="fa" [ngClass]="viewModel.getSortClass('deliveryTerms')"></i>
                                Lev.bet.
                            </th>
                            <th class="text-start d-none d-sm-table-cell clickable" (click)="viewModel.sortBy('href')">
                                <i class="fa" [ngClass]="viewModel.getSortClass('href')"></i>
                                Info
                            </th>
                        </tr>
                        </thead>
                        <ng-container *ngFor="let autoflexRow of viewModel.filteredAndSorted">
                            <tr *ngIf="autoflexRow.data.visible" class="autoflex-top-row" [class.original-offering]="viewModel.readonly"
                                [class.selection-blocked]="autoflexRow.data.selectionBlocked">
                                <td>
                                    <i class="mx-0 px-0 fa fa-2x select-icon clickable"
                                       *ngIf="autoflexRow.hasMultipleData()"
                                       [ngClass]="viewModel.getSelectionClass(autoflexRow)"
                                       (click)="viewModel.clickedRow(autoflexRow)"></i>
                                </td>
                                <td>
                                    <i class="mx-0 px-1 fa fa-2x select-icon" *ngIf="!autoflexRow.hasMultipleData()"
                                       (click)="viewModel.clickedRow(autoflexRow)"
                                       [ngClass]="{'fa-check-square-o': autoflexRow.data.selected,
                                       'fa-square-o': !autoflexRow.data.selected,
                                       'clickable': !autoflexRow.data.selectionBlocked && !viewModel.readonly,
                                       'bg-success' : autoflexRow.data.bookAcknowledge === 'ACCEPTED',
                                       'bg-danger' : autoflexRow.data.bookAcknowledge === 'REJECTED',
                                       'selection-blocked' : viewModel.readonly}"></i>
                                </td>
                                <td>{{autoflexRow.data.guideNumber}}</td>
                                <td>{{autoflexRow.data.description}}</td>
                                <td><lc-truncate-display [value]="autoflexRow.data.oemNumber"></lc-truncate-display></td>
                                <td>{{autoflexRow.data.indicativePrice | number: '1.2-2'}}</td>
                                <td><lc-truncate-display [value]="autoflexRow.data.supplierName"></lc-truncate-display></td>
                                <td>{{autoflexRow.data.supplierDescription}}</td>
                                <td>{{autoflexRow.data.price | number: '1.2-2'}}</td>
                                <td><lc-truncate-display [value]="autoflexRow.data.supplierReference"></lc-truncate-display></td>
                                <td>{{autoflexRow.data.state | autoflexState}}</td>
                                <td><div class="quality" [ngbTooltip]="autoflexRow.data.qualityDesignationDescription" placement="end">{{autoflexRow.data.quality}}</div></td>
                                <td>{{autoflexRow.data.km}}</td>
                                <td>{{autoflexRow.data.year}}</td>
                                <td>{{autoflexRow.data.color}}</td>
                                <td><lc-truncate-display [value]="autoflexRow.data.thirdPartySupplier"></lc-truncate-display></td>
                                <td><lc-truncate-display [value]="autoflexRow.data.make"></lc-truncate-display></td>
                                <td>{{autoflexRow.data | autoflexDeliveryTerm}}</td>
                                <td><a *ngIf="!!autoflexRow.data.href" href="{{autoflexRow.data.href}}" target="_blank">
                                    <i class="fa fa-2x fa-external-link"></i>
                                </a></td>
                            </tr>
                            <ng-container *ngIf="autoflexRow.hasMultipleData() && autoflexRow.data.selected">
                                <ng-container *ngFor="let autoflexSubRow of autoflexRow.sameGuideNumber">
                                    <tr *ngIf="autoflexSubRow.visible" class="autoflex-row"
                                        [class.selection-blocked]="autoflexSubRow.selectionBlocked">
                                        <td></td>
                                        <td><i class="mx-0 px-1 fa fa-square-o fa-2x select-icon"
                                               (click)="viewModel.clickedSubRow(autoflexRow, autoflexSubRow)"
                                               [ngClass]="{'fa-check-square-o': autoflexSubRow.selected,
                                               'fa-square-o': !autoflexSubRow.selected,
                                               'clickable': !autoflexSubRow.selectionBlocked && !viewModel.readonly,
                                               'bg-success' : autoflexSubRow.bookAcknowledge === 'ACCEPTED',
                                               'bg-danger' : autoflexSubRow.bookAcknowledge === 'REJECTED',
                                               'selection-blocked' : viewModel.readonly}">
                                        </i></td>
                                        <td>{{autoflexSubRow.guideNumber}}</td>
                                        <td>{{autoflexSubRow.description}}</td>
                                        <td><lc-truncate-display [value]="autoflexSubRow.oemNumber"></lc-truncate-display></td>
                                        <td>{{autoflexSubRow.indicativePrice | number: '1.2-2'}}</td>
                                        <td><lc-truncate-display [value]="autoflexSubRow.supplierName"></lc-truncate-display></td>
                                        <td>{{autoflexSubRow.supplierDescription}}</td>
                                        <td>{{autoflexSubRow.price | number: '1.2-2'}}</td>
                                        <td><lc-truncate-display [value]="autoflexSubRow.supplierReference"></lc-truncate-display></td>
                                        <td>{{autoflexSubRow.state | autoflexState}}</td>
                                        <td><div class="quality" [ngbTooltip]="autoflexSubRow.qualityDesignationDescription" placement="end">{{autoflexSubRow.quality}}</div></td>
                                        <td>{{autoflexSubRow.km}}</td>
                                        <td>{{autoflexSubRow.year}}</td>
                                        <td>{{autoflexSubRow.color}}</td>
                                        <td><lc-truncate-display [value]="autoflexSubRow.thirdPartySupplier"></lc-truncate-display></td>
                                        <td><lc-truncate-display [value]="autoflexSubRow.make"></lc-truncate-display></td>
                                        <td>{{autoflexSubRow | autoflexDeliveryTerm}}</td>
                                        <td><a *ngIf="!!autoflexSubRow.href" href="{{autoflexSubRow.href}}"
                                               target="_blank">
                                            <i class="fa fa-2x fa-external-link"></i>
                                        </a></td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </table>
                </div>
            </div>
        </div>

        <div class="row d-md-none">
            <!--<div class="row">-->
            <ng-container *ngFor="let autoflexRow of viewModel.filteredAndSorted">
                <div class="card ms-3 me-3 mb-3" style="width: 100%" *ngIf="autoflexRow.data.visible"
                     [ngClass]="{'selected': autoflexRow.hasMultipleData() ? autoflexRow.hasSubSelection() : autoflexRow.data.selected}">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-2">{{autoflexRow.data.guideNumber}}</div>
                            <div class="col-4">{{autoflexRow.data.description}}</div>
                            <div class="col-3">{{autoflexRow.data.oemNumber}}</div>
                            <div class="col-3 text-end"
                                 style="white-space: nowrap">{{autoflexRow.data.indicativePrice}}</div>
                        </div>
                    </div>
                    <div class="card-body pt-0 pb-0">
                        <ng-container *ngIf="!autoflexRow.hasMultipleData()">
                            <div class="row clickable" (click)="viewModel.clickedRow(autoflexRow)">
                                <div class="col-2 p-1">
                                    <i class="m-2 fa fa-square-o fa-2x select-icon"
                                       [ngClass]="{'fa-check-square-o': autoflexRow.data.selected, 'fa-square-o': !autoflexRow.data.selected}"></i>
                                </div>
                                <div class="col-4 p-3">{{autoflexRow.data.supplierDescription}}</div>
                                <div class="col-3 p-3">{{autoflexRow.data.supplierReference}}</div>
                                <div class="col-3 p-3 text-end"
                                     style="white-space: nowrap">{{autoflexRow.data.price}}</div>
                            </div>
                            <div class="row clickable" (click)="viewModel.clickedRow(autoflexRow)">
                                <div class="col-2">&nbsp;</div>
                                <div class="col-4 p-3">{{autoflexRow.data.thirdPartySupplier}}</div>
                                <div class="col-3 p-3">{{autoflexRow.data.quality}}</div>
                                <div class="col-3">&nbsp;</div>
                            </div>
                            <div class="row clickable" (click)="viewModel.clickedRow(autoflexRow)">
                                <div class="col-2"></div>
                                <div class="col-4 p-3">{{autoflexRow.data.color}}</div>
                                <div class="col-3 p-3">{{autoflexRow.data | autoflexDeliveryTerm}}</div>
                                <div class="col-3"><a *ngIf="!!autoflexRow.data.href"
                                                      href="{{autoflexRow.data.href}}">{{autoflexRow.data.href}}</a>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="autoflexRow.hasMultipleData()">
                            <ng-container *ngIf="autoflexRow.hasSubSelection()">
                                <div class="row clickable" (click)="viewModel.clickedRow(autoflexRow)">
                                    <div class="col-2 p-1">
                                        <i class="m-2 fa fa-plus-square-o fa-2x select-icon multi-selected"></i>
                                    </div>
                                    <div class="col-4 p-3">{{autoflexRow.getSelectedRow().supplierDescription}}</div>
                                    <div class="col-3 p-3">{{autoflexRow.getSelectedRow().supplierReference}}</div>
                                    <div class="col-3 p-3 text-end" style="white-space: nowrap">
                                        {{autoflexRow.getSelectedRow().price}}
                                    </div>
                                </div>
                                <div class="row clickable" (click)="viewModel.clickedRow(autoflexRow)">
                                    <div class="col-2">&nbsp;</div>
                                    <div class="col-4 p-3">{{autoflexRow.getSelectedRow().thirdPartySupplier}}</div>
                                    <div class="col-3 p-3">{{autoflexRow.getSelectedRow().quality}}</div>
                                    <div class="col-3">&nbsp;</div>
                                </div>

                            </ng-container>
                            <ng-container *ngIf="!autoflexRow.hasSubSelection()">
                                <div class="row clickable" (click)="viewModel.clickedRow(autoflexRow)">
                                    <div class="col-2 p-1">
                                        <i class="m-2 fa fa-plus-square-o fa-2x select-icon"></i>
                                    </div>
                                    <div class="col-10 p-3">Der findes alternative autoflex dele</div>
                                </div>
                                <div class="row clickable" (click)="viewModel.clickedRow(autoflexRow)">
                                    <div class="col-2">&nbsp;</div>
                                    <div class="col-10 p-3">Ingen valgt</div>
                                </div>
                            </ng-container>
                            <!-- show sub rows -->
                            <ng-container *ngIf="autoflexRow.data.selected">
                                <ng-container *ngFor="let autoflexSubRow of autoflexRow.sameGuideNumber">
                                    <div class="row autoflex-subrow clickable"
                                         (click)="viewModel.clickedSubRow(autoflexRow, autoflexSubRow)"
                                         *ngIf="autoflexSubRow.visible"
                                         [ngClass]="{'selected' : autoflexSubRow.selected}">
                                        <div class="col-2 p-1"><i class="m-2 fa fa-square-o fa-2x select-icon"
                                                                  [ngClass]="{'fa-check-square-o': autoflexSubRow.selected, 'fa-square-o': !autoflexSubRow.selected}"></i>
                                        </div>
                                        <div class="col-4 p-3">{{autoflexSubRow.supplierDescription}}</div>
                                        <div class="col-3 p-3">{{autoflexSubRow.supplierReference}}</div>
                                        <div class="col-3 p-3 text-end" style="white-space: nowrap">
                                            {{autoflexSubRow.price}}
                                        </div>
                                    </div>
                                    <div class="row autoflex-subrow autoflex-subrow-border clickable"
                                         (click)="viewModel.clickedSubRow(autoflexRow, autoflexSubRow)"
                                         *ngIf="autoflexSubRow.visible"
                                         [ngClass]="{'selected' : autoflexSubRow.selected}">
                                        <div class="col-2">&nbsp;</div>
                                        <div class="col-4 p-3">{{autoflexSubRow.thirdPartySupplier}}</div>
                                        <div class="col-3 p-3">{{autoflexSubRow.quality}}</div>
                                        <div class="col-3">&nbsp;</div>
                                    </div>
                                    <div class="row autoflex-subrow autoflex-subrow-border clickable"
                                         (click)="viewModel.clickedSubRow(autoflexRow, autoflexSubRow)"
                                         *ngIf="autoflexSubRow.visible" [class.selected]="autoflexSubRow.selected"
                                    >
                                        <div class="col-2"></div>
                                        <div class="col-4 p-3">{{autoflexSubRow.color}}</div>
                                        <div class="col-3 p-3">{{autoflexSubRow | autoflexDeliveryTerm}}</div>
                                        <div class="col-3"><a *ngIf="!!autoflexSubRow.href"
                                                              href="{{autoflexSubRow.href}}">{{autoflexSubRow.href}}</a>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </ng-container>

        </div>

        <div class="row">
            <div class="col-12 dynamic-padding-bottom">&nbsp;</div>
        </div>

    </div>
</div>
