import {isNullOrUndefined} from '../../utils/object-utils';

export class ContextMenu {
  public menuItems: ContextMenuItem[] = [];

  public addItem(menuItem: ContextMenuItem): ContextMenuItem {
    this.menuItems.push(menuItem);
    return menuItem;
  }

  public updateMenuHighlights(scrolltomenuItem: ContextMenuItem): void {
    if (isNullOrUndefined(scrolltomenuItem)) {
      scrolltomenuItem = this.menuItems[0];
    }
    this.menuItems.map(menuItem => {
      menuItem.highlighted = (menuItem === scrolltomenuItem);
    });
  }
}

export class ContextMenuItem {
  public highlighted = false;
  public visibleHeight;
  public actualHeight;

  constructor(public fontawesomeIconName: string, public title: string, public anchorName: string, private didClickMenuCallback: Function, private invalidCallback?: Function) {
  }

  public didClickMenuItem(): void {
    if (!isNullOrUndefined(this.didClickMenuCallback)) {
      this.didClickMenuCallback(this);
    }
  }

  public isInvalid(): boolean {
    return !isNullOrUndefined(this.invalidCallback) ? this.invalidCallback() : false;
  }

  public isHighlighted(): boolean {
    return this.highlighted;
  }
}
