<div class="modal-header pre-size header-style" style="text-align: center">
    <h3 class="text-truncate">
        Nye Broadcasts
    </h3>
    <div aria-label="Close" data-dismiss="modal" class="btn-close clickable" (click)="cancel()"></div>
</div>

<div class="modal-content">
    <div class="modal-body modal-body-style">
        <div class="container-fluid">
            <ng-container *ngIf="!broadcasts">
                <div class="text-center">
                    <i class="fa fa-circle-o-notch fa-spin fop-page-spinner"></i>
                </div>
            </ng-container>
            <ng-container *ngIf="broadcasts">
                <lc-broadcast-list [broadcasts]="broadcasts"
                                   (deleteBroadcastEmitter)="deleteBroadcast($event)"></lc-broadcast-list>
            </ng-container>
        </div>
    </div>
</div>
