<h1 class="">Indstillinger</h1>
<div class="no-focus" tabindex="0" [lcAutofocus]>
    <div class="row">
        <div class="col-12">
            <ngb-accordion [closeOthers]="true">
                <ngb-panel *lcHasAnyAuthority="['ROLE_Taks', 'ROLE_VK']">
                    <ng-template ngbPanelTitle>
                        <i class="fa fa-2x fa-newspaper-o at-icon"></i><span class="ps-3 settings-header-label">Broadcast</span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <lc-settings-broadcast></lc-settings-broadcast>
                    </ng-template>
                </ngb-panel>
                <ngb-panel *lcHasAnyAuthority="['ROLE_Taks', 'ROLE_VK']">
                    <ng-template ngbPanelTitle>
                        <i class="fa fa-2x fa-cogs at-icon"></i><span
                        class="ps-3 settings-header-label">Funktioner</span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <lc-settings-function></lc-settings-function>
                    </ng-template>
                </ngb-panel>
                <ngb-panel *lcHasAnyAuthority="'ROLE_Taks'">
                    <ng-template ngbPanelTitle>
                        <i class="fa fa-2x fa-male at-icon"></i><span
                        class="ps-3 settings-header-label">Afløsning</span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <lc-settings-postbox></lc-settings-postbox>
                    </ng-template>
                </ngb-panel>
                <ngb-panel *lcHasAnyAuthority="['ROLE_Taks', 'ROLE_VK']">
                    <ng-template ngbPanelTitle>
                        <i class="fa fa-2x fa-support at-icon"></i><span
                        class="ps-3 settings-header-label">Support</span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <lc-settings-support></lc-settings-support>
                    </ng-template>
                </ngb-panel>
                <ngb-panel *lcHasAnyAuthority="'ROLE_Taks'">
                    <ng-template ngbPanelTitle>
                        <i class="fa fa-2x fa-archive at-icon"></i><span
                        class="ps-3 settings-header-label">Diverse</span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <lc-settings-miscellaneous></lc-settings-miscellaneous>
                    </ng-template>
                </ngb-panel>
                <ngb-panel *lcHasAnyAuthority="'ROLE_Taks'">
                    <ng-template ngbPanelTitle>
                        <i class="fa fa-2x fa-clipboard at-icon"></i><span class="ps-3 settings-header-label">Følgeseddel</span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <lc-settings-deliverynote></lc-settings-deliverynote>
                    </ng-template>
                </ngb-panel>

                <ngb-panel *lcHasAnyAuthority="['ROLE_Taks', 'ROLE_VK']">
                    <ng-template ngbPanelTitle>
                        <i class="fa fa-2x fa-print at-icon"></i><span
                        class="ps-3 settings-header-label">Udskriftlayout</span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <lc-settings-printlayout></lc-settings-printlayout>
                    </ng-template>
                </ngb-panel>

                <ngb-panel>
                    <ng-template ngbPanelTitle>
                        <i class="fa fa-2x fa-info at-icon"></i><span class="ps-3 settings-header-label">Version</span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <a class="btn btn-primary ms-4" [routerLink]="['/about']"><span class="fa fa-lg fa-info-circle"></span> Flyttet til Om Autotaks</a>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>
    </div>
</div>

