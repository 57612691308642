import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {UserDTO} from '../../dto/user-dto.model';
import {Subject} from 'rxjs';
import {SearchType, SearchTypes} from './search-types.model';
import {ReportKey} from '../../../search/reportkey.model';
import {SearchTypeEnum} from './search-type.enum';
import {takeUntil} from 'rxjs/operators';
import {AssessorCasesSearchProps} from './assessor-cases-search-props.model';
import {StringUtils} from '../../utils/string-utils';
import {isNullOrUndefined} from '../../utils/object-utils';

@Component({
  selector: 'lc-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: [],
  providers: []
})
export class SearchInputComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  searchTypes: SearchTypes = new SearchTypes();
  chosenSearchType;
  showSearchOptions = false;

  @Input() users: UserDTO[];
  @Input() orgNr = '';
  @Input() tvNr = '';
  @ViewChild('f', {static: true}) public cform: NgForm;

  @Output() onChangeSearchType = new EventEmitter<SearchType>();
  @Output() onSearchByKey = new EventEmitter<string>();
  @Output() onSearchByVkKey = new EventEmitter<string>();
  @Output() onSearchByRegNr = new EventEmitter<string>();
  @Output() onSearchByStelnummer = new EventEmitter<string>();
  @Output() onSearchBySkadenummer = new EventEmitter<string>();
  @Output() onSearchAssessorCases = new EventEmitter<AssessorCasesSearchProps>();
  @Output() onFormValid = new EventEmitter<boolean>();
  @Output() onDraftsOnlyChanged = new EventEmitter<boolean>();

  @Input() rappNr: ReportKey;
  @Input() vkNr: ReportKey;
  @Input() regnr: string;
  @Input() stelnummer: string;
  @Input() skadenummer: string;
  @Input() searchType: SearchType;
  @Input() searchAssessorCases: AssessorCasesSearchProps;

  @Input() defaultAssessor: UserDTO;
  selectedUser: UserDTO;

  onlyDrafts = false;

  ngOnInit(): void {
    this.cform.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      const formValid = this.cform && this.cform.valid;
      this.onFormValid.emit(formValid);
    });
    this.selectedUser = this.defaultAssessor;
    if (this.searchAssessorCases) {
      this.onAssessorChosen();
    }
    if (this.searchType) {
      this.chosenSearchType = this.searchTypes.getTypes().filter( type => type.getCode() === this.searchType.getCode())[0];
    } else {
      this.chosenSearchType = this.searchTypes.getTypes()[0];
    }
  }

  search(): void {
    if (this.cform.form.valid) {
      switch (this.chosenSearchType.getCode()) {
        case SearchTypeEnum.RapportNr:
          this.onSearchByKey.emit(this.rappNr.getReportWidePk());
          break;
        case SearchTypeEnum.VKNr:
          this.onSearchByVkKey.emit(this.vkNr.getReportWidePk());
          break;
        case SearchTypeEnum.Regnr:
          this.onSearchByRegNr.emit(this.regnr);
          break;
        case SearchTypeEnum.Stelnummer:
          this.onSearchByStelnummer.emit(this.stelnummer);
          break;
        case SearchTypeEnum.Skadenummer:
          this.onSearchBySkadenummer.emit(this.skadenummer);
          break;
        case SearchTypeEnum.AssessorsCases:
          this.onSearchAssessorCases.emit(this.searchAssessorCases);
          break;
      }
    }
  }

  isSearchTypeAssessorCases(): boolean {
    return (this.chosenSearchType.getCode() === SearchTypeEnum.AssessorsCases);
  }

  isSearchTypeTaksRapp(): boolean {
    return (this.chosenSearchType.getCode() === SearchTypeEnum.RapportNr);
  }

  isSearchTypeProformaInvoiceNr(): boolean {
    return (this.chosenSearchType.getCode() === SearchTypeEnum.VKNr);
  }

  isSearchTypeRegNr(): boolean {
    return (this.chosenSearchType.getCode() === SearchTypeEnum.Regnr);
  }

  isSearchTypeStelnummer(): boolean {
    return (this.chosenSearchType.getCode() === SearchTypeEnum.Stelnummer);
  }

  isSearchTypeSkadenummer(): boolean {
    return (this.chosenSearchType.getCode() === SearchTypeEnum.Skadenummer);
  }

  onSearchTypeChanged(): void {
    this.onChangeSearchType.emit(this.chosenSearchType);
    this.onlyDrafts = false;
    this.emitOnDraftsOnlyChanged();
  }

  assessorCaseSearchPropIsRequired(name: string): boolean {
    const dateValid = !isNullOrUndefined(this.searchAssessorCases.ngFromDate) && !isNullOrUndefined(this.searchAssessorCases.ngFromDate);
    const vkNummerValid = StringUtils.isNotEmpty(this.searchAssessorCases.vkNummer);
    const fabrikatValid = StringUtils.isNotEmpty(this.searchAssessorCases.fabrikat);
    const modelValid = StringUtils.isNotEmpty(this.searchAssessorCases.model);
    const assessorValid = !isNullOrUndefined(this.selectedUser) && !isNullOrUndefined(this.selectedUser.atClientNumber);
    const regnrValid = StringUtils.isNotEmpty(this.searchAssessorCases.regnr);
    const vinValid = StringUtils.isNotEmpty(this.searchAssessorCases.vin);

    if (dateValid || vkNummerValid || fabrikatValid || modelValid || assessorValid || regnrValid || vinValid) {
      switch (name) {
        case 'dateFrom':
        case 'dateTo':
          return dateValid;
        case 'assessorCasesWorkshop':
          return vkNummerValid;
        case 'assessorCasesFabrikat':
          return fabrikatValid;
        case 'assessorCasesModel':
          return modelValid;
        case 'assessorCasesAssessorNr':
          return assessorValid;
        case 'assessorCasesRegnr':
          return regnrValid;
        case 'assessorCasesVin':
          return vinValid;
        default:
          return false;
      }
    } else {
      return true;
    }
  }

  onAssessorChosen(): void {
    this.searchAssessorCases.taksUser = this.selectedUser;
  }

  toggleSearchOptions(): void {
    this.showSearchOptions = !this.showSearchOptions;
  }

  emitOnDraftsOnlyChanged(): void {
    this.onDraftsOnlyChanged.emit(this.onlyDrafts);
  }

  getZeroPaddedAssessorNr(assessornr: number): string {
    return ('00000' + assessornr).slice(-5);
  }

  enableOnlyDraftCheckBox(): boolean {
    return this.chosenSearchType.getCode() === SearchTypeEnum.AssessorsCases
      || this.chosenSearchType.getCode() === SearchTypeEnum.Regnr
      || this.chosenSearchType.getCode() === SearchTypeEnum.Stelnummer
      || this.chosenSearchType.getCode() === SearchTypeEnum.Skadenummer;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
