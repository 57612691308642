import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {TextLibraryService} from '../../service/text-library.service';
import {TextLibraryDTO} from '../../dto/text-library-dto';
import {TextLibraryEntryDTO} from '../../dto/text-library-entry-dto';
import {TextLibraryTypeEnum} from '../../service/text-library-type.enum';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {RapportType} from '../../model/rapport-type.model';

@Component({
  selector: 'lc-text-library',
  templateUrl: './text-library.component.html',
  styleUrls: ['text-library.scss']
})
export class TextLibraryComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  @Input() type: TextLibraryTypeEnum;
  @Input() reportType: RapportType;
  @Input() editable: boolean;
  @Input() textLibraryService: TextLibraryService;
  @Output() selectedText: EventEmitter<string> = new EventEmitter<string>();
  textLibrary: TextLibraryDTO;
  newEntryText = '';
  showCreate = false;
  updatingEntry: TextLibraryEntryDTO;

  ngOnInit(): void {
    this.textLibraryService.getTextLibrary(this.type)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(tl => {
        this.textLibrary = tl;
        if (this.reportType) {
          this.textLibrary.entries = this.textLibrary.entries
            .filter(entry => entry.reportTypes.length === 0 || !!entry.reportTypes.find(rt => rt === this.reportType.type));
        }
      });
  }

  selectEntry(textLibraryEntry: TextLibraryEntryDTO): void {
    this.selectedText.emit(textLibraryEntry.text);
  }

  createNewEntry(): void {
    this.textLibraryService.createTextLibraryEntry(this.type, this.newEntryText, [])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(createdTextLibraryEntry => {
        this.textLibrary.entries.push(createdTextLibraryEntry);
        this.clearNewEntry();
      });
  }

  clearNewEntry(): void {
    this.newEntryText = null;
    this.showCreate = false;
  }

  deleteEntry(textLibraryEntry: TextLibraryEntryDTO): void {
    this.textLibraryService.deleteTextLibraryEntry(textLibraryEntry.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        const textLibraryEntryIndex = this.textLibrary.entries.indexOf(textLibraryEntry);
        this.textLibrary.entries.splice(textLibraryEntryIndex, 1);
      });
  }

  setUpdatingEntry(textLibraryEntry: TextLibraryEntryDTO): void {
    this.updatingEntry = new TextLibraryEntryDTO();
    this.updatingEntry.id = textLibraryEntry.id;
    this.updatingEntry.text = textLibraryEntry.text;
  }

  updateEntry(): void {
    this.textLibraryService.updateTextLibraryEntry(this.updatingEntry.id, this.updatingEntry.text, [])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(updatedTextLibraryEntry => {
        const textLibraryEntryIndex = this.textLibrary.entries.findIndex(e => e.id === updatedTextLibraryEntry.id);
        this.textLibrary.entries[textLibraryEntryIndex].text = updatedTextLibraryEntry.text;
        this.updatingEntry = null;
      });
  }

  textValid(text: string): boolean {
    return text != null && text.length < 4000;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
