import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {SharedCommonModule} from 'app/shared/shared-common.module';
import {SharedModule} from 'app/shared/shared.module';
import {PrintComponent} from 'app/shared/modals/print/print.component';

@NgModule({
  imports: [
    SharedModule,
    SharedCommonModule
  ],
  declarations: [
    PrintComponent,
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PrintModule {
}
