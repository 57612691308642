import {Component, HostListener, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ClientStateDetails} from '../../../../index';
import {NgForm} from '@angular/forms';
import {FormSaver} from '../../../../../shared/service/core/form-saver.service';
import {PositionNode, SkavuPositionService} from '../../../../service/skavu-position-service';
import {SkavuPositionValue} from '../../../../model/skavu-position-value.model';
import {ExtraTextUI} from './extra-text-ui.model';
import {Specialkode} from '../../../../../shared/model/specialkode.model';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {isNullOrUndefined} from '../../../../../shared/utils/object-utils';

@Component({
  selector: 'lc-draft-skavu-positions',
  templateUrl: './draft-skavu-positions.component.html',
  styleUrls: ['../../../../../shared/ui/multiselect/multiselect-item.scss', '../../../draft-common.scss']
})

export class DraftSkavuPositionsComponent implements OnInit, OnDestroy {
  static CUSTOM_POSITION_NO = '99999';
  private unsubscribe$ = new Subject<void>();

  extraTexts: ExtraTextUI[];
  expandedValue: SkavuPositionValue;
  positions: PositionNode[];
  position: PositionNode;
  oldPosition: PositionNode;
  subPositions: PositionNode[];
  subPosition: PositionNode;
  oldSubPosition: PositionNode;
  subSubPositions: PositionNode[];
  unmatchedSelectedSkavuPositionValues: SkavuPositionValue[] = [];
  filter = false;
  showLak = false;
  oldFilter = false;
  showAllSelected = false;
  _listeTypeId: number;
  _lak: number = undefined;
  @Input() formId: string;
  @Input() details: ClientStateDetails;
  @ViewChild('f', {static: true}) public cform: NgForm;

  @Input() set listeTypeId(listeTypeid: number) {
    if (this._listeTypeId !== listeTypeid) {
      this._listeTypeId = listeTypeid;
      this.initPositions(listeTypeid);
    }
  }

  @HostListener('document:click', ['$event']) clickedOutside($event): void {
    if (this.expandedValue) {
      this.hideExtraTextEditor()
    }
  }

  // When extratexts are expanded, keyboard can be used to select.
  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.hideExtraTextEditor();
    }
    if (isNullOrUndefined(this.extraTexts)) {
      return;
    }
    const extraText = this.extraTexts.filter(e => e.code === event.key.toUpperCase()).shift();
    if (!isNullOrUndefined(extraText)) {
      if (extraText.selected) {
        extraText.selected = false;
      } else {
        if (!this.disableExtratext(extraText.code)) {
          extraText.selected = true;
        }
      }
    }
  }

  private getLakkode(createIfNotFound: boolean): Specialkode {
    let lakKode = this.details.specialkoder.filter(f => f.kode === Specialkode.SkavuLakPris).shift();
    if (createIfNotFound && isNullOrUndefined(lakKode)) {
      lakKode = new Specialkode(Specialkode.SkavuLakPris, null, null, false);
      this.details.specialkoder.push(lakKode);
    }
    return lakKode;
  }

  get lak(): number {
    if (typeof this._lak === 'undefined') {
      const lakKode = this.getLakkode(false);
      if (isNullOrUndefined(lakKode)) {
        this._lak = null;
      } else {
        this._lak = lakKode.value;
      }
    }
    return this._lak;
  }

  set lak(value: number) {
    this._lak = value;
    const lakKode = this.getLakkode(true);
    lakKode.value = value;
  }

  constructor(private formSaver: FormSaver,
              private skavuPositionService: SkavuPositionService) {

  }

  clickedInside($event): void {
    $event.stopPropagation();  // <- that will stop propagation on lower layers
  }

  ngOnInit(): void {
    this.formSaver.registerForm(this.formId, this.cform);
    this.skavuPositionService.getExtraTexts().pipe(takeUntil(this.unsubscribe$)).subscribe();
  }

  private saveState(): void {
    this.oldPosition = this.position;
    this.oldSubPosition = this.subPosition;
    this.oldFilter = this.filter;
  }

  private restoreState(): void {
    this.filter = this.oldFilter;
    this.selectPositions(this.oldPosition, this.oldSubPosition);
  }

  hideExtraTextEditor(): void {
    this.expandedValue = null;
    this.extraTexts = null;
  }

  onShowSelectedChange(isLak: boolean, selected: boolean): void {
    this.hideExtraTextEditor();
    if (selected) {
      if (!this.showAllSelected || !this.showLak) {
        this.saveState();
        this.selectPositions(this.positions[0], this.positions[0].children[0]);
        this.filter = true;
      }
      if (isLak) {
        this.showAllSelected = false;
      } else {
        this.showLak = false;
      }

    } else {
      this.restoreState();
    }
  }

  findValue(pn: PositionNode): SkavuPositionValue {
    return this.details.skavu.positionValues.filter(pv => pv.nr === pn.ledenr).shift();
  }

  onFilterChange(): void {
    this.reRenderSelectedPositions();
  }

  private reRenderSelectedPositions(): void {
    this.hideExtraTextEditor();
    this.selectPositions(this.positions[0], null);
  }

  doShow(pv: SkavuPositionValue): boolean {
    if (this.showLak && pv.extraTexts.filter(e => e === 'L').length === 0) {
      return false;
    }
    return !this.filter || pv && pv.checked;
  }

  onCreateNew(pn: PositionNode): SkavuPositionValue {
    this.hideExtraTextEditor();
    const selectedPositionValues = this.details.skavu.positionValues;
    if (isNullOrUndefined(pn)) {
      const newValue = new SkavuPositionValue();
      newValue.position = this.findNextSkavuPosition(selectedPositionValues);
      newValue.nr = '*****';
      newValue.workText = '';
      newValue.checked = true;
      selectedPositionValues.push(newValue);
      this.reRenderSelectedPositions();
    } else {
      const newValue = new SkavuPositionValue();
      newValue.position = this.findNextSkavuPosition(pn.values);
      newValue.nr = pn.ledenr;
      newValue.workText = newValue.position === 1 ? pn.text : '';
      newValue.checked = true;
      pn.values.push(newValue);
      selectedPositionValues.push(newValue);
      return newValue;
    }
  }

  hasAnyCheckedValues(pn: PositionNode): boolean {
    if (isNullOrUndefined(pn)) {
      return false;
    } else {
      return pn.values.filter(pv => pv.checked).length > 0;
    }
  }

  private findNextSkavuPosition(positionValues: SkavuPositionValue[]): number {
    let result = 1;
    if (!isNullOrUndefined(positionValues) && positionValues.length > 0) {
      result = Math.max.apply(Math, positionValues.map(value => value.position)) + 1;
    }
    return result;
  }

  onCheckboxChanged(pn: PositionNode, pv: SkavuPositionValue, checked: boolean): void {
    let existingValue = pv;
    if (checked) {
      if (isNullOrUndefined(existingValue)) {
        existingValue = this.onCreateNew(pn);
      }
    } else {
      const index = pn.values.indexOf(existingValue, 0);
      if (index > -1) {
        pn.values.splice(index, 1);
      }

    }
    existingValue.checked = checked;
  }

  onUnmatchedCheckboxPositionValueChanged(pv: SkavuPositionValue, checked: boolean): void {
    if (!checked) {
      this.details.skavu.positionValues = this.details.skavu.positionValues.filter(value => !(value.nr === pv.nr && value.position === pv.position));
      this.reRenderSelectedPositions();
    }
  }

  public find(pn: PositionNode, pvs: SkavuPositionValue[]): SkavuPositionValue[] {
    const values = pvs.filter(pv => pv.nr === pn.ledenr);
    pn.values = values;
    return values;
  }

  createCustomValue(): SkavuPositionValue {
    this.hideExtraTextEditor();
    const newValue = new SkavuPositionValue();
    newValue.position = this.details.skavu.customValues.length + 1;
    newValue.nr = DraftSkavuPositionsComponent.CUSTOM_POSITION_NO;
    newValue.workText = '';
    newValue.checked = true;
    this.details.skavu.positionValues.push(newValue);
    return newValue;
  }

  disableExtratext(id: string): boolean {
    if (!this.expandedValue) {
      return false;
    }

    let result = false;
    if (id === 'L') {
      return false;
    }
    if (this.expandedValue.extraTexts.length >= 3 && !this.expandedValue.lak || this.expandedValue.extraTexts.length >= 4) {
      //disable zone that is not in the already selected list
      result = this.expandedValue.extraTexts.filter(e => e === id).length === 0;
    }
    return result;
  }

  toggleExtratext(positionValue: SkavuPositionValue, $event): void {
    $event.stopPropagation();
    if (positionValue === this.expandedValue) {
      this.expandedValue = null;
    } else {
      this.skavuPositionService.getExtraTexts().pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
        this.extraTexts = result.map(e => new ExtraTextUI(e, positionValue));
        this.expandedValue = positionValue;
      });
    }
  }

  initPositions(listeTypeId: number): void {
    if (!isNullOrUndefined(listeTypeId)) {
      this.skavuPositionService.get(listeTypeId).pipe(takeUntil(this.unsubscribe$)).subscribe(positions => {
        this.positions = positions;
        this.selectPositions(this.positions[0], null);
        this.filter = true;
      });
    }
  }

  selectPositions(position: PositionNode, subPosition: PositionNode): void {
    this.hideExtraTextEditor();
    this.position = position;
    if (!isNullOrUndefined(position)) {
      this.subPositions = this.position.children;
      if (isNullOrUndefined(subPosition) || subPosition.parent !== this.position) {
        // Set subposition to "Alle"
        this.subPosition = this.subPositions[0];
      } else {
        this.subPosition = subPosition;
      }
      if (this.position !== this.positions[0]) {
        this.filter = false;
      }

    } else {
      this.subPosition = null;
      this.subPositions = [];
    }
    if (!isNullOrUndefined(this.subPosition)) {
      this.subSubPositions = this.subPosition.children;
    } else {
      this.subSubPositions = [];
    }
    //Now find all subPositions that needs to be marked as selected.
    //And create transient versions of the positionvalues that is not matched with any subSubPosition
    const selectedSkavuPositionValues = this.details.skavu.positionValues;
    let unmatchedSelectedPositionValues: SkavuPositionValue[] = selectedSkavuPositionValues.filter(value => value.nr !== DraftSkavuPositionsComponent.CUSTOM_POSITION_NO);
    if (this.subSubPositions) {
      this.subSubPositions.forEach(p => {
        const matchedPositionValues = this.find(p, selectedSkavuPositionValues);
        unmatchedSelectedPositionValues = unmatchedSelectedPositionValues.filter(unmatched => isNullOrUndefined(matchedPositionValues.find(matched => unmatched.nr === matched.nr)));
      });
    }
    this.unmatchedSelectedSkavuPositionValues = unmatchedSelectedPositionValues;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
