<div class="modal-header pre-size header-style" style="text-align: center">
    <h3>
        Importer 1000-numre fra værksted {{exportNonStandardPosition.atKlientNr}}
    </h3>
    <div aria-label="Close" data-dismiss="modal" class="btn-close clickable" (click)="cancel()"></div>
</div>

<div class="modal-content">
    <div class="modal-body modal-body-style">
        <div class="row">
            <div class="col">
                <p>
                    Data er eksporteret den {{exportNonStandardPosition.sysTime | date: 'dd-MM-yyyy HH:mm:ss'}} fra '{{exportNonStandardPosition.env}}' af brugeren '{{exportNonStandardPosition.username}}'.
                </p>
            </div>
        </div>
        
        <table class="table table-hover">
            <thead>
              <tr>
                <th class="align-middle">Beskrivelse</th>
                <th class="align-middle">Arbejdsenheder</th>
                <th class="align-middle">NFG</th>
                <th class="align-middle">Beløb</th>
                <th class="align-middle">Reservedel</th>
                <th class="align-middle">Reparationsart</th>
                <th class="align-middle">Gruppe</th>
                <th class="align-middle text-center">Dublet</th>
                <th class="align-middle"><input type="checkbox" class="checkbox-lg mt-1" (click)="toggleAllSelected()" [ngModel]="selectedNonStandardPositions.length > 0"></th>
              </tr>
            </thead>
            <tbody>
                <tr *ngFor="let nonStandardPosition of exportNonStandardPosition.positions">
                    <td class="align-middle">{{nonStandardPosition.description}}</td>
                    <td class="align-middle">{{nonStandardPosition.workingUnits}}</td>
                    <td class="align-middle">{{nonStandardPosition.newForOld}}</td>
                    <td class="align-middle">{{nonStandardPosition.price}}</td>
                    <td class="align-middle">{{nonStandardPosition.sparePartDescription}}</td>
                    <td class="align-middle">{{nonStandardPosition.repairMethod}}</td>
                    <td class="align-middle">{{nonStandardPosition.group}}</td>
                    <td class="align-middle text-center">{{existsAlready(nonStandardPosition) ? 'Ja': ''}}</td>
                    <td class="align-middle"><input type="checkbox" class="checkbox-lg mt-1" (click)="toggleSelected(nonStandardPosition)" [ngModel]="isSelected(nonStandardPosition)"></td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="modal-footer ">
            <button class="btn btn-primary me-1" (click)="import()" [disabled]="selectedNonStandardPositions.length == 0"
                    type="button" aria-hidden="true">
                <i class="fa fa-2x action-icon fa-check"></i>
                <span class="itemText d-none d-md-inline-block">Importer valgte</span>
            </button>
    </div>
</div>



