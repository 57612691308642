export class SpecialkodeDTO {
  kode: number;
  value: number;
  agreementValue: number;
  deleted: boolean;

  constructor(kode: number, value: number, agreementValue: number, deleted: boolean) {
    this.kode = kode;
    this.value = value;
    this.agreementValue = agreementValue;
    this.deleted = deleted;
  }
}
