import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AutostatRoleDTO} from '../dto/autostat-role.dto.model';
import {AutostatUserDTO} from '../dto/autostat-user.dto.model';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {StatisticService} from '../statistic.service';
import {AutostatRoleAndUserDTO} from '../dto/autostat-role-and-user.dto.model';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {isNullOrUndefined} from '../../shared/utils/object-utils';

@Component({
  templateUrl: './role-user-popup.component.html',
  providers: [StatisticService]
})

export class RoleUserPopupComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  roles: AutostatRoleDTO[];
  @Input() public chosenRole: AutostatRoleDTO;
  users: AutostatUserDTO[];
  @Input() public chosenUser: AutostatUserDTO;
  @Output() public choiceMadeEvent = new EventEmitter<AutostatRoleAndUserDTO>();

  constructor(private activeModal: NgbActiveModal,
              public statisticService: StatisticService) {
  }

  ngOnInit(): void {

    const rolesDTOObs = this.statisticService.getRoles();
    rolesDTOObs.pipe(takeUntil(this.unsubscribe$)).subscribe(roles => {
      this.roles = roles;
    });

    this.chosenUser = new AutostatUserDTO();
  }

  public cancel(): void {
    this.activeModal.dismiss(false);
  }

  public confirm(): void {
    this.activeModal.close(true);
  }

  public decline(): void {
    this.activeModal.close(false);
  }

  onSelectRoleChanged(): void {
    this.chosenUser.name = null;
  }

  isRoleChosen(): boolean {
    return isNullOrUndefined(this.chosenRole);
  }

  roleHasUser(role: AutostatRoleDTO): boolean {
    return role.hasUsers;
  }

  onHentRapportCLick(): void {
    if (this.chosenRole && this.chosenUser) {
      const userRole = new AutostatRoleAndUserDTO();
      userRole.role = this.chosenRole;
      userRole.user = this.chosenUser;
      this.choiceMadeEvent.emit(userRole);
      this.activeModal.close(true);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}


