import {Injectable} from '@angular/core';
import {ValueTypeDTO} from '../dto/value-type-dto.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {CompanyDTO} from '../dto/company-dto.model';
import {
  AgreementBulkDTO,
  AgreementChangeDTO,
  AgreementDTO,
  AgreementHistoryDTO,
  AgreementTypeDTO
} from '../agreement/agreement-dto.model';
import {AgreementUtils} from '../agreement/agreement-utils';

@Injectable({
  providedIn: 'root'
})
export class AgreementService {
  private agreementUrl = 'agreement';
  private valueTypeUrl = 'valuetype';
  private _valueTypesSubject: Observable<ValueTypeDTO[]>;
  private priceAgreementsDeviatesSubject = new Subject<boolean>();

  constructor(private http: HttpClient) {
  }

  getValueTypes(): Observable<ValueTypeDTO[]> {
    if (!this._valueTypesSubject) {
      this._valueTypesSubject =
        this.http.get<ValueTypeDTO[]>(this.valueTypeUrl).pipe(
          map(response => response
              .map(jsonResult => Object.assign(new ValueTypeDTO(), jsonResult))
              .sort((a, b) => (a.type < b.type ? -1 : 1))),
          shareReplay(1)
        );
    }
    return this._valueTypesSubject;
  }

  getValueTypesFromAftaleValueType(aftaleValueType: string): Observable<ValueTypeDTO[]> {
    return this.http.get<ValueTypeDTO[]>(this.valueTypeUrl + '/' + aftaleValueType).pipe(
      map(response => response
          .map(jsonResult => Object.assign(new ValueTypeDTO(), jsonResult))
          .sort((a, b) => (a.kode < b.kode ? -1 : 1))));
  }

  getAgreementTypes(): Observable<AgreementTypeDTO[]> {
    return this.http.get<AgreementTypeDTO[]>(this.agreementUrl + '/types').pipe(map(response => response.map(json => Object.assign(new AgreementTypeDTO(), json))
    ));
  }

  getCompanies(): Observable<CompanyDTO[]> {
    return this.http.get<CompanyDTO[]>(this.agreementUrl + '/companies').pipe(map(response => response.map(taksOrgJson => Object.assign(new CompanyDTO(), taksOrgJson))
    ));
  }

  getCompanyAgreements(companyId: number): Observable<AgreementDTO[]> {
    return this.http.get<AgreementDTO[]>(this.agreementUrl + '/company/' + companyId).pipe(map(response => this.sortAgreements(response.map(aftaleJson => Object.assign(new AgreementDTO(), aftaleJson)))
    ));
  }

  getCompanyAgreementsByType(companyId: number, agreementTypeId: number): Observable<AgreementDTO[]> {
    return this.http.get<AgreementDTO[]>(this.agreementUrl + '/company/' + companyId + '/' + agreementTypeId).pipe(map(response => this.sortAgreements(response.map(aftaleJson => Object.assign(new AgreementDTO(), aftaleJson)))
    ));
  }

  getAgreementsHistory(companyId: number, from: Date, to: Date): Observable<AgreementHistoryDTO[]>  {
    const params = new HttpParams()
      .set('startDate', from.toISOString())
      .set('endDate', to.toISOString());
    return this.http.get<AgreementHistoryDTO[]>(this.agreementUrl + '/company/' + companyId + '/agreement-history', {params});
  }

  public getPriceAgreementsDeviatesSubject(): Subject<boolean> {
    return this.priceAgreementsDeviatesSubject;
  }

  getLatestCompanyAgreementChanges(): Observable<AgreementChangeDTO[]> {
    return this.http.get<AgreementChangeDTO[]>(this.agreementUrl + '/changes');
  }

  getMyWorkshopAgreements(workshopNo: string | number) {
    return this.http.get<AgreementDTO[]>(this.agreementUrl + '/workshop/' + workshopNo);
  }

  updateMyWorkshopAgreements(agreements: AgreementBulkDTO) {
    return this.http.put<void>(this.agreementUrl + '/workshop', agreements);
  }
  
  private sortAgreements(agreements: AgreementDTO[]): AgreementDTO[] {
    if (agreements && agreements.length > 1) {
      agreements.sort((a1, a2) => AgreementUtils.compare(a1, a2))
    }
    return agreements;    
  }
}
