        <div class="card imgcontent mb-5">
            <div *ngIf="isImage(attachment)" class="image-wrapper clickable"
                 (click)="openGallery.emit(attachment)">
                <img class="vehicle-card-right-image" [lazyLoad]="getImageUrl(attachment, true, false)"
                     [defaultImage]="defaultImage"/>
            </div>
            <div *ngIf="!isImage(attachment)">
                <a (click)="openGallery.emit(attachment)">
                    <span class="fa fa-5x file-attachment-icon"
                          [ngClass]="[isUploaded(attachment) ? 'fa-file-o': 'fa-upload file-upload-in-progress', getNotPresentableFileClass(attachment)]"></span>
                </a>
            </div>
            <div class="content" *ngIf="attachment.remark">
                <i class="fa fa-comment-o fa-2x"></i>
                {{attachment.remark}}
            </div>
            <div>
                <strong>{{attachment.name}}</strong>
            </div>
            <div class="pt-4 image-actions" *ngIf="isUploaded(attachment)">
                <a href="{{getImageUrl(attachment, false, true)}}"><i class="fa fa-download fa-2x pull-left"></i></a>
                <a *ngIf="deletable" (click)="deleteAttachment.emit(attachment)" class="ps-1 pull-left"><i
                    class="fa fa-trash fa-2x"></i></a>
                <span *ngIf="principal.isTaksator() && metadataSummary">
                        <i class="fa fa-exclamation-triangle fa-2x pull-right text-danger metadata-danger-pulse" title="Billedet er taget ca. {{metadataSummary.getDistance(attachment) | number: '1.0-0'}} meter væk fra det første billede." *ngIf="isWorkshopImage(attachment) && !metadataSummary.validateDistanceOK(attachment)"></i>
                        <i class="fa fa-exclamation-triangle fa-2x pull-right text-warning" title="Der er ingen metadata på billedet." *ngIf="isWorkshopImage(attachment) && (!metadataSummary.metadataMap || !metadataSummary.metadataMap[attachment.id])"></i>
                    </span>

            </div>
        </div>
