<ng-container *ngIf="showRespondToVideoRequestButton$ | async">
    <label class="btn btn-file btn-sm" [class.btn-secondary]="videoRequestIsResponded | async" [class.btn-outline-danger]="(videoRequestIsResponded | async) === false" [ngClass]="{'disabled': fileUploader.isUploading}">
        <input type="file" ng2FileSelect [uploader]="fileUploader" [disabled]="fileUploader.isUploading" multiple/>
        <lc-spinner-text [spin]="fileUploader.isUploading" text="Tilf&oslash;j video"></lc-spinner-text>
    </label>
    <span *ngIf="videoRequestMessage" class="clickable mt-1" (click)="showVideoRequestInfo()" title="Information om video forespørgsel">
        <i class="fa fa-question-circle-o fa-2x" style="vertical-align: middle; padding-bottom: 2px"></i>
    </span>
</ng-container>

