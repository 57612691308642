import {Component} from '@angular/core';
import {DraftEditService} from './draft-edit.service';

/**
 * The purpose of this wrapper component is to provide services that lives inside the draft edit feature.
 * (Including calculate, autoflex etc.) but is destroyed as soon as the user navigates away from the draft edit feature.
 * possibly see: https://github.com/angular/angular/issues/11228#issuecomment-327255605
 *
 */
@Component({
  template: '<router-outlet></router-outlet>',
  providers: [DraftEditService]
})
export class DraftEditWrapperComponent {
  // necessary as this is responsible for initializing the draftEditService once for all subroute
  // @ts-ignore
  constructor(private draftEditService: DraftEditService) {
  }
}
