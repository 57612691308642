<lc-qapter-start [startQapter$]="startQapter$"></lc-qapter-start>
<lc-bottombar [menu]="viewmodel.bottomMenu" [iconClass]="viewmodel.menuIconClass"
              [title]="viewmodel.getReportKey()| lcForsiReportKey"
              [shortTitle]="viewmodel.getReportKey() | lcForsiReportKeyShort"></lc-bottombar>
<div class="text-center" *ngIf="viewmodel.loading">
    <i class="fa fa-circle-o-notch fa-spin fop-page-spinner"></i>
</div>
<router-outlet></router-outlet>
<div *ngIf="!viewmodel.loading">
    <lc-context-menu [menu]="viewmodel.contextMenu"></lc-context-menu>
    <div class="split-page">
        <div class="split-left d-none d-lg-block">&nbsp;</div>
        <div class="split-right">
            <div class="row">
                <div id="report-print" class="col-12 pt-lg-2">
                    <lc-combined-print-viewer [vkPrintModel]="viewmodel.vkPrintTab"
                                              [taksPrintModel]="viewmodel.taksPrintTab"
                                              [allowPrinting]="viewmodel.activePrintSettings.allowPrinting"
                                              (selectedPrintModel)="selectPrint($event)"
                                              (showPrintClicked)="showPrintPopup($event)"></lc-combined-print-viewer>
                </div>
            </div>
            <div class="row" *lcHasAnyAuthority="'ROLE_Taks'">
                <div id="report-police" class="col-12">
                    <h2>Police</h2>
                    <lc-show-police [policy]="viewmodel.policy"></lc-show-police>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h2>Bilag</h2>
                    <div class="card">
                        <div class="card-body">
                            <div id="report-attachments" class="col-12 p-0">
                                <lc-report-attachment
                                    [attachmentSummary]="viewmodel.attachmentSummary"
                                    (readyForApproval)="readyForApproval($event)"
                                    (onAttachmentChanged)="getAttachmentsForReport()"
                                    [readonly]="false"
                                    [workshopReportId]="viewmodel.report.vkReportId"
                                    [reportKey]="viewmodel.getAttachmentReportKey()"
                                    [registrationNumber]="viewmodel.report.licensePlate"
                                    [videoRequestUseCase]="true"
                                ></lc-report-attachment>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 ">
                    <h2>Beskeder</h2>
                    <div class="card">
                        <div class="card-body">
                            <div id="report-messages" class="col-12 p-0">
                                <lc-message
                                    [sendEnabled]="sendMessageEnabled"
                                    [messages]="viewmodel.messages"
                                    [textLibraryType]="textLibraryType"
                                    (postMessage)="sendMessage($event)"
                                    (reload)="viewmodel.loadMessages()">
                                </lc-message>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12" id="misc">
                    <div *ngIf="!!viewmodel.workshopResponsible">
                        <h2>Værkstedsansvarlig</h2>
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-6">{{viewmodel.workshopResponsible.name}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2>Bemærkninger</h2>
                    <div class="card" *ngIf="viewmodel.reportInfo">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6" *ngIf="viewmodel.reportInfo.taksRapportBemaerkning">
                                    <label>Bemærkninger</label>
                                    <div
                                        class="text-presentation mb-2">{{viewmodel.reportInfo.taksRapportBemaerkning}}</div>
                                </div>
                                <div class="col-md-6" *ngIf="viewmodel.reportInfo.internInfo">
                                    <label>Intern info</label>
                                    <div class="text-presentation mb-2">{{viewmodel.reportInfo.internInfo}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row dynamic-padding-bottom">
                <!-- Room for floating action buttons -->
                <div class="col-md-12"></div>
            </div>
        </div>
    </div>
</div>
