<lc-bottombar [menu]="viewmodel.draftMenu" [iconClass]="viewmodel.menuIconClass"
              [title]="viewmodel.menuBottomTitle"
              [shortTitle]="viewmodel.menuBottomShortTitle"></lc-bottombar>
<div class="text-center" *ngIf="viewmodel.showLoading">
    <i class="fa fa-circle-o-notch fa-spin fop-page-spinner"></i>
</div>
<ng-container *ngIf="!viewmodel.showLoading">
    <h1>Policeopslag</h1>
    <lc-show-police [policy]="viewmodel.policy"></lc-show-police>
</ng-container>
