<fieldset ngForm="f" #f="ngForm">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <!-- Left part -->
                <div class="col-md-12">
                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label for="forbehold">Forbehold</label>
                        </div>
                        <div class="col">
                            <select [(ngModel)]="forbehold" class="col form-select form-select-sm" id="forbehold"
                                name="forbehold" (change)="onSelectOptionChanged()" required>
                                <option *ngIf="!forbehold" [ngValue]="null" disabled="true">--Vælg
                                    forbehold--
                                </option>
                                <option *ngFor="let forbehold of getForbeholdOptions()" [ngValue]="forbehold">
                                    {{forbehold}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label for="reperationfaerdigdato">Reparation færdig</label>
                        </div>
                        <div class="col">
                            <div class="input-group" style="padding: 0px;">
                                <input class="form-control" type="tel" id="reperationfaerdigdato"
                                    name="reperationfaerdigdato" [(ngModel)]="details.reperationfaerdigDato"
                                    [disabled]="details.forbehold" ngbDatepicker #reperationfaerdigdatoDP="ngbDatepicker"
                                    lcInputMask="date" [minDate]="{ 'year': 1900, 'month': 01, 'day': 01 }"
                                    [maxDate]="{ 'year': 2099, 'month': 12, 'day': 31 }">
                                <button class="btn btn-outline-secondary" (click)="reperationfaerdigdatoDP.toggle()"
                                    type="button" [disabled]="details.forbehold">
                                    <i class="fa fa-calendar fa-lg"></i>
                                </button>
                                <button class="btn btn-outline-secondary"
                                    (click)='details.reperationfaerdigDato = dateService.todayNgbDateStruct()'
                                    [disabled]="details.forbehold" type="button">&nbsp;d.d.&nbsp;
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label for="faktura">Gælder som faktura</label>
                        </div>
                        <div class="col-8">
                            <input type="checkbox" name="faktura" class="form-input-check-single" id="faktura"
                                [(ngModel)]="details.faktura" autocomplete="off" [disabled]="details.forbehold" />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</fieldset>