import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {PotentialLinkedReportDTO} from '../../../shared/dto/potential-linked-report-dto.model';
import {ReportService} from '../../service/report.service';
import {CreateLinkedReportDTO} from '../../../shared/model/create-linked-report-dto.model';
import {Router} from '@angular/router';
import {ReportDTO} from '../..';
import {combineLatest, Subject} from 'rxjs';
import {CreateGLinkedReportDTO} from '../../../shared/model/create-g-linked-report-dto.model';
import {ConfirmButtonType, ConfirmPopupButton, ConfirmPopupComponent} from '../../../shared/modals/confirm/confirm-popup.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CreateTLinkedReportDTO} from '../../../shared/model/create-t-linked-report-dto.model';
import {PotentialLinkedReportType} from '../../../shared/model/potential-linked-report-type.enum';
import {PotentialLinkedReportState} from '../../../shared/model/potential-linked-report-state.enum';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'lc-create-linked-report-from-report',
  templateUrl: './create-linked-report-from-report.component.html',
  styleUrls: ['create-linked-report-from-report.scss']
})
export class CreateLinkedReportFromReportComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  @Input() reportId: number;
  ongoingRequest = false;
  report: ReportDTO;
  isLoading = true;
  potentialLinkedReportTypes: PotentialLinkedReportDTO[];

  constructor(private reportService: ReportService,
              private router: Router,
              private modalService: NgbModal) {
  }

  ngOnInit(): void {
    const report = this.reportService.getReport(this.reportId);
    const potentialLinkedReportTypes = this.reportService.getPotentialLinkedReports(this.reportId);

    combineLatest([report, potentialLinkedReportTypes])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([report, potentialLinkedReportTypes]) => {
        this.report = report;
        this.potentialLinkedReportTypes = potentialLinkedReportTypes;
        this.isLoading = false;
      });
  }

  private createLinkedTReport(linkedReportType: PotentialLinkedReportDTO): void {
    const newTLinkedReportDTO = new CreateTLinkedReportDTO();
    newTLinkedReportDTO.type = linkedReportType.type;
    const modalChangesRef = this.modalService.open(ConfirmPopupComponent);
    modalChangesRef.componentInstance.title = 'Fjern ledenumre?';
    modalChangesRef.componentInstance.body = 'Skal ledenumre fjernes? (Til annullering)';
    modalChangesRef.componentInstance.customFirstButton = new ConfirmPopupButton('Nej', ConfirmButtonType.DECLINE);
    modalChangesRef.componentInstance.customSecondButton = new ConfirmPopupButton('Fjern', ConfirmButtonType.CONFIRM);

    modalChangesRef.result.then((confirm: boolean) => {
      newTLinkedReportDTO.removeLedenr = confirm;
      this.createLinkedReport(newTLinkedReportDTO);
    }, () => {
    });
  }

  private createLinkedGReport(linkedReportType: PotentialLinkedReportDTO): void {
    const newGLinkedReportDTO = new CreateGLinkedReportDTO();
    newGLinkedReportDTO.type = linkedReportType.type;
    if (PotentialLinkedReportState.ALLOW_CREATION_WITH_PROMPT === linkedReportType.state) {
      const modalChangesRef = this.modalService.open(ConfirmPopupComponent);
      modalChangesRef.componentInstance.title = 'Ændringer';
      modalChangesRef.componentInstance.body = 'Er der ændringer i rep.metode/rep.omfang?';

      modalChangesRef.result.then((confirm: boolean) => {
        if (confirm) {
          const modalCopyRef = this.modalService.open(ConfirmPopupComponent);
          modalCopyRef.componentInstance.title = 'Kopier';
          modalCopyRef.componentInstance.body = 'Skal alle dele kopieres?';
          modalCopyRef.result.then((confirm: boolean) => {
            newGLinkedReportDTO.flushOnepadData = !confirm;
            this.createLinkedReport(newGLinkedReportDTO);
          }, () => {
          });
        } else {
          this.createLinkedReport(newGLinkedReportDTO);
        }
      }, () => {
      });
    } else {
      this.createLinkedReport(newGLinkedReportDTO);
    }
  }

  private createLinkedReport(createLinkedReport): void {
    this.ongoingRequest = true;
    this.reportService.createLinkedReport(this.reportId, createLinkedReport)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(linkedReport => {
        this.ongoingRequest = false;
        this.navigateToClientState(linkedReport.clientStateToken);
      }, () => {
        this.ongoingRequest = false;
      });
  }

  navigateToClientState(clientStateToken: string): void {
    this.router.navigate(['draft', 'edit', clientStateToken])
  }

  handlePotentialLinkedReportSelected(potentialLinkedReport: PotentialLinkedReportDTO): void {
    switch (potentialLinkedReport.type) {
      case PotentialLinkedReportType.G: {
        this.createLinkedGReport(potentialLinkedReport);
        break;
      }
      case PotentialLinkedReportType.T: {
        this.createLinkedTReport(potentialLinkedReport);
        break;
      }
      default: {
        const newGenericLinkedReportDTO = new CreateLinkedReportDTO();
        newGenericLinkedReportDTO.type = potentialLinkedReport.type;
        this.createLinkedReport(newGenericLinkedReportDTO);
        break;
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
