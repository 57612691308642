<div class="navbar-fixed-bottom">
    <div class="bar-title ms-lg-4 ms-2" [class.screen-xl]="xlScreen$ | async">
        <i *ngIf="iconClass" class="fa {{iconClass}} me-lg-3 me-2"></i>
        <span class="d-md-inline d-none">{{title}}</span>
        <span class="d-inline d-md-none">{{shortTitle ? shortTitle : title}}</span>
    </div>
    <ul class="m-1 me-0 p-0 pe-lg-0 pe-3">
        <li *ngFor="let item of menu.menuItems">
            <div placement="top" [ngbTooltip]="item.getDisabledReason()" tooltipClass="text-start"
                 *ngIf="item.showMenuItem() && item.subMenuItems.length === 0">
                <button type="button" (click)="item.didClickMenuItem()"
                        [disabled]="item.disableMenuItem()" class="btn me-lg-3 me-2" [ngClass]="item.buttonClass"
                        [ngbTooltip]="item.buttomTooltipCallback()" placement="top" triggers="mouseenter:mouseleave">
                    <i *ngIf="item.faIcon" class="fa fa-2x align-middle" [ngClass]="item.faIcon"
                       aria-hidden="true"></i>
                    <img *ngIf="item.imgUrl" [ngClass]="{'fast-fa-spin': item.spin}" [src]="item.imgUrl"
                         class="lc-img-button align-middle"/>
                    <i *ngIf="item.spin && !item.imgUrl" class="fa fa-circle-o-notch fa-spin"
                       aria-hidden="true"></i>
                    <span class="itemText d-none align-middle d-xl-inline-block">{{item.title}}</span>
                </button>
            </div>
            <div placement="top" [ngbTooltip]="item.getDisabledReason()" tooltipClass="text-start"
                 *ngIf="item.showMenuItem() && item.subMenuItems.length > 0"
                 ngbDropdown class="d-inline-block">
                <button type="button" ngbDropdownToggle
                        [disabled]="item.disableMenuItem()" class="btn me-lg-3 me-2" [ngClass]="item.buttonClass"
                        [ngbTooltip]="item.buttomTooltipCallback()" placement="top" triggers="mouseenter:mouseleave"
                        [id]="item.getIdentifier()">
                    <i *ngIf="item.faIcon" class="fa fa-2x align-middle" [ngClass]="item.faIcon"
                       aria-hidden="true"></i>
                    <img *ngIf="item.imgUrl" [ngClass]="{'fast-fa-spin': item.spin}" [src]="item.imgUrl"
                         class="lc-img-button align-middle"/>
                    <i *ngIf="item.spin && !item.imgUrl" class="fa fa-circle-o-notch fa-spin"
                       aria-hidden="true"></i>
                    <span class="itemText d-none align-middle d-xl-inline-block">{{item.title}}</span>
                </button>
                <div ngbDropdownMenu aria-labelledby="item.getIdentifier()">
                    <button ngbDropdownItem *ngFor="let submenu of item.subMenuItems"
                            (click)="submenu.didClickMenuItem()"
                            [disabled]="submenu.disabled" [class]="submenu.customClass">{{submenu.title}}</button>
                </div>
            </div>
        </li>
    </ul>
</div>
