<div class="modal-header pre-size header-style" style="text-align: center">
    <h3 class="text-truncate">
        Opret via Rekvisition
    </h3>
    <div aria-label="Close" data-dismiss="modal" class="btn-close clickable" type="button" (click)="cancel()"></div>
</div>

<div class="modal-body modal-body-style">
        <div class="row">
            <div class="col-6">
                <div class="pb-2">
                    <label>Selskab</label>
                    <input type="text" lcInputMask="leftTrim" class="form-control" maxlength="2" size="2"
                           [(ngModel)]="searchRequisitionSelskab" name="searchRequisitionSelskab"
                           [disabled]="requisition"
                           (keyup)="searchRequisitionKeyUp($event)"/>
                </div>
            </div>
            <div class="col">
                <div class="pb-3">
                    <label>Skadenr</label>
                    <input type="text" lcInputMask="leftTrim" class="form-control" maxlength="20"
                           size="20"
                           [(ngModel)]="searchRequisition" name="searchRequisition" [disabled]="requisition"
                           (keyup)="searchRequisitionKeyUp($event)"/>

                </div>
            </div>
        </div>
            <div class="row" *ngIf="requisition">
                <div class="col-lg-6 col-12">
                    <div class="card">
                        <div class="card-header">Forsikringstager</div>
                        <div class="card-body">
                            <div class="row pb-3">
                                <label class="col-4 field-header">Regnr</label><label
                                class="col-8">{{forsikringstager.regNr}}</label>
                                <label class="col-4 field-header">Navn</label><label
                                class="col-8">{{forsikringstager.navn}}</label>
                                <label class="col-4 field-header">Adresse</label><label
                                class="col-8">{{forsikringstager.adresse1}}</label>
                                <label class="col-4 field-header">&nbsp;</label><label
                                class="col-8">{{forsikringstager.adresse2}}</label>
                                <label class="col-4 field-header">Postnr</label><label
                                class="col-8">{{forsikringstager.postNr}}</label>
                                <label class="col-4 field-header">By</label><label
                                class="col-8">{{forsikringstager.by}}</label>
                                <label class="col-4 field-header">Telefon</label><label
                                class="col-8">{{forsikringstager.telefon}}</label>
                                <label class="col-4 field-header">Email</label><label
                                class="col-8">{{forsikringstager.email}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-12">
                    <div class="card">
                        <div class="card-header">Skadelidte</div>
                        <div class="card-body">
                            <div class="row pb-3">
                                <label class="col-4 field-header">Regnr</label><label
                                class="col-8">{{skadelidte.regNr}}</label>
                                <label class="col-4 field-header">Navn</label><label
                                class="col-8">{{skadelidte.navn}}</label>
                                <label class="col-4 field-header">Adresse</label><label
                                class="col-8">{{skadelidte.adresse1}}</label>
                                <label class="col-4 field-header">&nbsp;</label><label
                                class="col-8">{{skadelidte.adresse2}}</label>
                                <label class="col-4 field-header">Postnr</label><label
                                class="col-8">{{skadelidte.postNr}}</label>
                                <label class="col-4 field-header">By</label><label
                                class="col-8">{{skadelidte.by}}</label>
                                <label class="col-4 field-header">Telefon</label><label
                                class="col-8">{{skadelidte.telefon}}</label>
                                <label class="col-4 field-header">Email</label><label
                                class="col-8">{{skadelidte.email}}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="requisition">
                <div class="col-12 col-lg-6">
                    <div class="card">
                        <div class="card-header">Skadet k&oslash;ret&oslash;j</div>
                        <div class="card-body">
                            <div class="row pb-3">
                                <label class="col-4 field-header">Regnr</label><label
                                class="col-8">{{vehicle.regNr}}</label>
                                <label class="col-4 field-header">Nr.pladetype</label><label
                                class="col-8">{{vehicle.nummerpladetype}}</label>
                                <label class="col-4 field-header">Stelnr</label><label
                                class="col-8">{{vehicle.stelNr}}</label>
                                <label class="col-4 field-header">&Aring;rgang</label><label
                                class="col-8">{{vehicle.aargang}}</label>
                                <label class="col-4 field-header">Fabrikat</label><label
                                class="col-8">{{vehicle.fabrikat}}</label>
                                <label class="col-4 field-header">Km.stand</label><label
                                class="col-8">{{vehicle.kmstand}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="card">
                        <div class="card-header">Oplysninger om skade</div>
                        <div class="card-body">
                            <div class="row pb-3">
                                <label class="col-4 field-header">Selskabskode</label><label
                                class="col-8">{{info.selskabskode}}</label>
                                <label class="col-4 field-header">Skadenummer</label><label
                                class="col-8">{{info.skadeNr}}</label>
                                <label class="col-4 field-header">Policenummer</label><label
                                class="col-8">{{info.policenummer}}</label>
                                <label class="col-4 field-header">Type</label><label
                                class="col-8">{{info.skadetype}}</label>
                                <label class="col-4 field-header">Skadedato</label><label
                                class="col-8">{{info.skadedato}}</label>
                                <label class="col-4 field-header">Zoner</label><label
                                class="col-8">{{info.zoner}}</label>
                                <label class="col-4 field-header">Sagsbehandler</label><label
                                class="col-8">{{info.sagsbehandler}}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <div class="row" *ngIf="requisition">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">Bem&aelig;rkninger</div>
                    <div class="card-body">
                        <div *ngFor="let detail of remarks.lines">{{detail.value}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

<div class="modal-footer">
    <button class="btn btn-primary" type="button" id="requisitionButton" (click)="doRequisitionLookup()"
            *ngIf="!requisition" [disabled]="!searchRequisition || !searchRequisitionSelskab">
        <lc-spinner-text [spin]="searchingRequisition" text="Søg"></lc-spinner-text>
    </button>
    <button class="btn btn-primary" (click)="create()" *ngIf="requisition">
        <lc-spinner-text [spin]="isCreating" text="Opret kladde"></lc-spinner-text>
    </button>
    <button class="btn btn-outline-primary" (click)="newSearch()" *ngIf="requisition">
        <lc-spinner-text [spin]="isCreating" text="Ny søgning"></lc-spinner-text>
    </button>
</div>
