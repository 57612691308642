<fieldset ngForm="f" #f="ngForm">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <!-- Left part -->
                <div class="col-md-12">
                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label class="m-0" for="assessmentmethod">Takseringsmetode</label>
                        </div>
                        <div class="col">
                            <lc-autocomplete required id="assessmentmethod" name="assessmentmethod"
                                            [ngModel]="selectedTakserform"
                                            (ngModelChange)="updateTaksForm($event)"
                                            [items]="getTaksFormer()"
                                            exactKey="kode" itemKey="kode" itemText="text">
                            </lc-autocomplete>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fieldset>
