<div class="modal-header pre-size header-style" style="text-align: center">
    <h3 class="text-truncate">
        Aftale dokumenter
        <div class="d-inline-block body-font">
        <div ngbDropdown class="d-inline-block ps-2 pe-2" >
            <button class="btn btn-sm btn-outline-primary" id="agreementDocument"
                    ngbDropdownToggle>{{selectedAgreementDocument?.filename}}</button>
            <div ngbDropdownMenu aria-labelledby="agreementDocument">
                <button ngbDropdownItem *ngFor="let agreementDocument of agreementDocuments"
                        (click)="selectAgreementDocument(agreementDocument)">{{agreementDocument.filename}}</button>
            </div>
        </div>
        <small *ngIf="selectedAgreementDocument">Fra: {{selectedAgreementDocument.fromName}}</small>
        </div>
    </h3>

    <div aria-label="Close" data-dismiss="modal" class="btn-close clickable" (click)="cancel()"></div>
</div>
<div class="modal-body modal-body-style">
    <div class="row">
        <div class="col-12">
            <div class="row">
                <!-- hidden instead of ngIf so we can access the PdfViewerComponent ViewChild at all times -->
                <div class="col-12" [hidden]="!selectedAgreementDocument">
                    <div *ngIf="loading" class="text-center">
                        <i class="fa fa-circle-o-notch fa-spin fop-page-spinner"></i>
                    </div>
                    <pdf-viewer [src]="getAgreementDocumentUrl(selectedAgreementDocument)"
                                [render-text]="true"
                                [autoresize]="true"
                                [original-size]="false"
                                style="display: block;">
                    </pdf-viewer>
                </div>
            </div>
            <div *ngIf="!selectedAgreementDocument">
                Vælg Aftale Dokument
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div class="pull-right" *ngIf="selectedAgreementDocument">
        <button class="btn btn-primary" (click)="downloadAgreementDocument(selectedAgreementDocument)">Download</button>
    </div>
    <div class="pull-right">
        <button class="btn btn-primary" (click)="cancel()">Luk</button>
    </div>
</div>
