import {Component, Input, OnDestroy, OnInit} from '@angular/core';

@Component({
  selector: 'lc-spinner-text',
  template: `<span><i *ngIf="spin" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
                      <span *ngIf="!spin">{{text}}<ng-content></ng-content></span></span>`
})
export class SpinnerTextComponent implements OnInit, OnDestroy {

  @Input() spin: boolean;
  @Input() text: string;

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

}
