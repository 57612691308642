export enum LOGIN_FLOW {
  LOCAL = 'LOCAL',
  B2C = 'B2C'
}

export enum B2C_FLOW {
  SMS_CALL = 'SMS_CALL',
  EMAIL = 'EMAIL',
  APP = 'APP'
}

export class OAuthLoginFlowDTO {
  flow: LOGIN_FLOW;
}
