import {Component, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {PrincipalService} from '../../../shared';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {takeUntil} from 'rxjs/operators';
import {UserB2CLoginDTO} from '../../../shared/service/auth/user-b2c-login-dto.model';
import {SignupPopup} from '../signup.popup';
import {BootstrapAlertType, BootstrapGrowlService} from '../../../shared/ui/ngx-bootstrap-growl';

@Component({
  templateUrl: './b2-c-signup-flow-popup.component.html'
})
export class B2CSignupFlowPopupComponent implements OnDestroy, SignupPopup {
  private unsubscribe$ = new Subject<void>();
  public b2cUser: UserB2CLoginDTO;

  constructor(private principal: PrincipalService,
              private bootstrapGrowlService: BootstrapGrowlService,
              private activeModal: NgbActiveModal) {
  }

  startB2C(): void {
    if (this.b2cUser && this.b2cUser.email) {
      this.principal.startB2CFlow(this.b2cUser).pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
        this.bootstrapGrowlService.addAlert('Vi har sendt en aktiveringsemail til dig', BootstrapAlertType.SUCCESS, 5000, true);
        this.activeModal.dismiss();
      });
    }
  }

  postponeB2C(): void {
    this.principal.postponeB2CFlow().pipe(takeUntil(this.unsubscribe$)).subscribe();
    this.activeModal.dismiss();
  }

  canPostpone(): boolean {
    const date = new Date();
    date.setDate(date.getDate() - 7);
    return new Date(this.b2cUser.createdAt) > date;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
