import {Routes} from '@angular/router';
import {AboutComponent} from './about.component';
import {UserRouteAccessService} from '../shared/service/auth/user-route-access.service';
import {AppRole} from '../shared/service/auth/app-roles.model';

export const ABOUT_ROUTES: Routes = [{
  path: 'about',
  component: AboutComponent,
  data: {
    authorities: [AppRole.VK, AppRole.TAKS, AppRole.SAGSB],
    pageTitle: 'Om',
    breadcrumb: 'Om Autotaks'
  },
  canActivate: [UserRouteAccessService]
}
];
