import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class DmrService {
  private dmrUrl = 'dmr/';

  constructor(private http: HttpClient) {
  }

  getCarDetails(licensePlate: string): any {
    const carDetailsUrl = this.dmrUrl + 'car-details/' + licensePlate;
    return this.http.get<any>(carDetailsUrl);
  }
}
