<fieldset ngForm="f" #f="ngForm">

    <div class="card">
        <div class="card-body">

            <div class="row pb-3">
                <div class="col-form-label col-4">
                    <label for="skadedato">Skadedato</label>
                </div>
                <div class="col">
                    <div class="input-group">
                        <input class="form-control" type="tel"
                            id="skadedato" name="skadedato" [(ngModel)]="details.skadeDato"
                            ngbDatepicker #skadedatoDP="ngbDatepicker" lcInputMask="date"
                            [minDate]="fiveYearsPriorMinDateSelection()" [maxDate]="pastOrPresentMaxDateSelection()">
                        <button class="btn btn-outline-secondary" (click)="skadedatoDP.toggle()"
                                type="button"><i class="fa fa-calendar fa-lg"></i></button>
                        <button class="btn btn-outline-secondary"
                                (click)='details.skadeDato = dateService.todayNgbDateStruct()'
                                type="button">&nbsp;d.d.&nbsp;
                        </button>
                    </div>
                </div>
            </div>

            <div class="row pb-3">
                <div class="col-form-label col-4">
                    <label for="besigtigelsesdato">Kan besigtiges</label>
                </div>
                <div class="col">
                    <div class="input-group col-8 p-0">
                        <input class="form-control" type="tel"
                            id="besigtigelsesdato" name="besigtigelsesdato"
                            [(ngModel)]="details.besigtigelsesDato"
                            ngbDatepicker #besigtigelsesdatoDP="ngbDatepicker" lcInputMask="date"
                            [minDate]="{ 'year': 1900, 'month': 1, 'day': 1 }" [maxDate]="{ 'year': 2099, 'month': 12, 'day': 31 }"
                            [required]="inspectionDateRequired()">
                        <button class="btn btn-outline-secondary"
                                (click)="besigtigelsesdatoDP.toggle()"
                                type="button">
                            <i class="fa fa-calendar fa-lg"></i>
                        </button>
                        <button class="btn btn-outline-secondary"
                                (click)='details.besigtigelsesDato = dateService.todayNgbDateStruct()'
                                type="button">&nbsp;d.d.&nbsp;
                        </button>
                    </div>
                </div>
            </div>

            <div *lcHasAnyAuthority="'ROLE_VK'" class="row pb-3">
                <div class="col-form-label col-4">
                    <label for="reperationfaerdigdato">Reparation færdig</label>
                </div>
                <div class="col">
                    <div class="input-group col-8 p-0">
                        <input class="form-control" type="tel"
                            id="reperationfaerdigdato" name="reperationfaerdigdato"
                            [(ngModel)]="details.reperationfaerdigDato"
                            ngbDatepicker #reperationfaerdigdatoDP="ngbDatepicker" lcInputMask="date"
                            [minDate]="{ 'year': 1900, 'month': 1, 'day': 1 }" [maxDate]="{ 'year': 2099, 'month': 12, 'day': 31 }"
                        >
                        <button class="btn btn-outline-secondary"
                                (click)="reperationfaerdigdatoDP.toggle()"
                                type="button">
                            <i class="fa fa-calendar fa-lg"></i>
                        </button>
                        <button class="btn btn-outline-secondary"
                                (click)='details.reperationfaerdigDato = dateService.todayNgbDateStruct()'
                                type="button">&nbsp;d.d.&nbsp;
                        </button>
                    </div>
                </div>
            </div>

            <div class="row pb-3">
                <div class="col-form-label col-4">
                    <label for="skadeanmeldelseModtaget">Skadeanmeldelse modtaget</label>
                </div>
                <div class="col-8">
                        <input type="checkbox" class="form-input-check-single"
                               name="skadeanmeldelseModtaget"
                               id="skadeanmeldelseModtaget"
                               [(ngModel)]="details.skadeanmeldelseModtaget"
                               autocomplete="off"/>
                </div>
            </div>
        </div>
    </div>
</fieldset>
