<fieldset ngForm="f" #f="ngForm">
    <h2>Ejer / Leasingselskab</h2>
    <div class="card">
        <div class="card-body">
            <div class="row pb-3">
                <div class="col-form-label col-4">
                    <label for="leasingCompanyCvrNr">CVR Nr.</label>
                </div>
                <div class="col">
                    <input [(ngModel)]="details.leasingselskab.cvrNr" class="form-control" type="text"
                        id="leasingCompanyCvrNr" name="leasingCompanyCvrNr" lcInputMask="int8" />
                </div>
            </div>
            <div class="row pb-3">
                <div class="col-form-label col-4">
                    <label for="leasingCompanyName">Navn</label>
                </div>
                <div class="col">
                    <input class="form-control text-uppercase" [(ngModel)]="details.leasingselskab.navn" type="text"
                        id="leasingCompanyName" name="leasingCompanyName" />
                </div>
            </div>
            <div class="row pb-3">
                <div class="col-form-label col-4">
                    <label for="leasingCompanyAddressLine1">Adresse</label>
                </div>
                <div class="col">
                    <input class="form-control text-uppercase" [(ngModel)]="details.leasingselskab.addressLine1" type="text"
                        maxlength="35" id="leasingCompanyAddressLine1" name="leasingCompanyAddressLine1" />
                </div>
            </div>
            <div class="row pb-3">
                <div class="col-form-label col-4">
                    <label for="leasingCompanyAddressLine2"></label>
                </div>
                <div class="col">
                    <input class="form-control text-uppercase" [(ngModel)]="details.leasingselskab.addressLine2" type="text"
                        maxlength="35" id="leasingCompanyAddressLine2" name="leasingCompanyAddressLine2" />
                </div>
            </div>
            <div class="row pb-3">
                <div class="col-form-label col-4">
                    <label for="leasingCompanyZipCode">Post nr.</label>
                </div>
                <div class="col">
                    <input class="form-control" [(ngModel)]="details.leasingselskab.postNr" (change)="postNrChanged()"
                        type="text" id="leasingCompanyZipCode" name="leasingCompanyZipCode" lcInputMask="int4" />
                </div>
            </div>
            <div class="row pb-3">
                <div class="col-form-label col-4">
                    <label for="leasingCompanyCity">By</label>
                </div>
                <div class="col">
                    <input class="form-control" [ngModel]="postNr?.navn" [readonly]="true" type="text"
                        id="leasingCompanyCity" name="leasingCompanyCity" />
                </div>
            </div>
        </div>
    </div>
</fieldset>
