import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {VideoLinkDTO} from '../dto/video-link-dto.model';

@Injectable({
  providedIn: 'root',
})
export class VideoLinkService {
  private videoLinkUrl = 'videolink';

  constructor(private http: HttpClient) {
  }

  public getAllByRole(): Observable<VideoLinkDTO[]> {
    return this.http.get<VideoLinkDTO[]>(this.videoLinkUrl);
  }

  public getAll(): Observable<VideoLinkDTO[]> {
    return this.http.get<VideoLinkDTO[]>(this.videoLinkUrl + '/all');
  }

  public create(newVideoLink: VideoLinkDTO): Observable<VideoLinkDTO> {
    return this.http.post<VideoLinkDTO>(this.videoLinkUrl, newVideoLink);
  }

  public update(updatedVideoLink: VideoLinkDTO): Observable<VideoLinkDTO> {
    return this.http.put<VideoLinkDTO>(this.videoLinkUrl + '/' + updatedVideoLink.id, updatedVideoLink);
  }

  public delete(id: number): Observable<any> {
    return this.http.delete(this.videoLinkUrl + '/' + id);
  }

  public search(text: string): Observable<VideoLinkDTO[]> {
    return this.http.get<VideoLinkDTO[]>(this.videoLinkUrl + '/search?text=' + encodeURI(text));
  }

}
