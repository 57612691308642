import {SkavuPositionValueDTO} from '../dto/skavu/skavu-position-value-dto.model';
import {StringUtils} from '../../shared/utils/string-utils';
import {isNullOrUndefined} from '../../shared/utils/object-utils';

export class SkavuPositionValue {
  nr: string;
  position: number;
  workText: string;
  hours: number;
  amount: number;
  sparePartPrice: number;
  nfgPct: number; //note: 10% == 10 and not 0.1 as it could be.
  lak: boolean;
  checked: boolean;
  extraTexts: string[] = [];

  static fromDTOs(dtos: SkavuPositionValueDTO[]): SkavuPositionValue[] {
    if (isNullOrUndefined(dtos)) {
      return null;
    }
    return dtos.map(dto => SkavuPositionValue.fromDTO(dto));
  }

  static fromDTO(dto: SkavuPositionValueDTO): SkavuPositionValue {
    const positionValue = new SkavuPositionValue();
    if (dto) {
      positionValue.amount = dto.amount;
      positionValue.hours = dto.hours;
      positionValue.lak = dto.lak;
      positionValue.nfgPct = dto.nfgPct;
      positionValue.nr = dto.nr;
      positionValue.position = dto.position;
      positionValue.sparePartPrice = dto.sparePartPrice;
      positionValue.workText = dto.workText;
      if (!isNullOrUndefined(dto.extraTexts)) {
        positionValue.extraTexts = dto.extraTexts.slice();
      }
      positionValue.checked = true;
    }
    return positionValue;
  }

  getNumberInput(field: string): string {
    if (isNullOrUndefined(this[field])) {
      return '';
    } else {
      return this[field].toString();
    }
  }

  setNumberInput(field: string, strValue: string): void {
    if (StringUtils.isEmpty(strValue)) {
      this[field] = null;
    } else {
      this[field] = Number.parseInt(strValue, 10);
    }
  }

  getNumberFloatInput(field: string): string {
    if (isNullOrUndefined(this[field])) {
      return '';
    } else {
      return this[field].toString().replace('.', ',');
    }
  }

  setNumberFloatInput(field: string, strValue: string): void {
    if (StringUtils.isEmpty(strValue)) {
      this[field] = null;
    } else {
      this[field] = Number.parseFloat(strValue.replace(',', '.')).toFixed(1);
    }
  }

  get hoursStr(): string {
    return this.getNumberFloatInput('hours')
  }

  set hoursStr(value: string) {
    this.setNumberFloatInput('hours', value);
  }

  get amountStr(): string {
    return this.getNumberInput('amount')
  }

  set amountStr(value: string) {
    this.setNumberInput('amount', value);
  }

  get sparePartPriceStr(): string {
    return this.getNumberInput('sparePartPrice')
  }

  set sparePartPriceStr(value: string) {
    this.setNumberInput('sparePartPrice', value);
  }

  get nfgPctStr(): string {
    return this.getNumberInput('nfgPct')
  }

  set nfgPctStr(value: string) {
    this.setNumberInput('nfgPct', value);
  }

  get hoursDisabled(): boolean {
    return !(this.checked && (isNullOrUndefined(this.amount)));
  }

  get amountDisabled(): boolean {
    return !(this.checked && (isNullOrUndefined(this.hours)));
  }

  toDTO(): SkavuPositionValueDTO {
    const dto = new SkavuPositionValueDTO();
    dto.amount = this.amount;
    dto.hours = this.hours;
    dto.lak = this.lak;
    dto.nfgPct = this.nfgPct;
    dto.nr = this.nr;
    dto.position = this.position;
    dto.sparePartPrice = this.sparePartPrice;
    dto.workText = this.workText.trim();
    dto.extraTexts = this.extraTexts.slice();
    return dto;
  }
}
