import {Pipe, PipeTransform} from '@angular/core';
import {UserState} from '../dto/user-state.model';
import {isNullOrUndefined} from '../utils/object-utils';

@Pipe({
  name: 'userState'
})
export class UserStatePipe implements PipeTransform {
  private readonly USER_STATE_MAPPINGS: { state: UserState, desc: string }[] = [
    {state: UserState.OK, desc: 'Ok'},
    {state: UserState.LUKKET, desc: 'Lukket'},
    {state: UserState.OPSAGT, desc: 'Opsagt'},
    {state: UserState.SLETTET, desc: 'Slettet'},
  ];

  transform(value: UserState): string {
    return !isNullOrUndefined(value) ? this.USER_STATE_MAPPINGS.find(v => v.state === value)?.desc : value;
  }
}
