import {NgbDateStruct, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import {ForsiDateTimeParserFormatter} from '../formatter/forsi-date-time.formatter';
import {UserType} from '../dto/user-type.model';
import {UserDTO} from '../dto/user-dto.model';

export class Notification {
  private static forsiDateTimeParserFormatter = new ForsiDateTimeParserFormatter();

  id: number;
  userType: UserType = null;
  text: string;
  link: string;
  envContext: string;
  createdBy: UserDTO;
  createdAt: number;
  activeFromDate: NgbDateStruct;
  activeFromTime: NgbTimeStruct;
  activeToDate: NgbDateStruct;
  activeToTime: NgbTimeStruct;

  public getActiveFrom(): number {
    const activeFrom = Notification.forsiDateTimeParserFormatter.format(this.activeFromDate, this.activeFromTime);
    return activeFrom ? activeFrom.getTime() : null
  }

  public getActiveTo(): number {
    const activeTo = Notification.forsiDateTimeParserFormatter.format(this.activeToDate, this.activeToTime);
    return activeTo ? activeTo.getTime() : null
  }
}
