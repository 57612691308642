import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {MatchSparePartsResponseDTO, SparePartMatchDTO} from '../dto/spare-part-match-response-dto.model';
import {
  SparePartProviderDTO
} from '../../shared/admin-assessor-org-spare-part-provider/dto/spare-part-provider-dto.model';
import {AutoflexEnabledDTO} from '../../shared/dto/autoflex-enabled-dto.model';
import {AutoflexSparePartSortOrder} from '../../shared/dto/autoflex-spare-part-sort-order.model';
import {SparePartBookResponseDTO} from '../dto/spare-part-book-response-dto.model';
import {AutoflexStatusDTO} from '../dto/autoflex-status-dto.model';
import {ClientStateDTO} from '../dto/client-state-dto.model';

@Injectable({
  providedIn: 'root',
})
export class AutoflexSparePartService {
  private serviceURL = 'autoflex-spare-part/';

  constructor(private http: HttpClient) {
  }

  isAutoflexSparePartEnabledForVK(selskabKode: string): Observable<AutoflexEnabledDTO> {
    return this.http.get<AutoflexEnabledDTO>(this.serviceURL + 'vk/enabledfor/' + encodeURIComponent(selskabKode));
  }

  hasActiveSparePartSuppliers(): Observable<AutoflexEnabledDTO> {
    return this.http.get<AutoflexEnabledDTO>(this.serviceURL + 'has-active-spare-part-suppliers');
  }

  getSparePartSuppliers(selskabKode: string): Observable<SparePartProviderDTO[]> {
    return this.http.get<SparePartProviderDTO[]>(this.serviceURL + 'active-spare-part-suppliers/' + encodeURIComponent(selskabKode));
  }

  initMatchSpareParts(clientState: ClientStateDTO): Observable<AutoflexStatusDTO> {
    return this.http.post<AutoflexStatusDTO>(this.serviceURL + 'init-match-spare-parts', clientState);
  }
  getSparePartsFromSupplier(autoflexStatus: AutoflexStatusDTO, supplierId: number): Observable<MatchSparePartsResponseDTO> {
    return this.http.post<MatchSparePartsResponseDTO>(this.serviceURL + 'match-spare-parts/' + supplierId, autoflexStatus);
  }

  getDefaultSortOrder(selskabKode: string): Observable<AutoflexSparePartSortOrder[]> {
    return this.http.get<AutoflexSparePartSortOrder[]>(this.serviceURL + 'default-sort-order/' + encodeURIComponent(selskabKode));
  }

  bookSpareParts(autoflexStatus: AutoflexStatusDTO, selection: SparePartMatchDTO[]): Observable<SparePartBookResponseDTO> {
    return this.http.post<SparePartBookResponseDTO>(this.serviceURL + 'book-spare-parts/' + autoflexStatus.id, selection);
  }

  getBookedSpareParts(autoflexStatus: AutoflexStatusDTO): Observable<MatchSparePartsResponseDTO> {
    return this.http.get<MatchSparePartsResponseDTO>(this.serviceURL + 'booked-spare-parts/' + autoflexStatus.id);
  }
  getBookedSparePartsByToken(token: string): Observable<MatchSparePartsResponseDTO> {
    return this.http.get<MatchSparePartsResponseDTO>(this.serviceURL + 'booked-spare-parts-by-token/' + token);
  }
  getOriginalOffering(autoflexStatus: AutoflexStatusDTO): Observable<MatchSparePartsResponseDTO> {
    return this.http.get<MatchSparePartsResponseDTO>(this.serviceURL + 'spare-parts-on-vktilbud/' + autoflexStatus.id);
  }

  existsOnVkTilbud(proformaOrdreNummer: string): Observable<AutoflexEnabledDTO> {
    return this.http.get<AutoflexEnabledDTO>(this.serviceURL + 'exists-on-vktilbud/' + proformaOrdreNummer);
  }
}
