import {Component, Input, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AssessmentMethodRelation} from '../assessment-method.relation';
import {TakserFormDTO} from '../../../dto/takser-form-dto.model';

@Component({
  templateUrl: './assessor-org-assessment-method-relation-edit-popup.component.html',
})
export class AssessorOrgAssessmentMethodRelationEditPopupComponent {

  @ViewChild('form', {static: true}) public form: NgForm;

  @Input('selectableAssessorOrgs')
  public selectableAssessmentMethods: TakserFormDTO[];

  public selectedAssessmentMethod: TakserFormDTO;

  @Input()
  public assessmentMethodRelation: AssessmentMethodRelation;

  public isEditingExisingRelation(): boolean {
    return this.assessmentMethodRelation && this.assessmentMethodRelation.persisted;
  }

  constructor(private activeModal: NgbActiveModal) {
  }

  public get title(): string {
    const mode = this.isEditingExisingRelation() ? 'Rediger' : 'Tilføj';
    return `${mode} takserform for taksatororganisation`;
  }

  public get submitLabel(): string {
    return this.isEditingExisingRelation() ? 'Gem ændringer' : 'Tilføj takserform';
  }

  public didClickCancel(): void {
    this.activeModal.dismiss(false);
  }

  public didClickSubmit(): void {
    this.activeModal.close(this.assessmentMethodRelation)
  }

  public disableSubmit(): boolean {
    return !this.form.valid;
  }

  public onSelectAssessorOrg(): void {
    if (this.selectedAssessmentMethod) {
      this.assessmentMethodRelation.code = this.selectedAssessmentMethod.kode;
    } else {
      this.assessmentMethodRelation.code = null;
    }
  }
}
