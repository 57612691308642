import {B2BAdviseTimelineDTO} from '../../shared/dto/b2b-advise-timeline-dto.model';

export class DraftAdviseDTO {
  id: number;
  draftId: number;
  toVkNr: number;
  toVkName: string;
  updatedAt: number;
  timeline: B2BAdviseTimelineDTO[];
}

