import {Component, HostListener, OnDestroy} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {PrincipalService} from '../../..';
import {AttachmentService} from '../attachment.service';
import {Subject} from 'rxjs';
import {AttachmentDTO} from '../../../dto/attachment-dto.model';
import AttachmentUtil from '../../../utils/attachment-utils';
import {HttpClient} from '@angular/common/http';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {isNullOrUndefined} from '../../../utils/object-utils';

export enum PostProcessStatus {
  UNDETERMINED = 'UNDETERMINED',
  PROCESSED = 'PROCESSED',
  NOT_PROCESSED = 'NOT_PROCESSED'
}

@Component({
  templateUrl: './attachment-gallery.component.html',
  styleUrls: [
    'attachment-gallery.component.scss'
  ]
})
export class AttachmentGalleryComponent implements OnDestroy {
  readonly = true;
  reportKey: string;
  registrationNumber: string;
  postProcessStatus: Map<number, PostProcessStatus> = new Map<number, PostProcessStatus>();
  galleryItems: AttachmentDTO[];
  currentGalleryItem: AttachmentDTO;
  galleryIndex: number;
  updateAttachmentRemark: Subject<AttachmentDTO>;
  currentGalleryItemInitialRemark: string;

  onShowOriginalImageMousePos = null;
  isScrolling = false;
  presentableText = '';
  showOriginalSize = false;
  private unsubscribe$ = new Subject<void>();

  constructor(private activeModal: NgbActiveModal, public principal: PrincipalService, private attachmentService: AttachmentService, private httpClient: HttpClient) {
  }

  initializeGallery(galleryItems: AttachmentDTO[], currentItem: AttachmentDTO, readonly: boolean, reportKey: string, registrationNumber: string, updateAttachmentRemark: Subject<AttachmentDTO>): void {
    this.readonly = readonly
    this.galleryItems = galleryItems;
    this.galleryItems.filter(attachment => this.isVideo(attachment)).forEach(attachment => {
      this.postProcessStatus.set(attachment.id, PostProcessStatus.UNDETERMINED);
    });
    this.setCurrentItem(currentItem);
    this.reportKey = reportKey
    this.registrationNumber = registrationNumber
    this.galleryIndex = galleryItems.findIndex((attachment) => attachment.id === currentItem.id);
    this.showOriginalSize = false;
    this.updateAttachmentRemark = updateAttachmentRemark;
  }

  private setCurrentItem(currentItem: AttachmentDTO): void {
    this.currentGalleryItem = currentItem;
    this.currentGalleryItemInitialRemark = currentItem.remark;
    this.preparePresentableText(this.currentGalleryItem);

    if (!this.isPostProcessed(currentItem)) {
      this.attachmentService.getAttachmentPostProcessing(this.currentGalleryItem.id, this.currentGalleryItem.accessToken).pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe(attachmentPostProcessStatus => {
        this.postProcessStatus.set(attachmentPostProcessStatus.attachmentId, attachmentPostProcessStatus.beingPostProcessed ? PostProcessStatus.NOT_PROCESSED : PostProcessStatus.PROCESSED);
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  public closeModal(): void {
    if (this.activeModal) {
      this.saveRemarkForCurrentItemIfChanged();
      this.activeModal.dismiss();
    }
  }

  public showMetadata(attachmentId: number, accessToken: string): void {
    if (this.principal.isTaksator()) {
      this.attachmentService.getAttachmentMetaData(attachmentId, accessToken)
        .pipe(takeUntil(this.unsubscribe$)).subscribe(metaData => {
        alert(JSON.stringify(metaData));
      });
    }
  }

  public isImage(attachment: AttachmentDTO): boolean {
    return AttachmentUtil.isImage(attachment);
  }

  isVideo(attachment: AttachmentDTO): boolean {
    return AttachmentUtil.isVideo(attachment);
  }
  isHeif(attachment: AttachmentDTO): boolean {
    return AttachmentUtil.isHeif(attachment);
  }
  @HostListener('document:keydown.arrowright', ['$event'])
  nextGalleryItem(): void {
    const nextIndex = this.galleryIndex + 1;
    if (this.galleryItems) {
      if (nextIndex < this.galleryItems.length) {
        this.galleryIndex = nextIndex;
        this.saveRemarkForCurrentItemIfChanged();
        this.setCurrentItem(this.galleryItems[this.galleryIndex])
        this.showOriginalSize = false;
      }
    }
  }

  @HostListener('document:keydown.arrowleft', ['$event'])
  previousGalleryItem(): void {
    const nextIndex = this.galleryIndex - 1;
    if (this.galleryItems) {
      if (nextIndex >= 0) {
        this.galleryIndex = nextIndex;
        this.saveRemarkForCurrentItemIfChanged();
        this.setCurrentItem(this.galleryItems[this.galleryIndex])
        this.showOriginalSize = false;
      }
    }
  }

  @HostListener('document:keydown.escape', ['$event'])
  escapeKey(): void {
    this.saveRemarkForCurrentItemIfChanged();
  }

  public getNonImageUrl(attachment: AttachmentDTO, download: boolean): string {
    return AttachmentUtil.composeNonImageAttachmentUrl(attachment, download);
  }

  public getImageUrl(attachment: AttachmentDTO, thumbnail: boolean, download: boolean): string {
    return AttachmentUtil.composeImageAttachmentUrl(attachment, thumbnail, download);
  }

  getGalleryImageStyle(galleryItem: AttachmentDTO): object {
    if (!this.showOriginalSize) {
      return {'background-image': 'url(\'' + this.getImageUrl(galleryItem, false, false) + '\'), url(\'' + this.getImageUrl(galleryItem, true, false) + '\')'};
    } else if (this.onShowOriginalImageMousePos) {
      return {cursor: 'grabbing'};
    } else {
      return {};
    }
  }

  public preparePresentableText(selectedAttachment: AttachmentDTO): void {
    this.presentableText = null;
    if (this.isPresentableText(selectedAttachment)) {
      const url = AttachmentUtil.composeNonImageAttachmentPath(selectedAttachment, false);
      this.httpClient.get(url, {responseType: 'text'}).pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
        this.presentableText = data;
      });
    }
  }

  public isPdf(attachment: AttachmentDTO): boolean {
    return attachment && attachment.contentType && attachment.contentType === 'application/pdf';
  }

  public isPresentableText(attachment: AttachmentDTO): boolean {
    return AttachmentUtil.isText(attachment);
  }

  public isNotPresentable(attachment: AttachmentDTO): boolean {
    return !this.isPdf(attachment) && !this.isImage(attachment) && !this.isPresentableText(attachment) && !this.isVideo(attachment);
  }


  public getNotPresentableFileClass(attachment: AttachmentDTO): string {
    return AttachmentUtil.getNotPresentableFileClass(attachment);
  }

  scrollImage(event: MouseEvent, modalScrollElement: HTMLElement): void {
    const prevPos = this.onShowOriginalImageMousePos;
    const newPos = {left: event.clientX, top: event.clientY};
    if (prevPos) {
      this.isScrolling = true;
      modalScrollElement.scrollBy({left: prevPos.left - newPos.left, top: prevPos.top - newPos.top});
      this.onShowOriginalImageMousePos = newPos;
    }
  }

  startScroll(event: MouseEvent): boolean {
    if (this.showOriginalSize) {
      this.onShowOriginalImageMousePos = {left: event.clientX, top: event.clientY};
    }
    return false;
  }

  stopScroll(event: MouseEvent): void {
    this.onShowOriginalImageMousePos = null;
  }

  toggleShowOriginalSize(event: MouseEvent): void {
    if (!this.isScrolling) {
      this.showOriginalSize = !this.showOriginalSize;
    } else {
      this.isScrolling = false;
    }
  }

  public isReadonly(attachment: AttachmentDTO): boolean {
    return this.readonly || attachment.report;
  }

  public isBeingPostProcessed(attachment: AttachmentDTO): boolean {
    return this.getPostProcessStatus(attachment) === PostProcessStatus.NOT_PROCESSED;
  }

  public isPostProcessed(attachment: AttachmentDTO): boolean {
    return this.getPostProcessStatus(attachment) === PostProcessStatus.PROCESSED;
  }

  public getPostProcessStatus(attachment: AttachmentDTO): PostProcessStatus {
    const postProcessStatus = this.postProcessStatus.get(attachment.id);
    return isNullOrUndefined(postProcessStatus) ? PostProcessStatus.PROCESSED : postProcessStatus;
  }

  /**
   * For some reason IE and Firefox does not register a change using (change) for the remark textarea
   * if the modal is closed by using the escape key.
   * Instead this method is manually called from events that close or change current item.
   * If using (change) for the remark textarea, in addition to this. Chrome will update the remark twice,
   * so it is not possible to do both.
   */
  private saveRemarkForCurrentItemIfChanged(): void {
    if (this.currentGalleryItem && this.currentGalleryItem.remark !== this.currentGalleryItemInitialRemark) {
      if (!this.isReadonly(this.currentGalleryItem)) {
        this.updateAttachmentRemark.next(this.currentGalleryItem);
      }
    }
  }


}
