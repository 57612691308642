import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbDropdown, NgbPaginationConfig} from '@ng-bootstrap/ng-bootstrap';
import {ReportService} from '../service/report.service';
import {RapportInfoDTO} from '..';
import {BootstrapAlertType, BootstrapGrowlService} from '../../shared/ui/ngx-bootstrap-growl';
import {BusinessToBusinessService} from '../../b2b/service/business-to-business.service';
import {BusinessToBusinessDTO} from '../../b2b/model/business-to-business-dto.model';
import {AttachmentApprovalModalService} from '../ui/attachment-approval/attachment-approval-modal.service';
import {ListUtils} from 'app/shared/utils/list-utils';
import {StringUtils} from '../../shared/utils/string-utils';
import {PrincipalService} from '../../shared';
import {Router} from '@angular/router';
import {AttachmentUseCase} from '../../shared/model/attachment-use-case.model';
import {SettingsService} from '../../shared/service/settings.service';
import {FunctionConfigurationDTO} from '../../settings/model/function-configuration-dto.model';
import {NewDraftsPopupService} from '../../shared/modals/new-drafts/new-drafts-popup.service';
import {StorageService} from '../../shared/service/storage.service';
import {ReportListConfig} from '../model/report-list.config';
import {MessageDTO} from 'app/shared/dto/message-dto.model';
import {CarSaleList} from '../../shared/model/carsalelist.model';
import URLBuilder from '../../shared/utils/url-builder';
import {ReportCategory} from '../../shared/model/report-category.model';
import {RapportListDTO} from '../model/report-list-dto.model';
import {AppRole} from '../../shared/service/auth/app-roles.model';
import {SearchProperties} from '../../shared/ui/report-search-input/SearchProperties';
import {TemporaryAttachmentApprovalModel} from '../../shared/model/temporary-attachment-approval.model';
import {formatDate} from '@angular/common';
import {takeUntil} from 'rxjs/operators';
import {LoadingSpinnerUtil} from '../../shared/utils/loading-spinner-util';
import {BusinessToBusinessViewModel} from './business-to-business-view.model';
import {IntegrationUserDTO} from '../../shared/dto/integration-user-dto.model';
import {UserService} from '../../shared/service/user.service';
import {CreateMessageDTO} from '../../shared/ui/message/dto/create-message-dto.model';
import {RapportType} from '../../shared/model/rapport-type.model';
import AttachmentUtil from '../../shared/utils/attachment-utils';
import {Subject, Subscription} from 'rxjs';
import {isNullOrUndefined} from '../../shared/utils/object-utils';
import {ReportAcceptStatus} from '../model/report-accept-status';
import {AppVisibilityService} from '../../shared/service/core/app-visibility.service';
import {AttachmentSummaryDTO} from '../../shared/dto/attachment-summary-dto.model';
import {TextLibraryTypeEnum} from '../../shared/service/text-library-type.enum';

@Component({
  templateUrl: './report-list.component.html',
  styleUrls: [
    './report-list.scss'
  ],
  providers: [ReportService, BusinessToBusinessService, NgbDropdown, NewDraftsPopupService, LoadingSpinnerUtil],
})
export class ReportListComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  public defaultImage = URLBuilder.basePath + 'content/images/defaultImage.png';
  searchLimitMessage: string;
  searchInputOpen: boolean;
  private keydownEnter: boolean;
  public showCardImages: boolean;
  textLibraryType = TextLibraryTypeEnum.BESKED;

  reports: RapportListDTO[];
  filteredReports: RapportListDTO[];
  currentPage: RapportListDTO[];
  tilfoejBeskedShow = null;
  b2bSamArbShow = null;
  taksB2bSamArbShow = null;
  bilagShow = null;
  showMore = null;
  showNewReportTypes = null;
  print: RapportInfoDTO;
  attachments = {};
  messages = {};
  b2bIntegrations = {};
  orderProp: string;
  orderReverse: boolean;
  orderType = 'string';
  itemsPerPage: any;
  totalItems: number;
  filteredItems: number;
  page: number;
  private restoredPage: number;
  _selectedIndex: number;
  functionConfiguration: FunctionConfigurationDTO;
  carSaleList = new CarSaleList();
  currentSearchProperties: SearchProperties = new SearchProperties();
  currentReportSubscription: Subscription;
  integrationUsers: IntegrationUserDTO[];

  latestSearchCriteria: string;
  attachmentUseCase = AttachmentUseCase.Report;
  currentFilter: string;
  lastReportResponse = 0;
  refreshInterval = 5 * 60 * 1000;
  public INTEGRATION_PART = {LAK: 'LAK', PLANNING: 'TIDSPLANLAEGNING'};
  public showAcceptInLegend: boolean;

  @Input()
  set selectedIndex(selectedIndex: number) {
    this._selectedIndex = selectedIndex;
    const reportListConfig = this.storageService.getReportListConfig();
    reportListConfig.selectedIndex = selectedIndex;
    this.storageService.storeReportListConfig(reportListConfig);
  }

  get selectedIndex(): number {
    return this._selectedIndex;
  }

  constructor(private principalService: PrincipalService,
              public reportService: ReportService,
              private businessToBusinessService: BusinessToBusinessService,
              private attachmentApprovalModalService: AttachmentApprovalModalService,
              private paginationConfig: NgbPaginationConfig,
              private bootstrapGrowlService: BootstrapGrowlService,
              private settingsService: SettingsService,
              private router: Router,
              private newDraftsPopupService: NewDraftsPopupService,
              private storageService: StorageService,
              public spinnerUtil: LoadingSpinnerUtil,
              private appVisibilityService: AppVisibilityService,
              private userService: UserService) {
    this.paginationConfig.boundaryLinks = true;
    this.paginationConfig.maxSize = 5;
    this.paginationConfig.pageSize = 20;
    this.paginationConfig.size = 'sm';
    this.itemsPerPage = this.paginationConfig.pageSize;
    this.reports = [];
    this.filteredReports = [];
    this.currentPage = [];
  }

  private getSearchCriteria(searchProperties: SearchProperties): string {
    let criteria = '';
    if (searchProperties != null) {
      [searchProperties.searchVkKey, searchProperties.searchTaksKey, searchProperties.searchRegNr, searchProperties.searchDato, searchProperties.searchSelskab, searchProperties.searchState, searchProperties.searchVin].forEach(str => {
        if (!StringUtils.isEmpty(str)) {
          criteria += str;
        }
      });
    }
    return criteria;
  }

  readyForApproval(report: RapportListDTO, attachmentApprovalModel: TemporaryAttachmentApprovalModel): void {
    if (this.principalService.hasAnyAuthorityDirect([AppRole.VK]) && (report.vkReportKey)) {
      this.attachmentApprovalModalService.open(report.id, report.vkReportKey, null, attachmentApprovalModel)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(ok => this.approved(report, ok));
    } else if (this.principalService.hasAnyAuthorityDirect([AppRole.TAKS]) && (report.taksatorReportKey)) {
      this.attachmentApprovalModalService.open(report.id, null, report.taksatorReportKey, attachmentApprovalModel)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(ok => this.approved(report, ok));
    }
  }

  approved(report: RapportListDTO, ok: boolean): void {
    if (ok) {
      this.bootstrapGrowlService.addAlert('Bilag er godkendt', BootstrapAlertType.SUCCESS);
      //clear cache to ensure we fetch correct attachment summary next time
      this.reportService.clearCache();
      this.getAttachmentsForReport(report);
    } else {
      this.bootstrapGrowlService.addAlert('Bilag er ikke godkendt', BootstrapAlertType.WARNING);
    }
  }

  doSorting(): void {
    const filter = StringUtils.isNotEmpty(this.currentFilter) ? this.currentFilter.toUpperCase() : '';
    this.totalItems = this.reports.length;
    this.filteredReports = ListUtils.filterAndSort<RapportListDTO>(this.reports, filter, this.isInFilter, this.orderProp, this.orderReverse, this.orderType);
    this.filteredItems = this.filteredReports.length;
    // reset pagination when filtering
    this.loadPage(this.page);
  }

  getReports(searchProperties: SearchProperties): void {
    this.spinnerUtil.startLoading();
    this.latestSearchCriteria = this.getSearchCriteria(searchProperties);
    if (StringUtils.isEmpty(this.latestSearchCriteria)) {
      this.currentReportSubscription = this.reportService.findAllReports()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          reports => this.gotReportResult(reports, false, '', searchProperties),
          () => this.gotError());
    } else {
      this.currentReportSubscription = this.reportService.findReports(
        searchProperties.searchVkKey,
        searchProperties.searchTaksKey,
        searchProperties.searchRegNr,
        searchProperties.searchDato,
        searchProperties.searchSelskab,
        searchProperties.searchState,
        searchProperties.searchVin
      ).pipe(takeUntil(this.unsubscribe$)).subscribe(
        searchResult => {
          this.gotReportResult(searchResult.rapportDTOs, searchResult.limitReached, searchResult.searchLimit, searchProperties);
        },
        () => this.gotError());
    }
  }

  gotError(): void {
    this.bootstrapGrowlService.addAlert('Kunne ikke søge rapporter', BootstrapAlertType.WARNING);
    this.reports = [];
    this.spinnerUtil.stopLoading();
  }

  gotReportResult(reports: RapportListDTO[], limitReached: boolean, searchLimit: string, searchProperties: SearchProperties): void {
    this.lastReportResponse = Date.now();
    this.currentSearchProperties = searchProperties;
    this.searchLimitMessage = '';

    if (limitReached) {
      //The search limit is reached (too many reports)
      this.searchLimitMessage = 'Resultatet er begrænset til ' + searchLimit + ' rapporter!';
    }

    if (this.isReportListUpdated(reports)) {
      this.attachments = {};
      this.b2bIntegrations = {};
      this.reports = reports;
      this.doSorting();
    } else {
      this.getAllMessages(true);
    }
    this.showAcceptInLegend = reports.findIndex(r => r.acceptStatus !== null) > -1;


    this.spinnerUtil.stopLoading();
  }

  private isReportListUpdated(newReportList: RapportListDTO[]): boolean {
    if (!this.reports || this.reports.length === 0) {
      return true;
    }

    if (this.reports.length !== newReportList.length) {
      return true;
    }

    const currentSortedListAsString = JSON.stringify(ListUtils.sort<RapportListDTO>(this.reports, 'approvedDate', true, null));
    const newSortedListAsString = JSON.stringify(ListUtils.sort<RapportListDTO>(newReportList, 'approvedDate', true, null));
    return newSortedListAsString !== currentSortedListAsString;
  }

  getAllMessages(reloadAll: boolean): void {
    for (const report of this.currentPage) {
      if (reloadAll || !Array.isArray(this.messages[this.getCombinedKey(report)])) {
        this.getMessagesForReport(report);
      }
    }
  }

  getMessagesForReport(report: RapportListDTO): void {
    this.reportService.getMessages(report.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(messages => {
        this.messages[this.getCombinedKey(report)] = messages;
      });
  }

  sendMessage(report: RapportListDTO, createMessageDTO: CreateMessageDTO): void {
    this.reportService.sendMessage(report.id, createMessageDTO)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.getMessagesForReport(report);
        this.bootstrapGrowlService.addAlert('Besked sendt', BootstrapAlertType.SUCCESS, 3000);
      });
  }

  getB2BIntegrationsForKey(reportKey: string): void {
    this.businessToBusinessService.getIntegrationData(reportKey)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(integrationData => {
        this.b2bIntegrations[reportKey] = new BusinessToBusinessViewModel(integrationData);
      });
  }

  getB2BIntegrationData(reportKey: string): BusinessToBusinessViewModel {
    let data = this.b2bIntegrations[reportKey];
    if (!data) {
      data = new BusinessToBusinessViewModel(new BusinessToBusinessDTO());
    }
    return data;
  }

  hasB2BIntegrationData(reportKey: string): boolean {
    const data = this.getB2BIntegrationData(reportKey);
    return !!(data.vkNrLak || data.vkNrCabplan);
  }

  addB2BIntegration(reportKey: string, integrationPart: string): void {
    const b2bViewModel = this.getB2BIntegrationData(reportKey);
    const vkNr = integrationPart === this.INTEGRATION_PART.LAK ? b2bViewModel.inputVkNrLak : b2bViewModel.inputVkNrCabplan;
    this.businessToBusinessService.saveIntegrationData(reportKey, integrationPart, vkNr)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(result => {
        this.getB2BIntegrationsForKey(reportKey);
        this.bootstrapGrowlService.addAlert(result.message, result.integrationWentOk ? BootstrapAlertType.SUCCESS : BootstrapAlertType.DANGER, 3000);
      });

  }

  removeB2BIntegration(reportKey: string, integrationPart: string): void {
    this.businessToBusinessService.removeIntegrationData(reportKey, integrationPart)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(result => {
        this.getB2BIntegrationsForKey(reportKey);
        this.bootstrapGrowlService.addAlert('Integration fjernet', BootstrapAlertType.SUCCESS, 3000);
      });
  }

  showPrint(report: RapportListDTO): void {
    this.router.navigate(['report', 'viewer', report.id]);
  }

  public canDoB2B(report: RapportListDTO): boolean {
    const canDoB2BStates = ['GODKENDT', 'GODKENDT_M_FORBEHOLD', 'GODKENDT_M_AENDRINGER'];
    return report.state && canDoB2BStates.indexOf(report.state) > -1;
  }

  public hasThumbnail(report: RapportListDTO): boolean {
    const reportKey = this.getCombinedKey(report);
    return this.attachments[reportKey] && this.attachments[reportKey].thumbnailUrl;
  }

  public getThumbnailUrl(report: RapportListDTO): string {
    return AttachmentUtil.composeThumbnailUrl(this.attachments[this.getCombinedKey(report)]);
  }

  newReportTypesAllowed(report: RapportListDTO): boolean {
    return ReportCategory.possibleToCreateNewReport(this.getSchemaByReport(report));
  }

  getCombinedKey(report: RapportListDTO): string {
    return (report.taksatorReportKey ? report.taksatorReportKey : '') + (report.vkReportKey ? report.vkReportKey : '');
  }

  getReportKey(report: RapportListDTO): string {
    let reportKey = report.taksatorReportKey;
    if (this.principalService.isVK() && StringUtils.isNotEmpty(report.vkReportKey)) {
      reportKey = report.vkReportKey;
    }
    return reportKey;
  }

  getAttachmentCount(reportCombinedKey): number {
    return this.attachments[reportCombinedKey] ? this.attachments[reportCombinedKey].count : 0;
  }
  getAttachmentSummary(reportCombinedKey): AttachmentSummaryDTO {
    return this.attachments[reportCombinedKey];
  }

  getMessageCount(combinedKey): string {
    if (!this.messages[combinedKey]) {
      return ' ';
    } else {
      return this.messages[combinedKey].length;
    }
  }

  getUnreadMessageCount(combinedKey): number {
    let messageCount = 0;
    if (this.messages[combinedKey]) {
      this.messages[combinedKey].forEach(message => {
        if (!message.read && this.isMyMessage(message)) {
          messageCount++;
        }
      });
    }
    return messageCount;
  }

  hasUnreadMessages(combinedKey): boolean {
    return this.getUnreadMessageCount(combinedKey) > 0;
  }

  isMyMessage(message: MessageDTO): boolean {
    if (message && message.from) {
      if (this.principalService.isVK()) {
        return message.to.organisationCode === this.principalService.getOrganizationCode();
      } else {
        return message.to.username === this.principalService.getUsername();
      }
    }
    return false;
  }

  isToggleAttachmentsDisabled(report: RapportListDTO): boolean {
    return this.isAttachmentsReadonly(report) && this.getAttachmentCount(this.getCombinedKey(report)) === 0
  }

  toggleShowBilag(report: RapportListDTO): void {
    if (!this.isToggleAttachmentsDisabled(report)) {
      const combinedKey = this.getCombinedKey(report);
      const before: string = this.bilagShow;
      this.hideAllSubRows(combinedKey);

      // show bilag for chosen report
      this.bilagShow = before === combinedKey ? null : combinedKey;
      this.showMore = combinedKey;
    }
  }

  toggleShowNewReportTypes(report: RapportListDTO): void {
    if (!this.newReportTypesAllowed(report)) {
      return;
    }
    const before: string = this.showNewReportTypes;
    const combinedKey = this.getCombinedKey(report);
    this.hideAllSubRows(combinedKey);

    // show new report types for chosen report
    this.showNewReportTypes = before === combinedKey ? null : combinedKey;
    this.showMore = combinedKey;
  }

  toggleShowMessages(report: RapportListDTO): void {
    const before: string = this.tilfoejBeskedShow;
    const combinedKey = this.getCombinedKey(report);
    this.hideAllSubRows(combinedKey);

    // show besked for chosen report
    this.tilfoejBeskedShow = before ? null : combinedKey;
    this.showMore = combinedKey;
  }

  toggleShowB2B(report: RapportListDTO): void {
    if (this.canDoB2B(report)) {
      const before: string = this.b2bSamArbShow;
      const combinedKey = this.getCombinedKey(report);
      this.hideAllSubRows(combinedKey);

      // show b2b for chosen report
      this.b2bSamArbShow = before === combinedKey ? null : combinedKey;
      this.showMore = combinedKey;
    }
  }

  toggleShowTaksB2B(report: RapportListDTO): void {
    if (this.carSaleList.carSaleSupportedByReportType(report.taksatorReportKey)) {
      const before: string = this.taksB2bSamArbShow;
      const combinedKey = this.getCombinedKey(report);
      this.hideAllSubRows(combinedKey);

      // show b2b for chosen report
      this.taksB2bSamArbShow = before === combinedKey ? null : combinedKey;
      this.showMore = combinedKey;
    }
  }

  private hideAllSubRows(combinedKey: string): void {
    this.b2bSamArbShow = null;
    this.taksB2bSamArbShow = null;
    this.tilfoejBeskedShow = null;
    this.showNewReportTypes = null;
    this.bilagShow = null;
    this.showMore = this.showMore !== combinedKey ? null : combinedKey;
  }

  getAttachmentsForReport(report: RapportListDTO): void {
    let reportKey = report.taksatorReportKey;

    if (this.principalService.isVK()) {
      if (report.vkReportKey) {
        reportKey = report.vkReportKey;
      }
    }
    this.reportService.getAttachmentSummary(reportKey)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(attachmentSummary => {
        this.attachments[this.getCombinedKey(report)] = attachmentSummary;
      });
  }

  isAttachmentsReadonly(report: RapportListDTO): boolean {
    //VK is not allowed to upload attachments on a report assigned by an assessor
    return this.principalService.isVK() && StringUtils.isEmpty(report.vkReportKey)
  }

  toggleShowMore(report: RapportListDTO): void {
    const combinedKey = this.getCombinedKey(report);
    this.showMore = this.showMore === combinedKey ? null : combinedKey;
    this.taksB2bSamArbShow = !this.showMore || this.taksB2bSamArbShow !== combinedKey ? null : combinedKey;
    this.b2bSamArbShow = !this.showMore || this.b2bSamArbShow !== combinedKey ? null : combinedKey;
    this.bilagShow = !this.showMore || this.bilagShow !== combinedKey ? null : combinedKey;
    this.tilfoejBeskedShow = !this.showMore || this.tilfoejBeskedShow !== combinedKey ? null : combinedKey;
  }

  ngOnInit(): void {
    this.settingsService.getFunctionConfiguration()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(functionConfiguration => this.functionConfiguration = functionConfiguration);

    if (this.principalService.isTaksator()) {
      this.userService.getAssessorOrgIntegrationUserRelations()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(relations => {
          this.integrationUsers = relations.map(relation => relation.integrationUser);
        });
    }
    this.initializeConfig();
    this.getReports(this.currentSearchProperties);
    this.startReportUpdateTimer();
    this.newDraftsPopupService.initIncommingDraftsPopup(() => this.router.navigate(['draft']));
  }

  private initializeConfig(): void {
    const reportListConfig = this.storageService.getReportListConfig();
    this.currentSearchProperties = new SearchProperties();
    if (reportListConfig != null) {
      this.restoredPage = reportListConfig.page;
      this._selectedIndex = reportListConfig.selectedIndex;
      this.orderProp = reportListConfig.orderProp;
      this.orderReverse = reportListConfig.orderReverse;
      this.orderType = reportListConfig.orderType;
      this.currentSearchProperties = Object.assign(new SearchProperties(), reportListConfig.searchProperties);
      this.currentFilter = reportListConfig.filter;
    } else {
      // set defaults
      this.page = 1;
      this._selectedIndex = 0;
      this.orderProp = 'approvedDate';
      this.orderReverse = true;

      // store defaults
      this.storeCurrentListConfig();
    }
  }

  private startReportUpdateTimer(): void {
    this.appVisibilityService.forsiVisibilityInterval(10_000, 10_000).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      if (!this.spinnerUtil.isLoading && !this.searchInputOpen) {
        if (this.lastReportResponse < Date.now() - this.refreshInterval) {
          this.getReports(this.currentSearchProperties);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
    this.searchLimitMessage = '';
  }

  isAuthenticated(): boolean {
    return this.principalService.isAuthenticated();
  }

  loadPage(page: number): void {
    this.page = page || this.restoredPage;
    let start = (this.itemsPerPage * this.page) - this.itemsPerPage;
    if (this.filteredReports.length - 1 < start) {
      start = 0;
      this.page = 1;
    }
    const end = (this.itemsPerPage * this.page);
    this.currentPage = this.filteredReports.slice(start, end);

    this.getAllMessages(true);

    for (const report of this.currentPage) {
      const combinedKey = this.getCombinedKey(report);
      if (!this.attachments[combinedKey]) {
        this.getAttachmentsForReport(report);
      }
      if (this.principalService.isVK() && report.taksatorReportKey && !this.b2bIntegrations[report.taksatorReportKey]) {
        this.getB2BIntegrationsForKey(report.taksatorReportKey);
      }
    }

    this._selectedIndex = 0;
    this.storeCurrentListConfig();

    if (this.principalService.isTaksator()) {
      this.getCarSales();
    }
  }

  getStateClassFromReport(report: RapportListDTO): string {
    const state = report.cancelled ? 'ANNULLERET' : report.state;
    return this.getStateClass(state);
  }

  getStateClass(state: string): string {
    let stateClass = 'fa ';
    let color = '';
    switch (state) {
      case 'UNKNOWN':
        stateClass += 'fa-question';
        color = 'black';
        break;
      case 'ADVIS':
        stateClass += 'fa-exclamation';
        color = 'black';
        break;
      case 'OVERTAGET':
        stateClass += 'fa-exchange';
        color = 'black';
        break;
      case 'AFVIST':
        stateClass += 'fa-thumbs-down';
        color = 'red';
        break;
      case 'ANNULLERET':
        stateClass += 'fa-exclamation';
        color = 'red';
        break;
      case 'GODKENDT':
        stateClass += 'fa-thumbs-up';
        color = 'green';
        break;
      case 'GODKENDT_M_AENDRINGER':
        stateClass += 'fa-thumbs-o-up';
        color = 'green';
        break;
      case 'GODKENDT_M_FORBEHOLD':
        stateClass += 'fa-exclamation';
        color = 'light-green';
        break;
      case 'FORELOEBIG':
        stateClass += 'fa-circle';
        color = 'yellow';
        break;
      case 'BACKUP':
        stateClass += 'fa-life-ring';
        color = 'yellow';
        break;
      case 'TOTALSKADE':
        stateClass += 'fa-exclamation';
        color = 'yellow';
        break;
      case 'SOLGT_TIL_GENOPBYGNING':
        stateClass += 'fa-gavel';
        color = 'yellow';
        break;
      default:
        stateClass += 'fa-question';
    }

    stateClass += ' fa-xl report-state-' + color;

    return stateClass;
  }

  getTypeClass(type: string): string {
    const cssClassName = ReportCategory.iconCssClass(type);
    const isEnabled = ReportCategory.enabled(type);
    return 'fa ' + cssClassName + ' fa-xl report-type-' + (isEnabled ? 'green' : 'red');
  }

  getTypeClassByReport(report: RapportListDTO): string {
    return this.getTypeClass(this.getSchemaByReport(report));
  }

  getName(report: RapportListDTO): string {
    return ReportCategory.nameFrom(this.getSchemaByReport(report));
  }

  private getSchemaByReport(report: RapportListDTO): string {
    return report.vkReportId != null ? report.vkReportSchema : report.taksatorReportSchema;
  }

  keyEventHandler(event: KeyboardEvent): void {
    if (event.repeat) {
      return;
    }

    switch (event.key) {
      case 'Enter':
        // The component shown if "tilfoejBeskedShow" is true has a textarea where users want to be allowed to use the ENTER key.
        // Since most of the report-list page is listening for keyup events this would trigger showing the print.
        if (event.type === 'keydown') {
          // We check if the keydown.enter has been initialize from this component otherwise we ignore the event
          // This is mainly done because we otherwise trigger an open details after the search
          this.keydownEnter = true;
        } else if (this.keydownEnter && this.currentPage && this.currentPage.length > this.selectedIndex && !this.tilfoejBeskedShow) {
          this.showPrint(this.currentPage[this.selectedIndex]);
        }
        break;
      case 'ArrowUp':
        if (this.selectedIndex > 0) {
          this.selectedIndex--;
        }
        break;
      case 'ArrowDown':
        if (this.selectedIndex < this.currentPage.length - 1) {
          this.selectedIndex++;
        }
        break;
      default:
      //nothing
    }
  }

  handleUpdatedDamagesNote(report: RapportListDTO, updatedDamagesNoteText: string): void {
    // not sure why report id is a string
    const reportId: number = +(!isNullOrUndefined(report.taksatorReportId) ? report.taksatorReportId : report.vkReportId);
    this.reportService.updateDamagesNote(reportId, updatedDamagesNoteText)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(updatedDamagesNote => {
        report.damagesNotes = updatedDamagesNote.text;
        //We fetch a new list of reports if the current user is TAKS. Because multiple reports could be changed
        //We don't do the for VK for 2 reasons: 1) Gazello is not updated yet, 2) VK can only have one report with the same damage note
        if (this.principalService.isTaksator()) {
          this.getReports(this.currentSearchProperties);
        }
      });
  }

  showXrefColumn(): boolean {
    return this.functionConfiguration && this.functionConfiguration.importId && this.principalService.isVK();
  }

  private storeCurrentListConfig(): void {
    const reportListConfig = new ReportListConfig();
    reportListConfig.page = this.page;
    reportListConfig.selectedIndex = this._selectedIndex;
    reportListConfig.orderProp = this.orderProp;
    reportListConfig.orderReverse = this.orderReverse;
    reportListConfig.orderType = this.orderType;
    reportListConfig.searchProperties = this.currentSearchProperties;
    reportListConfig.filter = this.currentFilter;
    this.storageService.storeReportListConfig(reportListConfig);
  }

  getCarSales(): void {
    this.reportService.getCarSales(this.currentPage).pipe(takeUntil(this.unsubscribe$)).subscribe(carSales => {
      const carSalesArrLocal = {};
      carSales.forEach((carSale): void => {
        if (carSale.identifier) {
          carSalesArrLocal[carSale.identifier] = carSale;
        }
      });
      this.carSaleList = new CarSaleList();
      this.carSaleList.setCarSaleList(carSalesArrLocal);
    });
  }

  isTaks(): boolean {
    return this.principalService.isTaksator();
  }

  cancelSearch(): void {
    if (this.currentReportSubscription) {
      this.currentReportSubscription.unsubscribe();
    }
    this.spinnerUtil.stopLoading();
  }

  doFiltering(filter: string): void {
    this.page = 1;
    this.currentFilter = filter;
    this.doSorting();
  }

  private isInFilter(filter: string, report: RapportListDTO): boolean {
    if (StringUtils.isEmpty(filter)) {
      return true;
    }

    if (report.registrationNumber && report.registrationNumber.toUpperCase().indexOf(filter) > -1) {
      return true;
    }

    const rappKeyFilter = filter.replace(/ /g, '');
    if (report.taksatorReportKey && report.taksatorReportKey.toUpperCase().indexOf(rappKeyFilter) > -1) {
      return true;
    }

    if (report.vkReportKey && report.vkReportKey.toUpperCase().indexOf(rappKeyFilter) > -1) {
      return true;
    }

    if (report.vehicleText && report.vehicleText.toUpperCase().indexOf(filter) > -1) {
      return true;
    }

    if (report.selskabsName && (report.selskabsName).toUpperCase().indexOf(filter) > -1) {
      return true;
    }

    if (report.selskabsCode && (report.selskabsCode).toUpperCase().indexOf(filter) > -1) {
      return true;
    }

    return report.approvedDate && formatDate(report.approvedDate, 'dd-MM-yyyy HH:mm:ss', 'da').indexOf(filter) > -1;
  }

  stopPropagation(event): void {
    event.stopPropagation();
  }

  enableSearchOnState(): boolean {
    return this.principalService.isVK();
  }

  sendMessageEnabled(report: RapportListDTO): boolean {
    return StringUtils.isNotEmpty(report.vkReportKey) && this.extractReportType(report).isOfAny([RapportType.H, RapportType.T])
  }

  isVehicleCardImageVisible(report: RapportListDTO): boolean {
    return this.showCardImages && this.hasThumbnail(report);
  }

  toggleShowVehicleCardImages(): void {
    this.showCardImages = !this.showCardImages;
  }

  getCarSaleMenuTitle(report: RapportListDTO): string {
    let title = 'Bilsalg'
    if (!this.carSaleList.carSaleSupportedByReportType(report.taksatorReportKey)) {
      title += ' er ikke supporteret for denne rapporttype'
    }
    if (this.carSaleList.isCarSaleDeprecated(report.taksatorReportKey, report.approvedDate)) {
      title += '\n - Denne rapport er opdateret efter bilsalg'
    }
    return title;
  }

  getB2BMenuTitle(report: RapportListDTO): string {
    return this.canDoB2B(report) ? 'Business-to-business' : 'Rapporten skal være godkendt før du kan lave Business-to-business'
  }

  getNewReportMenuTitle(report: RapportListDTO): string {
    if (this.newReportTypesAllowed(report)) {
      return 'Ny rapport';
    } else {
      return 'Ny rapport er ikke muligt for denne type af rapport';
    }
  }

  getAcceptStatusClass(acceptStatus: ReportAcceptStatus): string {
    switch (acceptStatus) {
      case ReportAcceptStatus.ACCEPTED:
        return 'bg-success text-white'
      case ReportAcceptStatus.REQUESTED:
        return 'bg-warning'
      case ReportAcceptStatus.REJECTED:
        return 'bg-danger text-white'
    }
    return ''
  }

  extractReportType(report: RapportListDTO): RapportType {
    return RapportType.extractFrom(report.taksatorReportKey || report.vkReportKey);
  }

}
