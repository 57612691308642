<h1>FAQ</h1>

<div class="row">
    <div class="col faq-size">
        <p>Autotaks teamet håber, at du finder svar på, hvad du søger her på vores FAQ side.</p>
        <p>Fandt du ikke hvad du søgte, har vi meget mere information i vores
            <a [href]="EnvironmentUtils.getVideoGalleryUrl()" target="_blank">videogalleri</a>,
            vores <a [href]="EnvironmentUtils.getAutotaksUrl()" target="_blank">hjemmeside</a>,
            samt vores <a [href]="EnvironmentUtils.getLinkedinUrl()" target="_blank">Linkedin</a> side.
            <span *ngIf="EnvironmentUtils.isSupportEnabled">Du kan desuden indberette fejl i vores <a [href]="EnvironmentUtils.getSupportUrl()" target="_blank">supportsystem</a>.</span></p>
        <hr>
        <i class="fa fa-fw fa-vimeo fa-2x mb-2 vertical-center"></i><a [href]="EnvironmentUtils.getVideoGalleryUrl()" target="_blank">{{EnvironmentUtils.getVideoGalleryUrl()}}</a><br>
        <i class="fa fa-fw fa-link fa-2x mb-2 vertical-center"></i><a [href]="EnvironmentUtils.getAutotaksUrl()" target="_blank">{{EnvironmentUtils.getAutotaksUrl()}}</a><br>
        <i class="fa fa-fw fa-linkedin fa-2x mb-2 vertical-center"></i><a [href]="EnvironmentUtils.getLinkedinUrl()" target="_blank">@autotaks</a><br>
        <div *ngIf="EnvironmentUtils.isSupportEnabled"><i class="fa fa-fw fa-exclamation-triangle fa-2x vertical-center"></i><a [href]="EnvironmentUtils.getSupportUrl()" target="_blank">Fejlindberet</a><br></div>
    </div>
    <div class="col video-embed">
        <div *ngIf="currentVideoEmbedLink">
            <iframe [src]="currentVideoEmbedLink"
                    width="640" height="316" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
            <p class="faq-size"><a [href]="currentVideoLink.link" target="_blank">{{currentVideoLink.text}}</a>
                fra <a href="https://vimeo.com/autotaks" target="_blank">Autotaks</a>
                på <a href="https://vimeo.com" target="_blank">Vimeo</a>.</p>
        </div>
    </div>
</div>
<div class="input-group mt-4">
    <input type="text" placeholder="Søg" class="form-control" [ngModel]="searchString"
           (ngModelChange)="onSearchChange($event)" (keyup)="searchkeyUp($event)">
    <button class="btn btn-primary" type="button" (click)="onSearchChange('')">
        <i class="fa fa-eraser fa-lg"></i>
    </button>
</div>
<div class="card mt-4">
    <div class="card-body">
        <ul class="navbar-nav ms-auto" *ngFor="let link of searchLinks">
            <li class="nav-item">
                <a (click)="openVideo(link)">
                    <div class="pt-2 col-sm-6 col-md-4">{{link.text}}</div>
                </a>
            </li>
        </ul>
    </div>
</div>
