import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {RoadmapItemListDTO} from '../model/roadmap-Item-list-dto.model';
import {RoadmapItemContentDTO} from '../model/roadmap-Item-content-dto.model';
import {RoadmapItemStateEnum} from '../model/roadmap-Item-state.enum';

@Injectable({
  providedIn: 'root',
})
export class RoadmapService {
  private roadmapUrl = 'roadmap';

  constructor(private http: HttpClient) {
  }

  public getAssessorRoadmap(states: RoadmapItemStateEnum[]): Observable<RoadmapItemListDTO[]> {
    let httpParams = new HttpParams();
    states.forEach(state => httpParams = httpParams.append('state', state));
    return this.http.get<RoadmapItemListDTO[]>(this.roadmapUrl + '/assessor', {params: httpParams});
  }
  
  public getRoadmapItemContent(item: RoadmapItemListDTO): Observable<RoadmapItemContentDTO> {
    const httpParams = new HttpParams().set('accessToken', item.accessToken).set('accessData', item.accessData);
    return this.http.get<RoadmapItemContentDTO>(this.roadmapUrl + '/item-content', {params: httpParams});
  }

}
