import {SettingsService} from '../../shared/service/settings.service';
import {Component, OnDestroy, OnInit, Output} from '@angular/core';
import {ReportTypeViewModel} from './report-type-view-model.service';
import {ReportCategory} from '../../shared/model/report-category.model';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'lc-report-type',
  templateUrl: './report-type.component.html',
  styleUrls: [
    '../draft.scss'
  ],
  providers: [SettingsService]
})
export class ReportTypeComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  @Output() draftType: ReportCategory;

  constructor(public reportTypeViewModel: ReportTypeViewModel) {
  }

  ngOnInit(): void {
    this.reportTypeViewModel.selectedTypeObs()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(next => {
        this.draftType = next;
      });
  }

  onReportTypeChanged(reportType: ReportCategory): void {
    this.reportTypeViewModel.selectedType = reportType;
  }

  fontAwsomeIconClass(reportType: ReportCategory): string {
    return reportType.cssClass;
  }

  isSelected(reportType: ReportCategory): boolean {
    return reportType === this.reportTypeViewModel.selectedType;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
