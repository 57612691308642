/**
 * These values match enums used in the api.
 */
export enum TextLibraryTypeEnum {
  FORM18_REMARKS = 'FORM18_REMARKS',
  REMARKS = 'REMARKS',
  SKADENOTAT = 'SKADENOTAT',
  INTERNINFO = 'INTERNINFO',
  BESKED = 'BESKED',
  TAKSATORAKTIONBESKED = 'TAKSATORAKTIONBESKED'
}
