import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {SearchProperties} from './SearchProperties';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {StringUtils} from '../../utils/string-utils';
import {SearchReportState, SearchReportStates} from './search-report-states.model';

@Component({
  selector: 'lc-report-search-input',
  templateUrl: './report-search-input.component.html',
  styleUrls: ['./report-search-input.component.scss'],
  providers: []
})
export class ReportSearchInputComponent implements OnChanges {
  @Input() title = 'Søg';
  @Input() currentSearchProperties: SearchProperties;
  @Input() searchLimitMessage: string;
  @Input() filter: string;
  @Input() isLoading: boolean;
  @Input() enableSearchOnState = false;

  @Output() onCancelSearch = new EventEmitter<boolean>();
  @Output() onSearch = new EventEmitter<SearchProperties>();
  @Output() onRefresh = new EventEmitter<void>();
  @Output() onSearchInputOpen = new EventEmitter<void>();
  @Output() onSearchInputClosed = new EventEmitter<void>();
  @Output() onFilteringChanged = new EventEmitter<string>();

  modalRef: NgbModalRef;
  searchProperties: SearchProperties;
  closeModalOnLoadingDone: boolean;
  searchReportStates = new SearchReportStates();
  chosenState: SearchReportState = this.searchReportStates.getStates()[0];

  constructor(private modalService: NgbModal) {
  }

  openSearchDialog(popupContent): void {
    this.searchProperties = this.currentSearchProperties ? this.currentSearchProperties.copy() : new SearchProperties();
    this.modalRef = this.modalService.open(popupContent, {backdrop: 'static', keyboard: false});
    this.initializeChosenState();
    this.onSearchInputOpen.emit();
  }

  private closeModalRef(): void {
    if (this.closeModalOnLoadingDone && this.modalRef) {
      this.modalRef.close();
      this.modalRef = null;
      this.onSearchInputClosed.emit();
    }
  }

  public cancelSearch(close: boolean = true): void {
    this.closeModalOnLoadingDone = close;
    this.onCancelSearch.emit();
    if (close) {
      this.closeModalRef();
    }
  }

  public handleSearchCommit(): void {
    if (this.searchProperties) {
      this.searchProperties.trim();
    }
    if (this.isLoading) {
      this.cancelSearch(false);
    } else {
      this.closeModalOnLoadingDone = true;
      this.onSearch.emit(this.searchProperties);
    }
  }

  getSearchButtonText(): string {
    return this.isLoading ? 'Annuller' : 'Søg';
  }

  clearSearchField(propertyName: string): void {
    if (this.currentSearchProperties != null) {
      this.currentSearchProperties[propertyName] = '';
      this.onSearch.emit(this.currentSearchProperties);
    }
  }

  searchFieldNotEmpty(propertyName: string): boolean {
    if (this.currentSearchProperties != null) {
      const property = this.currentSearchProperties[propertyName];
      return property && property.length > 0;
    }
    return false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.closeModalOnLoadingDone && changes.isLoading && changes.isLoading.currentValue === false) {
      this.closeModalRef();
    }
  }

  onSearchStatusChanged(): void {
    this.searchProperties.searchState = null;
    if (this.chosenState.getCode()) {
      this.searchProperties.searchState = this.chosenState.getCode();
    }
  }

  initializeChosenState(): void {
    if (!this.currentSearchProperties.searchState || StringUtils.isEmpty(this.currentSearchProperties.searchState)) {
      this.chosenState = this.searchReportStates.getStates()[0];
    }
  }
}
