import {Pipe, PipeTransform} from '@angular/core';
import {ObjectUtils} from '../utils/object-utils';

@Pipe({
  name: 'badgeLabel'
})
export class BadgeLabelPipe implements PipeTransform {
  transform(value: number, limit?: number, postFix: string = '+'): string {
    if (!ObjectUtils.exists(value)) {
      return '';
    }
    if (!ObjectUtils.exists(limit)) {
      return '' + value;
    }
    return value > limit ? '' + limit + postFix : '' + value;
  }
}
