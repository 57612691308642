import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DraftService} from '../../../service/draft.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {RapportType} from '../../../../shared/model/rapport-type.model';

@Component({
  selector: 'lc-draft-damages-note',
  templateUrl: './draft-damages-note.component.html',
  styleUrls: ['../../draft-common.scss']
})
export class DraftDamagesNoteComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  @Input() clientStateToken: string;
  @Input() reportType: RapportType;
  damagesNote: string;

  constructor(private draftService: DraftService) {
  }

  ngOnInit(): void {
    this.draftService.getDamagesNote(this.clientStateToken)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(damagesNote => this.damagesNote = damagesNote.text);
  }

  handleUpdatedDamagesNote(updatedDamagesNoteText: string): void {
    this.draftService.updateDamagesNote(this.clientStateToken, updatedDamagesNoteText)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(updatedDamagesNote => {
        this.damagesNote = updatedDamagesNote.text;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
