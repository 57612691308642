import {SharedModule} from 'app/shared/shared.module';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AppRole} from '../shared/service/auth/app-roles.model';
import {UserRouteAccessService} from '../shared';

export const MESSAGE_ROUTES: Routes = [
  {
    path: 'message',
    loadChildren: () => import('./message.module').then(m => m.MessageModule),
    data: {
      authorities: [AppRole.TAKS, AppRole.VK],
    },
    canActivate: [UserRouteAccessService],
  }
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(MESSAGE_ROUTES)
  ],
  declarations: [],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class MessageRoutingModule {
}
