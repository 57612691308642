import {Injectable} from '@angular/core';
import {TextLibraryDTO} from '../dto/text-library-dto';
import {TextLibraryEntryDTO} from '../dto/text-library-entry-dto';
import {Observable} from 'rxjs';
import {TextLibraryService} from './text-library.service';
import {HttpClient} from '@angular/common/http';
import {TextLibraryTypeEnum} from './text-library-type.enum';

@Injectable({
  providedIn: 'root',
})
export class UserTextLibraryService extends TextLibraryService {
  protected url = this.serviceUrl + 'user/';

  public constructor(protected http: HttpClient) {
    super(http);
  }

  public getTextLibrary(type: TextLibraryTypeEnum): Observable<TextLibraryDTO> {
    return super.getSuperTextLibrary(type, 'USER');
  }

  public createTextLibraryEntry(type: TextLibraryTypeEnum, text: string): Observable<TextLibraryEntryDTO> {
    const createTextLibraryEntryDTO = {type, text};
    return this.http.post<TextLibraryEntryDTO>(this.url + 'entry', createTextLibraryEntryDTO);
  }

  public updateTextLibraryEntry(id: number, text: string): Observable<TextLibraryEntryDTO> {
    const updateTextLibraryEntryDTO = {text};
    return this.http.put<TextLibraryEntryDTO>(this.url + 'entry/' + id, updateTextLibraryEntryDTO);
  }

  public deleteTextLibraryEntry(id: number): Observable<any> {
    return this.http.delete(this.url + 'entry/' + id);
  }
}
