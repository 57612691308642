import {Injectable} from '@angular/core';
import {OAuthApiService} from './o-auth-api.service';
import {Observable, of} from 'rxjs';
import {PrincipalDTO, PrincipalService} from '../..';
import {catchError, mergeMap, tap} from 'rxjs/operators';
import {OAuthStorageService} from './o-auth-storage.service';
import {OAuthLoginFlowDTO} from './model/o-auth-login-flow-dto.model';
import {MsalService} from '@azure/msal-angular';

/**
 * Service used to do client credential authentication and "logout"
 */
@Injectable({providedIn: 'root'})
export class OAuthAuthenticationService {

  public constructor(private oAuthApiService: OAuthApiService,
                     private oAuthCredentialsService: OAuthStorageService,
                     private principalService: PrincipalService,
                     private msalService: MsalService) {
  }

  authenticate(username: string, password: string): Observable<PrincipalDTO> {
    return this.oAuthApiService.authenticate(username, password).pipe(
      tap(credentials => {
        this.oAuthCredentialsService.setAccessTokenExpiration(credentials);
      }),
      mergeMap(() => this.principalService.identity(true))
    )
  }

  removePossibleB2BAuthenticationAndRedirect(): Observable<boolean> {
    try {
      if (!!this.msalService.instance?.getActiveAccount()) {
        return this.msalService.logoutRedirect().pipe(mergeMap(() => of(true)));
      } else {
        return of(false);
      }
    } catch (ignore) {
      return of(false);
    }
  }
  
  removeAuthentication(): Observable<void> {
    return this.oAuthApiService.removeAuthentication().pipe(
      tap(() => {
        this.oAuthCredentialsService.clearAccessTokenExpiration();
        this.principalService.authenticate(null);
      }),
      catchError(err => {
        this.oAuthCredentialsService.clearAccessTokenExpiration();
        this.principalService.authenticate(null);
        //silent removing authentication
        return of(void 0);
      })
    )
  }

  getLoginFlow(username: string): Observable<OAuthLoginFlowDTO> {
    return this.oAuthApiService.getLoginFlow(username);
  }

  authenticateB2C(): Observable<PrincipalDTO> {
    return this.oAuthApiService.authenticateB2C().pipe(
      tap(credentials => {
        this.oAuthCredentialsService.setAccessTokenExpiration(credentials);
      }),
      mergeMap(() => this.principalService.identity(true))
    )
  }
  
  activateB2C(email: string, activationId: string): Observable<boolean> {
    return this.oAuthApiService.activateB2C(email, activationId);
  }
}
