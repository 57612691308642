import {Injectable} from '@angular/core';
import {LcActionMenu} from '../../../shared/ui/bottombar/lc-action-menu.model';
import {DraftEditService} from '../draft-edit.service';
import {ClientStateDetails} from '../../model/client-state-details.model';
import {ForsiReportKeyPipe} from '../../../shared/pipe/forsi-report-key.pipe';
import {ForsiReportKeyShortPipe} from '../../../shared/pipe/forsi-report-key-short.pipe';
import {combineLatest, Observable, Subject} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {AgreementDifference, ClientStateDifferService, PoliceDifference} from './client-state-differ.service';
import {AgreementService} from '../../../shared/service/agreement.service';
import {mergeMap} from 'rxjs/operators';
import {ReportCategory} from '../../../shared/model/report-category.model';
import {DraftService} from '../../service/draft.service';

@Injectable()
export class CheckOpslagViewModel {
  showLoading = true;
  menuBottomTitle = '';
  menuBottomShortTitle = '';
  menuIconClass = null;
  draftMenu: LcActionMenu;
  navigateSubject = new Subject<string>();
  policeDifferences: PoliceDifference[] = [];
  agreementDifferences: AgreementDifference[] = [];
  clientstate: ClientStateDetails;

  constructor(private draftEditService: DraftEditService,
              private draftService: DraftService,
              private agreementService: AgreementService,
              private reportKeyPipe: ForsiReportKeyPipe,
              private reportKeyShortPipe: ForsiReportKeyShortPipe,
              private route: ActivatedRoute,
              private differ: ClientStateDifferService) {
    this.draftMenu = new LcActionMenu();
    this.setupObservables();
  }

  public emptyPoliceDifferences(): boolean {
    return this.policeDifferences.length <= 0;
  }

  public emptyAgreementDifferences(): boolean {
    return this.agreementDifferences.length <= 0;
  }

  private setupObservables(): void {
    const paramsObserver = {
      next: ([clientState, policeDiffs, agreementDiffs]) => {
        this.clientstate = clientState;
        this.policeDifferences = policeDiffs;
        this.agreementDifferences = agreementDiffs;
        this.setupDraftActionMenu(clientState);
        this.showLoading = false;
      },
      error: err => {
        console.log('Error', err);
        this.navigateSubject.next('../');
      }
    };
    this.route.parent.params.pipe(
      mergeMap(params => this.draftEditService.getClientStateObservable(params.token)),
      mergeMap((clientState) => {
        const clientStateFwdObs = new Observable(sub => {
          sub.next(clientState);
        }) as Observable<ClientStateDetails>;
        const valueTypeObs = this.agreementService.getValueTypes();
        return combineLatest([clientStateFwdObs, valueTypeObs, this.draftService.getAgreements(clientState.token) ]);
      }),
      mergeMap(([clientState, valueTypes, aftaler]) => new Observable(subscriber => {
          subscriber.next([clientState, this.differ.getPoliceDifferences(clientState), this.differ.getAgreementDifferences(clientState, valueTypes, aftaler)]);
        })))
      .subscribe(paramsObserver);
  }

  setupDraftActionMenu(clientstateDetails: ClientStateDetails): void {
    this.menuIconClass = ReportCategory.iconCssClass(clientstateDetails.schema);
    this.menuBottomTitle = this.reportKeyPipe.transform(clientstateDetails.taksRapportKey);
    this.menuBottomShortTitle = this.reportKeyShortPipe.transform(clientstateDetails.taksRapportKey);

    this.draftMenu.add(
      'fa-arrow-left',
      () => this.navigateSubject.next('../'),
      'Tilbage');
  }

  resetPoliceDifference(policeDiff: PoliceDifference): void {
    policeDiff.resetValue();
    this.showLoading = true;
    const draftEditObserver = {
      next: () => {
        this.policeDifferences = this.differ.getPoliceDifferences(this.clientstate);
        this.showLoading = false;
      },
      error: () => {
        this.showLoading = false;
      }
    };
    this.draftEditService.save().subscribe(draftEditObserver);
  }
}
