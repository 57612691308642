<div class="modal-header pre-size header-style" style="text-align: center">
    <h3 class="text-truncate">
        {{title}}
    </h3>
    <div aria-label="Close" data-dismiss="modal" class="btn-close clickable" (click)="activeModal.dismiss()"></div>
</div>

<fieldset ngForm="form" #form="ngForm">
    <div class="modal-content">
        <div class="modal-body modal-body-style me-3 ms-3">
            <div class="row pt-1">
                <label class="col-form-label col-4" for="position">{{label ? label : 'Flyt til position'}}</label>
                <input autofocus id="position" name="position" type="tel" required maxlength="3" lcInputMask="int3_LeftAlign"
                       class="form-control col-8 pe-1" [(ngModel)]="position">
            </div>
        </div>
    </div>
</fieldset>
<div class="modal-footer">
    <div class="full-width">
        <div class="pull-right">
            <button class="btn btn-lg btn-secondary"
                    (click)="activeModal.dismiss()">Fortryd</button>
        </div>
        <div class="pull-right pe-1">
            <button class="btn btn-lg btn-primary" [disabled]="disableReorder()"
                    (click)="reorder()">Gem</button>
        </div>
    </div>
</div>
