export class SkadeKodeDTO {
  id: number;
  beskrivelse: string;
  kode: string;

  //tekst: string;
  get tekst(): string {
    return this.beskrivelse + ' [' + this.kode.toString() + ']';
  };
}
