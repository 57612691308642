<lc-qapter-start [startQapter$]="startQapter$"></lc-qapter-start>
<lc-bottombar [menu]="draftMenu" [iconClass]="this.bottomMenuIconClass" [title]="this.bottomMenuTitle"
              [shortTitle]="this.bottomMenuShortTitle"></lc-bottombar>
<div class="text-center" *ngIf="isLoading">
    <i class="fa fa-circle-o-notch fa-spin fop-page-spinner"></i>
</div>
<router-outlet></router-outlet>
<form #f="ngForm" autocomplete="off">
    <div *ngIf="!isLoading"
         [ngClass]="{'form-dirty':this.showClientStateAsDirty(), 'form-error':this.showClientStateAsInError()} ">
        <lc-context-menu [menu]="this.contextMenu"></lc-context-menu>
        <div class="split-page">
            <div class="split-left d-none d-lg-block">&nbsp;</div>
            <div class="split-right">
                <div class="row" id="masterdata">
                    <div class="col-12">
                        <lc-draft-master-data [formId]="'masterData'" [details]="details"
                                              [brands]="brands"
                                              (onVinDetailsChanged)="reloadFlow()"></lc-draft-master-data>
                    </div>
                    <div class="col-12">
                        <div class="row card-row">
                            <div class="col-md-6 mt-2">
                                <lc-condtion-rules [formId]="'conditionRules'" [details]="details" [assessmentConfiguration]="assessmentConfiguration"
                                                   [companyCode]="details.selskab"></lc-condtion-rules>
                            </div>
                            <div class="col-md-6 mt-2">
                                <lc-draft-damages-dates [formId]="'draftDamagesDates'" [details]="details"></lc-draft-damages-dates>
                            </div>
                            <div class="col-md-6 mt-2" id="calculation-date">
                                <lc-calculation-date [formId]="'calculationDate'"
                                                     [details]="details"></lc-calculation-date>
                            </div>
                            <div class="col-md-6 mt-2">
                                <lc-draft-misc-amounts [details]="details"></lc-draft-misc-amounts>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-xl-4">
                        <lc-draft-forsikringstager-info [formId]="'forsikringInfo'"
                                                        [details]="details"></lc-draft-forsikringstager-info>
                    </div>
                    <div class="col-lg-6 col-xl-4">
                        <lc-draft-skadelidte-info [formId]="'skadelidteInfo'"
                                                  [details]="details"></lc-draft-skadelidte-info>
                    </div>
                    <div class="col-lg-6 col-xl-4">
                        <lc-leasing-company [formId]="'leasingselskab'" [details]="details"></lc-leasing-company>
                    </div>
                </div>
                <div id="agreements" class="row">
                    <div class="col-12">
                        <lc-draft-agreements [formId]="'agreements'" [details]="details"
                                             (isLoading)="isAgreementsLoading = $event"></lc-draft-agreements>
                    </div>

                </div>
                <div id="assessment-method" class="row mt-2">
                    <div class="col-12">
                        <lc-assessment-method [formId]="'assessmentMethod'" [details]="details"
                                              [companyDetails]="companyDetails"></lc-assessment-method>
                    </div>
                </div>

                <div class="row mt-2">
                    <div class="col-12">
                        <lc-vat-information formId="vat-information" [details]="details"></lc-vat-information>
                    </div>
                </div>

                <div id="damages" class="row">
                    <div class="col-12">
                        <lc-draft-damages [formId]="'damages'" [fantomBrands]="fantomBrands" [details]="details"
                                          (save)="saveForm()"></lc-draft-damages>
                    </div>
                </div>

                <div id="damagedescription" class="row">
                    <div class="col-12">
                        <lc-draft-damagedescription [formId]="'damagedescription'" [details]="details"
                                                    [damageDescription]="details.damageDescription"
                                                    [selskab]="details.selskab"></lc-draft-damagedescription>
                    </div>
                </div>

                <div id="valuation">
                    <lc-draft-valuation [formId]="'valuation'" [details]="details"
                                        [damageDate]="details.skadeDato"></lc-draft-valuation>
                </div>
                <div id="attachments" class="row">
                    <div class="col-12">
                        <h2>Bilag</h2>
                        <div class="card">
                            <div class="card-body">
                                <div *ngIf="attachmentSummary">
                                    <lc-draft-attachment
                                        [attachmentSummary]="attachmentSummary"
                                        (onAttachmentChanged)="updateAttachmentSummary(details.token)"
                                        [readonly]="false"
                                        [token]="details.token"
                                        [clientStateId]="details.id"
                                        [reportKey]="getAttachmentReportKey(details)"
                                        [registrationNumber]="getRegistrationNumber(details)"
                                        [videoRequestUseCase]="true"
                                    ></lc-draft-attachment>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" id="misc">
                    <div class="col-12">
                        <h2>Beskeder</h2>
                        <div class="card">
                            <div class="card-body">
                                <lc-message
                                    [sendEnabled]="sendMessageEnabled"
                                    [messages]="messages"
                                    [textLibraryType]="textLibraryType"
                                    (postMessage)="sendMessage($event)"
                                    (reload)="getMessages()"></lc-message>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <h2>Bemærkninger</h2>
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-4">
                                        <lc-draft-remarks formId="remarks" [details]="details"></lc-draft-remarks>
                                    </div>
                                    <div class="col-lg-4">
                                        <lc-intern-info [details]="details" formId="intern-info"></lc-intern-info>
                                    </div>
                                    <div class="col-lg-4">
                                        <lc-draft-damages-note
                                            [clientStateToken]="details.token"></lc-draft-damages-note>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row dynamic-padding-bottom">
                    <!-- Room for floating action buttons -->
                    <div class="col-md-12"></div>
                </div>
            </div>
        </div>
    </div>
</form>
