import {Component, EventEmitter, Input, OnChanges, OnDestroy, Output} from '@angular/core';
import {AttachmentService} from './attachment.service';
import {PrincipalService} from '../..';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AttachmentDTO} from 'app/shared/dto/attachment-dto.model';
import {FileUploader, FileUploaderOptions} from 'ng2-file-upload';
import URLBuilder from '../../utils/url-builder';
import {AttachmentSummaryDTO} from '../../dto/attachment-summary-dto.model';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AttachmentMetadataSummary} from '../../dto/attachment-metadata-summary.model';
import AttachmentUtil from '../../utils/attachment-utils';
import {AttachmentGalleryComponent} from './attachment-gallery/attachment-gallery.component';
import {isNullOrUndefined} from '../../utils/object-utils';
import {LcPopupService} from '../../modals/lc-popup.service';

@Component({
  template: ''
})
export abstract class BaseAttachmentComponent implements OnDestroy, OnChanges {
  private _unsubscribe$ = new Subject<void>();
  protected updateAttachmentRemark = new Subject<AttachmentDTO>();

  draftDropZoneOver = false;
  defaultImage = URLBuilder.basePath + 'content/images/defaultImage.png';
  attachmentSummary: AttachmentSummaryDTO;
  attachment: AttachmentDTO;
  fileUploader: FileUploader;
  urlBuilder = new URLBuilder();
  metadataSummary: AttachmentMetadataSummary;

  @Output() onAttachmentChanged = new EventEmitter();
  @Input() readonly ? = true;
  @Input() reportKey: string;
  @Input() registrationNumber?: string;

  @Input('attachmentSummary') set setAttachmentSummary(attachmentSummary: AttachmentSummaryDTO) {
    if (isNullOrUndefined(this.attachmentSummary) || attachmentSummary.id !== this.attachmentSummary.id) {
      this.attachmentSummary = attachmentSummary;
    }
  }

  protected constructor(private attachmentService: AttachmentService,
                        public principal: PrincipalService,
                        protected modalService: NgbModal,
                        private popupService: LcPopupService) {
    this.attachment = new AttachmentDTO();
  }

  protected abstract getAttachmentsObs(): Observable<AttachmentDTO[]>;
  protected abstract prepareFileUploader(): FileUploader;

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
    const summaryChange = changes['setAttachmentSummary'];
    if (summaryChange && summaryChange.isFirstChange()) {
      this.getAttachments(false);
      this.fileUploader = this.prepareFileUploader();
    }
  }

  public isImage(attachment: AttachmentDTO): boolean {
    return AttachmentUtil.isImage(attachment);
  }

  public getImageUrl(attachment: AttachmentDTO, thumbnail: boolean, download: boolean): string {
    return AttachmentUtil.composeImageAttachmentUrl(attachment, thumbnail, download);
  }

  protected getUploadQueue(): AttachmentDTO[] {
    const retval: AttachmentDTO[] = [];
    if (this.fileUploader) {
      this.fileUploader.queue.forEach(f => {
        retval.push({
          id: null,
          contentType: 'file/uploading',
          accessToken: null,
          name: f.file.name,
          remark: '',
          report: false,
          token: null,
          vkReport: this.principal.isVK(),
          createdAt: null
        })
      })
    }
    return retval;
  }

  /**
   * Returns an array of all attachments
   *
   * @param includeUploads If true we include pending uploads. DEFAULT: true
   */
  public concatAttachments(includeUploads?: boolean): AttachmentDTO[] {
    includeUploads = isNullOrUndefined(includeUploads) ? true : includeUploads;
    const images: AttachmentDTO[] = this.attachmentSummary.images;
    const files: AttachmentDTO[] = this.attachmentSummary.files;
    const newUploads = this.getUploadQueue();
    let result: AttachmentDTO[] = [];
    if (!isNullOrUndefined(images) && images.length > 0) {
      result = result.concat(images);
    }
    if (!isNullOrUndefined(files) && files.length > 0) {
      result = result.concat(files);
    }
    if (includeUploads && !isNullOrUndefined(newUploads) && newUploads.length > 0) {
      result = result.concat(newUploads);
    }

    result.sort((a, b) => {
      // Drafts MUST come before reports AND images MUST come before files all sorted by created date ascending
      if (a.report !== b.report) {
        return a.report ? 1 : -1;
      } else if (AttachmentUtil.isImage(a) !== AttachmentUtil.isImage(b)) {
        return AttachmentUtil.isImage(a) ? -1 : 1;
      } else {
        return a.createdAt < b.createdAt ? -1 : 1
      }
    });

    return result;
  }

  private getAttachments(force: boolean): void {
    if (force || !this.attachmentSummary || !(this.attachmentSummary.images || this.attachmentSummary.files)) {
      this.getAttachmentsObs().pipe(takeUntil(this._unsubscribe$)).subscribe(attachments => {
        this.gotAttachments(attachments);
      });
    }
  }

  private gotAttachments(attachments: AttachmentDTO[]): void {
    this.attachmentSummary.images = [];
    this.attachmentSummary.files = [];
    this.attachmentSummary.workshopImages = [];
    this.attachmentSummary.count = attachments.length;

    attachments.forEach(a => {
      if (AttachmentUtil.isImage(a)) {
        this.attachmentSummary.images.push(a);
        //TODO Enhance to check wheter it's a workshop image
        if (a.report && a.vkReport) {
          this.attachmentSummary.workshopImages.push(a);
        }
      } else {
        this.attachmentSummary.files.push(a);
      }
    });
    if (this.principal.isTaksator()) {
      this.attachmentService.getAttachmentMetaDataBatch(this.attachmentSummary.workshopImages).pipe(takeUntil(this._unsubscribe$)).subscribe(response => {
        this.attachmentService.generateMetadataSummary(response).pipe(takeUntil(this._unsubscribe$)).subscribe(summary => {
          this.metadataSummary = summary;
        });
      })
    }
  }

  fileOverDropZone(e: any): void {
    if (!this.readonly) {
      this.draftDropZoneOver = e;
    }
  }

  protected getFileUploadOptions(path: string, autoUpload?: boolean): FileUploaderOptions {
    return {
      removeAfterUpload: true,
      autoUpload: autoUpload,
      url: this.urlBuilder.getRestServicePath() + path
    }
  }

  protected updateLocally(attachment): void {
    if (AttachmentUtil.isImage(attachment)) {
      if (isNullOrUndefined(this.attachmentSummary.images)) {
        this.attachmentSummary.images = [];
      }
      this.attachmentSummary.images.push(attachment)
    } else {
      if (isNullOrUndefined(this.attachmentSummary.files)) {
        this.attachmentSummary.files = [];
      }
      this.attachmentSummary.files.push(attachment)
    }
  }
  
  // Gallery functionality - START
  public openGallery(selectedAttachment: AttachmentDTO): void {
    if (selectedAttachment) {
      const galleryItems = this.concatAttachments(false);
      const modalRef = this.modalService.open(AttachmentGalleryComponent, {size: 'lg', windowClass: 'lc-fullscreen attachment-gallery'});
      const instance = modalRef.componentInstance as AttachmentGalleryComponent;
      instance.initializeGallery(galleryItems, selectedAttachment, this.readonly, this.reportKey, this.registrationNumber, this.updateAttachmentRemark)
    }
  }
  
  downloadAllAttachments(): void {
    const downloads = new Map<string, Observable<Blob>>();
    this.concatAttachments(false).forEach(attachment => downloads.set(attachment.name, this.attachmentService.downloadAttachmentBlob(attachment)));
    this.popupService.startMultipleDownload('Download alle bilag', downloads, this.reportKey + '-bilag.zip');
  }
}
