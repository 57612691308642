import {Routes} from '@angular/router';
import {ErrorComponent} from './error.component';

export const errorRoute: Routes = [
  {
    path: 'error',
    component: ErrorComponent,
    data: {
      authorities: [],
      pageTitle: 'Fejl',
      showLogoutButton: true,
      errorMessage: 'Der er desværre sket en fejl.'
    },
  },
  {
    path: 'accessdenied',
    component: ErrorComponent,
    data: {
      authorities: [],
      pageTitle: 'Ingen adgang',
      showLogoutButton: true,
      error403: true
    },
  }
];
