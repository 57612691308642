<div class="modal-header pre-size header-style" style="text-align: center">
    <h3 class="text-truncate">
        {{title}}
    </h3>
    <div aria-label="Close" data-dismiss="modal" class="btn-close clickable"  (click)="cancel()"></div>
</div>

<div class="modal-content" style="border: 0px;">
    <div class="modal-body">
        <div [innerHTML]="body">
        </div>
    </div>
</div>

<div class="modal-footer">
    <div class="full-width">
        <div class="pull-left">
            <button class="btn btn-lg" [ngClass]="buttonClass(firstButton())"
                    (click)="clicked(firstButton())">{{firstButton().title}}</button>
        </div>
        <div class="pull-right">
            <button class="btn btn-lg" [ngClass]="buttonClass(secondButton())"
                    (click)="clicked(secondButton())">{{secondButton().title}}</button>
        </div>
    </div>
</div>


