import {Injectable} from '@angular/core';

export enum LoadingState {
  IDLE = 'IDLE',
  FINISHING = 'FINISHING',
  LOADING = 'LOADING'
}

@Injectable()
export class LoadingSpinnerUtil {
  private static MINIMUM_SPINNER_TIME_MILLIS = 1000;
  private state: LoadingState = LoadingState.IDLE;
  private loadingStarted: number;
  private loadingTimeout: number;

  public get isSpinning(): boolean {
    return this.isLoading || this.state === LoadingState.FINISHING;
  }

  public get isLoading(): boolean {
    return this.state === LoadingState.LOADING;
  }

  startLoading(): void {
    this.state = LoadingState.LOADING;
    this.loadingStarted = Date.now();
    clearTimeout(this.loadingTimeout)
  }

  stopLoading(): void {
    if (this.state === LoadingState.LOADING) {
      setTimeout(() => {
        if (this.isLoading) {
          this.state = LoadingState.FINISHING;
        }
      }, 300);
      // @ts-ignore
      this.loadingTimeout = setTimeout(() => {
        this.state = LoadingState.IDLE;
      }, this.remainingSpinnerTime());
    }
  }

  /**
   * use stopLoading() instead - it does the same :-)
   */
  instantStopLoading(): void {
    this.stopLoading();
  }

  private remainingSpinnerTime(): number {
    const spinTime = LoadingSpinnerUtil.MINIMUM_SPINNER_TIME_MILLIS;
    const totalLoadTime = Date.now() - this.loadingStarted;
    return totalLoadTime < spinTime ? spinTime - totalLoadTime : totalLoadTime % spinTime;
  }
}
