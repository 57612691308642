import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BroadcastDTO} from '../../dto/broadcast-dto.model';

@Component({
  selector: 'lc-broadcast-list',
  templateUrl: './broadcast-list.component.html',
  styleUrls: ['broadcast-list.scss']
})
export class BroadcastListComponent implements OnInit {
  @Input() broadcasts: BroadcastDTO[];
  @Output() deleteBroadcastEmitter: EventEmitter<BroadcastDTO> = new EventEmitter<BroadcastDTO>();
  selectedBroadcasts: BroadcastDTO[] = [];

  constructor() {
  }

  ngOnInit(): void {
    for (const broadcast of this.broadcasts) {
      if (broadcast.isUnread) {
        this.selectedBroadcasts.push(broadcast);
      }
    }
  }

  public toggleSelected(broadCast: BroadcastDTO): void {
    if (this.isSelected(broadCast)) {
      delete this.selectedBroadcasts[this.selectedBroadcasts.indexOf(broadCast)];
    } else {
      this.selectedBroadcasts.push(broadCast);
    }
  }

  public isSelected(broadCast: BroadcastDTO): boolean {
    return this.selectedBroadcasts.indexOf(broadCast) > -1;
  }

  public delete(broadCast: BroadcastDTO): void {
    this.deleteBroadcastEmitter.emit(broadCast);
  }
}
