import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

export enum ConfirmButtonType {
  CONFIRM = 'CONFIRM',
  DECLINE = 'DECLINE'
}

export class ConfirmPopupButton {
  public static readonly DEFAULT_FIRST = new ConfirmPopupButton('Ja', ConfirmButtonType.CONFIRM);
  public static readonly DEFAULT_SECOND = new ConfirmPopupButton('Nej', ConfirmButtonType.DECLINE);

  private _title: string;
  private _type: ConfirmButtonType;
  public btnClass: string;

  constructor(title: string, type: ConfirmButtonType, btnClass?: string) {
    this._title = title;
    this._type = type;
    this.btnClass = btnClass;
  }

  get title(): string {
    return this._title;
  }

  get type(): ConfirmButtonType {
    return this._type;
  }
}

@Component({
  templateUrl: './confirm-popup.component.html',
})
export class ConfirmPopupComponent {
  @Input() title: string;
  @Input() body: string;
  @Input() customFirstButton: ConfirmPopupButton;
  @Input() customSecondButton: ConfirmPopupButton;

  constructor(private activeModal: NgbActiveModal) {
  }

  public cancel(): void {
    this.activeModal.dismiss(false);
  }

  public clicked(button: ConfirmPopupButton): void {
    this.activeModal.close(button.type === ConfirmButtonType.CONFIRM);
  }

  public buttonClass(button: ConfirmPopupButton): string {
    return button.btnClass ? button.btnClass : (button.type === ConfirmButtonType.CONFIRM ? 'btn-danger' : 'btn-outline-primary');
  }

  public firstButton(): ConfirmPopupButton {
    return this.customFirstButton ? this.customFirstButton : ConfirmPopupButton.DEFAULT_FIRST;
  }

  public secondButton(): ConfirmPopupButton {
    return this.customSecondButton ? this.customSecondButton : ConfirmPopupButton.DEFAULT_SECOND;
  }
}

