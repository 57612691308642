import {Routes} from '@angular/router';

import {HomeComponent} from './';
import {MsalRedirectComponent} from '@azure/msal-angular';
import {B2cActivationComponent} from './b2c-activation/b2c-activation.component';
import {ProductRecycleComponent} from '../product/product-recycle.component';

export const HOME_ROUTES: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: {
      authorities: [],
      pageTitle: 'Forsikringsselskabernes integrerede skadeopgørelsessystem'
    },
    children: [
      {
        // Needed for handling redirect after login
        path: 'state',
        component: MsalRedirectComponent
      }
    ]
  },
  {
    path: 'b2c-login-activation',
    component: B2cActivationComponent
  },
  {
    path: 'product-recycle',
    component: ProductRecycleComponent,
  }
];
