import {NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import {DeliveryNoteTemplateOptions} from './model/delivery-note-template-options.model';
import {NgbForsiDateMomentParserFormatter} from '../../index';

export class DeliveryNoteUtil {
  static ngbForsiDateMomentParserFormatter = new NgbForsiDateMomentParserFormatter();

  static formatTime(time: NgbTimeStruct): string {
    const t = moment({
      hour: time.hour,
      minute: time.minute
    });
    return t.isValid() ? t.format('HH:mm') : '';
  }

  static getCallOnText(options: DeliveryNoteTemplateOptions): string {
    let text = options.callOnText;

    if (options.callOn) {
      text = options.callOnText + ' ' + (options.callOnNumber ? options.callOnNumber : '');
      if (options.callOnBetweenTimes && options.callOnBetweenTimesFrom != null && options.callOnBetweenTimesTo != null) {
        text = text + ' ' + this.getCallOnBetweenTimesText(options);
      }
    }

    return text;
  }

  static getCallOnBetweenTimesText(options: DeliveryNoteTemplateOptions): string {
    let text = options.callOnBetweenTimesTextOne + ' ' + options.callOnBetweenTimesTextTwo;

    if (options.callOnBetweenTimes) {
      text = options.callOnBetweenTimesTextOne +
        (options.callOnBetweenTimesFrom ? this.formatTime(options.callOnBetweenTimesFrom) : '') +
        ' ' +
        options.callOnBetweenTimesTextTwo +
        (options.callOnBetweenTimesTo ? this.formatTime(options.callOnBetweenTimesTo) : '');
    }

    return text;
  }

  static getCheckForAmountForSaleOfSparePartsText(options: DeliveryNoteTemplateOptions): string {
    return options.checkForAmountForSaleOfSparePartsTextOne +
      ' ' +
      (options.checkForAmountForSaleOfSparePartsAmount ? options.checkForAmountForSaleOfSparePartsAmount : '') +
      ' ' +
      options.checkForAmountForSaleOfSparePartsTextTwo;
  }

  static getAccordingToLetterOrPhoneText(options: DeliveryNoteTemplateOptions): string {
    return options.accordingToLetterOrPhoneText +
      ' ' +
      (options.accordingToLetterOrPhoneDate ? this.ngbForsiDateMomentParserFormatter.format(options.accordingToLetterOrPhoneDate) : '');
  }

  static getVehicleCanceledText(options: DeliveryNoteTemplateOptions): string {
    return options.vehicleCanceledText +
      ' ' +
      (options.vehicleCanceledDate ? this.ngbForsiDateMomentParserFormatter.format(options.vehicleCanceledDate) : '');
  }
}
