import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {AboutComponent} from './about.component';
import {ABOUT_ROUTES} from './about.route';
import {SharedCommonModule} from '../shared/shared-common.module';
import {SharedModule} from 'app/shared/shared.module';

@NgModule({
  imports: [
    SharedModule,
    SharedCommonModule,
    RouterModule.forRoot(ABOUT_ROUTES, {useHash: true})
  ],
  declarations: [
    AboutComponent,
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AboutModule {
}
