<div class="modal-header pre-size header-style" style="text-align: center">
    <h3 class="text-truncate">
        Vælg Rolle / Bruger
    </h3>
    <div aria-label="Close" data-dismiss="modal" class="btn-close clickable" type="button" (click)="cancel()"></div>
</div>

<div class="modal-body">
                        <div class="pb-3">
                            <label>Vælg rolle</label>
                            <select [(ngModel)]="chosenRole" class="col-12 form-select" id="chosenRole"
                                    [disabled]="!roles" name="chosenRole" (change)="onSelectRoleChanged()">
                                <option *ngFor="let chosenRole of roles" [ngValue]="chosenRole">
                                    {{chosenRole.name}}
                                </option>
                            </select>
                        </div>
                        <div class="pb-3">
                            <label>Vælg bruger</label>
                            <input [(ngModel)]="chosenUser.name"
                                   class="col-12 form-select"
                                   type="text" id="user1" name="user"
                                   minlength=1 maxlength=10 size="10"
                                   oninput="this.value = this.value.toUpperCase()"
                                   [disabled]="isRoleChosen() || !roleHasUser(chosenRole)"
                            />
                        </div>

</div>


<div class="modal-footer">
    <button class="btn btn-sm btn-primary pull-right" (click)="onHentRapportCLick()">
        Hent Rapport
    </button>
</div>
