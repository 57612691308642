import {Routes} from '@angular/router';
import {UserRouteAccessService} from '../shared/service/auth/user-route-access.service';
import {FaqComponent} from './faq.component';
import {AppRole} from '../shared/service/auth/app-roles.model';

export const FAQ_ROUTES: Routes = [{
  path: 'faq',
  component: FaqComponent,
  data: {
    authorities: [AppRole.VK, AppRole.TAKS, AppRole.SAGSB, AppRole.AFTALEADMIN, AppRole.ONLYADMIN],
    pageTitle: 'FAQ',
    breadcrumb: 'FAQ'
  },
  canActivate: [UserRouteAccessService]
}
];
