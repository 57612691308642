<fieldset ngForm="f" #f="ngForm">
    <label for="remarksText">Bemærkninger</label>
    <span class="float-end">
        <button type="button" class="btn btn-sm btn-primary" (click)="showUserTextLibrary()">Eget Tekstbibliotek</button>
        <lc-org-text-library-button *lcHasAnyAuthority="'ROLE_Taks'" class="ms-2" [libraryType]="libraryType" [reportType]="getReportType()" (selectedText)="didSelectTextFromLibrary($event)"></lc-org-text-library-button>
    </span>
    <div class="mt-2">
	    <textarea [(ngModel)]="details.bemaerkninger" #remarksText
                  id="remarksText" name="remarksText" class="form-control monospace-text text-uppercase"
                  rows="{{remarkLinesLimit}}" [ngClass]="{'ng-invalid':remarkLinesCount > remarkLinesLimit}"
                  (ngModelChange)="fixMaxLineLength(remarksText)" maxlength="420">
        </textarea>
        <div class="{{getMessageClass()}}">{{getMessage()}}</div>
    </div>
</fieldset>
