export class MessageUtils {
  public static REPLY_PREFIX = 'SV:';

  /**
   * JOL: If you reply to a reply we expect the initial subject to start with "SV: SV: SUBJECT"
   * This subject is only a initial suggestion. The users can change it however they want afterwards.
   */
  public static getReplySubject(subject: string): string {
    return MessageUtils.REPLY_PREFIX + ' ' + subject;
  }
}


