import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ClientStateDetails} from '../../../../../model/client-state-details.model';
import {NgForm} from '@angular/forms';
import {FormSaver} from '../../../../../../shared/service/core/form-saver.service';

@Component({
  selector: 'lc-er-replacement-receiver',
  templateUrl: './er-replacement-receiver.component.html'
})
export class ErReplacementReceiverComponent implements OnInit {
  @Input() formId: string;
  @Input() details: ClientStateDetails;
  @ViewChild('f', {static: true}) public cform: NgForm;

  constructor(private formSaver: FormSaver) {
  }

  ngOnInit(): void {
    this.formSaver.registerForm(this.formId, this.cform);
  }
}
