import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormSaver} from '../../../../../shared/service/core/form-saver.service';
import {NgForm} from '@angular/forms';
import {ClientStateDetails} from '../../../../model/client-state-details.model';

@Component({
  selector: 'lc-vat-information',
  templateUrl: './vat-information.component.html',
  providers: [],
  styleUrls: []
})
export class VatInformationComponent implements OnInit {
  @Input() formId: string;
  @Input() details: ClientStateDetails;
  @ViewChild('f', {static: true}) public cform: NgForm;

  constructor(private formSaver: FormSaver) {
  }

  ngOnInit(): void {
    this.formSaver.registerForm(this.formId, this.cform);
  }
}

