export enum RepairType {
  A = 'A', // Landbrugsredskab
  B = 'B', // Bygning
  D = 'D', // Campingvogn
  E = 'E', // Entreprenør Materialer
  I = 'I', // Diverse
  L = 'L', // Lastvogn
  M = 'M', // Motorcykel
  P = 'P', // Personbil
  Ø = 'Ø'  // Løsøre
}
