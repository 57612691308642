export enum SparePartState {
  CM = 'CM',
  BM = 'BM'
}
export enum DeliveryTerm {
  DAY_TO_DAY = 'DAY_TO_DAY',
  WITHIN_THREE_DAYS = 'WITHIN_THREE_DAYS',
  WITHIN_FIVE_DAYS = 'WITHIN_FIVE_DAYS',
  WITHIN_SEVEN_DAYS = 'WITHIN_SEVEN_DAYS',
  WITHIN_TEN_DAYS = 'WITHIN_TEN_DAYS',
  WITHIN_FOURTEEN_DAYS = 'WITHIN_FOURTEEN_DAYS',
  ESTIMATE = 'ESTIMATE'
}
export class SparePartMatchDTO {
  sparePartMatchId: number;
  supplierId: number;
  supplierName: string;
  guideNumber: string;
  description: string;
  indicativePrice: number;
  selectionBlocked: boolean;

  state: SparePartState;
  oemNumber: string;
  supplierReference: string;
  make: string;
  thirdPartySupplier: string;
  quality: string;
  color: string;
  href: string;
  deliveryTerms: DeliveryTerm;
  deliveryEstimate: number;
  price: number;
  supplierDescription: string;
  remarks: string;
  year: number;
  km: number;

  qualityDesignationDescription: string;

  selected = false;
  visible = true;
  bookAcknowledge: string;
}

export class MatchSparePartsResponseDTO {
  supplierId: number;
  success: boolean;
  errorMessage: string;
  matches: SparePartMatchDTO[];
  autoflexFetchedAt: Date;
}
