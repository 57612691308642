export class DeliveryNoteConfigurationDTO {
  line1: string;
  line2: string;
  line3: string;
  line4: string;
  line5: string;
  topmargin: number;
  venstremargin: number;
  telefon: string;
  fraKlokken: string;
  tilKlokken: string;
}
