import {PriceVersionState} from '../dto/price-version.state';
import {isNullOrUndefined} from './object-utils';

export class StringUtils {

  public static isEmpty(str: string): boolean {
    return isNullOrUndefined(str) || str.trim() === '';
  }

  public static isNotEmpty(str: string): boolean {
    return !StringUtils.isEmpty(str);
  }

  public static splitStringAtIntervalWord(s: string, interval: number): string[] {
    const result: string[] = [];
    if (!s) {
      result.push('');
    }
    if (s.length <= interval) {
      return [s];
    }
    let lastLinebreakPosition = 0;
    while (lastLinebreakPosition < s.length) {
      const longestPossibleLine = s.substr(lastLinebreakPosition, interval);
        const newLinebreakPosition = StringUtils.findLastIndexOfAnyString(longestPossibleLine, [' ', '\n']);
        if (newLinebreakPosition <= 0 || lastLinebreakPosition + interval >= s.length) {
          result.push(longestPossibleLine);
          lastLinebreakPosition += interval;
        } else {
          result.push(s.substr(lastLinebreakPosition, newLinebreakPosition));
          lastLinebreakPosition += newLinebreakPosition + 1;
      }
    }
    return result;
  }

  private static findLastIndexOfAnyString(str: string, lookupStrings: string[]): number {
    let result = -1;
    for (const lookupString of lookupStrings) {
      const idx = str.lastIndexOf(lookupString);
      result = Math.max(idx, result);
    }
    return result;
  }

  public static padStart(orgString: string, paddingString: string, length: number): string {
    let result = orgString;
    if (result.length < length) {
      while (result.length < length) {
        result = paddingString + result;
      }
    }
    return result;
  }

  public static getPriceVersionUseTitle(state: PriceVersionState): string {
    switch (state) {
      case PriceVersionState.NONE: {
        return 'Ingen';
      }
      case PriceVersionState.VALIDATION: {
        return 'AF';
      }
      case PriceVersionState.PUBLIC: {
        return 'Alle';
      }
      default: {
        return 'Ukendt';
      }
    }
  }

  public static compare(a: string, b: string): number {
    return (a < b ? -1 : (a > b ? 1 : 0));
  }
}
