import {Component, OnDestroy} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Subject} from 'rxjs';

@Component({
  templateUrl: './assessor-video-request-popup.component.html',
})
export class AssessorVideoRequestPopupComponent implements OnDestroy {

  private unsubscribe$ = new Subject<void>();
  public reasonText;

  constructor(public activeModal: NgbActiveModal) {
  }

  public didClickSubmit(): void {
    this.activeModal.close(this.reasonText);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
