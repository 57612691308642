<div class="modal-header pre-size header-style" style="text-align: center">
    <h3 class="text-truncate">
        Kopier kladde
    </h3>
    <div aria-label="Close" data-dismiss="modal" class="btn-close clickable" (click)="cancel()">
    </div>
</div>

<div class="modal-content">

    <div class="text-center" *ngIf="showLoading">
        <i class="fa fa-circle-o-notch fa-spin fop-page-spinner"></i>
    </div>

    <div *ngIf="!showLoading" class="modal-body modal-body-style">
        <div class="mb-0">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" [(ngModel)]="beregningsData" [disabled]="isTypeChanged()" name="beregningsData" id="beregningsData">
                <label for="beregningsData">Beregningsdata</label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" [(ngModel)]="bilag" name="bilag" id="bilag">
                <label for="bilag">Bilag</label>
            </div>
            <ng-container *ngIf="showCopyPossibilities()">
                <div class="form-check">
                    <input class="form-check-input" type="radio" value="autotaks-report" id="damageType1" name="damageType" (change)="onDamageTypeChanged()" [(ngModel)]="reportTypeOfCopy">
                    <label for="damageType1">Autotaks</label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio" value="glastaks-report" id="damageType2" name="damageType" (change)="onDamageTypeChanged()" [(ngModel)]="reportTypeOfCopy">
                    <label for="damageType2">Glastaks</label>
                </div>
            </ng-container>
            <div *lcHasAnyAuthority="'ROLE_Taks'" class="form-check">
                <input class="form-check-input" type="checkbox" [(ngModel)]="niTusindNr" name="niTusindNr" id="niTusindNr">
                <label for="niTusindNr">9000 Nr</label>
            </div>
            <div class="mt-1 p-2 autoflex-warning" *ngIf="hasAutoflexBookings|async">
                <p>Du er ved at kopiere en sag, som indeholder Autoflex-data. Disse data kopieres ikke.</p>
                <p class="mb-0">Det anbefales at køre Autoflex igen efter kopiering</p>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <div class="full-width">
            <button class="btn btn-primary me-2 pull-left" (click)="doCopy()">Opret</button>
            <button class="btn pull-right" (click)="cancel()">Fortryd</button>
        </div>
    </div>
</div>
