<div class="w-100" *ngIf="!isLoading; else spinner">
    <lc-linked-report-list
        [potentialLinkedReportTypes]="potentialLinkedReportTypes"
        [reportKey]="report.key"
        [disableButtons]="ongoingRequest"
        (potentialLinkedReportSelectedEmitter)="handlePotentialLinkedReportSelected($event)">
    </lc-linked-report-list>
</div>
<ng-template #spinner>
    <div class="text-center">
        <i class="fa fa-circle-o-notch fa-spin fop-page-spinner"></i>
    </div>
</ng-template>
