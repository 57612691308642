import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SkavuPositionValue} from '../model/skavu-position-value.model';
import {map, shareReplay} from 'rxjs/operators';

export class PositionNode {
  position: Position;
  values: SkavuPositionValue[] = [];
  children: PositionNode[] = [];
  parent: PositionNode;
  id: number;
  text: string;
  ledenr: string;
  follow: boolean;

  public static create(p: Position): PositionNode {
    const pn = new PositionNode();
    //pn.
    pn.position = p;
    pn.ledenr = p.ledenr;
    pn.id = p.id;
    pn.text = p.arbejdsTekst;
    pn.follow = (p !== SkavuPositionService.allP);
    return pn;
  }

  public getAll(): PositionNode[] {
    let result = [];
    result.push(this);
    this.children.forEach(p => {
      if (p.follow) {
        result = result.concat(p.getAll());
      }
    });
    return result;
  }
}

export class Position {
  id: number;
  arbejdsTekst: string;
  reservedelsTekst: string;
  ledenr: string;
  ledenrDel: string;
  sortNumber: number;
}

export class PositionDTO {
  id: number;
  arbejdsTekst: string;
  reservedelsTekst: string;
  ledenr: string;
  ledenrDel: string;
  sortNumber: number;
  children: PositionDTO[];

}

export class ExtraText {
  workText: boolean;
  repairText: boolean;
  code: string;
  text: string;

  static fromDTO(dto: ExtraTextDTO): ExtraText {
    const model = new ExtraText();
    model.workText = dto.workText;
    model.repairText = dto.repairText;
    model.code = dto.code;
    model.text = dto.text;
    if (model.text.length > 1) {
      model.text = model.text.charAt(0).toUpperCase() + model.text.slice(1);
    }
    model.text += ' (' + model.code + ')';
    return model;
  }

  static fromDTOs(dtos: ExtraTextDTO[]): ExtraText[] {
    return dtos.map(ExtraText.fromDTO)
  }
}

export class ExtraTextDTO {
  workText: boolean;
  repairText: boolean;
  code: string;
  text: string;
}

@Injectable()
export class SkavuPositionService {
  static allP = new Position();

  baseUrl = 'skavuposition';

  private positions = new Map<number, Observable<PositionNode[]>>();
  private extraTexts: Observable<ExtraText[]> = null;

  private static fromDtos(dtos: PositionDTO[], parent: PositionNode): PositionNode[] {
    const result = dtos.map(dto => SkavuPositionService.fromDto(dto, parent));
    this.allP.arbejdsTekst = 'Alle';
    if (parent == null) {
      // At root level
      const all0 = PositionNode.create(this.allP);
      const all1 = PositionNode.create(this.allP);
      all0.children.push(all1);
      all1.parent = all0;
      all1.children = [].concat.apply([], result.map(r => r.getAll()));
      result.unshift(all0);
    } else {
      if (parent.parent == null) {
        // at level 1.
        const all1 = PositionNode.create(this.allP);
        all1.parent = parent;
        all1.children = [].concat.apply([], result.map(r => r.getAll()));
        result.unshift(all1);
      }
    }
    return result;
  }

  private static fromDto(dto: PositionDTO, parent: PositionNode): PositionNode {
    const p = new Position();
    p.id = dto.id;
    p.arbejdsTekst = dto.arbejdsTekst;
    p.reservedelsTekst = dto.reservedelsTekst;
    p.ledenrDel = dto.ledenrDel;
    p.ledenr = dto.ledenr;
    p.sortNumber = dto.sortNumber;
    const pn = PositionNode.create(p);
    pn.parent = parent;
    pn.children = SkavuPositionService.fromDtos(dto.children, pn);
    return pn;
  }

  constructor(private http: HttpClient) {
  }

  private htGet(listeTypeId: number): Observable<PositionNode[]> {
    return this.http.get<PositionDTO[]>(
      this.baseUrl + '/' + listeTypeId.toString())
      .pipe(
        map(p => SkavuPositionService.fromDtos(p, null)));
  }

  public get(listeTypeId: number): Observable<PositionNode[]> {
    if (!this.positions.get(listeTypeId)) {
      this.positions.set(listeTypeId, this.htGet(listeTypeId).pipe(shareReplay(1)));
    }
    return this.positions.get(listeTypeId);
  }

  public getExtraTexts(): Observable<ExtraText[]> {
    if (!this.extraTexts) {
      this.extraTexts = this.http.get<ExtraTextDTO[]>(this.baseUrl + '/extratexts')
        .pipe(
          map(e => ExtraText.fromDTOs(e)),
          shareReplay(1)
        );
    }
    return this.extraTexts;
  }
}
