import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {UserDTO} from '../dto/user-dto.model';
import {SubstituteDTO} from '../dto/substitute-dto.model';
import {BusinessPartnerDTO} from '../dto/business-partner-dto.model';
import {CreateBusinessPartnerDTO} from '../dto/create-business-partner-dto.model';

/**
 * Used as Current Account Service
 * Here you can get elements belonging / related to the current account.
 */
@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private serviceUrl = 'account/';

  private hasBusinessPartners = new Subject<boolean>();
  private hasBusinessPartnersInitialized = false;

  constructor(private http: HttpClient) {
  }

  public getColleagues(includePostboxes: boolean, includeRobots: boolean): Observable<UserDTO[]> {
    let params = new HttpParams();
    params = params.set('postboxes', '' + includePostboxes);
    params = params.set('robots', '' + includeRobots);
    return this.http.get<UserDTO[]>(this.serviceUrl + 'colleagues', {params});
  }
  
  public getSubstitutes(): Observable<SubstituteDTO[]> {
    return this.http.get<SubstituteDTO[]>(this.serviceUrl + 'substitutes');
  }
  
  public createBusinessPartner(businessPartner: CreateBusinessPartnerDTO): Observable<BusinessPartnerDTO> {
    return this.http.post<BusinessPartnerDTO>(this.serviceUrl + 'businesspartners', businessPartner);
  }

  public getBusinessPartners(): Observable<BusinessPartnerDTO[]> {
    return this.http.get<BusinessPartnerDTO[]>(this.serviceUrl + 'businesspartners');
  }

  public deleteBusinessPartner(businessPartnerId: number): Observable<any> {
    return this.http.delete(this.serviceUrl + 'businesspartners/' + businessPartnerId);
  }

  public setShowBusinessToBusinessMenu(hasBusinessPartners: boolean): void {
    this.hasBusinessPartners.next(hasBusinessPartners);
  }

  public getShowBusinessToBusinessMenu(): Subject<boolean> {
    if (!this.hasBusinessPartnersInitialized) {
      this.getBusinessPartners().subscribe((businessPartners: BusinessPartnerDTO[]) => {
        this.hasBusinessPartners.next(businessPartners.length > 0);
        this.hasBusinessPartnersInitialized = true;
      });
    }

    return this.hasBusinessPartners;
  }

  public setShowBusinessToBusinessMenuNotInitialized(): void {
    this.hasBusinessPartnersInitialized = false;
  }
}
