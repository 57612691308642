import {Component, OnDestroy, OnInit} from '@angular/core';
import {CheckOpslagViewModel} from './check-opslag.view-model';
import {ForsiReportKeyShortPipe} from '../../../shared/pipe/forsi-report-key-short.pipe';
import {ForsiReportKeyPipe} from '../../../shared/pipe/forsi-report-key.pipe';
import {ActivatedRoute, Router} from '@angular/router';
import {ClientStateDifferService, PoliceDifference} from './client-state-differ.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  templateUrl: './check-opslag.component.html',
  styleUrls: ['./check-opslag.scss'],
  providers: [CheckOpslagViewModel, ClientStateDifferService, ForsiReportKeyPipe, ForsiReportKeyShortPipe]
})
export class CheckOpslagComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  constructor(public viewmodel: CheckOpslagViewModel,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.viewmodel.navigateSubject.pipe(takeUntil(this.unsubscribe$)).subscribe((path: string) => {
      this.router.navigate([path], {relativeTo: this.route});
    });
  }

  resetPoliceDiff(policeDiff: PoliceDifference): void {
    this.viewmodel.resetPoliceDifference(policeDiff);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
