export class DamageZoneDTO {
  public static zone1 = new DamageZoneDTO('1', 'Front');
  public static zone2 = new DamageZoneDTO('2', 'Højre side forrest');
  public static zone3 = new DamageZoneDTO('3', 'Venstre side forrest');
  public static zone4 = new DamageZoneDTO('4', 'Højre side midtfor');
  public static zone5 = new DamageZoneDTO('5', 'Venstre side midtfor');
  public static zone6 = new DamageZoneDTO('6', 'Højre side bagerst');
  public static zone7 = new DamageZoneDTO('7', 'Venstre side bagerst');
  public static zone8 = new DamageZoneDTO('8', 'Bagparti');
  public static zone9 = new DamageZoneDTO('9', 'Tag');
  public static zoneB = new DamageZoneDTO('B', 'Bagrude');
  public static zoneF = new DamageZoneDTO('F', 'Forrude');
  public static zoneK = new DamageZoneDTO('K', 'Kabine');
  public static zoneM = new DamageZoneDTO('M', 'Mekaniske dele');
  public static zoneO = new DamageZoneDTO('O', 'Overalt');
  public static zoneT = new DamageZoneDTO('T', 'Tilbehør');
  public static zoneU = new DamageZoneDTO('U', 'Undervogn');

  zone: string;
  name: string;

  public static valueOf(zone: string): DamageZoneDTO {
    switch (zone) {
      case '1':
        return DamageZoneDTO.zone1;
      case '2':
        return DamageZoneDTO.zone2;
      case '3':
        return DamageZoneDTO.zone3;
      case '4':
        return DamageZoneDTO.zone4;
      case '5':
        return DamageZoneDTO.zone5;
      case '6':
        return DamageZoneDTO.zone6;
      case '7':
        return DamageZoneDTO.zone7;
      case '8':
        return DamageZoneDTO.zone8;
      case '9':
        return DamageZoneDTO.zone9;
      case 'B':
        return DamageZoneDTO.zoneB;
      case 'F':
        return DamageZoneDTO.zoneF;
      case 'K':
        return DamageZoneDTO.zoneK;
      case 'M':
        return DamageZoneDTO.zoneM;
      case 'O':
        return DamageZoneDTO.zoneO;
      case 'T':
        return DamageZoneDTO.zoneT;
      case 'U':
        return DamageZoneDTO.zoneU;
    }

    return null;
  }

  constructor(zone: string, name: string) {
    this.zone = zone;
    this.name = name;
  }

  public getLongName(): string {
    return 'Zone ' + this.zone + ': ' + this.name;
  }

  public getShortName(): string {
    return this.name;
  }
}
