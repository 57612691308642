export class ValueTypeDTO {
  id: number;
  type: string;
  unit: string;
  kode: number;

  get unitSign(): string {
    switch (this.unit) {
      case 'procent':
        return '%';
      case 'kroner':
        return 'kr';
      case 'arbejdsenhed':
        return 'AE';
      default:
        return this.unit;
    }
  }

  get description(): string {
    return this.type + ' (' + this.kode + ') [' + this.unitSign + ']';
  }

  round(val: number): number {
    switch (this.unit) {
      case 'procent':
        return Math.round(val * 10) / 10;
      case 'kroner':
        return Math.round(val);
      default:
        return Math.round(val);
    }
  }

  getInputMask(): string {
    switch (this.unit) {
      case 'procent':
        return 'int6_1RightAlign';
      case 'kroner':
        return 'int7RightAlign';
      default:
        return 'int6RightAlign';
    }
  }
}
