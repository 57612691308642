import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, Renderer2} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ReorderButtonPopupComponent} from './reorder-popup/reorder-button-popup.component';
import {from, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

/**
 * Common reorder data table button
 */
@Component({
  selector: 'lc-reorder-button',
  templateUrl: './reorder-button.component.html',
  styleUrls: ['./reorder-button.scss'],
})
export class ReorderButtonComponent  implements AfterViewInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  @Input() public title: string;
  @Input() public label: string;
  @Input() public position: number;
  @Output('onReorder') reorderEmitter = new EventEmitter<number>();

  constructor(private renderer: Renderer2, private ref: ElementRef, private modal: NgbModal) {
  }

  ngAfterViewInit(): void {
    const nativeElement = this.ref.nativeElement as Element;
    nativeElement.addEventListener('click', (event) => {
      this.didPressReorder();
    });
    this.renderer.addClass(nativeElement, 'table-button');
  }

  public didPressReorder(): void {
    const ngbModalRef = this.modal.open(ReorderButtonPopupComponent);
    const componentInstance = ngbModalRef.componentInstance as ReorderButtonPopupComponent;
    componentInstance.init(this.title, this.label, this.position);
    from(ngbModalRef.result).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(value => {
      this.reorderEmitter.emit(value);
    }, error => {
      //popup just closed
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
