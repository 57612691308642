import {StringUtils} from '../../shared/utils/string-utils';

export class BankDetails {
  bankRegnr: string;
  bankKontonr: string;
  nemkonto: boolean;

  validate(): void {
    if (this.nemkonto) {
      this.bankRegnr = '';
      this.bankKontonr = '';
    }
  }

  isBankRequired(): boolean {
    const bankRegnrFilled = StringUtils.isNotEmpty(this.bankRegnr);
    const bankKontonrFilled = StringUtils.isNotEmpty(this.bankKontonr);
    return bankRegnrFilled || bankKontonrFilled;
  }
}
