<fieldset ngForm="f" #f="ngForm">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <!-- Left part -->
                <div class="col-12">
                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label for="calculationdate">Reservedelsprisdato</label>
                        </div>
                        <div class="col">
                            <div class="input-group" style="padding: 0px;">
                                <input class="form-control" type="tel" id="calculationdate" name="calculationdate"
                                    [(ngModel)]="details.calculationDate" ngbDatepicker
                                    #calculationdateDP="ngbDatepicker" lcInputMask="date"
                                    [minDate]="{ 'year': 1900, 'month': 01, 'day': 01 }"
                                    [maxDate]="{ 'year': 2099, 'month': 12, 'day': 31}">
                                <button class="btn btn-outline-secondary" (click)="calculationdateDP.toggle()"
                                    type="button">
                                    <i class="fa fa-calendar fa-lg"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fieldset>