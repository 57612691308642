import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BootstrapGrowlService} from './bootstrap-growl.service';
import {BootstrapAlert} from './bootstrap-alert.model';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'lc-bootstrap-growl',
  templateUrl: './bootstrap-growl.component.html'
})
export class BootstrapGrowlComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  @Input() alertCount: number;
  @Input() autoClose: number;

  public alerts: BootstrapAlert[];

  constructor(private growlService: BootstrapGrowlService) {

  }

  ngOnInit(): void {
    this.growlService.configure(this.alertCount, this.autoClose);
    this.growlService.alerts.pipe(takeUntil(this.unsubscribe$)).subscribe((alerts: BootstrapAlert[]) => {
      this.alerts = alerts;
    });
  }

  public closeAlert(alert: BootstrapAlert): void {
    // service is automatically notified!
    if (alert.dismissable) {
      this.growlService.removeAlert(alert);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
