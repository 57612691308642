import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {distinctUntilChanged, filter, map, mergeMap, switchMap, takeUntil} from 'rxjs/operators';
import {ApplicationKeyDTO} from '../../sysadmin/application-key/model/application-key-dto.model';
import {PrincipalService} from './auth/principal.service';
import {AppVisibilityService} from './core/app-visibility.service';

@Injectable({
  providedIn: 'root',
})
export class ApplicationKeyPendingChecker {

  private loginChanged$ = this.principalService.getAuthenticationState().pipe(distinctUntilChanged());
  private sysadminLoggedOn$ = this.loginChanged$.pipe(map(p => this.principalService.isSysadminUser()), filter((isSysadmin => isSysadmin)));
  private sysadminLoggedOut$ = this.loginChanged$.pipe(map(p => this.principalService.isSysadminUser()), filter((isSysadmin => !isSysadmin)));

  constructor(private http: HttpClient,
              private principalService: PrincipalService,
              private appVisibilityService: AppVisibilityService) {
  }

  /**
   * When sysadminLoggedOn$ emits a value, the observable switches to a timer and trigger fetch of pending keys periodically until sysadminLoggedOut$ emits a value.
   */
  public listenForPendingApplicationKeys$(): Observable<number> {
    return this.sysadminLoggedOn$.pipe(
      switchMap(() => this.appVisibilityService.forsiVisibilityInterval(5000, 30 * 60000).pipe(takeUntil(this.sysadminLoggedOut$))),
      mergeMap(() => this.http.get<ApplicationKeyDTO[]>('sysadmin/application-key/pending').pipe(map((response) => response.length)))
    );
  }
}
