<div class="modal-header">
    <h3 class="text-truncate">Hjælpetekst til
        model, {{modelHelpText.validFromAsDate()|date : 'dd-MM-yyyy'}}</h3>
    <div aria-label="Close" data-dismiss="modal" class="btn-close" (click)="activeModal.dismiss('closed')">
    </div>
</div>
<div class="modal-body">
    <div class="mb-1" style="font-weight: bold;font-size: 0.8rem">{{modelHelpText.header}}</div>
    <div style="white-space: break-spaces;">{{modelHelpText.text}}</div>
</div>
