import {Pipe, PipeTransform} from '@angular/core';

import {isNullOrUndefined} from '../utils/object-utils';
import {AudatexSettlementPeriodState} from '../../sysadmin/audatex/settlement/dto/audatex-settlement-period-dto.model';

@Pipe({
  name: 'audatexSettlementPeriodState'
})
export class AudatexSettlementPeriodStatePipe implements PipeTransform {
  private readonly STATE_MAPPINGS: { state: AudatexSettlementPeriodState, desc: string }[] = [
    {state: AudatexSettlementPeriodState.DONE, desc: 'Behandlet'},
    {state: AudatexSettlementPeriodState.FAILED, desc: 'Fejlet'},
    {state: AudatexSettlementPeriodState.NOT_PROCESSED, desc: 'Ubehandlet'},
    {state: AudatexSettlementPeriodState.IN_PROGRESS, desc: 'I gang'},
  ];

  transform(value: AudatexSettlementPeriodState): string {
    return !isNullOrUndefined(value) ? this.STATE_MAPPINGS.find(v => v.state === value).desc : value;
  }
}
