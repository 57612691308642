import {AdhocVkDetails} from '../../draft/model/adhoc-vk-details.model';
import {isNullOrUndefined} from '../utils/object-utils';

export class AdhocVkDetailsDTO {
  name: string;
  address1: string;
  address2: string;
  postalcode: string;
  email: string;
  phoneno: string;

  public static createAdhocVkDetailsDTO(adhocVkDetails: AdhocVkDetails): AdhocVkDetailsDTO {
    const result = new AdhocVkDetailsDTO();
    if (!isNullOrUndefined(adhocVkDetails)) {
      result.name = adhocVkDetails.name;
      result.address1 = adhocVkDetails.address1;
      result.address2 = adhocVkDetails.address2;
      result.postalcode = adhocVkDetails.postalcode;
      result.email = adhocVkDetails.email;
      result.phoneno = adhocVkDetails.phoneno;
    }
    return result;
  }

  public static createAdhocVkDetails(adhocVkDetailsDTO: AdhocVkDetailsDTO): AdhocVkDetails {
    const result = new AdhocVkDetails();
    if (!isNullOrUndefined(adhocVkDetailsDTO)) {
      result.name = adhocVkDetailsDTO.name;
      result.address1 = adhocVkDetailsDTO.address1;
      result.address2 = adhocVkDetailsDTO.address2;
      result.postalcode = adhocVkDetailsDTO.postalcode;
      result.email = adhocVkDetailsDTO.email;
      result.phoneno = adhocVkDetailsDTO.phoneno;
    }
    return result;
  }
}
