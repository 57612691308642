import {Component, ElementRef, HostListener, Input, ViewChild} from '@angular/core';
import {ReportInputAndPrintDiff} from './input-and-print-diff-dto.model';
import {BootstrapAlertType, BootstrapGrowlService} from '../ngx-bootstrap-growl';

@Component({
  selector: 'lc-input-and-print-diff',
  templateUrl: './input-and-print-diff.component.html',
  styleUrls: ['input-and-print-diff.scss']
})
export class InputAndPrintDiffComponent {
  private _reportDiff: ReportInputAndPrintDiff;
  @Input() alteredTitle: string;
  showing = 'inputRecords';
  visibleReport = 'original';

  // [innerHtml] is great option in most cases, but it fails with really large strings or when you need hard-coded styling in html.
  @ViewChild('inputRecordsDiffHtmlContainer', {static: true})
  inputRecordsDiffHtmlContainer: ElementRef;

  // [innerHtml] is great option in most cases, but it fails with really large strings or when you need hard-coded styling in html.
  @ViewChild('printDiffHtmlContainer', {static: true})
  printDiffHtmlContainer: ElementRef;

  @HostListener('window:keydown', ['$event'])
  spaceEvent(event: KeyboardEvent): any {
    if (event.code === 'Space') {
      event.preventDefault();
      this.toggleOriginalOrAltered();
    }
  }

  constructor(private bootstrapGrowlService: BootstrapGrowlService) {
  }

  @Input()
  get reportDiff(): ReportInputAndPrintDiff {
    return this._reportDiff;
  }

  set reportDiff(value: ReportInputAndPrintDiff) {
    this._reportDiff = value;
    this.inputRecordsDiffHtmlContainer.nativeElement.innerHTML = value.inputRecordsDiffHtml;
    this.printDiffHtmlContainer.nativeElement.innerHTML = value.printDiffHtml;
  }

  toggleOriginalOrAltered(): void {
    this.visibleReport = this.visibleReport === 'original' ? 'altered' : 'original';
  }

  copyText(text: string): void {
    const selectionBox = document.createElement('textarea');
    selectionBox.style.position = 'fixed';
    selectionBox.style.left = '0';
    selectionBox.style.top = '0';
    selectionBox.style.opacity = '0';
    selectionBox.value = text;
    document.body.appendChild(selectionBox);
    selectionBox.focus();
    selectionBox.select();
    document.execCommand('copy');
    document.body.removeChild(selectionBox);
    this.bootstrapGrowlService.addAlert('Kopieret til udklipsholder!', BootstrapAlertType.SUCCESS)
  }
}
