import {Injectable} from '@angular/core';
import {fromEvent, Observable} from 'rxjs';
import {distinctUntilChanged, map, shareReplay, startWith} from 'rxjs/operators';

export class ScreenSize {
  public static XL = new ScreenSize('xl', 4);
  public static LG = new ScreenSize('lg', 3);
  public static MD = new ScreenSize('md', 2);
  public static SM = new ScreenSize('sm', 1);
  public static XS = new ScreenSize('xs', 0);

  public static ALL = [ScreenSize.XS, ScreenSize.SM, ScreenSize.MD, ScreenSize.LG, ScreenSize.XL];

  constructor(public size: string, private weight: number) {
  }



  public lessThan(screenSize: ScreenSize): boolean {
    return this.weight < screenSize.weight;
  }

  public largerThan(screenSize: ScreenSize): boolean {
    return this.weight > screenSize.weight;
  }

  public eq(screenSize: ScreenSize): boolean {
    return this.weight === screenSize.weight;
  }
}


//Bootstrap 4 breakpoints
const QUERY: Map<ScreenSize, string> = new Map([
  [ScreenSize.XL, '(min-width: 1400px)'],
  [ScreenSize.LG, '(min-width: 992px)'],
  [ScreenSize.MD, '(min-width: 768px)'],
  [ScreenSize.SM, '(min-width: 576px)'],
  [ScreenSize.XS, '(min-width: 0px)'],
]);

@Injectable({
  providedIn: 'root'
})
export class ScreenSizeService {
  private _size$: Observable<ScreenSize>;

  constructor() {
    this._size$ = fromEvent(window, 'resize')
      .pipe(
        startWith(this._getScreenSize()),
        map((event: Event) => this._getScreenSize()),
        distinctUntilChanged(),
        shareReplay(1)
      )
  }

  public get size$(): Observable<ScreenSize> {
    return this._size$;
  }

  public get sizeXL$(): Observable<boolean> {
    return this.size$.pipe(map(currentScreenSize => ScreenSize.XL.eq(currentScreenSize)), distinctUntilChanged());
  }

  public get size(): ScreenSize {
    return this._getScreenSize();
  }

  private _getScreenSize(): ScreenSize {
    const [[newSize = null]] = Array.from(QUERY.entries())
      .filter(([size, mediaQuery]) => window.matchMedia(mediaQuery).matches);
    return newSize;
  }
}
