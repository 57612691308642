<div class="row mb-3">
    <div class="col-12">
        <label *ngIf="!readonly" class="btn btn-file btn-primary btn-sm" [ngClass]="{'disabled': fileUploader.isUploading}">
            <input type="file" ng2FileSelect [uploader]="fileUploader" [disabled]="fileUploader.isUploading" multiple/>
            <lc-spinner-text [spin]="fileUploader.isUploading" text="Tilf&oslash;j bilag"></lc-spinner-text>
        </label>
        <lc-workshop-report-video-request-upload-button [workshopReportId]="workshopReportId" [fileUploader]="fileUploader" *ngIf="renderWorkshopVideoRequestComponent" class="ms-3"></lc-workshop-report-video-request-upload-button>
        <label class="btn btn-primary btn-sm pull-right" [ngClass]="{'disabled': concatAttachments(false).length === 0}" (click)="downloadAllAttachments()">
            Download alle bilag
        </label>
    </div>
</div>

<div ng2FileDrop class="file-drop-zone row ms-0 mt-0 mb-0" [ngClass]="{'nv-file-over': draftDropZoneOver}" (fileOver)="fileOverDropZone($event)"
     [uploader]="fileUploader">
    <div class="col-12 ps-0">
        <h4>Rapport bilag</h4>
    </div>

    <div *ngIf="principal.isTaksator() && metadataSummary && (this.attachmentSummary.workshopImages.length > 0)" class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3 ps-0 pe-3" >
        <lc-metadata-summary
            [metadataSummary]="metadataSummary"
            [attachmentSummary]="attachmentSummary"
            ></lc-metadata-summary>
    </div>
    <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center mb-3 ps-0 pe-3"
         *ngFor="let attachment of concatAttachments() | filter: 'report' : true">
            <lc-attachment-card
                [attachment]="attachment"
                [deletable]="false"
                (openGallery)="openGallery($event)"
                [metadataSummary]="metadataSummary"
            ></lc-attachment-card>
    </div>
</div>
