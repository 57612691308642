<fieldset ngForm="f" #f="ngForm">
    <h2>Erstatning</h2>
    <div class="card">
        <div class="card-body">
            <div class="row">
                <!-- Left part -->
                <div class="col-12 col-md-6">
                    <div class="row pb-3">
                        <label class="col-form-label col-4" for="erstatningsBeloeb">Beløb</label>
                        <div class="input-group col-8 ps-0 pe-0">
                            <input [(ngModel)]="details.erstatningsBeloeb" class="form-control" type="text"
                                id="erstatningsBeloeb" name="erstatningsBeloeb" minlength=0 maxlength=9 size="9"
                                lcInputMask="numbersOnly" />
                                <div class="input-group-text">Kr.</div>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <label class="col-form-label col-4" for="moms">Inkl. Moms</label>
                        <div class="col-8">
                            <input type="checkbox" class="form-input-check-single" name="moms" id="moms"
                                [(ngModel)]="details.moms" autocomplete="off" />
                        </div>
                    </div>

                    <div class="row mb-2 mt-lg-0 mt-lg-4">
                        <div class="col-4 text-end">Bankoplysninger</div>
                    </div>

                    <div class="row pb-3">
                        <label class="col-form-label col-4 col-lg-2" for="regNr">Regnr</label>
                        <div class="col-8 col-lg-2 ">
                            <input [(ngModel)]="details.bankRegnr" class="form-control mb-2 mb-lg-0" type="text"
                                id="regNr" name="regNr" minlength=4 maxlength=4 size="4" lcInputMask="int4"
                                [required]="details.isBankRequired()" [readonly]="details.nemkonto" />
                        </div>
                        <label class="col-form-label col-4 col-lg-3" for="regNr">Kontonr</label>
                        <div class="col-8 col-lg-5">
                            <input [(ngModel)]="details.bankKontonr" class="form-control" type="text" id="kontonr"
                                name="kontonr" minlength=0 maxlength=10 size="10" [required]="details.isBankRequired()"
                                lcInputMask="numbersOnly" [readonly]="details.nemkonto" />
                        </div>
                    </div>

                    <div class="row pb-3">
                        <label class="col-form-label col-4" for="nemkonto">Nemkonto</label>
                        <div class="col-8">
                            <input class="form-input-check-single" type="checkbox" [(ngModel)]="details.nemkonto"
                                id="nemkonto" name="nemkonto" (change)="details.validate()" autocomplete="off">
                        </div>
                    </div>
                </div>
                <!-- right part -->
                <div class="col-12 col-md-6 mt-md-0 mt-4">
                    <div class="row pb-3">
                        <label class="col-form-label col-4">Navn</label>
                        <div class="col-8">
                            <input class="form-control" [(ngModel)]="details.erstatningsModtagerNavn" type="text"
                                id="erstatningsModtagerNavn" name="erstatningsModtagerNavn" maxlength="30" />
                        </div>
                    </div>
                    <div class="row pb-3">
                        <label class="col-form-label col-4">Adresse</label>
                        <div class="col-8">
                            <input class="col-8 form-control" [(ngModel)]="details.erstatningsModtagerAdresse1"
                                type="text" id="erstatningsModtagerAdresse1" name="erstatningsModtagerAdresse1" />
                        </div>
                    </div>
                    <div class="row pb-3">
                        <label class="col-form-label col-4"></label>
                        <div class="col-8">
                            <input class="form-control" [(ngModel)]="details.erstatningsModtagerAdresse2" type="text"
                                id="erstatningsModtagerAdresse2" name="erstatningsModtagerAdresse2" />
                        </div>
                    </div>
                    <div class="row pb-3">
                        <label class="col-form-label col-4">Postnr</label>
                        <div class="col-8">
                            <input class="form-control" [(ngModel)]="details.erstatningsModtagerPostalCode" type="tel"
                                id="erstatningsModtagerPostalCode" name="erstatningsModtagerPostalCode" minlength=1
                                size="4" maxlength="4" lcInputMask="int4" (change)="updateCityByPostalCode()" />
                        </div>
                    </div>

                    <div class="row pb-3">
                        <label class="col-form-label col-4">By</label>
                        <div class="col-8">
                            <input class="form-control" [(ngModel)]="by" [readonly]="true" type="text" id="by"
                                name="by" />
                        </div>
                    </div>

                    <div class="row pb-3">
                        <label class="col-form-label col-4">Telefon</label>
                        <div class="col-8">
                            <input class="form-control" [(ngModel)]="details.erstatningsModtagerTlf" type=tel
                                id="erstatningsModtagerTlf" name="erstatningsModtagerTlf" size="8" maxlength="8"
                                lcInputMask="int8" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fieldset>