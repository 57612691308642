<ng-container *ngIf="textLibrary">
    <ng-container *ngIf="!showCreate && !updatingEntry">
        <button *ngIf="editable" (click)="showCreate = true" class="btn btn-primary btn-sm mb-2" type="button">
            Opret
        </button>
        <ul class="list-group">
            <ng-container *ngFor="let entry of textLibrary.entries">
                <li class="list-group-item">
                    <strong class="d-inline">{{entry.text}}</strong>
                    <span class="float-end">
						<button (click)="selectEntry(entry)" class="btn btn-primary btn-sm" type="button">
							<span class="fa fa-check"></span> Indsæt
						</button>
						<button *ngIf="editable" (click)="setUpdatingEntry(entry)" class="btn btn-primary btn-sm" type="button">
							<span class="fa fa-edit"></span>
						</button>
						<button *ngIf="editable" (click)="deleteEntry(entry)" class="btn btn-primary btn-sm" type="button">
							<span class="fa fa-trash"></span>
						</button>
					</span>
                </li>
            </ng-container>
        </ul>
    </ng-container>
    <ng-container *ngIf="showCreate">
        <h3>Opret ny tekst i bibliotek</h3>
        <div class="pb-3">
            <textarea [(ngModel)]="newEntryText" class="form-control" rows="10"></textarea>
            <div *ngIf="!textValid(newEntryText)">
                <p class="error">Teksten må højst være 4000 bogstaver lang</p>
            </div>
        </div>
        <div class="pb-3">
            <button (click)="createNewEntry()" [disabled]="!newEntryText || !textValid(newEntryText)"
                    class="btn btn-primary btn-sm" type="button">
                Opret
            </button>
            <button (click)="clearNewEntry()" class="btn btn-primary btn-sm" type="button">
                Tilbage
            </button>
        </div>
    </ng-container>
    <ng-container *ngIf="updatingEntry">
        <h3>Rediger tekst i bibliotek</h3>
        <div class="pb-3">
            <textarea [(ngModel)]="updatingEntry.text" class="form-control" rows="10"></textarea>
            <div *ngIf="!textValid(updatingEntry.text)">
                <p class="error">Teksten må højst være 4000 bogstaver lang</p>
            </div>
        </div>
        <div class="pb-3">
            <button (click)="updateEntry()" [disabled]="!updatingEntry.text  || !textValid(updatingEntry.text)"
                    class="btn btn-primary btn-sm" type="button">
                Gem
            </button>
            <button (click)="updatingEntry = null" class="btn btn-primary btn-sm" type="button">
                Tilbage
            </button>
        </div>
    </ng-container>
</ng-container>
