import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MessageDTO} from 'app/shared/dto/message-dto.model';
import {BootstrapAlertType, BootstrapGrowlService} from 'app/shared/ui/ngx-bootstrap-growl';
import {MessageService} from 'app/shared/service/message.service';
import {PrincipalService} from 'app/shared/service/auth/principal.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TextLibraryPopupComponent} from '../../modals/text-library/text-library-popup.component';
import {CreateMessageDTO} from './dto/create-message-dto.model';
import {MessageReplyDTO} from './dto/message-reply-dto.model';
import {MessageUtils} from '../../utils/message-utils';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {LoadingSpinnerUtil} from '../../utils/loading-spinner-util';
import {UserTextLibraryService} from '../../service/user-text-library.service';
import {TextLibraryTypeEnum} from '../../service/text-library-type.enum';

@Component({
  selector: 'lc-message',
  templateUrl: './message.component.html',
  styleUrls: [
    'message.scss'
  ],
  providers: [LoadingSpinnerUtil]
})
export class MessageComponent implements OnInit, OnDestroy {
  @Input()
  set messages(value: MessageDTO[]) {
    this._messages = value;
    this.spinnerUtil.stopLoading();
  }
  get messages(): MessageDTO[] {
    return this._messages;
  }
  private unsubscribe$ = new Subject<void>();
  @Input() sendEnabled: boolean;
  showSend = false;
  currentMessage: MessageDTO;
  /**
   * textLibraryType is optional
   */
  @Input() textLibraryType: TextLibraryTypeEnum;
  @Output() reload = new EventEmitter();
  @Output() postMessage = new EventEmitter<CreateMessageDTO>();

  messageReplyTo: MessageDTO;
  replySubject: string;
  replyContent: string;
  messageSubject: string;
  messageContent: string;
  messageContentShow = {};
  maxContentLength = 2000;
  maxSubjectLength = 250;
  _messages: MessageDTO[];

  constructor(private principal: PrincipalService,
              private messageService: MessageService,
              private bootstrapGrowlService: BootstrapGrowlService,
              public spinnerUtil: LoadingSpinnerUtil,
              private modalService: NgbModal,
              private userTextLibraryService: UserTextLibraryService) {
  }

  ngOnInit(): void {
    if (this._messages && this._messages.length > 0) {
      this.selectMessage(this._messages[0]);
    }
  }

  newMessage(): void {
    this.messageContent = this.principal.getUdskriftLinier(true);
    this.messageSubject = null;
    this.showSend = true;
  }

  sendMessage(): void {
    if (this.sendEnabled) {
      const createMessage = new CreateMessageDTO();
      createMessage.content = this.messageContent;
      createMessage.subject = this.messageSubject;
      this.postMessage.emit(createMessage);
      this.showSend = false;
    }
  }

  sendReply(): void {
    const messageReply = new MessageReplyDTO();
    messageReply.content = this.replyContent;
    messageReply.subject = this.replySubject;

    this.messageService.sendReply(this.messageReplyTo.id, messageReply).pipe(takeUntil(this.unsubscribe$)).subscribe((messageDTO: MessageDTO) => {
      this.replyContent = null;
      this.replySubject = null;
      this.messageReplyTo = null;
      this._messages.push(messageDTO)
      this.bootstrapGrowlService.addAlert('Besked sendt', BootstrapAlertType.SUCCESS, 3000);
    });
  }

  markAsRead(message: MessageDTO): void {
    if (!message.read && this.isMyMessage(message)) {
      this.messageService.markAsRead(message.id).pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
        message.read = true;
      });
    }
  }

  selectMessage(message: MessageDTO): void {
    this.currentMessage = message;
    this.markAsRead(message);
  }

  isMyMessage(message: MessageDTO): boolean {
    if (message && message.from) {
      if (this.principal.isVK()) {
        return message.to.organisationCode === this.principal.getOrganizationCode();
      } else {
        return message.to.username === this.principal.getUsername();
      }
    }
    return false;
  }

  canReply(message: MessageDTO): boolean {
    return this.isMyMessage(message);
  }

  print(message: MessageDTO): void {
    window.open(this.messageService.getMessagePdfUrl(message.id), '_blank');
  }

  doReply(message: MessageDTO): void {
    if (this.messageReplyTo === message) {
      this.messageReplyTo = null;
    } else {
      this.messageReplyTo = message;
      this.replySubject = MessageUtils.getReplySubject(message.subject);
    }
  }

  showTextLibrary(isReply = false): void {
    if (this.textLibraryType) {
      const modalRef = this.modalService.open(TextLibraryPopupComponent, {size: 'lg', windowClass: 'lc-fullscreen'});
      const popUpComponent = modalRef.componentInstance as TextLibraryPopupComponent;
      popUpComponent.title = 'Tekstbibliotek';
      popUpComponent.textLibraryType = this.textLibraryType;
      popUpComponent.textLibraryService = this.userTextLibraryService;
      modalRef.result.then((text: string) => {
        if (text) {
          this.didSelectTextFromLibrary(text, isReply);
        }
      }).catch(() => {
        // Necessary to avoid "Uncaught error Error: Uncaught (in promise)" even though we do not use the returned value.
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  reloadMessages(): void {
    this.spinnerUtil.startLoading();
    this.reload.emit();
  }

  didSelectTextFromLibrary(text: string, replying = false): void {
    if (replying) {
      this.replyContent = this.replyContent ? text + this.replyContent : text;
    } else {
      this.messageContent = this.messageContent ? text + this.messageContent : text;
    }
  }
}
