import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {LcActionMenu, LcActionMenuItem} from '../../shared/ui/bottombar/lc-action-menu.model';
import {ClientNavigationService} from '../../shared/service/client-navigation.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  templateUrl: './new-report-type-from-report.component.html',
  styleUrls: ['new-report-type-from-report.scss']
})
export class NewReportTypeFromReportComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  reportId: number;
  bottomMenu: LcActionMenu;

  constructor(private route: ActivatedRoute,
              private navigation: ClientNavigationService) {
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe(params => {
      this.reportId = +params['reportId'];
    });
  }

  ngOnInit(): void {
    this.bottomMenu = new LcActionMenu();
    this.bottomMenu.addItem(new LcActionMenuItem('fa-arrow-left', () => {
      this.navigation.back(['/report']);
    }, 'Tilbage'));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
