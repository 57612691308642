<div class="col-12" style="font-size: 0.8rem;">
    <h1>Om Autotaks</h1>

    <h3>Version</h3>
    <ul>
        <li><b>Server version:</b> "{{serverVersion}}"</li>
        <li><b>Klient version: </b> "{{versionTimestamp}}"</li>
    </ul>

    <h3 class="pt-3">Cookiepolitik</h3>
    <div>
        <lc-cookie-text></lc-cookie-text>
    </div>
</div>
