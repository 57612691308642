import {Component, OnDestroy, OnInit} from '@angular/core';
import {LanguageConfigurationDTO, SelectedLanguageDTO} from '../../model/language-configuration-dto.model';
import {SettingsService} from '../../../shared/service/settings.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  templateUrl: './settings.miscellaneous.component.html',
  styleUrls: [
    '../../settings.scss'
  ],
  selector: 'lc-settings-miscellaneous'
})
export class SettingsMiscellaneousComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  isLoading: boolean;
  languageConfiguration: LanguageConfigurationDTO = new LanguageConfigurationDTO();
  selectedLanguage: SelectedLanguageDTO;

  constructor(private settingsService: SettingsService) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.settingsService.getLanguageConfiguration().pipe(takeUntil(this.unsubscribe$)).subscribe(selectedLanguageDTO => {
      this.selectedLanguage = selectedLanguageDTO;
      this.isLoading = false;
    });
  }

  onLanguageChange(): void {
    this.isLoading = true;
    this.settingsService.setLanguageConfiguration(this.selectedLanguage).pipe(takeUntil(this.unsubscribe$)).subscribe(selectedLanguageDTO => {
      this.isLoading = false;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
