import {ErDetailsDTO, GbDetailsDTO} from '../../shared/dto/er-details-dto.model';
import {Flow2CarDetails} from './flowtwo-car-details.model';
import {applyMixins} from 'rxjs/internal/util/applyMixins';
import {BankDetails} from './bank.details';
import {VATProgramme} from './op-details.model';

export class ErGbDetails extends Flow2CarDetails {
  selskab: string;
  bud1: string;
  bud2: string;
  bud3: string;
  budgiver1: string;
  budgiver2: string;
  budgiver3: string;
  budgiverAdresse1: string;
  budgiverAdresse2: string;
  budgiverPostNr: string;
  budgiverNavn: string;
  budgiverTelefon: string;
  budgiverSolgtTil: string;
}

export class GbDetails extends ErGbDetails {
  registreringsAttest: boolean;
  vatProgramme: VATProgramme;

  static fromDTO(dto: GbDetailsDTO): GbDetails {
    const details = new GbDetails();
    if (dto) {
      details.selskab = dto.selskab;
      details.bud1 = dto.bud1;
      details.bud2 = dto.bud2;
      details.bud3 = dto.bud3;
      details.budgiver1 = dto.budgiver1;
      details.budgiver2 = dto.budgiver2;
      details.budgiver3 = dto.budgiver3;
      details.budgiverAdresse1 = dto.budgiverAdresse1;
      details.budgiverAdresse2 = dto.budgiverAdresse2;
      details.budgiverPostNr = dto.budgiverPostNr;
      details.budgiverNavn = dto.budgiverNavn;
      details.budgiverTelefon = dto.budgiverTelefon;
      details.budgiverSolgtTil = dto.budgiverSolgtTil;
      details.fabrikatKode = dto.fabrikatKode;
      details.fabrikatText = dto.fabrikatText;
      details.modelKode = dto.modelKode;
      details.modelText = dto.modelText;
      details.editableFabrikat = dto.editableFabrikat;
      details.editableModel = dto.editableModel;
      details.resterTilhoerSelskab = dto.resterTilhoerSelskab;
      details.registreringsAttest = dto.registreringsAttest;
      details.vatProgramme = dto.vatProgramme;
    }
    return details;
  }

  toDTO(): GbDetailsDTO {
    const dto = new GbDetailsDTO();
    dto.selskab = this.selskab;
    dto.bud1 = this.bud1;
    dto.bud2 = this.bud2;
    dto.bud3 = this.bud3;
    dto.budgiver1 = this.budgiver1;
    dto.budgiver2 = this.budgiver2;
    dto.budgiver3 = this.budgiver3;

    dto.budgiverAdresse1 = this.budgiverAdresse1;
    dto.budgiverAdresse2 = this.budgiverAdresse2;
    dto.budgiverPostNr = this.budgiverPostNr;
    dto.budgiverNavn = this.budgiverNavn;
    dto.budgiverTelefon = this.budgiverTelefon;
    dto.budgiverSolgtTil = this.budgiverSolgtTil;
    dto.fabrikatKode = this.fabrikatKode;
    dto.fabrikatText = this.fabrikatText;
    dto.modelKode = this.modelKode;
    dto.modelText = this.modelText;
    dto.editableFabrikat = this.editableFabrikat;
    dto.editableModel = this.editableModel;
    dto.resterTilhoerSelskab = this.resterTilhoerSelskab;
    dto.registreringsAttest = this.registreringsAttest;
    dto.vatProgramme = this.vatProgramme;
    return dto;
  }
}

export class ErDetails extends ErGbDetails implements BankDetails {
  bankKontonr: string;
  bankRegnr: string;
  nemkonto: boolean;
  validate: () => void;
  isBankRequired: () => boolean;

  pantehaverRestGaeld: boolean;
  pantehaverRestGaeldBeloeb: number;
  pantehaverNavn: string;
  pantehaverAdresse: string;
  pantehaverTlf: string;
  pantehaverPostalCode: string;

  static fromDTO(dto: ErDetailsDTO): ErDetails {
    const details = new ErDetails();
    if (dto) {
      details.selskab = dto.selskab;
      details.bankRegnr = dto.bankRegnr;
      details.bankKontonr = dto.bankKontonr;
      details.nemkonto = dto.nemkonto;
      details.bud1 = dto.bud1;
      details.bud2 = dto.bud2;
      details.bud3 = dto.bud3;
      details.budgiver1 = dto.budgiver1;
      details.budgiver2 = dto.budgiver2;
      details.budgiver3 = dto.budgiver3;
      details.budgiverAdresse1 = dto.budgiverAdresse1;
      details.budgiverAdresse2 = dto.budgiverAdresse2;
      details.budgiverPostNr = dto.budgiverPostNr;
      details.budgiverNavn = dto.budgiverNavn;
      details.budgiverTelefon = dto.budgiverTelefon;
      details.budgiverSolgtTil = dto.budgiverSolgtTil;
      details.fabrikatKode = dto.fabrikatKode;
      details.fabrikatText = dto.fabrikatText;
      details.modelKode = dto.modelKode;
      details.modelText = dto.modelText;
      details.editableFabrikat = dto.editableFabrikat;
      details.editableModel = dto.editableModel;
      details.resterTilhoerSelskab = dto.resterTilhoerSelskab;
      details.pantehaverRestGaeld = dto.pantehaverRestGaeld;
      details.pantehaverRestGaeldBeloeb = dto.pantehaverRestGaeldBeloeb;
      details.pantehaverNavn = dto.pantehaverNavn;
      details.pantehaverAdresse = dto.pantehaverAdresse;
      details.pantehaverTlf = dto.pantehaverTlf;
      details.pantehaverPostalCode = dto.pantehaverPostalCode;
    }
    return details;
  }

  toDTO():
    ErDetailsDTO {
    const dto = new ErDetailsDTO();
    dto.selskab = this.selskab;
    dto.bankRegnr = this.bankRegnr;
    dto.bankKontonr = this.bankKontonr;
    dto.nemkonto = this.nemkonto;
    dto.bud1 = this.bud1;
    dto.bud2 = this.bud2;
    dto.bud3 = this.bud3;
    dto.budgiver1 = this.budgiver1;
    dto.budgiver2 = this.budgiver2;
    dto.budgiver3 = this.budgiver3;

    dto.budgiverAdresse1 = this.budgiverAdresse1;
    dto.budgiverAdresse2 = this.budgiverAdresse2;
    dto.budgiverPostNr = this.budgiverPostNr;
    dto.budgiverNavn = this.budgiverNavn;
    dto.budgiverTelefon = this.budgiverTelefon;
    dto.budgiverSolgtTil = this.budgiverSolgtTil;
    dto.fabrikatKode = this.fabrikatKode;
    dto.fabrikatText = this.fabrikatText;
    dto.modelKode = this.modelKode;
    dto.modelText = this.modelText;
    dto.editableFabrikat = this.editableFabrikat;
    dto.editableModel = this.editableModel;
    dto.resterTilhoerSelskab = this.resterTilhoerSelskab;
    dto.pantehaverRestGaeld = this.pantehaverRestGaeld;
    dto.pantehaverRestGaeldBeloeb = this.pantehaverRestGaeldBeloeb;
    dto.pantehaverNavn = this.pantehaverNavn;
    dto.pantehaverAdresse = this.pantehaverAdresse;
    dto.pantehaverTlf = this.pantehaverTlf;
    dto.pantehaverPostalCode = this.pantehaverPostalCode;
    return dto;
  }

}

applyMixins(ErDetails, [BankDetails]);
