import {BootstrapAlertType} from './bootstrap-alert-type.enum';

export class BootstrapAlert {
  title: string;
  type: BootstrapAlertType;
  message: string;
  link: string;
  dismissable: boolean;

  constructor(title: string, type: BootstrapAlertType, message: string, link: string, dismissable: boolean) {
    this.title = title;
    this.type = type;
    this.message = message;
    this.link = link;
    this.dismissable = dismissable;
  }
}
