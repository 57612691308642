import {Component, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {LcActionMenu} from '../../../shared/ui/bottombar/lc-action-menu.model';
import {ActivatedRoute, Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {CarDetailsProviderService} from '../../../shared/ui/car-details/car-details-provider.service';
import {DraftCarDetailsProviderService} from './draft-car-details-provider.service';
import {DmrService} from '../../../shared/service/dmr.service';

@Component({
  templateUrl: './draft-car-details.component.html'
})
export class DraftCarDetailsComponent implements OnDestroy {
  private unsubscribe$ = new Subject<void>();
  menu: LcActionMenu = new LcActionMenu();
  loading = true;
  carDetailsProvider: CarDetailsProviderService;

  constructor(protected dmrService: DmrService,
              private router: Router,
              private route: ActivatedRoute) {
    this.route.params.pipe(takeUntil(this.unsubscribe$))
      .subscribe(params => {
        this.carDetailsProvider = new DraftCarDetailsProviderService(dmrService, params['licensePlate']);
      });
  }

  navigateBack(): void {
    this.router.navigate(['../../'], {relativeTo: this.route})
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
