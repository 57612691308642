<fieldset ngForm="f" #f="ngForm">
    <h2>Skadet køretøj</h2>
    <div class="card">
        <div class="card-body">
            <div class="row">
                <!-- Left part -->
                <div class="col-md-6">

                    <div class="row pb-3">
                        <div class="col-4 col-form-label">
                            <label for="regnr1">Reg. nr</label>
                        </div>
                        <div class="col">
                            <!--<input [(ngModel)]="details.vehicle.regNr" class="col-8 form-control" type="text" id="regNr" name="regNr"-->
                            <input *ngIf="!details.ansvarsskade" [(ngModel)]="details.forsikringstager.regNr"
                                   class="form-control text-uppercase"
                                   type="text" id="regnr1" name="regnr"
                                   minlength=1 maxlength=10 size="10"
                                   lcInputMask="regNr"
                                   [disabled]="uiConfig.disableEditRegNr()"
                                   (ngModelChange)="onLicensePlateChanged.emit($event)"
                                   required/>
                            <input *ngIf="details.ansvarsskade" [(ngModel)]="details.skadelidte.regNr"
                                   class="form-control text-uppercase"
                                   type="text" id="regnr1" name="regnr"
                                   lcInputMask="regNr"
                                   [disabled]="uiConfig.disableEditRegNr()"
                                   (ngModelChange)="onLicensePlateChanged.emit($event)"
                                   minlength=1 maxlength=10 size="10"
                                   required/>
                        </div>
                    </div>

                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label class="m-0" for="fabrikat">Fabrikat</label>
                        </div>
                        <div class="col">
                            <lc-autocomplete id="fabrikat" name="fabrikat" required
                                             [items]="brands" [ngModel]="details.vehicle.fabrikat" debugInfo="fabrikat"
                                             itemText="navn" exactKey="kode"
                                             [disabled]="uiConfig.disableEditFabrikat()"
                                             (ngModelChange)="onSelectFabrikatChanged($event)"></lc-autocomplete>
                        </div>
                    </div>

                    <div *ngIf="showAngivetFabrikat()" class="row pb-3">
                        <div class="col-form-label col-4">
                            <label for="angivetFabrikat">Angivet fabrikat</label>
                        </div>
                        <div class="col">
                            <input [(ngModel)]="details.vehicle.angivetFabrikat" class="form-control text-uppercase" type="text"
                                   id="angivetFabrikat" name="angivetFabrikat"
                                   [disabled]="uiConfig.disableEditFabrikat()"
                                   minlength=1 size="20"
                                   required/></div>
                    </div>

                    <div *ngIf="details.vehicle.fabrikat; else emptyModel">
                        <div class="row pb-3">
                            <div class="col-form-label col-4">
                                <label class="m-0" for="model">Model</label>
                            </div>
                            <div class="col">
                                <div class="row">
                                    <div class="d-flex">
                                        <lc-autocomplete id="model" name="model" required class="w-100"
                                                         [items]="models" [(ngModel)]="details.vehicle.model"
                                                         exactKey="kode"
                                                         debugInfo="model"
                                                         itemText="navn"
                                                         [disabled]="uiConfig.disableEditModel()"
                                                         (ngModelChange)="onSelectModelChanged($event)"></lc-autocomplete>
                                        <div *ngIf="!!details.vehicle.model?.modelHelpText" class="text-warning clickable">
                                            <i class="fa fa-exclamation-triangle fa-2x pt-1" (click)="showModelHelpText()"
                                               [ngbTooltip]="details.vehicle.model.modelHelpText?.header" placement="right" tooltipClass="text-start"
                                            ></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-template #emptyModel>
                        <div class="row pb-3">
                            <div class="col-form-label col-4">
                                <label for="model">Model</label>
                            </div>
                            <select class="form-select">
                            </select>
                        </div>
                    </ng-template>

                    <div *ngIf="showAngivetModel()" class="row pb-3">
                        <div class="col-form-label col-4">
                            <label for="angivetModel">Angivet model</label>
                        </div>
                        <div class="col">
                            <input [(ngModel)]="details.vehicle.angivetModel" class="form-control text-uppercase" type="text"
                                   id="angivetModel"
                                   name="angivetModel"
                                   [disabled]="uiConfig.disableEditFabrikat()"
                                   minlength=1 size="20"
                                   required/>
                        </div>
                    </div>

                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label for="stelnr">Stelnummer</label>
                        </div>
                        <div class="col">
                            <div class="input-group ps-0 pe-0 ">
                                <input [(ngModel)]="details.vehicle.stelnr" class="form-control text-uppercase" type="text" id="stelnr"
                                       name="stelnr" minlength=1 maxlength=20 size="20" required
                                       [disabled]="uiConfig.disableEditStelNr()"/>
                                <button type="button" class="btn btn-outline-secondary"
                                        (click)="openVinLookupModal(vinLookupPopup)"
                                        [ngClass]="{'d-none': !isTaksator()}"
                                        [disabled]="uiConfig.disableVinLookup()"
                                        title="{{uiConfig.getVinLookupTitle()}}">
                                    <i class="fa fa-search fa-lg"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label for="aargang">Årgang</label>
                        </div>
                        <div class="col">
                            <input [(ngModel)]="details.vehicle.aargang"
                                   (change)="onVehicleYearChanged(details.vehicle.aargang)" class="form-control"
                                   type="tel" id="aargang"
                                   name="aargang" lcInputMask="year" lcValidate="maxNextYear" size="4" min="1900"
                                   max="9999"
                                   required/></div>
                    </div>

                    <div *lcHasAnyAuthority="'ROLE_VK'" class="row pb-3">
                        <div class="col-form-label col-4">
                            <label for="foersteRegDato">Første reg. dato</label>
                        </div>
                        <div class="col">
                            <div class="input-group" style="padding: 0px;">
                                <input class="form-control" type="tel"
                                       id="foersteRegDato" name="foersteRegDato"
                                       [(ngModel)]="details.vehicle.foersteRegDato"
                                       ngbDatepicker #foersteRegDatoDP="ngbDatepicker" lcInputMask="date"
                                       [minDate]="{ 'year': 1900, 'month': 1, 'day': 1 }"
                                       [maxDate]="pastOrPresentMaxDateSelection()"
                                       [disabled]="details.disableFirstRegistrationDate()"
                                       required>
                                <button class="btn btn-outline-secondary"
                                        (click)="foersteRegDatoDP.toggle()"
                                        type="button">
                                    <i class="fa fa-calendar fa-lg"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label for="kmstand">Km. stand</label>
                        </div>
                        <div class="col">
                        <input [(ngModel)]="details.vehicle.kmstand" class="form-control" type="tel" id="kmstand"
                               name="kmstand" lcInputMask="int6" maxlength="6" size="6" required/></div>
                    </div>
                    <div *lcHasAnyAuthority="'ROLE_VK'">
                        <div *ngIf="details.isAutotaksType()" class="row pb-3">
                            <div class="col-form-label col-4">
                                <label for="handelsvaerdi">Handelsværdi</label>
                            </div>
                            <div class="col">
                            <input [(ngModel)]="details.vehicle.handelsvaerdi" class="form-control" type="tel"
                                   id="handelsvaerdi"
                                   name="handelsvaerdi" lcInputMask="int8" size="8"/></div>
                        </div>
                    </div>
                    <div *ngIf="details.isAutotaksType()" class="row pb-3">
                        <div class="col-form-label col-4">
                            <label for="ansvarsskade">Ansvarsskade</label>
                        </div>
                        <div class="col">
                                <input (change)="ansvarsSkadeChanged()" type="checkbox" class="form-input-check-single"
                                       name="ansvarsskade" [disabled]="uiConfig.disableEditAnsvarsSkade()"
                                       id="ansvarsskade" [(ngModel)]="details.ansvarsskade" autocomplete="off"/>
                        </div>
                    </div>
                </div>
                <!-- Center -->

                <!-- Right part -->
                <div class="col-md-6">
                    <lc-licenseplate [details]="details"></lc-licenseplate>
                    <div class="pb-3"></div>
                    <lc-vehicle-condition *ngIf="details.isAutotaksType()" [details]="details"></lc-vehicle-condition>
                </div>
            </div>
        </div>
    </div>
</fieldset>

<ng-template #vinLookupPopup let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h3 class="text-truncate">Resultat af VIN opslag</h3>
        <div aria-label="Close" data-dismiss="modal" class="btn-close" (click)="closeModal()">
        </div>
    </div>
    <div class="modal-body">
        <div class="text-center" *ngIf="vinLookupInProgress">
            <i class="fa fa-circle-o-notch fa-spin fop-page-spinner mb-5"></i>
        </div>
        <div *ngIf="!vinLookupInProgress">

            <table class="table">
                <thead>
                <tr>
                    <th class="border-top-0" scope="col"></th>
                    <th class="border-start border-top-0" scope="col">Nuværende</th>
                    <th class="border-start border-top-0" scope="col">Erstattes med</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th scope="row">TYPENUMMER</th>
                    <td class="border-start">
                        <pre
                            style="font-size: 0.9rem; white-space: normal">{{details.vehicle.fabrikat.kode}} {{details.vehicle.model.kode}} {{details.vehicle.submodel}}</pre>
                    </td>
                    <td class="border-start">
                        <pre
                            style="font-size: 0.9rem; white-space: normal">{{vinLookupResult?.audatexLookupDTO?.vinFabrikat}} {{vinLookupResult?.audatexLookupDTO?.vinModel}} {{vinLookupResult?.audatexLookupDTO?.vinSubModel}}</pre>
                    </td>
                </tr>
                <tr>
                    <th scope="row"> - MODEL</th>
                    <td class="border-start">
                        <pre
                            style="font-size: 0.9rem; white-space: normal">{{getModelOptionsChunked(details.vehicle.modelOptions)}}</pre>
                    </td>
                    <td class="border-start">
                        <pre
                            style="font-size: 0.9rem; white-space: normal">{{getModelOptionsChunked(vinLookupResult?.audatexLookupDTO?.vinResult?.substr(6))}}</pre>
                    </td>
                </tr>
                <tr>
                    <th></th>
                    <td class="border-start">
                        <button class="btn btn-default w-100" (click)="closeModal()"
                                [disabled]="replaceVinDetailsInProgress">
                            <lc-spinner-text [spin]="replaceVinDetailsInProgress"
                                             text="Behold nuværende"></lc-spinner-text>
                        </button>
                    </td>
                    <td class="border-start">
                        <button class="btn btn-default bg-danger w-100 text-white"
                                (click)="replaceVinDetails(vinLookupResult)"
                                [disabled]="replaceVinDetailsInProgress">
                            <lc-spinner-text [spin]="replaceVinDetailsInProgress"
                                             text="Erstat nuværende"></lc-spinner-text>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>

        </div>
    </div>
</ng-template>
