import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {NgbForsiDateMomentParserFormatter, PrincipalDTO, PrincipalService} from 'app/shared';
import {StringUtils} from 'app/shared/utils/string-utils';
import {BootstrapAlertType, BootstrapGrowlService} from 'app/shared/ui/ngx-bootstrap-growl';
import {PoliceService} from '../shared/service/police.service';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date';
import {CompanyDTO} from 'app/shared/dto/company-dto.model';
import {LcActionMenu, LcActionMenuItem} from '../shared/ui/bottombar/lc-action-menu.model';
import {ClientNavigationService} from '../shared/service/client-navigation.service';
import {DateUtils} from '../shared/utils/date-utils';
import {fromEvent, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {HttpParams} from '@angular/common/http';
import {PolicyDTO} from '../shared/dto/policy-dto.model';
import {isNullOrUndefined} from '../shared/utils/object-utils';

export class PoliceRequest {
  selskabsKode: string;
  regnr: string;
  kundenr: string;
  policenr: string;
  stelnr: string;
  skadenr: string;
  skadedato: string;

  public toHttpParams(): HttpParams {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('selskabsKode', this.selskabsKode.toUpperCase())

    if (!isNullOrUndefined(this.kundenr)) {
      httpParams = httpParams.append('kundenr', this.kundenr.toUpperCase());
    }

    if (!isNullOrUndefined(this.regnr)) {
      httpParams = httpParams.append('regnr', this.regnr.toUpperCase());
    }

    if (!isNullOrUndefined(this.policenr)) {
      httpParams = httpParams.append('policenr', this.policenr.toUpperCase());
    }

    if (!isNullOrUndefined(this.stelnr)) {
      httpParams = httpParams.append('stelnr', this.stelnr.toUpperCase());
    }

    if (!isNullOrUndefined(this.skadenr)) {
      httpParams = httpParams.append('skadenr', this.skadenr.toUpperCase());
    }

    if (!isNullOrUndefined(this.skadedato)) {
      httpParams = httpParams.append('skadedato', this.skadedato);
    }

    return httpParams;
  }
}

@Component({
  templateUrl: './police.component.html',
  styleUrls: [
    'police.scss'
  ],
  providers: [PoliceService]
})
export class PoliceComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  bottomMenu: LcActionMenu;
  submitting: boolean;
  skadedatoDP: NgbDateStruct;
  skadedatoVal: NgbDate;
  principalModel: PrincipalDTO;
  policeRequest: PoliceRequest;
  policeResponse: PolicyDTO;
  selskaber: CompanyDTO[];
  selskab: CompanyDTO;
  vkNr: string;

  constructor(private principal: PrincipalService,
              private policeService: PoliceService,
              private navigation: ClientNavigationService,
              private bootstrapGrowlService: BootstrapGrowlService) {
  }

  ngOnInit(): void {
    //search on enter key up
    fromEvent(document, 'keyup').pipe(
      filter(event => {
        const keyEvent = event as KeyboardEvent;
        return keyEvent.key === 'Enter';
      }),
      filter(event => this.searchDisabled()),
      takeUntil(this.unsubscribe$)
    ).subscribe(keyEvent => {
      this.search();
    });

    this.setupBottomActionMenu();
    this.policeRequest = new PoliceRequest();
    this.policeResponse = null;
    this.selskaber = [];
    this.principal.identity().pipe(takeUntil(this.unsubscribe$)).subscribe((principalModel) => {
      this.principalModel = principalModel;
      this.policeRequest.selskabsKode = this.principalModel.orgNr.toString();
      this.policeService.getSelskaber().pipe(takeUntil(this.unsubscribe$)).subscribe(selskaber => {
        this.selskaber = selskaber;
        this.selskab = selskaber.find(s => s.code === this.policeRequest.selskabsKode);
      });
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  private setupBottomActionMenu(): void {
    this.bottomMenu = new LcActionMenu();

    this.bottomMenu.addItem(new LcActionMenuItem('fa-arrow-left', () => {
      this.navigateBack();
    }, 'Tilbage'));

    const searchBtn = this.bottomMenu.addItem(new LcActionMenuItem('fa-search', () => {
      this.search();
    }, 'Søg'));
    searchBtn.disabledCallback = () => this.searchDisabled();

    const resetSearchBtn = this.bottomMenu.addItem(new LcActionMenuItem('fa-search', () => {
      this.reset();
    }, 'Ny søgning'));

    searchBtn.hideCallback = () => this.policeResponse;
    resetSearchBtn.hideCallback = () => !this.policeResponse;
  }

  navigateBack(): void {
    this.navigation.back();
  }

  isAuthenticated(): boolean {
    return this.principal.isAuthenticated();
  }

  public setFeedback(feedback: string, error: boolean): void {
    if (error) {
      this.bootstrapGrowlService.addAlert(feedback, BootstrapAlertType.WARNING);
    } else {
      this.bootstrapGrowlService.addAlert(feedback, BootstrapAlertType.SUCCESS);
    }
  }

  public reset(): void {
    this.policeResponse = null;
  }

  public search(): void {
    if (this.skadedatoVal) {
      this.policeRequest.skadedato = new NgbForsiDateMomentParserFormatter().format(this.skadedatoVal);
    } else {
      this.policeRequest.skadedato = null;
    }
    this.submitting = true;
    this.policeRequest.selskabsKode = this.selskab.code;
    this.policeService.standaloneSearch(this.policeRequest).pipe(takeUntil(this.unsubscribe$)).subscribe((response) => {
      this.submitting = false;
      this.policeResponse = response;
    }, (errObj) => {
      this.setFeedback(errObj && errObj.error ? errObj.error.message : errObj, true);
      this.submitting = false;
    });
  }

  public allowPrint(): boolean {
    return !this.principal.isVK();
  }

  public print(): void {
    this.policeService.print(this.policeRequest, this.vkNr);
  }

  pastOrPresentMaxDateSelection(): any {
    return DateUtils.getNgbDateStructToday();
  }

  public isInputRequired(): boolean {
    return StringUtils.isEmpty(this.policeRequest.regnr) &&
      StringUtils.isEmpty(this.policeRequest.policenr) &&
      StringUtils.isEmpty(this.policeRequest.stelnr) &&
      StringUtils.isEmpty(this.policeRequest.skadenr);
  }

  public searchDisabled(): boolean {
    return this.isInputRequired();
  }
}
