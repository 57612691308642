import {RapportType} from '../../../shared/model/rapport-type.model';
import {ClientStateDetails} from '../..';
import {ReportCategory} from '../../../shared/model/report-category.model';

/**
 * Used to extract relevant information from a clientstate object, used by UI components during draft editing.
 * Data is extracted once to make it efficient for many requests.
 */
export class UiDraftComponentClientStateInformation {

  private _reportType: ReportCategory;
  private _rapportType: RapportType;

  public constructor(clientState: ClientStateDetails) {
    this._reportType = ReportCategory.getReportCategory(clientState.schema);
    this._rapportType = RapportType.extractFrom(clientState.rapportKey());
  }

  get reportType(): ReportCategory {
    return this._reportType;
  }

  get rapportType(): RapportType {
    return this._rapportType;
  }

  public isOfAny(rapportTyper: RapportType[]): boolean {
    return this.rapportType.isOfAny(rapportTyper);
  }
}
