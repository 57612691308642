import {Pipe, PipeTransform} from '@angular/core';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {isNotNullOrUndefined} from '../utils/object-utils';

@Pipe({name: 'ngbDate'})
export class NgbDatePipe implements PipeTransform {
  transform(value: NgbDate): Date {
    if (isNotNullOrUndefined(value)) {
      return new Date(value.year, value.month - 1, value.day);
    } else {
      return null;
    }
  }
}
