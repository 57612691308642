import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {BreadCrumb} from './breadcrumb';
import {distinctUntilChanged, filter, takeUntil} from 'rxjs/operators';
import {merge, Subject} from 'rxjs';
import {PrincipalService} from '../..';
import {BreadcrumbService} from './breadcrumb.service';
import {ObjectUtils} from '../../utils/object-utils';
import {NotificationService} from '../../service/notification.service';

/**
 * Note: you can use parameters from the route path to build breadcrumbs like:
 * breadcrumb: '${code}'
 * will substitute the placeholder with the :code part from the route path
 */
@Component({
  selector: 'lc-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  breadcrumbs: BreadCrumb[] = [];

  constructor(public principalService: PrincipalService,
              private activatedRoute: ActivatedRoute,
              private breadcrumbService: BreadcrumbService,
              public notificationService: NotificationService,
              private router: Router) {
  }

  ngOnInit(): void {
    const routeNavigationEnd$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      distinctUntilChanged()
    );
    merge(routeNavigationEnd$, this.breadcrumbService.context$()).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  buildBreadCrumb(route: ActivatedRoute, url: string = '',
                  breadcrumbs: Array<BreadCrumb> = []): Array<BreadCrumb> {
    // If no routeConfig is avalailable we are on the root path

    const routeConfigData = route.routeConfig && route.routeConfig.data;
    let label = routeConfigData ? routeConfigData['breadcrumb'] : 'Start';
    let path = route.routeConfig ? '/' + route.routeConfig.path : '';
    //apply possibly path params from routeConfig into placeholders like '/:code'
    const params = route.snapshot ? (route.snapshot.params ? route.snapshot.params : []) : [];
    Object.keys(params).forEach(paramKey => {
      path = path.replace(':' + paramKey, params[paramKey]);
      if (label) {
        if (label.startsWith('$ctx.')) {
          const context = label.replace('$ctx.', '').split(':');
          const ctxType = context[0];
          if (BreadcrumbService.isAContextType(ctxType)) {
            const breadcrumbValue = this.breadcrumbService.get(ctxType, params[context[1]]);
            label = ObjectUtils.exists(breadcrumbValue) ? breadcrumbValue : routeConfigData['pageTitle'];
          } else {
            label = params[context[1]];
          }
        } else if (label.startsWith('${')) {
          label = label.replace('${' + paramKey + '}', params[paramKey]);
        }
      }
    });
    // In the routeConfig the complete path is not available,
    // so we rebuild it each time
    const nextUrl = `${url}${path}`;
    const breadcrumb = {
      label: label,
      url: nextUrl
    };
    const newBreadcrumbs = [...breadcrumbs];
    if ((routeConfigData && routeConfigData['breadcrumb'])) {
      newBreadcrumbs.push(breadcrumb);
    }

    if (route.firstChild) {
      // If we are not on our current path yet,
      // there will be more children to look after, to build our breadcumb
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }
    return newBreadcrumbs;
  }
}
