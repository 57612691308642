import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {AttachmentService} from './attachment.service';
import {AttachmentCardComponent} from './attachment-card/attachment-card.component';
import {SharedCommonModule} from '../../shared-common.module';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {FileUploadModule} from 'ng2-file-upload';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {MetadataSummaryComponent} from './metadata-summary/metadata-summary.component';
import {AttachmentGalleryComponent} from './attachment-gallery/attachment-gallery.component';
import {DraftAttachmentComponent} from './draft-attachment/draft-attachment.component';
import {ReportAttachmentComponent} from './report-attachment/report-attachment.component';
import {B2bReportAttachmentComponent} from './b2b-attachment/report/b2b-report-attachment.component';
import {B2bAdviseAttachmentComponent} from './b2b-attachment/advise/b2b-advise-attachment.component';
import {VideoRequestModule} from './video-request/video-request.module';
import {AttachmentBadgeComponent} from './attachment-badge/attachment-badge.component';

@NgModule({
  imports: [
    SharedCommonModule,
    LazyLoadImageModule,
    FileUploadModule,
    VideoRequestModule,
    PdfViewerModule
  ],
  declarations: [
    DraftAttachmentComponent,
    ReportAttachmentComponent,
    B2bReportAttachmentComponent,
    B2bAdviseAttachmentComponent,
    AttachmentCardComponent,
    MetadataSummaryComponent,
    AttachmentGalleryComponent,
    AttachmentBadgeComponent
  ],
  exports: [
    ReportAttachmentComponent,
    DraftAttachmentComponent,
    B2bReportAttachmentComponent,
    B2bAdviseAttachmentComponent,
    AttachmentCardComponent,
    AttachmentBadgeComponent
  ],
  providers: [
    AttachmentService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class AttachmentModule {
}
