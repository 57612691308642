import {Component} from '@angular/core';
import {QAPTER_VIDEOS_URL} from '../../constants/url.constants';

@Component({
  selector: 'lc-qapter-help-button',
  templateUrl: './qapter-help-button.component.html'
})
export class QapterHelpButtonComponent {
  constructor() {
  }

  buttonClicked(): void {
    window.open(QAPTER_VIDEOS_URL);
  }
}
