import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import URLBuilder from '../../utils/url-builder';
import {OAuthRefreshTokenService} from './o-auth-refresh-token.service';

/**
 * Intercepting request to our secure API and delegating requests to the refreshTokenService
 */
@Injectable()
export class OAuthInterceptor implements HttpInterceptor {

  private urlBuilder = new URLBuilder();

  constructor(private refreshTokenService: OAuthRefreshTokenService) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isSecureApiUrl(request.url) && !request.url.startsWith(this.urlBuilder.getProductRestServicePath())) {
      return this.refreshTokenService.handleRequest(request, next);
    }
    return next.handle(request);
  }

  private isSecureApiUrl(url: string): boolean {
    return url.startsWith(this.urlBuilder.getRestServicePath());
  }
}
