<fieldset ngForm="f" #f="ngForm" ngNativeValidate>
    <div *ngIf="isNotVKFlow()">
        <div class="row">
            <div class="col-12"><h2>Værkstedsoplysninger</h2></div>
        </div>
        <div class="card">
            <div class="card-body">
                <div class="row pb-4" *ngIf="uiConfig && uiConfig.isEditVkNumberVisible">
                    <div class="col-form-label col-2">
                        <label for="vknr">Værksteds Nummer</label>
                    </div>
                    <div class="col-2">
                        <div class="input-group">
                            <input [(ngModel)]="vknr" class="form-control" type="tel" id="vknr" name="vknr" minlength=1 maxlength="5"
                                [required]="isVkNrRequired()" [disabled]="uiConfig.disableEditVkNr() || isSearchingByVkNr"
                                (blur)="updateVkNr()" />
                            <button type="button" class="btn btn-outline-secondary" (click)="updateVkNr()"
                                [disabled]="uiConfig.disableEditVkNr() || isSearchingByVkNr" title="Søg på vknr">
                                <i class="fa fa-search fa-lg"></i>
                            </button>
                        </div>
                    </div>
                    <div class="col-form-label clickable" *ngIf="agreementDocuments && agreementDocuments.length > 0">
                        <div (click)="openAgreementDocumentsPopup()">
                            Vis aftaledokumenter <span class="fa fa-lg action-icon fa-file-pdf-o"></span>
                        </div>
                    </div>
                </div>
                <div *ngIf="!vkIsOperating()" class="alert alert-warning">
                    Bemærk: {{getVKNotOperatingMessage()}}
                </div>
                <p *ngIf="vkIsOperating() && clientStateWarning && vkstam"
                   [ngClass]="{'alert-danger' : clientStateWarning.requiresNonSystemReportMessage}">
                    {{clientStateWarning.systemReportMessageActionDescription}}
                </p>
                <lc-vk-info *ngIf="vkstam" [vkstam]="vkstam" [responsibleWorkshopUser]="responsibleWorkshopUser"></lc-vk-info>
                <lc-adhoc-vk-details *ngIf="!vknr" [formId]="'adHocVkDetails'"
                                     [details]="details"></lc-adhoc-vk-details>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12"><h2>Prisaftaler</h2></div>
    </div>

    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-form-label col-2">
                    <label class="m-0" for="selskab">Selskab</label>
                </div>
                <div class="col-md-6 col-lg-4">
                    <div class="input-group">
                        <lc-autocomplete *ngIf="!isLoading" (ngModelChange)="onSelskabChange($event)"
                                         [ngModel]="selskab"
                                         id="selskab" name="selskab" required
                                         [items]="selskaber" itemKey="code" itemText="text"
                                         [disabled]="uiConfig.disableEditSelskab()"
                                         exactKey="code" debugInfo="selskab" class="flex-fill"
                                         ngbTooltip="{{getHasAssessorOrganisationSpecificPositionsTooltip()}}"
                                         tooltipClass="tooltip-large-text">
                        </lc-autocomplete>
                        <div
                             [ngbTooltip]="getHasAssessorOrganisationSpecificPositionsTooltip()"
                             tooltipClass="tooltip-large-text"
                             *ngIf="nonStandardPositionStatus?.hasAssessorOrganisationSpecificPositions">
                            <div class="input-group-text">
                                <i class="fa fa-xl fa-exclamation" style="color: red"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="col-form-label clickable label-left" *ngIf="agreementDocuments && agreementDocuments.length > 0">
                        <div (click)="openAgreementDocumentsPopup()">
                            Vis aftaledokumenter <span class="fa fa-lg action-icon fa-file-pdf-o"></span>
                        </div>
                    </div>
                    <div class="col-form-label clickable label-left" *ngIf="isVKFlow() && this.selskab">
                        <div (click)="openAgreementOverviewPopup()">
                            <span title="Vis aftaler" class="fa fa-lg action-icon fa-handshake-o"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="agreementtable mt-3" *ngIf="aftalerValid">
                <div class="row align-items-center">
                    <div [ngClass]="agreementTableColumnClasses['column1']">Aftale</div>
                    <div [ngClass]="agreementTableColumnClasses['column2']"><span
                        class="d-none d-sm-block">Aftaleværdi</span></div>
                    <div [ngClass]="agreementTableColumnClasses['column3']"><span class="d-none d-sm-block">Værdi</span>
                    </div>
                    <div [ngClass]="agreementTableColumnClasses['column4']">
                        Bemærkning
                    </div>
                    <div [ngClass]="agreementTableColumnClasses['column5']"></div>
                </div>
                <div class="row align-items-center" [ngClass]="{'mb-5': !details.isAutotaksType()}">
                    <div [ngClass]="agreementTableColumnClasses['column1']">Timepris (1000) [kr.]</div>
                    <div [ngClass]="agreementTableColumnClasses['column2']">
                        <span *ngIf="timeAftalepris">{{timeAftalepris | number}}</span>
                        <span *ngIf="!timeAftalepris" class="d-none d-sm-block">Ingen aftale</span></div>
                    <div [ngClass]="agreementTableColumnClasses['column3']">
                        <input [ngModel]="details.timepris | number"
                               (ngModelChange)="updateTimepris($event)"
                               class="form-control" type="text"
                               name="timepris"
                               size="8" lcInputMask="int6_2RightAlign"/>
                    </div>
                    <div [ngClass]="agreementTableColumnClasses['column4']">
                        {{timeAftale?.bemaerkning}}
                    </div>
                    <div [ngClass]="agreementTableColumnClasses['column5']">
                        <a (click)="resetTimepris()" class="pull-right">
                            <i class="fa fa-undo fa-2x text-warning me-0"></i>
                        </a>
                    </div>
                </div>
                <div class="row mb-5 align-items-center" *ngIf="details.isAutotaksType()">
                    <div [ngClass]="agreementTableColumnClasses['column1']">Laktimepris (1001) [kr.]</div>
                    <div [ngClass]="agreementTableColumnClasses['column2']">
                        <span *ngIf="lakAftalepris">{{lakAftalepris | number}}</span>
                        <span *ngIf="!lakAftalepris" class="d-none d-sm-block">Ingen aftale</span>
                    </div>
                    <div [ngClass]="agreementTableColumnClasses['column3']">
                        <input [ngModel]="details.lakTimepris | number"
                               (ngModelChange)="updateLakTimepris($event)"
                               class="form-control" type="text"
                               name="lakTimepris" lcInputMask="int6_2RightAlign"
                               size="8"/>
                    </div>
                    <div [ngClass]="agreementTableColumnClasses['column4']">
                        {{lakAftale?.bemaerkning}}
                    </div>
                    <div [ngClass]="agreementTableColumnClasses['column5']">
                        <a (click)="resetLakTimepris()" class="pull-right">
                            <i class="fa fa-undo fa-2x text-warning me-0"></i>
                        </a>
                    </div>
                </div>

                <ng-container *ngFor="let prisaftale of prisaftaler">
                    <div class="row align-items-center" [ngClass]="{'deleted-row':prisaftale.specialkode.deleted}">
                        <div class="{{agreementTableColumnClasses['column1']}}"
                             [ngClass]="{'hide':prisaftale.specialkode.deleted}">{{prisaftale.valueType.description}}</div>
                        <div [ngClass]="agreementTableColumnClasses['column2']">
                            {{prisaftale.aftale?.value | number}}
                        </div>
                        <div [ngClass]="agreementTableColumnClasses['column3']">
                            <input class="form-control" type="text"
                                   [ngModel]="prisaftale.specialkode.value | number"
                                   (ngModelChange)="updateSpecialkode(prisaftale, $event)"
                                   lcInputMask="{{prisaftale.valueType.getInputMask()}}"
                                   name="{{prisaftale.specialkode.kode}}"
                                   size="8"
                                   [lcAutofocus]="prisaftale.specialkode.kode == prisaftaleAdded"
                                   [required]="!prisaftale.specialkode.deleted"
                                   [disabled]="prisaftale.specialkode.deleted"/>
                        </div>
                        <div [ngClass]="agreementTableColumnClasses['column4']">
                            {{prisaftale.specialkode.description}}
                        </div>
                        <div [ngClass]="agreementTableColumnClasses['column5']">
                            <a *ngIf="!prisaftale.specialkode.deleted"
                               (click)="deletePrisaftale(prisaftale)" class="pull-right">
                                <i class="fa fa-remove fa-2x text-danger me-0"></i>
                            </a>
                            <a *ngIf="prisaftale.specialkode.deleted"
                               (click)="undeletePrisaftale(prisaftale)" class="pull-right">
                                <i class="fa fa-plus fa-2x text-success me-0"></i>
                            </a>
                            <a (click)="resetPrisaftale(prisaftale)" class="pull-right pe-1">
                                <i class="fa fa-undo fa-2x text-warning ms-0"></i>
                            </a>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="row" style="margin-top:10px;" *ngIf="aftalerValid">
            <div class="col-md-6">
                <div class="row pb-3">
                    <div class="col-form-label col-4">
                        <label class="m-0" for="newagreement">Tilføj ny aftale</label>
                    </div>
                    <div class="col">
                        <lc-autocomplete (ngModelChange)="onNewvalueType($event)" [ngModel]="newValue"
                                        id="newagreement" debugInfo="agreements"
                                        name="newagreement" disabled="{{atMax()}}"
                                        [items]="availableValueTypes" itemText="description" exactKey="kode">
                        </lc-autocomplete>
                    </div>
                </div>
            </div>
            <div class="col-md-6" *ngIf="atMax()">
                Du kan højst vælge {{getSpecialCodeNoLimit()}} specialkoder.
            </div>
        </div>
        <div class="row" style="margin-top:10px;" *ngIf="!aftalerValid">
            <div class="col-md-12">
                <ng-container *ngIf="isVKFlow()">
                    For at kunne vælge aftaler, skal fabrikat, model og selskab være udfyldt.
                </ng-container>
                <ng-container *ngIf="isNotVKFlow()">
                    For at kunne vælge aftaler, skal værksted, fabrikat, model og selskab være udfyldt.
                </ng-container>
            </div>
        </div>
    </div>
</fieldset>
