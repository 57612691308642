<lc-bottombar [menu]="viewmodel.bottomMenu"
              [title]="'I alt: ' + (viewmodel.autoflexSavings.totalSavings() | currency:'DKK':'da')"></lc-bottombar>
<div class="text-center" *ngIf="viewmodel.loading">
    <i class="fa fa-circle-o-notch fa-spin fop-page-spinner"></i>
</div>
<div *ngIf="!viewmodel.loading && !viewmodel.hasAutoflex">
    <div class="row">
        <div class="col-md-12">
            <h2>Ingen Autoflex</h2>
            <b>Data version: {{viewmodel.autoflexVersion.version}}, Oprettet: {{viewmodel.autoflexVersion.createdAt |
                date:'medium'}}</b>
            <div>Der er ikke autoflex dele til denne skade</div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">&nbsp;</div>
    </div>
</div>

<div *ngIf="!viewmodel.loading && viewmodel.hasAutoflex">
    <div class="row mb-2">
        <div class="col-md-12">
            <h2>Autoflex</h2>
            <b>Data version: {{viewmodel.autoflexVersion.version}}, Oprettet: {{viewmodel.autoflexVersion.createdAt |
                date:'medium'}}</b>
        </div>
    </div>

    <div class="row d-block no-focus m-0 mb-2">
        <div id="filter" class="card" [ngClass]="{'enabled': viewmodel.autoflexRowFilterAndSorter.isFilterEnabled()}">
            <div class="card-header" (click)="didClickExpandFilter()">
                <span>{{viewmodel.autoflexRowFilterAndSorter.filterText()}}</span>
                <a class="float-end"><i id="filterButton" class="fa fa-2x fa-filter m-0 p-0" width="20px"></i></a>
            </div>
            <div class="card-block" *ngIf="viewmodel.expandFilter">
                <div class="row m-0">
                    <div class="col-12 mb-1 mt-1">Leverandør</div>
                    <div *ngFor="let supplierFilterRow of viewmodel.autoflexRowFilterAndSorter.supplierFilter"
                         style="white-space: nowrap;" class="col-sm-3 col-md-2 clickable"
                         (click)="didClickFilter(supplierFilterRow)">
                        <i class="m-2 fa fa-square-o fa-2x select-icon"
                           [ngClass]="{'fa-check-square-o': supplierFilterRow.selected, 'fa-square-o': !supplierFilterRow.selected}"></i>
                        <span>{{supplierFilterRow.value}}</span>
                    </div>
                </div>
                <hr class="m-0 p-0">
                <div class="row m-0">
                    <div class="col-12 mb-1 mt-1">Kvalitet</div>
                    <div *ngFor="let qualityFilterRow of viewmodel.autoflexRowFilterAndSorter.qualityFilter"
                         style="white-space: nowrap;" class="col-sm-3 col-md-2 clickable"
                         (click)="didClickFilter(qualityFilterRow)">
                        <i class="m-2 fa fa-square-o fa-2x select-icon"
                           [ngClass]="{'fa-check-square-o': qualityFilterRow.selected, 'fa-square-o': !qualityFilterRow.selected}"></i>
                        <span>{{qualityFilterRow.value}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row d-none d-md-block no-focus">
        <div class="col-md-12">
            <div class="table-responsive overflow-hide">
                <table class="table table-hover">
                    <thead>
                    <tr>
                        <th class="text-start d-none d-sm-table-cell">
                        </th>
                        <th class="text-start d-none d-sm-table-cell clickable"
                            (click)="viewmodel.didClickSort('ledenr', !viewmodel.autoflexRowFilterAndSorter.orderReverse);">
                            <i class="fa" [ngClass]="viewmodel.getSortClass('ledenr')"></i>
                            Ledenr
                        </th>
                        <th class="text-start d-none d-sm-table-cell clickable"
                            (click)="viewmodel.didClickSort('text', !viewmodel.autoflexRowFilterAndSorter.orderReverse);">
                            <i class="fa" [ngClass]="viewmodel.getSortClass('text')"></i>
                            Autotaks tekst
                        </th>
                        <th class="text-start d-none d-sm-table-cell clickable"
                            (click)="viewmodel.didClickSort('altText', !viewmodel.autoflexRowFilterAndSorter.orderReverse);">
                            <i class="fa" [ngClass]="viewmodel.getSortClass('altText')"></i>
                            Alternativ tekst
                        </th>
                        <th class="text-start d-none d-sm-table-cell clickable"
                            (click)="viewmodel.didClickSort('price', !viewmodel.autoflexRowFilterAndSorter.orderReverse);">
                            <i class="fa" [ngClass]="viewmodel.getSortClass('price')"></i>
                            Vejl. pris
                        </th>
                        <th class="text-start d-none d-sm-table-cell clickable"
                            (click)="viewmodel.didClickSort('altPrice', !viewmodel.autoflexRowFilterAndSorter.orderReverse);">
                            <i class="fa" [ngClass]="viewmodel.getSortClass('altPrice')"></i>
                            Alt. pris
                        </th>
                        <th class="text-start d-none d-sm-table-cell clickable"
                            (click)="viewmodel.didClickSort('quality', !viewmodel.autoflexRowFilterAndSorter.orderReverse);">
                            <i class="fa" [ngClass]="viewmodel.getSortClass('quality')"></i>
                            Kvalitet
                        </th>
                        <th class="text-start d-none d-sm-table-cell clickable"
                            (click)="viewmodel.didClickSort('oeNo', !viewmodel.autoflexRowFilterAndSorter.orderReverse);">
                            <i class="fa" [ngClass]="viewmodel.getSortClass('oeNo')"></i>
                            <span class="d-none d-lg-inline">Autotaks</span> OE nr.
                        </th>
                        <th class="text-start d-none d-sm-table-cell clickable"
                            (click)="viewmodel.didClickSort('resNo', !viewmodel.autoflexRowFilterAndSorter.orderReverse);">
                            <i class="fa" [ngClass]="viewmodel.getSortClass('resNo')"></i>
                            Resnr.
                        </th>
                        <th class="text-start d-none d-sm-table-cell clickable"
                            (click)="viewmodel.didClickSort('supplier', !viewmodel.autoflexRowFilterAndSorter.orderReverse);">
                            <i class="fa" [ngClass]="viewmodel.getSortClass('supplier')"></i>
                            Leverandør
                        </th>
                        <th class="text-start d-none d-sm-table-cell clickable"
                            (click)="viewmodel.didClickSort('manufacturer', !viewmodel.autoflexRowFilterAndSorter.orderReverse);">
                            <i class="fa" [ngClass]="viewmodel.getSortClass('manufacturer')"></i>
                            Fabrikant
                        </th>
                    </tr>
                    </thead>
                    <ng-container *ngFor="let autoflexRow of viewmodel.autoflexRowFilterAndSorter.autoflexRows">
                        <tr *ngIf="autoflexRow.visible" class="autoflex-top-row clickable"
                            (click)="didClick(autoflexRow)">
                            <td>
                                <i class="m-2 fa fa-square-o fa-2x select-icon"
                                   [ngClass]="{'fa-plus-square-o': autoflexRow.hasMultipleData(), 'multi-selected': autoflexRow.hasSubSelection(), 'fa-check-square-o': !autoflexRow.hasMultipleData() && autoflexRow.selected, 'fa-square-o': !autoflexRow.hasMultipleData() && !autoflexRow.selected}"></i>
                            </td>
                            <td>{{autoflexRow.ledenr}}</td>
                            <td>{{autoflexRow.text}}</td>
                            <td>{{autoflexRow.altText}}</td>
                            <td>{{autoflexRow.price}}</td>
                            <td>{{autoflexRow.altPrice}}</td>
                            <td>{{autoflexRow.quality}}</td>
                            <td>{{autoflexRow.oeNo}}</td>
                            <td>{{autoflexRow.resNo}}</td>
                            <td>{{autoflexRow.supplier}}</td>
                            <td>{{autoflexRow.manufacturer}}</td>
                        </tr>
                        <ng-container *ngIf="autoflexRow.hasMultipleData() && autoflexRow.selected">
                            <ng-container *ngFor="let autoflexSubRow of autoflexRow.data">
                                <tr *ngIf="autoflexSubRow.visible" class="clickable" (click)="didClick(autoflexSubRow)">
                                    <td><i class="m-2 fa fa-square-o fa-2x select-icon"
                                           [ngClass]="{'fa-check-square-o': autoflexSubRow.selected, 'fa-square-o': !autoflexSubRow.selected}"></i>
                                    </td>
                                    <td>{{autoflexSubRow.ledenr}}</td>
                                    <td>{{autoflexSubRow.text}}</td>
                                    <td>{{autoflexSubRow.altText}}</td>
                                    <td>{{autoflexSubRow.price}}</td>
                                    <td>{{autoflexSubRow.altPrice}}</td>
                                    <td>{{autoflexSubRow.quality}}</td>
                                    <td>{{autoflexSubRow.oeNo}}</td>
                                    <td>{{autoflexSubRow.resNo}}</td>
                                    <td>{{autoflexSubRow.supplier}}</td>
                                    <td>{{autoflexSubRow.manufacturer}}</td>
                                </tr>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </table>
            </div>
        </div>
    </div>

    <div class="row d-md-none">
        <!--<div class="row">-->
        <ng-container *ngFor="let autoflexRow of viewmodel.autoflexRowFilterAndSorter.autoflexRows">
            <div class="card ms-3 me-3 mb-3" style="width: 100%" *ngIf="autoflexRow.visible"
                 [ngClass]="{'selected': autoflexRow.hasMultipleData() ? autoflexRow.hasSubSelection() : autoflexRow.selected}">
                <div class="card-header">
                    <div class="row">
                        <div class="col-2">{{autoflexRow.ledenr}}</div>
                        <div class="col-4">{{autoflexRow.text}}</div>
                        <div class="col-3">{{autoflexRow.oeNo}}</div>
                        <div class="col-3 text-end" style="white-space: nowrap">{{autoflexRow.price}}</div>
                    </div>
                </div>
                <div class="card-body pt-0 pb-0">
                    <ng-container *ngIf="!autoflexRow.hasMultipleData()">
                        <div class="row clickable" (click)="didClick(autoflexRow)">
                            <div class="col-2 p-1">
                                <i class="m-2 fa fa-square-o fa-2x select-icon"
                                   [ngClass]="{'fa-check-square-o': autoflexRow.selected, 'fa-square-o': !autoflexRow.selected}"></i>
                            </div>
                            <div class="col-4 p-3">{{autoflexRow.altText}}</div>
                            <div class="col-3 p-3">{{autoflexRow.resNo}}</div>
                            <div class="col-3 p-3 text-end" style="white-space: nowrap">{{autoflexRow.altPrice}}</div>
                        </div>
                        <div class="row clickable" (click)="didClick(autoflexRow)">
                            <div class="col-2">&nbsp;</div>
                            <div class="col-4 p-3">{{autoflexRow.supplier}}</div>
                            <div class="col-3 p-3">{{autoflexRow.quality}}</div>
                            <div class="col-3">&nbsp;</div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="autoflexRow.hasMultipleData()">
                        <ng-container *ngIf="autoflexRow.hasSubSelection()">
                            <div class="row clickable" (click)="didClick(autoflexRow)">
                                <div class="col-2 p-1">
                                    <i class="m-2 fa fa-plus-square-o fa-2x select-icon multi-selected"></i>
                                </div>
                                <div class="col-4 p-3">{{autoflexRow.getSelectedRow().altText}}</div>
                                <div class="col-3 p-3">{{autoflexRow.getSelectedRow().resNo}}</div>
                                <div class="col-3 p-3 text-end" style="white-space: nowrap">
                                    {{autoflexRow.getSelectedRow().altPrice}}
                                </div>
                            </div>
                            <div class="row clickable" (click)="didClick(autoflexRow)">
                                <div class="col-2">&nbsp;</div>
                                <div class="col-4 p-3">{{autoflexRow.getSelectedRow().supplier}}</div>
                                <div class="col-3 p-3">{{autoflexRow.getSelectedRow().quality}}</div>
                                <div class="col-3">&nbsp;</div>
                            </div>

                        </ng-container>
                        <ng-container *ngIf="!autoflexRow.hasSubSelection()">
                            <div class="row clickable" (click)="didClick(autoflexRow)">
                                <div class="col-2 p-1">
                                    <i class="m-2 fa fa-plus-square-o fa-2x select-icon"></i>
                                </div>
                                <div class="col-10 p-3">Der findes alternative autoflex dele</div>
                            </div>
                            <div class="row clickable" (click)="didClick(autoflexRow)">
                                <div class="col-2">&nbsp;</div>
                                <div class="col-10 p-3">Ingen valgt</div>
                            </div>
                        </ng-container>
                        <!-- show sub rows -->
                        <ng-container *ngIf="autoflexRow.selected">
                            <ng-container *ngFor="let autoflexSubRow of autoflexRow.data">
                                <div class="row autoflex-subrow clickable" (click)="didClick(autoflexSubRow)"
                                     *ngIf="autoflexSubRow.visible" [ngClass]="{'selected' : autoflexSubRow.selected}">
                                    <div class="col-2 p-1"><i class="m-2 fa fa-square-o fa-2x select-icon"
                                                              [ngClass]="{'fa-check-square-o': autoflexSubRow.selected, 'fa-square-o': !autoflexSubRow.selected}"></i>
                                    </div>
                                    <div class="col-4 p-3">{{autoflexSubRow.altText}}</div>
                                    <div class="col-3 p-3">{{autoflexSubRow.resNo}}</div>
                                    <div class="col-3 p-3 text-end" style="white-space: nowrap">
                                        {{autoflexSubRow.altPrice}}
                                    </div>
                                </div>
                                <div class="row autoflex-subrow autoflex-subrow-border clickable"
                                     (click)="didClick(autoflexSubRow)"
                                     *ngIf="autoflexSubRow.visible" [ngClass]="{'selected' : autoflexSubRow.selected}">
                                    <div class="col-2">&nbsp;</div>
                                    <div class="col-4 p-3">{{autoflexSubRow.supplier}}</div>
                                    <div class="col-3 p-3">{{autoflexSubRow.quality}}</div>
                                    <div class="col-3">&nbsp;</div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </ng-container>

    </div>

    <div class="row">
        <div class="col-12 dynamic-padding-bottom">&nbsp;</div>
    </div>
</div>
