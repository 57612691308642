/**
 * Class capable of notifying about important changes
 */
import {ClientStateDetails} from '../../model/client-state-details.model';
import {Injectable} from '@angular/core';
import {StringUtils} from '../../../shared/utils/string-utils';
import {DateUtils} from '../../../shared/utils/date-utils';
import {Specialkode} from '../../../shared/model/specialkode.model';
import {ValueTypeDTO} from '../../../shared/dto/value-type-dto.model';
import {AftaleDTO} from '../../../shared/dto/aftale-dto.model';
import {isNullOrUndefined} from '../../../shared/utils/object-utils';

@Injectable()
export class ClientStateDifferService {

  private addPossibleDifference(result: Array<PoliceDifference>, id: string, actualValue: string, expectedValue: string, resetCallback?: Function): void {
    if (StringUtils.isNotEmpty(actualValue) && actualValue !== expectedValue) {
      result.push(new PoliceDifference(id, actualValue, expectedValue, resetCallback));
    }
  }

  private addPossibleAgreementDifference(result: Array<AgreementDifference>, specialKode: Specialkode, valueType: ValueTypeDTO): void {
    const deleted = specialKode.deleted;
    const valueChanged = !isNullOrUndefined(specialKode.value) && specialKode.value !== specialKode.agreementValue;
    if (valueChanged || deleted) {
      const valueFormatted = isNullOrUndefined(specialKode.value) || deleted ? '' : valueType.round(specialKode.value);
      const agreementValueFormatted = isNullOrUndefined(specialKode.agreementValue) ? '' : valueType.round(specialKode.agreementValue);
      result.push(new AgreementDifference(valueType.type, '' + valueFormatted, '' + (agreementValueFormatted), valueType.unitSign));
    }
  }

  private addPossibleSpecificAgreementDifference(result: Array<AgreementDifference>, value: number, valueType: ValueTypeDTO, agreements: AftaleDTO[]): void {
    const aftaleDTO = agreements.find(aftale => aftale.valueTypeId === valueType.id);
    if (!isNullOrUndefined(value) && !isNaN(value)) {
      if (isNullOrUndefined(aftaleDTO)) {
        result.push(new AgreementDifference(valueType.type, '' + valueType.round(value), '', valueType.unitSign));
      } else {
        if (aftaleDTO.value !== value) {
          result.push(new AgreementDifference(valueType.type, '' + valueType.round(value), '' + (valueType.round(aftaleDTO.value)), valueType.unitSign));
        }
      }
    }
  }

  public getAgreementDifferences(clientState: ClientStateDetails, valueTypes: ValueTypeDTO[], aftaler: AftaleDTO[]): AgreementDifference[] {
    const result = [];
    const specialkoder = clientState.specialkoder;

    const timeprisValueType = valueTypes.find(valueType => valueType.kode === 1000);
    this.addPossibleSpecificAgreementDifference(result, clientState.timepris, timeprisValueType, aftaler);
    const lakTimeprisValueType = valueTypes.find(valueType => valueType.kode === 1001);
    this.addPossibleSpecificAgreementDifference(result, clientState.lakTimepris, lakTimeprisValueType, aftaler);

    //go through all other specialkodes
    if (!isNullOrUndefined(specialkoder)) {
      specialkoder.forEach(specialKode => {
        const valueType = valueTypes.find(x => specialKode.kode === x.kode);
        this.addPossibleAgreementDifference(result, specialKode, valueType);
      });
    }
    return result;
  }

  public getPoliceDifferences(clientState: ClientStateDetails): PoliceDifference[] {
    const result = [];
    if (!isNullOrUndefined(clientState.police) && !clientState.police.empty) {
      this.addPossibleDifference(result, 'Forsikringstager Regnr', clientState.forsikringstager.regNr, clientState.police.regNr, () => {
        clientState.forsikringstager.regNr = clientState.police.regNr;
      });
      this.addPossibleDifference(result, 'Forsikringstager kundenummer', clientState.forsikringstager.kundenr, clientState.police.kundenr, () => {
        clientState.forsikringstager.kundenr = clientState.police.kundenr;
      });
      this.addPossibleDifference(result, 'Forsikringstager policenr', clientState.forsikringstager.policenr, clientState.police.policenr, () => {
        clientState.forsikringstager.policenr = clientState.police.policenr;
      });
      this.addPossibleDifference(result, 'Forsikringstager navn', clientState.forsikringstager.navn, clientState.police.navn, () => {
        clientState.forsikringstager.navn = clientState.police.navn;
      });
      this.addPossibleDifference(result, 'Forsikringstager adresse1', clientState.forsikringstager.addressLine1, clientState.police.addressLine1, () => {
        clientState.forsikringstager.addressLine1 = clientState.police.addressLine1;
      });
      this.addPossibleDifference(result, 'Forsikringstager adresse2', clientState.forsikringstager.addressLine2, clientState.police.addressLine2, () => {
        clientState.forsikringstager.addressLine2 = clientState.police.addressLine2;
      });
      this.addPossibleDifference(result, 'Forsikringstager postnr', clientState.forsikringstager.postNr, clientState.police.postNr, () => {
        clientState.forsikringstager.postNr = clientState.police.postNr;
      });
      this.addPossibleDifference(result, 'Leasingselskab navn', clientState.leasingselskab.navn, clientState.police.leasingNavn, () => {
        clientState.leasingselskab.navn = clientState.police.leasingNavn;
      });
      this.addPossibleDifference(result, 'Leasingselskab adresse1', clientState.leasingselskab.addressLine1, clientState.police.leasingAdrLinie1, () => {
        clientState.leasingselskab.addressLine1 = clientState.police.leasingAdrLinie1;
      });
      this.addPossibleDifference(result, 'Leasingselskab adresse2', clientState.leasingselskab.addressLine2, clientState.police.leasingAdrLinie2, () => {
        clientState.leasingselskab.addressLine2 = clientState.police.leasingAdrLinie2;
      });
      this.addPossibleDifference(result, 'Leasingselskab postnr', clientState.leasingselskab.postNr, clientState.police.leasingPostnr, () => {
        clientState.leasingselskab.postNr = clientState.police.leasingPostnr;
      });
      this.addPossibleDifference(result, 'Leasingselskab by', clientState.leasingselskab.by, clientState.police.leasingBy, () => {
        clientState.leasingselskab.by = clientState.police.leasingBy;
      });
      this.addPossibleDifference(result, 'Stelnr', clientState.vehicle.stelnr, clientState.police.stelnr, () => {
        clientState.vehicle.stelnr = clientState.police.stelnr;
      });
      const firstRegistrationDateMillis = DateUtils.ngbDateStructFromMilliseconds(clientState.police.foersteregDato)
      this.addPossibleDifference(result, 'Førsteregistreringsdato', DateUtils.ngbDateStructToString(clientState.vehicle.foersteRegDato), DateUtils.ngbDateStructToString(firstRegistrationDateMillis), () => {
        clientState.vehicle.foersteRegDato = firstRegistrationDateMillis;
      });
      this.addPossibleDifference(result, 'Forsikringstager selvrisiko', clientState.forsikringstager.selvrisiko, clientState.police.selvrisiko, () => {
        clientState.forsikringstager.selvrisiko = clientState.police.selvrisiko;
      });
      this.addPossibleDifference(result, 'Forsikringstager Skadenummer', clientState.forsikringstager.skadenr, clientState.police.skadenr, () => {
        clientState.forsikringstager.skadenr = clientState.police.skadenr;
      });
    }
    return result;
  }
}

export class PoliceDifference {
  constructor(public id: string, public value: string, public originalValue: string, public resetCallback: Function) {
  }

  public canReset(): boolean {
    return !isNullOrUndefined(this.resetCallback);
  }

  public resetValue(): void {
    if (this.canReset()) {
      this.resetCallback();
    }
  }

  public valueMissing(): boolean {
    return StringUtils.isEmpty(this.value);
  }

  public valueText(): string {
    return this.valueMissing() ? '' : this.value;
  }

  public originalMissing(): boolean {
    return StringUtils.isEmpty(this.originalValue);
  }

  public originalText(): string {
    return this.originalMissing() ? 'mangler i opslag' : this.originalValue;
  }
}

export class AgreementDifference {
  constructor(public title: string, public actualValue: string, public expectedValue: string, public unit: string) {
  }

  public actualValueMissing(): boolean {
    return StringUtils.isEmpty(this.actualValue);
  }

  public actualValueText(): string {
    return this.actualValueMissing() ? 'slettet' : this.actualValue;
  }

  public expectedValueMissing(): boolean {
    return StringUtils.isEmpty(this.expectedValue);
  }

  public expectedValueText(): string {
    return this.expectedValueMissing() ? 'ingen aftale' : this.expectedValue;
  }
}
