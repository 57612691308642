import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NonStandardPositionDTO} from '../dto/non-standard-position/non-standard-position-dto.model';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {NonStandardPositionRepairMethodDTO} from '../dto/non-standard-position/non-standard-position-repair-method-dto.model';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  templateUrl: './shared-non-standard-position-form.html',
  selector: 'lc-shared-non-standard-position-form',
})
export class SharedNonStandardPositionFormComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  @Input() form: UntypedFormGroup;
  @Input() nonStandardPosition: NonStandardPositionDTO;
  @Input() repairMethods: NonStandardPositionRepairMethodDTO[];

  defaultDescriptionValidators = [Validators.maxLength(20)];
  defaultWorkingUnitsValidators = [Validators.min(0), Validators.max(9999)];
  defaultNewForOldValidators = [Validators.min(0), Validators.max(100)];
  defaultPriceValidators = [Validators.min(0), Validators.max(999999.99)];
  defaultSparePartDescriptionValidators = [Validators.maxLength(15)];

  // Both workingUnits AND price is allowed
  //  - if workingUnits is given, description is required.
  //  - if price is given, sparePartDescription is required.
  private rule1 = ['E', 'LE', 'L', 'LS'];

  // workingUnits OR price must be provided.
  //  -  sparePartDescription and newForOld is disabled
  private rule2 = ['N', 'I', 'P', 'H', 'U', 'V'];

  constructor() {
  }

  ngOnInit(): void {
    this.form.addControl('description', new UntypedFormControl(this.nonStandardPosition.description, this.defaultDescriptionValidators));
    this.form.addControl('workingUnits', new UntypedFormControl(this.nonStandardPosition.workingUnits, this.defaultWorkingUnitsValidators));
    this.form.addControl('newForOld', new UntypedFormControl(this.nonStandardPosition.newForOld, this.defaultNewForOldValidators));
    this.form.addControl('price', new UntypedFormControl(this.nonStandardPosition.price, this.defaultPriceValidators));
    this.form.addControl('sparePartDescription', new UntypedFormControl(this.nonStandardPosition.sparePartDescription, this.defaultSparePartDescriptionValidators));
    this.form.addControl('repairMethod', new UntypedFormControl(this.nonStandardPosition.repairMethod, [Validators.required]));
    this.form.addControl('group', new UntypedFormControl(this.nonStandardPosition.group, [Validators.maxLength(20)]));
    this.updateFormValidators();
    this.form.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.updateFormValidators();
      })
  }

  updateFormValidators(): void {
    this.setDefaultValidators();
    const repairMethod = this.form.controls['repairMethod'].value;
    if (repairMethod) {
      if (this.rule1.includes(repairMethod)) {
        if (this.form.controls['workingUnits'].value) {
          this.form.controls['description'].setValidators(this.defaultDescriptionValidators.concat([Validators.required]));
          this.form.controls['description'].updateValueAndValidity({emitEvent: false});
        }
        if (this.form.controls['price'].value) {
          this.form.controls['sparePartDescription'].setValidators(this.defaultSparePartDescriptionValidators.concat([Validators.required]));
          this.form.controls['sparePartDescription'].updateValueAndValidity({emitEvent: false});
        }
      } else if (this.rule2.includes(repairMethod)) {
        if (!this.form.controls['workingUnits'].value && !this.form.controls['price'].value) {
          this.form.controls['workingUnits'].setValidators(this.defaultWorkingUnitsValidators.concat([Validators.required]));
          this.form.controls['workingUnits'].updateValueAndValidity({emitEvent: false});
          this.form.controls['price'].setValidators(this.defaultPriceValidators.concat([Validators.required]));
          this.form.controls['price'].updateValueAndValidity({emitEvent: false});
        }
        this.form.controls['newForOld'].disable({emitEvent: false});
        this.form.controls['newForOld'].setValue(null, {emitEvent: false});
        this.form.controls['newForOld'].updateValueAndValidity({emitEvent: false});
        this.form.controls['sparePartDescription'].disable({emitEvent: false});
        this.form.controls['sparePartDescription'].setValue(null, {emitEvent: false});
        this.form.controls['sparePartDescription'].updateValueAndValidity({emitEvent: false});
      } else {
        this.setDefaultValidators();
      }
    } else {
      this.setDefaultValidators();
    }
  }

  private setDefaultValidators(): void {
    this.form.controls['description'].setValidators(this.defaultDescriptionValidators);
    this.form.controls['description'].updateValueAndValidity({emitEvent: false});
    this.form.controls['workingUnits'].setValidators(this.defaultWorkingUnitsValidators);
    this.form.controls['workingUnits'].updateValueAndValidity({emitEvent: false});
    this.form.controls['newForOld'].setValidators(this.defaultNewForOldValidators);
    this.form.controls['newForOld'].enable({emitEvent: false});
    this.form.controls['newForOld'].updateValueAndValidity({emitEvent: false});
    this.form.controls['price'].setValidators(this.defaultPriceValidators);
    this.form.controls['price'].updateValueAndValidity({emitEvent: false});
    this.form.controls['sparePartDescription'].setValidators(this.defaultSparePartDescriptionValidators);
    this.form.controls['sparePartDescription'].enable({emitEvent: false});
    this.form.controls['sparePartDescription'].updateValueAndValidity({emitEvent: false});
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
