import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ClientStateWarningDTO} from '../../../../dto/client-state-warning-dto.model';
import {DraftService, ReportState} from '../../../../service/draft.service';
import {LcActionMenu, LcActionMenuItem} from '../../../../../shared/ui/bottombar/lc-action-menu.model';
import {Router} from '@angular/router';
import {combineLatest, Observable, of, Subject} from 'rxjs';
import {ForsiReportKeyPipe} from '../../../../../shared/pipe/forsi-report-key.pipe';
import {ForsiReportKeyShortPipe} from '../../../../../shared/pipe/forsi-report-key-short.pipe';
import {CreateReportDTO} from '../../../../../shared/dto/create-report-dto.model';
import {BootstrapAlertType, BootstrapGrowlService} from '../../../../../shared/ui/ngx-bootstrap-growl';
import {ClientStateDetails} from '../../../../model/client-state-details.model';
import {DraftEditService} from '../../../draft-edit.service';
import {ConfirmPopupComponent} from '../../../../../shared/modals/confirm/confirm-popup.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CarDetails} from '../../../../model/flowtwo-car-details.model';
import {ReportCategory} from '../../../../../shared/model/report-category.model';
import {RapportType} from '../../../../../shared/model/rapport-type.model';
import {PriceVersionService} from '../../../../../shared/service/price-version.service';
import {ClientNavigationService} from '../../../../../shared/service/client-navigation.service';
import {mergeMap, takeUntil} from 'rxjs/operators';
import {VKStamService} from '../../../../../shared/service/vkstam.service';
import {VkStam} from '../../../../../shared/model/vk-stam-model';
import {isNullOrUndefined} from '../../../../../shared/utils/object-utils';
import {AssessorApprovalDeltaDTO} from '../../../../dto/assessor-approval-options-dto.model';
import {TextLibraryPopupComponent} from '../../../../../shared/modals/text-library/text-library-popup.component';
import {TextLibraryTypeEnum} from '../../../../../shared/service/text-library-type.enum';
import {UserTextLibraryService} from '../../../../../shared/service/user-text-library.service';

@Component({
  selector: 'lc-taks-draft-approve',
  templateUrl: './taks-draft-approve.component.html',
  styleUrls: [
    '../../../draft-common.scss'
  ]
})
export class TaksDraftApproveComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  @Input() clientStateToken: string;
  loading = true;
  private updatingDetails = false;
  private eligibleForCancel: boolean;
  private originalValueDeterioration: boolean;
  clientStateWarning: ClientStateWarningDTO;
  approvalDelta: AssessorApprovalDeltaDTO;
  clientState: ClientStateDetails;
  carDetails: CarDetails;
  bottomMenu: LcActionMenu;
  bottomMenuTitle: string;
  bottomMenuShortTitle: string;
  menuIconClass: string;
  showApproveCalculationDate: boolean;
  vkStam: VkStam;
  requestWorkshopAccept: boolean;
  acceptMessage: string;
  libraryType = TextLibraryTypeEnum.BESKED;

  constructor(private draftService: DraftService,
              private modalService: NgbModal,
              private userTextLibraryService: UserTextLibraryService,
              private draftEditService: DraftEditService,
              private router: Router,
              private bootstrapGrowlService: BootstrapGrowlService,
              private reportKeyPipe: ForsiReportKeyPipe,
              private reportKeyShortPipe: ForsiReportKeyShortPipe,
              private navigationService: ClientNavigationService,
              private priceVersionService: PriceVersionService,
              private vkStamService: VKStamService) {
  }

  ngOnInit(): void {
    const options$ = this.draftService.getAssessorApprovalOptions(this.clientStateToken);
    const clientState$ = this.draftEditService.getClientStateObservable(this.clientStateToken).pipe();
    const calculationDateObs$ = this.priceVersionService.getPriceVersionFromDate(new Date(Date.now()));

    combineLatest([options$, clientState$, calculationDateObs$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([options, clientState, calculationDate]) => {
        this.eligibleForCancel = options && options.eligibleForCancel;
        this.clientStateWarning = options ? options.warning : null;
        this.approvalDelta = options ? options.approvalDelta : null
        this.originalValueDeterioration = clientState.valueDeteriorationText;
        this.clientState = clientState;
        this.carDetails = clientState.getCarDetails();
        this.setupBottomActionMenu();

        this.priceVersionService.getPriceVersionFromDate(clientState.updatedAt)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(price => {
            this.showApproveCalculationDate = price.version !== calculationDate.version;
          });

        this.vkStamService.getVaerkstedInfo(clientState.vknr)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(vkStam => {
            this.vkStam = vkStam;
          });
        this.loading = false;
      });
  }

  private setupBottomActionMenu(): void {
    this.bottomMenu = new LcActionMenu();

    const backMenuItem = new LcActionMenuItem('fa-arrow-left', () => {
      this.navigationService.back(['draft']);
      // this.router.navigate(['../calculate'], {relativeTo: this.route});
    }, 'Tilbage');
    backMenuItem.disabledCallback = () => this.loading || this.updatingDetails;
    this.bottomMenu.addItem(backMenuItem);

    const preliminaryApproveMenuItem = new LcActionMenuItem('fa-check', () => {
      this.createReport(ReportState.PRELIMINARY, false);
    }, 'Foreløbig');
    preliminaryApproveMenuItem.disabledCallback = () => {
      if (this.loading || this.updatingDetails) {
        return true;
      }
      return !ReportCategory.enabled(this.clientState.schema);
    };
    preliminaryApproveMenuItem.buttonClass = 'btn-warning';
    this.bottomMenu.addItem(preliminaryApproveMenuItem);

    const approveMenuItem = new LcActionMenuItem('fa-check', () => {
      if (this.eligibleForCancel) {
        const modalChangesRef = this.modalService.open(ConfirmPopupComponent);
        modalChangesRef.componentInstance.title = 'Godkend / Annullering';
        modalChangesRef.componentInstance.body = 'Du har fjernet alle ledenumre, skal rapporten godkendes som annullering?';
        modalChangesRef.result.then((cancelConfirmed: boolean) => {
          if (cancelConfirmed) {
            this.createReport(ReportState.APPROVE, cancelConfirmed);
          } else {
            this.createReport(ReportState.APPROVE, cancelConfirmed, this.requestWorkshopAccept, this.acceptMessage);
          }
        }, () => {
        });
      } else {
        this.createReport(ReportState.APPROVE, false, this.requestWorkshopAccept, this.acceptMessage);
      }
    }, 'Godkend');
    approveMenuItem.disabledCallback = () => {
      if (this.loading || this.updatingDetails) {
        return true;
      }

      return !ReportCategory.enabled(this.clientState.schema);
    };
    approveMenuItem.buttonClass = 'btn-success';
    this.bottomMenu.addItem(approveMenuItem);

    this.bottomMenuTitle = this.reportKeyPipe.transform(this.clientState.taksRapportKey);
    this.bottomMenuShortTitle = this.reportKeyShortPipe.transform(this.clientState.taksRapportKey);
    this.menuIconClass = ReportCategory.iconCssClass(this.clientState.schema);
  }

  public showChangeValueDeterioration(): boolean {
    return RapportType.extractFrom(this.clientState.rapportKey()).isOfAny([RapportType.H, RapportType.T]);
  }

  private createReport(state: ReportState, cancelReport: boolean, requestWorkshopAccept?: boolean, acceptMessage?: string): void {
    this.loading = true;
    const createReport = new CreateReportDTO();
    createReport.rapportState = state;
    createReport.cancelReport = cancelReport;
    createReport.acceptRequested = requestWorkshopAccept
    createReport.acceptMessage = acceptMessage;
    this.ensureDeteriorationIsSavedBeforeCreation().pipe(
      mergeMap(clientState => this.draftService.createTaksatorReportFromClientState(clientState.token, createReport)),
      takeUntil(this.unsubscribe$)
    ).subscribe(result => {
      if (result.succeeded) {
        let message = result.message;
        if (isNullOrUndefined(message)) {
          switch (state) {
            case ReportState.APPROVE:
              if (cancelReport) {
                message = 'Rapporten er godkendt som annullering.';
              } else {
                message = 'Rapporten er oprettet og godkendt.';
              }
              break;
            case ReportState.BACKUP:
              message = 'Rapporten er oprettet i tilstand BU';
              break;
            case ReportState.PRELIMINARY:
              message = 'Rapporten er foreløbig godkendt';
              break;
            default:
              message = 'Rapporten er godkendt';
          }
        }
        this.bootstrapGrowlService.addAlert(message, BootstrapAlertType.SUCCESS, 3000);
        this.router.navigate([this.draftEditService.getNavigateToUponApproval()]);
      } else {
        const message = 'Rapporten kunne ikke oprettes. ' + result.message;
        this.bootstrapGrowlService.addAlert(message, BootstrapAlertType.WARNING, 5000);
      }
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }

  public getVKNotOperatingMessage(): string {
    let result: string = null;
    if (!isNullOrUndefined(this.vkStam)) {
      result = this.vkStam.vkNotOperatingMessage;
    }
    return result;
  }

  public vkIsOperating(): boolean {
    return !isNullOrUndefined(this.vkStam) ? this.vkStam.vkIsOperating : true;
  }

  private ensureDeteriorationIsSavedBeforeCreation(): Observable<ClientStateDetails> {
    const changeInDeteriorationValue = this.originalValueDeterioration !== this.clientState.valueDeteriorationText;
    return changeInDeteriorationValue ? this.draftService.saveAndMap(this.clientState) : of(this.clientState);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  showTextLibrary(): void {
    const modalRef = this.modalService.open(TextLibraryPopupComponent, {size: 'lg'});
    const popUpComponent = modalRef.componentInstance as TextLibraryPopupComponent;
    popUpComponent.title = 'Bemærkninger Tekstbibliotek';
    popUpComponent.textLibraryType = this.libraryType;
    popUpComponent.textLibraryService = this.userTextLibraryService;
    modalRef.result.then((text: string) => {
      if (text) {
        this.didSelectTextFromLibrary(text);
      }
    }).catch(() => {
      // Necessary to avoid "Uncaught error Error: Uncaught (in promise)" even though we do not use the returned value.
    });
  }

  didSelectTextFromLibrary(text: string) {
    this.acceptMessage = this.acceptMessage ? this.acceptMessage + text : text;
  }
}
