<div class="mb-5 pb-5">
    <div class="card" *ngIf="!policy">
        <div class="card-body">
            <h4 class="m-0">Der blev ikke fundet noget police opslag for denne rapport</h4>
        </div>
    </div>
    <div class="card" *ngIf="policy">
        <div class="card-body">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-3 col-md-6"><span
                            class="fw-bold">Kundenr:</span>{{policy.customerNumber}}
                        </div>
                        <div class="col-lg-3 col-md-6"><span class="fw-bold">Policenr:</span>
                            {{policy.policyNumber}}
                        </div>
                        <div class="col-lg-3 col-md-6"><span class="fw-bold">Forbehold:</span>
                            <span *ngIf="!isVK()"> {{policy.conditioned ? 'Ja' : 'Nej'}}</span>
                        </div>
                        <div class="col-lg-3 col-md-6"><span class="fw-bold">Selskab:</span>
                            {{policy.companyCode}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <h3>Forsikringstager</h3>
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-6 col-sm-4 font-weight-bold">Navn</div>
                                <div class="col">{{policy.policyHolder?.name}}</div>
                            </div>
                            <div class="row">
                                <div class="col-6 col-sm-4 font-weight-bold">Adresse</div>
                                <div class="col">
                                    {{policy.policyHolder?.address}}
                                    <span *ngIf="policy.policyHolder?.address2">
                                        <br/>
                                        {{policy.policyHolder?.address2}}
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6 col-sm-4 font-weight-bold">Postnr. / by</div>
                                <div class="col">{{policy.policyHolder?.zipCode}} {{policy.policyHolder?.city}}</div>
                            </div>
                            <div class="row">
                                <div class="col-6 col-sm-4 font-weight-bold">Email</div>
                                <div class="col">{{policy.policyHolder?.email}}</div>
                            </div>
                            <div class="row">
                                <div class="col-6 col-sm-4 font-weight-bold">Telefon</div>
                                <div class="col">{{policy.policyHolder?.phoneNumber}}</div>
                            </div>
                            <div class="row">
                                <div class="col-6 col-sm-4 font-weight-bold">CVR</div>
                                <div class="col">{{policy.policyHolder?.vatNumber}}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <h3>Ejer/Leasingselskab</h3>
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-6 col-sm-4 font-weight-bold">Navn</div>
                                <div class="col">{{policy.vehicleOwner?.name}}</div>
                            </div>
                            <div class="row">
                                <div class="col-6 col-sm-4 font-weight-bold">Adresse</div>
                                <div class="col">
                                    {{policy.vehicleOwner?.address}}
                                    <span *ngIf="policy.vehicleOwner?.address2">
                                        <br/>
                                        {{policy.vehicleOwner?.address2}}
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6 col-sm-4 font-weight-bold">Postnr. / by</div>
                                <div class="col">{{policy.vehicleOwner?.zipCode}} {{policy.vehicleOwner?.city}}</div>
                            </div>
                            <div class="row">
                                <div class="col-6 col-sm-4 font-weight-bold">Email</div>
                                <div class="col">{{policy.vehicleOwner?.email}}</div>
                            </div>
                            <div class="row">
                                <div class="col-6 col-sm-4 font-weight-bold">Telefon</div>
                                <div class="col">{{policy.vehicleOwner?.phoneNumber}}</div>
                            </div>
                            <div class="row">
                                <div class="col-6 col-sm-4 font-weight-bold">CVR</div>
                                <div class="col">{{policy.vehicleOwner?.vatNumber}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6">
                    <h3>Dækningsomfang</h3>
                    <div class="card">
                        <div class="card-body insurance-policy-info">
                            <div class="row">
                                <div class="col-6 col-sm-4 font-weight-bold">Kode</div>
                                <div class="col">{{policy.coverage}}</div>
                            </div>
                            <div class="row">
                                <div class="col-6 col-sm-4  font-weight-bold">Beskrivelse</div>
                                <div class="col">{{policy.coverageDescription}}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <h3>Biloplysninger</h3>
                    <div class="card">
                        <div class="card-body insurance-policy-info">
                            <div class="row">
                                <div class="col-6 col-sm-4  font-weight-bold">Regnr</div>
                                <div class="col">{{policy.vehicle?.licensePlate}}</div>
                            </div>
                            <div class="row">
                                <div class="col-6 col-sm-4  font-weight-bold">Bilmodel</div>
                                <div class="col">{{policy.vehicle?.model}}</div>
                            </div>
                            <div class="row">
                                <div class="col-6 col-sm-4  font-weight-bold">Stelnr</div>
                                <div class="col">{{policy.vehicle?.vin}}</div>
                            </div>
                            <div class="row">
                                <div class="col-6 col-sm-4  font-weight-bold">Første regdato</div>
                                <div class="col">{{policy.vehicle?.firstRegistrationAt | date: 'dd-MM-yyyy'}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6">
                    <h3>Vilkår</h3>
                    <div class="card">
                        <div class="card-body insurance-policy-info">
                            <div class="row">
                                <div class="col-6 col-sm-4  font-weight-bold">Specialvilkår</div>
                                <div class="col">{{policy.specialConditionsDescription}}</div>
                            </div>
                            <div class="row">
                            <div class="col-6 col-sm-4  font-weight-bold">Ikrafttrædelsesdato</div>
                                <div class="col">{{policy.startAt | date: 'dd-MM-yyyy'}}</div>
                            </div>
                            <div class="row">
                                <div class="col-6 col-sm-4  font-weight-bold">Afgangsdato</div>
                                <div class="col">{{policy.endAt | date: 'dd-MM-yyyy'}}</div>
                            </div>
                            <div class="row">
                                <div class="col-6 col-sm-4  font-weight-bold">Selvrisiko</div>
                                <div class="col">{{policy.noClaimsFee}}</div>
                            </div>
                            <div class="row">
                                <div class="col-6 col-sm-4  font-weight-bold">Momspligtig</div>
                                <div class="col">{{policy.subjectToVat}}</div>
                            </div>
                            <div class="row">
                                <div class="col-6 col-sm-4  font-weight-bold">Betingelsesnummer</div>
                                <div class="col">{{policy.termsReference}}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <h3>Skadeoplysninger</h3>
                    <div class="card">
                        <div class="card-body insurance-policy-info">
                            <div class="row">
                                <div class="col-6 col-sm-4 font-weight-bold">Skadenummer</div>
                                <div class="col">{{policy.incident?.incidentNumber}}</div>
                            </div>
                            <div class="row">
                                <div class="col-6 col-sm-4 font-weight-bold">Skadedato</div>
                                <div class="col">{{policy.incident?.incidentAt | date: 'dd-MM-yyyy'}}</div>
                            </div>
                            <div class="row">
                                <div class="col-6 col-sm-4 font-weight-bold">Omfang</div>
                                <div class="col">{{policy.incident?.damageExtent}}</div>
                            </div>
                            <div class="row">
                                <div class="col-6 col-sm-4 font-weight-bold">Skadetype</div>
                                <div class="col">{{policy.incident?.damageType}}</div>
                            </div>
                            <div class="row">
                                <div class="col-6 col-sm-4 font-weight-bold">Skadezoner</div>
                                <div class="col">{{policy.incident?.damageZones?.join(", ")}}</div>
                            </div>
                            <div class="row">
                                <div class="col-6 col-sm-4 font-weight-bold">Beskrivelse</div>
                                <div class="col">{{policy.incident?.description}}</div>
                            </div>
                            <div class="row">
                                <div class="col-6 col-sm-4 font-weight-bold">Seneste&nbsp;skadedato</div>
                                <div class="col">{{policy.lastIncidentAt | date: 'dd-MM-yyyy'}}</div>
                            </div>
                            <div class="row">
                                <div class="col-6 col-sm-4 font-weight-bold">Antal poster fundet</div>
                                <div class="col">{{policy.numberOfRecords}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="policy.incidents && policy.incidents.length > 0">
                <h3>Skadehistorik</h3>
                <div class="card">
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>Skadenummer</th>
                                        <th>Skadedato</th>
                                        <th>Omfang</th>
                                        <th>Skadetype</th>
                                        <th>Skadezoner</th>
                                        <th>Beskrivelse</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                <ng-container *ngFor="let incident of getSortedIncidents()">
                                    <tr>
                                        <td>{{incident.incidentNumber}}</td>
                                        <td>{{incident.incidentAt | date: 'dd-MM-yyyy'}}</td>
                                        <td>{{incident.damageExtent}}</td>
                                        <td>{{incident.damageType}}</td>
                                        <td>{{incident.damageZones?.join(", ")}}</td>
                                        <td>{{incident.description}}</td>
                                        <td>{{incident.state}}</td>
                                    </tr>
                                </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="policy.stakeholders && policy.stakeholders.length > 0">
                <h3>Interessenter</h3>
                <div class="card">
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-hover">
                                <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Navn</th>
                                    <th>Adresse</th>
                                    <th>Postnr. / by</th>
                                    <th>Email</th>
                                    <th>Telefon</th>
                                </tr>
                                </thead>
                                <tbody>
                                <ng-container *ngFor="let stakeholder of policy.stakeholders">
                                    <tr>
                                        <td>{{getType(stakeholder.type)}}</td>
                                        <td>{{stakeholder.name}}</td>
                                        <td>
                                            {{policy.policyHolder?.address}}
                                            <span *ngIf="policy.policyHolder?.address2">
                                                <br/>
                                                {{policy.policyHolder?.address2}}
                                            </span>
                                        </td>
                                        <td>{{policy.policyHolder?.zipCode}} {{policy.policyHolder?.city}}</td>
                                        <td>{{stakeholder.email}}</td>
                                        <td>{{stakeholder.phoneNumber}}</td>

                                    </tr>
                                </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>


            <div *ngIf="policy.text && policy.text.length > 0">
                <h3>Fritekst</h3>
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12" *ngFor="let text of policy.text">
                                {{text}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
