import {RouterModule, Routes} from '@angular/router';
import {UserRouteAccessService} from '../shared';
import {AppRole} from '../shared/service/auth/app-roles.model';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

export const PROFILE_ROUTE: Routes = [
  {
    path: 'admin-workshop',
    loadChildren: () => import('./admin-workshop.module').then(m => m.AdminWorkshopModule),
    data: {
      authorities: [AppRole.EVERYONE],
      breadcrumb: 'Administration'
    },
    canActivate: [UserRouteAccessService],
  }
];

/**
 * Routing module enabling lazyloading (through the loadChildren function) of the entire sysadmin module.
 */
@NgModule({
  imports: [
    RouterModule.forChild(PROFILE_ROUTE)
  ],
  declarations: [],
  exports: [],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AdminWorkshopRoutingModule {
}
