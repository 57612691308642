import {Pipe, PipeTransform} from '@angular/core';


import {isNullOrUndefined} from '../utils/object-utils';
import {AudatexAggregateSettlementPeriodType} from '../dto/audatex-aggregate-settlement-period-type.model';

@Pipe({
  name: 'audatexAggregateSettlementPeriodType'
})
export class AudatexAggregateSettlementPeriodTypePipe implements PipeTransform {
  private readonly STATE_MAPPINGS: { state: AudatexAggregateSettlementPeriodType, desc: string }[] = [
    {state: AudatexAggregateSettlementPeriodType.DAY, desc: 'Dag'},
    {state: AudatexAggregateSettlementPeriodType.MONTH, desc: 'Måned'},
    {state: AudatexAggregateSettlementPeriodType.YEAR, desc: 'År'},
    {state: AudatexAggregateSettlementPeriodType.QUARTER, desc: 'Kvartal'},
    {state: AudatexAggregateSettlementPeriodType.HALF_YEAR, desc: 'Halvårligt'},
  ];

  transform(value: AudatexAggregateSettlementPeriodType): string {
    return !isNullOrUndefined(value) ? this.STATE_MAPPINGS.find(v => v.state === value).desc : value;
  }
}
