import {Pipe, PipeTransform} from '@angular/core';
import {AdminUserInterface} from '../admin-user/dto/admin-user-dto.model';
import {UserStatePipe} from './user-state.pipe';

@Pipe({
  name: 'userName'
})
export class UserNamePipe implements PipeTransform {
  private userStatePipe = new UserStatePipe();

  transform(user: AdminUserInterface): string {
    if (user) {
      if (user.previousUsername) {
        return `${user.previousUsername} (${this.userStatePipe.transform(user.state)})`;
      } else {
        return user.username
      }
    }
    return '';
  }
}
