import {Component, OnDestroy, OnInit} from '@angular/core';
import {PrincipalDTO} from '../shared';
import {VideoLinkService} from '../shared/service/videolink.service';
import {VideoLinkDTO} from '../shared/dto/video-link-dto.model';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import EnvironmentUtils from '../shared/utils/environment-utils';

@Component({
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.scss'],
  providers: []
})
export class FaqComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  principalModel: PrincipalDTO;
  links: VideoLinkDTO[];
  searchLinks: VideoLinkDTO[];
  searchString: string;
  currentVideoLink: VideoLinkDTO;
  currentVideoEmbedLink: SafeResourceUrl;

  constructor(private videoLinkService: VideoLinkService,
              private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    this.videoLinkService.getAllByRole()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(result => {
        this.searchString = '';
        this.links = result;
        this.onSearchChange('');
      });
  }

  onSearchChange(input: string): void {
    this.searchString = input;
    this.searchLinks = this.links.filter(l => l.text.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) > -1);
  }

  searchkeyUp(key: any): void {
    switch (key.key) {
      case 'Escape':
        this.onSearchChange('');
        break;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  openVideo(videoLink: VideoLinkDTO): void {
    this.currentVideoLink = videoLink;
    if (videoLink.embedLink) {
      this.currentVideoEmbedLink = this.sanitizer.bypassSecurityTrustResourceUrl(videoLink.embedLink);
    } else {
      window.open(this.currentVideoLink.link);
    }
  }

  protected readonly EnvironmentUtils = EnvironmentUtils;
}
