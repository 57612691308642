import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {FabrikatDTO} from '../../makes/dto/fabrikat-dto.model';
import {ModelDTO} from '../../makes/dto/model-dto.model';
import URLBuilder from '../../utils/url-builder';
import {VinDetails} from '../../model/vin-details.model';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {Subject} from 'rxjs';
import {mergeMap, takeUntil} from 'rxjs/operators';
import {QapterPrepareModeService} from '../../service/qapter-prepare-mode.service';
import {DraftEditService} from '../../../draft/edit/draft-edit.service';
import {QapterPrepareModeDTO} from '../../dto/qapter-prepare-mode-dto.model';
import {QapterStartModel} from './qapter-start/qapter-start.component';

@Component({
  selector: 'lc-qapter-button',
  templateUrl: './qapter-button.component.html'
})
export class QapterButtonComponent implements OnInit, OnChanges, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  @Input() set token(newToken: string) {
    this._token = newToken;
  }
  get token(): string {
    return this._token;
  }
  private _token: string;
  @Input() fabrikat: FabrikatDTO;
  @Input() model: ModelDTO;
  @Input() fantomFabrikat: FabrikatDTO;
  @Input() fantomModel: ModelDTO;
  @Input() calcDate: NgbDateStruct;

  @Input() spin: boolean;
  @Input() required: boolean;
  @Input() vinDetails: VinDetails;
  @Input() submodel: string;
  buttonDisabled = false;
  modelHelpText: string|null;

  public startQapter$ = new Subject<QapterStartModel>();

  constructor(public draftEditService: DraftEditService,
              private qapterPrepareModeService: QapterPrepareModeService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.modelHelpText = null;
    //disable button when mandatory data is missing
    let hasEnoughVehicleData = this.fabrikat != null && this.model != null;
    if (hasEnoughVehicleData) {
      if (!FabrikatDTO.isKnownByAudatex(this.fabrikat) || !ModelDTO.isKnownByAudatex(this.model)) {
        hasEnoughVehicleData = this.fantomFabrikat != null && this.fantomModel != null;
      }
      this.modelHelpText = this.model.modelHelpText?.header;
    }
    this.buttonDisabled = (this.token == null) || !hasEnoughVehicleData;
  }

  buttonClicked(): void {
    this.spin = true;
    this.draftEditService.save().pipe(
      mergeMap(() => this.qapterPrepareModeService.prepareDraftMode(this.token)),
      takeUntil(this.unsubscribe$)
    ).subscribe({
      next: (qapterPrepareMode: QapterPrepareModeDTO) => {
        this.startQapter$.next({
          qapterPrepareMode: qapterPrepareMode,
          returnURL: new URLBuilder().constructReturnURL('draft/edit/' + this.token + '#damages')
        });
        this.spin = false;
      },
      error: () => this.spin = false
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
