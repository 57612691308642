import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {FormSaver} from '../../../shared/service/core/form-saver.service';
import {ForsiReportKeyPipe} from '../../../shared/pipe/forsi-report-key.pipe';
import {ForsiReportKeyShortPipe} from '../../../shared/pipe/forsi-report-key-short.pipe';
import {ContextMenu, ContextMenuItem} from '../../../shared/ui/context-menu/context-menu.model';
import {BootstrapGrowlService} from '../../../shared/ui/ngx-bootstrap-growl/bootstrap-growl.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PrincipalService} from '../../../shared/service/auth/principal.service';
import {DraftService} from '../../service/draft.service';
import {DraftEditService} from '../draft-edit.service';
import {UIUtils} from '../../../shared/utils/ui-utils';
import {DraftEditSkavuFlowComponent} from '../draft-edit-skavu-flow.component';
import {AttachmentUseCase} from '../../../shared/model/attachment-use-case.model';
import {Subject} from 'rxjs';
import {mergeMap, takeUntil} from 'rxjs/operators';
import {ClientNavigationService} from '../../../shared/service/client-navigation.service';
import {VideoRequestResponseState} from '../../../shared/ui/attachment/video-request/model/video-request-state.model';
import {VideoRequestObserverService} from '../../../shared/ui/attachment/video-request/video-request-observer.service';
import {CopyDraftModalService} from '../../service/copy-draft.modal.service';

@Component({
  selector: 'lc-vk-camptaks',
  templateUrl: './vk-camptaks.component.html',
  styleUrls: [
    '../../draft.scss', '../draft-edit.scss', './vk-camptaks.scss'
  ],
  providers: [FormSaver],
})
export class VkCamptaksComponent extends DraftEditSkavuFlowComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  listeTypeId: number;
  isLoading = true;
  attachmentUseCase = AttachmentUseCase.Draft;
  isAgreementsLoading = true;
  missingVideoRequest = false;

  constructor(draftEditService: DraftEditService,
              clientStateService: DraftService,
              principal: PrincipalService,
              reportKeyPipe: ForsiReportKeyPipe,
              reportKeyShortPipe: ForsiReportKeyShortPipe,
              formSaver: FormSaver,
              router: Router,
              route: ActivatedRoute,
              bootstrapGrowlService: BootstrapGrowlService,
              el: ElementRef,
              clientNavigationService: ClientNavigationService,
              private videoRequestObserverService: VideoRequestObserverService,
              copyDraftModalService: CopyDraftModalService) {
    super(formSaver, reportKeyPipe, reportKeyShortPipe, clientStateService, draftEditService, principal, router, route, bootstrapGrowlService, el, clientNavigationService, copyDraftModalService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    console.log('VkCamptaksComponent.ngOnInit...');
    const clientState$ = this.route.params.pipe(mergeMap(params => this.draftEditService.getClientStateObservable(params.token)));

    this.isLoading = true;
    clientState$.pipe(takeUntil(this.unsubscribe$)).subscribe(clientState => {
      this.details.token = clientState.token;
      const fragment = this.route.snapshot.fragment;
      this.initializeWithClientState(clientState);
      this.possibleNavigateToFragmentUrlPart(fragment);
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      console.log('Error preparing vk-camptaks flow', error);
      this.router.navigate(['/draft']);
    });

    clientState$.pipe(
      mergeMap(cs => this.videoRequestObserverService.clientStateResponseState$(cs.id)),
      takeUntil(this.unsubscribe$)
    ).subscribe(responseState => {
      this.missingVideoRequest = responseState === VideoRequestResponseState.REQUESTED;
    });
  }

  setupContextMenu(): void {
    this.contextMenu = new ContextMenu();
    const uiUtils = new UIUtils();
    this.contextMenu.addItem(new ContextMenuItem('fa-vcard-o', 'Stamdata', 'masterdata',
      (item) => {
        uiUtils.scrollToElementId(['masterdata']);
      }, () => !this.formSaver.isFormsValid(['masterData', 'forsikringInfo', 'skadelidteInfo'])));

    this.contextMenu.addItem(new ContextMenuItem('fa-handshake-o', 'Prisaftaler', 'agreements',
      (item) => {
        uiUtils.scrollToElementId(['agreements']);
      }, () => !(this.isAgreementsLoading || this.formSaver.isFormsValid(['agreements']))));
    this.contextMenu.addItem(new ContextMenuItem('fa-car', 'Skader', 'positions',
      (item) => {
        uiUtils.scrollToElementId(['positions', 'damagedescription']);
      }, () => {
        const zoneDataProvided = this.details.damageDescription.zoneIsValid();
        return !(this.formSaver.isFormsValid(['positions', 'damagedescription']) && zoneDataProvided);
      }));
    this.contextMenu.addItem(new ContextMenuItem('fa-image', 'Bilag', 'attachments',
      (item) => {
        uiUtils.scrollToElementId(['attachments']);
      }, () => this.missingVideoRequest));
  }

  setupDraftActionMenu(): void {
    this.draftMenu.addItem(this.createCopyDraftMenuItem());
    this.draftMenu.addItem(this.createDmrLookupMenuItem());
    const menuItem = this.createCalculateActionMenu();
    menuItem.disabledCallback = () => false;
    this.draftMenu.addItem(menuItem);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
