import {DamageDescription} from '../../draft/model/damage-description.model';
import {StringUtils} from '../utils/string-utils';

export class DamageDescriptionDTO {
  skadeType: string;
  andreSkader: string;
  zone1: boolean;
  zone2: boolean;
  zone3: boolean;
  zone4: boolean;
  zone5: boolean;
  zone6: boolean;
  zone7: boolean;
  zone8: boolean;
  zone9: boolean;
  zoneB: boolean;
  zoneF: boolean;
  zoneK: boolean;
  zoneM: boolean;
  zoneO: boolean;
  zoneT: boolean;
  zoneU: boolean;

  public static createDamageDescriptionDTO(damageDescription: DamageDescription): DamageDescriptionDTO {
    const result: DamageDescriptionDTO = new DamageDescriptionDTO();
    result.skadeType = damageDescription.skadeType;
    result.andreSkader = damageDescription.andreSkader;
    result.zone1 = damageDescription.zone1;
    result.zone2 = damageDescription.zone2;
    result.zone3 = damageDescription.zone3;
    result.zone4 = damageDescription.zone4;
    result.zone5 = damageDescription.zone5;
    result.zone6 = damageDescription.zone6;
    result.zone7 = damageDescription.zone7;
    result.zone8 = damageDescription.zone8;
    result.zone9 = damageDescription.zone9;
    result.zone9 = damageDescription.zone9;
    result.zoneB = damageDescription.zoneB;
    result.zoneF = damageDescription.zoneF;
    result.zoneK = damageDescription.zoneK;
    result.zoneM = damageDescription.zoneM;
    result.zoneO = damageDescription.zoneO;
    result.zoneT = damageDescription.zoneT;
    result.zoneU = damageDescription.zoneU;
    return result;
  }

  public static createDamageDescription(damageDescriptionDTO: DamageDescriptionDTO): DamageDescription {
    const result = new DamageDescription();

    if (damageDescriptionDTO) {
      if (StringUtils.isNotEmpty(damageDescriptionDTO.skadeType)) {
        result.skadeType = damageDescriptionDTO.skadeType;
      }

      result.andreSkader = damageDescriptionDTO.andreSkader;
      if (result.andreSkader === null) {
        result.andreSkader = '';
      }

      result.zone1 = damageDescriptionDTO.zone1;
      result.zone2 = damageDescriptionDTO.zone2;
      result.zone3 = damageDescriptionDTO.zone3;
      result.zone4 = damageDescriptionDTO.zone4;
      result.zone5 = damageDescriptionDTO.zone5;
      result.zone6 = damageDescriptionDTO.zone6;
      result.zone7 = damageDescriptionDTO.zone7;
      result.zone8 = damageDescriptionDTO.zone8;
      result.zone9 = damageDescriptionDTO.zone9;
      result.zoneB = damageDescriptionDTO.zoneB;
      result.zoneF = damageDescriptionDTO.zoneF;
      result.zoneK = damageDescriptionDTO.zoneK;
      result.zoneM = damageDescriptionDTO.zoneM;
      result.zoneO = damageDescriptionDTO.zoneO;
      result.zoneT = damageDescriptionDTO.zoneT;
      result.zoneU = damageDescriptionDTO.zoneU;
    }

    return result
  }
}
