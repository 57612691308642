import {SearchTypeEnum} from '../shared/ui/search/search-type.enum';
import {AssessorCasesSearchProps} from '../shared/ui/search/assessor-cases-search-props.model';
import {StorageService} from '../shared/service/storage.service';
import {SearchService} from './search.service';
import {Injectable} from '@angular/core';
import {ReportKey} from './reportkey.model';
import {CaseSearchResultModel} from './case-search-result.model';
import {isNullOrUndefined} from '../shared/utils/object-utils';



@Injectable()
export class CaseSearchResultHighlighter {


  public constructor(private searchService: SearchService,
                     private storage: StorageService) {
  }


  public highlightRegnr(regnrFromResult: string): boolean {
    if (this.searchService.getCurrentSearchType().getCode() === SearchTypeEnum.Regnr) {
      const searchObj: any = this.storage.getSearchConfig(this.searchService.getCurrentSearchType());
      const regnr = searchObj as string;
      // console.log("'" + regnr + "'" + ", '"+ regnrFromResult + "'");
      return regnr && regnrFromResult.toUpperCase().includes(regnr.toUpperCase());
    } else if (this.searchService.getCurrentSearchType().getCode() === SearchTypeEnum.AssessorsCases) {
      const searchObj: any = this.storage.getSearchConfig(this.searchService.getCurrentSearchType());
      const searchProps = searchObj as AssessorCasesSearchProps;
      // console.log("'" + searchProps.regnr + "'" + ", '"+ regnrFromResult + "'");
      return searchProps && searchProps.regnr && regnrFromResult.toUpperCase().includes(searchProps.regnr.toUpperCase()) ;
    }
    return false;
  }

  public highlightSearchReportKey(key: string, vkRappKey: ReportKey, taksRappKey: ReportKey): boolean {
    if (this.searchService.getCurrentSearchType().getCode() === SearchTypeEnum.VKNr && vkRappKey.reportNr && key.trim() === vkRappKey.getReportWidePk()) {
      return true;
    } else if ( this.searchService.getCurrentSearchType().getCode() === SearchTypeEnum.RapportNr && taksRappKey.reportNr && key.trim() === taksRappKey.getReportWidePk() ) {
      return true;
    }
    return false;
  }

  public highlightDate(): boolean {
    const searchObj: any = this.storage.getSearchConfig(this.searchService.getCurrentSearchType());
    const searchProps = searchObj as AssessorCasesSearchProps;
    if (this.searchService.getCurrentSearchType().getCode() === SearchTypeEnum.AssessorsCases && searchProps) {
      if (searchProps.ngFromDate || searchProps.ngToDate) {
        return true;
      }
    } return false;
  }

  public highlightVin(vin: string): boolean {
    const searchObj: any = this.storage.getSearchConfig(this.searchService.getCurrentSearchType());
    const searchProps = searchObj as AssessorCasesSearchProps;
    if (this.searchService.getCurrentSearchType().getCode() === SearchTypeEnum.AssessorsCases && searchProps) {
      return searchProps.vin && vin.trim() === searchProps.vin.trim();
    }
  }

  public highlightVkNrOrAssessorNr(reportKey: string, vkRappKey: ReportKey, taksRappKey: ReportKey): boolean {
    const searchObj: any = this.storage.getSearchConfig(this.searchService.getCurrentSearchType());
    const searchProps = searchObj as AssessorCasesSearchProps;
    if (this.searchService.getCurrentSearchType().getCode() === SearchTypeEnum.AssessorsCases && searchProps) {
      if (reportKey.startsWith('RP')) {
        return this.getVkOrAssessorNr(reportKey) === searchProps.vkNummer;
      } else {
        return this.getVkOrAssessorNr(reportKey) === ('00000' + searchProps.taksUser.atClientNumber).slice(-5);
      }
    } else {
      return this.highlightSearchReportKey(reportKey, vkRappKey, taksRappKey);
    }
  }

  public highlightFabrikat(fabrikat: string): boolean {
    const searchObj: any = this.storage.getSearchConfig(this.searchService.getCurrentSearchType());
    const searchProps = searchObj as AssessorCasesSearchProps;
    if (!isNullOrUndefined(searchProps)) {
      if ( searchProps && searchProps.fabrikat && fabrikat && fabrikat.toUpperCase().includes(searchProps.fabrikat.toUpperCase()) ) {
        return true;
      }
    }
    return false;
  }

  public highlightModel(model: string, kass: CaseSearchResultModel): boolean {
    const searchObj: any = this.storage.getSearchConfig(this.searchService.getCurrentSearchType());
    const searchProps = searchObj as AssessorCasesSearchProps;
    if (!isNullOrUndefined(searchProps)) {
      if ( searchProps && searchProps.model && model && model.toUpperCase().includes(searchProps.model.toUpperCase()) ) {
        return true;
      }
    }
    return false;
  }

  private getVkOrAssessorNr(reportKey: string): string {
    const rKey = new ReportKey();
    rKey.setReportWidePk(reportKey);
    return rKey.tvNr;
  }

}
