import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import * as pbi from 'powerbi-client';
import {PowerbiConf} from './model/powerbi.conf.model';
import {StatisticService} from './statistic.service';
import {combineLatest, Subject} from 'rxjs';
import {Router} from '@angular/router';
import {NgbAccordion, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {RoleUserPopupComponent} from './modal/role-user-popup.component';
import {AutostatRoleAndUserDTO} from './dto/autostat-role-and-user.dto.model';
import {PrincipalService} from '../shared';
import {EmbedUrlDTO} from './dto/embed-url.dto.model';
import {ReportidDTO} from './dto/reportid.dto.model';
import {AccessTokenDTO} from './dto/access-token.dto.model';
import {takeUntil} from 'rxjs/operators';
import {NotificationService} from '../shared/service/notification.service';

@Component({
  templateUrl: './statistic.component.html',
  providers: [StatisticService],
  styleUrls: [
    'statistic.scss'
  ]
})

export class StatisticComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  @ViewChild('accordion') accordion: NgbAccordion;
  configuration = new PowerbiConf();
  reportContainer: HTMLElement;

  constructor(public statisticService: StatisticService,
              private router: Router,
              private principal: PrincipalService,
              public notificationService: NotificationService,
              private modalService: NgbModal) {
  }

  ngOnInit(): void {
    if (!this.isAF()) {
      this.hentRapport();
    }
  }

  onPickRoleUserClicked(): void {
    const roleUserModalRef = this.modalService.open(RoleUserPopupComponent);
    roleUserModalRef.componentInstance.choiceMadeEvent.pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
      roleUserModalRef.componentInstance.choiceMadeEvent.unsubscribe();
      this.onHentRapportCLick(data);
    });
  }

  isAF(): boolean {
    return this.principal.isAF();
  }

  onHentRapportCLick(userAndRole: AutostatRoleAndUserDTO): void {
    if (userAndRole.role && userAndRole.user) {
      const embedUrlDTOObs = this.statisticService.getEmbedUrlForRole(userAndRole.role.name);
      const reportIDDTOObs = this.statisticService.getReportIDForRole(userAndRole.role.name);
      const accessTokenDTOObs = this.statisticService.getAccessTokenForRoleAndUser(userAndRole.role.name, userAndRole.user.name);

      combineLatest([embedUrlDTOObs, reportIDDTOObs, accessTokenDTOObs]).pipe(takeUntil(this.unsubscribe$)).subscribe(([embedUrlDTO, reportIDDTO, accessTokenDTO]) => {
        this.fillReportContainer(embedUrlDTO, reportIDDTO, accessTokenDTO);

      }, error => {
        console.log('Kan ikke tilgå statistik siden');
        this.router.navigate(['/draft']);
      });
    }
  }

  hentRapport(): void {
    const embedUrlDTOObs = this.statisticService.getEmbedUrl();
    const reportIDDTOObs = this.statisticService.getReportID();
    const accessTokenDTOObs = this.statisticService.getAccessToken();

    combineLatest([embedUrlDTOObs, reportIDDTOObs, accessTokenDTOObs]).pipe(takeUntil(this.unsubscribe$)).subscribe(([embedUrlDTO, reportIDDTO, accessTokenDTO]) => {
      this.fillReportContainer(embedUrlDTO, reportIDDTO, accessTokenDTO);

    }, error => {
      console.log('Kan ikke tilgå statistik siden');
      this.router.navigate(['/draft']);
    });
  }

  private fillReportContainer(embedUrlDTO: EmbedUrlDTO, reportIDDTO: ReportidDTO, accessTokenDTO: AccessTokenDTO): void {
    this.configuration.setAccessToken(accessTokenDTO.accessToken);
    this.configuration.setEmbedUrl(embedUrlDTO.embedUrl);
    this.configuration.setId(reportIDDTO.reportID);

    this.reportContainer = document.getElementById('embedContainer') as HTMLElement;
    const powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
    powerbi.reset(this.reportContainer);
    powerbi.embed(this.reportContainer, this.configuration.getConf());

    const iframe = this.reportContainer.getElementsByTagName('iframe').item(0);
    if (iframe) {
      iframe.style['border'] = '0';
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

}
