<fieldset ngForm="f" #f="ngForm">
    <div class="card">
        <div class="card-body">
            <div class="row pb-3">
                <label class="col-form-label col-4">Momsoplysninger</label>
                <div class="col-8">
                    <div class="btn-group">
                        <input type="radio" value="T" [(ngModel)]="details.vatInformation" name="vatInformation" class="btn-check" id="vat-tillaeg"/>
                        <label class="btn btn-secondary btn-sm" for="vat-tillaeg">Tillæg gældende moms</label>
                        <input type="radio" value="I" [(ngModel)]="details.vatInformation" name="vatInformation" class="btn-check" id="vat-inkl">
                        <label class="btn btn-secondary btn-sm" for="vat-inkl">Beløb inkl. moms</label>
                        <input type="radio" value="U" [(ngModel)]="details.vatInformation" name="vatInformation" class="btn-check" id="vat-undlad">
                        <label class="btn btn-secondary btn-sm" for="vat-undlad">Undlad at tillægge moms</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fieldset>
