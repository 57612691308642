import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PrincipalService} from 'app/shared/index';
import {BootstrapGrowlService} from 'app/shared/ui/ngx-bootstrap-growl/index';
import {SettingsService} from '../../../shared/service/settings.service';
import {PostboxConfigurationDTO, SelectedPostboxDTO} from 'app/settings/model/postbox-configuration-dto.model';
import {NgForm} from '@angular/forms';
import {BootstrapAlertType} from 'app/shared/ui/ngx-bootstrap-growl/bootstrap-alert-type.enum';
import {AccountService} from '../../../shared/service/account.service';
import {UserDTO} from '../../../shared/dto/user-dto.model';
import {combineLatest, Subject} from 'rxjs';
import {AppRole} from '../../../shared/service/auth/app-roles.model';
import {takeUntil} from 'rxjs/operators';

@Component({
  templateUrl: './settings.postbox.component.html',
  styleUrls: [
    '../../settings.scss'
  ],
  selector: 'lc-settings-postbox'
})
export class SettingsPostboxComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  @ViewChild('f') public form: NgForm;

  private NUMBER_OF_SUBSTITUTES = 6;
  private NUMBER_OF_MAILBOXES = 6;
  isLoading: boolean;
  postboxConfiguration: PostboxConfigurationDTO = new PostboxConfigurationDTO();
  commonPostboxes: UserDTO[];
  personalReplacementPostboxes: UserDTO[];

  constructor(private principal: PrincipalService,
              private settingsService: SettingsService,
              private accountService: AccountService,
              private bootstrapGrowlService: BootstrapGrowlService) {
  }

  ngOnInit(): void {
    this.reset();
  }

  reset(): void {
    this.isLoading = true;
    const isTaksManager = this.principal.hasAnyAuthorityDirect([AppRole.TAKS_MANAGER]);
    combineLatest([this.settingsService.getPostboxConfiguration(),
      this.accountService.getColleagues(true, true),
      this.accountService.getColleagues(isTaksManager, isTaksManager)]
    ).pipe(takeUntil(this.unsubscribe$)).subscribe(([configuration, commonPostboxes, personalReplacementPostboxes]) => {
      this.updateConfigurationModel(configuration);
      this.commonPostboxes = commonPostboxes;
      this.personalReplacementPostboxes = personalReplacementPostboxes;
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
    });
  }

  save(): void {
    this.isLoading = true;
    this.settingsService.savePostboxConfiguration(this.postboxConfiguration)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(postboxConfiguration => {
        // we have to do a reset to make sure everyhing is sortet correctly in db
        this.reset();
        this.bootstrapGrowlService.addAlert('Afløsere opdateret', BootstrapAlertType.SUCCESS);
      }, error => {
        this.isLoading = false;
      });
  }

  toogleSubstitute(substitutePostbox: SelectedPostboxDTO): void {
    if (substitutePostbox.active) {
      //see if any common postboxes should be deselected
      this.postboxConfiguration.commonMailboxes.forEach((mailbox) => {
        this.possibleDeselect(substitutePostbox, mailbox);
      })
    }
  }

  toogleCommon(commonPostbox: SelectedPostboxDTO): void {
    if (commonPostbox.active) {
      //see if any common postboxes should be deselected
      this.postboxConfiguration.substitutes.forEach((mailbox) => {
        this.possibleDeselect(commonPostbox, mailbox);
      })
    }
  }

  possibleDeselect(mainPostbox: SelectedPostboxDTO, secondaryPostbox: SelectedPostboxDTO): void {
    if (mainPostbox.active && mainPostbox.username === secondaryPostbox.username) {
      secondaryPostbox.active = false;
    }
  }

  private updateConfigurationModel(newConfiguration: PostboxConfigurationDTO): void {
    while (newConfiguration.substitutes.length < this.NUMBER_OF_SUBSTITUTES) {
      const sortOrder = newConfiguration.substitutes.length;
      newConfiguration.substitutes.push(new SelectedPostboxDTO(sortOrder + 1))
    }
    while (newConfiguration.commonMailboxes.length < this.NUMBER_OF_MAILBOXES) {
      const sortOrder = newConfiguration.substitutes.length;
      newConfiguration.commonMailboxes.push(new SelectedPostboxDTO(sortOrder + 1))
    }
    this.postboxConfiguration = newConfiguration;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}

