import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ClientStateDetails} from '../../../model/client-state-details.model';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {FormSaver} from '../../../../shared/service/core/form-saver.service';
import {NgForm} from '@angular/forms';
import {Validatable} from '../validatable.interface';
import {DateUtils} from '../../../../shared/utils/date-utils';
import {PrincipalService} from '../../../../shared/service/auth/principal.service';
import {DateService} from '../../../../shared/service/date.service';

@Component({
  selector: 'lc-draft-misc',
  templateUrl: './draft-misc.component.html',
  styleUrls: ['../../draft-common.scss']
})
export class DraftMiscComponent implements Validatable, OnInit {
  skadedatoDP: NgbDateStruct;

  @Input() formId: string;
  @Input() details: ClientStateDetails;
  @Output() onDamageDateChanged = new EventEmitter<NgbDateStruct>();

  @ViewChild('f', {static: true}) public cform: NgForm;

  constructor(private formSaver: FormSaver,
              private principal: PrincipalService,
              public dateService: DateService) {
  }

  ngOnInit(): void {
    this.formSaver.registerForm(this.formId, this.cform);
  }

  isValid(): boolean {
    return this.cform.form.valid;
  }

  fiveYearsPriorMinDateSelection(): any {
    const date = DateUtils.getNgbDateStructToday();
    date.year = date.year - 5;
    return date;
  }

  pastOrPresentMaxDateSelection(): any {
    return DateUtils.getNgbDateStructToday();
  }

  inspectionDateRequired(): boolean {
    return this.principal.isVK();
  }

  setToDay(): void {
    this.details.skadeDato = this.dateService.todayNgbDateStruct();
    this.onDamageDateChanged.emit(this.details.skadeDato);
  }
}
