<div class="text-center" *ngIf="!broadcasts">
    <i class="fa fa-circle-o-notch fa-spin fop-page-spinner"></i>
</div>
<div *ngIf="broadcasts" class="no-focus" tabindex="0" [lcAutofocus]>
    <div class="card">
        <div class="card-body">
            <lc-broadcast-list [broadcasts]="broadcasts"
                               (deleteBroadcastEmitter)="deleteBroadcast($event)"></lc-broadcast-list>
        </div>
    </div>
</div>

