import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ClientStateDetails} from '../../../model/client-state-details.model';
import {NgForm} from '@angular/forms';
import {FormSaver} from '../../../../shared/service/core/form-saver.service';
import {CompanyDetailsDTO} from '../../../dto/company-details-dto.model';
import {DraftService} from '../../../service/draft.service';
import {AgreementService} from '../../../../shared/service/agreement.service';
import {AftaleDTO} from '../../../../shared/dto/aftale-dto.model';
import {ValueTypeDTO} from '../../../../shared/dto/value-type-dto.model';
import {PrincipalDTO, PrincipalService} from '../../../../shared';
import {VKStamService} from '../../../../shared/service/vkstam.service';
import {BootstrapAlertType, BootstrapGrowlService} from 'app/shared/ui/ngx-bootstrap-growl';
import {isNotNullOrUndefined, isNullOrUndefined, ObjectUtils} from '../../../../shared/utils/object-utils';
import {StringUtils} from '../../../../shared/utils/string-utils';
import {Validatable} from '../validatable.interface';
import {VkStam} from '../../../../shared/model/vk-stam-model';
import {Specialkode} from '../../../../shared/model/specialkode.model';
import {Prisaftale} from './prisaftale.model';
import {DraftEditService} from '../../draft-edit.service';
import {combineLatest, Subject} from 'rxjs';
import {distinctUntilChanged, filter, takeUntil} from 'rxjs/operators';
import {ClientStateWarningDTO} from '../../../dto/client-state-warning-dto.model';
import {UiDraftComponentClientStateInformation} from '../ui-draft-component-client-state-information.model';
import {RapportType} from '../../../../shared/model/rapport-type.model';
import {ReportCategory} from '../../../../shared/model/report-category.model';
import {ClientStateNonStandardPositionStatusDTO} from '../../../dto/client-state-non-standard-position-status-dto.model';
import {AgreementDocumentDTO} from '../../../../shared/dto/agreement-document-dto.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DraftAgreementDocumentsPopupComponent} from './draft-agreement-documents-popup/draft-agreement-documents-popup.component';
import {VkAgreementsPopupComponent} from '../vk-agreements-popup/vk-agreements-popup.component';
import {ReportService} from '../../../../report/service/report.service';
import {UserDTO} from '../../../../shared/dto/user-dto.model';

@Component({
  selector: 'lc-draft-agreements',
  templateUrl: './draft-agreements.component.html',
  styleUrls: ['./draft-agreements.scss', '../../draft-common.scss']
})
export class DraftAgreementsComponent implements Validatable, OnInit, OnDestroy {
  public static readonly SPECIALCODE_LIMIT = 20;

  private unsubscribe$ = new Subject();
  private _details: ClientStateDetails;
  private agreementDependantProperties: AgreementDependantProperties = null;

  agreementDocuments: AgreementDocumentDTO[];
  uiConfig: AgreementsUIConfig;
  selskaber: CompanyDetailsDTO[];
  selskab: CompanyDetailsDTO = null;
  valueTypes: ValueTypeDTO[] = [];
  availableValueTypes: ValueTypeDTO[] = [];
  newValue: ValueTypeDTO;
  prisaftaler: Prisaftale[] = [];
  prisaftaleAdded = -1;
  principalModel: PrincipalDTO;
  timeAftalepris: number;
  timeAftale: AftaleDTO;
  lakAftalepris: number;
  lakAftale: AftaleDTO;
  isLoading = true;
  aftalerValid = false;
  vknr: number;
  vkstam: VkStam;
  responsibleWorkshopUser: UserDTO;
  isSearchingByVkNr = false;
  vkChange = new Subject<number>();
  clientStateWarning: ClientStateWarningDTO;
  nonStandardPositionStatus: ClientStateNonStandardPositionStatusDTO | null;
  agreementTableColumnClasses = {
    column1: 'col-3 col-lg-2',
    column2: 'col-2 text-end',
    column3: 'col-2 text-end',
    column4: 'col',
    column5: 'col-2 col-xl-1 text-end'
  };

  @Output('isLoading') isLoadingEmitter = new EventEmitter<boolean>(true);
  @ViewChild('f', {static: true}) public cform: NgForm;

  @Input() formId: string;

  private _companyCode: string;

  @Input()
  set companyCode(newCompanyCode: string) {
    if (newCompanyCode !== this._companyCode) {
      this._companyCode = newCompanyCode;
      if (!!this.selskaber) { // change company, only of we have loaded
        this.onSelskabChange(this.selskaber.find(s => s.code === this._companyCode))
      }
    }
  }

  @Input()
  set details(details: ClientStateDetails) {
    this._details = details;
  }

  get details(): ClientStateDetails {
    return this._details;
  }

  constructor(private formSaver: FormSaver,
              public clientStateService: DraftService,
              private draftEditService: DraftEditService,
              private agreementService: AgreementService,
              private reportService: ReportService,
              public principalService: PrincipalService,
              private vkStamService: VKStamService,
              private growlService: BootstrapGrowlService,
              protected modalService: NgbModal) {
    this.vkChange
      .pipe(distinctUntilChanged(), takeUntil(this.unsubscribe$))
      .subscribe(vknr => {
        if (this.isLoading) {
          return;
        }
        this.updateVk(vknr);
      });

    this.draftEditService.warning
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(warning => this.clientStateWarning = warning);
  }

  ngOnInit(): void {
    this.formSaver.registerForm(this.formId, this.cform);
    this.uiConfig = new AgreementsUIConfig(this.details);

    const companyDetails$ = this.draftEditService.getCompanyDetailsObservable();
    const valueTypeForDraft$ = this.agreementService.getValueTypesFromAftaleValueType(ReportCategory.getAftaleValueType(this._details.schema));
    const auth$ = this.principalService.getAuthenticationState();
    const nonStandardPositionStatus$ = this.clientStateService.getCalculationInputNonStandardPositionStatus(this._details.token);
    combineLatest([companyDetails$, valueTypeForDraft$, auth$, nonStandardPositionStatus$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([companyDetails, valueTypes, principal, nonStandardPositionStatus]) => {
        if (principal === null) {
          return;
        }
        this.selskaber = companyDetails;
        this.valueTypes = valueTypes;
        this.principalModel = principal;
        this.nonStandardPositionStatus = nonStandardPositionStatus;

        if (this.principalModel.vkStam) {
          this.vknr = this.principalModel.vkStam.vkNr;
        } else {
          if (this._details.vknr) {
            this.vknr = this._details.vknr;
          }
        }

        if (this.details.selskab) {
          this.selskab = this.selskaber.find(s => s.code === this.details.selskab);
        } else {
          if (this.selskaber.length === 1) {
            this.onSelskabChange(this.selskaber[0]);
          }
        }

        if (this.isNotVKFlow() && this.vknr) {
          this.updateVk(this.vknr);
          this.reportService.getWorkshopResponsibleByKey(this._details.proformaOrdreNummer)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(workshopUser => this.responsibleWorkshopUser = workshopUser);
        }

        this.updateAgreementsOnChanges(new AgreementDependantProperties(this.details));
        this.isLoading = false;
        this.isLoadingEmitter.next(false);
      });
    this.draftEditService.clientStateSaved
      .pipe(
        filter(value => value !== null),
        takeUntil(this.unsubscribe$))
      .subscribe((savedClientState: ClientStateDetails) => {
        // I hereby accept subscribing to a client state different than the one from @Input()
        // This is done because agreements must be updated based on properties that must be
        // persisted in the backend. We cannot update based on changed view/modelbound properties alone.
        this.updateAgreementsOnChanges(new AgreementDependantProperties(savedClientState));
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  updateAgreementDocuments(): void {
    if (this.details.selskab && this.vknr) {
      this.clientStateService.getAgreementDocuments(this.details.token)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(docs => this.agreementDocuments = docs);
    } else {
      this.agreementDocuments = [];
    }
  }

  private updateAgreementsOnChanges(newAgreementDependantProperties: AgreementDependantProperties): void {
    let updateAgreements = this.agreementDependantProperties == null || !this.agreementDependantProperties.equals(newAgreementDependantProperties);
    if (this.agreementDependantProperties?.changedFlowType(newAgreementDependantProperties)) {
      updateAgreements = true;
      this.prisaftaler.length = 0;
      this.clearSpecialkoder();
    }
    if (updateAgreements) {
      this.agreementDependantProperties = newAgreementDependantProperties;
      this.updateAgreements();
    }
  }

  public openAgreementOverviewPopup(): void {
    if (this.selskab && this.vknr) {
      this.agreementService.getCompanyAgreementsByType(this.selskab.id, this.details.aftaleArtId).pipe(takeUntil(this.unsubscribe$)).subscribe(agreements => {
        const modalRef = this.modalService.open(VkAgreementsPopupComponent, {size: 'xl', scrollable: true});
        const instance = modalRef.componentInstance as VkAgreementsPopupComponent;
        instance.agreements = agreements;
        instance.title = 'Aftaler';
        instance.body = 'Hello';
      })
    }
  }

  public openAgreementDocumentsPopup(): void {
    const modalRef = this.modalService.open(DraftAgreementDocumentsPopupComponent, {size: 'xl', scrollable: true});
    const instance = modalRef.componentInstance as DraftAgreementDocumentsPopupComponent;
    instance.token = this.details.token;
    instance.agreementDocuments = this.agreementDocuments;
  }

  /**
   * @param agreementsValid - used to avoid the agreements element being removed and added
   * causing a "flash" in the page when adding an agreement.
   */
  private updateAgreements(agreementsValid: boolean = false): void {
    if (this.principalService.isVK() || this.principalService.isTaksator()) {
      this.updateAgreementDocuments();
    }

    this.aftalerValid = agreementsValid;
    if (this.isDataOkForlookup()) {
      this.clientStateService.getAgreements(this.details.token)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(aftaler => {
          //clearing aftaler
          this.clearCurrentAftaleValues();

          // Find først de specialkoder der hører til aftalen.
          if (aftaler != null) {
            aftaler.forEach(aftale => {
              const valueType = this.valueTypes.find(x => x.id === aftale.valueTypeId);
              if (isNullOrUndefined(valueType)) {
                if (aftale.valueTypeId === 0) {
                  this.timeAftalepris = aftale.value;
                  this.timeAftale = aftale;
                } else if (aftale.valueTypeId === 1) {
                  this.lakAftalepris = aftale.value;
                  this.lakAftale = aftale;
                }
              } else {
                aftale.value = valueType.round(aftale.value);
                let specialkode = this.details.specialkoder.find(specialkode => specialkode.kode === valueType.kode);

                if (!specialkode) {
                  specialkode = new Specialkode(valueType.kode, aftale.value, aftale.value, false);
                  specialkode.description = aftale.bemaerkning;
                  this.details.specialkoder.push(specialkode)
                } else {
                  specialkode.description = aftale.bemaerkning;
                }

                specialkode.agreementValue = aftale.value;

                // Hvis værdien er aftaleværdien skal den ikke være sat endnu.
                if (specialkode.value === specialkode.agreementValue) {
                  specialkode.value = null;
                }

                const prisaftale = new Prisaftale();
                prisaftale.specialkode = specialkode;
                prisaftale.aftale = aftale;
                prisaftale.valueType = valueType;
                this.prisaftaler.push(prisaftale);
              }
            });

            // Find de specialkoder der ikke hører til aftalen. (
            this.applyNonAgreementSpecialkoderFromClientState();

            // Hvis værdien ikke er sat, sættes den til aftaleværdien.
            this.prisaftaler.forEach(prisaftale => {
              if (prisaftale.specialkode.value === null) {
                prisaftale.specialkode.value = prisaftale.specialkode.agreementValue;
              }
            });

            this.availableValueTypes = this.getAvailableValueTypes();
            this.aftalerValid = true;
          }
          this.priceAgreementsDeviates();
        }, error => {
          console.error('AftaleService failed');
          return Promise.reject(error.message || error);
        });
    } else if (this.isDataOkForAdHocVkSpecialKoder()) {
      this.clearCurrentAftaleValues();
      this.applyNonAgreementSpecialkoderFromClientState();
      this.availableValueTypes = this.getAvailableValueTypes();
      this.aftalerValid = true;
    } else {
      this.clearSpecialkoder();
      this.clearCurrentAftaleValues();
    }
  }

  private clearCurrentAftaleValues(): void {
    this.timeAftalepris = null;
    this.timeAftale = null;
    this.lakAftalepris = null;
    this.lakAftale = null;
    this.prisaftaler = [];
  }

  private applyNonAgreementSpecialkoderFromClientState(): void {
    this.details.specialkoder.forEach(specialkode => {
      const whitelistedSpecialkodeValueType = this.valueTypes.find(x => specialkode.kode === x.kode);
      //this check is necessary as we could potentially have drafts that already has selected specialkoder for the
      //specific flow which is not allowed.
      const specialkodeIsWhitelisted = !isNullOrUndefined(whitelistedSpecialkodeValueType);
      if (specialkodeIsWhitelisted) {
        if (isNullOrUndefined(specialkode.agreementValue) || !this.isSpecialCodePartOfAgreements(specialkode)) {
          const prisaftale = new Prisaftale();
          specialkode.agreementValue = null;
          prisaftale.specialkode = specialkode;
          prisaftale.valueType = whitelistedSpecialkodeValueType;
          this.prisaftaler.push(prisaftale);
        }
      }
    });
  }

  private isSpecialCodePartOfAgreements(specialCode: Specialkode): boolean {
    if (this.prisaftaler && this.prisaftaler.length > 0) {
      return this.prisaftaler.findIndex(agreement => agreement.specialkode.kode === specialCode.kode) > -1
    }
    return false;
  }

  updateSpecialkode(prisaftale: Prisaftale, value: any): void {
    prisaftale.specialkode.value = ObjectUtils.inputToNumber(value);
    this.priceAgreementsDeviates();
  }

  updateTimepris(value: string): void {
    this.details.timepris = ObjectUtils.inputToNumber(value);
    this.priceAgreementsDeviates();
  }

  updateLakTimepris(value: string): void {
    this.details.lakTimepris = ObjectUtils.inputToNumber(value);
    this.priceAgreementsDeviates();
  }

  resetTimepris(): void {
    this.details.timepris = null;
    this.priceAgreementsDeviates();
  }

  resetLakTimepris(): void {
    this.details.lakTimepris = null;
    this.priceAgreementsDeviates();
  }

  atMax(): boolean {
    const aktiveSpecialkoder = this.details.specialkoder.filter(s => !s.deleted);
    return aktiveSpecialkoder.length >= this.getSpecialCodeNoLimit();
  }

  public getSpecialCodeNoLimit(): number {
    return DraftAgreementsComponent.SPECIALCODE_LIMIT;
  }

  private updateVk(vknr: number): void {
    if (this.isNotVKFlow() && vknr >= 1 && vknr < 100000) {
      this.isSearchingByVkNr = true;
      this.vkStamService.getVaerkstedInfo(vknr)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(vkstam => {
          this.vkstam = vkstam;
          if (this.vkstam) {
            this.details.vknr = vknr;
            this.clearVkDetails();
          } else {
            this.vknr = null;
            this.details.vknr = null;
          }
          this.clientStateWarning = null;
          this.isSearchingByVkNr = false;
          this.formSaver.save(() => {
          }, () => {
          });
        }, () => {
          this.growlService.addAlert('Fejl i værkstedsopslag.', BootstrapAlertType.WARNING);
          this.vknr = this.details.vknr;
          this.isSearchingByVkNr = false;
        });
    } else {
      this.vkstam = null;
      this.vknr = null;
      this.details.vknr = null;
      this.agreementDocuments = [];
    }
  }

  updateVkNr(): void {
    this.prisaftaler.length = 0;
    this.clearSpecialkoder();
    this.vkChange.next(this.vknr);
  }

  clearSpecialkoder(): void {
    this.details.specialkoder.length = 0;
  }

  onSelskabChange(e: CompanyDetailsDTO): void {
    this.clearSpecialkoder();
    this.prisaftaler.length = 0;
    if (e != null) {
      this.selskab = e;
      this.details.selskab = e.code;
    }
    this.formSaver.save(() => {
      this.nonStandardPositionStatus = null;
      this.clientStateService.getCalculationInputNonStandardPositionStatus(this._details.token)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(nonStandardPositionStatus => this.nonStandardPositionStatus = nonStandardPositionStatus);
    }, () => {
    });
  }

  clearVkDetails(): void {
    this.details.adhocVkDetails.name = null;
    this.details.adhocVkDetails.address1 = null;
    this.details.adhocVkDetails.address2 = null;
    this.details.adhocVkDetails.postalcode = null;
    this.details.adhocVkDetails.phoneno = null;
    this.details.adhocVkDetails.email = null;
  }

  deletePrisaftale(prisaftale: Prisaftale): void {
    prisaftale.specialkode.deleted = true;
    this.priceAgreementsDeviates();
  }

  resetPrisaftale(prisaftale: Prisaftale): void {
    prisaftale.specialkode.value = prisaftale.specialkode.agreementValue;
    this.priceAgreementsDeviates();
  }

  undeletePrisaftale(prisaftale: Prisaftale): void {
    if (this.atMax()) {
      this.growlService.addAlert('Der kan maksimalt være 10 aktive prisaftaler', BootstrapAlertType.WARNING);
    } else {
      prisaftale.specialkode.deleted = false;
      this.priceAgreementsDeviates();
    }
  }

  getAvailableValueTypes(): ValueTypeDTO[] {
    const valueTypeDTOS = this.valueTypes.filter(valueType => this.valueTypeAvailable(valueType));
    return valueTypeDTOS.filter(value => value.kode !== 1000 && value.kode !== 1001);
  }

  valueTypeAvailable(valueType: ValueTypeDTO): boolean {
    return this.prisaftaler.findIndex(prisAftale => prisAftale.valueType === valueType) === -1;
  }

  onNewvalueType(valueType: ValueTypeDTO): void {
    if (valueType == null || this.isLoading) {
      return;
    }
    const specialkode = new Specialkode(valueType.kode, null, null, false);
    this.prisaftaleAdded = valueType.kode;
    this.details.specialkoder.push(specialkode);
    this.updateAgreements(true);
    this.priceAgreementsDeviates();
  }

  isVKFlow(): boolean {
    return !this.isNotVKFlow();
  }

  isNotVKFlow(): boolean {
    return this.principalModel && !this.principalModel.vkStam;
  }

  isVkNrRequired(): boolean {
    const isTotalForsvundet = ClientStateDetails.TOTALFORSVUNDET_SKADETYPE === this.details.damageDescription.skadeType;
    return !isTotalForsvundet && isNullOrUndefined(this.details.vknr) && StringUtils.isEmpty(this.details.adhocVkDetails.name);
  }

  /**
   * contains a message in case the current vkstam is either ophoert or has midlertidig stop samarbejde set to true
   */
  public getVKNotOperatingMessage(): string {
    let result: string = null;
    if (!isNullOrUndefined(this.vkstam)) {
      result = this.vkstam.vkNotOperatingMessage;
    }
    return result;
  }

  public vkIsOperating(): boolean {
    return !isNullOrUndefined(this.vkstam) ? this.vkstam.vkIsOperating : true;
  }

  private isDataOkForlookup(): boolean {
    return ObjectUtils.exists(this.vknr) &&
      StringUtils.isNotEmpty(this.details.selskab) &&
      (
        !this.details.isQapterType() ||
        ObjectUtils.exists(this.details.vehicle.fabrikat) &&
        ObjectUtils.exists(this.details.vehicle.model) &&
        StringUtils.isNotEmpty(this.details.vehicle.fabrikat.kode) &&
        StringUtils.isNotEmpty(this.details.vehicle.model?.kode) &&
        ObjectUtils.exists(this.details.vehicle.aargang) && this.details.vehicle.aargang > 1899
      );
  }

  private isDataOkForAdHocVkSpecialKoder(): boolean {
    return StringUtils.isNotEmpty(this.details.selskab) &&
      (ObjectUtils.exists(this.details.vehicle.fabrikat) &&
        ObjectUtils.exists(this.details.vehicle.model) &&
        StringUtils.isNotEmpty(this.details.vehicle.fabrikat.kode) &&
        StringUtils.isNotEmpty(this.details.vehicle.model.kode) || !this.details.isQapterType());
  }

  private priceAgreementsDeviates(): void {
    //Get those prisaftaler that either have different agreementvalues compared to specialkodevalue OR prisaftaler having deleted specialkoder that has an agreement
    const aftale =
      this.prisaftaler.find(pa => (!pa.specialkode.deleted && pa.specialkode.value !== pa.specialkode.agreementValue ||
        pa.specialkode.deleted && !isNullOrUndefined(pa.aftale)
      ));
    const deviate = isNotNullOrUndefined(aftale)
      || this.details.timepris !== null && !Number.isNaN(this.details.timepris) && this.details.timepris !== this.timeAftalepris
      || this.details.lakTimepris !== null && !Number.isNaN(this.details.lakTimepris) && this.details.lakTimepris !== this.lakAftalepris;
    if (deviate) {
      this.agreementService.getPriceAgreementsDeviatesSubject().next(true);
    } else {
      this.agreementService.getPriceAgreementsDeviatesSubject().next(false);
    }
  }

  isValid(): boolean {
    if (this.isLoading) {
      return true;
    }
    return this.cform.form.valid;
  }

  getHasAssessorOrganisationSpecificPositionsTooltip(): string {
    if (this.nonStandardPositionStatus?.hasAssessorOrganisationSpecificPositions) {
      return 'Hvis du ændre forsikringsselskab nu, vil de 1001 og 1002 positioner (NS) som du måske have valgt i Qapter forsvinde, da disse nødvendigvis ikke er er tilgængelige for det nye forsikringsselskab!'
    }
    return null;
  }
}

export class AgreementsUIConfig {
  clientStateInfo: UiDraftComponentClientStateInformation;
  clientState: ClientStateDetails;
  isEditVkNumberVisible: boolean;

  constructor(clientState: ClientStateDetails) {
    this.clientStateInfo = new UiDraftComponentClientStateInformation(clientState);
    this.clientState = clientState;
    this.initEditVkNumberVisible(clientState);
  }

  isAutotaks(): boolean {
    return ReportCategory.AUTOTAKS.equals(this.clientStateInfo.reportType) || ReportCategory.AUTOTAKS_LORRY.equals(this.clientStateInfo.reportType);
  }

  disableEditVkNr(): boolean {
    return this.isAutotaks() && this.clientStateInfo.isOfAny([RapportType.T]) && this.clientState.vkNrOnParentReport;
  }

  disableEditSelskab(): boolean {
    return (this.clientState.companySetByAssessor && !!this.clientState.selskab) || (this.isAutotaks() && this.clientStateInfo.isOfAny([RapportType.T, RapportType.S]));
  }

  private initEditVkNumberVisible(clientState: ClientStateDetails): void {
    this.isEditVkNumberVisible = StringUtils.isEmpty(clientState.proformaOrdreNummer);
    //Check if the report type is G. If that's the case then the workshop number can be changed
    if (!this.isEditVkNumberVisible && clientState && clientState.taksRapportKey) {
      this.isEditVkNumberVisible = (RapportType.extractFrom(clientState.taksRapportKey.trim()).isOfAny([RapportType.G]));
    }
  }
}

export class AgreementDependantProperties {
  vkNr: number;
  selskab: string;
  fabrikatKode: string;
  modelKode: string;
  year: number;
  damageType: string

  constructor(clientState: ClientStateDetails) {
    this.vkNr = clientState.vknr;
    this.selskab = clientState.selskab;
    this.year = clientState.vehicle.aargang;
    this.damageType = clientState.damageDescription?.skadeType;

    if (clientState.isQapterType()) {
      this.fabrikatKode = clientState.vehicle.fabrikat.kode;
      this.modelKode = clientState.vehicle.model?.kode;
    } else {
      this.fabrikatKode = null;
      this.modelKode = null;
    }
  }

  changedFlowType(newPropeties: AgreementDependantProperties): boolean {
    return this.damageType !== newPropeties?.damageType &&
      (ClientStateDetails.GLASSKADE_SKADETYPE === this.damageType || ClientStateDetails.GLASSKADE_SKADETYPE === newPropeties?.damageType);
  }

  equals(agreementDependantProperties: AgreementDependantProperties): boolean {
    return this.vkNr === agreementDependantProperties.vkNr &&
      this.selskab === agreementDependantProperties.selskab &&
      this.fabrikatKode === agreementDependantProperties.fabrikatKode &&
      this.modelKode === agreementDependantProperties.modelKode &&
      this.year === agreementDependantProperties.year;
  }
}
