<ng-template #searchPopup let-c="close" let-d="dismiss">
    <form (submit)="handleSearchCommit()">
        <div class="modal-header pre-size header-style" style="text-align: center">
            <h3 class="text-truncate">
                {{title}}
            </h3>
            <div aria-label="Close" data-dismiss="modal" class="btn-close clickable" (click)="cancelSearch()"></div>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12 text-center pt-4">
                    <div class="row pb-3">
                        <label class="col-3 col-form-label">Tilbud</label>
                        <div class="col-9">
                            <input name="searchVkKey" [(ngModel)]="searchProperties.searchVkKey"
                                   placeholder="Værkstedstilbud" class="form-control"/>
                            <small class="form-text text-muted text-start">

                            </small>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <label class="col-3 col-form-label">Regnr.</label>
                        <div class="col-9">
                            <input name="searchRegNr" [(ngModel)]="searchProperties.searchRegNr" placeholder="Regnr"
                                   class="form-control"/>
                            <small class="form-text text-muted text-start">

                            </small>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <label class="col-3 col-form-label">Stelnr.</label>
                        <div class="col-9">
                            <input name="searchVin" [(ngModel)]="searchProperties.searchVin" placeholder="Stelnr"
                                   class="form-control"/>
                            <small class="form-text text-muted text-start">

                            </small>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <label class="col-3 col-form-label">Rapport</label>
                        <div class="col-9">
                            <input name="searchTaksKey" [(ngModel)]="searchProperties.searchTaksKey"
                                   placeholder="Taksatorrapport" class="form-control"/>
                            <small class="form-text text-muted text-start">

                            </small>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <label class="col-3 col-form-label">Dato</label>
                        <div class="col-9">
                            <input name="searchDato" [(ngModel)]="searchProperties.searchDato" placeholder="Dato"
                                   class="form-control"/>
                            <small class="form-text text-muted text-start">

                            </small>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <label class="col-3 col-form-label">Selskab</label>
                        <div class="col-9">
                            <input name="searchSelskab" [(ngModel)]="searchProperties.searchSelskab"
                                   placeholder="Selskab"
                                   class="form-control"/>
                            <small class="form-text text-muted text-start">

                            </small>
                        </div>
                    </div>
                    <div *ngIf="enableSearchOnState" class="row pb-3">
                        <label class="col-3 col-form-label" for="vkSearchStatus">Status</label>
                        <div class="col-9">
                            <select [(ngModel)]="chosenState" class="col-9 form-select form-select-sm"
                                    id="vkSearchStatus"
                                    name="searchStatus" (change)="onSearchStatusChanged()">
                                <option *ngFor="let chosenState of searchReportStates.getStates()"
                                        [ngValue]="chosenState">
                                    {{chosenState.getLabel()}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn col-6 me-1"
                    [ngClass]="isLoading ? 'btn-danger' : 'btn-primary'">
                <lc-spinner-text [spin]="isLoading"></lc-spinner-text>
                {{getSearchButtonText()}}</button>
        </div>
    </form>
</ng-template>

<div class="row">
    <div class="col-md-12">
        <div class="list-search">
            <div class="list-filtering-container">
                <div class="input-group align-top">
                    <input class="form-control form-control-sm list-filtering" type="search"
                           placeholder="Filter" aria-label="Search" [(ngModel)]="filter"
                           (ngModelChange)="onFilteringChanged.emit(filter)">
                </div>
            </div>
            <div class="search-criterias">
                <div *ngIf="searchFieldNotEmpty('searchVkKey')" class="badge bg-primary p-2 m-1"
                     style="font-size: 0.8rem;">
                    VK: {{currentSearchProperties.searchVkKey | uppercase}} <i
                    class="fa fa-close remove-search-criteria" (click)="clearSearchField('searchVkKey')"></i>
                </div>
                <div *ngIf="searchFieldNotEmpty('searchTaksKey')" class="badge bg-primary p-2 m-1"
                     style="font-size: 0.8rem;">
                    Taks: {{currentSearchProperties.searchTaksKey | uppercase}} <i
                    class="fa fa-close remove-search-criteria" (click)="clearSearchField('searchTaksKey')"></i>
                </div>
                <div *ngIf="searchFieldNotEmpty('searchSelskab')" class="badge bg-primary p-2 m-1"
                     style="font-size: 0.8rem;">
                    Selskab: {{currentSearchProperties.searchSelskab | uppercase}} <i
                    class="fa fa-close remove-search-criteria" (click)="clearSearchField('searchSelskab')"></i>
                </div>
                <div *ngIf="searchFieldNotEmpty('searchDato')" class="badge bg-primary p-2 m-1"
                     style="font-size: 0.8rem;">
                    Dato: {{currentSearchProperties.searchDato | uppercase}} <i
                    class="fa fa-close remove-search-criteria" (click)="clearSearchField('searchDato')"></i>
                </div>
                <div *ngIf="searchFieldNotEmpty('searchRegNr')" class="badge bg-primary p-2 m-1"
                     style="font-size: 0.8rem;">
                    Regnr: {{currentSearchProperties.searchRegNr | uppercase}} <i
                    class="fa fa-close remove-search-criteria" (click)="clearSearchField('searchRegNr')"></i>
                </div>
                <div *ngIf="searchFieldNotEmpty('searchVin')" class="badge bg-primary p-2 m-1"
                     style="font-size: 0.8rem;">
                    Stelnr: {{currentSearchProperties.searchVin | uppercase}} <i
                    class="fa fa-close remove-search-criteria" (click)="clearSearchField('searchVin')"></i>
                </div>
                <div *ngIf="searchFieldNotEmpty('searchState')" class="badge bg-primary p-2 m-1"
                     style="font-size: 0.8rem;">
                    Status: {{currentSearchProperties.searchState | uppercase}} <i
                    class="fa fa-close remove-search-criteria" (click)="clearSearchField('searchState')"></i>
                </div>
                <div *ngIf="searchLimitMessage && searchLimitMessage.length > 0" class="badge badge-warning p-2 m-1"
                     style="font-size: 0.8rem;">
                    {{searchLimitMessage}}
                </div>
            </div>
            <div class="input-group-addon clickable pull-right pt-1 pb-1 mt-1 m-1"
                 (click)="openSearchDialog(searchPopup)">
                <i class="fa fa-search fa-2x"></i>
            </div>
            <div class="input-group-addon clickable pull-right pt-1 pb-1 mt-1 m-1" (click)="onRefresh.emit()">
                <i class="fa fa-refresh fa-2x"
                   [ngClass]="{'fa-spin': isLoading, 'fa-spin-stop-ie-hack': !isLoading}"></i>
            </div>
        </div>
    </div>
</div>
