import {Pipe, PipeTransform} from '@angular/core'

@Pipe({
  name: 'lcForsiReportKey'
})
export class ForsiReportKeyPipe implements PipeTransform {
  transform(reportKey: string, args?: any): any {
    if (reportKey) {
      return reportKey.replace(/(\w{2})(\d{5})(\d{2})(\d{4})(\w{1})?/g, '$1 $2 $3 $4 $5');
    }

    return null;
  }
}
