<div class="forsi-home w-100 h-100">
    <div class="forsi-home-overlay w-100 h-100">
        <div class="center-box container">
            <div class="border border-primary rounded-1 border-opacity-25 p-4 mx-5">
                <div class="row mb-5 text-center">
                    <div class="mx-auto">
                        <img src="/content/images/Autotaks_logo.svg" style="width: 10rem;">
                    </div>
                </div>
                <div class="row text-center">
                    <div class="col">
                        Dit nye login er ved at blive aktiveret!
                    </div>
                </div>
            </div>
            <div class="text-center pt-4">
                <a href="https://itsupport.fogp.dk/app/submit-ticket?entityTypeId=281&ticketTypeId=97" target="_blank"
                   class="py-2 px-3 align-middle d-inline-block">Support</a>
                <div class="vr align-middle"></div>
                <a href="https://www.linkedin.com/company/autotaks" target="_blank" class="py-2 px-3 align-middle d-inline-block">Nyheder</a>
                <div class="vr align-middle"></div>
                <a href="https://www.forsikringogpension.dk/services/autotaks/udbydere-og-support/" target="_blank"
                   class="py-2 px-3 align-middle d-inline-block">Udbydere</a>
                <div class="vr align-middle"></div>
                <a href="https://www.forsikringogpension.dk/services/autotaks/" target="_blank"
                   class="py-2 px-3 align-middle d-inline-block">Autotaks.dk</a>
            </div>
        </div>
    </div>
</div>
