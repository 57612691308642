import {Component, OnDestroy, OnInit} from '@angular/core';
import {ReportViewerViewModel} from './report-viewer.view-model';
import {PrincipalService} from '../../shared';
import {ActivatedRoute, Router} from '@angular/router';
import {ReportService} from '../service/report.service';
import {CopyReportModalService} from '../ui/copy-report/copy-report-modal.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PrintComponent} from 'app/shared/modals/print/print.component';
import {CombinedPrintModel} from '../../shared/ui/combined-print-viewer/combined-print.model';
import {ClientNavigationService} from '../../shared/service/client-navigation.service';
import {StringUtils} from '../../shared/utils/string-utils';
import {BootstrapAlertType, BootstrapGrowlService} from '../../shared/ui/ngx-bootstrap-growl';
import {AttachmentApprovalModalService} from '../ui/attachment-approval/attachment-approval-modal.service';
import {TemporaryAttachmentApprovalModel} from '../../shared/model/temporary-attachment-approval.model';
import {from, of, Subject} from 'rxjs';
import {mergeMap, takeUntil} from 'rxjs/operators';
import {CreateMessageDTO} from '../../shared/ui/message/dto/create-message-dto.model';
import {RapportType} from '../../shared/model/rapport-type.model';
import {QapterPrepareModeService} from '../../shared/service/qapter-prepare-mode.service';
import {ReportAcceptRespondPopupComponent} from './report-accept-respond-popup/report-accept-respond-popup.component';
import {UserType} from '../../shared/dto/user-type.model';
import {ReportAcceptStatus} from '../model/report-accept-status';
import {UpdateReportAcceptStatusDTO} from '../model/update-report-accept-status-dto.model';
import {VideoRequestObserverService} from '../../shared/ui/attachment/video-request/video-request-observer.service';
import {QapterStartModel} from '../../shared/ui/qapter/qapter-start/qapter-start.component';
import URLBuilder from '../../shared/utils/url-builder';
import {TextLibraryTypeEnum} from '../../shared/service/text-library-type.enum';
import {LcActionMenuItem} from '../../shared/ui/bottombar/lc-action-menu.model';

@Component({
  templateUrl: './report-viewer.component.html',
  styleUrls: ['./report-viewer.scss']
})
export class ReportViewerComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  viewmodel: ReportViewerViewModel;
  qapterReturnUrl: string;
  sendMessageEnabled = false;
  public startQapter$ = new Subject<QapterStartModel>();
  textLibraryType = TextLibraryTypeEnum.BESKED;

  constructor(private principal: PrincipalService,
              private qapterPrepareModeService: QapterPrepareModeService,
              private route: ActivatedRoute,
              private router: Router,
              private reportService: ReportService,
              private modalService: NgbModal,
              private navigation: ClientNavigationService,
              private bootstrapGrowlService: BootstrapGrowlService,
              private attachmentApprovalModalService: AttachmentApprovalModalService,
              private copyReportModalService: CopyReportModalService,
              private videoRequestObserverService: VideoRequestObserverService) {
  }

  ngOnInit(): void {
    this.viewmodel = new ReportViewerViewModel(this.reportService, this.videoRequestObserverService, this.modalService, this.principal, this.principal.isTaksator(), this.unsubscribe$);
    this.route.parent.params.pipe(takeUntil(this.unsubscribe$)).subscribe(params => {
      this.reportService.getReport(params.reportId).pipe(takeUntil(this.unsubscribe$))
        .subscribe(report => {
          const isVaerkstedReport = report.key === report.vkReportKey;

          if (isVaerkstedReport) {
            this.viewmodel.initTabs(report, report.key, null);
          } else {
            this.viewmodel.initTabs(report, report.vkReportKey, report.key);
          }

          this.viewmodel.diffCallback = () => {
            if (isVaerkstedReport) {
              this.router.navigate(['report/diff', report.id, report.key, '']);
            } else {
              this.router.navigate(['report/diff', report.id, report.vkReportKey, report.key]);
            }
          };

          this.viewmodel.qapterCallback = (qapterMenuItem: LcActionMenuItem, rappnr) => this.didClickQapter(qapterMenuItem, rappnr);

          this.viewmodel.deliveryNoteCallBack = () => {
            this.router.navigate(['deliverynote'], {relativeTo: this.route});
          };

          this.viewmodel.newReportCallback = () => {
            this.router.navigate(['new'], {relativeTo: this.route});
          };

          this.viewmodel.reportAcceptResponseCallback = () => {
            const reportAccept = this.viewmodel.reportInfo.reportAccept;

            if (reportAccept === ReportAcceptStatus.REQUESTED) {
              const modalRef = this.modalService.open(ReportAcceptRespondPopupComponent);
              const componentInstance = modalRef.componentInstance as ReportAcceptRespondPopupComponent;
              componentInstance.userType = UserType.VK;
              from(modalRef.result).pipe(
                mergeMap((updateReportAcceptStatusDTO) => this.reportService.updateReportAcceptStatus(this.viewmodel.report.id, updateReportAcceptStatusDTO).pipe(
                    mergeMap(() => of(updateReportAcceptStatusDTO))
                  )),
                takeUntil(this.unsubscribe$)
              ).subscribe((updateReportAcceptDTO: UpdateReportAcceptStatusDTO) => {
                this.viewmodel.reportInfo.reportAccept = updateReportAcceptDTO.reportAcceptStatus;
                if (updateReportAcceptDTO.reportAcceptStatus === ReportAcceptStatus.REJECTED) {
                  this.bootstrapGrowlService.addAlert(`Ændringerne til rapporten er 'Afvist', med besked til taksator`, BootstrapAlertType.WARNING, 5000);
                } else {
                  this.bootstrapGrowlService.addAlert(`Ændringerne til rapporten er 'Accepteret'`, BootstrapAlertType.SUCCESS, 5000);
                }
              }, (error) => {
                //if user cancels response, this is triggered.
              });
            }
          };

          this.qapterReturnUrl = 'report/viewer/' + report.id;

          this.sendMessageEnabled = StringUtils.isNotEmpty(report.vkReportKey) && RapportType.extractFrom(report.key || report.vkReportKey).isOfAny([RapportType.H, RapportType.T])
        });
    });

    this.viewmodel.navigateBackCallback = () => {
      this.navigation.back(['/report']);
    };

    this.viewmodel.navigateToCarDetailsCallback = () => {
      this.router.navigate(['car-details'], {relativeTo: this.route});
    };

    this.viewmodel.copyCallback = () => {
      this.didClickCopy();
    };
  }

  didClickQapter(qapterMenuItem: LcActionMenuItem, rappnr): void {
    qapterMenuItem.isLoading = true;
    qapterMenuItem.spin = true;
    this.qapterPrepareModeService.prepareReportMode(rappnr).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe({
      next: qapterPrepareMode => {
        this.startQapter$.next({
          qapterPrepareMode: qapterPrepareMode,
          returnURL: new URLBuilder().constructReturnURL(this.qapterReturnUrl)
        });
        qapterMenuItem.isLoading = false;
        qapterMenuItem.spin = false;
      }, error: () => {
        qapterMenuItem.isLoading = false;
        qapterMenuItem.spin = false;
      }
    });
  }

  didClickCopy(): void {
    if (this.viewmodel.activeTab.canCopy) {
      this.copyReportModalService.open(this.viewmodel.activeTab.rappNr, this.viewmodel.activeTab.rapportType);
    }
  }

  selectPrint(selectedPrint: CombinedPrintModel): void {
    this.viewmodel.selectPrint(selectedPrint)
  }

  showPrintPopup(selectedPrint: CombinedPrintModel): void {
    const rappNr = selectedPrint === this.viewmodel.taksPrintTab ? this.viewmodel.taksPrintTab.rappNr : this.viewmodel.vkPrintTab.rappNr;
    const modalRef = this.modalService.open(PrintComponent, {});
    const componentInstance = modalRef.componentInstance as PrintComponent;
    componentInstance.initialize(rappNr, false);
  }

  getAttachmentsForReport(): void {
    this.reportService.getAttachmentSummary(this.viewmodel.getAttachmentReportKey()).pipe(takeUntil(this.unsubscribe$)).subscribe(attachmentSummary => {
      this.viewmodel.attachmentSummary = attachmentSummary;
    });
  }

  readyForApproval(attachmentApprovalModel: TemporaryAttachmentApprovalModel): void {
    const report = this.viewmodel.report;
    if (this.principal.isVK() && (report.vkReportKey)) {
      this.attachmentApprovalModalService.open(attachmentApprovalModel.reportId, report.vkReportKey, null, attachmentApprovalModel)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(ok => this.approved(ok));
    } else if (this.principal.isTaksator() && (report.key)) {
      this.attachmentApprovalModalService.open(attachmentApprovalModel.reportId, null, report.key, attachmentApprovalModel)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(ok => this.approved(ok));
    }
  }

  approved(ok: boolean): void {
    if (ok) {
      this.bootstrapGrowlService.addAlert('Bilag er godkendt', BootstrapAlertType.SUCCESS);
      this.getAttachmentsForReport();
    } else {
      this.bootstrapGrowlService.addAlert('Bilag er ikke godkendt', BootstrapAlertType.WARNING);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  sendMessage(createMessageDTO: CreateMessageDTO): void {
    this.reportService.sendMessage(this.viewmodel.report.id, createMessageDTO)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.viewmodel.loadMessages();
        this.bootstrapGrowlService.addAlert('Besked sendt', BootstrapAlertType.SUCCESS, 3000);
      });
  }
}
