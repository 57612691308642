import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CaseSearchResultDTO} from './dto/case-search-result-dto';
import {StringUtils} from '../shared/utils/string-utils';
import {map} from 'rxjs/operators';
import {CaseSearchResultModel} from './case-search-result.model';
import {SearchType, SearchTypes} from '../shared/ui/search/search-types.model';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  baseUrl = 'search';
  currentResults: CaseSearchResultModel[];
  currentSearchType: SearchType;

  constructor(private http: HttpClient) {
  }

  public searchByKey(reportWidePk: string): Observable<CaseSearchResultModel[]> {
    return this.http.get<CaseSearchResultDTO[]>(this.baseUrl + '/report/' + reportWidePk)
      .pipe(map(response => response.map(searchResultDTO => this.convertToModelHavingReportKeyInActiveTab(searchResultDTO, reportWidePk))));
  }

  public searchByVkKey(reportWidePk: string): Observable<CaseSearchResultModel[]> {
    return this.http.get<CaseSearchResultDTO[]>(this.baseUrl + '/vkreport/' + reportWidePk)
      .pipe(map(response => response.map(searchResultDTO => this.convertToModelHavingReportKeyInActiveTab(searchResultDTO, reportWidePk))));
  }

  public searchByRegNr(regNr: string, draftsOnly: boolean): Observable<CaseSearchResultModel[]> {
    const params = new HttpParams().set('draftsOnly', draftsOnly);
    return this.http.get<CaseSearchResultDTO[]>(this.baseUrl + '/regnr/' + regNr, {params})
      .pipe(map(response => response.map(searchResultDTO => this.convertToModel(searchResultDTO))));
  }

  public searchByStelnummer(stelnummer: string, draftsOnly: boolean): Observable<CaseSearchResultModel[]> {
    const params = new HttpParams().set('draftsOnly', draftsOnly);
    return this.http.get<CaseSearchResultDTO[]>(this.baseUrl + '/stelnummer/' + stelnummer, {params})
      .pipe(map(response => response.map(searchResultDTO => this.convertToModel(searchResultDTO))));
  }

  public searchBySkadenummer(skadenummer: string, draftsOnly: boolean): Observable<CaseSearchResultModel[]> {
    const params = new HttpParams().set('draftsOnly', draftsOnly);
    return this.http.get<CaseSearchResultDTO[]>(this.baseUrl + '/skadenummer/' + skadenummer, {params})
      .pipe(map(response => response.map(searchResultDTO => this.convertToModel(searchResultDTO))));
  }

  public searchAssessorsDrafts(dateFrom: Date,
                               dateTo: Date,
                               vkNummer: string,
                               assessorUserName: string,
                               fabrikat: string,
                               model: string,
                               regnr: string,
                               vin: string): Observable<CaseSearchResultModel[]> {
    return this.searchWithVariousParams(dateFrom, dateTo, vkNummer, assessorUserName, fabrikat, model, regnr, vin, true);
  }

  public searchAssessorCases(dateFrom: Date,
                             dateTo: Date,
                             vkNummer: string,
                             taksNummer: number,
                             fabrikat: string,
                             model: string,
                             regnr: string,
                             vin: string): Observable<CaseSearchResultModel[]> {

    return this.searchWithVariousParams(dateFrom, dateTo, vkNummer, taksNummer + '', fabrikat, model, regnr, vin, false);
  }

  private searchWithVariousParams(dateFrom: Date,
                                  dateTo: Date,
                                  vkNummer: string,
                                  taksNummer: string,
                                  fabrikat: string,
                                  model: string,
                                  regnr: string,
                                  vin: string,
                                  searchForDraftsOnly: boolean
  ): Observable<CaseSearchResultModel[]> {

    let params = new HttpParams();
    if (dateFrom) {
      params = params.append('dateFrom', dateFrom.toDateString());
    }
    if (dateTo) {
      params = params.append('dateTo', dateTo.toDateString());
    }
    if (!StringUtils.isEmpty(vkNummer)) {
      params = params.append('vkNummer', vkNummer);
    }
    if (!StringUtils.isEmpty(taksNummer) && !searchForDraftsOnly) {
      params = params.append('taksNummer', taksNummer);
    }
    if (!StringUtils.isEmpty(fabrikat)) {
      params = params.append('fabrikat', fabrikat);
    }
    if (!StringUtils.isEmpty(model)) {
      params = params.append('model', model);
    }
    if (!StringUtils.isEmpty(regnr)) {
      params = params.append('regnr', regnr);
    }
    if (!StringUtils.isEmpty(vin)) {
      params = params.append('vin', vin);
    }

    const url = searchForDraftsOnly ? this.baseUrl + '/colleague/' + taksNummer : this.baseUrl + '/wildcard';

    return this.http.get<CaseSearchResultDTO[]>(url, {params: params}).pipe(map(response => response.map(searchResultDTO => this.convertToModel(searchResultDTO))));
  }

  convertToModel(caseSearchResultDTO: CaseSearchResultDTO): CaseSearchResultModel {
    return CaseSearchResultModel.fromDTO(caseSearchResultDTO);
  }

  convertToModelHavingReportKeyInActiveTab(caseSearchResultDTO: CaseSearchResultDTO, reportKeySoughtfor: string): CaseSearchResultModel {
    const model = CaseSearchResultModel.fromDTO(caseSearchResultDTO);
    model.determineActiveTabBasedOnReportKey(reportKeySoughtfor);
    return model;
  }

  setCurrentSearchResult(results: CaseSearchResultModel[]): void {
    this.currentResults = results;
  }

  getCurrentSearchResult(): CaseSearchResultModel[] {
    return this.currentResults;
  }

  setCurrentSearchType(searchType: SearchType): void {
    this.currentSearchType = searchType;
  }

  getCurrentSearchType(): SearchType {
    if (!this.currentSearchType) {
      this.currentSearchType = new SearchTypes().getTypes()[0];
    }
    return this.currentSearchType;
  }
}
