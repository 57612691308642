import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PrincipalService} from '../../../shared';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  templateUrl: './draft-approve.component.html',
})
export class DraftApproveComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  clientStateToken: string;

  constructor(private route: ActivatedRoute, private principalService: PrincipalService) {
    this.route.parent.params
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(params => {
        this.clientStateToken = params.token;
      });
  }

  ngOnInit(): void {
  }

  isVK(): boolean {
    return this.principalService.isVK();
  }

  isTaks(): boolean {
    return this.principalService.isVK();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
