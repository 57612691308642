import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'lcTimeAgo',
  pure: true
})
export class LcTimeAgoPipe implements PipeTransform {

  transform(value: number | string | any, args?: any): any {
    if (value) {
      if (typeof value === 'string') {
        value = Date.parse(value);
      }
      if (isNaN(Number(value))) {
        return '-';
      }
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < 5) {
        // less than 30 seconds ago will show as 'Just now'
        return 'få sekunder siden';
      } else if (seconds < 60) {
        // less than 30 seconds ago will show as 'Just now'
        return 'mindre end et minut siden';
      }
      const intervals = [
        {unit: 31536000, s: 'år', m: 'år'},
        {unit: 2592000, s: 'måned', m: 'måneder'},
        {unit: 604800, s: 'uge', m: 'uger'},
        {unit: 86400, s: 'dag', m: 'dage'},
        {unit: 3600, s: 'time', m: 'timer'},
        {unit: 60, s: 'minut', m: 'minutter'},
        ];
      let counter;
      for (const interval of intervals) {
        counter = Math.floor(seconds / interval.unit);
        if (counter > 0) {
          const denotion = counter === 1 ? interval.s : interval.m;
          return counter + ' ' + denotion + ' siden';
        }
      }
    }
    return value;
  }
}
