import {Injectable} from '@angular/core';
import {BusinessToBusinessDTO, BusinessToBusinessIntegrationResultDTO} from '../model/business-to-business-dto.model';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {BusinessToBusinessPaintReportDTO} from '../model/business-to-business-paint-report-dto.model';
import URLBuilder from '../../shared/utils/url-builder';
import {Cacheable} from 'ts-cacheable';
import {AttachmentDTO} from '../../shared/dto/attachment-dto.model';
import {AttachmentSummaryDTO} from '../../shared/dto/attachment-summary-dto.model';
import {B2BAdviseDTO} from '../model/b2b-advise-dto.model';
import {map, tap} from 'rxjs/operators';
import {BreadcrumbService} from '../../shared/ui/breadcrumb/breadcrumb.service';
import {PrintAsTextDTO} from '../../shared/dto/print-as-text-dto.model';
import {B2BAdviseTimelineDTO} from '../../shared/dto/b2b-advise-timeline-dto.model';
import {B2BCreateAdviseTimelineDTO} from '../../shared/dto/b2b-create-advise-timeline-dto.model';

const cacheBuster$ = new Subject<void>();
const maxAge = 5000;

@Injectable()
export class BusinessToBusinessService {
  private urlBuilder = new URLBuilder();
  private rootUrl = 'businesstobusiness';
  private getIntegrationDataUrl = this.rootUrl + '/report';
  private saveIntegrationDataUrl = this.rootUrl + '/report';

  constructor(private http: HttpClient,
              private breadcrumbService: BreadcrumbService) {
  }

  clearCache(): void {
    cacheBuster$.next();
  }

  getB2BPaintReports(): Observable<BusinessToBusinessPaintReportDTO[]> {
    return this.http.get<BusinessToBusinessPaintReportDTO[]>(this.rootUrl + '/paint');
  }

  getB2BPaintReport(reportId: number): Observable<BusinessToBusinessPaintReportDTO> {
    return this.http.get<BusinessToBusinessPaintReportDTO>(this.rootUrl + '/paint/' + reportId).pipe(
    tap(b2bPaintReportDTO => {
      //we fill in the breadcrumb context each time we request a an advise by its id
      this.breadcrumbService.setB2BPaintReport(b2bPaintReportDTO);
    }));
  }

  getB2BPaintReportPdfUrl(reportId: number): string {
    return this.urlBuilder.getBaseApiUrl() + this.rootUrl + '/paint/' + reportId + '/print/pdf'
  }

  getB2BPaintReportPrint(reportId: number): Observable<PrintAsTextDTO> {
    return this.http.get<PrintAsTextDTO>(this.rootUrl + '/paint/' + reportId + '/print/text');
  }

  getIntegrationData(reportKey: string): Observable<BusinessToBusinessDTO> {
    return this.http.get<BusinessToBusinessDTO>(this.getIntegrationDataUrl + '/' + reportKey);
  }

  saveIntegrationData(reportKey: string, type: string, vkNr: string): Observable<BusinessToBusinessIntegrationResultDTO> {
    const url = this.saveIntegrationDataUrl + '/' + reportKey + '/' + type + '/' + vkNr;
    return this.http.put<BusinessToBusinessIntegrationResultDTO>(url, null);
  }

  removeIntegrationData(reportKey: string, type: string): Observable<object> {
    const url = this.saveIntegrationDataUrl + '/' + reportKey + '/' + type;
    return this.http.delete(url);
  }

  @Cacheable({cacheBusterObserver: cacheBuster$, maxAge: maxAge})
  getReportAttachments(reportId: number): Observable<AttachmentDTO[]> {
    return this.http.get<AttachmentDTO[]>(this.rootUrl + '/paint/' + reportId + '/attachments');
  }

  @Cacheable({cacheBusterObserver: cacheBuster$, maxAge: maxAge})
  getReportAttachmentSummary(reportId: number): Observable<AttachmentSummaryDTO> {
    return this.http.get<AttachmentSummaryDTO>(this.rootUrl + '/paint/' + reportId + '/attachments/summary');
  }

  public getAdvises(): Observable<B2BAdviseDTO[]> {
    return this.http.get<B2BAdviseDTO[]>(this.rootUrl + '/advise').pipe(map(response => response.map(b2bAdviseJsonString => Object.assign(new B2BAdviseDTO(), b2bAdviseJsonString))));
  }

  public getAdvise(adviseId: number): Observable<B2BAdviseDTO> {
    return this.http.get<B2BAdviseDTO>(this.rootUrl + '/advise/' + adviseId).pipe(
      map(response => Object.assign(new B2BAdviseDTO(), response)),
      tap(b2bAdviseDTO => {
        //we fill in the breadcrumb context each time we request a an advise by its id
        this.breadcrumbService.setB2BAdvise(b2bAdviseDTO);
      }));
  }

  getAdviseTimeline(adviseId: number): Observable<B2BAdviseTimelineDTO[]> {
    return this.http.get<B2BAdviseTimelineDTO[]>(this.rootUrl + '/advise/' + adviseId + '/timeline').pipe(map(response => response.map(b2bAdviseTimelineJsonString => Object.assign(new B2BAdviseTimelineDTO(), b2bAdviseTimelineJsonString))));
  }

  createAdviseTimeline(createAdviseTimelineDTO: B2BCreateAdviseTimelineDTO): Observable<B2BAdviseTimelineDTO> {
    return this.http.put<B2BAdviseTimelineDTO>(this.rootUrl + '/advise/' + createAdviseTimelineDTO.adviseId + '/timeline', createAdviseTimelineDTO).pipe(map(response => Object.assign(new B2BAdviseTimelineDTO(), response)));
  }

  getAdvisePrint(adviseId: number): Observable<PrintAsTextDTO> {
    return this.http.get<PrintAsTextDTO>(this.rootUrl + '/advise/' + adviseId + '/print/text');
  }

  getAdvisePDFUrl(adviseId: number): string {
    return this.urlBuilder.getBaseApiUrl() + this.rootUrl + '/advise/' + adviseId + '/print/pdf'
  }

  @Cacheable({cacheBusterObserver: cacheBuster$, maxAge: maxAge})
  getAdviseAttachmentSummary(adviseId: number): Observable<AttachmentSummaryDTO> {
    return this.http.get<AttachmentSummaryDTO>(this.rootUrl + '/advise/' + adviseId + '/attachments/summary');
  }

  @Cacheable({cacheBusterObserver: cacheBuster$, maxAge: maxAge})
  getAdviseAttachments(adviseId: number): Observable<AttachmentDTO[]> {
    return this.http.get<AttachmentDTO[]>(this.rootUrl + '/advise/' + adviseId + '/attachments');
  }
}
