import {LcInputValidator} from './lcinput.validator';
import {ValidationErrors} from '@angular/forms';
import {isNullOrUndefined} from '../../utils/object-utils';

export class LcYearValidator extends LcInputValidator {
  constructor(private minYear?: number, private maxYear?: number) {
    super();
  }

  validate(value: string): ValidationErrors {
    let result = null;
    if (!isNullOrUndefined(value)) {
      const valNumber = Number(value);
      if (!isNullOrUndefined(this.minYear)) {
        if (valNumber < this.minYear) {
          result = LcYearValidator.appendErrors(result, {lcYearMin: 'År skal være større end "' + this.minYear + '"'});
        }
      }
      if (!isNullOrUndefined(this.maxYear)) {
        if (valNumber > this.maxYear) {
          result = LcYearValidator.appendErrors(result, {lcYearMax: 'År skal være mindre end "' + this.maxYear + '"'});
        }
      }
    }
    return result;
  }
}
