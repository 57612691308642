<fieldset ngForm="f" #f="ngForm">
    <h2>Restgæld</h2>
    <div class="card">
        <div class="card-body">
            <div class="row pb-3">
                <label class="col-form-label col-md-4 col-sm-12" for="residual-debt">Er der restgæld i
                    køretøjet?</label>
                    <div class="col-md-8 col-sm-12">
                <select [(ngModel)]="details.erReportDetails.pantehaverRestGaeld"
                        class="form-control" id="residual-debt"
                        name="residual-debt" (change)="residualDebtChanged()">
                    <option *ngFor="let residualDebt of getResidualDebtOptions()" [ngValue]="residualDebt.value">
                        {{residualDebt.name}}
                    </option>
                </select></div>
            </div>
            <div class="row pb-3">
                <div class="col-12">Hvis der er valgt 'Ja' til restgæld, bedes nedenstående oplysninger være udfyldt
                </div>
            </div>
            <div class="row pb-3">
                <label class="col-form-label col-md-4 col-sm-12" for="pantehaverRestGaeldBeloeb">Restgælden udgør ca.</label>
                <div class="col-md-8 col-sm-12">
                    <div class="input-group pe-0 ps-0">
                        <input [(ngModel)]="details.erReportDetails.pantehaverRestGaeldBeloeb" class="form-control" type="text"
                            id="pantehaverRestGaeldBeloeb" name="pantehaverRestGaeldBeloeb" minlength=0 maxlength=8 size="8"
                            lcInputMask="numbersOnly" [readonly]="disableResidualDetails()" />
                        <div class="input-group-text">Kr.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <h2>Panthaver</h2>
    <div class="card">
        <div class="card-body">
            <div class="row pb-3">
                <label class="col-form-label col-4" for="pantehaverNavn">Navn</label>
                <div class="col-8">
                <input class="form-control"
                       [(ngModel)]="details.erReportDetails.pantehaverNavn"
                       type="text" id="pantehaverNavn" name="pantehaverNavn" maxlength="35"
                       [readonly]="disableResidualDetails()"
                /></div>
            </div>
            <div class="row pb-3">
                <label class="col-form-label col-4" for="pantehaverAdresse">Adresse</label>
                <div class="col-8">
                    <input class="form-control" [(ngModel)]="details.erReportDetails.pantehaverAdresse" type="text"
                        id="pantehaverAdresse" name="pantehaverAdresse" maxlength="35" [readonly]="disableResidualDetails()" />
                </div>
            </div>
            <div class="row pb-3">
                <label class="col-form-label col-4" for="pantehaverPostalCode">Postnr</label>
                <div class="col-8">
                    <input class="form-control"
                        [(ngModel)]="details.erReportDetails.pantehaverPostalCode"
                        type="tel" id="pantehaverPostalCode" name="pantehaverPostalCode"
                        minlength=1 size="4" maxlength="4" lcInputMask="int4" [readonly]="disableResidualDetails()"
                        (change)="updateCityByPostalCode()"
                    />
                </div>
            </div>

            <div class="row pb-3">
                <label class="col-form-label col-4">By</label>
                <div class="col-8">
                    <input class="form-control" [(ngModel)]="by" [readonly]="true" type="text" id="by"
                        name="by"
                    />
                </div>
            </div>

            <div class="row pb-3">
                <label class="col-form-label col-4" for="pantehaverTlf">Telefon</label>
                <div class="col-8">
                    <input class="form-control"
                        [(ngModel)]="details.erReportDetails.pantehaverTlf"
                        type=tel id="pantehaverTlf" name="pantehaverTlf"
                        size="8" maxlength="8" lcInputMask="int8" [readonly]="disableResidualDetails()"/>
                </div>
            </div>
        </div>
    </div>
</fieldset>
