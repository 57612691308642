import {Injectable} from '@angular/core';
import {RepairType} from '../../model/repair-type.model';
import {Observable} from 'rxjs';
import {FabrikatDTO} from '../dto/fabrikat-dto.model';
import {isNullOrUndefined} from '../../utils/object-utils';
import {map} from 'rxjs/operators';
import {ModelDTO} from '../dto/model-dto.model';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class MakeService {
  private fabrikatUrl = 'fabrikater';

  constructor(private http: HttpClient) {
  }
  
  findAllBrands(repairType?: RepairType): Observable<FabrikatDTO[]> {
    const filter = !isNullOrUndefined(repairType) ? '?repairType=' + repairType : '';
    return this.http.get<FabrikatDTO[]>(this.fabrikatUrl + filter).pipe(map(response => response
        .map(fabrikatJson => {
          const fabrikatObject = Object.assign(new FabrikatDTO(), fabrikatJson);
          fabrikatObject.fantomModels = fabrikatObject.modeller.filter(model => ModelDTO.isKnownByAudatex(model));
          return fabrikatObject
        })
        .sort((a, b) => {
          const args = 'navn';
          if (a[args] < b[args]) {
            return -1;
          } else {
            if (a[args] > b[args]) {
              return 1;
            } else {
              return 0;
            }
          }
        })));
  }

  getModel(makeId: number, modelId: number): Observable<ModelDTO> {
    return this.http.get<ModelDTO>(`${this.fabrikatUrl}/${makeId}/model/${modelId}`).pipe(map(json => ModelDTO.fromJson(json)));
  }
}
