<div class="row" *ngIf="vkstam">
    <div class="col-md-6">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="text-end col-4">Vknr</div>
                    <div class="col-8">{{vkstam.vkNr}}</div>
                    <div class="text-end col-4">Navn</div>
                    <div class="col-8">{{vkstam.navn}}</div>
                    <div class="text-end col-4">Adresse1</div>
                    <div class="col-8">{{vkstam.adresse1}}</div>
                    <div class="text-end col-4">Adresse2</div>
                    <div class="col-8">{{vkstam.adresse2}}</div>
                    <div class="text-end col-4">Postnr / By</div>
                    <div class="col-8">{{vkstam.postnr}} {{vkstam.bynavn}}</div>
                    <div class="text-end col-4">Telefon</div>
                    <div class="col-8">{{vkstam.telefonnr}}</div>
                    <div class="text-end col-4">E-mail</div>
                    <div class="col-8">{{vkstam.email}}</div>
                </div>
            </div>
        </div>
        <div class="card mt-1" *ngIf="!!responsibleWorkshopUser">
            <div class="card-body">
                <div class="row">
                    <div class="text-end col-4">Ansvarlig</div>
                    <div class="col-8">{{responsibleWorkshopUser.name}}</div>
                </div>
            </div>
        </div>
        <ng-container *ngIf="vkstam.customAftaler && vkstam.customAftaler.length > 0">
            <div><h3>Egne attributter</h3></div>
            <div class="card mt-2">
                <div class="card-body">
                    <div class="row" *ngFor="let customAftale of vkstam.customAftaler">
                        <div class="col-md-12">{{customAftale.tekst}}</div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="col-md-6">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="text-end col-4">CVR</div>
                    <div class="col-8">{{vkstam.cvrNr}}</div>
                    <div class="text-end col-4">Generelle bemærkninger</div>
                    <div class="col-8">{{vkstam.bemaerkninger}}</div>
                    <div class="text-end col-4">Interne bemærkninger</div>
                    <div class="col-8">{{vkstam.intBem}}</div>
                    <div class="text-end col-4">Fabrikatkoder</div>
                    <div class="col-8">{{vkstam.fabrikatkoder}}</div>
                    <div class="text-end col-4">Inetegrationsværksted</div>
                    <div class="col-8">{{vkstam.intVk | yesNo}}</div>
                    <div class="text-end col-4">Fototaksering</div>
                    <div class="col-8">{{vkstam.fotoTaksering | yesNo}}</div>
                    <div class="text-end col-4">Værkstedstype</div>
                    <div class="col-8">{{vkstam.vkTypeId}}</div>
                    <div class="text-end col-4">Kategori</div>
                    <div class="col-8">{{vkstam.kategori}}</div>
                    <ng-container *ngIf="vkstam.maaAutoflex">
                        <div class="text-end col-4">Autoflex</div>
                        <div class="col-8"><i class="fa fa-check"></i></div>
                    </ng-container>
                    <ng-container *ngIf="vkstam.midlertidigtStop">
                        <div class="text-end col-4">Stop samarbejde</div>
                        <div class="col-8"><i class="fa fa-check"></i></div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
