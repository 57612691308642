import {NgbDateStruct, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';

export class ForsiDateTimeParserFormatter {

  format(date: NgbDateStruct, time: NgbTimeStruct): Date {
    if (!date) {
      return null;
    }
    time = time || {hour: 0, minute: 0, second: 0};
    return new Date(date.year || 0, date.month - 1 || 0, date.day || 0, time.hour || 0, time.minute || 0, time.second || 0);
  }

  parseDate(value: Date): NgbDateStruct {
    if (!value) {
      return null;
    }
    return {
      year: value.getFullYear(),
      month: value.getMonth() + 1,
      day: value.getDate()
    };
  }

  parseTime(value: Date): NgbTimeStruct {
    if (!value) {
      return null;
    }
    return {
      hour: value.getHours(),
      minute: value.getMinutes(),
      second: value.getSeconds()
    };
  }
}
