export class B2BAdviseDTO {
  id: number;
  createdAt: number;
  updatedAt: number;
  receiverName: string;
  senderWorkshopNo: number;
  senderName: string;
  reportKey: string;

  //vehicle details
  makeName: string;
  modelName: string;
}
