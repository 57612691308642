import {AgreementSectionModel} from './agreement-section.model';
import {AgreementModel} from './agreement.model';
import {StringUtils} from '../utils/string-utils';
import {NumberUtils} from '../utils/number-utils';
import {AgreementHistoryDTO, AgreementValueDTO, AdminAgreementTypeDTO, AgreementDTO} from './agreement-dto.model';

export class AgreementUtils {

  public static sortAgreementSectionModels(sections: AgreementSectionModel[]): AgreementSectionModel[] {
    if (sections && sections.length > 1) {
      sections.sort((s1, s2) => s1.valueTypeId - s2.valueTypeId)
    }
    return sections;
  }

  public static sortAgreements(agreements: AgreementModel[]): void {
    if (agreements && agreements.length > 1) {
      agreements.sort((a1, a2) => this.compare(a1.agreementDTO, a2.agreementDTO))
    }
  }
  
  public static sortAgreementDTOs(agreements: AgreementDTO[]): void {
    if (agreements && agreements.length > 1) {
      agreements.sort((a1, a2) => this.compare(a1, a2))
    }
  }
  
  static sortAgreementHistory(agreements: AgreementHistoryDTO[]): void {
    agreements.sort((a1, a2) => this.compare(a1, a2))
  }
  
  public static sortAgreementValues(agreementValues: AgreementValueDTO[]): AgreementValueDTO[] {
    return agreementValues.sort((a1: AgreementValueDTO, a2: AgreementValueDTO) => a1.id - a2.id);
  }

  public static sortAgreementTypes(agreementTypes: AdminAgreementTypeDTO[]): AdminAgreementTypeDTO[] {
    if (agreementTypes && agreementTypes.length > 1) {
      agreementTypes.sort((at1, at2) => at1.id - at2.id)
    }
    return agreementTypes;
  }

  public static compare(a1: AgreementDTO, a2: AgreementDTO): number {
    let result = NumberUtils.compare(a1.agreementTypeId, a2.agreementTypeId)
    if (result === 0) {
      result = NumberUtils.compare(a1.valueTypeId, a2.valueTypeId)
      if (result === 0) {
        result = StringUtils.compare(a2.makeCode || '', a1.makeCode || '');
        if (result === 0) {
          result = StringUtils.compare(a2.modelCode || '', a1.modelCode || '');
          if (result === 0) {
            result = NumberUtils.compare(a1.ageFrom || 0, a2.ageFrom || 0);
            if (result === 0) {
              result = NumberUtils.compare(a1.ageTo || 999, a2.ageTo || 999);
            }
          }
        }
      }
    }
    return result;
  }

}
