import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {SharedCommonModule} from '../shared/shared-common.module';
import {RouterModule} from '@angular/router';
import {STATISTIC_ROUTES} from './statistic.route';
import {StatisticComponent} from './statistic.component';
import {RoleUserPopupComponent} from './modal/role-user-popup.component';

@NgModule({
  imports: [
    SharedModule,
    SharedCommonModule,
    RouterModule.forRoot(STATISTIC_ROUTES, {useHash: true})
  ],
  declarations: [
    StatisticComponent,
    RoleUserPopupComponent
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class StatisticModule {
}
