import {Component, Input} from '@angular/core';
import {Observable} from 'rxjs';
import {LoadingSpinnerUtil} from '../../../../../utils/loading-spinner-util';
import {VideoRequestResultDTO} from '../../model/video-request-result-dto.model';
import {FileUploader} from 'ng2-file-upload';
import {VideoRequestObserverService} from '../../video-request-observer.service';
import {ClientStateVideoRequestService} from '../../client-state-video-request.service';
import {VideoRequestResponseState} from '../../model/video-request-state.model';

@Component({
  selector: 'lc-workshop-client-state-video-request-upload-button',
  templateUrl: './workshop-client-state-video-request-upload-button.component.html',
  providers: [LoadingSpinnerUtil]
})
export class WorkshopClientStateVideoRequestUploadButtonComponent {

  @Input() token: string;
  @Input() clientStateId: number;
  @Input() fileUploader: FileUploader;

  constructor(public spinnerUtil: LoadingSpinnerUtil,
              private clientStateVideoRequestService: ClientStateVideoRequestService,
              private videoRequestObserverService: VideoRequestObserverService) {
  }

  public videoRequest$(): Observable<VideoRequestResultDTO> {
    return this.clientStateVideoRequestService.getVideoRequest(this.token);
  }

  public videoRequestResponseState$(): Observable<VideoRequestResponseState> {
    return this.videoRequestObserverService.clientStateResponseState$(this.clientStateId);
  }
}
