import {Pipe, PipeTransform} from '@angular/core';
import {ObjectUtils} from '../utils/object-utils';
import {SparePartState} from '../../draft/dto/spare-part-match-response-dto.model';

@Pipe({
  name: 'autoflexState'
})
export class AutoflexStatePipe implements PipeTransform {
  transform(data: SparePartState): string {
    if (!ObjectUtils.exists(data)) {
      return '';
    }
    if (data === SparePartState.CM) {
      return 'Ny';
    } else {
      return 'Brugt'
    }
  }
}
