import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {VkDraftApproveViewModel} from './vk-draft-approve.view-model';
import {ForsiReportKeyShortPipe} from '../../../../../shared/pipe/forsi-report-key-short.pipe';
import {ForsiReportKeyPipe} from '../../../../../shared/pipe/forsi-report-key.pipe';
import {DraftService} from '../../../../service/draft.service';
import {BootstrapAlertType, BootstrapGrowlService} from '../../../../../shared/ui/ngx-bootstrap-growl';
import {TransientReportRepository} from '../../../../../shared/repository/transient.report.repository';
import {ActivatedRoute, Router} from '@angular/router';
import {DeliverResultDTO} from '../../../../dto/deliver-result-dto.model';
import {ApprovePossibilityType} from '../../../../../shared/dto/approval-choice-dto.model';
import {StorageService} from '../../../../../shared/service/storage.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {isNullOrUndefined} from '../../../../../shared/utils/object-utils';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {VkDraftValidationPopupComponent} from './validation-popup/vk-draft-validation-popup.component';

@Component({
  selector: 'lc-vk-draft-approve',
  templateUrl: './vk-draft-approve.component.html',
  providers: [VkDraftApproveViewModel, ForsiReportKeyPipe, ForsiReportKeyShortPipe]
})
export class VkDraftApproveComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  @Input() clientStateToken: string;

  constructor(
    public viewmodel: VkDraftApproveViewModel,
    private clientStateService: DraftService,
    private bootstrapGrowlService: BootstrapGrowlService,
    private transientReportRepository: TransientReportRepository,
    private router: Router,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.viewmodel.token = this.clientStateToken;
    this.viewmodel.loading = true;

    this.viewmodel.navigateBackCallback = () => {
      this.router.navigate(['../calculate'], {relativeTo: this.route});
    };

    this.viewmodel.navigateToEditCallback = () => {
      this.router.navigate(['..'], {relativeTo: this.route});
    };

    this.viewmodel.approveCallback = (token, ignoreValidation?: boolean, ignoreValidationReason?: string) => {
      this.viewmodel.loading = true;
      // Confirm.
      if (this.viewmodel.approvalChoice.approvePossibilityType === ApprovePossibilityType.NONE) {
        this.viewmodel.approvalChoice.message = '';
      }
      this.viewmodel.approvalChoice.ignoreValidation = ignoreValidation;
      this.viewmodel.approvalChoice.ignoreValidationReason = ignoreValidationReason;
      this.viewmodel.approvalChoice.responsibleWorkshopUserId = this.viewmodel.responsibleUser.id;
      return this.clientStateService.createOffer(token, this.viewmodel.approvalChoice)
        .pipe(takeUntil(this.unsubscribe$)).subscribe(approved => {
          if (approved.succeeded) {
            this.bootstrapGrowlService.addAlert((approved.message === null ? 'Rapporten er godkendt' : approved.message), BootstrapAlertType.SUCCESS, 3000);
            //add newly created object to list of temporary transient reports as the backend will perform eventual consistency (through Gazello)
            this.transientReportRepository.add(approved.report);

            // Clear report list config before navigating so that the newly created report will be on top.
            this.storageService.clearReportListConfig();
            this.router.navigate(['report']);
          } else if (approved.validationResult != null && !approved.validationResult.success) {
            const modalRef = this.modalService.open(VkDraftValidationPopupComponent, {backdrop: 'static'});
            const instance = modalRef.componentInstance as VkDraftValidationPopupComponent;
            instance.initialize(this.viewmodel, approved.validationResult);
          } else {
            this.bootstrapGrowlService.addAlert('Rapporten kunne ikke godkendes. ' + approved.message, BootstrapAlertType.WARNING, 3000);
          }
        }, reason => {

          if (!isNullOrUndefined(reason._body)) {
            const result = JSON.parse(reason._body) as DeliverResultDTO;
            this.bootstrapGrowlService.addAlert('Rapporten blev ikke godkendt: ' + result.message, BootstrapAlertType.DANGER);
          } else {
            this.bootstrapGrowlService.addAlert('Rapporten blev ikke godkendt. Der skete en fejl: ' + reason, BootstrapAlertType.DANGER);
          }

          this.viewmodel.loading = false;
        }, () => {
          this.viewmodel.loading = false;
        });
    };
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
