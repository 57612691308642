<fieldset ngForm="f" #f="ngForm">
    <div class="row pb-3">
        <label class="col-form-label col-4" for="fabrikatModel">Fabrikat Model</label>
        <div class="col-8">
            <input class="form-control" [ngModel]="fabrikatAndModelCode" type="text" id="fabrikatModel"
                name="fabrikatModel" readonly />
        </div>
        <label class="col-form-label col-4 mt-1" for="fabrikatModelText"></label>
        <div class="col-8">
            <input class="form-control mt-1" [ngModel]="fabrikatAndModelText" type="text" id="fabrikatModelText"
                name="fabrikatModelText" readonly />
        </div>
    </div>
    <div class="row pb-3">
        <label class="col-form-label col-4" for="aargang">Årgang</label>
        <div class="col-8">
            <input class="form-control" [ngModel]="aargang" type="text" id="aargang" name="aargang" readonly />
        </div>
    </div>
    <div class="row pb-3" *ngIf="!readonly">
        <label class="col-form-label col-4" for="editFabrikat">Fabrikat</label>
        <div class="col-8">
            <input class="form-control" [(ngModel)]="carDetails.editableFabrikat" type="text" id="editFabrikat"
                name="editFabrikat" minlength=0 maxlength=20 size="20" />
        </div>
    </div>
    <div class="row pb-3" *ngIf="!readonly">
        <label class="col-form-label col-4" for="editModel">Model</label>
        <div class="col-8">
            <input class="form-control" [(ngModel)]="carDetails.editableModel" type="text" id="editModel"
                name="editModel" minlength=0 maxlength=20 size="20" />
        </div>
    </div>
</fieldset>