<div class="modal-header pre-size header-style" style="text-align: center">
    <h3 class="text-truncate">
        {{title}}
    </h3>
    <div aria-label="Close" data-dismiss="modal" class="btn-close clickable" (click)="cancel()"></div>
</div>

<div class="modal-content">
    <div class="modal-body modal-body-style">
        <div class="container-fluid" *ngIf="!loading">                         
            <ng-container *ngIf="agreements && agreements.length > 0">            
                <table class="table">
                    <tbody> 
                        <tr *ngFor="let aftale of agreements">
                            <ng-container *ngIf="getValueType(aftale.valueTypeId); let valuetype">
                                <td class="ps-5">{{valuetype.description}}</td>
                                <td>{{getMake(aftale.makeCode) || 'Alle fabrikater'}}</td>
                                <td>{{getModel(aftale.makeCode, aftale.modelCode) || 'Alle modeller'}}</td>
                                <td>{{aftale.ageFrom || 0}} - {{aftale.ageTo || '?'}} År</td>
                                <td class="text-end">{{aftale.value}} {{valuetype.unit}}</td>
                                <td>{{aftale.bemaerkning}}</td>
                            </ng-container>
                        </tr>
                    </tbody>
                </table>
            </ng-container>        
        </div>
    </div>
</div>


