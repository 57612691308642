<lc-bottombar [menu]="viewmodel.bottomMenu" [title]="viewmodel.bottomMenuTitle"
              [shortTitle]="viewmodel.bottomMenuShortTitle"
              [iconClass]="viewmodel.menuIconClass"></lc-bottombar>
<div class="text-center" *ngIf="viewmodel.loading">
    <i class="fa fa-circle-o-notch fa-spin fop-page-spinner"></i>
</div>
<div *ngIf="!viewmodel.loading">
    <h2>Godkend tilbud</h2>
    <p>Tryk 'OK' i bunden for at godkende tilbudet.</p>
    <div class="row">
        <div class="col-md-6  mb-4">
            <div class="card" *ngIf="viewmodel.clientStateDetails">
                <div class="card-header">
                    Køretøj
                </div>
                <div class="card-block p-1 pt-2 pb-2">
                    <div class="col-12 ps-3">
                        Regnr: {{viewmodel.clientStateDetails.ansvarsskade ? viewmodel.clientStateDetails.skadelidte.regNr : viewmodel.clientStateDetails.forsikringstager.regNr}}</div>
                    <div class="col-12 ps-3">Fabrikat: {{viewmodel.clientStateDetails.vehicle.fabrikat.simpleName}}</div>
                    <div class="col-12 ps-3">Model: {{viewmodel.clientStateDetails.vehicle.model.simpleName}}</div>
                    <div class="col-12 ps-3">Stelnr: {{viewmodel.clientStateDetails.vehicle.stelnr}}</div>
                </div>
            </div>
        </div>
        <div class="col-md-6  mb-4">
            <div class="card" *ngIf="viewmodel.clientStateDetails">
                <div class="card-header">
                    Detaljer
                </div>
                <div class="card-block p-1 pt-2 pb-2">
                    <div class="col-12 ps-3">Selskab: {{viewmodel.clientStateDetails.selskab}}</div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mb-4">
        <div class="col-md-6">
            <div class="card">
                <div class="card-header">
                    Ansvarlig for tilbuddet
                </div>
                <div class="card-block p-1 py-2">
                    <lc-autocomplete
                        class="form-control" [items]="viewmodel.vkUsers" itemText="name" itemKey="id"
                        [(ngModel)]="viewmodel.responsibleUser" [disabled]="viewmodel.vkUsers.length <= 1" required>
                    </lc-autocomplete>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="viewmodel.approvalChoices.length > 0">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    Hvordan skal tilbudet godkendes?
                </div>
                <div class="card-block p-1 pt-2 pb-2">
                    <div class="col-12 ps-3">
                        <div class="radio">
                            <label><input type="radio" name="approvalChoice" value="NONE"
                                          [(ngModel)]="viewmodel.approvalChoice.approvePossibilityType">Godkend</label>
                        </div>
                    </div>
                    <div *ngIf="viewmodel.requestingContactChoice" class="col-12 ps-3">
                        <div class="radio">
                            <label><input type="radio" name="approvalChoice" value="REQUESTING_CONTACT"
                                          [(ngModel)]="viewmodel.approvalChoice.approvePossibilityType">{{viewmodel.requestingContactChoice.approvePossibilityText}}
                            </label>
                        </div>

                    </div>
                    <div *ngIf="viewmodel.multipleCasesChoice" class="col-12 ps-3">
                        <div class="radio">
                            <label><input type="radio" name="approvalChoice" value="MULTIPLE_DAMAGES"
                                          [(ngModel)]="viewmodel.approvalChoice.approvePossibilityType">{{viewmodel.multipleCasesChoice.approvePossibilityText}}
                            </label>
                        </div>
                    </div>
                    <div class="col-12 ps-3">
                            <label class="col-form-label" for="comment">Besked til taksator</label>
                            <textarea class="form-control" rows="5" id="comment"
                                      [(ngModel)]="viewmodel.approvalChoice.message"
                                      [disabled]="viewmodel.approvalChoice.approvePossibilityType === 'NONE'"></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <lc-autoflex-part-list [clientStateToken]="clientStateToken"></lc-autoflex-part-list>
</div>


