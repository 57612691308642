import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ClientStateDetails} from '../../../model/client-state-details.model';
import {StringUtils} from '../../../../shared/utils/string-utils';
import {OpslagService} from '../../../../shared/service/opslag.service';
import {FormSaver} from '../../../../shared/service/core/form-saver.service';
import {NgForm} from '@angular/forms';
import {PrincipalService} from '../../../../shared';
import {DraftEditService} from '../../draft-edit.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {isNullOrUndefined} from '../../../../shared/utils/object-utils';

@Component({
  selector: 'lc-draft-forsikringstager-info',
  templateUrl: './draft-forsikringstager-info.component.html',
  styleUrls: ['../../draft-common.scss']
})
export class DraftForsikringstagerInfoComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  @ViewChild('f', {static: true}) public cform: NgForm;
  @Input() formId: string;
  @Input() details: ClientStateDetails;
  by: string;
  isTaksator: boolean;

  private enableDamageNumber = {AF: true, 11: true, TR: true, 32: true, 34: true};
  private defaultAssessors;

  constructor(private opslagService: OpslagService,
              protected formSaver: FormSaver,
              public principalService: PrincipalService,
              private draftEditService: DraftEditService) {
  }

  get regNrPlaceholder(): string {
    return this.isRegNrRequired() ? 'Obligatorisk hvis personoplysninger eller policenummer ikke udfyldes' : '';
  }

  get navnPlaceholder(): string {
    const regNrFilled = StringUtils.isNotEmpty(this.details.forsikringstager.regNr);
    const policeNrFilled = StringUtils.isNotEmpty(this.details.forsikringstager.policenr);
    return !regNrFilled && !policeNrFilled ? 'Obligatorisk hvis regNr eller policenummer ikke udfyldes' : '';
  }

  ngOnInit(): void {
    this.formSaver.registerForm(this.formId, this.cform);
    this.isTaksator = this.principalService.isTaksator();
    this.draftEditService.getCompanyDetailsObservable()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(companyDetails => {
        this.defaultAssessors = {};
        for (const companyDetail of companyDetails) {
          if (companyDetail.code && companyDetail.defaultAssessorOrg) {
            this.defaultAssessors[companyDetail.code] = companyDetail.defaultAssessorOrg.code
          }
        }
      });
    this.postNrChanged();
  }

  isRegNrRequired(): boolean {
    if (this.hasPoliceResult() && StringUtils.isNotEmpty(this.details.police.regNr)) {
      return false;
    }
    let required = false;
    if (this.details.ansvarsskade) {
      //can only be required in case the use has selected ansvarsskade - otherwise the regnr is edited in stamdata
      const personInfo = this.details.forsikringstager;
      const personNameAndAdressFilled = StringUtils.isNotEmpty(personInfo.navn) && StringUtils.isNotEmpty(personInfo.addressLine1) && StringUtils.isNotEmpty(personInfo.postNr);
      const policeFilled = StringUtils.isNotEmpty(personInfo.policenr);
      required = !personNameAndAdressFilled && !policeFilled;
    }
    return required;
  }

  isNavnRequired(): boolean {
    if (this.hasPoliceResult() && StringUtils.isNotEmpty(this.details.police.navn)) {
      return false;
    }
    const personInfo = this.details.forsikringstager;
    const regNrFilled = StringUtils.isNotEmpty(personInfo.regNr);
    const addressFilled = StringUtils.isNotEmpty(personInfo.addressLine1);
    const postNrFilled = StringUtils.isNotEmpty(personInfo.postNr);
    const policeFilled = StringUtils.isNotEmpty(personInfo.policenr);
    return (addressFilled || postNrFilled) || (!regNrFilled && !policeFilled);
  }

  isAddressRequired(): boolean {
    if (this.hasPoliceResult() && StringUtils.isNotEmpty(this.details.police.addressLine1)) {
      return false;
    }
    const personInfo = this.details.forsikringstager;
    const regNrFilled = StringUtils.isNotEmpty(personInfo.regNr);
    const nameFilled = StringUtils.isNotEmpty(personInfo.navn);
    const postNrFilled = StringUtils.isNotEmpty(personInfo.postNr);
    const policeFilled = StringUtils.isNotEmpty(personInfo.policenr);
    return (!regNrFilled && !policeFilled) && (!nameFilled && postNrFilled) || postNrFilled;
  }

  isPostNrRequired(): boolean {
    if (this.hasPoliceResult() && StringUtils.isNotEmpty(this.details.police.postNr)) {
      return false;
    }
    const personInfo = this.details.forsikringstager;
    const regNrFilled = StringUtils.isNotEmpty(personInfo.regNr);
    const nameFilled = StringUtils.isNotEmpty(personInfo.navn);
    const addressFilled = StringUtils.isNotEmpty(personInfo.addressLine1);
    const policeFilled = StringUtils.isNotEmpty(personInfo.policenr);
    return (!regNrFilled && !policeFilled) && (nameFilled || addressFilled);
  }

  hasPoliceResult(): boolean {
    return !isNullOrUndefined(this.details.police) && !this.details.police.empty;
  }

  postNrChanged(): void {
    if (this.details.forsikringstager.postNr) {
      this.opslagService.getPostNr(Number(this.details.forsikringstager.postNr))
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(postNr => {
          if (postNr) {
            this.by = postNr.navn;
          }
        }, () => {
          this.by = '';
        });
    } else {
      this.by = '';
    }
  }

  public allowedToEditDamageNumber(): boolean {
    let result = false;
    if (this.isTaksator) {
      result = true;
    } else {
      if (!this.details.damageNumberSetByAssessor) {
        result = !!this.defaultAssessors && !!this.enableDamageNumber[this.defaultAssessors[this.details.selskab]];
      }
    }
    return result;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
