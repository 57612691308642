export enum AppRole {
  VK = 'ROLE_VK',
  TAKS = 'ROLE_Taks',
  TAKS_MANAGER = 'ROLE_TaksManager',
  PBI_STATISTIC_USER = 'ROLE_PowerBIStatisticUser',
  SYSADMIN = 'ROLE_sysadmin',
  AUTOTAKS_ADMIN = 'ROLE_AutoAdminRolle',
  AFTALEADMIN = 'ROLE_AftaleAdmin',
  REDIRECTADMIN = 'ROLE_RedirectAdmin',
  SAGSB = 'ROLE_SagsB',
  ONLYADMIN = 'ROLE_OnlyAdmin',
  USERADMINKONFIGURATOR = 'ROLE_UserAdminKonfigurator',
  USERADMINVK = 'ROLE_UserAdminVK',
  USER_ADMIN_TAKS = 'ROLE_UserAdminTaks',
  USER_ADMIN_SAGSB = 'ROLE_UserAdminSagsB',
  USER_ADMIN_USER_ADM = 'ROLE_UserAdminBrugerAdm',
  USER_ADMIN_STAT = 'ROLE_UserAdminStat',
  USER_ADMIN_AGREEMENT_ADM = 'ROLE_UserAdminAftaleAdm',
  EXTERNAL_VALIDATION_USER = 'ROLE_ExternalValidationUser',
  EVERYONE = 'ROLE_everyone',
  INTEGRATION = 'ROLE_Integration',
  ROADMAP_VIEWER = 'ROLE_RoadmapViewer',
  EDIT_AUTOFLEX_AGREEMENT = 'ROLE_EditAutoflexAftale',
  EDIT_POLICE_AGREEMENT = 'ROLE_EditPoliceAftale',
  AUTOMATION = 'ROLE_Automatisering',
  CONFIGURATOR = 'ROLE_Konfigurator',
  EDIT_OWN_CUSTOM_ATTRIBUTES = 'ROLE_EditEgneAttributterAftale',
  REPORT_DATE_SEARCHER = 'ROLE_ReportDateSearcher',
  EDIT_WORKSHOP_COLLABORATION = 'ROLE_EditStopSamarbejdeAftale',
  VK_ADMIN = 'ROLE_WorkshopAdmin',

  //add more here as they are needed
}

export class AppRoles {

  public static ROLES_TO_START_IN_DRAFT_SECTION = [AppRole.VK, AppRole.TAKS];
  public static ROLES_TO_START_IN_ADMINISTRATION_SECTION = [AppRole.CONFIGURATOR, AppRole.SAGSB, AppRole.AFTALEADMIN, AppRole.ONLYADMIN];
  public static ROLES_TO_START_IN_PROFILE_TOKEN_SECTION = [AppRole.INTEGRATION];

  // any role needed to have general access to administration menu
  public static GENERAL_ADMINISTRATION_ACCESS = [
    AppRole.TAKS, AppRole.SAGSB, AppRole.USERADMINVK, AppRole.AFTALEADMIN,
    AppRole.ONLYADMIN, AppRole.INTEGRATION, AppRole.VK_ADMIN, AppRole.CONFIGURATOR,
    AppRole.USERADMINVK, AppRole.USER_ADMIN_TAKS, AppRole.USER_ADMIN_SAGSB, AppRole.USER_ADMIN_USER_ADM,
    AppRole.USER_ADMIN_STAT, AppRole.USER_ADMIN_AGREEMENT_ADM, AppRole.USERADMINKONFIGURATOR
  ];

  //ALL roles required to access automation
  public static readonly ADMINISTRATE_AUTOMATION = [AppRole.CONFIGURATOR, AppRole.AUTOMATION];

  //ANY role required to access user administration
  public static readonly ADMINISTRATE_USER = [
    AppRole.USERADMINVK, AppRole.USER_ADMIN_TAKS, AppRole.USER_ADMIN_SAGSB, AppRole.USER_ADMIN_USER_ADM,
    AppRole.USER_ADMIN_STAT, AppRole.USER_ADMIN_AGREEMENT_ADM, AppRole.USERADMINKONFIGURATOR
  ];
  
  public static matchesRole(userRole: string, appRole: AppRole): boolean {
    return userRole === appRole || 'ROLE_' + userRole === appRole
  }
}

