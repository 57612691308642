import {RapportType} from './rapport-type.model';
import {isNullOrUndefined} from '../utils/object-utils';
import {RepairType} from './repair-type.model';

/**
 * Towards the end user this is known as "Opgørelsesystem"
 * Before being renamed to {@link ReportCategory} it was called ReportType.
 * However this was confusing because we also have {@link RapportType}
 * If you find naming that uses "reportType" please rename it to "reportCategory".
 * However do not confuse this with {@link RapportType}
 */
export class ReportCategory {
  //general autotaks
  static AUTOTAKS = new ReportCategory('autotaks-report', 'Autotaks', true, true, true, true, 'fa-car', [RapportType.H, RapportType.HT, RapportType.T, RapportType.S, RapportType.G], 'AUTOTAKS');
  static AUTOTAKS_LORRY = new ReportCategory('autotaks-lorry-report', 'Autotaks Lastbil', true, true, true, true, 'fa-truck', [RapportType.H, RapportType.HT, RapportType.T, RapportType.S, RapportType.G], 'AUTOTAKS_LORRY');
  static ASSESSMENT_MCTAKS = new ReportCategory('assessment-mctaks-report', 'MCTaks', true, true, true, true, 'fa-motorcycle', [RapportType.H, RapportType.HT, RapportType.T, RapportType.S, RapportType.G], 'ASSESSMENT_MCTAKS');
  static ASSESSMENT_CAMPTAKS = new ReportCategory('assessment-camptaks-report', 'CampTaks', true, true, true, true, 'icon-caravan', [RapportType.H, RapportType.HT, RapportType.T, RapportType.S, RapportType.G], 'ASSESSMENT_CAMPTAKS');
  static ASSESSMENT_BUILDING = new ReportCategory('assessment-building-report', 'Bygning', true, true, true, true, 'fa-building', [RapportType.H, RapportType.HT, RapportType.T, RapportType.S, RapportType.G], 'ASSESSMENT_BUILDING');
  static ASSESSMENT_MOVABLES = new ReportCategory('assessment-movables-report', 'Løsøre', true, true, true, true, 'fa-trophy', [RapportType.H, RapportType.HT, RapportType.T, RapportType.S, RapportType.G], 'ASSESSMENT_MOVABLES');
  static ASSESSMENT_MISC = new ReportCategory('assessment-miscellaneous-report', 'Diverse', true, true, true, true, 'fa-archive', [RapportType.H, RapportType.HT, RapportType.T, RapportType.S, RapportType.G], 'ASSESSMENT_MISC');
  static AGRICULTURE_EQPT = new ReportCategory('agriculture-eqpt-report', 'Landbrugsredskab', true, true, true, true, 'fa-leaf', [RapportType.H, RapportType.HT, RapportType.T, RapportType.S, RapportType.G], 'AGRICULTURE_EQPT');
  static ENTREPRENEUR_MATR =
    new ReportCategory('assessment-entrepreneur-report', 'Entreprenør materialer', true, true, true, true, 'fa-briefcase', [RapportType.H, RapportType.HT, RapportType.T, RapportType.S, RapportType.G], 'ENTREPRENEUR_MATR');
  //taks flow one (also denoted UNITAKS as it handles all types that doesn't use Qapter)
  static SKAVUTAKS = new ReportCategory('skavutaks-report', 'Unitaks', true, true, true, false, 'fa-ship', [RapportType.H, RapportType.HT, RapportType.T, RapportType.S, RapportType.G], 'SKAVUTAKS');
  //taks flow two
  static ER = ReportCategory.flowTwoReportCategory('er-report', RapportType.ER, 'Auto', false);
  static OP = ReportCategory.flowTwoReportCategory('op-report', RapportType.OP, 'Auto', false);
  static GB = ReportCategory.flowTwoReportCategory('gb-report', RapportType.GB, 'Auto', false);
  static EL = ReportCategory.flowTwoReportCategory('el-report', RapportType.EL, 'Auto', false);
  static KR = ReportCategory.flowTwoReportCategory('kr-report', RapportType.KR, 'Auto', false);
  //vk
  static GLASTAKS = ReportCategory.vkReportCategory('glastaks-report', 'Glastaks', 'fa-glass', true, 'GLASTAKS');
  static STORTAKS = ReportCategory.vkReportCategory('stortaks-report', 'Stortaks', 'fa-truck', false, 'STORTAKS');
  static MCTAKS = ReportCategory.vkReportCategory('mctaks-report', 'Mctaks', 'fa-motorcycle', false, 'MCTAKS');
  static CAMPTAKS = ReportCategory.vkReportCategory('camptaks-report', 'Camptaks', 'icon-caravan', false, 'CAMPTAKS');
  //as long as vk_unitaks only supports simple glass reports we can stick to the glass icons for the report
  static VK_UNITAKS = ReportCategory.vkReportCategory('vk-unitaks-report', 'Glastaks', 'fa-glass', false, 'VK_UNITAKS');

  static UNKNOWN = new ReportCategory('', 'Ukendt', false, false, false, false, 'fa-question', [], null);

  private static readonly ALL_REPORT_CATEGORIES = [
    ReportCategory.AUTOTAKS,
    ReportCategory.AUTOTAKS_LORRY,
    ReportCategory.ASSESSMENT_MCTAKS,
    ReportCategory.ASSESSMENT_CAMPTAKS,
    ReportCategory.ENTREPRENEUR_MATR,
    ReportCategory.ASSESSMENT_BUILDING,
    ReportCategory.ASSESSMENT_MISC,
    ReportCategory.ASSESSMENT_MOVABLES,
    ReportCategory.AGRICULTURE_EQPT,
    ReportCategory.SKAVUTAKS,
    ReportCategory.ER, ReportCategory.OP, ReportCategory.GB, ReportCategory.EL, ReportCategory.KR,
    ReportCategory.GLASTAKS, ReportCategory.STORTAKS, ReportCategory.MCTAKS, ReportCategory.CAMPTAKS, ReportCategory.VK_UNITAKS,
    ReportCategory.UNKNOWN
  ];

  schema: string;
  name: string;
  enabled: boolean;
  allowNewReport: boolean;
  copyable: boolean;
  usesQapter: boolean;
  cssClass: string;
  supportedRapportTypes: RapportType[];
  reportTypeName: string;

  public static getReportCategory(schema: string): ReportCategory {
    schema = isNullOrUndefined(schema) ? '' : schema;
    const filteredReportTypes = ReportCategory.ALL_REPORT_CATEGORIES.filter(next => next.schema === schema);
    if (filteredReportTypes.length === 1) {
      return filteredReportTypes[0];
    } else if (filteredReportTypes.length > 1) {
      throw new Error('ReportType: Found multiple reportTypes for[' + schema + ']');
    } else {
      throw new Error('ReportType: No reportType exists for[' + schema + ']');
    }
  }

  public static isOfRapportType(reportType: string, rapportType: RapportType): boolean {
    return ReportCategory.getReportCategory(reportType).supportsRapportType(rapportType);
  }

  /**
   * If the reporttype only supports one RapportType, this is returned - otherwise it will be the main flow (RapportType.H)
   *
   * @param schema
   */
  public static getAftaleValueType(schema: string): string {
    return ReportCategory.getReportCategory(schema).reportTypeName;
  }

  public static iconCssClass(schema: string): string {
    return this.getReportCategory(schema).cssClass;
  }

  public static enabled(schema: string): boolean {
    return this.getReportCategory(schema).enabled;
  }

  public static usesQapter(schema: string): boolean {
    return this.getReportCategory(schema).usesQapter;
  }

  public static isCopyable(schema: string): boolean {
    return this.getReportCategory(schema).copyable;
  }

  public static possibleToCreateNewReport(schema: string): boolean {
    return this.getReportCategory(schema).allowNewReport;
  }

  public static nameFrom(schema: string): string {
    const reportType = this.getReportCategory(schema);
    return reportType ? reportType.name : schema;
  }

  public static canDiff(rapportType: string): boolean {
    return !isNullOrUndefined(rapportType) && rapportType !== RapportType.HT.type;
  }

  public static canAutoflex(reportType: string): boolean {
    return !isNullOrUndefined(reportType) &&
      (reportType !== RapportType.HT.type && reportType !== RapportType.EL.type && reportType !== RapportType.ER.type);
  }

  public static autotaksRepairType(schema: string): RepairType {
    //autotaks, autotaks-lorry and vk-glastaks flows only operate on specific repairtypes
    let result: RepairType;
    const reportCategory = this.getReportCategory(schema);
    if (ReportCategory.AUTOTAKS === reportCategory || ReportCategory.GLASTAKS === reportCategory) {
      result = RepairType.P;
    } else if (ReportCategory.AUTOTAKS_LORRY === reportCategory) {
      result = RepairType.L;
    } else if (ReportCategory.ASSESSMENT_MCTAKS === reportCategory) {
      result = RepairType.M;
    } else if (ReportCategory.ASSESSMENT_CAMPTAKS === reportCategory) {
      result = RepairType.D;
    } else if (ReportCategory.AGRICULTURE_EQPT === reportCategory) {
      result = RepairType.A;
    } else if (ReportCategory.ASSESSMENT_BUILDING === reportCategory) {
      result = RepairType.B;
    } else if (ReportCategory.ASSESSMENT_MOVABLES === reportCategory) {
      result = RepairType.Ø;
    } else if (ReportCategory.ASSESSMENT_MISC === reportCategory) {
      result = RepairType.I;
    }
    return result;
  }

  public static flowTwoReportCategory(schema: string, reportType: RapportType, reportName: string, usesQapter): ReportCategory {
    return new ReportCategory(schema, reportName, true, false, false, usesQapter, 'fa-car', [reportType], undefined);
  }

  public static vkReportCategory(schema: string, reportName: string, cssClass: string, usesQapter, aftaleValueType: string): ReportCategory {
    return new ReportCategory(schema, reportName, true, false, true, usesQapter, cssClass, [RapportType.H], aftaleValueType);
  }

  constructor(schema: string, name: string, enabled: boolean, allowNewReport: boolean, copyable: boolean, usesQapter: boolean, cssClass: string, supportedRapportTypes: RapportType[], reportTypeName: string) {
    this.schema = schema;
    this.name = name;
    this.enabled = enabled;
    this.allowNewReport = allowNewReport;
    this.copyable = copyable;
    this.usesQapter = usesQapter;
    this.cssClass = cssClass;
    this.supportedRapportTypes = supportedRapportTypes;
    this.reportTypeName = reportTypeName;
  }

  public supportsRapportType(rapportType: RapportType): boolean {
    return this.supportedRapportTypes.filter(next => next.type === rapportType.type).length > 0;
  }

  public isEqualToAny(reportCategories: ReportCategory[]): boolean {
    return reportCategories.filter(value => value.equals(this)).length > 0;
  }

  public mainRapportType(): RapportType {
    let rapportType: RapportType;
    if (this.supportedRapportTypes.length === 1) {
      rapportType = this.supportedRapportTypes[0];
    } else {
      const rapportTypes = this.supportedRapportTypes.filter(value => value.type === RapportType.H.type);
      if (rapportTypes.length === 1) {
        rapportType = this.supportedRapportTypes[0];
      } else {
        throw new Error('Cannot determine the main RapportType for[' + this.schema + ']');
      }
    }
    return rapportType;
  }

  public equals(reportCategory: ReportCategory): boolean {
    return this.schema === reportCategory.schema;
  }
}
