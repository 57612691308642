import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormSaver} from '../../../../shared/service/core/form-saver.service';
import {ForsiReportKeyPipe} from '../../../../shared/pipe/forsi-report-key.pipe';
import {ForsiReportKeyShortPipe} from '../../../../shared/pipe/forsi-report-key-short.pipe';
import {ClientStateDetails} from '../../..';
import {NgForm} from '@angular/forms';
import {DraftEditService} from '../../draft-edit.service';
import {BootstrapGrowlService} from '../../../../shared/ui/ngx-bootstrap-growl';
import {PrincipalService} from '../../../../shared';
import {ContextMenu, ContextMenuItem} from '../../../../shared/ui/context-menu/context-menu.model';
import {AttachmentUseCase} from '../../../../shared/model/attachment-use-case.model';
import {TextLibraryTypeEnum} from '../../../../shared/service/text-library-type.enum';
import {DraftService} from '../../../service/draft.service';
import {UIUtils} from '../../../../shared/utils/ui-utils';
import {Subject} from 'rxjs';
import {ClientStateDifferService} from '../../checkopslag/client-state-differ.service';
import {DraftEditFlowTwoComponent} from '../../draft-edit-flow-two.component';
import {takeUntil} from 'rxjs/operators';
import {ClientNavigationService} from '../../../../shared/service/client-navigation.service';

/**
 * Is an Autotaks Flow Two component
 */
@Component({
  selector: 'lc-taks-el-flow-two',
  templateUrl: './taks-el-flow-two.component.html',
  styleUrls: [
    '../../../draft.scss', '../../draft-edit.scss', './../taks-autotaks.scss'
  ],
  providers: [FormSaver, ClientStateDifferService]
})
export class TaksElFlowTwoComponent extends DraftEditFlowTwoComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  isAgreementsLoading = true;
  @ViewChild('f', {static: true}) public form: NgForm;
  attachmentUseCase = AttachmentUseCase.Draft;
  textLibraryType = TextLibraryTypeEnum.BESKED;

  constructor(principal: PrincipalService,
              clientStateService: DraftService,
              draftEditService: DraftEditService,
              reportKeyPipe: ForsiReportKeyPipe,
              reportKeyShortPipe: ForsiReportKeyShortPipe,
              formSaver: FormSaver,
              router: Router,
              route: ActivatedRoute,
              bootstrapGrowlService: BootstrapGrowlService,
              el: ElementRef,
              clientNavigationService: ClientNavigationService) {
    super(formSaver, reportKeyPipe, reportKeyShortPipe, clientStateService, draftEditService, principal, router, route, bootstrapGrowlService, el, clientNavigationService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    //specific flow initialization goes here

    this.route.params
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(params => {
        this.details.token = params['token'];
        const fragment = this.route.snapshot.fragment;
        this.draftEditService.getRefreshedClientStateObservable(this.details.token)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((clientStateDetails) => {
            this.initializeWithClientState(clientStateDetails);
            this.isLoading = false;
            this.possibleNavigateToFragmentUrlPart(fragment);
          }, error => {
            this.router.navigate(['/draft']);
          });
      });
  }

  initializeWithClientState(clientStateDetails: ClientStateDetails): void {
    super.initializeWithClientState(clientStateDetails);
    console.log('clientstate', this.details.elReportDetails);
    this.getMessages();
  }

  setupContextMenu(): void {
    this.contextMenu = new ContextMenu();
    const uiUtils = new UIUtils();
    this.contextMenu.addItem(new ContextMenuItem('fa-vcard-o', 'Biloplysninger', 'car-details', (item) => {
      uiUtils.scrollToElementId(['car-details']);
    }));

    this.contextMenu.addItem(new ContextMenuItem('fa-money', 'Erstatning', 'replacement', (item) => {
      uiUtils.scrollToElementId(['replacement']);
    }));

    this.contextMenu.addItem(new ContextMenuItem('fa-edit', 'Bemærkninger', 'remarkscontainer', (item) => {
      uiUtils.scrollToElementId(['remarkscontainer']);
    }));
  }

  setupDraftActionMenu(): void {
    const calcItem = this.createCalculateActionMenu();
    calcItem.disabledCallback = () => this.formSaver.busy;
    this.draftMenu.addItem(calcItem);
    if (this.clientNavigationService.getPreviousLocation() === '/search') {
      this.draftMenu.addItem(this.createBackMenuItem());
    }
    this.draftMenu.addItem(this.createDmrLookupMenuItem());
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
