import {Injectable} from '@angular/core';
import {VERSION} from '../../app.constants';
import {LoggingDTO} from '../dto/logging-dto.model';
import EnvironmentUtils from '../utils/environment-utils';

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  public static LOG_URL = 'clientlog/error/';

  constructor() {
  }

  public logError(username: string, url: string, error: Error): void {
    const loggingDTO = new LoggingDTO();
    loggingDTO.key = '';
    loggingDTO.url = url ? url : '-';
    loggingDTO.version = VERSION;
    loggingDTO.env = EnvironmentUtils.isEap() ? 'eap' : '/';
    loggingDTO.message = error.message ? error.message : '-';
    loggingDTO.stacktrace = error.stack ? error.stack : '-';
  }
}
