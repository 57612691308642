<div class="modal-header pre-size header-style" style="text-align: center">
    <h3 class="text-truncate">
        Skadenotat
    </h3>
    <button aria-label="Close" data-dismiss="modal" class="btn-close" type="button" (click)="cancel()"></button>
</div>

<div class="modal-content">
    <div class="modal-body modal-body-style">
        <div class="container-fluid">
            <lc-damages-note [damagesNoteText]="damagesNoteText" [reportType]="reportType"
                             (damagesNoteTextUpdatedEmitter)="textUpdated($event)"></lc-damages-note>
        </div>
    </div>
</div>
