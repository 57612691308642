import {Component, OnDestroy} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {DraftService} from '../../../service/draft.service';
import {DraftCopyOptionsDTO} from '../../../dto/draft-copy-options.dto';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {isNullOrUndefined} from '../../../../shared/utils/object-utils';
import {ReportCategory} from '../../../../shared/model/report-category.model';
import {PrincipalService} from '../../../../shared';

@Component({
  templateUrl: './copy-draft.component.html',
  styleUrls: ['./copy-draft.component.scss']
})
export class CopyDraftModalComponent implements OnDestroy {
  private unsubscribe$ = new Subject<void>();
  beregningsData: boolean;
  changeFlowTypeForCopy: boolean;
  bilag: boolean;
  niTusindNr: boolean;
  token: string;
  showLoading: boolean;
  reportTypeOfCopy: string = null;
  reportType: string = null;
  hasAutoflexBookings: Observable<boolean>;

  constructor(public activeModal: NgbActiveModal,
              private router: Router,
              private draftService: DraftService,
              private principal: PrincipalService
  ) {
    this.beregningsData = false;
    this.bilag = false;
    this.niTusindNr = false;
  }

  open(token: string, reportType?: string): void {
    this.token = token;
    this.reportType = reportType;
    if (ReportCategory.usesQapter(reportType)) {
      this.reportTypeOfCopy = reportType;
    }
    this.hasAutoflexBookings = this.draftService.hasAutoflexBookings(token);
  }

  public cancel(): void {
    this.activeModal.dismiss('cancel');
  }

  doCopy(): void {
    this.showLoading = true;
    if (this.token) {
      const dopyDraftOptions = new DraftCopyOptionsDTO();
      dopyDraftOptions.copyBeregningsData = this.beregningsData;
      dopyDraftOptions.copyBilagData = this.bilag;
      dopyDraftOptions.copyToNextAvailable9000Number = this.niTusindNr;
      dopyDraftOptions.changeFlowTypeForCopy = this.isTypeChanged();

      this.draftService.copyDraft(this.token, dopyDraftOptions)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(draftCopyResult => {
          this.showLoading = false;
          this.cancel();
          this.router.navigate(['/draft/edit/', draftCopyResult.token]);
        }, error => {
          this.cancel();
        });
    }
  }

  isTypeChanged(): boolean {
    return !isNullOrUndefined(this.reportTypeOfCopy) && this.reportType !== this.reportTypeOfCopy;
  }

  onDamageTypeChanged(): void {
    this.beregningsData = false;
  }
  showCopyPossibilities(): boolean {
    return !isNullOrUndefined(this.reportTypeOfCopy) && this.principal.isVK();
  }
  
  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
