import {Pipe, PipeTransform} from '@angular/core';
import {DeliveryTerm, SparePartMatchDTO} from '../../dto/spare-part-match-response-dto.model';
import {ObjectUtils} from '../../../shared/utils/object-utils';

@Pipe({
  name: 'autoflexDeliveryTerm'
  })
export class AutoflexDeliveryTermPipe implements PipeTransform {
  transform(data: SparePartMatchDTO): string {
    if (!ObjectUtils.exists(data)) {
      return '';
    }
    switch(data.deliveryTerms) {
      case DeliveryTerm.DAY_TO_DAY:
        return 'Dag til dag';
        break;
      case DeliveryTerm.WITHIN_THREE_DAYS:
        return 'Inden 3 dage';
        break;
      case DeliveryTerm.WITHIN_FIVE_DAYS:
        return 'Inden 5 dage';
        break;
      case DeliveryTerm.WITHIN_SEVEN_DAYS:
        return 'Inden 7 dage';
        break;
      case DeliveryTerm.WITHIN_TEN_DAYS:
        return 'Inden 10 dage';
        break;
      case DeliveryTerm.WITHIN_FOURTEEN_DAYS:
        return 'Inden 14 dage';
        break;
      case DeliveryTerm.ESTIMATE:
        return 'Estimeret inden ' + data.deliveryEstimate + ' dage';
        break;
      default:
        return '';
    }
  }
}
