import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ClientStateDetails} from '../../../model/client-state-details.model';
import {FormSaver} from '../../../../shared/service/core/form-saver.service';
import {NgForm} from '@angular/forms';
import {Validatable} from '../validatable.interface';
import {DateUtils} from '../../../../shared/utils/date-utils';
import {PrincipalService} from '../../../../shared/service/auth/principal.service';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {DateService} from '../../../../shared/service/date.service';

@Component({
  selector: 'lc-draft-valuation',
  templateUrl: './draft-valuation.component.html',
  styleUrls: [
    '../../draft-common.scss'
  ]
})
export class DraftValuationComponent implements Validatable, OnInit {

  private _damageDate: NgbDateStruct;

  @Input() formId: string;
  @Input() details: ClientStateDetails;

  @Input()
  set damageDate(value: NgbDateStruct) {
    this._damageDate = value;
    this.setRepairLimit(value)
  }

  get damageDate(): NgbDateStruct {
    return this._damageDate;
  }

  repairLimits = [
    {damageFrom: 0, limit: 55},
    {damageFrom: 1546297200000, limit: 60},
    {damageFrom: 1577833200000, limit: 65}
  ];

  repairLimit = 55;

  @ViewChild('f', {static: true}) public cform: NgForm;

  constructor(private formSaver: FormSaver,
              private principal: PrincipalService,
              public dateService: DateService) {
  }

  ngOnInit(): void {
    this.formSaver.registerForm(this.formId, this.cform);
  }

  isValid(): boolean {
    return this.cform.form.valid;
  }

  private setRepairLimit(damageDateStruct: NgbDateStruct): void {
    let damageDate = Date.now();
    if (damageDateStruct) {
      damageDate = new Date(damageDateStruct.year, damageDateStruct.month - 1, damageDateStruct.day).getTime();
    }
    for (const repairLimit of this.repairLimits) {
      if (damageDate > repairLimit.damageFrom) {
        this.repairLimit = repairLimit.limit
      }
    }
  }

  onRepairLimitExceededChange(): void {
    if (this.details.valuation.repairLimitExceeded) {
      this.details.valuation.erstatningType = 'I';
      this.details.valuation.erstatningBeloeb = null;
      this.details.valuation.erstatningMoms = null;
      this.details.valuation.erstatningAfgift = null;
      this.details.valuation.resterType = 'I';
      this.details.valuation.resterBeloeb = null;
      this.details.valuation.resterTold = null;
    }
  }

  onChangePercentOfValuation(): void {
    if (this.details.valuation.percentOfValue) {
      this.details.valuation.reparationsGraense = this.getPercentValue(this.details.valuation.percentOfValue);
    } else {
      this.details.valuation.reparationsGraense = null;
    }
  }

  onChangeHandelsvaerdi(): void {
    this.details.valuation.reparationsGraense = null;
    this.details.valuation.percentOfValue = null;
  }

  onReparationsGraenseChanged(): void {
    if (this.details.valuation.reparationsGraense !== this.getPercentValue(this.details.valuation.percentOfValue)) {
      this.details.valuation.percentOfValue = null;
    }
  }

  public pastOrPresentMaxDateSelection(): any {
    return DateUtils.getNgbDateStructToday();
  }

  public getPercentValue(percent: number): number {
    return Math.floor(!this.details.vehicle.handelsvaerdi ? 0 : (this.details.vehicle.handelsvaerdi * percent / 100));
  }

  public isUnitaks(): boolean {
    return this.principal.isTaksator() && this.details.isUnitaks();
  }
}
