import {Injectable} from '@angular/core';
import {forkJoin, Subject} from 'rxjs';
import {mergeMap, tap} from 'rxjs/operators';
import {LcActionMenu, LcActionMenuItem} from '../../../../../shared/ui/bottombar/lc-action-menu.model';
import {ApprovalChoiceDTO, ApprovePossibilityType} from '../../../../../shared/dto/approval-choice-dto.model';
import {CreateOfferDtoModel} from '../../../../../shared/dto/create-offer-dto.model';
import {ClientStateDetails} from '../../../..';
import {DraftEditService} from '../../../draft-edit.service';
import {DraftService} from '../../../../service/draft.service';
import {ForsiReportKeyPipe} from '../../../../../shared/pipe/forsi-report-key.pipe';
import {ForsiReportKeyShortPipe} from '../../../../../shared/pipe/forsi-report-key-short.pipe';
import {ReportCategory} from '../../../../../shared/model/report-category.model';
import {isNullOrUndefined} from '../../../../../shared/utils/object-utils';
import {PrincipalService} from '../../../../../shared';
import {WorkshopUserService} from '../../service/workshop-user.service';
import {WorkshopUserDTO} from '../../model/workshop-user-dto.model';

@Injectable()
export class VkDraftApproveViewModel {
  loading: boolean;
  bottomMenu: LcActionMenu;
  navigateBackCallback: Function;
  navigateToEditCallback: Function;
  approveCallback: (token, ignoreValidation?: boolean, ignoreValidationReason?: string) => void;
  approvalChoices: ApprovalChoiceDTO[];
  approvalChoice: CreateOfferDtoModel = new CreateOfferDtoModel();
  requestingContactChoice: ApprovalChoiceDTO;
  multipleCasesChoice: ApprovalChoiceDTO;
  private _token: string;
  private tokenSub: Subject<string> = new Subject<string>();
  clientStateDetails: ClientStateDetails;
  bottomMenuTitle: string;
  bottomMenuShortTitle: string;
  menuIconClass: string = null;

  responsibleUser: WorkshopUserDTO;
  vkUsers: WorkshopUserDTO[];

  getSelectedChoice(): ApprovalChoiceDTO {
    if (this.approvalChoices.length === 0 || this.approvalChoice.approvePossibilityType === ApprovePossibilityType.NONE) {
      return null;
    }
    if (this.approvalChoice.approvePossibilityType === 'REQUESTING_CONTACT') {
      return this.requestingContactChoice;
    }
    if (this.approvalChoice.approvePossibilityType === 'MULTIPLE_DAMAGES') {
      return this.multipleCasesChoice;
    }
  }

  set token(value: string) {
    this._token = value;
    this.tokenSub.next(value);
  }

  get token(): string {
    return this._token;
  }

  constructor(private draftEditService: DraftEditService,
              private draftService: DraftService,
              private principalService: PrincipalService,
              private workshopUserService: WorkshopUserService,
              private reportKeyPipe: ForsiReportKeyPipe,
              private reportKeyShortPipe: ForsiReportKeyShortPipe) {
    this.setupBottomActionMenu();
    this.setupObservables();
  }

  private setupBottomActionMenu(): void {
    this.bottomMenu = new LcActionMenu();
    const backMenuItem = new LcActionMenuItem('fa-arrow-left', () => {
      if (!isNullOrUndefined(this.navigateBackCallback)) {
        this.navigateBackCallback(this._token);
      }
    }, 'Tilbage');
    backMenuItem.disabledCallback = () => this.loading;
    this.bottomMenu.addItem(backMenuItem);

    const approveMenuItem = new LcActionMenuItem('fa-check', () => {
      if (!isNullOrUndefined(this.approveCallback)) {
        this.approveCallback(this._token);
      }
    }, 'OK');
    approveMenuItem.buttonClass = 'btn-success';
    approveMenuItem.disabledCallback = () => this.loading;
    this.bottomMenu.addItem(approveMenuItem);
  }

  private setupObservables(): void {
    this.tokenSub.pipe(
      mergeMap(token => this.draftEditService.getClientStateObservable(token)),
      tap(clientState => this.clientStateDetails = clientState),
      mergeMap(clientState => forkJoin([this.draftService.getApprovalChoices(clientState.selskab), this.workshopUserService.getAll()])
      )
    ).subscribe( ([choices, users]) => {
      this.approvalChoices = choices;
      choices.forEach(choice => {
        switch (choice.approvePossibilityType) {
          case ApprovePossibilityType.REQUESTING_CONTACT:
            this.requestingContactChoice = choice;
            break;
          case ApprovePossibilityType.MULTIPLE_DAMAGES:
            this.multipleCasesChoice = choice;
            break;
        }
      });
      this.bottomMenuTitle = this.reportKeyPipe.transform(this.clientStateDetails.proformaOrdreNummer);
      this.bottomMenuShortTitle = this.reportKeyShortPipe.transform(this.clientStateDetails.proformaOrdreNummer);
      this.menuIconClass = ReportCategory.iconCssClass(this.clientStateDetails.schema);

      this.vkUsers = users;
      this.responsibleUser = users.find(user => user.id === this.principalService.getCurrentPrincipal().id);

      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }
}

