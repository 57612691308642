import {NgbForsiDateMomentParserFormatter} from 'app/shared';
import {ValuationDTO} from 'app/shared/dto/valuation-dto.model';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

export class Valuation {
  resterType = 'I';
  resterTold: boolean;
  resterBeloeb: number;

  erstatningType = 'I';
  erstatningMoms: boolean;
  erstatningAfgift: boolean;
  erstatningBeloeb: number;

  repairLimitExceeded: boolean;

  statusBesigtiges: boolean;
  statusNummerplader: boolean;
  statusRegistreringsattest: boolean;
  statusStelNummer: boolean;

  reparationsGraense: number;
  nyVaerdi: number;

  rekvirent: string;
  rekvireretDato: NgbDateStruct;
  takseringsDato: NgbDateStruct;

  percentOfValue: number;

  public static fromDTO(dto: ValuationDTO): Valuation {
    const result = new Valuation();

    result.erstatningBeloeb = dto.erstatningBeloeb;
    result.erstatningMoms = dto.erstatningMoms;
    result.erstatningAfgift = dto.erstatningAfgift;
    result.erstatningType = dto.erstatningType;

    result.nyVaerdi = dto.nyVaerdi;
    result.rekvirent = dto.rekvirent;
    result.reparationsGraense = dto.reparationsGraense;
    result.repairLimitExceeded = dto.repairLimitExceeded;

    result.resterBeloeb = dto.resterBeloeb;
    result.resterTold = dto.resterTold;
    result.resterType = dto.resterType;

    result.statusBesigtiges = dto.statusBesigtiges;
    result.statusNummerplader = dto.statusNummerplader;
    result.statusRegistreringsattest = dto.statusRegistreringsattest;
    result.statusStelNummer = dto.statusStelNummer;

    const parser = new NgbForsiDateMomentParserFormatter();
    result.takseringsDato = parser.parse(dto.takseringsDato);
    result.rekvireretDato = parser.parse(dto.rekvireretDato);

    result.percentOfValue = dto.percentOfValue;

    return result;
  }

  public toDTO(): ValuationDTO {
    const dto = new ValuationDTO();

    dto.erstatningBeloeb = this.erstatningBeloeb;
    dto.erstatningMoms = this.erstatningMoms;
    dto.erstatningAfgift = this.erstatningAfgift;
    dto.erstatningType = this.erstatningType;

    dto.nyVaerdi = this.nyVaerdi;
    dto.rekvirent = this.rekvirent;
    dto.reparationsGraense = this.reparationsGraense;
    dto.repairLimitExceeded = this.repairLimitExceeded;

    dto.resterBeloeb = this.resterBeloeb;
    dto.resterTold = this.resterTold;
    dto.resterType = this.resterType;

    dto.statusBesigtiges = this.statusBesigtiges;
    dto.statusNummerplader = this.statusNummerplader;
    dto.statusRegistreringsattest = this.statusRegistreringsattest;
    dto.statusStelNummer = this.statusStelNummer;

    const parser = new NgbForsiDateMomentParserFormatter();
    dto.takseringsDato = parser.format(this.takseringsDato);
    dto.rekvireretDato = parser.format(this.rekvireretDato);

    dto.percentOfValue = this.percentOfValue;

    return dto;
  }

}
