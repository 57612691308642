import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {isNullOrUndefined} from './object-utils';
import {NumberUtils} from './number-utils';

const DATE_REG_EXP_POINT = '^\\d{1,2}\\.\\d{1,2}\\.\\d{4}$';

export class DateUtils {
  public static isValidOrEmptyDate(date: string): boolean {
    return (date == null) || (date.length === 0) || (date.search(DATE_REG_EXP_POINT) !== -1);
  }

  public static ngbDateStructToString(dateStruct: NgbDateStruct, del: string = '.'): string {
    if (isNullOrUndefined(dateStruct)) {
      return '';
    }
    return DateUtils.two(dateStruct.day) + del + DateUtils.two(dateStruct.month) + del + dateStruct.year;
  }

  public static ngbDateStructToISO8601Date(dateStruct: NgbDateStruct): string {
    return `${dateStruct.year}-${DateUtils.two(dateStruct.month)}-${DateUtils.two(dateStruct.day)}`;
  }

  public static dateToISO8601Str(date: Date): string {
    return DateUtils.ngbDateStructToISO8601Date(DateUtils.ngbDateStructFromDate(date));
  }
  private static two(n: number): string {
    if (n < 10) {
      return '0' + n.toString();
    } else {
      return n.toString();
    }
  }

  public static getNgbDateStructToday(): NgbDateStruct {
    return DateUtils.ngbDateStructFromDate(new Date());
  }

  public static ngbDateStructFromDate(date: Date): NgbDateStruct {
    if (!isNullOrUndefined(date)) {
      return {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
      };
    } else {
      return null;
    }
  }

  public static ngbDateStructFromMilliseconds(millis: number): NgbDateStruct {
    const date = new Date(millis);
    return DateUtils.ngbDateStructFromDate(date);
  }

  public static dateFromNgbDateStruct(dateStruct: NgbDateStruct): Date {
    return !isNullOrUndefined(dateStruct) ? new Date(dateStruct.year, dateStruct.month - 1, dateStruct.day) : null;
  }

  public static dateIsToday(date: Date): boolean {
    const dateWithoutTimestamp = DateUtils.dateWithoutTimestamp(date);
    const todayWithoutTimestamp = DateUtils.dateWithoutTimestamp(new Date());
    return todayWithoutTimestamp.getTime() === dateWithoutTimestamp.getTime();
  }

  public static dateWithoutTimestamp(date: Date): Date {
    return DateUtils.dateFromNgbDateStruct(DateUtils.ngbDateStructFromDate(date));
  }

  static compare(d1: Date | string | number, d2: Date | string | number): number {
    return NumberUtils.compare(new Date(d1).getTime(), new Date(d2).getTime());
  }

  static equalsDateStruct(d1: NgbDateStruct , d2: NgbDateStruct): boolean {
    const bothAreNull = !!!d1 && !!!d2;
    const anyAreNull = !!!d1 || !!!d2;
    if (bothAreNull) {
      return true;
    } else if (anyAreNull) {
      return false;
    } else {
      return d1.year === d2.year && d1.month === d2.month && d1.day === d2.day;
    }
  }
}
