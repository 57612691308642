import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {OAuthAuthenticationService} from '../../shared/o-auth/forsi/o-auth-authentication-service';

@Component({
  templateUrl: './error.component.html'
})
export class ErrorComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  header: string;
  errorMessage: string;
  error403: boolean;
  showLogoutButton: boolean;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private oAuthAuthenticationService: OAuthAuthenticationService) {
  }

  ngOnInit(): void {
    this.route.data.pipe(takeUntil(this.unsubscribe$)).subscribe((routeData) => {
      this.header = routeData.pageTitle;
      this.showLogoutButton = routeData.showLogoutButton;
      if (routeData.error403) {
        this.error403 = routeData.error403;
      }
      if (routeData.errorMessage) {
        this.errorMessage = routeData.errorMessage;
      }
    });
  }

  didClick(): void {
    this.oAuthAuthenticationService.removeAuthentication().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() =>  {
      this.router.navigate(['']);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
