import {AttachmentMetadataDTO} from './attachment-metadata-dto.model';

export class AttachmentMetadata {
  attachmentId: number;
  date: Date;
  latitude: number;
  longitude: number;
  camera: string;
  distanceToBaseline: number;
  
  public static convertFromAttachmentMetadataDTO(dto: AttachmentMetadataDTO): AttachmentMetadata {
    const model = new AttachmentMetadata();
    model.attachmentId = dto.attachmentId;
    model.date = new Date(dto.date);
    model.latitude = dto.latitude;
    model.longitude = dto.longitude;
    model.camera = dto.camera;
    return model;
  }
}
