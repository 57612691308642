import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BehaviorSubject, combineLatest, from, Observable, Subject} from 'rxjs';
import {map, mergeMap, shareReplay, takeUntil, tap} from 'rxjs/operators';
import {ClientStateVideoRequestService} from '../client-state-video-request.service';
import {isNullOrUndefined} from '../../../../utils/object-utils';
import {VideoRequestState} from '../model/video-request-dto.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LoadingSpinnerUtil} from '../../../../utils/loading-spinner-util';
import {ValueObsModel} from '../../../../model/value-obs.model';
import {AssessorVideoRequestPopupComponent} from './assessor-video-request-popup/assessor-video-request-popup.component';

@Component({
  selector: 'lc-assessor-video-request',
  templateUrl: './assessor-video-request.component.html',
  providers: [LoadingSpinnerUtil]
})
export class AssessorVideoRequestComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  @Input() token: string;
  @Output() videoRequestUpdated = new EventEmitter();

  public reloadVideoRequestState$ = new BehaviorSubject<void>(void 0);
  public buttonStateValueObs = new ValueObsModel<AssessorVideoRequestButtonState>(AssessorVideoRequestButtonState.REQUEST_NOT_ALLOWED);
  public showVideoRequestButton$: Observable<boolean>;
  public videoRequestIsUploaded$: Observable<boolean>;
  public showCancelVideoRequestButton$: Observable<boolean>;

  constructor(private clientStateVideoRequestService: ClientStateVideoRequestService,
              public spinnerUtil: LoadingSpinnerUtil,
              private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.setupObservables();
  }

  private setupObservables(): void {
    const possibleToRequestVideoUpload$ = this.clientStateVideoRequestService.possibleToRequestForVideoUpload(this.token).pipe(shareReplay());
    this.reloadVideoRequestState$.pipe(
      tap(() => this.spinnerUtil.startLoading()),
      mergeMap(() => combineLatest([
        possibleToRequestVideoUpload$,
        this.clientStateVideoRequestService.getVideoRequest(this.token)])),
      map(([allowedToRequestForVideos, videoRequestResult]) => this.decideVideoRequestButtonState(videoRequestResult, allowedToRequestForVideos)),
      takeUntil(this.unsubscribe$)
    ).subscribe(state => {
      this.buttonStateValueObs.value = state;
      this.spinnerUtil.stopLoading();
    }, (error) => {
      this.spinnerUtil.stopLoading();
    });
    this.videoRequestIsUploaded$ = this.buttonStateValueObs.$.pipe(map(buttonState => AssessorVideoRequestButtonState.RESPONDED === buttonState));
    this.showVideoRequestButton$ = this.buttonStateValueObs.$.pipe(map(buttonState => buttonState === AssessorVideoRequestButtonState.READY_TO_REQUEST));
    this.showCancelVideoRequestButton$ = this.buttonStateValueObs.$.pipe(map(buttonState => buttonState === AssessorVideoRequestButtonState.REQUESTED || buttonState === AssessorVideoRequestButtonState.RESPONDED));
  }

  public showVideoRequestPopup(): void {
    const modalRef = this.modalService.open(AssessorVideoRequestPopupComponent, {size: 'lg'});
    from(modalRef.result).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(message => {
      this.performVideoRequestOperation(this.clientStateVideoRequestService.createVideoRequestByAssessorClientState(this.token, message));
    }, () => {});
  }

  public createVideoRequest(): void {
    this.showVideoRequestPopup();
  }

  public cancelVideoRequest(): void {
    this.performVideoRequestOperation(this.clientStateVideoRequestService.deleteVideoRequestByAssessorClientState(this.token));
  }

  public performVideoRequestOperation(videoRequestOperation$: Observable<any>): void {
    if (this.spinnerUtil.isLoading) {
      return;
    }
    this.spinnerUtil.startLoading();
    videoRequestOperation$.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      this.spinnerUtil.stopLoading();
      this.reloadVideoRequestState$.next();
      this.videoRequestUpdated.emit();
    }, (error) => {
      console.log('Failed to render assessor video request', error);
      this.spinnerUtil.stopLoading();
      this.reloadVideoRequestState$.next();
    });
  }

  private decideVideoRequestButtonState(videoRequestResult, allowedToRequestForVideos): AssessorVideoRequestButtonState {
    let result: AssessorVideoRequestButtonState;
    const videoRequest = videoRequestResult?.videoRequest;
    if (videoRequest) {
      switch (videoRequest.state) {
        case VideoRequestState.OPEN:
          const isUploaded = !isNullOrUndefined(videoRequest.uploadedAt) && videoRequest.uploadedAt > videoRequest.requestedAt;
          result = isUploaded ? AssessorVideoRequestButtonState.RESPONDED : AssessorVideoRequestButtonState.REQUESTED;
          break;
        case VideoRequestState.CLOSED:
          result = allowedToRequestForVideos ? AssessorVideoRequestButtonState.READY_TO_REQUEST : AssessorVideoRequestButtonState.REQUEST_NOT_ALLOWED;
          break;
        default:
          throw new Error(`Unknown VideoRequest state[${videoRequest.state}] for token[${this.token}]`);
      }
    } else {
      result = allowedToRequestForVideos ? AssessorVideoRequestButtonState.READY_TO_REQUEST : AssessorVideoRequestButtonState.REQUEST_NOT_ALLOWED;
    }
    return result;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}

export enum AssessorVideoRequestButtonState {
  REQUEST_NOT_ALLOWED = 'REQUEST_NOT_ALLOWED',
  READY_TO_REQUEST = 'READY_TO_REQUEST',
  REQUESTED = 'REQUESTED',
  RESPONDED = 'RESPONDED'
}
