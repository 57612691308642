<div class="row">
    <div class="col-lg-12 mt-3" *ngIf="deliveryNote">
		<textarea #deliveryNoteTextArea
                  id="deliveryNoteText" class="form-control delivery-note-print"
                  [(ngModel)]="deliveryNote.print"
                  (ngModelChange)="fixMaxLineLength(deliveryNoteTextArea)"
                  lcAutosize></textarea>
    </div>
</div>
<lc-bottombar [menu]="bottomMenu"></lc-bottombar>
