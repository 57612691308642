<lc-bottombar [menu]="draftMenu" [iconClass]="this.bottomMenuIconClass" [title]="this.bottomMenuTitle"
              [shortTitle]="this.bottomMenuShortTitle"></lc-bottombar>
<div class="text-center" *ngIf="isLoading">
    <i class="fa fa-circle-o-notch fa-spin fop-page-spinner"></i>
</div>
<router-outlet></router-outlet>
<form #f="ngForm" autocomplete="off">
    <div *ngIf="!isLoading"
         [ngClass]="{'form-dirty':this.showClientStateAsDirty(), 'form-error':this.showClientStateAsInError()} ">
        <lc-context-menu [menu]="this.contextMenu"></lc-context-menu>
        <div class="split-page">
            <div class="split-left d-none d-lg-block">&nbsp;</div>
            <div class="split-right">
                <div class="row" id="car-details">
                    <div class="col-12 col-sm-6">
                        <h2>Biloplysninger</h2>
                        <div class="card">
                            <div class="card-body">
                                <lc-flow2-car-details [formId]="'car-details'" [carDetails]="details.krReportDetails"
                                                      [aargang]="details.vehicle.aargang"
                                                      [readonly]="true"></lc-flow2-car-details>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" id="attachments">
                    <div class="col-12">
                        <h2>Bilag</h2>
                        <div class="card">
                            <div class="card-body">
                                <div *ngIf="attachmentSummary">
                                    <lc-draft-attachment
                                        [attachmentSummary]="attachmentSummary"
                                        (onAttachmentChanged)="updateAttachmentSummary(details.token)"
                                        [readonly]="false"
                                        [token]="details.token"
                                        [clientStateId]="details.id"
                                        [reportKey]="getAttachmentReportKey(details)"
                                        [registrationNumber]="getRegistrationNumber(details)"
                                    ></lc-draft-attachment>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" id="remarkscontainer">
                    <div class="col-12">
                        <h2>Bemærkninger</h2>
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-6">
                                        <lc-draft-remarks formId="remarks" [details]="details"></lc-draft-remarks>
                                    </div>
                                    <div class="col-md-6">
                                        <lc-intern-info [details]="details"></lc-intern-info>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row dynamic-padding-bottom">
                    <!-- Room for floating action buttons -->
                    <div class="col-md-12"></div>
                </div>
            </div>
        </div>
    </div>
</form>
