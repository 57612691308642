import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {FormSaver} from '../../../../../../shared/service/core/form-saver.service';
import {StringUtils} from '../../../../../../shared/utils/string-utils';
import {OpslagService} from '../../../../../../shared/service/opslag.service';
import {ErGbDetails} from '../../../../../model/er-details.model';
import {Valuation} from '../../../../../model/valuation.model';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {isNullOrUndefined} from '../../../../../../shared/utils/object-utils';

@Component({
  selector: 'lc-ergb-bidders',
  templateUrl: './ergb-bidders.component.html'
})
export class ErGbBiddersComponent implements OnInit, OnDestroy {
  public static readonly UNKNOWN_BIDDER: string = '*****';
  private unsubscribe$ = new Subject<void>();

  @Input() formId: string;
  @Input() erGbDetails: ErGbDetails;
  @Input() valuation: Valuation;
  @ViewChild('f', {static: true}) public cform: NgForm;
  by: string;

  constructor(private formSaver: FormSaver,
              private opslagService: OpslagService) {
  }

  ngOnInit(): void {
    this.formSaver.registerForm(this.formId, this.cform);
    this.updateCityByPostalCode();
  }

  /**
   * Return true if a bidder is selected and the bid is different from the resterBeloeb rom the original H report
   */
  public showBidderRemark(): boolean {
    let result = false;
    const budgiverSolgtTil = this.erGbDetails.budgiverSolgtTil;
    if (StringUtils.isNotEmpty(budgiverSolgtTil)) {
      const resterBeloeb = this.resterBeloeb();
      const bid = this.erGbDetails['bud' + budgiverSolgtTil];
      result = StringUtils.isNotEmpty(bid) && parseInt(bid, 10) !== resterBeloeb;
    }
    return result;
  }

  public resterBeloeb(): number {
    return isNullOrUndefined(this.valuation.resterBeloeb) ? 0 : this.valuation.resterBeloeb;
  }

  public onVkNrChange(bidderNo: string): void {
    const inputValue = this.erGbDetails['budgiver' + bidderNo];
    if (inputValue.includes('*')) {
      this.erGbDetails['budgiver' + bidderNo] = ErGbBiddersComponent.UNKNOWN_BIDDER;
    }
  }

  public disableBidderInfoFields(): boolean {
    return !this.hasUnknownBidder();
  }

  public hasUnknownBidder(): boolean {
    return this.erGbDetails.budgiver1 === ErGbBiddersComponent.UNKNOWN_BIDDER ||
      this.erGbDetails.budgiver2 === ErGbBiddersComponent.UNKNOWN_BIDDER ||
      this.erGbDetails.budgiver3 === ErGbBiddersComponent.UNKNOWN_BIDDER;
  }

  updateCityByPostalCode(): void {
    if (StringUtils.isNotEmpty(this.erGbDetails.budgiverPostNr)) {
      this.opslagService.getPostNr(Number(this.erGbDetails.budgiverPostNr))
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(postNr => {
          this.by = postNr ? postNr.navn : '';
        }, () => {
          this.by = '';
        });
    } else {
      this.by = '';
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
