<div class="modal-header pre-size header-style" style="text-align: center">
    <h3 class="text-truncate">
        Skift kodeord
    </h3>
    <div aria-label="Close" data-dismiss="modal" class="btn-close clickable" (click)="cancel()">
    </div>
</div>

<div class="modal-content">
    <div class="modal-body modal-body-style">
        <div class="row" style="margin-top: 20px;" *ngIf="!showSuccessFeedback()">
            <div class="col-md-12">
                <div class="pb-3">
                    <label for="username" style="text-align: right">Bruger ID</label>
                    <input [(ngModel)]="username" autocapitalize="none" autocorrect="off"
                           class="form-control"
                           type="text" #usernameInput id="username" name="username"
                           minlength=1 maxlength=17 size="30"
                           required/>
                </div>
                <div class="pb-3">
                    <label for="password" style="text-align: right">Kodeord</label>
                    <input [(ngModel)]="password" autocorrect="off" class="form-control"
                           type="password"
                           #passwordInput id="password" name="password"
                           minlength=1 maxlength=17 size="30"
                           required/>
                </div>
                <div class="pb-3">
                    <label for="newpassword" style="text-align: right">Nyt
                        kodeord</label>
                    <input [(ngModel)]="newPassword" autocorrect="off" class="form-control"
                           type="password"
                           #newPasswordInput id="newpassword" name="newpassword"
                           minlength=1 maxlength=17 size="30"
                           required/>
                </div>
                <div class="pb-3">
                    <label for="newpasswordrepeat" style="text-align: right">Gentag
                        nyt
                        kodeord</label>
                    <input [(ngModel)]="newPasswordRepeat" autocorrect="off" class="form-control"
                           type="password" id="newpasswordrepeat" name="newpasswordrepeat"
                           minlength=1 maxlength=17 size="30"
                           required/>
                </div>
            </div>
            <div class="col-12">
                <div class="pb-3 pull-right">
                    <button (click)="changePassword()" [disabled]="!isFormValid()" class="btn btn-primary"
                            style="margin-left: 10px; width: 100px;">
                        <lc-spinner-text [spin]="submitting" text="Skift kodeord"></lc-spinner-text>
                    </button>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div *ngIf="showErrorFeedback() && passwordRules" class="alert alert-danger">
                    <div *ngFor="let passwordRule of passwordRules">
                        {{passwordRule}}
                    </div>
                </div>
                <div class="alert alert-danger" *ngIf="showErrorFeedback()">
                    {{changepasswordErrorFeedback}}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 ">
                <div class="alert alert-warning" *ngIf="!showSuccessFeedback() && $capslockEnabled | async">
                    Bemærk at Caps Lock er slået til
                </div>
            </div>
        </div>

        <div class="row" style="margin-top: 20px;" *ngIf="showSuccessFeedback()">
            <div class="col-12">
                <div class="alert alert-success">
                    {{changepasswordSuccessFeedback}}
                </div>
                <div class="pull-right">
                    <button type="submit" class="btn btn-primary" (click)="cancel()">Luk</button>
                </div>
            </div>
        </div>
    </div>
</div>


