import {Routes} from '@angular/router';
import {UserRouteAccessService} from '../shared';
import {StatisticComponent} from './statistic.component';
import EnvironmentUtils from '../shared/utils/environment-utils';

export const STATISTIC_ROUTES: Routes = [
  {
    path: 'statistic',
    data: {
      authorities: EnvironmentUtils.getStatisticRoles(),
    },
    canActivate: [UserRouteAccessService],
    children: [
      {
        path: '',
        component: StatisticComponent,
        data: {
          authorities: EnvironmentUtils.getStatisticRoles(),
          pageTitle: 'Statistik',
          breadcrumb: 'Statistik'
        },
        canActivate: [UserRouteAccessService],
      },
    ]
  }
];
