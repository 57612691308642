<div class="text-center" *ngIf="isLoading">
    <i class="fa fa-circle-o-notch fa-spin fop-page-spinner"></i>
</div>
<div *ngIf="!isLoading" class="card sub-row-card">
    <div class="row m-0">
        <div class="col-12 col-sm-4 col-lg-6 m-2">
            <ng-container
                *ngTemplateOutlet="b2bText;">
            </ng-container>
        </div>
        <div class="col m-2">
            <div *ngIf="!showNoIntegrationRelationWarning(); else noIntegrationUserRelationText">
                <ng-container
                    *ngTemplateOutlet="b2bInput;">
                </ng-container>
            </div>
        </div>
    </div>

    <ng-template #b2bText>
        <div class="text-start">
            <strong>B2B samarbejde</strong><br/>
            Find leverandører for samarbejde om IT-løsninger.<br/>
            Hvis du angiver et reparatørnummer i feltet, vil alle relevante data
            <br/>
            blive stillet til rådighed.<br/>
            <strong>NB.</strong> Funktionen betinges af, at der er udviklet en
            "hentefunktion" hos modtager.
        </div>
    </ng-template>

    <ng-template #b2bInput>
            <form *lcHasAnyAuthority="'ROLE_Taks'" class="col-12">
                <div class="row pb-3">
                    <label class="col-form-label col-4" for="userId">Rester:</label>
                    <div class="input-group col-8">
                        <select *ngIf="!carSaleUserNotInSelectableIntegrationUsers(); else carsaleUserNameWithNoRelation" [(ngModel)]="userId" class="form-select"
                                id="userId" name="userId" required>
                            <option *ngFor="let integrationUser of integrationUsers" [ngValue]="integrationUser.id"
                                    [selected]="integrationUser.id === userId" class="form-control">
                                {{integrationUser.displayName}}
                            </option>
                        </select>
                        <button *ngIf="!carSale" class="btn btn-success" style="height: 38px"
                                [title]="hasActiveDrafts.connectedToActiveDrafts ? 'Du kan kun foretage CarSale på din igangværende tillægsrapport' : ''"
                                (click)="createCarSale()"
                                [disabled]="disableCarSaleCreation()">
                            <i class="fa fa-lg fa-chain"></i>
                        </button>
                        <button *ngIf="carSale" class="btn btn-danger" style="height: 38px"
                                [title]="hasActiveDrafts.connectedToActiveDrafts ? 'Du kan kun fjerne CarSale på din igangværende tillægsrapport' : ''"
                                (click)="deleteCarSale()"
                                [disabled]="!carSaleSupportedByReportType() || hasActiveDrafts.connectedToActiveDrafts">
                            <i class="fa fa-lg fa-chain-broken"></i>
                        </button>
                    </div>
                </div>
                <div class="row pb-3" *ngIf="carSale">
                    <div class="col-4"></div>
                    <div class="col-8" >
                        <b>Salgsdato:</b> {{carSale.updatedAt | date: 'dd-MM-yy HH:mm'}}<br/>
                        <b>Delt med:</b> {{carSale.userDisplayName}}
                    </div>

                    
                </div>    
                <div class="row pb-3">
                    <label class="col-form-label pt-0 col-4" for="userId">Medtag følgende data:</label>
                    <div class="col-8">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" name="keepLicensePlate"
                                   id="keepLicensePlate"
                                   [(ngModel)]="keepLicensePlate" autocomplete="off"/>
                            <label for="keepLicensePlate" class="form-check-label">Registreringsnummer</label>
                        </div>
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" name="keepVin"
                                   id="keepVin"
                                   [(ngModel)]="keepVin" autocomplete="off"/>
                            <label for="keepVin" class="form-check-label">Stelnummer</label>
                        </div>
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" name="keepPoliceNumber"
                                   id="keepPoliceNumber"
                                   [(ngModel)]="keepPoliceNumber" autocomplete="off"/>
                            <label for="keepPoliceNumber" class="form-check-label">Policenummer</label>
                        </div>
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" name="keepDamageNumber"
                                   id="keepDamageNumber"
                                   [(ngModel)]="keepDamageNumber" autocomplete="off"/>
                            <label for="keepDamageNumber" class="form-check-label">Skadenummer</label>
                        </div>
                        <button *ngIf="carSale" class="btn btn-primary btn-sm mt-1" [disabled]="!isCarSaleDeprecated()"
                                (click)="createCarSale()">
                            Opdatér salg
                        </button>
                    </div>
                </div>
            </form>
    </ng-template>

    <ng-template #noIntegrationUserRelationText class="col-12">
        <label class="alert alert-warning col-lg-8 col-md-8 pull-right" >
            <div>
                Kan ikke etablere samarbejde. <br>
                Cheftaksatoren skal tilknytte partnere til din organisation under Administration -> Min organisation
            </div>
        </label>
    </ng-template>

    <!--    Used for displaying name of CarSale recipient that has no relation to current user's  org -->
    <ng-template #carsaleUserNameWithNoRelation >
        <input class="col-8" disabled type="text" [(ngModel)]="carSale.userDisplayName">
    </ng-template>
</div>
