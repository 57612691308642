/**
 * Util methods to handle our EAP issue regarding base paths
 */
import EnvironmentUtils from './environment-utils';
import {PaintSystem} from '../model/paint-system.model';

export default class URLBuilder {

  public static basePath = window['location'] && window.location.pathname ? window.location.pathname : '/';

  public static readonly SECURE_REST_API_PATH = 'secure/api/';
  public static readonly PRODUCT_REST_API_PATH = URLBuilder.SECURE_REST_API_PATH + 'product-';

  private isEap = EnvironmentUtils.isEap();

  private contextPath = this.isEap ? EnvironmentUtils.getEapPath() : '/';

  public restServicePath = this.contextPath + URLBuilder.SECURE_REST_API_PATH;

  public getRestServicePath(): string {
    return this.restServicePath;
  }
  public getProductRestServicePath(): string {
    return this.contextPath + URLBuilder.PRODUCT_REST_API_PATH;
  }

  public constructPDFStreamURL(token: string): string {
    return this.contextPath + 'open/pdfstream?printToken=' + token;
  }

  public constructQapterSubmitURL(xRecordsId: string,
                                  username: string,
                                  readonly: boolean,
                                  classFabrikatKode: string,
                                  classModelKode: string,
                                  returnURL: string,
                                  priceVersion: string,
                                  paintSystem: PaintSystem): string {
    let externalUrl = this.qapterBaseURL();
    externalUrl += '/qapter-initialize';
    externalUrl += '?xRecordId=' + xRecordsId;
    externalUrl += '&paintSystem=' + (paintSystem === PaintSystem.NONE ? '' : paintSystem.toLowerCase());
    externalUrl += '&user=' + username;
    externalUrl += '&classManufactureCode=' + classFabrikatKode;
    externalUrl += '&classModelCode=' + classModelKode;
    externalUrl += '&readonly=' + readonly;
    externalUrl += '&priceVersion=' + priceVersion;
    externalUrl += '&returnPage=' + returnURL;
    return externalUrl;
  }

  public constructQapterStandAloneSubmitURL(username: string,
                                            productContext: string,
                                            classFabrikatKode: string,
                                            classModelKode: string,
                                            vinSubModelKode: string,
                                            printSkabelon: string,
                                            paintSystem: string,
                                            idBlockCodeEnabled: boolean,
                                            priceVersion: string,
                                            returnURL: string): string {
    let externalUrl = this.qapterBaseURL();
    externalUrl += '/qapter-initialize/standalone';
    externalUrl += '?paintSystem=' + (paintSystem === PaintSystem.NONE ? '' : paintSystem.toLowerCase());
    externalUrl += '&user=' + username;
    if (productContext) {
      externalUrl += '&productContext=' + productContext;
    }
    externalUrl += '&classManufactureCode=' + classFabrikatKode;
    externalUrl += '&classModelCode=' + classModelKode;
    externalUrl += '&classSubModel=' + vinSubModelKode;
    externalUrl += '&printTemplate=' + printSkabelon;
    externalUrl += '&idbcmode=' + idBlockCodeEnabled;
    externalUrl += '&priceVersion=' + priceVersion;
    externalUrl += '&returnPage=' + returnURL;
    return externalUrl;
  }

  private qapterBaseURL(): string {
    let qapterBaseURL =
      location.protocol
      + '//'
      + (EnvironmentUtils.isDev() ? 'qapter' : '') // For dev.forsi.dk on Azure, we want Qapter on qapterdev.forsi.dk
      + location.hostname;

    const servedWithoutProxy = EnvironmentUtils.isDevelopment() || location.port === '8080';
    if (servedWithoutProxy) {
      qapterBaseURL += ':6080';
    }
    qapterBaseURL += (this.isEap ? '/dcm/eap' : '/dcm');
    qapterBaseURL += '/webpad';
    return qapterBaseURL;
  }

  public productRecycleInitUrl(callerId: number, vin: string): string {
    let productRecycleInitUrl = location.protocol + '//' + location.hostname;

    const servedWithoutProxy = EnvironmentUtils.isDevelopment() || location.port === '8080';
    if (servedWithoutProxy) {
      productRecycleInitUrl += ':8080';
    }
    productRecycleInitUrl += (this.isEap ? '/eap' : '');
    productRecycleInitUrl += `/product/recycle/${callerId}/init/${vin}`;
    return productRecycleInitUrl;
  }

  public constructReturnURL(lcUrlPartWithoutHashtag: string): string {
    const lcUrlPartWithHashtag = '/#/' + lcUrlPartWithoutHashtag;
    let returnURL = window.location.origin;
    returnURL += this.isEap ? '/eap' : '';
    returnURL += encodeURIComponent(lcUrlPartWithHashtag);
    return returnURL;
  }

  public getBaseApiUrl(): string {
    return this.getBaseUrl() + this.getRestServicePath();
  }

  public getBaseUrl(): string {
    const hostname = window.location.hostname;
    const port = window.location.port;
    const protocol = window.location.protocol;
    const portString = port ? ':' + port : '';
    return protocol + '//' + hostname + portString;
  }
}

