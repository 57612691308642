export class DraftUtil {

  /**
   * When working on Mctaks or Camptaks there is currently only one Vognart with value "Ingen"
   * We do not want the user to choose "Ingen" before being able to select Fabrikat.
   * However the list of Fabrikat is based on the choise "Ingen".
   * This hides Vognart if Mctaks or Camptaks, but it only works because we automatically set Vognart if the list given after
   * choosing Vogntype only has one element.
   * See {@link DraftSkavuTaksArtModel#setupObservers()}
   */
  public static showVognArtDropdown(taksArtKode: string): boolean {
    return taksArtKode === 'L';
  }

}
