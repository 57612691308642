/**
 * NOTE: This service is purposely not annotated with @Injectable(...), as it is initialized and provided in main.ts
 * with values read from the b2c-configuration.json file.
 */
export class B2CConfigurationService {

  private _flowAuthorities: B2CUserFlowsAuthorities;

  get flowAuthorities(): B2CUserFlowsAuthorities {
    return this._flowAuthorities;
  }

  constructor(b2cUserFlowAuthorities: B2CUserFlowsAuthorities) {
    this._flowAuthorities = b2cUserFlowAuthorities;
  }
}

export interface B2CUserFlowsAuthorities {
  resetPassword: string;
  signInEmail: string;
  signInSmsCall: string;
  signInApp: string;
  scopes: string[]
}
