<lc-bottombar [menu]="viewmodel.draftMenu" [iconClass]="viewmodel.menuIconClass"
              [title]="viewmodel.menuBottomTitle"
              [shortTitle]="viewmodel.menuBottomShortTitle"></lc-bottombar>
<div class="text-center" *ngIf="viewmodel.showLoading">
    <i class="fa fa-circle-o-notch fa-spin fop-page-spinner"></i>
</div>
<ng-container *ngIf="!viewmodel.showLoading">
    <h1>Check policeopslag</h1>

    <div class="d-none d-md-block no-focus">
        <div class="card mb-2">
            <div class="card-header">
                Police opslagsændringer
            </div>
            <div class="card-body">
                <ng-container *ngIf="viewmodel.emptyPoliceDifferences()">
                    Ingen ændringer
                </ng-container>
                <ng-container *ngIf="!viewmodel.emptyPoliceDifferences()">
                    <div class="row">
                        <div class="col-2"><h6>Id</h6></div>
                        <div class="col-2"><h6>Indtastet værdi</h6></div>
                        <div class="col-2"><h6>Opslagets værdi</h6></div>
                        <div class="col-1">&nbsp;</div>
                    </div>
                    <ng-container *ngFor="let policeDiff of viewmodel.policeDifferences">
                        <div class="row even">
                            <div class="col-2">{{policeDiff.id}}</div>
                            <div class="col-2">{{policeDiff.valueText()}}</div>
                            <div class="col-2"
                                 [ngClass]="{'orgvalue-missing' : policeDiff.originalMissing()}">{{policeDiff.originalText()}}</div>
                            <div class="col-1">
                                <a *ngIf="policeDiff.canReset()" (click)="resetPoliceDiff(policeDiff)">
                                    <i class="fa fa-2x fa-undo" aria-hidden="true"
                                       (click)="resetPoliceDiff(policeDiff)"></i>
                                </a>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                Aftaleændringer
            </div>
            <div class="card-body">
                <ng-container *ngIf="viewmodel.emptyAgreementDifferences()">
                    Ingen ændringer
                </ng-container>
                <ng-container *ngIf="!viewmodel.emptyAgreementDifferences()">
                    <div class="row">
                        <div class="col-3"><h6>Aftale</h6></div>
                        <div class="col-3"><h6>Rapport</h6></div>
                        <div class="col-3"><h6>Aftaleværdi</h6></div>
                        <div class="col-3">&nbsp;</div>
                    </div>
                    <ng-container *ngFor="let agreementDiff of viewmodel.agreementDifferences">
                        <div class="row">
                            <div class="col-3">{{agreementDiff.title}}</div>
                            <div class="col-3"
                                 [ngClass]="{'orgvalue-missing' : agreementDiff.actualValueMissing()}">{{agreementDiff.actualValueText()}}</div>
                            <div class="col-3"
                                 [ngClass]="{'orgvalue-missing' : agreementDiff.expectedValueMissing()}">{{agreementDiff.expectedValueText()}}</div>
                            <div class="col-3">{{agreementDiff.unit}}</div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="d-md-none">
        <h3>Police opslagsændringer</h3>
        <ng-container *ngIf="viewmodel.emptyPoliceDifferences()">
            <div class="card mb-1">
                <div class="card-body">
                    Ingen ændringer
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!viewmodel.emptyPoliceDifferences()">
            <ng-container *ngFor="let policeDiff of viewmodel.policeDifferences">
                <div class="card mb-1">
                    <div class="card-header">{{policeDiff.id}}</div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-5">{{policeDiff.valueText()}}</div>
                            <div class="col-5"
                                 [ngClass]="{'orgvalue-missing' : policeDiff.originalMissing()}">{{policeDiff.originalText()}}</div>
                            <div class="col-2">
                                <a *ngIf="policeDiff.canReset()" (click)="resetPoliceDiff(policeDiff)">
                                    <i class="fa fa-2x fa-undo" aria-hidden="true"
                                       (click)="resetPoliceDiff(policeDiff)"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <h3>Aftaleændringer</h3>
        <ng-container *ngIf="viewmodel.emptyAgreementDifferences()">
            <div class="card mb-1">
                <div class="card-body">
                    Ingen ændringer
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!viewmodel.emptyAgreementDifferences()">
            <ng-container *ngFor="let agreementDiff of viewmodel.agreementDifferences">
                <div class="card mb-1">
                    <div class="card-header">{{agreementDiff.title}}</div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-4"
                                 [ngClass]="{'orgvalue-missing' : agreementDiff.actualValueMissing()}">{{agreementDiff.actualValueText()}}</div>
                            <div class="col-4"
                                 [ngClass]="{'orgvalue-missing' : agreementDiff.expectedValueMissing()}">{{agreementDiff.expectedValueText()}}</div>
                            <div class="col-3">{{agreementDiff.unit}}</div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
    <div class="row dynamic-padding-bottom">
        <!-- Room for floating action buttons -->
        <div class="col-md-12"></div>
    </div>
</ng-container>