<fieldset ngForm="f" #f="ngForm">
    <h2>Forsikringstager</h2>
    <div class="card">
        <div class="card-body">
            <div class="row pb-3">
                <div class="col-form-label col-4">
                    <label for="forsregnr">Reg. nr</label>
                </div>
                <div class="col">
                    <input [(ngModel)]="details.forsikringstager.regNr"
                           class="form-control text-uppercase"
                           type="text" id="forsregnr" name="regnr"
                           minlength=1 maxlength=10 size="10"
                           lcInputMask="regNr"
                           [required]="isRegNrRequired()" [readonly]="!details.ansvarsskade"
                           [placeholder]="regNrPlaceholder"/></div>
            </div>
            <div class="row pb-3">
                <div class="col-form-label col-4">
                    <label for="forsnavn">Navn</label>
                </div>
                <div class="col">
                    <input class="form-control text-uppercase"
                           [(ngModel)]="details.forsikringstager.navn"
                           type="text" id="forsnavn" name="navn"
                           minlength=1 maxlength="35"
                           [required]="isNavnRequired()"
                           [placeholder]="navnPlaceholder"/></div>
            </div>
            <div class="row pb-3">
                <div class="col-form-label col-4">
                    <label for="forsadresse1">Adresse</label>
                </div>
                <div class="col">
                    <input class="form-control text-uppercase"
                           [(ngModel)]="details.forsikringstager.addressLine1"
                           type="text" id="forsadresse1" name="adresse1"
                           minlength=1 maxlength="35" [required]="isAddressRequired()"/></div>
            </div>
            <div class="row pb-3">
                <div class="col-form-label col-4">&nbsp;</div>
                <div class="col">
                    <input class="form-control text-uppercase"
                           [(ngModel)]="details.forsikringstager.addressLine2"
                           type="text" id="forsadresse2" name="adresse2"
                           minlength=1 maxlength="35"/></div>
            </div>
            <div class="row pb-3">
                <div class="col-form-label col-4">
                    <label for="forspostnr">Postnr</label>
                </div>
                <div class="col">
                    <input class="form-control"
                           [(ngModel)]="details.forsikringstager.postNr"
                           type="tel" id="forspostnr" name="postnr"
                           minlength=1 size="4" maxlength="4" minlength="3" lcInputMask="int4"
                           (change)="postNrChanged()"
                           [required]="isPostNrRequired()"/></div>
            </div>
            <div class="row pb-3">
                <div class="col-form-label col-4">
                    <label>By</label>
                </div>
                <div class="col">
                    <input class="form-control" [(ngModel)]="by" [readonly]="true" type="text" id="skadeby"
                           name="by" minlength=1 [required]="isPostNrRequired()"/></div>
            </div>
            <div class="row pb-3">
                <div class="col-form-label col-4">
                    <label for="forstelefon">Telefon</label>
                </div>
                <div class="col">
                    <input class="form-control"
                           [(ngModel)]="details.forsikringstager.telefonNr"
                           type=tel id="forstelefon" name="telefon"
                           size="8" maxlength="8" minlength="6" lcInputMask="int8"/></div>
            </div>
            <div class="row pb-3">
                <div class="col-form-label col-4">
                    <label for="policenr">Police</label>
                </div>
                <div class="col">
                    <input [(ngModel)]="details.forsikringstager.policenr" class="form-control text-uppercase" type="text"
                           id="policenr" name="policenr" minlength=1 maxlength="15"/></div>
            </div>
            <div class="row pb-3" *ngIf="isTaksator">
                <div class="col-form-label col-4">
                    <label for="kundenr">Kundenummer</label>
                </div>
                <div class="col">
                    <input [(ngModel)]="details.forsikringstager.kundenr" class="form-control text-uppercase" type="text"
                           id="kundenr" name="kundenr" minlength=1 maxlength="15"/></div>
            </div>
            <div class="row pb-3">
                <div class="col-form-label col-4">
                    <label for="skadenr">Skadenummer</label>
                </div>
                <div class="col">
                    <input [(ngModel)]="details.forsikringstager.skadenr"
                           class="form-control text-uppercase" type="text"
                           id="skadenr" name="skadenr"
                           minlength=1 maxlength="35"
                           [disabled]="!allowedToEditDamageNumber()"/></div>
            </div>
            <div class="row pb-3" *ngIf="isTaksator">
                <div class="col-form-label col-4">
                    <label for="selvrisiko">Selvrisiko</label>
                </div>
                <div class="col">
                    <input [(ngModel)]="details.forsikringstager.selvrisiko" #selvrisiko="ngModel"
                           class="form-control text-uppercase" type="text"
                           [placeholder]="details.police ? details.police.selvrisiko : ''"
                           id="selvrisiko" name="selvrisiko"
                           lcSelvrisikoValidation
                           minlength=1 maxlength="6"/></div>
                <div *ngIf="selvrisiko.errors?.selvrisiko" class="offset-4 error">
                    {{selvrisiko.errors.selvrisiko.message}}
                </div>
            </div>
        </div>
    </div>
</fieldset>
