import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {combineLatest, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {
  NonStandardPositionRepairMethodDTO
} from '../../shared/dto/non-standard-position/non-standard-position-repair-method-dto.model';
import {DataTableDirective} from 'angular-datatables';
import {NonStandardPositionsService} from '../../shared/service/NonStandardPositions.service';
import {ADTSettings} from 'angular-datatables/src/models/settings';
import {LcDeclarativeDataTable} from '../../shared/lc-declarative-data-table';
import {LoadingSpinnerUtil} from '../../shared/utils/loading-spinner-util';
import {NonStandardPositionType} from '../../shared/dto/non-standard-position/non-standard-position-type';
import {
  NonStandardPositionUtil
} from '../../admin/assessor-organisation/non-standard-position/non-standard-position.util';
import {PrincipalService} from '../../shared';

@Component({
  templateUrl: './provided-non-standard-positions.component.html',
  selector: 'lc-provided-non-standard-positions',
  providers: [LoadingSpinnerUtil]
})
export class ProvidedNonStandardPositionsComponent implements OnInit, AfterViewInit, OnDestroy {
  private unsubscribe$ = new Subject<ADTSettings>();
  repairMethods: NonStandardPositionRepairMethodDTO[];
  @ViewChild('createdAt') createdAt: TemplateRef<any>;
  @ViewChild('repairMethod') repairMethod: TemplateRef<any>;
  @ViewChild('typeTranslate') typeTranslate: TemplateRef<any>;
  
  @ViewChild(DataTableDirective)
  dataTableDirective: DataTableDirective;
  dataTable = new LcDeclarativeDataTable(this.unsubscribe$);

  constructor(private nonStandardPositionsService: NonStandardPositionsService,
              protected modalService: NgbModal,
              public spinnerUtil: LoadingSpinnerUtil,
              public principal: PrincipalService) {
  }

  ngOnInit(): void {
    this.loadData();
  }

  ngAfterViewInit(): void {
    const dtOptions = {
      columns: [
        {title: 'Beskrivelse', data: 'description', className: 'py-3'},
        {title: 'Arbejdsenheder', data: 'workingUnits'},
        {title: 'NFG - Ny For Gammel', data: 'newForOld'},
        {title: 'Beløb', data: 'price'},
        {title: 'Reservedel', data: 'sparePartDescription'},
        {title: 'Reparationsart', data: 'repairMethod', ngTemplateRef: {ref: this.repairMethod}},
        {title: 'Gruppe', data: 'group'},
        {title: 'Oprettet dato', data: 'createdAt', ngTemplateRef: {ref: this.createdAt}, className: 'text-end' }
      ],
      order: [[0, 'desc']],
      data: []
    };
    if (this.principal.isVK()) {
      dtOptions.columns.splice(7, 0, {title: 'Oprettet af', data: 'providedBy'})
    } else if (this.principal.isTaksator()) {
      dtOptions.columns.splice(7, 0, {title: 'Tilgængelighed', data: 'providedBy', ngTemplateRef: {ref: this.typeTranslate}})
    }
    this.dataTable.init(this.dataTableDirective, dtOptions);
  }
  
  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  refresh(): void {
    this.dataTable.refresh(() => this.loadData())
  }
  
  private loadData(): void {
    const nonStandardPositions$ = this.nonStandardPositionsService.getProvidedNonStandardPositions();
    const repairMethods$ = this.nonStandardPositionsService.getNonStandardPositionRepairMethods();
    this.spinnerUtil.startLoading();
    combineLatest([nonStandardPositions$, repairMethods$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([nonStandardPositions, repairMethods]) => {
        this.repairMethods = repairMethods;
        this.spinnerUtil.stopLoading();
        this.dataTable.setData(nonStandardPositions);
      }, () => {
        this.spinnerUtil.stopLoading();
      });
  }

  public getRepairMethodDescription(repairMethodIdentifier: string): string {
    const index = this.repairMethods.findIndex(b => b.identifier === repairMethodIdentifier);
    return index > -1 ? this.repairMethods[index].identifier + ' - ' + this.repairMethods[index].description : repairMethodIdentifier;
  }

  public getNonStandardPositionTypeText(assessorOrganisationNonStandardPositionType: NonStandardPositionType): string {
    return NonStandardPositionUtil.getNonStandardPositionTypeText(assessorOrganisationNonStandardPositionType);
  }
  
  getTooltip(): string {
    if (this.principal.isVK()) {
      return 'Disse 1000-numre er oprettet og vedligeholdt af taksator';
    } else {
      return 'Disse 1000-numre er oprettet og vedligeholdt af cheftaksator. Administration under administration / Min organisation / Fælles 1000 numre';
    }
  }
}
