import {Component, Input, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PrincipalService} from '../..';
import {TextLibraryPopupComponent} from '../text-library/text-library-popup.component';
import {UserTextLibraryService} from '../../service/user-text-library.service';
import {TextLibraryTypeEnum} from '../../service/text-library-type.enum';

@Component({
  template: ''
})
export abstract class ExtendableComposeMessagePopupComponent implements OnInit {
  @Input() title: string;
  @Input() defaultMessageTitle: string;
  @Input() textLibraryType: TextLibraryTypeEnum;
  @Input() textLibraryTitle: string;
  // forceSendMessage is optional
  @Input() forceSendMessage = false;
  @Input() allowDefaultMessage = false;

  private _sendMessage;
  messageTitle: string;
  messageText: string;

  get sendMessage(): boolean {
    return this._sendMessage;
  }

  set sendMessage(value: boolean) {
    this._sendMessage = value;
    this.setMessageAndTitle(this._sendMessage);
  }

  protected constructor(protected modalService: NgbModal,
                        protected principalService: PrincipalService,
                        protected userTextLibraryService: UserTextLibraryService) {
  }

  protected isMessageValid(): boolean {
    let result = true;
    const allWhiteSpaceRegex = /\s+/g;
    const comparableMessageText = this.messageText.toLowerCase().replace(allWhiteSpaceRegex, '');
    const comparableMessageSignature = this.getMessageSignature().toLowerCase().replace(allWhiteSpaceRegex, '');
    if (this.forceSendMessage) {
      result = comparableMessageText.length > 0;
    }
    if (result && !this.allowDefaultMessage) {
      result = comparableMessageText !== comparableMessageSignature;
    }
    return result;
  }

  /**
   * For now we have decided to open a popup in a popup and change it later if necessary.
   */
  showTextLibrary(): void {
    const modalRef = this.modalService.open(TextLibraryPopupComponent, {size: 'lg', windowClass: 'lc-fullscreen'});
    const popUpComponent = modalRef.componentInstance as TextLibraryPopupComponent;
    popUpComponent.title = this.textLibraryTitle;
    popUpComponent.textLibraryType = this.textLibraryType;
    popUpComponent.textLibraryService = this.userTextLibraryService;
    modalRef.result.then((text: string) => {
      if (text) {
        this.didSelectTextFromLibrary(text);
      }
    }).catch(() => {
      // Necessary to avoid "Uncaught error Error: Uncaught (in promise)" even though we do not use the returned value.
    });
  }

  didSelectTextFromLibrary(text: string) {
    this.messageText = this.messageText ? text + this.messageText : text;
  }

  ngOnInit(): void {
    if (this.forceSendMessage) {
      this._sendMessage = true;
      this.setMessageAndTitle(true);
    }
  }

  private setMessageAndTitle(sendMessage: boolean): void {
    if (sendMessage) {
      this.messageTitle = this.defaultMessageTitle;
      this.messageText = this.getMessageSignature();
    } else {
      this.messageTitle = '';
      this.messageText = '';
    }
  }

  private getMessageSignature(): string {
    return this.principalService.getUdskriftLinier(true);
  }
}
