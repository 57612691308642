<fieldset ngForm="f" #f="ngForm">
    <label for="internalInfoText">Intern Info</label>
    <span class="float-end">
    <button class="btn btn-sm btn-primary" [disabled]="disableShowInternalInfoPdf()"
            (click)="didClickShowInternalInfoPdf()">Udskriv</button>
    <button class="btn btn-sm btn-primary ms-2" (click)="showTextLibrary()">Tekstbibliotek</button>
    <lc-org-text-library-button *lcHasAnyAuthority="'ROLE_Taks'" class="ms-2" [libraryType]="textLibraryType"
                                [reportType]="getReportType()"
                                (selectedText)="didSelectTextFromLibrary($event)"></lc-org-text-library-button>
</span>
    <div class="mt-2">
	<textarea [(ngModel)]="details.internInfo" #internalInfoText
              id="internalInfoText" name="internalInfoText" class="form-control monospace-text text-uppercase"
              rows="12"
              [ngClass]="{'ng-invalid':hasMessageTooManyLines() || isMessageTooLong()}"
    >
	</textarea>
        <div class="mb-1">
            <div class="pull-left {{getMessageClass()}}">{{getMessage()}}</div>&nbsp;
            <div class="pull-right" *ngIf="showChangeValueDeterioration()"><input type="checkbox"
                                                                                  class="form-input-check-single"
                                                                                  name="vaerdiforringelse"
                                                                                  id="vaerdiforringelse"
                                                                                  [(ngModel)]="details.valueDeteriorationText"
                                                                                  autocomplete="off"/><label
                for="vaerdiforringelse">Evt. værdiforringelse</label></div>
        </div>
    </div>
</fieldset>
