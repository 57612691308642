import {Attribute, Directive, forwardRef} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';
import {LcInputValidator} from './lcinputvalidators/lcinput.validator';
import {LcYearValidator} from './lcinputvalidators/year.validator';

@Directive({
  selector: '[lcValidate][formControlName],[lcValidate][formControl],[lcValidate][ngModel]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => InputValidatorDirective), multi: true}
  ]
})
export class InputValidatorDirective implements Validator {

  /**
   * Register all validator configurations under a key that can be referred to as the attribute value of this directive
   * ie. <input ... lcValidate="maxNextYear" .../>
   *
   * @type {{maxNextYear: LcYearValidator}}
   */
  private static LC_VALIDATORS: { [key: string]: LcInputValidator } = {
    maxNextYear: new LcYearValidator(1900, (new Date()).getFullYear() + 1)
  };

  private validator: LcInputValidator;

  constructor(@Attribute('lcValidate') public validateInputType: string) {
    this.validator = InputValidatorDirective.LC_VALIDATORS[validateInputType];
    if (this.validator === null || this.validator === undefined) {
      throw new Error('misconfigured validator! No lcValidator of type \'' + validateInputType + '\'')
    }
  }

  validate(control: AbstractControl): ValidationErrors {
    return this.validator.validate(control.value);
  }
}
