export class ElDetailsDTO {
  selskab: string;
  bankRegnr: string;
  bankKontonr: string;
  nemkonto: boolean;
  fabrikatKode: string;
  fabrikatText: string;
  modelKode: string;
  modelText: string;
  resterTilhoerSelskab: boolean;
  tekst1: string;
  tekst2: string;
  moms: boolean;
  erstatningsBeloeb: number;
  erstatningsModtagerNavn: string;
  erstatningsModtagerAdresse1: string;
  erstatningsModtagerAdresse2: string;
  erstatningsModtagerTlf: string;
  erstatningsModtagerPostalCode: string;
}
