<div class="messages-card d-none d-md-block">
    <ng-container>
        <div class="row mb-4" *ngIf="sendEnabled">
            <div class="col-12">
                <button *ngIf="!showSend" class="btn btn-sm btn-primary" (click)="newMessage()">Ny besked</button>
                <div class="clickable pull-right" (click)="reloadMessages()">
                    <i class="fa fa-refresh fa-2x"
                       [ngClass]="{'fa-spin': spinnerUtil.isLoading, 'fa-spin-stop-ie-hack': !spinnerUtil.isLoading}"></i>
                </div>
            </div>
        </div>
        <div *ngIf="showSend"><strong>Tilføj besked</strong>
            <div class="messages-card-left">
                <form style="width: 100%">
                    <div *ngIf="sendEnabled">
                        <div>
                            <span class="input-group-addon">Emne</span>
                            <input type="text" class="form-control form-control-sm"
                                   [(ngModel)]="messageSubject" [maxLength]="maxSubjectLength"
                                   name="messageSubject"/>
                        </div>
                        <div>
                            <span class="input-group-addon">Besked</span>
                            <textarea [(ngModel)]="messageContent" name="messageContent" rows="10"
                                      class="form-control form-control-sm"
                                      [maxLength]="maxContentLength"></textarea>
                        </div>
                        <div class="pt-1">
                            <div class="pull-right mt-1">
                                <lc-org-text-library-button *lcHasAnyAuthority="'ROLE_Taks'" (selectedText)="didSelectTextFromLibrary($event)" [libraryType]="textLibraryType"></lc-org-text-library-button>
                                <button [disabled]="!textLibraryType" class="btn btn-sm btn-primary ms-1"
                                        (click)="showTextLibrary()">Tekstbibliotek
                                </button>
                                <button [disabled]="!messageSubject" class="btn btn-sm btn-primary ms-1"
                                        (click)="sendMessage()">Tilføj besked
                                </button>
                                <button class="btn btn-sm btn-primary ms-1"
                                        (click)="showSend = false">Annuller
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </ng-container>

    <div class="row">
        <div class="col-md-4">
            <table class="table">
                <tbody>
                <tr *ngFor="let message of _messages" (click)="selectMessage(message)" class="inbox-row"
                    [ngClass]="{'active':currentMessage==message}">
                    <td scope="row"><strong>{{message.subject}}</strong><br/>
                        <lc-inline-user *ngIf="message.from" [user]="message.from"></lc-inline-user>
                    </td>
                    <td>{{message.createdAt | date: 'dd-MM-yy HH:mm'}}
                        <span *ngIf="isMyMessage(message)">
							<span *ngIf="message.read" class="fa fa-envelope-open-o fa-sm"></span>
							<span *ngIf="!message.read" class="fa fa-envelope text-danger fa-sm"></span>
						</span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-8" *ngIf="currentMessage">
            <div style="border-top: 1px solid rgb(222, 226, 230);"><strong>Emne: {{currentMessage.subject}}</strong>
            </div>
            <div *ngIf="currentMessage.from">Fra:
                <lc-inline-user [user]="currentMessage.from"></lc-inline-user>
            </div>
            <div *ngIf="currentMessage.to">Til:
                <lc-inline-user [user]="currentMessage.to"></lc-inline-user>
            </div>
            <div style="background:white;min-height: 300px;white-space: pre-wrap; padding: 5px;"
                 class="mb-2">{{currentMessage.content}}</div>
            <div>
                <button *ngIf="this.canReply(currentMessage)" class="btn btn-sm btn-primary pull-right ms-1"
                        (click)="doReply(currentMessage)">Svar
                </button>
                <button class="btn btn-sm btn-primary pull-right" (click)="print(currentMessage)">
                    Udskriv
                </button>
            </div>
            <div class="reply-container" *ngIf="messageReplyTo && currentMessage.id === messageReplyTo.id">
                <form style="width: 100%">
                    <div>
                        <strong>Svar</strong>
                        <div>
                            <div class="input-group input-group-sm">
                                <span class="input-group-addon">Emne</span>
                                <input type="text" class="form-control form-control-sm"
                                       [(ngModel)]="replySubject" [maxLength]="maxSubjectLength"
                                       name="replySubject"/>
                            </div>
                        </div>
                        <div>
                      <textarea style="width: 100%" [(ngModel)]="replyContent" name="replyContent" rows="10"
                                class="form-control form-control-sm" [maxLength]="maxContentLength"></textarea>
                            <div class="float-end mt-1">
                                <lc-org-text-library-button *lcHasAnyAuthority="'ROLE_Taks'" (selectedText)="didSelectTextFromLibrary($event, true)" [libraryType]="textLibraryType"></lc-org-text-library-button>
                                <button [disabled]="!textLibraryType" class="btn btn-sm btn-primary ms-1"
                                        (click)="showTextLibrary(true)">Tekstbibliotek
                                </button>
                                <button [disabled]="!this.replySubject"
                                        class="btn btn-sm btn-primary ms-1"
                                        (click)="sendReply()">Send svar
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="messages-card-action d-md-none">
    <form style="width: 100%" *ngIf="sendEnabled">
        <strong>Tilføj besked</strong>
        <div>
            <div class="input-group input-group-sm">
                <span class="input-group-addon">Emne</span>
                <input type="text" class="form-control form-control-sm" [(ngModel)]="messageSubject"
                       [maxLength]="maxSubjectLength" name="messageSubject"/>
            </div>
        </div>
        <div>
        		<textarea [(ngModel)]="messageContent" name="messageContent" rows="10"
                          class="form-control form-control-sm" [maxLength]="maxContentLength"></textarea>
        </div>
        <div class="float-end mt-1">
            <lc-org-text-library-button *lcHasAnyAuthority="'ROLE_Taks'" (selectedText)="didSelectTextFromLibrary($event)" [libraryType]="textLibraryType"></lc-org-text-library-button>
            <button [disabled]="!textLibraryType" class="btn btn-sm btn-primary ms-1"
                    (click)="showTextLibrary()">Tekstbibliotek
            </button>
            <button [disabled]="!messageSubject" class="btn btn-sm btn-primary ms-1" (click)="sendMessage()">
                Tilføj besked
            </button>
        </div>
    </form>
    <div class="spacer mb-5"></div>
    <div class="container-fluid">
        <strong>Beskeder</strong>
        <br/>
        <ng-container *ngFor="let message of _messages">
            <div class="row spacer">
                <div class="col-12" [ngClass]="{'can-reply': this.canReply(message)}">
                    <i>{{message.createdAt | date: 'dd-MM-yy HH:mm'}}</i> -
                    fra:
                    {{message.from?.username}} - til: {{message.to?.username}}
                    <span *ngIf="isMyMessage(message)">
						<span *ngIf="message.read" class="fa fa-envelope-open-o fa-sm"></span>
						<span *ngIf="!message.read" class="fa fa-envelope text-danger fa-sm"></span>
					</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <a (click)="messageContentShow[message.id]=!messageContentShow[message.id]">
                        <i class="fa"
                           [ngClass]="{'fa-plus': !messageContentShow[message.id], 'fa-minus': messageContentShow[message.id]}"></i>
                        <i>{{message.subject}}</i>
                    </a>
                </div>
            </div>
            <div class="row" *ngIf="messageContentShow[message.id]">
                <div class="col-12 message-content">
                    <div style="background:white;white-space: pre-wrap; padding: 5px;">{{message.content}}</div>
                    <button *ngIf="this.canReply(message)" class="btn btn-sm btn-primary pull-right mt-1"
                            (click)="doReply(message)">Svar
                    </button>
                    <div class="reply-container" *ngIf="messageReplyTo && message.id === messageReplyTo.id">
                        <form style="width: 100%">
                            <div>
                                <strong>Svar</strong>
                                <div>
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-addon">Emne</span>
                                        <input type="text" class="form-control form-control-sm"
                                               [(ngModel)]="replySubject" [maxLength]="maxSubjectLength"
                                               name="replySubject"/>
                                    </div>
                                </div>
                                <div>
                  <textarea style="width: 100%" [(ngModel)]="replyContent" name="replyContent" rows="10"
                            class="form-control form-control-sm" [maxLength]="maxContentLength"></textarea>
                                    <div class="float-end mt-1">
                                        <lc-org-text-library-button *lcHasAnyAuthority="'ROLE_Taks'" (selectedText)="didSelectTextFromLibrary($event, true)" [libraryType]="textLibraryType"></lc-org-text-library-button>
                                        <button [disabled]="!textLibraryType" class="btn btn-sm btn-primary ms-1"
                                                (click)="showTextLibrary(true)">Tekstbibliotek
                                        </button>
                                        <button [disabled]="!this.replySubject"
                                                class="btn btn-sm btn-primary ms-1"
                                                (click)="sendReply()">Send svar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
