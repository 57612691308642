<div class="timeline timeline-line-solid w-100">
    <div *ngFor="let message of messages" class="timeline-item" [class.timeline-item-left]="message.position === 0"
         [class.timeline-item-right]="message.position === 1" [ngClass]="message.type ? message.type : ''">
        <div class="timeline-point"></div>
        <div class="timeline-event" >
            <div class="widget has-shadow" >
                <div class="widget-header">
                    <div class="user-avatar pull-left">
                        <i class="fa fa-lg {{message.headerIconClass}}"></i>
                    </div>
                    <div class="">
                        <div class="title text-truncate">
                            <span class="username">{{message.headerTitle}}</span>
                        </div>
                    </div>
                </div>

                <div class="widget-body">
                    <div class="timeline-message">{{message.message}}</div>
                </div>

            </div>
            <div [class.time-left]="message.position === 0" [class.time-right]="message.position === 1">{{message.date | date: 'dd.MM.yyyy H:mm'}}</div>
        </div>
    </div>

    <div *ngIf="addMessageEnabled" class="timeline-item timeline-item-right add-new-message">
        <div class="timeline-point"></div>
        <div class="timeline-event">
            <div class="widget  has-shadow">
                <div class="widget-header">
                    <div class="user-avatar pull-left">
                        <i class="fa fa-lg fa-envelope-o"></i>
                    </div>
                    <div class="">
                        <div class="title text-truncate">
                            <span class="username">Tilføj ny besked</span>
                        </div>
                    </div>
                </div>

                <div class="widget-body">
                    <textarea id="messageInput" class="form-control" id="exampleFormControlTextarea1" rows="3" style="resize: none" [(ngModel)]="newMessage"></textarea>
                    <button [disabled]="!newMessage" class="mt-2 pull-right btn btn-primary btn-sm" (click)="addMessage()">Tilføj besked</button>
                </div>

            </div>
        </div>
    </div>

    <div class="timeline-item timeline-item-right messages-refresh">
        <div class="timeline-point clickable" (click)="refreshTimeline()">
            <i class="fa fa-refresh fa-2x"
               [ngClass]="{'fa-spin': spinnerUtil.isLoading, 'fa-spin-stop-ie-hack': !spinnerUtil.isLoading}"></i>
        </div>
    </div>
</div>
