<h1>NS 1000-numre</h1>

<ul ngbNav #nav="ngbNav" class="nav-tabs">
    <li ngbNavItem="user" *lcHasAnyAuthority="['ROLE_Taks']">
        <a ngbNavLink>Egne</a>
        <ng-template ngbNavContent>
            <lc-private-non-standard-positions></lc-private-non-standard-positions>
        </ng-template>
    </li>
    <li ngbNavItem="org" *lcHasAnyAuthority="['ROLE_Taks']">
        <a ngbNavLink>Min organisation</a>
        <ng-template ngbNavContent>
            <lc-provided-non-standard-positions></lc-provided-non-standard-positions>
        </ng-template>
    </li>
    <li ngbNavItem="vk" *lcHasAnyAuthority="['ROLE_VK']">
        <a ngbNavLink>Værkstedets</a>
        <ng-template ngbNavContent>
            <lc-private-non-standard-positions></lc-private-non-standard-positions>
        </ng-template>
    </li>
    <li ngbNavItem="org" *lcHasAnyAuthority="['ROLE_VK']">
        <a ngbNavLink>Fra taksator</a>
        <ng-template ngbNavContent>
            <lc-provided-non-standard-positions></lc-provided-non-standard-positions>
        </ng-template>
    </li>
</ul>

<div [ngbNavOutlet]="nav"></div>
