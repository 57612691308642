<fieldset ngForm="f" #f="ngForm">
    <h2>Skadet køretøj</h2>
    <div class="card">
        <div class="card-body">
            <div class="row">
                <!-- Left part -->
                <div class="col-md-6">

                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label for="regnr1">Reg. nr</label>
                        </div>
                        <!--<input [(ngModel)]="details.vehicle.regNr" class="col-8 form-control" type="text" id="regNr" name="regNr"-->
                        <div class="col-8">
                            <input *ngIf="!details.ansvarsskade" [(ngModel)]="details.forsikringstager.regNr"
                                class="form-control text-uppercase"
                                type="text" id="regnr1" name="regnr"
                                minlength=1 maxlength=10 size="10"
                                lcInputMask="regNr"
                                [disabled]="details.vehicle.nummerpladeType===' '"
                                required/>
                            <input *ngIf="details.ansvarsskade" [(ngModel)]="details.skadelidte.regNr"
                                class="form-control text-uppercase"
                                type="text" id="regnr1" name="regnr"
                                lcInputMask="regNr"
                                [disabled]="details.vehicle.nummerpladeType===' '"
                                minlength=1 maxlength=10 size="10"
                                required/>
                        </div>
                    </div>

                    <div class="row pb-3" *ngIf="!takseringsartKode">
                        <div class="col-form-label col-4">
                            <label class="m-0" for="takseringsart">Takseringsart</label>
                        </div>
                        <div class="col-8">
                            <lc-autocomplete id="takseringsart" name="takseringsart" required class="custom-control"
                                            [items]="takseringsarter" [ngModel]="takseringsart" debugInfo="takseringsart"
                                            itemid="id" itemText="navn"
                                            (ngModelChange)="selectArt($event)"></lc-autocomplete>
                        </div>
                    </div>

                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label class="m-0" for="vogntype">Vogntype</label>
                        </div>
                        <div class="col-8">
                            <lc-autocomplete id="vogntype" name="vogntype" required class="custom-control" [items]="vogntyper"
                                [ngModel]="vogntype" debugInfo="vogntype" itemid="id" itemText="navn"
                                (ngModelChange)="selectVogntype($event)"></lc-autocomplete>
                        </div>
                    </div>

                    <div class="row pb-3" *ngIf="showVognArtDropdown()">
                        <div class="col-form-label col-4">
                            <label class="m-0" for="vognart">Vognart</label>
                        </div>
                        <div class="col-8">
                            <lc-autocomplete id="vognart" name="vognart" required
                                            [items]="vognarter" [ngModel]="vognart" debugInfo="vognart"
                                            itemid="id" itemText="art"
                                            (ngModelChange)="selectVognArt($event)"></lc-autocomplete>
                        </div>
                    </div>

                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label class="m-0" for="fabrikat">Fabrikat</label>
                        </div>
                        <div class="col-8">
                            <lc-autocomplete id="fabrikat" name="fabrikat" required
                                            [items]="fabrikater" [ngModel]="fabrikat" debugInfo="fabrikat"
                                            itemid="id" itemText="fabrikatTekst"
                                            (ngModelChange)="selectFabrikat($event)"></lc-autocomplete>
                        </div>
                    </div>

                    <div *ngIf="fabrikat && fabrikat.fabrikatTekst==='ØVRIGE'" class="row pb-3">
                        <div class="col-form-label col-4">
                            <label for="angivetFabrikat">Angivet fabrikat</label>
                        </div>
                        <div class="col-8">
                            <input [(ngModel)]="details.skavu.angivetFabriat" class="form-control text-uppercase" type="text"
                                id="angivetFabrikat" name="angivetFabrikat"
                                minlength=1 size="20"
                                required/>
                        </div>
                    </div>

                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label for="model">Model</label>
                        </div>
                        <div class="col-8">
                            <input [(ngModel)]="details.skavu.model" class="form-control text-uppercase" type="text" id="model"
                                name="model"
                                minlength=1 maxlength=20 size="20"
                                required/>
                        </div>
                    </div>

                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label for="stelnr">Stelnummer</label>
                        </div>
                        <div class="col-8"><input [(ngModel)]="details.vehicle.stelnr" class="form-control text-uppercase" type="text" id="stelnr"
                               name="stelnr"
                               minlength=1 maxlength=20 size="20"
                               required/></div>
                    </div>
                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label for="aargang">Årgang</label>
                        </div>
                        <div class="col-8"><input [(ngModel)]="details.vehicle.aargang" class="form-control" type="tel" id="aargang"
                               name="aargang" lcInputMask="year" lcValidate="maxNextYear" size="4" min="1900" max="9999"
                               required/></div>
                    </div>

                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label for="kmstand">Km. stand</label>
                        </div>
                        <div class="col-8">
                            <input [(ngModel)]="details.vehicle.kmstand" class="form-control" type="tel" id="kmstand"
                               name="kmstand" lcInputMask="int6" maxlength="6" size="6" required/>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label for="handelsvaerdi">Handelsværdi</label>
                        </div>
                        <div class="col-8">
                            <input [(ngModel)]="details.vehicle.handelsvaerdi" class="form-control" type="tel"
                                id="handelsvaerdi"
                                name="handelsvaerdi" lcInputMask="int8" size="8"/>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label for="ansvarsskade">Ansvarsskade</label>
                        </div>
                        <div class="col-8">
                                <input (change)="ansvarsSkadeChanged()" type="checkbox" class="form-input-check-single"
                                       name="ansvarsskade"
                                       id="ansvarsskade" [(ngModel)]="details.ansvarsskade" autocomplete="off"/>
                        </div>
                    </div>
                </div>
                <!-- Right part -->
                <div class="col-md-6">
                    <div class="row pb-3">
                        <label class="col-form-label col-4">Nummerplade</label>
                        <div class="col-8">
                            <div class="row">
                                <div class="btn-group">
                                    <input type="radio" class="btn-check" name="nummerpladeType" value="H" id="HVID" autocomplete="off" [(ngModel)]="details.vehicle.nummerpladeType"/>
                                    <label class="btn btn-secondary btn-sm" for="HVID">Hvid</label>
                                    <input type="radio" class="btn-check" name="nummerpladeType" value="G" id="GUL" autocomplete="off" [(ngModel)]="details.vehicle.nummerpladeType"/>
                                    <label class="btn btn-secondary btn-sm" for="GUL">Gul/Papegøje</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="btn-group">
                                    <input type="radio" class="btn-check" name="nummerpladeType" value="B" id="BLAA" autocomplete="off" [(ngModel)]="details.vehicle.nummerpladeType"/>
                                    <label class="btn btn-secondary btn-sm" for="BLAA">Blå</label>
                                    <input type="radio" class="btn-check" name="nummerpladeType" value="P" id="PROEVE" autocomplete="off" [(ngModel)]="details.vehicle.nummerpladeType"/>
                                    <label class="btn btn-secondary btn-sm" for="PROEVE">Prøve</label>
                                    <input type="radio" class="btn-check" name="nummerpladeType" value=" " id="INGEN" autocomplete="off" [(ngModel)]="details.vehicle.nummerpladeType"/>
                                    <label class="btn btn-secondary btn-sm" for="INGEN">Ingen</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label for="ekstraudstyr">Ekstraudstyr</label>
                        </div>
                        <div class="col-8">
                            <textarea rows="5" class="form-control text-uppercase" name="ekstraudstyr" id="ekstraudstyr"
                                        [(ngModel)]="details.skavu.ekstraudstyr"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fieldset>
