import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DraftCreateViewModel} from '../draft-create.view-model';
import {NgForm} from '@angular/forms';
import {ClientStateDetails} from '../../model/client-state-details.model';
import {DraftSkavuTaksArtModel, TaksSkavuTaksDataProvider, VKSkavuTaksDataProvider} from '../../model/draft-skavu-taks-art.model';
import {map, takeUntil} from 'rxjs/operators';
import {DraftUtil} from '../../draft.util';
import {SkavuFabrikatService} from '../../service/skavu-fabrikat.service';
import {StringUtils} from '../../../shared/utils/string-utils';
import {Subject} from 'rxjs';
import {isNullOrUndefined} from '../../../shared/utils/object-utils';
import {ReportCategory} from '../../../shared/model/report-category.model';

@Component({
  selector: 'lc-draft-skavu-create',
  templateUrl: './draft-skavu-create.component.html',
  styleUrls: [
    '../../draft.scss'
  ]
})
export class DraftSkavuCreateComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  @Input() createViewModel: DraftCreateViewModel;
  @Input() taksArtKode: string;
  @Input() details: ClientStateDetails;
  @Input() explicitReportCategory: ReportCategory;
  @ViewChild('f', {static: true}) public form: NgForm;
  private draftSkavuTaksArtViewModel: DraftSkavuTaksArtModel;

  get taksArtViewModel(): DraftSkavuTaksArtModel {
    return this.draftSkavuTaksArtViewModel;
  }

  constructor(private skavuFabrikatService: SkavuFabrikatService) {
  }

  ngOnInit(): void {
    this.createViewModel.registerForm(this.form);
    this.createViewModel.explicitReportCategory = this.explicitReportCategory;

    if (this.isVKSkavuTaks()) {
      this.details.vehicle.nummerpladeType = 'H';
      this.draftSkavuTaksArtViewModel = new DraftSkavuTaksArtModel(new VKSkavuTaksDataProvider(this.skavuFabrikatService));
      this.draftSkavuTaksArtViewModel.takseringsArtKode.value = this.taksArtKode;
    } else {
      this.details.vehicle.nummerpladeType = ' ';
      this.draftSkavuTaksArtViewModel = new DraftSkavuTaksArtModel(new TaksSkavuTaksDataProvider(this.skavuFabrikatService));
      this.draftSkavuTaksArtViewModel.takseringsArtKode.value = null;
    }

    this.setupObservers();
  }

  showVognArtDropdown(): boolean {
    return DraftUtil.showVognArtDropdown(this.details.skavu.takseringsart);
  }

  showTakseringsArtDropdown(): boolean {
    return !this.isVKSkavuTaks();
  }

  isVKSkavuTaks(): boolean {
    return !isNullOrUndefined(this.taksArtKode);
  }

  setupObservers(): void {
    this.draftSkavuTaksArtViewModel.takseringsart.getObs().pipe(
      map(taksArt => isNullOrUndefined(taksArt) ? null : taksArt.value), takeUntil(this.unsubscribe$)).subscribe(taksArt => {
      this.details.skavu.takseringsart = taksArt;
    });

    this.draftSkavuTaksArtViewModel.vogntype.getObs().pipe(map(vogntype => isNullOrUndefined(vogntype) ? null : vogntype), takeUntil(this.unsubscribe$)).subscribe(vogntype => {
      if (!isNullOrUndefined(vogntype)) {
        if (this.isVKSkavuTaks()) {
          this.details.skavu.commontype = vogntype.vognType;
        } else {
          this.details.skavu.commontype = vogntype.value;
        }
      } else {
        this.details.skavu.commontype = '';
      }
    });

    this.draftSkavuTaksArtViewModel.vognart.getObs().pipe(map(vognArt => isNullOrUndefined(vognArt) ? null : vognArt), takeUntil(this.unsubscribe$)).subscribe(vognArt => {
      if (!isNullOrUndefined(vognArt)) {
        if (this.isVKSkavuTaks()) {
          this.details.skavu.modelkode = vognArt.modelkode;
        } else {
          this.details.skavu.modelkode = vognArt.modelkode;
          this.details.skavu.vognart = vognArt.art;
        }
      } else {
        this.details.skavu.modelkode = '';
        this.details.skavu.vognart = '';
      }
    });

    this.draftSkavuTaksArtViewModel.fabrikat.getObs().pipe(takeUntil(this.unsubscribe$)).subscribe(fabrikat => {
      if (!isNullOrUndefined(fabrikat)) {
        if (this.isVKSkavuTaks()) {
          this.details.skavu.angivetFabriat = fabrikat.fabrikatTekst;
          switch (this.taksArtKode) {
            case 'D':
              this.details.skavu.fabrikat = fabrikat.modelKode;
              break;
            case 'L':
              this.details.skavu.fabrikat = fabrikat.fabrikatKode;
              break;
            case 'M':
              this.details.skavu.fabrikat = fabrikat.modelKode;
              break;
            default:
              this.details.skavu.fabrikat = fabrikat.fabrikatKode;
              break;
          }
        } else {
          this.details.skavu.angivetFabriat = fabrikat.fabrikatTekst;
          this.details.skavu.fabrikat = StringUtils.isNotEmpty(fabrikat.fabrikatKode) ? fabrikat.fabrikatKode.trim() : '';
        }
      } else {
        this.details.skavu.angivetFabriat = '';
        this.details.skavu.fabrikat = '';
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
