import {Address} from './address.model';

export class PersonInfo extends Address {
  regNr: string;
  telefonNr: string;
}

export class PoliceInfo extends PersonInfo {
  policenr: string;
  kundenr: string;
  selvrisiko: string;
  skadenr: string;
}
