import {NonStandardPositionType} from '../../../shared/dto/non-standard-position/non-standard-position-type';

export class NonStandardPositionUtil {
  public static getNonStandardPositionTypeText(assessorOrganisationNonStandardPositionType: NonStandardPositionType): string {
    switch (assessorOrganisationNonStandardPositionType) {
      case NonStandardPositionType.ASSESSOR: {
        return 'Taksator';
      }
      case NonStandardPositionType.ASSESSOR_AND_WORKSHOP: {
        return 'Værksted og Taksator';
      }
      default: {
        return '';
      }
    }
  }

  public static getNonStandardPositionTypes(): NonStandardPositionType[] {
    return Object.values(NonStandardPositionType);
  }
}


