<div class="col-12" style="font-size: 0.8rem;">
    <h1>Roadmap</h1>

    <div class="timeline timeline-line-solid w-100">
        <div class="roadmap-filter timeline-item timeline-item-right">
            <div class="form-check form-check-inline filter filter-upcoming">
                <input class="form-check-input clickable ms-0" type="radio" id="inlineCheckbox2" value="New" [(ngModel)]="currentState" (ngModelChange)="requestRoadmapItems()">
                <label class="form-check-label ps-1 pe-2 clickable" for="inlineCheckbox2">Kommende</label>
            </div>
            <div class="form-check form-check-inline ps-1 filter filter-active" >
                <input class="form-check-input clickable ms-0" type="radio" id="inlineCheckbox1" value="Active" [(ngModel)]="currentState" (ngModelChange)="requestRoadmapItems()">
                <label class="form-check-label ps-1 pe-2 clickable" for="inlineCheckbox1" >Igangværende</label>
            </div>
            <div class="form-check form-check-inline filter filter-closed">
                <input class="form-check-input clickable ms-0" type="radio" id="inlineCheckbox3" value="Closed" [(ngModel)]="currentState" (ngModelChange)="requestRoadmapItems()">
                <label class="form-check-label ps-1 pe-2 clickable" for="inlineCheckbox3">Afsluttede</label>
            </div>
            <div class="input-group align-top pull-right list-search">
                <input class="form-control list-filtering" [ngClass]="getFilteringClass()" type="search"
                       placeholder="Filter" aria-label="Search" [(ngModel)]="filter"
                       (ngModelChange)="onFilteringChanged(filter)">
            </div>
        </div>
        <div *ngFor="let item of filteredItems, let i=index" class="timeline-item timeline-item-right" [ngClass]="getTimelineEventType(item.state)" [ngSwitch]="item.state === 'Closed' && !!item.closedDate">
            <div class="timeline-point"></div>
            <div class="timeline-event">
                <div class="widget has-shadow p-2">
                    <div class="widget-header">
                        <div class="">
                            <div class="title text-truncate">
                                <h3 class="mt-0"><span *ngSwitchCase="false" class="roadmap-priority">{{item.rank}}</span> {{item.title}} <small *ngIf="hasTargetDate(item)" class="text-muted pull-right">{{getTargetQuater(item)}}. kvartal {{getTargetYear(item)}}</small></h3>
                            </div>
                        </div>
                    </div>

                    <div class="widget-body" id="{{'widget_body_' + item.rank}}"#widgetBody [ngSwitch]="isExpanded(widgetBody)">
                        <div class="timeline-message overflow-hide" [innerHTML]="item.description"></div>
                        <div *ngIf="hasOverflow(widgetBody) && !isExpanded(widgetBody)" class="roadmap-fading"></div>
                    </div>
                    <div *ngIf="hasOverflow(widgetBody)" class="widget-footer">
                        <bottom class="btn btn-outline-primary clickable" (click)="toggleShowMore(widgetBody)">{{isExpanded(widgetBody) ? 'Vis mindre': 'Vis mere'}}</bottom>
                    </div>
                </div>
                <div *ngSwitchCase="false" class="time-right">Sidst opdateret: {{item.changedDate | date: 'dd.MM.yyyy H:mm'}}</div>
                <div *ngSwitchCase="true" class="time-right">Lukket: {{item.closedDate | date: 'dd.MM.yyyy'}}</div>
            </div>
        </div>
    </div>

</div>
