import {ModelDTO} from './model-dto.model';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {NgbForsiDateMomentParserFormatter} from '../../index';
import {isNullOrUndefined} from '../../utils/object-utils';

export class FabrikatDTO {
  private static formatter = new NgbForsiDateMomentParserFormatter();
  id: number;
  kode: string;
  navn: string;
  simpleName: string;
  classKode: string;
  audatex: boolean;
  modeller: ModelDTO[] = new Array<ModelDTO>();
  fantomModels: ModelDTO[] = new Array<ModelDTO>();
  gyldigFra: NgbDate;
  gyldigTil: NgbDate;
  workUnitsPerHour: number;

  static fromJson(fabrikatJson: any): FabrikatDTO {
    const result = Object.assign(new FabrikatDTO(), fabrikatJson) as FabrikatDTO;

    if (!isNullOrUndefined(fabrikatJson.gyldigFra)) {
      const fra = new Date(fabrikatJson.gyldigFra);
      result.gyldigFra = FabrikatDTO.formatter.parseDate(fra);
    }

    if (!isNullOrUndefined(fabrikatJson.gyldigTil)) {
      const til = new Date(fabrikatJson.gyldigTil);
      result.gyldigTil = FabrikatDTO.formatter.parseDate(til);
    }
    result.modeller = result.modeller.map(ModelDTO.fromJson);
    return result;
  }

  public static toJson(fabrikat: FabrikatDTO): object {
    const jsonObj = Object.assign({}, fabrikat) as any;
    jsonObj.gyldigFra = fabrikat.gyldigFra ? FabrikatDTO.formatter.toDate(fabrikat.gyldigFra).getTime() : null;
    jsonObj.gyldigTil = fabrikat.gyldigTil ? FabrikatDTO.formatter.toDate(fabrikat.gyldigTil).getTime() : null;
    return jsonObj;
  }

  public static isKnownByAudatex(fabrikat): boolean {
    return fabrikat !== undefined && fabrikat !== null && fabrikat.audatex;
  }

  public static isOther(fabrikat): boolean {
    return fabrikat !== null && !FabrikatDTO.isKnownByAudatex(fabrikat) && fabrikat.kode === 'V8';
  }

  public isKnownByAudatex(): boolean {
    return FabrikatDTO.isKnownByAudatex(this);
  }

}
