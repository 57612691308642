<div class="text-center" *ngIf="isLoading">
    <i class="fa fa-circle-o-notch fa-spin fop-page-spinner"></i>
</div>
<div *ngIf="!isLoading" class="no-focus" tabindex="0" [lcAutofocus]>
    <div class="card">
        <div class="card-body">
            <h4 class="card-title">Rapport</h4>
            <div class="row">
                <label class="col-4 col-form-label" for="startLoebeNr">Start løbenr</label>
                <input id="startLoebeNr" type="number" lcInputmask="numbersOnly"
                       class="col-md-1 col-sm-2 col-3 form-control"
                       name="startLoebeNr"
                       [(ngModel)]="functionConfiguration.startLoebeNr"/>
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-body">
            <h4 class="card-title">Opgørelsesystem</h4>
            <form>
                <div class="row">
                    <div class="offset-sm-4 col-md-8">
                        <div class="form-check">
                            <label class="form-check-label" *lcHasAnyAuthority="'ROLE_VK'">
                                <input class="form-check-input" type="checkbox" name="stortaksSelected"
                                       [(ngModel)]="functionConfiguration.stortaksSelected"/>
                                Stortaks: Lastbiler, busser, trailere o.lign.
                            </label>
                        </div>

                        <div class="form-check">
                            <label class="form-check-label">
                                <input class="form-check-input" type="checkbox" name="autotaksSelected"
                                       [(ngModel)]="functionConfiguration.autotaksSelected">
                                Autotaks: Person/varevogne
                            </label>
                        </div>

                        <div class="form-check" *lcHasAnyAuthority="'ROLE_VK'">
                            <label class="form-check-label">
                                <input class="form-check-input" type="checkbox" name="glastaksSelected"
                                       [(ngModel)]="functionConfiguration.glastaksSelected">
                                Glastaks: Glasskader på person/varevogne
                            </label>
                        </div>

                        <div class="form-check" *lcHasAnyAuthority="'ROLE_VK'">
                            <label class="form-check-label">
                                <input class="form-check-input" type="checkbox" name="mctaksSelected"
                                       [(ngModel)]="functionConfiguration.mctaksSelected">
                                MCTaks: Motorcykler, knallerter, o.lign.
                            </label>
                        </div>

                        <div class="form-check" *lcHasAnyAuthority="'ROLE_VK'">
                            <label class="form-check-label">
                                <input class="form-check-input" type="checkbox" name="camptaksksSelected"
                                       [(ngModel)]="functionConfiguration.camptaksSelected">
                                Camptaks: Campingvogne, o.lign.
                            </label>
                        </div>

                        <div class="form-check" *lcHasAnyAuthority="'ROLE_Taks'">
                            <label class="form-check-label">
                                <input class="form-check-input" type="checkbox" name="skavutaksSelected"
                                       [(ngModel)]="functionConfiguration.skavutaksSelected">
                                Unitaks: Storvogne, motorcykler, ...
                            </label>
                        </div>

                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="card">
        <div class="card-body" *lcHasAnyAuthority="'ROLE_VK'">
            <h4 class="card-title">Andet</h4>
            <div class="row">
                <label class="col-4 col-form-label" for="importId">Import Id</label>
                <input id="importId" type="text" class="col-md-2 col-sm-2 col-3 form-control" name="importId"
                       [(ngModel)]="functionConfiguration.importId"/>
            </div>

        </div>
        <div class="card-body" *lcHasAnyAuthority="'ROLE_Taks'">
            <h4 class="card-title">Autoflex</h4>
            <div class="row">
                <div class="offset-sm-4 col-md-8">
                    <h5>Upload ny autoflex version</h5>
                    <label class="btn btn-file btn-primary ms-2">
                        <input id="autoflexUpload" type="file" style="display: none;"
                               (change)="uploadAutoFlex($event)"/>
                        <lc-spinner-text [spin]="isUploadingAutoflex" text="Upload"></lc-spinner-text>
                    </label>
                    <label class="btn btn-primary ms-2" (click)="refreshAutoflexVersions()">
                        <i class="fa fa-refresh fa-lg"></i>
                    </label>
                </div>
                <div class="offset-sm-4 col-md-8" *ngIf="autoflexVersions">
                    <p *ngIf="lastestActiveAutoflexVersion">Den nuværende autoflex version blev oprettet d.
                        <b>{{lastestActiveAutoflexVersion.createdAt | date: 'dd-MM-yy HH:mm'}}</b></p>
                    <p *ngIf="!lastestActiveAutoflexVersion">Der blev ikke fundet nogen aktiv autoflex version</p>
                </div>
                <div class="offset-sm-4 col-md-8" *ngIf="pendingAutoflexVersions">
                    <ng-container *ngIf="pendingAutoflexVersions.length > 0; else noPendingAutoflexVersions">
                        <p>Venter på at håndtere version(er) uploaded d.</p>
                        <ul>
                            <li *ngFor="let pendingAutoflexVersion of pendingAutoflexVersions">{{pendingAutoflexVersion.createdAt | date: 'dd-MM-yy HH:mm'}}</li>
                        </ul>
                    </ng-container>
                    <ng-template #noPendingAutoflexVersions>
                        <p>Der er ingen ventende autoflex versioner</p>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
    <div class="p-2">
        <button class="btn btn-primary pull-right" (click)="save()">Gem</button>
        <button class="btn btn-secondary pull-right me-2" (click)="reset()">Reset</button>
    </div>
</div>
