import {Injectable} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {CopyReportModalComponent} from './copy-report.component';

@Injectable()
export class CopyReportModalService {
  private isOpen = false;

  constructor(private modalService: NgbModal) {
  }

  open(reportKey: string, currentReportType: string): NgbModalRef {
    if (this.isOpen) {
      return;
    }
    this.isOpen = true;
    const modalRef = this.modalService.open(CopyReportModalComponent, {
      size: 'sm'
    });
    modalRef.result.then((result) => {
      this.isOpen = false;
    }, (reason) => {
      this.isOpen = false;
    });

    modalRef.componentInstance.open(reportKey, currentReportType);
    return modalRef;
  }
}

