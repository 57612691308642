import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormSaver} from '../../../../../shared/service/core/form-saver.service';
import {NgForm} from '@angular/forms';
import {CompanyDetailsDTO} from '../../../../dto/company-details-dto.model';
import {ClientStateDetails} from '../../../../model/client-state-details.model';
import {TakserFormDTO} from '../../../../../shared/dto/takser-form-dto.model';
import {isNullOrUndefined} from '../../../../../shared/utils/object-utils';

@Component({
  selector: 'lc-assessment-method',
  templateUrl: './assessment-method.component.html',
  providers: [],
  styleUrls: []
})
export class AssessmentMethodComponent implements OnInit {
  @Input() formId: string;
  @Input() details: ClientStateDetails;
  @Input() companyDetails: CompanyDetailsDTO[];
  selectedTakserform: TakserFormDTO;
  @ViewChild('f', {static: true}) public cform: NgForm;

  constructor(private formSaver: FormSaver) {
  }

  ngOnInit(): void {
    this.formSaver.registerForm(this.formId, this.cform);
    const selectedTakserformer = this.getTaksFormer().filter(value => value.kode === this.details.takserForm);
    this.selectedTakserform = selectedTakserformer.length > 0 ? selectedTakserformer[0] : null;
  }

  getTaksFormer(): TakserFormDTO[] {
    let result = [];
    const selskabKode = this.details.selskab;
    if (!isNullOrUndefined(selskabKode)) {
      const companyDetailDTOS = this.companyDetails.filter(value => value.code === selskabKode);
      if (companyDetailDTOS.length > 0) {
        result = companyDetailDTOS[0].takserformer;
      }
    }
    return result;
  }

  updateTaksForm(value: TakserFormDTO): void {
    this.selectedTakserform = value;
    this.details.takserForm = value.kode;
  }
}

