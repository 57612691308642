import {SkavuFabrikatService} from '../service/skavu-fabrikat.service';
import {SkavufabrikatDTO} from '../dto/skavu/skavufabrikat-dto.model';
import {VognartDTO} from '../dto/skavu/vognart-dto.model';
import {VogntypeDTO} from '../dto/skavu/vogntype-dto.model';
import {TakseringsartDTO} from '../dto/skavu/takseringsart-dto.model';
import {ValueObsModel} from '../../shared/model/value-obs.model';
import {combineLatest, Observable, of} from 'rxjs';
import {ListUtils} from '../../shared/utils/list-utils';
import {distinctUntilChanged, mergeMap} from 'rxjs/operators'
import {SkavuDetails} from './skavu-details.model';
import {DraftUtil} from '../draft.util';
import {isNullOrUndefined} from '../../shared/utils/object-utils';

export class DraftSkavuTaksArtModel {

  takseringsArtKode: ValueObsModel<string> = new ValueObsModel<string>();

  takseringsart: ValueObsModel<TakseringsartDTO> = new ValueObsModel<TakseringsartDTO>();
  takseringsarter: TakseringsartDTO[] = [];

  vogntype: ValueObsModel<VogntypeDTO> = new ValueObsModel<VogntypeDTO>();
  vogntyper: VogntypeDTO[] = [];

  vognart: ValueObsModel<VognartDTO> = new ValueObsModel<VognartDTO>();
  vognarter: VognartDTO[] = [];

  fabrikat: ValueObsModel<SkavufabrikatDTO> = new ValueObsModel<SkavufabrikatDTO>();
  fabrikater: SkavufabrikatDTO[] = [];

  //temporary values use while initializing the model
  initialCommonVognType: string;
  initialModelKode: string;
  initialAngivetFabrikat: string;

  constructor(private skavuTaksDataProvider: SkavuTaksDataProvider) {
    this.setupObservers();
  }

  public initializeSkavuTaks(skavuDetails: SkavuDetails): void {
    this.initialAngivetFabrikat = skavuDetails.angivetFabriat;
    if (DraftUtil.showVognArtDropdown(skavuDetails.takseringsart)) {
      this.initialModelKode = skavuDetails.modelkode;
    }
    this.initialCommonVognType = skavuDetails.commontype;
    this.takseringsArtKode.value = skavuDetails.takseringsart;
  }

  public hasVogntyper(): boolean {
    return !ListUtils.isEmpty(this.vogntyper);
  }

  public hasVognarter(): boolean {
    return !ListUtils.isEmpty(this.vognarter);
  }

  public hasFabrikater(): boolean {
    return !ListUtils.isEmpty(this.fabrikater);
  }

  private setupObservers(): void {
    //takseringsartkode obs
    this.takseringsArtKode.getObs().pipe(
      distinctUntilChanged(),
      mergeMap(artKode => combineLatest([of(artKode), this.skavuTaksDataProvider.getTakseringsarter()]))).subscribe(([artKode, takseringsarter]) => {
      this.takseringsarter = takseringsarter;
      this.takseringsart.value = takseringsarter.filter(t => t.value === artKode).shift();
    });

    //takseringsart obs
    this.takseringsart.getObs().pipe(
      distinctUntilChanged(),
      mergeMap(artKode => {
        if (isNullOrUndefined(artKode)) {
          return of<VogntypeDTO[]>([]);
        } else {
          return this.skavuTaksDataProvider.getVogntyper(artKode);
        }
      })).subscribe(vognTyper => {
      this.vogntyper = vognTyper;

      if (!isNullOrUndefined(this.initialCommonVognType)) {
        this.vogntype.value = vognTyper.filter(v => this.skavuTaksDataProvider.initialialVognTypeEqualTo(v, this.initialCommonVognType)).shift();
        this.initialCommonVognType = null;
      } else if (!isNullOrUndefined(this.vogntype.value)) {
        this.vogntype.value = vognTyper.filter(v => v.vognType === this.vogntype.value.vognType).shift();
      }
    });

    //vogntype obs
    this.vogntype.getObs().pipe(
      distinctUntilChanged(),
      mergeMap(vogntype => {
        if (isNullOrUndefined(vogntype)) {
          return of<VognartDTO[]>([]);
        } else {
          return this.skavuTaksDataProvider.getVognarter(this.takseringsart.value, vogntype);
        }
      })).subscribe(vognarter => {
      this.vognarter = vognarter;

      if (vognarter.length === 1) {
        this.vognart.value = vognarter[0];
      }

      if (!isNullOrUndefined(this.initialModelKode)) {
        this.vognart.value = vognarter.filter(v => v.modelkode === this.initialModelKode).shift();
        this.initialModelKode = null;
      } else if (!isNullOrUndefined(this.vognart.value)) {
        this.vognart.value = vognarter.filter(v => v.modelkode === this.vognart.value.modelkode).shift();
      }
    });

    //vognart obs
    this.vognart.getObs().pipe(
      distinctUntilChanged(),
      mergeMap(vognart => {
        if (isNullOrUndefined(vognart)) {
          return of<SkavufabrikatDTO[]>([]);
        } else {
          return this.skavuTaksDataProvider.getFabrikater(this.takseringsart.value, this.vogntype.value, vognart);
        }
      })).subscribe(fabrikater => {
      this.fabrikater = fabrikater;
      if (!isNullOrUndefined(this.initialAngivetFabrikat)) {
        const value = fabrikater.filter(f => f.fabrikatTekst.toUpperCase() === this.initialAngivetFabrikat.toUpperCase()).shift();
        if (!isNullOrUndefined(value)) {
          this.fabrikat.value = value;
        } else {
          this.fabrikat.value = fabrikater.filter(f => f.fabrikatTekst.toUpperCase() === SkavufabrikatDTO.OTHER_FABRIKAT_TEXT.toUpperCase()).shift();
        }
        this.initialAngivetFabrikat = null;
      } else if (!isNullOrUndefined(this.fabrikat.value)) {
        this.fabrikat.value = fabrikater.filter(f => f.fabrikatTekst.toUpperCase() === this.fabrikat.value.fabrikatTekst.toUpperCase()).shift();
      }
    });
  }
}

export interface SkavuTaksDataProvider {
  getTakseringsarter(): Observable<TakseringsartDTO[]>;

  getVogntyper(takseringsartDTO: TakseringsartDTO): Observable<VogntypeDTO[]>;

  getVognarter(takseringsartDTO: TakseringsartDTO, vogntypeDTO: VogntypeDTO): Observable<VognartDTO[]>;

  getFabrikater(takseringsartDTO: TakseringsartDTO, vogntypeDTO: VogntypeDTO, vognartDTO: VognartDTO): Observable<SkavufabrikatDTO[]>;

  initialialVognTypeEqualTo(vognType: VogntypeDTO, initialCommonVogntype: string): boolean;
}

export class VKSkavuTaksDataProvider implements SkavuTaksDataProvider {
  constructor(private skavuFabrikatService: SkavuFabrikatService) {
  }

  getFabrikater(takseringsart: TakseringsartDTO, vogntype: VogntypeDTO, vognart: VognartDTO): Observable<SkavufabrikatDTO[]> {
    //note!! this is where the two implementations differ!!
    return this.skavuFabrikatService.getFabrikater(takseringsart.id, vogntype.listetypeId, vognart.id);
  }

  getTakseringsarter(): Observable<TakseringsartDTO[]> {
    return this.skavuFabrikatService.getTakseringsarter();
  }

  getVognarter(takseringsart: TakseringsartDTO, vogntype: VogntypeDTO): Observable<VognartDTO[]> {
    return this.skavuFabrikatService.getVognarter(takseringsart.id, vogntype.listetypeId);
  }

  getVogntyper(takseringsart: TakseringsartDTO): Observable<VogntypeDTO[]> {
    return this.skavuFabrikatService.getVogntyper(takseringsart.id);
  }

  initialialVognTypeEqualTo(vognType: VogntypeDTO, initialCommonVogntype: string): boolean {
    return vognType.vognType === initialCommonVogntype;
  }
}

export class TaksSkavuTaksDataProvider implements SkavuTaksDataProvider {
  constructor(private skavuFabrikatService: SkavuFabrikatService) {
  }

  getFabrikater(takseringsart: TakseringsartDTO, vogntype: VogntypeDTO, vognart: VognartDTO): Observable<SkavufabrikatDTO[]> {
    //note!! this is where the two implementations differ!!
    const fabrikaterObs = this.skavuFabrikatService.getFabrikaterByVognTypeID(takseringsart.id, vogntype.id, vognart.id);
    return fabrikaterObs.pipe(mergeMap(value => {
      //other fabrikat could possible be defined at the serverside
      const existingOtherFabrikat = value.find(value => value.fabrikatTekst === SkavufabrikatDTO.OTHER_FABRIKAT_TEXT);
      const arrayClone = Object.assign([], value);
      if (isNullOrUndefined(existingOtherFabrikat)) {
        //this is part of a rater dirty solution ported from the flex where we rely on the last entry in fabrikat to be the template of a user provided
        //fabrikat. See: SkavutaksVognMediator.as#addEditableItem This is necessary as we need to keep a valid fabrikatkode and modelkode
        arrayClone.push(SkavufabrikatDTO.createOtherFromTemplate(value[value.length - 1]));
      }
      return of(arrayClone);
    }));
  }

  getTakseringsarter(): Observable<TakseringsartDTO[]> {
    return this.skavuFabrikatService.getTakseringsarter();
  }

  getVognarter(takseringsart: TakseringsartDTO, vogntype: VogntypeDTO): Observable<VognartDTO[]> {
    return this.skavuFabrikatService.getVognarter(takseringsart.id, vogntype.listetypeId);
  }

  getVogntyper(takseringsart: TakseringsartDTO): Observable<VogntypeDTO[]> {
    return this.skavuFabrikatService.getVogntyper(takseringsart.id);
  }

  initialialVognTypeEqualTo(vognType: VogntypeDTO, initialCommonVogntype: string): boolean {
    return vognType.value === initialCommonVogntype;
  }
}

