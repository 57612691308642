export class SearchProperties {
  searchDato: string;
  searchRegNr: string;
  searchTaksKey: string;
  searchVkKey: string;
  searchSelskab: string;
  searchState: string;
  searchVin: string;

  copy(): SearchProperties {
    return Object.assign(new SearchProperties(), this);
  }

  getDatoUpperCase(): string {
    return this.searchDato ? this.searchDato.toUpperCase() : '';
  }

  getVinUpperCase(): string {
    return this.searchVin ? this.searchVin.toUpperCase() : '';
  }

  getRegNrUpperCase(): string {
    return this.searchRegNr ? this.searchRegNr.toUpperCase() : '';
  }

  getTaksKeyUpperCase(): string {
    return this.searchTaksKey ? this.searchTaksKey.toUpperCase() : '';
  }

  getVkKeyUpperCase(): string {
    return this.searchVkKey ? this.searchVkKey.toUpperCase() : '';
  }

  getSelskabUpperCase(): string {
    return this.searchSelskab ? this.searchSelskab.toUpperCase() : '';
  }

  trim(): void {
    if (this.searchDato) {
      this.searchDato = this.searchDato.trim();
    }
    if (this.searchRegNr) {
      this.searchRegNr = this.searchRegNr.trim();
    }
    if (this.searchTaksKey) {
      this.searchTaksKey = this.searchTaksKey.trim();
    }
    if (this.searchVkKey) {
      this.searchVkKey = this.searchVkKey.trim();
    }
    if (this.searchSelskab) {
      this.searchSelskab = this.searchSelskab.trim();
    }
  }
}
