import {Component, Input, OnInit} from '@angular/core';
import {UserDTO} from '../../dto/user-dto.model';

@Component({
  selector: 'lc-inline-user',
  templateUrl: './inline-user.component.html',
  styleUrls: [
    'inline-user.scss'
  ]
})
export class InlineUserComponent implements OnInit {
  @Input() user: UserDTO;
  @Input() username = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  public isFromVK(): boolean {
    return this.user.organisationCode === 'RP'
  }

  private getName(): string {
    return this.isFromVK() ? this.user.organisationName : this.user.name;
  }

  get inlineName(): string {
    return this.username ? this.user.username : this.getName();
  }

  get popupName(): string {
    return this.username ? this.user.name : this.getName();
  }
}
