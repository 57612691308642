import {Injectable} from '@angular/core';
import {LcActionMenu, LcActionMenuItem} from '../../../shared/ui/bottombar/lc-action-menu.model';
import {DraftEditService} from '../draft-edit.service';
import {ClientStateDetails} from '../../model/client-state-details.model';
import {ForsiReportKeyPipe} from '../../../shared/pipe/forsi-report-key.pipe';
import {ForsiReportKeyShortPipe} from '../../../shared/pipe/forsi-report-key-short.pipe';
import {ActivatedRoute} from '@angular/router';
import {mergeMap} from 'rxjs/operators';
import {ReportCategory} from '../../../shared/model/report-category.model';
import {ClientNavigationService} from '../../../shared/service/client-navigation.service';
import {combineLatest} from 'rxjs';
import {ExternalValidationResultDTO} from '../../../shared/dto/external-validation-result-dto.model';
import {StringUtils} from '../../../shared/utils/string-utils';
import {ExternalValidationService} from '../../../shared/service/external-validation.service';
import URLBuilder from '../../../shared/utils/url-builder';
import {BootstrapAlertType, BootstrapGrowlService} from '../../../shared/ui/ngx-bootstrap-growl';
import {isNullOrUndefined} from '../../../shared/utils/object-utils';

@Injectable()
export class CheckReportViewModel {
  showLoading = true;
  menuBottomTitle = '';
  menuBottomShortTitle = '';
  menuIconClass = null;
  draftMenu: LcActionMenu;
  preparingPDF = false;
  private externalValidationResult: ExternalValidationResultDTO;

  constructor(private draftEditService: DraftEditService,
              private externalValidationService: ExternalValidationService,
              private reportKeyPipe: ForsiReportKeyPipe,
              private reportKeyShortPipe: ForsiReportKeyShortPipe,
              private route: ActivatedRoute,
              private bootstrapGrowlService: BootstrapGrowlService,
              private navigationService: ClientNavigationService) {
    this.initialCreateActionMenu();
    this.setupObservables();
  }

  private setupObservables(): void {
    const paramsObserver = {
      next: ([clientState, externalValidationResult]) => {
        this.setupDraftActionMenu(clientState);
        this.externalValidationResult = externalValidationResult;
        this.showLoading = false;
      },
      error: () => {
        this.showLoading = false;
        this.navigationService.back();
      }
    };
    this.route.parent.params.pipe(
      mergeMap(params => combineLatest([
          this.draftEditService.getClientStateObservable(params.token),
          this.externalValidationService.doExternalValidation(params.token)
            .pipe(mergeMap(value => this.externalValidationService.getLatestResult(params.token)))
        ]))
    ).subscribe(paramsObserver);
  }

  initialCreateActionMenu(): void {
    this.draftMenu = new LcActionMenu();
    const pdfMenuItem = new LcActionMenuItem(
      'fa-print',
      () => this.preparePDF(this.externalValidationResult.reportKey),
      'PDF');
    pdfMenuItem.disabledCallback = () => !this.hasExternalValidationResult();

    this.draftMenu.addItem(pdfMenuItem);
    this.draftMenu.add(
      'fa-arrow-left',
      () => this.navigationService.back(),
      'Tilbage');
  }

  setupDraftActionMenu(clientstateDetails: ClientStateDetails): void {
    this.menuIconClass = ReportCategory.iconCssClass(clientstateDetails.schema);
    this.menuBottomTitle = this.reportKeyPipe.transform(clientstateDetails.taksRapportKey);
    this.menuBottomShortTitle = this.reportKeyShortPipe.transform(clientstateDetails.taksRapportKey);
  }

  preparePDF(taksReportKey: string): void {
    this.preparingPDF = true;
    const pdfObserver = {
      next: prepareResult => {
        this.preparingPDF = false;
        console.log('prepared pdf: ', prepareResult);
        window.open(new URLBuilder().constructPDFStreamURL(prepareResult.token));
      },
      error: error => {
        console.log('error', error);
        this.bootstrapGrowlService.addAlert('Kunne ikke åbne taksator støtte rapport for rapport:' + taksReportKey, BootstrapAlertType.WARNING);
      }
    };
    this.externalValidationService.preparePDF(taksReportKey).subscribe(pdfObserver);
  }

  get headerColor(): string {
    let result = '';
    if (this.hasExternalValidationResult()) {
      if (StringUtils.isNotEmpty(this.externalValidationResult.headerColor)) {
        result = this.externalValidationResult.headerColor;
      } else {
        result = this.externalValidationResult.errorsFound ? 'red' : 'green';
      }
    }
    return result;
  }

  get headerText(): string {
    let result;
    if (!isNullOrUndefined(this.externalValidationResult)) {
      result = 'Resultat af rapportcheck (' + this.externalValidationResult.reportKey + ')';
    } else {
      result = 'Resultat af rapportcheck';
    }
    return result;
  }

  get bodyText(): string {
    let result;
    if (this.hasExternalValidationResult() && StringUtils.isNotEmpty(this.externalValidationResult.resultText)) {
      result = this.externalValidationResult.resultText;
    } else {
      result = 'Intet resultat tilgængeligt';
    }
    return result;
  }

  private hasExternalValidationResult(): boolean {
    return !isNullOrUndefined(this.externalValidationResult) && this.externalValidationResult.hasResult;
  }
}
