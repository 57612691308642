import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PrincipalDTO, PrincipalService} from '../../shared';
import {Observable, Subject} from 'rxjs';
import {Router} from '@angular/router';
import {LcActionMenu, LcActionMenuItem} from '../../shared/ui/bottombar/lc-action-menu.model';
import {UserDTO} from '../../shared/dto/user-dto.model';
import {SearchInputComponent} from '../../shared/ui/search/search-input.component';
import {AssessorCasesSearchProps} from '../../shared/ui/search/assessor-cases-search-props.model';

import {ReportKey} from '../reportkey.model';

import {StorageService} from '../../shared/service/storage.service';

import {AccountService} from '../../shared/service/account.service';
import {takeUntil} from 'rxjs/operators';
import {SearchType, SearchTypes} from '../../shared/ui/search/search-types.model';
import {StringUtils} from '../../shared/utils/string-utils';
import {SearchService} from '../search.service';
import {SearchTypeEnum} from '../../shared/ui/search/search-type.enum';
import {ClientstateResultDTO} from '../dto/clientstate-result-dto.model';
import {ReportResultDTO} from '../dto/report-result-dto.model';
import {ReportService} from '../../report/service/report.service';
import {CaseSearchResultModel} from '../case-search-result.model';
import {BootstrapAlertType, BootstrapGrowlService} from '../../shared/ui/ngx-bootstrap-growl';
import {ReportCategory} from '../../shared/model/report-category.model';
import {CaseSearchResultHighlighter} from '../case-search-result-higlighter';
import {isNullOrUndefined} from '../../shared/utils/object-utils';

@Component({
  templateUrl: './search.component.html',
  styleUrls: ['./search.scss'],
  providers: []
})
export class SearchComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  @ViewChild('searchInput') searchInput: SearchInputComponent;
  bottomMenu: LcActionMenu;
  loading = true;

  searchInputValid = false;

  principal: PrincipalDTO;
  results: CaseSearchResultModel[] = null;

  assessors: UserDTO[];
  rappNr: ReportKey = new ReportKey();
  vkRappNr: ReportKey = new ReportKey();
  regnr: string;
  stelnummer: string;
  skadenummer: string;
  assessorCasesSearchProps: AssessorCasesSearchProps;
  defaultAssessor: UserDTO;
  searchTypes: SearchTypes = new SearchTypes();

  showOnlyDrafts = false;
  noResultMessage = 'Ingen resultat af søgningen';

  constructor(private reportService: ReportService,
              private searchService: SearchService,
              private currentPrincipal: PrincipalService,
              private router: Router,
              private storage: StorageService,
              private accountService: AccountService,
              private bootstrapGrowlService: BootstrapGrowlService,
              private resultHighlighter: CaseSearchResultHighlighter
  ) {
    this.setupBottomActionMenu();
    this.currentPrincipal.getAuthenticationState()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(principal => this.principal = principal);
  }

  ngOnInit(): void {
    this.reset();
    this.setCurrentUserAsDefaultInCriterias();
    this.accountService.getColleagues(true, true).pipe(takeUntil(this.unsubscribe$))
      .subscribe(colleagues => {
        this.assessors = colleagues;
        this.assessors.push(this.defaultAssessor);
        this.loading = false;
      });
    this.results = this.searchService.getCurrentSearchResult();
    this.loadSearchProps();
  }

  loadSearchProps(): void {
    if (this.searchService.getCurrentSearchType()) {
      const searchObj: any = this.storage.getSearchConfig(this.searchService.getCurrentSearchType());

      switch (this.searchService.getCurrentSearchType().getCode()) {
        case SearchTypeEnum.RapportNr:
          const key = searchObj as string;
          if (StringUtils.isNotEmpty(key) && (key.length >= 14 || key.length <= 15)) {
            this.rappNr.setReportWidePk(key);
          }
          break;
        case SearchTypeEnum.VKNr:
          const vkkey = searchObj as string;
          if (StringUtils.isNotEmpty(vkkey) && vkkey.length === 14) {
            this.vkRappNr.setReportWidePk(vkkey);
          }
          break;
        case SearchTypeEnum.Regnr:
          this.regnr = searchObj as string;
          break;
        case SearchTypeEnum.Stelnummer:
          this.stelnummer = searchObj as string;
          break;
        case SearchTypeEnum.Skadenummer:
          this.skadenummer = searchObj as string;
          break;
        case SearchTypeEnum.AssessorsCases:
          const searchProps = searchObj as AssessorCasesSearchProps;
          if (!isNullOrUndefined(this.assessorCasesSearchProps)) {
            this.assessorCasesSearchProps = AssessorCasesSearchProps.create(searchProps.ngFromDate, searchProps.ngToDate,
              searchProps.vkNummer, searchProps.fabrikat, searchProps.model, searchProps.regnr, searchProps.vin, searchProps.taksUser);
          } else {
            this.assessorCasesSearchProps = new AssessorCasesSearchProps();
          }
          break;
      }
    } else {
      this.searchService.setCurrentSearchType(this.searchTypes.getTypes()[0]);
    }
  }

  reset(): void {
    const year = new Date().getFullYear().toString().substr(2);
    this.rappNr = new ReportKey();
    this.rappNr.companyId = this.principal.orgNr;
    this.rappNr.tvNr = this.principal.atKlientNr.toString();
    this.rappNr.year = year;
    this.rappNr.reportType = 'H';
    this.vkRappNr = new ReportKey();
    this.vkRappNr.companyId = 'RP';
    this.vkRappNr.year = year;
    this.vkRappNr.reportType = 'H';
    this.regnr = '';
    this.defaultAssessor = null;
    this.assessorCasesSearchProps = new AssessorCasesSearchProps();
  }

  formValid(valid: boolean): void {
    this.searchInputValid = valid;
  }

  handleResult(resultObs: Observable<CaseSearchResultModel[]>): void {
    if (resultObs !== null) {
      resultObs.pipe(takeUntil(this.unsubscribe$)).subscribe(
        result => this.results = result,
        error1 => {
          this.loading = false;
        },
        () => {
          this.loading = false;
          this.searchService.setCurrentSearchResult(this.results);
        }
      );
    }
  }

  private setupBottomActionMenu(): void {
    this.bottomMenu = new LcActionMenu();

    const searchBtn = this.bottomMenu.addItem(new LcActionMenuItem('fa-search', () => {
      this.searchInput.search();
    }, 'Søg'));

    const resetSearchBtn = this.bottomMenu.addItem(new LcActionMenuItem('fa-eraser', () => {
      this.clearSearchResult();
      this.reset();
      this.storage.resetSearchConfig();
    }, 'Ryd'));

    searchBtn.disabledCallback = () => this.loading || !this.searchInputValid;
    resetSearchBtn.disabledCallback = () => this.loading;
  }

  searchTypeChanged(searchType: SearchType): void {
    this.clearSearchResult();
    this.searchService.setCurrentSearchType(searchType);
  }

  searchByVkKey(vkKey: string): void {
    this.storage.setSearchConfig(this.searchService.getCurrentSearchType(), vkKey);
    this.search();
  }

  searchByRegNr(regNr: string): void {
    this.storage.setSearchConfig(this.searchService.getCurrentSearchType(), regNr);
    this.search();
  }

  searchByStelnummer(stelnummer: string): void {
    this.storage.setSearchConfig(this.searchService.getCurrentSearchType(), stelnummer);
    this.search();
  }

  searchBySkadenummer(skadenummer: string): void {
    this.storage.setSearchConfig(this.searchService.getCurrentSearchType(), skadenummer);
    this.search();
  }

  searchByKey(key: string): void {
    this.storage.setSearchConfig(this.searchService.getCurrentSearchType(), key);
    this.search();
  }

  searchAssessorCase(searchAssessorCases: AssessorCasesSearchProps): void {
    this.storage.setSearchConfig(this.searchService.getCurrentSearchType(), searchAssessorCases);
    this.search();
  }

  search(): void {
    this.clearSearchResult();
    let results: Observable<CaseSearchResultModel[]> = null;
    this.loadSearchProps();
    switch (this.searchService.getCurrentSearchType().getCode()) {
      case SearchTypeEnum.RapportNr:
        results = this.searchService.searchByKey(this.rappNr.getReportWidePk());
        break;
      case SearchTypeEnum.VKNr:
        results = this.searchService.searchByVkKey(this.vkRappNr.getReportWidePk());
        break;
      case SearchTypeEnum.Regnr:
        results = this.searchService.searchByRegNr(this.regnr, this.showOnlyDrafts);
        break;
      case SearchTypeEnum.Stelnummer:
        results = this.searchService.searchByStelnummer(this.stelnummer, this.showOnlyDrafts);
        break;
      case SearchTypeEnum.Skadenummer:
        results = this.searchService.searchBySkadenummer(this.skadenummer, this.showOnlyDrafts);
        break;
      case SearchTypeEnum.AssessorsCases:
        if (!this.showOnlyDrafts) {
          results = this.searchService.searchAssessorCases(
            this.assessorCasesSearchProps.getFromDate(),
            this.assessorCasesSearchProps.getToDate(),
            this.assessorCasesSearchProps.vkNummer,
            this.assessorCasesSearchProps.taksUser.atClientNumber,
            this.assessorCasesSearchProps.fabrikat,
            this.assessorCasesSearchProps.model,
            this.assessorCasesSearchProps.regnr,
            this.assessorCasesSearchProps.vin);
        } else {
          results = this.searchService.searchAssessorsDrafts(
            this.assessorCasesSearchProps.getFromDate(),
            this.assessorCasesSearchProps.getToDate(),
            this.assessorCasesSearchProps.vkNummer,
            this.assessorCasesSearchProps.taksUser.username,
            this.assessorCasesSearchProps.fabrikat,
            this.assessorCasesSearchProps.model,
            this.assessorCasesSearchProps.regnr,
            this.assessorCasesSearchProps.vin);
        }
        break;
    }
    if (results) {
      this.loading = true;
      this.handleResult(results);
    } else {
      this.results = null;
    }
  }

  setCurrentUserAsDefaultInCriterias(): UserDTO {
    const currentUserDTO = new UserDTO();
    currentUserDTO.name = this.principal.personName;
    currentUserDTO.atClientNumber = parseInt(this.principal.atKlientNr, 10);
    currentUserDTO.username = this.principal.userName;
    currentUserDTO.organisationCode = this.principal.orgNr;
    currentUserDTO.organisationName = this.principal.organizationName;
    this.defaultAssessor = currentUserDTO;
    return currentUserDTO;
  }

  isOwnClientState(clientStateResult: ClientstateResultDTO): boolean {
    let result = false;
    if (!isNullOrUndefined(clientStateResult.ownerUsername)) {
      result = clientStateResult.ownerUsername.localeCompare(this.currentPrincipal.getUsername()) === 0;
    }
    return result;
  }

  showReport(report: ReportResultDTO): void {
    this.loading = true;
    if (report.reportKey.startsWith('RP')) {
      this.reportService.getVKTilbudStatus(report.id).pipe(takeUntil(this.unsubscribe$)).subscribe(vkTilbudStatus => {
        if (vkTilbudStatus.freeforaction) {
          this.router.navigate(['/offer/', vkTilbudStatus.offerId]);
        } else {
          this.router.navigate(['/report/viewer/', report.id]);
        }
      }, error => {
        console.log('error while deciding is free for action', error);
        this.loading = false;
      });
    } else {
      this.router.navigate(['/report/viewer/', report.id]);
    }
  }

  isEditable(clientState: ClientstateResultDTO): boolean {
    return ReportCategory.enabled(clientState.type);
  }

  editDraft(clientState: ClientstateResultDTO): void {
    if (this.isEditable(clientState)) {
      this.router.navigate(['/draft/edit/', clientState.token]);
    } else {
      this.bootstrapGrowlService.addAlert('Man kan endnu ikke redigere denne ' + clientState.type + ' kladde', BootstrapAlertType.WARNING, 5000);
    }
  }

  clearSearchResult(): void {
    this.results = null;
    this.searchService.setCurrentSearchResult(this.results);
  }

  onDraftClick(clientStateResult: ClientstateResultDTO): void {
    if (this.isOwnClientState(clientStateResult)) {
      this.editDraft(clientStateResult);
    } else {
      this.router.navigate(['/draft/takeover/', clientStateResult.token]);
    }
  }

  onDraftTabCLicked(searchedCase: CaseSearchResultModel): void {
    if (searchedCase.hasDrafts()) {
      searchedCase.setDraftTabActiveInViewer(true);
    }
  }

  onReportTabCLicked(searchedCase: CaseSearchResultModel): void {
    if (searchedCase.hasReports()) {
      searchedCase.setDraftTabActiveInViewer(false);
    }
  }

  getVkOrAssessorNr(reportKey: string): string {
    const rKey = new ReportKey();
    rKey.setReportWidePk(reportKey);
    return rKey.tvNr;
  }

  getCompanyId(reportKey: string): string {
    const rKey = new ReportKey();
    rKey.setReportWidePk(reportKey);
    return rKey.companyId;
  }

  getReportnr(reportKey: string): string {
    const rKey = new ReportKey();
    rKey.setReportWidePk(reportKey);
    return rKey.year + ' ' + rKey.reportNr + ' ' + rKey.reportType;
  }

  onDraftsOnlyChanged(draftsOnly: boolean): void {
    this.showOnlyDrafts = draftsOnly;
  }

  disableReportsTab(resultCase: CaseSearchResultModel): boolean {
    return !resultCase.hasReports() || this.showOnlyDrafts;
  }

  getResultHighLighter(): CaseSearchResultHighlighter {
    return this.resultHighlighter;
  }

  noResult(): boolean {
    return isNullOrUndefined(this.results) || this.results && this.results.length === 0;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
