import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {LcActionMenu} from '../../../shared/ui/bottombar/lc-action-menu.model';
import {takeUntil} from 'rxjs/operators';
import {CarDetailsProviderService} from './car-details-provider.service';

@Component({
  selector: 'lc-car-details',
  templateUrl: './car-details.component.html'
})
export class CarDetailsComponent implements OnInit, OnDestroy {
  @Input() carDetailsService: CarDetailsProviderService;
  @Output() navigateBack = new EventEmitter<boolean>();

  private unsubscribe$ = new Subject<void>();
  menu: LcActionMenu = new LcActionMenu();
  loading = true;
  dmrDetails: any;

  constructor() {
    this.setupActionMenu();
  }

  ngOnInit(): void {
    this.carDetailsService.getCarDetails()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(dmrDetails => {
        this.dmrDetails = dmrDetails;
        this.loading = false;
      }, () => {
        this.emitNavigateBack();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  setupActionMenu(): void {
    this.menu.add(
      'fa-arrow-left',
      () => this.emitNavigateBack(),
      'Tilbage');
  }

  private emitNavigateBack(): void {
    this.navigateBack.emit(true);
  }
}
