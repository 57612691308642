import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormGroup} from '@angular/forms';
import {NonStandardPositionDTO} from '../../../shared/dto/non-standard-position/non-standard-position-dto.model';
import {
  NonStandardPositionRepairMethodDTO
} from '../../../shared/dto/non-standard-position/non-standard-position-repair-method-dto.model';
import {NonStandardPositionType} from '../../../shared/dto/non-standard-position/non-standard-position-type';


@Component({
  templateUrl: './crud-private-non-standard-position-popup.html'
})
export class CrudPrivateNonStandardPositionPopupComponent {
  @Output() onConfirm = new EventEmitter<NonStandardPositionDTO>();
  @Input() repairMethods: NonStandardPositionRepairMethodDTO[];
  @Input() nonStandardPosition: NonStandardPositionDTO = {} as NonStandardPositionDTO;
  form = new UntypedFormGroup({});
  
  constructor(private activeModal: NgbActiveModal) {
  }

  public cancel(): void {
    this.activeModal.dismiss('cancel');
  }

  create(): void {
    const nonStandardPosition = Object.assign(new NonStandardPositionDTO(), this.form.value);
    nonStandardPosition.id = this.nonStandardPosition.id;
    nonStandardPosition.itemId = this.nonStandardPosition.itemId;
    nonStandardPosition.type = NonStandardPositionType.PRIVATE
    this.onConfirm.emit(nonStandardPosition);
    this.activeModal.dismiss('confirm');
  }
}
