import {Component, Input} from '@angular/core';

@Component({
  selector: 'lc-camera-icon',
  templateUrl: './camera-icon.component.html'
})
export class CameraIconComponent {

  @Input() large = false;

  @Input() enableCamera = false;

  constructor() {
  }

}
