import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {DraftCreateComponent, DraftEditComponent, DraftListComponent} from './';
import {DRAFT_ROUTES} from 'app/draft/draft.route';
import {LightclientQapterModule} from '../shared/ui/qapter/qapter.module';
import {AttachmentModule} from '../shared/ui/attachment/attachment.module';
import {
  DraftAgreementsComponent,
  DraftDamageDescriptionComponent,
  DraftDamagesComponent,
  DraftMasterDataComponent,
  DraftMiscComponent,
  DraftValuationComponent
} from './edit/ui/.';
import {DraftSkadelidteInfoComponent} from './edit/ui/draft-skadelidte-info/draft-skadelidte-info.component';
import {DraftCalculateComponent} from './edit/calculate/draft-calculate.component';
import {SharedModule} from 'app/shared/shared.module';
import {DraftAutoflexComponent} from './edit/autoflex/draft-autoflex.component';
import {DraftEditWrapperComponent} from './edit/draft-edit-wrapper.component';
import {VkAutotaksComponent} from './edit/vk-autotaks/vk-autotaks.component';
import {VkGlastaksComponent} from './edit/vk-glastaks/vk-glastaks.component';
import {VkStortaksComponent} from './edit/vk-stortaks/vk-stortaks.component';
import {TaksAutotaksComponent} from './edit/taks-autotaks/taks-autotaks.component';
import {DraftCommonUiModule} from './edit/ui/common/draft-common-ui.module';
import {TaksAutotaksFlowOneComponent} from './edit/taks-autotaks/flow-one/taks-autotaks-flow-one.component';
import {TaksErFlowTwoComponent} from './edit/taks-autotaks/flow-two/taks-er-flow_two.component';
import {AssessmentMethodComponent} from './edit/taks-autotaks/ui/assessment-method/assessment-method.component';
import {LeasingCompanyComponent} from './edit/taks-autotaks/ui/leasing-company/leasing-company.component';
import {CalculationDateComponent} from './edit/taks-autotaks/ui/calculation-date/calculation-date.component';
import {InternInfoComponent} from './edit/taks-autotaks/ui/intern-info/intern-info.component';
import {DraftRemarksComponent} from './edit/ui/common/draft-remarks/draft-remarks.component';
import {VatInformationComponent} from './edit/taks-autotaks/ui/vat-information/vat-information.component';
import {DraftDamagesDatesComponent} from './edit/taks-autotaks/ui/draft-damages-dates/draft-damages-dates.component';
import {DraftPoliceComponent} from './edit/police/draft-police.component';
import {CondtionRulesComponent} from './edit/taks-autotaks/ui/condition-rules/condition-rules.component';
import {CheckOpslagComponent} from './edit/checkopslag/check-opslag.component';
import {DraftDamagesNoteComponent} from './edit/ui/draft-damages-note/draft-damages-note.component';
import {DraftMiscAmountsComponent} from './edit/ui/draft-misc-amounts/draft-misc-amounts.component';
import {DraftQapterCreateComponent} from './create/create-qapter/draft-qapter-create.component';
import {DraftSkavuMasterDataComponent} from './edit/ui/skavu/draft-skavu-masterdata/draft-skavu-master-data.component';
import {DraftSkavuCreateComponent} from './create/create-skavu/draft-skavu-create.component';
import {DraftSkavuPositionsComponent} from './edit/ui/skavu/draft-skavu-positions/draft-skavu-positions.component';
import {CopyDraftModalComponent} from './list/ui/copydraft/copy-draft.component';
import {CopyDraftModalService} from './service/copy-draft.modal.service';
import {ReportTypeComponent} from './create/report-type.component';
import {VkCamptaksComponent} from './edit/vk-camptaks/vk-camptaks.component';
import {VkMctaksComponent} from './edit/vk-mctaks/vk-mctaks.component';
import {SkavuFabrikatService} from './service/skavu-fabrikat.service';
import {DraftService} from './service/draft.service';
import {SkavuPositionService} from './service/skavu-position-service';
import {DraftApproveComponent} from './edit/approve/draft-approve.component';
import {TaksDraftApproveComponent} from './edit/approve/ui/taks-draft-approve/taks-draft-approve.component';
import {VkDraftApproveComponent} from './edit/approve/ui/vk-draft-approve/vk-draft-approve.component';
import {ForsiReportKeyPipe} from '../shared/pipe/forsi-report-key.pipe';
import {ForsiReportKeyShortPipe} from '../shared/pipe/forsi-report-key-short.pipe';
import {
  DraftForsikringstagerInfoComponent
} from './edit/ui/draft-forsikringstager-info/draft-forsikringstager-info.compontent';
import {
  ErReplacementReceiverComponent
} from './edit/taks-autotaks/flow-two/ui/er-replacement-receiver/er-replacement-receiver.component';
import {ErResidualDebtComponent} from './edit/taks-autotaks/flow-two/ui/er-residual-debt/er-residual-debt.component';
import {ErGbBiddersComponent} from './edit/taks-autotaks/flow-two/ui/ergb-bidders/ergb-bidders.component';
import {Flow2CarDetailsComponent} from './edit/taks-autotaks/flow-two/ui/flow2-car-details/flow2-car-details.component';
import {TaksGbFlowTwoComponent} from './edit/taks-autotaks/flow-two/taks-gb-flow_two.component';
import {TaksDraftB2bComponent} from './list/ui/taks-draft-b2b/taks-draft-b2b.component';
import {
  CreateLinkedReportFromDraftComponent
} from './ui/create-linked-report/create-linked-report-from-draft.component';
import {NewReportTypeFromDraftComponent} from './new-report-type/new-report-type-from-draft.component';
import {TaksOpFlowTwoComponent} from './edit/taks-autotaks/flow-two/taks-op-flow_two.component';
import {TaksElFlowTwoComponent} from './edit/taks-autotaks/flow-two/taks-el-flow_two.component';
import {TaksKrFlowTwoComponent} from './edit/taks-autotaks/flow-two/taks-kr-flow_two.component';
import {
  ElReplacementReceiverComponent
} from './edit/taks-autotaks/flow-two/ui/el-replacment-receiver/el-replacement-receiver.component';
import {TaksSkavutaksFlowOneComponent} from './edit/taks-autotaks/flow-one/taks-skavutaks-flow-one.component';
import {
  DraftTaksSkavuMasterDataComponent
} from './edit/ui/skavu/draft-taks-skavu-masterdata/draft-taks-skavu-masterdata.component';
import {CheckReportComponent} from './edit/checkrapport/check-report.component';
import {FileUploadModule} from 'ng2-file-upload';
import {DraftTakeoverComponent} from './takeover/draft-takeover.component';
import {DeliveryNoteDraftWrapperComponent} from '../shared/ui/delivery-note/delivery-note-draft-wrapper.component';
import {DraftDiffComponent} from './edit/diff/draft-diff.component';
import {SelvrisikoValidationDirective} from './edit/ui/draft-forsikringstager-info/selvrisiko-validation.directive';
import {DraftCarDetailsComponent} from './edit/car-details/draft-car-details.component';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {DraftAdviseModalComponent} from './list/ui/advise/draft-advise-modal.component';
import {DraftAdviseComponent} from './edit/ui/draft-advise/draft-advise.component';
import {DraftAdviseBaseComponent} from './ui/draft-advise-base/draft-advise-base.component';
import {ForwardDraftPopupComponent} from './ui/forward-draft-popup/forward-draft-popup.component';
import {
  VkDraftValidationPopupComponent
} from './edit/approve/ui/vk-draft-approve/validation-popup/vk-draft-validation-popup.component';
import {
  DraftAgreementDocumentsPopupComponent
} from './edit/ui/draft-agreements/draft-agreement-documents-popup/draft-agreement-documents-popup.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {MakeService} from '../shared/makes/service/make.service';
import {VkAgreementsPopupComponent} from './edit/ui/vk-agreements-popup/vk-agreements-popup.component';
import {DraftSparePartSupplierComponent} from './edit/spare-part-supplier/draft-spare-part-supplier.component';
import {AutoflexDeliveryTermPipe} from './edit/spare-part-supplier/autoflex-delivery-term.pipe';
import {AutoITCompanyUpdateService} from './service/auto-it/auto-it-company-update.service';
import {WorkshopUserService} from './edit/approve/service/workshop-user.service';
import {AutoflexPartListComponent} from './edit/approve/ui/autoflex-part-list/autoflex-part-list.component';
import {ModelHelpTextPopupComponent} from './ui/model-help-text-popup/model-help-text-popup.component';
import {NgxSliderModule} from 'ngx-slider-v2';
import {TruncateDisplayComponent} from './edit/spare-part-supplier/truncate-display/truncate-display.component';

@NgModule({
    imports: [
        SharedModule,
        LightclientQapterModule,
        AttachmentModule,
        DraftCommonUiModule,
        RouterModule.forRoot(DRAFT_ROUTES, {useHash: true}),
        FileUploadModule,
        LazyLoadImageModule,
        PdfViewerModule,
        NgxSliderModule,
    ],
    declarations: [
        DraftListComponent,
        DraftEditComponent,
        DraftApproveComponent,
        DraftCalculateComponent,
        DraftAutoflexComponent,
        DraftSparePartSupplierComponent,
        DraftDiffComponent,
        DraftCreateComponent,
        DraftTakeoverComponent,
        ReportTypeComponent,
        DraftQapterCreateComponent,
        DraftSkavuCreateComponent,
        DraftMasterDataComponent,
        DraftDamagesComponent,
        DraftAgreementsComponent,
        DraftDamageDescriptionComponent,
        DraftMiscComponent,
        DraftSkadelidteInfoComponent,
        DraftForsikringstagerInfoComponent,
        DraftEditWrapperComponent,
        VkAutotaksComponent,
        VkGlastaksComponent,
        VkStortaksComponent,
        VkCamptaksComponent,
        VkMctaksComponent,
        TaksAutotaksComponent,
        TaksAutotaksFlowOneComponent,
        TaksSkavutaksFlowOneComponent,
        TaksErFlowTwoComponent,
        TaksElFlowTwoComponent,
        TaksKrFlowTwoComponent,
        TaksGbFlowTwoComponent,
        TaksOpFlowTwoComponent,
        ErGbBiddersComponent,
        Flow2CarDetailsComponent,
        ErReplacementReceiverComponent,
        ElReplacementReceiverComponent,
        ErResidualDebtComponent,
        AssessmentMethodComponent,
        CondtionRulesComponent,
        LeasingCompanyComponent,
        CalculationDateComponent,
        DraftRemarksComponent,
        InternInfoComponent,
        VatInformationComponent,
        DraftDamagesDatesComponent,
        DraftPoliceComponent,
        CheckOpslagComponent,
        CheckReportComponent,
        DraftDamagesNoteComponent,
        DraftMiscAmountsComponent,
        DraftValuationComponent,
        DraftSkavuMasterDataComponent,
        DraftTaksSkavuMasterDataComponent,
        CopyDraftModalComponent,
        DraftSkavuPositionsComponent,
        TaksDraftApproveComponent,
        TaksDraftB2bComponent,
        VkDraftApproveComponent,
        CreateLinkedReportFromDraftComponent,
        NewReportTypeFromDraftComponent,
        DeliveryNoteDraftWrapperComponent,
        SelvrisikoValidationDirective,
        DraftCarDetailsComponent,
        DraftAdviseBaseComponent,
        DraftAdviseModalComponent,
        DraftAdviseComponent,
        ForwardDraftPopupComponent,
        VkDraftValidationPopupComponent,
        DraftAgreementDocumentsPopupComponent,
        VkAgreementsPopupComponent,
        AutoflexDeliveryTermPipe,
        AutoflexPartListComponent,
        ModelHelpTextPopupComponent,
        TruncateDisplayComponent
    ],
    providers: [
        CopyDraftModalService,
        SkavuFabrikatService,
        SkavuPositionService,
        DraftService,
        MakeService,
        ForsiReportKeyPipe,
        TaksDraftB2bComponent,
        ForsiReportKeyShortPipe,
        AutoITCompanyUpdateService,
        CreateLinkedReportFromDraftComponent,
        WorkshopUserService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DraftModule {
}
