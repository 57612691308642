import {StringUtils} from '../shared/utils/string-utils';

export class ReportKey {
  companyId: string;
  tvNr: string;
  year: string;
  reportNr: string;
  reportType: string;

  public static fromReportWidePk(reportWidePk: string): ReportKey {
    const result = new ReportKey();
    result.setReportWidePk(reportWidePk?.toUpperCase());
    return result;
  }

  isValid(): boolean {
    return !StringUtils.isNotEmpty(this.companyId) &&
      !StringUtils.isNotEmpty(this.tvNr) &&
      !StringUtils.isNotEmpty(this.year) &&
      !StringUtils.isNotEmpty(this.reportNr) &&
      !StringUtils.isNotEmpty(this.reportType) &&
      this.companyId.length === 2 &&
      this.tvNr.length > 0 &&
      (this.year.length === 2 || this.year.length === 1) &&
      this.reportNr.length > 0 &&
      this.reportType.length === 1 || this.reportType.length === 2;
  }

  fixReportWidePk(): void {
    while (this.reportNr.length < 4) {
      this.reportNr = '0' + this.reportNr;
    }
    while (this.tvNr.length < 5) {
      this.tvNr = '0' + this.tvNr;
    }
  }

  getPaddedValue(value, length): string {
    const result = value || '0';
    return StringUtils.padStart(result, '0', length);
  }

  getReportWidePk(): string {
    const reportWidePk = this.getPaddedValue(this.companyId, 2) + this.getPaddedValue(this.tvNr, 5) + this.getPaddedValue(this.year, 2) + this.getPaddedValue(this.reportNr, 4) + (this.reportType || '');
    return reportWidePk.toUpperCase();
  }

  setReportWidePk(reportWidePk: string): void {
    if (reportWidePk) {
      reportWidePk = reportWidePk?.toUpperCase();
      this.companyId = reportWidePk.substr(0, 2);
      this.tvNr = reportWidePk.substr(2, 5);
      this.year = reportWidePk.substr(7, 2);
      this.reportNr = reportWidePk.substr(9, 4);
      const reportTypeLength = reportWidePk.length > 14 ? 2 : 1;
      this.reportType = reportWidePk.substr(13, reportTypeLength);
    }
  }

}
