<div *ngFor="let alert of alerts">
    <div class="alert alert-{{alert.type}}" [ngClass]="{'alert-dismissible': alert.dismissable}" role="alert">
        <button type="button" class="btn-close" data-dismiss="alert" (click)="closeAlert(alert)" *ngIf="alert.dismissable">
        </button>
        <h3 *ngIf="alert.title" class="mt-0">{{alert.title}}</h3>
        <div [innerHtml]="alert.message"></div>
        <div *ngIf="alert.link" class="pt-3">
            <a href="{{alert.link}}">Læs mere</a>
        </div>
    </div>
</div>
