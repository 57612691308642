import {Injectable} from '@angular/core';
import {PostNrDTO} from '../dto/postnr-dto.model';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OpslagService {
  private serviceUrl = 'opslag/postnr';

  constructor(private http: HttpClient) {
  }

  getAllPostNr(): Observable<PostNrDTO[]> {
    return this.http.get<PostNrDTO[]>(this.serviceUrl);
  }

  getPostNr(postNr: number): Observable<PostNrDTO> {
    return this.http.get<PostNrDTO>(this.serviceUrl + '/' + postNr);
  }
}
