import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ClientStateDetails} from '../../../../model/client-state-details.model';
import {TextLibraryPopupComponent} from '../../../../../shared/modals/text-library/text-library-popup.component';
import {TextLibraryTypeEnum} from '../../../../../shared/service/text-library-type.enum';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TextAreaUtils} from '../../../../../shared/utils/text-area-utils';
import {RapportType} from '../../../../../shared/model/rapport-type.model';
import {PrincipalService} from '../../../../../shared';
import {StringUtils} from '../../../../../shared/utils/string-utils';
import URLBuilder from '../../../../../shared/utils/url-builder';
import {FormSaver} from '../../../../../shared/service/core/form-saver.service';
import {UserTextLibraryService} from '../../../../../shared/service/user-text-library.service';
import {Subject} from 'rxjs';
import {map, takeUntil, tap} from 'rxjs/operators';
import {DraftEditService} from '../../../draft-edit.service';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'lc-intern-info',
  templateUrl: './intern-info.component.html',
  styleUrls: ['./intern-info.scss']
})
export class InternInfoComponent implements OnInit, OnDestroy {
  private urlBuilder = new URLBuilder();
  private unsubscribe$ = new Subject<void>();
  private surplusChars = false;
  @Input() details: ClientStateDetails;
  @Input() formId: string;
  @ViewChild('internalInfoText', {static: true}) public internalInfoText: ElementRef<HTMLTextAreaElement>;
  @ViewChild('f', {static: true}) public cform: NgForm;
  // Documented line limit is 99
  // However backend has a limit on 4000 chars where internal info is saved.
  // Check for max 4000 characters OR max 99 lines
  internalInfoLengthLimit = 4000;
  internalInfoLinesLimit = 99;
  textLibraryType = TextLibraryTypeEnum.INTERNINFO;

  constructor(private modalService: NgbModal,
              private principal: PrincipalService,
              private formSaver: FormSaver,
              private userTextLibraryService: UserTextLibraryService,
              private draftEditService: DraftEditService) {
  }

  ngOnInit() {
    this.formSaver.registerForm(this.formId, this.cform);
    this.draftEditService.clientStateSaved
      .pipe(takeUntil(this.unsubscribe$), tap(() => this.surplusChars = false), map(saved => saved.internInfo))
      .subscribe(savedInternInfo => {
        if (this.hasMessageTooManyLines() || this.isMessageTooLong()) {
          this.internalInfoText.nativeElement.setSelectionRange(savedInternInfo.length, this.details.internInfo.length);
          this.cform.controls['internalInfoText'].setErrors({incorrect: true})
          this.surplusChars = true;
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public disableShowInternalInfoPdf(): boolean {
    return StringUtils.isEmpty(this.details.internInfo) || this.formSaver.busy;
  }

  public didClickShowInternalInfoPdf(): void {
    this.formSaver.save((wasBusy, didSave) => {
      window.open(this.urlBuilder.getBaseApiUrl() + 'clientstate/' + this.details.token + '/internalinfo/pdf', '_blank');
    }, () => {
      console.log('Failed to show pdf - the user may try again');
    });
  }

  showTextLibrary(): void {
    const modalRef = this.modalService.open(TextLibraryPopupComponent, {size: 'lg', windowClass: 'lc-fullscreen'});
    const popupComponent = modalRef.componentInstance as TextLibraryPopupComponent;
    popupComponent.title = 'Intern Info Tekstbibliotek';
    popupComponent.textLibraryType = this.textLibraryType;
    popupComponent.textLibraryService = this.userTextLibraryService;
    modalRef.result.then((text: string) => {
      if (text) {
        this.didSelectTextFromLibrary(text);
      }
    }).catch(() => {
      // Necessary to avoid "Uncaught error Error: Uncaught (in promise)" even though we do not use the returned value.
    });
  }

  didSelectTextFromLibrary(text: string) {
    this.details.internInfo = this.details.internInfo ? this.details.internInfo + text : text;
  }

  getReportType(): RapportType {
    return RapportType.extractFrom(this.details.rapportKey());
  }

  getMessageClass(): string {
    if (this.details.internInfo.length > this.internalInfoLengthLimit) {
      return 'text-danger';
    }
    return TextAreaUtils.getMessageClass(this.internalInfoLinesLimit, this.internalInfoLineCount());
  }

  getMessage(): string {
    if (this.details.internInfo.length > this.internalInfoLengthLimit) {
      let message = 'Der er maksimalt plads til ' + this.internalInfoLengthLimit + ' tegn';
      if (this.surplusChars) {
        message += '. Afskårede tegn er markeret';
      } else {
        message += ' - Slet venligst nogen. ';
      }
      return message
    }
    const remainingLengthText = ' Eller ' + (this.internalInfoLengthLimit - this.details.internInfo.length) + ' tegn.';
    return TextAreaUtils.getMessage(this.internalInfoLinesLimit, this.internalInfoLineCount()) + remainingLengthText;
  }

  public showChangeValueDeterioration(): boolean {
    return this.principal.isTaksator() && RapportType.extractFrom(this.details.rapportKey()).isOfAny([RapportType.H, RapportType.T]);
  }

  public internalInfoLineCount(): number {
    return this.details.internInfo.split('\n').length;
  }

  hasMessageTooManyLines(): boolean {
    return this.internalInfoLineCount() > this.internalInfoLinesLimit;
  }

  isMessageTooLong(): boolean {
    return this.details.internInfo.length > this.internalInfoLengthLimit;
  }
}
