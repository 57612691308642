import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {LoadingSpinnerUtil} from '../../../../utils/loading-spinner-util';
import {map, takeUntil} from 'rxjs/operators';
import {VideoRequestResultDTO} from '../model/video-request-result-dto.model';
import {ValueObsModel} from '../../../../model/value-obs.model';
import {FileUploader} from 'ng2-file-upload';
import {VideoRequestResponseState} from '../model/video-request-state.model';
import {LcPopupService} from '../../../../modals/lc-popup.service';

@Component({
  selector: 'lc-workshop-video-request-upload-button',
  templateUrl: './workshop-video-request-upload-button.component.html',
  providers: [LoadingSpinnerUtil]
})
export class WorkshopVideoRequestUploadButtonComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  @Input() videoRequest$: Observable<VideoRequestResultDTO>;
  @Input() videoRequestResponseState$: Observable<VideoRequestResponseState>;
  @Input() fileUploader: FileUploader;

  public buttonStateValueObs = new ValueObsModel<VideoRequestResponseState>(VideoRequestResponseState.NO_REQUEST);
  public showRespondToVideoRequestButton$: Observable<boolean>;
  public videoRequestIsResponded: Observable<boolean>;
  public videoRequestMessage: string;

  constructor(public spinnerUtil: LoadingSpinnerUtil, private lcPopupService: LcPopupService) {
  }

  ngOnInit(): void {
    this.setupObservables();
  }

  private setupObservables(): void {
    this.videoRequest$.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(result => {
      this.videoRequestMessage = result.videoRequest?.message;
      this.spinnerUtil.stopLoading();
    }, () => {
      this.videoRequestMessage = null;
      this.spinnerUtil.stopLoading();
    });

    //observe for changes to the related video request
    this.videoRequestResponseState$.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(state => {
      this.buttonStateValueObs.value = state;
    });
    this.showRespondToVideoRequestButton$ = this.buttonStateValueObs.$.pipe(map(state => state !== VideoRequestResponseState.NO_REQUEST));
    this.videoRequestIsResponded = this.buttonStateValueObs.$.pipe(map(buttonState => VideoRequestResponseState.RESPONDED === buttonState));
  }

  public showVideoRequestInfo(): void {
    this.lcPopupService.textPopup('Video forespørgsel', this.videoRequestMessage).pipe(takeUntil(this.unsubscribe$)).subscribe(() => {});
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
