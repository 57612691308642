import {AfterViewInit, Component, ElementRef, forwardRef, HostListener, Input, Renderer2} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {isNullOrUndefined} from '../../utils/object-utils';

const noop = () => {
};

@Component({
  selector: 'lc-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: [
    './autocomplete.scss'
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => AutocompleteComponent),
    }
  ]
})
export class AutocompleteComponent implements ControlValueAccessor, AfterViewInit {
  private onChangeCallback: (_: any) => void = noop;
  private onTouchedCallback: () => void = noop;
  public query = '';
  public filteredList = [];
  selectedIndex: number;
  private _selectedItem = null;

  _items: any[];
  @Input() autoFocus = false;

  @Input() onSearch: (query: string) => any[];
  @Input() itemKey = 'id';
  @Input() exactKey: string;
  @Input() itemText: string;
  @Input() subItemText: string;
  @Input() debugInfo: string;
  @Input() placeholder?: string;
  @Input() uppercase = false;
  @Input('limit') providedLimit?: number;
  limit: number;

  @Input() set items(items: any[]) {
    if (this._items !== items) {
      this._items = items;
      this.limit = this.providedLimit || (items ? items.length : 0)
      if (!this._selectedItem) {
        this.query = '';
      }
    }
  }

  @HostListener('focusout')
  onFocusOut(): void {
    const div = this._elementRef.nativeElement.getElementsByClassName('suggestions').item(0);
    if (document.activeElement === div) {
      return;
    }
    if (this.filteredList && this.filteredList.length > 0) {
      this.selectCurrent();
    }
    this.filteredList = [];
    if (!isNullOrUndefined(this._selectedItem)) {
      this.query = this.getItemTextFull(this._selectedItem);
    }
    this.onTouchedCallback();
  }

  @HostListener('focusin', ['$event'])
  onFocusIn(event): void {
    this.resetToItem(this._selectedItem, true);
    setTimeout(() => {
      event.target.setSelectionRange(0, 9999);
    }, 1);
  }

  ngAfterViewInit(): void {
    if (isNullOrUndefined(this._selectedItem) && this._items && this._items.length === 1) {
      this._selectedItem = this._items[0];
    }

    if (this.autoFocus) {
      this._elementRef.nativeElement.getElementsByTagName('INPUT').item(0).focus();
    }
  }

  constructor(private renderer: Renderer2, private _elementRef: ElementRef) {
    this.selectedIndex = 0;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this._elementRef.nativeElement.getElementsByTagName('INPUT').item(0), 'disabled', isDisabled);
  }

  //Set touched on blur
  writeValue(obj: any): void {
    this._selectedItem = obj;
    if (isNullOrUndefined(obj)) {
      this.query = '';
    } else {
      this.query = this.getItemTextFull(obj);
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  inputClick(event): void {

  }

  resetToItem(item: any, scrollToTop: boolean): void {
    this.filteredList = this._items;
    this.selectedIndex = this._items.indexOf(item);
    setTimeout(() => {
      this.scrollToCurrent(scrollToTop);
    })
  }

  public scrollToCurrent(scrollToTop: boolean): void {
    const elements = this._elementRef.nativeElement.getElementsByTagName('li');
    if (elements.length > 0) {
      if (this.selectedIndex > 0 && this.selectedIndex < elements.length) {
        const element = elements.item(this.selectedIndex);
        if (scrollToTop) {
          //element.scrollIntoView({behavior: "instant", block: 'nearest'});
        } else {
          element.scrollIntoViewIfNeeded()
        }
      }
    }
  }

  preFilter(event): void {
    if (event && event.key) {
      switch (event.key) {
        case 'ArrowDown':
          if (this.filteredList.length === 0) {
            this.resetToItem(this._selectedItem, false);
          } else {
            this.selectedIndex++;
            if (this.selectedIndex >= this.filteredList.length) {
              this.selectedIndex = this.filteredList.length - 1;
            }
            this.scrollToCurrent(false);
          }
          break;
        case 'ArrowUp':
          this.selectedIndex--;
          if (this.selectedIndex < 0) {
            this.selectedIndex = 0;
          }
          this.scrollToCurrent(false);
          break;
        case 'Enter':
          event.preventDefault();
          if (this.filteredList.length === 0) {
            this.resetToItem(this._selectedItem, true);
          } else {
            this.selectCurrent();
          }
          break;
        default:
          break;
      }
    }
  }

  search(): any[] {
    this.selectedIndex = 0;
    let result = this._items.filter(item => {
      if (this.query === '' || isNullOrUndefined(this.exactKey)) {
        return false;
      }
      const exactValue = item[this.exactKey];
      return !isNullOrUndefined(exactValue) && exactValue.toString().toLowerCase().trim() === this.query.toLowerCase();
    });
    if (result.length === 0) {
      result = this._items.filter(item => this.query === '' || this.getItemTextFull(item).toLowerCase().indexOf(this.query.toLowerCase()) > -1);
    }
    return result;
  }

  getItemTextFull(item: any): string {
    if (!!item[this.itemText]) {
      return item[this.itemText] + (this.subItemText && item[this.subItemText] ? ' [' + item[this.subItemText] + ']' : '');
    }
    return '';
  }

  filter(event): void {
    switch (event.key) {
      case 'ArrowDown':
      case 'ArrowUp':
      case 'Enter':
        break;
      default:
        if (this.onSearch) {
          this.filteredList = this.onSearch(this.query);
        } else {
          this.filteredList = this.search().slice();
        }
        //if the selection is empty, we deselect
        if (this.query === '') {
          this.selectedIndex = -1;
          this.unselect();
        }
        break;
    }
  }

  selectCurrent(): void {
    if (this.selectedIndex === -1) {
      return;
    }
    this.select(this.filteredList[this.selectedIndex])
  }

  unselect(): void {
    const newValue = null;
    const didChange = newValue !== this._selectedItem;
    this.query = '';
    this._selectedItem = newValue;
    if (didChange) {
      //only call onChangeCallback in case data really changes
      this.onChangeCallback(this._selectedItem);
    }
  }

  select(item): void {
    const didChange = item !== this._selectedItem;
    this.query = this.getItemTextFull(item);
    this._selectedItem = item;
    this.filteredList = [];
    if (didChange) {
      //only call onChangeCallback in case data really changes
      this.onChangeCallback(item);
      this._elementRef.nativeElement.getElementsByTagName('INPUT').item(0).blur();
    }
  }

  // HACK: For some reason, we get focusout events when the user clicks/scrolls the scrollbar
  // in our autocomplete suggestions list.
  // BUT only when the autocomplete component is used in a modal window!
  // Developers on the big internet, suggest using this little hack to prevent
  // the focus out event from happening during scroll:
  // https://newbedev.com/clicking-on-a-div-s-scroll-bar-fires-the-blur-event-in-i-e
  // Hence we do the same...
  public preventFocusOutOnScrollbarClick(): boolean {
    return false;
  }
}
