import {DataTableDirective} from 'angular-datatables';
import {from, Subject} from 'rxjs';
import {ADTSettings} from 'angular-datatables/src/models/settings';
import {takeUntil} from 'rxjs/operators';
import {StringUtils} from './utils/string-utils';

export class LcDeclarativeDataTable {
  get dtTrigger(): Subject<ADTSettings> {
    return this._dtTrigger;
  }
  get dtOptions(): ADTSettings {
    return this._dtOptions;
  }

  private _dataTableDirective: DataTableDirective;
  private _dtTrigger = new Subject<ADTSettings>();
  private _dtOptions: ADTSettings = {};
  private _api: DataTables.Api;

  constructor(private unsubscribe$: Subject<ADTSettings>) {
  }

  init(dataTableDirective: DataTableDirective, dtOptions: ADTSettings) {
    this._dataTableDirective = dataTableDirective;
    dtOptions.initComplete = this.initCallback.bind(this);
    this._dtOptions = dtOptions;
    let columnDefs = this._dtOptions.columnDefs;
    if (columnDefs === null || columnDefs === undefined) {
      columnDefs = [];
    }
    columnDefs.push({ targets: '_all', render:$.fn.dataTable.render.text() });
    this._dtOptions.columnDefs = columnDefs;
  }

  private initCallback() {
    if (!!this._dataTableDirective?.dtInstance) {
      from(this._dataTableDirective.dtInstance).pipe(takeUntil(this.unsubscribe$)).subscribe((api: DataTables.Api) => this._api = api);
    }

  }

  setData(data: any[]) {
    this._dtOptions.data = data;
    this._dtTrigger.next(this._dtOptions);
  }
  
  getData(): any[] {
    return this._dtOptions.data;
  }
  
  refresh(getDataCallback: () => void) {
    from(this._dataTableDirective.dtInstance).pipe(takeUntil(this.unsubscribe$)).subscribe((api: DataTables.Api) => {
      this._api = api;
      api.destroy();
      getDataCallback();
    });
  }

  public isFiltering(): boolean {
    return this._api && StringUtils.isNotEmpty(this._api.search());
  }
}
