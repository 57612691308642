import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './simple-popup.component.html',
})
export class SimplePopupComponent {
  @Input() title: string;
  @Input() body: string;
  @Input() unsafeHtml = false;
  submitting: boolean;

  constructor(private activeModal: NgbActiveModal) {
  }

  public cancel(): void {
    this.activeModal.dismiss('cancel');
  }
}
