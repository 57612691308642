<form #f="ngForm" autocomplete="off">
    <div class="card mb-4">
        <div class="card-body">
            <div class="row">
                <!-- Left part -->
                <div class="col-md-6">
                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label for="regnr">Reg. nr</label>
                        </div>
                        <div class="col">
                            <input [(ngModel)]="createViewModel.regNr" class="form-control text-uppercase" type="text" id="regnr"
                                name="regnr" lcInputMask="regNr" [disabled]="details.vehicle.nummerpladeType===' '"
                                minlength=1 maxlength=10 size="10" required />
                        </div>
                    </div>
                    <div class="row pb-3" *ngIf="showTakseringsArtDropdown()">
                        <div class="col-form-label col-4">
                            <label class="m-0" for="takseringsart">Takseringsart</label>
                        </div>
                        <div class="col">
                            <lc-autocomplete id="takseringsart" name="takseringsart" required
                                [items]="taksArtViewModel.takseringsarter"
                                [(ngModel)]="taksArtViewModel.takseringsart.value" debugInfo="takseringsart" itemid="id"
                                itemText="navn"></lc-autocomplete>
                        </div>
                    </div>

                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label class="m-0" for="vogntype">Vogntype</label>
                        </div>
                        <div class="col">
                            <lc-autocomplete id="vogntype" name="vogntype" required
                                [disabled]="!taksArtViewModel.hasVogntyper()" [items]="taksArtViewModel.vogntyper"
                                [(ngModel)]="taksArtViewModel.vogntype.value" debugInfo="vogntype" itemid="id"
                                itemText="navn"></lc-autocomplete>
                        </div>
                    </div>
                    <div class="row pb-3" *ngIf="showVognArtDropdown()">
                        <div class="col-form-label col-4">
                            <label class="m-0" for="vognart">Vognart</label>
                        </div>
                        <div class="col">
                            <lc-autocomplete id="vognart" name="vognart" required
                                [disabled]="!taksArtViewModel.hasVognarter()" [items]="taksArtViewModel.vognarter"
                                [(ngModel)]="taksArtViewModel.vognart.value" debugInfo="vognart" itemid="id"
                                itemText="art"></lc-autocomplete>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label class="m-0" for="fabrikat">Fabrikat</label>
                        </div>
                        <div class="col">
                            <lc-autocomplete id="fabrikat" name="fabrikat" required
                                [disabled]="!taksArtViewModel.hasFabrikater()" [items]="taksArtViewModel.fabrikater"
                                [(ngModel)]="taksArtViewModel.fabrikat.value" debugInfo="fabrikat" itemid="id"
                                itemText="fabrikatTekst"></lc-autocomplete>
                        </div>
                    </div>
                </div>
                <!-- Right part -->
                <div class="col-md-6">
                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label>Nummerplade</label>
                        </div>
                        <div class="col-8">
                            <div class="row">
                                <div class="btn-group">
                                    <input type="radio" class="btn-check" name="nummerpladeType" value="H" id="HVID" autocomplete="off" [(ngModel)]="details.vehicle.nummerpladeType"
                                           (ngModelChange)="createViewModel.nummerpladeTypeChanged()"/>
                                    <label class="btn btn-secondary btn-sm" for="HVID">Hvid</label>
                                    <input type="radio" class="btn-check" name="nummerpladeType" value="G" id="GUL" autocomplete="off" [(ngModel)]="details.vehicle.nummerpladeType"
                                           (ngModelChange)="createViewModel.nummerpladeTypeChanged()"/>
                                    <label class="btn btn-secondary btn-sm" for="GUL">Gul/Papegøje</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="btn-group">
                                    <input type="radio" class="btn-check" name="nummerpladeType" value="B" id="BLAA" autocomplete="off" [(ngModel)]="details.vehicle.nummerpladeType"
                                           (ngModelChange)="createViewModel.nummerpladeTypeChanged()"/>
                                    <label class="btn btn-secondary btn-sm" for="BLAA">Blå</label>
                                    <input type="radio" class="btn-check" name="nummerpladeType" value="P" id="PROEVE" autocomplete="off" [(ngModel)]="details.vehicle.nummerpladeType"
                                           (ngModelChange)="createViewModel.nummerpladeTypeChanged()"/>
                                    <label class="btn btn-secondary btn-sm" for="PROEVE">Prøve</label>
                                    <input type="radio" class="btn-check" name="nummerpladeType" value=" " id="INGEN" autocomplete="off" [(ngModel)]="details.vehicle.nummerpladeType"
                                           (ngModelChange)="createViewModel.nummerpladeTypeChanged()"/>
                                    <label class="btn btn-secondary btn-sm" for="INGEN">Ingen</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
