import {Observable, of, Subject} from 'rxjs';
import {LcActionMenu, LcActionMenuItem} from '../../../shared/ui/bottombar/lc-action-menu.model';
import {DraftEditService} from '../draft-edit.service';
import {Injectable} from '@angular/core';
import {ClientStateDetails} from '../../model/client-state-details.model';
import {ActivatedRoute} from '@angular/router';
import {ForsiReportKeyPipe} from '../../../shared/pipe/forsi-report-key.pipe';
import {ForsiReportKeyShortPipe} from '../../../shared/pipe/forsi-report-key-short.pipe';
import {catchError, mergeMap} from 'rxjs/operators';
import {PoliceService} from '../../../shared/service/police.service';
import {ReportCategory} from '../../../shared/model/report-category.model';
import {PolicyDTO} from '../../../shared/dto/policy-dto.model';
import {ClientStatePolicyDTO} from '../../../shared/model/client-state-policy-dto.model';
import {StringUtils} from '../../../shared/utils/string-utils';
import {DraftService} from '../../service/draft.service';
import {combineLatest} from 'rxjs';

@Injectable()
export class DraftPoliceViewModel {
  showLoading = true;
  policy: PolicyDTO;
  menuBottomTitle = '';
  menuBottomShortTitle = '';
  menuIconClass = null;
  draftMenu: LcActionMenu;
  details: ClientStateDetails;
  navigateBackSubject = new Subject<void>();
  newPoliceObservable = new Observable<PolicyDTO>();
  private policeRequestAllowed = false;

  constructor(private draftEditService: DraftEditService,
              private policeService: PoliceService,
              private draftService: DraftService,
              private route: ActivatedRoute,
              private reportKeyPipe: ForsiReportKeyPipe,
              private reportKeyShortPipe: ForsiReportKeyShortPipe) {
    this.draftMenu = new LcActionMenu();
    this.setupObservables();
  }

  onPoliceLoaded(police: PolicyDTO): void {
    this.showLoading = false;
    this.policy = police;
  }

  reload(): void {
    this.showLoading = true;
    const newPoliceObserver = {
      next: (policy: PolicyDTO) => {
        this.details.police = ClientStatePolicyDTO.fromPolicy(policy);
        this.onPoliceLoaded(policy);
        this.policeService.populatePolicyholderByPoliceIfExists(this.details);
        this.policeService.populateLeasingCompanyByPoliceIfExists(this.details);
        this.draftEditService.save().subscribe(cs => {
        });
      }, error: () => {
        this.showLoading = false;
      }
    };
    this.newPoliceObservable.subscribe(newPoliceObserver);
  }

  setupDraftActionMenu(clientstateDetails: ClientStateDetails): void {
    this.menuIconClass = ReportCategory.iconCssClass(clientstateDetails.schema);
    this.menuBottomTitle = this.reportKeyPipe.transform(clientstateDetails.taksRapportKey);
    this.menuBottomShortTitle = this.reportKeyShortPipe.transform(clientstateDetails.taksRapportKey);

    const policeRequestAction = new LcActionMenuItem('fa-id-card-o',
      () => this.reload(),
      'Hent');
    policeRequestAction.disabledReason = 'Der skal vælges selskab før der kan laves et policeopslag';
    policeRequestAction.disabledCallback = () => !this.policeRequestAllowed;

    this.draftMenu.add(
      'fa-arrow-left',
      () => this.navigateBackSubject.next(null),
      'Tilbage');
    this.draftMenu.addItem(policeRequestAction);
  }

  setupPoliceObservable(clientStateDetails: ClientStateDetails): void {
    this.policeRequestAllowed = StringUtils.isNotEmpty(clientStateDetails.selskab);
    this.newPoliceObservable = this.draftService.searchNewPolicy(clientStateDetails.token);
  }

  private setupObservables(): void {
    const paramsObserver = {
      next: ([clientStateDetails, policyDTO]) => {
        this.details = clientStateDetails;
        this.setupDraftActionMenu(clientStateDetails);
        this.setupPoliceObservable(clientStateDetails);
        this.onPoliceLoaded(policyDTO);
      },
      error: err => {
        console.log('Error', err);
        this.navigateBackSubject.next(null);
      }
    };
    this.route.parent.params.pipe(
      mergeMap(params => combineLatest([this.draftEditService.getClientStateObservable(params.token), this.draftService.searchForPolicy(params.token)
        .pipe(catchError(e => of(null)))]))).subscribe(paramsObserver);
  }
}
