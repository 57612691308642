import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {NgbForsiDateMomentParserFormatter} from '../..';
import {UserDTO} from '../../dto/user-dto.model';

export class AssessorCasesSearchProps {
  vkNummer: string;
  fabrikat: string;
  model: string;
  regnr: string;
  vin: string;
  taksUser: UserDTO;
  ngFromDate: NgbDateStruct;
  ngToDate: NgbDateStruct;

  static create(fromDate: NgbDateStruct,
                toDate: NgbDateStruct,
                vkNummer: string,
                fabrikat: string,
                model: string,
                regnr: string,
                vin: string,
                taksUser: UserDTO): AssessorCasesSearchProps {
    const assessorCasesSearchProps = new AssessorCasesSearchProps();
    assessorCasesSearchProps.ngFromDate = fromDate;
    assessorCasesSearchProps.ngToDate = toDate;
    assessorCasesSearchProps.vkNummer = vkNummer;
    assessorCasesSearchProps.fabrikat = fabrikat;
    assessorCasesSearchProps.model = model;
    assessorCasesSearchProps.regnr = regnr;
    assessorCasesSearchProps.vin = vin;
    assessorCasesSearchProps.taksUser = taksUser;
    return assessorCasesSearchProps;
  }

  getFromDate(): Date {
    return new NgbForsiDateMomentParserFormatter().toDate(this.ngFromDate);
  }

  getToDate(): Date {
    return new NgbForsiDateMomentParserFormatter().toDate(this.ngToDate);
  }
}
