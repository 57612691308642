import {Component, Input, OnInit} from '@angular/core';
import {PrincipalService} from '../..';
import {PolicyDTO, PolicyIncidentDTO} from '../../dto/policy-dto.model';
import {DateUtils} from '../../utils/date-utils';

@Component({
  selector: 'lc-show-police',
  templateUrl: './show-police.component.html',
  styleUrls: [
    './show-police.scss'
  ]
})
export class ShowPoliceComponent implements OnInit {
  @Input() policy: PolicyDTO;
  constructor(private principalService: PrincipalService) {
  }

  ngOnInit(): void {
  }

  isVK(): boolean {
    return this.principalService.isVK();
  }

  getSortedIncidents(): PolicyIncidentDTO[] { //Sort by State & Date
    this.policy.incidents.sort(
      (incidentA, incidentB): number => {
        if (incidentA.state === incidentB.state) {
          return DateUtils.compare(incidentB.incidentAt, incidentA.incidentAt);
        }
        return incidentA.state < incidentB.state ? 1 : -1;
      });
    return this.policy.incidents;
  }

  getType(type: string): string {
    switch (type) {
      case 'POLICY_HOLDER':
        return 'Forsikringstager'
      case 'VEHICLE_OWNER':
        return 'Ejer/Leasingselskab'
      case 'VEHICLE_USER':
        return 'Bruger'
    }
  }
}
