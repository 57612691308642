import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {FormSaver} from '../../../../shared/service/core/form-saver.service';
import {ClientStateDifferService} from '../../checkopslag/client-state-differ.service';
import {DraftEditFlowComponent} from '../../draft-edit-flow.component';
import {PrincipalService} from '../../../../shared';
import {AgreementService} from '../../../../shared/service/agreement.service';
import {DraftService} from '../../../service/draft.service';
import {DraftEditService} from '../../draft-edit.service';
import {ForsiReportKeyPipe} from '../../../../shared/pipe/forsi-report-key.pipe';
import {ForsiReportKeyShortPipe} from '../../../../shared/pipe/forsi-report-key-short.pipe';
import {ActivatedRoute, Router} from '@angular/router';
import {BootstrapGrowlService} from '../../../../shared/ui/ngx-bootstrap-growl';
import {combineLatest, Subject} from 'rxjs';
import {UIUtils} from '../../../../shared/utils/ui-utils';
import {CompanyDetailsDTO} from '../../../dto/company-details-dto.model';
import {AttachmentUseCase} from '../../../../shared/model/attachment-use-case.model';
import {ClientStateDetails} from '../../..';
import {MessageDTO} from '../../../../shared/dto/message-dto.model';
import {TextLibraryTypeEnum} from '../../../../shared/service/text-library-type.enum';
import {ContextMenu, ContextMenuItem} from '../../../../shared/ui/context-menu/context-menu.model';
import {LcActionMenuItem} from '../../../../shared/ui/bottombar/lc-action-menu.model';
import {takeUntil} from 'rxjs/operators';
import {ClientNavigationService} from '../../../../shared/service/client-navigation.service';
import {AssessmentConfigurationService} from '../../../../shared/service/assessment-configuration.service';
import {AssessmentConfigurationDTO} from '../../../../shared/dto/assessment-configuration-dto.model';
import {isNullOrUndefined} from '../../../../shared/utils/object-utils';
import {StringUtils} from '../../../../shared/utils/string-utils';
import {CopyDraftModalService} from '../../../service/copy-draft.modal.service';

/**
 * Is an Skavutaks Flow One component (H, Tx and Sx)
 */
@Component({
  selector: 'lc-taks-skavutaks-flow-one',
  templateUrl: './taks-skavutaks-flow-one.component.html',
  styleUrls: [
    '../../../draft.scss', '../../draft-edit.scss', './../taks-autotaks.scss'
  ],
  providers: [FormSaver, ClientStateDifferService]
})
export class TaksSkavutaksFlowOneComponent extends DraftEditFlowComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  listeTypeId: number;
  companyDetails: CompanyDetailsDTO[];
  isAgreementsLoading = true;
  attachmentUseCase = AttachmentUseCase.Draft;
  messages: MessageDTO[];
  textLibraryType = TextLibraryTypeEnum.BESKED;
  assessmentConfiguration: AssessmentConfigurationDTO;

  constructor(principal: PrincipalService,
              private agreementService: AgreementService,
              private assessmentConfigurationService: AssessmentConfigurationService,
              clientStateService: DraftService,
              draftEditService: DraftEditService,
              reportKeyPipe: ForsiReportKeyPipe,
              reportKeyShortPipe: ForsiReportKeyShortPipe,
              formSaver: FormSaver,
              router: Router,
              route: ActivatedRoute,
              bootstrapGrowlService: BootstrapGrowlService,
              el: ElementRef,
              clientNavigationService: ClientNavigationService,
              copyDraftModalService: CopyDraftModalService) {
    super(formSaver, reportKeyPipe, reportKeyShortPipe, clientStateService, draftEditService, principal, router, route, bootstrapGrowlService, el, clientNavigationService, copyDraftModalService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.route.params
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(params => {
        this.details.token = params.token;
        const fragment = this.route.snapshot.fragment;
        const clientStateObs = this.draftEditService.getClientStateObservable(this.details.token);
        const companyDetailsObs = this.draftEditService.getCompanyDetailsObservable();
        const configurationObs = this.assessmentConfigurationService.getConfiguration();
        combineLatest([clientStateObs, companyDetailsObs, configurationObs])
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(([clientStateDetails, companyDetails, assessmentConfiguration]) => {
            this.initializeWithClientState(clientStateDetails);
            this.companyDetails = companyDetails;
            this.assessmentConfiguration = assessmentConfiguration;
            this.isLoading = false;
            this.possibleNavigateToFragmentUrlPart(fragment);
          }, error => {
            this.router.navigate(['/draft']);
          });
      });
  }

  initializeWithClientState(clientStateDetails: ClientStateDetails): void {
    super.initializeWithClientState(clientStateDetails);
    this.getMessages();
  }

  public isFormValid(): boolean {
    return (!this.formSaver.isInError() && this.formSaver.isValid());
  }

  setupContextMenu(): void {
    this.contextMenu = new ContextMenu();
    const uiUtils = new UIUtils();
    this.contextMenu.addItem(new ContextMenuItem('fa-vcard-o', 'Stamdata', 'masterdata', () => {
      uiUtils.scrollToElementId(['masterdata']);
    }, () => !this.formSaver.isFormsValid(['masterData', 'conditionRules', 'draftDamagesDates', 'forsikringInfo', 'skadelidteInfo', 'leasingselskab'])));

    this.contextMenu.addItem(new ContextMenuItem('fa-handshake-o', 'Prisaftaler', 'agreements', () => {
      uiUtils.scrollToElementId(['agreements', 'assessment-method']);
    }, () => !(this.isAgreementsLoading || this.formSaver.isFormsValid(['agreements', 'assessmentMethod']))));

    this.contextMenu.addItem(new ContextMenuItem('fa-car', 'Skader', 'positions', () => {
      uiUtils.scrollToElementId(['positions', 'damagedescription']);
    }, () => {
      const zoneDataProvided = this.details.damageDescription.zoneIsValid();
      return !(this.formSaver.isFormsValid(['positions', 'damagedescription']) && zoneDataProvided);
    }));

    this.contextMenu.addItem(new ContextMenuItem('fa-database', 'Værdi', 'valuation', () => {
      uiUtils.scrollToElementId(['valuation']);
    }, () => !this.formSaver.isFormsValid(['valuation'])));

    this.contextMenu.addItem(new ContextMenuItem('fa-image', 'Bilag', 'attachments', () => {
      uiUtils.scrollToElementId(['attachments']);
    }));

    this.contextMenu.addItem(new ContextMenuItem('fa-archive', 'Diverse', 'misc', () => {
      uiUtils.scrollToElementId(['misc']);
    }));
  }

  createPoliceMenuItem(): LcActionMenuItem {
    const policyMenuItem = new LcActionMenuItem('fa-id-card-o', () => {
      this.draftEditService.scrollPosition = window.pageYOffset;
      this.router.navigate(['police'], {relativeTo: this.route});
    }, 'Policeopslag');
    policyMenuItem.disabledCallback = () => this.isMissingCompanySelection()
    policyMenuItem.disabledReason = 'Der mangler at blive angivet et selskab';
    return policyMenuItem;
  }

  private isMissingCompanySelection(): boolean {
    return !isNullOrUndefined(this.details) && StringUtils.isEmpty(this.details.selskab);
  }

  setupDraftActionMenu(): void {
    if (this.clientNavigationService.getPreviousLocation() === '/search') {
      this.draftMenu.addItem(this.createBackMenuItem());
    }
    this.draftMenu.addItem(this.createCopyDraftMenuItem());
    this.draftMenu.addItem(this.createNewReportTypeMenuItem());
    this.draftMenu.addItem(this.createPoliceMenuItem());
    this.draftMenu.addItem(this.createCheckOpslagMenuItem(this.agreementService.getPriceAgreementsDeviatesSubject(), this.unsubscribe$));
    this.draftMenu.addItem(this.createDmrLookupMenuItem());
    const calcItem = this.createCalculateActionMenu();
    calcItem.disabledCallback = () => this.formSaver.busy;
    this.draftMenu.addItem(calcItem);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
