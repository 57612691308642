import {Injectable} from '@angular/core';
import {BootstrapAlertType} from './bootstrap-alert-type.enum';
import {BootstrapAlert} from './bootstrap-alert.model';
import {Subject, timer} from 'rxjs';
import {PrincipalService} from '../../service/auth/principal.service';

@Injectable()
export class BootstrapGrowlService {
  public alerts: Subject<BootstrapAlert[]> = new Subject<BootstrapAlert[]>();
  public onAlertRemovedManually: Subject<BootstrapAlert> = new Subject<BootstrapAlert>();

  public alertHolder: BootstrapAlert[] = new Array<BootstrapAlert>();

  alertCount = 999;
  autoClose = -1;

  constructor(principalService: PrincipalService) {
    principalService.getAuthenticationState().subscribe((principal) => {
      if (!principal) {
        this.alertHolder = []
        this.alerts.next([]);
      }
    })
  }



  public configure(alertCount: number, autoClose: number): void {
    if (typeof alertCount !== 'undefined' && alertCount !== null) {
      if (!isNaN(alertCount) && alertCount > 0) {
        this.alertCount = alertCount;
      } else {
        console.error('parameter alertCount must be a valid number > 0, to leave default, do not provide this parameter');
      }
    }
    if (typeof autoClose !== 'undefined' && autoClose !== null) {
      if (!isNaN(autoClose) && autoClose > 0) {
        this.autoClose = autoClose;
      } else {
        console.error('parameter autoClose must be a valid number > 0, to leave default, do not provide this parameter');
      }
    }
  }

  /**
   * @param message
   * @param type BootstrapAlertType
   * @param autoClose - IF autoClose is set to -1 AND the alert is dismissable (default) THEN the alert will stay on screen until closed
   * @param dismissable wether or not the alert can be manually removed.
   */
  public addAlert(message: string, type: BootstrapAlertType, autoClose?: number, dismissable?: boolean): void {
    this.addBootstrapAlert(new BootstrapAlert(null, type, message, null, dismissable), autoClose);
  }
  /**
   * @param autoClose - IF autoClose is set to -1 AND the alert is dismissable (default) THEN the alert will stay on screen until closed
   */
  public addBootstrapAlert(alert: BootstrapAlert, autoClose?: number): void {
    const existingMessage = this.alertHolder.find(a => a.message === alert.message);
    //We never want to show the exact same message twice
    if (existingMessage != null) {
      this.removeAlert(existingMessage);
    }
    if (this.alertHolder.length >= this.alertCount) {
      // remove the oldest alert
      this._removeAlertById(0, this.alertHolder, this.alerts);
    }
    if (typeof alert.dismissable === 'undefined' || alert.dismissable === null) {
      alert.dismissable = true;
    }
    this.alertHolder.push(alert);
    this.alerts.next(this.alertHolder);
    if (autoClose > -1) {
      this._scheduleAlertHide(autoClose, alert);
    } else if ( !(autoClose < 0 && alert.dismissable) && this.autoClose > -1) {
      this._scheduleAlertHide(this.autoClose, alert);
    }
  }

  public removeAlert(alert: BootstrapAlert): void {
    this._removeAlert(alert, this.alertHolder, this.alerts);
    this.onAlertRemovedManually.next(alert);
  }

  private _removeAlert(alert: BootstrapAlert, alertHolder: BootstrapAlert[], alerts: Subject<BootstrapAlert[]>): void {
    const index: number = alertHolder.indexOf(alert);
    this._removeAlertById(index, alertHolder, alerts);
  }

  private _scheduleAlertHide(timeout: number, alert: BootstrapAlert): void {
    const displayTimeout = timer(timeout);
    displayTimeout.subscribe(() => {
      this._removeAlert(alert, this.alertHolder, this.alerts);
    });
  }

  private _removeAlertById(id: number, alertHolder: BootstrapAlert[], alerts: Subject<BootstrapAlert[]>): void {
    alertHolder.splice(id, 1);
    alerts.next(alertHolder);
  }

}
