import {isNullOrUndefined} from '../../utils/object-utils';

export enum OAuthDebugLevel {
  TRACE= 0,
  DEBUG= 1,
  INFO = 2,
  WARN= 3,
  ERROR = 4
}

/**
 * Simple service to centralize any possible debug logging of concurrency issues
 */
export class OAuthDebug {

  //flip this to enable console logging of refresh and wait events
  private static readonly logLevel = OAuthDebugLevel.ERROR;

  public static log(level: OAuthDebugLevel, msg: string, params?: any): void {
    if (level >= this.logLevel) {
      if (isNullOrUndefined(params)) {
        console.log(`OAuth ${OAuthDebug.logLevelToString(level)}:`, msg);
      } else {
        console.log(`OAuth ${OAuthDebug.logLevelToString(level)}:`, msg, params);
      }
    }
  }

  private static logLevelToString(level: OAuthDebugLevel): string {
    switch (level) {
      case OAuthDebugLevel.TRACE:
        return 'TRACE'
      case OAuthDebugLevel.DEBUG:
        return 'DEBUG'
      case OAuthDebugLevel.INFO:
        return ' INFO'
      case OAuthDebugLevel.WARN:
        return ' WARN'
      case OAuthDebugLevel.ERROR:
        return 'ERROR'
      default:
        return '?????'
    }
  }
}
