import {Injectable} from '@angular/core';
import {BehaviorSubject, EMPTY, Observable, Subject, timer} from 'rxjs';
import {distinctUntilChanged, switchMap, takeUntil} from 'rxjs/operators';

/**
 * Service to provide access to visibility state of a browser tap.
 */
@Injectable({providedIn: 'root'})
export class AppVisibilityService {

  private appVisible = new BehaviorSubject<boolean>(!AppVisibilityService.appIsHidden());

  private static appIsHidden(): boolean {
    return document.visibilityState === 'hidden';
  }

  constructor() {
    const self = this;
    document.addEventListener('visibilitychange', () => {
      self.appVisible.next(!AppVisibilityService.appIsHidden())
    });
  }

  public get visible$(): Observable<boolean> {
    return this.appVisible.asObservable();
  }

  public get visible(): boolean {
    return this.appVisible.value;
  }

  /**
   * Get a timer observable that triggers at the provided millisBetween.
   * BUT only if the tab is considered visible according to the Page Visibility API.
   *
   * We are checking visibility like: Tab is concidered visible if !(document.visibilityState === 'hidden'),
   * This is done to ensure browsers not implementing the  visibility api,
   * will setup a timer as if it was visible all the time.
   *
   * @param initialDelay the initial delay in millis to apply, before interval starts to trigger
   * @param millisBetween the millis between each interval
   */
  public forsiVisibilityInterval(initialDelay: number, millisBetween: number): Observable<number> {
    return new Observable(subscriber => {
      const unsubscribe$ = new Subject<void>();
      try {
        const createdAt = new Date().getTime();
        let lastTickAt = null;
        this.visible$.pipe(
          distinctUntilChanged(),
          switchMap(isVisible => {
            if (isVisible) {
              // calculate when to trigger timer the first time.
              // I.e. the last tick was older than the provided millisBetween, we need to trigger the timer at once
              let timeUntilNextTick;
              if (lastTickAt) { // has triggered before
                timeUntilNextTick = Math.max(lastTickAt + millisBetween - new Date().getTime(), 0);
              } else { // first time (could have been stopped
                timeUntilNextTick = Math.max(createdAt + initialDelay - new Date().getTime(), 0);
              }
              return timer(timeUntilNextTick, millisBetween);
            } else {
              //when app is hidden switch to empty observable
              return EMPTY;
            }
          }),
          takeUntil(unsubscribe$)
        ).subscribe((number) => {
          lastTickAt = new Date().getTime();
          subscriber.next(number);
        });
      } catch (error) {
        unsubscribe$.next();
        unsubscribe$.complete();
        subscriber.error({msg: 'Visibility timer failed with error', error: error});
      }
      return () => {
        // return function with unsubscribe logic
        unsubscribe$.next();
        unsubscribe$.complete();
        subscriber.complete();
      }
    });
  }
}
