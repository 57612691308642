<nav id="sidebar" class="h-100" [ngClass]="{'sidebar-inactive':sidebarHidden, 'sidebar-active':!sidebarHidden}" [class.notification-active]="notificationService.onNotificationChangedSub | async">
    <div *ngIf="canToggleSidebar$ | async" class="modal-overlay" (click)="toggleSidebarMenu.emit()"></div>
    <div class="custom-menu">
        <div id="sidebarCollapse" [class.clickable]="canToggleSidebar$ | async" (click)="toggleSidebarMenu.emit()">
            <i *ngIf="canToggleSidebar$ | async" class="fa" [ngClass]="sidebarHidden ? 'fa-bars fa-2x' : 'fa-close'"></i>
            <i *ngIf="(canToggleSidebar$ | async) === false"></i>
        </div>
    </div>
    <div class="p-2 ps-4 pe-4">
        <div class="mt-2 m">
            <img src="/content/images/Autotaks_logo.svg" style="width: 7rem;">
        </div>
        <ul class="list-unstyled components mb-0 mt-4" >
            <li *lcHasAnyAuthority="['ROLE_Taks', 'ROLE_VK']" class="first-level" [class.active]="activeRootUrl(['create', 'draft', 'police', 'search', 'report', 'offer', 'message', 'ns-positions'])">
                <a (click)="toggleMenu('menu-damage', 'draft')"><span class="fa fa-sign-in me-3"></span>Skadesregistrering</a>
                <ul #menuDamage class="list-unstyled sub-menu" [ngClass]="{'collapsed': isCollapsed('menu-damage')}" [ngStyle]="getSubMenuStyle(menuDamage)">
                    <li class="d-flex justify-content-between align-items-center" [class.active]="activeUrl('create')">
                        <a (click)="toggleSidebarMenu.emit()" [routerLink]="'create'"><span class="fa fa-plus me-3"></span>Opret ny sag</a>
                    </li>
                    <li *lcHasAnyAuthority="['ROLE_VK']" class="d-flex justify-content-between align-items-center">
                        <a (click)="openRequisitionLookupPopup()"><span class="fa fa-search me-3"></span>Rekvisition</a>
                    </li>
                    <li class="d-flex justify-content-between align-items-center" [class.active]="activeUrl('police')">
                        <a (click)="toggleSidebarMenu.emit()" [routerLink]="'police'"><span class="fa fa-id-card-o me-3"></span>Policeopslag</a>
                    </li>
                    <li *lcHasAnyAuthority="'ROLE_Taks'" class="d-flex justify-content-between align-items-center" [class.active]="activeUrl('search')">
                        <a (click)="toggleSidebarMenu.emit()" [routerLink]="'search'"><span class="fa fa-search me-3"></span>Søg</a>
                    </li>
                    <li class="d-flex justify-content-between align-items-center" [class.active]="activeUrl('draft')">
                        <a (click)="toggleSidebarMenu.emit()" [routerLink]="'draft'"><span class="fa fa-warning me-3"></span>Igangværende</a>
                        <span *ngIf="incommingClientStateCount$ | async as incommingCount" class="badge bg-primary badge-pill">{{incommingCount | badgeLabel: 99}}</span>
                    </li>
                    <li class="d-flex justify-content-between align-items-center" [class.active]="activeUrl('report')">
                        <a (click)="toggleSidebarMenu.emit()" [routerLink]="'report'"><span class="fa fa-car me-3"></span>Rapporter</a>
                    </li>
                    <li *lcHasAnyAuthority="['ROLE_Taks']" class="d-flex justify-content-between align-items-center" [class.active]="activeUrl('offer')">
                        <a (click)="toggleSidebarMenu.emit()" [routerLink]="'offer'"><span class="fa fa-inbox me-3"></span>Indbakke</a>
                        <span *ngIf="offerCount$ | async as offerCount" class="badge bg-primary badge-pill">{{offerCount | badgeLabel: 99}}</span>
                    </li>
                    <li class="d-flex justify-content-between align-items-center" [class.active]="activeUrl('message')">
                        <a (click)="toggleSidebarMenu.emit()" [routerLink]="'message'"><span class="fa fa-envelope-o me-3"></span>Beskeder</a>
                        <span *ngIf="messageCount$ | async as messageCount" class="badge bg-primary badge-pill">{{messageCount | badgeLabel: 99}}</span>
                    </li>
                    <li *lcHasAnyAuthority="['ROLE_VK', 'ROLE_Taks']" class="d-flex justify-content-between align-items-center" [class.active]="activeUrl('ns-positions')">
                        <a (click)="toggleSidebarMenu.emit()" [routerLink]="'ns-positions'">
                            <span class="text-quincy fw-bold" style="margin-left: 3px; margin-right: 0.9rem;">NS</span>1000-numre
                        </a>
                    </li>
                </ul>
            </li>
            <li *ngIf="showBusinessToBusiness$ | async" class="first-level" [class.active]="activeRootUrl(['b2b'])">
                <a (click)="toggleMenu('menu-b2b', 'b2b')"><span class="fa fa-chain-broken me-3"></span>Business to Business</a>
                <ul #menuB2B class="list-unstyled sub-menu" [ngClass]="{'collapsed': isCollapsed('menu-b2b')}" [ngStyle]="getSubMenuStyle(menuB2B)">
                    <li class="d-flex justify-content-between align-items-center" [class.active]="activeUrl('b2b/paint/report')">
                        <a (click)="toggleSidebarMenu.emit()" [routerLink]="'b2b/paint/report'"><span class="fa fa-chain-broken me-3"></span>Lak rapporter</a>
                    </li>
                    <li *lcHasAnyAuthority="'ROLE_VK'" class="d-flex justify-content-between align-items-center" [class.active]="activeUrl('b2b/paint/advise')">
                        <a (click)="toggleSidebarMenu.emit()" [routerLink]="'b2b/paint/advise'"><span class="fa fa-chain-broken me-3"></span>Lak rådgivning</a>
                    </li>
                </ul>
            </li>
            <li *ngIf="hasGeneralAdministrationAccess()" class="first-level" [class.active]="activeRootUrl(['admin', 'admin-workshop'])">
                <a (click)="toggleMenu('menu-admin', 'admin')"><span class="fa fa-gear me-3"></span>Administration</a>
                <ul #menuAdmin class="list-unstyled sub-menu" [ngClass]="{'collapsed': isCollapsed('menu-admin')}" [ngStyle]="getSubMenuStyle(menuAdmin)">
                    <li *lcHasAnyAuthority="['ROLE_Taks', 'ROLE_SagsB']" class="d-flex justify-content-between align-items-center" [class.active]="activeUrl('admin/report')">
                        <a (click)="toggleSidebarMenu.emit()" [routerLink]="'admin/report'"><span class="fa fa-car me-3"></span>Rapportopslag</a>
                    </li>
                    <li *lcHasAnyAuthority="['ROLE_TaksManager', 'ROLE_RedirectAdmin']" class="d-flex justify-content-between align-items-center" [class.active]="activeUrl('admin/assessororg')">
                        <a (click)="this.toggleSidebarMenu.emit();" [routerLink]="'admin/assessororg'"><span class="fa fa-sitemap me-3"></span>Min organisation</a>
                    </li>
                    <li *ngIf="hasMyCompanyAccess()" class="d-flex justify-content-between align-items-center" [class.active]="activeUrl('admin/company')">
                        <a (click)="this.toggleSidebarMenu.emit();" [routerLink]="'admin/company'"><span class="fa fa-building me-3"></span>Mit selskab</a>
                    </li>
                    <li *lcHasAnyAuthority="['ROLE_Taks', 'ROLE_AftaleAdmin', 'ROLE_SagsB']" class="d-flex justify-content-between align-items-center" [class.active]="activeUrl('admin/workshops')">
                        <a (click)="this.toggleSidebarMenu.emit();" [routerLink]="'admin/workshops'"><span class="fa fa-wrench me-3"></span>Værksteder</a>
                    </li>
                    <li *ngIf="principalService.hasAnyAuthorityDirect(['ROLE_Taks', 'ROLE_AftaleAdmin'])" class="d-flex justify-content-between align-items-center" [class.active]="activeUrl('admin/workshops')">
                        <a (click)="this.toggleSidebarMenu.emit();" [routerLink]="'admin/agreement-management'"><span class="fa fa-handshake-o me-3"></span>Aftalehåndtering</a>
                    </li>
                    <li *ngIf="canAdministrateUsers()" class="d-flex justify-content-between align-items-center" [class.active]="activeUrl('admin/user')">
                        <a (click)="this.toggleSidebarMenu.emit();" [routerLink]="'admin/user'"><span class="fa fa-user me-3"></span>Brugere</a>
                    </li>
                    <li *ngIf="principalService.hasAnyAuthorityDirect(['ROLE_WorkshopAdmin'])" class="d-flex justify-content-between align-items-center" [class.active]="activeUrl('admin-workshop/user')">
                        <a (click)="this.toggleSidebarMenu.emit();" routerLink="admin-workshop/user"><span class="fa fa-user me-3"></span>Brugere</a>
                    </li>
                    <li *lcHasAnyAuthority="['ROLE_TaksManager']" class="d-flex justify-content-between align-items-center" [class.active]="activeUrl('admin/broadcast')">
                        <a (click)="this.toggleSidebarMenu.emit();" [routerLink]="'admin/broadcast'"><span class="fa fa-bullhorn me-3"></span>Broadcast</a>
                    </li>
                    <li *ngIf="canAdministrateAutomation()" class="d-flex justify-content-between align-items-center" [class.active]="activeUrl('admin/automation')">
                        <a (click)="this.toggleSidebarMenu.emit();" [routerLink]="'admin/automation'"><span class="fa fa-cogs me-3"></span>Automatisering</a>
                    </li>
                    <li *lcHasAnyAuthority="['ROLE_TaksManager', 'ROLE_UserAdminSagsB']" class="d-flex justify-content-between align-items-center" [class.active]="activeUrl('admin/token/')">
                        <a (click)="this.toggleSidebarMenu.emit(); gotoAdminUserTokens()"><span class="fa fa-key me-3"></span>Eksterne adgange</a>
                    </li>
                    <li *lcHasAnyAuthority="['ROLE_WorkshopAdmin']" class="d-flex justify-content-between align-items-center" [class.active]="activeUrl('admin-workshop/agreements')">
                        <a (click)="toggleSidebarMenu.emit()" routerLink="admin-workshop/agreements"><span class="fa fa-handshake-o me-3"></span>Aftaler</a>
                    </li>
                    <li *ngIf="canAdministrateB2BPartners()" class="d-flex justify-content-between align-items-center" [class.active]="activeUrl('admin-workshop/businesspartners')">
                        <a (click)="toggleSidebarMenu.emit()" routerLink="admin-workshop/businesspartners"><span class="fa fa-address-book-o me-3"></span>Værksteds samarbejde</a>
                    </li>
                    <li *lcHasAnyAuthority="['ROLE_WorkshopAdmin', 'ROLE_Integration']" class="d-flex justify-content-between align-items-center" [class.active]="activeUrl('admin-workshop/tokens')">
                        <a (click)="toggleSidebarMenu.emit()" routerLink="admin-workshop/tokens"><span class="fa fa-key me-3"></span>Ekstern adgang</a>
                    </li>
                </ul>
            </li>
            <li *lcHasAnyAuthority="'ROLE_sysadmin'" class="first-level" [class.active]="activeRootUrl('sysadmin')">
                <a (click)="toggleMenu('menu-sysadmin', 'sysadmin')"><span class="fa fa-gears me-3"></span>Systemadministration</a>
                <ul #menuSysadmin class="list-unstyled sub-menu" [ngClass]="{'collapsed': isCollapsed('menu-sysadmin')}" [ngStyle]="getSubMenuStyle(menuSysadmin)">
                    <li class="d-flex justify-content-between align-items-center" [class.active]="activeUrl('sysadmin/notification')">
                        <a (click)="toggleSidebarMenu.emit()" [routerLink]="'sysadmin/notification'"><span class="fa fa-bullhorn me-3"></span>Advisering</a>
                    </li>
                    <li class="d-flex justify-content-between align-items-center" [class.active]="activeUrl('sysadmin/drift')">
                        <a (click)="toggleSidebarMenu.emit()" [routerLink]="'sysadmin/drift'"><span class="fa fa-wrench me-3"></span>Drift</a>
                    </li>
                    <li class="d-flex justify-content-between align-items-center" [class.active]="activeUrl('sysadmin/stamdata')">
                        <a (click)="toggleSidebarMenu.emit()" [routerLink]="'sysadmin/stamdata'"><span class="fa fa-clipboard me-3"></span>Stamdata</a>
                    </li>
                    <li class="d-flex justify-content-between align-items-center" [class.active]="activeUrl('sysadmin/insight')">
                        <a (click)="toggleSidebarMenu.emit()" [routerLink]="'sysadmin/insight'"><span class="fa fa-bar-chart me-3"></span>Indsigter</a>
                    </li>
                </ul>
            </li>
            <li *lcHasAnyAuthority="['ROLE_PowerBIStatisticUser', 'ROLE_WorkshopAdmin', 'ROLE_sysadmin']" class="first-level d-flex justify-content-between align-items-center"
                [class.active]="activeRootUrl('statistic')">
                <a [routerLink]="'statistic'" (click)="toggleMenu('menu-stats'); this.toggleSidebarMenu.emit()"><span class="fa fa-line-chart me-3"></span>Statistik</a>
            </li>
            <li *ngIf="principalService.hasAllAuthorityDirect(['ROLE_Taks', 'ROLE_RoadmapViewer'])" class="first-level d-flex justify-content-between align-items-center"
                [class.active]="activeRootUrl('roadmap')">
                <a [routerLink]="'roadmap'" (click)="toggleMenu('menu-roadmap'); this.toggleSidebarMenu.emit()"><span class="fa fa-road me-3"></span>Roadmap</a>
            </li>
        </ul>

        <div class="footer">
            <div class="mb-0">
                <a [routerLink]="'about'" ngbTooltip="Om Autotaks" placement="top-left"><span class="fa fa-lg fa-info-circle me-3 ms-3 pt-2 pb-2"></span></a>
                <a [routerLink]="'news'"  ngbTooltip="Nyheder" placement="top"><span class="fa fa-lg fa-newspaper-o me-3 ms-3 pt-2 pb-2"></span></a>
                <a *ngIf="showReportError()" (click)="errorReportClicked()" ngbTooltip="Fejlindberetning" placement="top"><span class="fa fa-lg fa-exclamation-triangle me-3 ms-3 pt-2 pb-2"></span></a>
                <a (click)="didClickLogout()" ngbTooltip="Log ud af forsi.dk" placement="top-right"><span class="fa fa-lg fa-sign-out me-3 ms-3 pt-2 pb-2"></span></a>
            </div>
            <div>{{userIdentity$ | async}}</div>
        </div>
    </div>
</nav>
