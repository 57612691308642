import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {DataTablesModule} from 'angular-datatables';

@NgModule({
  imports: [
  ],
  exports: [
    FormsModule,
    CommonModule,
    NgbModule,
    DataTablesModule
  ]
})
export class SharedLibsModule {
}
