import {LcActionMenu, LcActionMenuItem} from '../../shared/ui/bottombar/lc-action-menu.model';
import {ReportService} from '../service/report.service';
import {ReportDiffResult} from '../dto/report-diff-result-dto.model';

export class ReportDiffViewModel {
  loading = true;
  valid = true;
  bottomMenu: LcActionMenu;
  navigateBackCallback: Function;
  changeActiveCallback: Function;

  report: ReportDiffResult;

  constructor(private reportService: ReportService,
              public vkWidePk: string,
              public taksatorWidePk: string) {
    this.setupBottomActionMenu();
    this.setupObservables();
  }

  private setupBottomActionMenu(): void {
    this.bottomMenu = new LcActionMenu();
    //this.bottomMenu.title = "Hello menu";
    this.bottomMenu.addItem(new LcActionMenuItem('fa-arrow-left', () => {
      this.navigateBackCallback();
    }, 'Tilbage'));
    this.bottomMenu.addItem(new LcActionMenuItem('fa-arrows-h', () => {
      this.changeActiveCallback();
    }, 'Skift'));
  }

  private setupObservables(): void {
    this.reportService.diff(this.vkWidePk, this.taksatorWidePk).subscribe(data => {
      this.report = data;
      this.valid = true;
      this.loading = false;
    }, error => {
      this.valid = false;
      this.loading = false;
    })
  }
}
