import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ExternalValidationResultDTO} from '../dto/external-validation-result-dto.model';
import {PreparePdfResultDTO} from '../dto/prepare-pdf-result-dto.model';

@Injectable({
  providedIn: 'root',
})
export class ExternalValidationService {

  private externalValidationUrl = 'externalvalidation';

  constructor(private http: HttpClient) {
  }

  isConfigured(clientStateToken: string): Observable<boolean> {
    return this.http.get<boolean>(this.externalValidationUrl + '/' + clientStateToken + '/configured');
  }

  doExternalValidation(clientStateToken: string): Observable<void> {
    return this.http.post<void>(this.externalValidationUrl + '/' + clientStateToken + '/perform', null);
  }

  getLatestResult(clientStateToken: string): Observable<ExternalValidationResultDTO> {
    return this.http.get<ExternalValidationResultDTO>(this.externalValidationUrl + '/' + clientStateToken + '/latestresult');
  }

  preparePDF(reportKey: string): Observable<PreparePdfResultDTO> {
    return this.http.post<PreparePdfResultDTO>(this.externalValidationUrl + '/' + reportKey + '/pdf', null);
  }
}
