import {FabrikatDTO} from '../../shared/makes/dto/fabrikat-dto.model';
import {ModelDTO} from '../../shared/makes/dto/model-dto.model';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {VinDetails} from '../../shared/model/vin-details.model';
import {BehaviorSubject} from 'rxjs';
import {CarDetails} from './flowtwo-car-details.model';

export class QapterVehicle {
  private _fabrikat: FabrikatDTO;
  fabrikatSubject: BehaviorSubject<FabrikatDTO> = new BehaviorSubject<FabrikatDTO>(null);
  fantomFabrikat: FabrikatDTO;
  maerke: string;
  stelnr: string;
  aargang: number;
  foersteRegDato: NgbDateStruct;
  regNrUdloebDato: NgbDateStruct;
  kmstand: number;
  handelsvaerdi: number;
  regnr: string;
  nummerpladeType = ' ';
  tilstandsType = 'I';
  model: ModelDTO;
  submodel: string;
  modelOptions: string;
  fantomModel: ModelDTO;
  vinDetails: VinDetails;
  angivetFabrikat: string;
  angivetModel: string;
  hybridType: string;
  propellantType: string;

  public getCarDetails(): CarDetails {
    const result = new CarDetails();
    result.fabrikatKode = this._fabrikat.kode;
    result.fabrikatText = this._fabrikat.simpleName;
    result.modelKode = this.model.kode;
    result.modelText = this.model.simpleName;
    return result;
  }

  public resetVinInfo(resetFabrikat: boolean): void {
    this.vinDetails = null;
    this.stelnr = null;
    this.regnr = null;
    this.foersteRegDato = null;
    this.regNrUdloebDato = null;
    if (resetFabrikat) {
      this._fabrikat = null;
    }
    this.angivetModel = null;
    this.model = null;
    this.angivetModel = null;
    this.aargang = null;
  }

  public setVinLookupInfo(vinLookupResult: VinDetails): void {
    this.vinDetails = new VinDetails();
    this.vinDetails.vinCallId = vinLookupResult.vinCallId;
    this.vinDetails.vinResult = vinLookupResult.vinResult;
    this.vinDetails.vinResultCode = vinLookupResult.vinResultCode;
    this.vinDetails.vinColorCode = vinLookupResult.vinColorCode;
    this.vinDetails.vinFabrikat = vinLookupResult.vinFabrikat;
    this.vinDetails.vinStelNr = vinLookupResult.vinStelNr;
    this.stelnr = vinLookupResult.vinStelNr;
  }

  public isGeneralModel(): boolean {
    return this.model && !this.model.audatex;
  }

  public isModelQapterWildcard(): boolean {
    return this.model && this.model.kode === '**'
  }

  public isGeneralFabrikat(): boolean {
    return (this._fabrikat !== undefined && this._fabrikat !== null && FabrikatDTO.isOther(this._fabrikat));
  }

  get fabrikat(): FabrikatDTO {
    return this._fabrikat;
  }

  set fabrikat(value: FabrikatDTO) {
    this._fabrikat = value;
    this.fabrikatSubject.next(value);
  }

}
