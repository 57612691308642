import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CompanyDTO} from 'app/shared/dto/company-dto.model'
import {Observable, throwError} from 'rxjs';
import {PoliceRequest} from '../../police/police.component';
import {map} from 'rxjs/operators';
import {StringUtils} from '../utils/string-utils';
import URLBuilder from '../utils/url-builder';
import {ClientStateDetails} from '../../draft';
import {PolicyDTO} from '../dto/policy-dto.model';
import {isNullOrUndefined} from '../utils/object-utils';

@Injectable({
  providedIn: 'root'
})
export class PoliceService {
  constructor(private http: HttpClient) {
  }

  public getSelskaber(): Observable<CompanyDTO[]> {
    return this.http.get<CompanyDTO[]>('police/selskaber').pipe(
      map(response => response.map(selskabJson => Object.assign(new CompanyDTO(), selskabJson))));
  }

  public standaloneSearch(policeRequest: PoliceRequest): Observable<PolicyDTO> {
    if (isNullOrUndefined(policeRequest.selskabsKode)) {
      return throwError(() => 'Der skal vælges selskab før der kan laves et policeopslag');
    } else {
      const params = policeRequest.toHttpParams();
      return this.http.get<PolicyDTO>('police/search', {params});
    }
  }

  public print(policeRequest: PoliceRequest, vkNr?: string): void {
    let httpParams = policeRequest.toHttpParams();
    if (!isNullOrUndefined(vkNr)) {
      httpParams = httpParams.append('vknr', vkNr)
    }
    const url = new URLBuilder().getRestServicePath() + 'police/searchpdf?' + httpParams.toString();
    window.open(url, '_blank');
  }

  /**
   * VPD:
   * Værksteder/taksatorer må altid redigere forsikringstagers oplysninger uanset om de er indtastet eller om de kommer fra policeopslaget.
   * Reglen for policeopslaget er: hvis forsikringstagerfelter ikke er udfyldt, kopieres policedatafelters som defaultværdi.
   */
  public populatePolicyholderByPoliceIfExists(details: ClientStateDetails): void {
    if (details.hasPoliceResult()) {
      details.forsikringstager.skadenr = StringUtils.isNotEmpty(details.forsikringstager.skadenr) ?
        details.forsikringstager.skadenr : details.police.skadenr;
      details.forsikringstager.kundenr = StringUtils.isNotEmpty(details.forsikringstager.kundenr) ?
        details.forsikringstager.kundenr : details.police.kundenr;
      details.forsikringstager.policenr = StringUtils.isNotEmpty(details.forsikringstager.policenr) ?
        details.forsikringstager.policenr : details.police.policenr;
      // While PoliceResponse extends PoliceInfo which extends PersonInfo which has "telefonNr"
      // the "telefonNr" is not present in the response from the backend. e.g. set empty string.
      details.forsikringstager.telefonNr = StringUtils.isNotEmpty(details.forsikringstager.telefonNr) ?
        details.forsikringstager.telefonNr : '';
      details.forsikringstager.postNr = StringUtils.isNotEmpty(details.forsikringstager.postNr) ?
        details.forsikringstager.postNr : details.police.postNr;
      details.forsikringstager.addressLine1 = StringUtils.isNotEmpty(details.forsikringstager.addressLine1) ?
        details.forsikringstager.addressLine1 : details.police.addressLine1;
      details.forsikringstager.addressLine2 = StringUtils.isNotEmpty(details.forsikringstager.addressLine2) ?
        details.forsikringstager.addressLine2 : details.police.addressLine2;
      details.forsikringstager.regNr = StringUtils.isNotEmpty(details.forsikringstager.regNr) ?
        details.forsikringstager.regNr : details.police.regNr;
      details.forsikringstager.navn = StringUtils.isNotEmpty(details.forsikringstager.navn) ?
        details.forsikringstager.navn : details.police.navn;
      // will be updated based on "postNr"
      details.forsikringstager.by = '';
    }
  }

  public populateLeasingCompanyByPoliceIfExists(details: ClientStateDetails): void {
    if (details.hasPoliceResult()) {
      details.leasingselskab.navn = StringUtils.isNotEmpty(details.leasingselskab.navn) ?
        details.leasingselskab.navn : details.police.leasingNavn;
      details.leasingselskab.addressLine1 = StringUtils.isNotEmpty(details.leasingselskab.addressLine1) ?
        details.leasingselskab.addressLine1 : details.police.leasingAdrLinie1;
      details.leasingselskab.addressLine2 = StringUtils.isNotEmpty(details.leasingselskab.addressLine2) ?
        details.leasingselskab.addressLine2 : details.police.leasingAdrLinie2;
      details.leasingselskab.postNr = StringUtils.isNotEmpty(details.leasingselskab.postNr) ?
        details.leasingselskab.postNr : details.police.leasingPostnr;
      //will be updated by postnr
      details.leasingselskab.by = '';
    }
  }
}
