<div class="text-center" *ngIf="!clientStateDetails">
    <i class="fa fa-circle-o-notch fa-spin fop-page-spinner"></i>
</div>

<div *ngIf="clientStateDetails">
    <lc-vk-autotaks *ngIf="isVKAutotaks()"></lc-vk-autotaks>
    <lc-vk-glastaks *ngIf="isVKGlastaks()"></lc-vk-glastaks>
    <lc-vk-stortaks *ngIf="isVKStortaks()"></lc-vk-stortaks>
    <lc-vk-camptaks *ngIf="isVkCampTaks()"></lc-vk-camptaks>
    <lc-vk-mctaks *ngIf="isVKMctaks()"></lc-vk-mctaks>
    <lc-taks-autotaks *ngIf="isTaksFlow()"></lc-taks-autotaks>
</div>
<div class="row dynamic-padding-bottom">
    <!-- Room for floating action buttons -->
    <div class="col-md-12"></div>
</div>
