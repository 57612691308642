import {Component, Input, OnInit} from '@angular/core';
import {ObjectPropertyEntry} from './object-property-entry.model';

@Component({
  selector: 'lc-display-object-properties',
  templateUrl: './display-object-properties.component.html'
})
export class DisplayObjectPropertiesComponent implements OnInit {
  @Input() object: any;
  entries: ObjectPropertyEntry[] = [];

  constructor() {
  }

  ngOnInit(): void {
    Object.keys(this.object)
      .forEach(key => {
        let name = key.charAt(0).toUpperCase() + key.slice(1); // uppercase first char
        name = name.replace(/([A-Z])/g, ' $1').trim(); // insert space before uppercase chars
        this.entries.push(new ObjectPropertyEntry(name, this.object[key]))
      });
  }
}
