<div class="text-center" *ngIf="isLoading">
    <i class="fa fa-circle-o-notch fa-spin fop-page-spinner"></i>
</div>
<div *ngIf="!isLoading" class="no-focus" tabindex="0" [lcAutofocus]>
    <div class="card">
        <div class="card-body">
            <h4 class="card-title">Rapport layout</h4>
            <div class="row pb-1 ms-1">
                <div class="col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 col-form-label">
                    <label class="text-start" for="topMargin">Top margin</label>
                </div>
                <div class="col-3">
                    <input type="number" class=" form-control" id="topMargin" [(ngModel)]="printLayout.udskriftConfigTopmargin">
                </div>
            </div>
            <div class="row pb-1 ms-1">
                <div class="col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 col-form-label">
                    <label class="text-start" for="leftMargin">Venstre margin</label>
                </div>
                <div class="col-3">
                    <input type="number" class="form-control" id="leftMargin" [(ngModel)]="printLayout.udskriftConfigVenstremargin"/>
                </div>
            </div>
        </div>
    </div>

    <div *lcHasAnyAuthority="'ROLE_Taks'" class="card">
        <div class="card-body">
            <h4 class="card-title">Følgeseddel layout</h4>
            <div class="row pb-1 ms-1">
                <div class="col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 col-form-label">
                    <label class="text-start" for="topMargin">Top margin</label>
                </div>
                <div class="col-3">
                    <input type="number" class="form-control" name="form18topMargin"
                           [(ngModel)]="printLayout.form18ConfigTopmargin">
                </div>
            </div>
            <div class="row pb-1 ms-1">
                <div class="col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 col-form-label">
                    <label class="text-start" for="leftMargin">Venstre margin</label>
                </div>
                <div class="col-3">
                    <input type="number" class="form-control" name="form18leftMargin"
                           [(ngModel)]="printLayout.form18ConfigVenstremargin"/>
                </div>
            </div>
        </div>
    </div>

    <div class="card">
        <div class="card-body">
            <h4 class="card-title">Inkluderede sektioner</h4>
            <div class="row ms-3">
                <div class="col">
                    <div class="form-check">
                        <label class="form-check-label">
                            <input class="form-check-input" type="checkbox" name="sektionH3"
                                   [(ngModel)]="printLayout.sektionH3"/>
                            Kontrolblad
                        </label>
                    </div>
                </div>
            </div>
            <div class="row ms-3">
                <div class="col">
                    <div class="form-check">
                        <label class="form-check-label">
                            <input class="form-check-input" type="checkbox" name="sektionH1"
                                   [(ngModel)]="printLayout.sektionH1">
                            Forside
                        </label>
                    </div>
                </div>
            </div>
            <div class="row ms-3">
                <div class="col">
                    <div class="form-check">
                        <label class="form-check-label">
                            <input class="form-check-input" type="checkbox" name="sektionH2"
                                   [(ngModel)]="printLayout.sektionH2">
                            Arbejdsbeskrivelse
                        </label>
                    </div>
                </div>
            </div>
            <div class="row ms-3">
                <div class="col">
                    <div class="form-check">
                        <label class="form-check-label">
                            <input class="form-check-input" type="checkbox" name="sektionH"
                                   [(ngModel)]="printLayout.sektionH">
                            Reservedele
                        </label>
                    </div>
                </div>
            </div>
            <div class="row ms-3">
                <div class="col">
                    <div class="form-check">
                        <label class="form-check-label">
                            <input class="form-check-input" type="checkbox" name="sektionH5"
                                   [(ngModel)]="printLayout.sektionH5">
                            Lak
                        </label>
                    </div>
                </div>
            </div>
            <div class="row ms-3">
                <div class="col">
                    <div class="form-check">
                        <label class="form-check-label">
                            <input class="form-check-input" type="checkbox" name="sektionH4"
                                   [(ngModel)]="printLayout.sektionH4">
                            Infoblad
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *lcHasAnyAuthority="'ROLE_Taks'" class="card">
        <div class="card-body">
            <h4 class="card-title">Øvrige</h4>
            <div class="row ms-3">
                <div class="col">
                    <div class="form-check">
                        <label class="form-check-label">
                            <input class="form-check-input" type="checkbox" name="taksatorInfoPaaLakblad"
                                   [(ngModel)]="printLayout.taksatorInfoPaaLakblad">
                            Taksators kontaktinformationer på på lakbladet
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="pt-2 text-end">
        <button class="btn btn-secondary me-2" (click)="reset()">Reset</button>
        <button class="btn btn-primary " (click)="savePrintLayout()">Gem</button>
    </div>
</div>
