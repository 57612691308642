import {ExtraText} from '../../../../service/skavu-position-service';
import {SkavuPositionValue} from '../../../../model/skavu-position-value.model';

export class ExtraTextUI extends ExtraText {
  _selected: boolean;
  value: SkavuPositionValue;

  get selected(): boolean {
    return this._selected;
  }

  set selected(active: boolean) {
    if (this._selected === active) {
      return;
    }

    if (active) {
      this.value.extraTexts.push(this.code);
    } else {
      this.value.extraTexts = this.value.extraTexts.filter(e => e !== this.code);
    }
    if (this.code === 'L') {
      this.value.lak = active;
    }
    this._selected = active;
  }

  constructor(extraText: ExtraText, value: SkavuPositionValue) {
    super();
    this.value = value;
    this.code = extraText.code;
    this.text = extraText.text;
    if (this.code === 'L') {
      this.text = 'Lak';
    }
    this._selected = value.extraTexts.filter(e => e === this.code).length > 0;
  }
}
