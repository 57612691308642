import {Injectable} from '@angular/core';
import {RequisitionDTO} from '../dto/requisition-dto.model';
import {ClientStateDTO} from 'app/draft';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RequisitionService {
  private serviceUrl = 'requisition/';

  constructor(private http: HttpClient) {
  }

  public getRequisition(selskab: string, requisition: string): Observable<RequisitionDTO> {
    return this.http.get<RequisitionDTO>(this.serviceUrl + selskab + '/' + requisition);
  }

  public createDraftFromRequisition(selskab: string, requisition: string): Observable<ClientStateDTO> {
    return this.http.post<ClientStateDTO>(this.serviceUrl + selskab + '/' + requisition, null);
  }
}
