import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {PrincipalService} from 'app/shared/service/auth/principal.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {StorageService} from '../storage.service';

@Injectable({
  providedIn: 'root',
})
export class UserRouteAccessService implements CanActivate {
  constructor(private router: Router,
              private principalService: PrincipalService,
              private storageService: StorageService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    const authorities = route.data['authorities'];
    if (!authorities || authorities.length === 0) {
      return true;
    }

    return this.checkLogin(authorities, state.url);
  }

  checkLogin(authorities: string[], url: string): Observable<boolean> {
    const principal = this.principalService;
    return principal.identity().pipe(map((p) => {
      if (!principal.isIdentityResolved()) {
        // no identity - go to login
        this.storageService.storeUrl(url);
        this.router.navigate(['']);
        return false;
      } else {
        if (principal.hasAnyAuthorityDirect(authorities)) {
          // user has access
          return true;
        } else {
          console.log('access denied for: ', p.authorities, 'requiring: ', authorities);
          // access is denied
          this.router.navigate(['accessdenied']);
          return false;
        }
      }
    }));
  }
}
