import {AfterViewInit, Component, OnDestroy, TemplateRef, ViewChild} from '@angular/core';
import {PrincipalService} from '../../shared';
import {StringUtils} from '../../shared/utils/string-utils';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {CookieService} from 'ngx-cookie-service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lc-cookie',
  templateUrl: 'cookie.component.html',
  styleUrls: ['cookie.scss']
})
export class CookieComponent implements OnDestroy, AfterViewInit {
  @ViewChild('cookiePolicyPopup') 
  public cookiePolicyPopup: TemplateRef<any>;
  private unsubscribe$ = new Subject<void>();

  constructor(principalService: PrincipalService,
              private cookieService: CookieService,
              private modalService: NgbModal) {

    principalService.getAuthenticationState().pipe(takeUntil(this.unsubscribe$)).subscribe(state => {
      if (state && !StringUtils.isEmpty(state.userName)) {
        this.acceptCookies();
      }
    })
  }

  ngAfterViewInit(): void {
    if (!this.cookieService.check('cookieAccepted')) {
      this.modalService.open(this.cookiePolicyPopup, {
        size: '',
        backdrop: 'static',
        keyboard: false
      })
    }
  }
  
  acceptCookies(): void {
    if (!this.cookieService.check('cookieAccepted')) {
      this.cookieService.set('cookieAccepted', 'true', 90);
    }
    this.modalService.dismissAll();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
