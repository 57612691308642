import { Injectable } from '@angular/core';
/**
 * A transient timeboxed repository
 */
@Injectable()
export abstract class TransientRepository<T> {
  private static readonly DEFAULT_TTL = 30 * 1000; //30 seconds
  private transientList: Array<TransientTimeBoxedEntry<T, Date>> = [];

  constructor() {
  }

  public add(entry: T): void {
    this.transientList.push(new TransientTimeBoxedEntry(entry, new Date(), TransientRepository.DEFAULT_TTL));
  }

  public livingObjects(): Array<T> {
    this.pruneDeadEntries();
    return this.transientList.map(transientValue => transientValue.object);
  }

  private pruneDeadEntries(): void {
    const now = new Date();
    this.transientList = this.transientList.filter(value => value.isAlive(now));
  }
}

class TransientTimeBoxedEntry<T, S> {
  private _object: T;
  private _createdAt: Date;
  private _timeToLiveMillis: number;

  constructor(object: T, createdAt: Date, timeToLiveMillis: number) {
    this._object = object;
    this._createdAt = createdAt;
    this._timeToLiveMillis = timeToLiveMillis;
  }

  get object(): T {
    return this._object;
  }

  set object(value: T) {
    this._object = value;
  }

  get createdAt(): Date {
    return this._createdAt;
  }

  set createdAt(value: Date) {
    this._createdAt = value;
  }

  get timeToLiveMillis(): number {
    return this._timeToLiveMillis;
  }

  set timeToLiveMillis(value: number) {
    this._timeToLiveMillis = value;
  }

  public isAlive(date: Date): boolean {
    return (this.createdAt.getTime() + this.timeToLiveMillis) > date.getTime();
  }
}
