import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {NewsComponent} from './news.component';
import {NEWS_ROUTES} from './news.route';

@NgModule({
  imports: [
    RouterModule.forRoot(NEWS_ROUTES, {useHash: true})
  ],
  declarations: [
    NewsComponent,
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class NewsModule {
}
