<div class="card" [ngClass]="metadataSummary.validateSummary(this.attachmentSummary.workshopImages)" style="height: 100%">
    <div class="card-header p-2 metadata-summary-header" >
        <div class="metadata-summary-header-text text-truncate">Analyse af værkstedets billeder</div>
        <div class="metadata-summary-header-info clickable" (click)="showMetadataSummaryInfo(summaryInfoPupup)" title="Information" >
            <i class="fa fa-question-circle-o fa-2x "></i>
        </div>
    </div>
    <div class="card-body p-1" [ngClass]="metadataSummary.validateSummary(this.attachmentSummary.workshopImages)" style="height: 100%">
        <div *ngIf="metadataSummary.getMetadataMapSize() > 0">
            <div class="info-box">
                            <span class="info-box-icon ">
                                <i class="fa fa-camera"></i>
                            </span>
                <div class="info-box-content">
                    <div class="info-box-header">Kameraer</div>
                    <div class="info-box-body">{{metadataSummary.getCamerasAsString()}}</div>
                </div>
            </div>
            <div class="info-box">
                            <span class="info-box-icon ">
                                <i class="fa fa-calendar"></i>
                            </span>
                <div class="info-box-content">
                    <div class="info-box-header">Datointerval</div>
                    <div class="info-box-body">
                        <i class="fa fa-arrow-down pull-left" style="position: absolute; padding-top: 12px;"></i>
                        <div class="ms-4 overflow-hide text-nowrap text-truncate">
                            {{metadataSummary.firstDate | date: 'dd-MM-yyyy HH:mm'}}
                            <br/>
                            {{metadataSummary.lastDate | date: 'dd-MM-yyyy HH:mm'}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="info-box">
                            <span class="info-box-icon ">
                                <i class="fa fa-map-marker"></i>
                            </span>
                <div class="info-box-content">
                    <div class="info-box-header">Distance</div>
                    <div class="info-box-body">Ca. {{metadataSummary.maximumDistance | number: '1.0-0'}} meter</div>
                </div>
            </div>
        </div>

        <p *ngIf="(metadataSummary.getMetadataMapSize() < attachmentSummary.workshopImages.length)" class="p-2 font-weight-bold font-italic">
            {{attachmentSummary.workshopImages.length - metadataSummary.getMetadataMapSize()}} billeder har ikke metadata.
        </p>
    </div>
</div>

<ng-template #summaryInfoPupup let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h3 class="text-truncate">Hvad betyder analysen?</h3>
        <div aria-label="Close" data-dismiss="modal" class="btn-close" (click)="d('Cross click')">
        </div>
    </div>
    <div class="modal-body">
        <div class="info-box">
            <span class="info-box-icon ">
                <i class="fa fa-question"></i>
            </span>
            <div class="info-box-content">
                <div class="info-box-header">Generelt</div>
                <div class="info-box-body">
                    <p>
                        Fra medio januar 2020 er vi begyndt at gemme metadata på alle billeder der bliver uploadet til
                        forsi.dk.
                        Det er ikke alle billeder der indeholder metadata og det er helt OK. Det kan for eksempel være
                        billeder
                        der er uploadet via en mobil der ikke tillader upload af disse data. <br>
                    </p>
                    <p>
                        I denne analyse viser vi hvor mange af værkstedets billeder der mangler metadata og hvis mere en
                        40% mangler metadata vil baggrunden være <span class="text-warning"><b>"gul"</b></span>.<br>
                        Værkstedets billeder der mangler metadata er markeret med <i
                        class="fa fa-exclamation-triangle text-warning" title="Der er ingen metadata på billedet."></i>.
                    </p>
                </div>
            </div>
        </div>

        <div class="info-box">
            <span class="info-box-icon ">
                <i class="fa fa-camera"></i>
            </span>
            <div class="info-box-content">
                <div class="info-box-header">Kameraer</div>
                <div class="info-box-body">Viser en liste af de benyttede kameraer.</div>
            </div>
        </div>

        <div class="info-box">
            <span class="info-box-icon ">
                <i class="fa fa-calendar"></i>
            </span>
            <div class="info-box-content">
                <div class="info-box-header">Datointerval</div>
                <div class="info-box-body">
                    Viser datoen på det første og det seneste billede som værkstedet har uploadet. (Kun billeder med
                    metadata indgår)
                </div>
            </div>
        </div>
        <div class="info-box">
                            <span class="info-box-icon ">
                                <i class="fa fa-map-marker"></i>
                            </span>
            <div class="info-box-content">
                <div class="info-box-header">Distance</div>
                <div class="info-box-body">
                    <p>
                        Viser den maksimale distance fra det første af værkstedets billeder til det billede der ligger længst
                        væk. Er der et af billederne der er taget mere end 500m væk fra det første billede, vil baggrunden være <span
                        class="text-danger"><b>"rød"</b></span>
                    </p>
                    <p>
                        Det eller de billeder der er taget mere end 500m væk fra det første vil være er markeret med
                        <i class="fa fa-exclamation-triangle text-danger metadata-danger-pulse"></i>
                    </p>
                </div>
            </div>

        </div>

        <div class="info-box">
            <span class="info-box-icon ">
                <i class="fa fa-star"></i>
            </span>
            <div class="info-box-content">
                <div class="info-box-header">Brug din sunde fornuft</div>
                <div class="info-box-body">
                    Denne analyse skal kun ses som en hjælp til at vurdere de billeder værkstedet har uploadet.
                </div>
            </div>
        </div>

    </div>
</ng-template>

