import {Injectable} from '@angular/core';
import {ClientStateDTO} from '../dto/client-state-dto.model';
import {VinLookupResultDTO} from '../dto/vin-lookup-result-dto.model';
import {ClientStateDetails} from '../model/client-state-details.model';
import {CompanyDetailsDTO} from '../dto/company-details-dto.model';
import {CalculationResponseDTO} from '../dto/calculation-response-dto.model';
import {ClientStateListViewDTO} from '../dto/client-state-list-view-dto.model';
import {Observable, of, Subject} from 'rxjs';
import {DeliverResultDTO} from '../dto/deliver-result-dto.model';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {DamagesNoteDTO} from '../../shared/dto/damages-note-dto.model';
import {DeleteDraftResultDTO} from '../dto/delete-client-state-result-dto.model';
import {DraftCopyResponseDTO} from '../dto/draft-copy-result.dto';
import {map} from 'rxjs/operators';
import {ApprovalChoiceDTO} from '../../shared/dto/approval-choice-dto.model';
import {CreateOfferDtoModel} from '../../shared/dto/create-offer-dto.model';
import {ClientStateWarningDTO} from '../dto/client-state-warning-dto.model';
import {CreateReportDTO} from '../../shared/dto/create-report-dto.model';
import {CarSaleDTO} from '../../shared/dto/carsale-dto.model';
import {CreateCarSaleDTO} from '../../shared/dto/create-carsale-dto.model';
import {CancelClientStateResultDTO} from '../dto/cancel-client-state-result-dto.model';
import {PotentialLinkedReportDTO} from '../../shared/dto/potential-linked-report-dto.model';
import {ForwardDraftDTO} from '../../shared/dto/transfer-draft-dto.model';
import {DraftCopyOptionsDTO} from '../dto/draft-copy-options.dto';
import {ReportCategory} from '../../shared/model/report-category.model';
import {TakeoverDraftResultDTO} from '../../shared/dto/takeover-draft-result-dto.model';
import {DeliveryNoteTemplateDataDTO} from '../../shared/ui/delivery-note/model/delivery-note-template-data-dto.model';
import {DeliveryNoteReceiverType} from '../../shared/ui/delivery-note/model/delivery-note-receiver-type.enum';
import {
  DeliveryNoteReceiverTemplateDataDTO
} from '../../shared/ui/delivery-note/model/delivery-note-receiver-template-data-dto.model';
import {CreateDeliveryNoteDTO} from '../../shared/ui/delivery-note/model/create-delivery-note-dto.model';
import {DeliveryNoteDTO} from '../../shared/ui/delivery-note/model/delivery-note-dto.model';
import {AttachDeliveryNoteDTO} from '../../shared/ui/delivery-note/model/attach-delivery-note-dto.model';
import {PrintDeliveryNoteDTO} from '../../shared/ui/delivery-note/model/print-delivery-note-dto.model';
import {LinkedReportDTO} from '../../shared/model/linked-report-dto.model';
import {CreateLinkedReportDTO} from '../../shared/model/create-linked-report-dto.model';
import {ReportDiffResult, ReportDiffResultDto} from '../../report/dto/report-diff-result-dto.model';
import {PrintAsTextDTO} from '../../shared/dto/print-as-text-dto.model';
import {PrintSettingsDTO} from '../../shared/dto/print-settings-dto.model';
import {AftaleDTO} from '../../shared/dto/aftale-dto.model';
import URLBuilder from '../../shared/utils/url-builder';
import {MessageDTO} from '../../shared/dto/message-dto.model';
import {CreateMessageDTO} from '../../shared/ui/message/dto/create-message-dto.model';
import {PolicyDTO} from '../../shared/dto/policy-dto.model';
import {Cacheable} from 'ts-cacheable';
import {AttachmentDTO} from '../../shared/dto/attachment-dto.model';
import {AttachmentSummaryDTO} from '../../shared/dto/attachment-summary-dto.model';
import {DraftAdviseListDTO} from '../dto/draft-advise-list-dto.model';
import {B2BCreateAdviseTimelineDTO} from '../../shared/dto/b2b-create-advise-timeline-dto.model';
import {DraftAdviseDTO} from '../dto/draft-advise-dto.model';
import {AssessorApprovalOptionsDTO} from '../dto/assessor-approval-options-dto.model';
import {ClientStateNonStandardPositionStatusDTO} from '../dto/client-state-non-standard-position-status-dto.model';
import {AgreementDocumentDTO} from '../../shared/dto/agreement-document-dto.model';

export enum ReportState {
  APPROVE = 'GK',
  PRELIMINARY = 'FO',
  BACKUP = 'BU',
}

const cacheBuster$ = new Subject<void>();
const maxAge = 5000;

@Injectable()
export class DraftService {
  private urlBuilder = new URLBuilder();
  private clientStateUrl = 'clientstate';
  private createClientStateUrl = this.clientStateUrl + '/create/';
  private deleteClientStateUrl = this.clientStateUrl + '/delete/';
  private calculateUrl = this.clientStateUrl + '/calculate/';
  private refreshUrl = this.clientStateUrl + '/refresh/';
  private selskabUrl = 'selskab';
  private vinOpslagUrl = 'dmr/audatex/';

  constructor(private http: HttpClient) {
  }

  clearCache(): void {
    cacheBuster$.next();
  }

  findAllClientStates(): Observable<ClientStateListViewDTO[]> {
    return this.http.get<ClientStateListViewDTO[]>(this.clientStateUrl);
  }

  getClientStateDTOObs(token: string): Observable<ClientStateDTO> {
    return this.http.get<ClientStateDTO>(this.clientStateUrl + '/' + encodeURIComponent(token)).pipe(
      map(response => response));
  }

  getClientStateObs(token: string): Observable<ClientStateDetails> {
    return this.getClientStateDTOObs(token).pipe(map(ClientStateDetails.fromClientStateDTO));
  }

  getClientState(token: string): Observable<ClientStateDTO> {
    return this.getClientStateDTOObs(token);
  }

  getCompanyDetails(token: string): Observable<CompanyDetailsDTO[]> {
    //do not cache this data as the result depends on the requesting user
    return this.http.get<CompanyDetailsDTO[]>(this.clientStateUrl + '/' + token + '/selskaber').pipe(map(response => response.map(selskabJson => Object.assign(new CompanyDetailsDTO(), selskabJson))));
  }

  vinLookup(regnr: string): Observable<VinLookupResultDTO> {
    return this.http.get<VinLookupResultDTO>(this.vinOpslagUrl + encodeURIComponent(regnr));
  }

  create(reportType: ReportCategory, clientStateDetails: ClientStateDetails): Observable<ClientStateDTO> {
    const clientStateDTO: ClientStateDTO = clientStateDetails.asClientStateDTO();
    return this.http.post<ClientStateDTO>(this.createClientStateUrl + reportType.reportTypeName, clientStateDTO);
  }

  cancelDraft(token: string): Observable<CancelClientStateResultDTO> {
    return this.http.put<CancelClientStateResultDTO>(this.clientStateUrl + '/' + token + '/cancel', null);
  }

  deleteDraft(clientStateListViewDTO: ClientStateListViewDTO): Observable<DeleteDraftResultDTO[]> {
    return this.http.post<DeleteDraftResultDTO[]>(this.deleteClientStateUrl + clientStateListViewDTO.token, null);
  }

  saveAndMap(clientStateDetails: ClientStateDetails): Observable<ClientStateDetails> {
    return this.save(clientStateDetails).pipe(map(ClientStateDetails.fromClientStateDTO));
  }

  save(clientStateDetails: ClientStateDetails): Observable<ClientStateDTO> {
    const clientStateDTO: ClientStateDTO = clientStateDetails.asClientStateDTO();
    return this.http.put<ClientStateDTO>(this.clientStateUrl + '/' + clientStateDetails.token, clientStateDTO);
  }

  calculate(draft: ClientStateDetails): Observable<CalculationResponseDTO> {
    const clientStateToken = draft.token;
    const clientStateDTO = draft.asClientStateDTO();
    return this.http.post<CalculationResponseDTO>(this.calculateUrl + encodeURIComponent(clientStateToken), clientStateDTO).pipe(map(response => response));
  }

  calculationExists(clientStateToken: string): Observable<boolean> {
    return this.http.get<boolean>(this.calculateUrl + encodeURIComponent(clientStateToken) + '/exists');
  }

  /**
   * Can diff a taks clientstate with a possible associated vk report
   *
   * @param clientStateToken
   */
  diff(clientStateToken: string): Observable<ReportDiffResult> {
    return this.http.get<ReportDiffResultDto>(this.clientStateUrl + '/' + clientStateToken + '/diff')
      .pipe(map(response => {
        const result = new ReportDiffResult();
        result.addSimpleSection('t1', 'Kontrolblad', response.kontrolblad);
        result.addSimpleSection('t2', 'Forside', response.forside);
        result.addSimpleSection('t3', 'Arbejdsbeskrivelse', response.arbejdsbeskrivelse);
        result.addSimpleSection('t4', 'Reservedele', response.reservedele);
        result.addSimpleSection('t5', 'Lak', response.lak);
        return result;
      }));
  }

  refresh(clientStateToken: string): Observable<ClientStateDTO> {
    return this.http.put<ClientStateDTO>(this.refreshUrl + encodeURIComponent(clientStateToken), null).pipe(map(response => response));
  }

  createOffer(clientStateToken: string, createOffer: CreateOfferDtoModel): Observable<DeliverResultDTO> {
    return this.http.post<DeliverResultDTO>(this.clientStateUrl + '/' + encodeURIComponent(clientStateToken) + '/createoffer', createOffer).pipe(
      map(response => response));
  }

  createTaksatorReportFromClientState(clientStateToken: string, createReport: CreateReportDTO): Observable<DeliverResultDTO> {
    return this.http.post<DeliverResultDTO>(this.clientStateUrl + '/' + encodeURIComponent(clientStateToken) + '/report', createReport);
  }

  getDamagesNote(clientStateToken: string): Observable<DamagesNoteDTO> {
    return this.http.get<DamagesNoteDTO>(this.clientStateUrl + '/' + clientStateToken + '/damagesnote');
  }

  getApprovalChoices(selskab: string): Observable<ApprovalChoiceDTO[]> {
    return this.http.get<ApprovalChoiceDTO[]>(this.selskabUrl + '/' + selskab + '/approvalchoices');
  }

  public copyDraft(clientStateToken: string, draftCopyOptions: DraftCopyOptionsDTO): Observable<DraftCopyResponseDTO> {
    return this.http.post<DraftCopyResponseDTO>(this.clientStateUrl + '/' + clientStateToken + '/copy', draftCopyOptions);
  }

  updateDamagesNote(clientStateToken: string, text: string): Observable<DamagesNoteDTO> {
    const damagesNote = new DamagesNoteDTO();
    damagesNote.text = text;
    return this.http.put<DamagesNoteDTO>(this.clientStateUrl + '/' + clientStateToken + '/damagesnote', damagesNote);
  }

  getClientStateWarning(clientStateToken: string): Observable<ClientStateWarningDTO> {
    return this.http.get<ClientStateWarningDTO>(this.clientStateUrl + '/' + clientStateToken + '/warning');
  }

  getAssessorApprovalOptions(clientStateToken: string): Observable<AssessorApprovalOptionsDTO> {
    return this.http.get<AssessorApprovalOptionsDTO>(this.clientStateUrl + '/' + clientStateToken + '/assessor-approval-options');
  }

  getCarSales(list: ClientStateListViewDTO[]): Observable<CarSaleDTO[]> {
    if (list.length === 0) {
      return of([]);
    } else {
      return this.http.post<CarSaleDTO[]>(this.clientStateUrl + '/carsale', list.map(t => t.token));
    }
  }

  createCarSale(clientStateToken: string, createCarSaleDTO: CreateCarSaleDTO): Observable<CarSaleDTO> {
    return this.http.post<CarSaleDTO>(this.clientStateUrl + '/' + clientStateToken + '/carsale', createCarSaleDTO);
  }

  deleteCarSale(clientStateToken: string): Observable<any> {
    return this.http.delete(this.clientStateUrl + '/' + clientStateToken + '/carsale');
  }

  getPotentialLinkedReports(clientStateToken: string): Observable<PotentialLinkedReportDTO[]> {
    return this.http.get<PotentialLinkedReportDTO[]>(this.clientStateUrl + '/' + clientStateToken + '/linkedreport');
  }

  createLinkedReport(clientStateToken: string, createLinkedReport: CreateLinkedReportDTO): Observable<LinkedReportDTO> {
    return this.http.post<LinkedReportDTO>(this.clientStateUrl + '/' + clientStateToken + '/linkedreport/' + createLinkedReport.type, createLinkedReport);
  }

  forwardDraft(clientStateToken: string, forwardDraftDTO: ForwardDraftDTO): Observable<void> {
    return this.http.put<void>(this.clientStateUrl + '/' + clientStateToken + '/forward', forwardDraftDTO);
  }

  takeoverDraft(clientStateToken: string): Observable<TakeoverDraftResultDTO> {
    return this.http.put<TakeoverDraftResultDTO>(this.clientStateUrl + '/' + clientStateToken + '/takeover', null);
  }

  replaceVinDetails(clientStateToken: string, vinLookup: VinLookupResultDTO): Observable<ClientStateDetails> {
    return this.http.put<ClientStateDTO>(this.clientStateUrl + '/' + clientStateToken + '/vinlookup', vinLookup).pipe(map(ClientStateDetails.fromClientStateDTO));
  }

  public getDeliveryNoteTemplateData(token: any): Observable<DeliveryNoteTemplateDataDTO> {
    return this.http.get<DeliveryNoteTemplateDataDTO>(this.clientStateUrl + '/' + token + '/deliverynote/templatedata');
  }

  public getDeliveryNoteTemplateDataByReceiverType(token: any, type: DeliveryNoteReceiverType): Observable<DeliveryNoteReceiverTemplateDataDTO> {
    return this.http.get<DeliveryNoteReceiverTemplateDataDTO>(this.clientStateUrl + '/' + token + '/deliverynote/receiver/' + type + '/templatedata');
  }

  public generateDeliveryNote(token: any, createDeliveryNote: CreateDeliveryNoteDTO): Observable<DeliveryNoteDTO> {
    return this.http.post<DeliveryNoteDTO>(this.clientStateUrl + '/' + token + '/deliverynote', createDeliveryNote);
  }

  attachDeliveryNote(token: any, print: DeliveryNoteDTO): Observable<AttachDeliveryNoteDTO> {
    return this.http.post<AttachDeliveryNoteDTO>(this.clientStateUrl + '/' + token + '/deliverynote/attach', print);
  }

  printDeliveryNote(token: any, print: DeliveryNoteDTO): Observable<PrintDeliveryNoteDTO> {
    return this.http.post<PrintDeliveryNoteDTO>(this.clientStateUrl + '/' + token + '/deliverynote/print', print);
  }

  getPrintSettings(clientStateToken: string): Observable<PrintSettingsDTO> {
    return this.http.get<PrintSettingsDTO>(this.clientStateUrl + '/' + encodeURIComponent(clientStateToken) + '/print/settings');
  }

  getPrintAsText(clientStateToken: string): Observable<PrintAsTextDTO> {
    return this.http.get<PrintAsTextDTO>(this.clientStateUrl + '/' + encodeURIComponent(clientStateToken) + '/print/text');
  }

  getPrintAsTextOfOfferRelatedToDraft(clientStateToken: string): Observable<PrintAsTextDTO> {
    return this.http.get<PrintAsTextDTO>(this.clientStateUrl + '/' + encodeURIComponent(clientStateToken) + '/offer/print/text').pipe(
      map(response => response));
  }

  getAgreements(token: string): Observable<AftaleDTO[]> {
    return this.http.get<AftaleDTO[]>(this.clientStateUrl + '/' + token + '/agreements');
  }

  getAgreementDocuments(token: string): Observable<AgreementDocumentDTO[]> {
    return this.http.get<AgreementDocumentDTO[]>(this.clientStateUrl + '/' + token + '/agreement-documents');
  }

  getAgreementDocumentUrl(token: string, agreementDocumentId: number, download: boolean): string {
    return this.urlBuilder.getBaseApiUrl() + this.clientStateUrl + '/' + token + '/agreement-documents/' + agreementDocumentId + '?download=' + String(download);
  }

  getMessages(token: string): Observable<MessageDTO[]> {
    return this.http.get<MessageDTO[]>(this.clientStateUrl + '/' + token + '/messages');
  }

  sendMessage(token: string, createMessageDTO: CreateMessageDTO): Observable<any> {
    return this.http.post<any>(this.clientStateUrl + '/' + token + '/messages', createMessageDTO);
  }

  searchForPolicy(token: string): Observable<PolicyDTO> {
    return this.http.get<PolicyDTO>(this.clientStateUrl + '/' + token + '/policy');
  }

  searchNewPolicy(token: string): Observable<PolicyDTO> {
    return this.http.get<PolicyDTO>(this.clientStateUrl + '/' + token + '/policy/new');
  }

  @Cacheable({cacheBusterObserver: cacheBuster$, maxAge: maxAge})
  getAttachments(clientStateToken: string): Observable<AttachmentDTO[]> {
    return this.http.get<AttachmentDTO[]>(this.clientStateUrl + '/' + clientStateToken + '/attachments');
  }

  @Cacheable({cacheBusterObserver: cacheBuster$, maxAge: maxAge})
  getAttachmentSummary(token: string): Observable<AttachmentSummaryDTO> {
    return this.http.get<AttachmentSummaryDTO>(this.clientStateUrl + '/' + token + '/attachments/summary');
  }

  public uploadAttachment(attachment: File, token: string): Observable<AttachmentDTO> {
    const formData = new FormData();
    formData.append('file', attachment);
    return this.http.post<AttachmentDTO>(this.clientStateUrl + '/' + token + '/attachments', formData).pipe(e => {
      cacheBuster$.next();
      return e;
    });
  }

  public updateAttachmentRemark(token: string, attachment: AttachmentDTO): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'text/plain;charset=UTF-8'
    });
    const url = 'clientstate/' + token + '/attachments/' + attachment.id;
    return this.http.put(url, attachment.remark, {headers: headers});
  }

  public deleteAttachment(token: string, attachmentId: number): Observable<void> {
    const url = 'clientstate/' + token + '/attachments/' + attachmentId;
    return this.http.delete<void>(url).pipe(map(() => {
      cacheBuster$.next();
    }));
  }

  getAdvise(token: string): Observable<DraftAdviseDTO> {
    return this.http.get<DraftAdviseDTO>(this.clientStateUrl + '/' + token + '/advise')
  }

  createAdvise(token: string, advise: DraftAdviseDTO): Observable<DraftAdviseDTO> {
    return this.http.post<DraftAdviseDTO>(this.clientStateUrl + '/' + token + '/advise', advise)
  }

  deleteAdvise(token: string): Observable<void> {
    return this.http.delete<void>(this.clientStateUrl + '/' + token + '/advise')
  }

  getAdvises(list: ClientStateListViewDTO[]): Observable<DraftAdviseListDTO[]> {
    if (list.length === 0) {
      return of([]);
    } else {
      let params = new HttpParams();
      list.forEach((draft) => {
        params = params.append('cId', String(draft.id))
      })
      return this.http.get<DraftAdviseListDTO[]>(this.clientStateUrl + '/advises', {params});
    }
  }

  createAdviseMessage(token: string, adviseMessage: B2BCreateAdviseTimelineDTO): Observable<DraftAdviseDTO> {
    return this.http.put<DraftAdviseDTO>(this.clientStateUrl + '/' + token + '/advise', adviseMessage)
  }

  getCalculationInputNonStandardPositionStatus(token: string): Observable<ClientStateNonStandardPositionStatusDTO> {
    return this.http.get<ClientStateNonStandardPositionStatusDTO>(this.clientStateUrl + '/' + token + '/non-standard-position-status')
  }

  hasAutoflexBookings(token: string): Observable<boolean> {
    return this.http.get<boolean>(this.clientStateUrl + '/' + token + '/has-autoflex-bookings');
  }
}
