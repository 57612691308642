import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {PriceVersionDTO} from '../dto/price-version-dto.model';
import {isNullOrUndefined} from '../utils/object-utils';

@Injectable({
  providedIn: 'root'
})
export class PriceVersionService {
  private baseUrl = 'priceversion';

  constructor(private http: HttpClient) {
  }

  getPriceVersionFromDate(fromdate?: Date): Observable<PriceVersionDTO> {
    if (!isNullOrUndefined(fromdate)) {
      const day = ('0' + fromdate.getDate()).slice(-2);
      const month = ('0' + (fromdate.getMonth() + 1)).slice(-2);
      const year = fromdate.getFullYear();
      const calcDateStr = day + '-' + month + '-' + year;
      return this.http.get<PriceVersionDTO>(this.baseUrl + '/fromdate/' + calcDateStr)
    } else {
      return this.http.get<PriceVersionDTO>(this.baseUrl + '/default');
    }
  }
}
