/**
 * Creating own declarations of isNullOrUndefined,
 * to prevent dependency to some external library for this small operation.
 * (was part of warnings when upgrading from Angular 9.x to 10.x)
 */
export const isNullOrUndefined = (obj: Object): boolean => obj === null || obj === undefined;

export const isNotNullOrUndefined = (obj: Object): boolean => !(obj === null || obj === undefined);

export class ObjectUtils {

  public static exists(obj: Object): boolean {
    return !isNullOrUndefined(obj);
  }

  public static isEmpty(obj: Object): boolean {
    return isNullOrUndefined(obj);
  }

  public static inputToNumber(value: string): number {
    return parseFloat(value.replace(',', '.'));
  }
}
