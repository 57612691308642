<lc-bottombar [menu]="viewmodel.draftMenu" [iconClass]="viewmodel.menuIconClass"
              [title]="viewmodel.menuBottomTitle"
              [shortTitle]="viewmodel.menuBottomShortTitle"></lc-bottombar>
<div class="text-center" *ngIf="viewmodel.showLoading">
    <i class="fa fa-circle-o-notch fa-spin fop-page-spinner"></i>
</div>
<ng-container *ngIf="!viewmodel.showLoading">
    <h2>Check rapport</h2>

    <div class="no-focus">
        <div class="card mb-2">
            <div class="card-header" [style.background-color]="viewmodel.headerColor">
                {{viewmodel.headerText}}
            </div>
            <div class="card-body">
                <pre>{{viewmodel.bodyText}}</pre>
            </div>
        </div>
    </div>
    <div class="row dynamic-padding-bottom">
        <!-- Room for floating action buttons -->
        <div class="col-md-12"></div>
    </div>
</ng-container>