import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {SharedCommonModule} from '../shared/shared-common.module';
import {RouterModule} from '@angular/router';
import {NonStandardPositionsComponent} from './non-standard-positions.component';
import {NS_POSITIONS_ROUTES} from './non-standard-positions.route';
import {
  PrivateNonStandardPositionsComponent
} from './private-non-standard-positions/private-non-standard-positions.component';
import {
  CrudPrivateNonStandardPositionPopupComponent
} from './private-non-standard-positions/crud/crud-private-non-standard-position-popup.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  ProvidedNonStandardPositionsComponent
} from './provided-non-standard-positions/provided-non-standard-positions.component';
import {
  ImportPrivateNonStandardPositionPopupComponent
} from './private-non-standard-positions/import/import-private-non-standard-position-popup.component';

@NgModule({
    imports: [
        SharedModule,
        SharedCommonModule,
        FormsModule,
        ReactiveFormsModule,
    RouterModule.forRoot(NS_POSITIONS_ROUTES, {useHash: true})
    ],
  declarations: [
    NonStandardPositionsComponent,
    PrivateNonStandardPositionsComponent,
    ProvidedNonStandardPositionsComponent,
    CrudPrivateNonStandardPositionPopupComponent,
    ImportPrivateNonStandardPositionPopupComponent
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class NonStandardPositionsModule {
}
