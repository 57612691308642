<lc-bottombar [menu]="menu" [title]="'Bil detaljer'"></lc-bottombar>

<div class="row">
    <div class="col-12">
        <h1>Bil detaljer</h1>
        <p>Data vises direkte fra vores integrations partner og er afhængig af deres systemer for at fungere.</p>
        <hr/>
    </div>
</div>

<div class="row" *ngIf="loading">
    <div class="col-12">
        <div class="text-center">
            <i class="fa fa-circle-o-notch fa-spin fop-page-spinner"></i>
        </div>
    </div>
</div>

<ng-container *ngIf="!loading">
    <lc-display-object-properties [object]="dmrDetails"></lc-display-object-properties>
</ng-container>

<!-- so bottombar does not hide entries -->
<br/>
<br/>
<br/>
<br/>