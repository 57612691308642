import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ClientStateDetails} from '../../../model/client-state-details.model';
import {DraftService} from '../../../service/draft.service';
import {DraftAdviseDTO} from '../../../dto/draft-advise-dto.model';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {
  ConfirmButtonType,
  ConfirmPopupButton,
  ConfirmPopupComponent
} from '../../../../shared/modals/confirm/confirm-popup.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lc-draft-advise',
  templateUrl: './draft-advise.component.html'
})
export class DraftAdviseComponent implements OnInit, OnDestroy {
  @Input() details: ClientStateDetails;
  advise: DraftAdviseDTO;
  create = true;
  private unsubscribe$ = new Subject<void>();

  constructor(private draftService: DraftService, private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.loadAdvise();
  }

  public loadAdvise(): void {
    this.draftService.getAdvise(this.details.token).pipe(takeUntil(this.unsubscribe$)).subscribe((advise) => {
      this.setAdvise(advise);
    })
  }

  private setAdvise(advise: DraftAdviseDTO): void {
    if (advise) {
      this.advise = advise
      this.create = false;
    } else {
      this.advise = new DraftAdviseDTO();
      this.create = true;
    }
  }

  createAdvise(): void {
    this.draftService.createAdvise(this.details.token, this.advise).pipe(takeUntil(this.unsubscribe$)).subscribe((advise) => {
      this.setAdvise(advise);
    });
  }

  deleteAdvise(): void {
    const modalRef = this.modalService.open(ConfirmPopupComponent);
    const instance = modalRef.componentInstance as ConfirmPopupComponent;
    instance.title = 'Slet rådgivning';
    instance.body = 'Når du sletter en rådgivning vil alle beskeder på rådgivningen også blive slettet!';
    instance.customFirstButton = new ConfirmPopupButton('OK', ConfirmButtonType.CONFIRM);
    instance.customSecondButton = new ConfirmPopupButton('Fortryd', ConfirmButtonType.DECLINE);
    modalRef.result.then((deleteConfirmed: boolean) => {
      if (deleteConfirmed) {
        this.draftService.deleteAdvise(this.details.token).pipe(
          takeUntil(this.unsubscribe$)
        ).subscribe(() => {
          this.setAdvise(null);
        });
      }
    });
  }

  adviseEnabled(): boolean {
    return !!this.advise && (this.details.calculatedPrice > 0 || !this.create)
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
