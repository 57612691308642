import {PrintLayoutConfigurationDTO} from '../../settings/model/print-layout-configuration-dto.model'
import {GdprPrintOptionsDTO} from './gdpr-print-options-dto.model';
import {HttpParams} from '@angular/common/http';

export class PrintInputDTO {
  customerCopy: boolean;
  gdprOptions: GdprPrintOptionsDTO;
  printLayout: PrintLayoutConfigurationDTO;

  public toHttpParams(): HttpParams {
    return new HttpParams()
      .set('customerCopy', this.getValue(this.customerCopy, 'false'))
      .set('gdprOptions.gdprPrint', this.getValue(this.gdprOptions.gdprPrint, 'false'))
      .set('gdprOptions.inclRegNr', this.getValue(this.gdprOptions.inclRegNr, 'false'))
      .set('printLayout.form18ConfigTopmargin', this.getValue(this.printLayout.form18ConfigTopmargin, '0'))
      .set('printLayout.form18ConfigVenstremargin', this.getValue(this.printLayout.form18ConfigVenstremargin, '0'))
      .set('printLayout.udskriftConfigTopmargin', this.getValue(this.printLayout.udskriftConfigTopmargin, '0'))
      .set('printLayout.udskriftConfigVenstremargin', this.getValue(this.printLayout.udskriftConfigVenstremargin, '0'))
      .set('printLayout.sektionH', this.getValue(this.printLayout.sektionH, 'false'))
      .set('printLayout.sektionH1', this.getValue(this.printLayout.sektionH1, 'false'))
      .set('printLayout.sektionH2', this.getValue(this.printLayout.sektionH2, 'false'))
      .set('printLayout.sektionH3', this.getValue(this.printLayout.sektionH3, 'false'))
      .set('printLayout.sektionH4', this.getValue(this.printLayout.sektionH4, 'false'))
      .set('printLayout.sektionH5', this.getValue(this.printLayout.sektionH5, 'false'))
      .set('printLayout.taksatorInfoPaaLakblad', this.getValue(this.printLayout.taksatorInfoPaaLakblad, 'false'));
  }

  private getValue(value: any, defaultValue = ''): string {
    return value ? '' + value : defaultValue;
  }
}
