import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ClientStateDetails} from '../../../..';
import {TextLibraryTypeEnum} from '../../../../../shared/service/text-library-type.enum';
import {TextLibraryPopupComponent} from '../../../../../shared/modals/text-library/text-library-popup.component';
import {NgForm} from '@angular/forms';
import {FormSaver} from '../../../../../shared/service/core/form-saver.service';
import {TextAreaUtils} from '../../../../../shared/utils/text-area-utils';
import {UserTextLibraryService} from '../../../../../shared/service/user-text-library.service';
import {RapportType} from '../../../../../shared/model/rapport-type.model';

@Component({
  selector: 'lc-draft-remarks',
  templateUrl: './draft-remarks.component.html',
  styleUrls: ['./draft-remarks.scss', '../../../draft-common.scss']
})
export class DraftRemarksComponent implements OnInit {
  @Input() details: ClientStateDetails;
  @Input() formId: string;
  @ViewChild('remarksText', {static: true}) public remarksText: HTMLTextAreaElement;
  @ViewChild('f', {static: true}) public cform: NgForm;
  libraryType = TextLibraryTypeEnum.REMARKS;
  remarkLinesCount: number;
  remarkLinesLimit = 12;

  constructor(private modalService: NgbModal,
              private formSaver: FormSaver,
              private userTextLibraryService: UserTextLibraryService) {
  }

  ngOnInit(): void {
    this.formSaver.registerForm(this.formId, this.cform);
    this.fixMaxLineLength(this.remarksText);
  }

  showUserTextLibrary(): void {
    const modalRef = this.modalService.open(TextLibraryPopupComponent, {size: 'lg', windowClass: 'lc-fullscreen'});
    const instance = modalRef.componentInstance as TextLibraryPopupComponent;
    instance.title = 'Bemærkninger Eget Tekstbibliotek';
    instance.editable = true;
    instance.textLibraryType = this.libraryType;
    instance.textLibraryService = this.userTextLibraryService;
    modalRef.result.then((text: string) => {
      if (text) {
        this.didSelectTextFromLibrary(text);
      }
    }).catch(() => {
      // Necessary to avoid "Uncaught error Error: Uncaught (in promise)" even though we do not use the returned value.
    });
  }

  didSelectTextFromLibrary(text: string) {
    this.details.bemaerkninger = this.details.bemaerkninger ? this.details.bemaerkninger + text : text;
    this.fixMaxLineLength(this.remarksText);
  }

  getReportType(): RapportType {
    return RapportType.extractFrom(this.details.rapportKey());
  }

  /**
   * There is no backend logic to make sure each line is only 35 chars.
   * Everything more than 35 chars pr. line will not be in the Report Print.
   */
  fixMaxLineLength(textArea: HTMLTextAreaElement): void {
    const textAreaElementId = 'remarksText';
    const fixMaxLineLength = TextAreaUtils.fixMaxLineLength(textArea, 35, this.details.bemaerkninger, textAreaElementId);

    this.remarkLinesCount = fixMaxLineLength.linesCount;
    if (this.remarkLinesCount > this.remarkLinesLimit) {
      setTimeout(() => {
        this.cform.form.controls[textAreaElementId].setErrors({incorrect: true});
      });

    }

    this.details.bemaerkninger = fixMaxLineLength.text;
  }

  getMessageClass(): string {
    return TextAreaUtils.getMessageClass(this.remarkLinesLimit, this.remarkLinesCount);
  }

  getMessage(): string {
    return TextAreaUtils.getMessage(this.remarkLinesLimit, this.remarkLinesCount);
  }
}
