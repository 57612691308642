import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {DraftVehicleConditionComponent} from './draft-vehicle-condition/draft-vehicle-condition.component';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {DraftLicenseplateComponent} from './draft-numberplate/draft-licenseplate.component';
import {DraftAdhocVkDetailsComponent} from './draft-adhoc-vk-details/draft-adhoc-vk-details.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule
  ],
  declarations: [
    DraftLicenseplateComponent,
    DraftVehicleConditionComponent,
    DraftAdhocVkDetailsComponent
  ],
  exports: [
    DraftLicenseplateComponent,
    DraftVehicleConditionComponent,
    DraftAdhocVkDetailsComponent
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DraftCommonUiModule {

}
