import {Component, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {LcActionMenu} from '../../../shared/ui/bottombar/lc-action-menu.model';
import {ActivatedRoute} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {CarDetailsProviderService} from '../../../shared/ui/car-details/car-details-provider.service';
import {ReportCarDetailsProviderService} from './report-car-details-provider.service';
import {ReportService} from '../../service/report.service';
import {ClientNavigationService} from '../../../shared/service/client-navigation.service';

@Component({
  templateUrl: './report-car-details.component.html'
})
export class ReportCarDetailsComponent implements OnDestroy {
  private unsubscribe$ = new Subject<void>();
  menu: LcActionMenu = new LcActionMenu();
  loading = true;
  carDetailsProvider: CarDetailsProviderService;

  constructor(protected reportService: ReportService,
              private clientNavigationService: ClientNavigationService,
              private route: ActivatedRoute) {
    this.route.parent.params.pipe(takeUntil(this.unsubscribe$))
      .subscribe(params => {
        this.carDetailsProvider = new ReportCarDetailsProviderService(reportService, +params['reportId']);
      });
  }

  navigateBack(): void {
    this.clientNavigationService.back();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
