<div class="text-center" *ngIf="isLoading">
    <i class="fa fa-circle-o-notch fa-spin fop-page-spinner"></i>
</div>
<div *ngIf="!isLoading" class="no-focus" tabindex="0" [lcAutofocus]>
    <div class="card">
        <div class="card-body">
            <h4 class="card-title">Personlig afløsning</h4>
            <lc-settings-postbox-row *ngFor="let substitute of postboxConfiguration.substitutes"
                                     class="row mb-2 ms-0"
                                     [postbox]="substitute"
                                     [postboxUsers]="personalReplacementPostboxes"
                                     [editPostboxInterval]="false"
                                     (togglePostbox)="toogleSubstitute($event)">
            </lc-settings-postbox-row>
        </div>
    </div>

    <div class="card mt-2">
        <div class="card-body">
            <h4 class="card-title">Fælles postkasse</h4>
            <lc-settings-postbox-row *ngFor="let mailbox of postboxConfiguration.commonMailboxes"
                                     class="row mb-2 ms-0"
                                     [postbox]="mailbox"
                                     [postboxUsers]="commonPostboxes"
                                     (togglePostbox)="toogleCommon($event)">
            </lc-settings-postbox-row>
        </div>
    </div>

    <div class="p-2">
        <button class="btn btn-primary pull-right" (click)="save()">Gem</button>
        <button class="btn btn-secondary pull-right me-2" (click)="reset()">Reset</button>
    </div>

</div>
