import {isNullOrUndefined} from './object-utils';

declare var $: JQueryStatic;

export class UIUtils {

  /**
   * Capable of either navigating to the first elementId in the elementIds list or if the highlightFirstInvalidInput
   * properties is 'true' it will search to find the first
   * invalid inputfield within the boundaries of the elementIds list provided.
   *
   * Usefull to scroll and focus within a section in draft-menu.
   */
  public scrollToElementId(elementIds: string[], highlightFirstInvalidInput: boolean = true): void {
    let focusChanged: boolean;
    if (highlightFirstInvalidInput) {
      for (const elementId of elementIds) {
        const element = document.getElementById(elementId);
        focusChanged = this.focusOnFirstInvalidHTMLElement(element);
        if (focusChanged) {
          break;
        }
      }
    }
    if (!focusChanged) {
      this.scrollToElementWithId(elementIds[0]);
    }
  }

  public focusOnFirstInvalidHTMLElement(htmlElement: HTMLElement): boolean {
    const target = htmlElement.querySelector(':not(fieldset).ng-invalid');
    if (target) {
      if (target instanceof HTMLElement) {
        this.scrollToElement(target, -50);
      }
      //set focus on element
      if (target instanceof HTMLElement) {
        //lc-autocomplete needs a little special handling ...
        if (target.tagName.toLowerCase() === 'lc-autocomplete') {
          const innerInputElement = target.querySelector('input');
          if (innerInputElement) {
            (innerInputElement as HTMLElement).focus();
          }
        } else {
          target.focus();
        }
      }
    }
    return !isNullOrUndefined(target);
  }

  public scrollToElementWithId(elementId: string, offSet: number = 0): void {
    const element = document.getElementById(elementId);
    if (element) {
      this.scrollToElement(element, offSet);
    }
  }

  private scrollToElement(element: HTMLElement, offSet: number = 0): void {
    const topOffset = this.calculateTopOffsetFromNavBar();
    const scrollToPosition = $(element).offset().top - topOffset + offSet;
    $('html,body').animate({scrollTop: scrollToPosition}, 'fast');
  }

  public calculateTopOffsetFromNavBar(): number {
    const navBarElement = document.getElementById('breadcrumb-position');
    let topOffset = 0;
    if (!isNullOrUndefined(navBarElement)) {
      const topNav = navBarElement.getBoundingClientRect();
      topOffset = topNav.top + topNav.height;
    }

    //
    const navNotificationbar = document.getElementById('navNotificationbar');
    if (!isNullOrUndefined(navNotificationbar)) {
      const draftMenuTopRect = navNotificationbar.getBoundingClientRect();
      topOffset += draftMenuTopRect.height;
    }

    //In large viewport mode we will show the navigation menu in the top, hence we need to correct for that as well.
    const LARGE_VIEWPORT_WIDTH = 992;
    if (window.innerWidth < LARGE_VIEWPORT_WIDTH) {
      const contextMenuTopElement = document.getElementById('context-menu-top');
      if (!isNullOrUndefined(contextMenuTopElement)) {
        const draftMenuTopRect = contextMenuTopElement.getBoundingClientRect();
        topOffset += draftMenuTopRect.height;
      }
    }
    return topOffset;
  }
}
