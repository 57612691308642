import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {SharedCommonModule} from '../shared/shared-common.module';
import {ProductRecycleComponent} from './product-recycle.component';
import {ProductRecycleService} from './product-recycle.service';
import {LightclientQapterModule} from '../shared/ui/qapter/qapter.module';

@NgModule({
    imports: [
        SharedModule,
        BrowserModule,
        FormsModule,
        SharedCommonModule,
        LightclientQapterModule
    ],
    declarations: [
        ProductRecycleComponent
    ],
    exports: [],
    providers: [
        ProductRecycleService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ProductModule {
}
