import {AttachmentMetadata} from './attachment-metadata.model';
import {AttachmentDTO} from './attachment-dto.model';

export class AttachmentMetadataSummary {
  cameras: Set<string> = new Set();
  firstDate: Date;
  lastDate: Date;
  maximumDistance: number;
  baseline: AttachmentMetadata;
  metadataMap: Record<number, AttachmentMetadata>;
  
  public addAttachmentMetadata(metadata: AttachmentMetadata): void {
    if (!this.metadataMap) {
      this.metadataMap = {};
    }
    this.metadataMap[metadata.attachmentId] = metadata;
    
    if (!this.baseline) {
      this.baseline = metadata;
      this.maximumDistance = 0;
    } else {
      if (metadata.distanceToBaseline > this.maximumDistance) {
        this.maximumDistance = metadata.distanceToBaseline
      }
    }
    this.cameras.add(metadata.camera);

    if (!this.firstDate || metadata.date < this.firstDate) {
      this.firstDate = metadata.date;
    }
    if (!this.lastDate || metadata.date > this.lastDate) {
      this.lastDate = metadata.date;
    }

  }
  
  public getCamerasAsString(): string {
    return Array.from(this.cameras).join(', ')
  }

  public validateSummary(attachments: AttachmentDTO[]): string {
    const total = attachments.length;
    let noMetadata = 0;
    if (!this.metadataMap && total > 0) {
      noMetadata = total;
    } else {
      for (let i = 0; i < total; i++) {
        const attachmentDTO = attachments[i];
        if (!this.metadataMap[attachmentDTO.id]) {
          noMetadata++
        } else if (!this.validateDistanceOK(attachmentDTO)) {
          return 'bg-danger text-white'
        }
      }
    }
    
    return noMetadata / total > 0.4 ? 'bg-warning' : 'bg-success text-white';
  }
  
  getMetadataMapSize(): number {
    return !this.metadataMap ? 0 : Object.keys(this.metadataMap).length;
  }
  
  validateDistanceOK(attachment: AttachmentDTO): boolean {
    const metadata = this.metadataMap && this.metadataMap[attachment.id];
    return !metadata || metadata.distanceToBaseline < 500;
  }

  getDistance(attachment: AttachmentDTO): number {
    const metadata = this.metadataMap && this.metadataMap[attachment.id];
    return metadata ? metadata.distanceToBaseline : 0;
  }
}

