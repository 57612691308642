import {Component} from '@angular/core';

@Component({
  templateUrl: './settings.component.html',
  styleUrls: [
    'settings.scss'
  ]
})
export class SettingsComponent {
  constructor() {
  }
}
