import {Routes} from '@angular/router';

import {UserRouteAccessService} from '../shared';
import {ReportDiffComponent} from './diff/report-diff.component';
import {ReportViewerComponent} from './viewer/report-viewer.component';
import {ReportListComponent} from './list/report-list.component';
import {NewReportTypeFromReportComponent} from './new-report-type/new-report-type-from-report.component';
import {EditDeliveryNoteComponent} from '../shared/ui/delivery-note/edit/edit-delivery-note.component';
import {DeliveryNoteReportWrapperComponent} from '../shared/ui/delivery-note/delivery-note-report-wrapper.component';
import {ShowDeliveryNoteComponent} from '../shared/ui/delivery-note/show/show-delivery-note.component';
import {AppRole} from '../shared/service/auth/app-roles.model';
import {ReportCarDetailsComponent} from './viewer/car-details/report-car-details.component';

export const REPORT_ROUTES: Routes = [
  {
    path: 'report',
    data: {
      authorities: [AppRole.VK, AppRole.TAKS],
      breadcrumb: 'Rapporter'
    },
    canActivate: [UserRouteAccessService],
    children: [
      {
        path: '',
        component: ReportListComponent,
        data: {
          authorities: [AppRole.VK, AppRole.TAKS],
          pageTitle: 'Rapporter'
        },
        canActivate: [UserRouteAccessService],
      },
      {
        path: 'viewer/:reportId',
        data: {
          breadcrumb: '$ctx.REPORT_KEY:reportId',
          pageTitle: 'Vis rapport'
        },
        canActivate: [UserRouteAccessService],
        children: [
          {
            path: '',
            component: ReportViewerComponent,
            data: {
              authorities: [AppRole.VK, AppRole.TAKS],
              pageTitle: 'Vis rapport'
            },
            canActivate: [UserRouteAccessService]
          },
          {
            path: 'car-details',
            component: ReportCarDetailsComponent,
            data: {
              authorities: [AppRole.TAKS, AppRole.VK],
              pageTitle: 'Bil detaljer',
              breadcrumb: 'Bil detaljer'
            },
            canActivate: [UserRouteAccessService]
          },
          {
            path: 'deliverynote',
            component: DeliveryNoteReportWrapperComponent,
            data: {
              authorities: [AppRole.TAKS],
              breadcrumb: 'Rediger følgeseddel'
            },
            canActivate: [UserRouteAccessService],
            children: [
              {
                path: '',
                component: EditDeliveryNoteComponent,
                data: {
                  authorities: [AppRole.TAKS],
                  pageTitle: 'Rediger følgeseddel'
                },
                canActivate: [UserRouteAccessService]
              },
              {
                path: 'show',
                component: ShowDeliveryNoteComponent,
                data: {
                  authorities: [AppRole.TAKS],
                  pageTitle: 'Vis følgeseddel',
                  breadcrumb: 'Vis'
                },
                canActivate: [UserRouteAccessService]
              }
            ]
          },
          {
            path: 'new',
            component: NewReportTypeFromReportComponent,
            data: {
              authorities: [AppRole.TAKS],
              pageTitle: 'Ny Rapporttype',
              breadcrumb: 'Ny rapporttype'
            },
            canActivate: [UserRouteAccessService]
          }
        ]
      },
      {
        path: 'diff/:reportId/:vkWidePk/:taksatorWidePk',
        component: ReportDiffComponent,
        data: {
          authorities: [AppRole.VK, AppRole.TAKS],
          pageTitle: 'Sammenlign rapporter'
        },
        canActivate: [UserRouteAccessService]
      }]
  }
];
