import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject, Subscription, timer} from 'rxjs';
import {ClientStateListViewDTO} from '../../draft/dto/client-state-list-view-dto.model';
import {map, tap} from 'rxjs/operators';
import {isNullOrUndefined} from '../utils/object-utils';
import {AppVisibilityService} from './core/app-visibility.service';

@Injectable({
  providedIn: 'root'
})
export class IncomingClientStateService implements OnDestroy {
  private serviceUrl = 'incommingclientstate';
  private data = new Subject<ClientStateListViewDTO[]>();
  private timerObs: Observable<Subscription>;
  private timerSub: Subscription;
  private dtos: ClientStateListViewDTO[] = [];

  constructor(private http: HttpClient, private appVisibilityService: AppVisibilityService) {
    this.timerObs = this.appVisibilityService.forsiVisibilityInterval(0, 5 * 60 * 1000).pipe(
      map(() => this.tick())
    );
  }

  public tick(): Subscription {
    if (this.data.observers.length > 0) {
      return this.getStatus().subscribe(
        data => {
          this.dtos = data;
          this.data.next(data);
          //console.log(data.length + " New drafts has arrived (" + this.data.observers.length+ " watchers)");
        }
      )
    } else {
      //console.log("No listeners. Dont ask");
      return this.timerSub;
    }
  }

  ngOnDestroy(): void {
    this.stop();
  }

  public start(): void {
    if (isNullOrUndefined(this.timerSub) || this.timerSub.closed) {
      this.timerSub = this.timerObs.subscribe();
    }
  }

  public stop(): void {
    if (!isNullOrUndefined(this.timerSub)) {
      this.timerSub.unsubscribe();
      this.timerSub = null;
    }
  }

  public isRunning(): boolean {
    return !isNullOrUndefined(this.timerSub) && !this.timerSub.closed;
  }

  public getData(): Observable<ClientStateListViewDTO[]> {
    timer(100).subscribe(() => {
      this.data.next(this.dtos)
    });
    return this.data.asObservable();
  }

  public accept(list: ClientStateListViewDTO[]): Observable<void> {
    return this.http.post<void>(this.serviceUrl, list.map(t => t.token)).pipe(
      tap(data => {
        this.dtos = [];
        this.data.next([]);
      })
    );
  }

  private getStatus(): Observable<ClientStateListViewDTO[]> {
    return this.http.get<ClientStateListViewDTO[]>(this.serviceUrl);
  }
}
