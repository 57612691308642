import {Routes} from '@angular/router';

import {SettingsComponent} from './';
import {UserRouteAccessService} from 'app/shared/service/auth/user-route-access.service';
import {AppRole} from '../shared/service/auth/app-roles.model';

export const SETTINGS_ROUTES: Routes = [
  {
    path: 'settings',
    component: SettingsComponent,
    data: {
      authorities: [AppRole.VK, AppRole.TAKS, AppRole.SAGSB, AppRole.AFTALEADMIN, AppRole.ONLYADMIN],
      pageTitle: 'Indstillinger',
      breadcrumb: 'Indstillinger'
    },
    canActivate: [UserRouteAccessService],
    children: []
  }
];
