export class VinDetails {
  vinCallId: number;
  vinResult: string;
  vinResultCode: string;
  vinColorCode: string;
  vinFabrikat: string;
  vinStelNr: string;
  vinModel: string;
  vinSubModel: string;
}
