import {BusinessToBusinessDTO} from '../../b2b/model/business-to-business-dto.model';

export class BusinessToBusinessViewModel {
  reportKey: string;
  vkNrLak: string;
  vkNrCabplan: string;
  vkNameLak: string;
  vkNameCabplan: string;
  inputVkNrLak: string;
  inputVkNrCabplan: string;

  constructor(businessToBusinessDTO: BusinessToBusinessDTO) {
    this.reportKey = businessToBusinessDTO.reportKey;
    this.vkNrLak = businessToBusinessDTO.vkNrLak;
    this.vkNrCabplan = businessToBusinessDTO.vkNrCabplan;
    this.vkNameLak = businessToBusinessDTO.vkNameLak;
    this.vkNameCabplan = businessToBusinessDTO.vkNameCabplan;
    this.inputVkNrLak = businessToBusinessDTO.vkNrLak;
    this.inputVkNrCabplan = businessToBusinessDTO.vkNrCabplan;
  }
}
