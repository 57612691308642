<div class="modal-header pre-size header-style" style="text-align: center">
    <h3 class="text-truncate">Godkend bilag</h3>
    <div aria-label="Close" data-dismiss="modal" class="btn-close" (click)="regret()">
    </div>
</div>

<div class="modal-content approve-attachments">
    <div class="modal-body modal-body-style text-center">
        <div class="card mb-4 p-4" *ngFor="let tempAttachment of attachmentApprovalModel.temporaryAttachmentsToApprove">
            <div class="row">
                <div class="col-md-6 col-sm-12">
					<span *ngIf="isImage(tempAttachment)" class="image-wrapper">
						<img class="vehicle-card-right-image w-100" [lazyLoad]="getImageUrl(tempAttachment)"
                             [defaultImage]="defaultImage" [scrollTarget]="getModalElement()"/>
					</span>
                    <span *ngIf="!isImage(tempAttachment)">
						<span class="fa fa-5x file-attachment-icon"
                              [ngClass]="{'fa-file-o': isUploaded(tempAttachment), 'fa-upload file-upload-in-progress': !isUploaded(tempAttachment)}"></span>
					</span>
                </div>
                <div class="col-md-6 col-sm-12">
                    <textarea [(ngModel)]="tempAttachment.remark" class="w-100 h-100"
                              placeholder="Tilføj bemærkning til bilaget" [disabled]="!isUploaded(tempAttachment)"
                              maxlength="500"></textarea>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer">
    <div class="full-width">
        <div class="pull-left">
            <button class="btn btn-primary pull-left" (click)="approve()"
                    [disabled]="!attachmentApprovalModel.readyForApproval || isApproving">
                <lc-spinner-text [spin]="isApproving"></lc-spinner-text>
                Godkend
            </button>
        </div>
        <div class="pull-right">
            <button class="btn pull-right" (click)="regret()"
                    [disabled]="!attachmentApprovalModel.readyForApproval || isApproving">Fortryd
            </button>
        </div>
    </div>
</div>
