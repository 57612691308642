import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ClientStateDetails} from '../../../../model/client-state-details.model';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {FormSaver} from '../../../../../shared/service/core/form-saver.service';
import {NgForm} from '@angular/forms';
import {DateUtils} from '../../../../../shared/utils/date-utils';
import {Validatable} from '../../../ui/validatable.interface';
import {PrincipalService} from '../../../../../shared/service/auth/principal.service';
import {DateService} from '../../../../../shared/service/date.service';

@Component({
  selector: 'lc-draft-damages-dates',
  templateUrl: './draft-damages-dates.component.html',
  styleUrls: ['../../../draft-common.scss']
})
export class DraftDamagesDatesComponent implements Validatable, OnInit {
  skadedatoDP: NgbDateStruct;
  besigtigelsesdatoDP: NgbDateStruct;
  reperationfaerdigdatoDP: NgbDateStruct;

  @Input() formId: string;
  @Input() details: ClientStateDetails;

  @ViewChild('f', {static: true}) public cform: NgForm;

  constructor(private formSaver: FormSaver,
              private principal: PrincipalService,
              public dateService: DateService) {
  }

  ngOnInit(): void {
    this.formSaver.registerForm(this.formId, this.cform);
  }

  isValid(): boolean {
    return this.cform.form.valid;
  }

  pastOrPresentMaxDateSelection(): any {
    return DateUtils.getNgbDateStructToday();
  }

  fiveYearsPriorMinDateSelection(): any {
    const date = DateUtils.getNgbDateStructToday();
    date.year = date.year - 5;
    return date;
  }

  inspectionDateRequired(): boolean {
    return this.principal.isVK();
  }
}
