export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}
export interface AutoflexSparePartSortOrder {
  attribute: string;
  sortOrder: number;
  sortAttribute: any;
  direction: SortDirection;
}
