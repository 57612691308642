import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {FormSaver} from '../../../../shared/service/core/form-saver.service';
import {SkadeTypeDTO} from '../../../dto/skade-type-dto.model';
import {DamageDescription} from '../../../model/damage-description.model';
import {SkadeTypeService} from '../../../../shared/service/skadetype.service';
import {ObjectUtils} from '../../../../shared/utils/object-utils';
import {StringUtils} from '../../../../shared/utils/string-utils';
import {DamageZoneDTO} from '../../../../shared/dto/damage-zone-dto.model';
import {Validatable} from '../validatable.interface';
import {ClientStateDetails} from '../../../model/client-state-details.model';
import {combineLatest, Subject} from 'rxjs';
import {CompanyDetailsDTO} from '../../../dto/company-details-dto.model';
import {DraftEditService} from '../../draft-edit.service';
import {PrincipalService} from '../../../../shared';
import {SkadeKodeDTO} from 'app/shared/dto/skade-kode-dto.model';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'lc-draft-damagedescription',
  templateUrl: './draft-damage-description.component.html',
  styleUrls: ['../../../../shared/ui/multiselect/multiselect-item.scss', '../../draft-common.scss']
})

export class DraftDamageDescriptionComponent implements Validatable, OnInit, OnChanges, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  @Input() formId: string;
  @Input() details: ClientStateDetails;
  @Input() damageDescription: DamageDescription;
  @Input() selskab: string;
  @Input() hideSkadekode = false;
  @ViewChild('f', {static: true}) public cform: NgForm;
  allSkadeTyper: SkadeTypeDTO[];
  possibleCompanyDetails: CompanyDetailsDTO[] = [];
  selectedCompany: CompanyDetailsDTO = null;
  selectedSkadekode: SkadeKodeDTO = null;
  isLoading = true;

  damageZone = DamageZoneDTO;

  constructor(private formSaver: FormSaver,
              private principalService: PrincipalService,
              private draftEditService: DraftEditService,
              private skadeTypeService: SkadeTypeService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const selskabChanged = ObjectUtils.exists(changes['selskab']);
    if (selskabChanged && this.allSkadeTyper) {
      const foundSkadeType = this.getSkadeTyper().find(value => value.name === this.damageDescription.skadeType);
      if (!ObjectUtils.exists(foundSkadeType)) {
        this.damageDescription.skadeType = null;
      }
    }
    if (selskabChanged) {
      if (!this.isLoading) {
        this.details.skadekode = '';
        this.selectedCompany = this.possibleCompanyDetails.find(s => s.code === this.details.selskab);
      }
    }
  }

  ngOnInit(): void {
    this.formSaver.registerForm(this.formId, this.cform);
    const self = this;
    const companyDetailsObs = this.draftEditService.getCompanyDetailsObservable();
    const skadetyperObs = this.skadeTypeService.getSkadetyper();

    combineLatest([companyDetailsObs, skadetyperObs])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([companyDetails, skadeTyper]) => {
        this.possibleCompanyDetails = companyDetails;
        self.allSkadeTyper = skadeTyper;

        if (this.details.selskab) {
          this.selectedCompany = this.possibleCompanyDetails.find(s => s.code === this.details.selskab);
          if (this.selectedCompany) {
            this.selectedSkadekode = this.selectedCompany.skadekoder.find(s => s.kode === this.details.skadekode);
          }
        } else {
          this.selectedCompany = null;
        }
        this.isLoading = false;
      });
  }

  getSkadeTyper(): SkadeTypeDTO[] {
    if (ObjectUtils.exists(this.allSkadeTyper)) {
      return this.allSkadeTyper.filter(skadetype => StringUtils.isEmpty(skadetype.onlyForSelskab) || skadetype.onlyForSelskab === this.selskab);
    } else {
      return null;
    }
  }

  isValid(): boolean {
    if (this.damageDescription) {
      return this.cform.form.valid && this.damageDescription.zoneIsValid();
    } else {
      return false;
    }
  }

  isSkadekodeValid(): boolean {
    if (this.principalService.isInOrganization('TR')) {
      return this.damageDescription.skadeType !== 'GLASSKADE';
    } else {
      return true;
    }
  }

  onSelectSkadekode(): void {
    if (this.selectedSkadekode) {
      this.details.skadekode = this.selectedSkadekode.kode;
    } else {
      this.details.skadekode = null;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  onDamageTypeChanged(): void {
    //Call save instantly in order to trigger possible agreements changes
    this.formSaver.save(() => {}, () => {} );
  }
}
