import {Flow2CarDetails} from './flowtwo-car-details.model';
import {KrDetailsDTO} from '../../shared/dto/kr-details-dto.model';

export class KrDetails extends Flow2CarDetails {
  selskab: string;

  static fromDTO(dto: KrDetailsDTO): KrDetails {
    const details = new KrDetails();
    if (dto) {
      details.selskab = dto.selskab;
      details.fabrikatKode = dto.fabrikatKode;
      details.fabrikatText = dto.fabrikatText;
      details.modelKode = dto.modelKode;
      details.modelText = dto.modelText;
    }
    return details;
  }

  toDTO(): KrDetailsDTO {
    const dto = new KrDetailsDTO();
    dto.selskab = this.selskab;
    dto.fabrikatKode = this.fabrikatKode;
    dto.fabrikatText = this.fabrikatText;
    dto.modelKode = this.modelKode;
    dto.modelText = this.modelText;
    return dto;
  }
}
