import {DOCUMENT, Location} from '@angular/common';
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRouteSnapshot, NavigationEnd, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {PrincipalService} from '../../shared';
import {VersionCheckService} from '../../shared/service/version-check.service';
import {environment} from '../../../environments/environment';
import {Subject} from 'rxjs';
import {distinctUntilChanged, filter, map, takeUntil} from 'rxjs/operators';
import {
  ConfirmButtonType,
  ConfirmPopupButton,
  ConfirmPopupComponent
} from '../../shared/modals/confirm/confirm-popup.component';
import {ApplicationKeyPendingChecker} from '../../shared/service/application-key-pending-checker.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ScreenSize, ScreenSizeService} from '../../shared/ui/screen-size/screen-size.service';
import {NotificationService} from '../../shared/service/notification.service';
import EnvironmentUtils from '../../shared/utils/environment-utils';
import {B2CLoginRequestedChecker} from '../../shared/service/b2c-login-requested-checker.service';
import {B2CSignupFlowPopupComponent} from '../b2c-signup-flow/b2c-signup-flow-popup/b2-c-signup-flow-popup.component';
import {B2CLoginStatus} from '../../shared/service/auth/b2c-login-status.model';
import {
  B2CPostponedReminderPopupComponent
} from '../b2c-signup-flow/b2c-postponed-reminder-popup/b2c-postponed-reminder-popup.component';
import {SignupPopup} from '../b2c-signup-flow/signup.popup';

@Component({
  selector: 'lc-app-main',
  templateUrl: './main.component.html',
  styleUrls: [
    './main.scss'
  ], providers: [{provide: Window, useValue: window}]
})
export class MainComponent implements OnInit, OnDestroy {

  get sidebarHidden(): boolean {
    return this._sidebarHidden || !this.showNavigationMenu();
  }

  private unsubscribe$ = new Subject<void>();
  private stopPollingForB2CLogin$ = new Subject<void>();
  isVersionUpdated = false;
  versionTimestamp = environment.version;
  private currentApplicationKeyPendingPopup: NgbModalRef;
  private _sidebarHidden = false;
  enableTransition = false;

  constructor(private titleService: Title,
              private router: Router,
              private location: Location,
              private principal: PrincipalService,
              private versionCheckService: VersionCheckService,
              public notificationService: NotificationService,
              private modalService: NgbModal,
              private applicationKeyPendingPoller: ApplicationKeyPendingChecker,
              private b2CLoginRequestedChecker: B2CLoginRequestedChecker,
              private screenSizeService: ScreenSizeService,
              private window: Window,
              @Inject(DOCUMENT) private document: Document) {
    //We do this in order to load the pdf.worker.min.js from our server (Content Security Policy CSP)
    //The pdf.worker.min.js is copied in angular.json - ../architect/build/options/assets/..
    (window as any).pdfWorkerSrc = (EnvironmentUtils.isEap() ? '/eap/' : '/') + 'content/js/pdf.worker.min.js';

    this.versionTimestamp = environment.version;
    this._sidebarHidden = false;
  }

  private getPageTitle(routeSnapshot: ActivatedRouteSnapshot): string {
    let title: string = (routeSnapshot.data && routeSnapshot.data['pageTitle']) ? routeSnapshot.data['pageTitle'] : 'lightclientApp';
    if (routeSnapshot.firstChild) {
      title = this.getPageTitle(routeSnapshot.firstChild) || title;
    }
    return title;
  }

  ngOnInit(): void {
    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.titleService.setTitle(this.getPageTitle(this.router.routerState.snapshot.root));
      }
    });

    //show/hide menu when switching between XL size
    this.screenSizeService.size$.pipe(
      map(s => ScreenSize.XL.eq(s)),
      distinctUntilChanged(),
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      this.toggleSidebarMenu();
    });

    this.versionCheckService.startVersionCheck();
    this.versionCheckService.versionUpdatedSub
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(versionUpdated => this.isVersionUpdated = versionUpdated);

    this.b2CLoginRequestedChecker.startListenForPendingB2CRequests$().pipe(
      filter(() => !this.modalService.hasOpenModals()),
      takeUntil(this.stopPollingForB2CLogin$),
      takeUntil(this.unsubscribe$)
    ).subscribe(b2cUser => {
      if (b2cUser && b2cUser.actionRequired && !b2cUser.disabled) {
        let possibleSignupFlowPopupComponent = null;
        switch (b2cUser.status) {
          case B2CLoginStatus.REQUESTED:
          case B2CLoginStatus.POSTPONED:
            possibleSignupFlowPopupComponent = B2CSignupFlowPopupComponent;
            break;
          case B2CLoginStatus.IN_PROGRESS:
            possibleSignupFlowPopupComponent = B2CPostponedReminderPopupComponent;
            break;
        }
        if (possibleSignupFlowPopupComponent) {
          const modalRef = this.modalService.open(possibleSignupFlowPopupComponent, {
            backdrop: 'static',
            keyboard: false
          });
          const componentInstance = modalRef.componentInstance as SignupPopup;
          componentInstance.b2cUser = b2cUser
        }
      }
      if (!b2cUser || B2CLoginStatus.ACTIVATED === b2cUser.status || b2cUser.disabled) {
        this.stopPollingForB2CLogin$.next();
      }
    });

    this.applicationKeyPendingPoller.listenForPendingApplicationKeys$().pipe(takeUntil(this.unsubscribe$)).subscribe(numberOfPending => {
      if (numberOfPending > 0) {
        if (this.currentApplicationKeyPendingPopup != null) {
          this.currentApplicationKeyPendingPopup.dismiss();
        }
        this.currentApplicationKeyPendingPopup = this.modalService.open(ConfirmPopupComponent);
        const componentInstance = this.currentApplicationKeyPendingPopup.componentInstance;
        componentInstance.title = 'Applicationsnøgler afventer godkendelse';
        const newWord = numberOfPending > 1 ? ' nye' : ' ny';
        const appKeyWord = numberOfPending > 1 ? ' Applikationsnøgler' : ' Applikationsnøgle';
        const userWord = numberOfPending > 1 ? ' Brugerene ' : ' Brugeren ';
        componentInstance.body = 'Der er oprettet ' + numberOfPending + newWord + appKeyWord + ' der afventer Forsikring & Pensions godkendelse.<br/><br/>' +
          userWord + 'der ønsker adgang til vores REST api, kan <b>ikke</b> få adgang før vi har godkendt ansøgningen.';
        componentInstance.customFirstButton = new ConfirmPopupButton('Gå til administration', ConfirmButtonType.CONFIRM, 'btn-primary');
        componentInstance.customSecondButton = new ConfirmPopupButton('Senere', ConfirmButtonType.DECLINE, 'btn-danger');
        this.currentApplicationKeyPendingPopup.result.then((confirm: boolean) => {
          if (confirm) {
            this.router.navigate(['sysadmin', 'stamdata', 'application-key']);
          }
        }, () => {
          //dismissed popup
        });
      }
    });

    let transitionTimer;
    this.principal.getAuthenticationState().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(principal => {
      //We enable and disable transitions on login and logout in order to prevent animations on the initial rendering
      if (principal) {
        transitionTimer = setTimeout(() => {
          this.enableTransition = true
        }, 2000);
      } else {
        clearTimeout(transitionTimer);
        this.enableTransition = false
      }
    });

    this.principal.getAuthenticationState().pipe(takeUntil(this.unsubscribe$)).subscribe(principal => {
      if (principal) {
        this.notificationService.startNotificationUpdateCheck();
      } else {
        this.notificationService.stopNotificationUpdateCheck();
      }
    });

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  showNavigationMenu(): boolean {
    return this.principal.isAuthenticated() && this.presentNavigationBarForCurrentRoute();
  }

  /**
   * Decide if current location should have menu presented
   *
   * @returns {boolean}
   */
  presentNavigationBarForCurrentRoute(): boolean {
    const routesNamesWithNoNavigationBar = ['/error', '/product-recycle'];
    for (const routeName of routesNamesWithNoNavigationBar) {
      const currentPath = this.location.path(false);
      if (currentPath && currentPath.startsWith(routeName)) {
        return false;
      }
    }
    return true;
  }

  reload(): void {
    window.location.reload();
  }

  public toggleSidebarMenu(close?: boolean): void {
    const scrollbarWidth = this.getScrollBarWidth(this.window, this.document.body)
    if (this.screenSizeService.size.lessThan(ScreenSize.XL)) {
      this.enableTransition = true;
      this._sidebarHidden = close || !this._sidebarHidden;
      if (this._sidebarHidden) {
        this.document.body.classList.remove('menu-open')
        this.document.body.style.paddingRight = '0px';

      } else {
        this.document.body.classList.add('menu-open');
        this.document.body.style.paddingRight = scrollbarWidth + 'px';
      }
    } else {
      this._sidebarHidden = false;
      this.document.body.classList.remove('menu-open')
      this.document.body.style.paddingRight = '0px';
    }

  }

  private getScrollBarWidth(window: Window, body: HTMLElement): number {
    return window.innerWidth - body.offsetWidth;
  }
}
