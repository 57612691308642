import {Component, OnDestroy} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import URLBuilder from '../../../shared/utils/url-builder';
import {TemporaryAttachmentApprovalModel} from '../../../shared/model/temporary-attachment-approval.model';
import {TemporaryAttachmentDTO} from '../../../shared/dto/temporary-attachment-dto.model';
import {Observable, of, Subject} from 'rxjs';
import {mergeMap, takeUntil} from 'rxjs/operators';
import {ReportService} from '../../service/report.service';
import {isNullOrUndefined} from '../../../shared/utils/object-utils';
import {ReportVideoRequestService} from '../../../shared/ui/attachment/video-request/report-video-request.service';
import {PrincipalService} from '../../../shared';

@Component({
  templateUrl: './attachment-approval.component.html',
  styleUrls: ['./attachment-approval.component.scss']
})
export class AttachmentApprovalModalComponent implements OnDestroy {
  private unsubscribe$ = new Subject<void>();
  defaultImage = URLBuilder.basePath + 'content/images/defaultImage.png';
  attachmentsApprovedOnReportId: number;
  reportKey: string;
  private modalScrollElement: Element;
  attachmentApprovalModel: TemporaryAttachmentApprovalModel;
  isApproving: boolean;

  constructor(public activeModal: NgbActiveModal,
              private reportVideoRequestService: ReportVideoRequestService,
              private principalService: PrincipalService,
              private reportService: ReportService) {
    this.modalScrollElement = null;
  }

  initializeAttachmentsForApproval(attachmentsApprovedOnReportId: number, vkReportKey: string, taksReportKey: string, temporaryAttachmentApprovalModel: TemporaryAttachmentApprovalModel): void {
    this.attachmentsApprovedOnReportId = attachmentsApprovedOnReportId;
    this.reportKey = !isNullOrUndefined(taksReportKey) ? taksReportKey : vkReportKey;
    this.attachmentApprovalModel = temporaryAttachmentApprovalModel;
    this.isApproving = false;
  }

  approve(): void {
    this.isApproving = true;
    this.reportService.approveAttachments(this.reportKey, this.attachmentApprovalModel).pipe(
      mergeMap(approvedAttachments => {
        let videoRequest$: Observable<any>;
        if (this.principalService.isVK()) {
          //in case attachments are approved by a workshop, we need to update any possible video requests updates
          videoRequest$ = this.reportVideoRequestService.getVideoRequestByWorkshopReportId(this.attachmentsApprovedOnReportId);
          return videoRequest$.pipe(mergeMap(() => of(approvedAttachments)))
        } else {
          return of(approvedAttachments);
        }
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe((approvedAttachments) => {
        this.isApproving = true;
        this.activeModal.close(true);
        this.attachmentApprovalModel.attachmentsApproved(approvedAttachments);
      }, (error) => {
        this.isApproving = false;
      });
  }

  getModalElement(): Element {
    if (isNullOrUndefined(this.modalScrollElement)) {
      this.modalScrollElement = document.getElementsByTagName('ngb-modal-window')[0];
    }
    return this.modalScrollElement;
  }

  regret(): void {
    this.activeModal.close(false);
  }

  public isUploaded(temporaryDocument: TemporaryAttachmentDTO): boolean {
    return temporaryDocument.contentType !== 'file/uploading';
  }

  public isImage(temporaryDocument: TemporaryAttachmentDTO): boolean {
    return temporaryDocument && temporaryDocument.contentType && temporaryDocument.contentType.startsWith('image');
  }

  getImageUrl(temporaryDocument: TemporaryAttachmentDTO): string {
    const imageUrl = this.attachmentApprovalModel.getImageUrl(temporaryDocument);
    return imageUrl;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
