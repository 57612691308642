import {Component, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {UserB2CLoginDTO} from '../../../shared/service/auth/user-b2c-login-dto.model';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {SignupPopup} from '../signup.popup';
import {PrincipalService} from '../../../shared';
import {BootstrapAlertType, BootstrapGrowlService} from '../../../shared/ui/ngx-bootstrap-growl';
import {takeUntil} from 'rxjs/operators';

enum PostbonedReminderState {
  RESEND_EMAIL = 'RESEND_EMAIL',
  CHANGE_EMAIL = 'CHANGE_EMAIL'
}

@Component({
  templateUrl: './b2c-postponed-reminder-popup.component.html'
})
export class B2CPostponedReminderPopupComponent implements OnDestroy, SignupPopup {
  private unsubscribe$ = new Subject<void>();
  public b2cUser: UserB2CLoginDTO;
  public reminderState = PostbonedReminderState.RESEND_EMAIL;
  public newMailAddress = '';

  constructor(private activeModal: NgbActiveModal,
              private principalService: PrincipalService,
              private bootstrapGrowlService: BootstrapGrowlService,
              ) {
  }

  resendActivationEmail(): void {
    this.principalService.resendActivationMailB2CFlow().pipe(takeUntil(this.unsubscribe$)).subscribe(
      {
        next: () => {
          this.bootstrapGrowlService.addAlert('Aktiverings email er gensendt', BootstrapAlertType.SUCCESS, 5000, true);
          this.activeModal.dismiss();
        },
        error: () => {
          this.bootstrapGrowlService.addAlert('Aktiverings email kunne ikke gensendes', BootstrapAlertType.DANGER, 5000, true);
          this.activeModal.dismiss();
        }
      }
    );
  }

  changeEmailAddress(): void {
    if (!this.newMailAddress) {
      return;
    }
    this.principalService.changeActivationMailB2CFlow(this.newMailAddress).pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: () => {
        this.bootstrapGrowlService.addAlert('Mailadressen er skiftet og ny aktiverings mail er sendt', BootstrapAlertType.SUCCESS, 5000, true);
        this.activeModal.dismiss();
      }
    })
  }

  goToChangeEmailState(): void {
    this.reminderState = PostbonedReminderState.CHANGE_EMAIL;
  }

  goToResendEmailState(): void {
    this.newMailAddress = '';
    this.reminderState = PostbonedReminderState.RESEND_EMAIL;
  }

  public inResendEmailState(): boolean {
    return this.reminderState === PostbonedReminderState.RESEND_EMAIL;
  }

  public inChangeEmailState(): boolean {
    return this.reminderState === PostbonedReminderState.CHANGE_EMAIL;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
