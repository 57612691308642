<fieldset ngForm="f" #f="ngForm">
    <h2>Budgivere</h2>
    <div class="card">
        <div class="card-body">
            <div class="row">
                <!-- Left part -->
                <div class="col-12 col-md-6">
                    <div class="row pb-3">
                        <label class="col-form-label col-xs-12 col-sm-2" for="budgiver1">Bud 1</label>
                        <div class="col-xs-12 col-sm-3">
                            <input (change)="onVkNrChange('1')" [(ngModel)]="erGbDetails.budgiver1"
                                class="form-control"
                                type="text" id="budgiver1" name="budgiver1"
                                minlength=0 maxlength=5 size="5"
                                lcInputMask="vkNr"/>
                        </div>
                        <div class="col-xs-12 col-sm-7">
                            <div class="input-group pe-0 ps-0 ps-sm-2">
                                <input [(ngModel)]="erGbDetails.bud1"
                                    class="form-control"
                                    type="text" id="bud1" name="bud1"
                                    minlength=0 maxlength=9 size="9"
                                    lcInputMask="numbersOnly"/>
                                <div class="input-group-text">Kr.</div>
                            </div>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <label class="col-form-label col-xs-12 col-sm-2" for="budgiver2">Bud 2</label>
                        <div class="col-xs-12 col-sm-3">
                            <input (change)="onVkNrChange('2')" [(ngModel)]="erGbDetails.budgiver2"
                                class="form-control"
                                type="text" id="budgiver2" name="budgiver2"
                                minlength=0 maxlength=5 size="5"
                                lcInputMask="vkNr"/>
                        </div>
                        <div class="col-xs-12 col-sm-7">
                            <div class="input-group  pe-0 ps-0 ps-sm-2">
                                <input [(ngModel)]="erGbDetails.bud2"
                                    class="form-control"
                                    type="text" id="bud2" name="bud2"
                                    minlength=0 maxlength=9 size="9"
                                    lcInputMask="numbersOnly"/>
                                <div class="input-group-text">Kr.</div>
                            </div>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <label class="col-form-label col-xs-12 col-sm-2" for="budgiver3">Bud 3</label>
                        <div class="col-xs-12 col-sm-3">
                            <input (change)="onVkNrChange('3')" [(ngModel)]="erGbDetails.budgiver3"
                                class="form-control"
                                type="text" id="budgiver3" name="budgiver3"
                                minlength=0 maxlength=5 size="5"
                                lcInputMask="vkNr"/>
                        </div>
                        <div class="col-xs-12 col-sm-7">
                            <div class="input-group pe-0 ps-0 ps-sm-2">
                                <input [(ngModel)]="erGbDetails.bud3"
                                    class="form-control"
                                    type="text" id="bud3" name="bud3"
                                    minlength=0 maxlength=9 size="9"
                                    lcInputMask="numbersOnly"/>
                                <div class="input-group-text">Kr.</div>
                            </div>
                        </div>
                        <div class="col-2"></div>
                    </div>
                    <div class="row">
                        <div class="btn-group col-12">
                            <input type="radio" class="btn-check" name="budgiverSolgtTil" value="1" id="solgttil1" autocomplete="off" [(ngModel)]="erGbDetails.budgiverSolgtTil"/>
                            <label class="btn btn-secondary btn-sm" for="solgttil1">Solgt <span
                                class="d-none d-md-inline">til</span> budgiver 1</label>
                            <input type="radio" class="btn-check" name="budgiverSolgtTil" value="2" id="solgttil2" autocomplete="off" [(ngModel)]="erGbDetails.budgiverSolgtTil"/>
                            <label class="btn btn-secondary btn-sm" for="solgttil2">Solgt <span
                                class="d-none d-md-inline">til</span> budgiver 2</label>
                            <input type="radio" class="btn-check" name="budgiverSolgtTil" value="3" id="solgttil3" autocomplete="off" [(ngModel)]="erGbDetails.budgiverSolgtTil"/>
                            <label class="btn btn-secondary btn-sm" for="solgttil3">Solgt <span
                                class="d-none d-md-inline">til</span> budgiver 3</label>
                        </div>
                        <div class="btn-group col-12 mb-3 pt-1">
                            <input type="radio" class="btn-check" name="budgiverSolgtTil" value="" id="ikkesolgt" autocomplete="off" [(ngModel)]="erGbDetails.budgiverSolgtTil"/>
                            <label class="btn btn-secondary btn-sm" for="ikkesolgt">Ikke solgt</label>
                        </div>
                    </div>
                    <div *ngIf="showBidderRemark()" class="row mb-3">
                        <div class="col-12 bg-warning">Bemærk: Restbeløb på hovedrapport under værdisætning er:
                            {{resterBeloeb() | currency:'DKK' }}
                        </div>
                    </div>
                </div>
                <!-- right part -->
                <div class="col-12 col-md-6">
                    <div class="row pb-3">
                        <label class="col-form-label col-4">Navn</label>
                        <div class="col-8">
                            <input class="form-control"
                                [(ngModel)]="erGbDetails.budgiverNavn"
                                type="text" id="navn" name="navn" maxlength="30" [readonly]="disableBidderInfoFields()"
                            />
                        </div>
                    </div>
                    <div class="row pb-3">
                        <label class="col-form-label col-4">Adresse</label>
                        <div class="col-8">
                            <input class="form-control"
                                [(ngModel)]="erGbDetails.budgiverAdresse1"
                                type="text" id="budgiverAdresse1" name="budgiverAdresse1"
                                [readonly]="disableBidderInfoFields()"
                            />
                        </div>
                    </div>
                    <div class="row pb-3">
                        <label class="col-form-label col-4"></label>
                        <div class="col-8">
                            <input class="form-control"
                                [(ngModel)]="erGbDetails.budgiverAdresse2"
                                type="text" id="budgiverAdresse2" name="budgiverAdresse2"
                                [readonly]="disableBidderInfoFields()"
                            />
                        </div>
                    </div>
                    <div class="row pb-3">
                        <label class="col-form-label col-4">Postnr</label>
                        <div class="col-8">
                            <input class="form-control"
                                [(ngModel)]="erGbDetails.budgiverPostNr"
                                type="tel" id="budgiverPostNr" name="budgiverPostNr"
                                minlength=1 size="4" maxlength="4" lcInputMask="int4"
                                [readonly]="disableBidderInfoFields()"
                                (change)="updateCityByPostalCode()"
                            />
                        </div>
                    </div>

                    <div class="row pb-3">
                        <label class="col-form-label col-4">By</label>
                        <div class="col-8">
                            <input class="form-control" [(ngModel)]="by" [readonly]="true" type="text" id="by"
                                name="by"
                            />
                        </div>
                    </div>

                    <div class="row pb-3">
                        <label class="col-form-label col-4">Telefon</label>
                        <div class="col-8">
                            <input class="form-control"
                                [(ngModel)]="erGbDetails.budgiverTelefon"
                                type=tel id="budgiverTelefon" name="budgiverTelefon"
                                size="8" maxlength="8" lcInputMask="int8" [readonly]="disableBidderInfoFields()"/>
                        </div>
                    </div>
                    <div class="row" *ngIf="hasUnknownBidder()">
                        <div class="col-12">
                            <span class="pull-right">Udfyld venligst oplysninger på ***** budgiver ovenfor.</span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</fieldset>
