import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ForsiReportKeyPipe} from '../../../shared/pipe/forsi-report-key.pipe';
import {ForsiReportKeyShortPipe} from '../../../shared/pipe/forsi-report-key-short.pipe';
import {LcActionMenu, LcActionMenuItem} from '../../../shared/ui/bottombar/lc-action-menu.model';
import {combineLatest, Subject} from 'rxjs';
import {DraftService} from '../../service/draft.service';
import {DraftEditService} from '../draft-edit.service';
import {ActivatedRoute} from '@angular/router';
import {mergeMap, takeUntil} from 'rxjs/operators';
import {ReportDiffResult} from '../../../report/dto/report-diff-result-dto.model';
import {StringUtils} from '../../../shared/utils/string-utils';
import {ReportCategory} from '../../../shared/model/report-category.model';
import {BootstrapAlertType, BootstrapGrowlService} from '../../../shared/ui/ngx-bootstrap-growl';
import {ClientNavigationService} from '../../../shared/service/client-navigation.service';
import {PrintDiffViewerComponent} from '../../../shared/ui/print-diff-viewer/print-diff-viewer.component';

@Component({
  templateUrl: './draft-diff.component.html',
  styleUrls: []
})
export class DraftDiffComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  @ViewChild('diff') diffView: PrintDiffViewerComponent;
  draftMenu: LcActionMenu;
  reportDiffResult: ReportDiffResult;
  loading = true;
  menuIconClass: string;
  menuBottomTitle: string;
  menuBottomShortTitle: string;

  constructor(private draftEditService: DraftEditService,
              private route: ActivatedRoute,
              private draftService: DraftService,
              private reportKeyPipe: ForsiReportKeyPipe,
              private reportKeyShortPipe: ForsiReportKeyShortPipe,
              private bootstrapGrowl: BootstrapGrowlService,
              private navigationService: ClientNavigationService) {
  }

  ngOnInit(): void {
    this.draftMenu = new LcActionMenu();
    this.setupObservables();
    this.setupMenus();
  }

  private setupObservables(): void {
    const paramsObserver = {
      next: ([clientState, reportDiffResult]) => {
        this.reportDiffResult = reportDiffResult;
        const reportKey = !StringUtils.isEmpty(clientState.taksRapportKey) ? clientState.taksRapportKey : clientState.proformaOrdreNummer;
        this.menuBottomTitle = this.reportKeyPipe.transform(reportKey);
        this.menuBottomShortTitle = this.reportKeyShortPipe.transform(reportKey);
        this.menuIconClass = ReportCategory.iconCssClass(clientState.schema);
        this.loading = false;
      },
      error: error => {
        console.log('error', error);
        this.bootstrapGrowl.addAlert('Kunne ikke lave sammenligning med tilhørende værkstedsrapport', BootstrapAlertType.WARNING, 3000);
        this.loading = false;
      }
    };
    //collect all subscriptions to be able to unsubscribe upon destroy
    this.route.parent.params.pipe(mergeMap(params => {
      const token = params['token'];
      return combineLatest([this.draftEditService.getClientStateObservable(token), this.draftService.diff(token)]);
    }), takeUntil(this.unsubscribe$)).subscribe(paramsObserver);
  }

  setupMenus(): void {
    const backMenuItem = new LcActionMenuItem('fa-arrow-left', () => {
      this.navigationService.back(['draft']);
    }, 'Tilbage');
    this.draftMenu.addItem(backMenuItem);
    const diffMenuItem = new LcActionMenuItem('fa-arrows-h', () => {
      this.diffView.switchActivated();
    }, 'Skift');
    this.draftMenu.addItem(diffMenuItem);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
