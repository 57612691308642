import {VinDetails} from '../model/vin-details.model';
import {isNullOrUndefined} from '../utils/object-utils';

export class VinDetailsDTO {
  private static TIMEOUT_CODE = 'HYSTRIX_TIMEOUT';

  vinCallId: number;
  vinResult: string;
  vinResultCode: string;
  vinColorCode: string;
  vinFabrikat: string;
  vinStelNr: string;
  vinModel: string;
  vinSubModel: string;
  vinValid: boolean;

  public static createPossibleVinDetailsDTO(vinDetails: VinDetails): VinDetailsDTO {
    let result: VinDetailsDTO = null;
    if (vinDetails !== null && vinDetails !== undefined) {
      result = new VinDetailsDTO();
      result.vinCallId = vinDetails.vinCallId;
      result.vinResult = vinDetails.vinResult;
      result.vinResultCode = vinDetails.vinResultCode;
      result.vinColorCode = vinDetails.vinColorCode;
      result.vinFabrikat = vinDetails.vinFabrikat;
      result.vinStelNr = vinDetails.vinStelNr;
      result.vinModel = vinDetails.vinModel;
      result.vinSubModel = vinDetails.vinSubModel;
    }
    return result;
  }

  public static createPossibleVinDetails(vinDetailsDTO: VinDetailsDTO): VinDetails {
    let result: VinDetails = null;
    if (vinDetailsDTO !== null && vinDetailsDTO !== undefined) {
      result = new VinDetails();
      result.vinCallId = vinDetailsDTO.vinCallId;
      result.vinResult = vinDetailsDTO.vinResult;
      result.vinResultCode = vinDetailsDTO.vinResultCode;
      result.vinColorCode = vinDetailsDTO.vinColorCode;
      result.vinFabrikat = vinDetailsDTO.vinFabrikat;
      result.vinStelNr = vinDetailsDTO.vinStelNr;
      result.vinModel = vinDetailsDTO.vinModel;
      result.vinSubModel = vinDetailsDTO.vinSubModel;
    }
    return result;
  }

  public static vinLookupTimedOut(vinDetailsDTO: VinDetailsDTO): boolean {
    return !isNullOrUndefined(vinDetailsDTO) ? vinDetailsDTO.vinResultCode === this.TIMEOUT_CODE : false;
  }
}
