<div class="modal-header pre-size header-style" style="text-align: center">
    <h3 class="text-truncate">
        {{title}}
    </h3>
</div>

<div class="modal-content">
    <input class="d-none">
    <div class="modal-body modal-body-style">
        <div class="row">
            <div class="col">
                Downloader: <span class="pull-right" *ngIf="downloadFailed > 0">({{downloadFailed}} Fejlet)</span>
                <ngb-progressbar [type]="downloadFailed > 0 ? 'danger' : 'success'" [value]="downloadTotal-downloadWaiting" [max]="downloadTotal" striped="true" showValue="true"></ngb-progressbar>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col">
                Forbereder zip-fil:
                <ngb-progressbar type="success" [value]="packagePct" striped="true" showValue="true"></ngb-progressbar>
            </div>
        </div>
        <div *ngIf="finished" class="row mt-2">
            <div class="col">
                Status:
                <ul>
                    <li *ngIf="downloadFailed > 0">{{downloadFailed}} Fejlet download(s)</li>
                    <li>{{downloadTotal - downloadFailed}} Succesfuldt downloadet</li>
                    <li *ngIf="downloadTotal-downloadFailed > 0">{{zipFileName}} gemt i "Downloads"</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer">
        <div class="pull-right">
            <button *ngIf="!finished" class="btn btn-danger"
                    (click)="close()">Fortryd
            </button>
            <button *ngIf="finished" class="btn btn-primary"
                    (click)="close()">Luk
            </button>
        </div>
</div>
