import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {FunctionConfigurationDTO} from '../../settings/model/function-configuration-dto.model';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {ReportCategory} from '../model/report-category.model';

@Injectable({
  providedIn: 'root',
})
export class ClientConfigService {
  private functionUrl = 'clientconfig/function';

  constructor(private http: HttpClient) {
  }

  public reportCategories$(): Observable<ReportCategory[]> {
    return this.http.get<FunctionConfigurationDTO>(this.functionUrl).pipe(map(functionConfig => this.mapToReportCategory(functionConfig)));
  }

  public mapToReportCategory(functionConfig: FunctionConfigurationDTO): ReportCategory[] {
    const result: ReportCategory[] = [];
    if (functionConfig.autotaksSelected) {
      result.push(ReportCategory.AUTOTAKS);
    }
    if (functionConfig.skavutaksSelected) {
      result.push(ReportCategory.SKAVUTAKS);
    }
    if (functionConfig.glastaksSelected) {
      result.push(ReportCategory.GLASTAKS);
    }
    if (functionConfig.stortaksSelected) {
      result.push(ReportCategory.STORTAKS);
    }
    if (functionConfig.mctaksSelected) {
      result.push(ReportCategory.MCTAKS);
    }
    if (functionConfig.camptaksSelected) {
      result.push(ReportCategory.CAMPTAKS);
    }
    return result;
  }
}
