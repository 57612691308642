import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {FeatureService} from '../service/feature.service';

@Directive({
  selector: '[lcFeatureToggle]'
})
export class FeatureToggleDirective {

  constructor(private featureService: FeatureService, private templateRef: TemplateRef<any>, private viewContainerRef: ViewContainerRef) {
  }

  @Input()
  set lcFeatureToggle(feature: string) {
    if (this.featureService.hasFeature(feature)) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainerRef.clear();
    }
  }
}
