<div class="card mb-2">
    <div class="card-body">
        <div class="row">
            <div class="col-12 col-sm-4 col-md-3 col-xl-2 p-2" *ngFor="let reportType of reportTypeViewModel.types">
                <button class="btn btn-outline-primary w-100 text-start" (click)="onReportTypeChanged(reportType)" >
                    <i class="fa fa-2x align-middle" [ngClass]="fontAwsomeIconClass(reportType)"
                       aria-hidden="true"></i><span class="align-middle ps-1 itemText">{{reportType.name}}</span>
                </button>
            </div>
        </div>
    </div>
</div>
