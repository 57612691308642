/**
 * Remember to update the UserState Pipe with a a human readable description of the state
 */
export enum UserState {
  OK = 'OK',
  LUKKET = 'LUKKET',
  SLETTET = 'SLETTET',
  OPSAGT = 'OPSAGT'
}

export class UserStates {
  public static readonly ALL = [UserState.OK, UserState.LUKKET, UserState.OPSAGT, UserState.SLETTET];
}
