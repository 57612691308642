import {SkavuPositionValueDTO} from './skavu-position-value-dto.model';

export class SkavuDetailsDTO {
  takseringsart: string;
  vogntype: string;
  camptakstype: string;
  mcTaksType: string;
  vognart: string;
  angivetFabriat: string;
  modelkode: string;
  model: string;
  positionValues: SkavuPositionValueDTO[];
  unspecifiedSaleryAmount: number;
  unspecifiedLicensplatesAmount: number;
  unspecifiedSparepartsAmount: number;
  ekstraudstyr: string;
}
