import {VkStamDTO} from 'app/shared/dto/vk-stam.dto.model';

export class UdbyderDTO {
  name: string;
  phoneNo: string;
  email: string;
}

export class PrincipalDTO {
  id: number;
  userName: string;
  orgNr: string;
  atKlientNr: string;
  personName: string;
  udskriftLinier: string;
  roles: string[];
  organizationName: string;
  authorities: string[]; // not part of dto from backend
  udbyder: UdbyderDTO;
  vkStam: VkStamDTO;
  policeOpslagAllowed: boolean;
}
