import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ValueTypeDTO} from '../../../../shared/dto/value-type-dto.model';
import {FabrikatDTO} from '../../../../shared/makes/dto/fabrikat-dto.model';
import {MakeService} from '../../../../shared/makes/service/make.service';
import {AgreementService} from '../../../../shared/service/agreement.service';
import {StringUtils} from '../../../../shared/utils/string-utils';
import {combineLatest, Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AgreementDTO} from '../../../../shared/agreement/agreement-dto.model';

@Component({
  templateUrl: './vk-agreements-popup.component.html',
})
export class VkAgreementsPopupComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  @Input() title: string;
  @Input() body: string;
  @Input() agreements: AgreementDTO[];
  currentMakes: FabrikatDTO[];
  loading = true;
  valueTypes: ValueTypeDTO[];
  currentValueTypeId: number;

  constructor(private activeModal: NgbActiveModal,
    private agreementService: AgreementService, 
    private makeService: MakeService) {
  }

  ngOnInit(): void {
    this.currentValueTypeId = null;
    this.setupObservers()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([valuesTypes, makes]) => {          
        this.valueTypes = valuesTypes;
        this.currentMakes = makes;
        this.loading = false;  
      });    
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  public cancel(): void {
    this.activeModal.dismiss('cancel');
  }

  private setupObservers(): Observable<any> {        
    const valueTypes$ = this.agreementService.getValueTypes();
    const makes$ = this.makeService.findAllBrands();
    return combineLatest([valueTypes$, makes$]);    
  }

  public getMake(makeCode: string): string {
    if (StringUtils.isNotEmpty(makeCode)) {      
      const make = this.currentMakes.find(m => m.kode === makeCode )
      return make ? make.navn : null;
    }
    return null
  }

  public getModel(makeCode: string, modelCode: string): string {
    if (StringUtils.isNotEmpty(makeCode) && StringUtils.isNotEmpty(modelCode)) {
      const make = this.currentMakes.find(m => m.kode === makeCode)
      if (make) {
        const model = make.modeller.find((m) => m.kode === modelCode)
        return model ? model.navn : null;
      }
    }
    return null;
  }

  public getValueType(valueTypeId: number): ValueTypeDTO {
    return this.valueTypes.find(v => v.id === valueTypeId);
  }  
}
