import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {DraftAdviseDTO} from '../../dto/draft-advise-dto.model';
import {TimelineMessage} from '../../../shared/ui/message-timeline/timeline-message.model';
import {DraftService} from '../../service/draft.service';
import {PrincipalService} from '../../../shared';
import {takeUntil} from 'rxjs/operators';
import MessageTimelineConverter from '../../../shared/ui/message-timeline/message-timeline-converter';
import {VKStamService} from '../../../shared/service/vkstam.service';

@Component({
  selector: 'lc-draft-advise-base',
  templateUrl: './draft-advise-base.component.html'
})
export class DraftAdviseBaseComponent implements OnDestroy {

  private _advise: DraftAdviseDTO;
  @Input() token: string;
  @Input() reportKey: string;
  @Input() create = true;
  @Output() onRefresh: EventEmitter<void> = new EventEmitter();

  private unsubscribe$ = new Subject<void>();
  onCreate: Subject<DraftAdviseDTO>;
  onDelete: Subject<DraftAdviseDTO>
  messages: TimelineMessage[] = [];

  constructor(private draftService: DraftService, private vkStamService: VKStamService, private principalService: PrincipalService) {}

  get advise(): DraftAdviseDTO {
    return this._advise;
  }

  @Input() set advise(advise: DraftAdviseDTO) {
    this._advise = advise;
    this.messages = advise ? MessageTimelineConverter.mapToTimelineMessages(this.principalService.getCurrentPrincipal(), 'fa-wrench', 'fa-paint-brush', advise.timeline) : [];
  }

  lookupWorkshopName(vkNr: number): void {
    this.vkStamService.getWorkshopName(vkNr).pipe(takeUntil(this.unsubscribe$)).subscribe((name) => {
      this._advise.toVkName = name;
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  createNewMessage(message: string): void {
    this.draftService.createAdviseMessage(this.token, {adviseId: this._advise.id, message: message})
      .pipe(takeUntil(this.unsubscribe$)).subscribe((advise) => {
        this.advise = advise;
    })
  }

}
