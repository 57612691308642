import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {FabrikatDTO} from '../../../shared/makes/dto/fabrikat-dto.model';
import {ClientStateDetails} from '../../model/client-state-details.model';
import {AutoItDTO, VinLookupResultDTO} from '../../dto/vin-lookup-result-dto.model';
import {VinAllowedDTO} from '../../../shared/dto/vin-allowed-dto.model';
import {Subject} from 'rxjs';
import {PrincipalService} from '../../../shared/service/auth/principal.service';
import {DraftService} from '../../service/draft.service';
import {BootstrapGrowlService} from '../../../shared/ui/ngx-bootstrap-growl/bootstrap-growl.service';
import {BootstrapAlertType} from '../../../shared/ui/ngx-bootstrap-growl/bootstrap-alert-type.enum';
import {ClientStateDTO} from '../../dto/client-state-dto.model';
import {DateUtils} from '../../../shared/utils/date-utils';
import {NgbForsiDateMomentParserFormatter} from '../../../shared/formatter/forsi-date.formatter';
import {ModelDTO} from '../../../shared/makes/dto/model-dto.model';
import {DraftCreateViewModel} from '../draft-create.view-model';
import {NgForm} from '@angular/forms';
import {StringUtils} from '../../../shared/utils/string-utils';
import {VinDetailsDTO} from '../../../shared/dto/vin-details-dto.model';
import {FileUploader} from 'ng2-file-upload';
import URLBuilder from '../../../shared/utils/url-builder';
import {takeUntil} from 'rxjs/operators';
import {isNullOrUndefined} from '../../../shared/utils/object-utils';
import {ReportCategory} from '../../../shared/model/report-category.model';
import {MakeService} from '../../../shared/makes/service/make.service';
import {DateService} from '../../../shared/service/date.service';
import {ModelHelpText} from '../../../shared/makes/dto/model-help-text.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ModelHelpTextPopupComponent} from '../../ui/model-help-text-popup/model-help-text-popup.component';

@Component({
  selector: 'lc-draft-qapter-create',
  templateUrl: './draft-qapter-create.component.html',
  styleUrls: [
    '../../draft.scss', '../../edit/draft-common.scss'
  ]
})
export class DraftQapterCreateComponent implements OnInit, AfterViewInit, OnDestroy, AfterViewChecked {
  private unsubscribe$ = new Subject<void>();
  @Input() createViewModel: DraftCreateViewModel;
  @Input() details: ClientStateDetails;
  @Input() explicitReportCategory: ReportCategory;
  @ViewChild('f', {static: true}) public form: NgForm;
  @ViewChild('photoButton') photoButton: ElementRef;
  brands: FabrikatDTO[];
  searchParm = '';
  vinLookupWarnMessage: string;
  searchingVin: boolean;
  doingOcrScan: boolean;
  dmrResult: AutoItDTO;
  fabrikatChangedViaVinlookup = false;
  vinAllowedDTO: VinAllowedDTO;
  vinLookupAttempted: boolean;
  fileUploader: FileUploader;
  urlBuilder = new URLBuilder();
  ocrDropZoneOver = false;
  modelHelpText: ModelHelpText;

  get models(): ModelDTO[] {
    if (this.details.vehicle.fabrikat) {
      return this.details.vehicle.fabrikat.modeller;
    }
    return [];
  }

  constructor(private principal: PrincipalService,
              private clientStateService: DraftService,
              public dateService: DateService,
              private makeService: MakeService,
              private bootstrapGrowlService: BootstrapGrowlService,
              private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.createViewModel.explicitReportCategory = !isNullOrUndefined(this.explicitReportCategory) ? this.explicitReportCategory : null;
    this.createViewModel.registerForm(this.form);
    this.resetBeforeVinLookup();
    this.searchParm = null;
    this.searchingVin = false;
    this.doingOcrScan = false;
    this.vinAllowedDTO = null;
    this.vinLookupAttempted = false;
    this.brands = [];
    this.getBrands();

    this.details.vehicle.fabrikatSubject
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(nextFabrikat => {
        this.details.vehicle.model = null;
        this.details.vehicle.angivetModel = null;
        this.details.vehicle.angivetFabrikat = null;
        if (nextFabrikat !== null) {
          if (FabrikatDTO.isOther(nextFabrikat)) {
            this.details.vehicle.angivetFabrikat = this.dmrResult ? this.dmrResult.fabrikat : null;
          }
        }
        if (!this.fabrikatChangedViaVinlookup) {
          this.resetExceptFabrikat();
        } else {
          this.fabrikatChangedViaVinlookup = false;
        }
      });
    this.prepareFileUploader();
  }

  private prepareFileUploader(): void {
    this.fileUploader = new FileUploader({
      maxFileSize: 15000000,
      removeAfterUpload: false,
      queueLimit: 1,
      autoUpload: true,
      url: this.urlBuilder.getRestServicePath() + 'clientstate/ocrscan'
    });

    this.fileUploader.onAfterAddingFile = (fileItem) => {
      this.doingOcrScan = true;
      const reader = new FileReader()
      const self = this;
      reader.readAsDataURL(fileItem._file); // read file as data url
      reader.onload = (event) => { // called once readAsDataURL is completed
        self.createViewModel.ocrImage = fileItem._file;
        self.createViewModel.ocrImageUrl = reader.result.toString();
      }
    };

    this.fileUploader.onSuccessItem = (item, response) => {
      const ocrTextDTO = JSON.parse(response);
      if (ocrTextDTO.text != null) {
        this.searchParm = ocrTextDTO.text;
        this.doVinLookup();
      } else {
        this.onOcrError();
      }
    };

    this.fileUploader.onErrorItem = this.onOcrError;
  }

  private onOcrError(): void {
    this.vinLookupAttempted = true;
    this.doingOcrScan = false;
    this.createViewModel.form.control.enable();
    const message = 'OCR kunne ikke skanne nummerpladen.';
    this.vinLookupWarnMessage = message;
    this.bootstrapGrowlService.addAlert(message, BootstrapAlertType.WARNING);
  }

  ngAfterViewInit(): void {
    if (this.details.token) {
      this.clientStateService.getClientState(this.details.token)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(clientStateDTO => {
          this.updateModel(clientStateDTO);
        });
    }
  }

  ngAfterViewChecked(): void {
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  isAuthenticated(): boolean {
    return this.principal.isAuthenticated();
  }

  getBrands(): void {
    this.makeService.findAllBrands()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(brands => {
        this.brands = brands;
      });
  }

  resetBeforeVinLookup(): void {
    this.createViewModel.regNr = null;
    this.form.control.enable();
    this.details.vehicle.nummerpladeType = 'H';
    this.details.vehicle.tilstandsType = ' ';
    this.vinLookupWarnMessage = null;
    this.dmrResult = null;
    this.vinAllowedDTO = null;
    this.details.vehicle.resetVinInfo(true);
  }

  resetExceptFabrikat(): void {
    this.createViewModel.form.control.enable();
    this.details.vehicle.nummerpladeType = 'H';
    this.details.vehicle.tilstandsType = ' ';
    this.vinLookupWarnMessage = null;
    this.dmrResult = null;
    this.vinAllowedDTO = null;
    this.details.vehicle.resetVinInfo(false);
  }

  vinButtonKeyUp(event): void {
    if (event.key === 'Enter') {
      // do not submit form
      event.preventDefault();
      this.doVinLookup();
    }
  }

  removeImage(): void {
    this.fileUploader.clearQueue();
    this.createViewModel.clearImage();
  }

  doVinLookup(): void {
    if (!this.searchParm || !this.searchParm.trim()) {
      // nothing in searchParm, so just ignore
      return;
    }
    this.searchParm = this.searchParm.trim();

    this.resetBeforeVinLookup();
    this.searchingVin = true;
    this.createViewModel.form.control.disable();
    this.clientStateService.vinLookup(this.searchParm)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(vinOpslagResult => {
          this.vinLookupAttempted = true;

          if (!StringUtils.isEmpty(vinOpslagResult.fabrikatKode)) {
            this.gotVinLookupResult(vinOpslagResult);
          } else {
            const message = !StringUtils.isEmpty(vinOpslagResult.autoItResult.message) ? vinOpslagResult.autoItResult.message : vinOpslagResult.message;
            this.bootstrapGrowlService.addAlert(message, BootstrapAlertType.WARNING);
            this.gotVinLookupResult(vinOpslagResult);
            this.vinLookupWarnMessage = message;
          }
          this.createViewModel.form.control.enable();
          this.searchingVin = false;
          this.doingOcrScan = false;
        }
        , reason => {
          this.transferSearchParamToInputField();
          this.vinLookupAttempted = true;
          this.bootstrapGrowlService.addAlert('Der skete en fejl i forbindelse med vinopslag', BootstrapAlertType.WARNING);
          this.createViewModel.form.control.enable();
          this.searchingVin = false;
          this.doingOcrScan = false;
        }
      );
  }

  private transferSearchParamToInputField(): void {
    if (this.searchParm != null) {
      if (this.searchParm.length < 8) {
        this.createViewModel.regNr = this.searchParm;
      } else {
        this.details.vehicle.stelnr = this.searchParm
      }
    }
  }

  gotVinLookupResult(vinLookupResult: VinLookupResultDTO): void {
    const vehicle = this.details.vehicle;
    if (vinLookupResult.autoItResult.succeeded) {
      this.dmrResult = vinLookupResult.autoItResult;
      vehicle.stelnr = vinLookupResult.autoItResult.stelNr;
      this.createViewModel.regNr = vinLookupResult.autoItResult.regNr;
      this.details.vehicle.foersteRegDato = new NgbForsiDateMomentParserFormatter().parse(vinLookupResult.autoItResult.foersteRegDato);
      if (vinLookupResult.autoItResult.foersteRegDato != null && vinLookupResult.autoItResult.foersteRegDato.trim().length > 6) {
        this.details.vehicle.aargang = +vinLookupResult.autoItResult.foersteRegDato.substring(6);
        this.details.vehicle.regNrUdloebDato = new NgbForsiDateMomentParserFormatter().parse(vinLookupResult.autoItResult.regNrUdloebDato);
      }

      if (StringUtils.isEmpty(this.createViewModel.regNr) && isNullOrUndefined(this.details.vehicle.foersteRegDato)) {
        this.details.vehicle.nummerpladeType = ' ';
      }
      this.details.vehicle.hybridType = vinLookupResult.autoItResult.hybridType
      this.details.vehicle.propellantType = vinLookupResult.autoItResult.propellantType
    } else {
      this.transferSearchParamToInputField();
    }
    const audatexLookupDTO = vinLookupResult.audatexLookupDTO;
    if (audatexLookupDTO && audatexLookupDTO.vinValid) {
      this.fabrikatChangedViaVinlookup = true;
      this.updateBrandAndModel(audatexLookupDTO.vinFabrikat, audatexLookupDTO.vinModel);

      this.details.vehicle.setVinLookupInfo(audatexLookupDTO);
    } else {
      this.fabrikatChangedViaVinlookup = true;
      const fabrikatKodeOrName = !isNullOrUndefined(vinLookupResult.fabrikatKode) ? vinLookupResult.fabrikatKode : vinLookupResult.autoItResult.fabrikat;
      this.updateBrandAndModel(fabrikatKodeOrName, fabrikatKodeOrName === '90' ? vinLookupResult.autoItResult.model : null);

      const expectedAudatexVinlookupToAnswer = !isNullOrUndefined(vinLookupResult.vinAllowedDTO) ? vinLookupResult.vinAllowedDTO.allowed : false;
      if (expectedAudatexVinlookupToAnswer && VinDetailsDTO.vinLookupTimedOut(audatexLookupDTO)) {
        this.vinLookupWarnMessage = 'Det lykkedes ikke at hente VIN oplysninger. Prøv igen.';
      }
    }
    this.vinAllowedDTO = vinLookupResult.vinAllowedDTO;
  }

  onSelectModelChanged(model: ModelDTO): void {
    this.modelHelpText = null;
    this.details.vehicle.model = model;
    if (model === null || ModelDTO.isKnownByAudatex(model)) {
      this.details.vehicle.angivetModel = null;
      this.details.vehicle.angivetFabrikat = null;
      this.getModelHelpText();
    } else {
      this.details.vehicle.angivetModel = this.dmrResult ? this.dmrResult.model : null;
      //only change angivetFabrikat in case it is not already set (manually by the user)
      if (isNullOrUndefined(this.details.vehicle.angivetFabrikat)) {
        this.details.vehicle.angivetFabrikat = this.details.vehicle.fabrikat.simpleName;
      }
    }
  }

  updateBrandAndModel(brandKode: string, modelKode: string): void {
    this.modelHelpText = null;
    if (!isNullOrUndefined(brandKode)) {
      let fabrikatOthers: FabrikatDTO;
      for (const brand of this.brands) {
        if (brand.kode === 'V8') {  //Oevrige fabrikater
          fabrikatOthers = brand;
        }

        if (brand.kode.trim() === brandKode.trim()) {
          this.details.vehicle.fabrikat = brand;
          for (const model of brand.modeller) {
            if (modelKode && (model.kode.trim() === modelKode.trim())) {
              this.details.vehicle.model = model;
              this.getModelHelpText();
              break;
            }
          }
          break;
        }
      }

      if (!this.details.vehicle.fabrikat) {
        this.details.vehicle.fabrikat = fabrikatOthers;
      }
    }
  }

  private getModelHelpText() {
    this.modelHelpText = null;
    this.makeService.getModel(this.details.vehicle.fabrikat.id, this.details.vehicle.model?.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((modelDTO) => {
        if (!!modelDTO.modelHelpText) {
          this.modelHelpText = modelDTO.modelHelpText;
        }
      });
  }

  updateModel(clientStateDTO: ClientStateDTO): void {
    this.details = ClientStateDetails.fromClientStateDTO(clientStateDTO);
    this.updateBrandAndModel(clientStateDTO.fabrikat, clientStateDTO.model);
  }

  onPhotoButtonClicked(): void {
    this.photoButton.nativeElement.click();
  }

  showAngivetFabrikat(): boolean {
    // console.log("fabrikat: " + this.details.vehicle.model);
    return (this.details.vehicle.isGeneralFabrikat());
  }

  showAngivetModel(): boolean {
    return this.details.vehicle.isGeneralModel() || this.details.vehicle.isModelQapterWildcard();
  }

  public pastOrPresentMaxDateSelection(): any {
    return DateUtils.getNgbDateStructToday();
  }

  public fileOverOcrDropZone($event: any): void {
    this.ocrDropZoneOver = $event && this.fileUploader.queue.length < 1;
  }

  showModelHelpText() {
    const helpTextRef = this.modalService.open(ModelHelpTextPopupComponent);
    helpTextRef.componentInstance.modelHelpText = this.modelHelpText;
  }
}
