import {Component, OnDestroy} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Subject} from 'rxjs';
import {VkDraftApproveViewModel} from '../vk-draft-approve.view-model';
import {ValidationResultDTO} from '../../../../../dto/validation-result-dto.model';
import {isNullOrUndefined} from '../../../../../../shared/utils/object-utils';

@Component({
  templateUrl: './vk-draft-validation-popup.component.html',
  styleUrls: ['./vk-draft-validation-popup.scss'],
  providers: []
})
export class VkDraftValidationPopupComponent implements OnDestroy {
  private unsubscribe$ = new Subject<void>();
  private viewModel: VkDraftApproveViewModel;

  validationResult: ValidationResultDTO;
  ignoreValidationReason: string;

  constructor(public activeModal: NgbActiveModal) {
  }

  public initialize(viewModel: VkDraftApproveViewModel, validationResult: ValidationResultDTO): void {
    this.viewModel = viewModel;
    this.validationResult = validationResult;
  }

  public ignoreValidation(): void {
    let formattedIgnoreValidationReason = '';
    if (this.ignoreValidationReasonProvided()) {
      return;
    } else {
      const validationMessages = this.validationResult.messages;
      formattedIgnoreValidationReason = 'Prækvalifikation havde følgende svar:\n';
      formattedIgnoreValidationReason += validationMessages.map(value => `- ${value}`).join('\n');
      formattedIgnoreValidationReason = `${formattedIgnoreValidationReason}\n\nVærkstedets årsag til at godkende:\n${this.ignoreValidationReason}`;
    }
    if (!isNullOrUndefined(this.viewModel.approveCallback)) {
      this.viewModel.approveCallback(this.viewModel.token, true, formattedIgnoreValidationReason);
    }
    this.close();
  }

  ignoreValidationReasonProvided(): boolean {
    return !this.ignoreValidationReason || this.ignoreValidationReason.length < 10;
  }

  public editReport(): void {
    if (!isNullOrUndefined(this.viewModel.navigateToEditCallback)) {
      this.viewModel.navigateToEditCallback();
    }
    this.close();
  }

  public close(): void {
    this.activeModal.dismiss();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
