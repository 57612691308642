export class UserTokenDTO {
  id: number;
  userName: string;
  name: string;
  startDate: string;
  endDate: string;
  enabled: boolean;
  token: string;
  accessRoles: AccessRole[];
}

export class AccessRole {
    name: string;
    enabled: boolean;
}
