import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ClientStateDetails} from '../../../model/client-state-details.model';
import {FormSaver} from '../../../../shared/service/core/form-saver.service';
import {NgForm} from '@angular/forms';
import {Validatable} from '../validatable.interface';

@Component({
  selector: 'lc-draft-misc-amounts',
  templateUrl: './draft-misc-amounts.component.html',
  styleUrls: [
    '../../draft-common.scss'
  ]
})
export class DraftMiscAmountsComponent implements Validatable, OnInit {
  @Input() formId: string;
  @Input() details: ClientStateDetails;

  @ViewChild('f', {static: true}) public cform: NgForm;

  constructor(private formSaver: FormSaver) {
  }

  ngOnInit(): void {
    this.formSaver.registerForm(this.formId, this.cform);
  }

  isValid(): boolean {
    return this.cform.form.valid;
  }
}
