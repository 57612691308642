import {Injectable} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {AttachmentApprovalModalComponent} from './attachment-approval.component';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {TemporaryAttachmentApprovalModel} from '../../../shared/model/temporary-attachment-approval.model';

@Injectable()
export class AttachmentApprovalModalService {
  complete: Subject<boolean>;
  private modalRef: NgbModalRef;

  constructor(private modalService: NgbModal) {
  }

  open(approvingForReportId: number, vkReportKey: string, taksReportKey: string, attachmentsToApprove: TemporaryAttachmentApprovalModel): Observable<boolean> {
    this.complete = new ReplaySubject<boolean>(1);
    if (this.modalRef != null) {
      return this.complete;
    }

    this.modalRef = this.modalService.open(AttachmentApprovalModalComponent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false
    });

    this.modalRef.result.then((result) => {
      if (result) {
        this.complete.next(true);
        this.complete.complete();
        console.log('Approved');
      } else {
        this.complete.next(false);
        this.complete.complete();
        console.log('Cancelled');
      }

      this.modalRef = null;
    }, () => {
      this.complete.next(false);
      this.complete.complete();
      this.modalRef = null;
      console.log('Not OK');
    });

    this.modalRef.componentInstance.initializeAttachmentsForApproval(approvingForReportId, vkReportKey, taksReportKey, attachmentsToApprove);
    return this.complete;
  }
}
