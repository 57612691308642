<fieldset ngForm="f" #f="ngForm">
    <h2>Skadeomfang</h2>
    <div class="card">
        <div class="card-body">
            <div class="row" *ngIf="showFantomSelection()">
                <div class="col-md-6">
                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label class="m-0" for="fantomFabrikat">Fantom fabrikat</label>
                        </div>
                        <div class="col">
                        <lc-autocomplete
                            [(ngModel)]="details.vehicle.fantomFabrikat"
                            id="fantomFabrikat"
                            name="fantomFabrikat"
                            [items]="fantomBrands" exactKey="kode" itemText="navn"
                            [required]="showFantomSelection()"
                            (ngModelChange)="phantomVehicleMakeChanged()"
                        >
                        </lc-autocomplete></div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label class="m-0" for="fantomModel">Fantom Model</label>
                        </div>
                        <div class="col">
                            <lc-autocomplete
                                id="fantomModel" name="fantomModel"
                                [(ngModel)]="details.vehicle.fantomModel"
                                [items]="fantomModels"
                                exactKey="kode" itemText="navn"
                                [required]="showFantomSelection()"
                            >
                            </lc-autocomplete>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md">
                    <div class="row pb-3">
                        <lc-qapter-button style="width: 150px; height: 47px; margin-right: 20px"
                                          [required]="!details.onepadDataValid"
                                          [spin]="formSaver.busy || qapterActivated"
                                          [token]="details.token"
                                          [fabrikat]="details.vehicle.fabrikat"
                                          [fantomFabrikat]="details.vehicle.fantomFabrikat"
                                          [vinDetails]="details.vehicle.vinDetails"
                                          [submodel]="details.vehicle.submodel"
                                          [calcDate]="details.calculationDate"
                                          [model]="details.vehicle.model"
                                          [fantomModel]="details.vehicle.fantomModel"></lc-qapter-button>
                        <lc-qapter-help-button style="width: 160px; height: 47px;"></lc-qapter-help-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fieldset>
