<fieldset ngForm="f" #f="ngForm">
    <h2>Skadet køretøj</h2>
    <div class="card">
        <div class="card-body">
            <div class="row">
                <!-- Left part -->
                <div class="col-md-6">
                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label for="regnr1">Reg. nr</label>
                        </div>
                        <!--<input [(ngModel)]="details.vehicle.regNr" class="col-8 form-control" type="text" id="regNr" name="regNr"-->
                        <div class="col-8">
                            <input *ngIf="!details.ansvarsskade" [(ngModel)]="details.forsikringstager.regNr"
                                class="form-control text-uppercase"
                                type="text" id="regnr1" name="regnr"
                                minlength=1 maxlength=10 size="10"
                                lcInputMask="regNr"
                                [disabled]="details.vehicle.nummerpladeType===' '"
                                required/>
                            <input *ngIf="details.ansvarsskade" [(ngModel)]="details.skadelidte.regNr"
                                class="form-control text-uppercase"
                                type="text" id="regnr1" name="regnr"
                                lcInputMask="regNr"
                                [disabled]="details.vehicle.nummerpladeType===' '"
                                minlength=1 maxlength=10 size="10"
                                required/>
                        </div>                            
                    </div>

                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label class="m-0" for="takseringsart">Takseringsart</label>
                        </div>
                        <div class="col-8">
                            <lc-autocomplete id="takseringsart" name="takseringsart" required class="custom-control"
                                            [items]="taksArtViewModel.takseringsarter"
                                            [(ngModel)]="taksArtViewModel.takseringsart.value" debugInfo="takseringsart"
                                            itemid="id" itemText="navn"></lc-autocomplete>
                        </div>
                    </div>

                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label class="m-0" for="vogntype">Vogntype</label>
                        </div>
                        <div class="col-8">
                            <lc-autocomplete id="vogntype" name="vogntype" required
                                            [disabled]="!taksArtViewModel.hasVogntyper()"
                                            [items]="taksArtViewModel.vogntyper"
                                            [(ngModel)]="taksArtViewModel.vogntype.value" debugInfo="vogntype"
                                            itemid="id" itemText="navn"></lc-autocomplete>
                        </div>
                    </div>
                    <div class="row pb-3" *ngIf="showVognArtDropdown()">
                        <div class="col-form-label col-4">
                            <label class="m-0" for="vognart">Vognart</label>
                        </div>
                        <div class="col-8">
                            <lc-autocomplete id="vognart" name="vognart" required 
                                            [disabled]="!taksArtViewModel.hasVognarter()"
                                            [items]="taksArtViewModel.vognarter"
                                            [(ngModel)]="taksArtViewModel.vognart.value" debugInfo="vognart"
                                            itemid="id" itemText="art"></lc-autocomplete>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label class="m-0" for="fabrikat">Fabrikat</label>
                        </div>
                        <div class="col-8">
                            <lc-autocomplete id="fabrikat" name="fabrikat" required 
                                            [disabled]="!taksArtViewModel.hasFabrikater()"
                                            [items]="taksArtViewModel.fabrikater"
                                            [(ngModel)]="taksArtViewModel.fabrikat.value" debugInfo="fabrikat"
                                            itemid="id" itemText="fabrikatTekst"></lc-autocomplete>
                        </div>
                    </div>
                    <div class="row pb-3" *ngIf="showAngivetFabrikat()">
                        <div class="col-form-label col-4">
                            <label for="angivetfabrikat">Angivet fabrikat</label>
                        </div>
                        <div class="col-8">
                            <input [(ngModel)]="details.skavu.angivetFabriat" class="form-control text-uppercase" type="text"
                                id="angivetfabrikat"
                                name="angivetfabrikat"
                                minlength=1 maxlength=20 size="20"
                                required/>
                        </div>
                    </div>

                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label for="model">Modeltekst</label>
                        </div>
                        <div class="col-8">
                            <input [(ngModel)]="details.skavu.model" class="form-control text-uppercase" type="text" id="model"
                                name="model"
                                minlength=1 maxlength=20 size="20"
                                required/>
                        </div>
                    </div>

                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label for="stelnr">Stelnummer</label>
                        </div>
                        <div class="col-8">
                            <input [(ngModel)]="details.vehicle.stelnr" class="form-control text-uppercase" type="text" id="stelnr"
                                name="stelnr"
                                minlength=1 maxlength=20 size="20"/>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label for="aargang">Årgang</label>
                        </div>
                        <div class="col-8">
                            <input [(ngModel)]="details.vehicle.aargang" class="form-control" type="tel" id="aargang"
                                name="aargang" lcInputMask="year" lcValidate="maxNextYear" size="4" min="1900" max="9999"
                                required/>
                        </div>
                    </div>

                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label for="kmstand">Km. stand</label>
                        </div>
                        <div class="col-8">
                            <input [(ngModel)]="details.vehicle.kmstand" class="form-control" type="tel" id="kmstand"
                                name="kmstand" lcInputMask="int6" maxlength="6" size="6"/>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label for="ansvarsskade">Ansvarsskade</label>
                        </div>
                        <div class="col-8">
                            <input (change)="ansvarsSkadeChanged()" type="checkbox" class="form-input-check-single"
                                    name="ansvarsskade"
                                    id="ansvarsskade" [(ngModel)]="details.ansvarsskade" autocomplete="off"/>
                        </div>
                    </div>
                </div>

                <!-- Right part -->
                <div class="col-md-6">
                    <lc-licenseplate [details]="details"></lc-licenseplate>
                    <lc-vehicle-condition [details]="details"></lc-vehicle-condition>

                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label for="ekstraudstyr">Ekstraudstyr</label>
                        </div>
                        <div class="col-8">
                            <textarea rows="5" class="form-control text-uppercase" id="ekstraudstyr" name="ekstraudstyr"
                                        [(ngModel)]="details.skavu.ekstraudstyr"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fieldset>
