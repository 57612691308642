import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormSaver} from '../../../../../shared/service/core/form-saver.service';
import {SkavuFabrikatService} from '../../../../service/skavu-fabrikat.service';
import {NgForm} from '@angular/forms';
import {NgbTypeahead} from '@ng-bootstrap/ng-bootstrap';
import {ClientStateDetails} from '../../../..';
import {DraftSkavuTaksArtModel, TaksSkavuTaksDataProvider} from '../../../../model/draft-skavu-taks-art.model';
import {DraftUtil} from '../../../../draft.util';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {TakseringsartDTO} from '../../../../dto/skavu/takseringsart-dto.model';
import {StringUtils} from '../../../../../shared/utils/string-utils';
import {Subject} from 'rxjs';
import {SkavufabrikatDTO} from '../../../../dto/skavu/skavufabrikat-dto.model';
import {isNullOrUndefined} from '../../../../../shared/utils/object-utils';

@Component({
  selector: 'lc-taks-draft-skavu-master-data',
  templateUrl: './draft-taks-skavu-masterdata.component.html',
  styleUrls: ['../../../draft-common.scss']
})
export class DraftTaksSkavuMasterDataComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  @ViewChild('f', {static: true}) public cform: NgForm;
  @ViewChild('instance', {static: true}) instance: NgbTypeahead;
  @Input() formId: string;
  @Output() takseringsArtChanged = new EventEmitter<TakseringsartDTO>();
  @Output() listeTypeId = new EventEmitter<number>();
  @Input() details: ClientStateDetails;
  taksArtViewModel: DraftSkavuTaksArtModel;

  constructor(private formSaver: FormSaver,
              private skavuFabrikatService: SkavuFabrikatService) {
  }

  ngOnInit(): void {
    this.formSaver.registerForm(this.formId, this.cform);
    this.initializeTaksArtModel();
    this.setupObservables();
  }

  private initializeTaksArtModel(): void {
    this.taksArtViewModel = new DraftSkavuTaksArtModel(new TaksSkavuTaksDataProvider(this.skavuFabrikatService));
    this.taksArtViewModel.initializeSkavuTaks(this.details.skavu);
  }

  private setupObservables(): void {
    this.taksArtViewModel.takseringsart.getObs()
      .pipe(distinctUntilChanged(), takeUntil(this.unsubscribe$))
      .subscribe(takseringsArt => {
        if (!isNullOrUndefined(takseringsArt)) {
          if (this.details.skavu.takseringsart !== takseringsArt.value) {
            this.takseringsArtChanged.emit(takseringsArt);
          }
          this.details.skavu.takseringsart = takseringsArt.value;
        } else {
          this.details.skavu.takseringsart = '';
        }
      });

    this.taksArtViewModel.vogntype.getObs()
      .pipe(distinctUntilChanged(), takeUntil(this.unsubscribe$))
      .subscribe(vognType => {
        if (!isNullOrUndefined(vognType)) {
          this.details.skavu.commontype = vognType.value;
          this.listeTypeId.emit(vognType.listetypeId);
        } else {
          this.details.skavu.commontype = '';
          this.listeTypeId.emit(null);
        }
      });

    this.taksArtViewModel.vognart.getObs()
      .pipe(distinctUntilChanged(), takeUntil(this.unsubscribe$))
      .subscribe(vognArt => {
        if (!isNullOrUndefined(vognArt)) {
          this.details.skavu.vognart = vognArt.art;
        } else {
          this.details.skavu.vognart = '';
        }
      });

    this.taksArtViewModel.fabrikat.getObs()
      .pipe(distinctUntilChanged(), takeUntil(this.unsubscribe$))
      .subscribe(fabrikat => {
        if (!isNullOrUndefined(fabrikat)) {
          if (fabrikat.fabrikatTekst !== SkavufabrikatDTO.OTHER_FABRIKAT_TEXT) {
            this.details.skavu.angivetFabriat = fabrikat.fabrikatTekst;
          }
          this.details.skavu.fabrikat = StringUtils.isEmpty(fabrikat.fabrikatKode) ? '' : fabrikat.fabrikatKode.trim();
          this.details.skavu.modelkode = fabrikat.modelKode;
        } else {
          this.details.skavu.angivetFabriat = '';
          this.details.skavu.modelkode = '';
          this.details.skavu.fabrikat = '';
        }
      });
  }

  ansvarsSkadeChanged(): void {
    if (this.details.ansvarsskade) {
      this.details.skadelidte.regNr = this.details.forsikringstager.regNr;
      this.details.forsikringstager.regNr = null;
    } else {
      this.details.forsikringstager.regNr = this.details.skadelidte.regNr;
      this.details.skadelidte.regNr = null;
      this.details.skadelidte.navn = null;
      this.details.skadelidte.addressLine1 = null;
      this.details.skadelidte.addressLine2 = null;
      this.details.skadelidte.postNr = null;
      this.details.skadelidte.telefonNr = null;
    }
  }

  showVognArtDropdown(): boolean {
    if (!isNullOrUndefined(this.taksArtViewModel.takseringsart.value)) {
      return DraftUtil.showVognArtDropdown(this.taksArtViewModel.takseringsart.value.value);
    }
    return false;
  }

  showAngivetFabrikat(): boolean {
    return !isNullOrUndefined(this.taksArtViewModel.fabrikat.value) && this.taksArtViewModel.fabrikat.value.fabrikatTekst === SkavufabrikatDTO.OTHER_FABRIKAT_TEXT;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
