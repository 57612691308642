export class AutoflexDTO {
  ledenr: string;
  autotaksReservedelNr: string;
  autoflexReservedelNr: string;
  autotaksReservedelPris: number;
  autotaksReservedelPrisFormatted: string;
  autoflexReservedelPris: number;
  autoflexReservedelPrisFormatted: string;
  autotaksReservedelTekst: string;
  autoflexReservedelTekst: string;
  autoflexKvalitet: string;
  autoflexLeverandoer: string;
  autoflexFabrikant: string;
}
