import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {NgbDateStruct, NgbTypeahead} from '@ng-bootstrap/ng-bootstrap';
import {NgForm} from '@angular/forms';
import {Validatable} from '../../validatable.interface';
import {ClientStateDetails} from '../../../..';
import {FormSaver} from '../../../../../shared/service/core/form-saver.service';
import {DateUtils} from '../../../../../shared/utils/date-utils';
import {TakseringsartDTO} from '../../../../dto/skavu/takseringsart-dto.model';
import {VogntypeDTO} from '../../../../dto/skavu/vogntype-dto.model';
import {VognartDTO} from '../../../../dto/skavu/vognart-dto.model';
import {SkavufabrikatDTO} from '../../../../dto/skavu/skavufabrikat-dto.model';
import {SkavuFabrikatService} from '../../../../service/skavu-fabrikat.service';
import {merge, Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, takeUntil} from 'rxjs/operators';
import {DraftUtil} from '../../../../draft.util';
import {isNullOrUndefined} from '../../../../../shared/utils/object-utils';

@Component({
  selector: 'lc-draft-skavu-master-data',
  templateUrl: './draft-skavu-master-data.component.html',
  styleUrls: [
    '../../../draft-common.scss'
  ]
})
export class DraftSkavuMasterDataComponent implements Validatable, OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  @ViewChild('f', {static: true}) public cform: NgForm;
  @ViewChild('instance') instance: NgbTypeahead;
  @Input() formId: string;
  @Input() details: ClientStateDetails;
  @Input() takseringsartKode: string = null;
  @Input() vognartKode: number = null;
  @Output() listeTypeId = new EventEmitter<number>();
  takseringsart: TakseringsartDTO;
  takseringsarter: TakseringsartDTO[] = [];
  vogntype: VogntypeDTO;
  vogntyper: VogntypeDTO[] = [];
  vognart: VognartDTO;
  vognarter: VognartDTO[] = [];
  fabrikat: SkavufabrikatDTO;
  fabrikater: SkavufabrikatDTO[] = [];
  model: string;
  foersteRegDatoDP: NgbDateStruct;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  constructor(private formSaver: FormSaver,
              private skavuFabrikatService: SkavuFabrikatService) {
  }

  ngOnInit(): void {
    this.formSaver.registerForm(this.formId, this.cform);
    this.initFabrikatDropdowns();
  }

  showVognArtDropdown(): boolean {
    return !this.vognartKode && this.takseringsart && DraftUtil.showVognArtDropdown(this.takseringsart.value);
  }

  initFabrikatDropdowns(): void {
    this.skavuFabrikatService.getTakseringsarter().pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
      this.takseringsarter = result;
      if (this.takseringsartKode) {
        this.takseringsart = result.filter(t => t.value === this.takseringsartKode).shift();
      }
      this.skavuFabrikatService.getVogntyper(this.takseringsart.id).pipe(takeUntil(this.unsubscribe$)).subscribe(vogntyper => {
        this.vogntyper = vogntyper;
        if (!isNullOrUndefined(this.takseringsart)) {
          this.vogntype = vogntyper.filter(v => v.vognType === this.details.skavu.commontype).shift();
          if (!isNullOrUndefined(this.vogntype)) {
            this.listeTypeId.next(this.vogntype.listetypeId);
            this.skavuFabrikatService.getVognarter(this.takseringsart.id, this.vogntype.listetypeId)
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe(vognarter => {
                this.vognarter = vognarter;
                if (!isNullOrUndefined(this.vogntype)) {
                  if (this.vognarter.length === 1) {
                    this.vognart = this.vognarter[0];
                  } else {
                    this.vognart = this.vognarter.filter(v => v.modelkode === this.details.skavu.modelkode).shift();
                  }
                  this.skavuFabrikatService.getFabrikater(this.takseringsart.id, this.vogntype.listetypeId, this.vognart.id)
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(fabrikater => {
                      this.fabrikater = fabrikater;
                      if (!isNullOrUndefined(this.vognart)) {
                        this.fabrikat = this.fabrikater.filter(f => f.fabrikatTekst === this.details.skavu.angivetFabriat).shift();
                        if (isNullOrUndefined(this.fabrikat)) {
                          this.fabrikat = this.fabrikater.slice(-1).pop();
                        }
                      }
                    });
                }
              });
          }
        }
      });
    });
  }

  selectArt(takseringsArt: TakseringsartDTO): void {
    this.takseringsart = takseringsArt;
    this.skavuFabrikatService.getVogntyper(this.takseringsart.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(vogntyper => {
        this.vogntyper = vogntyper;
        this.selectVogntype(this.vogntype);
      })
  }

  selectVogntype(vogntype: VogntypeDTO): void {
    this.vogntype = vogntype;
    if (this.vogntype) {
      this.listeTypeId.next(vogntype.listetypeId)
    }
    this.details.skavu.commontype = this.vogntype.vognType;
    if (this.takseringsart && vogntype) {
      this.skavuFabrikatService.getVognarter(this.takseringsart.id, vogntype.listetypeId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(vognarter => {
          this.vognarter = vognarter;
          this.selectVognArt(this.vognart);
        });
    } else {
      this.vognarter = [];
      this.selectVognArt(this.vognart);
    }
  }

  selectVognArt(vognart: VognartDTO): void {
    if (!isNullOrUndefined(vognart)) {
      this.vognart = this.vognarter.filter(v => v.art === vognart.art).shift();
    } else {
      this.vognart = null;
    }

    if (this.vognart && vognart) {
      this.details.skavu.modelkode = vognart.modelkode;
      this.skavuFabrikatService.getFabrikater(this.takseringsart.id, this.vogntype.listetypeId, vognart.id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(fabrikater => {
          this.fabrikater = fabrikater;
          this.selectFabrikat(this.fabrikat);
        })
    } else {
      this.fabrikater = [];
      this.selectFabrikat(this.fabrikat);
    }
  }

  selectFabrikat(fabrikat: SkavufabrikatDTO): void {
    if (!isNullOrUndefined(fabrikat)) {
      this.fabrikat = this.fabrikater.filter(f => f.fabrikatTekst === fabrikat.fabrikatTekst).shift();

      if (this.details.isStortaksType()) {
        this.details.skavu.fabrikat = fabrikat.fabrikatKode;
      }
      if (this.details.isCamptaksType()) {
        this.details.skavu.fabrikat = fabrikat.modelKode;
      }
      if (this.details.isMctaksType()) {
        this.details.skavu.fabrikat = fabrikat.modelKode;
      }
      this.details.skavu.angivetFabriat = fabrikat.fabrikatTekst;
      if (fabrikat.fabrikatTekst === SkavufabrikatDTO.OTHER_FABRIKAT_TEXT) {
        this.details.skavu.angivetFabriat = '';
      }
    } else {
      this.fabrikat = null;
      this.details.skavu.fabrikat = null;
      this.details.skavu.angivetFabriat = null;
    }

  }

  ansvarsSkadeChanged(): void {
    if (this.details.ansvarsskade) {
      this.details.skadelidte.regNr = this.details.forsikringstager.regNr;
      this.details.forsikringstager.regNr = null;
    } else {
      this.details.forsikringstager.regNr = this.details.skadelidte.regNr;
      this.details.skadelidte.regNr = null;
      this.details.skadelidte.navn = null;
      this.details.skadelidte.addressLine1 = null;
      this.details.skadelidte.addressLine2 = null;
      this.details.skadelidte.postNr = null;
      this.details.skadelidte.telefonNr = null;
    }
  }

  nummerpladeTypeChanged(): void {
    if (this.details.vehicle.nummerpladeType === ' ') {
      if (this.details.ansvarsskade) {
        this.details.skadelidte.regNr = null;
      } else {
        this.details.forsikringstager.regNr = null;
      }

      this.details.vehicle.foersteRegDato = null;
    }
  }

  isValid(): boolean {
    return this.cform.form.valid;
  }

  public pastOrPresentMaxDateSelection(): any {
    return DateUtils.getNgbDateStructToday();
  }

  searchFabrikat2 = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 1 ? []
        : this.fabrikater.filter(f => f.fabrikatTekst.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    );

  searchFabrikat = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.fabrikater
        : this.fabrikater.filter(f => f.fabrikatTekst.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10)).map(f => f.fabrikatTekst))
    );
  };

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
