import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SharedCommonModule} from 'app/shared/shared-common.module';
import {SharedModule} from 'app/shared/shared.module';
import {SEARCH_ROUTES} from './search.route';
import {SearchComponent} from './list/search.component';
import {CaseSearchResultHighlighter} from './case-search-result-higlighter';
import {SearchService} from './search.service';

@NgModule({
  imports: [
    SharedModule,
    SharedCommonModule,
    RouterModule.forRoot(SEARCH_ROUTES, {useHash: true})
  ],
  declarations: [
    SearchComponent,

  ],
  providers: [
    CaseSearchResultHighlighter,
    SearchService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SearchModule {
}
