<div class="text-center" *ngIf="isLoading">
    <i class="fa fa-circle-o-notch fa-spin fop-page-spinner"></i>
</div>
<div *ngIf="!isLoading" class="no-focus row" tabindex="0" [lcAutofocus]>
    <div class="col">
    <div class="card">
        <div class="card-body">
            <h4 class="card-title">Afsender</h4>
            <div class="row">
                <div class="col-4 col-form-label">
                    <label for="line1">Linie 1</label>
                </div>
                <div class="col-md-4 col-sm-6 col-6">
                    <input type="text" class="form-control" id="line1"
                           [(ngModel)]="deliveryNoteConfiguration.line1">
                </div>
            </div>
            <div class="row">
                <div class="col-4 col-form-label">
                    <label for="line2">Linie 2</label>
                </div>
                <div class="col-md-4 col-sm-6 col-6">
                    <input type="text" class="form-control" id="line2"
                           [(ngModel)]="deliveryNoteConfiguration.line2">
                </div>
            </div>
            <div class="row">
                <div class="col-4 col-form-label">
                    <label for="line3">Linie 3</label>
                </div>
                <div class="col-md-4 col-sm-6 col-6">
                    <input type="text" class="form-control" id="line3"
                           [(ngModel)]="deliveryNoteConfiguration.line3">
                </div>
            </div>
            <div class="row">
                <div class="col-4 col-form-label">
                    <label for="line4">Linie 4</label>
                </div>
                <div class="col-md-4 col-sm-6 col-6">
                    <input type="text" class="form-control" id="line4"
                           [(ngModel)]="deliveryNoteConfiguration.line4">
                </div>
            </div>
            <div class="row">
                <div class="col-4 col-form-label">
                    <label for="line5">Linie 5</label>
                </div>
                <div class="col-md-4 col-sm-6 col-6">
                    <input type="text" class="form-control" id="line5"
                           [(ngModel)]="deliveryNoteConfiguration.line5">
                </div>
            </div>
            <div class="row">
                <div class="col-4 col-form-label">
                    <label for="telefon">Telefon</label>
                </div>
                <div class="col-md-4 col-sm-6 col-6">
                    <input type="text" class="form-control" id="telefon"
                           [(ngModel)]="deliveryNoteConfiguration.telefon">
                </div>
            </div>
            <div class="row">
                <div class="col-4 col-form-label">
                    <label for="fraKlokken">Fra kl.</label>
                </div>
                <div class="col-md-1 col-sm-2 col-3">
                    <input type="text" class="form-control" id="fraKlokken" maxlength="5"
                           [(ngModel)]="deliveryNoteConfiguration.fraKlokken">
                </div>
            </div>
            <div class="row">
                <div class="col-4 col-form-label">
                    <label for="tilKlokken">Til kl.</label>
                </div>
                <div class="col-md-1 col-sm-2 col-3">
                    <input type="text" class="form-control" id="tilKlokken" maxlength="5"
                           [(ngModel)]="deliveryNoteConfiguration.tilKlokken">
                </div>
            </div>
        </div>
    </div>
    </div>
</div>
<div class="row mt-2">
    <div class="col">
        <button class="btn btn-primary pull-right" (click)="saveDeliveryNoteConfiguration()">Gem</button>
        <button class="btn btn-secondary pull-right me-2" (click)="reset()">Reset</button>
    </div>
</div>
