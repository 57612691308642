<div class="modal-header pre-size header-style" style="text-align: center">
    <h3 class="text-truncate">
        <i class="fa fa-lg fa-question-circle requested"></i>Svar taksator
    </h3>
    <div aria-label="Close" data-dismiss="modal" class="btn-close clickable" (click)="close()"></div>
</div>

<div class="modal-content">
    <div class="modal-body modal-body-style">
        <div class="container-fluid">
            Taksator ønsker svar om hvorvidt ændringer til rapporten kan accepteres.
            <div class="form-check">
                <label class="form-check-label">
                    <input type="radio" class="form-check-input" [value]="acceptValue()" [(ngModel)]="reportAcceptStatus.reportAcceptStatus" name="optradio">Accepter ændringer til rapporten
                </label>
            </div>
            <div class="form-check">
                <label class="form-check-label">
                    <input type="radio" class="form-check-input" [value]="rejectValue()" [(ngModel)]="reportAcceptStatus.reportAcceptStatus" name="optradio">Afvis ændringer til rapporten
                </label>
            </div>
        </div>

        <div *ngIf="isRejected()" class="mt-2">
            <label class="form-check-label">Besked til taksator</label>
            <textarea [(ngModel)]="rejectMessage"  id="acceptMessage" name="rejectMessage"
                      placeholder="Tilføj besked til taksator" class="form-control monospace-text"></textarea>
        </div>
    </div>
</div>

<div class="modal-footer">
    <div class="full-width">
        <div class="pull-right ps-1">
            <button class="btn btn-lg btn-primary" [disabled]="disableAnswerButton()"
                    (click)="didClickRespond()">Afgiv svar</button>
        </div>
    </div>
</div>
