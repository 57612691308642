export class TakserFormDTO {
  name: string;
  kode: string;

  get text(): string {
    return this.name + ' [' + this.kode + ']';
  }

  static empty(suggestedCode: string): TakserFormDTO {
    const result = new TakserFormDTO();
    result.kode = suggestedCode;
    return result;
  }

}
