/**
 * The model the view should react to
 */
import {DraftService} from '../../service/draft.service';
import {BehaviorSubject, combineLatest, of, Subject} from 'rxjs';
import {StringUtils} from '../../../shared/utils/string-utils';
import {ClientStateDetails} from '../../model/client-state-details.model';
import {CalculationResponseDTO} from '../../dto/calculation-response-dto.model';
import {LcActionMenu, LcActionMenuItem, LcActionSubMenuItem} from '../../../shared/ui/bottombar/lc-action-menu.model';
import {AutoflexService} from '../../../shared/service/autoflex.service';
import {PrincipalService} from '../../../shared/service/auth/principal.service';
import {ForsiReportKeyPipe} from '../../../shared/pipe/forsi-report-key.pipe';
import {ForsiReportKeyShortPipe} from '../../../shared/pipe/forsi-report-key-short.pipe';
import {DraftEditService} from '../draft-edit.service';
import {Injectable} from '@angular/core';
import {mergeMap} from 'rxjs/operators';
import {ReportKey} from '../../../search/reportkey.model';
import {ReportCategory} from '../../../shared/model/report-category.model';
import {ExternalValidationService} from '../../../shared/service/external-validation.service';
import {RapportType} from '../../../shared/model/rapport-type.model';
import {isNullOrUndefined} from '../../../shared/utils/object-utils';
import {AutoflexSparePartService} from '../../service/autoflex-spare-part.service';
import {AutoflexEnabledDTO} from '../../../shared/dto/autoflex-enabled-dto.model';

class PrintTapModel {
  //tab state
  active = false;

  //tab data
  hasData = false;
  print: string;

  setPrint(print: string): void {
    this.hasData = true;
    this.print = print;
  }
}

@Injectable()
export class DraftCalculateViewModel {
  private _token: string;
  private tokenSub: Subject<string> = new Subject<string>();
  private isTaksator = false;
  private isVK = false;
  vkPrintTap = new PrintTapModel();
  taksPrintTap = new PrintTapModel();
  private allTaps = [this.vkPrintTap, this.taksPrintTap];
  loading = true;
  enableAutoflex = false;
  enableSparePartSupplier = false;
  enableApprove = false;
  clientStateSub: BehaviorSubject<ClientStateDetails> = new BehaviorSubject<ClientStateDetails>(null);
  menuBottomTitle = '';
  menuBottomShortTitle = '';
  menuIconClass = null;

  draftMenu: LcActionMenu;

  autoflexCallback: Function;
  sparePartSupplierCallback: Function;
  sparePartSupplierOriginalCallback: Function;
  checkOpslagCallback: Function;
  navigateBackCallback: Function;
  approveCallback: Function;
  deliveryNoteCallBack: Function;
  diffCallBack: Function;
  proformaOrdreNummer: string = null;
  reportKey: string = null;

  set token(value: string) {
    this._token = value;
    this.tokenSub.next(value);
  }

  get token(): string {
    return this._token;
  }

  constructor(private draftEditService: DraftEditService,
              private clientStateService: DraftService,
              private externalValidationService: ExternalValidationService,
              private autoflexService: AutoflexService,
              private autoflexSparePartService: AutoflexSparePartService,
              private principalService: PrincipalService,
              private reportKeyPipe: ForsiReportKeyPipe,
              private reportKeyShortPipe: ForsiReportKeyShortPipe) {
    this.isTaksator = this.principalService.isTaksator();
    this.isVK = this.principalService.isVK();
    this.setupDraftActionMenu();
    this.setupObservables();
  }

  private setupDraftActionMenu(): void {
    this.draftMenu = new LcActionMenu();
    this.draftMenu.addItem(new LcActionMenuItem('fa-arrow-left', () => {
      if (!isNullOrUndefined(this.navigateBackCallback)) {
        this.navigateBackCallback(this._token);
      }
    }, 'Tilbage'));

    const approveMenuItem = new LcActionMenuItem('fa-check', () => {
      if (!isNullOrUndefined(this.approveCallback)) {
        this.approveCallback(this._token);
      }
    }, this.principalService.isTaksator() ? 'Status' : 'Godkend..');
    approveMenuItem.disabledCallback = () => !this.enableApprove;
    this.draftMenu.addItem(approveMenuItem);
  }

  private setupObservables(): void {
    this.tokenSub.pipe(
      mergeMap(token => this.draftEditService.getClientStateObservable(token)),
      mergeMap((clientState: ClientStateDetails) => {
        this.clientStateSub.next(clientState);
        this.reportKey = !StringUtils.isEmpty(clientState.taksRapportKey) ? clientState.taksRapportKey : clientState.proformaOrdreNummer;
        this.menuBottomTitle = this.reportKeyPipe.transform(this.reportKey);
        this.menuBottomShortTitle = this.reportKeyShortPipe.transform(this.reportKey);
        this.menuIconClass = ReportCategory.iconCssClass(clientState.schema);
        
        const calculate$ = (this.clientStateService.calculate(clientState));
        //possibly combine result with a print call as well
        const hasOffer = this.isTaksator && StringUtils.isNotEmpty(clientState.proformaOrdreNummer);
        const offerPrint$ = hasOffer ? this.clientStateService.getPrintAsTextOfOfferRelatedToDraft(clientState.token) : of(null);
        if (hasOffer) {
          this.proformaOrdreNummer = clientState.proformaOrdreNummer;
        }
        const autoflexEnabledForVK$ = this.isVK ? this.autoflexService.isAutoflexEnabledForVK(clientState.selskab) : of(null)
        const autoflexSparePartEnabled$ = this.isVK ? this.autoflexSparePartService.isAutoflexSparePartEnabledForVK(clientState.selskab) : this.autoflexSparePartService.hasActiveSparePartSuppliers();
        const autoflexExistsForVkTilbud$ = this.isVK || this.isEmpty(clientState.proformaOrdreNummer) ? of({enabled: false}) : this.autoflexSparePartService.existsOnVkTilbud(clientState.proformaOrdreNummer);
        return combineLatest([calculate$, offerPrint$, autoflexEnabledForVK$, autoflexSparePartEnabled$, autoflexExistsForVkTilbud$]);
      })).subscribe({
      next: ([calculationResultDTO, offerPrint, autoflexEnabledDTO, autoflexSparePartEnabled, autoflexOnVkTilbud]) => {
        this.clientStateSub.getValue().calculatedPrice = calculationResultDTO.calculatedPrice;
        const calculationPrint = this.extractCalculationPrint(calculationResultDTO);
        if (this.isTaksator) {
          this.taksPrintTap.setPrint(calculationPrint);
          this.activateTap(this.taksPrintTap);
          if (offerPrint) {
            this.vkPrintTap.setPrint(offerPrint.print);
          }
        } else {
          this.vkPrintTap.setPrint(calculationPrint);
          this.activateTap(this.vkPrintTap);
        }
        this.addAutoflex(autoflexEnabledDTO, calculationResultDTO);
        this.addSparePartSupplier(autoflexSparePartEnabled, calculationResultDTO, autoflexOnVkTilbud);
        // disable report types?
        this.enableApprove = calculationResultDTO.calculateSucceeded;

        //possible add check report button
        const checkRapportMenuItem = new LcActionMenuItem('fa-file-text-o', () => {
          if (!isNullOrUndefined(this.checkOpslagCallback)) {
            this.checkOpslagCallback(this._token);
          }
        }, 'Check Rapport');
        checkRapportMenuItem.disabledCallback = () => true;
        if (this.principalService.isExternalValidationUser()) {
          this.externalValidationService.isConfigured(this._token).subscribe(enabled => {
            checkRapportMenuItem.disabledCallback = () => !enabled
          })
        }

        this.draftMenu.addItem(checkRapportMenuItem, 1);

        if (this.principalService.isTaksator()) {
          const deliveryNoteMenuItem = new LcActionMenuItem('fa fa-tag', () => {
            if (!isNullOrUndefined(this.deliveryNoteCallBack)) {
              this.deliveryNoteCallBack();
            }
          }, 'Følgeseddel');
          this.draftMenu.addItem(deliveryNoteMenuItem, 1)
        }

        const showDiffButton =
          this.principalService.isTaksator() &&
          RapportType.extractFrom(this.reportKey).isOfAny([RapportType.H, RapportType.T]) &&
          !isNullOrUndefined(this.proformaOrdreNummer);
        if (showDiffButton) {
          const diffMenuItem = new LcActionMenuItem('fa-exchange', () => {
            if (!isNullOrUndefined(this.diffCallBack)) {
              this.diffCallBack();
            }
          }, 'Sammenlign');
          this.draftMenu.addItem(diffMenuItem, this.draftMenu.menuItems.length - 1)
        }

        this.loading = false;
      }, error: (error) => {
        console.log('failed to fetch calculation and prints', error);
        //todo: if an error occures, where should we go then?
        this.loading = false;
      }
    });
  }

  private isEmpty(value: string): boolean {
    return isNullOrUndefined(value) || value === '';
  }

  private addAutoflex(autoflexEnabledDTO: AutoflexEnabledDTO, calculationResultDTO: CalculationResponseDTO): void {
    let canAutoflex: boolean;
    if (this.isVK && autoflexEnabledDTO) {
      canAutoflex = autoflexEnabledDTO.enabled;
    } else {
      canAutoflex = ReportCategory.canAutoflex(ReportKey.fromReportWidePk(this.clientStateSub.getValue().taksRapportKey).reportType);
    }
    this.enableAutoflex = calculationResultDTO.calculateSucceeded && canAutoflex;

    //possible add autoflex button
    if (!this.clientStateSub.getValue().isUnitaks()) {
      const autoflexMenuItem = new LcActionMenuItem('fa-car', () => {
        if (!isNullOrUndefined(this.autoflexCallback)) {
          this.autoflexCallback(this._token);
        }
      }, 'Autoflex');
      autoflexMenuItem.disabledCallback = () => !this.enableAutoflex;
      this.draftMenu.addItem(autoflexMenuItem, 1);
    }
  }

  private addSparePartSupplier(autoflexEnabledDTO: AutoflexEnabledDTO, calculationResultDTO: CalculationResponseDTO, autoflexOnVkTilbud: AutoflexEnabledDTO): void {
    if (this.isVK) {
      this.enableSparePartSupplier = calculationResultDTO.calculateSucceeded && autoflexEnabledDTO.enabled;
    } else {
      this.enableSparePartSupplier = calculationResultDTO.calculateSucceeded
        && autoflexEnabledDTO.enabled
        && ReportCategory.canAutoflex(ReportKey.fromReportWidePk(this.clientStateSub.getValue().taksRapportKey).reportType);
    }
    let autoflexV2MenuItem: LcActionMenuItem;
    if (this.isVK || !autoflexOnVkTilbud.enabled) {
      autoflexV2MenuItem = new LcActionMenuItem('fa-car', () => {
        if (!isNullOrUndefined(this.sparePartSupplierCallback)) {
          this.sparePartSupplierCallback(this._token);
        }
      }, 'Autoflex V2');
    } else {
      autoflexV2MenuItem = new LcActionMenuItem('fa-car', null, 'Autoflex V2');
      autoflexV2MenuItem.subMenuItems = [new LcActionSubMenuItem('Vis oprindeligt udbud', 'fp-green-light', () => {
        if (!isNullOrUndefined(this.sparePartSupplierOriginalCallback)) {
          this.sparePartSupplierOriginalCallback(this.token);
        }
      }),
        new LcActionSubMenuItem('Hent nyt udbud', 'fp-blue-light', () => {
          if (!isNullOrUndefined(this.sparePartSupplierCallback)) {
            this.sparePartSupplierCallback(this._token);
          }
        })];
    }
    autoflexV2MenuItem.disabledCallback = () => !this.enableSparePartSupplier;
    this.draftMenu.addItem(autoflexV2MenuItem, 1);
  }

  activateTap(printTap: PrintTapModel): void {
    //only do something if tap isn't already active
    if (!printTap.active) {
      this.allTaps.map(tap => tap.active = false);
      printTap.active = true;
    }
  }

  private extractCalculationPrint(calculationResponseDto: CalculationResponseDTO): string {
    let calculationPrint: string;
    if (!calculationResponseDto.versionAccepted) {
      calculationPrint = 'Kladden er lavet med en for gammel version af programmet. \nLog ud og log ind igen. \nHerefter kan kladden beregnes og godkendes.';
    } else {
      calculationPrint = calculationResponseDto.printResult;
    }
    return calculationPrint;
  }
}
