import {PoliceInfo} from '../../draft/model/person-info.model';
import {PolicyDTO} from '../dto/policy-dto.model';

export class ClientStatePolicyDTO extends PoliceInfo {
  empty = false;
  companyCode: string;
  kundenr: string;
  forbehold: string;
  addressLine3: string;
  bilmodel: string;
  stelnr: string;
  foersteregDato: number;
  ikrafttraedelsesdato: number;
  afgangsdato: number;
  skadeDato: number;
  senesteSkadedato: number;
  text: string[];
  daekningsomfangkode: string;
  daekningsomfang: string;
  leasingNavn: string;
  leasingAdrLinie1: string;
  leasingAdrLinie2: string;
  leasingPostnr: string;
  leasingBy: string;
  leasingCvrNummer: string;
  momspligtig: string;
  specialVilkaar: string;
  antalPoster: number;

  static empty(): ClientStatePolicyDTO {
    const response = new ClientStatePolicyDTO();
    response.empty = true;
    response.policenr = '';
    response.kundenr = '';
    response.skadenr = '';
    response.selvrisiko = null;
    response.postNr = '';
    response.addressLine1 = '';
    response.addressLine2 = '';
    response.addressLine3 = '';
    response.leasingNavn = '';
    response.leasingPostnr = '';
    response.leasingNavn = '';
    response.leasingAdrLinie1 = '';
    response.leasingAdrLinie2 = '';
    response.regNr = '';
    response.stelnr = '';
    return response;
  }

  public static fromPolicy(policy: PolicyDTO): ClientStatePolicyDTO {
    const clientStatePolicy = new ClientStatePolicyDTO();
    clientStatePolicy.companyCode = policy.companyCode;
    clientStatePolicy.forbehold = policy.conditioned ? 'J' : 'N';
    clientStatePolicy.addressLine1 = policy.policyHolder?.address;
    clientStatePolicy.addressLine2 = policy.policyHolder?.address2;
    clientStatePolicy.bilmodel = policy.vehicle?.model;
    clientStatePolicy.stelnr = policy.vehicle?.vin;
    clientStatePolicy.foersteregDato = policy.vehicle?.firstRegistrationAt;
    clientStatePolicy.ikrafttraedelsesdato = policy.startAt;
    clientStatePolicy.afgangsdato = policy.endAt;
    clientStatePolicy.skadeDato = policy.incident?.incidentAt;
    clientStatePolicy.senesteSkadedato = policy.lastIncidentAt;
    clientStatePolicy.daekningsomfangkode = policy.coverage;
    clientStatePolicy.daekningsomfang = policy.coverageDescription;
    clientStatePolicy.leasingNavn = policy.vehicleOwner?.name;
    clientStatePolicy.leasingAdrLinie1 = policy.vehicleOwner?.address;
    clientStatePolicy.leasingAdrLinie2 = policy.vehicleOwner?.address2;
    clientStatePolicy.leasingPostnr = policy.vehicleOwner?.zipCode;
    clientStatePolicy.leasingBy = policy.vehicleOwner?.city;
    clientStatePolicy.leasingCvrNummer = policy.vehicleOwner?.vatNumber;
    clientStatePolicy.postNr = policy.policyHolder?.zipCode;
    clientStatePolicy.navn = policy.policyHolder?.name;
    clientStatePolicy.by = policy.policyHolder?.city;
    clientStatePolicy.policenr = policy.policyNumber;
    clientStatePolicy.kundenr = policy.customerNumber;
    clientStatePolicy.skadenr = policy.incident?.incidentNumber;
    clientStatePolicy.selvrisiko = String(policy.noClaimsFee);
    clientStatePolicy.regNr = policy.vehicle?.licensePlate;
    clientStatePolicy.text = policy.text;
    clientStatePolicy.momspligtig = policy.subjectToVat;
    clientStatePolicy.specialVilkaar = policy.specialConditionsDescription;
    clientStatePolicy.antalPoster = policy.numberOfRecords;
    return clientStatePolicy;
  }

}
