import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UserDTO} from '../../../shared/dto/user-dto.model';
import {PrincipalService} from '../../../shared';
import {ExtendableComposeMessagePopupComponent} from '../../../shared/modals/compose-message/extendable-compose-message-popup.component';
import {ForwardDraftModel} from '../../model/forward-draft.model';
import {UserTextLibraryService} from '../../../shared/service/user-text-library.service';
import {RapportType} from '../../../shared/model/rapport-type.model';

@Component({
  templateUrl: './forward-draft-popup.component.html'
})
export class ForwardDraftPopupComponent extends ExtendableComposeMessagePopupComponent {
  @Input() users: UserDTO[];
  @Input() userDescription: string;
  @Output() onConfirm = new EventEmitter<ForwardDraftModel>();
  selectedUser: UserDTO;
  reportType: RapportType;

  constructor(private activeModal: NgbActiveModal,
              protected modalService: NgbModal,
              protected userTextLibraryService: UserTextLibraryService,
              protected principalService: PrincipalService) {
    super(modalService, principalService, userTextLibraryService);
  }

  confirmDisabled(): boolean {
    return !this.selectedUser || (this.sendMessage && !this.isMessageValid());
  }

  cancel(): void {
    this.activeModal.dismiss('cancel');
  }

  confirmOffer(): void {
    const message = new ForwardDraftModel();
    message.selectedUser = this.selectedUser;
    message.title = this.messageTitle;
    message.text = this.messageText;
    message.sendMessage = this.sendMessage;
    this.onConfirm.emit(message);
    this.activeModal.dismiss('confirm');
  }
}
