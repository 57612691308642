<p>
    Autotaks anvender cookies der hjælper med at gøre applikationen brugbar ved at aktivere grundlæggende
    funktioner, såsom side-navigation og adgang til sikre områder af hjemmesiden. Hjemmesiden kan ikke fungere
    optimalt uden disse cookies.
</p>

<p>
    Vi gemmer dine foretrukne indstillinger og valg på hjemmesiden. Det gør vi for at kunne vise dig
    den version af hjemmesiden, der passer til dine præferencer.
</p>

<p>
    Der er ingen personlige oplysninger gemt i vores cookies, og de kan ikke indeholde virus.
</p>