import {Component} from '@angular/core';
import {ModelHelpText} from '../../../shared/makes/dto/model-help-text.model';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './model-help-text-popup.component.html'
})
export class ModelHelpTextPopupComponent {
  modelHelpText: ModelHelpText;

  constructor(public activeModal: NgbActiveModal) {
  }
}
