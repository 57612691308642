<ngb-accordion [closeOthers]="true" (panelChange)="beforeChange($event)">
    <ngb-panel *ngFor="let section of reportDiffResult.sections" id="{{section.id}}">
        <ng-template ngbPanelTitle>
            <div>{{section.title}}&nbsp;<i class="fa fa-exclamation-circle at-icon" *ngIf="section.hasChanges"></i>
            </div>
        </ng-template>
        <ng-template ngbPanelContent>
            <div class="row">
                <div class="col-md-12">
                    <nav class="nav nav-tabs">
                        <button (click)="activate(true)" [ngClass]="{'active' : leftActive}"
                                class="text-sm-center nav-link clickable">{{section.left.widePk}}</button>
                        <button (click)="switchActivated()"
                                class="text-sm-center nav-link clickable"><i class="fa fa-arrows-h at-icon"></i>
                        </button>
                        <button (click)="activate(false)" [ngClass]="{'active' : !leftActive}"
                                class="text-sm-center nav-link clickable">{{section.right.widePk}}</button>
                    </nav>
                </div>
            </div>
            <div class="row" *ngIf="leftActive">
                <div class="col-md-12 pt-1">
                    <lc-print-viewer [print]="section.left.content"></lc-print-viewer>
                </div>
            </div>
            <div class="row" *ngIf="!leftActive">
                <div class="col-md-12 pt-1">
                    <lc-print-viewer [print]="section.right.content"></lc-print-viewer>
                </div>
            </div>
        </ng-template>
    </ngb-panel>
</ngb-accordion>