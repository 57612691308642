import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';
import {StringUtils} from '../../../../shared/utils/string-utils';

@Directive({
  selector: '[lcSelvrisikoValidation]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: SelvrisikoValidationDirective, multi: true}
  ]
})
export class SelvrisikoValidationDirective implements Validator {
  validate(c: AbstractControl): ValidationErrors | null {
    const value = c.value;
    if (!StringUtils.isEmpty(value)) {
      const result = new RegExp('^[0-9]*$').test(value);
      if (!result) {
        return {
          selvrisiko: {message: 'Selvrisiko kan kun bestå af heltal'}
        };
      }
    }
    return null;
  }
}
