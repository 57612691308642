<div class="modal-header pre-size header-style" style="text-align: center">
    <h3 class="text-truncate">
        <i class="fa fa-lg fa-question-circle" [ngClass]="titleIconClass()"></i>{{title()}}
    </h3>
    <div aria-label="Close" data-dismiss="modal" class="btn-close clickable" (click)="close()"></div>
</div>

<div class="modal-content">
    <div class="modal-body modal-body-style">
        <div class="container-fluid">
            <div [innerHTML]="body()"></div>
        </div>
    </div>
</div>

<div class="modal-footer">
    <div class="full-width">
        <div class="pull-right ps-1">
            <button class="btn btn-lg btn-primary"
                    (click)="close()">Luk</button>
        </div>
    </div>
</div>
