import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HomeModule} from './home/home.module';
import {ReportModule} from './report/report.module';
import {BootstrapGrowlModule} from './shared/ui/ngx-bootstrap-growl';
import {DraftModule} from './draft/draft.module';
import {AttachmentModule} from './shared/ui/attachment/attachment.module';
import {PoliceModule} from './police/police.module';
import {SearchModule} from './search/search.module';
import {RequisitionModule} from './shared/modals/requisition/requisition.module';
import {AboutModule} from './about/about.module'
import {SettingsModule} from './settings/settings.module';
import {ErrorComponent, MainComponent} from './layouts';
import {LayoutRoutingModule} from './layouts/layout-routing.module'
import {CustomErrorHandler} from './shared/service/core/custom-error-handler.service';
import {SharedModule} from 'app/shared/shared.module';
import {registerLocaleData} from '@angular/common';
import localeDa from '@angular/common/locales/da';
import localeDaExtra from '@angular/common/locales/extra/da';
import {PrintModule} from './shared/modals/print/print.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ErrorHandlerInterceptor} from 'app/shared/interceptor/error-handler.interceptor';
import {XHRInterceptor} from 'app/shared/interceptor/xhr.interceptor';
import {FaqModule} from './faq/faq.module';
import {DataTablesModule} from 'angular-datatables';
import {StatisticModule} from './statistic/statistic.module';
import smoothscroll from 'smoothscroll-polyfill';
import {SysadminRoutingModule} from './sysadmin/sysadmin-routing.module';
import {OfferRoutingModule} from './offer/offer-routing.route';
import {MessageRoutingModule} from './message/message-routing.module';
import {AdminWorkshopRoutingModule} from './admin-workshop/admin-workshop-routing.module';
import {BusinessToBusinessRoutingModule} from './b2b/business-to-business-routing.module';
import {AdminRoutingModule} from './admin/admin-routing.module';
import {NgSelectModule} from '@ng-select/ng-select';
import {PendingChangesGuard} from './shared/guard/pending-changes.guard';
import {RoadmapModule} from './roadmap/roadmap.module';
import {OAuthInterceptor} from './shared/o-auth/forsi/o-auth-http.interceptor';
import {MsalInterceptor, MsalModule, MsalRedirectComponent, MsalService} from '@azure/msal-angular';
import {NewsModule} from './news/news.module';
import {NonStandardPositionsModule} from './non-standard-positions/non-standard-positions.module';
import {ProductModule} from './product/product.module';

registerLocaleData(localeDa, 'Da', localeDaExtra);
smoothscroll.polyfill();

@NgModule({
  imports: [
    BrowserModule,
    DataTablesModule,
    NgSelectModule,
    LayoutRoutingModule,
    SharedModule,
    HomeModule,
    ReportModule,
    DraftModule,
    StatisticModule,
    AttachmentModule,
    PoliceModule,
    SearchModule,
    SettingsModule,
    RequisitionModule,
    PrintModule,
    AboutModule,
    NewsModule,
    FaqModule,
    OfferRoutingModule,
    BootstrapGrowlModule,
    HttpClientModule,
    SysadminRoutingModule,
    AdminRoutingModule,
    AdminWorkshopRoutingModule,
    MessageRoutingModule,
    BusinessToBusinessRoutingModule,
    RoadmapModule,
    NonStandardPositionsModule,
    ProductModule,
    MsalModule //The Msal is configured in main.ts after we have fetched the configuration
  ],
  declarations: [
    MainComponent,
    ErrorComponent
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'da-DK'},
    {provide: HTTP_INTERCEPTORS, useClass: XHRInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: OAuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true},
    {provide: ErrorHandler, useClass: CustomErrorHandler},
    {provide: PendingChangesGuard, useClass: PendingChangesGuard},
    MsalService
  ],
  bootstrap: [MainComponent, MsalRedirectComponent]
})
export class AppModule {
}

// Setting default settings for datatables
$.extend($.fn.dataTable.defaults, {
    searching: true,
    stateSave: true,
    stateDuration: -1, //-1 indicate session storage
    lengthChange: false,
    pageLength: 15,
    info: false,
    language: {
      search: '',
      searchPlaceholder: 'Filter',
      emptyTable: 'Ingen data',
      thousands: '.',
      loadingRecords: 'Henter...',
      processing: 'Henter...',
      zeroRecords: 'Ingen rækker fundet',
      paginate: {
        first: '',
        last: '',
        next: '>>',
        previous: '<<'
      }
    }
  }
);
