<div class="col-12 mb-4">
    <h3>Opret eller rediger andre rapporttyper tilhørende <b>{{reportKey | lcForsiReportKey}}</b></h3>
</div>

<div class="col-12 pb-3">
    <div class="row mb-1 mt-2" *ngFor="let potentialLinkedReportType of potentialLinkedReportTypes">
        <div class="col-12 col-sm-5 col-md-4 col-xl-3"><b>{{potentialLinkedReportType.name}}</b></div>
        <div class="col">
            <button class="btn btn-primary btn-new-report me-1 mt-1 text-truncate" *ngIf="allowCreation(potentialLinkedReportType)"
                    (click)="select(potentialLinkedReportType)"
                    [disabled]="disableButtons">
                <i class="fa fa-plus fa-1" aria-hidden="true"></i>Opret ny <b>{{potentialLinkedReportType.type}}</b>
                rapport
            </button>

            <button class="btn btn-primary btn-new-report disabled me-1 mt-1 text-truncate" *ngIf="!allowCreation(potentialLinkedReportType)" disabled>Ny
                rapport ikke muligt
            </button>

            <button class="btn btn-primary mt-1 btn-new-report text-truncate"
                    *ngFor="let existing of potentialLinkedReportType.existingClientStates"
                    (click)="navigateToClientState(existing)"
                    [disabled]="disableButtons">{{isOwnClientState(existing) ? 'Rediger' : 'Overtag'}} <b>{{existing.key}}</b>
            </button>
        </div>
    </div>
</div>



