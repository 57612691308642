import {RouterModule, Routes} from '@angular/router';
import {UserRouteAccessService} from '../shared';
import {AppRole} from '../shared/service/auth/app-roles.model';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

export const SYSADMIN_ROUTE: Routes = [
  {
    path: 'sysadmin',
    loadChildren: () => import('./sysadmin.module').then(m => m.SysadminModule),
    data: {
      authorities: [AppRole.SYSADMIN],
      breadcrumb: 'Sysadmin'
    },
    canActivate: [UserRouteAccessService],
  }
];

/**
 * Routing module enabling lazyloading (through the loadChildren function) of the entire sysadmin module.
 */
@NgModule({
  imports: [
    RouterModule.forChild(SYSADMIN_ROUTE)
  ],
  declarations: [],
  exports: [],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SysadminRoutingModule {

}
