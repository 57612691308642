export class PrintLayoutConfigurationDTO {
  form18ConfigTopmargin: number;
  form18ConfigVenstremargin: number;
  udskriftConfigTopmargin: number;
  udskriftConfigVenstremargin: number;
  sektionH: boolean;
  sektionH1: boolean;
  sektionH2: boolean;
  sektionH3: boolean;
  sektionH4: boolean;
  sektionH5: boolean;
  taksatorInfoPaaLakblad: boolean;
}
