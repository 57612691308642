export class AudatexSettlementPeriodDTO {
  id: number;
  settledFor: Date;
  state: AudatexSettlementPeriodState;
  createdAt: Date;
  startedAt: Date;
  doneAt: Date;
}

export enum AudatexSettlementPeriodState {
  NOT_PROCESSED = 'NOT_PROCESSED',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  FAILED = 'FAILED'
}
