import {Component, EventEmitter, Output} from '@angular/core';
import {NavDynamicDataService} from '../nav-dynamic-data.service';

@Component({
  selector: 'lc-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['topbar.component.scss']
})
export class TopbarComponent {

  @Output() closeSidebarMenu = new EventEmitter();

  offerCount$ = this.navDynamicDataService.offerCount$();
  messageCount$ = this.navDynamicDataService.messageCount$();

  constructor(private navDynamicDataService: NavDynamicDataService) {
  }

}
