import {Injectable} from '@angular/core';
import {OfferDTO} from '../../offer/dto/offer-dto.model';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {AcceptOfferResultDTO} from '../../offer/dto/accept-offer-result-dto.model';
import {ForwardOfferDTO} from '../../offer/dto/forward-offer-dto.model';
import {UpdateOfferResultDTO} from '../../offer/dto/update-offer-result-dto.model';
import {UpdateOfferDTO} from '../../offer/dto/update-offer-dto.model';
import URLBuilder from '../utils/url-builder';
import {LicencePlateLinkOfferDTO} from '../../offer/dto/licence-plate-link-offer-dto.model';
import {ReportRelatedToOfferByVinDTO} from '../../report/model/report-related-to-offer-by-vin-dto.model';
import {PolicyDTO} from '../dto/policy-dto.model';
import {BreadcrumbService} from '../ui/breadcrumb/breadcrumb.service';
import {TransferableAssessorOrgDTO} from '../../offer/dto/transferable-assessor-org.dto.model';
import {TransferOfferDTO} from '../../offer/dto/transfer-offer-dto.model';
import {AppVisibilityService} from './core/app-visibility.service';

@Injectable({
  providedIn: 'root',
})
export class OfferService {
  private serviceUrl = 'offer/';
  private offersCount = new BehaviorSubject<number>(null);
  private updateOffersSubscription: Subscription;

  constructor(private http: HttpClient, private breadcrumbService: BreadcrumbService, private appVisibilityService: AppVisibilityService) {
  }

  public getOffersCount(): Observable<number> {
    return this.offersCount.asObservable();
  }

  private fetchOffersCount(): void {
    this.http.get<number>(this.serviceUrl + 'count').subscribe((count) => {
      this.offersCount.next(count)
    });
  }

  public startOfferCountBackgroundFetcher(): void {
    if (!this.updateOffersSubscription) {
      this.updateOffersSubscription = this.appVisibilityService.forsiVisibilityInterval(0, 60000).subscribe(() => {
        this.fetchOffersCount();
      });
    }
  }

  public stopOfferCountBackgroundFetcher(): void {
    if (this.updateOffersSubscription) {
      this.updateOffersSubscription.unsubscribe();
      this.updateOffersSubscription = null;
    }
  }

  public getOffers(): Observable<OfferDTO[]> {
    return this.http.get<OfferDTO[]>(this.serviceUrl).pipe(map(response => {
      this.offersCount.next(response.length);
      return response;
    }));
  }

  public getOfferByPostBox(username: string): Observable<OfferDTO> {
    return this.http.get<OfferDTO>(this.serviceUrl + 'postbox/' + username);
  }

  public accept(offerId: number, offerDTO: UpdateOfferDTO): Observable<AcceptOfferResultDTO> {
    return this.http.put<AcceptOfferResultDTO>(this.serviceUrl + offerId + '/accept', offerDTO).pipe(map(response => {
      this.fetchOffersCount();
      return response;
    }));
  }

  public forward(offerId: number, forwardOffer: ForwardOfferDTO): Observable<UpdateOfferResultDTO> {
    return this.http.put<UpdateOfferResultDTO>(this.serviceUrl + offerId + '/forward', forwardOffer).pipe(map(response => {
      this.fetchOffersCount();
      return response;
    }));
  }

  transfer(offerId: number, transferOffer: TransferOfferDTO): Observable<UpdateOfferResultDTO> {
    return this.http.put<UpdateOfferResultDTO>(this.serviceUrl + offerId + '/transfer', transferOffer).pipe(map(response => {
      this.fetchOffersCount();
      return response;
    }));
  }

  public reject(offerId: number, offerDTO: UpdateOfferDTO): Observable<UpdateOfferResultDTO> {
    return this.http.put<UpdateOfferResultDTO>(this.serviceUrl + offerId + '/reject', offerDTO).pipe(map(response => {
      this.fetchOffersCount();
      return response;
    }));
  }

  public getOffer(offerId: number): Observable<OfferDTO> {
    return this.http.get<OfferDTO>(this.serviceUrl + offerId).pipe(
      tap(offer => {
        //we fill in the breadcrumb context each time we request a report by its reportid
        this.breadcrumbService.setOffer(offer);
      }));
  }

  public getOffersLinkedByLicencePlate(offerId: number): Observable<LicencePlateLinkOfferDTO[]> {
    return this.http.get<LicencePlateLinkOfferDTO[]>(this.serviceUrl + offerId + '/licenseplatelink');
  }

  public getExternalValidationUrl(offerId: number): string {
    return new URLBuilder().getRestServicePath() + this.serviceUrl + offerId + '/externalvalidationpdf/';
  }

  public getExternalValidationUrlForLicencePlateLink(offerId: number, licensePlateLinkId: number): string {
    return new URLBuilder().getRestServicePath() + this.serviceUrl + offerId + '/licenseplatelink/' + licensePlateLinkId + '/externalvalidationpdf';
  }

  public getReportListRelatedToOfferByVin(offerId: number): Observable<ReportRelatedToOfferByVinDTO[]> {
    return this.http.get<ReportRelatedToOfferByVinDTO[]>(this.serviceUrl + offerId + '/relatedreportsbyvin');
  }

  public getOfferPolicy(offerId: any, reportId: any): Observable<PolicyDTO> {
    return this.http.get<PolicyDTO>(this.serviceUrl + offerId + '/report/' + reportId + '/policy');
  }

  getTransferableAssessorOrgs(offerId: number): Observable<TransferableAssessorOrgDTO[]> {
    return this.http.get<TransferableAssessorOrgDTO[]>(this.serviceUrl + offerId + '/transferable-assessor-orgs');
  }

}
