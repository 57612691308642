import {Component, OnDestroy, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Subject} from 'rxjs';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './reorder-button-popup.component.html'
})
export class ReorderButtonPopupComponent implements OnDestroy {
  private unsubscribe$ = new Subject<void>();
  title: string;
  label: string;
  position: number;

  @ViewChild('form', {static: true}) public form: NgForm;

  constructor(public activeModal: NgbActiveModal) {
  }

  public init(title: string, label: string, current: number): void {
    this.title = title;
    this.position = current;
    this.label = label;
  }

  public reorder(): void {
    this.activeModal.close(this.position);
  }

  public disableReorder(): boolean {
    return !this.form.valid;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
