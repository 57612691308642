<fieldset ngForm="f" #f="ngForm">
    <div class="row">
        <div class="col-md-6">
            <div class="row pb-3">
                <div class="col-4"></div>
                <div class="col-8">Adhoc værkstedsoplysninger</div>
            </div>
            <div class="row pb-3">
                <label class="col-form-label col-4" for="vkname">Navn</label>
                <div class="col">
                    <input class="form-control text-uppercase" maxlength="35" type="text" [(ngModel)]="details.adhocVkDetails.name"
                        [required]="isNameRequired()" id="vkname" name="vkname" />
                </div>
            </div>
            <div class="row pb-3">
                <label class="col-form-label col-4" for="vkadress1">Adresse 1</label>
                <div class="col">
                    <input class="form-control text-uppercase" maxlength="35" type="text" [(ngModel)]="details.adhocVkDetails.address1"
                        id="vkadress1" name="vkadress1" />
                </div>
            </div>
            <div class="row pb-3">
                <label class="col-form-label col-4" for="vkadress2">Adresse 2</label>
                <div class="col">
                    <input class="form-control text-uppercase" maxlength="35" type="text" [(ngModel)]="details.adhocVkDetails.address2"
                        id="vkadress2" name="vkadress2" />
                </div>
            </div>
            <div class="row pb-3">
                <label class="col-form-label col-4" for="vkpostalcode">Postnr</label>
                <div class="col">
                    <input class="form-control" maxlength="6" lcInputMask="int6" type="tel"
                        [(ngModel)]="details.adhocVkDetails.postalcode" (change)="postNrChanged()" id="vkpostalcode"
                        name="vkpostalcode" />
                </div>
            </div>
            <div class="row pb-3">
                <label class="col-form-label col-4" for="vkcity">By</label>
                <div class="col">
                    <input class="form-control" maxlength="35" type="text" [(ngModel)]="by" id="vkcity" readonly
                        name="vkcity" />
                </div>
            </div>
            <div class="row pb-3">
                <label class="col-form-label col-4" for="vkphoneno">Telefon</label>
                <div class="col">
                    <input class="form-control text-uppercase" maxlength="8" lcInputMask="int8" type="tel"
                        [(ngModel)]="details.adhocVkDetails.phoneno" id="vkphoneno" name="vkphoneno" />
                </div>
            </div>
            <div class="row pb-3">
                <label class="col-form-label col-4" for="vkemail">Email</label>
                <div class="col">
                    <input class="form-control text-uppercase" maxlength="35" type="text" [(ngModel)]="details.adhocVkDetails.email"
                        id="vkemail" name="vkemail" />
                </div>
            </div>
        </div>
    </div>
</fieldset>
