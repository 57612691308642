import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {QapterPrepareModeDTO} from '../dto/qapter-prepare-mode-dto.model';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class QapterPrepareModeService {
  private readonly rootURL = 'qapter/prepare';

  constructor(private http: HttpClient) {
  }

  prepareReportMode(reportKey: string): Observable<QapterPrepareModeDTO> {
    return this.prepareMode('report', reportKey);
  }

  prepareAdviseMode(adviseId: number): Observable<QapterPrepareModeDTO> {
    return this.prepareMode('advise', adviseId);
  }

  prepareDraftMode(clientStateToken: string): Observable<QapterPrepareModeDTO> {
    return this.prepareMode('draft', clientStateToken);
  }

  private prepareMode(mode: string, identifier: string | number): Observable<QapterPrepareModeDTO> {
    return this.http.get<QapterPrepareModeDTO>(`${this.rootURL}/${mode}/${identifier}`);
  }
}
