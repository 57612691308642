import {Component, OnDestroy} from '@angular/core';
import {DeliveryNoteStateService} from './delivery-note-state.service';
import {ActivatedRoute} from '@angular/router';
import {ReportService} from '../../../report/service/report.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

/**
 * The purpose of this wrapper component is to provide services that lives inside the delivery note feature,
 * but is destroyed as soon as the user navigates away from the delivery note feature.
 * possibly see: https://github.com/angular/angular/issues/11228#issuecomment-327255605
 */
@Component({
  template: '<router-outlet></router-outlet>',
  providers: [DeliveryNoteStateService]
})
export class DeliveryNoteReportWrapperComponent implements OnDestroy {
  private unsubscribe$ = new Subject<void>();
  private reportId: number;

  //necessary as this is responsible for initializing the deliveryNoteService once for all subroute
  constructor(private deliveryNoteService: DeliveryNoteStateService,
              protected reportService: ReportService,
              private route: ActivatedRoute) {
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe(params => {
      this.reportId = +params['reportId'];
      this.deliveryNoteService.initializeData(this.reportId, reportService);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
