import {Component, Input} from '@angular/core';

@Component({
  template: '<div *ngIf="!truncate(); else truncateDisplay">{{value}}</div><ng-template #truncateDisplay><div style="width: fit-content" [ngbTooltip]="value" placement="end">{{truncatedValue()}}</div> </ng-template>',
  selector: 'lc-truncate-display'
})
export class TruncateDisplayComponent {
  @Input() value: string;
  private readonly limit = 4;

  truncate(): boolean {
    return !!this.value && this.value.length > this.limit;
  }

  truncatedValue(): string {
    return this.value.slice(0, this.limit) + '...';
  }
}
