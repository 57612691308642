import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

import {Injectable} from '@angular/core';
import {AccessRole, UserTokenDTO} from '../dto/token/user-token-dto.model';
import {TokenUserColleagueDTO} from '../dto/token/user-token-colleague-dto.model';
import {tap} from 'rxjs/operators';
import {BreadcrumbService} from '../ui/breadcrumb/breadcrumb.service';

@Injectable()
export class UserTokenService {
  userTokenUrl = 'user';

  constructor(private http: HttpClient, private breadcrumbService: BreadcrumbService) {
  }

  private getBaseUrl(userId: number): string {
    return `user/${userId}/token`
  }

  public getList(userId: number): Observable<UserTokenDTO[]> {
    return this.http.get<UserTokenDTO[]>(this.getBaseUrl(userId));
  }

  public create(userId: number, token: UserTokenDTO): Observable<UserTokenDTO> {
    return this.http.post<UserTokenDTO>(this.getBaseUrl(userId), token);
  }

  public update(userId: number, token: UserTokenDTO): Observable<UserTokenDTO> {
    const baseUrl = this.getBaseUrl(userId);
    return this.http.put<UserTokenDTO>(`${baseUrl}/${token.id}`, token);
  }

  public generateNewRefreshToken(userId: number, token: UserTokenDTO): Observable<UserTokenDTO> {
    const baseUrl = this.getBaseUrl(userId);
    return this.http.put<UserTokenDTO>(`${baseUrl}/${token.id}/generate-new-token`, null);
  }

  public disable(userId: number, token: UserTokenDTO): Observable<UserTokenDTO> {
    const baseUrl = this.getBaseUrl(userId);
    return this.http.put<UserTokenDTO>(`${baseUrl}/${token.id}/disable`, null);
  }

  public enable(userId: number, token: UserTokenDTO): Observable<UserTokenDTO> {
    const baseUrl = this.getBaseUrl(userId);
    return this.http.put<UserTokenDTO>(`${baseUrl}/${token.id}/enable`, null);
  }

  getAccessRoleOptions(userId: number): Observable<AccessRole[]> {
    const baseUrl = this.getBaseUrl(userId);
    return this.http.get<UserTokenDTO[]>(`${baseUrl}/access-role-options`);
  }

  getColleagues(userId: number): Observable<TokenUserColleagueDTO[]> {
    const baseUrl = this.getBaseUrl(userId);
    return this.http.get<TokenUserColleagueDTO[]>(`${baseUrl}/colleague`);
  }

  getColleague(userId: number, colleagueId): Observable<TokenUserColleagueDTO> {
    const baseUrl = this.getBaseUrl(userId);
    return this.http.get<TokenUserColleagueDTO>(`${baseUrl}/colleague/${colleagueId}`).pipe(
      tap(user => {
        //we fill in the breadcrumb context each time we request a Colleague
        this.breadcrumbService.setUser(user.id, user.username);
      })
    );
  }
}
