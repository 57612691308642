import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {LcActionMenu, LcActionMenuItem} from '../../shared/ui/bottombar/lc-action-menu.model';
import {ForsiReportKeyPipe} from '../../shared/pipe/forsi-report-key.pipe';
import {ForsiReportKeyShortPipe} from '../../shared/pipe/forsi-report-key-short.pipe';
import {mergeMap, takeUntil} from 'rxjs/operators';
import {ClientStateDTO} from '..';
import {ClientNavigationService} from '../../shared/service/client-navigation.service';
import {DraftService} from '../service/draft.service';
import {ReportCategory} from '../../shared/model/report-category.model';
import {Subject} from 'rxjs';

@Component({
  templateUrl: './new-report-type-from-draft.component.html',
  styleUrls: ['new-report-type-from-draft.scss']
})
export class NewReportTypeFromDraftComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  clientState: ClientStateDTO;
  bottomMenu: LcActionMenu;
  bottomMenuTitle: string;
  bottomMenuShortTitle: string;
  bottomMenuIconClass: string;
  loading = true;

  constructor(private route: ActivatedRoute,
              private navigationService: ClientNavigationService,
              private draftService: DraftService,
              private reportKeyPipe: ForsiReportKeyPipe,
              private reportKeyShortPipe: ForsiReportKeyShortPipe) {
  }

  ngOnInit(): void {
    this.bottomMenu = new LcActionMenu();
    this.bottomMenu.addItem(new LcActionMenuItem('fa-arrow-left', () => {
      this.navigationService.back(['draft']);
    }, 'Tilbage'));


    this.route.parent.params
      .pipe(
        mergeMap(params => this.draftService.getClientState(params['token'])),
        takeUntil(this.unsubscribe$))
      .subscribe(clientState => {
        this.clientState = clientState;
        this.bottomMenuTitle = this.reportKeyPipe.transform(clientState.taksRapportKey);
        this.bottomMenuShortTitle = this.reportKeyShortPipe.transform(clientState.taksRapportKey);
        this.bottomMenuIconClass = ReportCategory.iconCssClass(clientState.schema);
        this.loading = false;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
