import {Component, Input} from '@angular/core';
import {AttachmentMetadataSummary} from '../../../dto/attachment-metadata-summary.model';
import {AttachmentSummaryDTO} from '../../../dto/attachment-summary-dto.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lc-metadata-summary',
  templateUrl: './metadata-summary.component.html',
  styleUrls: [
    'metadata-summary.component.scss'
  ]
})
export class MetadataSummaryComponent {
  @Input() attachmentSummary: AttachmentSummaryDTO;
  @Input() metadataSummary: AttachmentMetadataSummary;

  constructor(private modalService: NgbModal) {
  }

  showMetadataSummaryInfo(summaryInfoPupup: any): void {
    this.modalService.open(summaryInfoPupup)
  }
}
