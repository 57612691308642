import {Component, OnDestroy, OnInit} from '@angular/core';

import {PrincipalService} from 'app/shared';
import {ReportTypeViewModel} from './report-type-view-model.service';
import {SettingsService} from '../../shared/service/settings.service';
import {LcActionMenu, LcActionMenuItem} from '../../shared/ui/bottombar/lc-action-menu.model';
import {DraftCreateViewModel} from './draft-create.view-model';
import {BootstrapAlertType, BootstrapGrowlService} from '../../shared/ui/ngx-bootstrap-growl';
import {DraftService} from '../service/draft.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ReportCategory} from '../../shared/model/report-category.model';
import {of, Subject} from 'rxjs';
import {delay, takeUntil} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {isNullOrUndefined} from '../../shared/utils/object-utils';

@Component({
  templateUrl: './draft-create.component.html',
  styleUrls: [
    '../draft.scss'
  ],
  providers: [SettingsService, DraftCreateViewModel, ReportTypeViewModel]
})
export class DraftCreateComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  draftMenu: LcActionMenu;
  bottomMenuTitle = '';
  bottomMenuShortTitle = '';
  bottomMenuIconClass = null;
  uploadingImage = false;
  loading = false;
  private reportType: ReportCategory;

  constructor(public viewmodel: DraftCreateViewModel,
              private principal: PrincipalService,
              private draftService: DraftService,
              private router: Router,
              private route: ActivatedRoute,
              public reportTypeViewModel: ReportTypeViewModel,
              private bootstrapGrowlService: BootstrapGrowlService) {
  }

  ngOnInit(): void {
    this.reportTypeViewModel.loadReportTypes();
    this.setupDraftActionMenu();
    this.setupObservers();
    this.viewmodel.reset();
  }

  public setupObservers(): void {
    this.reportTypeViewModel.selectedTypeObs()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(reportType => {
        if (isNullOrUndefined(reportType)) {
          this.viewmodel.registerForm(null);
          this.bottomMenuIconClass = '';
          this.reportType = null;
        } else {
          if (!isNullOrUndefined(this.reportType) && this.reportType.usesQapter !== reportType.usesQapter) {
            this.viewmodel.reset();
          }
          this.bottomMenuIconClass = reportType.cssClass;
          this.reportType = reportType;
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
    this.reportTypeViewModel.destroy();
  }

  isQapterAutotaksFlow(): boolean {
    return this.reportType === ReportCategory.AUTOTAKS;
  }

  isVKQapterGlastaksFlow(): boolean {
    return this.reportType === ReportCategory.GLASTAKS;
  }

  isUnitaksFlow(): boolean {
    return this.reportType === ReportCategory.SKAVUTAKS;
  }

  isStortaksFlow(): boolean {
    return this.reportType === ReportCategory.STORTAKS;
  }

  isMCtaksFlow(): boolean {
    return this.reportType === ReportCategory.MCTAKS;
  }

  isCamptaksFlow(): boolean {
    return this.reportType === ReportCategory.CAMPTAKS;
  }

  setupDraftActionMenu(): void {
    this.draftMenu = new LcActionMenu();

    const carDetails = new LcActionMenuItem('fa-cloud-download', () => {
      this.navigateToCarDetails();
      window.scrollTo(0, 0);
    }, 'Bil detaljer');
    carDetails.disabledCallback = () => isNullOrUndefined(this.viewmodel.regNr);
    this.draftMenu.addItem(carDetails);

    const createAndNext = new LcActionMenuItem('fa-arrow-right', () => {
      this.save();
    }, 'Videre til skadeomfang');
    createAndNext.disabledCallback = () => isNullOrUndefined(this.reportType) || isNullOrUndefined(this.viewmodel.form) || !this.viewmodel.form.valid || this.uploadingImage;
    this.draftMenu.addItem(createAndNext);

    const createAndMoreImages = new LcActionMenuItem('fa-image', () => {
      this.saveAndAttachments();
    }, 'Videre til bilag');
    createAndMoreImages.disabledCallback = () => isNullOrUndefined(this.reportType) || isNullOrUndefined(this.viewmodel.form) || !this.viewmodel.form.valid || this.uploadingImage;
    this.draftMenu.addItem(createAndMoreImages);

    const createAndNew = new LcActionMenuItem('fa-plus', () => {
      this.saveAndNew();
    }, 'Opret og ny');
    createAndNew.disabledCallback = () => isNullOrUndefined(this.reportType) || isNullOrUndefined(this.viewmodel.form) || !this.viewmodel.form.valid || this.uploadingImage;
    this.draftMenu.addItem(createAndNew);
  }

  navigateToCarDetails(): void {
    this.router.navigate(['car-details', this.viewmodel.regNr], {relativeTo: this.route});
  }

  save(): void {
    this.createDraft('damages');
  }

  saveAndAttachments(): void {
    this.createDraft('attachments');
  }

  saveAndNew(): void {
    this.createDraft(null);
  }

  createDraft(navigateTo: string): void {
    this.loading = true;
    let reportCategory = this.viewmodel.explicitReportCategory;
    if (isNullOrUndefined(reportCategory)) {
      const repairType = this.viewmodel.clientState.vehicle.model.repairType;
      reportCategory = ReportCategory.AUTOTAKS;
      if (repairType === 'L') {
        reportCategory = ReportCategory.AUTOTAKS_LORRY;
      } else if (repairType === 'M') {
        reportCategory = ReportCategory.ASSESSMENT_MCTAKS;
      } else if (repairType === 'E') {
        reportCategory = ReportCategory.ENTREPRENEUR_MATR;
      } else if (repairType === 'D') {
        reportCategory = ReportCategory.ASSESSMENT_CAMPTAKS;
      } else if (repairType === 'I') {
        reportCategory = ReportCategory.ASSESSMENT_MISC;
      } else if (repairType === 'A') {
        reportCategory = ReportCategory.AGRICULTURE_EQPT;
      } else if (repairType === 'B') {
        reportCategory = ReportCategory.ASSESSMENT_BUILDING;
      } else if (repairType === 'Ø') {
        reportCategory = ReportCategory.ASSESSMENT_MOVABLES;
      }

    }
    console.log('Creating client state of category', reportCategory.schema);
    this.viewmodel.clientState.schema = reportCategory.schema;
    if (this.principal.isTaksator()) {
      this.viewmodel.clientState.faktura = true;
    }
    if (this.viewmodel.clientState.ansvarsskade) {
      this.viewmodel.clientState.skadelidte.regNr = this.viewmodel.regNr;
      this.viewmodel.clientState.forsikringstager.regNr = null;
    } else {
      this.viewmodel.clientState.forsikringstager.regNr = this.viewmodel.regNr;
      this.viewmodel.clientState.skadelidte.regNr = null;
    }

    this.uploadingImage = this.viewmodel.containsImage();
    this.draftService.create(reportCategory, this.viewmodel.clientState)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: clientState => {
          this.bootstrapGrowlService.addAlert('Kladden er oprettet', BootstrapAlertType.SUCCESS);

          if (this.viewmodel.containsImage()) {
            this.uploadingImage = true;
            this.bootstrapGrowlService.addAlert('Uploader nummerplade billede', BootstrapAlertType.INFO);
            this.draftService.uploadAttachment(this.viewmodel.ocrImage, clientState.token)
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe(() => {
                this.uploadingImage = false;
                this.doNavigation(navigateTo, clientState.token);
              });
          } else {
            this.doNavigation(navigateTo, clientState.token);
          }
        },
        error: error => {
          if (error instanceof HttpErrorResponse && error.status === 409) {
            this.bootstrapGrowlService.addAlert('Der skete en fejl. Prøv igen!', BootstrapAlertType.WARNING);
          } else {
            this.bootstrapGrowlService.addAlert('Der skete en fejl ved oprettelse af kladden', BootstrapAlertType.DANGER);
          }
        }
      });
  }

  private doNavigation(navigateTo: string, clientStateToken: string): void {
    this.loading = false;
    if (navigateTo) {
      this.router.navigate(['/draft/edit/', clientStateToken], {fragment: navigateTo});
    } else {
      this.viewmodel.reset();
      const currentType = this.reportTypeViewModel.selectedType;
      this.reportTypeViewModel.selectedType = null;
      if (!this.reportTypeViewModel.hasMultiSelection()) {
        const self = this;
        //adding minimal delay to signal as it will allow UI to render in between change of selected type
        of(1).pipe(delay(1), takeUntil(this.unsubscribe$)).subscribe(
          value => self.reportTypeViewModel.selectedType = currentType);
      }
    }
  }
}
