import {CompanyDTO} from '../dto/company-dto.model';

export class AgreementDTO {
  id: number;
  agreementTypeId: number;
  remark: string;
  makeCode: string;
  modelCode: string;
  ageFrom: number;
  ageTo: number;
  value: number;
  valueTypeId: number;
  workshopId: number;
  createdAt: string;
  updatingUserName: string;
  updatingUserId: string;
}

export class AgreementBulkDTO {
  changedAgreements: AgreementDTO[];
  createdAgreements: AgreementDTO[];
  deletedAgreements: AgreementDTO[];
}

export class AgreementTypeDTO {
  id: number;
  description: string;
}

export class AdminAgreementTypeDTO extends AgreementTypeDTO {
  companyCode: string | null;
  valueTypes: AgreementValueDTO[];
}

export class AgreementValueDTO {
  id: number;
  type: string;
  unit: string;
  kode: number;
}

export class AgreementHistoryDTO extends AgreementDTO {
  terminatedAt: number;
  latestChange: Date;
}

export class AgreementChangeDTO {
  company: CompanyDTO;
  latestChange: Date;
  agreements: AgreementHistoryDTO[]
}

export class AgreementPair {
  oldAgreement: AgreementDTO;
  newAgreement: AgreementDTO;

  constructor(oldAgreement: AgreementDTO, newAgreement: AgreementDTO) {
    this.oldAgreement = oldAgreement;
    this.newAgreement = newAgreement;
    // When we save the new agreement, we want to overwrite the old one, thus needing the old IDs
    this.newAgreement.id = oldAgreement.id;
    this.newAgreement.workshopId = oldAgreement.workshopId;
  }
}
