import {ClientStateDetails} from '../model/client-state-details.model';
import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {ContextMenu} from '../../shared/ui/context-menu/context-menu.model';
import {LcActionMenu, LcActionMenuItem} from '../../shared/ui/bottombar/lc-action-menu.model';
import {StringUtils} from '../../shared/utils/string-utils';
import {ForsiReportKeyShortPipe} from '../../shared/pipe/forsi-report-key-short.pipe';
import {ForsiReportKeyPipe} from '../../shared/pipe/forsi-report-key.pipe';
import {FormSaver, SaveFormListener} from '../../shared/service/core/form-saver.service';
import {DraftService} from '../service/draft.service';
import {PrincipalDTO, PrincipalService} from '../../shared';
import {UIUtils} from '../../shared/utils/ui-utils';
import {ObjectUtils} from '../../shared/utils/object-utils';
import {AttachmentSummaryDTO} from '../../shared/dto/attachment-summary-dto.model';
import {BootstrapAlertType, BootstrapGrowlService} from '../../shared/ui/ngx-bootstrap-growl';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, Subject, timer} from 'rxjs';
import {distinctUntilChanged, map, takeUntil, tap} from 'rxjs/operators';
import {DraftEditService} from './draft-edit.service';
import {ReportCategory} from '../../shared/model/report-category.model';
import {ClientNavigationService} from '../../shared/service/client-navigation.service';
import {MessageDTO} from '../../shared/dto/message-dto.model';
import {CreateMessageDTO} from '../../shared/ui/message/dto/create-message-dto.model';
import {RapportType} from '../../shared/model/rapport-type.model';
import {AttachmentDTO} from '../../shared/dto/attachment-dto.model';
import {CopyDraftModalService} from '../service/copy-draft.modal.service';

@Component({
  template: ''
})
// the following tslint rule is disabled, because it does not support inheritance:
// https://github.com/cartant/rxjs-tslint-rules/issues/113
/* eslint-disable */
export abstract class DraftEditFlowComponent implements OnInit, AfterViewInit, OnDestroy, SaveFormListener {
  private _unsubscribe$ = new Subject<void>();
  private REPORT_KEY_TRANSFORM = new ForsiReportKeyPipe();
  attachmentSummary: AttachmentSummaryDTO = null;
  details: ClientStateDetails;
  isLoading = true;
  draftMenu: LcActionMenu = new LcActionMenu();
  contextMenu: ContextMenu;
  bottomMenuTitle = '';
  bottomMenuShortTitle = '';
  bottomMenuIconClass = null;
  principalModel: PrincipalDTO;
  protected messages: MessageDTO[];
  protected sendMessageEnabled: boolean;

  protected constructor(protected formSaver: FormSaver,
                        private reportKeyPipe: ForsiReportKeyPipe,
                        private reportKeyShortPipe: ForsiReportKeyShortPipe,
                        protected draftService: DraftService,
                        protected draftEditService: DraftEditService,
                        protected principal: PrincipalService,
                        protected router: Router,
                        protected route: ActivatedRoute,
                        protected bootstrapGrowlService: BootstrapGrowlService,
                        private el: ElementRef,
                        public clientNavigationService: ClientNavigationService,
                        private copyDraftModalService?: CopyDraftModalService) {
  }

  ngOnInit(): void {
    this.setupContextMenu();
    this.setupDraftActionMenu();
    this.details = new ClientStateDetails();
    this.sendMessageEnabled = false
    this.principal.identity().pipe(takeUntil(this._unsubscribe$)).subscribe((principalModel) => {
      this.principalModel = principalModel;
    });
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  initializeWithClientState(clientStateDetails: ClientStateDetails): void {
    this.attachmentSummary = this.draftEditService.attachmentSummary;
    this.details = clientStateDetails;
    this.setupMenus();
    this.formSaver.init(this);
    this.sendMessageEnabled = StringUtils.isNotEmpty(clientStateDetails.proformaOrdreNummer) && RapportType.extractFrom(clientStateDetails.taksRapportKey || clientStateDetails.proformaOrdreNummer).isOfAny([RapportType.H, RapportType.T])
    this.updateAttachmentSummary(clientStateDetails.token);
  }

  possibleNavigateToFragmentUrlPart(fragment: string): void {
    if (ObjectUtils.exists(fragment)) {
      // do the scroll in a timer so a possible 'isLoading=false' will render the page before trying to scroll to anchor
      const displayTimeout = timer(0);
      displayTimeout.pipe(takeUntil(this._unsubscribe$)).subscribe(() => {
        new UIUtils().scrollToElementId([fragment], false);
      });
    }
  }

  getRegistrationNumber(details: ClientStateDetails): string {
    return details.ansvarsskade ? details.skadelidte.regNr : details.forsikringstager.regNr
  }

  getAttachmentReportKey(details: ClientStateDetails): string {
    let reportKey = details.taksRapportKey;
    if (this.principal.isVK() && StringUtils.isNotEmpty(details.proformaOrdreNummer)) {
      reportKey = details.proformaOrdreNummer;
    }
    return reportKey;
  }

  updateAttachmentSummary(clienstateToken: string): void {
    if (!this.attachmentSummary) {
      this.draftService.getAttachmentSummary(clienstateToken).pipe(takeUntil(this._unsubscribe$)).subscribe(attachmentSummary => {
        this.attachmentSummary = attachmentSummary;
      });
    }
  }

  getAttachments(clienstateToken: string): Observable<AttachmentDTO[]> {
    return this.draftService.getAttachments(clienstateToken).pipe(takeUntil(this._unsubscribe$));
  }

  setupMenus(): void {
    const reportKey = !StringUtils.isEmpty(this.details.taksRapportKey) ? this.details.taksRapportKey : this.details.proformaOrdreNummer;
    this.bottomMenuTitle = this.reportKeyPipe.transform(reportKey);
    this.bottomMenuShortTitle = this.reportKeyShortPipe.transform(reportKey);
    this.bottomMenuIconClass = ReportCategory.iconCssClass(this.details.schema);
    this.contextMenu.updateMenuHighlights(null);
  }

  isInvoiceAndTotaldamageOrRebuild(): boolean {
    return this.details.faktura && (this.details.valuation.resterType === 'T' || this.details.valuation.resterType === 'G');
  }

  abstract setupContextMenu(): void;

  abstract setupDraftActionMenu(): void;

  getData(): string {
    return JSON.stringify(this.details.asClientStateDTO());
  }

  saveForm(): Observable<string> {
    return this.draftEditService.save().pipe(
      tap((clientState: ClientStateDetails) => {
        // Every time the form is saved, we want to check if the newly saved changes has provoked any warnings.
        this.draftEditService.refreshClientStateWarning();
        this.draftEditService.clientStateSaved.next(clientState);
        this.details.calculatedPrice = clientState.calculatedPrice
        if (this.details.vehicle) {
          this.details.vehicle.vinDetails = clientState.vehicle.vinDetails
          this.details.vehicle.hybridType = clientState.vehicle.hybridType;
          this.details.vehicle.propellantType = clientState.vehicle.propellantType;
        }
      }),
      map(data => JSON.stringify(data))
    );
  }

  public showClientStateAsDirty(): boolean {
    return !this.formSaver.isInError() && this.formSaver.isDirty();
  }

  public showClientStateAsInError(): boolean {
    return this.formSaver.isInError();
  }

  createNewReportTypeMenuItem(): LcActionMenuItem {
    return new LcActionMenuItem(
      'fa-plus', () => {
        this.formSaver.save(() => {
          this.isLoading = false;
          this.router.navigate(['new'], {relativeTo: this.route});
        }, () => {
          this.bootstrapGrowlService.addAlert('Rapporten kunne ikke gemmes. Fortsætter problemer så kontakt forsi.dk', BootstrapAlertType.DANGER, 3000);
          this.isLoading = false;
        });
      }, 'Ny Rapporttype',
      () => {
        return this.draftEditService.saving;
      });
  }

  createBackMenuItem(): LcActionMenuItem {
    return new LcActionMenuItem(
      'fa-arrow-left', () => {
        this.formSaver.save(() => {
          this.isLoading = false;
          this.clientNavigationService.back(['draft'])
        }, () => {
          this.bootstrapGrowlService.addAlert('Rapporten kunne ikke gemmes. Fortsætter problemer så kontakt forsi.dk', BootstrapAlertType.DANGER, 3000);
          this.isLoading = false;
        });
      }, 'Tilbage');
  }

  public createCalculateActionMenu(): LcActionMenuItem {
    return new LcActionMenuItem('fa-print', () => {
        this.draftEditService.scrollPosition = window.pageYOffset;
        const uiUtils = new UIUtils();
        if (!this.isFormValid()) {
          uiUtils.focusOnFirstInvalidHTMLElement(this.el.nativeElement);
          return
        }

        if (this.isInvoiceAndTotaldamageOrRebuild()) {
          uiUtils.scrollToElementId(['RESTTYPE_TOTALSKADE']);
          this.bootstrapGrowlService.addAlert('272: Rapport \'' + this.REPORT_KEY_TRANSFORM.transform(this.details.taksRapportKey) + '\' må ikke gælde som faktura hvis det er en Totalskade / Genopbygning', BootstrapAlertType.DANGER, 5000);
          return;
        }

        this.isLoading = true;
        this.formSaver.save((wasBusy: boolean) => {
          this.isLoading = false;
          if (!wasBusy) {
            this.router.navigate(['calculate'], {relativeTo: this.route});
          } else {
            this.bootstrapGrowlService.addAlert('Beregning mislykkedes - prøv igen', BootstrapAlertType.WARNING, 3000);
          }
        }, () => {
          this.bootstrapGrowlService.addAlert('Rapporten kunne ikke beregnes. Fortsætter problemer så kontakt forsi.dk', BootstrapAlertType.DANGER, 3000);
          this.isLoading = false;
        });
      }, 'Beregn',
      () => {
        return this.draftEditService.saving || this.formSaver.busy;
      })
  }

  createCheckOpslagMenuItem(priceAgreementsDeviatesSubject: Subject<boolean>, unsubscribe$: Subject<void>): LcActionMenuItem {
    const checkOpslagMenuItem = new LcActionMenuItem('fa-list-alt', () => {
        this.draftEditService.scrollPosition = window.pageYOffset;
        this.router.navigate(['check-opslag'], {relativeTo: this.route});
      }, 'Check opslag',
      () => {
        return this.draftEditService.saving;
      });

    priceAgreementsDeviatesSubject
      .pipe(
        distinctUntilChanged(),
        takeUntil(unsubscribe$))
      .subscribe(deviates => {
        if (this.isLoading) {
          return;
        }
        const textDanger = 'text-danger';
        if (deviates) {
          checkOpslagMenuItem.addIconColorClass(textDanger);
        } else {
          checkOpslagMenuItem.removeIconColorClass(textDanger);
        }
      });

    return checkOpslagMenuItem;
  }

  reloadFlow(): void {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/draft', {skipLocationChange: true}).then(x =>
      this.router.navigate([currentUrl]));
  }

  public abstract isFormValid(): boolean;

  createDmrLookupMenuItem(): LcActionMenuItem {
    return new LcActionMenuItem('fa-cloud-download', () => {
        this.draftEditService.scrollPosition = window.pageYOffset;
        this.router.navigate(['car-details', this.details.vehicle.regnr], {relativeTo: this.route});
      }, 'Bil detaljer',
      () => {
        return this.draftEditService.saving;
      });
  }

  createCopyDraftMenuItem(): LcActionMenuItem {
    const lcActionMenuItem = new LcActionMenuItem('fa-copy', () => {
        this.draftEditService.scrollPosition = window.pageYOffset;
        this.copyDraftModalService.open(this.details.token, this.details.schema)
      }, 'Kopier',
      () => {
        return !ReportCategory.isCopyable(this.details.schema) || !this.copyDraftModalService || this.draftEditService.saving;
      },
      () => lcActionMenuItem.disableMenuItem() ? 'Denne rapporttype kan ikke kopieres' : null
    );
    return lcActionMenuItem;
  }

  getMessages(): void {
    this.draftService.getMessages(this.details.token)
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(messages => {
        if (messages) {
          this.messages = messages;
        }
      });
  }

  sendMessage(createMessageDTO: CreateMessageDTO): void {
    this.draftService.sendMessage(this.details.token, createMessageDTO)
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(() => {
        this.getMessages();
        this.bootstrapGrowlService.addAlert('Besked sendt', BootstrapAlertType.SUCCESS, 3000);
      });
  }
}
