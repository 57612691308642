import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {FormSaver} from '../../../../../../shared/service/core/form-saver.service';
import {Flow2CarDetails} from '../../../../../model/flowtwo-car-details.model';

@Component({
  selector: 'lc-flow2-car-details',
  templateUrl: './flow2-car-details.component.html'
})
export class Flow2CarDetailsComponent implements OnInit {
  @Input() formId: string;
  @Input() carDetails: Flow2CarDetails;
  @Input() aargang: number;
  @Input() readonly = false;
  @ViewChild('f', {static: true}) public cform: NgForm;

  constructor(private formSaver: FormSaver) {
  }

  ngOnInit(): void {
    this.formSaver.registerForm(this.formId, this.cform);
  }

  get fabrikatAndModelCode(): string {
    return this.carDetails.fabrikatKode + this.carDetails.modelKode;
  }

  get fabrikatAndModelText(): string {
    return this.carDetails.fabrikatText + ' ' + this.carDetails.modelText;
  }
}
