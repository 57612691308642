<fieldset ngForm="f" #f="ngForm">
    <div class="row">
        <div class="col-md-6">
            <h2>Værdisætning</h2>
            <div class="card">
                <div class="card-body">
                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label for="nyVaerdi">Nyværdi</label>
                        </div>
                        <div class="col">
                            <input type="tel" [(ngModel)]="details.valuation.nyVaerdi" class="form-control"
                                id="nyVaerdi" name="nyVaerdi" lcInputMask="int8" size="8" />
                        </div>
                    </div>
                    <div *ngIf="!details.isGlastaksType()" class="row pb-3" (disabled)="!details.vehicle.handelsvaerdi">
                        <div class="col-form-label col-4">
                            <label for="handelsvaerdi">Handelsværdi</label>
                        </div>
                        <div class="col">
                            <input [(ngModel)]="details.vehicle.handelsvaerdi" class="form-control" type="tel"
                                id="handelsvaerdi" (change)="onChangeHandelsvaerdi()" name="handelsvaerdi"
                                lcInputMask="int8" size="8" />
                        </div>
                    </div>
                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label for="percent">Procent af handelsværdi</label>
                        </div>
                        <div class="col">
                            <select [(ngModel)]="this.details.valuation.percentOfValue"
                                class="form-control form-control-sm" id="percent" name="percent"
                                (change)="onChangePercentOfValuation()">
                                <option selected [ngValue]="null"> Vælg procent </option>
                                <option *ngFor="let percent of [50,55,60,65,70,75,80,85,90]" [ngValue]="percent">
                                    {{percent}}% : {{getPercentValue(percent)}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label for="reparationsGraense">Reparationsgrænse</label>
                        </div>
                        <div class="col">
                            <input [(ngModel)]="details.valuation.reparationsGraense" class="form-control" type="tel"
                                (change)="onReparationsGraenseChanged()" id="reparationsGraense"
                                name="reparationsGraense" lcInputMask="int8" size="8" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <h2>Datoer</h2>
            <div class="card">
                <div class="card-body">
                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label for="rekvirent">Rekvireret</label>
                        </div>
                        <div class="col">
                            <input type="text" [(ngModel)]="details.valuation.rekvirent" class="form-control text-uppercase"
                                id="rekvirent" name="rekvirent" />
                        </div>
                    </div>

                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label for="rekvireretDato">Rekvireret dato</label>
                        </div>
                        <div class="col">
                            <div class="input-group" style="padding: 0px;">
                                <input class="form-control" type="tel" id="rekvireretDato" name="rekvireretDato"
                                    [(ngModel)]="details.valuation.rekvireretDato" ngbDatepicker
                                    #rekvireretDatoDP="ngbDatepicker" lcInputMask="date"
                                    [minDate]="{ 'year': 1900, 'month': 01, 'day': 01 }"
                                    [maxDate]="pastOrPresentMaxDateSelection()" required>
                                <button class="btn btn-outline-secondary" (click)="rekvireretDatoDP.toggle()"
                                    type="button">
                                    <i class="fa fa-calendar fa-lg"></i>
                                </button>
                                <button class="btn btn-outline-secondary"
                                    (click)='details.valuation.rekvireretDato = dateService.todayNgbDateStruct()'
                                    type="button">&nbsp;d.d.&nbsp;
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label for="takseringsDato">Takseringsdato</label>
                        </div>
                        <div class="col">
                            <div class="input-group" style="padding: 0px;">
                                <input class="form-control" type="tel" id="takseringsDato" name="takseringsDato"
                                    [(ngModel)]="details.valuation.takseringsDato" ngbDatepicker
                                    #takseringsDatoDP="ngbDatepicker" lcInputMask="date"
                                    [minDate]="{ 'year': 1900, 'month': 01, 'day': 1 }"
                                    [maxDate]="pastOrPresentMaxDateSelection()" required>
                                <button class="btn btn-outline-secondary" (click)="takseringsDatoDP.toggle()"
                                    type="button">
                                    <i class="fa fa-calendar fa-lg"></i>
                                </button>
                                <button class="btn btn-outline-secondary"
                                    (click)='details.valuation.takseringsDato = dateService.todayNgbDateStruct()'
                                    type="button">&nbsp;d.d.&nbsp;
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label for="foersteRegDato">Første reg. dato</label>
                        </div>
                        <div class="col">
                            <div class="input-group" style="padding: 0px;">
                                <input class="form-control" type="tel" id="foersteRegDato" name="foersteRegDato"
                                    [(ngModel)]="details.vehicle.foersteRegDato" ngbDatepicker
                                    #foersteRegDatoDP="ngbDatepicker" lcInputMask="date"
                                    [minDate]="{ 'year': 1900, 'month': 01 }"
                                    [maxDate]="pastOrPresentMaxDateSelection()"
                                    [disabled]="details.disableFirstRegistrationDate()" [required]="!isUnitaks()">
                                <button class="btn btn-outline-secondary" (click)="foersteRegDatoDP.toggle()"
                                    type="button">
                                    <i class="fa fa-calendar fa-lg"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <h2>Erstatning</h2>
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-form-label col-4">
                            <label for="repairLimitExceeded">Reparation over {{repairLimit}}%</label>
                        </div>
                        <div class="col-8">
                            <input type="checkbox" class="form-input-check-single" name="repairLimitExceeded"
                                id="repairLimitExceeded" [(ngModel)]="details.valuation.repairLimitExceeded"
                                (change)="onRepairLimitExceededChange()" autocomplete="off" />
                        </div>
                    </div>
                    <div *ngIf="!details.valuation.repairLimitExceeded">
                        <div class="row">
                            <div class="col-12">
                                <hr />
                            </div>
                        </div>
                        <div class="row pb-3">
                            <div class="col-form-label col-4">
                                <label>Erstatning</label>
                            </div>
                            <div class="col-8">
                                <div class="btn-group">
                                    <input type="radio" class="btn-check" name="erstatningType" value="I" id="ERSTATTYPE_INGEN"
                                        autocomplete="off" [(ngModel)]="details.valuation.erstatningType" />
                                    <label class="btn btn-secondary btn-sm" for="ERSTATTYPE_INGEN">Ingen</label>
                                    <input type="radio" class="btn-check" name="erstatningType" value="N"
                                        id="RESTTYPE_NYVAERDI" autocomplete="off" [(ngModel)]="details.valuation.erstatningType" />
                                    <label class="btn btn-secondary btn-sm" for="RESTTYPE_NYVAERDI">Nyværdi</label>
                                    <input type="radio" class="btn-check" name="erstatningType" value="K"
                                        id="RESTTYPE_KONTANT" autocomplete="off"
                                        [(ngModel)]="details.valuation.erstatningType" />
                                    <label class="btn btn-secondary btn-sm" for="RESTTYPE_KONTANT">Kontant</label>
                                </div>
                            </div>
                        </div>
                        <div class="row pb-3"
                            *ngIf="details.valuation.erstatningType==='N' || details.valuation.erstatningType==='K'">
                            <div class="col-form-label col-4">
                                <label for="erstatningBeloeb">Beløb</label>
                            </div><div class="col">
                            <input type="tel" lcInputMask="int8" size="8"
                                [(ngModel)]="details.valuation.erstatningBeloeb" class="form-control"
                                id="erstatningBeloeb" name="erstatningBeloeb" required /></div>
                        </div>
                        <div class="row pb-3"
                            *ngIf="details.valuation.erstatningType==='N' || details.valuation.erstatningType==='K'">
                            <div class="col-form-label col-4">
                                <label for="erstatningMoms">Moms</label>
                            </div><div class="col">
                            <select [(ngModel)]="details.valuation.erstatningMoms" class="form-select form-select-sm"
                                id="erstatningMoms" name="erstatningMoms" required>
                                <option [ngValue]="null" disabled="true" selected>--Vælg moms--</option>
                                <option [ngValue]="true">Ja</option>
                                <option [ngValue]="false">Nej</option>
                            </select></div>
                        </div>
                        <div class="row pb-3"
                            *ngIf="details.valuation.erstatningType==='N' || details.valuation.erstatningType==='K'">
                            <div class="col-form-label col-4">
                                <label for="erstatningAfgift">Afgift</label>
                            </div>
                            <div class="col">
                                <select [(ngModel)]="details.valuation.erstatningAfgift"
                                    class="form-control form-control-sm" id="erstatningAfgift" name="erstatningAfgift"
                                    required>
                                    <option [ngValue]="null" disabled="true" selected>--Vælg afgift--</option>
                                    <option [ngValue]="true">Ja</option>
                                    <option [ngValue]="false">Nej</option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <hr />
                            </div>
                        </div>
                        <div class="row pb-3">
                            <div class="col-form-label col-4">
                                <label>Rester</label>
                            </div>
                            <div class="col-8">
                                <div class="btn-group">
                                    <input type="radio" class="btn-check" name="resterType" value="I" id="RESTTYPE_INGEN"
                                        autocomplete="off" [(ngModel)]="details.valuation.resterType" />
                                    <label class="btn btn-secondary btn-sm" for="RESTTYPE_INGEN">Ingen</label>
                                    <input type="radio" class="btn-check" name="resterType" value="T"
                                        id="RESTTYPE_TOTALSKADE" autocomplete="off" [(ngModel)]="details.valuation.resterType" />
                                    <label class="btn btn-secondary btn-sm" for="RESTTYPE_TOTALSKADE">Totalskade</label>
                                    <input type="radio" class="btn-check" name="resterType" value="G"
                                        id="RESTTYPE_GENOPBYGNING" autocomplete="off"
                                        [(ngModel)]="details.valuation.resterType" />
                                    <label class="btn btn-secondary btn-sm" for="RESTTYPE_GENOPBYGNING">Genopbygning</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row pb-3"
                        *ngIf="details.valuation.resterType==='T' || details.valuation.resterType==='G'">
                        <div class="col-form-label col-4">
                            <label for="resterBeloeb">Beløb</label>
                        </div>
                        <div class="col">
                            <input type="tel" lcInputMask="int8" size="8" [(ngModel)]="details.valuation.resterBeloeb"
                                class="form-control" id="resterBeloeb" name="resterBeloeb" required />
                        </div>
                    </div>
                    <div class="row pb-3"
                        *ngIf="details.valuation.resterType==='T' || details.valuation.resterType==='G'">
                        <div class="col-form-label col-4">
                            <label for="resterTold">Told</label>
                        </div>
                        <div class="col">
                            <select [(ngModel)]="details.valuation.resterTold" class="form-select form-select-sm"
                                id="resterTold" name="resterTold" required>
                                <option [ngValue]="null" disabled="true" selected>--Vælg told--</option>
                                <option [ngValue]="true">Ja</option>
                                <option [ngValue]="false">Nej</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <h2>Status</h2>
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-form-label col-4 col-md-6">
                        <label for="statusNummerplader">Nummerplader fjernet</label>
                    </div>
                    <div class="col-md-6">
                        <input type="checkbox" class="form-input-check-single" name="statusNummerplader"
                            id="statusNummerplader" [(ngModel)]="details.valuation.statusNummerplader"
                            autocomplete="off" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-form-label col-4 col-md-6">
                        <label for="statusRegistreringsattest">Registreringsattest modtaget</label>
                    </div>
                    <div class="col-md-6">
                        <input type="checkbox" class="form-input-check-single" name="statusRegistreringsattest"
                            id="statusRegistreringsattest" [(ngModel)]="details.valuation.statusRegistreringsattest"
                            autocomplete="off" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-form-label col-4 col-md-6">
                        <label for="statusStelNummer">Stelnummer genihugges</label>
                    </div>
                    <div class="col-md-6">
                        <input type="checkbox" class="form-input-check-single" name="statusStelNummer"
                            id="statusStelNummer" [(ngModel)]="details.valuation.statusStelNummer" autocomplete="off" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-form-label col-4 col-md-6">
                        <label for="statusBesigtiges">Genbesigtiges</label>
                    </div>
                    <div class="col-md-6">
                        <input type="checkbox" class="form-input-check-single" name="statusBesigtiges"
                            id="statusBesigtiges" [(ngModel)]="details.valuation.statusBesigtiges" autocomplete="off" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</fieldset>
