import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {PrincipalService} from '../service/auth/principal.service';

/**
 * @whatItDoes Conditionally includes an HTML element if current user has any
 * of the authorities passed as the `expression`.
 *
 * @howToUse
 * ```
 *     <some-element *hasAnyAuthority="'ROLE_AutoAdminRolle'">...</some-element>
 *
 *     <some-element *hasAnyAuthority="['ROLE_AutoAdminRolle', 'ROLE_autotaksuser']">...</some-element>
 * ```
 */
@Directive({
  selector: '[lcHasAnyAuthority]'
})
export class HasAnyAuthorityDirective {

  private authorities: string[];

  constructor(private principal: PrincipalService, private templateRef: TemplateRef<any>, private viewContainerRef: ViewContainerRef) {
  }

  @Input()
  set lcHasAnyAuthority(value: string | string[]) {
    this.authorities = typeof value === 'string' ? [value as string] : value as string[];
    // Get notified each time authentication state changes.
    this.updateView();
    this.principal.getAuthenticationState().subscribe((identity) => this.updateView());
  }
  private updateView(): void {
    this.viewContainerRef.clear();
    if (this.principal.hasAnyAuthorityDirect(this.authorities)) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}
