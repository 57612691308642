import {Component, EventEmitter, Output} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ExtendableComposeMessagePopupComponent} from './extendable-compose-message-popup.component';
import {PrincipalService} from '../..';
import {ComposedMessage} from '../../model/composed-message.model';
import {UserTextLibraryService} from '../../service/user-text-library.service';

@Component({
  templateUrl: './compose-message-popup.component.html'
})
export class ComposeMessagePopupComponent extends ExtendableComposeMessagePopupComponent {
  @Output() onConfirm = new EventEmitter<ComposedMessage>();

  constructor(private activeModal: NgbActiveModal,
              protected modalService: NgbModal,
              protected userTextLibraryService: UserTextLibraryService,
              protected principalService: PrincipalService) {
    super(modalService, principalService, userTextLibraryService);
  }

  public cancel(): void {
    this.activeModal.dismiss('cancel');
  }

  confirmOffer(): void {
    const message = new ComposedMessage();
    message.text = this.messageText;
    message.title = this.messageTitle;
    message.sendMessage = this.sendMessage;
    this.onConfirm.emit(message);
    this.activeModal.dismiss('confirm');
  }

  confirmDisabled(): boolean {
    return this.sendMessage && !this.isMessageValid();
  }
}
