import {Injectable} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ClientStateDetails} from '../model/client-state-details.model';
import {isNullOrUndefined} from '../../shared/utils/object-utils';
import {ReportCategory} from '../../shared/model/report-category.model';

@Injectable()
export class DraftCreateViewModel {
  explicitReportCategory: ReportCategory;
  clientState: ClientStateDetails;
  form: NgForm;
  regNr: string;
  ocrImage: File;
  ocrImageUrl: string;

  private _originalRegNr = null;
  private _originalFirstRegDate = null;

  constructor() {
    this.reset();
    this.form = null;
  }

  reset(): void {
    this.clientState = new ClientStateDetails();
    this.explicitReportCategory = null;
    this.regNr = null;
    this.ocrImageUrl = null;
    this.ocrImage = null;
    this._originalRegNr = null;
    this._originalFirstRegDate = null;
  }

  registerForm(form: NgForm): void {
    this.form = form;
  }

  nummerpladeTypeChanged(): void {
    if (this.clientState.vehicle.nummerpladeType === ' ') {
      this._originalRegNr = this.regNr;
      this._originalFirstRegDate = this.clientState.vehicle.foersteRegDato;
      this.regNr = null;
      this.clientState.vehicle.foersteRegDato = null;
    } else {
      if (this._originalRegNr !== null) {
        this.regNr = this._originalRegNr;
      }
      if (this._originalFirstRegDate !== null) {
        this.clientState.vehicle.foersteRegDato = this._originalFirstRegDate
      }
    }
  }

  containsImage(): boolean {
    return !isNullOrUndefined(this.ocrImage);
  }

  clearImage(): void {
    this.ocrImageUrl = null;
    this.ocrImage = null;
  }

}
