import {DraftEditFlowComponent} from './draft-edit-flow.component';
import {ElementRef} from '@angular/core';
import {BootstrapGrowlService} from '../../shared/ui/ngx-bootstrap-growl/bootstrap-growl.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PrincipalService} from '../../shared/service/auth/principal.service';
import {DraftService} from '../service/draft.service';
import {ForsiReportKeyShortPipe} from '../../shared/pipe/forsi-report-key-short.pipe';
import {ForsiReportKeyPipe} from '../../shared/pipe/forsi-report-key.pipe';
import {FormSaver} from '../../shared/service/core/form-saver.service';
import {FabrikatDTO} from '../../shared/makes/dto/fabrikat-dto.model';
import {DraftEditService} from './draft-edit.service';
import {LcActionMenuItem} from '../../shared/ui/bottombar/lc-action-menu.model';
import {ModelDTO} from '../../shared/makes/dto/model-dto.model';
import URLBuilder from '../../shared/utils/url-builder';
import {ClientNavigationService} from '../../shared/service/client-navigation.service';
import {mergeMap} from 'rxjs/operators';
import {QapterPrepareModeService} from '../../shared/service/qapter-prepare-mode.service';
import {QapterPrepareModeDTO} from '../../shared/dto/qapter-prepare-mode-dto.model';
import {CopyDraftModalService} from '../service/copy-draft.modal.service';
import {QapterStartModel} from '../../shared/ui/qapter/qapter-start/qapter-start.component';
import {Subject} from 'rxjs';
import {LcPopupService} from '../../shared/modals/lc-popup.service';

export abstract class DraftEditQapterFlowComponent extends DraftEditFlowComponent {

  brands: FabrikatDTO[] = [];
  fantomBrands: FabrikatDTO[] = [];
  public startQapter$ = new Subject<QapterStartModel>();

    protected constructor(formSaver: FormSaver,
                        reportKeyPipe: ForsiReportKeyPipe,
                        reportKeyShortPipe: ForsiReportKeyShortPipe,
                        clientStateService: DraftService,
                        draftEditService: DraftEditService,
                        principal: PrincipalService,
                        private qapterPrepareModeService: QapterPrepareModeService,
                        router: Router,
                        route: ActivatedRoute,
                        bootstrapGrowlService: BootstrapGrowlService,
                        el: ElementRef,
                        clientNavigationService: ClientNavigationService,
                        copyDraftModalService: CopyDraftModalService,
                        protected popupService: LcPopupService) {
    super(formSaver, reportKeyPipe, reportKeyShortPipe, clientStateService, draftEditService, principal, router, route, bootstrapGrowlService, el, clientNavigationService, copyDraftModalService);
  }

  updateWithBrands(brands: FabrikatDTO[]): void {
    this.brands = brands;
    this.fantomBrands = brands.filter((fabrikat) => fabrikat.isKnownByAudatex() && fabrikat.fantomModels.length > 0);
    this.updateBrandAndModel(this.details.vehicle.fabrikat.kode, this.details.vehicle.model?.kode);
    const fantomFabrikat = this.details.vehicle.fantomFabrikat;
    const fantomModel = this.details.vehicle.fantomModel;
    this.updateFantomBrandAndModel(fantomFabrikat ? fantomFabrikat.kode : '', fantomModel ? fantomModel.kode : '');
  }

  private updateBrandAndModel(brandKode: string, modelKode: string): void {
    for (const brand of this.brands) {
      if (brandKode != null && brand.kode.trim() === brandKode.trim()) {
        this.details.vehicle.fabrikat = brand;
        if (modelKode) {
          for (const model of brand.modeller) {
            if (model.kode.trim() === modelKode.trim()) {
              this.details.vehicle.model = model;
              break;
            }
          }
        }
        break;
      }
    }
  }

  private updateFantomBrandAndModel(fantomBrandKode: string, fantomModelKode: string): void {
    for (const brand of this.fantomBrands) {
      if (brand.kode.trim() === fantomBrandKode.trim()) {
        this.details.vehicle.fantomFabrikat = brand;
        for (const model of brand.fantomModels) {
          if (model.kode.trim() === fantomModelKode.trim()) {
            this.details.vehicle.fantomModel = model;
            break;
          }
        }
        break;
      }
    }
  }

  public isFormValid(): boolean {
    return (this.details.onepadDataValid && !this.formSaver.isInError() && this.formSaver.isValid() && this.details.damageDescription.zoneIsValid());
  }

  createQapterMenuItem(): LcActionMenuItem {
    const qapterMenuItem = new LcActionMenuItem('lc-qapter', () => this.proceedToQapter(qapterMenuItem), 'apter');
    qapterMenuItem.setImageUrl('content/images/qapter-logo.png');
    qapterMenuItem.disabledCallback = () => {
      if (this.formSaver.busy || qapterMenuItem.isLoading || this.draftEditService.saving) {
        return true;
      }
      const vehicle = this.details.vehicle;
      let hasEnoughVehicleData = vehicle.fabrikat != null && vehicle.model != null;
      if (hasEnoughVehicleData) {
        if (!FabrikatDTO.isKnownByAudatex(vehicle.fabrikat) || !ModelDTO.isKnownByAudatex(vehicle.model)) {
          hasEnoughVehicleData = vehicle.fantomFabrikat != null && vehicle.fantomModel != null;
        }
      }
      return !hasEnoughVehicleData;
    };
    qapterMenuItem.buttomTooltipCallback = () => this.details?.vehicle.model?.modelHelpText?.header;
    return qapterMenuItem;
  }

  private proceedToQapter(qapterMenuItem: LcActionMenuItem) {
    if (!this.formSaver.busy && !qapterMenuItem.isLoading) {
      qapterMenuItem.isLoading = true;
      qapterMenuItem.spin = true;

      this.draftEditService.save().pipe(
        mergeMap(() => this.qapterPrepareModeService.prepareDraftMode(this.details.token))
      ).subscribe({
        next: (prepareMode: QapterPrepareModeDTO) => {
          this.startQapter$.next({
            qapterPrepareMode: prepareMode,
            returnURL: new URLBuilder().constructReturnURL('draft/edit/' + this.details.token + '#damages')
          });
          qapterMenuItem.isLoading = false;
          qapterMenuItem.spin = false;
        }, error: () => {
          qapterMenuItem.isLoading = false;
          qapterMenuItem.spin = false;
        }
      });
    }
  }
}
