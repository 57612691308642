import {Component, Input} from '@angular/core';
import {SparePartMatchDTO} from '../../../../dto/spare-part-match-response-dto.model';
import {AutoflexSparePartService} from '../../../../service/autoflex-spare-part.service';
import {Observable, ReplaySubject} from 'rxjs';
import {map, share, switchMap} from 'rxjs/operators';

@Component({
  selector: 'lc-autoflex-part-list',
  templateUrl: './autoflex-part-list.component.html',
  styleUrls: [
    './autoflex-part-list.component.scss', '../../../draft-common.scss'
  ]
})
export class AutoflexPartListComponent {
  @Input() set clientStateToken(token: string) {
    this.clientStateToken$.next(token);
  }

  private clientStateToken$ = new ReplaySubject<string>();
  noAutoflexPart$: Observable<boolean>;
  autoflexParts$: Observable<SparePartMatchDTO[]>;

  constructor(private autoflexSparePartService: AutoflexSparePartService) {
    const tokenPipe = this.clientStateToken$.pipe(switchMap(token => this.autoflexSparePartService.getBookedSparePartsByToken(token)), share());
    this.autoflexParts$ = tokenPipe.pipe(map(booked => booked.matches));
    this.noAutoflexPart$ = tokenPipe.pipe(map(booked => booked.matches.length === 0));
  }
}
