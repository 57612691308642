import {Directive, ElementRef, Input} from '@angular/core';
import Inputmask from 'inputmask';

/**
 * Simple directive wrapper for the InputMask API.
 * Here where can define common masks that we use in our application to prevent and guide the user in the direction
 * of entering valid data.
 *
 * Usage example: <input type="tel" ... lcInputMask="date" ../>
 * This will mask the input according to the defined mask below
 */
@Directive({
  selector: '[lcInputMask]',
})
export class InputMaskDirective {
  // map of the inputmask definitions we use. Add missing here!
  private inputMaskDefMap = {
    repairtype: {
      maskDef: {regex: '^[PIMDØBALEpimdøbale]?$'}
    },
    date: {
      maskDef: {mask: '99.99.9999', placeholder: ''}
    },
    year: {
      maskDef: {regex: '^[0-9]{0,4}$', placeholder: ''}
    },
    int5: {
      maskDef: {regex: '^[0-9]{0,5}$', placeholder: '', leftAlign: true}
    },
    int6: {
      maskDef: {regex: '^[0-9]{0,6}$', placeholder: '', leftAlign: true}
    },
    int6RightAlign: {
      maskDef: {regex: '^[0-9]{0,6}$', placeholder: '', rightAlign: true}
    },
    int6_1: {
      maskDef: {regex: '^[0-9]{0,6}(,[0-9])?$', placeholder: '', leftAlign: true}
    },
    int6_1RightAlign: {
      maskDef: {regex: '^[0-9]{0,6}(,[0-9])?$', placeholder: '', rightAlign: true}
    },
    int6_2: {
      maskDef: {regex: '^[0-9]{0,6}(,[0-9]{1,2})?$', placeholder: '', leftAlign: true}
    },
    int6_2RightAlign: {
      maskDef: {regex: '^[0-9]{0,6}(,[0-9]{1,2})?$', placeholder: '', rightAlign: true}
    },
    int7RightAlign: {
      maskDef: {regex: '^[0-9]{0,7}$', placeholder: '', rightAlign: true}
    },
    int1: {
      maskDef: {regex: '^[0-9]?$', placeholder: '', leftAlign: true}
    },
    int2: {
      maskDef: {regex: '^[0-9]{0,2}$', placeholder: '', leftAlign: true}
    },
    int7: {
      maskDef: {regex: '^[0-9]{0,7}$', placeholder: '', leftAlign: true}
    },
    int7_right_align: {
      maskDef: {regex: '^[0-9]{0,7}$', placeholder: '', rightAlign: true}
    },
    int8: {
      maskDef: {regex: '^[0-9]{0,8}$', placeholder: '', leftAlign: true}
    },
    int4: {
      maskDef: {regex: '^[0-9]{0,4}$', placeholder: '', leftAlign: true}
    },
    int3: {
      maskDef: {regex: '^[0-9]{0,3}$', placeholder: '', rightAlign: true}
    },
    int3_LeftAlign: {
      maskDef: {regex: '^[0-9]{0,3}$', placeholder: '', leftAlign: true}
    },
    numbersOnly: {
      maskDef: {regex: '^[0-9]*$', placeholder: '', leftAlign: true}
    },
    leftTrim: {
      maskDef: {regex: '^[a-zA-Z0-9]{1}.*$', placeholder: ''}
    },
    lettersOnly: {
      maskDef: {regex: '^[A-Za-z ]+$', placeholder: ''}
    },
    regNr: {
      maskDef: {regex: '^[ÆØÅæøåA-Za-z0-9]([ÆØÅæøåA-Za-z0-9 ]*[ÆØÅæøåA-Za-z0-9])*$', placeholder: ''}
    },
    stelNr: {
      maskDef: {regex: '^[abcdefghjklmnprstuvwxyzABCDEFGHJKLMNPRSTUVWXYZ0-9]*$', placeholder: ''}
    },
    vkNr: {
      maskDef: {regex: '^[0-9*]{0,5}$', placeholder: '', leftAlign: true}
    },
    vkCategory: {
      maskDef: {regex: '^([1-9][0-9]{0,2})$', placeholder: '', leftAlign: true}
    },
    maxNextYear: {
      type: 'numeric',
      //allow input up until next year
      maskDef: {rightAlign: false, mask: '9999', max: (new Date()).getFullYear() + 1}
    },
    decimal: {
      maskDef: {regex: '^[0-9]*(,[0-9]{0,2})?$', placeholder: '', rightAlign: true}
    },
    decimal_4_2: {
      maskDef: {regex: '^[0-9]{0,4}(,[0-9]{0,2})?$', placeholder: '', rightAlign: true}
    },
    report_key: {
      maskDef: {regex: '^[a-zA-Z0-9]{2}( [0-9]{5})( [0-9]{2})( [0-9]{4})( [a-zA-Z0-9]{2})()?$', placeholder: ''}
    },
    workshop_report_key: {
      maskDef: {regex: '^[RP,rp]{2}( [0-9]{5})( [0-9]{2})( [0-9]{4})( [H,h]{1})()?$', placeholder: ''}
    },
    // aligned with backend rules
    username: {
      maskDef: {regex: '^[0-9a-zæøå@.+-]{5,64}$', placeholder: ''}
    },
    pct_1_99: {
      maskDef: {regex: '^[1-9][0-9]?$', placeholder: '', rightAlign: true}
    },
    phoneNumber: {
      maskDef: {regex: '^\\+?\\d{8,10}$', placeholder: ''}
    },
    no_newline: {
      maskDef: {regex: '[^\r\n]*', placeholder: ''}
    }
  };

  constructor(private el: ElementRef) {
  }

  @Input('lcInputMask')
  public set defineInputType(type: string) {
    const inputMaskDef = this.inputMaskDefMap[type];
    if (inputMaskDef === null || inputMaskDef === undefined) {
      throw new Error('misconfigured inputmask! No lcInputmask of type \'' + type + '\'')
    }
    const inputMaskType = inputMaskDef.type;
    const inputMask = this.inputMaskDefMap[type].maskDef;
    if (inputMaskType === undefined) {
      Inputmask(inputMask).mask(this.el.nativeElement);
    } else {
      Inputmask(inputMaskType, inputMask).mask(this.el.nativeElement);
    }
  }
}
