import {AttachmentSummaryDTO} from '../dto/attachment-summary-dto.model';
import {XHRInterceptor} from '../interceptor/xhr.interceptor';
import {AttachmentDTO} from '../dto/attachment-dto.model';
import {StringUtils} from './string-utils';
import URLBuilder from './url-builder';

export default class AttachmentUtil {

  public static composeThumbnailUrl(attachmentSummary: AttachmentSummaryDTO): string {
    if (attachmentSummary && attachmentSummary.thumbnailUrl != null) {
      return XHRInterceptor.getRequestUrl(attachmentSummary.thumbnailUrl);
    }
  }

  public static isImage(attachment: AttachmentDTO): boolean {
    return attachment && attachment.contentType && attachment.contentType.startsWith('image');
  }

  public static isVideo(attachment: AttachmentDTO): boolean {
    return attachment && attachment.contentType && attachment.contentType.startsWith('video');
  }

  public static isHeif(attachment: AttachmentDTO): boolean {
    return attachment && attachment.contentType && attachment.contentType === 'image/heif';
  }
  
  public static isText(attachment: AttachmentDTO): boolean {
    let result = false;
    if (attachment && attachment.contentType) {
      if (attachment.contentType.startsWith('text/plain')) {
        result = true
      } else if (attachment.contentType.startsWith('application/octet-stream')) {
        if (StringUtils.isNotEmpty(attachment.name)) {
          const nameParts = attachment.name.split('.');
          if (nameParts.length > 1) {
            const extension = nameParts[nameParts.length - 1];
            result = ['log', 'txt', 'csv'].includes(extension.toLowerCase());
          }
        }
      }
    }
    return result;
  }

  public static composeImageAttachmentUrl(attachment: AttachmentDTO, thumbnail: boolean, download: boolean): string {
    if (attachment) {
      return new URLBuilder().getRestServicePath() + 'attachment/' + attachment.id + '?thumbnail=' + Boolean(thumbnail) + '&download=' + Boolean(download) + '&accessToken=' + attachment.accessToken;
    }
    return null;
  }

  public static composeNonImageAttachmentUrl(attachment: AttachmentDTO, download: boolean): string {
    if (attachment) {
      return new URLBuilder().getRestServicePath() + this.composeNonImageAttachmentPath(attachment, download);
    }
    return null;
  }

  public static composeNonImageAttachmentPath(attachment: AttachmentDTO, download: boolean): string {
    if (attachment) {
      return 'attachment/' + attachment.id + '?download=' + Boolean(download) + '&accessToken=' + attachment.accessToken;
    }
    return null;
  }

  public static getNotPresentableFileClass(attachment: AttachmentDTO): string {
    if (attachment && attachment.name) {
      const ext = attachment.name.split('.').pop();
      switch (ext) {
        case 'xls':
        case 'xlsx':
        case 'xlsb':
        case 'xlsm':
          return 'fa-file-excel-o';
        case 'doc':
        case 'docx':
        case 'docb':
        case 'docm':
          return 'fa-file-word-o';
        case 'ppt':
        case 'pptx':
        case 'ppsx':
          return 'fa-file-powerpoint-o';
        case 'pdf':
          return 'fa-file-pdf-o';
      }
    }
    if (attachment.contentType.startsWith('video/')) {
      return 'fa-file-video-o';
    }
    if (AttachmentUtil.isText(attachment)) {
      return 'fa-file-text-o'
    }
    return 'fa-file-o ';
  }

}
