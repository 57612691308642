export enum PotentialLinkedReportType {
  T = 'T',
  S = 'S',
  G = 'G',
  HT = 'HT',
  ER = 'ER',
  EL = 'EL',
  OP = 'OP',
  GB = 'GB',
  KR = 'KR'
}
