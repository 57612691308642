    <div class="col-12 col-sm-6">
        <div class="row align-items-center">
            <div class="col-10">
            <select class="form-select"
                    [(ngModel)]="postbox.username">
                <option></option>
                <option *ngFor="let user of postboxUsers" [ngValue]="user.username">
                    {{user.name}} - nr. {{user.atClientNumber}}
                </option>
            </select></div>
            <div class="col">
                <input type="checkbox" class="form-check checkbox-lg" (change)="changeSelection()" [(ngModel)]="postbox.active">
                <!-- <input type="checkbox" class="form-control checkbox-lg "
                       (change)="changeSelection()"
                       [(ngModel)]="postbox.active"> -->
            </div>
        </div>
    </div>
    <div class="col">
        <div *ngIf="editPostboxInterval" class="mb-1">
            <div class="d-inline text-nowrap text-truncate"
                   for="postalcodefrom">Primær postnr fra</div>
            <input id="postalcodefrom" name="postalcodefrom" type="text" maxlength="4" lcInputMask="int4"
                   class="d-inline form-control ms-1 p-1 text-center" style="width: 50px" [(ngModel)]="postbox.preferredPostalCodeFrom">
            <div class="d-inline ms-1">til</div>
            <input id="postalcodeto" name="postalcodeto" type="text" maxlength="4" lcInputMask="int4"
                   class="d-inline form-control ms-1 p-1 ps-2 pe-2 text-center" style="width: 50px" [(ngModel)]="postbox.preferredPostalCodeTo">
        </div>
    </div>
