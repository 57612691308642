<div class="modal-header pre-size header-style" style="text-align: center">
    <h3 class="text-truncate">
        {{title}}
    </h3>
    <div aria-label="Close" data-dismiss="modal" class="btn-close clickable" (click)="didClickCancel()">
    </div>
</div>

<fieldset ngForm="form" #form="ngForm">
<!-- Inserted to disable autofocus -->
<input type="text" class="d-none">
<div class="modal-content">
    <div class="modal-body modal-body-style me-3 ms-3">
        <div class="row pb-3">
            <div class="col-form-label col-4">
                <label class="m-0" for="code">Takserform kode</label>
            </div>
            <div class="col-8">
                <input *ngIf="isEditingExisingRelation()" disabled [(ngModel)]="assessmentMethodRelation.code" class="form-control"
                    type="text" id="code"
                    name="code"
                    maxlength="2" size="2"/>
                <lc-autocomplete *ngIf="!isEditingExisingRelation()" id="code" name="code" required
                                [items]="selectableAssessmentMethods" [(ngModel)]="selectedAssessmentMethod" debugInfo="assessorOrgCode"
                                itemText="name" itemKey="kode" subItemText="kode"
                                (ngModelChange)=onSelectAssessorOrg()></lc-autocomplete>
            </div>
        </div>
        <div class="row pb-3">
            <div class="col-form-label col-4">
                <label for="validFrom">Gyldig fra og med</label>
            </div>
            <div class="col-8">
                <div class="input-group" style="padding: 0px;">
                    <input class="form-control" type="tel"
                        required
                        id="validFrom" name="validFrom" [(ngModel)]="assessmentMethodRelation.validFrom"
                        ngbDatepicker #validFromDP="ngbDatepicker" lcInputMask="date"
                        [minDate]="{ year: 1900, month: 1, day: 1}">
                    <button class="btn btn-outline-secondary" (click)="validFromDP.toggle()"
                            type="button"><i class="fa fa-calendar fa-lg"></i></button>
                </div>
            </div>
        </div>
        <div class="row pb-3">
            <div class="col-form-label col-4">
                <label for="validTo">Gyldig til og med</label>
            </div>
            <div class="col-8">
                <div class="input-group" style="padding: 0;">
                    <input class="form-control" type="tel"
                        id="validTo" name="validTo" [(ngModel)]="assessmentMethodRelation.validTo"
                        ngbDatepicker #validToDP="ngbDatepicker" lcInputMask="date"
                        [minDate]="{ year: 1900, month: 1, day: 1}">
                    <button class="btn btn-outline-secondary" (click)="validToDP.toggle()"
                            type="button"><i class="fa fa-calendar fa-lg"></i></button>
                </div>
            </div>
        </div>
    </div>
</div>
</fieldset>
<div class="modal-footer">
    <div class="full-width">
        <div class="pull-right">
            <button class="btn btn-lg btn-primary" (click)="didClickSubmit()" [disabled]="disableSubmit()">{{submitLabel}}</button>
        </div>
    </div>
</div>
