import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {CarSaleDTO} from '../../../shared/dto/carsale-dto.model';
import {ReportService} from '../../service/report.service';
import {RapportType} from '../../../shared/model/rapport-type.model';
import {CreateCarSaleDTO} from '../../../shared/dto/create-carsale-dto.model';
import {ConnectedDraftsDTO} from '../../model/connected-drafts-dto.model';
import {BootstrapAlertType, BootstrapGrowlService} from '../../../shared/ui/ngx-bootstrap-growl';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {IntegrationUserDTO} from '../../../shared/dto/integration-user-dto.model';

@Component({
  selector: 'lc-taks-report-b2b',
  templateUrl: './taks-report-b2b.component.html',
  styleUrls: ['taks-report-b2b.scss']
})
export class TaksReportB2bComponent implements OnInit, OnChanges, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  @Input() reportId: number;
  @Input() taksRapportKey: string;
  @Input() updatedAt: number;
  @Input() carSale: CarSaleDTO;
  @Input() integrationUsers: IntegrationUserDTO[];
  @Output() carSaleCreated = new EventEmitter<CarSaleDTO>();
  @Output() carSaleDeleted = new EventEmitter<CarSaleDTO>();

  isLoading = false;
  userId: number = null;
  keepLicensePlate = false;
  keepVin = false;
  keepPoliceNumber = false;
  keepDamageNumber = false;
  hasActiveDrafts: ConnectedDraftsDTO;

  constructor(private reportService: ReportService,
              private growlService: BootstrapGrowlService) {
    this.hasActiveDrafts = new ConnectedDraftsDTO();
    this.hasActiveDrafts.connectedToActiveDrafts = true;
  }

  ngOnInit(): void {
    this.initComponent();
    this.checkActiveDrafts();
  }

  ngOnChanges(): void {
    this.initComponent();
  }

  initComponent(): void {
    if (this.carSale) {
      this.userId = this.carSale.userId;
      this.keepLicensePlate = this.carSale.keepLicensePlate;
      this.keepVin = this.carSale.keepVin;
      this.keepPoliceNumber = this.carSale.keepPoliceNumber;
      this.keepDamageNumber = this.carSale.keepDamageNumber;
    } else {
      if (this.integrationUsers.length === 1) {
        this.userId = this.integrationUsers[0].id;
      } else {
        this.userId = null;
      }
    }
  }

  disableCarSaleCreation(): boolean {
    return (!this.userId || this.hasActiveDrafts.connectedToActiveDrafts);
  }

  carSaleSupportedByReportType(): boolean {
    return (RapportType.extractFrom(this.taksRapportKey.trim()).isOfAny([RapportType.H, RapportType.T]));
  }

  createCarSale(): void {
    this.isLoading = true;

    const createCarSaleDTO = new CreateCarSaleDTO();
    createCarSaleDTO.userId = this.userId;
    createCarSaleDTO.keepLicensePlate = this.keepLicensePlate;
    createCarSaleDTO.keepVin = this.keepVin;
    createCarSaleDTO.keepPoliceNumber = this.keepPoliceNumber;
    createCarSaleDTO.keepDamageNumber = this.keepDamageNumber;

    this.reportService.createCarSale(this.reportId, createCarSaleDTO).pipe(takeUntil(this.unsubscribe$)).subscribe(carSaleDTO => {
      this.carSale = carSaleDTO;
      this.userId = this.carSale.userId;
      this.carSaleCreated.emit(carSaleDTO);
      this.isLoading = false;
    }, error => {
      this.userId = null;
      console.log('Error while creating carsale', error);
      this.growlService.addAlert('Det lykkedes ikke at udstille et salg', BootstrapAlertType.WARNING);
      this.isLoading = false;
    });
  }

  deleteCarSale(): void {
    this.isLoading = true;
    this.reportService.deleteCarSale(this.reportId).pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.carSaleDeleted.emit(this.carSale);
      this.carSale = null;
      this.userId = null;
      this.keepLicensePlate = false;
      this.keepVin = false;
      this.keepPoliceNumber = false;
      this.keepDamageNumber = false;
      this.isLoading = false;
    });
  }

  isCarSaleDeprecated(): boolean {
    return !this.carSale || (this.updatedAt > this.carSale.updatedAt) || this.originalKeepChoicesDifferFromSelected();
  }

  private originalKeepChoicesDifferFromSelected(): boolean {
    return this.carSale.keepLicensePlate !== this.keepLicensePlate ||
      this.carSale.keepVin !== this.keepVin ||
      this.carSale.keepPoliceNumber !== this.keepPoliceNumber ||
      this.carSale.keepDamageNumber !== this.keepDamageNumber;
  }

  checkActiveDrafts(): void {
    this.reportService.hasDraftOfTypeHorT(this.reportId).pipe(takeUntil(this.unsubscribe$)).subscribe(hasActiveDrafts => {
      this.hasActiveDrafts = hasActiveDrafts;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  showNoIntegrationRelationWarning(): boolean {
    return (this.integrationUsers.length < 1 && !this.carSale) ? true : false;
  }

  carSaleUserNotInSelectableIntegrationUsers(): boolean {
    return (this.carSale && this.carSale.userId && !(this.integrationUsers.some(intUser => intUser.id === this.carSale.userId && intUser.displayName === this.carSale.userDisplayName)))
      ? true : false;
  }
}
