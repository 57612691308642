import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {AutoflexVersionDTO} from '../dto/autoflex-version-dto.model';
import {PendingAutoflexVersionDTO} from '../dto/pending-autoflex-version-dto.model';

@Injectable({
  providedIn: 'root',
})
export class AutoflexVersionService {
  private baseUrl = 'autoflexversion';

  constructor(private http: HttpClient) {
  }

  public getAutoflexVersions(): Observable<AutoflexVersionDTO[]> {
    return this.http.get<AutoflexVersionDTO[]>(this.baseUrl);
  }

  public getPendingAutoflexVersions(): Observable<PendingAutoflexVersionDTO[]> {
    return this.http.get<PendingAutoflexVersionDTO[]>(this.baseUrl + '/pending');
  }

  public createAutoflexVersion(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(this.baseUrl, formData);
  }
}
