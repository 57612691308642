import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UserTokenService} from '../../service/user-token.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DataTableDirective} from 'angular-datatables';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {BootstrapAlertType, BootstrapGrowlService} from '../ngx-bootstrap-growl';
import {LoadingSpinnerUtil} from '../../utils/loading-spinner-util';
import {UserTokenDTO} from '../../dto/token/user-token-dto.model';
import {isNullOrUndefined} from '../../utils/object-utils';

@Component({
  templateUrl: './user-token.component.html',
  styleUrls: ['./user-token.scss'],
  selector: 'lc-user-token',
  providers: [UserTokenService, LoadingSpinnerUtil]
})
export class UserTokenComponent implements OnInit, OnDestroy {
  private localStorage: Storage = localStorage;
  private INFO_ACCEPTED_KEY = 'ACCESS_TOKEN_INFO_ACCEPTED';
  private unsubscribe$ = new Subject<void>();
  userTokens: UserTokenDTO[];
  currentRenewToken: string;
  currentToken: UserTokenDTO;
  editTitle: string;
  dataTableRenderTrigger = new Subject<void>();
  accessRoleTranslation = {
    PAINT_ACCESS: {name: 'Paint integration', toolTip: 'Med denne rettighed kan brugeren se lakeringsomfanget og billeder på godkendte rapporter'},
    DRAFT_ACCESS: {name: 'Draft', toolTip: 'Med denne rettighed kan brugeren oprette en rapport med administrative data og alle bilag'},
    REPORT_ACCESS: {name: 'Report', toolTip: 'Med denne rettighed kan brugeren hente den fulde rapport og alle bilag'},
    CARSALE_ACCESS: {name: 'Car sale', toolTip: 'Med denne rettighed kan brugeren hente informationer på en rapport sat til salg'},
    STATISTIC_ACCESS: {name: 'Statistic', toolTip: 'Med denne rettighed kan brugeren hente statistik data, værkstedsinformationer, aftaler, Fabrikater og modeller'},
    AUTOFLEX_ACCESS: {name: 'Autoflex', toolTip: 'Med denne rettighed kan brugeren søge reservedele i den aktive Autoflex version'},
    WORKSHOP_ACCESS: {name: 'Workshop', toolTip: 'Med denne rettighed kan brugeren søge på værksteder'},
    OFFER_ACCESS: {name: 'Offer', toolTip: 'Med denne rettighed kan brugeren hente, godkende, afvise og videresende indkomne tilbud'},
    REQUISITION_ACCESS: {name: 'Requisition', toolTip: 'Med denne rettighed kan brugeren oprette rekvisitioner'},
    AGREEMENTS_ACCESS: {name: 'Agreements', toolTip: 'Med denne rettighed kan brugeren hente aftaler for selskab, taksatororganisation og gældende aftaler for et værksted'},
    POLICY_LOOKUP: {name: 'Policy lookup', toolTip: 'Med denne rettighed kan brugeren lave policeopslag hos de selskaber der har godkendt dette'}
  };
  dataTableOptions: DataTables.Settings = {
    stateSave: true,
    stateDuration: -1, //-1 indicate session storage
    searching: true,
    pageLength: 20,
    columnDefs: [
      {
        //disable sorting and searching on button column
        targets: 5, searchable: false, orderable: false
      }
    ]
  };

  @Input()
  public name: string;

  @Input()
  public userId: number;

  //getting access to the actual datatable in the html
  @ViewChild(DataTableDirective)
  dataTableDirective: DataTableDirective;

  @ViewChild('tokenInfoPopup', {static: true}) tokenInfoPopup: ElementRef;

  constructor(private userTokenService: UserTokenService, private modalService: NgbModal, private bootstrapGrowlService: BootstrapGrowlService, public spinnerUtil: LoadingSpinnerUtil) {
  }

  reload(): void {
    this.spinnerUtil.startLoading();
    this.userTokenService.getList(this.userId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(list => {
        this.userTokens = list;
        this.renderDatatable();
        this.spinnerUtil.stopLoading();
      }, error => this.spinnerUtil.stopLoading());
  }

  ngOnInit(): void {
    this.reload();
    if (isNullOrUndefined(this.localStorage.getItem(this.INFO_ACCEPTED_KEY))) {
      this.modalService.open(this.tokenInfoPopup)
    }
  }

  disableEnableToken(token: UserTokenDTO): void {
      this.userTokenService[(token.enabled ? 'disable' : 'enable')](this.userId, token)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(updated => {
          this.updateToken(token, updated);
        });
  }

  private updateToken(existing, updated): void {
    existing.enabled = updated.enabled;
    existing.endDate = updated.endDate;
    existing.name = updated.name;
  }

  private renderDatatable(): void {
    if (this.dataTableDirective.dtInstance) {
      this.dataTableDirective.dtInstance.then(dataTableInstance => dataTableInstance.destroy());
    }
    this.dataTableRenderTrigger.next();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
    this.dataTableRenderTrigger.unsubscribe();
  }

  open(content): void {
    this.modalService.open(content, {});
  }

  createNewToken(content): void {
    this.editTitle = 'Opret ny B2B adgang';
    this.userTokenService.getAccessRoleOptions(this.userId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(list => {
        const userTokenDTO = new UserTokenDTO();
        userTokenDTO.accessRoles = list;
        this.setupCurrentToken(userTokenDTO);
        this.modalService.open(content, {backdrop: 'static'});
      });
  }

  editToken(token: UserTokenDTO, content): void {
    this.editTitle = 'Rediger B2B adgang';
    this.setupCurrentToken(JSON.parse(JSON.stringify(token)));
    this.modalService.open(content, {backdrop: 'static'});
  }

  private setupCurrentToken(token: UserTokenDTO): void {
    this.currentToken = token;
  }

  createOrUpdateToken(modal: any, showGeneratedToken: any): void {
    this.spinnerUtil.startLoading();
    const newToken = isNullOrUndefined(this.currentToken.id);
    this.userTokenService[newToken ? 'create' : 'update'](this.userId, this.currentToken)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(brugerToken => {
        modal.dismiss();
        this.reload();
        if (newToken) {
          setTimeout(() => {
          this.bootstrapGrowlService.addAlert('En ny B2B adgang er oprettet - HUSK at kopier nøglen', BootstrapAlertType.SUCCESS);
          this.currentRenewToken = brugerToken.token;
          this.modalService.open(showGeneratedToken, {backdrop: 'static'});
          }, 1)
        }
      });
  }

  isDuplicate(token: UserTokenDTO): boolean {
    return !isNullOrUndefined(token) && !isNullOrUndefined(token.name) && this.userTokens.find(item => item.enabled && token.id !== item.id && token.name.toLocaleLowerCase() === item.name.toLocaleLowerCase()) !== undefined
  }

  tokenIsValid(token: UserTokenDTO): boolean {
    return !isNullOrUndefined(token) && !isNullOrUndefined(token.name) && !this.isDuplicate(token);
  }

  markTokenInfoAccepted(content: NgbModalRef): void {
    this.localStorage.setItem(this.INFO_ACCEPTED_KEY, 'true');
    content.dismiss();
  }

  generateNewRefreshTokenStep1(token: UserTokenDTO, showGenerateTokenWarning: any): void {
    this.currentToken = token;
    this.modalService.open(showGenerateTokenWarning, {backdrop: 'static'})
  }

  generateNewRefreshTokenStep2(showGeneratedToken: any): void {
    this.userTokenService.generateNewRefreshToken(this.userId, this.currentToken)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(updated => {
        this.updateToken(this.currentToken, updated);
        this.currentRenewToken = updated.token;
        this.modalService.open(showGeneratedToken, {backdrop: 'static'});
      });
  }

  copyTokenToClipboard(tokenArea: any): void {
    tokenArea.disabled = false;
    tokenArea.select();
    document.execCommand('copy');
    tokenArea.setSelectionRange(0, 0);
    tokenArea.disabled = true;
    this.bootstrapGrowlService.addAlert('Nøglen er kopieret!', BootstrapAlertType.SUCCESS)
  }

  getRoleToolTip(name: string): string {
    return name
  }
}
