import {Injectable} from '@angular/core';
import {SkadeTypeDTO} from '../../draft/dto/skade-type-dto.model';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SkadeTypeService {
  private skadeTypeUrl = 'skadetype/';

  constructor(private http: HttpClient) {
  }

  getSkadetyper(): Observable<SkadeTypeDTO[]> {
    return this.http.get<SkadeTypeDTO[]>(this.skadeTypeUrl);
  }

  getSkadeTyperForCompany(companyCode: string): Observable<SkadeTypeDTO[]> {
    return this.http.get<SkadeTypeDTO[]>(this.skadeTypeUrl + companyCode + '/selskab').pipe(
      map(response => response.map(rawData => Object.assign(new SkadeTypeDTO(), rawData))
      ));
  }
}
