<fieldset ngForm="f" #f="ngForm">
    <h2>Skadebeskrivelse</h2>
    <div class="card">
        <div class="card-body">
            <div *ngIf="!details.isGlastaksType()" class="row">
                <div class="col-md-6">
                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label for="skadetype">Skadetype</label>
                        </div>
                        <div class="col">
                            <select [(ngModel)]="damageDescription.skadeType" (change)="onDamageTypeChanged()"
                                    class="form-control form-control-sm"
                                    id="skadetype"
                                    name="skadetype" required>
                                <option *ngIf="!damageDescription.skadeType" [ngValue]="null" disabled="true">&#45;&#45;Vælg
                                    skadetype&#45;&#45;
                                </option>
                                <option *ngFor="let skadeType of getSkadeTyper()" [ngValue]="skadeType.name">
                                    {{skadeType.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <ul class="list-unstyled">
                <li>
                    <lc-multiselect-item [(model)]="damageDescription.zone1"
                                         [missing]="!damageDescription.zoneIsValid()"
                                         [disabled]="damageDescription.disableZone('1')">
                        {{damageZone.valueOf("1").getLongName()}}
                    </lc-multiselect-item>
                </li>
                <li>
                    <lc-multiselect-item [(model)]="damageDescription.zone2"
                                         [missing]="!damageDescription.zoneIsValid()"
                                         [disabled]="damageDescription.disableZone('2')">
                        {{damageZone.valueOf("2").getLongName()}}
                    </lc-multiselect-item>
                </li>
                <li>
                    <lc-multiselect-item [(model)]="damageDescription.zone3"
                                         [missing]="!damageDescription.zoneIsValid()"
                                         [disabled]="damageDescription.disableZone('3')">
                        {{damageZone.valueOf("3").getLongName()}}
                    </lc-multiselect-item>
                </li>
                <li>
                    <lc-multiselect-item [(model)]="damageDescription.zone4"
                                         [missing]="!damageDescription.zoneIsValid()"
                                         [disabled]="damageDescription.disableZone('4')">
                        {{damageZone.valueOf("4").getLongName()}}
                    </lc-multiselect-item>
                </li>
                <li>
                    <lc-multiselect-item [(model)]="damageDescription.zone5"
                                         [missing]="!damageDescription.zoneIsValid()"
                                         [disabled]="damageDescription.disableZone('5')">
                        {{damageZone.valueOf("5").getLongName()}}
                    </lc-multiselect-item>
                </li>
                <li>
                    <lc-multiselect-item [(model)]="damageDescription.zone6"
                                         [missing]="!damageDescription.zoneIsValid()"
                                         [disabled]="damageDescription.disableZone('6')">
                        {{damageZone.valueOf("6").getLongName()}}
                    </lc-multiselect-item>
                </li>

                <li>
                    <lc-multiselect-item [(model)]="damageDescription.zone7"
                                         [missing]="!damageDescription.zoneIsValid()"
                                         [disabled]="damageDescription.disableZone('7')">
                        {{damageZone.valueOf("7").getLongName()}}
                    </lc-multiselect-item>
                </li>
                <li>
                    <lc-multiselect-item [(model)]="damageDescription.zone8"
                                         [missing]="!damageDescription.zoneIsValid()"
                                         [disabled]="damageDescription.disableZone('8')">
                        {{damageZone.valueOf("8").getLongName()}}
                    </lc-multiselect-item>
                </li>
                <li>
                    <lc-multiselect-item [(model)]="damageDescription.zone9"
                                         [missing]="!damageDescription.zoneIsValid()"
                                         [disabled]="damageDescription.disableZone('9')">
                        {{damageZone.valueOf("9").getLongName()}}
                    </lc-multiselect-item>
                </li>
                <li>
                    <lc-multiselect-item [(model)]="damageDescription.zoneB"
                                         [missing]="!damageDescription.zoneIsValid()"
                                         [disabled]="damageDescription.disableZone('B')">
                        {{damageZone.valueOf("B").getLongName()}}
                    </lc-multiselect-item>
                </li>
                <li>
                    <lc-multiselect-item [(model)]="damageDescription.zoneF"
                                         [missing]="!damageDescription.zoneIsValid()"
                                         [disabled]="damageDescription.disableZone('F')">
                        {{damageZone.valueOf("F").getLongName()}}
                    </lc-multiselect-item>
                </li>
                <li>
                    <lc-multiselect-item [(model)]="damageDescription.zoneK"
                                         [missing]="!damageDescription.zoneIsValid()"
                                         [disabled]="damageDescription.disableZone('K')">
                        {{damageZone.valueOf("K").getLongName()}}
                    </lc-multiselect-item>
                </li>

                <li>
                    <lc-multiselect-item [(model)]="damageDescription.zoneM"
                                         [missing]="!damageDescription.zoneIsValid()"
                                         [disabled]="damageDescription.disableZone('M')">
                        {{damageZone.valueOf("M").getLongName()}}
                    </lc-multiselect-item>
                </li>
                <li>
                    <lc-multiselect-item [(model)]="damageDescription.zoneO"
                                         [missing]="!damageDescription.zoneIsValid()"
                                         [disabled]="damageDescription.disableZone('O')">
                        {{damageZone.valueOf("O").getLongName()}}
                    </lc-multiselect-item>
                </li>
                <li>
                    <lc-multiselect-item [(model)]="damageDescription.zoneT"
                                         [missing]="!damageDescription.zoneIsValid()"
                                         [disabled]="damageDescription.disableZone('T')">
                        {{damageZone.valueOf("T").getLongName()}}
                    </lc-multiselect-item>
                </li>
                <li>
                    <lc-multiselect-item [(model)]="damageDescription.zoneU"
                                         [missing]="!damageDescription.zoneIsValid()"
                                         [disabled]="damageDescription.disableZone('U')">
                        {{damageZone.valueOf("U").getLongName()}}
                    </lc-multiselect-item>
                </li>
            </ul>

            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="row pb-3">
                        <div class="col-form-label col-4">
                            <label for="andreskader">Andre skader</label>
                        </div>
                        <div class="col">
                            <input [(ngModel)]="damageDescription.andreSkader" class="form-control text-uppercase" type="text"
                                   maxlength="35"
                                   id="andreskader"
                                   name="andreskader"/></div>
                    </div>
                </div>
            </div>
            <div class="row"
                 *ngIf="!hideSkadekode && selectedCompany && selectedCompany.skadekoder && selectedCompany.skadekoder.length > 0">
                <div class="col-12 col-md-6">
                    <div class="row">
                        <div class="col-form-label col-4">
                            <label for="skadekode">Skadekode</label>
                        </div>
                        <div class="col">
                            <lc-autocomplete [(ngModel)]="selectedSkadekode" (ngModelChange)=onSelectSkadekode()
                                             id="skadekode" debugInfo="skadekoder"
                                             name="skadekode"
                                             [items]="selectedCompany.skadekoder" [required]="!isSkadekodeValid()"
                                             itemText="beskrivelse" subItemText="kode" exactKey="kode">
                            </lc-autocomplete>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fieldset>
