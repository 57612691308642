import {CarDetailsProviderService} from '../../../shared/ui/car-details/car-details-provider.service';
import {Observable} from 'rxjs';
import {DmrService} from '../../../shared/service/dmr.service';

export class DraftCarDetailsProviderService implements CarDetailsProviderService {
  private dmrService: DmrService;
  private readonly licensePlate: string;

  constructor(dmrService: DmrService, licensePlate: string) {
    this.dmrService = dmrService;
    this.licensePlate = licensePlate;
  }

  getCarDetails(): Observable<any> {
    return this.dmrService.getCarDetails(this.licensePlate);
  }
}
