import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Router} from '@angular/router';
import {LinkedReportClientStateDTO} from '../../dto/linked-report-client-state-dto.model';
import {PotentialLinkedReportDTO} from '../../dto/potential-linked-report-dto.model';
import {PotentialLinkedReportState} from '../../model/potential-linked-report-state.enum';
import {isNullOrUndefined} from '../../utils/object-utils';
import {PrincipalService} from '../../service/auth/principal.service';

@Component({
  selector: 'lc-linked-report-list',
  templateUrl: './linked-report-list.component.html',
  styleUrls: ['linked-report-list.scss']
})
export class LinkedReportListComponent {
  @Input() reportKey: string;
  @Input() disableButtons: boolean;
  @Input() potentialLinkedReportTypes: PotentialLinkedReportDTO[];
  @Output() potentialLinkedReportSelectedEmitter = new EventEmitter<PotentialLinkedReportDTO>();

  constructor(private router: Router, private currentPrincipal: PrincipalService) {
  }

  select(linkedReportType: PotentialLinkedReportDTO): void {
    this.potentialLinkedReportSelectedEmitter.emit(linkedReportType);
  }

  navigateToClientState(linkedClientStateDTO: LinkedReportClientStateDTO): void {
    if (this.isOwnClientState(linkedClientStateDTO)) {
      this.router.navigate(['draft', 'edit', linkedClientStateDTO.token])
    } else {
      this.router.navigate(['draft', 'takeover', linkedClientStateDTO.token])
    }
  }

  allowCreation(potentialLinkedReportType: PotentialLinkedReportDTO): boolean {
    return potentialLinkedReportType.state.toString() === PotentialLinkedReportState[PotentialLinkedReportState.ALLOW_CREATION].toString()
      || potentialLinkedReportType.state.toString() === PotentialLinkedReportState[PotentialLinkedReportState.ALLOW_CREATION_WITH_PROMPT].toString()
      || potentialLinkedReportType.state.toString() === PotentialLinkedReportState[PotentialLinkedReportState.ALLOW_MULTIPLE_CREATION].toString();
  }

  newReportNotAllowed(potentialLinkedReportType: PotentialLinkedReportDTO): boolean {
    return potentialLinkedReportType.state.toString() === PotentialLinkedReportState[PotentialLinkedReportState.NEW_REPORT_NOT_ALLOWED].toString();
  }

  clientStateExists(potentialLinkedReportType: PotentialLinkedReportDTO): boolean {
    return potentialLinkedReportType.state.toString() === PotentialLinkedReportState[PotentialLinkedReportState.CLIENT_STATE_EXISTS].toString();
  }

  hasClientStates(potentialLinkedReportType: PotentialLinkedReportDTO): boolean {
    return !isNullOrUndefined(potentialLinkedReportType.existingClientStates) && potentialLinkedReportType.existingClientStates.length >= 1;
  }

  isOwnClientState(linkedClientStateDTO: LinkedReportClientStateDTO): boolean {
    return linkedClientStateDTO.ownerId === this.currentPrincipal.getUserId()
  }
}
