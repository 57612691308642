import {Pipe, PipeTransform} from '@angular/core';
import {UserType} from '../dto/user-type.model';
import {isNullOrUndefined} from '../utils/object-utils';

@Pipe({
  name: 'userType'
})
export class UserTypePipe implements PipeTransform {
  private readonly USER_TYPE_MAPPINGS: {type: UserType, desc: string}[] = [
    {type: UserType.ADMINONLY, desc: 'Administrator'},
    {type: UserType.AFTALEADMINONLY, desc: 'Aftale administrator'},
    {type: UserType.SAGSB, desc: 'Sagsbehandler'},
    {type: UserType.STAT, desc: 'Statistik bruger'},
    {type: UserType.TAKS, desc: 'Taksator'},
    {type: UserType.VK, desc: 'Værksted'},
    {type: UserType.KONFIGURATOR, desc: 'Konfigurator'},
    {type: UserType.INTEGRATION, desc: 'Integration'},
  ];
  transform(value: UserType): string {
    return !isNullOrUndefined(value) ? this.USER_TYPE_MAPPINGS.find(v => v.type === value).desc : value;
  }
}
