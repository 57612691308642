import {AfterViewChecked, Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: 'textarea[lcAutosize]'
})
export class TextareaAutosizeDirective implements AfterViewChecked {
  constructor(public element: ElementRef) {
  }

  @HostListener('input', ['$event.target'])
  public onInput(): void {
    this.resize();
  }

  public ngAfterViewChecked(): void {
    this.resize();
  }

  public resize(): void {
    const style = this.element.nativeElement.style;
    style.overflow = 'hidden';
    style.height = 'auto';

    const height = this.element.nativeElement.scrollHeight;
    style.height = `${height}px`;
  }
}
