import {Injectable} from '@angular/core';
import {ChangePasswordDTO} from '../../home/ui/login/changepassword/change-password-dto.model';
import {ChangePasswordResult} from '../../home/ui/login/changepassword/change-password-result.model';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CredentialsService {
  //explicit access an open service
  static CHANGEPASSWORD_URL = 'open/rest/credentials/changepassword';

  constructor(private http: HttpClient) {
  }

  changePassword(username: string, password: string, newPassword: string): Observable<ChangePasswordResult> {
    const changePassword: ChangePasswordDTO = new ChangePasswordDTO();
    changePassword.username = username;
    changePassword.password = password;
    changePassword.newPassword = newPassword;
    return this.http.post<ChangePasswordResult>(CredentialsService.CHANGEPASSWORD_URL, changePassword);
  }
}
