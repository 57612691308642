import {Observable, ReplaySubject, Subject} from 'rxjs';

/**
 * Model combining ability to both read the current value and subscribe to changes
 */
export class ValueObsModel<T> {
  private _value: T;
  private sub: Subject<T>;



  constructor(initialValue?: T) {
    this.sub = new ReplaySubject(1);
    if (initialValue !== undefined) {
      this.value = initialValue;
    }

  }

  get value(): T {
    return this._value;
  }

  set value(value: T) {
    this._value = value;
    this.sub.next(value);
  }

  getObs(): Observable<T> {
    return this.sub.asObservable();
  }

  get $(): Observable<T> {
    return this.sub.asObservable();
  }
}
