import {VATProgramme} from '../../draft/model/op-details.model';

export class GbDetailsDTO {
  selskab: string;
  bankRegnr: string;
  bankKontonr: string;
  nemkonto: boolean;
  bud1: string;
  bud2: string;
  bud3: string;
  budgiver1: string;
  budgiver2: string;
  budgiver3: string;
  budgiverAdresse1: string;
  budgiverAdresse2: string;
  budgiverPostNr: string;
  budgiverNavn: string;
  budgiverTelefon: string;
  budgiverSolgtTil: string;
  fabrikatKode: string;
  fabrikatText: string;
  modelKode: string;
  modelText: string;
  editableFabrikat: string;
  editableModel: string;
  resterTilhoerSelskab: boolean;
  registreringsAttest: boolean;
  pantehaverRestGaeld: boolean;
  pantehaverRestGaeldBeloeb: number;
  pantehaverNavn: string;
  pantehaverAdresse: string;
  pantehaverTlf: string;
  pantehaverPostalCode: string;
  vatProgramme: VATProgramme;
}

export class ErDetailsDTO {
  selskab: string;
  bankRegnr: string;
  bankKontonr: string;
  nemkonto: boolean;
  bud1: string;
  bud2: string;
  bud3: string;
  budgiver1: string;
  budgiver2: string;
  budgiver3: string;
  budgiverAdresse1: string;
  budgiverAdresse2: string;
  budgiverPostNr: string;
  budgiverNavn: string;
  budgiverTelefon: string;
  budgiverSolgtTil: string;
  fabrikatKode: string;
  fabrikatText: string;
  modelKode: string;
  modelText: string;
  editableFabrikat: string;
  editableModel: string;
  resterTilhoerSelskab: boolean;
  pantehaverRestGaeld: boolean;
  pantehaverRestGaeldBeloeb: number;
  pantehaverNavn: string;
  pantehaverAdresse: string;
  pantehaverTlf: string;
  pantehaverPostalCode: string;
}
