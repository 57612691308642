import {NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {NgbForsiDateMomentParserFormatter} from '../../index';
import {PaintSystem} from '../../model/paint-system.model';
import {isNotNullOrUndefined} from '../../utils/object-utils';
import {ModelHelpText} from './model-help-text.model';

export class ModelDTO {
  private static formatter = new NgbForsiDateMomentParserFormatter();

  id: number;
  kode: string;
  navn: string;
  simpleName: string;
  segment: number;
  repairType: string;
  audatex: boolean;
  vinAllowed: boolean;
  paintSystem: PaintSystem;
  autotaksType: boolean;
  gyldigFra: NgbDate;
  gyldigTil: NgbDate;
  overwriteCode: string;
  modelHelpText: ModelHelpText;

  public static fromJson(modelJson: any): ModelDTO {
    const result = Object.assign(new ModelDTO(), modelJson) as ModelDTO;

    if (isNotNullOrUndefined(modelJson.gyldigFra)) {
      const fra = new Date(modelJson.gyldigFra);
      result.gyldigFra = ModelDTO.formatter.parseDate(fra);
    }

    if (isNotNullOrUndefined(modelJson.gyldigTil)) {
      const til = new Date(modelJson.gyldigTil);
      result.gyldigTil = ModelDTO.formatter.parseDate(til);
    }

    if(isNotNullOrUndefined(modelJson.modelHelpText)) {
      result.modelHelpText = Object.assign(new ModelHelpText(), modelJson.modelHelpText);
    }

    return result;
  }

  public static isKnownByAudatex(model): boolean {
    return model !== undefined && model !== null && model.audatex;
  }

  public static toJson(model: ModelDTO): object {
    const jsonObj = Object.assign({}, model) as any;
    jsonObj.gyldigFra = model.gyldigFra ? ModelDTO.formatter.toDate(model.gyldigFra).getTime() : null;
    jsonObj.gyldigTil = model.gyldigTil ? ModelDTO.formatter.toDate(model.gyldigTil).getTime() : null;
    return jsonObj;
  }
}

