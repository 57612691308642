<div class="row">
    <label class="col-form-label col-4">Tilstand</label>
    <div class="col-8">
        <div class="row">
            <div class="btn-group">
                <input type="radio" class="btn-check" name="tilstandsType" value="I" id="EJVURDERET" autocomplete="off" [(ngModel)]="details.vehicle.tilstandsType"/>
                <label class="btn btn-secondary btn-sm" for="EJVURDERET">Ej vurderet</label>
            </div>
        </div>
        <div class="row">
            <div class="btn-group">
                <input type="radio" class="btn-check" name="tilstandsType" value="G" id="GOD" autocomplete="off" [(ngModel)]="details.vehicle.tilstandsType"/>
                <label class="btn btn-secondary btn-sm" for="GOD">God</label>
                <input type="radio" class="btn-check" name="tilstandsType" value="M" id="MIDDEL" autocomplete="off" [(ngModel)]="details.vehicle.tilstandsType"/>
                <label class="btn btn-secondary btn-sm" for="MIDDEL">Middel</label>
                <input type="radio" class="btn-check" name="tilstandsType" value="U" id="UNDER" autocomplete="off" [(ngModel)]="details.vehicle.tilstandsType"/>
                <label class="btn btn-secondary btn-sm" for="UNDER">Under middel</label>
            </div>
        </div>
    </div>
</div>
