import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {TextLibraryPopupComponent} from '../../../modals/text-library/text-library-popup.component';
import {RapportType} from '../../../model/rapport-type.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TextLibraryTypeEnum} from '../../../service/text-library-type.enum';
import {OrganisationTextLibraryService} from '../../../service/organisation-text-library.service';

@Component({
  selector: 'lc-org-text-library-button',
  template: '<button type="button" class="btn btn-sm btn-primary" [disabled]="disabled" (click)="openTextLibrary()">Org. Tekstbibliotek</button>'
})
export class LcOrganisationTextLibraryButtonComponent implements OnInit, OnDestroy {

  constructor(private modalService: NgbModal, private textLibrary: OrganisationTextLibraryService) {
  }

  @Output() selectedText = new EventEmitter<string>();

  @Input() libraryType: TextLibraryTypeEnum;
  @Input() disabled = false;

  @Input() fullscreen = true;

  @Input() reportType: RapportType;

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  openTextLibrary() {
    const modalRef = this.modalService.open(TextLibraryPopupComponent, {size: 'lg', windowClass: this.fullscreen ? 'lc-fullscreen' : ''});
    const instance = modalRef.componentInstance as TextLibraryPopupComponent;
    instance.title = this.getLibraryTitle(this.libraryType);
    instance.editable = false;
    instance.textLibraryType = this.libraryType;
    instance.textLibraryService = this.textLibrary;
    instance.reportType = this.reportType;
    modalRef.result.then((text: string) => {
      if (text) {
        this.selectedText.emit(text);
      }
    }).catch(() => {
      // Necessary to avoid "Uncaught error Error: Uncaught (in promise)" even though we do not use the returned value.
    });
  }

  private getLibraryTitle(type: TextLibraryTypeEnum): string {
    switch (type) {
      case TextLibraryTypeEnum.FORM18_REMARKS:
        return 'Følgeseddel Org. Tekstbibliotek';
      case TextLibraryTypeEnum.REMARKS:
        return 'Bemærkninger Org. Tekstbibliotek';
      case TextLibraryTypeEnum.SKADENOTAT:
        return 'Skadenotat Org. Tekstbibliotek';
      case TextLibraryTypeEnum.INTERNINFO:
        return 'Intern Info Org. Tekstbibliotek';
      case TextLibraryTypeEnum.BESKED:
        return 'Besked Org. Tekstbibliotek';
      case TextLibraryTypeEnum.TAKSATORAKTIONBESKED:
        return 'Aktionsbesked Org. Tekstbibliotek';
      default:
        return 'Org. Tekstbibliotek';
    }
  }
}
