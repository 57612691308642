import {ClientstateResultDTO} from './dto/clientstate-result-dto.model';
import {ReportResultDTO} from './dto/report-result-dto.model';
import {CaseSearchResultDTO} from './dto/case-search-result-dto';
import {StringUtils} from '../shared/utils/string-utils';

export class CaseSearchResultModel {
  sagsKey: string;
  status: string;
  clientStates: ClientstateResultDTO[];
  reports: ReportResultDTO[];
  fabrikatTextForSearchResult: string;
  modelTextForSearchResult: string;
  regnrForSearchResult: string;
  draftTabActiveInViewer: boolean;
  vinForSearchResult: string;

  public static fromDTO(dto: CaseSearchResultDTO): CaseSearchResultModel {
    const model = new CaseSearchResultModel();
    model.sagsKey = dto.sagsKey;
    model.status = dto.status;
    model.clientStates = dto.clientStates;
    model.reports = dto.reports;

    model.trimProperties();
    model.determineActiveTabOncreation();
    model.setFabrikatAndModelTextForSearchResult();
    model.setRegnrForSearchResult();
    model.setVinForSearchResult();

    return model;
  }

  public setFabrikatAndModelTextForSearchResult(): void {
    if (this.hasTaksReports()) {
      const taksReports = this.reports.filter(value => !value.reportKey.startsWith('RP'));
      this.fabrikatTextForSearchResult = taksReports[0].fabrikatText;
      this.modelTextForSearchResult = taksReports[0].modelText;
    } else if (this.hasTaksDraft()) {
      const taksDrafts = this.clientStates.filter(value => !value.reportKey.startsWith('RP'));
      this.fabrikatTextForSearchResult = taksDrafts[0].fabrikatText;
      this.modelTextForSearchResult = taksDrafts[0].modelText;
    } else {
      this.fabrikatTextForSearchResult = this.reports[0].fabrikatText;
      this.modelTextForSearchResult = this.reports[0].modelText;
    }
  }

  public setRegnrForSearchResult(): void {
    if (this.hasDrafts()) {
      this.regnrForSearchResult = this.clientStates[0].regNr;
    } else {
      this.regnrForSearchResult = this.reports[0].regNr;
    }
  }

  public setVinForSearchResult(): void {
    if (this.hasReports()) {
        this.vinForSearchResult = this.reports[0].vin;
    } else {
      //some draft-types do not have vin (such as EL)
       const filteredArray = this.clientStates.filter( cs =>  StringUtils.isNotEmpty(cs.vin));
       if (filteredArray.length > 0) {
         this.vinForSearchResult = filteredArray[0].vin;
       } else {
         this.vinForSearchResult = this.clientStates[0].vin;
       }
    }
  }

  hasTaksReports(): boolean {
    if (this.reports && this.reports.length > 0) {
      return this.reports.filter(value => !value.reportKey.startsWith('RP')).length > 0;
    }
    return false;
  }

  hasTaksDraft(): boolean {
    if (this.clientStates && this.clientStates.length > 0) {
      return this.clientStates.filter(value => !value.reportKey.startsWith('RP')).length > 0;
    }
    return false;
  }

  hasReports(): boolean {
    return this.reports && this.reports.length > 0;
  }

  hasDrafts(): boolean {
    return this.clientStates.length > 0;
  }

  determineActiveTabOncreation(): void {
    this.draftTabActiveInViewer = !this.hasReports();
  }

  determineActiveTabBasedOnReportKey(key: string): void {
    console.log('determine based on reportkey!');
    this.draftTabActiveInViewer = true;
    if (this.hasReports()) {
      const reportsHavingKey = this.reports.filter(result => result.reportKey === key);
      if (reportsHavingKey && reportsHavingKey.length > 0) {
        this.draftTabActiveInViewer = false;
      }
    }
  }

  setDraftTabActiveInViewer(active: boolean): void {
    this.draftTabActiveInViewer = active;
  }

  getSagsKeyWithSpaces(): string {
    return this.sagsKey.substr(0, 2) + ' ' + this.sagsKey.substr(2, 5)
      + ' ' + this.sagsKey.substr(7, 2) + ' ' + this.sagsKey.substr(9, 4);
  }

  trimProperties(): void {
    if (this.clientStates) {
      this.clientStates.forEach(cs => {
        cs.reportKey = cs.reportKey.trim();
        cs.regNr = cs.regNr.trim();
      });
    }

    if (this.reports) {
      this.reports.forEach(report => {
        report.reportKey = report.reportKey.trim();
        report.regNr = report.regNr.trim();
      });
    }
  }
}
