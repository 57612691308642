import {AfterViewInit, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PrincipalService} from 'app/shared';
import {DraftCalculateViewModel} from './draft-calculate.view-model';
import {DraftService} from '../../service/draft.service';
import {ForsiReportKeyPipe} from '../../../shared/pipe/forsi-report-key.pipe';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PrintComponent} from 'app/shared/modals/print/print.component';
import {ForsiReportKeyShortPipe} from '../../../shared/pipe/forsi-report-key-short.pipe';
import {StringUtils} from '../../../shared/utils/string-utils';
import {UIUtils} from 'app/shared/utils/ui-utils';
import {mergeMap, takeUntil} from 'rxjs/operators';
import {PrintSettingsDTO} from '../../../shared/dto/print-settings-dto.model';
import {Subject} from 'rxjs';

@Component({
  templateUrl: './draft-calculate.component.html',
  styleUrls: ['../../draft.scss', './draft-calculate.scss'],
  providers: [ForsiReportKeyPipe, ForsiReportKeyShortPipe, DraftCalculateViewModel]
})
export class DraftCalculateComponent implements OnInit, OnDestroy, AfterViewInit {
  private unsubscribe$ = new Subject<void>();
  private debounce_timer: any;
  private uiUtils = new UIUtils();
  printSettings: PrintSettingsDTO;

  constructor(public viewmodel: DraftCalculateViewModel,
              private principal: PrincipalService,
              private route: ActivatedRoute,
              private router: Router,
              private clientStateService: DraftService,
              private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.viewmodel.navigateBackCallback = () => {
      this.router.navigate(['../'], {relativeTo: this.route});
    };
    this.viewmodel.autoflexCallback = () => {
      this.router.navigate(['../autoflex'], {relativeTo: this.route});
    };
    this.viewmodel.sparePartSupplierCallback = () => {
      this.router.navigate(['../spare-part-supplier'], {relativeTo: this.route});
    }
    this.viewmodel.sparePartSupplierOriginalCallback = () => {
      this.router.navigate(['../spare-part-supplier', {showOriginal: true}], {relativeTo: this.route});
    }
    this.viewmodel.checkOpslagCallback = () => {
      this.router.navigate(['../checkreport'], {relativeTo: this.route});
    };
    this.viewmodel.approveCallback = () => {
      this.router.navigate(['../approve'], {relativeTo: this.route});
    };
    this.viewmodel.deliveryNoteCallBack = () => {
      this.router.navigate(['../deliverynote'], {relativeTo: this.route});
    };
    this.viewmodel.diffCallBack = () => {
      this.router.navigate(['../diff'], {relativeTo: this.route});
    };

    this.route.parent.params.pipe(mergeMap(params => {
      this.viewmodel.token = params.token;
      return this.clientStateService.getPrintSettings(params.token);
    }), takeUntil(this.unsubscribe$)).subscribe(printSettings => {
      this.printSettings = printSettings;
    });
  }

  ngAfterViewInit(): void {
    this.updateHeaderPosition()
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    if (this.debounce_timer) {
      clearTimeout(this.debounce_timer);
    }
    // document.getElementById("print-header").hidden = true;
    this.debounce_timer = setTimeout(() => {
      this.updateHeaderPosition();
    }, 500);
  }

  updateHeaderPosition(): void {
    document.getElementById('print-header').style.top = '' + this.uiUtils.calculateTopOffsetFromNavBar() + 'px';
  }

  didClickVkTap(): void {
    this.viewmodel.activateTap(this.viewmodel.vkPrintTap);
  }

  didClickTaksTap(): void {
    this.viewmodel.activateTap(this.viewmodel.taksPrintTap);
  }

  showPrintPopup(): void {
    const modalRef = this.modalService.open(PrintComponent, {});
    const componentInstance = modalRef.componentInstance as PrintComponent;
    if (this.principal.isTaksator() && StringUtils.isNotEmpty(this.viewmodel.proformaOrdreNummer) && this.viewmodel.vkPrintTap.active) {
      componentInstance.initialize(this.viewmodel.proformaOrdreNummer, false);
    } else {
      componentInstance.initialize(this.viewmodel.token, true);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
