import {Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

/**
 * Capable of containing the "best guess" of the current navigation stack.
 * Will reset stack back to last known stack state in case the user navigates to an url already in the stack.
 * Using this stack we can do a back() navigation with a fallback navigate backurl in case we have a deep link, i.e. a link from qapter.
 * Using this navigation service as a wrapper around location.back(), we can prevent the user from navigating back
 * to qapter (and away from our application) when pressing a "Tilbage" button in the application.
 */
@Injectable({
  providedIn: 'root',
})
export class ClientNavigationService {

  private navigationStack: string[] = [];

  constructor(private location: Location,
              private router: Router) {
    this.updateNavigationStack(this.router.url);
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      //update navigationStack with current url
      this.updateNavigationStack(event.urlAfterRedirects);
    });
  }

  private updateNavigationStack(url): void {
    const elementIndex = this.navigationStack.lastIndexOf(url);
    if (elementIndex !== -1) {
      //pop until we reach current element
      while (this.navigationStack.length > 0) {
        const popped = this.navigationStack.pop();
        if (popped === url) {
          break;
        }
      }
    }
    this.navigationStack.push(url);
  }

  private containsStackedElements(): boolean {
    //navigationStack contains the main url "/" and the current route"/...".
    //to have an actual stack we need minimum two elements not being the home element
    return this.navigationStack.filter(next => next !== '/').length > 1;
  }

  back(fallbackNavigation?: any[]): void {
    if (this.containsStackedElements()) {
      this.navigationStack.pop();
      this.location.back();
    } else {
      //clear stack
      this.navigationStack = ['/'];
      if (fallbackNavigation) {
        this.router.navigate(fallbackNavigation);
      } else {
        this.router.navigate(['/']);
      }
    }
  }

  getPreviousLocation(): string {
    return this.navigationStack[this.navigationStack.length - 2];
  }

}
