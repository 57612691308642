<div class="topbar position-fixed z-3">
    <a *lcHasAnyAuthority="['ROLE_Taks', 'ROLE_VK']" class="float-end ms-2 align-middle" [routerLink]="'faq'" (click)="closeSidebarMenu.emit()">
        <i class="fa fa-question fa-2x"></i>
    </a>
    <a *lcHasAnyAuthority="['ROLE_Taks', 'ROLE_VK']" class="float-end ms-2" [routerLink]="'settings'" (click)="closeSidebarMenu.emit()">
        <i class="fa fa-cog fa-2x"></i>
    </a>

    <a *lcHasAnyAuthority="['ROLE_Taks', 'ROLE_VK']" class="float-end ms-2" [routerLink]="'message'" (click)="closeSidebarMenu.emit()">
        <i *ngIf="messageCount$ | async as messageCount" class="fa fa-envelope text-danger fa-2x position-relative">
            <span class="notify-badge-white">{{messageCount | badgeLabel: 99}}</span></i>
        <i *ngIf="(messageCount$ | async) === 0" class="fa fa-envelope-open-o fa-2x position-relative"></i>
    </a>
    <a *lcHasAnyAuthority="['ROLE_Taks']" class="float-end ms-2" [routerLink]="'offer'" (click)="closeSidebarMenu.emit()">
        <i class="fa fa-inbox fa-2x position-relative ">
            <span *ngIf="offerCount$ | async as offerCount" class="notify-badge-white">{{offerCount | badgeLabel: 99}}</span></i>
    </a>

</div>
