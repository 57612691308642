<lc-bottombar [menu]="bottomMenu" title="Policeopslag" shortTitle="Policeopslag"></lc-bottombar>
<div class="text-center" *ngIf="submitting">
    <i class="fa fa-circle-o-notch fa-spin fop-page-spinner"></i>
</div>

<ng-container *ngIf="policeResponse; then showResponse else showRequest">
</ng-container>

<ng-template #showRequest>
    <h1>Policeopslag</h1>
    <div class="card">
        <div class="card-body">
            <div class="row pb-3">
                <div class="col-form-label col-4">
                    <label class="m-0" for="selskab" style="text-align: right">Selskab</label>
                </div>
                <div class="col-4 pe-0">
                    <lc-autocomplete [(ngModel)]="selskab" id="selskab" name="selskab" required
                                        [items]="selskaber" exactKey="kode" itemText="text">
                    </lc-autocomplete>
                </div>
            </div>

            <div class="row pb-3">
                <div class="col-form-label col-4">
                    <label for="regnr">Registreringsnr</label>
                </div>
                <div class="col-4 pe-0">
                    <input [(ngModel)]="policeRequest.regnr" class="form-control" type="text" id="regnr"
                            autocomplete="off"
                            [required]="isInputRequired()"
                            name="regnr"
                            minlength=1 maxlength=8 size="20"
                    /></div>
            </div>

            <div class="row pb-3">
                <div class="col-form-label col-4">
                    <label for="kundenr">Kundenr</label>
                </div>
                <div class="col-4 pe-0">
                    <input [(ngModel)]="policeRequest.kundenr" class="col-4 form-control" type="text" id="kundenr"
                            autocomplete="off"
                            [required]="isInputRequired()"
                            name="kundenr"
                            minlength=1 maxlength=20 size="20"
                    /></div>
            </div>

            <div class="row pb-3">
                <div class="col-form-label col-4">
                    <label for="policenr">Policenr</label>
                </div>
                <div class="col-4 pe-0">
                    <input [(ngModel)]="policeRequest.policenr" class="col-4 form-control" type="text" id="policenr"
                            autocomplete="off"
                            [required]="isInputRequired()"
                            name="policenr"
                            minlength=1 maxlength=20 size="20"
                    /></div>
            </div>

            <div class="row pb-3">
                <div class="col-form-label col-4">
                    <label for="stelnr">Stelnr</label>
                </div>
                <div class="col-4 pe-0">
                    <input [(ngModel)]="policeRequest.stelnr" class="form-control" type="text" id="stelnr"
                            autocomplete="off"
                            [required]="isInputRequired()"
                            name="stelnr"
                            minlength=1 maxlength=20 size="20"
                    /></div>
            </div>

            <div class="row pb-3">
                <div class="col-form-label col-4">
                    <label for="skadenr">Skadenr</label>
                </div>
                <div class="col-4 pe-0">
                    <input [(ngModel)]="policeRequest.skadenr" class="form-control" type="text" id="skadenr"
                            autocomplete="off"
                            [required]="isInputRequired()"
                            name="skadenr"
                            minlength=1 maxlength=20 size="20"
                    />
                </div>
            </div>

            <div class="row pb-3">
                <div class="col-form-label col-4">
                    <label for="skadedato">Skadedato</label>
                </div>
                <div class="col-4 pe-0">
                            <input class="form-control"
                                    autocomplete="off"
                                    id="skadedato" name="skadedato" [(ngModel)]="skadedatoVal"
                                    ngbDatepicker #skadedatoDP="ngbDatepicker" lcInputMask="date"
                                    [minDate]="{ 'year': 1900, 'month': 01 }"
                                    [maxDate]="pastOrPresentMaxDateSelection()"/>
                </div>
                <div class="col ps-0">
                    <button class="input-group-addon clickable btn" (click)="skadedatoDP.toggle()"
                            type="button">
                        <i class="fa fa-calendar fa-lg"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #showResponse>
    <div class="row">
        <div class="pull-left col-6"><h2>Policeopslag</h2></div>
        <div class="col-6 " *ngIf="allowPrint()">
            <div class="input-group align-top mt-1 print-police-input pull-right">
                <input type="tel" [(ngModel)]="vkNr" maxlength="5" lcInputMask="int5" size="5"
                       placeholder="Værkstedsnummer" title="Indtast evt. værkstedsnummer til køreseddel"
                       class="form-control">
                    <div class="btn btn-outline-secondary" (click)="print()">
                        <i class="fa fa-2x fa-print clickable"></i>
                    </div>
            </div>
        </div>
    </div>

    <lc-show-police [policy]="policeResponse"></lc-show-police>

</ng-template>
