import {NonStandardPositionType} from './non-standard-position-type';

export class CoreNonStandardPositionDTO {
  type: NonStandardPositionType;
  description: string;
  workingUnits: number;
  newForOld: number;
  price: number;
  sparePartDescription: string;
  repairMethod: string;
  group: string;
}
export class NonStandardPositionDTO extends CoreNonStandardPositionDTO{
  id: number;
  itemId: string;
  createdAt: string;
}

export class ExportNonStandardPositionDTO {
  env: string;
  sysTime: number;
  atKlientNr: string;
  username: string;
  positions: CoreNonStandardPositionDTO[]
}
