export class AttachmentDTO {
  id: number;
  contentType: string;
  name: string;
  token: string;
  remark: string;
  report: boolean;
  accessToken: string;
  vkReport: boolean;
  createdAt: string;
}
