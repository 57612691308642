import {Component, EventEmitter, Input, Output} from '@angular/core';

let uniqueIdCounter = 0;
@Component({
  selector: 'lc-multiselect-item',
  templateUrl: './multiselect-item.component.html',
  styleUrls: ['./multiselect-item.scss']
})
export class LcMultiselectItemComponent {
  @Input() model: boolean;
  @Input() disabled: boolean;
  @Input() missing: boolean;
  @Input() fixedWidth = '180px';
  @Output() modelChange: EventEmitter<boolean> = new EventEmitter();
  public uniqueId = uniqueIdCounter++;

  onCheckboxChanged(): void {
    this.modelChange.next(this.model);
  }

  widthStyle(): string {
    return this.fixedWidth;
  }
}
