import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {VideoRequestResultDTO} from './model/video-request-result-dto.model';
import {tap} from 'rxjs/operators';
import {VideoRequestObserverService} from './video-request-observer.service';
import {Cacheable} from 'ts-cacheable';

const cacheBuster$ = new Subject<void>();
const maxAge = 200;

@Injectable()
export class ReportVideoRequestService {

  private static createBaseUrl(reportId: number): string {
    return `report/${reportId}/video-request`
  }

  constructor(private http: HttpClient, private videoRequestObserverService: VideoRequestObserverService) {
  }

  public clearCache(): void {
    cacheBuster$.next();
  }

  @Cacheable({cacheBusterObserver: cacheBuster$, maxAge: maxAge})
  public getVideoRequestByWorkshopReportId(reportId: number): Observable<VideoRequestResultDTO> {
    return this.http.get<VideoRequestResultDTO>(`${ReportVideoRequestService.createBaseUrl(reportId)}`).pipe(
      tap(videoRequestResult => this.videoRequestObserverService.next(videoRequestResult.videoRequest))
    );
  }
}
