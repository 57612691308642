<lc-bottombar [menu]="draftMenu" [iconClass]="this.bottomMenuIconClass" [title]="this.bottomMenuTitle"
              [shortTitle]="this.bottomMenuShortTitle"></lc-bottombar>
<div class="text-center" *ngIf="loading">
    <i class="fa fa-circle-o-notch fa-spin fop-page-spinner"></i>
</div>
<router-outlet></router-outlet>
<ng-container *ngIf="!loading">
    <lc-context-menu [menu]="contextMenu"></lc-context-menu>
    <div class="split-page">
        <div class="split-left d-none d-lg-block">&nbsp;</div>
        <div class="split-right">

            <div class="row">
                <div class="col-md-12">
                    <h2>Overtag kladde</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 pt-3 pb-3" id="report-print">
                    <lc-print-viewer [print]="print"></lc-print-viewer>
                </div>
            </div>
            <div class="row" id="misc">
                <div class="col-md-12">
                    <h2>Bilag</h2>
                    <div class="card">
                        <div class="card-body">
                            <lc-draft-attachment
                                [attachmentSummary]="attachmentSummary"
                                [readonly]="true"
                                [token]="details.token"
                                [clientStateId]="details.id"
                                [reportKey]="getAttachmentReportKey(details)"
                                [registrationNumber]="getRegistrationNumber(details)"
                            ></lc-draft-attachment>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<div class="row mb-4">
    <!-- Room for floating action buttons -->
    <div class="col-md-12 mb-4"></div>
</div>
