import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {DatePipe} from '@angular/common';
import {SharedCommonModule} from 'app/shared/shared-common.module';
import {ForsiDateDirective} from './validators/forsi-date.directive'
import {NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {NgbForsiDateMomentParserFormatter} from 'app/shared/formatter/forsi-date.formatter';
import {SimplePopupComponent} from './modals/simplepopup/simple-popup.component';
import {SharedLibsModule} from 'app/shared/shared-libs.module';
import {ConfirmPopupComponent} from './modals/confirm/confirm-popup.component';
import {NewBroadcastsPopupComponent} from './modals/new-broadcasts/new-broadcasts-popup.component';
import {TextLibraryPopupComponent} from './modals/text-library/text-library-popup.component';
import {DamagesNotePopupComponent} from './modals/damages-note-popup/damages-note-popup.component';
import {NewDraftsPopupComponent} from './modals/new-drafts/new-drafts-popup.component';
import {PrincipalService} from './service/auth/principal.service';
import {IncomingClientStateService} from './service/incoming-client-state.service';
import {SearchInputComponent} from './ui/search/search-input.component';
import {OfferService} from './service/offer.service';
import {ClientNavigationService} from './service/client-navigation.service';
import {ComposeMessagePopupComponent} from './modals/compose-message/compose-message-popup.component';
import {PrintDiffViewerComponent} from './ui/print-diff-viewer/print-diff-viewer.component';
import {LcMultiselectItemComponent} from './ui/multiselect/multiselect-item.component';
import {ReportSearchInputComponent} from './ui/report-search-input/report-search-input.component';
import {AccountService} from './service/account.service';
import {MessageService} from './service/message.service';
import {UserTokenComponent} from './ui/token/user-token.component';
import {InputAndPrintDiffComponent} from './ui/input-and-print-diff/input-and-print-diff.component';
import {isNullOrUndefined} from './utils/object-utils';
import {FeatureToggleDirective} from './directive/feature-toggle.directive';
import {AssessorOrgAssessmentMethodRelationEditPopupComponent} from './assessor-organisation/assessment-method/edit-popup/assessor-org-assessment-method-relation-edit-popup.component';
import {LcButtonModule} from './ui/button/lc-button.module';
import {LoginComponent} from '../home/ui/login/login.component';
import {MultipleDownloadComponent} from './modals/multiple-download-component/multiple-download.component';
import {DamagesNoteComponent} from './ui/damages-note/damages-note.component';
import {DamagesNoteTableViewComponent} from './ui/damages-note-table-view/damages-note-table-view.component';
import {DamagesNoteCardViewComponent} from './ui/damages-note-card-view/damages-note-card-view.component';
import {
  SharedNonStandardPositionFormComponent
} from './non-standard-position-form/shared-non-standard-position-form.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
  imports: [
    SharedLibsModule,
    SharedCommonModule,
    LcButtonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    LoginComponent,
    ForsiDateDirective,
    SimplePopupComponent,
    NewDraftsPopupComponent,
    ConfirmPopupComponent,
    NewBroadcastsPopupComponent,
    TextLibraryPopupComponent,
    DamagesNotePopupComponent,
    DamagesNoteComponent,
    DamagesNoteTableViewComponent,
    DamagesNoteCardViewComponent,
    SearchInputComponent,
    ReportSearchInputComponent,
    ComposeMessagePopupComponent,
    LcMultiselectItemComponent,
    PrintDiffViewerComponent,
    UserTokenComponent,
    InputAndPrintDiffComponent,
    FeatureToggleDirective,
    AssessorOrgAssessmentMethodRelationEditPopupComponent,
    MultipleDownloadComponent,
    SharedNonStandardPositionFormComponent
  ],
  providers: [
    DatePipe,
    {
      provide: NgbDateParserFormatter,
      useClass: NgbForsiDateMomentParserFormatter
    }
  ],
  exports: [
    SharedCommonModule,
    LcButtonModule,
    LoginComponent,
    SimplePopupComponent,
    NewDraftsPopupComponent,
    ForsiDateDirective,
    DatePipe,
    NewBroadcastsPopupComponent,
    TextLibraryPopupComponent,
    DamagesNotePopupComponent,
    DamagesNoteComponent,
    DamagesNoteTableViewComponent,
    DamagesNoteCardViewComponent,
    SearchInputComponent,
    ReportSearchInputComponent,
    ComposeMessagePopupComponent,
    PrintDiffViewerComponent,
    LcMultiselectItemComponent,
    UserTokenComponent,
    InputAndPrintDiffComponent,
    FeatureToggleDirective,
    AssessorOrgAssessmentMethodRelationEditPopupComponent,
    MultipleDownloadComponent,
    SharedNonStandardPositionFormComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {
  constructor(principalService: PrincipalService,
              navigation: ClientNavigationService,
              incomingClientStateService: IncomingClientStateService,
              offerService: OfferService,
              accountService: AccountService,
              messageService: MessageService) {
    principalService.getAuthenticationState().subscribe(principal => {
      if (isNullOrUndefined(principal)) {
        incomingClientStateService.stop();
        offerService.stopOfferCountBackgroundFetcher();
        messageService.stopMessageCountBackgroundFetcher();
        accountService.setShowBusinessToBusinessMenuNotInitialized();
      } else {
        if (principalService.isVK()) {
          incomingClientStateService.start();
          messageService.startMessageCountBackgroundFetcher();
        }
        if (principalService.isTaksator()) {
          offerService.startOfferCountBackgroundFetcher();
          messageService.startMessageCountBackgroundFetcher();
        }
      }
    })
  }
}
