export enum TimelinePositionEnum {
  LEFT,
  RIGHT
}

export enum TimelineEventType {
  DANGER = 'event-danger',
  SUCCESS = 'event-success',
  PRIMARY = 'event-primary',
  LIGHT = 'event-light'
}

export class TimelineMessage {
  headerIconClass: string;
  headerTitle: string;
  message: string;
  date: number;
  position?: TimelinePositionEnum;
  type?: string
}
