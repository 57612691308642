import * as pbi from 'powerbi-client';

export class ReportPdfUrlFilterModel {

  reportFilters: pbi.models.IBasicFilter[];

  constructor(www: boolean, eap: boolean) {
    this.reportFilters = this.setFiltersForReport(www, eap);
  }

  private setFiltersForReport(www: boolean, eap: boolean): pbi.models.IBasicFilter[] {
    let wwwFilterPropertyValue;
    let eapFilterPropertyValue;
    if (www) {
      wwwFilterPropertyValue = 1;
    } else {
      wwwFilterPropertyValue = 0;
    }
    if (eap) {
      eapFilterPropertyValue = 1;
    } else {
      eapFilterPropertyValue = 0;
    }

    return [
      {
        $schema: 'http://powerbi.com/product/schema#basic',
        target: {
          table: 'URL',
          column: 'www'
        },
        filterType: 1, // pbi.models.FilterType.BasicFilter,
        operator: 'In',
        values: [wwwFilterPropertyValue]
      },
      {
        $schema: 'http://powerbi.com/product/schema#basic',
        target: {
          table: 'URL',
          column: 'eap'
        },
        filterType: 1, // pbi.models.FilterType.BasicFilter,
        operator: 'In',
        values: [eapFilterPropertyValue]
      }
    ];
  }
}
