import {Component, OnDestroy} from '@angular/core';
import {ReportService} from '../../service/report.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {PrincipalService} from '../../../shared';
import {ReportCategory} from '../../../shared/model/report-category.model';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {isNullOrUndefined} from '../../../shared/utils/object-utils';

@Component({
  templateUrl: './copy-report.component.html',
  styleUrls: ['./copy-report.component.scss']
})
export class CopyReportModalComponent implements OnDestroy {
  private unsubscribe$ = new Subject<void>();
  beregningsData: boolean;
  bilag: boolean;
  public reportType: string;
  niTusindNr: boolean;
  reportKey: string;
  public reportTypeOfCopy: string = null;
  changeTypeForCopy: boolean;
  showLoading: boolean;
  hasAutoflexBookings: Observable<boolean>;

  constructor(public reportService: ReportService,
              public activeModal: NgbActiveModal,
              public principalService: PrincipalService,
              private router: Router) {
    this.beregningsData = false;
    this.bilag = false;
    this.niTusindNr = false;
    this.showLoading = false;
  }

  open(reportKey: string, reportType: string): void {
    this.reportKey = reportKey;
    this.reportType = reportType;
    //it is only qapter report types that can change type (glastaks and autotaks)
    //for all other report types we do not care about changing the type
    if (ReportCategory.usesQapter(reportType)) {
      this.reportTypeOfCopy = reportType;
    }
    this.hasAutoflexBookings = this.reportService.hasAutoflexBookings(reportKey);
  }

  public cancel(): void {
    this.activeModal.dismiss('cancel');
  }

  isTypeChanged(): boolean {
    return !isNullOrUndefined(this.reportTypeOfCopy) && this.reportType !== this.reportTypeOfCopy;
  }

  doCopy(): void {
    if (this.reportKey) {
      this.showLoading = true;
      this.changeTypeForCopy = this.isTypeChanged();
      this.reportService.copyReport(this.reportKey, this.beregningsData, this.bilag, this.changeTypeForCopy, this.niTusindNr)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(reportCopyResult => {
          this.showLoading = false;
          this.cancel();
          this.router.navigate(['/draft/edit/', reportCopyResult.token]);
        }, error => {
          this.cancel();
          this.showLoading = false;
        });
    }
  }

  showCopyPossibilities(): boolean {
    return !isNullOrUndefined(this.reportTypeOfCopy) && this.principalService.isVK();
  }

  onDamageTypeChanged(): void {
    this.beregningsData = false;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
