<ng-container *ngIf="!loading; else spinner">
    <h2>Godkend rapport</h2>
    <p>Tryk på den grønne 'Godkend' knap for at færdiggøre rapporten.</p>
    <p>Tryk på den gule 'Foreløbig' knap for at foretage en foreløbig godkendelse af rapporten.</p>
    <div class="row ">
        <div class="col-lg-8 pb-3">
            <div class="row p-1" *ngIf="clientStateWarning">
                <div class="col">
                    <div class="card" *ngIf="clientStateWarning.requiresNonSystemReportMessage">
                        <div class="card-header">
                            Advarsel!
                        </div>
                        <div class="card-body">
                            <p class="card-text alert-danger">
                                {{clientStateWarning.systemReportMessageActionDescription}}
                            </p>
                        </div>
                    </div>
                    <div class="card" *ngIf="!clientStateWarning.requiresNonSystemReportMessage">
                        <div class="card-header">
                            Info
                        </div>
                        <div class="card-body">
                            <p class="card-text">
                                {{clientStateWarning.systemReportMessageActionDescription}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row p-1" *ngIf="showApproveCalculationDate">
                <div class="col">
                    <div class="card">
                        <div class="card-header">
                            NB!
                        </div>
                        <div class="card-body">
                            <p class="card-text">
                                NB! Beregningen er lavet {{clientState.updatedAt.toLocaleDateString("da-dk")}}. Der kan være
                                kommet nye reservedelspriser siden.
                                Hvis du godkender får du muligvis ikke de seneste reservedelspriser med.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row p-1 pt-3" *ngIf="!vkIsOperating()">
                <div class="col">
                    <div class="alert alert-warning">
                        Bemærk: {{getVKNotOperatingMessage()}}
                    </div>
                </div>
            </div>
            <div class="row p-1">
                <div class="col">
                    <div class="card" *ngIf="clientState">
                        <div class="card-header">
                            Køretøj
                        </div>
                        <div class="card-block p-1 pt-2 pb-2">
                            <div class="col-12">Regnr: {{clientState.vehicle.regnr}}</div>
                            <div class="col-12">Fabrikat: {{carDetails.fabrikatText}}</div>
                            <div class="col-12">Model: {{carDetails.modelText}}</div>
                            <div class="col-12" *ngIf="clientState.vehicle.stelnr">Stelnr: {{clientState.vehicle.stelnr}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row p-1" *ngIf="showChangeValueDeterioration()">
                <div class="col ms-1">
                    <input type="checkbox" class="form-input-check-single me-2 " name="vaerdiforringelse"
                           id="vaerdiforringelse"
                           [(ngModel)]="clientState.valueDeteriorationText"
                           autocomplete="off"/><label class="align-middle" for="vaerdiforringelse">Evt. værdiforringelse</label>
                </div>
            </div>
        </div>
        <div *lcHasAnyAuthority="['ROLE_Taks']" class="col pb-3">
            <div class="row p-1" *ngIf="approvalDelta?.changeInTotal">
                <div class="col">
                    <div class="card bg-warning" *ngIf="clientStateWarning && !clientStateWarning.requiresNonSystemReportMessage">
                        <div class="card-header">
                            <strong>Værkstedsaccept</strong>
                        </div>
                        <div class="card-body">
                            <p class="card-text mb-2">
                                Din rapport har ikke samme totalbeløb som værkstedstilbud:
                            </p>
                            <div class="ps-1 pb-2">
                            <table>
                                <tr>
                                    <td>Værkstedstilbud:</td>
                                    <td class="ps-3 text-end">{{approvalDelta.vkTotal | number}}</td>
                                </tr>                                <tr>
                                    <td>Din rapport:</td>
                                    <td class="ps-3 text-end">{{approvalDelta.assessorTotal | number: '0.2'}}</td>
                                </tr>
                            </table>
                            </div>
                            <input type="checkbox" class="form-input-check-single ms-1 me-2" name="workshopAccept"
                                   id="workshopAccept"
                                   [(ngModel)]="requestWorkshopAccept"
                                   autocomplete="off"/><label class="align-middle" for="workshopAccept">Værkstedet skal acceptere mit tilbud</label>
                            <div *ngIf="requestWorkshopAccept">
                                <div class="row">
                                    <div class="col">
                                        <div class="float-end">
                                            <lc-org-text-library-button [libraryType]="libraryType" [fullscreen]="false" (selectedText)="didSelectTextFromLibrary($event)"></lc-org-text-library-button>
                                            <button type="button" class="btn btn-sm btn-primary ms-1" (click)="showTextLibrary()">Tekstbibliotek</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col">
                                        <textarea [(ngModel)]="acceptMessage"  id="acceptMessage" name="acceptMessage"
                                                  placeholder="Tilføj eventuelt en besked til værkstedet" class="form-control monospace-text"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <lc-autoflex-part-list [clientStateToken]="clientStateToken"></lc-autoflex-part-list>

    <lc-bottombar [menu]="bottomMenu" [title]="bottomMenuTitle"
                  [shortTitle]="bottomMenuShortTitle"
                  [iconClass]="menuIconClass"></lc-bottombar>
</ng-container>
<ng-template #spinner>
    <div class="text-center">
        <i class="fa fa-circle-o-notch fa-spin fop-page-spinner"></i>
    </div>
</ng-template>
