<div class="modal-header pre-size header-style" style="text-align: center">
    <h3 class="text-truncate">
        Rådgivning til lakering ({{reportKey | lcForsiReportKey}})
    </h3>
    <div aria-label="Close" data-dismiss="modal" class="btn-close clickable" (click)="cancel()">
    </div>
</div>
<div class="modal-body pe-4 ps-4">
    <lc-draft-advise-base
        [advise]="advise"
        [token]="token"
        [reportKey]="reportKey"
        [create]="create"
        (onRefresh)="loadAdvise(token)"
    ></lc-draft-advise-base>
</div>

<div class="modal-footer">
    <div class="pull-right">
        <button *ngIf="create" class="btn btn-primary me-2" (click)="createAdvise()" [disabled]="!advise.toVkName">Opret</button>
        <button *ngIf="!create" class="btn btn-danger me-2" (click)="deleteAdvise()">Slet</button>
        <button class="btn " (click)="cancel()">Luk</button>
    </div>
</div>
