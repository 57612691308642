import {Injectable, OnDestroy} from '@angular/core';
import {NewDraftsPopupComponent} from './new-drafts-popup.component';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {IncomingClientStateService} from '../../service/incoming-client-state.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {isNullOrUndefined} from '../../utils/object-utils';

@Injectable()
export class NewDraftsPopupService implements OnDestroy {
  private unsubscribe$ = new Subject<void>();
  newDraftsModal: NgbModalRef;

  constructor(private incommingClientStateService: IncomingClientStateService,
              private modalService: NgbModal) {

  }

  public initIncommingDraftsPopup(doneCallback: () => void): void {
    this.incommingClientStateService.getData().pipe(takeUntil(this.unsubscribe$)).subscribe(t => {
      if (t.length > 0) {
        if (isNullOrUndefined(this.newDraftsModal)) {
          this.newDraftsModal = this.modalService.open(NewDraftsPopupComponent, {backdrop: 'static', size: 'lg'});
          this.newDraftsModal.result.then(value => {
            this.incommingClientStateService.accept(t)
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe();
          }, reason => {
            this.incommingClientStateService.accept(t)
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe();
          });
          this.newDraftsModal.componentInstance.clientStateList = t;
        }
      } else {
        // Its open. Close it and reload.
        if (!isNullOrUndefined(this.newDraftsModal)) {
          this.newDraftsModal.close();
          this.newDraftsModal = null;
          doneCallback();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();

    if (!isNullOrUndefined(this.newDraftsModal)) {
      this.newDraftsModal.close();
      this.newDraftsModal = null;
    }
  }
}
