<!-- menu when presented in top -->
<div *ngIf="menu" class="row context-menu d-none d-lg-block">
    <div id="context-menu-left" class="navbar-fixed-left">
        <ul>
            <li *ngFor="let item of menu.menuItems"
                [ngClass]="{'invalid-input-fields': item.isInvalid(), 'context-menu-highlighted' : item.isHighlighted() }">
                <a class="d-block" (click)="item.didClickMenuItem()">
                    <i class="fa fa-2x" [ngClass]="item.fontawesomeIconName" aria-hidden="true"></i>
                    <span class="text-truncate">{{item.title}}</span>
                </a>
            </li>
        </ul>
    </div>
</div>
