import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {SharedCommonModule} from 'app/shared/shared-common.module';
import {SharedLibsModule} from 'app/shared/shared-libs.module';
import {DeleteButtonComponent} from './delete-button/delete-button.component';
import {EditButtonComponent} from './edit-button/edit-button.component';
import {ReorderButtonComponent} from './reorder-button/reorder-button.component';
import {ReorderButtonPopupComponent} from './reorder-button/reorder-popup/reorder-button-popup.component';

@NgModule({
  imports: [
    SharedLibsModule,
    SharedCommonModule
  ],
  declarations: [
    DeleteButtonComponent,
    EditButtonComponent,
    ReorderButtonComponent,
    ReorderButtonPopupComponent
  ],
  providers: [],
  exports: [
    DeleteButtonComponent,
    EditButtonComponent,
    ReorderButtonComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LcButtonModule {
}
