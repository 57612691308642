import {Routes} from '@angular/router';
import {RoadmapComponent} from './roadmap.component';
import {UserRouteAccessService} from '../shared/service/auth/user-route-access.service';
import {AppRole} from '../shared/service/auth/app-roles.model';

export const ROADMAP_ROUTES: Routes = [{
  path: 'roadmap',
  component: RoadmapComponent,
  data: {
    authorities: [AppRole.ROADMAP_VIEWER],
    pageTitle: 'Roadmap',
    breadcrumb: 'Roadmap'
  },
  canActivate: [UserRouteAccessService]
}
];
