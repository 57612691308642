import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LcActionMenu, LcActionMenuItem} from '../../bottombar/lc-action-menu.model';
import {DeliveryNoteReceiverType} from '../model/delivery-note-receiver-type.enum';
import {DeliveryNoteReceiverTemplateDataDTO} from '../model/delivery-note-receiver-template-data-dto.model';
import {DeliveryNoteTemplateDataDTO} from '../model/delivery-note-template-data-dto.model';
import {VKStamService} from '../../../service/vkstam.service';
import {DeliveryNoteTemplateOptions} from '../model/delivery-note-template-options.model';
import {NgbDateStruct, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DeliveryNoteStateService} from '../delivery-note-state.service';
import {combineLatest, Subject} from 'rxjs';
import {StringUtils} from '../../../utils/string-utils';
import {DeliveryNoteUtil} from '../delivery-note-util';
import {ClientNavigationService} from '../../../service/client-navigation.service';
import {NgbForsiDateMomentParserFormatter} from '../../../index';
import {ContextMenu, ContextMenuItem} from '../../context-menu/context-menu.model';
import {UIUtils} from '../../../utils/ui-utils';
import {TextLibraryPopupComponent} from '../../../modals/text-library/text-library-popup.component';
import {TextLibraryTypeEnum} from '../../../service/text-library-type.enum';
import {takeUntil} from 'rxjs/operators';
import {isNullOrUndefined} from '../../../utils/object-utils';
import {UserTextLibraryService} from '../../../service/user-text-library.service';
import {RapportType} from '../../../model/rapport-type.model';

@Component({
  templateUrl: './edit-delivery-note.component.html',
  styleUrls: ['edit-delivery-note.scss']
})
export class EditDeliveryNoteComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  private _activeDeliveryNoteReceiver: DeliveryNoteReceiverType;
  private _repairShopNumber: number;

  bottomMenu: LcActionMenu;
  deliveryNoteTemplateData: DeliveryNoteTemplateDataDTO;
  deliveryNoteReceiverTemplateData: DeliveryNoteReceiverTemplateDataDTO;
  deliveryNoteTemplateOptions: DeliveryNoteTemplateOptions;

  libraryType = TextLibraryTypeEnum.FORM18_REMARKS;
  reportType: RapportType;

  // These are required to toggle the datepicker from a button separate to the input field.
  // The selected dates are saved in deliveryNoteTemplateOptions
  dateDatePicker: NgbDateStruct;
  accordingToLetterOrPhoneDatePicker: NgbDateStruct;
  vehicleCanceledDatePicker: NgbDateStruct;
  public contextMenu: ContextMenu;

  get activeDeliveryNoteReceiver(): DeliveryNoteReceiverType {
    return this._activeDeliveryNoteReceiver;
  }

  set activeDeliveryNoteReceiver(value: DeliveryNoteReceiverType) {
    this._activeDeliveryNoteReceiver = value;
    if (!this.isOtherInput()) {
      this._repairShopNumber = null;
      this.deliveryNoteStateService.refreshDeliveryNoteReceiverTemplateDataByReceiverType(value);
    } else {
      this.deliveryNoteStateService.updateDeliveryNoteReceiverTemplateData(new DeliveryNoteReceiverTemplateDataDTO());
    }
  }

  get repairShopNumber(): number {
    return this._repairShopNumber;
  }

  set repairShopNumber(value: number) {
    this.clearRecieverInformation();
    this._repairShopNumber = value;
    if (Number(value) > 0 && this.isOtherInput()) {
      // Load repairshop info into "lines"
      this.vkStamService.getVaerkstedInfo(this._repairShopNumber).pipe(takeUntil(this.unsubscribe$)).subscribe(repairShop => {
        if (repairShop) {
          const deliveryNoteReceiverTemplateData = new DeliveryNoteReceiverTemplateDataDTO();
          deliveryNoteReceiverTemplateData.line1 = StringUtils.isNotEmpty(repairShop.navn) ? repairShop.navn : '';
          deliveryNoteReceiverTemplateData.line2 = StringUtils.isNotEmpty(repairShop.adresse1) ? repairShop.adresse1 : '';
          deliveryNoteReceiverTemplateData.line3 = StringUtils.isNotEmpty(repairShop.adresse2) ? repairShop.adresse2 : '';
          deliveryNoteReceiverTemplateData.line4 = '';
          if (StringUtils.isNotEmpty(repairShop.postnr)) {
            deliveryNoteReceiverTemplateData.line4 = repairShop.postnr;
          }
          if (StringUtils.isNotEmpty(repairShop.bynavn)) {
            deliveryNoteReceiverTemplateData.line4 = StringUtils.isNotEmpty(deliveryNoteReceiverTemplateData.line4) ?
              deliveryNoteReceiverTemplateData.line4 + ' ' + repairShop.bynavn : repairShop.bynavn;
          }
          deliveryNoteReceiverTemplateData.line5 = '';
          this.deliveryNoteStateService.updateDeliveryNoteReceiverTemplateData(deliveryNoteReceiverTemplateData);
        }
      })
    }
  }

  private clearRecieverInformation(): void {
    this.deliveryNoteStateService.updateDeliveryNoteReceiverTemplateData( new DeliveryNoteReceiverTemplateDataDTO())
  }

  constructor(private router: Router,
              private route: ActivatedRoute,
              private vkStamService: VKStamService,
              private navigationService: ClientNavigationService,
              public deliveryNoteStateService: DeliveryNoteStateService,
              private modalService: NgbModal,
              private userTextLibraryService: UserTextLibraryService) {
    if (this.deliveryNoteStateService.getDeliveryNoteTemplateOptions().getValue() == null) {
      this.deliveryNoteStateService.updateDeliveryNoteTemplateOptions(new DeliveryNoteTemplateOptions());
    }
  }

  ngOnInit(): void {
    combineLatest([
      this.deliveryNoteStateService.getReportType(),
      this.deliveryNoteStateService.getDeliveryNoteTemplateData(),
      this.deliveryNoteStateService.getDeliveryNoteReceiverTemplateData(),
      this.deliveryNoteStateService.getDeliveryNoteTemplateOptions()]
    ).pipe(takeUntil(this.unsubscribe$)).subscribe(([reportType, deliveryNoteTemplateData, deliveryNoteReceiverTemplateData, deliveryNoteTemplateOptions]) => {
      this.reportType = reportType;
      this.deliveryNoteTemplateData = deliveryNoteTemplateData;
      this.deliveryNoteReceiverTemplateData = deliveryNoteReceiverTemplateData;
      this.deliveryNoteTemplateOptions = deliveryNoteTemplateOptions;
      if (isNullOrUndefined(this.deliveryNoteTemplateOptions.date)) {
        this.deliveryNoteTemplateOptions.date = new NgbForsiDateMomentParserFormatter().parseDate(new Date())
      }
      //HACK: timeout with zero is necessary to have the UI refresh before trying access UI elements
      setTimeout(() => {
        this.contextMenu.updateMenuHighlights(null);
      });
    });

    this.bottomMenu = new LcActionMenu();
    this.bottomMenu.addItem(new LcActionMenuItem('fa-arrow-left', () => {
      this.navigationService.back(['report']);
    }, 'Tilbage'));
    this.bottomMenu.addItem(new LcActionMenuItem('fa-print', () => {
      this.deliveryNoteStateService.updateDeliveryNoteTemplateOptions(this.deliveryNoteTemplateOptions);
      this.router.navigate(['show'], {relativeTo: this.route});
    }, 'Vis'));
    this.setupContextMenu();
  }

  isOtherInput(): boolean {
    return this._activeDeliveryNoteReceiver != null && this._activeDeliveryNoteReceiver === DeliveryNoteReceiverType.OTHER;
  }

  getCallOnText(): string {
    return DeliveryNoteUtil.getCallOnText(this.deliveryNoteTemplateOptions);
  }

  getCallOnBetweenTimesText(): string {
    return DeliveryNoteUtil.getCallOnBetweenTimesText(this.deliveryNoteTemplateOptions);
  }

  getCheckForAmountForSaleOfSparePartsText(): string {
    return DeliveryNoteUtil.getCheckForAmountForSaleOfSparePartsText(this.deliveryNoteTemplateOptions);
  }

  getAccordingToLetterOrPhoneText(): string {
    return DeliveryNoteUtil.getAccordingToLetterOrPhoneText(this.deliveryNoteTemplateOptions);
  }

  getVehicleCanceledText(): string {
    return DeliveryNoteUtil.getVehicleCanceledText(this.deliveryNoteTemplateOptions);
  }

  setupContextMenu(): void {
    this.contextMenu = new ContextMenu();
    const uiUtils = new UIUtils();
    this.contextMenu.addItem(new ContextMenuItem('fa-vcard-o', 'Stamdata', 'masterdata',
      (item) => {
        uiUtils.scrollToElementId(['masterdata']);
      }));

    this.contextMenu.addItem(new ContextMenuItem('fa-bookmark-o', 'Erklæring / attest', 'declaration-section', (item) => {
      uiUtils.scrollToElementId(['declaration-section']);
    }));

    this.contextMenu.addItem(new ContextMenuItem('fa-envelope-o', 'Besked', 'message-section', (item) => {
      uiUtils.scrollToElementId(['message-section']);
    }));

    this.contextMenu.addItem(new ContextMenuItem('fa-sticky-note-o', 'Bemærkning', 'remarks-section', (item) => {
      uiUtils.scrollToElementId(['remarks-section']);
    }));
  }

  showTextLibrary(): void {
    const modalRef = this.modalService.open(TextLibraryPopupComponent, {size: 'lg', windowClass: 'lc-fullscreen'});
    const popUpComponent = modalRef.componentInstance as TextLibraryPopupComponent;
    popUpComponent.title = 'Bemærkninger Tekstbibliotek';
    popUpComponent.textLibraryType = this.libraryType;
    popUpComponent.textLibraryService = this.userTextLibraryService;
    modalRef.result.then((text: string) => {
      if (text) {
        this.didSelectTextFromLibrary(text);
      }
    }).catch(() => {
      // Necessary to avoid "Uncaught error Error: Uncaught (in promise)" even though we do not use the returned value.
    });
  }

  didSelectTextFromLibrary(text: string) {
    this.deliveryNoteStateService.remarks = this.deliveryNoteStateService.remarks ? this.deliveryNoteStateService.remarks + text : text;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
