<div class="modal-header pre-size header-style" style="text-align: center">
    <h3 class="text-truncate">
        Validering af din rapport
    </h3>
    <div aria-label="Close" data-dismiss="modal" class="btn-close clickable" (click)="close()">
    </div>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-12">
            Du har fået følgende feedback på din rapport:
            <ul>
                <li *ngFor="let message of validationResult.messages">{{message}}</li>
            </ul>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <label for="ignoreValidationReason">For at gennemtvinge godkendelse, skal du give en begrundelse nedenfor:</label>
            <textarea id="ignoreValidationReason" class="form-control"
                      maxlength="200"
                      [(ngModel)]="ignoreValidationReason" rows="3"></textarea>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div class="pull-right">
        <button class="btn btn-primary" (click)="editReport()">Ret rapporten</button>
    </div>
    <div class="pull-right" title="Skriv en årsag for at godkende rapporten">
        <button class="btn btn-danger" [disabled]="ignoreValidationReasonProvided()" (click)="ignoreValidation()">Godkend</button>
    </div>
</div>
