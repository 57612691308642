import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DraftPoliceViewModel} from './draft-police.view-model';
import {ForsiReportKeyPipe} from '../../../shared/pipe/forsi-report-key.pipe';
import {ForsiReportKeyShortPipe} from '../../../shared/pipe/forsi-report-key-short.pipe';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  templateUrl: './draft-police.component.html',
  styleUrls: [],
  providers: [DraftPoliceViewModel, ForsiReportKeyPipe, ForsiReportKeyShortPipe]
})
export class DraftPoliceComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  constructor(private route: ActivatedRoute,
              private router: Router,
              public viewmodel: DraftPoliceViewModel) {
  }

  ngOnInit(): void {
    // save scroll position
    this.viewmodel.navigateBackSubject.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.router.navigate(['../'], {relativeTo: this.route});
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
