import {Component} from '@angular/core';
import {DeliveryNoteStateService} from './delivery-note-state.service';
import {DraftEditService} from '../../../draft/edit/draft-edit.service';

/**
 * The purpose of this wrapper component is to provide services that lives inside the delivery note feature,
 * but is destroyed as soon as the user navigates away from the delivery note feature.
 * possibly see: https://github.com/angular/angular/issues/11228#issuecomment-327255605
 */
@Component({
  template: '<router-outlet></router-outlet>',
  providers: [DeliveryNoteStateService]
})
export class DeliveryNoteDraftWrapperComponent {
  //necessary as this is responsible for initializing the deliveryNoteService once for all subroute
  constructor(private deliveryNoteService: DeliveryNoteStateService,
              private draftEditService: DraftEditService) {

    this.deliveryNoteService.initializeData(this.draftEditService.getToken(), draftEditService);
  }
}
