import {DeliveryNoteSubjectDTO} from './delivery-note-subject-dto.model';
import {DeliveryNoteReceiverTemplateDataDTO} from './delivery-note-receiver-template-data-dto.model';

export class CreateDeliveryNoteDTO {
  date: number;
  subject: DeliveryNoteSubjectDTO;
  receiver: DeliveryNoteReceiverTemplateDataDTO;
  enableForwardOptionsText: string;
  registrationCertificateText: string;
  contractNoteText: string;
  receiptOfCancellationOfLicencePlateText: string;
  attachReportFrontPageText: string;
  attachErText: string;
  attachOpOrGbText: string;
  attachCanceledRegistrationCertificateText: string;
  attachErSignatureText: string;
  chargedByInsuranceCompanyText: string;
  checkForAmountForSaleOfSparePartsText: string;
  attachPrintOfCarBookText: string;
  attachCertificateOfScrapText: string;
  accordingToAgreementText: string;
  returningWithAThankYouText: string;
  accordingToLetterOrPhoneText: string;
  forYourInformationText: string;
  forYourApprovalText: string;
  vehicleCanceledText: string;
  forSignatureText: string;
  callOnText: string;
  requestToBeReturnedText: string;
  requestToHaveAttachedDeclarationReturnedText: string;
  remarks: string;
}
