<ng-container [formGroup]="form">
    <div class="row pb-3">
        <div class="col-form-label col-4">
            <label for="repair-method">Reparationsart</label>
        </div>
        <div class="col">
        <select class="form-select col-8" id="repair-method" name="repair-method"
                formControlName="repairMethod">
            <option *ngFor="let repairMethod of repairMethods" [ngValue]="repairMethod.identifier">
                {{repairMethod.identifier}} - {{repairMethod.description}}
            </option>
        </select></div>
    </div>
    <div class="row pb-3">
        <div class="col-form-label col-4">
            <label for="spare-part-description">Reservedel</label>
        </div>
        <div class="col">
        <input class="form-control"
               id="spare-part-description" name="spare-part-description"
               type="text" formControlName="sparePartDescription"/></div>
    </div>
    <div class="row pb-3">
        <div class="col-form-label col-4">
            <label for="description">Beskrivelse</label>
        </div>
        <div class="col">
            <input class="col form-control"
               id="description" name="description"
               type="text" formControlName="description"/>
        </div>
    </div>
    <div class="row pb-3">
        <div class="col-form-label col-4">
            <label for="working-units">Arbejdsenheder</label>
        </div>
        <div class="input-group col">
            <input class="form-control" type="tel" lcInputMask="int6_2" maxlength="4"
                   id="working-units" name="working-units"
                   formControlName="workingUnits"/>
                <div class="input-group-text">AE</div>
        </div>
    </div>
    <div class="row pb-3">
        <div class="col-form-label col-4">
            <label for="new-for-old">NFG - Ny For Gammel</label>
        </div>
        <div class="input-group col">
            <input class="form-control" type="number"
                   id="new-for-old" name="new-for-old"
                   formControlName="newForOld"/>
                <div class="input-group-text">%</div>
        </div>
    </div>
    <div class="row pb-3">
        <div class="col-form-label col-4">
            <label for="price">Beløb</label>
        </div>
        <div class="input-group col">
            <input class="form-control" type="tel"
                   id="price" name="price"
                   lcInputMask="int6_2" formControlName="price"/>
                <div class="input-group-text">Kr.</div>
        </div>
    </div>
    <div class="row pb-3">
        <div class="col-form-label col-4">
            <label for="group">Gruppe</label>
        </div>
        <div class="col">
            <input class="col-8 form-control"
               id="group" name="group"
               type="text" formControlName="group"/>
        </div>
    </div>
</ng-container>
