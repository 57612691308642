import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {RapportType} from '../../model/rapport-type.model';

@Component({
  templateUrl: 'damages-note-popup.component.html'
})
export class DamagesNotePopupComponent {
  damagesNoteText: string;
  reportType: RapportType;

  constructor(private activeModal: NgbActiveModal) {
  }

  cancel(): void {
    this.activeModal.dismiss();
  }

  textUpdated(updatedDamagesNoteText: string): void {
    this.activeModal.close(updatedDamagesNoteText);
  }
}
