import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {RequisitionComponent} from './';
import {SharedCommonModule} from 'app/shared/shared-common.module';
import {SharedModule} from 'app/shared/shared.module';
import {REQUISITION_ROUTE} from 'app/shared/modals/requisition/requisition.route';

@NgModule({
  imports: [
    SharedModule,
    SharedCommonModule,
    RouterModule.forRoot([REQUISITION_ROUTE], {useHash: true})
  ],
  declarations: [
    RequisitionComponent,
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class RequisitionModule {
}
