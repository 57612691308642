<lc-bottombar [menu]="draftMenu" [iconClass]="this.bottomMenuIconClass" [title]="this.bottomMenuTitle"
              [shortTitle]="this.bottomMenuShortTitle"></lc-bottombar>
<div class="text-center" *ngIf="isLoading">
    <i class="fa fa-circle-o-notch fa-spin fop-page-spinner"></i>
</div>
<router-outlet></router-outlet>
<form #f="ngForm" autocomplete="off">
    <div *ngIf="!isLoading"
         [ngClass]="{'form-dirty':this.showClientStateAsDirty(), 'form-error':this.showClientStateAsInError()} ">
        <lc-context-menu [menu]="this.contextMenu"></lc-context-menu>
        <div class="split-page">
            <div class="split-left d-none d-lg-block">&nbsp;</div>
            <div class="split-right">
                <div class="row" id="car-details">
                    <div class="col-12">
                        <h2>Biloplysninger</h2>
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12 col-sm-6">
                                        <lc-flow2-car-details [formId]="'car-details'"
                                                              [carDetails]="details.gbReportDetails"
                                                              [aargang]="details.vehicle.aargang"></lc-flow2-car-details>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <div class="row pb-3">
                                            <label class="col-form-label col-4" for="vatProgramme">Brugtmomsordning</label>
                                            <div class="col">
                                                <lc-autocomplete class=" col-8 form-control" id="vatProgramme" required [items]="vatProgrammes"
                                                                 [ngModel]="currentVatProgramme" debugInfo="vatProgramme" itemText="label" exactKey="value"
                                                                 (ngModelChange)="onVatProgrammeChanged($event)"></lc-autocomplete>
                                            </div>
                                        </div>
                                        <div class="row pb-3">
                                            <label class="col-form-label col-10" for="resterSelskab">Rester tilhører forsikringsselskab</label>
                                            <div class="col">
                                                <input class="col-2 pull-left mt-2 form-check-input" type="checkbox"
                                                       [(ngModel)]="details.gbReportDetails.resterTilhoerSelskab" id="resterSelskab" name="resterSelskab">
                                            </div>
                                        </div>
                                        <div class="row pb-3">
                                            <label class="col-form-label col-10" for="registreringsAttest">Registreringsattest udleveret</label>
                                            <div class="col">
                                                <input class="col-2 pull-left mt-2 form-check-input" type="checkbox"
                                                       [(ngModel)]="details.gbReportDetails.registreringsAttest" id="registreringsAttest"
                                                       name="registreringsAttest">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" id="bidders">
                    <div class="col-12">
                        <lc-ergb-bidders [formId]="'bidders'" [erGbDetails]="details.gbReportDetails"
                                         [valuation]="details.valuation"></lc-ergb-bidders>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <h2>Bemærkninger</h2>
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-6">
                                        <lc-draft-remarks formId="remarks" [details]="details"></lc-draft-remarks>
                                    </div>
                                    <div class="col-md-6">
                                        <lc-intern-info [details]="details"></lc-intern-info>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row dynamic-padding-bottom">
                    <!-- Room for floating action buttons -->
                    <div class="col-md-12"></div>
                </div>
            </div>
        </div>
    </div>
</form>
