import {StringUtils} from './string-utils';
import {isNullOrUndefined} from './object-utils';

export class ListUtils {
  public static sort<T>(list: T[], orderProp: string, orderReverse: boolean, orderType: string): T[] {
    let doNotSortList: T[] = [];
    if (orderType === 'stringEmptyAlwaysLast') {
      doNotSortList = list.filter(e => StringUtils.isEmpty(e[orderProp]));
      list = list.filter(e => StringUtils.isNotEmpty(e[orderProp]));
    }

    const sortedList: T[] = list.sort((listA, listB) => {
      let a = listA;
      let b = listB;
      if (orderReverse) {
        b = listA;
        a = listB;
      }

      let aValue = a[orderProp];
      let bValue = b[orderProp];

      if (orderType === 'currency' && aValue instanceof String) {
        aValue = aValue ? Number(aValue.replace('.', '').replace(',', '.')) : 0;
        bValue = bValue ? Number(bValue.replace('.', '').replace(',', '.')) : 0;
      } else {
        aValue = aValue ? aValue : '';
        bValue = bValue ? bValue : '';
      }
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return aValue.localeCompare(bValue);
      } else if (aValue < bValue) {
        return -1;
      } else if (bValue < aValue) {
        return 1;
      } else {
        return 0;
      }
    });

    return sortedList.concat(doNotSortList);
  }

  public static filter<T>(list: T[], filterString: string, filterCallback: (filterString: string, item: T) => boolean): T[] {
    const filteredList = [];
    list.forEach(item => {
      if (filterCallback(filterString, item)) {
        filteredList.push(item);
      }
    });

    return filteredList;
  }

  public static filterAndSort<T>(list: T[], filterString: string, filterCallback: (filterString: string, item: T) => boolean, orderProp: string, orderReverse: boolean, orderType: string): T[] {
    const filtered = ListUtils.filter<T>(list, filterString, filterCallback);

    if (orderProp) {
      return ListUtils.sort<T>(filtered, orderProp, orderReverse, orderType);
    }

    return filtered;
  }

  public static isEmpty<T>(list: T[]): boolean {
    return isNullOrUndefined(list) || list.length === 0;
  }
}
