import {Injectable} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {NgbNav} from '@ng-bootstrap/ng-bootstrap';
import {isNullOrUndefined} from './utils/object-utils';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

/**
 * Provided with a navbar the manager can apply deeplinking abilities
 */
@Injectable()
export class LcNavbarDeeplinkManagerService {
  private _unsubscribe$ = new Subject<void>();
  private _ngbNav: NgbNav;
  private defaultTabId: any;
  constructor(private router: Router,
              private route: ActivatedRoute) {
  }

  public initWithNavbar(ngbNav: NgbNav, unsubscribe$: Subject<void>): void {
    if (!isNullOrUndefined(this._ngbNav)) {
      throw new Error('Navbar deeplink manager is already initialised!');
    }
    this._unsubscribe$ = unsubscribe$;
    this._ngbNav = ngbNav;
    this.defaultTabId = this._ngbNav.items.first._id;

    //select tab according to URL params
    this.route.queryParams.pipe(
      takeUntil(this._unsubscribe$)
    ).subscribe(params => {
      this._ngbNav.select(this.extractTabId(params));
    });

    //change url params when new tab is selected
    this._ngbNav.activeIdChange.pipe(
      takeUntil(this._unsubscribe$)
    ).subscribe(value => {
      this.router.navigate([], {relativeTo: this.route, queryParams: { tab: value }, queryParamsHandling: 'merge' });
    });
  }

  /**
   * Extract tab id, default to first child if none is matched
   */
  private extractTabId(urlParams: Params): any {
    let extractedTabId = this.defaultTabId;
    if (!isNullOrUndefined(urlParams)) {
      const requestedTabId = urlParams['tab'];
      const requestedTab = this._ngbNav.items.find(item => item._id === requestedTabId);
      if (!isNullOrUndefined(requestedTab)) {
        extractedTabId = requestedTab._id;
      }
    }
    return extractedTabId;
  }
}
