import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbModal, NgbModalRef, NgbPaginationConfig} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {PrincipalService} from '../../shared';
import {ListUtils} from 'app/shared/utils/list-utils';
import {ClientStateListViewDTO} from '../dto/client-state-list-view-dto.model';
import {BootstrapAlertType, BootstrapGrowlService} from 'app/shared/ui/ngx-bootstrap-growl';
import {DraftService} from 'app/draft/service/draft.service';
import {AttachmentUseCase} from '../../shared/model/attachment-use-case.model';
import {AttachmentSummaryDTO} from '../../shared/dto/attachment-summary-dto.model';
import {ConfirmPopupComponent} from '../../shared/modals/confirm/confirm-popup.component';
import {CopyDraftModalService} from '../service/copy-draft.modal.service';
import {SettingsService} from '../../shared/service/settings.service';
import {FunctionConfigurationDTO} from '../../settings/model/function-configuration-dto.model';
import {NewDraftsPopupService} from '../../shared/modals/new-drafts/new-drafts-popup.service';
import {StorageService} from '../../shared/service/storage.service';
import {DraftListConfig} from '../model/draft-list-config';
import {CarSaleList} from '../../shared/model/carsalelist.model';
import {RapportType} from '../../shared/model/rapport-type.model';
import {StringUtils} from '../../shared/utils/string-utils';
import URLBuilder from '../../shared/utils/url-builder';
import {ReportCategory} from '../../shared/model/report-category.model';
import {DraftDeletableStatus} from './draft-deletable-status';
import {ForwardDraftDTO} from '../../shared/dto/transfer-draft-dto.model';
import {ForwardDraftPopupComponent} from '../ui/forward-draft-popup/forward-draft-popup.component';
import {ForwardDraftModel} from '../model/forward-draft.model';
import {TextLibraryTypeEnum} from '../../shared/service/text-library-type.enum';
import {AccountService} from '../../shared/service/account.service';
import {UserDTO} from '../../shared/dto/user-dto.model';
import {SearchProperties} from '../../shared/ui/report-search-input/SearchProperties';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {LoadingSpinnerUtil} from '../../shared/utils/loading-spinner-util';
import {IntegrationUserDTO} from '../../shared/dto/integration-user-dto.model';
import {UserService} from '../../shared/service/user.service';
import {AttachmentDTO} from '../../shared/dto/attachment-dto.model';
import AttachmentUtil from '../../shared/utils/attachment-utils';
import {isNullOrUndefined} from '../../shared/utils/object-utils';
import {DraftAdviseModalComponent} from './ui/advise/draft-advise-modal.component';
import {DraftAdviseDTO} from '../dto/draft-advise-dto.model';
import {DatePipe} from '@angular/common';
import {DraftAdviseListDTO} from '../dto/draft-advise-list-dto.model';
import {AppVisibilityService} from '../../shared/service/core/app-visibility.service';

@Component({
  templateUrl: './draft-list.component.html',
  styleUrls: [
    'draft-list.scss',
    '../draft.scss'
  ],
  providers: [NewDraftsPopupService, LoadingSpinnerUtil]
})
export class DraftListComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  modalRef: NgbModalRef;
  currentSearchResultAsString: string;
  clientStatesForListView: ClientStateListViewDTO[];
  filteredClientStates: ClientStateListViewDTO[];
  currentPage: ClientStateListViewDTO[];
  showMore: string;
  bilagShow = null;
  b2bShow = null;
  showNewReportTypes = null;
  carSaleList = new CarSaleList();
  orderProp: string;
  orderReverse: boolean;
  orderType = 'string';
  itemsPerPage: any;
  totalItems: number;
  filteredItems: number;
  page: number;
  private restoredPage: number;
  _selectedIndex: number;
  functionConfiguration: FunctionConfigurationDTO;
  attachmentUseCase = AttachmentUseCase.Draft;
  searchInputOpen: boolean;
  currentSearchProperties: SearchProperties;
  integrationUsers: IntegrationUserDTO[];

  public defaultImage = URLBuilder.basePath + 'content/images/defaultImage.png';
  private colleagues: UserDTO[];
  private keydownEnter: boolean;
  currentFilter = '';
  lastReportResponse = 0;
  refreshInterval = 5 * 60 * 1000;
  public showCardImages: boolean;
  private advises = new Map<number, DraftAdviseListDTO>();
  private onAdviseCreate = new Subject<DraftAdviseDTO>();
  private onAdviseDelete = new Subject<DraftAdviseDTO>();

  constructor(public principalService: PrincipalService,
              public draftService: DraftService,
              private paginationConfig: NgbPaginationConfig,
              private bootstrapGrowlService: BootstrapGrowlService,
              private modalService: NgbModal,
              private copyDraftModalService: CopyDraftModalService,
              private settingsService: SettingsService,
              private router: Router,
              private newDraftsPopupService: NewDraftsPopupService,
              private storageService: StorageService,
              private accountService: AccountService,
              public spinnerUtil: LoadingSpinnerUtil,
              private appVisibilityService: AppVisibilityService,
              private userService: UserService,
              private datePipe: DatePipe) {
    this.paginationConfig.boundaryLinks = true;
    this.paginationConfig.maxSize = 5;
    this.paginationConfig.pageSize = 20;
    this.paginationConfig.size = 'sm';
    this.itemsPerPage = this.paginationConfig.pageSize;
    this.clientStatesForListView = [];
    this.filteredClientStates = [];
  }

  @Input()
  set selectedIndex(selectedIndex: number) {
    this._selectedIndex = selectedIndex;
    const draftListConfig = this.storageService.getDraftListConfig();
    draftListConfig.selectedIndex = selectedIndex;
    this.storageService.storeDraftListConfig(draftListConfig);
  }

  get selectedIndex(): number {
    return this._selectedIndex;
  }

  private initializeConfig(): void {
    const draftListConfig = this.storageService.getDraftListConfig();
    if (draftListConfig != null) {
      this.restoredPage = draftListConfig.page;
      this._selectedIndex = draftListConfig.selectedIndex;
      this.orderProp = draftListConfig.orderProp;
      this.orderReverse = draftListConfig.orderReverse;
      this.orderType = draftListConfig.orderType;
      this.currentSearchProperties = Object.assign(new SearchProperties(), draftListConfig.searchProperties);
      this.currentFilter = draftListConfig.filter || '';
    } else {
      // set defaults
      this.page = 1;
      this._selectedIndex = 0;
      this.orderProp = 'updatedAt';
      this.orderReverse = true;

      // store defaults
      this.storeCurrentListConfig();
    }
  }

  doSorting(): void {
    this.filteredClientStates = ListUtils.sort<ClientStateListViewDTO>(this.filteredClientStates, this.orderProp, this.orderReverse, this.orderType);

    //reset pagination when sorting and filtering
    this.loadPage(this.page);
  }

  getDrafts(): void {
    this.spinnerUtil.startLoading();
    this.draftService.findAllClientStates()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(clientStateForListViewDTOResponse => this.gotDrafts(clientStateForListViewDTOResponse)
        , () => {
          this.clientStatesForListView = [];
          this.spinnerUtil.stopLoading();
        });
  }

  gotDrafts(clientStateForListViewDTO: ClientStateListViewDTO[]): void {
    this.lastReportResponse = Date.now();
    if (this.isClientStateListUpdated(clientStateForListViewDTO)) {
      this.clientStatesForListView = clientStateForListViewDTO;
      this.doSearch(this.currentSearchProperties);
    } else {
      this.updateAttachmentSummaries();
    }
    this.spinnerUtil.stopLoading();
  }

  private updateAttachmentSummaries(): void {
    for (const clientStateForListViewDTO of this.currentPage) {
      if (!clientStateForListViewDTO.attachmentSummary) {
        this.updateAttachmentSummary(clientStateForListViewDTO);
      }
    }
  }

  updateAttachmentSummary(clientState: ClientStateListViewDTO): void {
    this.draftService.getAttachmentSummary(clientState.token)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(attachmentSummary => {
        clientState.attachmentSummary = attachmentSummary;
      });
  }

  getAttachments(token: string): Observable<AttachmentDTO[]> {
    return this.draftService.getAttachments(token).pipe(takeUntil(this.unsubscribe$));
  }

  deleteAttachment(attachmentDTO: AttachmentDTO): void {
    this.draftService.deleteAttachment(attachmentDTO.token, attachmentDTO.id).pipe(takeUntil(this.unsubscribe$)).subscribe( () => true);
  }

  private isClientStateListUpdated(clientStateForListViewDTO: ClientStateListViewDTO[]): boolean {
    if (!this.clientStatesForListView || this.clientStatesForListView.length === 0 || this.clientStatesForListView.length !== clientStateForListViewDTO.length) {
      this.currentSearchResultAsString = JSON.stringify(ListUtils.sort<ClientStateListViewDTO>(clientStateForListViewDTO, 'updatedAt', true, null));
      return true;
    }

    const newSortedListAsString = JSON.stringify(ListUtils.sort<ClientStateListViewDTO>(clientStateForListViewDTO, 'updatedAt', true, null));
    const hasChanged = this.currentSearchResultAsString !== newSortedListAsString;
    this.currentSearchResultAsString = newSortedListAsString;
    return hasChanged;
  }

  public hasThumbnail(attachment: AttachmentSummaryDTO): boolean {
    return !isNullOrUndefined(attachment) && !isNullOrUndefined(attachment.thumbnailUrl);
  }

  public getThumbnailUrl(attachment: AttachmentSummaryDTO): string {
    if (attachment && attachment.thumbnailUrl != null) {
      return AttachmentUtil.composeThumbnailUrl(attachment);
    }
  }

  public getAttachmentCount(attachmentSummary: AttachmentSummaryDTO): string | number {
    return isNullOrUndefined(attachmentSummary) ? '-' : attachmentSummary.count;
  }

  getCombinedKey(clientStateForListViewDTO: ClientStateListViewDTO): string {
    return (clientStateForListViewDTO.taksRapportKey ? clientStateForListViewDTO.taksRapportKey : '') + (clientStateForListViewDTO.proformaOrdreNummer ? clientStateForListViewDTO.proformaOrdreNummer : '');
  }

  toggleShowNewReportTypes(clientStateForListViewDTO: ClientStateListViewDTO): void {
    if (this.newReportTypesAllowed(clientStateForListViewDTO)) {
      const before = this.showNewReportTypes;
      const key = this.getCombinedKey(clientStateForListViewDTO);
      this.hideAllSubRows(key);
      this.showNewReportTypes = before === key ? null : key;
      this.showMore = key;
    }
  }

  toggleShowBilag(clientStateForListViewDTO: ClientStateListViewDTO): void {
    const before = this.bilagShow;
    const key = this.getCombinedKey(clientStateForListViewDTO);
    this.hideAllSubRows(key);
    this.bilagShow = before === key ? null : key;
    this.showMore = key;
  }

  toggleShowB2b(clientStateForListViewDTO: ClientStateListViewDTO): void {
    if (!this.disableCarSale(clientStateForListViewDTO)) {
      const before = this.b2bShow;
      const key = this.getCombinedKey(clientStateForListViewDTO);
      this.hideAllSubRows(key);
      this.b2bShow = before === key ? null : key;
      this.showMore = key;
    }
  }

  toggleShowMore(clientStateForListViewDTO: ClientStateListViewDTO): void {
    const key = this.getCombinedKey(clientStateForListViewDTO);
    this.showMore = this.showMore === key ? null : key;
    this.bilagShow = this.bilagShow !== key ? null : key;
    this.b2bShow = this.b2bShow !== key ? null : key;
    this.showNewReportTypes = this.showNewReportTypes !== key ? null : key;
  }

  private hideAllSubRows(combinedKey: string): void {
    this.bilagShow = null;
    this.b2bShow = null;
    this.showNewReportTypes = null;
    this.showMore = this.showMore !== combinedKey ? null : combinedKey;
  }

  getCarSales(): void {
    this.draftService.getCarSales(this.currentPage)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(carSales => {
        const carSalesArrLocal = {};
        carSales.forEach((carSale): void => {
          if (carSale.identifier) {
            carSalesArrLocal[carSale.identifier] = carSale;
          }
        });
        this.carSaleList = new CarSaleList();
        this.carSaleList.setCarSaleList(carSalesArrLocal);
      });
  }

  draftHasBeloeb(draft: ClientStateListViewDTO): boolean {
    return (draft.beloeb.trim().length > 0 && draft.beloeb.trim() !== '0,00');
  }

  disableCarSale(draft: ClientStateListViewDTO): boolean {
    return (!this.draftHasBeloeb(draft) || !this.carSaleList.carSaleSupportedByReportType(draft.taksRapportKey));
  }

  ngOnInit(): void {
    this.settingsService.getFunctionConfiguration()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(functionConfiguration => this.functionConfiguration = functionConfiguration);

    if (this.principalService.isTaksator()) {
      this.accountService.getColleagues(true, false)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(colleagues => this.colleagues = colleagues);

      this.userService.getAssessorOrgIntegrationUserRelations()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(relations => {
          this.integrationUsers = relations.map(relation => relation.integrationUser);
        });
    }

    this.initializeConfig();
    this.getDrafts();
    this.startClientStateUpdateTimer();
    this.newDraftsPopupService.initIncommingDraftsPopup(() => this.getDrafts());
    this.onAdviseCreate.pipe(takeUntil(this.unsubscribe$)).subscribe((advise) => {
      this.advises.set(advise.draftId, {draftId: advise.draftId, updatedAt: advise.updatedAt});
    })
    this.onAdviseDelete.pipe(takeUntil(this.unsubscribe$)).subscribe((advise) => {
      this.advises.delete(advise.draftId);
    })
  }

  private startClientStateUpdateTimer(): void {
    this.appVisibilityService.forsiVisibilityInterval(10_000, 10_000).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      if (!this.spinnerUtil.isLoading && !this.searchInputOpen) {
        if (this.lastReportResponse < Date.now() - this.refreshInterval) {
          this.getDrafts();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  isAuthenticated(): boolean {
    return this.principalService.isAuthenticated();
  }

  editDraft(clientStateForListViewDTO: ClientStateListViewDTO): void {
    if (this.isEditable(clientStateForListViewDTO)) {
      this.router.navigate(['/draft/edit/', clientStateForListViewDTO.token], {state: {attachmentSummary: clientStateForListViewDTO.attachmentSummary}});
    } else {
      this.bootstrapGrowlService.addAlert('Man kan endnu ikke redigere denne ' + clientStateForListViewDTO.type + ' kladde', BootstrapAlertType.WARNING, 5000);
    }
  }

  transferDraft(clientStateForListViewDTO: ClientStateListViewDTO): void {
    const modalRef = this.modalService.open(ForwardDraftPopupComponent);
    const instance = modalRef.componentInstance as ForwardDraftPopupComponent;
    instance.defaultMessageTitle = 'Videresendt igangværende sag ' + clientStateForListViewDTO.taksRapportKey;
    instance.title = 'Videresend Kladde';
    instance.reportType = RapportType.extractFrom(clientStateForListViewDTO.taksRapportKey);
    instance.textLibraryType = TextLibraryTypeEnum.TAKSATORAKTIONBESKED;
    instance.textLibraryTitle = instance.title;
    instance.users = this.colleagues;
    instance.userDescription = 'Kollega';
    instance.forceSendMessage = true;
    instance.onConfirm
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((forwardDraftModel: ForwardDraftModel) => {
        const forwardDraft = new ForwardDraftDTO();
        forwardDraft.receivingUsername = forwardDraftModel.selectedUser.username;
        forwardDraft.messageTitle = forwardDraftModel.title;
        forwardDraft.messageText = forwardDraftModel.text;
        this.draftService.forwardDraft(clientStateForListViewDTO.token, forwardDraft)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(() => {
            this.clientSideRemoveFromClientStateListView([clientStateForListViewDTO.token]);
            this.bootstrapGrowlService.addAlert('Kladden med rapportnøgle \'' + clientStateForListViewDTO.taksRapportKey + '\' er videresendt', BootstrapAlertType.SUCCESS, 5000);
          });
      });
  }

  cancelDraft(clientStateForListViewDTO: ClientStateListViewDTO): void {
    if (!this.isCancellable(clientStateForListViewDTO)) {
      return;
    }
    const cancelModalRef = this.modalService.open(ConfirmPopupComponent);
    const draftKey = clientStateForListViewDTO.taksRapportKey;
    cancelModalRef.componentInstance.title = 'Annuller kladden ' + (draftKey);
    cancelModalRef.componentInstance.body = 'Er du sikker på at du vil annullere \'' + draftKey + '\' ?';
    cancelModalRef.result.then((confirm: boolean) => {
      if (confirm) {
        this.draftService.cancelDraft(clientStateForListViewDTO.token)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(cancelResult => {
            if (cancelResult.cancelled) {
              this.clientSideRemoveFromClientStateListView([clientStateForListViewDTO.token]);
              this.bootstrapGrowlService.addAlert('Kladden med rapportnøgle \'' + cancelResult.reportKey + '\' er annulleret', BootstrapAlertType.SUCCESS, 5000);
            } else {
              this.bootstrapGrowlService.addAlert(cancelResult.message, BootstrapAlertType.WARNING, 5000);
            }
          });
      }
    }).catch(result => {
      //user pressed 'X' (Close) in the modal window
    });
  }

  getDraftDeletableStatus(clientState: ClientStateListViewDTO): DraftDeletableStatus {
    if (clientState.fo) {
      return DraftDeletableStatus.FO
    } else if (StringUtils.isNotEmpty(clientState.proformaOrdreNummer) && !this.principalService.isVK() && StringUtils.isNotEmpty(clientState.taksRapportKey) && clientState.taksRapportKey.endsWith('H')) {
      return DraftDeletableStatus.DRAFT_ASSIGNED
    }
    return DraftDeletableStatus.OK;
  }

  isDraftDeletable(clientState: ClientStateListViewDTO): boolean {
    return this.getDraftDeletableStatus(clientState) === DraftDeletableStatus.OK;
  }

  deleteDraft(clientStateForListViewDTO: ClientStateListViewDTO): void {
    if (this.isDraftDeletable(clientStateForListViewDTO)) {
      const modalRef = this.modalService.open(ConfirmPopupComponent);
      let draftKey;

      if (this.principalService.isVK()) {
        draftKey = clientStateForListViewDTO.proformaOrdreNummer;
      } else {
        draftKey = clientStateForListViewDTO.taksRapportKey;
      }

      modalRef.componentInstance.title = 'Slet kladden ' + (draftKey);
      modalRef.componentInstance.body = 'Er du sikker på at du vil slette \'' + draftKey + '\' ?';
      modalRef.result.then((confirm: boolean) => {
        if (confirm) {
          this.draftService.deleteDraft(clientStateForListViewDTO)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(draftsDeleteResults => {
              const tokensToRemove = draftsDeleteResults.filter(value => value.deleted).map(value => value.token);
              this.clientSideRemoveFromClientStateListView(tokensToRemove);
              draftsDeleteResults.forEach(draftDeleteResult => {
                if (draftDeleteResult.deleted) {
                  this.bootstrapGrowlService.addAlert('Kladden er slettet', BootstrapAlertType.SUCCESS, 5000);
                } else {
                  this.bootstrapGrowlService.addAlert(draftDeleteResult.message, BootstrapAlertType.WARNING, 5000);
                }
              });
            });
        }
      }).catch(result => {
      });
    }
  }

  clientSideRemoveFromClientStateListView(tokensToRemove: string[]): void {
    let refresh = false;
    tokensToRemove.forEach(token => {
      const indexOfItem = this.clientStatesForListView.findIndex(e => e.token === token);
      if (indexOfItem !== -1) {
        this.clientStatesForListView.splice(indexOfItem, 1);
        refresh = true;
      }
    });
    if (refresh) {
      this.doSearch(this.currentSearchProperties);
    }
  }

  loadPage(page: number): void {
    this.page = page || this.restoredPage;
    let start = (this.itemsPerPage * this.page) - this.itemsPerPage;
    if (this.filteredClientStates.length - 1 < start) {
      start = 0;
      this.page = 1;
    }
    const end = (this.itemsPerPage * this.page);
    this.currentPage = this.filteredClientStates.slice(start, end);
    this.updateAttachmentSummaries();
    this._selectedIndex = 0;
    this.storeCurrentListConfig();

    if (this.principalService.isTaksator()) {
      this.getCarSales();
    } else if (this.principalService.isVK()) {
      this.reloadAdvises();
    }
  }

  keyEventHandler(event: KeyboardEvent): void {
    if (event.repeat) {
      return;
    }
    switch (event.key) {
      case 'Enter':
        if (event.type === 'keydown') {
          // We check if the keydown.enter has been initialize from this component otherwise we ignore the event
          // This is mainly done because we otherwise trigger an open details after the search
          this.keydownEnter = true;
        } else if (this.keydownEnter && this.currentPage && this.currentPage.length > this.selectedIndex) {
          this.keydownEnter = false;
          this.editDraft(this.currentPage[this.selectedIndex]);
        }

        break;
      case 'ArrowUp':
        if (this.selectedIndex > 0) {
          this.setSelectedIndex(this.selectedIndex - 1)
        }
        break;
      case 'ArrowDown':
        if (this.selectedIndex < this.currentPage.length - 1) {
          this.setSelectedIndex(this.selectedIndex + 1)
        }
        break;
      default:
      //nothing
    }
  }

  getName(type: string): string {
    return ReportCategory.nameFrom(type);
  }

  copyDraft(clientStateForListView: ClientStateListViewDTO): void {
    if (this.isCopyable(clientStateForListView)) {
      this.copyDraftModalService.open(clientStateForListView.token, clientStateForListView.type);
    }
  }

  getTypeClass(type: string): string {
    const cssClassName = ReportCategory.iconCssClass(type);
    const isEnabled = ReportCategory.enabled(type);
    return 'fa ' + cssClassName + ' fa-xl report-type-' + (isEnabled ? 'green' : 'red');
  }

  isEditable(clientState: ClientStateListViewDTO): boolean {
    return ReportCategory.enabled(clientState.type);
  }

  canApprove(clientState: ClientStateListViewDTO): boolean {
    return (StringUtils.isNotEmpty(clientState.beloeb))
  }

  approve(clientState: ClientStateListViewDTO): void {
    if (this.canApprove(clientState)) {
      this.router.navigate(['/draft/edit/', clientState.token, 'approve'], {state: {attachmentSummary: clientState.attachmentSummary}});
    } else {
      this.bootstrapGrowlService.addAlert('Du kan ikke godkende denne kladde', BootstrapAlertType.WARNING, 5000);
    }
  }

  isCancellable(clientState: ClientStateListViewDTO): boolean {
    return RapportType.H.equals(RapportType.extractFrom(clientState.taksRapportKey)) && !clientState.fo && StringUtils.isNotEmpty(clientState.proformaOrdreNummer);
  }

  isCopyable(clientState: ClientStateListViewDTO): boolean {
    return ReportCategory.isCopyable(clientState.type);
  }

  handleUpdatedDamagesNote(clientState: ClientStateListViewDTO, updatedDamagesNoteText: string): void {
    this.draftService.updateDamagesNote(clientState.token, updatedDamagesNoteText)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(updatedDamagesNote => {
        clientState.damagesNote = updatedDamagesNote.text;
        //We fetch a new list of drafts if the current user is TAKS. Because multiple drafts could be changed.
        //We don't do the for VK for 2 reasons: 1) Gazello is not updated yet, 2) VK can only have one report with the same damage note
        if (this.principalService.isTaksator()) {
          this.getDrafts();
        }

      });
  }

  newReportTypesAllowed(clientState: ClientStateListViewDTO): boolean {
    return ReportCategory.possibleToCreateNewReport(clientState.type);
  }

  showXrefColumn(): boolean {
    return this.functionConfiguration && this.functionConfiguration.importId && this.principalService.isVK();
  }

  isActive(s: string): boolean {
    return !isNullOrUndefined(s) && s.length !== 0
  }

  isTaks(): boolean {
    return this.principalService.isTaksator();
  }

  private storeCurrentListConfig(): void {
    const draftListConfig = new DraftListConfig();
    draftListConfig.page = this.page;
    draftListConfig.selectedIndex = this._selectedIndex;
    draftListConfig.orderProp = this.orderProp;
    draftListConfig.orderReverse = this.orderReverse;
    draftListConfig.orderType = this.orderType;
    draftListConfig.searchProperties = this.currentSearchProperties;
    draftListConfig.filter = this.currentFilter;
    this.storageService.storeDraftListConfig(draftListConfig);
  }

  cancelSearch(): void {
    this.spinnerUtil.stopLoading();
  }

  private stripPercentage(value: string): string {
    return value.replace(/%/g, '');
  }

  doFiltering(filter: string): void {
    this.page = 1;
    this.currentFilter = filter || '';
    this.doSearch(this.currentSearchProperties, false);
  }

  doSearch(searchProperties: SearchProperties, showLoading: boolean = true): void {
    if (showLoading) {
      this.spinnerUtil.startLoading();
    }
    const filter = this.currentFilter.toUpperCase();
    searchProperties = searchProperties ? searchProperties : new SearchProperties();
    this.filteredClientStates = [];
    this.totalItems = 0;
    this.filteredItems = 0;
    this.clientStatesForListView.forEach((clientState: ClientStateListViewDTO) => {
      let match = true;
      const regNrUpperCase = this.stripPercentage(searchProperties.getRegNrUpperCase());
      if (regNrUpperCase.length > 0) {
        match = clientState.regNr && clientState.regNr.toUpperCase().indexOf(regNrUpperCase) > -1;
      }
      const vinUpperCase = this.stripPercentage(searchProperties.getVinUpperCase());
      if (match && vinUpperCase.length > 0) {
        match = clientState.vin && clientState.vin.toUpperCase().indexOf(vinUpperCase) > -1;
      }
      const taksKeyUpperCase = this.stripPercentage(searchProperties.getTaksKeyUpperCase());
      if (match && taksKeyUpperCase.length > 0) {
        match = clientState['taksRapportKey'] && clientState.taksRapportKey.toUpperCase().indexOf(taksKeyUpperCase) > -1;
      }

      const vkKeyUpperCase = this.stripPercentage(searchProperties.getVkKeyUpperCase());
      if (match && vkKeyUpperCase.length > 0) {
        match = clientState.proformaOrdreNummer && clientState.proformaOrdreNummer.toUpperCase().indexOf(vkKeyUpperCase) > -1;
      }
      const datoUpperCase = this.stripPercentage(searchProperties.getDatoUpperCase());
      if (match && datoUpperCase.length > 0) {
        match = clientState.updatedAtFormatted && clientState.updatedAtFormatted.toUpperCase().indexOf(datoUpperCase) > -1;
      }
      const selskabUpperCase = this.stripPercentage(searchProperties.getSelskabUpperCase());
      if (match && selskabUpperCase.length > 0) {
        match = clientState.selskabNavn && clientState.selskabNavn.toUpperCase().indexOf(selskabUpperCase) > -1;
        if (!match) {
          match = clientState.selskab && clientState.selskab.toUpperCase().indexOf(selskabUpperCase) > -1;
        }
      }
      if (match) {
        this.totalItems++;
        if (this.isInFilter(filter, clientState)) {
          this.filteredItems++;
          this.filteredClientStates.push(clientState);
        }
      }
    });

    this.currentSearchProperties = searchProperties;

    this.doSorting();
    this.spinnerUtil.stopLoading();

  }

  doRefresh(): void {
    this.getDrafts();
  }

  setSelectedIndex(index: number): void {
    this.selectedIndex = index;
  }

  stopPropagation(event): void {
    event.stopPropagation();
  }

  private isInFilter(filter: string, clientStateForListViewDTO: ClientStateListViewDTO): boolean {
    if (StringUtils.isEmpty(filter)) {
      return true;
    }
    if (clientStateForListViewDTO.regNr && clientStateForListViewDTO.regNr.toUpperCase().indexOf(filter) > -1) {
      return true;
    }

    const rappKeyFilter = filter.replace(/ /g, '');
    if (clientStateForListViewDTO.taksRapportKey && clientStateForListViewDTO.taksRapportKey.toUpperCase().indexOf(rappKeyFilter) > -1) {
      return true;
    }

    if (clientStateForListViewDTO.proformaOrdreNummer && clientStateForListViewDTO.proformaOrdreNummer.toUpperCase().indexOf(rappKeyFilter) > -1) {
      return true;
    }

    if (clientStateForListViewDTO.vehicleText && (clientStateForListViewDTO.vehicleText).toUpperCase().indexOf(filter) > -1) {
      return true;
    }

    if (clientStateForListViewDTO.selskabNavn && (clientStateForListViewDTO.selskabNavn).toUpperCase().indexOf(filter) > -1) {
      return true;
    }

    if (clientStateForListViewDTO.selskab && (clientStateForListViewDTO.selskab).toUpperCase().indexOf(filter) > -1) {
      return true;
    }

    return clientStateForListViewDTO.updatedAtFormatted && clientStateForListViewDTO.updatedAtFormatted.indexOf(filter) > -1;
  }

  getReportKey(clientStateForListView: ClientStateListViewDTO): string {
    return clientStateForListView.taksRapportKey || clientStateForListView.proformaOrdreNummer;
  }

  isVehicleCardImageVisible(clientStateForListViewDTO: ClientStateListViewDTO): boolean {
    return this.showCardImages && this.hasThumbnail(clientStateForListViewDTO.attachmentSummary);
  }

  toggleShowVehicleCardImages(): void {
    this.showCardImages = !this.showCardImages;
  }

  getCancelDraftMenuTitle(clientState: ClientStateListViewDTO): string {
    if (clientState.fo) {
      return 'Du kan ikke annullere en foreløbig godkendt rapport';
    } else if (!this.isCancellable(clientState)) {
      return 'Du kan ikke annullere en rapport uden et tilbud'
    }
    return 'Annuller';
  }

  getCopyMenuTitle(clientStateForListview: ClientStateListViewDTO): string {
    if (!this.isCopyable(clientStateForListview)) {
      return 'Denne rapporttype kan ikke kopieres'
    } else {
      return 'Kopier';
    }
  }

  getCarSaleMenuTitle(clientState: ClientStateListViewDTO): string {
    let title = 'Bilsalg'
    if (!this.draftHasBeloeb(clientState)) {
      title += ' - Rapport uden beregning kan ikke sælges.'
    } else if (!this.carSaleList.carSaleSupportedByReportType(clientState.taksRapportKey)) {
      title += ' er ikke supporteret for denne rapporttype'
    }
    if (this.carSaleList.isCarSaleDeprecated(clientState.taksRapportKey, clientState.updatedAt)) {
      title += '\n - Denne rapport er opdateret efter bilsalg'
    }
    return title;
  }

  getNewReportMenuTitle(clientState: ClientStateListViewDTO): string {
    if (this.newReportTypesAllowed(clientState)) {
      return 'Ny rapport';
    } else {
      return 'Ny rapport er ikke muligt for denne type af rapport';
    }
  }

  hasAdvise(clientState: ClientStateListViewDTO): boolean {
    return !!this.advises.get(clientState.id)
  }

  openAdviseDialog(clientState: ClientStateListViewDTO): void {
    if (this.openAdviseDialogEnabled(clientState)) {
      const modalRef = this.modalService.open(DraftAdviseModalComponent, {backdrop: 'static', size: 'lg'});
      const instance = modalRef.componentInstance as DraftAdviseModalComponent;
      instance.open(clientState, !this.advises.get(clientState.id), this.onAdviseCreate, this.onAdviseDelete);
    }
  }

  reloadAdvises(): void {
    this.advises.clear();
    this.draftService.getAdvises(this.currentPage)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(advises => {
        advises.forEach((advise => {
          this.advises.set(advise.draftId, advise);
        }))
      });
  }

  getAdviseMenuTitle(clientStateForListview: ClientStateListViewDTO): string {
    let title = 'Rådgivning'
    const isAutotaks = clientStateForListview.type === ReportCategory.AUTOTAKS.schema
      || clientStateForListview.type === ReportCategory.AUTOTAKS_LORRY.schema;
    if (!isAutotaks) {
      title += ' - Der kan kun gives rådgivning på rapporter af typen \'Autotaks\''
    } else if (!this.draftHasBeloeb(clientStateForListview) && !this.advises.get(clientStateForListview.id)) {
      title += ' - Rapport uden beregning kan ikke sendes til rådgivning'
    }
    const advise = this.advises.get(clientStateForListview.id);
    if (advise != null) {
      title += '\nSidst opdateret: ' + this.datePipe.transform(advise.updatedAt, 'dd.MM.yyyy HH:mm:ss')
    }
    return title;

  }

  openAdviseDialogEnabled(clientStateForListview: ClientStateListViewDTO): boolean {
    const isAutotaks = clientStateForListview.type === ReportCategory.AUTOTAKS.schema || clientStateForListview.type === ReportCategory.AUTOTAKS_LORRY.schema;
    return isAutotaks && (this.draftHasBeloeb(clientStateForListview) || !!this.advises.get(clientStateForListview.id));
  }

  extractReportType(clientStateForListview: ClientStateListViewDTO): RapportType {
    const reportKey = this.principalService.isVK() ? clientStateForListview.proformaOrdreNummer : clientStateForListview.taksRapportKey;
    return RapportType.extractFrom(reportKey);
  }
}
