<div class="modal-header" style="text-align: center">
    <h3 class="text-truncate">
        <div *ngIf="inResendEmailState()">Vi har sendt dig en mail</div>
        <div *ngIf="inChangeEmailState()">Skift emailadresse</div>
        <small class="text-muted"></small>
    </h3>
</div>
<div class="modal-body">
    <div class="pb-3" *ngIf="inResendEmailState()">
        <p>Vi har tidligere sendt en mail til dig, hvor du skal bekræfte din mail adresse:<br /><b>{{ b2cUser?.email }}</b></p>
        <p>Da du ikke har reageret på vores mail, får du her muligheden for at gensende vores email eller rette den email adresse vi har registreret i tilfælde af den er forkert.</p>
    </div>
    <div class="pb-3" *ngIf="inChangeEmailState()">
        <p>Vi har registreret følgende email på din bruger:<br /><b>{{ b2cUser?.email }}</b></p>
        <p>Hvad skal denne emailadresse ændres til?</p>
        <div class="row">
            <div class="col">
                <lc-b2c-email-input (mail)="this.newMailAddress = $event"></lc-b2c-email-input>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div class="pull-right" *ngIf="inChangeEmailState()">
        <button class="btn btn-secondary" (click)="goToResendEmailState()">Fortryd</button>
    </div>
    <div class="pull-right" *ngIf="inChangeEmailState()">
        <button class="btn btn-primary" [disabled]="!this.newMailAddress" (click)="changeEmailAddress()">Bekræft ny emailadresse</button>
    </div>
    <div class="pull-right" *ngIf="inResendEmailState()">
        <button class="btn btn-secondary" (click)="goToChangeEmailState()">Skift email</button>
    </div>
    <div class="pull-right" *ngIf="inResendEmailState()">
        <button class="btn btn-primary" [lcAutofocus] (click)="resendActivationEmail()">Gensend aktiveringsemail</button>
    </div>
</div>
