<lc-bottombar [menu]="draftMenu" [iconClass]="this.bottomMenuIconClass" [title]="this.bottomMenuTitle"
              [shortTitle]="this.bottomMenuShortTitle"></lc-bottombar>
<div class="text-center" *ngIf="isLoading">
    <i class="fa fa-circle-o-notch fa-spin fop-page-spinner"></i>
</div>
<router-outlet></router-outlet>
<form #f="ngForm" autocomplete="off">
    <div *ngIf="!isLoading"
         [ngClass]="{'form-dirty':this.showClientStateAsDirty(), 'form-error':this.showClientStateAsInError()} ">
        <lc-context-menu [menu]="this.contextMenu"></lc-context-menu>
        <div class="split-page">
            <div class="split-left d-none d-lg-block">&nbsp;</div>
            <div class="split-right">
                <div class="row" id="masterdata">
                    <div class="col-12">
                        <lc-draft-skavu-master-data formId="masterData" [details]="details"
                                                    [takseringsartKode]="details.skavu.takseringsart"
                                                    (listeTypeId)="listeTypeId=$event"></lc-draft-skavu-master-data>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <lc-draft-forsikringstager-info [formId]="'forsikringInfo'"
                                                        [details]="details"></lc-draft-forsikringstager-info>
                    </div>
                    <div class="col-md-6">
                        <lc-draft-skadelidte-info [formId]="'skadelidteInfo'"
                                                  [details]="details"></lc-draft-skadelidte-info>
                    </div>
                </div>
                <div id="agreements" class="row">
                    <div class="col-12">
                        <lc-draft-agreements [formId]="'agreements'" [details]="details"
                                             (isLoading)="isAgreementsLoading = $event"></lc-draft-agreements>
                    </div>
                </div>
                <div class="row" id="positions">
                    <div class="col-12">
                        <lc-draft-skavu-positions formId="positions" [details]="details"
                                                  [listeTypeId]="listeTypeId"></lc-draft-skavu-positions>
                    </div>
                </div>
                <div id="damagedescription" class="row">
                    <div class="col-12">
                        <lc-draft-damagedescription [formId]="'damagedescription'" [details]="details"
                                                    [damageDescription]="details.damageDescription"
                                                    [selskab]="details.selskab"></lc-draft-damagedescription>
                    </div>
                </div>
                <div id="misc" class="row">
                    <div class="col-12">
                        <lc-draft-misc [formId]="'misc'" [details]="details"></lc-draft-misc>
                    </div>
                    <div class="col-6">
                        <h2>Bemærkninger</h2>
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <lc-draft-remarks formId="remarks" [details]="details"></lc-draft-remarks>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="attachments" class="row">
                    <div class="col-12">
                        <h2>Bilag</h2>
                        <div class="card">
                            <div class="card-body">
                                <div *ngIf="attachmentSummary">
                                    <lc-draft-attachment
                                        [attachmentSummary]="attachmentSummary"
                                        (onAttachmentChanged)="updateAttachmentSummary(details.token)"
                                        [readonly]="false"
                                        [token]="details.token"
                                        [clientStateId]="details.id"
                                        [reportKey]="getAttachmentReportKey(details)"
                                        [registrationNumber]="getRegistrationNumber(details)"
                                        [videoRequestUseCase]="true"
                                    ></lc-draft-attachment>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

