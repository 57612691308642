import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LcActionMenu, LcActionMenuItem} from '../../bottombar/lc-action-menu.model';
import {DeliveryNoteReceiverTemplateDataDTO} from '../model/delivery-note-receiver-template-data-dto.model';
import {DeliveryNoteTemplateDataDTO} from '../model/delivery-note-template-data-dto.model';
import {DeliveryNoteTemplateOptions} from '../model/delivery-note-template-options.model';
import {DeliveryNoteStateService} from '../delivery-note-state.service';
import {CreateDeliveryNoteDTO} from '../model/create-delivery-note-dto.model';
import {combineLatest, Subject} from 'rxjs';
import {DeliveryNoteUtil} from '../delivery-note-util';
import {RapportType} from '../../../model/rapport-type.model';
import {ClientNavigationService} from '../../../service/client-navigation.service';
import {first, takeUntil} from 'rxjs/operators';
import {DeliveryNoteDTO} from '../model/delivery-note-dto.model';
import {TextAreaUtils} from '../../../utils/text-area-utils';
import {BootstrapAlertType, BootstrapGrowlService} from '../../ngx-bootstrap-growl';

@Component({
  templateUrl: './show-delivery-note.component.html',
  styleUrls: ['show-delivery-note.scss']
})
export class ShowDeliveryNoteComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  bottomMenu: LcActionMenu;
  reportType: RapportType;
  deliveryNoteTemplateData: DeliveryNoteTemplateDataDTO;
  deliveryNoteReceiverTemplateData: DeliveryNoteReceiverTemplateDataDTO;
  deliveryNoteTemplateOptions: DeliveryNoteTemplateOptions;
  deliveryNote: DeliveryNoteDTO;
  @ViewChild('deliveryNoteText', {static: true}) public deliveryNoteText: HTMLTextAreaElement;

  constructor(private navigation: ClientNavigationService,
              private deliveryNoteStateService: DeliveryNoteStateService,
              private bootstrapGrowlService: BootstrapGrowlService) {
    // DeliveryNoteTemplateOptions is initialized from the edit view.
    // If you have not been to the edit view there is no point in showing a delivery note
    if (this.deliveryNoteStateService.getDeliveryNoteTemplateOptions().getValue() == null) {
      this.navigateBack();
    }
  }

  ngOnInit(): void {
    combineLatest([
      this.deliveryNoteStateService.getReportType(),
      this.deliveryNoteStateService.getDeliveryNoteTemplateData(),
      this.deliveryNoteStateService.getDeliveryNoteReceiverTemplateData(),
      this.deliveryNoteStateService.getDeliveryNoteTemplateOptions()
    ]).pipe(first(), takeUntil(this.unsubscribe$)).subscribe(([reportType, deliveryNoteTemplateData, deliveryNoteReceiverTemplateData, deliveryNoteTemplateOptions]) => {
      this.reportType = reportType;
      this.deliveryNoteTemplateData = deliveryNoteTemplateData;
      this.deliveryNoteReceiverTemplateData = deliveryNoteReceiverTemplateData;
      this.deliveryNoteTemplateOptions = deliveryNoteTemplateOptions;
      this.deliveryNoteStateService.generateDeliveryNote(this.createDeliveryNoteDTO())
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(deliveryNote => {
          this.deliveryNote = deliveryNote;
          if (this.deliveryNoteStateService.printId) {
            this.deliveryNote.printId = this.deliveryNoteStateService.printId;
          }
          this.fixMaxLineLength(this.deliveryNoteText);
        });
    });

    this.bottomMenu = new LcActionMenu();
    this.bottomMenu.addItem(new LcActionMenuItem('fa-arrow-left', () => {
      this.navigateBack();
    }, 'Tilbage'));

    this.bottomMenu.addItem(new LcActionMenuItem('fa-print', () => {
      this.deliveryNoteStateService.printDeliveryNote(this.deliveryNote).pipe(takeUntil(this.unsubscribe$)).subscribe(printResult => {
        this.deliveryNoteStateService.printId = printResult.printId;
        this.deliveryNote.printId = printResult.printId;
        this.deliveryNoteStateService.showDeliveryNotePrint(printResult.printId, printResult.fileName);
      });
    }, 'Print'));

    const attachToReportMenuItem = new LcActionMenuItem('fa-file', () => {
      this.deliveryNoteStateService.attachedDeliveryNote = this.deliveryNote != null ? this.deliveryNote.print : '';
      this.deliveryNoteStateService.attachDeliveryNote(this.deliveryNote)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          this.bootstrapGrowlService.addAlert('Bilag er gemt', BootstrapAlertType.SUCCESS, 5000);
        });
    }, 'Gem som bilag');

    attachToReportMenuItem.disableMenuItem = () => this.deliveryNote != null && this.deliveryNoteStateService.attachedDeliveryNote === this.deliveryNote.print;

    this.bottomMenu.addItem(attachToReportMenuItem);
  }

  private createDeliveryNoteDTO(): CreateDeliveryNoteDTO {
    const options = this.deliveryNoteTemplateOptions;
    const date = this.deliveryNoteTemplateOptions.date;
    const createDeliveryNote = new CreateDeliveryNoteDTO();

    createDeliveryNote.subject = this.deliveryNoteTemplateData.subject;
    createDeliveryNote.receiver = this.deliveryNoteReceiverTemplateData;
    // month is 0 indexed
    createDeliveryNote.date = date ? new Date(date.year, date.month - 1, date.day).valueOf() : null;
    createDeliveryNote.enableForwardOptionsText = options.enableForwardOptions ? options.enableForwardOptionsText : null;
    createDeliveryNote.registrationCertificateText = options.registrationCertificate ? options.registrationCertificateText : null;
    createDeliveryNote.contractNoteText = options.contractNote ? options.contractNoteText : null;
    createDeliveryNote.receiptOfCancellationOfLicencePlateText = options.receiptOfCancellationOfLicencePlate ? options.receiptOfCancellationOfLicencePlateText : null;
    createDeliveryNote.attachReportFrontPageText = options.attachReportFrontPage ? options.attachReportFrontPageText : null;
    createDeliveryNote.attachErText = options.attachEr ? options.attachErText : null;
    createDeliveryNote.attachOpOrGbText = options.attachOpOrGb ? options.attachOpOrGbText : null;
    createDeliveryNote.attachCanceledRegistrationCertificateText = options.attachCanceledRegistrationCertificate ? options.attachCanceledRegistrationCertificateText : null;
    createDeliveryNote.attachErSignatureText = options.attachErSignature ? options.attachErSignatureText : null;
    createDeliveryNote.chargedByInsuranceCompanyText = options.chargedByInsuranceCompany ? options.chargedByInsuranceCompanyText : null;
    if (options.checkForAmountForSaleOfSpareParts) {
      createDeliveryNote.checkForAmountForSaleOfSparePartsText = DeliveryNoteUtil.getCheckForAmountForSaleOfSparePartsText(options);
    }
    createDeliveryNote.attachPrintOfCarBookText = options.attachPrintOfCarBook ? options.attachPrintOfCarBookText : null;
    createDeliveryNote.attachCertificateOfScrapText = options.attachCertificateOfScrap ? options.attachCertificateOfScrapText : null;
    createDeliveryNote.accordingToAgreementText = options.accordingToAgreement ? options.accordingToAgreementText : null;
    createDeliveryNote.returningWithAThankYouText = options.returningWithAThankYou ? options.returningWithAThankYouText : null;
    if (options.accordingToLetterOrPhone) {
      createDeliveryNote.accordingToLetterOrPhoneText = DeliveryNoteUtil.getAccordingToLetterOrPhoneText(options);
    }
    createDeliveryNote.forYourInformationText = options.forYourInformation ? options.forYourInformationText : null;
    createDeliveryNote.forYourApprovalText = options.forYourApproval ? options.forYourApprovalText : null;
    if (options.vehicleCanceled) {
      createDeliveryNote.vehicleCanceledText = DeliveryNoteUtil.getVehicleCanceledText(options);
    }
    createDeliveryNote.forSignatureText = options.forSignature ? options.forSignatureText : null;
    if (options.callOn) {
      createDeliveryNote.callOnText = DeliveryNoteUtil.getCallOnText(options);
    }
    createDeliveryNote.requestToBeReturnedText = options.requestToBeReturned ? options.requestToBeReturnedText : null;
    createDeliveryNote.requestToHaveAttachedDeclarationReturnedText = options.requestToHaveAttachedDeclarationReturned ? options.requestToHaveAttachedDeclarationReturnedText : null;
    createDeliveryNote.remarks = this.deliveryNoteStateService.remarks;

    return createDeliveryNote;
  }

  private navigateBack(): void {
    this.navigation.back(['report']);
  }

  /**
   * There is no backend logic to make sure each line is only 72 chars.
   * Everything more than 72 chars pr. line will not be in the Report Print.
   */
  fixMaxLineLength(textArea: HTMLTextAreaElement): void {
    const fixMaxLineLength = TextAreaUtils.fixMaxLineLength(textArea, 72, this.deliveryNote.print, 'deliveryNoteText');
    this.deliveryNote.print = fixMaxLineLength.text;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
