<lc-bottombar [menu]="viewmodel.bottomMenu"></lc-bottombar>
<div class="text-center" *ngIf="viewmodel.loading">
    <i class="fa fa-circle-o-notch fa-spin fop-page-spinner"></i>
</div>

<div *ngIf="!viewmodel.valid">Kunne ikke vise siden.</div>
<div *ngIf="!viewmodel.loading && viewmodel.valid">
    <h2>{{titleService.getTitle()}}</h2>
    <lc-print-diff-viewer #diff [reportDiffResult]="viewmodel.report"></lc-print-diff-viewer>
    <div class="row">
        <div class="col-12 dynamic-padding-bottom">&nbsp;</div>
    </div>
</div>
