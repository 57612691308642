import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {distinctUntilChanged, filter, mergeMap, switchMap, takeUntil} from 'rxjs/operators';
import {PrincipalService} from './auth/principal.service';
import {AppVisibilityService} from './core/app-visibility.service';
import {UserB2CLoginDTO} from './auth/user-b2c-login-dto.model';

@Injectable({
  providedIn: 'root',
})
export class B2CLoginRequestedChecker {
  private loginChanged$ = this.principalService.getAuthenticationState().pipe(distinctUntilChanged());
  private loggedOn$ = this.loginChanged$.pipe(filter(p => !!p));
  private loggedOut$ = this.loginChanged$.pipe(filter(p => !p));

  constructor(private principalService: PrincipalService,
              private appVisibilityService: AppVisibilityService) {
  }

  public startListenForPendingB2CRequests$(): Observable<UserB2CLoginDTO> {
    return this.loggedOn$.pipe(
      switchMap(() => this.appVisibilityService.forsiVisibilityInterval(1000, 1000 * 60 * 10).pipe(takeUntil(this.loggedOut$))),
      mergeMap(() => this.principalService.getB2CUser())
    );
  }
}
