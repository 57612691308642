export class DamageDescription {
  //has to match the serverside hardcoded label in Skadetype
  private static TOTALFORSVUNDET_LABEL = 'TOTALFORSVUNDET';

  private static MAX_ALLOWED_SELECTED_ZONES = 8;

  skadeType: string = null;
  andreSkader = '';
  zone1: boolean;
  zone2: boolean;
  zone3: boolean;
  zone4: boolean;
  zone5: boolean;
  zone6: boolean;
  zone7: boolean;
  zone8: boolean;
  zone9: boolean;
  zoneB: boolean;
  zoneF: boolean;
  zoneK: boolean;
  zoneM: boolean;
  zoneO: boolean;
  zoneT: boolean;
  zoneU: boolean;

  disableZone(zoneId: string): boolean {
    let result = false;
    const selectedZonesString = this.getSelectedZonesString();
    if (selectedZonesString.length >= DamageDescription.MAX_ALLOWED_SELECTED_ZONES) {
      //disable zone that is not in the already selected list
      result = selectedZonesString.indexOf(zoneId) < 0;
    }
    return result;
  }

  zoneIsValid(): boolean {
    return DamageDescription.TOTALFORSVUNDET_LABEL === this.skadeType ||
      this.zone1 ||
      this.zone2 ||
      this.zone3 ||
      this.zone4 ||
      this.zone5 ||
      this.zone6 ||
      this.zone7 ||
      this.zone8 ||
      this.zone9 ||
      this.zoneB ||
      this.zoneF ||
      this.zoneK ||
      this.zoneM ||
      this.zoneO ||
      this.zoneT ||
      this.zoneU;
  }

  public getSelectedZonesString(): string {
    let result = '';
    result += this.zone1 ? '1' : '';
    result += this.zone2 ? '2' : '';
    result += this.zone3 ? '3' : '';
    result += this.zone4 ? '4' : '';
    result += this.zone5 ? '5' : '';
    result += this.zone6 ? '6' : '';
    result += this.zone7 ? '7' : '';
    result += this.zone8 ? '8' : '';
    result += this.zone9 ? '9' : '';
    result += this.zoneB ? 'B' : '';
    result += this.zoneF ? 'F' : '';
    result += this.zoneK ? 'K' : '';
    result += this.zoneM ? 'M' : '';
    result += this.zoneO ? 'O' : '';
    result += this.zoneT ? 'T' : '';
    result += this.zoneU ? 'U' : '';
    return result;
  }
}
