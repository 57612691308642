import {Component, Input, OnDestroy} from '@angular/core';
import {BaseAttachmentComponent} from '../../base-attachment.component';
import {AttachmentService} from '../../attachment.service';
import {PrincipalService} from '../../../../index';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable, Subject} from 'rxjs';
import {AttachmentDTO} from '../../../../dto/attachment-dto.model';
import {takeUntil} from 'rxjs/operators';
import {FileUploader} from 'ng2-file-upload';
import {BusinessToBusinessService} from '../../../../../b2b/service/business-to-business.service';
import {LcPopupService} from '../../../../modals/lc-popup.service';

@Component({
  selector: 'lc-b2b-advise-attachment',
  templateUrl: './b2b-advise-attachment.component.html',
  styleUrls: [
    '../../attachment.component.scss'
  ]
})
export class B2bAdviseAttachmentComponent extends BaseAttachmentComponent implements OnDestroy {
  private unsubscribe$ = new Subject<void>();

  @Input() adviseId: number;

  constructor(attachmentService: AttachmentService, principal: PrincipalService, modalService: NgbModal, private b2bService: BusinessToBusinessService, popupService: LcPopupService) {
    super(attachmentService, principal, modalService, popupService);
  }

  protected getAttachmentsObs(): Observable<AttachmentDTO[]> {
    return this.b2bService.getAdviseAttachments(this.adviseId).pipe(takeUntil(this.unsubscribe$));
  }

  public isDeletable(attachment: AttachmentDTO): boolean {
    return false;
  }

  protected prepareFileUploader(): FileUploader {
      return null;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

}
