import {Component, OnDestroy} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {takeUntil} from 'rxjs/operators';
import {saveAs} from 'file-saver';
import * as JSZip from 'jszip';

@Component({
  templateUrl: './multiple-download.component.html',
})
export class MultipleDownloadComponent implements OnDestroy {
  private unsubscribe$ = new Subject<void>();

  title: string;
  zipFileName: string;
  downloadTotal: number;
  downloadWaiting: number;
  downloadFailed: number;
  packagePct: number;
  finished = false;
  
  constructor(private activeModal: NgbActiveModal) {}

  public startDownload(title: string, downloads: Map<string, Observable<Blob>>, zipFileName: string): void {
    this.title = title;
    this.zipFileName = zipFileName;
    this.downloadTotal = downloads.size;
    this.downloadWaiting = downloads.size;
    this.downloadFailed = 0;
    
    const zip = new JSZip()
    downloads.forEach((download, fileName) => {
      download.pipe(takeUntil(this.unsubscribe$)).subscribe((blob) => {
        this.downloadWaiting--;
        zip.file(fileName, blob);
        // saveAs(blob, report.key)
        if (this.downloadWaiting === 0) {
          setTimeout(() => {
            this.finishDownload(zip, zipFileName)
          }, 1000)
        }
      }, () => {
        this.downloadFailed++;
        this.downloadWaiting--;
        if (this.downloadWaiting === 0) {
          this.finishDownload(zip, zipFileName);
        }
      })
    })
  }

  private finishDownload(zip: JSZip, zipFileName: string): void {
    if (this.downloadTotal - this.downloadFailed > 0) {
      zip.generateAsync({type: 'blob'}, (metadata) => {
        this.packagePct = metadata.percent;
      }).then(blob => {
        saveAs(blob, zipFileName);
        this.finished = true;
      });
    } else {
      this.finished = true;
    }
  }
  
  close(): void {
    this.activeModal.close();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

}
