import {Component, HostListener, Input} from '@angular/core';
import {ContextMenu, ContextMenuItem} from './context-menu.model';

@Component({
  selector: 'lc-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: [
    './context-menu.scss'
  ]
})
export class ContextMenuComponent {
  @Input() menu: ContextMenu;

  debounce_timer: any;

  @HostListener('window:scroll', [])
  onScroll(): void {
    if (this.debounce_timer) {
      window.clearTimeout(this.debounce_timer);
    }
    this.debounce_timer = window.setTimeout(() => this.updateVisibility(), 10);
  }

  // Javascript function. Not really angularish.
  private updateVisibleHeight(item: ContextMenuItem): ContextMenuItem {
    item.highlighted = false;
    const rect = document.getElementById(item.anchorName).getBoundingClientRect();
    item.actualHeight = rect.height;

    const topVisible = Math.max(rect.top, 0);
    const bottomVisible = Math.min(rect.bottom, window.innerHeight);
    item.visibleHeight = bottomVisible - topVisible;
    return item;
  }

  updateVisibility(): void {
    for (const item of this.menu.menuItems) {
      this.updateVisibleHeight(item);
    }

    this.menu.menuItems.forEach(this.updateVisibleHeight);
    const lastItem = this.menu.menuItems[this.menu.menuItems.length - 1];
    if (lastItem.actualHeight === lastItem.visibleHeight) { // Hvis det sidste element er fuldt synligt skal det vælges.
      lastItem.highlighted = true;
    } else {
      let lastActivedVisibleHeight = 0;
      let lastActivatedMenu: ContextMenuItem = null;
      let hasActivatedMenu = false;
      this.menu.menuItems.forEach(item => {
        if (!hasActivatedMenu) {
          if (item.visibleHeight === item.actualHeight) { // Hele elementet er vist. Aktiver det første fulde element.
            lastActivatedMenu = item;
            hasActivatedMenu = true;
          } else {
            if (item.visibleHeight > lastActivedVisibleHeight) { // Check om elemetet har en større synlig del end den sidste.
              lastActivatedMenu = item;
              lastActivedVisibleHeight = item.visibleHeight;
            }
          }
        }
      });
      if (lastActivatedMenu !== null) {
        lastActivatedMenu.highlighted = true;
      }
    }
  }
}
