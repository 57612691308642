import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';

import {Observable} from 'rxjs';
import {LoggingService} from 'app/shared/service/logging.service';
import {isNullOrUndefined, ObjectUtils} from 'app/shared/utils/object-utils';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {BootstrapAlertType, BootstrapGrowlService} from '../ui/ngx-bootstrap-growl';
import {VersionCheckService} from '../service/version-check.service';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {

  /**
   * During migration to stateless authentication we can have multiple allowed basepath's for authorization api access.
   */
  private readonly authRelatedUrls: string[] = [
    '/oauth/token',
    '/oauth/b2c',
    '/api/me'
  ];

  constructor(private router: Router,
              private versionCheckService: VersionCheckService,
              private bootstrapGrowlService: BootstrapGrowlService) {
  }

  showError(response: HttpErrorResponse): void {
    let message: string;
    let alertType = BootstrapAlertType.DANGER;
    let autoClose = 5000;
    const errorId = ObjectUtils.exists(response.error) && ObjectUtils.exists(response.error.id) ? response.error.id : null;
    let printErrorId = false;
    switch (response.status) {
      // connection refused, server not reachable
      case 0: {
        message = 'Kunne ikke få forbindelse til forsi.dk';
        break;
      }
      case 400: {
        const jsonError = response.error;
        if (ObjectUtils.exists(jsonError)) {
          const fieldValidationErrors = ObjectUtils.exists(jsonError.fieldErrors) ? jsonError.fieldErrors : null;
          if (fieldValidationErrors) {
            message = '';
            fieldValidationErrors.forEach(fieldError => {
              message += fieldError.field + ': ' + fieldError.message + '\n';
            });
          } else {
            const errorMessage = ObjectUtils.exists(jsonError.message) ? jsonError.message : null;
            if (errorMessage) {
              message = errorMessage;
            } else {
              message = 'Der er sket en fejl i kontakten med forsi.dk';
            }
          }
        }
        break;
      }
      case 403: {
        message = response.error && response.error.message ? response.error.message : 'Du har ikke adgang til denne ressource';
        break;
      }
      case 404: {
        const jsonError = response.error;
        if (ObjectUtils.exists(jsonError)) {
          const fieldValidationErrors = ObjectUtils.exists(jsonError.fieldErrors) ? jsonError.fieldErrors : null;
          if (fieldValidationErrors) {
            message = '';
            fieldValidationErrors.forEach(fieldError => {
              message += fieldError.field + ': ' + fieldError.message + '\n';
            });
            alertType = BootstrapAlertType.WARNING;
            autoClose = 4000;
          } else {
            const errorMessage = ObjectUtils.exists(jsonError.message) ? jsonError.message : null;
            if (errorMessage) {
              message = errorMessage;
              alertType = BootstrapAlertType.WARNING;
              autoClose = 4000;
            } else {
              message = 'Der er sket en fejl i kontakten med forsi.dk';
            }
          }
        }
        break;
      }
      case 409: { //Conflict
        printErrorId = true;
        message = response.error && response.error.message ? response.error.message : 'Der er opstået en serverfejl i kontakten med forsi.dk';
        break;
      }
      case 410: { //gone
        printErrorId = true;
        message = 'Data er ikke tilgængeligt for denne bruger';
        break;
      }
      case 500: {
        printErrorId = true;
        message = 'Der er opstået en serverfejl i kontakten med forsi.dk';
        break;
      }
      case 504: {
        printErrorId = true;
        message = 'Gateway timeout fra forsi.dk';
        break;
      }
      case 507: {
        printErrorId = true;
        message = response.error;
        break;
      }
      default: {
        printErrorId = true;
        message = 'Der er opstået en fejl i kontakten med forsi.dk (' + response.status + ')';
      }
    }
    this.versionCheckService.checkVersion();
    const messagePostfix = printErrorId && !isNullOrUndefined(errorId) ? '. Ref: ' + errorId : '';
    this.bootstrapGrowlService.addAlert(message + messagePostfix, alertType, autoClose);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(tap(
      (evt: any) => {
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {

          if (err.status === 304) {
            return;
          }

          //errors in our clientlog reporting tool, should be silent
          if (err.url && err.url.includes(LoggingService.LOG_URL)) {
            return;
          }

          const unauthorized = err.status === 401;
          const callingAuthRelatedUrls = err.url &&
            !isNullOrUndefined(this.authRelatedUrls.find(authRelatedUrl => err.url.includes(authRelatedUrl)));

          if (callingAuthRelatedUrls) {
            if (!unauthorized) {
              //all other than unauthorized should produce an generic err event on login page
              this.showError(err)
            }
          } else {
            if (unauthorized) {
              console.log('navigate to accessdenied', err.url);
              this.router.navigate(['/accessdenied']);
            } else {
              this.showError(err);
            }
          }
        }
      }));
  }
}
