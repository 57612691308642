<h1 class="">Igangv&aelig;rende <small>(<span *ngIf="filteredItems != totalItems">{{filteredItems}}
    af </span>{{totalItems}})</small></h1>

<div class="no-focus" (keydown.enter)="keyEventHandler($event)" (keyup)="keyEventHandler($event)" tabindex="0"
     [lcAutofocus]>

    <lc-report-search-input
        (onCancelSearch)="cancelSearch()"
        (onSearch)="doSearch($event)"
        (onRefresh)="doRefresh()"
        (onSearchInputOpen)="searchInputOpen = true"
        (onSearchInputClosed)="searchInputOpen = false"
        [isLoading]="spinnerUtil.isLoading"
        [currentSearchProperties]="currentSearchProperties"
        [filter]="currentFilter"
        (onFilteringChanged)="doFiltering($event)"
        [title]="'Søgning i egne igangværende'">
    </lc-report-search-input>

    <div class="row d-none d-md-block no-focus mt-1">
        <div class="col-md-12">
            <div class="table-responsive overflow-hide">
                <table class="table table-hover">
                    <thead>
                    <tr>
                        <th class="text-center clickable"
                            (click)="orderType='stringEmptyAlwaysLast'; orderProp = 'damagesNote'; orderReverse=!orderReverse; page=1; doSorting()">
                            <i class="fa"
                               [ngClass]="{'fa-sort': orderProp !== 'damagesNote', 'fa-sort-asc': orderProp === 'damagesNote' && !orderReverse, 'fa-sort-desc': orderProp === 'damagesNote' && orderReverse}"></i>
                            <i class="fa fa-sticky-note-o"></i>
                        </th>
                        <th class="text-center">Type</th>
                        <th class="text-start clickable"
                            (click)="orderType='string'; orderProp = 'regNr'; orderReverse=!orderReverse; page=1; doSorting()">
                            <i class="fa"
                               [ngClass]="{'fa-sort': orderProp !== 'regNr', 'fa-sort-asc': orderProp === 'regNr' && !orderReverse, 'fa-sort-desc': orderProp === 'regNr' && orderReverse}"></i>
                            Reg nr.
                        </th>
                        <th class="text-start d-none d-sm-table-cell">&nbsp;</th>
                        <th class="clickable"
                            (click)="orderType='string'; orderProp = 'vehicleText'; orderReverse=!orderReverse; page=1; doSorting()">
                            <i class="fa"
                               [ngClass]="{'fa-sort': orderProp !== 'vehicleText', 'fa-sort-asc': orderProp === 'vehicleText' && !orderReverse, 'fa-sort-desc': orderProp === 'vehicleText' && orderReverse}"></i>
                            Model
                        </th>
                        <th class="text-start d-none d-xl-table-cell clickable"
                            (click)="orderType='string'; orderProp = 'inspectionDate'; orderReverse=!orderReverse; page=1; doSorting()">
                            <i class="fa"
                               [ngClass]="{'fa-sort': orderProp !== 'inspectionDate', 'fa-sort-asc': orderProp === 'inspectionDate' && !orderReverse, 'fa-sort-desc': orderProp === 'inspectionDate' && orderReverse}"></i>
                            Besigtigelse
                        </th>
                        <th *lcHasAnyAuthority="'ROLE_Taks'" class="text-center d-none d-sm-table-cell">
                            &nbsp;
                        </th>
                        <th class="text-start d-none d-sm-table-cell">
							<span class="clickable"
                                  (click)="orderType='string'; orderProp = 'proformaOrdreNummer'; orderReverse=!orderReverse; page=1; doSorting()">
								<i class="fa"
                                   [ngClass]="{'fa-sort': orderProp !== 'proformaOrdreNummer', 'fa-sort-asc': orderProp === 'proformaOrdreNummer' && !orderReverse, 'fa-sort-desc': orderProp === 'proformaOrdreNummer' && orderReverse}"></i>
								Tilbud
							</span>
                            <span class="clickable" *lcHasAnyAuthority="'ROLE_Taks'"
                                  (click)="orderType='string'; orderProp = 'taksRapportKey'; orderReverse=!orderReverse; page=1; doSorting()">
								<i class="fa"
                                   [ngClass]="{'fa-sort': orderProp !== 'taksRapportKey', 'fa-sort-asc': orderProp === 'taksRapportKey' && !orderReverse, 'fa-sort-desc': orderProp === 'taksRapportKey' && orderReverse}"></i>
								Rapport
							</span>
                        </th>
                        <ng-container *ngIf="showXrefColumn()">
                            <th class="text-start d-none d-xl-table-cell clickable"
                                (click)="orderType='string'; orderProp = 'xref'; orderReverse=!orderReverse; page=1; doSorting()">
                                <i class="fa"
                                   [ngClass]="{'fa-sort': orderProp !== 'xref', 'fa-sort-asc': orderProp === 'xref' && !orderReverse, 'fa-sort-desc': orderProp === 'xref' && orderReverse}"></i>
                                {{functionConfiguration.importId}}
                            </th>
                        </ng-container>
                        <th class="text-start d-none d-lg-table-cell clickable"
                            (click)="orderType='string'; orderProp = 'selskabNavn'; orderReverse=!orderReverse; page=1; doSorting()">
                            <i class="fa"
                               [ngClass]="{'fa-sort': orderProp !== 'selskabNavn', 'fa-sort-asc': orderProp === 'selskabNavn' && !orderReverse, 'fa-sort-desc': orderProp === 'selskabNavn' && orderReverse}"></i>
                            Selskab
                        </th>
                        <th class="text-start d-none d-md-table-cell clickable"
                            (click)="orderType='currency'; orderProp = 'beloeb'; orderReverse=!orderReverse; page=1; doSorting()">
                            <i class="fa"
                               [ngClass]="{'fa-sort': orderProp !== 'beloeb', 'fa-sort-asc': orderProp === 'beloeb' && !orderReverse, 'fa-sort-desc': orderProp === 'beloeb' && orderReverse}"></i>
                            Beløb
                        </th>
                        <th class="text-start d-none d-md-table-cell clickable"
                            (click)="orderType='string'; orderProp = 'updatedAt'; orderReverse=!orderReverse; page=1; doSorting()">
                            <i class="fa"
                               [ngClass]="{'fa-sort': orderProp !== 'updatedAt', 'fa-sort-asc': orderProp === 'updatedAt' && !orderReverse, 'fa-sort-desc': orderProp === 'updatedAt' && orderReverse}"></i>
                            Opdateret
                        </th>
                        <th class="d-none d-xl-table-cell"></th>
                        <th class="d-none d-xl-table-cell"></th>
                        <th class="d-none d-sm-table-cell"></th>
                        <th class="d-none d-xl-table-cell"></th>
                        <th class="d-none d-xl-table-cell"></th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <ng-container *ngFor="let clientStateForListview of currentPage; let i = index">
                        <tr (dblclick)="editDraft(clientStateForListview)" (click)="selectedIndex=i;"
                            [title]="clientStateForListview.fo ? 'Denne rapport er foreløbig godkendt' : ''"
                            [ngClass]="{'selected-row':clientStateForListview === currentPage[selectedIndex], 'provisionally-approved': clientStateForListview.fo}">
                            <td class="d-none d-sm-table-cell text-center" style="width:80px;">
                                <lc-damages-note-table-view
                                    [damagesNoteObservable]="draftService.getDamagesNote(clientStateForListview.token)"
                                    [damagesNoteText]="clientStateForListview.damagesNote"
                                    [reportType]="extractReportType(clientStateForListview)"
                                    (damagesNoteTextUpdatedEmitter)="handleUpdatedDamagesNote(clientStateForListview, $event)"></lc-damages-note-table-view>
                            </td>
                            <td class="text-center">
                                <i class="clickable {{getTypeClass(clientStateForListview.type)}} report-state-padded"
                                   [ngbPopover]="reportStateLegend" triggers="mouseenter:mouseleave" placement="right">
									<span *ngIf="!getTypeClass(clientStateForListview.type)">
										{{getName(clientStateForListview.type)}}
									</span>
                                </i>
                            </td>
                            <td class="text-start" style="width:90px;">{{clientStateForListview.regNr}}</td>
                            <td class="d-none d-sm-table-cell" style="width:64px;">
                                <div [lazyLoad]="getThumbnailUrl(clientStateForListview.attachmentSummary)" [defaultImage]="defaultImage" class="list-thumbnail"></div>
                            </td>
                            <td><span
                                style="max-height: 32px; display: block; overflow: hidden;">{{clientStateForListview.vehicleText}}</span>
                            </td>
                            <td class="d-none d-xl-table-cell text-start" style="width:100px;">
                                {{clientStateForListview.inspectionDate | date: 'dd-MM-yyyy'}}
                            </td>
                            <td *lcHasAnyAuthority="'ROLE_Taks'" class="d-none d-sm-table-cell text-start"
                                style="width:25px;">
                                <ng-container *ngIf="clientStateForListview.hasUnreadMessages">
                                    <span class="fa fa-envelope text-danger fa-sm"></span>
                                </ng-container>
                            </td>
                            <td class="d-none d-sm-table-cell text-start" style="width:160px;white-space: nowrap;">
                                {{clientStateForListview.proformaOrdreNummer | lcForsiReportKey}}
                                <br/>
                                <span *lcHasAnyAuthority="'ROLE_Taks'">
									{{clientStateForListview.taksRapportKey | lcForsiReportKey}}
								</span>
                            </td>
                            <ng-container *ngIf="showXrefColumn()">
                                <td class="d-none d-xl-table-cell text-start">{{clientStateForListview.xref}}</td>
                            </ng-container>
                            <td class="d-none d-lg-table-cell text-start">{{clientStateForListview.selskabNavn}}</td>
                            <td class="d-none d-md-table-cell text-start">{{clientStateForListview.beloeb}}</td>
                            <td class="d-none d-md-table-cell text-start">{{clientStateForListview.updatedAtFormatted}}</td>
                            <td class="d-none d-sm-table-cell position-relative">
                                <div *ngIf="showMore===getCombinedKey(clientStateForListview)"
                                     [ngClass]="{'d-xl-none':principalService.isVK()}" class=" draft-item-menu"
                                     role="group">
                                    <div class="pull-right draft-item-menu-content">
                                        <div *lcHasAnyAuthority="'ROLE_VK'" class="table-cell table-button">
                                            <div (click)="openAdviseDialog(clientStateForListview)"
                                                 class="table-button" [title]="getAdviseMenuTitle(clientStateForListview)"
                                                 [class.table-button-disabled]="!openAdviseDialogEnabled(clientStateForListview)">
                                                <i class="fa fa-2x position-relative" [class.fa-link]="hasAdvise(clientStateForListview)" [class.fa-chain-broken]="!hasAdvise(clientStateForListview)">
                                                </i>
                                            </div>
                                        </div>
                                        <div class="table-cell table-button">
                                            <div (click)="copyDraft(clientStateForListview)"
                                                 class="table-button p-0" title="Kopier"
                                                 [ngClass]="{'table-button-disabled': !isCopyable(clientStateForListview)}"
                                                 [title]="getCopyMenuTitle(clientStateForListview)">
                                                <i class="fa fa-copy fa-2x position-relative">
                                                </i>
                                            </div>
                                        </div>
                                        <div class="table-cell table-button">
                                            <div (click)="deleteDraft(clientStateForListview)"
                                                 class="table-button"
                                                 [title]="getDraftDeletableStatus(clientStateForListview).message"
                                                 [ngClass]="{'table-button-disabled': !isDraftDeletable(clientStateForListview)}">
                                                <i class="fa fa-trash fa-2x position-relative">
                                                </i>
                                            </div>
                                        </div>
                                        <div class="table-cell table-button" *lcHasAnyAuthority="'ROLE_Taks'">
                                            <div (click)="transferDraft(clientStateForListview)"
                                                 class="table-button" title="Videresend">
                                                <i class="fa fa-share fa-2x position-relative">
                                                </i>
                                            </div>
                                        </div>
                                        <div class="table-cell table-button" *lcHasAnyAuthority="'ROLE_Taks'">
                                            <div (click)="cancelDraft(clientStateForListview)"
                                                 class="table-button"
                                                 [ngClass]="{'table-button-disabled': !isCancellable(clientStateForListview)}"
                                                 [title]="getCancelDraftMenuTitle(clientStateForListview)">
                                                <i class="fa fa-undo fa-2x position-relative">
                                                </i>
                                            </div>
                                        </div>
                                        <div class="table-cell table-button" *lcHasAnyAuthority="'ROLE_Taks'">
                                            <div (click)="toggleShowNewReportTypes(clientStateForListview)"
                                                 (dblclick)="stopPropagation($event)"
                                                 class="table-button"
                                                 [ngClass]="{'table-button-disabled': !newReportTypesAllowed(clientStateForListview),
											 'draft-item-menu-selected': showNewReportTypes===getCombinedKey(clientStateForListview)}"
                                                 [title]="getNewReportMenuTitle(clientStateForListview)">
                                                <i class="fa fa-plus fa-2x position-relative">
                                                </i>
                                            </div>
                                        </div>
                                        <div class="d-xl-none table-button" *lcHasAnyAuthority="'ROLE_Taks'">
                                            <div
                                                [ngClass]="{'table-button-disabled': disableCarSale(clientStateForListview),
                                                'draft-item-menu-selected': b2bShow===getCombinedKey(clientStateForListview)}"
                                                (click)="toggleShowB2b(clientStateForListview)"
                                                (dblclick)="stopPropagation($event)"
                                                class="table-button"
                                                [title]="getCarSaleMenuTitle(clientStateForListview)">
                                                <i class="fa fa-2x position-relative"
                                                   [ngClass]="!carSaleList.hasCarSale(clientStateForListview.taksRapportKey) ? 'fa-chain-broken' : 'fa-chain'">
                                                    <span *ngIf="carSaleList.isCarSaleDeprecated(clientStateForListview.taksRapportKey, clientStateForListview.updatedAt)" class="notify-badge-warning font-weight-bold">!</span>
                                                </i>
                                            </div>
                                        </div>
                                        <div class="d-xl-none table-button">
                                            <div (click)="toggleShowBilag(clientStateForListview)"
                                                 (dblclick)="stopPropagation($event)"
                                                 class="table-button"
                                                 [ngClass]="{'draft-item-menu-selected': bilagShow===getCombinedKey(clientStateForListview)}"
                                                 title="Bilag">
                                                <i class="fa fa-paperclip fa-2x position-relative">
                                                    <lc-attachment-badge [attachmentSummary]="clientStateForListview.attachmentSummary"></lc-attachment-badge>
                                                </i>
                                            </div>
                                        </div>
                                        <div class="d-xl-none table-button">
                                            <div class="table-button"
                                                 (click)="editDraft(clientStateForListview)"
                                                 [ngClass]="{'table-button-disabled': !isEditable(clientStateForListview)}"
                                                 title="Rediger">
                                                <i class="fa fa-edit fa-2x"></i>
                                            </div>
                                        </div>

                                        <div class="d-xl-none table-button" *lcHasAnyAuthority="'ROLE_Taks'">
                                            <div class="table-button"
                                                 (click)="approve(clientStateForListview)"
                                                 (dblclick)="stopPropagation($event)"
                                                 [ngClass]="{'table-button-disabled': !canApprove(clientStateForListview)}"
                                                 [title]="canApprove(clientStateForListview)?'Godkend':'Du kan kun godkende rapporter der har en beregning'">
                                                <i class="fa fa-check fa-2x"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="d-none d-xl-table-cell table-button" *lcHasAnyAuthority="'ROLE_Taks'">
                                <div
                                    [ngClass]="{'table-button-disabled': disableCarSale(clientStateForListview),
									  'draft-item-menu-selected': b2bShow===getCombinedKey(clientStateForListview)}"
                                    (click)="toggleShowB2b(clientStateForListview)" (dblclick)="stopPropagation($event)"
                                    class="table-button"
                                    [title]="getCarSaleMenuTitle(clientStateForListview)">
                                    <i class="fa fa-2x position-relative"
                                       [ngClass]="!carSaleList.hasCarSale(clientStateForListview.taksRapportKey) ? 'fa-chain-broken' : 'fa-chain'">
                                        <span *ngIf="carSaleList.isCarSaleDeprecated(clientStateForListview.taksRapportKey, clientStateForListview.updatedAt)" class="notify-badge-warning font-weight-bold">!</span>
                                    </i>
                                </div>
                            </td>
                            <td class="d-none d-xl-table-cell table-button" *lcHasAnyAuthority="'ROLE_VK'">
                                <div *lcHasAnyAuthority="'ROLE_VK'" (click)="openAdviseDialog(clientStateForListview)"
                                     class="table-button" [title]="getAdviseMenuTitle(clientStateForListview)"
                                     [class.table-button-disabled]="!openAdviseDialogEnabled(clientStateForListview)">
                                    <i class="fa fa-2x position-relative" [class.fa-link]="hasAdvise(clientStateForListview)" [class.fa-chain-broken]="!hasAdvise(clientStateForListview)">
                                    </i>
                                </div>
                            </td>
                            <td class="d-none d-xl-table-cell table-button" *lcHasAnyAuthority="'ROLE_VK'">
                                <div (click)="copyDraft(clientStateForListview)" (dblclick)="stopPropagation($event)"
                                     class="table-button" title="Kopier"
                                     [ngClass]="{'table-button-disabled': !isCopyable(clientStateForListview)}"
                                     [title]="getCopyMenuTitle(clientStateForListview)">
                                    <i class="fa fa-copy fa-2x">
                                    </i>
                                </div>
                            </td>
                            <td class="d-none d-xl-table-cell table-button" *lcHasAnyAuthority="'ROLE_VK'">
                                <div (click)="deleteDraft(clientStateForListview)" (dblclick)="stopPropagation($event)"
                                     class=" table-button"
                                     [title]="getDraftDeletableStatus(clientStateForListview).message"
                                     [ngClass]="{'table-button-disabled': !isDraftDeletable(clientStateForListview)}">
                                    <i class="fa fa-trash fa-2x">
                                    </i>
                                </div>
                            </td>
                            <td class="d-none d-xl-table-cell table-button">
                                <div (click)="toggleShowBilag(clientStateForListview)"
                                     (dblclick)="stopPropagation($event)"
                                     class="table-button"
                                     [ngClass]="{'draft-item-menu-selected': bilagShow===getCombinedKey(clientStateForListview)}"
                                     title="Bilag">
                                    <i class="fa fa-paperclip fa-2x position-relative">
                                        <lc-attachment-badge [attachmentSummary]="clientStateForListview.attachmentSummary"></lc-attachment-badge>
                                    </i>
                                </div>
                            </td>
                            <td class="d-none d-xl-table-cell table-button">
                                <div class=" table-button"
                                     (click)="editDraft(clientStateForListview)" (dblclick)="stopPropagation($event)"
                                     [ngClass]="{'table-button-disabled': !isEditable(clientStateForListview)}"
                                     title="Rediger">
                                    <i class="fa fa-edit fa-2x"></i>
                                </div>
                            </td>
                            <td class="d-none d-xl-table-cell table-button" *lcHasAnyAuthority="'ROLE_Taks'">
                                <div class="table-button"
                                     (click)="approve(clientStateForListview)" (dblclick)="stopPropagation($event)"
                                     [ngClass]="{'table-button-disabled': !canApprove(clientStateForListview)}"
                                     [title]="canApprove(clientStateForListview)?'Godkend':'Du kan kun godkende rapporter der har en beregning'">
                                    <i class="fa fa-check fa-2x"></i>
                                </div>
                            </td>
                            <td class="table-cell table-button"
                                [ngClass]="{'d-xl-none':principalService.isVK()}">

                                <div class="table-button"
                                     (click)="toggleShowMore(clientStateForListview)"
                                     (dblclick)="stopPropagation($event)"
                                     title="Handlinger">
                                    <i class="fa fa-ellipsis-h fa-2x position-relative">
                                        <span *ngIf="showMore !== getCombinedKey(clientStateForListview) && isTaks() && carSaleList.isCarSaleDeprecated(clientStateForListview.taksRapportKey, clientStateForListview.updatedAt)" class="notify-badge-warning font-weight-bold d-xl-none">!</span>
                                    </i>
                                </div>
                            </td>

                        </tr>
                        <tr *ngIf="bilagShow==getCombinedKey(clientStateForListview)">
                            <td colspan="17" class="sub-row-td">
                                <div class="card vehicle-main-card sub-row-card">
                                    <div class="messages-card">
                                        <div class="messages-card-action">
                                            <lc-draft-attachment
                                                [attachmentSummary]="clientStateForListview.attachmentSummary"
                                                (onAttachmentChanged)="updateAttachmentSummary(clientStateForListview)"
                                                [readonly]="false"
                                                [token]="clientStateForListview.token"
                                                [clientStateId]="clientStateForListview.id"
                                                [reportKey]="getReportKey(clientStateForListview)"
                                                [registrationNumber]="clientStateForListview.regNr"
                                                [videoRequestUseCase]="true"
                                            ></lc-draft-attachment>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="b2bShow==getCombinedKey(clientStateForListview)">
                            <td colspan="17" class="sub-row-td">
                                <lc-taks-draft-b2b
                                    [token]="clientStateForListview.token"
                                    [taksRapportKey]="clientStateForListview.taksRapportKey"
                                    [updatedAt]="clientStateForListview.updatedAt"
                                    [carSale]="carSaleList.getCarSale(clientStateForListview.taksRapportKey)"
                                    [integrationUsers]="integrationUsers"
                                    (carSaleCreated)="carSaleList.addToCarSaleList($event)"
                                    (carSaleDeleted)="carSaleList.removeFromCarSaleList($event)"
                                ></lc-taks-draft-b2b>
                            </td>
                        </tr>
                        <tr *ngIf="showNewReportTypes==getCombinedKey(clientStateForListview)">
                            <td colspan="17" class="sub-row-td">
                                <div class="card sub-row-card">
                                    <lc-create-linked-report-from-draft
                                    [taksatorClientState]="clientStateForListview"></lc-create-linked-report-from-draft>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="d-md-none pb-5">
        <div class="fixed-bottom-mobile-menu clickable text-center" (click)="toggleShowVehicleCardImages()" >
            <i class="fa fa-image fa-2x"></i>
            <br/>
            <i [ngClass]="{'fa-toggle-on': showCardImages, 'fa-toggle-off': !showCardImages}" class="fa"></i>
        </div>

        <div class="card mt-3" *ngFor="let clientStateForListview of currentPage">
            <div class="card-header p-2"
                 [ngClass]="{'provisionally-approved': clientStateForListview.fo}"
                 [title]="clientStateForListview.fo ? 'Denne rapport er foreløbig godkendt' : ''">
                <div class="float-start p-0 pe-1">
                    <i class="clickable {{getTypeClass(clientStateForListview.type)}}"
                       [ngbPopover]="reportStateLegend" triggers="mouseenter:mouseleave"
                       placement="right">
									<span *ngIf="!getTypeClass(clientStateForListview.type)">
										{{getName(clientStateForListview.type)}}
									</span>
                    </i>
                </div>
                <strong>{{getReportKey(clientStateForListview) | lcForsiReportKey}}</strong> - {{clientStateForListview.regNr}}
                <div class="float-end">
                    <span *ngIf="clientStateForListview.hasUnreadMessages" class="fa fa-envelope text-danger fa-2x"></span>
                </div>
            </div>
            <div class="card-body vehicle-card-body p-0" [ngClass]="{'vehicle-card-image-show': isVehicleCardImageVisible(clientStateForListview)}">
                <div class="vehicle-card-image w-100" [lazyLoad]="getThumbnailUrl(clientStateForListview.attachmentSummary)" [defaultImage]="defaultImage"></div>
                <div class="vehicle-card-info p-3">
                    <div class="w-100">
                        <div class="row">
                            <div class="col-4 text-truncate"><strong>Reg. nr.</strong></div>
                            <div class="col">{{clientStateForListview.regNr | lcForsiReportKey}}</div>
                        </div>
                        <div class="row">
                            <div class="col-4 text-truncate"><strong>Model</strong></div>
                            <div class="col">{{clientStateForListview.vehicleText}}</div>
                        </div>
                        <div class="row">
                            <div class="col-4 text-truncate "><strong>Besigtigelse</strong></div>
                            <div class="col ">{{clientStateForListview.inspectionDate | date: 'dd-MM-yyyy'}}</div>
                        </div>
                        <div class="row">
                            <div class="col-4 text-truncate"><strong>Tilbud</strong></div>
                            <div class="col">{{clientStateForListview.proformaOrdreNummer | lcForsiReportKey}}</div>
                        </div>
                        <div *lcHasAnyAuthority="'ROLE_Taks'" class="row">
                            <div class="col-4 text-truncate"><strong>Rapport</strong></div>
                            <div class="col">{{clientStateForListview.taksRapportKey | lcForsiReportKey}}</div>
                        </div>
                        <div class="row">
                            <div class="col-4 text-truncate"><strong>Selskab</strong></div>
                            <div class="col text-truncate">{{clientStateForListview.selskabNavn}}</div>
                        </div>
                        <div class="row">
                            <div class="col-4 text-truncate "><strong>Bel&oslash;b</strong></div>
                            <div class="col">{{clientStateForListview.beloeb}}</div>
                        </div>
                        <div class="row">
                            <div class="col-4 text-truncate "><strong>Opdateret</strong></div>
                            <div class="col">{{clientStateForListview.updatedAt | date: 'dd-MM-yyyy hh:mm:ss'}}</div>
                        </div>
                        <div class="row" *ngIf="showXrefColumn()">
                            <div class="col-4 text-truncate"><strong>{{functionConfiguration.importId}}</strong></div>
                            <div class="col">{{clientStateForListview.xref}}</div>
                        </div>


                    </div>
                </div>
            </div>
            <div class="card-footer p-2 pt-0">
                <div class="row">
                    <ng-container *lcHasAnyAuthority="'ROLE_VK'">
                        <!-- Specific rows of 4 buttons for VK. If you are adding / editing a shared action also add / edit it for Taks -->
                        <!-- This is necessary to keep the same button order as the big screen view -->
                        <div class="col-12">
                            <div (click)="editDraft(clientStateForListview)"
                                 class="clickable ms-2 float-end pt-2 inline-action-button">
                                <i class="fa fa-edit fa-2x position-relative"></i>
                            </div>
                            <div (click)="toggleShowBilag(clientStateForListview)"
                                 class="clickable ms-2 float-end pt-2 inline-action-button">
                                <i class="fa fa-paperclip fa-2x position-relative">
                                    <lc-attachment-badge [attachmentSummary]="clientStateForListview.attachmentSummary"></lc-attachment-badge>
                                </i>
                            </div>
                            <div (click)="deleteDraft(clientStateForListview)"
                                 [ngClass]="{'table-button-disabled': !isDraftDeletable(clientStateForListview)}"
                                 class="clickable ms-2 float-end pt-2 inline-action-button"
                                 [title]="getDraftDeletableStatus(clientStateForListview).message">
                                <i class="fa fa-trash fa-2x position-relative"></i>
                            </div>
                            <div (click)="copyDraft(clientStateForListview)"
                                 [ngClass]="{'table-button-disabled': !isCopyable(clientStateForListview)}"
                                 [title]="getCopyMenuTitle(clientStateForListview)"
                                 class="clickable ms-2 float-end pt-2 inline-action-button">
                                <i class="fa fa-copy fa-2x position-relative"></i>
                            </div>
                            <div *lcHasAnyAuthority="'ROLE_VK'" (click)="openAdviseDialog(clientStateForListview)"
                                 class="clickable ms-2 float-end pt-2 inline-action-button"
                                 [title]="getAdviseMenuTitle(clientStateForListview)"
                                 [class.table-button-disabled]="!openAdviseDialogEnabled(clientStateForListview)">
                                <i class="fa fa-2x position-relative" [class.fa-link]="hasAdvise(clientStateForListview)" [class.fa-chain-broken]="!hasAdvise(clientStateForListview)">
                                </i>
                            </div>
                        </div>
                    </ng-container>
                        <ng-container *lcHasAnyAuthority="'ROLE_Taks'">
                            <!-- Specific rows of 4 buttons for Taks. If you are adding / editing a shared action also adding / editing it for VK -->
                            <!-- This is necessary to keep the same button order as the big screen view -->
                            <div class="col-12 card-menu-two-lines">
                                <div (click)="approve(clientStateForListview)"
                                     [ngClass]="{'table-button-disabled': !canApprove(clientStateForListview)}"
                                     class="clickable ms-2 float-end pt-2 inline-action-button"
                                     [title]="canApprove(clientStateForListview)?'Godkend':'Du kan kun godkende rapporter der har en beregning'">
                                    <i class="fa fa-check fa-2x position-relative"></i>
                                </div>
                                <div (click)="editDraft(clientStateForListview)"
                                     class="clickable ms-2 float-end pt-2 inline-action-button">
                                    <i class="fa fa-edit fa-2x position-relative"></i>
                                </div>
                                <div (click)="toggleShowBilag(clientStateForListview)"
                                     class="clickable ms-2 float-end pt-2 inline-action-button">
                                    <i class="fa fa-paperclip fa-2x position-relative">
                                        <lc-attachment-badge [attachmentSummary]="clientStateForListview.attachmentSummary"></lc-attachment-badge>
                                    </i>
                                </div>
                                <div (click)="toggleShowB2b(clientStateForListview)"
                                     [ngClass]="{'table-button-disabled': disableCarSale(clientStateForListview)}"
                                     class="clickable ms-2 float-end pt-2 inline-action-button"
                                     [title]="getCarSaleMenuTitle(clientStateForListview)">
                                    <i class="fa fa-2x position-relative"
                                       [ngClass]="!carSaleList.hasCarSale(clientStateForListview.taksRapportKey) ? 'fa-chain-broken' : 'fa-chain'">
                                        <span
                                                *ngIf="carSaleList.isCarSaleDeprecated(clientStateForListview.taksRapportKey, clientStateForListview.updatedAt)"
                                            class="notify-badge-warning font-weight-bold">!</span>
                                    </i>
                                </div>
                                <div (click)="toggleShowNewReportTypes(clientStateForListview)"
                                     class="clickable ms-2 float-end pt-2 inline-action-button"
                                     [ngClass]="{'table-button-disabled': !newReportTypesAllowed(clientStateForListview)}"
                                     [title]="getNewReportMenuTitle(clientStateForListview)">
                                    <i class="fa fa-plus fa-2x position-relative">
                                    </i>
                                </div>
                                <div (click)="cancelDraft(clientStateForListview)"
                                     [ngClass]="{'table-button-disabled': !isCancellable(clientStateForListview)}"
                                     [title]="getCancelDraftMenuTitle(clientStateForListview)"
                                     class="clickable ms-2 float-end pt-2 inline-action-button">
                                    <i class="fa fa-undo fa-2x position-relative"></i>
                                </div>
                                <div (click)="transferDraft(clientStateForListview)"
                                     class="clickable ms-2 float-end pt-2 inline-action-button">
                                    <i class="fa fa-share fa-2x position-relative"></i>
                                </div>
                                <div (click)="deleteDraft(clientStateForListview)"
                                     [ngClass]="{'table-button-disabled': !isDraftDeletable(clientStateForListview)}"
                                     class="clickable ms-2 float-end pt-2 inline-action-button"
                                     [title]="getDraftDeletableStatus(clientStateForListview).message">
                                    <i class="fa fa-trash fa-2x position-relative"></i>
                                </div>
                                <div (click)="copyDraft(clientStateForListview)"
                                     [ngClass]="{'table-button-disabled': !isCopyable(clientStateForListview)}"
                                     [title]="getCopyMenuTitle(clientStateForListview)"
                                     class="clickable ms-2 float-end pt-2 inline-action-button">
                                    <i class="fa fa-copy fa-2x position-relative"></i>
                                </div>

                            </div>
                        </ng-container>
                </div>
                <div class="row">
                    <div class="col-12 mt-2" *ngIf="showNewReportTypes==getCombinedKey(clientStateForListview)">
                        <div class="card sub-row-card">
                            <div class="messages-card">
                                <div class="messages-card-action">
                                    <lc-create-linked-report-from-draft
                                        [taksatorClientState]="clientStateForListview"></lc-create-linked-report-from-draft>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-2" *ngIf="bilagShow==getCombinedKey(clientStateForListview)">
                        <div class="card sub-row-card">
                            <div class="messages-card">
                                <div class="messages-card-action">
                                    <lc-draft-attachment
                                        [attachmentSummary]="clientStateForListview.attachmentSummary"
                                        (onAttachmentChanged)="updateAttachmentSummary(clientStateForListview)"
                                        [readonly]="false"
                                        [token]="clientStateForListview.token"
                                        [clientStateId]="clientStateForListview.id"
                                        [reportKey]="getReportKey(clientStateForListview)"
                                        [registrationNumber]="clientStateForListview.regNr"
                                        [videoRequestUseCase]="true"
                                    ></lc-draft-attachment>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-2" *ngIf="b2bShow==getCombinedKey(clientStateForListview)">
                        <div class="card sub-row-card">
                            <div class="messages-card">
                                <div class="messages-card-action">
                                    <lc-taks-draft-b2b
                                        [token]="clientStateForListview.token"
                                        [taksRapportKey]="clientStateForListview.taksRapportKey"
                                        [updatedAt]="clientStateForListview.updatedAt"
                                        [carSale]="carSaleList.getCarSale(clientStateForListview.taksRapportKey)"
                                        [integrationUsers]="integrationUsers"
                                        (carSaleCreated)="carSaleList.addToCarSaleList($event)"
                                        (carSaleDeleted)="carSaleList.removeFromCarSaleList($event)"
                                    ></lc-taks-draft-b2b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div style="padding-top: 5px;">
        <div class="row justify-content-center">
            <ngb-pagination [collectionSize]="filteredItems" [(page)]="page"
                            (pageChange)="loadPage(page)"></ngb-pagination>
        </div>
    </div>
</div>

<ng-template #reportStateLegend>
    <table class="table table-borderless p-0 m-0">
        <tr>
            <td class="text-center"><i class="{{getTypeClass('autotaks-report')}}"></i></td>
            <td>Autotaks</td>
        </tr>
        <tr>
            <td class="text-center"><i class="{{getTypeClass('glastaks-report')}}"></i></td>
            <td>Glastaks</td>
        </tr>
        <tr>
            <td class="text-center"><i class="{{getTypeClass('stortaks-report')}}"></i></td>
            <td>Stortaks</td>
        </tr>
        <tr>
            <td class="text-center"><i class="{{getTypeClass('mctaks-report')}}"></i></td>
            <td>MCtaks</td>
        </tr>
        <tr>
            <td class="text-center"><i class="{{getTypeClass('skavutaks-report')}}"></i></td>
            <td>Skavutaks</td>
        </tr>
        <tr>
            <td class="text-center"><i class="{{getTypeClass('camptaks-report')}}"></i></td>
            <td>Camptaks</td>
        </tr>
        <tr>
            <td class="text-center"><i class="{{getTypeClass('assessment-building-report')}}"></i></td>
            <td>Bygning</td>
        </tr>
        <tr>
            <td class="text-center"><i class="{{getTypeClass('agriculture-eqpt-report')}}"></i></td>
            <td>Landbrugsredskaber</td>
        </tr>
        <tr>
            <td class="text-center"><i class="{{getTypeClass('assessment-entrepreneur-report')}}"></i></td>
            <td>Entreprenør materialer</td>
        </tr>
        <tr>
            <td class="text-center"><i class="{{getTypeClass('assessment-movables-report')}}"></i></td>
            <td>Løsøre</td>
        </tr>
        <tr>
            <td class="text-center"><i class="{{getTypeClass('assessment-miscellaneous-report')}}"></i></td>
            <td>Diverse</td>
        </tr>
    </table>
</ng-template>
