import {SearchProperties} from '../../shared/ui/report-search-input/SearchProperties';

export class ReportListConfig {
  page: number;
  selectedIndex: number;
  orderProp: string;
  orderReverse: boolean;
  orderType: string;
  searchProperties: SearchProperties;
  filter: string;
}
