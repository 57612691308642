import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {QapterButtonComponent} from './qapter-button.component';
import {QapterHelpButtonComponent} from './qapter-help-button.component';
import {SharedCommonModule} from '../../shared-common.module';
import {QapterStartComponent} from './qapter-start/qapter-start.component';

@NgModule({
  imports: [
    SharedCommonModule
  ],
  declarations: [
    QapterButtonComponent,
    QapterHelpButtonComponent,
    QapterStartComponent
  ],
  exports: [
    QapterButtonComponent,
    QapterHelpButtonComponent,
    QapterStartComponent
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LightclientQapterModule {
}
