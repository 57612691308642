<!-- Inserted to disable autofocus -->
<input type="text" class="d-none">
<div class="modal-header pre-size header-style" style="text-align: center">
    <h3 class="text-truncate">
        {{title}}
    </h3>
    <div aria-label="Close" data-dismiss="modal" class="btn-close clickable" (click)="cancel()"></div>
</div>

<div class="modal-content">
    <div class="modal-body modal-body-style" *ngIf="users">
        <div class="col-12">
            <div class="row">
                <div class="col-form-label col-3">
                    <label for="users">Vælg {{userDescription}}</label>
                </div>
                <div class="col-9">
                    <lc-autocomplete [(ngModel)]="selectedUser"
                                     id="users" name="users"
                                     [items]="users" exactKey="username" itemText="name" subItemText="atClientNumber">
                    </lc-autocomplete>
                </div>
            </div>
            <div class="row pt-3">
                <div class="col-form-label col-3">
                    <label for="includeMessage">Tilføj besked</label>
                </div>
                <div class="col">
                    <input id="includeMessage" name="includeMessage"
                           [(ngModel)]="sendMessage" [disabled]="forceSendMessage"
                           type="checkbox" autocomplete="off"
                           class="form-check-input form-input-check-single pt-1"/>
                </div>
            </div>
            <div class="row pt-1">
                <div class="col-form-label col-3">
                    <label for="subject">Emne</label>
                </div>
                <div class="col">
                    <input id="subject" [disabled]="!sendMessage" name="subject" type="text"
                           class="form-control pe-1" [(ngModel)]="messageTitle">
                </div>
            </div>
            <div class="row pt-1">
                <div class="col-form-label col-3">
                    <label for="body">Besked</label>
                </div>
                <div class="col">
                    <textarea id="body" [disabled]="!sendMessage" name="body" rows="10" class="form-control pe-1"
                              [(ngModel)]="messageText"></textarea>
                </div>
            </div>
            <div class="row pt-1">
                <div class="col">
                    <div class=" float-end">
                    <lc-org-text-library-button *lcHasAnyAuthority="'ROLE_Taks'" (selectedText)="didSelectTextFromLibrary($event)" [disabled]="!sendMessage"
                                                [libraryType]="textLibraryType" [reportType]="reportType"></lc-org-text-library-button>
                    <button class="btn btn-sm btn-primary ms-1" (click)="showTextLibrary()" [disabled]="!sendMessage"
                            type="button" aria-hidden="true">Tekstbibliotek</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer ">
        <div class="btn-group offset-2">
            <button class="btn btn-primary me-1" (click)="confirmOffer()" [disabled]="confirmDisabled()"
                    type="button" aria-hidden="true">
                <i class="fa fa-2x action-icon fa-check"></i>
                <span class="itemText d-none d-md-inline-block">Ok</span>
            </button>
        </div>
    </div>
</div>


