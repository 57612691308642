import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {NotificationDTO} from '../dto/notification-dto.model';
import {Notification} from '../model/notification.model';
import {map} from 'rxjs/operators';
import {ForsiDateTimeParserFormatter} from '../formatter/forsi-date-time.formatter';
import {isNullOrUndefined} from '../utils/object-utils';
import {AppVisibilityService} from './core/app-visibility.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private notificationUrl = 'notifications';
  public onNotificationChangedSub: BehaviorSubject<NotificationDTO> = new BehaviorSubject(null);
  private getNotificationSubscription: Subscription;
  private hideNotificationTimer;
  private showNotificationTimer;
  private updateFrequency = 1;

  private forsiDateTimeParserFormatter = new ForsiDateTimeParserFormatter();

  constructor(private http: HttpClient, private appVisibilityService: AppVisibilityService) {
  }

  public getLatest(): Observable<NotificationDTO> {
    return this.http.get<NotificationDTO>(this.notificationUrl + '/latest');
  }

  public getAll(): Observable<Notification[]> {
    return this.http.get<NotificationDTO[]>(this.notificationUrl).pipe(
      map(response => response.map(notification => this.convertToModel(notification))));
  }

  public createOrUpdate(notification: Notification): Observable<NotificationDTO> {
    return notification.id ? this.update(notification) : this.create(notification);
  }

  private create(newNotification: Notification): Observable<NotificationDTO> {
    const dto = this.convertToDto(newNotification);
    return this.http.post<NotificationDTO>(this.notificationUrl, dto);
  }

  private update(updatedNotification: Notification): Observable<NotificationDTO> {
    const dto = this.convertToDto(updatedNotification);
    return this.http.put<NotificationDTO>(this.notificationUrl + '/' + dto.id, dto);
  }

  public delete(id: number): Observable<any> {
    return this.http.delete(this.notificationUrl + '/' + id);
  }

  public startNotificationUpdateCheck(frequency = 60_000 * 5): void {
    this.updateFrequency = frequency;
    if (!this.getNotificationSubscription) {
      this.getNotificationSubscription = this.appVisibilityService.forsiVisibilityInterval(0, frequency).subscribe( () => {
        this.fetchLatestNotification();
      });
    }
  }

  public stopNotificationUpdateCheck(): void {
    if (this.getNotificationSubscription) {
      this.getNotificationSubscription.unsubscribe();
      this.getNotificationSubscription = null;
    }
  }

  private fetchLatestNotification(): void {
    clearTimeout(this.hideNotificationTimer);
    clearTimeout(this.showNotificationTimer);
    this.getLatest().subscribe(notification => {
      if (isNullOrUndefined(notification)) {
        this.onNotificationChangedSub.next(null);
        return;
      }
      const now = Date.now();
      const activeFrom = new Date(notification.activeFrom).getTime()
      const activeTo = new Date(notification.activeTo).getTime()
      if (now > activeFrom && now < activeTo) {
        this.publishNotificationChanged(notification, now);
      } else if (activeFrom > now && activeFrom - 2 * this.updateFrequency < now) {
        this.onNotificationChangedSub.next(null);
        this.showNotificationTimer = setTimeout(() => {
          this.publishNotificationChanged(notification, Date.now())
        }, activeFrom - now)
      } else {
        this.onNotificationChangedSub.next(null)
      }
    })
  }

  private publishNotificationChanged(notification: NotificationDTO, now): void {
    this.onNotificationChangedSub.next(notification);
    if (notification) {
      const activeTo = new Date(notification.activeTo).getTime()
      if (activeTo > now) {
        const timeout = activeTo - now;
        if (timeout < this.updateFrequency * 2) {
          this.hideNotificationTimer = setTimeout(() => {
            this.onNotificationChangedSub.next(null);
          }, timeout)
        }
      }
    }
  }

  private convertToModel(domain: NotificationDTO): Notification {
    const dto = Object.assign(new Notification(), domain);
    const activeFrom = domain.activeFrom && new Date(domain.activeFrom);
    const activeTo = domain.activeTo && new Date(domain.activeTo);
    dto.activeFromDate = this.forsiDateTimeParserFormatter.parseDate(activeFrom);
    dto.activeFromTime = this.forsiDateTimeParserFormatter.parseTime(activeFrom);
    dto.activeToDate = this.forsiDateTimeParserFormatter.parseDate(activeTo);
    dto.activeToTime = this.forsiDateTimeParserFormatter.parseTime(activeTo);
    return dto
  }

  private convertToDto(notification: Notification): NotificationDTO {
    return {
      id: notification.id,
      userType: notification.userType,
      text: notification.text,
      link: notification.link,
      envContext: notification.envContext,
      createdBy: notification.createdBy,
      createdAt: undefined,
      activeFrom: new Date(notification.getActiveFrom()).toISOString(),
      activeTo: new Date(notification.getActiveTo()).toISOString()
    };
  }
}
