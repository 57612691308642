export class ValuationDTO {
  resterType: string;
  resterTold: boolean;
  resterBeloeb: number;

  erstatningType: string;
  erstatningMoms: boolean;
  erstatningAfgift: boolean;
  erstatningBeloeb: number;

  repairLimitExceeded: boolean;

  statusBesigtiges: boolean;
  statusNummerplader: boolean;
  statusRegistreringsattest: boolean;
  statusStelNummer: boolean;

  reparationsGraense: number;
  nyVaerdi: number;

  rekvirent = 'VÆRKSTED';
  rekvireretDato: string;
  takseringsDato: string;

  percentOfValue: number;

}
