import {LcActionMenu, LcActionMenuItem} from '../../../shared/ui/bottombar/lc-action-menu.model';
import {AutoflexService} from '../../../shared/service/autoflex.service';
import {PrincipalService} from '../../../shared';
import {StringUtils} from '../../../shared/utils/string-utils';
import {AutoflexDTO} from '../../../shared/dto/autoflex-dto.model';
import {BootstrapAlertType, BootstrapGrowlService} from '../../../shared/ui/ngx-bootstrap-growl';
import {ListUtils} from '../../../shared/utils/list-utils';
import {DraftEditService} from '../draft-edit.service';
import {Injectable} from '@angular/core';
import {combineLatest, Observable, of, Subject} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {isNullOrUndefined} from '../../../shared/utils/object-utils';

@Injectable()
export class DraftAutoflexViewModel {
  private _token: string;
  private tokenSub: Subject<string> = new Subject<string>();
  private selskabKode: string;
  bottomMenu: LcActionMenu;
  loading = true;
  hasAutoflex = false;
  expandFilter = false;
  autoflexVersion: AutoflexVersion;
  autoflexRowFilterAndSorter = new AutoflexRowFilterAndSorter([]);
  autoflexSavings = new AutoflexSavingsViewModel();

  navigateBackCallback: Function;
  acceptBackCallback: Function;

  private static toRows(dtos: AutoflexDTO[]): AutoflexRowViewModel[] {
    const result: Array<AutoflexRowViewModel> = [];
    dtos.sort((n1, n2) => 0 - (n1.autotaksReservedelNr > n2.autotaksReservedelNr ? 1 : -1));

    let currentResNr: string = null;
    let autoflexDoublets: Array<AutoflexDTO> = [];
    for (const dto of dtos) {
      if (StringUtils.isEmpty(currentResNr)) { //bootstrap
        currentResNr = dto.autotaksReservedelNr;
        autoflexDoublets = [];
      }
      if (currentResNr !== dto.autotaksReservedelNr) { //change in ResNr
        result.push(this.createAutoflexRowViewModel(autoflexDoublets));
        currentResNr = dto.autotaksReservedelNr;
        autoflexDoublets = [];
      }
      autoflexDoublets.push(dto);
    }
    const row = this.createAutoflexRowViewModel(autoflexDoublets);
    if (!isNullOrUndefined(row)) {
      result.push(row);
    }
    return result;
  }

  private static createAutoflexRowViewModel(autoflexDoublets: Array<AutoflexDTO>): AutoflexRowViewModel {
    let newAutoflexRow: AutoflexRowViewModel = null;
    if (autoflexDoublets.length > 1) {
      newAutoflexRow = new AutoflexMultiRowViewModel(autoflexDoublets);
    } else if (autoflexDoublets.length === 1) {
      newAutoflexRow = new AutoflexSingleRowViewModel(autoflexDoublets[0]);
    }
    return newAutoflexRow;
  }

  set token(value: string) {
    this._token = value;
    this.tokenSub.next(value);
  }

  constructor(private principalService: PrincipalService, private autoflexService: AutoflexService, private draftEditService: DraftEditService, private bootstrapGrowlService: BootstrapGrowlService) {
    this.setupBottomActionMenu();
    this.setupObservables();
  }

  private setupBottomActionMenu(): void {
    this.bottomMenu = new LcActionMenu();
    this.bottomMenu.addItem(new LcActionMenuItem('fa-arrow-left', () => {
      if (!isNullOrUndefined(this.navigateBackCallback)) {
        this.navigateBackCallback(this._token);
      }
    }, 'Tilbage'));
    const acceptMenuItem = new LcActionMenuItem('fa-check', () => {
      if (!isNullOrUndefined(this.acceptBackCallback)) {
        this.acceptBackCallback(this._token);
      }
    }, 'Ok');
    acceptMenuItem.disabledCallback = () => !this.hasAutoflex || !this.hasSelectedAutoflexData();
    this.bottomMenu.addItem(acceptMenuItem);
  }

  private setupObservables(): void {
    const tokenObserver = {
      next: ([versionDTO, autoflexDTOs]) => {
        this.hasAutoflex = autoflexDTOs.length !== 0;

        //create filter posibilities
        const qualityFilterPosibilities: Array<string> = [];
        const supplierFilterPosibilities: Array<string> = [];
        for (const autoflexDTO of autoflexDTOs) {
          if (!qualityFilterPosibilities.includes(autoflexDTO.autoflexKvalitet)) {
            qualityFilterPosibilities.push(autoflexDTO.autoflexKvalitet);
          }
          if (!supplierFilterPosibilities.includes(autoflexDTO.autoflexLeverandoer)) {
            supplierFilterPosibilities.push(autoflexDTO.autoflexLeverandoer);
          }
        }

        this.autoflexRowFilterAndSorter = new AutoflexRowFilterAndSorter(DraftAutoflexViewModel.toRows(autoflexDTOs), supplierFilterPosibilities, qualityFilterPosibilities);
        this.autoflexRowFilterAndSorter.updateRowVisibity();
        this.autoflexSavings.setRows(this.autoflexRowFilterAndSorter.autoflexRows);
        this.autoflexVersion = new AutoflexVersion(versionDTO.id, versionDTO.createdAt);
        this.loading = false;
      }, error: error => {
        this.bootstrapGrowlService.addAlert('Kunne ikke hente Autoflex data', BootstrapAlertType.DANGER);
        console.log('Failed during fetch of autoflex data', error);
        this.loading = false;
      }
    };
    this.tokenSub.pipe(
      mergeMap(token => this.draftEditService.getClientStateObservable(token)),
      mergeMap(clientState => {
        const observablesToCombine = [];
        if (this.principalService.isVK()) {
          observablesToCombine.push(this.autoflexService.autoflexVersionVK(clientState.selskab));
          observablesToCombine.push(this.autoflexService.autoflexVK(clientState.token, clientState.selskab));
        } else if (this.principalService.isTaksator()) {
          observablesToCombine.push(this.autoflexService.autoflexVersionTaks());
          observablesToCombine.push(this.autoflexService.autoflexTaks(clientState.token));
        } else {
          return new Observable(subscriber => {
            subscriber.error(new Error('Cannot fetch autoflex for a user that isn\'t VK of Taks'));
          });
        }
        this.selskabKode = clientState.selskab; //not the functional way to do it - extracting state in a mergeMap
        return combineLatest(observablesToCombine);
      })).subscribe(tokenObserver);
  }

  didClick(row: AutoflexRowViewModel): void {
    row.setSelected(!row.selected);
  }

  didClickFilter(filter: RowFilter): void {
    filter.selected = !filter.selected;
    this.autoflexRowFilterAndSorter.updateRowVisibity();
  }

  didClickSort(orderProp: string, orderReverse: boolean): void {
    this.autoflexRowFilterAndSorter.orderProp = orderProp;
    this.autoflexRowFilterAndSorter.orderReverse = orderReverse;
    this.autoflexRowFilterAndSorter.updateSortOrder();
  }

  saveSelected(): Observable<AutoflexDTO[]> {
    const autoflexDTOS = this.getSelectedAutoflexData();
    if (autoflexDTOS.length > 0) {
      if (this.principalService.isVK()) {
        return this.autoflexService.saveAutoflexVk(this._token, this.selskabKode, autoflexDTOS as AutoflexDTO[]);
      } else if (this.principalService.isTaksator()) {
        return this.autoflexService.saveAutoflexTaks(this._token, autoflexDTOS as AutoflexDTO[]);
      } else {
        return new Observable(subscriber => {
          subscriber.error(new Error('Cannot fetch autoflex for a user that isn\'t VK of Taks'));
        });
      }
    }
    return of(AutoflexDTO[0]);
  }

  getSelectedAutoflexData(): AutoflexDTO[] {
    return this.autoflexRowFilterAndSorter.autoflexRows.map(obj => obj.getSelectedData()).filter(dto => dto !== null);
  }

  hasSelectedAutoflexData(): boolean {
    return this.getSelectedAutoflexData().length > 0;
  }

  getSortClass(orderProp: string): string {
    let result: string;
    const sortOrder = this.autoflexRowFilterAndSorter.getSortOrderForProp(orderProp);
    switch (sortOrder) {
      case SortOrder.ASC: {
        result = 'fa-sort-asc';
        break;
      }
      case SortOrder.DESC: {
        result = 'fa-sort-desc';
        break;
      }
      case SortOrder.NONE:
      default: {
        result = 'fa-sort';
        break;
      }
    }
    return result;
  }
}

class AutoflexVersion {
  version: number;
  createdAt: Date;

  constructor(version: number, createdAt: Date) {
    this.version = version;
    this.createdAt = createdAt;
  }
}

/**
 * Responsible for calculating the total savings from the selected Autoflex data
 */
export class AutoflexSavingsViewModel {
  rows: Array<AutoflexRowViewModel> = [];

  setRows(rows: Array<AutoflexRowViewModel>): void {
    this.rows = rows;
  }

  totalSavings(): number {
    let result = 0.0;
    for (const row of this.rows) {
      result += row.savings();
    }
    return result;
  }

}

/**
 * An abstract general Autoflex row defining common Autoflex row properties and behavior
 */
export abstract class AutoflexRowViewModel {
  data: any;
  ledenr: string;
  text: string;
  altText: string;
  price: string;
  altPrice: string;
  quality: string;
  oeNo: string;
  resNo: string;
  supplier: string;
  manufacturer: string;

  selected = false;
  visible = true; // determined when filter is updated;

  savings(): number {
    return 0.0;
  }

  //should be overridden in multi row
  hasMultipleData(): boolean {
    return false;
  }

  //should be overridden in multi row
  hasSubSelection(): boolean {
    return false;
  }

  //should be overridden in single and multi row
  getSelectedData(): AutoflexDTO {
    return null;
  }

  setSelected(value: boolean): void {
    this.selected = value;
  }

  //should be overridden in single and multi row
  getSelectedRow(): AutoflexRowViewModel {
    return null;
  }
}

/**
 * Represents a Autoflex row that is a child of a multi Autoflex row
 */
export class AutoflexSubRowViewModel extends AutoflexRowViewModel {
  data: AutoflexDTO;
  parent: AutoflexMultiRowViewModel;

  constructor(parent: AutoflexMultiRowViewModel, dto: AutoflexDTO) {
    super();
    this.parent = parent;
    this.data = dto;
    this.ledenr = this.data.ledenr;
    this.text = this.data.autotaksReservedelTekst;
    this.altText = this.data.autoflexReservedelTekst;
    this.price = this.data.autotaksReservedelPrisFormatted;
    this.altPrice = this.data.autoflexReservedelPrisFormatted;
    this.quality = this.data.autoflexKvalitet;
    this.oeNo = this.data.autotaksReservedelNr;
    this.resNo = this.data.autoflexReservedelNr;
    this.supplier = this.data.autoflexLeverandoer;
    this.manufacturer = this.data.autoflexFabrikant;
  }

  savings(): number {
    return this.selected ? this.data.autotaksReservedelPris - this.data.autoflexReservedelPris : 0.0;
  }

  setSelected(value: boolean): void {
    this.parent.deselectAllRows();
    super.setSelected(value);
  }

  getSelectedRow(): AutoflexRowViewModel {
    return this.selected ? this : null;
  }
}

/**
 * Represents a Autoflex row that represents multiple Autoflex data (doublets)
 */
export class AutoflexMultiRowViewModel extends AutoflexRowViewModel {
  data: Array<AutoflexSubRowViewModel> = [];

  constructor(dtos: AutoflexDTO[]) {
    super();
    const DOUBLET_TEXT = 'dublet';
    this.data = dtos.map(data => new AutoflexSubRowViewModel(this, data));
    this.ledenr = this.data[0].ledenr;
    this.text = this.data[0].text;
    this.altText = DOUBLET_TEXT;
    this.price = this.data[0].price;
    this.altPrice = DOUBLET_TEXT;
    this.quality = DOUBLET_TEXT;
    this.oeNo = this.data[0].oeNo;
    this.resNo = DOUBLET_TEXT;
    this.supplier = DOUBLET_TEXT;
    this.manufacturer = DOUBLET_TEXT;
  }

  hasMultipleData(): boolean {
    return true;
  }

  hasSubSelection(): boolean {
    return this.data.filter(obj => obj.selected).length > 0;
  }

  getSelectedData(): AutoflexDTO {
    const autoflexSubRowViewModels = this.data.filter(obj => obj.selected);
    return autoflexSubRowViewModels.length > 0 ? autoflexSubRowViewModels[0].data : null;
  }

  getSelectedRow(): AutoflexRowViewModel {
    const selectedSubRowModels = this.data.filter(obj => obj.selected);
    return selectedSubRowModels.length > 0 ? selectedSubRowModels[0] : null;
  }

  savings(): number {
    const selectedSubArrays = this.data.filter(obj => obj.selected);
    const savings = selectedSubArrays.map(obj => obj.savings());
    return savings.reduce((total, obj) => total + obj, 0.0);
  }

  deselectAllRows(): void {
    for (const subRow of this.data) {
      subRow.selected = false;
    }
  }
}

/**
 * Represents a Autoflex row that has no doublets
 */
export class AutoflexSingleRowViewModel extends AutoflexRowViewModel {

  data: AutoflexDTO;

  constructor(dto: AutoflexDTO) {
    super();
    this.data = dto;
    this.ledenr = this.data.ledenr;
    this.text = this.data.autotaksReservedelTekst;
    this.altText = this.data.autoflexReservedelTekst;
    this.price = this.data.autotaksReservedelPrisFormatted;
    this.altPrice = this.data.autoflexReservedelPrisFormatted;
    this.quality = this.data.autoflexKvalitet;
    this.oeNo = this.data.autotaksReservedelNr;
    this.resNo = this.data.autoflexReservedelNr;
    this.supplier = this.data.autoflexLeverandoer;
    this.manufacturer = this.data.autoflexFabrikant;
  }

  savings(): number {
    return this.selected ? this.data.autotaksReservedelPris - this.data.autoflexReservedelPris : 0.0
  }

  getSelectedData(): AutoflexDTO {
    return this.selected ? this.data : null;
  }

  getSelectedRow(): AutoflexRowViewModel {
    return this.selected ? this : null;
  }
}

/**
 * Responsible for the order and filter of the autoflex rows
 */
export class AutoflexRowFilterAndSorter {

  supplierFilter: Array<RowFilter> = [];
  qualityFilter: Array<RowFilter> = [];
  autoflexRows: AutoflexRowViewModel[] = [];
  orderProp: string;
  orderReverse: boolean;
  orderType = 'string';

  constructor(autoflexRows: AutoflexRowViewModel[], supplierFilterPossibilites?: Array<string>, qualityFilterPossibilites?: Array<string>) {
    this.autoflexRows = autoflexRows;
    if (supplierFilterPossibilites) {
      this.supplierFilter = [];
      for (const filter of supplierFilterPossibilites) {
        this.supplierFilter.push(new RowFilter(filter));
      }
    }
    if (qualityFilterPossibilites) {
      this.qualityFilter = [];
      for (const filter of qualityFilterPossibilites) {
        this.qualityFilter.push(new RowFilter(filter));
      }
    }
  }

  updateSortOrder(): void {
    ListUtils.sort(this.autoflexRows, this.orderProp, this.orderReverse, this.orderType);
  }

  updateRowVisibity(): void {
    for (const row of this.autoflexRows) {
      if (row.hasMultipleData()) {
        const subRows = row.data as Array<AutoflexSubRowViewModel>;
        for (const subRow of subRows) {
          this.updateRowVisibityAccordingToFilter(subRow);
        }
        row.visible = subRows.filter(row => row.visible).length > 0;
      } else {
        this.updateRowVisibityAccordingToFilter(row);
      }
    }
  }

  updateRowVisibityAccordingToFilter(row: AutoflexRowViewModel): void {
    let visibilityResult = true;
    if (visibilityResult && this.supplierFilter.length > 0) {
      visibilityResult = this.supplierFilter.filter(filter => filter.selected && filter.value === row.supplier).length > 0;
    }

    if (visibilityResult && this.qualityFilter.length > 0) {
      visibilityResult = this.qualityFilter.filter(filter => filter.selected && filter.value === row.quality).length > 0;
    }

    row.visible = visibilityResult;

    //deselect rows that is hidden by a filter
    if (!row.visible) {
      row.selected = false;
    }
  }

  getSortOrderForProp(orderProp: string): SortOrder {
    if (orderProp !== this.orderProp) {
      return SortOrder.NONE;
    } else {
      return this.orderReverse ? SortOrder.ASC : SortOrder.DESC;
    }
  }

  isFilterEnabled(): boolean {
    return (this.noOfSuppliersFiltered() > 0 ||
      this.noOfQualitiesFiltered() > 0);
  }

  noOfSuppliersFiltered(): number {
    return this.supplierFilter.filter(item => !item.selected).length;
  }

  noOfQualitiesFiltered(): number {
    return this.qualityFilter.filter(item => !item.selected).length;
  }

  filterText(): string {
    if (this.isFilterEnabled()) {
      return (this.noOfSuppliersFiltered() + this.noOfQualitiesFiltered()) + ' ud af ' + (this.supplierFilter.length + this.qualityFilter.length) + ' muligheder er filteret fra';
    } else {
      return 'Inter filter at valgt';
    }
  }
}

export class RowFilter {
  value: string;
  selected = true;

  constructor(value: string) {
    this.value = value;
  }
}

/**
 * Sort order enum
 */
export enum SortOrder {
  DESC = 1,
  ASC,
  NONE
}
