import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AssessmentConfigurationDTO} from '../dto/assessment-configuration-dto.model';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AssessmentConfigurationService {
  private rootURL = 'assessment/configuration';

  constructor(private http: HttpClient) {
  }

  getConfiguration(): Observable<AssessmentConfigurationDTO> {
    return this.http.get<AssessmentConfigurationDTO>(this.rootURL).pipe(map((response => Object.assign(new AssessmentConfigurationDTO(), response))));
  }
}
