import {StringUtils} from '../../utils/string-utils';

export class CombinedPrintModel {
  print: string;

  static emptyModel(): CombinedPrintModel {
    return new CombinedPrintModel(null);
  }

  constructor(print: string) {
    this.print = print;
  }

  get hasData(): boolean {
    return StringUtils.isNotEmpty(this.print);
  }
}
