import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AutoflexDTO} from '../dto/autoflex-dto.model';
import {AutoflexVersionDTO} from '../dto/autoflex-version-dto.model';
import {AutoflexEnabledDTO} from '../dto/autoflex-enabled-dto.model';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AutoflexService {
  private serviceURL = 'autoflex/';

  constructor(private http: HttpClient) {
  }

  isAutoflexEnabledForVK(selskabKode: string): Observable<AutoflexEnabledDTO> {
    return this.http.get<AutoflexEnabledDTO>(this.serviceURL + 'vk/enabledfor/' + encodeURIComponent(selskabKode));
  }

  autoflexVersionVK(selskabKode: string): Observable<AutoflexVersionDTO> {
    return this.http.get<AutoflexVersionDTO>(this.serviceURL + 'vk/version/' + encodeURIComponent(selskabKode));
  }

  autoflexVK(token: string, selskabKode: string): Observable<AutoflexDTO[]> {
    return this.http.get<AutoflexDTO[]>(this.serviceURL + 'vk/data/' + encodeURIComponent(selskabKode) + '/' + encodeURIComponent(token)).pipe(map(response => response.map(autoflexJson => Object.assign(new AutoflexDTO(), autoflexJson))));
  }

  saveAutoflexVk(token: string, selskabKode: string, autoflexToSave: AutoflexDTO[]): Observable<AutoflexDTO[]> {
    return this.http.post<AutoflexDTO[]>(this.serviceURL + 'vk/data/' + encodeURIComponent(selskabKode) + '/' + encodeURIComponent(token), autoflexToSave).pipe(
      map(response => response.map(autoflexJson => Object.assign(new AutoflexDTO(), autoflexJson))
      ));
  }

  autoflexVersionTaks(): Observable<AutoflexVersionDTO> {
    return this.http.get<AutoflexVersionDTO>(this.serviceURL + 'taks/version');
  }

  autoflexTaks(token: string): Observable<AutoflexDTO[]> {
    return this.http.get<AutoflexDTO[]>(this.serviceURL + 'taks/data/' + encodeURIComponent(token)).pipe(
      map(response => response.map(autoflexJson => Object.assign(new AutoflexDTO(), autoflexJson))
      ));
  }

  saveAutoflexTaks(token: string, autoflexToSave: AutoflexDTO[]): Observable<AutoflexDTO[]> {
    return this.http.post<AutoflexDTO[]>(this.serviceURL + 'taks/data/' + encodeURIComponent(token), autoflexToSave).pipe(
      map(response => response.map(autoflexJson => Object.assign(new AutoflexDTO(), autoflexJson))
      ));
  }
}
