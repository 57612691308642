import {NgModule} from '@angular/core';
import {Title} from '@angular/platform-browser';

import {AutofocusDirective, HasAnyAuthorityDirective, SpinnerImageComponent, SpinnerTextComponent} from '.';
import {InputMaskDirective} from './directive/input-mask.directive';
import {InputValidatorDirective} from './validators/inputvalidator.directive';
import {ForsiReportKeyPipe} from './pipe/forsi-report-key.pipe';
import {ForsiReportKeyShortPipe} from './pipe/forsi-report-key-short.pipe';
import {ForsiReportTypePipe} from './pipe/forsi-report-type.pipe';
import {SharedLibsModule} from './shared-libs.module';
import {BottombarComponent} from './ui/bottombar/bottombar.component';
import {AutocompleteComponent} from './ui/autocomplete/autocomplete.component';
import {InlineUserComponent} from './ui/inline-user/inline-user.component';
import {ShowPoliceComponent} from './ui/show-police/show-police.component';
import {ContextMenuComponent} from './ui/context-menu/context-menu.component';
import {VkInfoComponent} from './ui/vk-info/vk-info.component';
import {BroadcastListComponent} from './ui/broadcast-list/broadcast-list.component';
import {FilterPipe} from './pipe/filter.pipe';
import {NgbDatePipe} from './pipe/ngb-date-pipe';
import {TextLibraryComponent} from './ui/text-library/text-library.component';
import {TextareaAutosizeDirective} from './directive/textarea-autosize.directive';
import {PositionsDropdownValidatorDirective} from './validators/dropdown-position-validattor.directive';
import {YesNoPipe} from './pipe/yes-no.pipe';
import {MessageComponent} from './ui/message/message.component';
import {CameraIconComponent} from './ui/camera-icon/camera-icon.component';
import {BreadcrumbComponent} from './ui/breadcrumb/breadcrumb.component';
import {RouterModule} from '@angular/router';
import {LinkedReportListComponent} from './ui/linked-report-list/linked-report-list.component';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {FileUploadModule} from 'ng2-file-upload';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {PrintViewerComponent} from './ui/print-viewer/print-viewer.component';
import {CombinedPrintViewerComponent} from './ui/combined-print-viewer/combined-print-viewer.component';
import {DisplayObjectPropertiesComponent} from './ui/display-object-properties/display-object-properties.component';
import {CarDetailsComponent} from './ui/car-details/car-details.component';
import {DisplayPropertyComponent} from './ui/display-object-properties/display-property/display-property.component';
import {DotTextPipe} from './pipe/dot-text.pipe';
import {LcTimeAgoPipe} from './pipe/lc-time-ago.pipe';
import {UserTypePipe} from './pipe/user-type.pipe';
import {UserStatePipe} from './pipe/user-state.pipe';
import {BadgeLabelPipe} from './pipe/badge-label.pipe';
import {CookieTextComponent} from './ui/cookie-text/cookie-text.component';
import {MessageTimelineComponent} from './ui/message-timeline/message-timeline.component';
import {LcPopupService} from './modals/lc-popup.service';
import {InfoPopupComponent} from './ui/info/info-popup.component';
import {InfoButtonComponent} from './ui/info/info-button.component';
import {UnsafeHtmlPipe} from './pipe/unsafe-html.pipe';
import {IntegrationTypePipe} from './pipe/integration-type.pipe';
import {UserNamePipe} from './pipe/user-name.pipe';
import {AudatexAggregateSettlementPeriodTypePipe} from './pipe/audatex-aggregate-settlement-period-type.pipe';
import {AudatexSettlementPeriodStatePipe} from './pipe/audatex-settlement-period-state.pipe';
import {PaintSystemPipe} from './pipe/paint-system.pipe';
import {
  LcOrganisationTextLibraryButtonComponent
} from './ui/text-library/button/lc-organisation-text-library-button.component';
import {AutoflexStatePipe} from './pipe/autoflex-state.pipe';

@NgModule({
  imports: [
    SharedLibsModule,
    RouterModule,
    LazyLoadImageModule,
    FileUploadModule,
    PdfViewerModule
  ],
  declarations: [
    HasAnyAuthorityDirective,
    AutofocusDirective,
    SpinnerTextComponent,
    SpinnerImageComponent,
    InputMaskDirective,
    InputValidatorDirective,
    PositionsDropdownValidatorDirective,
    ForsiReportKeyPipe,
    FilterPipe,
    DotTextPipe,
    BadgeLabelPipe,
    NgbDatePipe,
    LcTimeAgoPipe,
    ForsiReportKeyShortPipe,
    ForsiReportTypePipe,
    IntegrationTypePipe,
    BottombarComponent,
    AutocompleteComponent,
    InlineUserComponent,
    ShowPoliceComponent,
    VkInfoComponent,
    ContextMenuComponent,
    BroadcastListComponent,
    TextLibraryComponent,
    LcOrganisationTextLibraryButtonComponent,
    TextareaAutosizeDirective,
    YesNoPipe,
    UserTypePipe,
    UserStatePipe,
    AudatexAggregateSettlementPeriodTypePipe,
    AudatexSettlementPeriodStatePipe,
    IntegrationTypePipe,
    MessageComponent,
    CameraIconComponent,
    BreadcrumbComponent,
    LinkedReportListComponent,
    PrintViewerComponent,
    CombinedPrintViewerComponent,
    DisplayObjectPropertiesComponent,
    DisplayPropertyComponent,
    CarDetailsComponent,
    CookieTextComponent,
    MessageTimelineComponent,
    InfoPopupComponent,
    InfoButtonComponent,
    UnsafeHtmlPipe,
    UserNamePipe,
    PaintSystemPipe,
    AutoflexStatePipe
  ],
  providers: [
    Title,
    LcPopupService,
    AudatexAggregateSettlementPeriodTypePipe,
    AudatexSettlementPeriodStatePipe
  ],
  exports: [
    SharedLibsModule,
    HasAnyAuthorityDirective,
    AutofocusDirective,
    SpinnerTextComponent,
    SpinnerImageComponent,
    InputMaskDirective,
    InputValidatorDirective,
    PositionsDropdownValidatorDirective,
    ForsiReportKeyPipe,
    FilterPipe,
    NgbDatePipe,
    LcTimeAgoPipe,
    ForsiReportKeyShortPipe,
    ForsiReportTypePipe,
    IntegrationTypePipe,
    BottombarComponent,
    BreadcrumbComponent,
    AutocompleteComponent,
    InlineUserComponent,
    ShowPoliceComponent,
    VkInfoComponent,
    ContextMenuComponent,
    BroadcastListComponent,
    TextLibraryComponent,
    TextareaAutosizeDirective,
    YesNoPipe,
    UserTypePipe,
    AudatexAggregateSettlementPeriodTypePipe,
    AudatexSettlementPeriodStatePipe,
    UserStatePipe,
    IntegrationTypePipe,
    DotTextPipe,
    BadgeLabelPipe,
    MessageComponent,
    CameraIconComponent,
    LinkedReportListComponent,
    PrintViewerComponent,
    CombinedPrintViewerComponent,
    DisplayObjectPropertiesComponent,
    DisplayPropertyComponent,
    CarDetailsComponent,
    CookieTextComponent,
    MessageTimelineComponent,
    InfoButtonComponent,
    UnsafeHtmlPipe,
    UserNamePipe,
    PaintSystemPipe,
    LcOrganisationTextLibraryButtonComponent,
    AutoflexStatePipe
  ]
})
export class SharedCommonModule {
}
