import {Component, OnDestroy} from '@angular/core';
import {PrincipalDTO} from 'app/shared';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {SettingsService} from '../shared/service/settings.service';

@Component({
  templateUrl: './about.component.html',
  styleUrls: [],
  providers: [SettingsService]
})
export class AboutComponent implements OnDestroy {
  private unsubscribe$ = new Subject<void>();
  principalModel: PrincipalDTO;
  versionTimestamp: string;
  serverVersion: string;

  constructor(settingsService: SettingsService) {
    this.versionTimestamp = environment.version;
    settingsService.getVersion().pipe(takeUntil(this.unsubscribe$)).subscribe(versionDTO => {
      this.serverVersion = versionDTO.version
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
