import {AfterViewInit, Component, Input, OnDestroy, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AgreementDocumentDTO} from '../../../../../shared/dto/agreement-document-dto.model';
import {DraftService} from '../../../../service/draft.service';
import {PdfViewerComponent} from 'ng2-pdf-viewer';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  templateUrl: './draft-agreement-documents-popup.component.html'
})
export class DraftAgreementDocumentsPopupComponent implements OnDestroy, AfterViewInit {
  private unsubscribe$ = new Subject<void>();

  @Input() token: string;
  @Input() agreementDocuments: AgreementDocumentDTO[] = [];
  selectedAgreementDocument: AgreementDocumentDTO | null = null;
  loading = false;

  @ViewChild(PdfViewerComponent)
  pdfViewerComponent: PdfViewerComponent;

  constructor(public clientStateService: DraftService,
              private activeModal: NgbActiveModal) {
  }

  ngAfterViewInit(): void {
    this.pdfViewerComponent.afterLoadComplete
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.loading = false;
      });

    if (this.agreementDocuments.length > 0) {
      this.selectAgreementDocument(this.agreementDocuments[0]);
    }
  }

  cancel(): void {
    this.activeModal.dismiss();
  }

  downloadAgreementDocument(agreementDocument: AgreementDocumentDTO): void {
    window.open(this.getAgreementDocumentUrl(agreementDocument, true), '_blank');
  }

  getAgreementDocumentUrl(agreementDocument: AgreementDocumentDTO, download = false): string | null {
    return agreementDocument !== null ? this.clientStateService.getAgreementDocumentUrl(this.token, agreementDocument.id, download) : null;
  }

  isSelected(agreementDocument: AgreementDocumentDTO): boolean {
    return this.selectedAgreementDocument !== null && this.selectedAgreementDocument.id === agreementDocument.id;
  }

  selectAgreementDocument(agreementDocument: AgreementDocumentDTO): void {
    this.loading = agreementDocument !== this.selectedAgreementDocument;
    this.selectedAgreementDocument = agreementDocument;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
