import {Component, OnDestroy, OnInit} from '@angular/core';
import {PrincipalDTO, PrincipalService} from 'app/shared/index';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {StringUtils} from '../../../shared/utils/string-utils';
import EnvironmentUtils from '../../../shared/utils/environment-utils';

@Component({
  templateUrl: './settings.support.component.html',
  styleUrls: [
    '../../settings.scss'
  ],
  selector: 'lc-settings-support'
})
export class SettingsSupportComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  isLoading: boolean;
  messageShown: number;
  principalModel: PrincipalDTO;
  teamviewerDownloadUrl = 'https://www.teamviewer.com/download';

  constructor(private principal: PrincipalService) {
  }

  ngOnInit(): void {
    this.principal.identity()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((principalModel) => {
        this.principalModel = principalModel;
      });
    this.messageShown = 0;
  }

  public providerInfo(): string {
    const providerInfo = this.principal.getProviderInfo();
    return !StringUtils.isEmpty(providerInfo) ? providerInfo : '-';
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  showReportError(): boolean {
    return EnvironmentUtils.isSupportEnabled();
  }

  getSupportUrl(): string {
    return EnvironmentUtils.getSupportUrl();
  }
}
