// DO NOT EDIT THIS FILE, EDIT THE WEBPACK COMMON CONFIG INSTEAD, WHICH WILL MODIFY THIS FILE
/* eslint-disable */
let _VERSION = '${pom.version}';
let _DEBUG_INFO_ENABLED = _VERSION.split("-").length > 2;
/* @toreplace VERSION */
/* @toreplace DEBUG_INFO_ENABLED */
/* eslint-enable */
export const VERSION = _VERSION;
export const VERSION_CHECK_URL = 'webapp/manifest.webapp';
export const DEBUG_INFO_ENABLED = _DEBUG_INFO_ENABLED;
