import {Injectable} from '@angular/core';
import {MessageDTO} from '../dto/message-dto.model';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {MessageReplyDTO} from '../ui/message/dto/message-reply-dto.model';
import URLBuilder from '../utils/url-builder';
import {AccountMessageDTO} from '../dto/account-message-dto.model';
import {tap} from 'rxjs/operators';
import {AppVisibilityService} from './core/app-visibility.service';
import {ReportIdTokenDto} from '../../message/list/report-id-token-dto.model';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  private urlBuilder = new URLBuilder();
  private messageBaseUrl = 'messages';
  private messagesCount = new BehaviorSubject<number>(null);
  private updateMessagesSubscription: Subscription;

  constructor(private http: HttpClient, private appVisibilityService: AppVisibilityService) {
  }

  getMessages(): Observable<AccountMessageDTO[]> {
    return this.http.get<AccountMessageDTO[]>(this.messageBaseUrl).pipe(
      tap(messages => this.messagesCount.next(messages.length))
    );
  }

  public getSentMessages(): Observable<AccountMessageDTO[]> {
    return this.http.get<AccountMessageDTO[]>(this.messageBaseUrl + '/sent');
  }

  public getMessageCount(): Observable<number> {
    return this.messagesCount.asObservable();
  }

  public startMessageCountBackgroundFetcher(): void {
    if (!this.updateMessagesSubscription) {
      this.updateMessagesSubscription = this.appVisibilityService.forsiVisibilityInterval(0, 60000).subscribe(() => {
        this.fetchMessageCount();
      });
    }
  }

  public stopMessageCountBackgroundFetcher(): void {
    if (this.updateMessagesSubscription) {
      this.updateMessagesSubscription.unsubscribe();
      this.updateMessagesSubscription = null;
    }
  }

  private fetchMessageCount(): void {
    this.http.get<number>(this.messageBaseUrl + '/count').subscribe(messagesCount => {
      this.messagesCount.next(messagesCount);
    });
  }
  
  sendReply(messageId: number, messageReply: MessageReplyDTO): Observable<MessageDTO> {
    return this.http.post<MessageDTO>(this.messageBaseUrl + '/' + messageId + '/reply', messageReply);
  }

  markAsRead(messageId: number): Observable<void> {
    const response = new Subject<void>();
    this.http.put<void>(this.messageBaseUrl + '/' + messageId, null).subscribe(() => {
      this.fetchMessageCount(); 
      response.next();
    });
    return response;
  }

  getMessagePdfUrl(messageId: number): string {
    return this.urlBuilder.getBaseApiUrl() + this.messageBaseUrl + '/' + messageId + '/pdf';
  }

  getLatestReportForMessage(messageId: number): Observable<ReportIdTokenDto> {
    return this.http.get<ReportIdTokenDto>(this.messageBaseUrl + '/latest-clientstate-and-report/' + messageId);
  }
}
