import {UserDTO} from '../../../../shared/dto/user-dto.model';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SelectedPostboxDTO} from '../../../model/postbox-configuration-dto.model';

@Component({
  templateUrl: './settings-postbox-row.component.html',
  styleUrls: [
  ],
  selector: 'lc-settings-postbox-row'
})
export class SettingsPostboxRowComponent {
  @Input()
  public postboxUsers: UserDTO[];

  @Input()
  public postbox: SelectedPostboxDTO;

  @Input()
  public post: UserDTO[];

  @Input()
  public editPostboxInterval = true;

  @Output()
  public togglePostbox = new EventEmitter<SelectedPostboxDTO>();


  public changeSelection(): void {
    this.togglePostbox.emit(this.postbox);
  }
}

