import {EventEmitter} from '@angular/core';
import {AttachmentDTO} from '../dto/attachment-dto.model';
import {FileItem} from 'ng2-file-upload';
import {TemporaryAttachmentDTO} from '../dto/temporary-attachment-dto.model';
import {isNullOrUndefined} from '../utils/object-utils';

export class TemporaryAttachmentApprovalModel {
  readyForApproval = false;
  reportId: number;
  reportKey: string;
  temporaryAttachmentsToApprove: TemporaryAttachmentDTO[];
  binaryData: object;
  approved = new EventEmitter(true);

  constructor(reportId: number, reportKey: string, attachments: AttachmentDTO[]) {
    this.binaryData = {};
    this.reportId = reportId;
    this.reportKey = reportKey;
    this.temporaryAttachmentsToApprove = [];
    attachments.forEach((attachment) => {
      const temporaryAttachment = new TemporaryAttachmentDTO();
      temporaryAttachment.name = attachment.name;
      temporaryAttachment.contentType = attachment.contentType;
      this.temporaryAttachmentsToApprove.push(temporaryAttachment)
    })
  }

  onTemporaryAttachmentLoaded(temporaryAttachment: TemporaryAttachmentDTO, item: FileItem): void {
    if (!isNullOrUndefined(this.temporaryAttachmentsToApprove)) {
      for (let i = 0; i < this.temporaryAttachmentsToApprove.length; i++) {
        const current = this.temporaryAttachmentsToApprove[i];
        if (isNullOrUndefined(current.id) && current.name === item.file.name) {
          this.temporaryAttachmentsToApprove[i] = temporaryAttachment;
          const reader = new FileReader();
          reader.readAsDataURL(item._file); // read file as data url
          reader.onload = (event) => { // called once readAsDataURL is completed
            this.binaryData[temporaryAttachment.name] = reader.result.toString();
          };

          return;
        }
      }
    }
  }

  onTemporaryAttachmentError(fileName: string): void {
    for (let i = 0; i < this.temporaryAttachmentsToApprove.length; i++) {
      const current = this.temporaryAttachmentsToApprove[i];
      if (current.name === fileName) {
        this.temporaryAttachmentsToApprove.splice(i, 1);
        break;
      }
    }
  }

  onAllTemporaryAttachmentsLoaded(): void {
    this.readyForApproval = true;
  }

  attachmentsApproved(approvedAttachments: AttachmentDTO[]): void {
    approvedAttachments.forEach((attachment) => {
      attachment.report = true;
      this.approved.emit(attachment);
    });
  }

  getImageUrl(temporaryDocument: TemporaryAttachmentDTO): string {
    return this.binaryData[temporaryDocument.name];
  }

}
