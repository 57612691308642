import {Injectable, Injector} from '@angular/core';
import {LocationStrategy} from '@angular/common';
import {PrincipalService} from '../auth/principal.service';
import {LoggingService} from '../logging.service';

@Injectable({
  providedIn: 'root',
})
export class CustomErrorHandler {
  constructor(private injector: Injector) {
  }

  handleError(error): void {
    const loggingService = this.injector.get(LoggingService);
    const locationStrategy = this.injector.get(LocationStrategy);
    const principalService = this.injector.get(PrincipalService);
    const url = locationStrategy.path();
    console.log('Uncaught error', error);
    loggingService.logError(principalService.getUsername(), url, error);
  }
}
