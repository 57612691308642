import {Component, OnDestroy, OnInit} from '@angular/core';
import {LcActionMenu, LcActionMenuItem} from '../../shared/ui/bottombar/lc-action-menu.model';
import {ClientNavigationService} from '../../shared/service/client-navigation.service';
import {ActivatedRoute, Router} from '@angular/router';
import {catchError, mergeMap, takeUntil} from 'rxjs/operators';
import {DraftService} from '../service/draft.service';
import {BootstrapAlertType, BootstrapGrowlService} from '../../shared/ui/ngx-bootstrap-growl';
import {combineLatest, of, Subject, throwError} from 'rxjs';
import {ContextMenu, ContextMenuItem} from '../../shared/ui/context-menu/context-menu.model';
import {UIUtils} from '../../shared/utils/ui-utils';
import {ClientStateDetails} from '..';
import {AttachmentUseCase} from '../../shared/model/attachment-use-case.model';
import {AttachmentSummaryDTO} from '../../shared/dto/attachment-summary-dto.model';
import {StringUtils} from '../../shared/utils/string-utils';
import {ReportCategory} from '../../shared/model/report-category.model';
import {ForsiReportKeyPipe} from '../../shared/pipe/forsi-report-key.pipe';
import {ForsiReportKeyShortPipe} from '../../shared/pipe/forsi-report-key-short.pipe';
import {isNullOrUndefined} from '../../shared/utils/object-utils';

@Component({
  templateUrl: './draft-takeover.component.html',
  styleUrls: [
    'draft-takeover.scss'
  ],
  providers: []
})
export class DraftTakeoverComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  loading = true;
  draftMenu: LcActionMenu;
  clientStateToken: string;
  contextMenu: ContextMenu;
  bottomMenuTitle: string;
  bottomMenuShortTitle: string;
  bottomMenuIconClass = null;
  details: ClientStateDetails;
  print: string;
  attachmentSummary: AttachmentSummaryDTO = null;
  attachmentUseCase = AttachmentUseCase.Takeover;

  constructor(private navigationService: ClientNavigationService,
              private draftService: DraftService,
              private router: Router,
              private reportKeyPipe: ForsiReportKeyPipe,
              private reportKeyShortPipe: ForsiReportKeyShortPipe,
              private bootstrapGrowlService: BootstrapGrowlService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.setupContextMenu();
    this.setupDraftActionMenu();
    this.setupObservables();
  }

  setupDraftActionMenu(): void {
    this.draftMenu = new LcActionMenu();
    this.draftMenu.addItem(new LcActionMenuItem('fa-arrow-left', () => {
      this.navigationService.back();
    }, 'Tilbage'));

    const takeoverDraftObserver = {
      next: takeoverResult => {
        this.bootstrapGrowlService.addAlert('Kladden er overtaget', BootstrapAlertType.SUCCESS, 3000);
        this.router.navigate(['../../edit/' + takeoverResult.clientStateToken], {relativeTo: this.route});
      }, error: err => {
        this.bootstrapGrowlService.addAlert('Kunne ikke overtage kladden', BootstrapAlertType.DANGER, 5000);
        this.loading = false;
      }
    };
    const takeoverDraft = new LcActionMenuItem('fa-check', () => {
      this.loading = true;
      this.draftService.takeoverDraft(this.clientStateToken)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(takeoverDraftObserver);
    }, 'Overtag');
    takeoverDraft.disabledCallback = () => isNullOrUndefined(this.clientStateToken);
    this.draftMenu.addItem(takeoverDraft);
  }

  private setupObservables(): void {
    this.route.params.pipe(
      mergeMap(params => {
        const token = params['token'];
        const clientStateObs = this.draftService.getClientStateObs(token);
        const attachmentSummaryObs = this.draftService.getAttachmentSummary(token);
        const printObs = this.draftService.getPrintAsText(token);
        return combineLatest([of(token), clientStateObs, attachmentSummaryObs, printObs]);
      }), catchError(err => {
        this.loading = false;
        return throwError(() => err);
      }), takeUntil(this.unsubscribe$)).subscribe(([token, clientState, attachmentSummary, printResult]) => {
      this.clientStateToken = token;
      this.details = clientState;
      this.bottomMenuIconClass =
        this.attachmentSummary = attachmentSummary;
      this.print = printResult.print;
      this.initializeMenus();
      this.loading = false;
    });
  }

  private setupContextMenu(): void {
    this.contextMenu = new ContextMenu();
    const uiUtils = new UIUtils();
    this.contextMenu.addItem(new ContextMenuItem('fa-print', 'Print', 'report-print', (item) => {
      uiUtils.scrollToElementId(['report-print'], false);
    }));

    this.contextMenu.addItem(new ContextMenuItem('fa-archive', 'Diverse', 'misc', () => {
      uiUtils.scrollToElementId(['misc']);
    }));
  }

  initializeMenus(): void {
    const reportKey = this.getAttachmentReportKey(this.details);
    this.bottomMenuTitle = this.reportKeyPipe.transform(reportKey);
    this.bottomMenuShortTitle = this.reportKeyShortPipe.transform(reportKey);
    this.bottomMenuIconClass = ReportCategory.iconCssClass(this.details.schema);
    setTimeout(() => {
      this.contextMenu.updateMenuHighlights(null);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  getRegistrationNumber(details: ClientStateDetails): string {
    return details.ansvarsskade ? details.skadelidte.regNr : details.forsikringstager.regNr
  }

  getAttachmentReportKey(details: ClientStateDetails): string {
    return !StringUtils.isEmpty(this.details.taksRapportKey) ? details.taksRapportKey : details.proformaOrdreNummer;
  }
}
