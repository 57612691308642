export class SkavuPositionValueDTO {
  nr: string;
  position: number;
  workText: string;
  hours: number;
  amount: number;
  sparePartPrice: number;
  nfgPct: number; //note: 10% == 10 and not 0.1 as it could be.
  lak: boolean;
  extraTexts: string[];
}
