import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable()
export class XHRInterceptor implements HttpInterceptor {
  private static EAP_PATH = '/eap/';
  private static SECURE_REST_API_PATH = 'secure/api/';
  private static OPEN_API_PATH = 'open/';
  private static O_AUTH_PATH = 'oauth/';
  private static WEBAPP_PATH = 'webapp/';

  public static isEap(): boolean {
    return location.pathname.substr(0, 5) === XHRInterceptor.EAP_PATH;
  }

  public static isWebapp(): boolean {
    return location.pathname.substr(0, 5) === XHRInterceptor.EAP_PATH;
  }

  public static getContextPath(): string {
    return XHRInterceptor.isEap() ? XHRInterceptor.EAP_PATH : '/';
  }

  public static getRequestUrl(url: string): string {
    let restServiceUrl = XHRInterceptor.getContextPath();
    if (url.startsWith(XHRInterceptor.WEBAPP_PATH)) {
      return url.substring(7);
    } else if (!url.startsWith(XHRInterceptor.OPEN_API_PATH) && !url.startsWith(XHRInterceptor.O_AUTH_PATH)) {
      restServiceUrl += XHRInterceptor.SECURE_REST_API_PATH;
    }
    return restServiceUrl + url;     // prefix base url
  }

  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.startsWith('http')) {
      //just parse any full http path calls directly through to next handler
      //could be calls to i.e. qapter
      return next.handle(req);
    }
    const requestUrl = XHRInterceptor.getRequestUrl(req.url);
    //apply lc-version to request header for further debugging
    let httpHeaders = req.headers;
    httpHeaders  = httpHeaders.set('x-lc-version', environment.version);
    if (requestUrl.endsWith('webpad/session/start')) {
      console.log('requestUrl towards qapter', requestUrl);
    }
    const request = req.clone({headers: httpHeaders, url: requestUrl});
    return next.handle(request);
  }
}
