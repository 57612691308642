import {Pipe, PipeTransform} from '@angular/core';
import {PaintSystem} from '../model/paint-system.model';

@Pipe({
  name: 'paintSystem'
})
export class PaintSystemPipe implements PipeTransform {
  transform(value: PaintSystem): string {
    return value === PaintSystem.NONE ? 'Uden laksystem' : value?.toLowerCase();
  }
}
