<form #f="ngForm" autocomplete="off">
    <h2>1. Automatisk opslag</h2>
    <p class="text-muted">Start med at lave et automatisk opslag af bilen. Du kan enten tilføje et billede af
        nummerpladen eller indtaste Registrerings-/ stelnummer</p>
    <div class="row row-cols-2 mb-4">
        <div class="col">
            <div class="card ocr-card">
                <div class="card-header">Billede af nummerpladen</div>
                <div class="card-body text-center" ng2FileDrop [uploader]="fileUploader"
                     [ngClass]="{'nv-file-over': ocrDropZoneOver}" (fileOver)="fileOverOcrDropZone($event)">
                    <input type="file" ng2FileSelect [uploader]="fileUploader" #photoButton id="photoButton"
                           [disabled]="fileUploader.isUploading" style="display: none"/>
                    <div class="container pe-0 ps-0">
                        <img src="{{createViewModel.ocrImageUrl}}" class="img-fluid" style="max-height: 200px"/>
                        <div class="ocr-delete-image">
                            <i *ngIf="createViewModel.ocrImage && (!doingOcrScan && !searchingVin)"
                               class="ocr-delete-image-icon fa fa-trash" (click)="removeImage()"></i>
                            <i *ngIf="!createViewModel.ocrImage && (!doingOcrScan && !searchingVin)"
                               class="ocr-delete-image-icon fa fa-camera" (click)="onPhotoButtonClicked()"></i>
                            <i *ngIf="doingOcrScan || searchingVin"
                               class="ocr-delete-image-icon align-middle fa fa-circle-o-notch fa-spin"
                               aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card">
                <div class="card-header">Registrerings- eller stelnummer</div>
                <div class="card-body">
                    <form>
                        <div class="input-group">
                            <input type="text" class="form-control text-uppercase" maxlength="20" size="20"
                                   autocomplete="off"
                                   [(ngModel)]="searchParm"
                                   name="searchParm" (keyup)="vinButtonKeyUp($event)"/>
                                <button class="btn btn-primary" style="width: 50px;" type="button" id="vinButton"
                                        (click)="doVinLookup()" [disabled]="!searchParm || searchingVin">
                                    <lc-spinner-text [spin]="searchingVin" text="Søg"></lc-spinner-text>
                                </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div [ngClass]="{'hide-information-section': (!vinLookupAttempted || doingOcrScan || searchingVin) }">

        <div class="container text-center col-6">
            <i class="fa fa-angle-double-down" style="font-size: 2rem;"></i>
            <div class="alert p-1 mb-0"
                 [ngClass]="{'alert-danger': (!!vinLookupWarnMessage && !dmrResult), 'alert-success': vinAllowedDTO, 'alert-warning': dmrResult && !vinAllowedDTO}">
                    <span class="" *ngIf="dmrResult">
                        DMR fabrikat/model: {{dmrResult.fabrikat + ' ' + dmrResult.model + (dmrResult.foersteRegDato ? ' (' + dmrResult.foersteRegDato + ')' : '')}}
                        <br/>
                    </span>
                <span class="" *ngIf="vinAllowedDTO">
                        {{vinAllowedDTO.message}}<br/>
                    </span>
                <span class="" *ngIf="vinLookupWarnMessage">
                        {{vinLookupWarnMessage}}<br/>
                    </span>
            </div>
            <i class="fa fa-angle-double-down" style="font-size: 2rem;"></i>
        </div>

        <h2>2. Indtast</h2>
        <p class="text-muted">Indtast de påkrævede informationer vi ikke har fået opdateret fra det automatiske
            opslag</p>
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <!-- Left part -->
                    <div class="col-md-6">
                        <div class="row pb-3">
                            <div class="col-form-label col-4">
                                <label for="regnr">Reg. nr</label>
                            </div>
                            <div class="col">
                                <input [(ngModel)]="createViewModel.regNr" class="form-control text-uppercase" type="text"
                                       id="regnr"
                                       name="regnr" lcInputMask="regNr"
                                       [disabled]="details.vehicle.nummerpladeType===' '"
                                       minlength=1 maxlength=10 size="10"
                                       required/>
                            </div>
                        </div>

                        <div class="row pb-3">
                            <div class="col-form-label col-4">
                                <label class="m-0" for="fabrikat">Fabrikat</label>
                            </div>
                            <div class="col">
                                <lc-autocomplete id="fabrikat" name="fabrikat" required
                                                 [items]="brands" [(ngModel)]="details.vehicle.fabrikat"
                                                 itemText="navn" exactKey="kode"></lc-autocomplete>
                            </div>
                        </div>

                        <div *ngIf="showAngivetFabrikat()" class="row pb-3">
                            <div class="col-form-label col-4">
                                <label class="m-0" for="angivetFabrikat">Angivet fabrikat</label>
                            </div>
                            <div class="col">
                                <input [(ngModel)]="details.vehicle.angivetFabrikat" class="form-control"
                                       type="text"
                                       id="angivetFabrikat" name="angivetFabrikat"
                                       minlength=1 size="20"
                                       required/></div>
                        </div>

                        <div class="row pb-3">
                            <div class="col-form-label col-4">
                                <label class="m-0" for="model">Model</label>
                            </div>
                            <div class="col">
                                <div class="d-flex">
                                    <lc-autocomplete class="w-100" id="model" name="model" required
                                                     [items]="models" [ngModel]="details.vehicle.model" itemText="navn"
                                                     exactKey="kode"
                                                     (ngModelChange)="onSelectModelChanged($event)"></lc-autocomplete>
                                    <div *ngIf="!!modelHelpText" class="text-warning clickable">
                                        <i class="fa fa-exclamation-triangle fa-2x pt-1 clickable"
                                           [ngbTooltip]="modelHelpText?.header" placement="right" tooltipClass="text-start"
                                           (click)="showModelHelpText()"
                                        ></i>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="showAngivetModel()" class="row pb-3">
                            <div class="col-form-label col-4">
                                <label for="angivetModel">Angivet model</label>
                            </div>
                            <div class="col">
                                <input [(ngModel)]="details.vehicle.angivetModel" class="form-control" type="text"
                                       id="angivetModel" name="angivetModel"
                                       minlength=1 size="20"
                                       required/></div>
                        </div>

                        <div class="row pb-3">
                            <div class="col-form-label col-4">
                                <label for="stelnr">Stelnummer</label>
                            </div>
                            <div class="col">
                                <input [(ngModel)]="details.vehicle.stelnr" class="form-control text-uppercase" type="text"
                                       id="stelnr"
                                       name="stelnr"
                                       minlength=1 maxlength=20 size="20"
                                       required/></div>
                        </div>
                        <div class="row pb-3">
                            <div class="col-form-label col-4">
                                <label for="aargang">Årgang</label>
                            </div>
                            <div class="col">
                                <input [(ngModel)]="details.vehicle.aargang" class="form-control" type="tel"
                                       id="aargang"
                                       name="aargang" lcInputMask="year" lcValidate="maxNextYear" size="4" min="1900"
                                       max="9999"
                                       required/></div>
                        </div>

                        <div class="row pb-3">
                            <div class="col-form-label col-4">
                                <label for="foersteRegDato">Første reg. dato</label>
                            </div>
                            <div class="col">
                                <div class="input-group" style="padding: 0px;">
                                    <input class="form-control" type="tel"
                                           id="foersteRegDato" name="foersteRegDato"
                                           [(ngModel)]="details.vehicle.foersteRegDato"
                                           ngbDatepicker #foersteRegDatoDP="ngbDatepicker" lcInputMask="date"
                                           [minDate]="{ 'year': 1900, 'month': 01 }"
                                           [maxDate]="pastOrPresentMaxDateSelection()"
                                           [disabled]="details.disableFirstRegistrationDate()"
                                           required>
                                        <button class="input-group-addon btn btn-outline-secondary"
                                                (click)="foersteRegDatoDP.toggle()"
                                                type="button">
                                            <i class="fa fa-calendar fa-lg"></i>
                                        </button>
                                </div>
                            </div>
                        </div>
                        <div class="row pb-3">
                            <div class="col-form-label col-4">
                                <label for="kmstand">Km. stand</label>
                            </div>
                            <div class="col">
                                <input [(ngModel)]="details.vehicle.kmstand" class="form-control" type="tel"
                                       id="kmstand"
                                       name="kmstand" lcInputMask="int6" maxlength="6" size="6" required/></div>
                        </div>
                        <div class="row pb-3">
                            <div class="col-form-label col-4">
                                <label for="handelsvaerdi">Handelsværdi</label>
                            </div>
                            <div class="col">
                                <input [(ngModel)]="details.vehicle.handelsvaerdi" class="form-control" type="tel"
                                       id="handelsvaerdi"
                                       name="handelsvaerdi" lcInputMask="int8" size="8"/></div>
                        </div>
                        <div class="row pb-3" *lcHasAnyAuthority="['ROLE_VK']">
                            <div class="col-form-label col-4">
                                <label for="skadedato">Skadedato</label>
                            </div>
                            <div class="col">
                                <div class="input-group" style="padding: 0px;">
                                    <input class="form-control" type="tel"
                                           id="skadedato" name="skadedato" [(ngModel)]="details.skadeDato"
                                           ngbDatepicker #skadedatoDP="ngbDatepicker" lcInputMask="date"
                                           [minDate]="{ 'year': 1900, 'month': 1, 'day': 1 }"
                                           [maxDate]="pastOrPresentMaxDateSelection()"
                                    >
                                    <button class="btn btn-outline-secondary" (click)="skadedatoDP.toggle()"
                                            type="button">
                                        <i class="fa fa-calendar fa-lg"></i>
                                    </button>
                                    <button class="btn btn-outline-secondary"
                                            (click)="this.details.skadeDato = dateService.todayNgbDateStruct()"
                                            type="button">&nbsp;d.d.&nbsp;
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Center -->

                    <!-- Right part -->
                    <div class="col-md-6">
                        <div class="row pb-3">
                            <div class="col-form-label col-4">
                                <label>Nummerplade</label>
                            </div>
                            <div class="col">
                                <div class="row">
                                    <div class="btn-group">
                                        <input type="radio" class="btn-check" name="nummerpladeType" value="H" id="HVID" autocomplete="off" [(ngModel)]="details.vehicle.nummerpladeType"
                                               (ngModelChange)="createViewModel.nummerpladeTypeChanged()"/>
                                        <label class="btn btn-secondary btn-sm" for="HVID">Hvid</label>
                                        <input type="radio" class="btn-check" name="nummerpladeType" value="G" id="GUL" autocomplete="off" [(ngModel)]="details.vehicle.nummerpladeType"
                                               (ngModelChange)="createViewModel.nummerpladeTypeChanged()"/>
                                        <label class="btn btn-secondary btn-sm" for="GUL">Gul/Papegøje</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="btn-group">
                                        <input type="radio" class="btn-check" name="nummerpladeType" value="B" id="BLAA" autocomplete="off" [(ngModel)]="details.vehicle.nummerpladeType"
                                               (ngModelChange)="createViewModel.nummerpladeTypeChanged()"/>
                                        <label class="btn btn-secondary btn-sm" for="BLAA">Blå</label>
                                        <input type="radio" class="btn-check" name="nummerpladeType" value="P" id="PROEVE" autocomplete="off" [(ngModel)]="details.vehicle.nummerpladeType"
                                               (ngModelChange)="createViewModel.nummerpladeTypeChanged()"/>
                                        <label class="btn btn-secondary btn-sm" for="PROEVE">Prøve</label>
                                        <input type="radio" class="btn-check" name="nummerpladeType" value=" " id="INGEN" autocomplete="off" [(ngModel)]="details.vehicle.nummerpladeType"
                                               (ngModelChange)="createViewModel.nummerpladeTypeChanged()"/>
                                        <label class="btn btn-secondary btn-sm" for="INGEN">Ingen</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-form-label col-4">
                                <label>Tilstand</label>
                            </div>
                            <div class="col">
                                <div class="row">
                                    <div class="btn-group">
                                        <input type="radio" class="btn-check" name="tilstandsType" value=" " id="EJVURDERET" autocomplete="off" [(ngModel)]="details.vehicle.tilstandsType"/>
                                        <label class="btn btn-secondary btn-sm" for="EJVURDERET">Ej vurderet</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="btn-group">
                                        <input type="radio" class="btn-check" name="tilstandsType" value="G" id="GOD" autocomplete="off" [(ngModel)]="details.vehicle.tilstandsType"/>
                                        <label class="btn btn-secondary btn-sm" for="GOD">God</label>
                                        <input type="radio" class="btn-check" name="tilstandsType" value="M" id="MIDDEL" autocomplete="off" [(ngModel)]="details.vehicle.tilstandsType"/>
                                        <label class="btn btn-secondary btn-sm" for="MIDDEL">Middel</label>
                                        <input type="radio" class="btn-check" name="tilstandsType" value="U" id="UNDER" autocomplete="off" [(ngModel)]="details.vehicle.tilstandsType"/>
                                        <label class="btn btn-secondary btn-sm" for="UNDER">Under middel</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">&nbsp;</div>
    </div>
</form>
