import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ProductRecycleService} from './product-recycle.service';
import {BehaviorSubject, interval, Observable, of, Subject} from 'rxjs';
import {filter, map, startWith, switchMap, takeUntil} from 'rxjs/operators';
import {ProductRecycleVinLookupModel} from './product-recycle-vin-lookup.model';
import {QapterStartModel} from '../shared/ui/qapter/qapter-start/qapter-start.component';
import {FabrikatDTO} from '../shared/makes/dto/fabrikat-dto.model';
import {ModelDTO} from '../shared/makes/dto/model-dto.model';
import URLBuilder from '../shared/utils/url-builder';
import {ProductRecycleCalculationModel} from './product-recycle-calculation.model';
import EnvironmentUtils from '../shared/utils/environment-utils';

@Component({
  selector: 'lc-product-recycle',
  templateUrl: './product-recycle.component.html',
  styleUrls: [
    'product-recycle.component.scss'
  ]
})
export class ProductRecycleComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  public sessionId: string;
  private token: string;
  public callbackUrl: string;
  vin$ = new BehaviorSubject<string>(null);
  fetchingVehicleInformation = false;
  vehicleInformation: ProductRecycleVinLookupModel | null = null;
  selectableMakes: FabrikatDTO[];
  selectableModels: ModelDTO[];
  selectedMake: FabrikatDTO;
  selectedModel: ModelDTO;
  spin = false;
  calculation: ProductRecycleCalculationModel | null = null;
  public startQapter$ = new Subject<QapterStartModel>();
  public formattedSessionTimeLeft$: Observable<string>;


  constructor(private route: ActivatedRoute,
              private productRecycleService: ProductRecycleService) {
  }

  ngOnInit(): void {
    this.vin$.pipe(
      filter(vin => !!vin),
      switchMap(vin => this.productRecycleService.vinLookup(this.sessionId, this.token, vin)),
      switchMap(vinLookup => {
        this.vehicleInformation = vinLookup;
        if (!vinLookup.model) {
          return this.productRecycleService.getMakes(this.sessionId, this.token);
        } else {
          return of([] as FabrikatDTO[]);
        }
      }),
      map(makes => {
        this.selectableMakes = makes;
        if (!!this.vehicleInformation && !this.vehicleInformation.model) {
          this.selectedMake = makes.find(m => m.classKode === this.vehicleInformation.audatexMakeCode);
          this.selectableModels = this.selectedMake?.modeller;
        }
      }),
      takeUntil(this.unsubscribe$))
      .subscribe(() => this.fetchingVehicleInformation = false);

    const qapterExit$ = new Subject<boolean>();
    qapterExit$.pipe(
      filter(qapterExit => qapterExit),
      switchMap(() => this.productRecycleService.calculate(this.sessionId, this.token)),
      takeUntil(this.unsubscribe$)
    ).subscribe({
      next: (calculation: ProductRecycleCalculationModel) => {
        this.calculation = calculation;
        this.fetchingVehicleInformation = false
      },
      error: () => this.fetchingVehicleInformation = false
    });

    this.fetchingVehicleInformation = true;
    this.sessionId = this.route.snapshot.queryParams.session;
    this.token = this.route.snapshot.queryParams.token;
    this.callbackUrl = this.route.snapshot.queryParams.callbackUrl;
    this.vin$.next(this.route.snapshot.queryParams.vin);
    qapterExit$.next(this.route.snapshot.queryParams.qapterExit)

    this.setupSessionTimeLeftCounter(this.decodeToken(this.token)?.exp);
  }

  private decodeToken(token: string) {
    const jwt = token.split('.').map(_token => {
      try {
        return (JSON.parse(window.atob(_token)))
      } catch {
        return;
      }
    }).reduce((acc, curr) => {
      if (!!curr) {
        acc = {...acc, ...curr};
      }
      return acc;
    }, Object.create(null));
    return jwt;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
  }

  makeSelectionChanged() {
    this.selectedModel = null;
    this.selectableModels = this.selectedMake.modeller;
  }

  public startQapter(): void {
    this.spin = true;
    const makeCode = !!this.vehicleInformation.audatexMakeCode ? this.vehicleInformation.audatexMakeCode : this.selectedMake.classKode;
    const modelCode = !!this.vehicleInformation.audatexModelCode ? this.vehicleInformation.audatexModelCode : this.selectedModel.kode;
    const submodelCode = !!this.vehicleInformation.audatexSubModelCode ? this.vehicleInformation.audatexSubModelCode : null;
    const modelOptions = this.vehicleInformation.audatexModelOptions;
    this.productRecycleService.prepareQapter(this.sessionId, this.token, makeCode, modelCode, submodelCode, modelOptions).pipe(
      map(prepareMode => {
        const callbackUrlParam = this.callbackUrl ? `&callbackUrl=${encodeURIComponent(this.callbackUrl)}` : '';
        return {
          qapterPrepareMode: prepareMode,
          returnURL: new URLBuilder().constructReturnURL(`product-recycle?session=${this.sessionId}&token=${this.token}&qapterExit=true${callbackUrlParam}`)
        } as QapterStartModel
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe({
      next: qapterStartModel => {
        this.startQapter$.next(qapterStartModel);
      }
    });
  }

  qapterEnabled(): boolean {
    return (!!this.vehicleInformation.audatexMakeCode && !!this.vehicleInformation.audatexModelCode) || (!!this.selectedMake && !!this.selectedModel);
  }

  private setupSessionTimeLeftCounter(expirationTime: number): void {
    if (expirationTime) {
      this.formattedSessionTimeLeft$ = interval(1000).pipe(
        map(() => this.formattedTimeLeft(this.sessionTimeLeftInMillis(expirationTime))),
        startWith('--:--:--'),
        takeUntil(this.unsubscribe$)
      );
    }
  }

  private sessionTimeLeftInMillis(epoc: number): number {
    const now = Date.now();
    const expDate = new Date(0); // The 0 there is the key, which sets the date to the epoch
    expDate.setUTCSeconds(epoc);
    return expDate.getTime() - now;
  }

  private formattedTimeLeft(milliseconds: number): string {
    if (milliseconds > 0) {
      const totalSeconds = Math.floor(milliseconds / 1000);
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const remainingSeconds = totalSeconds % 60;

      const formattedHours = hours > 9 ? hours.toString().padStart(2, '0') : hours.toString();
      const formattedMinutes = minutes.toString().padStart(2, '0');
      const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

      return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    } else {
      return 'Session expired';
    }
  }

  endSession() {
    if (this.callbackUrl) {
      window.location.replace(this.callbackUrl)
    }
  }

  showReportError(): boolean {
    return EnvironmentUtils.isSupportEnabled();
  }

  errorReportClicked(): void {
    window.open(EnvironmentUtils.getSupportUrl(), '_blank');
  }
}
