<div class="row">

    <div class="col-12 d-sm-inline d-none ">
        <i class="fa fa-3x fa-search-plus pull-right clickable ps-2" (click)="increaseFontsize()"></i>
        <i class="fa fa-3x fa-search-minus pull-right clickable" (click)="decreaseFontsize()"></i>
    </div>
</div>
<div class="row">
    <div class="col-12 pt-3 d-flex justify-content-center" *ngIf="print"
         [ngClass]="{'disable-text-selection': !allowCopyPrintAsText}">
        <pre [ngStyle]="{'font-size': fontSize + 'vw'}" [innerHTML]="print"></pre>
    </div>
</div>
