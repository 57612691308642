import {Pipe, PipeTransform} from '@angular/core';
import {isNullOrUndefined} from '../utils/object-utils';
import {IntegrationType} from '../dto/integration-type.model';

@Pipe({
  name: 'integrationType'
})
export class IntegrationTypePipe implements PipeTransform {
  private readonly INTEGRATION_TYPE_MAPPINGS: {type: IntegrationType, desc: string}[] = [
    {type: IntegrationType.PUSH_REPORT_REQUEST, desc: 'Push rapporter'},
    {type: IntegrationType.PREQUALIFICATION_REQUEST, desc: 'Prækvalifikation'},
    {type: IntegrationType.AUTOFLEX_SPARE_PART_MATCH, desc: 'Autoflex: Hent reservedele'},
    {type: IntegrationType.AUTOFLEX_SPARE_PART_BOOKING, desc: 'Autoflex: Book reservedel'},
    {type: IntegrationType.POLICY_LOOKUP_REQUEST, desc: 'Policeopslag'},
  ];
  transform(value: IntegrationType): string {
    return !isNullOrUndefined(value) ? this.INTEGRATION_TYPE_MAPPINGS.find(v => v.type === value).desc : value;
  }
}
