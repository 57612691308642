import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PrincipalService} from 'app/shared/index';
import {BootstrapGrowlService} from 'app/shared/ui/ngx-bootstrap-growl/index';
import {SettingsService} from '../../../shared/service/settings.service';
import {FunctionConfigurationDTO} from 'app/settings/model/function-configuration-dto.model';
import {NgForm} from '@angular/forms';
import {BootstrapAlertType} from 'app/shared/ui/ngx-bootstrap-growl/bootstrap-alert-type.enum';
import {AutoflexVersionService} from '../../../shared/service/autoflexversion.service';
import {AutoflexVersionDTO} from '../../../shared/dto/autoflex-version-dto.model';
import {PendingAutoflexVersionDTO} from '../../../shared/dto/pending-autoflex-version-dto.model';
import {forkJoin, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  templateUrl: './settings.function.component.html',
  styleUrls: [
    '../../settings.scss'
  ],
  selector: 'lc-settings-function'
})
export class SettingsFunctionComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  isLoading: boolean;
  isUploadingAutoflex: boolean;
  functionConfiguration: FunctionConfigurationDTO = new FunctionConfigurationDTO();
  autoflexVersions: AutoflexVersionDTO[];
  pendingAutoflexVersions: PendingAutoflexVersionDTO[];
  lastestActiveAutoflexVersion: AutoflexVersionDTO;

  @ViewChild('f') public form: NgForm;

  constructor(private principal: PrincipalService,
              private settingsService: SettingsService,
              private bootstrapGrowlService: BootstrapGrowlService,
              private autoflexVersionService: AutoflexVersionService) {
  }

  ngOnInit(): void {
    this.reset();
    if (this.principal.isTaksator()) {
      this.refreshAutoflexVersions();
    }
  }

  reset(): void {
    this.isLoading = true;
    this.settingsService.getFunctionConfiguration().pipe(takeUntil(this.unsubscribe$)).subscribe(functionConfiguration => {
      this.functionConfiguration = functionConfiguration;
      this.isLoading = false;
    });
  }

  save(): void {
    this.isLoading = true;
    this.settingsService.saveFunctionConfiguration(this.functionConfiguration).pipe(takeUntil(this.unsubscribe$)).subscribe(functionConfiguration => {
      this.functionConfiguration = functionConfiguration;
      this.isLoading = false;
      this.bootstrapGrowlService.addAlert('Funktioner opdateret', BootstrapAlertType.SUCCESS);
    });
  }

  uploadAutoFlex($event): void {
    const file: File = $event.target.files[0];
    this.isUploadingAutoflex = true;
    this.autoflexVersionService.createAutoflexVersion(file).pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.isUploadingAutoflex = false;
      this.bootstrapGrowlService.addAlert('Autoflexfil er modtaget og igangsættes hurtigst muligt.', BootstrapAlertType.SUCCESS);
      this.refreshAutoflexVersions();
    }, () => {
      this.isUploadingAutoflex = false;
    });
  }

  refreshAutoflexVersions(): void {
    this.autoflexVersions = null;
    this.lastestActiveAutoflexVersion = null;
    this.pendingAutoflexVersions = null;
    forkJoin([this.autoflexVersionService.getAutoflexVersions(), this.autoflexVersionService.getPendingAutoflexVersions()])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(results => {
        this.autoflexVersions = results[0];
        this.lastestActiveAutoflexVersion = this.autoflexVersions.filter(av => av.active)[0];
        this.pendingAutoflexVersions = results[1];
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
