import {isNullOrUndefined} from '../../utils/object-utils';

export class LcActionMenu {
  public menuItems: LcActionMenuItem[] = [];

  public addItem(menuItem: LcActionMenuItem, atIndex?: number): LcActionMenuItem {
    if (atIndex) {
      if (atIndex < this.menuItems.length) {
        this.menuItems.splice(atIndex, 0, menuItem);
      } else {
        this.menuItems.push(menuItem);
      }
    } else {
      this.menuItems.push(menuItem);
    }
    return menuItem;
  }

  public add(fontawesomeIconName: string, didClickMenuCallback: Function, title: string): LcActionMenuItem {
    const menuItem = new LcActionMenuItem(fontawesomeIconName, didClickMenuCallback, title);
    this.menuItems.push(menuItem);
    return menuItem;
  }
}

export class LcActionSubMenuItem {
  disabled = false;

  constructor(public title: string, public customClass: string, private didClickMenuCallback: Function) {
  }

  public didClickMenuItem(): void {
    this.didClickMenuCallback();
  }
}

export class LcActionMenuItem {
  public hideCallback: Function;
  public imgUrl: string;
  public buttonClass = 'btn-primary';
  public isLoading = false;
  public spin = false;
  public disabledReason: string;
  public buttomTooltipCallback: () => string = () => null;
  public subMenuItems: LcActionSubMenuItem[] = [];
  private identifier: number;

  constructor(
    public faIcon: string,
    private didClickMenuCallback: Function,
    public title: string,
    public disabledCallback?: Function,
    private disabledReasonCallback?: Function,
  ) {
    this.imgUrl = null;
    this.identifier = Date.now();
  }

  public setImageUrl(src: string): void {
    this.imgUrl = src;
    this.faIcon = null;
  }

  public didClickMenuItem(): void {
    this.didClickMenuCallback();
  }

  public disableMenuItem(): boolean {
    return this.isLoading || (!isNullOrUndefined(this.disabledCallback) ? this.disabledCallback() : false);
  }

  public showMenuItem(): boolean {
    return isNullOrUndefined(this.hideCallback) || !this.hideCallback();
  }

  public getDisabledReason(): string {
    if (this.disableMenuItem()) {
      return !isNullOrUndefined(this.disabledReasonCallback) ? this.disabledReasonCallback() : this.disabledReason;
    }
    return null;
  }

  public addIconColorClass(colorClass: string): void {
    if (this.faIcon && !this.faIcon.includes(colorClass)) {
      this.faIcon += (' ' + colorClass)
    }
  }

  public removeIconColorClass(colorClass: string): void {
    if (this.faIcon) {
      this.faIcon = this.faIcon.replace(' ' + colorClass, '');
    }
  }

  public getIdentifier(): string {
    return this.title.replace(/\s/g, '').toLowerCase() + this.identifier;
  }
}
