import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {VideoRequestResultDTO} from './model/video-request-result-dto.model';
import {VideoRequestDTO} from './model/video-request-dto.model';
import {tap} from 'rxjs/operators';
import {VideoRequestObserverService} from './video-request-observer.service';
import {Cacheable} from 'ts-cacheable';

const videoRequestCacheBuster$ = new Subject<void>();
const maxAge = 200;

@Injectable()
export class ClientStateVideoRequestService {

  private static createBaseUrl(token: string): string {
    return `clientstate/${token}/video-request`
  }

  constructor(private http: HttpClient, private videoRequestObserverService: VideoRequestObserverService) {
  }

  public clearCache(): void {
    videoRequestCacheBuster$.next();
  }

  @Cacheable({cacheBusterObserver: videoRequestCacheBuster$, maxAge: maxAge})
  public possibleToRequestForVideoUpload(token: string): Observable<boolean> {
    return this.http.get<boolean>(`${ClientStateVideoRequestService.createBaseUrl(token)}/possible-to-request-for-video-upload`);
  }

  @Cacheable({cacheBusterObserver: videoRequestCacheBuster$, maxAge: maxAge})
  public getVideoRequest(token: string): Observable<VideoRequestResultDTO> {
    return this.http.get<VideoRequestResultDTO>(`${ClientStateVideoRequestService.createBaseUrl(token)}`).pipe(
      tap(videoRequestResult => this.videoRequestObserverService.next(videoRequestResult.videoRequest))
    );
  }

  public createVideoRequestByAssessorClientState(token: string, message: string): Observable<VideoRequestDTO> {
    this.clearCache();
    return this.http.post<VideoRequestDTO>(`${ClientStateVideoRequestService.createBaseUrl(token)}`, {message: message}).pipe(
      tap(videoRequest => this.videoRequestObserverService.next(videoRequest))
    );
  }

  public deleteVideoRequestByAssessorClientState(token: string): Observable<VideoRequestDTO> {
    this.clearCache();
    return this.http.delete<VideoRequestDTO>(`${ClientStateVideoRequestService.createBaseUrl(token)}`).pipe(
      tap(videoRequest => this.videoRequestObserverService.next(videoRequest))
    );
  }
}
