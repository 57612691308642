<div class="col-12" *ngIf="!!advise">
    <div class="row pb-2">
        <div *ngIf="create" class="input-group">
            <input name="vkNr" [(ngModel)]="advise.toVkNr" placeholder="Værkstedsnummer" class="form-control"
                   [disabled]="!create" (keydown)="advise.toVkName = null"/>
                <button type="button" class="btn btn-outline-primary" [disabled]="!create"
                        (click)="lookupWorkshopName(advise.toVkNr)">Søg
                </button>
        </div>
        <div *ngIf="!create" class="pt-2">
            <h4>Du har spurgt om rådgivning fra værksted {{advise.toVkNr}}: {{advise.toVkName}}. </h4>
        </div>
    </div>
    <div *ngIf="create && !!advise.toVkName" class="row pb-2">
        <div class="col-12 p-0">
            <div class="form-text text-start">
                Du er ved at indhente rådgivning for lakering på <span
                class="text-nowrap font-weight-bold">{{reportKey | lcForsiReportKey}}</span> hos
                værkstedet: {{advise.toVkName}}
            </div>
            <div class="form-text text-start">
                Når der laves nye beregninger vil rådgivningen automatisk blive opdateret.
            </div>
        </div>
    </div>
    <div *ngIf="!create" class="row pb-3">
        <div class="col-12 input-group ps-0 pe-0">
            <lc-message-timeline
                [messages]="messages"
                (onAddMessage)="createNewMessage($event)"
                (onRefresh)="onRefresh.emit()"
            ></lc-message-timeline>
        </div>
    </div>
</div>
