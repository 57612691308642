<div class="no-focus" tabindex="0" [lcAutofocus]>
    <h1 class="card-title">B2B - Ekstern adgang for ({{name}})</h1>

    <div class="row mt-2 ">
        <div class="col-12 table-responsive overflow-hide">
            <div class="datatable-actions">
                <div class="position-absolute end-0 d-flex">
                    <div class="clickable py-1 m-1" (click)="open(tokenInfoPopup)" title="Information" >
                        <i class="fa fa-question-circle-o fa-2x"></i>
                    </div>
                    <div class="clickable py-1 mt-1 mb-1 ms-1" (click)="createNewToken(editTokenArea)" title="Opret ny B2B adgang">
                        <i class="fa fa-plus-square fa-2x"></i>
                    </div>
                    <div class="input-group-addon clickable py-1 m-1" (click)="reload()">
                        <i class="fa fa-refresh fa-2x"
                        [ngClass]="{'fa-spin': spinnerUtil.isLoading, 'fa-spin-stop-ie-hack': !spinnerUtil.isLoading}"></i>
                    </div>
                </div>
            </div>
            <table datatable id="userTokens" [dtOptions]="dataTableOptions" [dtTrigger]="dataTableRenderTrigger" class="table table-hover table-boxed">
                <thead>
                    <tr>
                        <th>Navn</th>
                        <th>Status</th>
                        <th>Oprettet</th>
                        <th>Deaktiveret</th>
                        <th>Api adgang</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let token of userTokens">
                        <td>{{token.name}}</td>
                        <td>{{token.enabled ? "Aktivt" : "Deaktiveret"}}</td>
                        <td>{{token.startDate}}</td>
                        <td>{{token.endDate}}</td>
                        <td style="font-size: 1.2em;">
                            <span *ngFor="let accessRole of token.accessRoles">
                                <span *ngIf="accessRole.enabled" class="badge p-1 bg-success ms-1">
                                    {{accessRoleTranslation[accessRole.name]?.name}}
                                    <i class="fa fa-question-circle-o fa-lg" ngbTooltip="{{accessRoleTranslation[accessRole.name]?.toolTip}}" tooltipClass="text-start"></i>
                                </span>
                            </span>
                        </td>
                        <td class="table-cell">
                            <div class="pull-right">
                                <div class="table-button" title="Lav ny nøgle"
                                     (click)="generateNewRefreshTokenStep1(token, showGenerateTokenWarning)">
                                    <i class="fa fa-refresh fa-2x"></i>
                                </div>
                                <div class="table-button" title="{{token.enabled ? 'Deaktiver' : 'Aktiver'}}"
                                     (click)="(disableEnableToken(token))">
                                    <i class="fa fa-2x "
                                       [ngClass]="{'fa-toggle-off': !token.enabled, 'fa-toggle-on': token.enabled}"></i>
                                </div>
                                <lc-edit-button title="Rediger" (onEdit)="editToken(token, editTokenArea)"></lc-edit-button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <ng-template #tokenInfoPopup let-modal>
        <div class="modal-header">
            <h3 class="text-truncate">Information</h3>
            <div class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            </div>
        </div>
        <div class="modal-body">
            <h4 class="card-title">B2B - Ekstern adgang</h4>
            <p>I dette skærmbillede kan du oprette og vedligeholde B2B adgange.</p>
            <p>Hvis du vælger, at samarbejde med en udbyder af værkstedsløsninger - som benytter dine rapportdata i
                servicen - skal denne udbyder have adgang til dine data for, at løse opgaven.
            </p>
            <p>En nøgle er et alias for dig som Forsi.dk bruger og betyder, at du kan videregive dine
                rapportdata til 3.part systemer uden, at disse data hentes af udbyderen ved hjælp af dit brugerID og
                passord.
            </p>
            <p>I forbindelse med opsætningen af forbindelsen mellem dig og din serviceudbyder, skal udbyderen
                have den autogenererede nøgle. Vær opmærksom på, at denne ”overdragelse” sker sikkert. (krypteret
                e-mail), da denne nøgle fremover vil give adgang til dine data uden din indblanding.
            </p>
            <p>Ved anvendelse af eksterne serviceudbydere, skal man være opmærksom på følgende:</p>
            <h5>Tavshedspligt efter lov om finansiel virksomhed</h5>
            <p>Personoplysninger som indsamles af finansielle virksomheder, herunder forsikringsselskaber, er i
                medfør af reglerne om lov om finansiel virksomhed (kapitel 9) § 117. stk. 1 tavshedsbelagt.
                Værkstederne er som modtager af oplysninger fra finansielle virksomheder, herunder forsikringsselskaber, også pålagt
                tavshedspligt efter fil § 117, stk. 1 i lov om finansiel virksomhed, i det denne følger personoplysningerne.</p>
            <h5>Persondata</h5>
            <p>Værkstederne er som modtager af personoplysninger via Autotaks selvstændig dataansvarlig for
                behandlingen af oplysningerne i persondataretlig forstand. Værkstederne bør derfor behandle kundeoplysninger
                med omhu og ikke videre-anvende oplysningerne udover, hvad der er nødvendigt for at fuldføre opgaven for
                forsikringsselskabet og forsikringstager. En videreanvendelse af oplysningerne kræver, at værkstedet
                har et lovligt behandlingsgrundlag efter databeskyttelses-forordningens art. 6. Værkstederne er selv
                ansvarlige for eventuelt at sikre dette grundlag.
            </p>
        </div>
        <div class="modal-footer">
            <div class="full-width text-center">
                <button type="submit" class="btn col-6 me-1 btn-primary" (click)="markTokenInfoAccepted(modal)">
                    Læst
                </button>
            </div>
        </div>
    </ng-template>

    <ng-template #editTokenArea let-modal>
        <form (submit)="createOrUpdateToken(modal, showGeneratedToken)" >
            <div class="modal-header pre-size header-style">
                <h3 class="text-truncate">
                    {{editTitle}}
                </h3>
                <div aria-label="Close" data-dismiss="modal" class="btn-close clickable" (click)="modal.dismiss('Cross click')"></div>
            </div>
            <div class="modal-body">
                <input class="d-none">
                <div class="col-12 pt-4">
                    <div class="row pb-3">
                        <label class="col-3 col-form-label">Navn</label>
                        <div class="col-9">
                            <input name="tokenName" [(ngModel)]="currentToken.name" placeholder="Navn" 
                                   ngbTooltip="Navn bruges kun af dig til at huske hvem der har fået udleveret nøglen." tooltipClass="text-start"
                                   class="form-control" [ngClass]="{'ng-invalid': isDuplicate(currentToken)}" required/>
                            <small class="form-text text-start text-danger">
                                <div *ngIf="isDuplicate(currentToken)">Navnet findes allerede</div>
                            </small>
                        </div>

                    </div>
                    <div class="row pb-3">
                        <label class="col-3 col-form-label">Adgangsrettigheder</label>
                        <div class="col-9">
                            <div *ngFor="let accessRole of currentToken.accessRoles" class="form-check">
                                 
                                <input type="checkbox" class="form-check-input"
                                       id="{{'access_role_' + accessRole.name}}" 
                                       [(ngModel)]="accessRole.enabled" 
                                       name="{{'access_role_' + accessRole.name}}">
                                <label class="form-check-label" for="{{'access_role_' + accessRole.name}}">
                                    {{accessRoleTranslation[accessRole.name]?.name}} 
                                    <i class="fa fa-question-circle-o fa-lg" ngbTooltip="{{accessRoleTranslation[accessRole.name]?.toolTip}}" tooltipClass="text-start"></i>
                                </label>
                                <span></span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="full-width text-center">
                    <button type="submit" class="btn col-6 me-1 btn-primary" [disabled]="!tokenIsValid(currentToken)">
                        <lc-spinner-text [spin]="spinnerUtil.isLoading"></lc-spinner-text>
                        Gem</button>
                </div>
            </div>
        </form>
    </ng-template>

    <ng-template #showGenerateTokenWarning let-modal>
        <div class="modal-header pre-size header-style color-danger" >
            <h3 class="text-truncate">
                Ny nøgle - Advarsel
            </h3>
            <div aria-label="Close" data-dismiss="modal" class="btn-close" (click)="modal.dismiss()"></div>
        </div>
        <div class="modal-body gallery-body">
            <div class="col-12">
                <div class="row pb-3">
                    <p>
                        Den aktuelle nøgle for <strong>{{currentToken.name}}</strong> bliver ugyldig øjeblikkeligt og kan ikke gendannes.
                    </p>
                    <p>
                        <em><strong>Vil du oprette en ny adgangsnøgle?</strong></em>
                    </p>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="full-width text-center">
                <button type="submit" class="btn col-3 me-1 btn-primary" (click)="modal.dismiss()">
                    Annuller</button>
                <button type="submit" class="btn col-3 me-1 btn-danger" (click)="modal.dismiss(); generateNewRefreshTokenStep2(showGeneratedToken)">
                    Fortsæt</button>
            </div>
        </div>
    </ng-template>

    <ng-template #showGeneratedToken let-modal>
            <div class="modal-header pre-size header-style" >
                <h3 class="text-truncate">
                    En ny B2B nøgle er blevet oprettet
                </h3>
                <div aria-label="Close" data-dismiss="modal" class="btn-close" (click)="modal.dismiss()"></div>
            </div>
            <div class="modal-body">
                <div class="row pb-3">
                    <p>
                        Den eksterne udbyder skal have nedenstående nøgle. Du skal kopiere denne nøgle fra feltet
                        nedenfor
                        og sende den til udbyderen. Du kan kun se denne nøgle denne ene gang, så hvis nøglen går tabt
                        skal du generere en ny.
                    </p>
                </div>
                <div class="row pb-3">
                    <div class="col-12">
                        <label>Nøgle</label>
                        <textarea #generatedTokenElement class="form-control" rows="6"
                                  [value]="currentRenewToken" disabled></textarea>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="full-width text-end">
                    <button type="submit" class="btn col-3 me-1 btn-primary" (click)="modal.dismiss()">
                        OK</button>
                    <button type="submit" class="btn col-3 me-1 btn-primary" (click)="copyTokenToClipboard(generatedTokenElement)">
                        Kopier nøgle</button>
                </div>
            </div>
    </ng-template>
</div>
