import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {
  B2CPostponedReminderPopupComponent
} from './b2c-postponed-reminder-popup/b2c-postponed-reminder-popup.component';
import {B2CSignupFlowPopupComponent} from './b2c-signup-flow-popup/b2-c-signup-flow-popup.component';
import {SharedCommonModule} from '../../shared/shared-common.module';
import {B2CEmailInputComponent} from './b2c-email-input.component';

@NgModule({
  imports: [
    SharedCommonModule
  ],
  declarations: [
    B2CSignupFlowPopupComponent,
    B2CPostponedReminderPopupComponent,
    B2CEmailInputComponent
  ],
  exports: [
    B2CSignupFlowPopupComponent,
    B2CPostponedReminderPopupComponent,
    B2CEmailInputComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class B2CSignupModule {
}
