import {Route} from '@angular/router';

import {UserRouteAccessService} from 'app/shared/service/auth/user-route-access.service';
import {PoliceComponent} from './police.component';
import {AppRole} from '../shared/service/auth/app-roles.model';

export const POLICE_ROUTE: Route = {
  path: 'police',
  component: PoliceComponent,
  data: {
    authorities: [AppRole.VK, AppRole.TAKS],
    pageTitle: 'Policeopslag',
    breadcrumb: 'Policeopslag'
  },
  canActivate: [UserRouteAccessService]
};
