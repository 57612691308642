import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {LcNavbarDeeplinkManagerService} from '../shared/lc-navbar-deeplink-manager.service';
import {Subject} from 'rxjs';
import {NgbNav} from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './non-standard-positions.component.html',
  styleUrls: [],
  providers: [LcNavbarDeeplinkManagerService]
})
export class NonStandardPositionsComponent implements AfterViewInit{

  private unsubscribe$ = new Subject<void>();

  @ViewChild(NgbNav, {static: true})
  ngbNav: NgbNav;

  constructor(private navbarManager: LcNavbarDeeplinkManagerService) {
  }

  ngAfterViewInit(): void {
    this.navbarManager.initWithNavbar(this.ngbNav, this.unsubscribe$);
  }
  
  
}

