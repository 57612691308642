<fieldset ngForm="f" #f="ngForm">
    <h2>Datoer</h2>
    <div class="card">
        <div class="card-body">
            <div class="row">
                <!-- Left part -->
                <div class="col-md-6">

                    <div class="row pt-3">
                        <div class="col-form-label col-4">
                            <label for="skadedato">Skadedato</label>
                        </div>
                        <div class="col-8 col-xl-6">
                            <div class="input-group" style="padding: 0;">
                                <input class="form-control" type="tel"
                                       id="skadedato" name="skadedato" [(ngModel)]="details.skadeDato"
                                       ngbDatepicker #skadedatoDP="ngbDatepicker" lcInputMask="date"
                                       [minDate]="fiveYearsPriorMinDateSelection()"
                                       [maxDate]="pastOrPresentMaxDateSelection()"
                                       (ngModelChange)="onDamageDateChanged.emit($event)"
                                >
                                    <button class="btn btn-outline-secondary" (click)="skadedatoDP.toggle()"
                                            type="button">
                                        <i class="fa fa-calendar fa-lg"></i>
                                    </button>
                                    <button class="btn btn-outline-secondary"
                                            (click)="setToDay()"
                                            type="button">&nbsp;d.d.&nbsp;
                                    </button>
                            </div>
                        </div>
                    </div>

                    <div class="row pt-3" *ngIf="details.isQapterType()">
                        <div class="col-form-label col-4">
                            <label for="besigtigelsesdato">Kan besigtiges</label>
                        </div>
                        <div class="col-8 col-xl-6">
                            <div class="input-group" style="padding: 0;">
                                <input class="form-control" type="tel"
                                       id="besigtigelsesdato" name="besigtigelsesdato"
                                       [(ngModel)]="details.besigtigelsesDato"
                                       ngbDatepicker #besigtigelsesdatoDP="ngbDatepicker" lcInputMask="date"
                                       [minDate]="{ 'year': 1900, 'month': 1, 'day': 1 }"
                                       [maxDate]="{ 'year': 2099, 'month': 12, 'day': 31 }"
                                       [required]="inspectionDateRequired()">
                                    <button class="btn btn-outline-secondary"
                                            (click)="besigtigelsesdatoDP.toggle()"
                                            type="button">
                                        <i class="fa fa-calendar fa-lg"></i>
                                    </button>
                                    <button class="btn btn-light btn-outline-secondary"
                                            (click)='details.besigtigelsesDato = dateService.todayNgbDateStruct()'
                                            type="button">&nbsp;d.d.&nbsp;
                                    </button>
                            </div>
                        </div>
                    </div>

                    <div class="row pt-3" *ngIf="details.isQapterType()">
                        <div class="col-form-label col-4">
                            <label for="reperationfaerdigdato">Reparation færdig</label>
                        </div>
                        <div class="col-8 col-xl-6">
                            <div class="input-group" style="padding: 0;">
                                <input class="form-control" type="tel"
                                       id="reperationfaerdigdato" name="reperationfaerdigdato"
                                       [(ngModel)]="details.reperationfaerdigDato"
                                       ngbDatepicker #reperationfaerdigdatoDP="ngbDatepicker" lcInputMask="date"
                                       [minDate]="{ 'year': 1900, 'month': 1, 'day': 1 }"
                                       [maxDate]="{ 'year': 2099, 'month': 12, 'day': 31}"
                                >
                                    <button class="btn btn-outline-secondary"
                                            (click)="reperationfaerdigdatoDP.toggle()"
                                            type="button">
                                        <i class="fa fa-calendar fa-lg"></i>
                                    </button>
                                    <button class="btn btn-light btn-outline-secondary"
                                            (click)='details.reperationfaerdigDato = dateService.todayNgbDateStruct()'
                                            type="button">&nbsp;d.d.&nbsp;
                                    </button>
                            </div>
                        </div>
                    </div>

                </div>
                <!-- Center -->
            </div>
        </div>
    </div>
</fieldset>
