import {Component, Input} from '@angular/core';
import {ClientStateDetails} from '../../../../model/client-state-details.model';

@Component({
  selector: 'lc-vehicle-condition',
  templateUrl: './draft-vehicle-condition.component.html',
  styleUrls: [
    '../../../draft-common.scss'
  ]
})
export class DraftVehicleConditionComponent {
  @Input() details: ClientStateDetails;
}
