import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SharedCommonModule} from 'app/shared/shared-common.module';
import {SharedModule} from 'app/shared/shared.module';
import {POLICE_ROUTE} from 'app/police/police.route';
import {PoliceComponent} from './police.component';

@NgModule({
  imports: [
    SharedModule,
    SharedCommonModule,
    RouterModule.forRoot([POLICE_ROUTE], {useHash: true})
  ],
  declarations: [
    PoliceComponent,
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PoliceModule {
}
