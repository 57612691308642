import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BootstrapGrowlService} from 'app/shared/ui/ngx-bootstrap-growl/index';
import {SettingsService} from '../../../shared/service/settings.service';
import {PrintLayoutConfigurationDTO} from 'app/settings/model/print-layout-configuration-dto.model';
import {NgForm} from '@angular/forms';
import {BootstrapAlertType} from 'app/shared/ui/ngx-bootstrap-growl/bootstrap-alert-type.enum';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  templateUrl: './settings.printlayout.component.html',
  styleUrls: [
    '../../settings.scss'
  ],
  selector: 'lc-settings-printlayout'
})
export class SettingsPrintlayoutComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  @ViewChild('f') public form: NgForm;

  isLoading: boolean;
  printLayout: PrintLayoutConfigurationDTO = new PrintLayoutConfigurationDTO();

  constructor(private settingsService: SettingsService,
              private bootstrapGrowlService: BootstrapGrowlService) {
  }

  ngOnInit(): void {
    this.reset();
  }

  reset(): void {
    this.isLoading = true;
    this.settingsService.getPrintLayout()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(printLayout => {
        this.printLayout = printLayout;
        this.isLoading = false;
      });
  }

  savePrintLayout(): void {
    this.isLoading = true;
    this.settingsService.savePrintLayout(this.printLayout)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(printLayout => {
        this.printLayout = printLayout;
        this.isLoading = false;
        this.bootstrapGrowlService.addAlert('Udskriftlayout opdateret', BootstrapAlertType.SUCCESS, 3000);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
