import {Component, Input, OnInit} from '@angular/core';
import {ClientStateDetails} from '../../../../model/client-state-details.model';
import {UiDraftComponentClientStateInformation} from '../../ui-draft-component-client-state-information.model';
import {RapportType} from '../../../../../shared/model/rapport-type.model';
import {StringUtils} from '../../../../../shared/utils/string-utils';
import {ReportCategory} from '../../../../../shared/model/report-category.model';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lc-licenseplate',
  templateUrl: './draft-licenseplate.component.html',
  styleUrls: [
    '../../../draft-common.scss'
  ]
})
export class DraftLicenseplateComponent implements OnInit {
  private _originalRegNr: string;
  private _originalFirstRegDate: NgbDateStruct;

  @Input() details: ClientStateDetails;
  uiConfig: LicensePlateUIConfig;

  ngOnInit(): void {
    this.uiConfig = new LicensePlateUIConfig(this.details);
  }

  onLicensPlateChanged(): void {
    if (this.details.vehicle.nummerpladeType === ' ') {
      if (this.details.ansvarsskade) {
        this._originalRegNr = this.details.skadelidte.regNr;
        this.details.skadelidte.regNr = null;
      } else {
        this._originalRegNr = this.details.forsikringstager.regNr;
        this.details.forsikringstager.regNr = null;
      }
      this._originalFirstRegDate = this.details.vehicle.foersteRegDato
      this.details.vehicle.foersteRegDato = null;
    } else {
      if (!!this._originalRegNr) {
        if (this.details.ansvarsskade) {
          this.details.skadelidte.regNr = this._originalRegNr;
        } else {
          this.details.forsikringstager.regNr = this._originalRegNr;
        }
        this._originalRegNr = null;
      }
      if (!!this._originalFirstRegDate) {
        this.details.vehicle.foersteRegDato = this._originalFirstRegDate;
        this._originalFirstRegDate = null
      }
    }
  }
}

export class LicensePlateUIConfig {
  clientStateInfo: UiDraftComponentClientStateInformation;
  clientState: ClientStateDetails;

  constructor(clientState: ClientStateDetails) {
    this.clientStateInfo = new UiDraftComponentClientStateInformation(clientState);
    this.clientState = clientState;
  }

  private isAutotaks(): boolean {
    return ReportCategory.AUTOTAKS.equals(this.clientStateInfo.reportType) || ReportCategory.AUTOTAKS_LORRY.equals(this.clientStateInfo.reportType);
  }

  public hideNoLicensePlateChoice(): boolean {
    //if it is an autotaks T or S report, it is not allowed to select "no license plate" in case the draft already contains a regNr.
    const isT_or_S_report = (this.isAutotaks() && this.clientStateInfo.isOfAny([RapportType.S, RapportType.T]));
    const emptyLicensePlate = this.clientState.ansvarsskade ? StringUtils.isEmpty(this.clientState.skadelidte.regNr) : StringUtils.isEmpty(this.clientState.forsikringstager.regNr);
    return isT_or_S_report && !emptyLicensePlate;
  }
}
