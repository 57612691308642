import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ReportDiffViewModel} from './report-diff-view.model';
import {ActivatedRoute} from '@angular/router';
import {ReportService} from '../service/report.service';
import {Title} from '@angular/platform-browser';
import {ClientNavigationService} from '../../shared/service/client-navigation.service';
import {PrintDiffViewerComponent} from '../../shared/ui/print-diff-viewer/print-diff-viewer.component';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  templateUrl: './report-diff.component.html',
  styleUrls: ['./report-diff.scss'],
  providers: [ReportService],
})
export class ReportDiffComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  viewmodel: ReportDiffViewModel;
  @ViewChild('diff') diffView: PrintDiffViewerComponent;

  constructor(private reportService: ReportService,
              private route: ActivatedRoute,
              private navigation: ClientNavigationService,
              public titleService: Title) {
  }

  ngOnInit(): void {
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe(params => {
      this.viewmodel = new ReportDiffViewModel(this.reportService, params.vkWidePk, params.taksatorWidePk);
      this.viewmodel.navigateBackCallback = () => {
        this.navigation.back(['/report'])
      };
      this.viewmodel.changeActiveCallback = () => {
        this.diffView.switchActivated();
      };
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
