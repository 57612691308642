import {Component, Input} from '@angular/core';
import {VkStam} from '../../model/vk-stam-model';
import {UserDTO} from '../../dto/user-dto.model';

@Component({
  selector: 'lc-vk-info',
  templateUrl: './vk-info.component.html',
  styleUrls: []

})
export class VkInfoComponent {
  @Input() vkstam: VkStam;
  @Input() responsibleWorkshopUser: UserDTO;
}
