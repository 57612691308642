<fieldset ngForm="f" #f="ngForm">
    <h2>Erstatningsmodtager</h2>
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-12">
                    <div class="row mb-2">
                        <div class="col-12">Bankoplysninger</div>
                    </div>
                    <div class="row pb-3">
                        <label class="col-form-label col-md-12 col-lg-2" for="regNr">Regnr</label>
                        <div class="col-md-12 col-lg-3">
                            <input [(ngModel)]="details.erReportDetails.bankRegnr" class="form-control" type="text"
                                id="regNr" name="regNr" minlength=4 maxlength=4 size="4" lcInputMask="int4"
                                [required]="details.erReportDetails.isBankRequired()"
                                [disabled]="details.erReportDetails.nemkonto" />
                        </div>
                        <label class="col-form-label col-md-12 col-lg-2" for="regNr">Kontonr</label>
                        <div class="col-md-12 col-lg-5">
                            <input [(ngModel)]="details.erReportDetails.bankKontonr" class="form-control" type="text"
                                id="kontonr" name="kontonr" minlength=0 maxlength=10 size="10"
                                [required]="details.erReportDetails.isBankRequired()" lcInputMask="numbersOnly"
                                [disabled]="details.erReportDetails.nemkonto" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="pull-right">
                                <label class="form-check-label pe-2" for="nemkonto">Nemkonto</label>
                                <input class="form-check-input" type="checkbox"
                                        [(ngModel)]="details.erReportDetails.nemkonto" id="nemkonto" name="nemkonto"
                                        (change)="details.erReportDetails.validate()">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fieldset>
