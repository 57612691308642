import {AppRole} from '../service/auth/app-roles.model';

export default class EnvironmentUtils {
  private static EAP_PATH = '/eap/';
  private static HOST_NAME: string = location ? location.hostname || location.host : '';
  private static PATH_NAME: string = location ? location.pathname : '';
  private static PORT: string = location ? location.port : '';

  public static getEapPath(): string {
    return this.EAP_PATH;
  }

  public static isEap(): boolean {
    return this.PATH_NAME.startsWith(this.EAP_PATH);
  }

  public static isTest(): boolean {
    return this.HOST_NAME.startsWith('test.');
  }

  public static isDev(): boolean {
    return this.HOST_NAME.startsWith('dev.');
  }

  public static isDevelopment(): boolean {
    return this.HOST_NAME.startsWith('localhost') || this.PORT === '4300';
  }

  public static isProduction(): boolean {
    return !this.isDevelopment() && !this.isDev() && !this.isTest();
  }

  public static hostnameStartsWithWWWW(): boolean {
    return this.HOST_NAME.startsWith('www.')
  }

  public static getStatisticRoles(): string[] {
    return [AppRole.PBI_STATISTIC_USER, AppRole.VK, AppRole.SYSADMIN];
  }

  /**
   * Will create a key unique for the current running environment (prod/, prod/eap, test/, test/eap and dev)
   *
   * @param key
   */
  public static getEnvironmentKey(key: string): string {
    const env = EnvironmentUtils.isProduction() ? '_prod' : (EnvironmentUtils.isTest() ? '_test' : '_dev');
    const postfix = EnvironmentUtils.isEap() ? '_eap' : '';
    return `${key}${env}${postfix}`
  }

  public static isSupportEnabled(): boolean {
    return EnvironmentUtils.isProduction() || EnvironmentUtils.isDevelopment();
  }

  public static getSupportUrl(): string {
    return 'https://itsupport.fogp.dk/app/submit-ticket?entityTypeId=281&ticketTypeId=108';
  }

  public static getLinkedinUrl(): string {
    return 'https://linkedin.com/company/autotaks';
  }

  public static getVideoGalleryUrl(): string {
    return 'https://vimeo.com/autotaks';
  }

  public static getAutotaksUrl(): string {
    return 'https://fogp.dk/brancheloesninger/autotaks';
  }
}
