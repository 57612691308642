import {OpDetailsDTO} from '../../shared/dto/op-details-dto.model';
import {ErGbDetails} from './er-details.model';

export class OpDetails extends ErGbDetails {
  registreringsAttest: boolean;
  vatProgramme: VATProgramme;

  static fromDTO(dto: OpDetailsDTO): OpDetails {
    const details = new OpDetails();
    details.selskab = dto.selskab;
    details.bud1 = dto.bud1;
    details.bud2 = dto.bud2;
    details.bud3 = dto.bud3;
    details.budgiver1 = dto.budgiver1;
    details.budgiver2 = dto.budgiver2;
    details.budgiver3 = dto.budgiver3;
    details.budgiverAdresse1 = dto.budgiverAdresse1;
    details.budgiverAdresse2 = dto.budgiverAdresse2;
    details.budgiverPostNr = dto.budgiverPostNr;
    details.budgiverNavn = dto.budgiverNavn;
    details.budgiverTelefon = dto.budgiverTelefon;
    details.budgiverSolgtTil = dto.budgiverSolgtTil;
    details.fabrikatKode = dto.fabrikatKode;
    details.fabrikatText = dto.fabrikatText;
    details.modelKode = dto.modelKode;
    details.modelText = dto.modelText;
    details.editableFabrikat = dto.editableFabrikat;
    details.editableModel = dto.editableModel;
    details.resterTilhoerSelskab = dto.resterTilhoerSelskab;
    details.registreringsAttest = dto.registreringsAttest;
    details.vatProgramme = dto.vatProgramme;
    return details;
  }

  toDTO(): OpDetailsDTO {
    const dto = new OpDetailsDTO();
    dto.selskab = this.selskab;
    dto.bud1 = this.bud1;
    dto.bud2 = this.bud2;
    dto.bud3 = this.bud3;
    dto.budgiver1 = this.budgiver1;
    dto.budgiver2 = this.budgiver2;
    dto.budgiver3 = this.budgiver3;

    dto.budgiverAdresse1 = this.budgiverAdresse1;
    dto.budgiverAdresse2 = this.budgiverAdresse2;
    dto.budgiverPostNr = this.budgiverPostNr;
    dto.budgiverNavn = this.budgiverNavn;
    dto.budgiverTelefon = this.budgiverTelefon;
    dto.budgiverSolgtTil = this.budgiverSolgtTil;
    dto.fabrikatKode = this.fabrikatKode;
    dto.fabrikatText = this.fabrikatText;
    dto.modelKode = this.modelKode;
    dto.modelText = this.modelText;
    dto.editableFabrikat = this.editableFabrikat;
    dto.editableModel = this.editableModel;
    dto.resterTilhoerSelskab = this.resterTilhoerSelskab;
    dto.registreringsAttest = this.registreringsAttest;
    dto.vatProgramme = this.vatProgramme;
    return dto;
  }
}

export enum VATProgramme {
  NO_DEDUCTION = 'NO_DEDUCTION',
  OLD_SCHEME = 'OLD_SCHEME'
}
