<ng-template #cookiePolicyPopup>
    <div class="modal-header">
        <h3 class="text-truncate">Cookiepolitik</h3>
    </div>
    <div class="modal-body">
        <lc-cookie-text></lc-cookie-text>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary pull-right" (click)="acceptCookies()">Accepter cookies</button>
    </div>
</ng-template>
