import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DamagesNotePopupComponent} from '../../modals/damages-note-popup/damages-note-popup.component';
import {Observable, Subject} from 'rxjs';
import {DamagesNoteDTO} from '../../dto/damages-note-dto.model';
import {takeUntil} from 'rxjs/operators';
import {RapportType} from '../../model/rapport-type.model';

@Component({
  selector: 'lc-damages-note-table-view',
  templateUrl: 'damages-note-table-view.component.html'
})
export class DamagesNoteTableViewComponent implements OnDestroy {
  private unsubscribe$ = new Subject<void>();
  @Input() damagesNoteObservable: Observable<DamagesNoteDTO>;
  @Input() damagesNoteText;
  @Input() reportType: RapportType;
  @Output() damagesNoteTextUpdatedEmitter = new EventEmitter<string>();

  constructor(private modalService: NgbModal) {
  }

  showDamagesNote(): void {
    this.damagesNoteObservable.pipe(takeUntil(this.unsubscribe$)).subscribe(damageNote => {
      this.damagesNoteText = damageNote.text;
      const modalRef = this.modalService.open(DamagesNotePopupComponent, {size: 'lg', windowClass: 'lc-fullscreen'});
      const componentInstance = modalRef.componentInstance as DamagesNotePopupComponent;
      componentInstance.damagesNoteText = damageNote.text;
      componentInstance.reportType = this.reportType;
      modalRef.result.then((updatedDamagesNoteText: string) => {
        this.damagesNoteTextUpdatedEmitter.emit(updatedDamagesNoteText);
      }).catch(() => {
        // Necessary to avoid "Uncaught error Error: Uncaught (in promise)" even though we do not use the returned value.
      });
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
