import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {FormSaver} from '../../../../../../shared/service/core/form-saver.service';
import {ElDetails} from '../../../../../model/el-details.model';
import {StringUtils} from '../../../../../../shared/utils/string-utils';
import {OpslagService} from '../../../../../../shared/service/opslag.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'lc-el-replacement-receiver',
  templateUrl: './el-replacement-receiver.component.html'
})
export class ElReplacementReceiverComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  @Input() formId: string;
  @ViewChild('f', {static: true}) public cform: NgForm;
  @Input() details: ElDetails;
  by: string;

  constructor(private formSaver: FormSaver,
              private opslagService: OpslagService) {

  }

  ngOnInit(): void {
    this.formSaver.registerForm(this.formId, this.cform);
    this.updateCityByPostalCode();
  }

  updateCityByPostalCode(): void {
    if (StringUtils.isNotEmpty(this.details.erstatningsModtagerPostalCode)) {
      this.opslagService.getPostNr(Number(this.details.erstatningsModtagerPostalCode))
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(postNr => {
          this.by = postNr ? postNr.navn : '';
        }, () => {
          this.by = '';
        });
    } else {
      this.by = '';
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
