<div class="modal-header pre-size header-style" style="text-align: center">
    <h3>
        {{!!nonStandardPosition.id ? 'Rediger' : 'Nyt'}} 1000 nummer
    </h3>
    <div aria-label="Close" data-dismiss="modal" class="btn-close clickable" (click)="cancel()"></div>
</div>

<div class="modal-content">
    <div class="modal-body modal-body-style">
        <form [formGroup]="form" autocomplete="off">
            <div class="col-12">
                <lc-shared-non-standard-position-form
                    [form]="form"
                    [nonStandardPosition]="nonStandardPosition"
                    [repairMethods]="repairMethods">
                </lc-shared-non-standard-position-form>
            </div>
        </form>
    </div>
    <div class="modal-footer ">
            <button class="btn btn-primary me-1" (click)="create()" [disabled]="!form.valid"
                    type="button" aria-hidden="true">
                <i class="fa fa-2x action-icon fa-check"></i>
                <span class="itemText d-none d-md-inline-block">{{!!nonStandardPosition.id ? 'Gem' : 'Opret'}}</span>
            </button>
    </div>
</div>



