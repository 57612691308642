import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IntegrationUserDTO} from '../dto/integration-user-dto.model';
import {AssessorOrganisationIntegrationUserRelationDTO} from '../dto/admin/assessor-organisation/assessor-organisation-integration-user-relation-dto.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private serviceUrl = 'bruger';

  constructor(private http: HttpClient) {
  }

  public getIntegrationUsers(): Observable<IntegrationUserDTO[]> {
    return this.http.get<IntegrationUserDTO[]>(this.serviceUrl + '/integration');
  }

  public getAssessorOrgIntegrationUserRelations(): Observable<AssessorOrganisationIntegrationUserRelationDTO[]> {
    return this.http.get<AssessorOrganisationIntegrationUserRelationDTO[]>('assessor-organisation/integrationuser');
  }
}
