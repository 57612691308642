export class PostboxConfigurationDTO {
  substitutes: SelectedPostboxDTO[] = [];
  commonMailboxes: SelectedPostboxDTO[] = [];

}

export class SelectedPostboxDTO {
  sortOrder: number;
  username: string;
  reportsWaitingCount: number;
  active: boolean;
  preferredPostalCodeFrom: string;
  preferredPostalCodeTo: string;

  constructor(sortOrder: number) {
    this.sortOrder = sortOrder;
  }
}
