import {Injectable} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {CopyDraftModalComponent} from '../list/ui/copydraft/copy-draft.component';

@Injectable()
export class CopyDraftModalService {
  private isOpen = false;

  constructor(private modalService: NgbModal) {
  }

  open(token: string, reportType?: string): NgbModalRef {
    if (this.isOpen) {
      return;
    }
    this.isOpen = true;
    const modalRef = this.modalService.open(CopyDraftModalComponent, {
      size: 'sm'
    });
    modalRef.result.then((result) => {
      this.isOpen = false;
    }, (reason) => {
      this.isOpen = false;
    });

    modalRef.componentInstance.open(token, reportType);
    return modalRef;
  }
}
