<div class="w-100" *ngIf="!isLoading; else spinner">
    <lc-linked-report-list
        [potentialLinkedReportTypes]="potentialLinkedReportTypes"
        [reportKey]="taksatorClientState.taksRapportKey"
        [disableButtons]="ongoingRequest"
        (potentialLinkedReportSelectedEmitter)="handlePotentialLinkedReportSelected($event)">
    </lc-linked-report-list>

    <div class="col-12 alert alert-warning mb-0" role="alert" *ngIf="!error && !calculated">
        <i class="fa fa-2x fa-exclamation-circle"></i><span>Der mangler beregningsgrundlag for at kunne afgøre alle nye mulige kladdetyper</span>
    </div>
</div>

<ng-template #spinner>
    <div class="text-center">
        <i class="fa fa-circle-o-notch fa-spin fop-page-spinner"></i>
    </div>
</ng-template>
