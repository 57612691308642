    <div id="breadcrumb-position" class="p-0 position-fixed breadcrumb-wrapper" [class.notification-active]="notificationService.onNotificationChangedSub | async">
        <ol class="breadcrumb m-0 pt-2 pb-2 ps-0">
            <li *ngFor="let breadcrumb of breadcrumbs" class="breadcrumb-item active">
                <a [routerLink]="[breadcrumb.url]">{{ breadcrumb.label }}</a>
            </li>
        </ol>
    </div>
    <div class="breadcrumb-fixed-position-placeholder">

    </div>
