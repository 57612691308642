import {Injectable} from '@angular/core';
import {DraftListConfig} from '../../draft/model/draft-list-config';
import {ReportListConfig} from '../../report/model/report-list.config';
import {OfferListConfig} from '../../offer/model/offer-list.config';
import {PrintViewerConfig} from '../ui/print-viewer/print-viewer-config.model';
import {OfferConfig} from '../../offer/model/offer.config';
import {MessageListConfig} from '../../message/model/message-list.config';
import {SearchType, SearchTypes} from '../ui/search/search-types.model';
import {SearchTypeEnum} from '../ui/search/search-type.enum';
import {AssessorCasesSearchProps} from '../ui/search/assessor-cases-search-props.model';
import {AdminPostboxSurveillanceWatchlistModel} from '../model/admin-postbox-surveillance-watchlist.model';
import {isNullOrUndefined} from '../utils/object-utils';
import {AudatexAggregateSettlementPeriodType} from '../dto/audatex-aggregate-settlement-period-type.model';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private static LAST_LOGGED_IN_USERNAME_KEY = 'loggedInUsername';
  private static PREVIOUS_URL_KEY = 'previousUrl';
  private static PROVIDER_DETAILS_KEY_PREFIX = '-providerInfo';
  private static DRAFT_LIST_CONFIG_KEY = 'draftListConfig';
  private static REPORT_LIST_CONFIG_KEY = 'reportListConfig';
  private static OFFER_LIST_CONFIG_KEY = 'offerListConfig';
  private static OFFER_CONFIG_KEY = 'offerConfig';
  private static PRINT_VIEWER_CONFIG = 'printViewerConfig';
  private static SYSADMIN_QAPTER_XRECORDS = 'sysadminQapterXRecords';
  private static MESSAGE_LIST_CONFIG_KEY = 'messageListConfig';
  private static ADMIN_POSTBOX_SURVEILLANCE_WATCHLIST_KEY = 'adminPostboxSurveillanceWatchlist';
  private static AUDATEX_USAGE_PERIOD_KEY = 'audatexUsagePeriod';

  // Local to tabs / windows
  private sessionStorage: Storage = sessionStorage;
  // Global in browser
  private localStorage: Storage = localStorage;

  constructor() {
  }

  public clearAllSesionStorage(): void {
    this.sessionStorage.clear();
  }

  private getFromSessionStorage(key: string): string | null {
    const value = this.sessionStorage.getItem(key);
    return value != null ? value : null;
  }

  private setInSessionStorage(key: string, value: string): void {
    this.sessionStorage.setItem(key, value);
  }

  private clearFromSessionStorage(key: string): void {
    this.sessionStorage.removeItem(key);
  }

  public getFromLocalStorage(key: string): string | null {
    const value = this.localStorage.getItem(key);
    return value != null ? value : null;
  }

  public setInLocalStorage(key: string, value: string): void {
    this.localStorage.setItem(key, value);
  }

  public clearFromLocalStorage(key: string): void {
    this.localStorage.removeItem(key);
  }

  storeUrl(url: string): void {
    this.localStorage.setItem(StorageService.PREVIOUS_URL_KEY, url);
  }

  clearUrl(): void {
    this.localStorage.removeItem(StorageService.PREVIOUS_URL_KEY);
  }

  getUrl(): string {
    return this.localStorage.getItem(StorageService.PREVIOUS_URL_KEY);
  }

  storeProviderDetails(username: string, providerInfoText: string): void {
    //store locally as it has to survive browser/tab closing
    this.setInLocalStorage(username + StorageService.PROVIDER_DETAILS_KEY_PREFIX, providerInfoText);
  }

  getProviderDetails(username: string): string {
    return this.getFromLocalStorage(username + StorageService.PROVIDER_DETAILS_KEY_PREFIX);
  }

  setLastLoggedInUsername(username: string): void {
    //store locally as it has to survive browser/tab closing
    this.setInLocalStorage(StorageService.LAST_LOGGED_IN_USERNAME_KEY, username);
  }

  getLastLoggedInUsername(): string {
    return this.getFromLocalStorage(StorageService.LAST_LOGGED_IN_USERNAME_KEY);
  }

  storeDraftListConfig(draftListConfig: DraftListConfig): void {
    const json = JSON.stringify(draftListConfig);
    this.setInLocalStorage(this.getUserSpecificKey(StorageService.DRAFT_LIST_CONFIG_KEY), json);
  }

  clearDraftListConfig(): void {
    this.clearFromLocalStorage(this.getUserSpecificKey(StorageService.DRAFT_LIST_CONFIG_KEY));
  }

  getDraftListConfig(): DraftListConfig | null {
    const json = this.getFromLocalStorage(this.getUserSpecificKey(StorageService.DRAFT_LIST_CONFIG_KEY));
    return isNullOrUndefined(json) ? null : JSON.parse(json);
  }

  storeReportListConfig(reportListConfig: ReportListConfig): void {
    const json = JSON.stringify(reportListConfig);
    this.setInLocalStorage(this.getUserSpecificKey(StorageService.REPORT_LIST_CONFIG_KEY), json);
  }

  clearReportListConfig(): void {
    this.clearFromLocalStorage(this.getUserSpecificKey(StorageService.REPORT_LIST_CONFIG_KEY));
  }

  getReportListConfig(): ReportListConfig | null {
    const json = this.getFromLocalStorage(this.getUserSpecificKey(StorageService.REPORT_LIST_CONFIG_KEY));
    return isNullOrUndefined(json) ? null : JSON.parse(json);
  }

  storeOfferListConfig(offerListConfig: OfferListConfig): void {
    const json = JSON.stringify(offerListConfig);
    this.setInLocalStorage(this.getUserSpecificKey(StorageService.OFFER_LIST_CONFIG_KEY), json);
  }

  clearOfferListConfig(): void {
    this.clearFromLocalStorage(this.getUserSpecificKey(StorageService.OFFER_LIST_CONFIG_KEY));
  }

  getOfferListConfig(): OfferListConfig | null {
    const json = this.getFromLocalStorage(this.getUserSpecificKey(StorageService.OFFER_LIST_CONFIG_KEY));
    return isNullOrUndefined(json) ? null : JSON.parse(json);
  }

  getOfferConfig(): OfferConfig | null {
    const json = this.getFromLocalStorage(this.getUserSpecificKey(StorageService.OFFER_CONFIG_KEY));
    return isNullOrUndefined(json) ? null : JSON.parse(json);
  }

  storeOfferConfig(offerConfig: OfferConfig): void {
    const json = JSON.stringify(offerConfig);
    this.setInLocalStorage(this.getUserSpecificKey(StorageService.OFFER_CONFIG_KEY), json);
  }

  getSysadminQapterXRecords(): string | null {
    return this.getFromLocalStorage(this.getUserSpecificKey(StorageService.SYSADMIN_QAPTER_XRECORDS));
  }

  setSysadminQapterXRecords(xRecords: string): void {
    this.setInLocalStorage(this.getUserSpecificKey(StorageService.SYSADMIN_QAPTER_XRECORDS), xRecords);
  }

  /**
   * Assume that LastLoggedInUsername is always present when this is used.
   */
  private getUserSpecificKey(key: string): string {
    return this.getLastLoggedInUsername() + '-' + key;
  }

  getPrintViewerConfig(): PrintViewerConfig | null {
    const json = this.getFromLocalStorage(this.getUserSpecificKey(StorageService.PRINT_VIEWER_CONFIG));
    return isNullOrUndefined(json) ? null : JSON.parse(json);
  }

  storePrintViewerConfig(printViewerConfig: PrintViewerConfig): void {
    const json = JSON.stringify(printViewerConfig);
    this.setInLocalStorage(this.getUserSpecificKey(StorageService.PRINT_VIEWER_CONFIG), json);
  }

  public getSearchConfig(searchType: SearchType): any {
    const json = this.getFromSessionStorage(this.getUserSpecificKey(searchType.getCode()));
    if (isNullOrUndefined(json)) {
      return null;
    }
    switch (searchType.getCode()) {
      case SearchTypeEnum.RapportNr:
      case SearchTypeEnum.VKNr:
      case SearchTypeEnum.Regnr:
      case SearchTypeEnum.Stelnummer:
      case SearchTypeEnum.Skadenummer:
        return JSON.parse(json);
      case SearchTypeEnum.AssessorsCases:
        return JSON.parse(json) as AssessorCasesSearchProps;
    }
  }

  public setSearchConfig(searchType: SearchType, searchObj: any): void {
    const json = JSON.stringify(searchObj);
    this.resetSearchConfig();
    this.setInSessionStorage(this.getUserSpecificKey(searchType.getCode()), json);
  }

  public resetSearchConfig(): void {
    const searchTypes = new SearchTypes();
    searchTypes.getTypes().forEach(type => this.clearFromSessionStorage(this.getUserSpecificKey(type.getCode())));
  }

  clearMessageListConfig(): void {
    this.clearFromLocalStorage(this.getUserSpecificKey(StorageService.MESSAGE_LIST_CONFIG_KEY));
  }

  getMessageListConfig(): MessageListConfig | null {
    const json = this.getFromLocalStorage(this.getUserSpecificKey(StorageService.MESSAGE_LIST_CONFIG_KEY));
    return isNullOrUndefined(json) ? null : JSON.parse(json);
  }

  storeMessageListConfig(messageListConfig: MessageListConfig): void {
    const json = JSON.stringify(messageListConfig);
    this.setInLocalStorage(this.getUserSpecificKey(StorageService.MESSAGE_LIST_CONFIG_KEY), json);
  }

  getAdminSurveillanceWatchlist(assessorOrg: string): AdminPostboxSurveillanceWatchlistModel[] | null {
    const json = this.getFromLocalStorage(this.getUserSpecificKey(assessorOrg + '-' + StorageService.ADMIN_POSTBOX_SURVEILLANCE_WATCHLIST_KEY));
    return isNullOrUndefined(json) ? [] : JSON.parse(json);
  }

  setAdminSurveillanceWatchlist(assessorOrg: string, watchlist: AdminPostboxSurveillanceWatchlistModel[]): void {
    const json = JSON.stringify(watchlist);
    this.setInLocalStorage(this.getUserSpecificKey(assessorOrg + '-' + StorageService.ADMIN_POSTBOX_SURVEILLANCE_WATCHLIST_KEY), json);
  }

  getAudatexUsagePeriod(): AudatexAggregateSettlementPeriodType | null {
    const json = this.getFromLocalStorage(this.getUserSpecificKey(StorageService.AUDATEX_USAGE_PERIOD_KEY));
    return isNullOrUndefined(json) ? null : JSON.parse(json);
  }

  setAudatexUsagePeriod(period: AudatexAggregateSettlementPeriodType): void {
    const json = JSON.stringify(period);
    this.setInLocalStorage(this.getUserSpecificKey(StorageService.AUDATEX_USAGE_PERIOD_KEY), json);
  }

  getAllowCopyPrintAsText(): boolean {
    return true;
  }

  public setObject(key: string, object: object): void {
    const json = JSON.stringify(object);
    this.setInLocalStorage(this.getUserSpecificKey(key), json);
  }

  public getObject(key: string): object | null {
    const json = this.getFromLocalStorage(this.getUserSpecificKey(key));
    return isNullOrUndefined(json) ? null : JSON.parse(json);
  }
}
