import {Injectable} from '@angular/core';
import {NgbDate, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

/**
 * Service methods are possible to call directly from html templates, hence neat date methods
 * can be put here
 */
@Injectable({
  providedIn: 'root',
})
export class DateService {

  public todayNgbDateStruct(): NgbDateStruct {
    const today = new Date();
    return {year: today.getFullYear(), month: (today.getMonth() + 1), day: today.getDate()};
  }

  public todayNgbDate(): NgbDate {
    const today = new Date();
    return new NgbDate(today.getFullYear(), today.getMonth() + 1, today.getDate());
    //return {year: today.getFullYear(), month: (today.getMonth() + 1), day: today.getDate()};
  }
}
