<div class="row mb-4">
    <div class="col">
        <div class="datatable-actions">
            <div class="position-absolute end-0 d-flex">
                <div class="mt-1 datatable-action" 
                    tooltipClass="text-start "
                    ngbTooltip="{{getTooltip()}}">
                    <i class="fa fa-question-circle-o fa-2x"></i>
                </div>
                <div class="datatable-action clickable m-0 mt-1" (click)="refresh()">
                    <i class="ms-3 fa fa-refresh fa-2x"
                       [ngClass]="{'fa-spin': spinnerUtil.isSpinning, 'fa-spin-stop-ie-hack': !spinnerUtil.isSpinning}"></i>
                </div>
            </div>
        </div>

        <div class="datatable-animated" [ngClass]="spinnerUtil.isLoading ? 'datatable-loading' : 'datatable-ready'">
            <table datatable id="provided-non-standard-positions" [dtOptions]="dataTable.dtOptions" [dtTrigger]="dataTable.dtTrigger" class="table table-hover w-100"></table>
        </div>
            
    </div>
</div>
<ng-template #createdAt let-nonStandardPosition="adtData">{{nonStandardPosition.createdAt | date: 'dd-MM-yyyy HH:mm:ss'}}</ng-template>
<ng-template #repairMethod let-nonStandardPosition="adtData">{{getRepairMethodDescription(nonStandardPosition.repairMethod)}}</ng-template>
<ng-template #typeTranslate let-nonStandardPosition="adtData">{{getNonStandardPositionTypeText(nonStandardPosition.type)}}</ng-template>
