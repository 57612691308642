import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ClientStateDetails} from '../../../../model/client-state-details.model';
import {FormSaver} from '../../../../../shared/service/core/form-saver.service';
import {NgForm} from '@angular/forms';
import {Validatable} from '../../../ui/validatable.interface';
import {OpslagService} from '../../../../../shared/service/opslag.service';
import {PostNrDTO} from '../../../../../shared/dto/postnr-dto.model';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ClientStatePolicyDTO} from '../../../../../shared/model/client-state-policy-dto.model';

@Component({
  selector: 'lc-leasing-company',
  templateUrl: './leasing-company.component.html'
})
export class LeasingCompanyComponent implements Validatable, OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  @ViewChild('f', {static: true}) public cform: NgForm;
  @Input() formId: string;
  @Input() details: ClientStateDetails;
  postNr: PostNrDTO;

  private dummyPolice = ClientStatePolicyDTO.empty();

  constructor(protected formSaver: FormSaver, private opslagService: OpslagService) {
  }

  get police(): ClientStatePolicyDTO {
    if (this.details.police) {
      return this.details.police;
    } else {
      return this.dummyPolice;
    }
  }

  ngOnInit(): void {
    this.formSaver.registerForm(this.formId, this.cform);
    this.postNrChanged();
  }

  postNrChanged(): void {
    if (this.postNr) {
      this.postNr.navn = '';
    }
    if (this.details.leasingselskab.postNr) {
      this.opslagService.getPostNr(Number(this.details.leasingselskab.postNr))
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(postNr => {
          this.postNr = postNr;
          this.details.leasingselskab.by = postNr.navn;
        });
    }
  }

  isValid(): boolean {
    return this.cform.form.valid;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
