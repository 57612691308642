import {Observable, of, timer} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpRequest} from '@angular/common/http';
import {catchError, mergeMap, switchMap, tap} from 'rxjs/operators';
import {OAuthDebug, OAuthDebugLevel} from './o-auth-debug.service';
import {isNullOrUndefined} from '../../utils/object-utils';
import {OAuthStorageService} from './o-auth-storage.service';
import {OAuthApiService} from './o-auth-api.service';
import {OAuthRefreshMutexService} from './o.auth-refresh-mutex.service';
import {OAuthAuthenticationService} from './o-auth-authentication-service';

/**
 * Responsible for refreshing any expired access_tokens.
 * Provided with an intercepted request, the service can coordinate logic reqarding concurrent requests and renewal of access_tokens in case
 * they are expired.
 */
@Injectable({
  providedIn: 'root'
})
export class OAuthRefreshTokenService {

  //we will wait a total of 3 seconds in a busy wait
  private static readonly MAX_BUSY_WAIT_RETRIES = 30;
  private static readonly BUSY_WAIT_BETWEEN_CHECK_TIME = 100;

  // to prevent possible concurrency issues right around access_token expiration we will always mark
  // an access_token as expired a bit before it actually is and trigger a renewal next time the access token is tried to be used
  private static readonly ACCESS_TOKEN_RENEWAL_BUFFER_SEC = 10;

  constructor(private oAuthRefreshMutexService: OAuthRefreshMutexService,
              private oAuthCredentialsService: OAuthStorageService,
              private oAuthAuthenticationService: OAuthAuthenticationService,
              private oAuthApiService: OAuthApiService) {
  }

  public handleRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isAccessTokenExpired()) {
      OAuthDebug.log(OAuthDebugLevel.DEBUG, 'Access token is not expired - just continue', request.url);
      //everything is good - just use the access_token
      return next.handle(request);
    }
    OAuthDebug.log(OAuthDebugLevel.WARN, 'Token expired. Something heeds to be done', request.url);
    return this.oAuthRefreshMutexService.reserve$().pipe(
      mergeMap(reserved => {
        if (reserved) {
          OAuthDebug.log(OAuthDebugLevel.WARN, 'We are the one to refresh the access_token', request.url);
          return this.refresh(request, next);
        } else {
          OAuthDebug.log(OAuthDebugLevel.WARN, 'Not me to refresh the access_token... busy wait', request.url);
          return this.busyWait(request, next, OAuthRefreshTokenService.MAX_BUSY_WAIT_RETRIES);
        }
      }));
  }

  private busyWait(request: HttpRequest<any>, next: HttpHandler, busyWaitRetries: number): Observable<HttpEvent<any>> {
    return timer(OAuthRefreshTokenService.BUSY_WAIT_BETWEEN_CHECK_TIME).pipe(
      mergeMap(() => {
        if (!this.isAccessTokenExpired()) {
          OAuthDebug.log(OAuthDebugLevel.WARN, 'Finished waiting as access_token was refresh - execute next.handle', request.url);
          return next.handle(request);
        } else {
          if (busyWaitRetries > 0) {
            return this.busyWait(request, next, busyWaitRetries - 1);
          } else {
            OAuthDebug.log(OAuthDebugLevel.WARN, 'Finished waiting BUT access_token was not refreshed within max wait time (probably because mutex holder failed to refresh) - execute next.handle', request.url);
            return next.handle(request);
          }
        }
      }));
  }

  private refresh(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    OAuthDebug.log(OAuthDebugLevel.INFO, 'prepare refresh', request.url);
    return this.refreshToken$().pipe(
      switchMap(() => {
        OAuthDebug.log(OAuthDebugLevel.WARN, 'Refreshed - execute next.handle', request.url);
        return next.handle(request);
      })
    )
  }

  private refreshToken$(): Observable<number> {
    return this.oAuthApiService.refreshAccessToken().pipe(
      tap(credentials => {
        this.oAuthCredentialsService.setAccessTokenExpiration(credentials);
      }),
      mergeMap(() => of<number>(this.oAuthCredentialsService.getAccessTokenExpiration())),
      tap(() => {
        this.oAuthRefreshMutexService.release();
      }),
      catchError(err => {
        this.oAuthRefreshMutexService.release();
        // in case we get an error on refresh token we clear the current authentication (principal etc.) and returns a zero expiration time
        // to force the original request
        return this.oAuthAuthenticationService.removeAuthentication().pipe(
          mergeMap(() => of(0)));
      }));
  }

  private isAccessTokenExpired(): boolean {
    const accessTokenExpiration = this.oAuthCredentialsService.getAccessTokenExpiration();
    if (isNullOrUndefined(accessTokenExpiration)) {
      return true;
    } else {
      const currentTimestamp = new Date().getTime();
      return currentTimestamp >= accessTokenExpiration - (OAuthRefreshTokenService.ACCESS_TOKEN_RENEWAL_BUFFER_SEC * 1000);
    }
  }
}
