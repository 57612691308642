import {Injectable} from '@angular/core';
import {PrintInputDTO} from '../dto/prepare-print-input-dto.model';
import URLBuilder from '../utils/url-builder';

@Injectable({
  providedIn: 'root',
})
export class PrintService {
  private urlBuilder = new URLBuilder();
  private prepareReportUrl = 'report/{REPORT_KEY}/print';
  private prepareSagUrl = 'report/{REPORT_KEY}/sag/print';
  private printDraftUrl = 'clientstate/{TOKEN}/print';

  public getReportPrintPdfUrl(reportKey: string, input: PrintInputDTO): string {
    return this.urlBuilder.getBaseApiUrl() + this.prepareReportUrl.replace(/{REPORT_KEY}/gi, reportKey) + '?' + input.toHttpParams().toString();
  }

  public getDraftPrintPdfUrl(token: string, input: PrintInputDTO): string {
    return this.urlBuilder.getBaseApiUrl() + this.printDraftUrl.replace(/{TOKEN}/gi, token) + '?' + input.toHttpParams().toString();
  }

  public getReportSagPrintPdfUrl(reportKey: string, input: PrintInputDTO): string {
    return this.urlBuilder.getBaseApiUrl() + this.prepareSagUrl.replace(/{REPORT_KEY}/gi, reportKey) + '?' + input.toHttpParams().toString();
  }
}
