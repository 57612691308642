import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'dotText'
})
export class DotTextPipe implements PipeTransform {
  transform(value: string, limit?: number, postFix: string = '...'): string {
    if (!value) {
      return value;
    }
    if (!limit) {
      return value;
    }
    return value.length > limit ? value.substr(0, limit - postFix.length) + postFix : value;
  }
}
