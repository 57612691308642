import {Component, OnDestroy, OnInit} from '@angular/core';
import {PrincipalService} from 'app/shared';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AppRoles} from '../shared/service/auth/app-roles.model';
import {isNullOrUndefined} from '../shared/utils/object-utils';

@Component({
  templateUrl: './home.component.html',
  styleUrls: [
    'home.scss'
  ]
})
export class HomeComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  constructor(private principal: PrincipalService,
              private router: Router) {
  }

  ngOnInit(): void {
    // In some places we redirect to other applications. This handles clicking "back" from those.
    this.principal.identity().pipe(takeUntil(this.unsubscribe$))
      .subscribe((principalModel) => {
        if (!isNullOrUndefined(principalModel)) {
          this.redirectLoggedOnUser();
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  redirectLoggedOnUser(navigateTo?: string): void {
    if (navigateTo) {
      this.router.navigate([navigateTo]);
    } else {
      if (this.principal.hasAnyAuthorityDirect(AppRoles.ROLES_TO_START_IN_DRAFT_SECTION)) {
        this.router.navigate(['/draft']);
      } else if (this.principal.hasAnyAuthorityDirect(AppRoles.ROLES_TO_START_IN_PROFILE_TOKEN_SECTION)) {
        this.router.navigate(['profile', 'tokens']);
      } else if (this.principal.hasAnyAuthorityDirect(AppRoles.ROLES_TO_START_IN_ADMINISTRATION_SECTION)) {
        this.router.navigate(['admin']);
      }
    }
  }
}
