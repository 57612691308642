export class CarDetails {
  fabrikatKode: string;
  fabrikatText: string;
  modelKode: string;
  modelText: string;
}

export class Flow2CarDetails extends CarDetails {
  editableFabrikat: string;
  editableModel: string;
  resterTilhoerSelskab: boolean;
}
