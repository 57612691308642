<div class="modal-header">
    <h3 class="text-truncate"><span *ngIf="header">{{header}}</span></h3>
    <div aria-label="Close" data-dismiss="modal" class="btn-close clickable" (click)="dismiss()">
    </div>
</div>
<div class="modal-body">
    <div class="info-box" *ngFor="let info of infoEntries">
        <span *ngIf="info.faIconCssClass" class="info-box-icon">
            <i class="fa {{info.faIconCssClass}}"></i>
        </span>
        <div class="info-box-content">
            <div class="info-box-header">{{info.header}}</div>
            <div class="info-box-body" [innerHTML]="info.htmlMessage">
            </div>
        </div>
    </div>
</div>
