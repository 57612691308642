import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {PrincipalService} from '../../../shared';
import {NavigationEnd, Router} from '@angular/router';
import {RequisitionComponent} from '../../../shared/modals/requisition';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {VERSION} from '../../../app.constants';
import {debounceTime, distinctUntilChanged, filter, map, mergeMap, startWith, takeUntil} from 'rxjs/operators';
import {fromEvent, Observable, of, Subject} from 'rxjs';
import {AccountService} from '../../../shared/service/account.service';
import {NavDynamicDataService} from '../nav-dynamic-data.service';
import {ObjectUtils} from '../../../shared/utils/object-utils';
import {ScreenSize, ScreenSizeService} from '../../../shared/ui/screen-size/screen-size.service';
import {NotificationService} from '../../../shared/service/notification.service';
import {AppRole, AppRoles} from '../../../shared/service/auth/app-roles.model';
import {OAuthAuthenticationService} from '../../../shared/o-auth/forsi/o-auth-authentication-service';
import EnvironmentUtils from '../../../shared/utils/environment-utils';

@Component({
  selector: 'lc-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: [
    'sidebar.component.scss'
  ]
})
export class SidebarComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  @Output() toggleSidebarMenu = new EventEmitter();
  @Input() sidebarHidden = false;
  versionTimestamp = VERSION;

  canToggleSidebar$ = this.screenSizeService.size$.pipe(
    map(ss => ss.lessThan(ScreenSize.XL))
  );

  private activeMenu: string = null;
  private navigateTimer;

  showBusinessToBusiness$: Observable<boolean> = this.principalService.getAuthenticationState().pipe(
    mergeMap(() => this.principalService.isVK() ? this.accountService.getShowBusinessToBusinessMenu() : of(false)));

  userIdentity$: Observable<string> = this.principalService.getAuthenticationState().pipe(
    filter(value => ObjectUtils.exists(value)),
    map(p => `${p.userName} | ${p.organizationName || p.personName}`));

  offerCount$ = this.navDynamicDataService.offerCount$();
  messageCount$ = this.navDynamicDataService.messageCount$();
  incommingClientStateCount$ = this.navDynamicDataService.incommingClientStatesCount$();

  constructor(public principalService: PrincipalService,
              private modalService: NgbModal,
              private navDynamicDataService: NavDynamicDataService,
              private oAuthAuthenticationService: OAuthAuthenticationService,
              private accountService: AccountService,
              private screenSizeService: ScreenSizeService,
              public notificationService: NotificationService,
              private router: Router,
              ) {
  }

  ngOnInit(): void {
    //esc button subscription to close foldable side menu
    fromEvent(document, 'keyup').pipe(
      map((key: KeyboardEvent) => key.key === 'Escape'),
      filter(escapePressed => escapePressed && !this.sidebarHidden && !this.screenSizeService.size.eq(ScreenSize.XL)),
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      this.toggleSidebarMenu.next(null);
    });

    //router changes subscription to toggle correct menu's
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      distinctUntilChanged(),
      map((activatedRoute) => this.router.url),
      startWith(this.router.url),
      debounceTime(100), // small delay to ensure menu navigation and main route content rendering isn't hogging the render thread at the same time
      takeUntil(this.unsubscribe$)
    ).subscribe(url => {
      //this part and the the sidebar.component.html file is a bit dublicated for now.
      //hence if you create a new root menu or submenu, ensure that the routes are added both here and in the html page
      //we have accepted to have this duplication for now
      if (this.activeRootUrl(['create', 'draft', 'police', 'search', 'report', 'offer', 'businesstobusiness', 'message', 'ns-positions'])) {
        this.toggleMenu('menu-damage');
      } else if (this.activeRootUrl(['admin', 'profile'])) {
        this.toggleMenu('menu-admin');
      } else if (this.activeRootUrl('sysadmin')) {
        this.toggleMenu('menu-sysadmin');
      } else if (this.activeRootUrl('statistic')) {
        this.toggleMenu('menu-stats')
      } else if (this.activeRootUrl('b2b')) {
        this.toggleMenu('menu-b2b')
      } else if (this.activeRootUrl('roadmap')) {
        this.toggleMenu('menu-roadmap')
      }
    });
  }

  isCollapsed(menu: string): boolean {
    return this.activeMenu !== menu;
  }

  toggleMenu(menu: string, routerLink?: string): void {
    clearTimeout(this.navigateTimer);
    const mainMenuChanges = this.activeMenu !== menu;
    this.activeMenu = menu;
    if (routerLink) {
      if (mainMenuChanges) {
        //We have added a delay in order to let the menu animation finish before loading the page.
        //Otherwise we see some glitches
        this.navigateTimer = setTimeout(() => {
          this.router.navigate([routerLink])
        }, 300)
      } else {
        this.router.navigate([routerLink]);
      }
    }
  }

  /**
   * Damage registration navigation
   */
  public openRequisitionLookupPopup(): void {
    this.modalService.open(RequisitionComponent, {size: 'xl'});
  }

  public gotoAdminUserTokens(): void {
    const employerCode = this.principalService.getCurrentPrincipal().orgNr;
    this.router.navigate(['admin', 'token', employerCode]);
  }

  errorReportClicked(): void {
    window.open(EnvironmentUtils.getSupportUrl(), '_blank');
  }

  didClickLogout(): void {
    this.toggleSidebarMenu.emit();

    this.oAuthAuthenticationService.removePossibleB2BAuthenticationAndRedirect();
    this.oAuthAuthenticationService.removeAuthentication().pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: () => {
        //in case we see a 'local' user, that wasn´t logout redirected by MSAL
        this.router.navigate(['']);
      }
    })
  }

  getSubMenuStyle(menuDamage: HTMLUListElement): object {
    return {height: ((menuDamage.childElementCount * 37) + 20) + 'px'}
  }

  activeRootUrl(urlParts: string | string[]): boolean {
    const startsWithAnyUrlParts = typeof urlParts === 'string' ? [urlParts as string] : urlParts as string[];
    for (const urlPart of startsWithAnyUrlParts) {
      if (this.activeUrl(urlPart)) {
        return true;
      }
    }
    return false;
  }
  activeUrl(startsWithUrlPart: string, furtherContainsUrlPart: string = ''): boolean {
    const rawActivatedUrl = this.router.url;
    if (ObjectUtils.exists(rawActivatedUrl)) {
      const searchingForURL = startsWithUrlPart.startsWith('/') ? startsWithUrlPart.substr(1) : startsWithUrlPart;
      const activatedUrl = rawActivatedUrl.startsWith('/') ? rawActivatedUrl.substr(1) : rawActivatedUrl;
      const startsWith = activatedUrl.startsWith(searchingForURL);
      let furtherContains = true;
      if (ObjectUtils.exists(furtherContainsUrlPart)) {
        furtherContains = activatedUrl.indexOf(furtherContainsUrlPart) !== -1;
      }
      return startsWith && furtherContains;
    } else {
      return false;
    }
  }

  public hasMyCompanyAccess(): boolean {
    return this.principalService.hasAnyAuthorityDirect([AppRole.REDIRECTADMIN]);
  }

  public hasGeneralAdministrationAccess(): boolean {
    return this.principalService.hasAnyAuthorityDirect(AppRoles.GENERAL_ADMINISTRATION_ACCESS);
  }

  public canAdministrateAutomation(): boolean {
    return this.principalService.hasAllAuthorityDirect(AppRoles.ADMINISTRATE_AUTOMATION);
  }

  public canAdministrateUsers(): boolean {
    return this.principalService.hasAnyAuthorityDirect(AppRoles.ADMINISTRATE_USER);
  }

  public canAdministrateB2BPartners(): boolean {
    return this.principalService.isVK() && this.principalService.hasAnyAuthorityDirect(['ROLE_WorkshopAdmin', 'ROLE_UserAdminVK']);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  showReportError(): boolean {
    return EnvironmentUtils.isSupportEnabled();
  }
}
