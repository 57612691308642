import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UserType} from '../../../shared/dto/user-type.model';
import {ReportAcceptStatus} from '../../model/report-accept-status';

@Component({
  templateUrl: './report-accept-requested-popup.component.html',
  styleUrls: ['report-accept-requested-popup.scss']
})
export class ReportAcceptRequestedPopupComponent {
  @Input() userType: UserType.TAKS | UserType.VK;
  @Input() acceptStatus: ReportAcceptStatus.REQUESTED | ReportAcceptStatus.REJECTED;
  submitting: boolean;

  constructor(private activeModal: NgbActiveModal) {
  }

  public titleIconClass(): string {
    return this.acceptStatus === ReportAcceptStatus.REQUESTED ? 'requested' : 'rejected';
  }

  public title(): string {
    switch (this.userType) {
      case UserType.TAKS:
        return this.acceptStatus === ReportAcceptStatus.REQUESTED ? 'Afventer accept fra værksted' : 'Ændringer er afvist';
      case UserType.VK:
        return this.acceptStatus === ReportAcceptStatus.REQUESTED ? 'Taksator ønsker accept af ændringer' : 'Ændringer er afvist';
    }
  }

  public body(): string {
    switch (this.userType) {
      case UserType.TAKS:
        return this.acceptStatus === ReportAcceptStatus.REQUESTED ?
          'Værkstedet har endnu ikke taget stilling til de ændringer der er foretage til rapporten.' :
          'Værkstedet har afvist ændringerne til rapporten. Se evt. beskeder for årsagen.';
      case UserType.VK:
        return this.acceptStatus === ReportAcceptStatus.REQUESTED ?
          'Taksator ønsker at du tager stilling til de ændringer der er foretaget på rapporten. <br>Benyt knappen "Svar taksator" i bunden af skærmen.' :
          'Bemærk at du tidligere har afvist taksators ændringer til rapporten.'
    }
  }

  public close(): void {
    this.activeModal.dismiss('cancel');
  }

  public didClickRespond(): void {
    console.log('didClickRespond')
  }
}
