import {Injectable} from '@angular/core';
import {Observable, of, timer} from 'rxjs';
import {StorageService} from '../../service/storage.service';
import EnvironmentUtils from '../../utils/environment-utils';
import {isNullOrUndefined} from '../../utils/object-utils';
import {OAuthDebug, OAuthDebugLevel} from './o-auth-debug.service';
import {mergeMap} from 'rxjs/operators';

interface RefreshMutex {
  clientId: string,
  requestId: string,
  timestamp: number
}

/**
 * Primitive mutex to ensure only one thread in any browsertab running forsi is refreshing the access_token
 * (distinct between environments (prod, prod /eap, test, ...)
 * Provided in root to ensure only one instance exists
 */
@Injectable({providedIn: 'root'})
export class OAuthRefreshMutexService {
  public static readonly SECOND_CHECK_MUTEX_DELAY: number = 200; // time to ensure we still are the one having the mutex lock
  public static readonly MAX_MUTEX_RESERVE_TIME: number = 4_000; // time a Mutex can be reserved before it is free for others
  public static readonly MUTEX_KEY = EnvironmentUtils.getEnvironmentKey('autotaks_oauth_mutex');
  public static readonly CLIENT_ID = OAuthRefreshMutexService.getRandomId();

  private static getRandomId(): string {
    const time = new Date().getTime();
    const random = Math.floor(Math.random() * 1000);
    return `${time}_${random}`;
  }

  constructor(private storageService: StorageService) {
  }


  /**
   * Will try to reserve the mutex for this particular client and request
   */
  public reserve$(): Observable<boolean> {
    if (this.isReserved()) {
      return of(false);
    } else {
      const requestId = OAuthRefreshMutexService.getRandomId();
      this.writeMutex({clientId: OAuthRefreshMutexService.CLIENT_ID, requestId: requestId, timestamp: this.currentTimeMillis()})
      return timer(OAuthRefreshMutexService.SECOND_CHECK_MUTEX_DELAY).pipe(
        mergeMap(() => {
          const currentRequestIsReserving = this.isReservedByCurrentClientRequest(requestId);
          return of(currentRequestIsReserving);
        }));
    }
  }

  public release(): void {
    if (this.isReservedByCurrentClient()) {
      OAuthDebug.log(OAuthDebugLevel.DEBUG, `Mutex is RELEASED by current client[${OAuthRefreshMutexService.CLIENT_ID}]`)
      this.writeMutex(null);
    }
  }

  private isReserved(): boolean {
    const activeRefreshMutex = this.readMutex();
    const result = !isNullOrUndefined(activeRefreshMutex);
    if (result) {
      OAuthDebug.log(OAuthDebugLevel.DEBUG, `Mutex is RESERVED by[${activeRefreshMutex.clientId}] requestID[${activeRefreshMutex.requestId}]`)
    }
    return result;
  }

  private isReservedByCurrentClient(): boolean {
    const activeRefreshMutex = this.readMutex();
    const result = !isNullOrUndefined(activeRefreshMutex) && activeRefreshMutex.clientId === OAuthRefreshMutexService.CLIENT_ID;
    if (result) {
      OAuthDebug.log(OAuthDebugLevel.DEBUG, `Mutex is RESERVED by current client[${OAuthRefreshMutexService.CLIENT_ID}]`)
    }
    return result;
  }

  private isReservedByCurrentClientRequest(requestId: string): boolean {
    const activeRefreshMutex = this.readMutex();
    const result = !isNullOrUndefined(activeRefreshMutex)
      && activeRefreshMutex.clientId === OAuthRefreshMutexService.CLIENT_ID
      && activeRefreshMutex.requestId === requestId;
    if (result) {
      OAuthDebug.log(OAuthDebugLevel.DEBUG, `Mutex is RESERVED by current client[${OAuthRefreshMutexService.CLIENT_ID}] and request[${requestId}]`)
    }
    return result;
  }

  private readMutex(): RefreshMutex {
    const mutexJson = this.storageService.getFromLocalStorage(OAuthRefreshMutexService.MUTEX_KEY);
    try {
      if (isNullOrUndefined(mutexJson)) {
        return null;
      } else {
        const mutex = JSON.parse(mutexJson) as RefreshMutex;
        return this.isActive(mutex) ? mutex : null;
      }
    } catch (error) {
      OAuthDebug.log(OAuthDebugLevel.WARN, `Failed to read mutex[${mutexJson}] as ReserveMutex, from local storage. Will return null`, error);
      return null;
    }
  }

  private writeMutex(mutex: RefreshMutex | null): void {
    if (!isNullOrUndefined(mutex)) {
      this.storageService.setInLocalStorage(OAuthRefreshMutexService.MUTEX_KEY, JSON.stringify(mutex));
    } else {
      this.storageService.clearFromLocalStorage(OAuthRefreshMutexService.MUTEX_KEY);
    }
  }

  /**
   * Check to see if mutex is active according to max reserve time
   */
  private isActive(mutex: RefreshMutex): boolean {
    try {
      const aliveMillis = (mutex.timestamp + OAuthRefreshMutexService.MAX_MUTEX_RESERVE_TIME) - this.currentTimeMillis();
      const isAlive = !isNullOrUndefined(mutex) && aliveMillis > 0;
      if (isAlive) {
        OAuthDebug.log(OAuthDebugLevel.DEBUG, `mutex from client[${mutex.clientId}] is active as aliveMillis[${aliveMillis}]`);
      }
      return isAlive;
    } catch (error) {
      OAuthDebug.log(OAuthDebugLevel.ERROR, `Failed to decide if mutex[${mutex}] is alive. Returning false`, error);
      return false;
    }
  }

  private currentTimeMillis(): number {
    return new Date().getTime();
  }
}
