    <div class="modal-header">
        <h4 class="modal-title">
            {{(reportKey) | lcForsiReportKey}}  - <span>{{registrationNumber}}</span>
            <i class="fa fa-camera clickable" (click)="showMetadata(currentGalleryItem.id, currentGalleryItem.accessToken)" title="Se metadata" *ngIf="principal.isTaksator()"></i>
        </h4>
        <div aria-label="Close" data-dismiss="modal" class="btn-close clickable" (click)="closeModal()">
        </div>
    </div>
    <div class="modal-body p-0 gallery-body" [ngClass]="{'gallery-image-original': showOriginalSize}"
         #modalScrollElement>
        <div *ngIf="isImage(currentGalleryItem) && !isHeif(currentGalleryItem)"
             [ngStyle]="getGalleryImageStyle(currentGalleryItem)"
             class="gallery-image-viewer"
             (click)="toggleShowOriginalSize($event)">
            <img *ngIf="showOriginalSize" [src]="getImageUrl(currentGalleryItem, false, false)"
                 (mousemove)="scrollImage($event, modalScrollElement)" (mousedown)="startScroll($event)"
                 (mouseup)="stopScroll($event)">
        </div>

        <div *ngIf="isHeif(currentGalleryItem)" class="gallery-image-viewer no-zooming">
            <div class="card mx-auto mt-5" style="width: 20rem;">
                <div class="card-body">
                    <h5 class="card-title">Kom tilbage om lidt...</h5>
                    <p class="card-text">Vi er i færd med at konvertere billedet til JPG format.</p>
                    <p class="card-text">Har du brug for billedet, før det er klar, kan du downloade originalen.</p>
                    <i class="ms-3 fa fa-refresh fa-2x fa-spin"></i>
                </div>
            </div>
        </div>

        <ng-container *ngIf="isVideo(currentGalleryItem)">
            <div *ngIf="isBeingPostProcessed(currentGalleryItem)" class="gallery-image-viewer no-zooming">
                <div class="card mx-auto mt-5" style="width: 20rem;">
                    <div class="card-body">
                        <h5 class="card-title">Kom tilbage om lidt...</h5>
                        <p class="card-text">Vi er i færd med at konvertere videoen til web format.</p>
                        <p class="card-text">Har du brug for videoen før den er klar, kan du downloade originalen.</p>
                        <i class="ms-3 fa fa-refresh fa-2x fa-spin"></i>
                    </div>
                </div>
            </div>
            <div *ngIf="isPostProcessed(currentGalleryItem)" class="gallery-image-viewer no-zooming">
                <video height="100%" controls  [src]="getImageUrl(currentGalleryItem, false, false)" type="{{currentGalleryItem.contentType}}">
                </video>
            </div>
        </ng-container>
        <div *ngIf="isPdf(currentGalleryItem)" class="gallery-file-viewer">
            <pdf-viewer [src]="getNonImageUrl(currentGalleryItem, false)"
                        [render-text]="true"
                        [autoresize]="true"
                        [original-size]="false"
                        class="w-100 h-100 d-block">
            </pdf-viewer>
        </div>

        <div *ngIf="isPresentableText(currentGalleryItem)" class="gallery-file-viewer">
            <div class="pre-wrap">{{presentableText}}</div>
        </div>

        <div *ngIf="isNotPresentable(currentGalleryItem)" class="gallery-not-presentable-attachment">
            <span class="fa not-presentable-attachment-icon"
                  [ngClass]="getNotPresentableFileClass(currentGalleryItem)"></span>
        </div>

        <div class="gallery-navigation">
            <div class="container-fluid row ms-0 me-0 pt-2 gallery-navigation-title">
                <div class="gallery-navigation-title-content text-center">
                    {{galleryIndex + 1}} / {{galleryItems.length}}
                </div>
            </div>
            <div class="container-fluid row ms-0 me-0 pt-2 gallery-navigation-overlay text-center">
                <div class="col-2 text-start ps-0">
                    <i class="fa fa-2x action-icon fa-angle-left gallery-navigation-button ms-0 me-0"
                       (click)="previousGalleryItem()"
                       [ngClass]="{'gallery-navigation-jump-to-other-end': (galleryIndex === 0)}"></i>
                </div>
                <div class="col-8 text-center">
                    <!-- There is no neat way to tell iOS that is shouldn't autofocus (and popup keyboard) on the first textarea of a modal.   -->
                    <!-- This hack brings focus to the hidden inputfield, and no keyboard is presented.                                        -->
                    <input id="draw-autofocus_attention" hidden>

                    <div class="gallery-filename p-1">{{currentGalleryItem.name}}</div>
                    <textarea [(ngModel)]="currentGalleryItem.remark" id="remarksText"
                              name="remarksText"
                              class="gallery-item-remarks" rows="2"
                              [ngClass]="{'gallery-item-disabled': isReadonly(currentGalleryItem)}"
                              [disabled]="isReadonly(currentGalleryItem)"
                              [placeholder]="isReadonly(currentGalleryItem) ? 'Det er ikke muligt at tilføje en bemærkninger til bilaget' : 'Tilføj bemærkning til bilaget'"
                              maxlength="500">
					</textarea>
                </div>
                <div class="col-2 text-end pe-0">
                    <i class="fa fa-2x action-icon fa-angle-right gallery-navigation-button ms-0 me-0"
                       (click)="nextGalleryItem()"
                       [ngClass]="{'gallery-navigation-jump-to-other-end': (galleryIndex + 1 === galleryItems.length)}"></i>
                </div>
            </div>
        </div>
    </div>
