import {SearchTypeEnum} from './search-type.enum';

export class SearchType {

  constructor(private label: string, private code: SearchTypeEnum) {
  }

  public getLabel(): string {
    return this.label;
  }

  public getCode(): SearchTypeEnum {
    return this.code;
  }
}

export class SearchTypes {
  private types: SearchType[] = [
    new SearchType('Taksatorrapport', SearchTypeEnum.RapportNr),
    new SearchType('Værkstedsrapport', SearchTypeEnum.VKNr),
    new SearchType('Reg.Nr.', SearchTypeEnum.Regnr),
    new SearchType('Stelnummer', SearchTypeEnum.Stelnummer),
    new SearchType('Skadenummer', SearchTypeEnum.Skadenummer),
    new SearchType('Taksators Skader', SearchTypeEnum.AssessorsCases)
  ];

  public getTypes(): SearchType[] {
    return this.types;
  }

  public setTypes(searchTypes: SearchType[]): void {
    this.types = searchTypes;
  }
}
