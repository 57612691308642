import {Component, OnDestroy, OnInit} from '@angular/core';
import {PrincipalDTO} from 'app/shared';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {SettingsService} from '../shared/service/settings.service';
import {RoadmapService} from './service/roadmap.service';
import {RoadmapItemListDTO} from './model/roadmap-Item-list-dto.model';
import {RoadmapItemContentDTO} from './model/roadmap-Item-content-dto.model';
import {RoadmapItemStateEnum} from './model/roadmap-Item-state.enum';
import {TimelineEventType} from '../shared/ui/message-timeline/timeline-message.model';
import {StringUtils} from '../shared/utils/string-utils';

@Component({
  templateUrl: './roadmap.component.html',
  styleUrls: ['./roadmap.scss'],
  providers: [SettingsService]
})
export class RoadmapComponent implements OnDestroy, OnInit {
  private expandedItems = {};
  private unsubscribe$ = new Subject<void>();
  principalModel: PrincipalDTO;
  currentItems: RoadmapItemContentDTO[];
  filteredItems: RoadmapItemContentDTO[];
  currentState: RoadmapItemStateEnum = RoadmapItemStateEnum.NEW;
  filter: string;
  
  constructor(private roadmapService: RoadmapService) {

  }

  ngOnInit(): void {
    this.requestRoadmapItems()
  }


  requestRoadmapItems(): void {
    this.expandedItems = {};
    const states: RoadmapItemStateEnum[] = [this.currentState];
    if (this.currentState === RoadmapItemStateEnum.ACTIVE) {
      states.push(RoadmapItemStateEnum.RESOLVED)
    }
    
    if (states.length > 0) {
      this.roadmapService.getAssessorRoadmap(states).pipe(takeUntil(this.unsubscribe$)).subscribe(items => {
        this.filteredItems = [];
        this.currentItems = [];
        items.forEach((item, index) => {
          this.addWorkItemContent(item, index + 1, items.length)
        })
        if (items.length === 0) {
          this.filteredItems = this.currentItems;
        }
      })
    } else {
      this.currentItems = []
    }
  } 
  
  addWorkItemContent(item: RoadmapItemListDTO, rank: number, total: number): void {
    this.roadmapService.getRoadmapItemContent(item).pipe(takeUntil(this.unsubscribe$)).subscribe(content => {
      content.targetDateObj = StringUtils.isNotEmpty(content.targetDate) ? new Date(content.targetDate) : null;
      content.rank = rank;
      this.currentItems.push(content);
      if (this.currentItems.length === total) {
        if (this.currentState === RoadmapItemStateEnum.CLOSED) {
          this.currentItems.sort((a, b) => Date.parse(b.closedDate) - Date.parse(a.closedDate))
        } else {
          this.currentItems.sort((a, b) => a.rank - b.rank)
        }
        this.filterItems(this.filter);
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  getTimelineEventType(state: string): string {
    switch (state) {
      case RoadmapItemStateEnum.NEW:
        return TimelineEventType.LIGHT;
      case RoadmapItemStateEnum.CLOSED:
        return TimelineEventType.SUCCESS;
      default:
        return TimelineEventType.PRIMARY;
    }
    
  }
  
  filterItems(filter: string): void {
    if (StringUtils.isNotEmpty(filter)) {
      this.filteredItems = this.currentItems.filter(item => item.title.indexOf(filter) > -1 || item.description.indexOf(filter) > -1)
    } else {
      this.filteredItems = this.currentItems;
    }  
  }
  
  getFilteringClass(): string[] {
    return StringUtils.isNotEmpty(this.filter) ? ['list-filtering-active', 'border-primary'] : [];
  }

  onFilteringChanged(filter: string): void {
    this.filter = filter;
    this.filterItems(filter);
  }

  
  hasOverflow(widgetBody: HTMLDivElement): boolean {
    console.log(widgetBody.offsetHeight)
    return widgetBody.offsetHeight >= 100;
  }
  isExpanded(widgetBody: HTMLDivElement): boolean {
    return this.expandedItems[widgetBody.id];
  }
  toggleShowMore(widgetBody: HTMLDivElement): void {
    if (this.expandedItems[widgetBody.id]) {
      this.expandedItems[widgetBody.id] = false;
      widgetBody.className = widgetBody.className.replace(' roadmap-show-all', '');

    } else {
      widgetBody.className = widgetBody.className + ' roadmap-show-all';
      this.expandedItems[widgetBody.id] = true;

    }
  }

  hasTargetDate(item: RoadmapItemContentDTO): boolean {
    return item.targetDateObj != null
  }
  
  getTargetQuater(item: RoadmapItemContentDTO): number {
    if (this.hasTargetDate(item)) {
      return Math.floor((item.targetDateObj.getMonth() + 3) / 3);
    }
    return -1
  }

  getTargetYear(item: RoadmapItemContentDTO): number {
    return this.hasTargetDate(item) ? item.targetDateObj.getFullYear() : -1;
  }
}
