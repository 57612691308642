import {Injectable} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {debounceTime, distinctUntilChanged, filter, map, skip, switchMap} from 'rxjs/operators';
import {SelskabService} from '../../../shared/service/selskab.service';
import {DateUtils} from '../../../shared/utils/date-utils';
import {CompanyDTO} from '../../../shared/dto/company-dto.model';
import {StringUtils} from '../../../shared/utils/string-utils';

/**
 * Service used to keep selected company up to date.
 * Aligned with damagedate and latest reg number.
 */
@Injectable()
export class AutoITCompanyUpdateService {


  constructor(private companyService: SelskabService) {
  }

  /**
   * Will emit when changes to damageDate or registration number occurs.
   */
  public autoITCompanyUpdate$(licensePlateChanged$: Observable<string>, damageDateChanged$: Observable<NgbDateStruct>, initialCompanyCode: string): Observable<CompanyDTO> {

    const licensePlateChanges$ = licensePlateChanged$.pipe(
      filter<string>(Boolean),
      debounceTime(1000), distinctUntilChanged());

    const damageDateChanges$ = damageDateChanged$.pipe(
      map(v => !!!v ? DateUtils.getNgbDateStructToday() : v), // use today if empty
      filter(v => {
        //ensure date is above a sane date, before we request for insurance
        const minMillis = DateUtils.dateFromNgbDateStruct({ year: 1900, month: 1, day: 1 }).getTime();
        const actualMillis = DateUtils.dateFromNgbDateStruct(v).getTime();
        return actualMillis > minMillis;
      }),
      debounceTime(1000), distinctUntilChanged(DateUtils.equalsDateStruct));

    return combineLatest([licensePlateChanges$, damageDateChanges$]).pipe(
      skip(StringUtils.isEmpty(initialCompanyCode) ? 0 : 1),
      switchMap(([licensePlate, damageDate]) => this.companyService.getSuggestedInsuranceCompany(licensePlate, damageDate)),
      filter<CompanyDTO>(Boolean)
    );
  }
}
