import {AfterViewInit, Component, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {CombinedPrintModel} from './combined-print.model';
import {UIUtils} from 'app/shared/utils/ui-utils';
import {isNullOrUndefined} from '../../utils/object-utils';

@Component({
  selector: 'lc-combined-print-viewer',
  templateUrl: './combined-print-viewer.component.html',
  styleUrls: [
    'combined-print-viewer.scss'
  ]
})
export class CombinedPrintViewerComponent implements OnChanges, AfterViewInit {

  @Input() vkPrintModel = CombinedPrintModel.emptyModel();
  @Input() taksPrintModel = CombinedPrintModel.emptyModel();
  @Input() allowPrinting;
  @Output() showPrintClicked: EventEmitter<CombinedPrintModel> = new EventEmitter<CombinedPrintModel>();
  @Output() selectedPrintModel: EventEmitter<CombinedPrintModel> = new EventEmitter<CombinedPrintModel>();

  private _activeTab: CombinedPrintModel;
  private debounce_timer: any;
  private uiUtils = new UIUtils();

  set activeTab(value: CombinedPrintModel) {
    this._activeTab = value;
    this.selectedPrintModel.emit(value);
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    if (this.debounce_timer) {
      window.clearTimeout(this.debounce_timer);
    }
    this.debounce_timer = window.setTimeout(() => {
      this.updateHeaderPosition();
    }, 500);
  }

  ngAfterViewInit(): void {
    this.updateHeaderPosition();
  }

  updateHeaderPosition(): void {
    const topOffsetFromNavBar = this.uiUtils.calculateTopOffsetFromNavBar();
    document.getElementById('print-header').style.top = '' + topOffsetFromNavBar + 'px';
    const printBody = document.getElementById('print-body');
    // calculate whether or not to show the header
    const isVisibleHeight = window.pageYOffset < printBody.scrollHeight;
    document.getElementById('print-header').hidden = !isVisibleHeight;
  }

  ngOnChanges(changes: SimpleChanges): void {
    //default to a selected tab if none is selected
    if (isNullOrUndefined(this._activeTab)) {
      if (this.taksPrintModel.hasData) {
        this.activeTab = this.taksPrintModel;
      } else {
        if (this.vkPrintModel.hasData) {
          this.activeTab = this.vkPrintModel;
        }
      }
    }
  }

  public didClickTab(tab: CombinedPrintModel): void {
    this.activeTab = tab;
  }

  public isActive(tab: CombinedPrintModel): boolean {
    return this._activeTab === tab;
  }

  public showPrintPopup(): void {
    this.showPrintClicked.emit(this._activeTab);
  }
}
