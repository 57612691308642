<ng-container *ngIf="broadcasts">
    <div *ngIf="broadcasts.length > 0" class="table-responsive draft-table">
        <table data-table class="table table-hover table-fixed">
            <thead>
            <tr>
                <th class="at-date-col">Dato</th>
                <th class="at-message-col">Emne</th>
                <th>Slet</th>
            </tr>
            </thead>
            <ng-container *ngFor="let broadcast of broadcasts">
                <tr class="at-subject">
                    <td class="at-field" (click)="toggleSelected(broadcast)">{{broadcast.date}}</td>
                    <td class="at-field" (click)="toggleSelected(broadcast)">{{broadcast.subject}}</td>
                    <td class="at-message at-field">
                        <a>
                            <div (click)="delete(broadcast)"
                                 class="table-button">
                                <i class="fa fa-times-circle-o fa-lg"></i>
                            </div>
                        </a>
                    </td>
                </tr>
                <tr *ngIf="isSelected(broadcast)" class="at-message">
                    <td colspan="3" [innerHTML]="broadcast.text"></td>
                </tr>
            </ng-container>
        </table>
        <br>
    </div>
    <div *ngIf="broadcasts.length === 0">
        <p>Der er ingen broadcasts</p>
    </div>
</ng-container>
