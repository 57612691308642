import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ForsiReportKeyPipe} from '../../pipe/forsi-report-key.pipe';
import {StringUtils} from '../../utils/string-utils';
import {ClientStateDetails, FabrikatDTO} from '../../../draft';
import {ObjectUtils} from '../../utils/object-utils';
import {OfferDTO} from '../../../offer/dto/offer-dto.model';
import {B2BAdviseDTO} from '../../../b2b/model/b2b-advise-dto.model';
import {BusinessToBusinessPaintReportDTO} from '../../../b2b/model/business-to-business-paint-report-dto.model';
import {WorkshopDTO} from '../../service/admin/admin-workshop-dto.model';
import {
  AudatexAggregateSettlementPeriodTypePipe
} from '../../pipe/audatex-aggregate-settlement-period-type.pipe';
import {AudatexAggregateSettlementPeriodType} from '../../dto/audatex-aggregate-settlement-period-type.model';

//Add new keys as we go along
export enum BreadcrumbContextType {
  CLIENTSTATE_REPORT_KEY = 'CLIENTSTATE_REPORT_KEY',
  REPORT_KEY = 'REPORT_KEY',
  AUDATEX_SETTLEMENT_PERIOD_KEY = 'AUDATEX_SETTLEMENT_PERIOD_KEY',
  USER_ID = 'USER_ID',
  B2B_ADVISE_ID = 'B2B_ADVISE_ID',
  B2B_PAINT_REPORT_ID = 'B2B_PAINT_REPORT_ID',
  OFFER_ID = 'OFFER_ID',
  VEHICLE_MAKE_ID = 'VEHICLE_MAKE_ID',
  WORKSHOP_ID = 'WORKSHOP_ID'
}

interface BreadcrumbContext {
  [key: string]: string;
}


@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {

  private _context: BehaviorSubject<BreadcrumbContext> = new BehaviorSubject<BreadcrumbContext>({});

  public static isAContextType(typeAsString: string): boolean {
    return ObjectUtils.exists(BreadcrumbContextType[typeAsString]);
  }

  constructor(private reportKeyPipe: ForsiReportKeyPipe,
              private audatexAggregateSettlementPeriodTypePipe: AudatexAggregateSettlementPeriodTypePipe) {
  }

  public setClientStateReportKey(details: ClientStateDetails): void {
    const reportKey = !StringUtils.isEmpty(details.taksRapportKey) ? details.taksRapportKey : details.proformaOrdreNummer;
    const value = this.reportKeyPipe.transform(reportKey);
    this.add(BreadcrumbContextType.CLIENTSTATE_REPORT_KEY, details.token, value);
  }

  public setReportKey(report: {reportId: number, reportKey: string}): void {
    const value = this.reportKeyPipe.transform(report.reportKey);
    this.add(BreadcrumbContextType.REPORT_KEY, '' + report.reportId, value);
  }

  public setAudatexSettlementPeriod(type: AudatexAggregateSettlementPeriodType, periodId: string): void {
    const contextKey = `${type}_${periodId}`;
    const periodType = this.audatexAggregateSettlementPeriodTypePipe.transform(type);
    const contextValue = `${periodType}: ${periodId}`;
    this.add(BreadcrumbContextType.AUDATEX_SETTLEMENT_PERIOD_KEY, contextKey,contextValue);
  }

  public setOffer(offer: OfferDTO): void {
    this.add(BreadcrumbContextType.OFFER_ID, '' + offer.id, this.reportKeyPipe.transform(offer.reportKey));
  }

  public setUser(userId: number, username: string): void {
    this.add(BreadcrumbContextType.USER_ID, '' + userId, username);
  }

  public setB2BAdvise(b2bAdvise: B2BAdviseDTO): void {
    this.add(BreadcrumbContextType.B2B_ADVISE_ID, '' + b2bAdvise.id, this.reportKeyPipe.transform(b2bAdvise.reportKey));
  }

  public setB2BPaintReport(b2bPaintReport: BusinessToBusinessPaintReportDTO): void {
    this.add(BreadcrumbContextType.B2B_PAINT_REPORT_ID, '' + b2bPaintReport.id, this.reportKeyPipe.transform(b2bPaintReport.key));
  }

  public setVehicle(make: FabrikatDTO): void {
    this.add(BreadcrumbContextType.VEHICLE_MAKE_ID, '' + make.id, make.navn);
  }

  public setWorkshop(workshop: WorkshopDTO): void {
    this.add(BreadcrumbContextType.WORKSHOP_ID, '' + workshop.id, workshop.name);
  }

  private add(type: BreadcrumbContextType, contextKey: string, contextValue: string): void {
    const context = this._context.value;
    context[type + '\'_\'' + contextKey] = contextValue;
    this._context.next(context);
  }

  public get(type: BreadcrumbContextType, contextKey: string): string {
    return this._context.value[type + '\'_\'' + contextKey];
  }

  public context$(): Observable<BreadcrumbContext> {
    return this._context.asObservable();
  }
}
