<div class="row">
    <div class="col-12">
        <div class="btn-group d-flex" role="group">
            <button class="btn btn-primary me-2 w-25" (click)="showing = 'inputRecords'"
                    [ngClass]="{'active': showing === 'inputRecords'}">
                Input Records
            </button>
            <button class="btn btn-primary me-2 w-25" (click)="showing = 'inputRecordsDiff'"
                    [ngClass]="{'active': showing === 'inputRecordsDiff'}">
                Input Records Diff
            </button>
            <button class="btn btn-primary me-2 w-25" (click)="showing = 'print'"
                    [ngClass]="{'active': showing === 'print'}">
                Print
            </button>
            <button class="btn btn-primary me-2 w-25" (click)="showing = 'printDiff'"
                    [ngClass]="{'active': showing === 'printDiff'}">
                Print Diff
            </button>
        </div>
        <hr/>
    </div>
</div>
<div class="row" *ngIf="showing === 'inputRecords'">
    <div class="col-12">
        <h1>Input Records <small>(Manuel sammenligning af records)</small></h1>
        <p>Tryk på mellerum (space) for at skifte imellem Original og {{alteredTitle}}</p>
    </div>
    <div class="col-12" *ngIf="visibleReport === 'original'">
        <h3>Original</h3><i class="fa fa-2x fa-copy pull-right clickable" (click)="copyText(reportDiff.originalInputRecords)"></i>
        <pre [innerText]="reportDiff.originalInputRecords">
        </pre>
    </div>
    <div class="col-12" *ngIf="visibleReport === 'altered'">
        <h3>{{alteredTitle}}</h3><i class="fa fa-2x fa-copy pull-right clickable" (click)="copyText(reportDiff.alteredInputRecords)"></i>
        <pre [innerText]="reportDiff.alteredInputRecords">
        </pre>
    </div>
</div>
<div class="row" [hidden]="showing !== 'inputRecordsDiff'">
    <!-- use hidden because the ViewChild is required OnInit -->
    <div class="col-12">
        <h1>Input Record Diff <small>(Automatiseret sammenligning af records)</small></h1>
    </div>
    <div class="col-12">
        <pre #inputRecordsDiffHtmlContainer></pre>
    </div>
</div>
<div class="row" *ngIf="showing === 'print'">
    <div class="col-12">
        <h1>Print <small>(Manuel sammenligning af prints)</small></h1>
        <p>Tryk på mellerum (space) for at skifte imellem Original og {{alteredTitle}}</p>
    </div>
    <div class="col-12" *ngIf="visibleReport === 'original'">
        <h3>Original</h3><i class="fa fa-2x fa-copy pull-right clickable" (click)="copyText(reportDiff.originalPrint)"></i>
        <lc-print-viewer [print]="reportDiff.originalPrint"></lc-print-viewer>
    </div>
    <div class="col-12" *ngIf="visibleReport === 'altered'">
        <h3>{{alteredTitle}}</h3><i class="fa fa-2x fa-copy pull-right clickable" (click)="copyText(reportDiff.alteredPrint)"></i>
        <lc-print-viewer [print]="reportDiff.alteredPrint"></lc-print-viewer>
    </div>
</div>
<div class="row" [hidden]="showing !== 'printDiff'">
    <!-- use hidden because the ViewChild is required OnInit -->
    <div class="col-12">
        <h1>Print Diff <small>(Automatiseret sammenligning af prints)</small></h1>
    </div>
    <div class="col-12">
        <pre #printDiffHtmlContainer></pre>
    </div>
</div>
