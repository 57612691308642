import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TextLibraryService} from '../../service/text-library.service';
import {TextLibraryTypeEnum} from '../../service/text-library-type.enum';
import {RapportType} from '../../model/rapport-type.model';

@Component({
  templateUrl: './text-library-popup.component.html',
})
export class TextLibraryPopupComponent {
  @Input() title: string;
  @Input() editable = true;
  @Input() textLibraryType: TextLibraryTypeEnum;
  @Input() reportType: RapportType;
  @Input() textLibraryService: TextLibraryService;

  constructor(private activeModal: NgbActiveModal) {
  }

  cancel(): void {
    this.activeModal.dismiss();
  }

  handleSelectedText(text: string): void {
    this.activeModal.close(text);
  }
}
