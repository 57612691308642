import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DamagesNotePopupComponent} from '../../modals/damages-note-popup/damages-note-popup.component';
import {RapportType} from '../../model/rapport-type.model';

@Component({
  selector: 'lc-damages-note-card-view',
  templateUrl: 'damages-note-card-view.component.html'
})
export class DamagesNoteCardViewComponent {
  @Input() damagesNoteText;
  @Input() reportType: RapportType;
  @Output() damagesNoteTextUpdatedEmitter = new EventEmitter<string>();

  constructor(private modalService: NgbModal) {
  }

  showDamagesNote(): void {
    const modalRef = this.modalService.open(DamagesNotePopupComponent, {size: 'lg', windowClass: 'lc-fullscreen'});
    const componentInstance = modalRef.componentInstance as DamagesNotePopupComponent;
    componentInstance.damagesNoteText = this.damagesNoteText;
    componentInstance.reportType = this.reportType;
    modalRef.result.then((updatedDamagesNoteText: string) => {
      this.damagesNoteTextUpdatedEmitter.emit(updatedDamagesNoteText);
    }).catch(() => {
      // Necessary to avoid "Uncaught error Error: Uncaught (in promise)" even though we do not use the returned value.
    });
  }
}
