/**
 * Responsible for storing tokens properly
 */
import {StorageService} from '../../service/storage.service';
import {OAuthClientCredentialsDTO} from './model/o-auth-client-credentials-dto.model';
import {isNullOrUndefined} from '../../utils/object-utils';
import {Injectable} from '@angular/core';
import EnvironmentUtils from '../../utils/environment-utils';

/**
 * Responsible for storing and retreiving O-auth details
 */
@Injectable({providedIn: 'root'})
export class OAuthStorageService {

  private static readonly ACCESS_TOKEN_EXPIRATION_KEY = EnvironmentUtils.getEnvironmentKey('access_token_expiration');

  constructor(private storageService: StorageService) {
  }

  clearAccessTokenExpiration(): void {
    this.storageService.clearFromLocalStorage(OAuthStorageService.ACCESS_TOKEN_EXPIRATION_KEY);
  }

  setAccessTokenExpiration(credentials: OAuthClientCredentialsDTO): void {
    if (!isNullOrUndefined(credentials)) {
      const accessTokenExpiration = new Date().getTime() + (credentials.expires_in * 1000);
      this.storageService.setInLocalStorage(OAuthStorageService.ACCESS_TOKEN_EXPIRATION_KEY, accessTokenExpiration.toString());
    } else {
      this.storageService.clearFromLocalStorage(OAuthStorageService.ACCESS_TOKEN_EXPIRATION_KEY);
    }
  }

  getAccessTokenExpiration(): number {
    const accessTokenExpiration = this.storageService.getFromLocalStorage(OAuthStorageService.ACCESS_TOKEN_EXPIRATION_KEY);
    try {
      return isNullOrUndefined(accessTokenExpiration) ? null : +accessTokenExpiration;
    } catch (error) {
      return null;
    }
  }
}
