import {PrincipalDTO} from '../..';
import {TimelineMessage, TimelinePositionEnum} from './timeline-message.model';
import {B2BAdviseTimelineDTO} from '../../dto/b2b-advise-timeline-dto.model';

export default class MessageTimelineConverter {

  public static mapToTimelineMessages(currentUser: PrincipalDTO, rightIcon: string, leftIcon: string, messages: B2BAdviseTimelineDTO[]): TimelineMessage[] {
    const timelineMessages = messages ? messages.map(msg => MessageTimelineConverter.mapToTimelineMessage(currentUser, rightIcon, leftIcon, msg)) : [];
    return timelineMessages;
  }

  public static mapToTimelineMessage(currentUser: PrincipalDTO, rightIcon: string, leftIcon: string, msg: B2BAdviseTimelineDTO):  TimelineMessage {
    const part = Number(currentUser.atKlientNr);
    const position = msg.createdBy === part ? TimelinePositionEnum.RIGHT : TimelinePositionEnum.LEFT;
    return {
      headerIconClass: position === TimelinePositionEnum.RIGHT ? rightIcon : leftIcon,
      headerTitle: msg.createdByUser,
      message: msg.message,
      position: position,
      date: msg.createdAt
    };
  }
}
