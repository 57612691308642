import {Component, EventEmitter} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {
  CoreNonStandardPositionDTO,
  ExportNonStandardPositionDTO,
  NonStandardPositionDTO
} from '../../../shared/dto/non-standard-position/non-standard-position-dto.model';

@Component({
  templateUrl: './import-private-non-standard-position-popup.html'
})
export class ImportPrivateNonStandardPositionPopupComponent {
  onConfirm = new EventEmitter<CoreNonStandardPositionDTO[]>();
  existingNonStandardPositions: NonStandardPositionDTO[];
  exportNonStandardPosition: ExportNonStandardPositionDTO;
  selectedNonStandardPositions = [] as CoreNonStandardPositionDTO[];
  
  constructor(private activeModal: NgbActiveModal) {
  }

  init(existingNonStandardPositions: NonStandardPositionDTO[], exportNonStandardPosition: ExportNonStandardPositionDTO): void {
    this.existingNonStandardPositions = existingNonStandardPositions;
    this.exportNonStandardPosition = exportNonStandardPosition;
    exportNonStandardPosition.positions.forEach((coreNsp) => {
      if (!this.existsAlready(coreNsp)) {
        this.selectedNonStandardPositions.push(coreNsp);
      }
    })
  }
  
  public cancel(): void {
    this.activeModal.dismiss('cancel');
  }

  import(): void {
    this.onConfirm.emit(this.selectedNonStandardPositions);
    this.activeModal.dismiss('confirm');
  }

  isSelected(nsp: CoreNonStandardPositionDTO): boolean {
    return this.selectedNonStandardPositions.indexOf(nsp, 0) > -1;
  }

  toggleSelected(nsp: CoreNonStandardPositionDTO): void {
    const index = this.selectedNonStandardPositions.indexOf(nsp, 0);
    if (index > -1) {
      this.selectedNonStandardPositions.splice(index, 1);
    } else {
      this.selectedNonStandardPositions.push(nsp);
    }
  }

  toggleAllSelected(): void {
    if (this.selectedNonStandardPositions.length > 0) {
      this.selectedNonStandardPositions = []
    } else {
      const data = this.exportNonStandardPosition.positions;
      this.selectedNonStandardPositions = [];
      data.forEach(nsp => this.selectedNonStandardPositions.push(nsp))
    }
  }
  
  existsAlready(nonStandardPosition: CoreNonStandardPositionDTO): boolean {
    const index = this.existingNonStandardPositions.findIndex(nsp => nsp.type === nonStandardPosition.type &&
        nsp.description === nonStandardPosition.description &&
        nsp.workingUnits === nonStandardPosition.workingUnits &&
        nsp.newForOld === nonStandardPosition.newForOld &&
        nsp.price === nonStandardPosition.price &&
        nsp.sparePartDescription === nonStandardPosition.sparePartDescription &&
        nsp.repairMethod === nonStandardPosition.repairMethod &&
        nsp.group === nonStandardPosition.group)
    return index > -1
  }
}
