<lc-bottombar [menu]="bottomMenu" [title]="bottomMenuTitle"
              [shortTitle]="bottomMenuShortTitle"
              [iconClass]="bottomMenuIconClass"></lc-bottombar>

<h1>Ny Rapporttype</h1>
<lc-create-linked-report-from-draft *ngIf="!loading; else spinner"
                                    [taksatorClientState]="clientState"></lc-create-linked-report-from-draft>
<div class="row dynamic-padding-bottom">
    <!-- Room for floating action buttons -->
    <div class="col-md-12"></div>
</div>

<ng-template #spinner>
    <div class="text-center">
        <i class="fa fa-circle-o-notch fa-spin fop-page-spinner"></i>
    </div>
</ng-template>
