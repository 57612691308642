import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormSaver} from '../../../shared/service/core/form-saver.service';
import {ForsiReportKeyPipe} from '../../../shared/pipe/forsi-report-key.pipe';
import {ForsiReportKeyShortPipe} from '../../../shared/pipe/forsi-report-key-short.pipe';
import {NgForm} from '@angular/forms';
import {DraftEditService} from '../draft-edit.service';
import {PrincipalService} from '../../../shared';
import {ContextMenu, ContextMenuItem} from '../../../shared/ui/context-menu/context-menu.model';
import {AttachmentUseCase} from '../../../shared/model/attachment-use-case.model';
import {TextLibraryTypeEnum} from '../../../shared/service/text-library-type.enum';
import {AttachmentService} from '../../../shared/ui/attachment/attachment.service';
import {DraftService} from '../../service/draft.service';
import {UIUtils} from '../../../shared/utils/ui-utils';
import {BehaviorSubject, combineLatest, of, Subject} from 'rxjs';
import {DraftEditQapterFlowComponent} from '../draft-edit-qapter-flow.component';
import {BootstrapGrowlService} from '../../../shared/ui/ngx-bootstrap-growl/bootstrap-growl.service';
import {filter, switchMap, takeUntil} from 'rxjs/operators';
import {ClientNavigationService} from '../../../shared/service/client-navigation.service';
import {FeatureService} from '../../../shared/service/feature.service';
import {QapterPrepareModeService} from '../../../shared/service/qapter-prepare-mode.service';
import {ReportCategory} from '../../../shared/model/report-category.model';
import {VideoRequestResponseState} from '../../../shared/ui/attachment/video-request/model/video-request-state.model';
import {VideoRequestObserverService} from '../../../shared/ui/attachment/video-request/video-request-observer.service';
import {CopyDraftModalService} from '../../service/copy-draft.modal.service';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {AutoITCompanyUpdateService} from '../../service/auto-it/auto-it-company-update.service';
import {StringUtils} from '../../../shared/utils/string-utils';
import {LcPopupService} from '../../../shared/modals/lc-popup.service';
import {ConfirmButtonType, ConfirmPopupButton} from '../../../shared/modals/confirm/confirm-popup.component';

@Component({
  selector: 'lc-vk-autotaks',
  templateUrl: './vk-autotaks.component.html',
  styleUrls: [
    '../../draft.scss', '../draft-edit.scss', './vk-autotaks.scss'
  ],
  providers: [FormSaver],
})
export class VkAutotaksComponent extends DraftEditQapterFlowComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  isAgreementsLoading = true;
  @ViewChild('f', {static: true}) public form: NgForm;
  attachmentUseCase = AttachmentUseCase.Draft;
  textLibraryType = TextLibraryTypeEnum.BESKED;
  missingVideoRequest = false;

  licensePlateChanged$ = new BehaviorSubject<string>(null);
  damageDateChanged$ = new BehaviorSubject<NgbDateStruct>(null);

  constructor(clientStateService: DraftService,
              popupService: LcPopupService,
              private autoITCompanyUpdateService: AutoITCompanyUpdateService,
              draftEditService: DraftEditService,
              principal: PrincipalService,
              reportKeyPipe: ForsiReportKeyPipe,
              reportKeyShortPipe: ForsiReportKeyShortPipe,
              formSaver: FormSaver,
              attachmentService: AttachmentService,
              qapterPrepareModeService: QapterPrepareModeService,
              router: Router,
              route: ActivatedRoute,
              bootstrapGrowlService: BootstrapGrowlService,
              el: ElementRef,
              clientNavigationService: ClientNavigationService,
              private videoRequestObserverService: VideoRequestObserverService,
              private featureService: FeatureService,
              copyDraftModalService: CopyDraftModalService) {
    super(formSaver, reportKeyPipe, reportKeyShortPipe, clientStateService, draftEditService, principal,
      qapterPrepareModeService, router, route, bootstrapGrowlService, el, clientNavigationService,
      copyDraftModalService, popupService
    );
  }

  ngOnInit(): void {
    super.ngOnInit();

    const clientState$ = this.route.params.pipe(switchMap(params => this.draftEditService.getClientStateObservable(params.token)));

    clientState$.pipe(
      takeUntil(this.unsubscribe$)).subscribe(cs => {
      const fragment = this.route.snapshot.fragment;
      const repairType = ReportCategory.autotaksRepairType(cs.schema);
      const brandPromise = this.draftEditService.getBrands$(repairType);
      combineLatest([of(cs), brandPromise]).pipe(takeUntil(this.unsubscribe$)).subscribe(
        ([clientState, brands]) => {
          this.initializeWithClientState(clientState);
          this.updateWithBrands(brands);
          this.possibleNavigateToFragmentUrlPart(fragment);
          this.isLoading = false;

          //set initial values for vinNo and DamageDate observables as soon as we have a loaded clientstate
          this.licensePlateChanged$.next(clientState.vehicle.regnr);
          this.damageDateChanged$.next(clientState.skadeDato);
        }, error => {
          console.log('Error preparing vk-autotaks flow', error);
          this.router.navigate(['/draft']);
        }
      );
    });

    clientState$.pipe(
      switchMap(() => this.autoITCompanyUpdateService.autoITCompanyUpdate$(this.licensePlateChanged$, this.damageDateChanged$, this.details.selskab)),
      filter(suggestedCompany => StringUtils.isEmpty(this.details.selskab) || this.details.selskab !== suggestedCompany.code), // only notify if we found a different company than the selected
      takeUntil(this.unsubscribe$)
    ).subscribe(suggestedCompany => {
      if (StringUtils.isEmpty(this.details.selskab)) {
        this.details.selskab = suggestedCompany.code;
      } else {

        this.popupService.confirm('Forslag til skift af selskab',
          `Du har ændret i et af felterne vedr. stelnummer eller skadedato. Det ser ud til at køretøjet er forsikret i \'${suggestedCompany.name}\'.<br />Vil du skifte selskab til dette?`, {
          first: new ConfirmPopupButton(
            'Skift selskab', ConfirmButtonType.CONFIRM, 'btn-primary'),
          second: ConfirmPopupButton.DEFAULT_SECOND
        }).pipe(
          takeUntil(this.unsubscribe$)
        ).subscribe(value => {
          if (value) {
            new UIUtils().scrollToElementId(['agreements']);
            this.details.selskab = suggestedCompany.code;
          }
        })
      }
    })

    clientState$.pipe(
      switchMap(cs => this.videoRequestObserverService.clientStateResponseState$(cs.id)),
      takeUntil(this.unsubscribe$)
    ).subscribe(responseState => {
      this.missingVideoRequest = responseState === VideoRequestResponseState.REQUESTED;
    });
  }

  setupContextMenu(): void {
    this.contextMenu = new ContextMenu();
    const uiUtils = new UIUtils();
    this.contextMenu.addItem(new ContextMenuItem('fa-vcard-o', 'Stamdata', 'masterdata',
      (item) => {
        uiUtils.scrollToElementId(['masterdata']);
      }, () => !this.formSaver.isFormsValid(['masterData', 'forsikringInfo', 'skadelidteInfo'])));

    this.contextMenu.addItem(new ContextMenuItem('fa-handshake-o', 'Prisaftaler', 'agreements', (item) => {
      uiUtils.scrollToElementId(['agreements']);
    }, () => !(this.isAgreementsLoading || this.formSaver.isFormsValid(['agreements']))));

    this.contextMenu.addItem(new ContextMenuItem('fa-car', 'Skader', 'damages', (item) => {
      uiUtils.scrollToElementId(['damages']);
    }, () => {
      const qapterDataProvided = this.details.onepadDataValid;
      const zoneDataProvided = this.details.damageDescription.zoneIsValid();
      return !(this.formSaver.isFormsValid(['damages', 'damagedescription']) && qapterDataProvided && zoneDataProvided);
    }));

    this.contextMenu.addItem(new ContextMenuItem('fa-calendar', 'Datoer', 'misc', (item) => {
      uiUtils.scrollToElementId(['misc']);
    }, () => !this.formSaver.isFormsValid(['misc'])));

    this.contextMenu.addItem(new ContextMenuItem('fa-image', 'Bilag', 'attachments', (item) => {
      uiUtils.scrollToElementId(['attachments']);
    }, () => this.missingVideoRequest));

    if (this.featureService.hasFeature('ADVISE')) {
      this.contextMenu.addItem(new ContextMenuItem('fa-chain', 'Rådgivning', 'advise-section', (item) => {
        uiUtils.scrollToElementId(['advise-section']);
      }));
    }
  }

  setupDraftActionMenu(): void {
    this.draftMenu.addItem(this.createCopyDraftMenuItem());
    this.draftMenu.addItem(this.createDmrLookupMenuItem());
    this.draftMenu.addItem(this.createQapterMenuItem());
    this.draftMenu.addItem(this.createCalculateActionMenu());
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
