import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {ValueObsModel} from '../../shared/model/value-obs.model';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'lc-b2c-email-input',
  template: `
      <input type="email" class="form-control" name="b2c-email" ngDefaultControl id="email" placeholder="Email"
             [(ngModel)]="userEnteredEmail.value">
      <div class="text-danger pull-right">&nbsp;<span *ngIf="!emailValid">Der er endnu ikke indtastet en invalid email</span></div>
  `,
})
export class B2CEmailInputComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  @Output() mail = new EventEmitter<string>();
  userEnteredEmail = new ValueObsModel<string>();
  emailValid = false;

  ngOnInit(): void {
    this.userEnteredEmail.$.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: value => {
        const regEx = /^\w+([\!#$%&'*+/=?^.-]?\w+)*@\w+([\-]?\w+)+(\.\w{2,3})/
        this.emailValid = regEx.test(value);
        this.mail.next(this.emailValid ? value : '');
      }
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
