import {Component, Input} from '@angular/core';
import {Observable} from 'rxjs';
import {LoadingSpinnerUtil} from '../../../../../utils/loading-spinner-util';
import {ReportVideoRequestService} from '../../report-video-request.service';
import {VideoRequestResultDTO} from '../../model/video-request-result-dto.model';
import {FileUploader} from 'ng2-file-upload';
import {VideoRequestObserverService} from '../../video-request-observer.service';
import {VideoRequestResponseState} from '../../model/video-request-state.model';

@Component({
  selector: 'lc-workshop-report-video-request-upload-button',
  templateUrl: './workshop-report-video-request-upload-button.component.html',
  providers: [LoadingSpinnerUtil]
})
export class WorkshopReportVideoRequestUploadButtonComponent {

  @Input() workshopReportId: number;
  @Input() fileUploader: FileUploader;

  constructor(private reportVideoRequestService: ReportVideoRequestService,
              private videoRequestObserverService: VideoRequestObserverService) {
  }

  public videoRequest$(): Observable<VideoRequestResultDTO> {
    return this.reportVideoRequestService.getVideoRequestByWorkshopReportId(this.workshopReportId);
  }

  public videoRequestResponseState$(): Observable<VideoRequestResponseState> {
    return this.videoRequestObserverService.reportResponseState$(this.workshopReportId)
  }
}
