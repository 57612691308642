import {IEmbedConfiguration, models} from 'powerbi-client';
import {ReportPdfUrlFilterModel} from './report-pdf-url-filter.model';
import EnvironmentUtils from '../../shared/utils/environment-utils';

export class PowerbiConf {
  private type = 'report';
  private id: string;
  private embedUrl: string;
  private accessToken: string;
  private tokenType = models.TokenType.Embed;
  private permissions = models.Permissions.Read;
  private viewMode = models.ViewMode.View;
  private settings = {
    filterPaneEnabled: false,
    navContentPaneEnabled: false,
  };

  public constructor() {
  }

  public setId(id: string): void {
    this.id = id;
  }

  public setEmbedUrl(embedUrl: string): void {
    this.embedUrl = embedUrl;
  }

  public setAccessToken(accessToken: string): void {
    this.accessToken = accessToken;
  }

  public getConf(): IEmbedConfiguration {
    const urlFilter = new ReportPdfUrlFilterModel(EnvironmentUtils.hostnameStartsWithWWWW(), EnvironmentUtils.isEap());
    return {
      type: this.type,
      id: this.id,
      embedUrl: this.embedUrl,
      accessToken: this.accessToken,
      tokenType: this.tokenType,
      permissions: this.permissions,
      viewMode: this.viewMode,
      settings: this.settings,
      filters: urlFilter.reportFilters
    }
  }

}
