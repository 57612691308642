<div class="card">
    <div class="card-body">
        <span *ngIf="adviseEnabled()">
            <lc-draft-advise-base
                [advise]="advise"
                [token]="details.token"
                [reportKey]="details.proformaOrdreNummer"
                [create]="create"
                (onRefresh)="loadAdvise()">
            </lc-draft-advise-base>
        </span>
        <h4 *ngIf="!adviseEnabled()">
            Rapport uden beregning kan ikke sendes til rådgivning.
        </h4>
    </div>

    <div *ngIf="adviseEnabled()" class="card-footer">
        <div class="pull-right">
            <button *ngIf="create" class="btn btn-primary me-2" (click)="createAdvise()" [disabled]="!advise?.toVkName">
                Opret
            </button>
            <button *ngIf="!create" class="btn btn-danger me-2" (click)="deleteAdvise()">Slet</button>
        </div>
    </div>
</div>
