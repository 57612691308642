import {Observable} from 'rxjs';
import {TakseringsartDTO} from '../dto/skavu/takseringsart-dto.model';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {VogntypeDTO} from '../dto/skavu/vogntype-dto.model';
import {VognartDTO} from '../dto/skavu/vognart-dto.model';
import {SkavufabrikatDTO} from '../dto/skavu/skavufabrikat-dto.model';
import {map, shareReplay} from 'rxjs/operators';
import {isNullOrUndefined} from '../../shared/utils/object-utils';

@Injectable()
export class SkavuFabrikatService {
  baseUrl = 'skavufabrikat';
  private vognTyper = new Map<number, Observable<VogntypeDTO[]>>();
  private vognArter = new Map<number, Observable<VognartDTO[]>>();
  private fabrikater = new Map<string, Observable<SkavufabrikatDTO[]>>();

  constructor(private http: HttpClient) {
  }

  public getTakseringsarter(): Observable<TakseringsartDTO[]> {
    return this.http.get<TakseringsartDTO[]>(this.baseUrl);
  }

  public getVogntyper(takseringsArtId: number): Observable<VogntypeDTO[]> {
    if (!this.vognTyper.get(takseringsArtId)) {
      this.vognTyper.set(takseringsArtId, this.http.get<VogntypeDTO[]>(
        this.baseUrl + '/' + takseringsArtId.toString()).pipe(
        shareReplay(1)
        )
      );

    }
    return this.vognTyper.get(takseringsArtId);
  }

  public getVognarter(takseringsArtId: number, listeTypeId: number): Observable<VognartDTO[]> {
    if (!this.vognArter.get(listeTypeId)) {
      this.vognArter.set(listeTypeId, this.http.get<VognartDTO[]>(
        this.baseUrl + '/' + takseringsArtId.toString() + '/' + listeTypeId.toString()).pipe(
        shareReplay(1)
        )
      );
    }
    return this.vognArter.get(listeTypeId).pipe(map(vognArter => {
      const result = isNullOrUndefined(vognArter) ? [] : vognArter;
      if (result.length === 0) {
        result.push(({id: 0, art: '(ingen)', listetype: '0', modelkode: ''} as VognartDTO));
      }
      return result;
    }));
  }

  public getFabrikater(takseringsArtId: number, listeTypeId: number, vognartId: number): Observable<SkavufabrikatDTO[]> {
    if (isNullOrUndefined(vognartId)) {
      vognartId = 0;
    }
    const fabrikatKey = takseringsArtId + '_' + listeTypeId + '_' + vognartId;
    if (!this.fabrikater.get(fabrikatKey)) {
      this.fabrikater.set(fabrikatKey, this.http.get<SkavufabrikatDTO[]>(
        this.baseUrl + '/' + takseringsArtId.toString() + '/' + listeTypeId.toString() + '/' + vognartId.toString()).pipe(
        shareReplay(1)
        )
      );

    }
    return this.fabrikater.get(fabrikatKey);
  }

  public getFabrikaterByVognTypeID(takseringsArtId: number, vognTypeId: number, vognartId: number): Observable<SkavufabrikatDTO[]> {
    if (isNullOrUndefined(vognartId)) {
      vognartId = 0;
    }
    const fabrikatKey = 'ByVognTypeID_' + takseringsArtId + '_' + vognTypeId + '_' + vognartId;
    if (!this.fabrikater.get(fabrikatKey)) {
      this.fabrikater.set(fabrikatKey, this.http.get<SkavufabrikatDTO[]>(
        this.baseUrl + '/byvogntypeid/' + takseringsArtId.toString() + '/' + vognTypeId.toString() + '/' + vognartId.toString()).pipe(
        shareReplay(1)
        )
      );

    }
    return this.fabrikater.get(fabrikatKey);
  }
}
