
<div class="file-drop-zone row ms-0 me-0 mt-0 mb-0">
    <div class="col-12 ps-0">
        <h4>Rapport bilag</h4>
    </div>

    <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center mb-3 ps-0 pe-3"
         *ngFor="let attachment of concatAttachments() | filter: 'report' : true">
            <lc-attachment-card
                [attachment]="attachment"
                [deletable]="false"
                (openGallery)="openGallery($event)"
                [metadataSummary]="metadataSummary"
            ></lc-attachment-card>
    </div>
</div>
