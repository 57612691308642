import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  Renderer2, SimpleChanges
} from '@angular/core';
import {LcPopupService} from '../../../modals/lc-popup.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {isNullOrUndefined} from '../../../utils/object-utils';

/**
 * Common confirm delete data table button
 */
@Component({
  selector: 'lc-delete-button',
  templateUrl: './delete-button.component.html',
  styleUrls: ['./delete-button.scss'],
})
export class DeleteButtonComponent implements AfterViewInit, OnDestroy, OnChanges {
  private unsubscribe$ = new Subject<void>();
  @Input('confirmMessage') confirmMessage: string;
  @Input('disable') disabled = false;
  @Output('onDelete') deleteEmitter = new EventEmitter<Event>();

  constructor(private renderer: Renderer2, private ref: ElementRef, private popupService: LcPopupService) {
  }

  ngAfterViewInit(): void {
    const nativeElement = this.ref.nativeElement as Element;
    nativeElement.addEventListener('click', (event) => {
      this.didPressDelete(event);
    });
    this.renderer.addClass(nativeElement, 'table-button');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled']) {
      const nativeElement = this.ref.nativeElement as Element;
      if (this.disabled) {
        this.renderer.addClass(nativeElement, 'table-button-disabled')  
      } else {
        this.renderer.removeClass(nativeElement, 'table-button-disabled')
      }
    }
  }
  
  didPressDelete(event: Event): void {
    if (!this.disabled) {
      if (isNullOrUndefined(this.confirmMessage)) {
        this.deleteEmitter.emit();
      } else {
        const body = this.confirmMessage ? this.confirmMessage : 'Er du sikker på du vil slette?';
        this.popupService.confirmRemove('Bekræft sletning', body).pipe(
          takeUntil(this.unsubscribe$)
        ).subscribe(confirmed => {
          if (confirmed) {
            this.deleteEmitter.emit(event);
          }
        });
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
