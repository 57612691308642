import {Pipe, PipeTransform} from '@angular/core'

@Pipe({
  name: 'lcForsiReportType'
})
export class ForsiReportTypePipe implements PipeTransform {
  transform(reportKey: string, args?: any): any {
    if (reportKey) {
      return reportKey.replace(/(\w{2})(\d{5})(\d{2})(\d{4})(\w{1})/g, '$5');
    }

    return null;
  }
}
