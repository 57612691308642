import {DraftEditFlowComponent} from './draft-edit-flow.component';
import {OnInit} from '@angular/core';
import {VATProgramme} from '../model/op-details.model';

export abstract class DraftEditFlowTwoComponent extends DraftEditFlowComponent implements OnInit {
  vatProgrammes = [
    {value: VATProgramme.NO_DEDUCTION, label: 'Brugtmoms'},
    {value: VATProgramme.OLD_SCHEME, label: 'Standstill'}
  ];


  public isFormValid(): boolean {
    return (!this.formSaver.isInError() && this.formSaver.isValid());
  }
}
