import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AttachmentDTO} from '../../../dto/attachment-dto.model';
import AttachmentUtil from '../../../utils/attachment-utils';
import {PrincipalService} from '../../..';
import {AttachmentMetadataSummary} from '../../../dto/attachment-metadata-summary.model';
import URLBuilder from '../../../utils/url-builder';

@Component({
  selector: 'lc-attachment-card',
  templateUrl: './attachment-card.component.html',
  styleUrls: [
    'attachment-card.component.scss'
  ]
})
export class AttachmentCardComponent {
  @Input() attachment: AttachmentDTO;
  @Input() deletable: boolean;
  @Input() metadataSummary?: AttachmentMetadataSummary;
  @Output() openGallery = new EventEmitter<AttachmentDTO>();
  @Output() deleteAttachment = new EventEmitter<AttachmentDTO>();

  defaultImage = URLBuilder.basePath + 'content/images/defaultImage.png';

  constructor(public principal: PrincipalService) {
  }

  public getImageUrl(attachment: AttachmentDTO, thumbnail: boolean, download: boolean): string {
    return AttachmentUtil.composeImageAttachmentUrl(attachment, thumbnail, download);
  }

  public isImage(attachment: AttachmentDTO): boolean {
    return AttachmentUtil.isImage(attachment);
  }

  public isWorkshopImage(attachment: AttachmentDTO): boolean {
    return attachment.vkReport && AttachmentUtil.isImage(attachment);
  }

  public isUploaded(attachment: AttachmentDTO): boolean {
    return attachment.contentType !== 'file/uploading';
  }

  public getNotPresentableFileClass(attachment: AttachmentDTO): string {
    if (attachment && attachment.name) {
      const ext = attachment.name.split('.').pop();
      switch (ext) {
        case 'xls':
        case 'xlsx':
        case 'xlsb':
        case 'xlsm':
          return 'fa-file-excel-o';
        case 'doc':
        case 'docx':
        case 'docb':
        case 'docm':
          return 'fa-file-word-o';
        case 'ppt':
        case 'pptx':
        case 'ppsx':
          return 'fa-file-powerpoint-o';
        case 'pdf':
          return 'fa-file-pdf-o';
      }
    }
    if (attachment.contentType.startsWith('video/')) {
      return 'fa-file-video-o';
    }
    if (AttachmentUtil.isText(attachment)) {
      return 'fa-file-text-o'
    }
    return 'fa-file-o ';
  }
}
