import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {SharedModule} from 'app/shared/shared.module';
import {ReportVideoRequestService} from './report-video-request.service';
import {AssessorVideoRequestComponent} from './assessor/assessor-video-request.component';
import {ClientStateVideoRequestService} from './client-state-video-request.service';
import {AssessorVideoRequestPopupComponent} from './assessor/assessor-video-request-popup/assessor-video-request-popup.component';
import {WorkshopReportVideoRequestUploadButtonComponent} from './workshop/report/workshop-report-video-request-upload-button.component';
import {VideoRequestObserverService} from './video-request-observer.service';
import {FileUploadModule} from 'ng2-file-upload';
import {WorkshopClientStateVideoRequestUploadButtonComponent} from './workshop/clientstate/workshop-client-state-video-request-upload-button.component';
import {WorkshopVideoRequestUploadButtonComponent} from './workshop/workshop-video-request-upload-button.component';

@NgModule({
  imports: [
    SharedModule,
    FileUploadModule
  ],
  declarations: [
    AssessorVideoRequestComponent,
    AssessorVideoRequestPopupComponent,
    WorkshopVideoRequestUploadButtonComponent,
    WorkshopReportVideoRequestUploadButtonComponent,
    WorkshopClientStateVideoRequestUploadButtonComponent
  ],
  exports: [
    AssessorVideoRequestComponent,
    WorkshopReportVideoRequestUploadButtonComponent,
    WorkshopClientStateVideoRequestUploadButtonComponent
  ],
  providers: [ReportVideoRequestService, ClientStateVideoRequestService, VideoRequestObserverService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class VideoRequestModule {
}
