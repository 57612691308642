import {Component} from '@angular/core';
import {InfoEntryModel} from './info-entry.model';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './info-popup.component.html',
  styleUrls: ['./info-popup.scss']
})
export class InfoPopupComponent {

  public header = '';
  public infoEntries: InfoEntryModel[] = [];

  constructor(private activeModal: NgbActiveModal) {
  }

  public dismiss(): void {
    this.activeModal.dismiss();
  }
}
