export class SkavufabrikatDTO {
  static OTHER_FABRIKAT_TEXT = 'ØVRIGE';
  takseringsartId: string;
  vogntypeId: string;
  vognartId: string;
  fabrikatTekst: string;
  fabrikatKode: string;
  modelKode: string;
  listetypeId: string;


  public static createOtherFromTemplate(template: SkavufabrikatDTO): SkavufabrikatDTO {
    //ALERT: this is not logically, but needed to be ported from the flex to be backward compatible and preserve a fabrikat and model

    //the following rules are ported from SkavutaksVognMediator.as#addEditableItem
    //1. in any normal case we fetch the last entry in the list of possible fabrikater.
    //I believe this only works as UKENDT and ØVRIGE is sorted last in the list, hence their fabrikat/model is what we need.
    //the flex has relied in this since 2013!!
    //2. MCtaks (taksartID 1) is a special case as it doesn't have a UKENDT/ØVRIGE provided from the server, hence the fabrikat/model needs to be fixed here :-/
    //until it is added to the fabrikat/model register.
    //But in general the rule relying on the last entry in the list is crappy.
    //We talk about substituting the entire Skavu solution with Qapter, hence we just port the flex clientside rules, but lets see for how long this statement will live in the codebase :-)
    const isSpecialMCTaksCase = template.takseringsartId === '2' &&  template.vogntypeId === '4';

    console.log('ØVRIGE: ', template);
    const empty = new SkavufabrikatDTO();
    empty.takseringsartId = template.takseringsartId;
    empty.vogntypeId = template.vogntypeId;
    empty.vognartId = template.vognartId;
    empty.fabrikatKode = isSpecialMCTaksCase ? 'V7' : template.fabrikatKode;
    empty.modelKode = isSpecialMCTaksCase ? '**' : template.modelKode;
    empty.listetypeId = template.listetypeId;
    empty.fabrikatTekst = SkavufabrikatDTO.OTHER_FABRIKAT_TEXT;
    return empty;
  }
}
