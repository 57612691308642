import {Component, OnDestroy, OnInit} from '@angular/core';
import {LcActionMenu, LcActionMenuItem} from '../../../shared/ui/bottombar/lc-action-menu.model';
import {formatDate, Location} from '@angular/common';
import {
  SparePartProviderDTO
} from '../../../shared/admin-assessor-org-spare-part-provider/dto/spare-part-provider-dto.model';
import {ActivatedRoute, Router} from '@angular/router';
import {mergeMap, takeUntil, tap} from 'rxjs/operators';
import {DraftService} from '../../service/draft.service';
import {DraftSparePartViewModel} from './draft-spare-part.view-model';
import {forkJoin, Subject} from 'rxjs';
import {AutoflexSparePartService} from '../../service/autoflex-spare-part.service';
import {MatchSparePartsResponseDTO} from '../../dto/spare-part-match-response-dto.model';
import {AcknowledgeEnum} from '../../dto/spare-part-book-response-dto.model';
import {AutoflexStatusDTO} from '../../dto/autoflex-status-dto.model';

enum RequestState {initialized, running, success, failure}

@Component({
  templateUrl: './draft-spare-part-supplier.component.html',
  styleUrls: ['../../draft.scss', './draft-spare-part-supplier.component.scss'],
})
export class DraftSparePartSupplierComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  private acceptMenuItem: LcActionMenuItem = null;
  bottomMenu: LcActionMenu;
  sparePartSuppliers: SparePartProviderDTO[];

  viewModel: DraftSparePartViewModel = new DraftSparePartViewModel();
  private requestStateMap: Map<number, RequestState> = new Map<number, RequestState>();
  private errorMap: Map<number, string> = new Map<number, string>();
  private autoflexStatus: AutoflexStatusDTO;
  private offeringCreatedAt: Date;

  constructor(private location: Location,
              private autoflexSparePartService: AutoflexSparePartService,
              private draftService: DraftService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.setupBottomActionMenu();
    this.getEndpoints();
  }

  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  private setupBottomActionMenu(): void {
    this.bottomMenu = new LcActionMenu();
    this.bottomMenu.addItem(new LcActionMenuItem('fa-arrow-left', () => {
      this.location.back();
    }, 'Tilbage'));
    this.acceptMenuItem = new LcActionMenuItem('fa-check', () => {
      this.bookSpareParts();
    }, 'Ok');
    this.acceptMenuItem.spin = false;
    this.acceptMenuItem.disabledCallback = () => this.acceptMenuItem.spin || this.viewModel.readonly;
    this.bottomMenu.addItem(this.acceptMenuItem);
  }

  bookSpareParts() {
    this.acceptMenuItem.spin = true;
    const selection = this.viewModel.sparePartsUnfiltered.filter(sp => sp.selected);
    this.autoflexSparePartService.bookSpareParts(this.autoflexStatus, selection)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (responseDTO) => {
          const rejected = responseDTO.sparePartList.filter(sparePart => sparePart.acknowledge === AcknowledgeEnum.REJECTED);
          if (rejected?.length > 0) {
            this.acceptMenuItem.spin = false;
            selection.forEach(bookRq => {
              const rejectStatus = rejected.find(rj => rj.sparePartSupplierId === bookRq.supplierId && rj.supplierReference === bookRq.supplierReference);
              if (rejectStatus) {
                bookRq.bookAcknowledge = 'REJECTED';
                bookRq.selected = false;
                bookRq.selectionBlocked = true;
              } else {
                bookRq.bookAcknowledge = 'ACCEPTED';
              }
            });
          } else {
            this.router.navigate(['../calculate'], {relativeTo: this.activatedRoute});
          }
        }, error: () => {
          this.acceptMenuItem.spin = false;
        }
      });
  }

  private getEndpoints(): void {
    const configObservable = this.activatedRoute.parent.params.pipe(
      mergeMap(params => this.draftService.getClientState(params.token)),
      tap(clientState => {
        this.viewModel.vehicle = {year: clientState.aargang, km: clientState.kmstand}
      }),
      mergeMap(clientState => forkJoin([
        this.autoflexSparePartService.initMatchSpareParts(clientState),
        this.autoflexSparePartService.getSparePartSuppliers(clientState.selskab),
        this.autoflexSparePartService.getDefaultSortOrder(clientState.selskab)
      ])));

    configObservable.pipe(takeUntil(this.unsubscribe$)).subscribe(([autoflexStatus, sparePartSuppliers, sortOrder]) => {
      this.autoflexStatus = autoflexStatus;
      this.sparePartSuppliers = sparePartSuppliers;
      this.viewModel.defaultSortOrder = sortOrder;

      const routeParams = this.activatedRoute.snapshot.params;
      if (!!routeParams && routeParams.showOriginal) {
        this.getOriginalOffering();
      } else {
        this.getNewOffering();
      }
    });
  }

  private getOriginalOffering() {
    this.autoflexSparePartService.getOriginalOffering(this.autoflexStatus).pipe(takeUntil(this.unsubscribe$)).subscribe(parts => {
      parts.matches.forEach(sparePart => this.viewModel.add(sparePart));
      this.viewModel.sort();
      this.viewModel.readonly = true;
      this.offeringCreatedAt = parts.autoflexFetchedAt;
    });
  }

  private getNewOffering() {
    this.autoflexSparePartService.getBookedSpareParts(this.autoflexStatus).pipe(takeUntil(this.unsubscribe$)).subscribe(booked => {
      booked.matches.forEach(match => this.viewModel.add(match));
      this.viewModel.sort();
    });

    this.sparePartSuppliers.forEach(supplier => this.requestStateMap.set(supplier.id, RequestState.initialized));
    this.sparePartSuppliers.forEach(supplier => {
      this.requestStateMap.set(supplier.id, RequestState.running);
      this.autoflexSparePartService.getSparePartsFromSupplier(this.autoflexStatus, supplier.id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (data) => this.appendSpareParts(supplier.id, data),
          error: (error) => this.registerFailureForSupplier(supplier.id, error)
        });
    });
  }

  private appendSpareParts(supplierId: number, matchResponse: MatchSparePartsResponseDTO): void {
    this.requestStateMap.set(supplierId, matchResponse && matchResponse.success ? RequestState.success : RequestState.failure);
    if (matchResponse && matchResponse.success) {
      matchResponse.matches.forEach(match => this.viewModel.add(match));
      this.viewModel.sort();
    } else {
      this.errorMap.set(supplierId, matchResponse.errorMessage);
    }
  }

  private registerFailureForSupplier(supplierId: number, error: any): void {
    this.requestStateMap.set(supplierId, RequestState.failure);
    this.errorMap.set(supplierId, JSON.stringify(error));
  }

  public isInitialized(supplierId: number): boolean {
    return RequestState.initialized === this.requestStateMap.get(supplierId);
  }
  public isRunning(supplierId: number): boolean {
    return RequestState.running === this.requestStateMap.get(supplierId);
  }
  public isSuccess(supplierId: number): boolean {
    return RequestState.success === this.requestStateMap.get(supplierId);
  }
  public isFailed(supplierId: number): boolean {
    return RequestState.failure === this.requestStateMap.get(supplierId);
  }
  public getErrorMessage(supplierId: number): string {
    return this.errorMap.get(supplierId);
  }

  public anyRunning(): boolean {
    let anyRunning = false;
    this.requestStateMap.forEach((state, supplierId) => anyRunning = state === RequestState.running ? true : anyRunning);
    return anyRunning;
  }

  refreshForSupplier(supplier: SparePartProviderDTO) {
    if (this.requestStateMap.get(supplier.id) === RequestState.failure) {
      this.requestStateMap.set(supplier.id, RequestState.running);
      this.autoflexSparePartService.getSparePartsFromSupplier(this.autoflexStatus, supplier.id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (data) => this.appendSpareParts(supplier.id, data),
          error: (error) => this.registerFailureForSupplier(supplier.id, error)
        });
    }
  }

  getHeading(): string {
    let heading = 'Autoflex';
    if (this.viewModel.readonly) {
      heading += '-udbud hentet ' + formatDate(this.offeringCreatedAt, 'dd-MM-yyyy HH:mm:ss', 'da');
    }
    return heading;
  }

  copyToClipboard(email: string) {
    navigator.clipboard.writeText(email).catch(() => console.error('Unable to copy to clipboard'));
  }

  sendEmail(email: string) {
    window.open(`mailto:${email}`);
  }
}
