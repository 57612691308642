import {NgbPanelChangeEvent} from '@ng-bootstrap/ng-bootstrap';
import {Component, Input} from '@angular/core';
import {ReportDiffResult} from '../../../report/dto/report-diff-result-dto.model';

@Component({
  selector: 'lc-print-diff-viewer',
  templateUrl: './print-diff-viewer.component.html',
  styleUrls: ['./print-diff-viewer.scss'],
})
export class PrintDiffViewerComponent {
  @Input() reportDiffResult: ReportDiffResult;

  leftActive = true;

  public switchActivated(): void {
    this.activate(!this.leftActive);
  }

  public activate(left: boolean): void {
    this.leftActive = left;
  }

  public beforeChange(event: NgbPanelChangeEvent): void {
    setTimeout(() => {
      const topNav = document.getElementById('navContainer').getBoundingClientRect();
      const top = topNav.top + topNav.height;
      document.getElementById(event.panelId + '-header').scrollIntoView(true);
      window.scrollBy(0, -top);
    }, 50);
  };

}
