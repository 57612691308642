<div class="text-center" *ngIf="isLoading">
    <i class="fa fa-circle-o-notch fa-spin fop-page-spinner"></i>
</div>
<div *ngIf="!isLoading" class="no-focus" tabindex="0" [lcAutofocus]>
    <div class="card">
        <div class="card-body">
            <h4 class="card-title">Sprog</h4>
            <div class="row">
                <div class="col-12">
                    <select id="select1" class="col-md-4 col-sm-6 col-6 form-select"
                            [(ngModel)]="selectedLanguage.language" (change)="onLanguageChange()">
                        <option *ngFor="let language of languageConfiguration.languages" [ngValue]="language">
                            {{language}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</div>