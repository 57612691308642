import {Component, OnDestroy, OnInit} from '@angular/core';
import {AutoflexRowViewModel, AutoflexSubRowViewModel, DraftAutoflexViewModel, RowFilter} from './draft-autoflex.view-model';
import {ActivatedRoute, Router} from '@angular/router';
import {BootstrapGrowlService} from '../../../shared/ui/ngx-bootstrap-growl/bootstrap-growl.service';
import {BootstrapAlertType} from '../../../shared/ui/ngx-bootstrap-growl/bootstrap-alert-type.enum';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  templateUrl: './draft-autoflex.component.html',
  styleUrls: ['../../draft.scss', './draft-autoflex.scss'],
  providers: [DraftAutoflexViewModel]
})
export class DraftAutoflexComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  constructor(public viewmodel: DraftAutoflexViewModel,
              private route: ActivatedRoute,
              private router: Router,
              private bootstrapGrowlService: BootstrapGrowlService) {
  }

  ngOnInit(): void {
    this.viewmodel.navigateBackCallback = (token) => {
      this.router.navigate(['../calculate'], {relativeTo: this.route});
    };

    this.viewmodel.acceptBackCallback = (token) => {
      this.viewmodel.loading = true;
      this.viewmodel.saveSelected()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(savedDTOs => {
          const noOfSavedDTOs = savedDTOs.length;
          this.viewmodel.loading = false;
          this.bootstrapGrowlService.addAlert('Der er gemt ' + noOfSavedDTOs + ' Autoflex række(r)', BootstrapAlertType.SUCCESS);
          this.router.navigate(['../calculate'], {relativeTo: this.route});
        }, error => {
          this.viewmodel.loading = false;
          console.log('Failed saving autoflex', error);
          this.bootstrapGrowlService.addAlert('Autoflex kunne ikke gemmes', BootstrapAlertType.DANGER);
        });
    };
    this.route.parent.params
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(params => this.viewmodel.token = params.token);
  }

  didClick(row: AutoflexRowViewModel): void {
    this.viewmodel.didClick(row);
    if (row instanceof AutoflexSubRowViewModel) {
      (row as AutoflexSubRowViewModel).parent.selected = false;
    }
  }

  didClickFilter(row: RowFilter): void {
    this.viewmodel.didClickFilter(row);
  }

  didClickExpandFilter(): void {
    this.viewmodel.expandFilter = !this.viewmodel.expandFilter;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
