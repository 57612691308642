import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, Renderer2} from '@angular/core';

/**
 * Common edit data table button
 */
@Component({
  selector: 'lc-edit-button',
  templateUrl: './edit-button.component.html'
})
export class EditButtonComponent implements AfterViewInit {
  @Output('onEdit') editEmitter = new EventEmitter<Event>();
  @Input('disable') disabled = false;

  constructor(private renderer: Renderer2, private ref: ElementRef) {
  }

  ngAfterViewInit(): void {
    const nativeElement = this.ref.nativeElement as Element;
    if (!this.disabled) {
      nativeElement.addEventListener('click', (event) => {
        this.didPressEdit(event);
      });
    } else {
      this.renderer.addClass(nativeElement, 'table-button-disabled');
    }
    this.renderer.addClass(nativeElement, 'table-button');
  }

  public didPressEdit(event: Event): void {
    this.editEmitter.emit(event);
  }
}
