<div class="row pb-3">
    <label class="col-form-label col-4">Nummerplade</label>
    <div class="col-8">
        <div class="row">
            <div class="btn-group">
                <input type="radio" class="btn-check" name="nummerpladeType" value="H" id="HVID" autocomplete="off" [(ngModel)]="details.vehicle.nummerpladeType" (ngModelChange)="onLicensPlateChanged()"/>
                <label class="btn btn-secondary btn-sm" for="HVID">Hvid</label>
                <input type="radio" class="btn-check" name="nummerpladeType" value="G" id="GUL" autocomplete="off" [(ngModel)]="details.vehicle.nummerpladeType" (ngModelChange)="onLicensPlateChanged()"/>
                <label class="btn btn-secondary btn-sm" for="GUL">Gul/Papegøje</label>
            </div>
        </div>
        <div class="row">
            <div class="btn-group">
                <input type="radio" class="btn-check" name="nummerpladeType" value="B" id="BLAA" autocomplete="off" [(ngModel)]="details.vehicle.nummerpladeType" (ngModelChange)="onLicensPlateChanged()"/>
                <label class="btn btn-secondary btn-sm" for="BLAA">Blå</label>
                <input type="radio" class="btn-check" name="nummerpladeType" value="P" id="PROEVE" autocomplete="off" [(ngModel)]="details.vehicle.nummerpladeType" (ngModelChange)="onLicensPlateChanged()"/>
                <label class="btn btn-secondary btn-sm" for="PROEVE">Prøve</label>
                <input type="radio" class="btn-check" name="nummerpladeType" value=" " id="INGEN" autocomplete="off" [(ngModel)]="details.vehicle.nummerpladeType" (ngModelChange)="onLicensPlateChanged()"/>
                <label class="btn btn-secondary btn-sm" for="INGEN">Ingen</label>
            </div>
        </div>
    </div>
</div>
