import {isNullOrUndefined} from '../utils/object-utils';

/**
 * This H, T, S, GB, etc. types
 */
export class RapportType {

  static H: RapportType = new RapportType('H');
  static T: RapportType = new RapportType('T');
  static S: RapportType = new RapportType('S');
  static G: RapportType = new RapportType('G');
  static HT: RapportType = new RapportType('HT');
  static ER: RapportType = new RapportType('ER');
  static EL: RapportType = new RapportType('EL');
  static OP: RapportType = new RapportType('OP');
  static GB: RapportType = new RapportType('GB');
  static KR: RapportType = new RapportType('KR');

  private _type: string;

  get type(): string {
    return this._type;
  }

  static extractFrom(reportKey: string): RapportType {
    let stringResult = null;
    if (!isNullOrUndefined(reportKey)) {
      reportKey = reportKey.trim();
      if (reportKey.length === 14) {
        stringResult = reportKey.substr(13, 1);
      } else if (reportKey.length === 15) {
        stringResult = reportKey.substr(13, 2);
        const endsWithNumberRegExp = new RegExp(/^[A-Z]{1}[1-9]{1}$/);
        if (endsWithNumberRegExp.test(stringResult)) {
          //remove possible numbers from a T or S report
          stringResult = stringResult.substr(0, 1);
        }
      } else {
        throw new Error('ReportKey[' + reportKey + '] is of unexpected length [14|15]')
      }
    } else {
      throw new Error('Cannot extract rapportype from an empty or null valued reportKey')
    }
    return new RapportType(stringResult);
  }

  constructor(type: string) {
    this._type = type;
  }

  public equals(rapportType: RapportType): boolean {
    return this._type === rapportType._type;
  }

  public isOfAny(rapportTyper: RapportType[]): boolean {
    return rapportTyper.filter(value => value.equals(this)).length === 1;
  }
}
