import {Component, Input} from '@angular/core';
import {ObjectPropertyEntry} from '../object-property-entry.model';

@Component({
  selector: 'lc-display-property',
  templateUrl: './display-property.component.html'
})
export class DisplayPropertyComponent {
  @Input() objectPropertyEntry: ObjectPropertyEntry;

  constructor() {
  }

  isDate(): boolean {
    const isNumber = /^\d+$/.test(this.objectPropertyEntry.value);
    return (this.objectPropertyEntry.name.includes('Dato') || this.objectPropertyEntry.name.includes('Date')
      || this.objectPropertyEntry.name.includes('Gyldig Fra') || this.objectPropertyEntry.name.includes('Gyldig Til')) && isNumber;
  }
}
