import {Injectable} from '@angular/core';
import {VkStamDTO} from '../dto/vk-stam.dto.model';
import {combineLatest, Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {VkStam} from '../model/vk-stam-model';
import {CustomaftaleService} from './customaftale.service';
import {map} from 'rxjs/operators';
import {isNullOrUndefined} from '../utils/object-utils';

@Injectable({
  providedIn: 'root',
})
export class VKStamService {
  private serviceUrl = 'vkstam/';

  constructor(private http: HttpClient,
              private customaftaleService: CustomaftaleService) {
  }

  public getVaerkstedInfo(vknr: number): Observable<VkStam> {
    if (isNullOrUndefined(vknr)) {
      return of(null);
    }
    const customAftaler = this.customaftaleService.getAftaler(vknr);
    const vkstam = this.http.get<VkStamDTO>(this.serviceUrl + vknr, {observe: 'response'});
    return combineLatest([vkstam, customAftaler])
      .pipe(map(([vkstamResponse, customAftaler]) => {
          if (vkstamResponse.status === 204) {
            return null;
          } else {
            return new VkStam(vkstamResponse.body, customAftaler)
          }
        }
      ));
  }

  public getWorkshopName(vknr: number): Observable<string> {
    if (isNullOrUndefined(vknr)) {
      return of(null);
    }
    // @ts-ignore
    return this.http.get<string>(this.serviceUrl + vknr + '/name').pipe(map(dto => dto.name));
  }
}
