import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ClientStateDetails} from '../../../../model/client-state-details.model';
import {ReservationOptions} from '../../../../../shared/model/reservation-options.model';
import {FormSaver} from '../../../../../shared/service/core/form-saver.service';
import {NgForm} from '@angular/forms';
import {DateService} from '../../../../../shared/service/date.service';
import {AssessmentConfigurationDTO} from '../../../../../shared/dto/assessment-configuration-dto.model';

@Component({
  selector: 'lc-condtion-rules',
  templateUrl: './condition-rules.component.html',
  providers: [],
  styleUrls: ['../../../draft-common.scss']
})

export class CondtionRulesComponent implements OnInit, OnChanges {
  @Input() formId: string;
  @Input() details: ClientStateDetails;
  @Input() companyCode: string;
  @Input() assessmentConfiguration: AssessmentConfigurationDTO;
  forbehold: ReservationOptions;
  forbeholdOptions = Object.keys(ReservationOptions);
  @ViewChild('f', {static: true}) public cform: NgForm;

  constructor(private formSaver: FormSaver,
              public dateService: DateService) {
  }

  ngOnInit(): void {
    this.formSaver.registerForm(this.formId, this.cform);
    if (this.details.forbehold === null) {
      this.forbehold = null;
    } else {
      if (this.details.forbehold) {
        this.forbehold = ReservationOptions.Ja;
      } else {
        this.forbehold = ReservationOptions.Nej;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['companyCode']) {
      const previous = this.getDefaultCondition(changes['companyCode'].previousValue)
      if (previous) {
        this.details.forbehold = null;
        this.forbehold = null;
      }
      this.setDefaultCondition(changes['companyCode'].currentValue)
    }
  }

  private getDefaultCondition(companyCode: string): ReservationOptions {
    return this.assessmentConfiguration.defaultConditionedCompanies.includes(companyCode) ? ReservationOptions.Ja : null;
  }

  private setDefaultCondition(companyCode: string): void {
    const defaultCondition = this.getDefaultCondition(companyCode);
    if (defaultCondition) {
      this.forbehold = defaultCondition;
      this.onSelectOptionChanged();
    }
  }

  getForbeholdOptions(): string[] {
    return this.forbeholdOptions;
  }

  onSelectOptionChanged(): void {
    if (this.forbehold === ReservationOptions.Ja) {
      this.details.forbehold = true;
      this.details.faktura = false;
      this.details.reperationfaerdigDato = null;

    } else if (this.forbehold === ReservationOptions.Nej) {
      this.details.forbehold = false;
    }
  }
}
