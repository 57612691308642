import {Pipe, PipeTransform} from '@angular/core';
import {isNullOrUndefined} from '../utils/object-utils';

@Pipe({
  name: 'yesNo'
})
export class YesNoPipe implements PipeTransform {
  transform(value: boolean, defaultValue: string = 'Nej'): string {
    return !isNullOrUndefined(value) ? (value ? 'Ja' : 'Nej') : defaultValue;
  }
}
