import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CredentialsService} from '../../../../shared/service/credentials.service';
import {StringUtils} from '../../../../shared/utils/string-utils';
import {BehaviorSubject, fromEvent, Subject} from 'rxjs';
import {distinctUntilChanged, filter, map, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'lc-changepassword-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss']
})
export class ChangePasswordModalComponent implements OnInit, OnDestroy, AfterViewInit {
  private unsubscribe$ = new Subject<void>();
  @ViewChild('usernameInput') usernameInput: ElementRef;
  @ViewChild('passwordInput') passwordInput: ElementRef;
  @ViewChild('newPasswordInput') newPasswordInput: ElementRef;

  submitting: boolean;
  username: string;
  password: string;
  newPassword: string;
  newPasswordRepeat: string;
  changepasswordSuccessFeedback: string;
  changepasswordErrorFeedback: string;
  passwordRules: string[];
  $capslockEnabled = new BehaviorSubject<boolean>(false);

  constructor(private activeModal: NgbActiveModal,
              private credentialsService: CredentialsService) {
    this.submitting = false;
  }

  ngOnInit(): void {
    fromEvent(document, 'keyup').pipe(
      filter(event => 
        //when the browser prefills passwords from previously saved passwords
        //an keyup event is fired, but the getModifierState doesn't exist
         typeof event['getModifierState'] === 'function'
      ),
      map((key: KeyboardEvent) => {
        const pressedCapsKey = key.key === 'CapsLock';
        const modifiedByCapslock = key.getModifierState('CapsLock');
        return pressedCapsKey ? !modifiedByCapslock : modifiedByCapslock;
      }),
      distinctUntilChanged(),
      takeUntil(this.unsubscribe$)
    ).subscribe(this.$capslockEnabled);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  ngAfterViewInit(): void {
    this.updateInputFocus();
  }

  updateInputFocus(): void {
    if (StringUtils.isEmpty(this.username)) {
      this.usernameInput.nativeElement.focus();
    } else if (StringUtils.isEmpty(this.password)) {
      this.passwordInput.nativeElement.focus();
    } else {
      console.log('set newPasswordInput focus');
      this.newPasswordInput.nativeElement.focus();
    }
  }

  isFormValid(): boolean {
    return StringUtils.isNotEmpty(this.username)
      && StringUtils.isNotEmpty(this.password)
      && StringUtils.isNotEmpty(this.newPassword)
      && StringUtils.isNotEmpty(this.newPasswordRepeat);
  }

  showSuccessFeedback(): boolean {
    return StringUtils.isNotEmpty(this.changepasswordSuccessFeedback);
  }

  showErrorFeedback(): boolean {
    return StringUtils.isNotEmpty(this.changepasswordErrorFeedback);
  }

  public changePassword(): void {
    if (this.newPassword !== this.newPasswordRepeat) {
      this.setFeedback('Nyt kodeord og gentaget kodeord er ikke ens', true);
      this.newPassword = '';
      this.newPasswordRepeat = '';
      this.updateInputFocus();
      return;
    }
    this.submitting = true;
    this.credentialsService.changePassword(this.username, this.password, this.newPassword)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        changePasswordResult => {
          this.submitting = false;
          if (changePasswordResult.code === 0) {
            this.setFeedback('Kodeordet er skiftet', false);
            this.password = '';
            this.newPassword = '';
            this.newPasswordRepeat = '';
          } else {
            if (changePasswordResult.code === 101) {
              this.password = '';
            }
            this.newPassword = '';
            this.newPasswordRepeat = '';
            this.setFeedback(changePasswordResult.description, true);
            this.updateInputFocus();
            this.passwordRules = changePasswordResult.passwordRules.split('\n');
          }
        }
        , error => {
          this.submitting = false;
        });
  }

  public setFeedback(feedback: string, error: boolean): void {
    if (error) {
      this.changepasswordSuccessFeedback = null;
      this.changepasswordErrorFeedback = feedback;
    } else {
      this.changepasswordSuccessFeedback = feedback;
      this.changepasswordErrorFeedback = null;
    }
  }

  public cancel(): void {
    this.activeModal.dismiss('cancel');
  }
}
