import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {ProdConfig} from './app/shared/config/prod.config';
import {AppModule} from './app/app.module';
import {MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG} from '@azure/msal-angular';
import {PublicClientApplication} from '@azure/msal-browser';
import {B2CConfigurationService, B2CUserFlowsAuthorities,} from './app/shared/service/b2c-configuration.service';

ProdConfig();

if (module['hot']) {
  module['hot'].accept();
}
const path = location ? location.pathname : '';
let b2cFilename = 'b2c-configuration.json'
if (path.substring(0, 5) === '/eap/') {
  b2cFilename = 'eap-' + b2cFilename;
}

fetch(b2cFilename)
  .then(response => response.json())
  .then(json => {
    platformBrowserDynamic([
      {
        provide: B2CConfigurationService,
        useValue: new B2CConfigurationService(json.b2cUserFlowAuthorities as B2CUserFlowsAuthorities)
      },
      {
        provide: MSAL_INSTANCE,
        useValue: new PublicClientApplication(json.msal)
      },
      {
        provide: MSAL_INTERCEPTOR_CONFIG,
        useValue: {
          interactionType: json.interceptor.interactionType,
          protectedResourceMap: new Map(json.interceptor.protectedResourceMap)
        }
      }
    ]).bootstrapModule(AppModule).catch(err => console.error(err));
  }).catch(error => {
  platformBrowserDynamic([
    {provide: B2CConfigurationService, useValue: new B2CConfigurationService({} as B2CUserFlowsAuthorities)},
    {provide: MSAL_INSTANCE, useValue: new PublicClientApplication({auth: {clientId: 'unknown'}})},
    {provide: MSAL_INTERCEPTOR_CONFIG, useValue: {interactionType: 'redirect', protectedResourceMap: new Map()}}
  ]).bootstrapModule(AppModule).catch((err) => console.error(err));
});

