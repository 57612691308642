/**
 * Helper class to perform trivial, verbose and repetitive popup operations
 */
import {Injectable} from '@angular/core';
import {from, Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmButtonType, ConfirmPopupButton, ConfirmPopupComponent} from './confirm/confirm-popup.component';
import {MultipleDownloadComponent} from './multiple-download-component/multiple-download.component';
import {SimplePopupComponent} from './simplepopup/simple-popup.component';

@Injectable()
export class LcPopupService {

  constructor(private modalService: NgbModal) {
  }

  public confirm(title: string, body: string, buttons?: {first: ConfirmPopupButton, second: ConfirmPopupButton}): Observable<boolean> {
    const modalRef = this.modalService.open(ConfirmPopupComponent, {backdrop: 'static', keyboard: false});
    const instance = modalRef.componentInstance as ConfirmPopupComponent;
    instance.title = title;
    instance.body = body;
    instance.customFirstButton = buttons?.first;
    instance.customSecondButton = buttons?.second;
    return from(modalRef.result).pipe(
      catchError(() => of(false))
    )
  }

  public confirmRemove(title: string, body: string): Observable<boolean> {
    return this.confirm(title, body, {
      first: new ConfirmPopupButton('Slet', ConfirmButtonType.CONFIRM),
      second: new ConfirmPopupButton('Fortryd', ConfirmButtonType.DECLINE)
    });
  }

  public confirm_YesNo(title: string, body: string): Observable<boolean> {
    return this.confirm(title, body, {
      first: new ConfirmPopupButton('Ja', ConfirmButtonType.CONFIRM),
      second: new ConfirmPopupButton('Nej', ConfirmButtonType.DECLINE)
    });
  }

  public startMultipleDownload(title: string, downloads: Map<string, Observable<Blob>>, zipFileName: string): void {
    const modalRef = this.modalService.open(MultipleDownloadComponent, {
      size: 'sm',
      backdrop: 'static',
      keyboard: false
    });
    const instance = modalRef.componentInstance as MultipleDownloadComponent;
    instance.startDownload(title, downloads, zipFileName);
  }

  public textPopup(title: string, body: string, popupSize?: string): Observable<boolean> {
    const options: NgbModalOptions = popupSize ? {size: popupSize} : null;
    const modalRef = this.modalService.open(SimplePopupComponent, options);
    const instance = modalRef.componentInstance as SimplePopupComponent;
    instance.title = title;
    instance.body = body;
    return from(modalRef.result).pipe(
      catchError(() => of(false))
    )
  }
}
