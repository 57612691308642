import {VinDetailsDTO} from '../../shared/dto/vin-details-dto.model';
import {SpecialkodeDTO} from '../../shared/dto/specialkode-dto.model';
import {DamageDescriptionDTO} from '../../shared/dto/damage-description-dto.model';
import {ValuationDTO} from '../../shared/dto/valuation-dto.model';
import {SkavuDetailsDTO} from './skavu/skavu-details-dto.model';
import {ErDetailsDTO, GbDetailsDTO} from '../../shared/dto/er-details-dto.model';
import {AdhocVkDetailsDTO} from '../../shared/dto/adhoc-vk-details-dto.model';
import {OpDetailsDTO} from '../../shared/dto/op-details-dto.model';
import {ElDetailsDTO} from '../../shared/dto/el-details-dto.model';
import {FantomDetailsDTO} from './qapter/fantom-details-dto.model';
import {KrDetailsDTO} from '../../shared/dto/kr-details-dto.model';
import {ClientStateTaksatorIdentifier} from './client-state-taksator-identifier.model';
import {ClientStatePolicyDTO} from '../../shared/model/client-state-policy-dto.model';

export class ClientStateDTO implements ClientStateTaksatorIdentifier {
  id: number;
  token: string;
  aftaleArtId: number;
  onepadDataValid: boolean;
  selskab: string;
  stelNr: string;
  updatedAt: Date;
  taksRapportKey: string;
  fabrikat: string;
  model: string;
  submodel: string;
  angivetFabrikat: string;
  angivetModel: string;
  aargang: number;
  foersteRegDato: string;
  regNrUdloebDato: string;
  kmstand: number;
  ansvarsskade: boolean;
  proformaOrdreNummer: string;
  besigtigelsesDato: string;
  tilstandsType: string;
  nummerpladeType: string;
  vinDetails: VinDetailsDTO;
  handelsvaerdi: number;
  damageDescription: DamageDescriptionDTO;
  erReportDetails: ErDetailsDTO;
  elReportDetails: ElDetailsDTO;
  krReportDetails: KrDetailsDTO;
  gbReportDetails: GbDetailsDTO;
  opReportDetails: OpDetailsDTO;
  specialkoder: SpecialkodeDTO[];
  fantomDetails: FantomDetailsDTO;
  skadekode: string;
  timepris: number;
  lakTimepris: number;
  vknr: number;
  adhocVkDetails: AdhocVkDetailsDTO;
  schema: string;
  skadeDato: string;
  reperationfaerdigDato: string;
  clientStateVersion: string;
  forsikringsTagerAddressLine1: string;
  forsikringsTagerAddressLine2: string;
  forsikringsTagerNavn: string;
  forsikringsTagerPolice: string;
  forsikringsTagerPostNr: string;
  forsikringsTagerRegNr: string;
  forsikringsTagerTelefon: string;
  forsikringsTagerKundenr: string;
  forsikringsTagerSkadenr: string;
  forsikringsTagerSelvrisiko: string;
  skadelidteAddressLine1: string;
  skadelidteAddressLine2: string;
  skadelidteNavn: string;
  skadelidtePostNr: string;
  skadelidteRegNr: string;
  skadelidteTelefon: string;
  takserForm: string;
  leasingselskabCvrNr: string;
  leasingselskabNavn: string;
  leasingselskabAddressLine1: string;
  leasingselskabAddressLine2: string;
  leasingselskabPostalcode: string;
  leasingselskabBy: string;
  calculationDate: string;
  synsgebyr: number;
  nrpladeBeloeb: number;
  bemaerkninger: string;
  internInfo: string;
  vatInformation: string;
  skadeanmeldelseModtaget: boolean;
  police: ClientStatePolicyDTO;
  valuation: ValuationDTO;
  forbehold: boolean;
  faktura: boolean;
  skavuDetails: SkavuDetailsDTO;
  mcTaksType: string;
  damageNumberSetByAssessor: boolean;
  companySetByAssessor: boolean;
  modelOptions: string;
  vkNrOnParentReport: boolean;
  calculatedPrice: number;
  hybridType: string;
  propellantType: string;
}
