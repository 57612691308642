import {Component, Input} from '@angular/core';
import {LcActionMenu} from './lc-action-menu.model';
import {ScreenSizeService} from '../screen-size/screen-size.service';

@Component({
  selector: 'lc-bottombar',
  templateUrl: './bottombar.component.html',
  styleUrls: [
    './bottombar.scss'
  ]
})
export class BottombarComponent {
  @Input() menu: LcActionMenu;
  @Input() title: string;
  @Input() shortTitle: string;
  @Input() iconClass: string;

  xlScreen$ = this.screenSizeService.sizeXL$;

  constructor(private screenSizeService: ScreenSizeService) {
  }
}
