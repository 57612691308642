import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ClientStateDetails} from '../../../model/client-state-details.model';
import {ModelDTO} from '../../../../shared/makes/dto/model-dto.model';
import {FabrikatDTO} from '../../../../shared/makes/dto/fabrikat-dto.model';
import {NgbModal, NgbModalRef, NgbTypeahead} from '@ng-bootstrap/ng-bootstrap';
import {NgForm} from '@angular/forms';
import {FormSaver} from '../../../../shared/service/core/form-saver.service';
import {Validatable} from '../validatable.interface';
import {Subject} from 'rxjs';
import {DateUtils} from '../../../../shared/utils/date-utils';
import {RapportType} from '../../../../shared/model/rapport-type.model';
import {UiDraftComponentClientStateInformation} from '../ui-draft-component-client-state-information.model';
import {DraftService} from '../../../service/draft.service';
import {PrincipalService} from '../../../../shared';
import {VinLookupResultDTO} from '../../../dto/vin-lookup-result-dto.model';
import {BootstrapAlertType, BootstrapGrowlService} from '../../../../shared/ui/ngx-bootstrap-growl';
import {ReportCategory} from '../../../../shared/model/report-category.model';
import {takeUntil} from 'rxjs/operators';
import {MakeService} from '../../../../shared/makes/service/make.service';
import {ModelHelpTextPopupComponent} from '../../../ui/model-help-text-popup/model-help-text-popup.component';

@Component({
  selector: 'lc-draft-master-data',
  templateUrl: './draft-master-data.component.html',
  styleUrls: ['../../draft-common.scss']
})

export class DraftMasterDataComponent implements Validatable, OnInit, AfterViewInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  activeModal: NgbModalRef;
  vinLookupInProgress = false;
  vinLookupResult: VinLookupResultDTO;
  replaceVinDetailsInProgress: boolean;
  @Output() onVinDetailsChanged: EventEmitter<ClientStateDetails> = new EventEmitter();

  @Input() formId: string;
  @Input() brands: FabrikatDTO[];
  @Input() details: ClientStateDetails;
  @Output() onLicensePlateChanged = new EventEmitter<string>();
  @ViewChild('f', {static: true}) public cform: NgForm;
  @ViewChild('instance') instance: NgbTypeahead;

  focus$ = new Subject<string>();

  uiConfig: MasterDataUIConfig;

  get models(): ModelDTO[] {
    if (this.details.vehicle.fabrikat) {
      return this.details.vehicle.fabrikat.modeller;
    }
    return [];
  }

  constructor(private formSaver: FormSaver,
              private clientStateService: DraftService,
              private principalService: PrincipalService,
              private makeService: MakeService,
              private modalService: NgbModal,
              private bootstrapGrowlService: BootstrapGrowlService) {
  }

  ngOnInit(): void {
    this.formSaver.registerForm(this.formId, this.cform);
    this.uiConfig = new MasterDataUIConfig(this.details);
    this.fetchModelHelpText();
  }

  private fetchModelHelpText() {
    if (this.details.vehicle?.model?.id) {
      this.makeService.getModel(this.details.vehicle.fabrikat.id, this.details.vehicle?.model?.id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((modelDTO) => {
          if (!!modelDTO.modelHelpText) {
            this.details.vehicle.model = modelDTO;
            this.bootstrapGrowlService.addAlert(modelDTO.modelHelpText.header, BootstrapAlertType.WARNING, 5000);
          }
        });
    }
  }

  ngAfterViewInit(): void {
  }

  onSelectFabrikatChanged(fabrikat): void {
    if (fabrikat === this.details.vehicle.fabrikat) {
      return;
    }
    this.details.vehicle.fabrikat = fabrikat;
    this.details.vehicle.model = null;
    this.details.vehicle.angivetModel = null;
    if (FabrikatDTO.isKnownByAudatex(fabrikat)) {
      this.details.vehicle.angivetFabrikat = null;
      this.details.vehicle.fantomFabrikat = null;
      this.details.vehicle.fantomModel = null;
    } else {
      this.details.vehicle.angivetFabrikat = fabrikat.simpleName;
    }
    //Call save instantly in order to trigger possible agreements changes
    this.formSaver.save(() => {}, () => {} );
  }

  onSelectModelChanged(data): void {
    const selectedModel = this.details.vehicle.model;
    if (selectedModel === null || ModelDTO.isKnownByAudatex(selectedModel)) {
      this.details.vehicle.angivetModel = null;
      this.details.vehicle.fantomModel = null;
      this.details.vehicle.angivetFabrikat = null;
    } else {
      this.details.vehicle.angivetModel = selectedModel.simpleName;
      this.details.vehicle.angivetFabrikat = this.details.vehicle.fabrikat.simpleName;
    }
    //Call save instantly in order to trigger possible agreements changes
    this.formSaver.save(() => {}, () => {} );
    this.fetchModelHelpText();
  }

  showAngivetFabrikat(): boolean {
    return this.details.vehicle.isGeneralFabrikat();
  }

  showAngivetModel(): boolean {
    return this.showAngivetFabrikat() || this.details.vehicle.isGeneralModel() || this.details.vehicle.isModelQapterWildcard();
  }

  ansvarsSkadeChanged(): void {
    if (this.details.ansvarsskade) {
      this.details.skadelidte.regNr = this.details.forsikringstager.regNr;
      this.details.forsikringstager.regNr = null;
    } else {
      this.details.forsikringstager.regNr = this.details.skadelidte.regNr;
      this.details.skadelidte.regNr = null;
      this.details.skadelidte.navn = null;
      this.details.skadelidte.addressLine1 = null;
      this.details.skadelidte.addressLine2 = null;
      this.details.skadelidte.postNr = null;
      this.details.skadelidte.telefonNr = null;
    }
  }

  isValid(): boolean {
    return this.cform.form.valid;
  }

  public pastOrPresentMaxDateSelection(): any {
    return DateUtils.getNgbDateStructToday();
  }

  isTaksator(): boolean {
    return this.principalService.isTaksator();
  }

  getModelOptionsChunked(vinResult: string): string {
    let result = '';
    const chunks = 2;
    if (vinResult) {
      for (let i = 0; i < vinResult.length; i++) {
        result += vinResult.substr(i, chunks) + ' ';
        i = i + chunks;
      }
    }
    return result;
  }

  public openVinLookupModal(vinLookupContent): void {
    if (!this.uiConfig.disableVinLookup() && this.isTaksator()) {
      this.clientStateService.vinLookup(this.details.vehicle.stelnr)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(lookUpResult => {
          this.vinLookupInProgress = false;
          if (lookUpResult.audatexLookupDTO.vinResultCode === 'SUCCEEDED') {
            this.vinLookupResult = lookUpResult;
          } else {
            this.bootstrapGrowlService.addAlert('Det var ikke muligt at lave VIN opslag', BootstrapAlertType.WARNING);
            this.closeModal();
          }
        });
      this.vinLookupInProgress = true;
      this.activeModal = this.modalService.open(vinLookupContent, {backdrop: 'static'});

    }
  }

  closeModal(): void {
    if (this.activeModal) {
      this.activeModal.dismiss();
    }
  }

  replaceVinDetails(vinLookup: VinLookupResultDTO): void {
    this.replaceVinDetailsInProgress = true;
    this.clientStateService.replaceVinDetails(this.details.token, vinLookup)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(details => {
        this.onVinDetailsChanged.emit(details);
        this.replaceVinDetailsInProgress = false;
        this.closeModal();
      }, error => {
        this.replaceVinDetailsInProgress = false;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  onVehicleYearChanged(year: number): void {
    if (year > 1900) {
      //Call save instantly in order to trigger possible agreements changes
      this.formSaver.save(() => {}, () => {} );
    }
    
  }

  showModal(modalContent) {
    this.activeModal = this.modalService.open(modalContent, {backdrop: 'static'});
  }
  showModelHelpText() {
    this.activeModal = this.modalService.open(ModelHelpTextPopupComponent);
    this.activeModal.componentInstance.modelHelpText = this.details.vehicle.model.modelHelpText;
  }
}

export class MasterDataUIConfig {
  clientStateInfo: UiDraftComponentClientStateInformation;
  clientState: ClientStateDetails;

  constructor(clientState: ClientStateDetails) {
    this.clientStateInfo = new UiDraftComponentClientStateInformation(clientState);
    this.clientState = clientState;
  }

  isAutotaks(): boolean {
    return ReportCategory.AUTOTAKS.equals(this.clientStateInfo.reportType) || ReportCategory.AUTOTAKS_LORRY.equals(this.clientStateInfo.reportType);
  }

  private disableDefault(): boolean {
    return this.isAutotaks() && !this.clientStateInfo.rapportType.equals(RapportType.H);
  }

  public disableEditRegNr(): boolean {
    return this.clientState.vehicle.nummerpladeType === ' ' || this.disableDefault();
  }

  public disableEditFabrikat(): boolean {
    return this.disableDefault();
  }

  public disableEditModel(): boolean {
    return this.disableDefault();
  }

  public disableEditAnsvarsSkade(): boolean {
    return this.disableDefault();
  }

  public disableEditStelNr(): boolean {
    return this.disableDefault();
  }

  public disableVinLookup(): boolean {
    return (!this.clientState.vehicle.vinDetails === false) || this.disableDefault();
  }

  public getVinLookupTitle(): string {
    if (this.disableVinLookup()) {
      if (!this.clientState.vehicle.vinDetails === false) {
        return 'Der er allerede et gyldigt VIN opslag';
      } else {
        return 'VIN opslag er ikke muligt på denne rapport type'
      }
    } else {
      return 'Foretag VIN opslag';
    }
  }
}
