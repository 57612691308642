import {Injectable} from '@angular/core';
import {distinctUntilChanged, map, share} from 'rxjs/operators';
import {OfferService} from '../../shared/service/offer.service';
import {Observable} from 'rxjs';
import {MessageService} from '../../shared/service/message.service';
import {IncomingClientStateService} from '../../shared/service/incoming-client-state.service';

/**
 * Responsible for providing shared and probably highly UI tailored observables
 * for both our sidebar and topbar navigation components
 */
@Injectable({
  providedIn: 'root',
})
export class NavDynamicDataService {

  constructor(private offerService: OfferService,
              private messageService: MessageService,
              private incomingClientStateService: IncomingClientStateService
  ) {
  }

  public offerCount$(): Observable<number> {
    return this.offerService.getOffersCount().pipe(
      distinctUntilChanged(),
      share()
    );
  }

  public messageCount$(): Observable<number> {
    return this.messageService.getMessageCount().pipe(
      distinctUntilChanged(),
      share()
    );
  }

  public incommingClientStatesCount$(): Observable<number> {
    return this.incomingClientStateService.getData().pipe(
      map(ics => ics.length),
      distinctUntilChanged(),
      share()
    );
  }
}
