import {Routes} from '@angular/router';
import {UserRouteAccessService} from '../shared/service/auth/user-route-access.service';
import {NonStandardPositionsComponent} from './non-standard-positions.component';
import {AppRole} from '../shared/service/auth/app-roles.model';

export const NS_POSITIONS_ROUTES: Routes = [{
  path: 'ns-positions',
  component: NonStandardPositionsComponent,
  data: {
    authorities: [AppRole.VK, AppRole.TAKS],
    pageTitle: 'NS 1000-numre',
    breadcrumb: 'NS 1000-numre'
  },
  canActivate: [UserRouteAccessService]
}
];
