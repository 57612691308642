<div class="modal-header pre-size header-style" style="text-align: center">
    <div class="header-view-text">
        <b>Anmod værksted om video</b>
    </div>
    <div aria-label="Close" data-dismiss="modal" class="btn-close clickable" (click)="activeModal.dismiss()">
    </div>
</div>

<div class="modal-content">
    <div class="modal-body modal-body-style">

        <div class="row pb-3">
            <div class="col-12">
                <p>Du er på vej til at anmode værkstedet om at uploade en video til den oprindelige værkstedsrapport<br/></p>
                <label style="font-size: 0.7rem;" for="reason">Besked til værkstedet i forbindelse med anmodningen:</label>
                <textarea id="reason" class="form-control"
                          rows="6"
                          maxlength="600"
                          minlength="0"
                          placeholder="Eks: Du bedes uploade en video der viser detaljerne omkring ..."
                          [(ngModel)]="reasonText"
                ></textarea>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer">
    <div class="full-width">
        <div class="pull-right">
            <button class="btn btn-lg btn-primary" (click)="didClickSubmit()">Anmod om video</button>
        </div>
    </div>
</div>
