import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BaseAttachmentComponent} from '../base-attachment.component';
import {AttachmentService} from '../attachment.service';
import {PrincipalService} from '../../..';
import {BootstrapAlertType, BootstrapGrowlService} from '../../ngx-bootstrap-growl';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DraftService} from '../../../../draft/service/draft.service';
import {Observable, Subject} from 'rxjs';
import {AttachmentDTO} from '../../../dto/attachment-dto.model';
import {takeUntil} from 'rxjs/operators';
import {ConfirmPopupComponent} from '../../../modals/confirm/confirm-popup.component';
import AttachmentUtil from '../../../utils/attachment-utils';
import {FileUploader} from 'ng2-file-upload';
import {StatusMessageDTO} from '../../../dto/status-message-dto.model';
import {isNullOrUndefined} from '../../../utils/object-utils';
import {LcPopupService} from '../../../modals/lc-popup.service';
import {ClientStateVideoRequestService} from '../video-request/client-state-video-request.service';

@Component({
  selector: 'lc-draft-attachment',
  templateUrl: './draft-attachment.component.html',
  styleUrls: [
    '../attachment.component.scss'
  ]
})
export class DraftAttachmentComponent extends BaseAttachmentComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  @Input() token: string;
  @Input() clientStateId: number;

  /**
   * Tells whether or not videoRequest in general is a usecase in the scenario the component draft component is being used.
   * If true, we test to see if all other requirements for the report and current user is met. Default the value is false.
   */
  @Input() videoRequestUseCase = false;
  public renderWorkshopVideoRequestComponent = false;
  public renderAssessorVideoRequestComponent = false;

  constructor(
    private clientStateVideoRequestService: ClientStateVideoRequestService, attachmentService: AttachmentService, principal: PrincipalService,
    modalService: NgbModal, private draftService: DraftService, private bootstrapGrowlService: BootstrapGrowlService, popupService: LcPopupService) {
    super(attachmentService, principal, modalService, popupService);
    this.token = '';
  }

  ngOnInit(): void {
    this.updateAttachmentRemark.pipe(takeUntil(this.unsubscribe$)).subscribe((attachment: AttachmentDTO) => {
      this.draftService.updateAttachmentRemark(this.token, attachment).pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
        this.bootstrapGrowlService.addAlert('Bemærkning gemt på ' + attachment.name, BootstrapAlertType.SUCCESS, 3000);
      })
    });
    this.renderAssessorVideoRequestComponent = this.videoRequestUseCase && this.principal.isTaksator();
    this.renderWorkshopVideoRequestComponent = this.videoRequestUseCase && this.principal.isVK();
  }

  protected getAttachmentsObs(): Observable<AttachmentDTO[]> {
    return this.draftService.getAttachments(this.token).pipe(takeUntil(this.unsubscribe$));
  }

  public isDeletable(attachment: AttachmentDTO): boolean {
    return !attachment.report && !this.readonly;
  }

  public deleteAttachment(attachment: AttachmentDTO): void {
    if (this.isDeletable(attachment)) {
      const modalRef = this.modalService.open(ConfirmPopupComponent);
      modalRef.componentInstance.title = 'Slet ' + (this.isImage(attachment) ? 'billede' : 'bilag');
      modalRef.componentInstance.body = 'Er du sikker på at du vil slette ' + attachment.name;
      modalRef.result.then((confirm: boolean) => {
        if (confirm) {
          this.draftService.deleteAttachment(this.token, attachment.id).pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.removeLocally(attachment);
            this.onAttachmentChanged.emit();
            this.bootstrapGrowlService.addAlert('Bilag slettet', BootstrapAlertType.SUCCESS);
          });
        }
      }).catch(() => {
      });
    }
  }

  private removeLocally(attachment: AttachmentDTO): void {
    const list = AttachmentUtil.isImage(attachment) ? this.attachmentSummary.images : this.attachmentSummary.files;
    if (!isNullOrUndefined(list)) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].id === attachment.id) {
          list.splice(i, 1)
        }
      }
    }
  }

  protected prepareFileUploader(): FileUploader {
    if (this.readonly) {
      return null;
    }

    const attachmentPath = 'clientstate/' + this.token + '/attachments';

    const fileUploader = new FileUploader(this.getFileUploadOptions(attachmentPath, true));

    fileUploader.onWhenAddingFileFailed = (item, filter, options) => {
      let message = item.name + ' blev ikke uploadet';
      switch (filter.name) {
        case 'fileSize':
          message += ' - Må ikke være større end 15 MB!';
          break;
        default:
          message += ' - Der skete en uventet fejl!';
      }
      this.bootstrapGrowlService.addAlert(message, BootstrapAlertType.DANGER);
    };

    fileUploader.onCompleteAll = () => {
      this.onAttachmentChanged.emit();
    };

    fileUploader.onSuccessItem = (item, response) => {
      this.updateLocally(JSON.parse(response));
      if (this.principal.isVK()) {
        //for workshops, we poke to fetch any possible video request updates and the observer will take care of the rest
        this.clientStateVideoRequestService.getVideoRequest(this.token).pipe(takeUntil(this.unsubscribe$)).subscribe();
      }
      this.draftService.clearCache();
    };

    fileUploader.onErrorItem = (item, response, status) => {
      if (status === 413 && !isNullOrUndefined(response)) {
        const statusMessage: StatusMessageDTO = JSON.parse(response);
        this.bootstrapGrowlService.addAlert('Det var ikke muligt at gemme ' + item.file.name + '! ' + statusMessage.message, BootstrapAlertType.WARNING);
      } else {
        this.bootstrapGrowlService.addAlert('Det var ikke muligt at gemme ' + item.file.name + '!', BootstrapAlertType.DANGER);
      }
    }
    return fileUploader;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  onVideoRequestUpdated(): void {
    this.draftService.clearCache();
    this.onAttachmentChanged.emit();
  }
}
