/** A hero's name can't match the given regular expression */
import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';
import {DateUtils} from '../utils/date-utils';

@Directive({
  selector: '[lcForsiDate]',
  providers: [{provide: NG_VALIDATORS, useExisting: ForsiDateDirective, multi: true}]
})
export class ForsiDateDirective implements Validator {
  validationMessages = {
    foersteRegDato: {
      forsiDate: 'Dato skal være af format DD.MM.YYYY.',
    },
  };

  validate(control: AbstractControl): { [key: string]: any } {
    // Dato skal være i formatet 21-08-2017
    return !DateUtils.isValidOrEmptyDate(control.value) ? {forsiDate: 'Dato skal være af format "DD.MM.YYYY"'} : null;
  }
}
