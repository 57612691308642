import {Component, OnDestroy} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Subject} from 'rxjs';
import {DraftService} from '../../../service/draft.service';
import {DraftAdviseDTO} from '../../../dto/draft-advise-dto.model';
import {TimelineMessage} from '../../../../shared/ui/message-timeline/timeline-message.model';
import {takeUntil} from 'rxjs/operators';
import {ClientStateListViewDTO} from '../../../dto/client-state-list-view-dto.model';
import MessageTimelineConverter from '../../../../shared/ui/message-timeline/message-timeline-converter';
import {PrincipalService} from '../../../../shared';
import {
  ConfirmButtonType,
  ConfirmPopupButton,
  ConfirmPopupComponent
} from '../../../../shared/modals/confirm/confirm-popup.component';

@Component({
  templateUrl: './draft-advise-modal.component.html',
  styleUrls: ['./draft-advise-modal.component.scss']
})
export class DraftAdviseModalComponent implements OnDestroy {
  private unsubscribe$ = new Subject<void>();
  token: string;
  reportKey: string;
  advise: DraftAdviseDTO;
  create: boolean;
  showLoading: boolean;
  onCreate: Subject<DraftAdviseDTO>;
  onDelete: Subject<DraftAdviseDTO>
  messages: TimelineMessage[];

  constructor(private modalService: NgbModal, public activeModal: NgbActiveModal, private draftService: DraftService, private principalService: PrincipalService) {}

  open(draft: ClientStateListViewDTO, create: boolean, onCreate: Subject<DraftAdviseDTO>, onDelete: Subject<DraftAdviseDTO>): void {
    this.token = draft.token;
    this.create = create;
    this.reportKey = draft.proformaOrdreNummer;
    this.onCreate = onCreate;
    this.onDelete = onDelete;
    if (create) {
      this.advise = new DraftAdviseDTO();
    } else {
      this.loadAdvise(draft.token);
    }
  }

  public loadAdvise(token: string): void {
    this.draftService.getAdvise(token).pipe(takeUntil(this.unsubscribe$)).subscribe(advise => {
      this.advise = advise;
    });
  }

  createAdvise(): void {
    this.draftService.createAdvise(this.token, this.advise).pipe(takeUntil(this.unsubscribe$)).subscribe((advise) => {
      this.onCreate.next(advise);
      this.advise = advise;
      this.create = false;
    });
  }

  deleteAdvise(): void {
    const modalRef = this.modalService.open(ConfirmPopupComponent);
    const instance = modalRef.componentInstance as ConfirmPopupComponent;
    instance.title = 'Slet rådgivning';
    instance.body = 'Når du sletter en rådgivning vil alle beskeder på rådgivningen også blive slettet!'
    instance.customFirstButton = new ConfirmPopupButton('OK', ConfirmButtonType.CONFIRM);
    instance.customSecondButton = new ConfirmPopupButton('Fortryd', ConfirmButtonType.DECLINE);
    modalRef.result.then((deleteConfirmed: boolean) => {
      if (deleteConfirmed) {
        this.draftService.deleteAdvise(this.token).pipe(
          takeUntil(this.unsubscribe$)
        ).subscribe(() => {
          this.activeModal.dismiss('close');
          this.onDelete.next(this.advise);
        });
      }
    });
  }

  public cancel(): void {
    this.activeModal.dismiss('cancel');
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  createNewMessage(message: string): void {
    this.draftService.createAdviseMessage(this.token, {adviseId: this.advise.id, message: message})
      .pipe(takeUntil(this.unsubscribe$)).subscribe((advise) => {
        this.setAdvise(advise);
    })
  }


  private setAdvise(advise: DraftAdviseDTO): void {
    this.advise = advise;
    this.messages = MessageTimelineConverter.mapToTimelineMessages(this.principalService.getCurrentPrincipal(), 'fa-wrench', 'fa-paint-brush', advise.timeline);
  }
}
