import {Component, OnDestroy} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {PrintService} from 'app/shared/service/print.service';
import {PrintInputDTO} from '../../dto/prepare-print-input-dto.model';
import {SettingsService} from 'app/shared/service/settings.service';
import {PrintLayoutConfigurationDTO} from 'app/settings/model/print-layout-configuration-dto.model';
import {PrincipalService} from '../..';
import {GdprPrintOptionsDTO} from '../../dto/gdpr-print-options-dto.model';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  templateUrl: './print.component.html',
  styleUrls: [
    'print.scss'
  ]
})
export class PrintComponent implements OnDestroy {
  private unsubscribe$ = new Subject<void>();
  printLayout: PrintLayoutConfigurationDTO;
  isLoading: boolean;
  isPrinting: boolean;
  isDraft: boolean;
  isGDPRPrint: boolean;
  inclRegNr: boolean;
  isVk: boolean;
  key: string;
  /**
   * Introduced because a Taksator user needs to print a Vk report.
   * This property disables some print options that does not work in the backend,
   * and does not make sense when a Taksator user requests a print for a Vk report.
   */
  isTaksatorUserPrintingVkReport = false;

  constructor(private activeModal: NgbActiveModal,
              private settingsService: SettingsService,
              private printService: PrintService,
              private principalService: PrincipalService) {
  }

  public initialize(key: string, isDraft: boolean): void {
    this.key = key;
    this.isDraft = isDraft;
    this.isLoading = true;
    this.isVk = this.principalService.isVK();
    this.isGDPRPrint = this.isVk;
    this.settingsService.getPrintLayout().pipe(takeUntil(this.unsubscribe$)).subscribe(printLayout => {
      this.printLayout = printLayout;
      this.isLoading = false;
    });
  }

  public cancel(): void {
    this.activeModal.dismiss('cancel');
  }

  public print(customerCopy: boolean): void {
    const preparePrintInput = new PrintInputDTO();
    preparePrintInput.printLayout = this.printLayout;
    preparePrintInput.customerCopy = customerCopy;
    preparePrintInput.gdprOptions = new GdprPrintOptionsDTO(this.isGDPRPrint, this.inclRegNr);

    if (this.isDraft) {
      window.open(this.printService.getDraftPrintPdfUrl(this.key, preparePrintInput), '_blank');
    } else {
      window.open(this.printService.getReportPrintPdfUrl(this.key, preparePrintInput), '_blank');
    }
  }

  public printSag(): void {
    const preparePrintInput = new PrintInputDTO();
    preparePrintInput.printLayout = this.printLayout;
    preparePrintInput.gdprOptions = new GdprPrintOptionsDTO(this.isGDPRPrint, this.inclRegNr);

    if (!this.isDraft) {
      window.open(this.printService.getReportSagPrintPdfUrl(this.key, preparePrintInput), '_blank');
    }
  }

  onGdprChanged(): void {
    if (!this.isGDPRPrint) {
      this.inclRegNr = false;
    }
  }

  onInclRegNrChanged(): void {
    if (this.inclRegNr) {
      this.isGDPRPrint = true;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
