import {Injectable} from '@angular/core';
import {PrincipalService} from './auth/principal.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureService {

  private features = {
    // no feature toggles at the moment, below is an example of an old one
    // "WORKSHOP_ADMIN": {
    //   roles: [AppRole.TAKS, AppRole.TAKS_MANAGER, AppRole.AFTALEADMIN],
    //   onlyORG: ["AF"]
    // }
  }

  constructor(private principal: PrincipalService) {
  }

  hasFeature(feature: string): boolean {
    const featureConf = this.features[feature];
    if (featureConf) {
      return this.principal.hasAnyAuthorityDirect(featureConf.roles)
        && (featureConf.onlyORG ? featureConf.onlyORG.indexOf(this.principal.getCurrentPrincipal().orgNr) > -1 : true)
        && (featureConf.onlyVK ? featureConf.onlyVK.indexOf(this.principal.getCurrentPrincipal().atKlientNr) > -1 : true);
    }
    return false;
  }
}
