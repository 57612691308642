<div class="row mb-4">
    <div class="col">
        <div class="datatable-actions">
            <div class="position-absolute end-0 d-flex">
                <div class="mt-1 datatable-action" 
                    tooltipClass="text-start"
                    ngbTooltip="Administration af dine egne 1000 numre">
                    <i class="fa fa-question-circle-o fa-2x"></i>
                </div>
                <div class="datatable-action clickable m-0 mt-1" (click)="refresh()">
                    <i class="ms-3 fa fa-refresh fa-2x"
                       [ngClass]="{'fa-spin': spinnerUtil.isSpinning, 'fa-spin-stop-ie-hack': !spinnerUtil.isSpinning}"></i>
                </div>
                <div class="datatable-action clickable mt-1" (click)="openCreateNonStandardPosition()"><i
                    class="ms-3 fa fa-plus-square fa-2x"></i></div>
                <div *lcHasAnyAuthority="['ROLE_WorkshopAdmin']" class="datatable-action btn-file clickable mt-1" ngbTooltip="Upload NS 1000-numre fra fil">
                    <i class="ms-3 fa fa-upload fa-2x position-relative">
                        <input type="file" (change)="uploadNonStandardPositions($event)">
                    </i>
                </div>
                <div *lcHasAnyAuthority="['ROLE_WorkshopAdmin']" class="datatable-action clickable mt-1" (click)="downloadNonStandardPositions()" ngbTooltip="Download NS 1000-numre til fil">
                    <i class="ms-3 fa fa-download fa-2x position-relative"
                       [ngStyle]="{'cursor' : selectedNSP.length < 1 ? 'not-allowed': '', 'opacity' : selectedNSP.length < 1 ? '0.4' : ''}">
                        <span class="notify-badge-white">{{selectedNSP?.length}}</span>
                    </i>
                </div>
                <div *lcHasAnyAuthority="['ROLE_WorkshopAdmin']" class="datatable-action clickable m-2 ms-3" style="margin-right: 12px !important;" >
                        <input type="checkbox" class="checkbox-lg" (click)="toggleAllSelected()" [ngModel]="selectedNSP.length > 0">
                </div>
            </div>
        </div>

        <div class="datatable-animated" [ngClass]="spinnerUtil.isLoading ? 'datatable-loading' : 'datatable-ready'">
            <table datatable id="private-non-standard-positions" [dtOptions]="dataTable.dtOptions" [dtTrigger]="dataTable.dtTrigger" class="table table-hover w-100"></table>
        </div>
            
    </div>
</div>
<ng-template #createdAt let-nonStandardPosition="adtData">{{nonStandardPosition.createdAt | date: 'dd-MM-yyyy HH:mm:ss'}}</ng-template>
<ng-template #repairMethod let-nonStandardPosition="adtData">{{getRepairMethodDescription(nonStandardPosition.repairMethod)}}</ng-template>
<ng-template #nspActions let-nonStandardPosition="adtData">
    <div class="pull-right">
        <lc-edit-button title="Rediger 1000 nummer"
                        (onEdit)="openUpdateNonStandardPosition(nonStandardPosition)"></lc-edit-button>
        <lc-delete-button title="Slet 1000 nummer" [confirmMessage]="'Er du sikker på du vil slette 1000 nr: ' + nonStandardPosition.id" (onDelete)="deleteNonStandardPosition(nonStandardPosition)"></lc-delete-button>
        <div *lcHasAnyAuthority="['ROLE_WorkshopAdmin']" class="table-button" >
            <div class="table-button" >
                <input type="checkbox" class="checkbox-lg" (click)="toggleSelected(nonStandardPosition)" [ngModel]="isSelected(nonStandardPosition)">
            </div>
        </div>
    </div>
</ng-template>
