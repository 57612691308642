import {Component, Input} from '@angular/core';
import {AttachmentSummaryDTO} from '../../../dto/attachment-summary-dto.model';
import {isNullOrUndefined} from '../../../utils/object-utils';

@Component({
  selector: 'lc-attachment-badge',
  template: '<span [className]="getBadgeCssClass()">{{getAttachmentCount(attachmentSummary)}}</span>'
})
export class AttachmentBadgeComponent {
  @Input() attachmentSummary: AttachmentSummaryDTO;

  getBadgeCssClass(): string {
    return this.attachmentSummary?.infoMessage ? 'notify-badge-danger' : 'notify-badge-white';
  }

  public getAttachmentCount(attachmentSummary: AttachmentSummaryDTO): string | number {
    return isNullOrUndefined(attachmentSummary) ? '-' : attachmentSummary.count;
  }
}
