import {Component, OnDestroy, OnInit} from '@angular/core';
import {DraftEditService} from './draft-edit.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PrincipalService} from '../../shared';
import {ClientStateDetails} from '..';
import {mergeMap, takeUntil} from 'rxjs/operators';
import {BootstrapAlertType, BootstrapGrowlService} from '../../shared/ui/ngx-bootstrap-growl';
import {ReportCategory} from '../../shared/model/report-category.model';
import {RapportType} from '../../shared/model/rapport-type.model';
import {Subject} from 'rxjs';
import {PoliceService} from '../../shared/service/police.service';

@Component({
  templateUrl: './draft-edit.component.html',
  styleUrls: [
    '../draft.scss', './draft-edit.scss'
  ]
})
export class DraftEditComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  error = false;
  clientStateDetails: ClientStateDetails;
  private populatePolicyHolderAndLeasingDetailsByPolice = false;

  constructor(private principalService: PrincipalService,
              private draftEditService: DraftEditService,
              private route: ActivatedRoute,
              private router: Router,
              private bootstrapGrowlService: BootstrapGrowlService,
              private policeService: PoliceService) {

    //getCurrentNavigation will return null if called outside a constructor.
    const state = this.router.getCurrentNavigation()?.extras?.state;
    if (state) {
      if (state.populatePolicyHolderAndLeasingDetailsByPolice) {
        this.populatePolicyHolderAndLeasingDetailsByPolice = state.populatePolicyHolderAndLeasingDetailsByPolice;
      }
      if (state.navigateToUponApproval) {
        draftEditService.setNavigateToUponApproval(state.navigateToUponApproval);
      } else {
        draftEditService.setNavigateToUponApproval('draft');
      }
    }
  }

  ngOnInit(): void {
    this.route.params.pipe(
      mergeMap(params => this.draftEditService.getClientStateObservable(params.token)),
      takeUntil(this.unsubscribe$)
    ).subscribe(clientStateDetails => {
        this.clientStateDetails = clientStateDetails;

        if (this.populatePolicyHolderAndLeasingDetailsByPolice) {
          this.policeService.populateLeasingCompanyByPoliceIfExists(this.clientStateDetails);
          this.policeService.populatePolicyholderByPoliceIfExists(this.clientStateDetails);
          this.populatePolicyHolderAndLeasingDetailsByPolice = false;
        }

        const reportType = ReportCategory.getReportCategory(this.clientStateDetails.schema);
        const allowEdit = reportType.enabled && reportType.supportsRapportType(RapportType.extractFrom(this.clientStateDetails.rapportKey()));

        if (!allowEdit) {
          this.bootstrapGrowlService.addAlert('Man kan endnu ikke redigere denne ' + this.clientStateDetails.schema + ' kladde', BootstrapAlertType.WARNING, 5000);
          this.router.navigate(['/draft']);
        }
      },
      error => {
        console.log('error', error);
        this.router.navigate(['/draft']);
      });
    const scrollPos = this.draftEditService.scrollPosition;
    if (scrollPos) {
      let i = 0;
      const max = 20; //Fallback in case we can never scroll to the right position. Not he best solution!
      const handleScroll = (): void => {
        if (scrollPos !== window.screenY) {
          window.scroll(0, scrollPos);
          if (window.pageYOffset !== scrollPos && i++ < max) {
            //apparently we were not able to scroll to the desired position - try again in 200ms
            setTimeout(handleScroll, 200)
          }
        }
      };
      setTimeout(handleScroll);
    }
  }

  isVKAutotaks(): boolean {
    return this.clientStateDetails.isAutotaksType() && this.principalService.isVK();
  }

  isVKGlastaks(): boolean {
    return this.clientStateDetails.isGlastaksType() && this.principalService.isVK();
  }

  isVKSkavutaks(): boolean {
    return this.principalService.isVK() && this.clientStateDetails.isSkavuType();
  }

  isVKStortaks(): boolean {
    return this.clientStateDetails.isStortaksType() && this.principalService.isVK();
  }

  isVkCampTaks(): boolean {
    return this.clientStateDetails.isCamptaksType() && this.principalService.isVK();
  }

  isTaksFlow(): boolean {
    return this.principalService.isTaksator();
  }

  isVKMctaks(): boolean {
    return this.clientStateDetails.isMctaksType() && this.principalService.isVK();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
