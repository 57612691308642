import {TextLibraryTypeEnum} from './text-library-type.enum';
import {Observable} from 'rxjs';
import {TextLibraryDTO} from '../dto/text-library-dto';
import {HttpClient, HttpParams} from '@angular/common/http';
import {TextLibraryEntryDTO} from '../dto/text-library-entry-dto';
import {TextLibraryTypeDTO} from '../dto/text-library-type-dto';

export abstract class TextLibraryService {
  protected serviceUrl = 'textlibrary/';

  protected constructor(protected http: HttpClient) {
  }

  public getTextLibraryTypes(): Observable<TextLibraryTypeDTO[]> {
    return this.http.get<TextLibraryTypeDTO[]>(this.serviceUrl + 'types');
  }

  public getAllTextLibraries(): Observable<TextLibraryDTO[]> {
    const params = new HttpParams()
      .set('category', 'ORGANISATION');
    return this.http.get<TextLibraryDTO[]>(this.serviceUrl + 'all', {params});
  }

  public getSuperTextLibrary(type: TextLibraryTypeEnum, category: string): Observable<TextLibraryDTO> {
    const params = new HttpParams()
      .set('type', TextLibraryTypeEnum[type])
      .set('category', category);
    return this.http.get<TextLibraryDTO>(this.serviceUrl, {params});
  }

  public abstract getTextLibrary(type: TextLibraryTypeEnum): Observable<TextLibraryDTO>;

  public abstract createTextLibraryEntry(type: TextLibraryTypeEnum, text: string, reportTypes: string[]): Observable<TextLibraryEntryDTO>;

  public abstract updateTextLibraryEntry(id: number, text: string, reportTypes: string[]): Observable<TextLibraryEntryDTO>;

  public abstract deleteTextLibraryEntry(id: number): Observable<any>;
}
