import {Routes} from '@angular/router';
import {UserRouteAccessService} from '../shared/service/auth/user-route-access.service';
import {AppRole} from '../shared/service/auth/app-roles.model';
import {NewsComponent} from './news.component';

export const NEWS_ROUTES: Routes = [{
  path: 'news',
  component: NewsComponent,
  data: {
    authorities: [AppRole.VK, AppRole.TAKS, AppRole.SAGSB],
    pageTitle: 'Autotaks nyheder',
    breadcrumb: 'Nyheder'
  },
  canActivate: [UserRouteAccessService]
}
];
