<div class="position-relative">
        <input name="autoInput" type="text" class="autocomplete-input form-control" [class.text-uppercase]="uppercase" [(ngModel)]="query"
               (keyup)=filter($event)
               (keydown)="preFilter($event)" autocomplete="off" (mousedown)="inputClick($event)"
               placeholder="{{placeholder}}">
        <div class="suggestions" *ngIf="filteredList.length > 0" (mousedown)="preventFocusOutOnScrollbarClick()">
            <ul *ngFor="let item of filteredList.slice(0, limit) let idx=index">
                <li [ngClass]="{'active': idx===selectedIndex}" (mousedown)="select(item)">
                    {{getItemTextFull(item)}}
                </li>
            </ul>
        </div>
</div>
