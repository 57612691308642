import {Injectable} from '@angular/core';
import {Observable, of, ReplaySubject} from 'rxjs';
import {PrincipalDTO} from '../../dto/principal-dto.model';
import {HttpClient} from '@angular/common/http';
import {catchError, distinctUntilChanged, map} from 'rxjs/operators';
import {AppRole} from './app-roles.model';
import {isNullOrUndefined} from '../../utils/object-utils';
import {UserB2CLoginDTO} from './user-b2c-login-dto.model';
import {B2CActivationEmailDTO} from '../../dto/b2c-activation-email-dto.model';

@Injectable({
  providedIn: 'root',
})
export class PrincipalService {
  private userIdentity: PrincipalDTO;
  private authenticated = false;
  private authenticationState = new ReplaySubject<PrincipalDTO>(0);

  constructor(private http: HttpClient) {
  }

  get(): Observable<PrincipalDTO> {
    return this.http.get<PrincipalDTO>('me').pipe(
      map(response => Object.assign(new PrincipalDTO(), response))
    );
  }

  authenticate(identity): void {
    this.userIdentity = identity;
    this.authenticated = identity !== null;
    this.authenticationState.next(this.userIdentity);
  }

  hasAnyAuthorityDirect(authorities: string[]): boolean {
    if (!this.authenticated || !this.userIdentity || !this.userIdentity.authorities) {
      return false;
    }

    for (const item of authorities) {
      if (this.userIdentity.authorities.indexOf(item) !== -1) {
        return true;
      }
    }

    return false;
  }

  hasAllAuthorityDirect(authorities: string[]): boolean {
    if (!this.authenticated || !this.userIdentity || !this.userIdentity.authorities) {
      return false;
    }

    for (const item of authorities) {
      if (this.userIdentity.authorities.indexOf(item) === -1) {
        return false;
      }
    }

    return true;
  }

  isTaksator(): boolean {
    return this.hasAnyAuthorityDirect([AppRole.TAKS]);
  }

  isSagsbehandler(): boolean {
    return this.hasAnyAuthorityDirect([AppRole.SAGSB]);
  }

  isVK(): boolean {
    return this.hasAnyAuthorityDirect([AppRole.VK]);
  }

  isAF(): boolean {
    return this.isInOrganization('AF');
  }

  isInOrganization(orgCode: string): boolean {
    return !isNullOrUndefined(this.userIdentity) && this.userIdentity.orgNr === orgCode;
  }

  isSysadminUser(): boolean {
    return this.hasAnyAuthorityDirect([AppRole.SYSADMIN]);
  }

  isAutotaksAdmin(): boolean {
    return this.hasAnyAuthorityDirect([AppRole.AUTOTAKS_ADMIN]);
  }

  isExternalValidationUser(): boolean {
    return this.hasAnyAuthorityDirect([AppRole.EXTERNAL_VALIDATION_USER]);
  }

  identity(force?: boolean): Observable<PrincipalDTO> {
    if (force === true) {
      this.userIdentity = undefined;
    }

    // check and see if we have retrieved the userIdentity data from the server.
    // if we have, reuse it by immediately resolving
    if (this.userIdentity) {
      return of(this.userIdentity);
    }

    // retrieve the userIdentity data from the server, update the identity object, and then resolve.
    return this.get().pipe(
      map((principal: PrincipalDTO) => {
        if (principal) {
          this.userIdentity = principal;
          if (!principal.authorities && principal.roles) {
            principal.authorities = principal.roles;
          }
          this.authenticated = true;
        } else {
          this.userIdentity = null;
          this.authenticated = false;
        }

        this.authenticationState.next(this.userIdentity);
        return this.userIdentity;
      }),
      catchError<any, any>((err: any, caught: Observable<PrincipalDTO>): Observable<PrincipalDTO> => {
        this.userIdentity = null;
        this.authenticated = false;
        this.authenticationState.next(this.userIdentity);
        return of(null as PrincipalDTO);
      }));
  }

  isAuthenticated(): boolean {
    return this.authenticated;
  }

  isIdentityResolved(): boolean {
    return !isNullOrUndefined(this.userIdentity);
  }

  isAftaleAdmin(): boolean {
    return this.hasAnyAuthorityDirect([AppRole.AFTALEADMIN]);
  }

  isAllowedToEditAutoflexAgreement(): boolean {
    return this.hasAnyAuthorityDirect([AppRole.EDIT_AUTOFLEX_AGREEMENT]);
  }

  isAllowedToEditPoliceAgreement(): boolean {
    return this.hasAllAuthorityDirect([AppRole.AFTALEADMIN, AppRole.EDIT_POLICE_AGREEMENT]) && this.userIdentity.orgNr !== 'TR';
  }

  getCurrentPrincipal(): PrincipalDTO | null {
    return this.isIdentityResolved() ? this.userIdentity : null;
  }

  getAuthenticationState(): Observable<PrincipalDTO> {
    return this.authenticationState.asObservable().pipe(distinctUntilChanged());
  }

  getUsername(): string {
    return this.isIdentityResolved() ? this.userIdentity.userName : '';
  }

  getUserId(): number {
    return this.isIdentityResolved() ? this.userIdentity.id : null;
  }

  getUdskriftLinier(addWithRegardsText = false): string {
    return this.isIdentityResolved() ? addWithRegardsText ? '\n\nMED VENLIG HILSEN\n' + this.userIdentity.udskriftLinier : this.userIdentity.udskriftLinier : '';
  }

  getProviderInfo(): string {
    let result: string = null;
    const udbyderDetails = this.isIdentityResolved() ? this.userIdentity.udbyder : null;
    if (udbyderDetails !== null) {
      if (udbyderDetails.name !== null) {
        result = udbyderDetails.name;
        if (!isNullOrUndefined(udbyderDetails.email)) {
          result += ', e-mail: ' + udbyderDetails.email;
        }
        if (!isNullOrUndefined(udbyderDetails.phoneNo)) {
          result += ', tlf.: ' + udbyderDetails.phoneNo;
        }

      }
    }
    return result;
  }

  public isWorkingForEducationOrg(): boolean {
    return this.userIdentity.orgNr === 'UD';
  }

  public isAllowedToEditOwnCustomAgreements(): boolean {
    return this.isAuthenticated() && this.hasAnyAuthorityDirect([AppRole.EDIT_OWN_CUSTOM_ATTRIBUTES]);
  }

  public getOrganizationName(): string {
    return this.userIdentity.organizationName;
  }

  public getOrganizationCode(): string {
    return this.userIdentity.orgNr;
  }

  public getB2CUser(): Observable<UserB2CLoginDTO> {
    return this.http.get<UserB2CLoginDTO>('me/b2c-user')
  }

  public startB2CFlow(b2cUser: UserB2CLoginDTO): Observable<UserB2CLoginDTO> {
    return this.http.put<UserB2CLoginDTO>('me/b2c-user/start', b2cUser)
  }

  public postponeB2CFlow(): Observable<String> {
    return this.http.put<String>('me/b2c-user/postpone', null)
  }

  public resendActivationMailB2CFlow(): Observable<String> {
    return this.http.put<String>('me/b2c-user/resend-activation', null)
  }

  public changeActivationMailB2CFlow(newEmail: string): Observable<String> {
    return this.http.put<String>('me/b2c-user/change-activation-email', {emailAddress: newEmail} as B2CActivationEmailDTO)
  }
}
