import {NgbDate, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Injectable } from '@angular/core';

@Injectable()
export class NgbForsiDateMomentParserFormatter extends NgbDateParserFormatter {
  private momentFormat = 'DD.MM.YYYY';

  constructor() {
    super();
  };

  format(date: NgbDateStruct): string {
    if (!date) {
      return '';
    }
    const d = moment({
      year: date.year,
      month: date.month - 1,
      date: date.day
    });
    return d.isValid() ? d.format(this.momentFormat) : '';
  }

  parse(value: string): NgbDate {
    if (!value) {
      return null;
    }
    const d = moment(value, this.momentFormat);
    return d.isValid() ? (new NgbDate(d.year(), d.month() + 1, d.date())) : null;
  }

  parseDate(date: Date): NgbDate {
    if (!date) {
      return null;
    }
    return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
  }

  toDate(date: NgbDateStruct): Date {
    if (!date) {
      return null;
    }
    return new Date(date.year, date.month - 1, date.day);
  }

}
