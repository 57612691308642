import {FabrikatDTO} from '../../shared/makes/dto/fabrikat-dto.model';
import {ClientStateDTO} from '../dto/client-state-dto.model';
import {ModelDTO} from 'app/shared/makes/dto/model-dto.model';
import {NgbForsiDateMomentParserFormatter} from 'app/shared/index';
import {VinDetailsDTO} from '../../shared/dto/vin-details-dto.model';
import {SpecialkodeDTO} from '../../shared/dto/specialkode-dto.model';
import {DamageDescription} from './damage-description.model';
import {DamageDescriptionDTO} from '../../shared/dto/damage-description-dto.model';
import {isNullOrUndefined, ObjectUtils} from '../../shared/utils/object-utils';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {PersonInfo, PoliceInfo} from './person-info.model';
import {LeasingCompany} from './leasing-company.model';
import {Specialkode} from '../../shared/model/specialkode.model';
import {QapterVehicle} from './qapter-vehicle.model';
import {SkavuDetails} from './skavu-details.model';
import {Valuation} from './valuation.model';
import {StringUtils} from '../../shared/utils/string-utils';
import {ErDetails, GbDetails} from './er-details.model';
import {AdhocVkDetails} from './adhoc-vk-details.model';
import {AdhocVkDetailsDTO} from '../../shared/dto/adhoc-vk-details-dto.model';
import {OpDetails} from './op-details.model';
import {FantomDetailsDTO} from '../dto/qapter/fantom-details-dto.model';
import {ElDetails} from './el-details.model';
import {KrDetails} from './kr-details.model';
import {CarDetails} from './flowtwo-car-details.model';
import {RapportType} from '../../shared/model/rapport-type.model';
import {ReportCategory} from '../../shared/model/report-category.model';
import {ClientStatePolicyDTO} from '../../shared/model/client-state-policy-dto.model';

export class ClientStateDetails {
  public static GLASSKADE_SKADETYPE = 'GLASSKADE';
  public static TOTALFORSVUNDET_SKADETYPE = 'TOTALFORSVUNDET';

  public static readonly VALUE_DETERIORATION_TEXT = 'VÆR OPMÆRKSOM PÅ EVENTUELT VÆRDIFORRINGELSE';

  id: number;
  onepadDataValid: boolean;
  taksRapportKey: string;
  proformaOrdreNummer: string;
  token: string;
  ansvarsskade: boolean;
  vehicle: QapterVehicle;
  skavu: SkavuDetails;
  erReportDetails: ErDetails;
  elReportDetails: ElDetails;
  krReportDetails: KrDetails;
  gbReportDetails: GbDetails;
  opReportDetails: OpDetails;
  forsikringstager: PoliceInfo;
  skadelidte: PersonInfo;
  leasingselskab: LeasingCompany;
  specialkoder: Specialkode[];
  skadekode: string;
  timepris: number;
  lakTimepris: number;
  aftaleArtId: number;
  selskab: string;
  vknr: number;
  adhocVkDetails: AdhocVkDetails;
  schema: string;
  skadeDato: NgbDateStruct;
  clientStateVersion: string;
  besigtigelsesDato: NgbDateStruct;
  reperationfaerdigDato: NgbDateStruct;
  takserForm: string;
  damageDescription: DamageDescription;
  calculationDate: NgbDateStruct;
  synsgebyr: number;
  nrpladeBeloeb: number;
  bemaerkninger: string;
  internInfo: string;
  vatInformation: string;
  skadeanmeldelseModtaget: boolean;
  police: ClientStatePolicyDTO;
  valuation: Valuation;
  forbehold: boolean;
  faktura = false;
  damageNumberSetByAssessor: boolean;
  companySetByAssessor: boolean;
  vehicleText: string;
  updatedAt: Date;
  vkNrOnParentReport: boolean;
  calculatedPrice: number;

  static fromClientStateDTO(clientStateDTO: ClientStateDTO): ClientStateDetails {
    const clientStateDetails = new ClientStateDetails();
    clientStateDetails.selskab = clientStateDTO.selskab;
    clientStateDetails.token = clientStateDTO.token;
    clientStateDetails.id = clientStateDTO.id;
    clientStateDetails.calculatedPrice = clientStateDTO.calculatedPrice;
    clientStateDetails.aftaleArtId = clientStateDTO.aftaleArtId;

    //if (!isNullOrUndefined(clientStateDetails.vehicle))
    clientStateDetails.vehicle = new QapterVehicle();
    clientStateDetails.vehicle.kmstand = clientStateDTO.kmstand;
    clientStateDetails.vehicle.aargang = clientStateDTO.aargang;

    clientStateDetails.vehicle.regnr = clientStateDTO.ansvarsskade ? clientStateDTO.skadelidteRegNr : clientStateDTO.forsikringsTagerRegNr;
    clientStateDetails.updatedAt = new Date(clientStateDTO.updatedAt);

    clientStateDetails.skavu = SkavuDetails.fromDTO(clientStateDTO, clientStateDTO.skavuDetails);

    if (ReportCategory.isOfRapportType(clientStateDTO.schema, RapportType.ER)) {
      clientStateDetails.erReportDetails = ErDetails.fromDTO(clientStateDTO.erReportDetails);
    }

    if (ReportCategory.isOfRapportType(clientStateDTO.schema, RapportType.EL)) {
      clientStateDetails.elReportDetails = ElDetails.fromDTO(clientStateDTO.elReportDetails);
    }

    if (ReportCategory.isOfRapportType(clientStateDTO.schema, RapportType.KR)) {
      clientStateDetails.krReportDetails = KrDetails.fromDTO(clientStateDTO.krReportDetails);
    }

    if (ReportCategory.isOfRapportType(clientStateDTO.schema, RapportType.GB)) {
      clientStateDetails.gbReportDetails = GbDetails.fromDTO(clientStateDTO.gbReportDetails);
    }

    if (ReportCategory.isOfRapportType(clientStateDTO.schema, RapportType.OP)) {
      clientStateDetails.opReportDetails = OpDetails.fromDTO(clientStateDTO.opReportDetails);
    }

    clientStateDetails.forsikringstager = new PoliceInfo();
    clientStateDetails.forsikringstager.addressLine1 = clientStateDTO.forsikringsTagerAddressLine1;
    clientStateDetails.forsikringstager.addressLine2 = clientStateDTO.forsikringsTagerAddressLine2;
    clientStateDetails.forsikringstager.navn = clientStateDTO.forsikringsTagerNavn;
    clientStateDetails.forsikringstager.postNr = clientStateDTO.forsikringsTagerPostNr;
    clientStateDetails.forsikringstager.regNr = clientStateDTO.forsikringsTagerRegNr;
    clientStateDetails.forsikringstager.telefonNr = clientStateDTO.forsikringsTagerTelefon;
    clientStateDetails.forsikringstager.policenr = clientStateDTO.forsikringsTagerPolice;
    clientStateDetails.forsikringstager.selvrisiko = clientStateDTO.forsikringsTagerSelvrisiko;
    clientStateDetails.forsikringstager.kundenr = clientStateDTO.forsikringsTagerKundenr;
    clientStateDetails.forsikringstager.skadenr = clientStateDTO.forsikringsTagerSkadenr;

    clientStateDetails.skadelidte = new PersonInfo();
    clientStateDetails.skadelidte.addressLine1 = clientStateDTO.skadelidteAddressLine1;
    clientStateDetails.skadelidte.addressLine2 = clientStateDTO.skadelidteAddressLine2;
    clientStateDetails.skadelidte.navn = clientStateDTO.skadelidteNavn;
    clientStateDetails.skadelidte.postNr = clientStateDTO.skadelidtePostNr;
    clientStateDetails.skadelidte.regNr = clientStateDTO.skadelidteRegNr;
    clientStateDetails.skadelidte.telefonNr = clientStateDTO.skadelidteTelefon;

    clientStateDetails.leasingselskab = new LeasingCompany();
    clientStateDetails.leasingselskab.cvrNr = clientStateDTO.leasingselskabCvrNr;
    clientStateDetails.leasingselskab.navn = clientStateDTO.leasingselskabNavn;
    clientStateDetails.leasingselskab.addressLine1 = clientStateDTO.leasingselskabAddressLine1;
    clientStateDetails.leasingselskab.addressLine2 = clientStateDTO.leasingselskabAddressLine2;
    clientStateDetails.leasingselskab.postNr = clientStateDTO.leasingselskabPostalcode;

    // Oneway mapping. This is only set by server.
    clientStateDetails.onepadDataValid = clientStateDTO.onepadDataValid;

    if (clientStateDTO.foersteRegDato) {
      clientStateDetails.vehicle.foersteRegDato = new NgbForsiDateMomentParserFormatter().parse(clientStateDTO.foersteRegDato);
    }
    if (clientStateDTO.regNrUdloebDato) {
      clientStateDetails.vehicle.regNrUdloebDato = new NgbForsiDateMomentParserFormatter().parse(clientStateDTO.regNrUdloebDato);
    }

    clientStateDetails.vehicle.stelnr = clientStateDTO.stelNr;
    clientStateDetails.ansvarsskade = clientStateDTO.ansvarsskade;
    clientStateDetails.vehicle.fabrikat = new FabrikatDTO();
    clientStateDetails.vehicle.fabrikat.kode = clientStateDTO.fabrikat;
    if (clientStateDTO.fantomDetails) {
      const fantomDetails = clientStateDTO.fantomDetails;
      if (fantomDetails.fantomFabrikat) {
        clientStateDetails.vehicle.fantomFabrikat = new FabrikatDTO();
        clientStateDetails.vehicle.fantomFabrikat.kode = fantomDetails.fantomFabrikat;
      }
      if (fantomDetails.fantomModel) {
        clientStateDetails.vehicle.fantomModel = new ModelDTO();
        clientStateDetails.vehicle.fantomModel.kode = fantomDetails.fantomModel;
      }
    }

    clientStateDetails.vehicle.model = new ModelDTO();
    clientStateDetails.vehicle.model.kode = clientStateDTO.model;
    clientStateDetails.vehicle.submodel = clientStateDTO.submodel;
    clientStateDetails.vehicle.modelOptions = clientStateDTO.modelOptions;

    clientStateDetails.vehicle.tilstandsType = StringUtils.isEmpty(clientStateDTO.tilstandsType) ? 'I' : clientStateDTO.tilstandsType;
    clientStateDetails.vehicle.nummerpladeType = clientStateDTO.nummerpladeType;
    clientStateDetails.vehicle.handelsvaerdi = clientStateDTO.handelsvaerdi;
    clientStateDetails.vehicle.angivetFabrikat = clientStateDTO.angivetFabrikat;
    clientStateDetails.vehicle.angivetModel = clientStateDTO.angivetModel;
    clientStateDetails.vehicle.vinDetails = VinDetailsDTO.createPossibleVinDetails(clientStateDTO.vinDetails);
    clientStateDetails.taksRapportKey = clientStateDTO.taksRapportKey;
    clientStateDetails.proformaOrdreNummer = clientStateDTO.proformaOrdreNummer;
    clientStateDetails.damageDescription = DamageDescriptionDTO.createDamageDescription(clientStateDTO.damageDescription);
    clientStateDetails.adhocVkDetails = AdhocVkDetailsDTO.createAdhocVkDetails(clientStateDTO.adhocVkDetails);

    if (clientStateDTO.specialkoder) {
      clientStateDetails.specialkoder = clientStateDTO.specialkoder
        .map(item => new Specialkode(item.kode, item.value, item.agreementValue, item.deleted));
    } else {
      clientStateDetails.specialkoder = [];
    }
    clientStateDetails.skadekode = clientStateDTO.skadekode;

    clientStateDetails.lakTimepris = clientStateDTO.lakTimepris;
    clientStateDetails.timepris = clientStateDTO.timepris;
    clientStateDetails.schema = clientStateDTO.schema;

    if (clientStateDTO.skadeDato) {
      clientStateDetails.skadeDato = new NgbForsiDateMomentParserFormatter().parse(clientStateDTO.skadeDato);
    }

    if (clientStateDTO.besigtigelsesDato) {
      clientStateDetails.besigtigelsesDato = new NgbForsiDateMomentParserFormatter().parse(clientStateDTO.besigtigelsesDato);
    }

    if (clientStateDTO.reperationfaerdigDato) {
      clientStateDetails.reperationfaerdigDato = new NgbForsiDateMomentParserFormatter().parse(clientStateDTO.reperationfaerdigDato);
    }
    clientStateDetails.takserForm = clientStateDTO.takserForm;

    clientStateDetails.clientStateVersion = clientStateDTO.clientStateVersion;

    clientStateDetails.vknr = clientStateDTO.vknr;

    if (clientStateDTO.calculationDate) {
      clientStateDetails.calculationDate = new NgbForsiDateMomentParserFormatter().parse(clientStateDTO.calculationDate);
    }

    clientStateDetails.synsgebyr = clientStateDTO.synsgebyr;
    clientStateDetails.nrpladeBeloeb = clientStateDTO.nrpladeBeloeb;
    clientStateDetails.vatInformation = clientStateDTO.vatInformation;

    clientStateDetails.bemaerkninger = clientStateDTO.bemaerkninger;
    clientStateDetails.internInfo = clientStateDTO.internInfo;
    clientStateDetails.skadeanmeldelseModtaget = clientStateDTO.skadeanmeldelseModtaget;
    clientStateDetails.forbehold = clientStateDTO.forbehold;
    clientStateDetails.faktura = clientStateDTO.faktura;
    clientStateDetails.police = clientStateDTO.police;

    if (!isNullOrUndefined(clientStateDTO.valuation)) {
      clientStateDetails.valuation = Valuation.fromDTO(clientStateDTO.valuation);
    } else {
      clientStateDetails.valuation = new Valuation();
    }

    clientStateDetails.damageNumberSetByAssessor = clientStateDTO.damageNumberSetByAssessor;
    clientStateDetails.companySetByAssessor = clientStateDTO.companySetByAssessor;
    clientStateDetails.vkNrOnParentReport = Boolean(clientStateDTO.vkNrOnParentReport);
    clientStateDetails.vehicle.hybridType = clientStateDTO.hybridType;
    clientStateDetails.vehicle.propellantType = clientStateDTO.propellantType;
    return clientStateDetails;
  }

  constructor() {
    this.vehicle = new QapterVehicle();
    this.skavu = new SkavuDetails();

    this.damageDescription = new DamageDescription();
    this.ansvarsskade = false;
    this.forsikringstager = new PoliceInfo();
    this.skadelidte = new PersonInfo();
  }

  public getCarDetails(): CarDetails {
    if (this.elReportDetails) {
      return this.elReportDetails;
    }
    if (this.erReportDetails) {
      return this.erReportDetails;
    }
    if (this.opReportDetails) {
      return this.opReportDetails;
    }
    if (this.krReportDetails) {
      return this.krReportDetails;
    }
    if (this.gbReportDetails) {
      return this.gbReportDetails;
    }
    if (this.skavu) {
      return this.vehicle.getCarDetails();
    }
    if (this.vehicle) {
      return this.vehicle.getCarDetails();
    }

    const result = new CarDetails();
    result.fabrikatKode = null;
    result.fabrikatText = 'Ukendt';
    result.modelKode = null;
    result.modelText = 'Ukendt';
    return result;
  }

  asClientStateDTO(): ClientStateDTO {
    const clientStateDto = new ClientStateDTO();
    clientStateDto.selskab = this.selskab;
    clientStateDto.token = this.token;
    clientStateDto.aftaleArtId = this.aftaleArtId;
    clientStateDto.schema = this.schema;
    clientStateDto.police = this.police;

    if (this.isQapterType()) {
      if (this.vehicle.fabrikat != null) {
        clientStateDto.fabrikat = this.trimmed(this.vehicle.fabrikat.kode);
      }
      const fantomDetails = new FantomDetailsDTO();
      clientStateDto.fantomDetails = fantomDetails;
      if (this.vehicle.fantomFabrikat != null) {
        fantomDetails.fantomFabrikat = this.trimmed(this.vehicle.fantomFabrikat.kode);
      }

      clientStateDto.stelNr = this.vehicle.stelnr;
      if (this.vehicle.model != null) {
        clientStateDto.model = this.trimmed(this.vehicle.model.kode);
      }
      if (this.vehicle.fantomModel != null) {
        fantomDetails.fantomModel = this.trimmed(this.vehicle.fantomModel.kode);
      }
      clientStateDto.kmstand = this.vehicle.kmstand;
      clientStateDto.ansvarsskade = this.ansvarsskade;
      if (this.vehicle.foersteRegDato) {
        clientStateDto.foersteRegDato = new NgbForsiDateMomentParserFormatter().format(this.vehicle.foersteRegDato);
      }
      if (this.vehicle.regNrUdloebDato) {
        clientStateDto.regNrUdloebDato = new NgbForsiDateMomentParserFormatter().format(this.vehicle.regNrUdloebDato);
      }
      clientStateDto.nummerpladeType = this.vehicle.nummerpladeType; // Skal være nummerplade.type
      clientStateDto.tilstandsType = this.vehicle.tilstandsType;

      clientStateDto.aargang = this.vehicle.aargang;
      clientStateDto.handelsvaerdi = this.vehicle.handelsvaerdi;

      clientStateDto.angivetFabrikat = this.vehicle.angivetFabrikat;
      clientStateDto.angivetModel = this.vehicle.angivetModel;
    }
    if (this.isOfType(ReportCategory.SKAVUTAKS)) {
      clientStateDto.tilstandsType = this.vehicle.tilstandsType;
    }

    if (this.isSkavuType()) {
      this.skavu.toDTO(clientStateDto);
      clientStateDto.stelNr = this.vehicle.stelnr;
      clientStateDto.aargang = this.vehicle.aargang;
      clientStateDto.kmstand = this.vehicle.kmstand;
      clientStateDto.ansvarsskade = this.ansvarsskade;
      if (this.vehicle.foersteRegDato) {
        clientStateDto.foersteRegDato = new NgbForsiDateMomentParserFormatter().format(this.vehicle.foersteRegDato);
      }
      clientStateDto.handelsvaerdi = this.vehicle.handelsvaerdi;
      clientStateDto.nummerpladeType = this.vehicle.nummerpladeType; // Skal være nummerplade.type
    }

    if (this.isOfType(ReportCategory.ER)) {
      clientStateDto.erReportDetails = this.erReportDetails.toDTO();
      clientStateDto.aargang = this.vehicle.aargang;
    } else if (this.isOfType(ReportCategory.EL)) {
      clientStateDto.elReportDetails = this.elReportDetails.toDTO();
      clientStateDto.aargang = this.vehicle.aargang;
    } else if (this.isOfType(ReportCategory.KR)) {
      clientStateDto.krReportDetails = this.krReportDetails.toDTO();
      clientStateDto.aargang = this.vehicle.aargang;
    } else if (this.isOfType(ReportCategory.GB)) {
      clientStateDto.gbReportDetails = this.gbReportDetails.toDTO();
      clientStateDto.aargang = this.vehicle.aargang;
    } else if (this.isOfType(ReportCategory.OP)) {
      clientStateDto.opReportDetails = this.opReportDetails.toDTO();
      clientStateDto.aargang = this.vehicle.aargang;
    }

    clientStateDto.forsikringsTagerAddressLine1 = this.forsikringstager.addressLine1;
    clientStateDto.forsikringsTagerAddressLine2 = this.forsikringstager.addressLine2;
    clientStateDto.forsikringsTagerNavn = this.forsikringstager.navn;
    clientStateDto.forsikringsTagerPostNr = this.forsikringstager.postNr;
    clientStateDto.forsikringsTagerRegNr = this.forsikringstager.regNr;
    clientStateDto.forsikringsTagerTelefon = this.forsikringstager.telefonNr;
    clientStateDto.forsikringsTagerPolice = this.forsikringstager.policenr;
    clientStateDto.forsikringsTagerSelvrisiko = this.forsikringstager.selvrisiko;
    clientStateDto.forsikringsTagerSkadenr = this.forsikringstager.skadenr;
    clientStateDto.forsikringsTagerKundenr = this.forsikringstager.kundenr;

    if (this.ansvarsskade) {
      clientStateDto.skadelidteAddressLine1 = this.skadelidte.addressLine1;
      clientStateDto.skadelidteAddressLine2 = this.skadelidte.addressLine2;
      clientStateDto.skadelidteNavn = this.skadelidte.navn;
      clientStateDto.skadelidtePostNr = this.skadelidte.postNr;
      clientStateDto.skadelidteRegNr = this.skadelidte.regNr;
      clientStateDto.skadelidteTelefon = this.skadelidte.telefonNr;
    }

    if (this.leasingselskab) {
      clientStateDto.leasingselskabCvrNr = this.leasingselskab.cvrNr;
      clientStateDto.leasingselskabNavn = this.leasingselskab.navn;
      clientStateDto.leasingselskabAddressLine1 = this.leasingselskab.addressLine1;
      clientStateDto.leasingselskabAddressLine2 = this.leasingselskab.addressLine2;
      clientStateDto.leasingselskabPostalcode = this.leasingselskab.postNr;
      clientStateDto.leasingselskabBy = this.leasingselskab.by;
    }

    if (this.skadeDato) {
      clientStateDto.skadeDato = new NgbForsiDateMomentParserFormatter().format(this.skadeDato);
    }
    if (this.besigtigelsesDato) {
      clientStateDto.besigtigelsesDato = new NgbForsiDateMomentParserFormatter().format(this.besigtigelsesDato);
    }
    if (this.reperationfaerdigDato) {
      clientStateDto.reperationfaerdigDato = new NgbForsiDateMomentParserFormatter().format(this.reperationfaerdigDato);
    }
    clientStateDto.takserForm = this.takserForm;

    clientStateDto.clientStateVersion = this.clientStateVersion;

    //handle vin details
    clientStateDto.vinDetails = VinDetailsDTO.createPossibleVinDetailsDTO(this.vehicle.vinDetails);

    clientStateDto.damageDescription = DamageDescriptionDTO.createDamageDescriptionDTO(this.damageDescription);

    clientStateDto.adhocVkDetails = AdhocVkDetailsDTO.createAdhocVkDetailsDTO(this.adhocVkDetails);

    if (ObjectUtils.exists(this.specialkoder)) {
      clientStateDto.specialkoder = this.specialkoder.map(item => new SpecialkodeDTO(item.kode, item.value === item.agreementValue ? null : item.value, item.agreementValue, item.deleted));
    }
    clientStateDto.skadekode = this.skadekode;

    clientStateDto.lakTimepris = this.lakTimepris;
    clientStateDto.timepris = this.timepris;

    clientStateDto.vknr = this.vknr;

    if (this.calculationDate) {
      clientStateDto.calculationDate = new NgbForsiDateMomentParserFormatter().format(this.calculationDate);
    }

    clientStateDto.synsgebyr = this.synsgebyr;
    clientStateDto.nrpladeBeloeb = this.nrpladeBeloeb;
    clientStateDto.vatInformation = this.vatInformation;

    clientStateDto.bemaerkninger = this.bemaerkninger;
    clientStateDto.internInfo = this.internInfo;
    clientStateDto.skadeanmeldelseModtaget = this.skadeanmeldelseModtaget;
    clientStateDto.forbehold = this.forbehold;
    clientStateDto.faktura = this.faktura;

    if (!isNullOrUndefined(this.valuation)) {
      clientStateDto.valuation = this.valuation.toDTO();
    }
    clientStateDto.hybridType = this.vehicle.hybridType;
    clientStateDto.propellantType = this.vehicle.propellantType;

    return clientStateDto;
  }

  trimmed(s: string): string {
    if (s != null) {
      return s.trim();
    } else {
      return '';
    }
  }

  public isOfType(reportType: ReportCategory): boolean {
    return reportType.schema === this.schema;
  }

  public rapportKey(): string {
    return !StringUtils.isEmpty(this.taksRapportKey) ? this.taksRapportKey : this.proformaOrdreNummer
  }

  public isQapterType(): boolean {
    return this.isAutotaksType() || this.isGlastaksType();
  }

  public isAutotaksType(): boolean {
    return this.isOfType(ReportCategory.AUTOTAKS) || this.isOfType(ReportCategory.AUTOTAKS_LORRY)
      || this.isOfType(ReportCategory.ASSESSMENT_MCTAKS) || this.isOfType(ReportCategory.ASSESSMENT_CAMPTAKS)
      || this.isOfType(ReportCategory.AGRICULTURE_EQPT) || this.isOfType(ReportCategory.ASSESSMENT_BUILDING)
      || this.isOfType(ReportCategory.ENTREPRENEUR_MATR) || this.isOfType(ReportCategory.ASSESSMENT_MOVABLES)
      || this.isOfType(ReportCategory.ASSESSMENT_MISC);
  }

  public isGlastaksType(): boolean {
    return this.isOfType(ReportCategory.GLASTAKS);
  }

  public isSkavuType(): boolean {
    return this.isStortaksType() || this.isMctaksType() || this.isCamptaksType() || this.isUnitaks();
  }

  public isStortaksType(): boolean {
    return this.isOfType(ReportCategory.STORTAKS);
  }

  public isMctaksType(): boolean {
    return this.isOfType(ReportCategory.MCTAKS);
  }

  public isCamptaksType(): boolean {
    return this.isOfType(ReportCategory.CAMPTAKS);
  }

  //skavutaks as a taksator is denoted Unitaks (as it handles all types of skavutaks)
  public isUnitaks(): boolean {
    return this.isOfType(ReportCategory.SKAVUTAKS);
  }

  get valueDeteriorationText(): boolean {
    return this.internInfo.toUpperCase().indexOf(ClientStateDetails.VALUE_DETERIORATION_TEXT) !== -1;
  }

  set valueDeteriorationText(deterioration: boolean) {
    if (deterioration) {
      if (!this.valueDeteriorationText) {
        this.internInfo = ClientStateDetails.VALUE_DETERIORATION_TEXT + '\n' + this.internInfo;
      }
    } else {
      if (this.valueDeteriorationText) {
        this.internInfo = this.internInfo.replace(ClientStateDetails.VALUE_DETERIORATION_TEXT, '');
        if (this.internInfo.indexOf('\n') === 0) {
          this.internInfo = this.internInfo.replace('\n', '');
        }
      }
    }
  }

  public disableFirstRegistrationDate(): boolean {
    return this.vehicle.nummerpladeType === ' ';
  }

  public hasPoliceResult(): boolean {
    return !isNullOrUndefined(this.police) && !this.police.empty;
  }
}
