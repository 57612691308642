import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {REPORT_ROUTES} from 'app/report/report.route';
import {AttachmentModule} from '../shared/ui/attachment/attachment.module';
import {AttachmentApprovalModalComponent} from './ui/attachment-approval/attachment-approval.component';
import {AttachmentApprovalModalService} from './ui/attachment-approval/attachment-approval-modal.service';
import {SharedModule} from 'app/shared/shared.module';
import {ReportDiffComponent} from './diff/report-diff.component';
import {ReportViewerComponent} from './viewer/report-viewer.component';
import {ReportListComponent} from './list/report-list.component';
import {CopyReportModalService} from './ui/copy-report/copy-report-modal.service';
import {CopyReportModalComponent} from './ui/copy-report/copy-report.component';
import {BusinessToBusinessService} from '../b2b/service/business-to-business.service';
import {ReportService} from './service/report.service';
import {NewReportTypeFromReportComponent} from './new-report-type/new-report-type-from-report.component';
import {TaksReportB2bComponent} from './ui/taks-report-b2b/taks-report-b2b.component';
import {DeliveryNoteReportWrapperComponent} from '../shared/ui/delivery-note/delivery-note-report-wrapper.component';
import {EditDeliveryNoteComponent} from '../shared/ui/delivery-note/edit/edit-delivery-note.component';
import {ShowDeliveryNoteComponent} from '../shared/ui/delivery-note/show/show-delivery-note.component';
import {CreateLinkedReportFromReportComponent} from './ui/create-linked-report/create-linked-report-from-report.component';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {ReportCarDetailsComponent} from './viewer/car-details/report-car-details.component';
import {ReportAcceptRespondPopupComponent} from './viewer/report-accept-respond-popup/report-accept-respond-popup.component';
import {ReportAcceptRequestedPopupComponent} from './viewer/report-accept-requested-popup/report-accept-requested-popup.component';
import {LightclientQapterModule} from '../shared/ui/qapter/qapter.module';

@NgModule({
    imports: [
        SharedModule,
        AttachmentModule,
        RouterModule.forRoot(REPORT_ROUTES, {useHash: true}),
        LazyLoadImageModule,
        LightclientQapterModule
    ],
  declarations: [
    ReportListComponent,
    ReportDiffComponent,
    AttachmentApprovalModalComponent,
    CopyReportModalComponent,
    ReportViewerComponent,
    ReportAcceptRespondPopupComponent,
    ReportAcceptRequestedPopupComponent,
    NewReportTypeFromReportComponent,
    TaksReportB2bComponent,
    CreateLinkedReportFromReportComponent,
    DeliveryNoteReportWrapperComponent,
    EditDeliveryNoteComponent,
    ShowDeliveryNoteComponent,
    ReportCarDetailsComponent
  ],
  providers: [
    AttachmentApprovalModalService,
    CopyReportModalService,
    BusinessToBusinessService,
    ReportService,
    TaksReportB2bComponent,
    CreateLinkedReportFromReportComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ReportModule {
}
