import {CustomaftaleDTO} from '../dto/customaftale-dto.model';

export class Customaftale {
  takestId: number;
  tekst: string;

  constructor(customAftaleDTO: CustomaftaleDTO) {
    this.takestId = customAftaleDTO.takestId;
    this.tekst = customAftaleDTO.tekst;
  }
}
