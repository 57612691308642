import {Component, Input, OnDestroy, OnInit} from '@angular/core';

@Component({
  selector: 'lc-spinner-image',
  template: `<img src="{{imgsrc}}" [ngClass]="{'fast-fa-spin': spin}" width="20px" class="align-middle"/><span style="vertical-align: middle; color: #f27e20"><ng-content></ng-content></span>`
})
export class SpinnerImageComponent implements OnInit, OnDestroy {

  @Input() spin: boolean;
  @Input() text: string;
  @Input() imgsrc: string;

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

}
