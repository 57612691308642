export class ModelHelpText {
  header: string;
  validFrom: string;
  text: string;

  validFromAsDate(): Date {
    const date = new Date();
    date.setTime(Date.parse(this.validFrom));
    return date;
  }
}
