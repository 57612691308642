import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ClientStateDetails} from '../..';
import {DraftEditService} from '../draft-edit.service';
import {mergeMap, takeUntil} from 'rxjs/operators';
import {RapportType} from '../../../shared/model/rapport-type.model';
import {ReportCategory} from '../../../shared/model/report-category.model';
import {Subject} from 'rxjs';

@Component({
  selector: 'lc-taks-autotaks',
  templateUrl: './taks-autotaks.component.html'
})
export class TaksAutotaksComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  error = false;
  clientStateDetails: ClientStateDetails;
  reportType: ReportCategory;
  rapportType: RapportType;

  constructor(private draftEditService: DraftEditService,
              private route: ActivatedRoute,
              private router: Router) {
  };

  ngOnInit(): void {
    const draftEditObserver = {
      next: clientStateDetails => {
        this.clientStateDetails = clientStateDetails;
        this.reportType = ReportCategory.getReportCategory(this.clientStateDetails.schema);
        this.rapportType = RapportType.extractFrom(this.clientStateDetails.taksRapportKey);
      },
      error: error => {
        console.log('error', error);
        this.router.navigate(['/draft']);
      }
    };
    this.route.params.pipe(
      mergeMap(params => this.draftEditService.getClientStateObservable(params.token)),
      takeUntil(this.unsubscribe$)
    ).subscribe(draftEditObserver)
  }

  draftIsOfType(reportType: ReportCategory): boolean {
    const draftReportType = this.reportType;
    return reportType.equals(draftReportType) && draftReportType.enabled && draftReportType.supportsRapportType(this.rapportType);
  }

  isTaksAutotaksFlowOne(): boolean {
    return this.draftIsOfType(ReportCategory.AUTOTAKS) || this.draftIsOfType(ReportCategory.AUTOTAKS_LORRY)
      || this.draftIsOfType(ReportCategory.ASSESSMENT_MCTAKS) || this.draftIsOfType(ReportCategory.ASSESSMENT_CAMPTAKS)
      || this.draftIsOfType(ReportCategory.AGRICULTURE_EQPT) || this.draftIsOfType(ReportCategory.ASSESSMENT_BUILDING)
      || this.draftIsOfType(ReportCategory.ENTREPRENEUR_MATR) || this.draftIsOfType(ReportCategory.ASSESSMENT_MOVABLES)
      || this.draftIsOfType(ReportCategory.ASSESSMENT_MISC);
  }

  isTaksSkavuFlowOne(): boolean {
    return this.draftIsOfType(ReportCategory.SKAVUTAKS);
  }

  isTaksErFlow(): boolean {
    return this.draftIsOfType(ReportCategory.ER);
  }

  isTaksElFlow(): boolean {
    return this.draftIsOfType(ReportCategory.EL);
  }

  isTaksKrFlow(): boolean {
    return this.draftIsOfType(ReportCategory.KR);
  }

  isTaksGbFlow(): boolean {
    return this.draftIsOfType(ReportCategory.GB);
  }

  isTaksOpFlow(): boolean {
    return this.draftIsOfType(ReportCategory.OP);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
