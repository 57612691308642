import {Component, EventEmitter, Input, Output} from '@angular/core';
import {LoadingSpinnerUtil} from '../../utils/loading-spinner-util';
import {TimelineMessage} from './timeline-message.model';

@Component({
  selector: 'lc-message-timeline',
  templateUrl: './message-timeline.component.html',
  styleUrls: [
    'message-timeline.scss'
  ],
  providers: [LoadingSpinnerUtil]
})
export class MessageTimelineComponent {
  @Input() addMessageEnabled = true;
  private _messages: TimelineMessage[];
  @Output() onAddMessage: EventEmitter<string> = new EventEmitter();
  @Output() onRefresh: EventEmitter<void> = new EventEmitter();

  newMessage: string;

  constructor(public spinnerUtil: LoadingSpinnerUtil) {
  }

  @Input() set messages(value: TimelineMessage[]) {
    this._messages = value;
    this.spinnerUtil.stopLoading();
  }

  get messages(): TimelineMessage[] {
    return this._messages;
  }

  addMessage(): void {
    this.onAddMessage.emit(this.newMessage);
    this.newMessage = '';
  }

  refreshTimeline(): void {
    this.spinnerUtil.startLoading();
    this.onRefresh.emit();
  }
}
