<lc-bottombar [menu]="bottomMenu" title="Søg" shortTitle="Søg"></lc-bottombar>
<div class="mb-5" *ngIf="principal">
    <div class="row">
        <div class="col-12 col-lg-3"><h1>Søg</h1></div>
        <div class="col d-none d-lg-block"><h1>Resultat</h1></div>
    </div>

    <div class="row">
        <lc-search-input #searchInput class="col-12 col-lg-3"
                         (onSearchByKey)="searchByKey($event)"
                         (onSearchByRegNr)="searchByRegNr($event)"
                         (onSearchByStelnummer)="searchByStelnummer($event)"
                         (onSearchBySkadenummer)="searchBySkadenummer($event)"
                         (onSearchByVkKey)="searchByVkKey($event)"
                         (onChangeSearchType)="searchTypeChanged($event)"
                         (onSearchAssessorCases)="searchAssessorCase($event)"
                         (onFormValid)="formValid($event)"
                         (onDraftsOnlyChanged)="onDraftsOnlyChanged($event)"
                         [orgNr]="principal.orgNr"
                         [tvNr]="principal.atKlientNr"
                         [users]="assessors"
                         [regnr]="regnr"
                         [rappNr]="rappNr"
                         [vkNr]="vkRappNr"
                         [defaultAssessor]="defaultAssessor"
                         [searchType]="searchService.getCurrentSearchType()"
                         [searchAssessorCases]="assessorCasesSearchProps"
        >
        </lc-search-input>

        <div *ngIf="!loading && principal" class="resultcard col">
            <div>
                <div>
                    <h2 class="d-block d-lg-none mt-4">Resultat</h2>
                    <h3 *ngIf="noResult()" class="text-muted">
                        {{noResultMessage}}
                    </h3>

                    <ng-container *ngFor="let case of results;">

                        <div class="card mb-4 p-0">
                            <div class="row p-3">
                                <h4 class="col-sm">{{case.getSagsKeyWithSpaces()}}</h4>

                                <h4 class="col-sm">
                                    <span [ngClass]="{'highlight-result-property' : getResultHighLighter().highlightVin(case.vinForSearchResult)}">{{case.vinForSearchResult}}</span>
                                </h4>
                                <h4 class="col-sm">
                                    <span [ngClass]="{'highlight-result-property' : getResultHighLighter().highlightRegnr(case.regnrForSearchResult)}">{{case.regnrForSearchResult}}</span>
                                </h4>
                                <h4 class="col-sm">
                                    <div class="row">
                                        <div class="col-sm">
                                        <span [ngClass]="{'highlight-result-property' : getResultHighLighter().highlightFabrikat(case.fabrikatTextForSearchResult)}">
                                                {{case.fabrikatTextForSearchResult}} &nbsp;
                                        </span>
                                        <span [ngClass]="{'highlight-result-property' : getResultHighLighter().highlightModel(case.modelTextForSearchResult, case)}">
                                                {{case.modelTextForSearchResult}}
                                        </span></div>
                                    </div>
                                </h4>
                            </div>
                            <ul class="nav nav-tabs nav-fill nav-search">
                                <li class="nav-item" (click)="onDraftTabCLicked(case)">
                                    <a [ngClass]="{'active' : case.draftTabActiveInViewer, 'disabled' : !case.hasDrafts()}"
                                       [ngStyle]="{'text-decoration' : !case.hasDrafts() ? 'line-through': ''}"
                                       class="nav-link "
                                    >Igangværende</a>
                                </li>
                                <li class="nav-item" (click)="onReportTabCLicked(case)">
                                    <a [ngClass]="{'active' : !case.draftTabActiveInViewer, 'disabled' : disableReportsTab(case)}"
                                       [ngStyle]="{'text-decoration' : disableReportsTab(case) ? 'line-through': ''}"
                                       class="nav-link">Rapporter</a>
                                </li>
                            </ul>
                            <div class="result-background p-2">
                                <div *ngIf="case.draftTabActiveInViewer">
                                    <div>
                                        <table class="table table-hover table-borderless">
                                            <ng-container
                                                *ngTemplateOutlet="innerDraftSearchResultTable; context:{case:case}">
                                            </ng-container>
                                        </table>
                                    </div>
                                </div>
                                <div *ngIf="!case.draftTabActiveInViewer">
                                    <div>
                                        <table class="table table-hover table-borderless">
                                            <ng-container
                                                *ngTemplateOutlet="innerReportSearchResultTable; context:{case:case}">
                                            </ng-container>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <ng-template #innerDraftSearchResultTable let-case="case">
                    <thead>
                    <tr style="border-top: none; border-end: none; border-start: none; ">
                        <th class="d-none d-md-block w-10-pct">Selskab</th>
                        <th class="w-10-pct">Status</th>
                        <th class="w-20-pct">Rapportnøgle</th>
                        <th class="d-none d-md-block w-20-pct">Opdateret</th>
                        <th class="w-15-pct">Beløb</th>
                        <th class="d-none d-md-block w-15-pct">Håndteret af</th>
                        <th class="w-10-pct"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let clientState of case.clientStates" class="search-result" style="border: none">
                        <td class="text-start d-none d-md-block d-md-table-cell text-start w-10-pct ps-0">{{clientState.selskab}}</td>
                        <td class="text-start w-10-pct ps-0"></td>
                        <td class="text-start w-20-pct ps-0">
                                <span [ngClass]="{'highlight-result-property' : getResultHighLighter().highlightSearchReportKey(clientState.reportKey, vkRappNr, rappNr) }">
                                    {{getCompanyId(clientState.reportKey)}}
                                </span>
                                <span [ngClass]="{'highlight-result-property' : getResultHighLighter().highlightVkNrOrAssessorNr(clientState.reportKey, vkRappNr, rappNr)}">
                                    {{getVkOrAssessorNr(clientState.reportKey)}}
                                </span>
                                <span [ngClass]="{'highlight-result-property' : getResultHighLighter().highlightSearchReportKey(clientState.reportKey, vkRappNr, rappNr)}">
                                    {{getReportnr(clientState.reportKey)}}
                                </span>
                        </td>
                        <td class="d-none d-md-block d-md-table-cell text-start w-20-pct ps-0">
                            <div [ngClass]="{'highlight-result-property' : getResultHighLighter().highlightDate()}">
                                {{clientState.updatedAt | date: 'dd-MM-yyyy HH:mm'}}
                            </div>
                        </td>
                        <td class="text-start w-15-pct ps-0">{{ clientState.beloeb }}</td>
                        <td class=" d-none d-md-block d-lg-table-cell text-start w-15-pct ps-0"></td>
                        <td class="text-start table-cell w-10-pct ps-0">
                            <div class="pull-right">
                                <div class="table-button" (click)="onDraftClick(clientState)">
                                    <i class="fa fa-2x"
                                       [ngClass]="{'fa-eye': !isOwnClientState(clientState), 'fa-edit': isOwnClientState(clientState)}"></i>
                                </div>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </ng-template>
                <ng-template #innerReportSearchResultTable let-case="case">
                    <thead>
                    <tr style="border-top: none; border-end: none; border-start: none">
                        <th class="d-none d-md-block w-10-pct">Selskab</th>
                        <th class="w-10-pct">Status</th>
                        <th class="w-20-pct">Rapportnøgle</th>
                        <th class="d-none d-md-block w-20-pct">Opdateret</th>
                        <th class="w-15-pct">Beløb</th>
                        <th class="d-none d-md-block w-15-pct">Håndteret af</th>
                        <th class="w-10-pct"></th>
                    </tr>
                    </thead>
                    <tbody>

                    <tr *ngFor="let report of case.reports" class="search-result" style="border: none">
                        <td class=" d-none d-md-block d-md-table-cell text-start w-10-pct ps-0">{{report.selskab}}</td>
                        <td class="text-start w-10-pct ps-0">{{report.state}}</td>
                        <td class="text-start w-20-pct ps-0">
                                <span [ngClass]="{'highlight-result-property' : getResultHighLighter().highlightSearchReportKey(report.reportKey, vkRappNr, rappNr)}">
                                    {{getCompanyId(report.reportKey)}}
                                </span>
                                <span [ngClass]="{'highlight-result-property' : getResultHighLighter().highlightVkNrOrAssessorNr(report.reportKey, vkRappNr, rappNr)} ">
                                    {{getVkOrAssessorNr(report.reportKey)}}
                                </span>
                                <span [ngClass]="{'highlight-result-property' : getResultHighLighter().highlightSearchReportKey(report.reportKey, vkRappNr, rappNr)}">
                                    {{getReportnr(report.reportKey)}}
                                </span>
                        </td>
                        <td class=" d-none d-md-block d-md-table-cell text-start w-20-pct ps-0">
                            <div [ngClass]="{'highlight-result-property' : getResultHighLighter().highlightDate()}">
                                {{report.updatedAt | date: 'dd-MM-yyyy HH:mm'}}
                            </div>
                        </td>
                        <td class="text-start w-15-pct ps-0">{{ report.beloeb }}</td>
                        <td class=" d-none d-md-block d-md-table-cell text-start w-15-pct ps-0"
                            title={{report.modtagerNr}}>{{report.modtagerNr}}</td>
                        <td class="text-start table-button w-10-pct ps-0">
                            <div class="pull-right">
                                <div class="table-button" (click)="showReport(report)"><i
                                    class="fa fa-eye fa-2x"></i>
                                </div>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </ng-template>
            </div>
        </div>
    </div>
</div>
