import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {NonStandardPositionRepairMethodDTO} from '../dto/non-standard-position/non-standard-position-repair-method-dto.model';
import {HttpClient} from '@angular/common/http';
import {CoreNonStandardPositionDTO, NonStandardPositionDTO} from '../dto/non-standard-position/non-standard-position-dto.model';

@Injectable({
  providedIn: 'root',
})
export class NonStandardPositionsService {
  private rootUrl = 'non-standard-positions';

  constructor(private http: HttpClient) {
  }
  
  getPrivateNonStandardPositions(): Observable<NonStandardPositionDTO[]> {
    return this.http.get<NonStandardPositionDTO[]>(this.rootUrl + '/private');
  }
  
  createPrivateNonStandardPosition(nonStandardPosition: NonStandardPositionDTO): Observable<NonStandardPositionDTO> {
    return this.http.post<NonStandardPositionDTO>(this.rootUrl + '/private', nonStandardPosition);
  }

  importPrivateNonStandardPosition(coreNonStandardPositionDTOs: CoreNonStandardPositionDTO[]): Observable<NonStandardPositionDTO[]> {
    return this.http.post<NonStandardPositionDTO[]>(this.rootUrl + '/private/import', coreNonStandardPositionDTOs);
  }

  updatePrivateNonStandardPosition(nonStandardPosition: NonStandardPositionDTO): Observable<NonStandardPositionDTO> {
    return this.http.put<NonStandardPositionDTO>(this.rootUrl + '/private', nonStandardPosition);
  }

  deletePrivateNonStandardPosition(nonStandardPositionItemId: string): Observable<any> {
    return this.http.delete<any>(this.rootUrl + '/private/' + nonStandardPositionItemId);
  }

  getProvidedNonStandardPositions(): Observable<NonStandardPositionDTO[]> {
    return this.http.get<NonStandardPositionDTO[]>(this.rootUrl + '/provided');
  }
  
  getAssessorOrganisationNonStandardPositions(): Observable<NonStandardPositionDTO[]> {
    return this.http.get<NonStandardPositionDTO[]>(this.rootUrl + '/assessor-organisation');
  }

  getNonStandardPositionRepairMethods(): Observable<NonStandardPositionRepairMethodDTO[]> {
    return this.http.get<NonStandardPositionRepairMethodDTO[]>(this.rootUrl + '/repair-method');
  }

  createAssessorOrganisationNonStandardPosition(createAssessorOrganisationNonStandardPosition: NonStandardPositionDTO): Observable<NonStandardPositionDTO> {
    return this.http.post<NonStandardPositionDTO>(this.rootUrl + '/assessor-organisation', createAssessorOrganisationNonStandardPosition);
  }

  updateAssessorOrganisationNonStandardPosition(updateAssessorOrganisationNonStandardPosition: NonStandardPositionDTO): Observable<NonStandardPositionDTO> {
    return this.http.put<NonStandardPositionDTO>(this.rootUrl + '/assessor-organisation', updateAssessorOrganisationNonStandardPosition);
  }

  deleteAssessorOrganisationNonStandardPosition(assessorOrganisationNonStandardPositionId: number): Observable<any> {
    return this.http.delete<any>(this.rootUrl + '/assessor-organisation/' + assessorOrganisationNonStandardPositionId);
  }
}
