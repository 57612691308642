import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {QapterPrepareModeDTO} from '../../../dto/qapter-prepare-mode-dto.model';
import URLBuilder from '../../../utils/url-builder';
import {Observable, Subject} from 'rxjs';
import {isNullOrUndefined} from '../../../utils/object-utils';
import {delay, takeUntil, tap} from 'rxjs/operators';

export interface QapterStartModel {
  qapterPrepareMode: QapterPrepareModeDTO,
  returnURL: string
}

/**
 * Responsible for changing client url address to the Qapter application.
 * This is performed as a form submit
 */
@Component({
  selector: 'lc-qapter-start',
  templateUrl: './qapter-start.component.html'
})
export class QapterStartComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  public submitUrlToQapter: string;
  public authorizationToken: string;

  @Input('startQapter$') public startQapter$: Observable<QapterStartModel>;
  @ViewChild('qapterSubmitForm', {static: false}) qapterSubmitForm: ElementRef;

  ngOnInit(): void {
    this.setupObservable();
  }

  private setupObservable(): void {
    if (!isNullOrUndefined(this.startQapter$)) {
      this.startQapter$.pipe(
        tap(qapterStartModel => {
          const urlBuilder = new URLBuilder();
          this.submitUrlToQapter = urlBuilder.constructQapterSubmitURL(
            qapterStartModel.qapterPrepareMode.xrecordsId,
            qapterStartModel.qapterPrepareMode.username,
            qapterStartModel.qapterPrepareMode.readonly,
            qapterStartModel.qapterPrepareMode.classFabrikatKode, qapterStartModel.qapterPrepareMode.classModelKode,
            qapterStartModel.returnURL,
            qapterStartModel.qapterPrepareMode.priceVersion,
            qapterStartModel.qapterPrepareMode.paintSystem);
          this.authorizationToken = qapterStartModel.qapterPrepareMode.authorization;
        }),
        //to ensure the authorization and submiturl is set in the form before we submit, we introduce a small tick delay
        delay(1),
        takeUntil(this.unsubscribe$)
      ).subscribe(() => {
        this.qapterSubmitForm.nativeElement.action = this.submitUrlToQapter;
        this.qapterSubmitForm.nativeElement.submit();
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
