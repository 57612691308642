import {ElDetailsDTO} from '../../shared/dto/el-details-dto.model';
import {Flow2CarDetails} from './flowtwo-car-details.model';
import {BankDetails} from './bank.details';
import {applyMixins} from 'rxjs/internal/util/applyMixins';

export class ElDetails extends Flow2CarDetails implements BankDetails {
  bankKontonr: string;
  bankRegnr: string;
  nemkonto: boolean;
  validate: () => void;
  isBankRequired: () => boolean;

  selskab: string;
  moms: boolean;
  erstatningsBeloeb: number;
  erstatningsModtagerNavn: string;
  erstatningsModtagerAdresse1: string;
  erstatningsModtagerAdresse2: string;
  erstatningsModtagerTlf: string;
  erstatningsModtagerPostalCode: string;

  static fromDTO(dto: ElDetailsDTO): ElDetails {
    const details = new ElDetails();
    if (dto) {
      details.selskab = dto.selskab;
      details.bankRegnr = dto.bankRegnr;
      details.bankKontonr = dto.bankKontonr;
      details.nemkonto = dto.nemkonto;
      details.fabrikatKode = dto.fabrikatKode;
      details.fabrikatText = dto.fabrikatText;
      details.modelKode = dto.modelKode;
      details.modelText = dto.modelText;
      details.resterTilhoerSelskab = dto.resterTilhoerSelskab;
      details.editableFabrikat = dto.tekst1;
      details.editableModel = dto.tekst2;
      details.moms = dto.moms;
      details.erstatningsBeloeb = dto.erstatningsBeloeb;
      details.erstatningsModtagerNavn = dto.erstatningsModtagerNavn;
      details.erstatningsModtagerAdresse1 = dto.erstatningsModtagerAdresse1;
      details.erstatningsModtagerAdresse2 = dto.erstatningsModtagerAdresse2;
      details.erstatningsModtagerTlf = dto.erstatningsModtagerTlf;
      details.erstatningsModtagerPostalCode = dto.erstatningsModtagerPostalCode;
    }
    return details;
  }

  toDTO(): ElDetailsDTO {
    const dto = new ElDetailsDTO();
    dto.selskab = this.selskab;
    dto.bankRegnr = this.bankRegnr;
    dto.bankKontonr = this.bankKontonr;
    dto.nemkonto = this.nemkonto;
    dto.fabrikatKode = this.fabrikatKode;
    dto.fabrikatText = this.fabrikatText;
    dto.modelKode = this.modelKode;
    dto.modelText = this.modelText;
    dto.resterTilhoerSelskab = this.resterTilhoerSelskab;
    dto.tekst1 = this.editableFabrikat;
    dto.tekst2 = this.editableModel;
    dto.moms = this.moms;
    dto.erstatningsBeloeb = this.erstatningsBeloeb;
    dto.erstatningsModtagerNavn = this.erstatningsModtagerNavn;
    dto.erstatningsModtagerAdresse1 = this.erstatningsModtagerAdresse1;
    dto.erstatningsModtagerAdresse2 = this.erstatningsModtagerAdresse2;
    dto.erstatningsModtagerTlf = this.erstatningsModtagerTlf;
    dto.erstatningsModtagerPostalCode = this.erstatningsModtagerPostalCode;
    return dto;
  }
}

applyMixins(ElDetails, [BankDetails]);
