import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SettingsComponent} from './';
import {SETTINGS_ROUTES} from 'app/settings/settings.route';
import {SharedCommonModule} from 'app/shared/shared-common.module';
import {SettingsBroadcastComponent} from './ui/settingsbroadcast/settings.broadcast.component';
import {SettingsSupportComponent} from './ui/settingssupport/settings.support.component';
import {SettingsPrintlayoutComponent} from './ui/settingsprintlayout/settings.printlayout.component';
import {SettingsDeliverynoteComponent} from './ui/settingsdeliverynote/settings.deliverynote.component';
import {SettingsFunctionComponent} from './ui/settingsfunction/settings.function.component';
import {SettingsPostboxComponent} from './ui/settingspostbox/settings.postbox.component';
import {SharedModule} from 'app/shared/shared.module';
import {SettingsMiscellaneousComponent} from './ui/settingsmiscellaneous/settings.miscellaneous.component';
import {SettingsPostboxRowComponent} from './ui/settingspostbox/ui/settings-postbox-row.component';

@NgModule({
  imports: [
    SharedModule,
    SharedCommonModule,
    RouterModule.forRoot(SETTINGS_ROUTES, {useHash: true})
  ],
  declarations: [
    SettingsComponent,
    SettingsBroadcastComponent,
    SettingsSupportComponent,
    SettingsPrintlayoutComponent,
    SettingsDeliverynoteComponent,
    SettingsMiscellaneousComponent,
    SettingsFunctionComponent,
    SettingsPostboxComponent,
    SettingsPostboxRowComponent
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SettingsModule {
}
