<ng-container *ngIf="damagesNoteText; else damagesIcon">
	<span class="clickable d-sm-block d-lg-none post-it pe-0"
          style="width:60px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
          (click)="showDamagesNote()" [ngbPopover]="popContent"
          triggers="mouseenter:mouseleave" placement="right">
		{{damagesNoteText}}
	</span>
    <span class="clickable d-none d-lg-block post-it pe-0"
          style="width:110px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
          (click)="showDamagesNote()" [ngbPopover]="popContent"
          triggers="mouseenter:mouseleave" placement="right">
		{{damagesNoteText}}
	</span>
</ng-container>

<ng-template #damagesIcon>
    <i class="clickable fa fa-sticky-note-o fa-2x"
       (click)="showDamagesNote()" [ngbPopover]="popContent"
       triggers="mouseenter:mouseleave" placement="right">
    </i>
</ng-template>

<ng-template #popContent>
    <ng-container *ngIf="damagesNoteText">
        <textarea class="post-it" rows="1" disabled lcAutosize>{{damagesNoteText}}</textarea>
    </ng-container>
    <ng-container *ngIf="!damagesNoteText">
        <p>Ingen skadenotat</p>
    </ng-container>
</ng-template>
