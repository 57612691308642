<ng-container *ngIf="deliveryNoteTemplateData">
    <lc-context-menu [menu]="this.contextMenu"></lc-context-menu>
    <div class="split-page">
        <div class="split-left d-none d-lg-block">&nbsp;</div>
        <div class="split-right">
            <div id="masterdata">
                <div class="row mt-4">
                    <div class="col-12">
                        <h2>Følgeseddel</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <h2>Til</h2>
                        <div class="card">
                            <div class="card-body">
                                <div class="row pb-3">
                                    <div class="col-lg-6">
                                        <div class="me-3 input-group">
                                            <label for="date">Dato</label>
                                            <input class="form-control ms-4" type="tel" id="date" ngbDatepicker
                                                #dateDatePicker="ngbDatepicker" lcInputMask="date"
                                                [(ngModel)]="deliveryNoteTemplateOptions.date"
                                                [minDate]="{ 'year': 1900, 'month': 1, 'day': 1 }"
                                                [maxDate]="{ 'year': 2099, 'month': 12, 'day': 31}">
                                            <button class="btn btn-outline-secondary" type="button"
                                                (click)="dateDatePicker.toggle()">
                                                <i class="fa fa-calendar fa-lg"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            <div class="row mt-2">
                                <div class="col-12">
                                    <div class="btn-group btn-group-toggle">
                                        <ng-container
                                            *ngFor="let deliveryNoteReceiver of deliveryNoteTemplateData.receiverTypes">
                                            <label class="btn btn-secondary btn-sm"
                                                [ngClass]="{'active': activeDeliveryNoteReceiver===deliveryNoteReceiver.type}">
                                                <input type="radio" name="to" class="visually-hidden" value="{{deliveryNoteReceiver.type}}"
                                                    [(ngModel)]="activeDeliveryNoteReceiver">{{deliveryNoteReceiver.name}}
                                            </label>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div class="row pb-3 mt-2" *ngIf="isOtherInput()">
                                <div class="col-12">
                                    <label>
                                        Værksteds Nummer
                                        <input class="form-control" type="tel" lcInputMask="vkNr"
                                            [(ngModel)]="repairShopNumber" />
                                    </label>
                                </div>
                            </div>
                            <div class="row pb-3 mt-2">
                                <div class="col-lg-6 me-3">
                                    <input class="form-control" [(ngModel)]="deliveryNoteReceiverTemplateData.line1" />
                                </div>
                                <div class="col-lg-6 me-3">
                                    <input class="form-control" [(ngModel)]="deliveryNoteReceiverTemplateData.line2" />
                                </div>
                                <div class="col-lg-6 me-3">
                                    <input class="form-control" [(ngModel)]="deliveryNoteReceiverTemplateData.line3" />
                                </div>
                                <div class="col-lg-6 me-3">
                                    <input class="form-control" [(ngModel)]="deliveryNoteReceiverTemplateData.line4" />
                                </div>
                                <div class="col-lg-6 me-3">
                                    <input class="form-control" [(ngModel)]="deliveryNoteReceiverTemplateData.line5" />
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <h2>Vedr.</h2>
                        <div class="card">
                            <div class="card-body">
                                <div class="row pt-2">
                                    <div class="col-lg-2"><b>Selskab</b></div>
                                    <div class="col-lg-10">{{deliveryNoteTemplateData.subject.line1}}</div>
                                    <div class="col-lg-2"><b>{{deliveryNoteTemplateData.subject.line2Label}}</b></div>
                                    <div class="col-lg-10">{{deliveryNoteTemplateData.subject.line2}}</div>
                                    <div class="col-lg-2"><b>Reg.nr.</b></div>
                                    <div class="col-lg-10">{{deliveryNoteTemplateData.subject.line3}}</div>
                                    <div class="col-lg-2"><b>Fabrikat/model</b></div>
                                    <div class="col-lg-10">{{deliveryNoteTemplateData.subject.line4}}</div>
                                    <div class="col-lg-2"><b>Rapport nr.</b></div>
                                    <div class="col-lg-10">{{deliveryNoteTemplateData.subject.line5}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4" id="declaration-section">
                <div class="col-12">
                    <h2>Erklæring/attest</h2>
                    <div class="card">
                        <div class="card-body">
                            <div class="row pb-3">
                                <div class="col-12">
                                    <label>
                                        <input type="checkbox" class="mt-0"
                                               [(ngModel)]="deliveryNoteTemplateOptions.enableForwardOptions" />
                                        {{deliveryNoteTemplateOptions.enableForwardOptionsText}}
                                    </label>
                                </div>
                            </div>
                            <div class="row pb-3">
                                <div class="col-12">
                                    <label class="ms-4">
                                        <input type="checkbox" class="mt-0"
                                               [(ngModel)]="deliveryNoteTemplateOptions.registrationCertificate"
                                               [disabled]="!deliveryNoteTemplateOptions.enableForwardOptions" />
                                        {{deliveryNoteTemplateOptions.registrationCertificateText}}
                                    </label>
                                </div>
                            </div>
                            <div class="row pb-3">
                                <div class="col-12">
                                    <label class="ms-4">
                                        <input type="checkbox" class="mt-0"
                                               [(ngModel)]="deliveryNoteTemplateOptions.receiptOfCancellationOfLicencePlate"
                                               [disabled]="!deliveryNoteTemplateOptions.enableForwardOptions" />
                                        {{deliveryNoteTemplateOptions.receiptOfCancellationOfLicencePlateText}}
                                    </label>
                                </div>
                            </div>
                            <div class="row pb-3">
                                <div class="col-12">
                                    <label class="ms-4">
                                        <input type="checkbox" class="mt-0"
                                               [(ngModel)]="deliveryNoteTemplateOptions.contractNote"
                                               [disabled]="!deliveryNoteTemplateOptions.enableForwardOptions" />
                                        {{deliveryNoteTemplateOptions.contractNoteText}}
                                    </label>
                                </div>
                            </div>
                            <div class="row pb-3">
                                <div class="col-12">
                                    <label>
                                        <input type="checkbox" class="mt-0"
                                               [(ngModel)]="deliveryNoteTemplateOptions.attachReportFrontPage" />
                                        {{deliveryNoteTemplateOptions.attachReportFrontPageText}}
                                    </label>
                                </div>
                            </div>
                            <div class="row pb-3">
                                <div class="col-12">
                                    <label>
                                        <input type="checkbox" class="mt-0"
                                               [(ngModel)]="deliveryNoteTemplateOptions.attachEr" />
                                        {{deliveryNoteTemplateOptions.attachErText}}
                                    </label>
                                </div>
                            </div>
                            <div class="row pb-3">
                                <div class="col-12">
                                    <label>
                                        <input type="checkbox" class="mt-0"
                                               [(ngModel)]="deliveryNoteTemplateOptions.attachOpOrGb" />
                                        {{deliveryNoteTemplateOptions.attachOpOrGbText}}
                                    </label>
                                </div>
                            </div>
                            <div class="row pb-3">
                                <div class="col-12">
                                    <label>
                                        <input type="checkbox" class="mt-0"
                                               [(ngModel)]="deliveryNoteTemplateOptions.attachCanceledRegistrationCertificate" />
                                        {{deliveryNoteTemplateOptions.attachCanceledRegistrationCertificateText}}
                                    </label>
                                </div>
                            </div>
                            <div class="row pb-3">
                                <div class="col-12">
                                    <label>
                                        <input type="checkbox" class="mt-0"
                                               [(ngModel)]="deliveryNoteTemplateOptions.attachErSignature" />
                                        {{deliveryNoteTemplateOptions.attachErSignatureText}}
                                    </label>
                                </div>
                            </div>
                            <div class="row pb-3">
                                <div class="col-12">
                                    <label>
                                        <input type="checkbox" class="mt-0"
                                               [(ngModel)]="deliveryNoteTemplateOptions.chargedByInsuranceCompany" />
                                        {{deliveryNoteTemplateOptions.chargedByInsuranceCompanyText}}
                                    </label>
                                </div>
                            </div>
                            <div class="row pb-3">
                                <div class="col-12">
                                    <label>
                                        <input type="checkbox" class="mt-0"
                                               [(ngModel)]="deliveryNoteTemplateOptions.checkForAmountForSaleOfSpareParts" />
                                        {{getCheckForAmountForSaleOfSparePartsText()}}
                                    </label>
                                </div>
                            </div>
                            <div class="row pb-3">
                                <div class="col-lg-6 me-3">
                                    <input class="form-control ms-4" type="tel" minlength=1 lcInputMask="numbersOnly"
                                           [disabled]="!deliveryNoteTemplateOptions.checkForAmountForSaleOfSpareParts"
                                           [(ngModel)]="deliveryNoteTemplateOptions.checkForAmountForSaleOfSparePartsAmount" />
                                </div>
                            </div>
                            <div class="row pb-3">
                                <div class="col-12">
                                    <label>
                                        <input type="checkbox" class="mt-0"
                                               [(ngModel)]="deliveryNoteTemplateOptions.attachPrintOfCarBook" />
                                        {{deliveryNoteTemplateOptions.attachPrintOfCarBookText}}
                                    </label>
                                </div>
                            </div>
                            <div class="row pb-3">
                                <div class="col-12">
                                    <label>
                                        <input type="checkbox" class="mt-0"
                                               [(ngModel)]="deliveryNoteTemplateOptions.attachCertificateOfScrap" />
                                        {{deliveryNoteTemplateOptions.attachCertificateOfScrapText}}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4" id="message-section">
                <div class="col-12">
                    <h2>Besked</h2>
                    <div class="card">
                        <div class="card-body">
                            <div class="row pb-3">
                                <div class="col-12">
                                    <label>
                                        <input type="checkbox" class="mt-0"
                                               [(ngModel)]="deliveryNoteTemplateOptions.accordingToAgreement" />
                                        {{deliveryNoteTemplateOptions.accordingToAgreementText}}
                                    </label>
                                </div>
                            </div>
                            <div class="row pb-3">
                                <div class="col-12">
                                    <label>
                                        <input type="checkbox" class="mt-0"
                                               [(ngModel)]="deliveryNoteTemplateOptions.accordingToLetterOrPhone" />
                                        {{getAccordingToLetterOrPhoneText()}}
                                    </label>
                                </div>
                            </div>
                            <div class="row pb-3">
                                <div class="col-lg-6 me-3">
                                    <div class="input-group">
                                        <input class="form-control ms-4" type="tel" ngbDatepicker
                                               #accordingToLetterOrPhoneDatePicker="ngbDatepicker" lcInputMask="date"
                                               [(ngModel)]="deliveryNoteTemplateOptions.accordingToLetterOrPhoneDate"
                                               [minDate]="{ 'year': 1900, 'month': 1, day: 1}"
                                               [maxDate]="{ 'year': 2099, 'month': 12, day: 31}"
                                               [disabled]="!deliveryNoteTemplateOptions.accordingToLetterOrPhone">
                                        <button class="btn btn-outline-secondary" type="button"
                                                (click)="accordingToLetterOrPhoneDatePicker.toggle()"
                                                [disabled]="!deliveryNoteTemplateOptions.accordingToLetterOrPhone">
                                            <i class="fa fa-calendar fa-lg"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="row pb-3">
                                <div class="col-12">
                                    <label>
                                        <input type="checkbox" class="mt-0"
                                               [(ngModel)]="deliveryNoteTemplateOptions.forSignature" />
                                        {{deliveryNoteTemplateOptions.forSignatureText}}
                                    </label>
                                </div>
                            </div>
                            <div class="row pb-3">
                                <div class="col-12">
                                    <label>
                                        <input type="checkbox" class="mt-0"
                                               [(ngModel)]="deliveryNoteTemplateOptions.forYourApproval" />
                                        {{deliveryNoteTemplateOptions.forYourApprovalText}}
                                    </label>
                                </div>
                            </div>
                            <div class="row pb-3">
                                <div class="col-12">
                                    <label>
                                        <input type="checkbox" class="mt-0"
                                               [(ngModel)]="deliveryNoteTemplateOptions.requestToBeReturned" />
                                        {{deliveryNoteTemplateOptions.requestToBeReturnedText}}
                                    </label>
                                </div>
                            </div>
                            <div class="row pb-3">
                                <div class="col-12">
                                    <label>
                                        <input type="checkbox" class="mt-0"
                                               [(ngModel)]="deliveryNoteTemplateOptions.returningWithAThankYou" />
                                        {{deliveryNoteTemplateOptions.returningWithAThankYouText}}
                                    </label>
                                </div>
                            </div>
                            <div class="row pb-3">
                                <div class="col-12">
                                    <label>
                                        <input type="checkbox" class="mt-0"
                                               [(ngModel)]="deliveryNoteTemplateOptions.forYourInformation" />
                                        {{deliveryNoteTemplateOptions.forYourInformationText}}
                                    </label>
                                </div>
                            </div>
                            <div class="row pb-3">
                                <div class="col-12">
                                    <label>
                                        <input type="checkbox" class="mt-0"
                                               [(ngModel)]="deliveryNoteTemplateOptions.vehicleCanceled" />
                                        {{getVehicleCanceledText()}}
                                    </label>
                                </div>
                            </div>
                            <div class="row pb-3">
                                <div class="col-lg-6 me-3">
                                    <div class=" input-group">
                                        <input class="form-control ms-4" type="tel" ngbDatepicker
                                               #vehicleCanceledDatePicker="ngbDatepicker" lcInputMask="date"
                                               [(ngModel)]="deliveryNoteTemplateOptions.vehicleCanceledDate"
                                               [minDate]="{ 'year': 1900, 'month': 1, day: 1}"
                                               [maxDate]="{ 'year': 2099, 'month': 12, day: 1}"
                                               [disabled]="!deliveryNoteTemplateOptions.vehicleCanceled">
                                        <button class="btn btn-outline-secondary" type="button"
                                                (click)="vehicleCanceledDatePicker.toggle()"
                                                [disabled]="!deliveryNoteTemplateOptions.vehicleCanceled">
                                            <i class="fa fa-calendar fa-lg"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="row pb-3">
                                <div class="col-12">
                                    <label>
                                        <input type="checkbox" class="mt-0"
                                               [(ngModel)]="deliveryNoteTemplateOptions.callOn" />
                                        {{getCallOnText()}}
                                    </label>
                                </div>
                            </div>
                            <div class="row pb-3">
                                <div class="col-lg-6 me-3">
                                    <input class="form-control ms-4" type="text"
                                           [disabled]="!deliveryNoteTemplateOptions.callOn"
                                           [(ngModel)]="deliveryNoteTemplateOptions.callOnNumber" />
                                </div>
                            </div>
                            <div class="row pb-3">
                                <div class="col-lg-6 me-3">
                                    <label class="ms-4">
                                        <input type="checkbox" class="mt-0"
                                               [(ngModel)]="deliveryNoteTemplateOptions.callOnBetweenTimes"
                                               [disabled]="!deliveryNoteTemplateOptions.callOn" />
                                        {{getCallOnBetweenTimesText()}}
                                    </label>
                                </div>
                            </div>
                            <div class="row pb-3">
                                <div class="col-lg-6 me-3">
                                    <label class="ms-4">
                                        From
                                        <ngb-timepicker [(ngModel)]="deliveryNoteTemplateOptions.callOnBetweenTimesFrom"
                                                        [spinners]="false" [disabled]="!deliveryNoteTemplateOptions.callOnBetweenTimes">
                                        </ngb-timepicker>
                                    </label>
                                    <label class="ms-4">
                                        To
                                        <ngb-timepicker [(ngModel)]="deliveryNoteTemplateOptions.callOnBetweenTimesTo"
                                                        [spinners]="false" [disabled]="!deliveryNoteTemplateOptions.callOnBetweenTimes">
                                        </ngb-timepicker>
                                    </label>
                                </div>
                            </div>
                            <div class="row pb-3">
                                <div class="col-12">
                                    <label>
                                        <input type="checkbox" class="mt-0"
                                               [(ngModel)]="deliveryNoteTemplateOptions.requestToHaveAttachedDeclarationReturned" />
                                        {{deliveryNoteTemplateOptions.requestToHaveAttachedDeclarationReturnedText}}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4" id="remarks-section">
                <div class="col-12">
                    <h2>
                        <span>Bemærkning</span>
                    </h2>
                    <div class="row">
                        <div class="col">
                            <div class="float-end">
                                <lc-org-text-library-button *lcHasAnyAuthority="'ROLE_Taks'" class="ms-2" [libraryType]="libraryType" [disabled]="!reportType" [reportType]="reportType" (selectedText)="didSelectTextFromLibrary($event)"></lc-org-text-library-button>
                                <button type="button" class="btn btn-sm btn-primary ms-2" (click)="showTextLibrary()">Tekstbibliotek</button>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col pb-3">
                        <textarea [(ngModel)]="deliveryNoteStateService.remarks" #remarksText id="remarksText"
                                  name="remarksText" class="form-control monospace-text" rows="12"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<div style="height: 60px;">&nbsp;</div>
<lc-bottombar [menu]="bottomMenu"></lc-bottombar>
