export class SearchReportState {

  constructor(private label: string, private code: string) {
  }

  public getLabel(): string {
    return this.label;
  }

  public getCode(): string {
    return this.code;
  }
}

export class SearchReportStates {
  private states: SearchReportState[] = [
    new SearchReportState('Vælg rapport status', null),
    new SearchReportState('Advisering', 'ADVIS'),
    new SearchReportState('Godkendt', 'GODKENDT'),
    new SearchReportState('Godkendt med forbehold', 'GODKENDT_M_FORBEHOLD'),
    new SearchReportState('Godkendt med ændringer', 'GODKENDT_M_AENDRINGER'),
    new SearchReportState('Afvist', 'AFVIST'),
    new SearchReportState('Annulleret', 'ANNULLERET'),
    new SearchReportState('Overtaget', 'OVERTAGET'),
    new SearchReportState('Solgt til genopbygning', 'SOLGT_TIL_GENOPBYGNING'),
    new SearchReportState('Totalskade', 'TOTALSKADE')
  ];

  public getStates(): SearchReportState[] {
    return this.states;
  }
}

