import {VkStamDTO} from '../dto/vk-stam.dto.model';
import {Customaftale} from './customaftale.model';

export class VkStam {
  id: number;
  vkNr: number;
  navn: string;
  adresse1: string;
  adresse2: string;
  postnr: string;
  bynavn: string;
  telefonnr: string;
  cvrNr: string;
  regnr: string;
  kontonr: string;
  vkTypeId: string;
  forhandlerForeninger: string[];
  betalingmodtagendeVkNr: number;
  intVk: boolean;
  email: string;
  bemaerkninger: string;
  opdateretDato: Date;
  opdateretAfBrugernavn: string;
  aktiv: boolean;
  vkCleanupExclude: boolean;
  fotoTaksering: boolean;
  policeopslag: boolean;
  midlertidigtStop: boolean;
  maaAutoflex: boolean;
  inviterVaerkstedTilUddannelseI7Dage: boolean;
  intBem: string;
  kategori: number;
  vkGrp: number;
  customAftaler: Customaftale[];

  get fabrikatkoder(): string {
    return this.forhandlerForeninger.join(' ');
  }

  get vkIsOperating(): boolean {
    return this.aktiv && !this.midlertidigtStop;
  }

  get vkNotOperatingMessage(): string {
    return !this.aktiv ? 'Værkstedet er ophørt' : this.midlertidigtStop ? 'Samarbejdet mellem selskab og værksted er midlertidigt stoppet' : null;
  }

  constructor(vkstamDto: VkStamDTO, customAftaler: Customaftale[]) {
    this.id = vkstamDto.id;
    this.vkNr = vkstamDto.vkNr;
    this.navn = vkstamDto.navn;
    this.adresse1 = vkstamDto.adresse1;
    this.adresse2 = vkstamDto.adresse2;
    this.postnr = vkstamDto.postnr;
    this.bynavn = vkstamDto.bynavn;
    this.telefonnr = vkstamDto.telefonnr;
    this.cvrNr = vkstamDto.cvrNr;
    this.regnr = vkstamDto.regnr;
    this.kontonr = vkstamDto.kontonr;
    this.vkTypeId = vkstamDto.vkTypeId;
    this.forhandlerForeninger = vkstamDto.forhandlerForeninger ? vkstamDto.forhandlerForeninger.slice() : [];
    this.betalingmodtagendeVkNr = vkstamDto.betalingmodtagendeVkNr;
    this.intVk = vkstamDto.intVk;
    this.email = vkstamDto.email;
    this.bemaerkninger = vkstamDto.bemaerkninger;
    this.opdateretDato = vkstamDto.opdateretDato;
    this.opdateretAfBrugernavn = vkstamDto.opdateretAfBrugernavn;
    this.aktiv = vkstamDto.aktiv;
    this.vkCleanupExclude = vkstamDto.vkCleanupExclude;
    this.fotoTaksering = vkstamDto.fotoTaksering;
    this.policeopslag = vkstamDto.policeopslag;
    this.midlertidigtStop = vkstamDto.midlertidigtStop;
    this.maaAutoflex = vkstamDto.maaAutoflex;
    this.inviterVaerkstedTilUddannelseI7Dage = vkstamDto.inviterVaerkstedTilUddannelseI7Dage;
    this.intBem = vkstamDto.intBem;
    this.kategori = vkstamDto.kategori;
    this.vkGrp = vkstamDto.vkGrp;
    this.customAftaler = customAftaler;
  }
}
