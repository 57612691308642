import {NgbDateStruct, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';

export class DeliveryNoteTemplateOptions {
  date: NgbDateStruct;
  private _enableForwardOptions = false;
  enableForwardOptionsText = 'Til brug for behandling af ovennævnte sag bedes de venligst fremsende:';
  registrationCertificate = false;
  registrationCertificateText = 'Registreringsattest (den originale)';
  receiptOfCancellationOfLicencePlate = false;
  receiptOfCancellationOfLicencePlateText = 'Kvittering for admelding af nr. plader';
  contractNote = false;
  contractNoteText = 'Slutseddel / Købekontrakt / Synsrapport / Faktura (original)';
  attachReportFrontPage = false;
  attachReportFrontPageText = 'Vedlagt: RapportForside';
  attachEr = false;
  attachErText = 'Vedlagt: Erstatningsopgørelse';
  attachOpOrGb = false;
  attachOpOrGbText = 'Vedlagt: Ophugningserklæring / Genopbygningserklæring';
  attachCanceledRegistrationCertificate = false;
  attachCanceledRegistrationCertificateText = 'Vedlagt: Afmeldt registreringsattest';
  attachErSignature = false;
  attachErSignatureText = 'Vedlagte erstatningsopgørelse bedes returneret hertil i underskrevet stand. (kundekopien kan beholdes)';
  chargedByInsuranceCompany = false;
  chargedByInsuranceCompanyText = 'Opkrævning for rester sker fra forsikringsselskabet';
  private _checkForAmountForSaleOfSpareParts = false;
  checkForAmountForSaleOfSparePartsTextOne = 'Check på kr.';
  checkForAmountForSaleOfSparePartsTextTwo = 'for salg af rester vedlagt / udbedes';
  checkForAmountForSaleOfSparePartsAmount: number;
  attachPrintOfCarBook = false;
  attachPrintOfCarBookText = 'Vedlagt: Udskrift af bilbogen';
  attachCertificateOfScrap = false;
  attachCertificateOfScrapText = 'Vedlagt: Skrotningsattest';
  accordingToAgreement = false;
  accordingToAgreementText = 'Ifølge aftale';
  private _accordingToLetterOrPhone = false;
  accordingToLetterOrPhoneText = 'Iht. brev/tlf den.';
  accordingToLetterOrPhoneDate: NgbDateStruct;
  forYourApproval = false;
  forYourApprovalText = 'Til Deres godkendelse';
  forSignature = false;
  forSignatureText = 'Til underskrift';
  requestToBeReturned = false;
  requestToBeReturnedText = 'Ønskes retur';
  returningWithAThankYou = false;
  returningWithAThankYouText = 'Return med tak for lån';
  forYourInformation = false;
  forYourInformationText = 'Til Deres orientering';
  private _vehicleCanceled = false;
  vehicleCanceledText = 'Køretøjet er afmeldt den.';
  vehicleCanceledDate: NgbDateStruct;
  private _callOn = false;
  callOnText = 'Ring på tlf.';
  callOnNumber: string;
  private _callOnBetweenTimes = false;
  callOnBetweenTimesTextOne = 'mellem kl.';
  callOnBetweenTimesTextTwo = 'og kl.';
  callOnBetweenTimesFrom: NgbTimeStruct;
  callOnBetweenTimesTo: NgbTimeStruct;
  requestToHaveAttachedDeclarationReturned = false;
  requestToHaveAttachedDeclarationReturnedText = 'Vedlagte erklæring/er bedes udfyldt og returneret';

  get enableForwardOptions(): boolean {
    return this._enableForwardOptions;
  }

  set enableForwardOptions(value: boolean) {
    this._enableForwardOptions = value;
    if (!this._enableForwardOptions) {
      this.registrationCertificate = false;
      this.receiptOfCancellationOfLicencePlate = false;
      this.contractNote = false;
    }
  }

  get checkForAmountForSaleOfSpareParts(): boolean {
    return this._checkForAmountForSaleOfSpareParts;
  }

  set checkForAmountForSaleOfSpareParts(value: boolean) {
    this._checkForAmountForSaleOfSpareParts = value;
    if (!this._checkForAmountForSaleOfSpareParts) {
      this.checkForAmountForSaleOfSparePartsAmount = null;
    }
  }

  get accordingToLetterOrPhone(): boolean {
    return this._accordingToLetterOrPhone;
  }

  set accordingToLetterOrPhone(value: boolean) {
    this._accordingToLetterOrPhone = value;
    if (!this._accordingToLetterOrPhone) {
      this.accordingToLetterOrPhoneDate = null;
    }
  }

  get vehicleCanceled(): boolean {
    return this._vehicleCanceled;
  }

  set vehicleCanceled(value: boolean) {
    this._vehicleCanceled = value;
    if (!this._vehicleCanceled) {
      this.vehicleCanceledDate = null;
    }
  }

  get callOn(): boolean {
    return this._callOn;
  }

  set callOn(value: boolean) {
    this._callOn = value;
    if (!this._callOn) {
      this.callOnNumber = null;
      this.callOnBetweenTimes = false;
    }
  }

  get callOnBetweenTimes(): boolean {
    return this._callOnBetweenTimes;
  }

  set callOnBetweenTimes(value: boolean) {
    this._callOnBetweenTimes = value;
    if (!this._callOnBetweenTimes) {
      this.callOnBetweenTimesFrom = null;
      this.callOnBetweenTimesTo = null;
    }
  }
}
