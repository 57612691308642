<h1 class="">Rapporter <small>(<span class *ngIf="filteredItems != totalItems">{{filteredItems}}
    af </span>{{totalItems}})</small></h1>

<div class="no-focus" (keydown.enter)="keyEventHandler($event)" (keyup)="keyEventHandler($event)" tabindex="0"
     [lcAutofocus]>

    <lc-report-search-input
        (onCancelSearch)="cancelSearch()"
        (onSearch)="getReports($event)"
        (onRefresh)="getReports(currentSearchProperties)"
        (onSearchInputOpen)="searchInputOpen = true"
        (onSearchInputClosed)="searchInputOpen = false"
        [isLoading]="spinnerUtil.isLoading"
        [currentSearchProperties]="currentSearchProperties"
        [searchLimitMessage]="searchLimitMessage"
        [filter]="currentFilter"
        (onFilteringChanged)="doFiltering($event)"
        [enableSearchOnState]="enableSearchOnState()"
        [title]="'Søgning i egne rapporter'">
    </lc-report-search-input>

    <div class="row d-none d-md-block mt-1">
        <div class="col-md-12">
            <div class="table-responsive">
                <table class="table table-hover">
                    <thead>
                    <tr>
                        <th class="text-center clickable"
                            (click)="orderType='stringEmptyAlwaysLast'; orderProp = 'damagesNotes'; orderReverse=!orderReverse; page=1; doSorting()">
                            <i class="fa"
                               [ngClass]="{'fa-sort': orderProp !== 'damagesNotes', 'fa-sort-asc': orderProp === 'damagesNotes' && !orderReverse, 'fa-sort-desc': orderProp === 'damagesNotes' && orderReverse}"></i>
                            <i class="fa fa-sticky-note-o"></i>
                        </th>
                        <th class="text-center clickable"
                            (click)="orderType='string'; orderProp = 'state'; orderReverse=!orderReverse; page=1; doSorting()">
                            <i class="fa"
                               [ngClass]="{'fa-sort': orderProp !== 'state', 'fa-sort-asc': orderProp === 'state' && !orderReverse, 'fa-sort-desc': orderProp === 'state' && orderReverse}"></i>
                            Status
                        </th>
                        <th class="text-center">Type</th>
                        <th class="text-start clickable"
                            (click)="orderType='string'; orderProp = 'registrationNumber'; orderReverse=!orderReverse; page=1; doSorting()">
                            <i class="fa"
                               [ngClass]="{'fa-sort': orderProp !== 'registrationNumber', 'fa-sort-asc': orderProp === 'registrationNumber' && !orderReverse, 'fa-sort-desc': orderProp === 'registrationNumber' && orderReverse}"></i>
                            Reg. nr.
                        </th>
                        <th></th>
                        <th class="text-start clickable"
                            (click)="orderType='string'; orderProp = 'vehicleText'; orderReverse=!orderReverse; page=1; doSorting()">
                            <i class="fa"
                               [ngClass]="{'fa-sort': orderProp !== 'vehicleText', 'fa-sort-asc': orderProp === 'vehicleText' && !orderReverse, 'fa-sort-desc': orderProp === 'vehicleText' && orderReverse}"></i>
                            Model
                        </th>
                        <th>
							<span class="clickable"
                                  (click)="orderType='string'; orderProp = 'vkReportKey'; orderReverse=!orderReverse; page=1; doSorting()">
								<i class="fa"
                                   [ngClass]="{'fa-sort': orderProp !== 'vkReportKey', 'fa-sort-asc': orderProp === 'vkReportKey' && !orderReverse, 'fa-sort-desc': orderProp === 'vkReportKey' && orderReverse}"></i>
								Tilbud
							</span>
                            <span class="clickable"
                                  (click)="orderType='string'; orderProp = 'taksatorReportKey'; orderReverse=!orderReverse; page=1; doSorting()">
								<i class="fa"
                                   [ngClass]="{'fa-sort': orderProp !== 'taksatorReportKey', 'fa-sort-asc': orderProp === 'taksatorReportKey' && !orderReverse, 'fa-sort-desc': orderProp === 'taksatorReportKey' && orderReverse}"></i>
								Rapport
							</span>
                        </th>
                        <ng-container *ngIf="showXrefColumn()">
                            <th class="text-start clickable d-none d-xl-table-cell"
                                (click)="orderType='string'; orderProp = 'xref'; orderReverse=!orderReverse; page=1; doSorting()">
                                <i class="fa"
                                   [ngClass]="{'fa-sort': orderProp !== 'xref', 'fa-sort-asc': orderProp === 'xref' && !orderReverse, 'fa-sort-desc': orderProp === 'xref' && orderReverse}"></i>
                                {{functionConfiguration.importId}}
                            </th>
                        </ng-container>
                        <th class="text-start clickable d-none d-lg-table-cell" title="Selskab"
                            (click)="orderType='string'; orderProp = 'selskabsCode'; orderReverse=!orderReverse; page=1; doSorting()">
                            <i class="fa"
                               [ngClass]="{'fa-sort': orderProp !== 'selskabsCode', 'fa-sort-asc': orderProp === 'selskabsCode' && !orderReverse, 'fa-sort-desc': orderProp === 'selskabsCode' && orderReverse}"></i>
                            Selskab
                        </th>
                        <th class="text-start clickable d-none d-lg-table-cell"
                            (click)="orderType='currency'; orderProp = 'beloeb'; orderReverse=!orderReverse; page=1; doSorting()">
                            <i class="fa"
                               [ngClass]="{'fa-sort': orderProp !== 'beloeb', 'fa-sort-asc': orderProp === 'beloeb' && !orderReverse, 'fa-sort-desc': orderProp === 'beloeb' && orderReverse}"></i>
                            Beløb
                        </th>
                        <th class="text-start clickable"
                            (click)="orderType='string'; orderProp = 'approvedDate'; orderReverse=!orderReverse; page=1; doSorting()">
                            <i class="fa"
                               [ngClass]="{'fa-sort': orderProp !== 'approvedDate', 'fa-sort-asc': orderProp === 'approvedDate' && !orderReverse, 'fa-sort-desc': orderProp === 'approvedDate' && orderReverse}"></i>
                            Opdateret
                        </th>
                        <th class="d-none d-xl-table-cell zero-padding"></th>
                        <th class="d-none d-xl-table-cell" *lcHasAnyAuthority="'ROLE_Taks'"></th>
                        <th class="d-none d-xl-table-cell"></th>
                        <th class="d-none d-xl-table-cell"></th>
                        <th class="d-none d-xl-table-cell"></th>
                        <th class="d-none d-xl-table-cell" *lcHasAnyAuthority="'ROLE_Taks'"></th>
                        <th class="d-xl-none"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <ng-container *ngFor="let report of currentPage; let i = index">
                        <tr (dblclick)="showPrint(report)" (click)="selectedIndex=i;"
                            [ngClass]="{'selected-row': report === currentPage[selectedIndex]}">
                            <td class="text-center">
                                <lc-damages-note-table-view
                                    [damagesNoteObservable]="reportService.getDamagesNote(report.id)"
                                    [damagesNoteText]="report.damagesNotes"
                                    [reportType]="extractReportType(report)"
                                    (damagesNoteTextUpdatedEmitter)="handleUpdatedDamagesNote(report, $event)"></lc-damages-note-table-view>
                            </td>
                            <td class="text-center">
                                <i class="clickable {{getStateClassFromReport(report)}} position-relative"
                                   [ngbPopover]="reportStateLegend" triggers="mouseenter:mouseleave"
                                   placement="right">
                                    <span *ngIf="report.acceptStatus" class="notify-badge fa-lg font-weight-bold {{getAcceptStatusClass(report.acceptStatus)}}" >?</span>
                                </i>
                            </td>
                            <td class="text-center">
                                <i class="clickable {{getTypeClassByReport(report)}} report-state-padded"
                                   [ngbPopover]="reportTypeLegend" triggers="mouseenter:mouseleave"
                                   placement="right">
									<span *ngIf="!getTypeClassByReport(report)">
										{{getName(report)}}
									</span>
                                </i>
                            </td>
                            <td class="text-start" style="width:90px;">{{report.registrationNumber}}</td>
                            <td class="text-start" style="width:64px;">
                                <div [lazyLoad]="getThumbnailUrl(report)" [defaultImage]="defaultImage" class="list-thumbnail"></div>
                            </td>
                            <td class="text-start"><span
                                style="max-height: 32px; display: block; overflow: hidden;">{{report.vehicleText}}</span>
                            </td>
                            <td class="text-start nowrap">
                                {{report.vkReportKey | lcForsiReportKey}}<br/>
                                {{report.taksatorReportKey | lcForsiReportKey}}<br/>
                            </td>
                            <ng-container *ngIf="showXrefColumn()">
                                <td class="text-start d-none d-xl-table-cell">{{report.xref}}</td>
                            </ng-container>
                            <td class="text-start d-none d-lg-table-cell">{{report.selskabsName}}
                                [{{report.selskabsCode}}]
                            </td>
                            <td class="text-start d-none d-lg-table-cell">{{report.beloeb / 100 | currency:'DKK':''}}</td>
                            <td class="text-start">{{report.approvedDate | date: 'dd-MM-yyyy HH:mm:ss'}}</td>
                            <td class="d-none d-xl-table-cell zero-padding" *lcHasAnyAuthority="'ROLE_Taks'"></td>
                            <td class="d-none d-xl-table-cell table-button" *lcHasAnyAuthority="'ROLE_Taks'" [ngClass]="{'report-item-menu-selected': taksB2bSamArbShow===getCombinedKey(report)}">
                                <a [title]="getCarSaleMenuTitle(report)">
                                    <div (click)="toggleShowTaksB2B(report)" (dblclick)="stopPropagation($event)"
                                         [ngClass]="{'table-button-disabled': !carSaleList.carSaleSupportedByReportType(report.taksatorReportKey)}">
                                        <i class="fa fa-2x position-relative"
                                           [ngClass]="!carSaleList.hasCarSale(report.taksatorReportKey) ? 'fa-chain-broken' : 'fa-chain'">
                                                <span *ngIf="carSaleList.isCarSaleDeprecated(report.taksatorReportKey, report.approvedDate.valueOf())" class="notify-badge-warning font-weight-bold">!</span>
                                        </i>
                                    </div>
                                </a>
                                <div class="table-button-highlight"></div>
                            </td>
                            <td class="d-none d-xl-table-cell table-button" *lcHasAnyAuthority="'ROLE_VK'" [ngClass]="{'report-item-menu-selected': b2bSamArbShow===getCombinedKey(report)}">
                                <a [title]="getB2BMenuTitle(report)">
                                    <div (click)="toggleShowB2B(report)" (dblclick)="stopPropagation($event)"
                                         [ngClass]="{'table-button-disabled': !canDoB2B(report)}">
                                        <i class="fa fa-2x position-relative"
                                           [ngClass]="{'fa-chain-broken': !hasB2BIntegrationData(report.taksatorReportKey), 'fa-chain': hasB2BIntegrationData(report.taksatorReportKey)}"></i>
                                    </div>
                                </a>
                            </td>
                            <td class="d-none d-xl-table-cell table-button" [ngClass]="{'report-item-menu-selected': bilagShow===getCombinedKey(report)}">
                                <a [title]="isToggleAttachmentsDisabled(report) ? 'Du kan ikke tilføje bilag til denne rapport' : 'Bilag'">
                                    <div (click)="toggleShowBilag(report)" (dblclick)="stopPropagation($event)" [ngClass]="{'table-button-disabled': isToggleAttachmentsDisabled(report)}">
                                        <i class="fa fa-paperclip fa-2x position-relative">
                                            <lc-attachment-badge [attachmentSummary]="getAttachmentSummary(getCombinedKey(report))"></lc-attachment-badge>
                                        </i>
                                    </div>
                                </a>
                            </td>
                            <td class="d-none d-xl-table-cell table-button" *lcHasAnyAuthority="'ROLE_Taks'" [ngClass]="{'report-item-menu-selected': showNewReportTypes===getCombinedKey(report)}">
                                <a [title]="getNewReportMenuTitle(report)">
                                    <div (click)="toggleShowNewReportTypes(report)" (dblclick)="stopPropagation($event)"
                                         [ngClass]="{'table-button-disabled': !newReportTypesAllowed(report)}">
                                        <i class="fa fa-plus fa-2x position-relative"></i>
                                    </div>
                                </a>
                            </td>
                            <td class="d-none d-xl-table-cell table-button">
                                <a title="Vis Print">
                                    <div (click)="showPrint(report);" class="table-button">
                                        <i class="fa fa-print fa-2x position-relative"></i>
                                    </div>
                                </a>
                            </td>
                            <td class="d-none d-xl-table-cell table-button" [ngClass]="{'report-item-menu-selected': tilfoejBeskedShow===getCombinedKey(report)}">
                                <a title="Beskeder">
                                    <div (click)="toggleShowMessages(report)">
                                        <i class="fa fa-envelope text-danger fa-2x position-relative"
                                           *ngIf="hasUnreadMessages(getCombinedKey(report))">
												<span
                                                    class="notify-badge-white">{{getUnreadMessageCount(getCombinedKey(report))}}</span>
                                        </i>
                                        <i class="fa fa-envelope-open-o fa-2x position-relative"
                                           *ngIf="!hasUnreadMessages(getCombinedKey(report))"></i>
                                    </div>
                                </a>
                            </td>
                            <td class="d-xl-none table-button">
                                <div *ngIf="showMore==getCombinedKey(report)" class="text-start">
                                    <div class="btn-group-vertical report-item-menu" role="group">
                                        <a *lcHasAnyAuthority="'ROLE_VK'" [title]="getB2BMenuTitle(report)" class="table-button">
                                            <div (click)="toggleShowB2B(report)" (dblclick)="stopPropagation($event)"
                                                 class="table-button"
                                                 [ngClass]="{'table-button-disabled': !canDoB2B(report), 'report-item-menu-selected': b2bSamArbShow===getCombinedKey(report)}">
                                                <i class="fa fa-2x position-relative"
                                                   [ngClass]="{'fa-chain-broken': !hasB2BIntegrationData(report.taksatorReportKey), 'fa-chain': hasB2BIntegrationData(report.taksatorReportKey)}"></i>
                                            </div>
                                        </a>
                                        <a *lcHasAnyAuthority="'ROLE_Taks'" [title]="getCarSaleMenuTitle(report)" class="table-button">
                                            <div (click)="toggleShowTaksB2B(report)"
                                                 (dblclick)="stopPropagation($event)"
                                                 class="table-button"
                                                 [ngClass]="{'table-button-disabled': !carSaleList.carSaleSupportedByReportType(report.taksatorReportKey), 'report-item-menu-selected': taksB2bSamArbShow===getCombinedKey(report)}">
                                                <i class="fa fa-2x position-relative"
                                                   [ngClass]="!carSaleList.hasCarSale(report.taksatorReportKey) ? 'fa-chain-broken' : 'fa-chain'">
                                                    <span *ngIf="carSaleList.isCarSaleDeprecated(report.taksatorReportKey, report.approvedDate.valueOf())" class="notify-badge-warning font-weight-bold">!</span>
                                                </i>
                                            </div>
                                        </a>
                                        <a [title]="isToggleAttachmentsDisabled(report) ? 'Du kan ikke tilføje bilag til denne rapport' : 'Bilag'" class="table-button">
                                            <div (click)="toggleShowBilag(report)" (dblclick)="stopPropagation($event)"
                                                 class="table-button"
                                                 [ngClass]="{'report-item-menu-selected': bilagShow===getCombinedKey(report), 'table-button-disabled': isToggleAttachmentsDisabled(report)}">
                                                <i class="fa fa-paperclip fa-2x position-relative">
                                                    <lc-attachment-badge [attachmentSummary]="getAttachmentSummary(getCombinedKey(report))"></lc-attachment-badge>
                                                </i>
                                            </div>
                                        </a>
                                        <a *lcHasAnyAuthority="'ROLE_Taks'" [title]="getNewReportMenuTitle(report)" class="table-button">
                                            <div (click)="toggleShowNewReportTypes(report)"
                                                 (dblclick)="stopPropagation($event)"
                                                 class="table-button"
                                                 [ngClass]="{'report-item-menu-selected': showNewReportTypes===getCombinedKey(report), 'table-button-disabled': !newReportTypesAllowed(report)}">
                                                <i class="fa fa-plus fa-2x position-relative"></i>
                                            </div>
                                        </a>
                                        <a title="Vis Print" class="table-button">
                                            <div (click)="showPrint(report);"
                                                 class="table-button">
                                                <i class="fa fa-print fa-2x position-relative"></i>
                                            </div>
                                        </a>
                                        <a title="Beskeder" class="table-button">
                                            <div (click)="toggleShowMessages(report)"
                                                 class="table-button"
                                                 [ngClass]="{'report-item-menu-selected': tilfoejBeskedShow===getCombinedKey(report)}">
                                                <i class="fa fa-envelope text-danger fa-2x position-relative"
                                                   *ngIf="hasUnreadMessages(getCombinedKey(report))">
												<span
                                                    class="notify-badge-white">{{getUnreadMessageCount(getCombinedKey(report))}}</span>
                                                </i>
                                                <i class="fa fa-envelope-open-o fa-2x position-relative"
                                                   *ngIf="!hasUnreadMessages(getCombinedKey(report))"></i>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <span class="table-button" (click)="toggleShowMore(report)"
                                      (dblclick)="stopPropagation($event)">
										<i class="fa fa-ellipsis-h fa-2x position-relative">
                                            <span *ngIf="showMore !== getCombinedKey(report) && isTaks() && carSaleList.isCarSaleDeprecated(report.taksatorReportKey, report.approvedDate.valueOf())" class="notify-badge-warning font-weight-bold">!</span>
                                        </i>
									</span>
                            </td>
                        </tr>
                        <tr *ngIf="b2bSamArbShow==getCombinedKey(report)" >
                            <td colspan="16" class="sub-row-td" *lcHasAnyAuthority="'ROLE_VK'">
                                <ng-container
                                    *ngTemplateOutlet="vkB2BCard;context:{report:report}">
                                </ng-container>
                            </td>
                        </tr>
                        <tr *ngIf="taksB2bSamArbShow==getCombinedKey(report)">
                            <td colspan="16" class="sub-row-td">
                                <lc-taks-report-b2b
                                    [reportId]="report.id"
                                    [taksRapportKey]="report.taksatorReportKey"
                                    [updatedAt]="report.approvedDate"
                                    [carSale]="carSaleList.getCarSale(report.taksatorReportKey)"
                                    [integrationUsers]="integrationUsers"
                                    (carSaleCreated)="carSaleList.addToCarSaleList($event)"
                                    (carSaleDeleted)="carSaleList.removeFromCarSaleList($event)"
                                ></lc-taks-report-b2b>
                            </td>
                        </tr>
                        <tr *ngIf="bilagShow==getCombinedKey(report)">
                            <td colspan="16" class="sub-row-td">
                                <div class="card sub-row-card">
                                    <div class="messages-card">
                                        <div class="messages-card-action">
                                            <lc-report-attachment
                                                [attachmentSummary]="attachments[getCombinedKey(report)]"
                                                (readyForApproval)="readyForApproval(report, $event)"
                                                (onAttachmentChanged)="getAttachmentsForReport(report)"
                                                [readonly]="isAttachmentsReadonly(report)"
                                                [workshopReportId]="report.vkReportId"
                                                [reportKey]="getReportKey(report)"
                                                [registrationNumber]="report.registrationNumber"
                                                [videoRequestUseCase]="true"
                                            ></lc-report-attachment>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="tilfoejBeskedShow==getCombinedKey(report)">
                            <td colspan="16" class="sub-row-td">
                                <div class="card sub-row-card p-3">
                                    <lc-message
                                        [sendEnabled]="sendMessageEnabled(report)"
                                        [messages]="messages[getCombinedKey(report)]"
                                        [textLibraryType]="textLibraryType"
                                        (postMessage)="sendMessage(report, $event)"
                                        (reload)="getMessagesForReport(report)">
                                    </lc-message>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="showNewReportTypes==getCombinedKey(report)">
                            <td colspan="16" class="sub-row-td">
                                <div class="card sub-row-card">
                                    <lc-create-linked-report-from-report
                                        [reportId]="report.id"></lc-create-linked-report-from-report>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <!-- Card layout -->

    <div class="d-md-none pb-5">
        <div class="fixed-bottom-mobile-menu clickable text-center" (click)="toggleShowVehicleCardImages()" >
            <i class="fa fa-image fa-2x"></i>
            <br/>
            <i [ngClass]="{'fa-toggle-on': showCardImages, 'fa-toggle-off': !showCardImages}" class="fa"></i>
        </div>

        <div class="card mt-3" *ngFor="let report of currentPage" >
            <div class="card-header p-2">
                <div class="float-start p-0 pe-1">
                    <i class="clickable {{getTypeClassByReport(report)}}"
                       [ngbPopover]="reportTypeLegend" triggers="mouseenter:mouseleave"
                       placement="right">
									<span *ngIf="!getTypeClassByReport(report)">
										{{getName(report)}}
									</span>
                    </i>
                </div>
                <strong>{{getReportKey(report) | lcForsiReportKey}}</strong> - {{report.registrationNumber}}
                <div class="float-end">
                    <i class="clickable {{getStateClassFromReport(report)}} position-relative"
                       [ngbPopover]="reportStateLegend" placement="left"
                       triggers="mouseenter:mouseleave"
                    >
                        <span *ngIf="report.acceptStatus" class="notify-badge fa-lg font-weight-bold {{getAcceptStatusClass(report.acceptStatus)}}" >?</span>
                    </i>
                </div>

            </div>
            <div class="card-body vehicle-card-body p-0" [ngClass]="{'vehicle-card-image-show': isVehicleCardImageVisible(report)}">
                <div class="vehicle-card-image w-100" [lazyLoad]="getThumbnailUrl(report)" [defaultImage]="defaultImage"></div>
                <div class="vehicle-card-info p-3">
                    <div class="w-100">
                        <div class="row">
                            <div class="col-4 text-truncate"><strong>Reg. nr.</strong></div>
                            <div class="col">
                                {{report.registrationNumber}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4 text-truncate"><strong>Model</strong></div>
                            <div class="col">
                                {{report.vehicleText}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4 text-truncate"><strong>Tilbud</strong></div>
                            <div class="col">
                                {{report.vkReportKey | lcForsiReportKey}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4 text-truncate"><strong>Rapport</strong></div>
                            <div class="col">
                                {{report.taksatorReportKey | lcForsiReportKey}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4 text-truncate"><strong>Selskab</strong></div>
                            <div class="col text-truncate">
                                [{{report.selskabsCode}}] {{report.selskabsName}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4 text-truncate"><strong>Beløb</strong></div>
                            <div class="col">
                                {{report.beloeb / 100 | currency:'DKK':''}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4 text-truncate"><strong>Opdateret</strong></div>
                            <div class="col">
                                {{report.approvedDate | date: 'dd-MM-yyyy HH:mm:ss'}}
                            </div>
                        </div>
                        <div class="row" *ngIf="showXrefColumn()">
                            <div class="col-4 text-truncate">{{functionConfiguration.importId}}</div>
                            <div class="col">{{report.xref}}</div>
                        </div>
                        <div class="row">
                            <div class="col-4 text-truncate"><strong>Notat</strong></div>
                            <div class="col">
                                <lc-damages-note-card-view [damagesNoteText]="report.damagesNotes"
                                                           [reportType]="extractReportType(report)"
                                                           (damagesNoteTextUpdatedEmitter)="handleUpdatedDamagesNote(report, $event)">
                                </lc-damages-note-card-view>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-footer p-2 pt-0">
                <div class="row">
                    <div *lcHasAnyAuthority="'ROLE_VK'" class="col-12">
                        <!-- Specific rows of 4 buttons for VK. If you are adding / editing a shared action also add / edit it for Taks -->
                        <!-- This is necessary to keep the same button order as the big screen view -->
                        <div (click)="toggleShowMessages(report)" title="Beskeder"
                             class="clickable ms-2 float-end pt-2 inline-action-button">

                            <i class="fa fa-envelope text-danger fa-2x position-relative"
                               *ngIf="hasUnreadMessages(getCombinedKey(report))">
												<span
                                                    class="notify-badge-white">{{getUnreadMessageCount(getCombinedKey(report))}}</span>
                            </i>
                            <i class="fa fa-envelope-open-o fa-2x position-relative"
                               *ngIf="!hasUnreadMessages(getCombinedKey(report))"></i>

                        </div>
                        <div (click)="showPrint(report)" title="Vis Print"
                                class="clickable ms-2 float-end pt-2 inline-action-button">
                            <i class="fa fa-print fa-2x position-relative"></i>
                        </div>
                        <div (click)="toggleShowBilag(report)" [ngClass]="{'table-button-disabled': isToggleAttachmentsDisabled(report)}"
                             [title]="isToggleAttachmentsDisabled(report) ? 'Du kan ikke tilføje bilag til denne rapport' : 'Bilag'"
                                class="clickable ms-2 float-end pt-2 inline-action-button">
                            <i class="fa fa-paperclip fa-2x position-relative">
                                <lc-attachment-badge [attachmentSummary]="getAttachmentSummary(getCombinedKey(report))"></lc-attachment-badge>
                            </i>
                        </div>
                        <div (click)="toggleShowB2B(report)" class="clickable ms-2 float-end pt-2 inline-action-button"
                             [title]="getB2BMenuTitle(report)"
                             [ngClass]="{'table-button-disabled': !canDoB2B(report)}">
                            <i class="fa fa-2x position-relative"
                               [ngClass]="{'fa-chain-broken': !hasB2BIntegrationData(report.taksatorReportKey), 'fa-chain': hasB2BIntegrationData(report.taksatorReportKey)}">
                            </i>
                        </div>
                    </div>
                    <div *lcHasAnyAuthority="'ROLE_Taks'" class="col-12">
                        <div (click)="toggleShowMessages(report)" title="Beskeder"
                             class="clickable ms-2 float-end pt-2 inline-action-button">
                            <i class="fa fa-envelope text-danger fa-2x position-relative"
                               *ngIf="hasUnreadMessages(getCombinedKey(report))">
												<span
                                                    class="notify-badge-white">{{getUnreadMessageCount(getCombinedKey(report))}}</span>
                            </i>
                            <i class="fa fa-envelope-open-o fa-2x position-relative"
                               *ngIf="!hasUnreadMessages(getCombinedKey(report))"></i>
                        </div>
                        <div (click)="showPrint(report);" (dblclick)="stopPropagation($event)"
                             title="Vis Print"
                             class="clickable ms-2 float-end pt-2 inline-action-button">
                            <i class="fa fa-print fa-2x position-relative"></i>
                        </div>
                        <div (click)="toggleShowNewReportTypes(report)" [title]="getNewReportMenuTitle(report)"
                             (dblclick)="stopPropagation($event)"
                             [ngClass]="{'table-button-disabled': !newReportTypesAllowed(report)}"
                             class="clickable ms-2 float-end pt-2 inline-action-button">
                            <i class="fa fa-plus fa-2x position-relative"></i>
                        </div>
                        <div (click)="toggleShowBilag(report)" (dblclick)="stopPropagation($event)"
                             [title]="isToggleAttachmentsDisabled(report) ? 'Du kan ikke tilføje bilag til denne rapport' : 'Bilag'"
                             class="clickable ms-2 float-end pt-2 inline-action-button">
                            <i class="fa fa-paperclip fa-2x position-relative">
                                <lc-attachment-badge [attachmentSummary]="getAttachmentSummary(getCombinedKey(report))"></lc-attachment-badge>
                            </i>
                        </div>
                        <div (click)="toggleShowTaksB2B(report)" (dblclick)="stopPropagation($event)" [title]="getCarSaleMenuTitle(report)"
                             class="clickable ms-2 float-end pt-2 inline-action-button"
                             [ngClass]="{'table-button-disabled': !carSaleList.carSaleSupportedByReportType(report.taksatorReportKey)}">
                            <i class="fa fa-2x position-relative"
                               [ngClass]="!carSaleList.hasCarSale(report.taksatorReportKey) ? 'fa-chain-broken' : 'fa-chain'">
                                <span *ngIf="carSaleList.isCarSaleDeprecated(report.taksatorReportKey, report.approvedDate.valueOf())" class="notify-badge-warning font-weight-bold">!</span>
                            </i>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <!-- Actions -->
                    <div class="col-12 mt-2" *ngIf="b2bSamArbShow==getCombinedKey(report)">
                        <ng-container
                            *ngTemplateOutlet="vkB2BCard;context:{report:report}">
                        </ng-container>
                    </div>
                    <div class="col-12 mt-2" *ngIf="bilagShow==getCombinedKey(report)">
                        <div class="card sub-row-card">
                            <div class="messages-card">
                                <div class="messages-card-action">
                                    <lc-report-attachment
                                        [attachmentSummary]="attachments[getCombinedKey(report)]"
                                        (readyForApproval)="readyForApproval(report, $event)"
                                        (onAttachmentChanged)="getAttachmentsForReport(report)"
                                        [readonly]="isAttachmentsReadonly(report)"
                                        [workshopReportId]="report.vkReportId"
                                        [reportKey]="getReportKey(report)"
                                        [registrationNumber]="report.registrationNumber"
                                        [videoRequestUseCase]="true"
                                    ></lc-report-attachment>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-2" *ngIf="showNewReportTypes==getCombinedKey(report)">
                        <div class="card sub-row-card">
                            <div class="messages-card">
                                <div class="messages-card-action">
                                    <lc-create-linked-report-from-report
                                        [reportId]="report.id"></lc-create-linked-report-from-report>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-2" *ngIf="taksB2bSamArbShow==getCombinedKey(report)">
                        <div class="card sub-row-card">
                            <div class="messages-card">
                                <div class="messages-card-action">
                                    <lc-taks-report-b2b
                                        [reportId]="report.id"
                                        [taksRapportKey]="report.taksatorReportKey"
                                        [updatedAt]="report.approvedDate"
                                        [carSale]="carSaleList.getCarSale(report.taksatorReportKey)"
                                        [integrationUsers]="integrationUsers"
                                        (carSaleCreated)="carSaleList.addToCarSaleList($event)"
                                        (carSaleDeleted)="carSaleList.removeFromCarSaleList($event)"
                                    >
                                    </lc-taks-report-b2b>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-2" *ngIf="tilfoejBeskedShow==getCombinedKey(report)">
                        <div class="card sub-row-card">
                            <lc-message
                                [sendEnabled]="sendMessageEnabled(report)"
                                [messages]="messages[getCombinedKey(report)]"
                                [textLibraryType]="textLibraryType"
                                (postMessage)="sendMessage(report, $event)"
                                (reload)="getMessagesForReport(report)">
                            </lc-message>
                        </div>
                    </div>
                    <div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div style="padding-top: 5px;">
        <div class="row justify-content-center">
            <ngb-pagination [collectionSize]="filteredItems" [(page)]="page"
                            (pageChange)="loadPage(page)"></ngb-pagination>
        </div>
    </div>

    <ng-template #reportStateLegend>
        <table class="table table-borderless p-0 m-0">
            <tr>
                <td class="text-center"><i class="{{getStateClass('GODKENDT')}}"></i></td>
                <td>Godkendt</td>
            </tr>
            <tr>
                <td class="text-center"><i class="{{getStateClass('GODKENDT_M_FORBEHOLD')}}"></i></td>
                <td>Godkendt med forbehold</td>
            </tr>
            <tr>
                <td class="text-center"><i class="{{getStateClass('GODKENDT_M_AENDRINGER')}}"></i></td>
                <td>Godkendt med ændringer</td>
            </tr>
            <tr *lcHasAnyAuthority="'ROLE_VK'">
                <td class="text-center"><i class="{{getStateClass('AFVIST')}}"></i></td>
                <td>Afvist</td>
            </tr>
            <tr>
                <td class="text-center"><i class="{{getStateClass('ANNULLERET')}}"></i></td>
                <td>Annulleret</td>
            </tr>
            <tr *lcHasAnyAuthority="'ROLE_VK'">
                <td class="text-center"><i class="{{getStateClass('ADVIS')}}"></i></td>
                <td>Advisering</td>
            </tr>
            <tr *lcHasAnyAuthority="'ROLE_VK'">
                <td class="text-center"><i class="{{getStateClass('OVERTAGET')}}"></i></td>
                <td>Overtaget</td>
            </tr>
            <tr>
                <td class="text-center"><i class="{{getStateClass('SOLGT_TIL_GENOPBYGNING')}}"></i></td>
                <td>Solgt til genopbygning</td>
            </tr>
            <tr>
                <td class="text-center"><i class="{{getStateClass('TOTALSKADE')}}"></i></td>
                <td>Totalskade</td>
            </tr>
            <tr *ngIf="showAcceptInLegend">
                <td class="text-center "><span class="notify-badge-legend bg-warning align-middle">?</span></td>
                <td>Accept er forespurgt</td>
            </tr>
            <tr *ngIf="showAcceptInLegend">
                <td class="text-center "><span class="notify-badge-legend bg-success text-white align-middle">?</span></td>
                <td>Rapporten er accepteret</td>
            </tr>
            <tr *ngIf="showAcceptInLegend">
                <td class="text-center "><span class="notify-badge-legend bg-danger text-white align-middle">?</span></td>
                <td>Rapporten er ikke accepteret</td>
            </tr>
        </table>
    </ng-template>

    <ng-template #reportTypeLegend>
        <table class="table table-borderless p-0 m-0">
            <tr>
                <td class="text-center"><i class="{{getTypeClass('autotaks-report')}}"></i></td>
                <td>Autotaks</td>
            </tr>
            <tr>
                <td class="text-center"><i class="{{getTypeClass('glastaks-report')}}"></i></td>
                <td>Glastaks</td>
            </tr>
            <tr>
                <td class="text-center"><i class="{{getTypeClass('stortaks-report')}}"></i></td>
                <td>Stortaks</td>
            </tr>
            <tr>
                <td class="text-center"><i class="{{getTypeClass('mctaks-report')}}"></i></td>
                <td>MCtaks</td>
            </tr>
            <tr>
                <td class="text-center"><i class="{{getTypeClass('skavutaks-report')}}"></i></td>
                <td>Skavutaks</td>
            </tr>
            <tr>
                <td class="text-center"><i class="{{getTypeClass('camptaks-report')}}"></i></td>
                <td>Camptaks</td>
            </tr>
        </table>
    </ng-template>

    <ng-template #vkB2BCard let-report="report">
        <div class="card sub-row-card">
            <div class="row">
                <div class="col-12 col-sm-4 col-lg-6 m-2">
                    <div>
                        <strong>B2B samarbejde 3</strong><br/>
                        Find leverandører for samarbejde om IT-løsninger.<br/>
                        Hvis du angiver et reparatørnummer i feltet, vil alle relevante data
                        <br/>
                        blive stillet til rådighed.<br/>
                        <strong>NB.</strong> Funktionen betinges af, at der er udviklet en
                        "hentefunktion" hos modtager.
                    </div>
                </div>
                <div class="col m-2">
                    <div class="row pb-3">
                        <label class="col-form-label col-4">Lak</label>
                        <div class="col-8">
                            <div class="input-group">
                                <input
                                    [disabled]="!!getB2BIntegrationData(report.taksatorReportKey).vkNrLak"
                                    [(ngModel)]="getB2BIntegrationData(report.taksatorReportKey).inputVkNrLak"
                                    name="inputVkLakNr" type="text"
                                    class="form-control"
                                    placeholder="Malerværksted">
                                <button
                                    *ngIf="!getB2BIntegrationData(report.taksatorReportKey).vkNrLak"
                                    class="btn btn-success"
                                    [disabled]="!getB2BIntegrationData(report.taksatorReportKey).inputVkNrLak"
                                    (click)="addB2BIntegration(report.taksatorReportKey, INTEGRATION_PART.LAK)">
                                    <i class="fa fa-lg fa-chain"></i>
                                </button>
                                <button
                                    *ngIf="getB2BIntegrationData(report.taksatorReportKey).vkNrLak"
                                    class="btn btn-danger"
                                    (click)="removeB2BIntegration(report.taksatorReportKey, INTEGRATION_PART.LAK)">
                                    <i class="fa fa-lg fa-chain-broken"></i>
                                </button>
                            </div>
                            <div class="form-text text-truncate">
                                {{getB2BIntegrationData(report.taksatorReportKey).vkNameLak}}
                            </div>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <label class="col-form-label col-4">Tid</label>
                        <div class="col-8">
                            <div class="input-group">
                                <input
                                    [disabled]="!!getB2BIntegrationData(report.taksatorReportKey).vkNrCabplan"
                                    [(ngModel)]="getB2BIntegrationData(report.taksatorReportKey).inputVkNrCabplan"
                                    name="inputVkCabplanNr"
                                    type="text" class="form-control"
                                    placeholder="Planlægningsværksted">
                                <button
                                    *ngIf="!getB2BIntegrationData(report.taksatorReportKey).vkNrCabplan"
                                    class="btn btn-success"
                                    [disabled]="!getB2BIntegrationData(report.taksatorReportKey).inputVkNrCabplan"
                                    (click)="addB2BIntegration(report.taksatorReportKey, INTEGRATION_PART.PLANNING)">
                                    <i class="fa fa-lg fa-chain"></i>
                                </button>
                                <button
                                    *ngIf="getB2BIntegrationData(report.taksatorReportKey).vkNrCabplan"
                                    class="btn btn-danger"
                                    (click)="removeB2BIntegration(report.taksatorReportKey, INTEGRATION_PART.PLANNING)">
                                    <i class="fa fa-lg fa-chain-broken"></i>
                                </button>
                            </div>
                            <div class="form-text text-truncate">
                                {{getB2BIntegrationData(report.taksatorReportKey).vkNameCabplan}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>
