import {SparePartMatchDTO, SparePartState} from '../../dto/spare-part-match-response-dto.model';
import {AutoflexSparePartSortOrder, SortDirection} from '../../../shared/dto/autoflex-spare-part-sort-order.model';
import {Options} from 'ngx-slider-v2';

export class WrapperSparePatchDTO {
  data: SparePartMatchDTO;
  sameGuideNumber: SparePartMatchDTO[] = null;
  hasMultipleData(): boolean {
    return !!this.sameGuideNumber;
  }
  hasSubSelection(): boolean {
    return !!this.sameGuideNumber && !!this.sameGuideNumber.find(data => data.selected);
  }

  getSelectedRow(): SparePartMatchDTO {
    return !this.sameGuideNumber ? null : this.sameGuideNumber.find(data => data.selected);
  }
}

export interface Vehicle {
  year: number;
  km: number;
}

export class DraftSparePartViewModel {
  defaultSortOrder: AutoflexSparePartSortOrder[] = [];
  sparePartsUnfiltered: SparePartMatchDTO[] = [];
  filteredAndSorted: WrapperSparePatchDTO[] = [];
  filterExpanded = false;
  vehicle: Vehicle;
  readonly = false;

  yearFilterValue = 3;
  yearFilterOptions: Options = {
    disabled: true,
    showTicksValues: true,
    stepsArray: [{value: 0},{value: 1},{value: 2},{value: 3}],
  };
  kmFilterValue = 200;
  kmFilterOptions: Options = {
    disabled: true,
    showTicksValues: true,
    stepsArray: [{value: 1},{value: 5},{value: 10},{value: 20},{value: 50},{value: 100},{value: 200}],
    translate: (value: number): string => value + 'k'
  };

  private sortedBy: string;
  private sortAscending: boolean;
  private supplierFilterMap: Map<string, boolean[]> = new Map<string, boolean[]>();
  private thirdPartyFilterMap: Map<string, boolean[]> = new Map<string, boolean[]>();
  private stateFilterMap: Map<SparePartState, boolean[]> = new Map<SparePartState, boolean[]>();
  private qualityFilterMap: Map<string, boolean[]> = new Map<string, boolean[]>();

  add(match: SparePartMatchDTO): void {
    this.sparePartsUnfiltered.push(match);

    if (!this.thirdPartyFilterMap.get(match.supplierName)) {
      this.supplierFilterMap.set(match.supplierName, [true, true]);
    }

    if (!!match.thirdPartySupplier && match.thirdPartySupplier.trim() !== '' && !this.thirdPartyFilterMap.get(match.thirdPartySupplier)) {
        this.thirdPartyFilterMap.set(match.thirdPartySupplier, [true, true]);
    }

    if (!this.stateFilterMap.get(match.state)) {
      this.stateFilterMap.set(match.state, [true, true]);
    }

    if (!!match.quality && match.quality.trim() !== '' && !this.qualityFilterMap.get(match.quality)) {
      this.qualityFilterMap.set(match.quality, [true, true]);
    }

    this.addToFilteredAndSorted(match);
  }

  public sort() {
    if (!!this.sortedBy) {
      this.doSort(this.getSortFunction(this.sortedBy, this.sortAscending));
    } else if(this.defaultSortOrder.length > 0) {
      const sortingFunctions = this.defaultSortOrder.map(so => this.getSortFunction(so.attribute, so.direction === SortDirection.ASC));
      const combined = [sortingFunctions[0]];
      for(let i = 1; i < sortingFunctions.length; i++) {
        const temp = (first,second) => combined[i-1](first,second) !== 0 ? combined[i-1](first,second) : sortingFunctions[i](first,second);
        combined.push(temp);
      }

      this.doSort(combined[combined.length-1]);
    }
  }

  private addToFilteredAndSorted(match: SparePartMatchDTO) {
    match.visible = true;
    const existingMatch = this.filteredAndSorted.find(existing => existing.data.guideNumber === match.guideNumber);
    if (!!existingMatch) {
      const selected = existingMatch.data.selected || match.selected;
      if (existingMatch.sameGuideNumber === null) {
        existingMatch.sameGuideNumber = [];
        existingMatch.sameGuideNumber.push(existingMatch.data)
      }
      existingMatch.data = Object.assign({}, match);
      existingMatch.data.sparePartMatchId = null;
      existingMatch.data.supplierName = null;
      existingMatch.data.supplierReference = null;
      existingMatch.data.thirdPartySupplier = null;
      existingMatch.data.make = null;
      existingMatch.data.price = null;
      existingMatch.data.supplierDescription = null;
      existingMatch.data.state = null;
      existingMatch.data.quality = null;
      existingMatch.data.km = null;
      existingMatch.data.year = null;
      existingMatch.data.color = null;
      existingMatch.data.href = null;
      existingMatch.data.deliveryTerms = null;
      existingMatch.data.selectionBlocked = false;
      existingMatch.data.selected = selected;
      existingMatch.data.qualityDesignationDescription = null;
      existingMatch.sameGuideNumber.push(match);
    } else {
      const newMatch = new WrapperSparePatchDTO();
      newMatch.data = match;
      this.filteredAndSorted.push(newMatch);
    }
  }
  sortBy(column: string) {
    if (this.sortedBy === column) {
      this.sortAscending = !this.sortAscending;
    } else {
      this.sortAscending = true;
      this.sortedBy = column
    }
    const sortFunction = this.getSortFunction(this.sortedBy, this.sortAscending);
    this.doSort(sortFunction);
  }

  private doSort(sortFunction: (first, second) => number) {
    this.filteredAndSorted.sort(sortFunction);
    this.filteredAndSorted.filter(r => r.sameGuideNumber !== null).forEach(r => {
      r.sameGuideNumber.sort(sortFunction);
    })
  }

  private getSortFunction(column: string, directionAsc: boolean): (first, second) => number {
    if (column === 'stateAndQuality') {
      return (first, second) => {
        const c1 = !!first.data ? first.data : first;
        const c2 = !!second.data ? second.data : second;
        if (!c1.state) {
          return directionAsc ? -1 : 1;
        } else if (!c2.state) {
          return directionAsc ? 1 : -1;
        } else if (c1.state !== c2.state) {
          return (directionAsc ? 1 : -1) * (c1.state === SparePartState.CM ? -1 : 1);
        } else {
          if (!c1.quality) {
            return directionAsc ? -1 : 1;
          } else if (!c2.quality) {
            return directionAsc ? 1 : -1;
          } else {
            return (directionAsc ? 1 : -1) * c1.quality.localeCompare(c2.quality);
          }
        }
      };
    } else if (column === 'indicativePrice' || column === 'price') {
      return (first, second) => (directionAsc ? 1 : -1) *
        (!!first.data ? first.data[column] : first[column]) - (!!second.data ? second.data[column] : second[column]);
    } else {
      return (first,second) => (directionAsc ? 1 : -1) *
        (!!first.data ? (!first.data[column] ? -1 : first.data[column].localeCompare(second.data[column])) :
          (!first[column] ? -1 : first[column].localeCompare(second[column])));
    }
  }

  getSortClass(column: string): string {
    if (this.sortedBy === column) {
      return this.sortAscending ? 'fa-sort-asc' : 'fa-sort-desc';
    }
    return 'fa-sort';
  }

  getSelectionClass(row: WrapperSparePatchDTO): string {
    if (row.hasMultipleData() && !row.data.selected) {
      return row.hasSubSelection() ? 'fa-plus-square-o text-success' : 'fa-plus-square-o';
    } else if (row.hasMultipleData() && !row.data.selected) {
      return 'fa-plus-square-o text-success';
    } else if (row.hasMultipleData() && row.data.selected) {
      return 'fa-minus-square-o';
    }
    return '';
  }

  clickedRow(row: WrapperSparePatchDTO): void {
    if (!row.data.selectionBlocked) {
      if (!row.hasMultipleData() && this.readonly) {
        return;
      }
      row.data.selected = !row.data.selected;
    }
  }
  clickedSubRow(wrapper: WrapperSparePatchDTO, row: SparePartMatchDTO): void {
    if (this.readonly) {
      return;
    }
    const sparePartMatch = wrapper.sameGuideNumber.find(r => r.sparePartMatchId === row.sparePartMatchId);
    if (!sparePartMatch.selectionBlocked) {
      const selected = sparePartMatch.selected;
      wrapper.sameGuideNumber.forEach((r => r.selected = false));
      sparePartMatch.selected = !selected;
    }
  }

  isFilterEnabled(): boolean {
    return this.sparePartsUnfiltered.length > 0;
  }

  expandFilter(): void {
    this.filterExpanded = !this.filterExpanded;
  }

  filterText(): string {
    const filterCount = Array.from(this.supplierFilterMap.keys()).filter(s => !this.supplierFilterMap.get(s)[0]).length +
      Array.from(this.thirdPartyFilterMap.keys()).filter(p => !this.thirdPartyFilterMap.get(p)[0]).length +
      this.getFilterableStates().filter(s => !this.stateFilterMap.get(s)[0]).length +
      Array.from(this.qualityFilterMap.keys()).filter(q => !this.qualityFilterMap.get(q)[0]).length +
      (this.yearFilterOptions.disabled ? 0 : 1) +
      (this.kmFilterOptions.disabled ? 0 : 1)
    ;

    return filterCount === 0 ?  'Intet filter er valgt' :
      'Filter valgt, viser ' + this.getFilteredUnwrapped().length + ' ud af ' + this.sparePartsUnfiltered.length + ' reservedele';
  }

  getFilterableSuppliers(): string[] {
    const suppliers = Array.from(this.supplierFilterMap.keys());
    suppliers.sort();
    return suppliers;
  }
  clickSupplierFilter(supplier: string): void {
    if(!this.isSupplierIncluded(supplier)) {
      return;
    }
    const expanded = this.preFiltering();
    const pairFilteredIncluded = this.supplierFilterMap.get(supplier);
    pairFilteredIncluded[0] = !pairFilteredIncluded[0];
    this.filter();
    this.postFiltering(expanded);
  }

  isSupplierFiltered(supplier: string): boolean {
    return this.supplierFilterMap.get(supplier)[0];
  }
  isSupplierIncluded(supplier: string): boolean {
    return this.supplierFilterMap.get(supplier)[1];
  }

  getFilterableThirdParties(): string[] {
    const thirdParties = Array.from(this.thirdPartyFilterMap.keys());
    thirdParties.sort();
    return thirdParties;
  }

  clickThirdPartyFilter(thirdParty: string): void {
    if(!this.isThirdPartyIncluded(thirdParty)) {
      return;
    }
    const expanded = this.preFiltering();
    const pairFilteredIncluded = this.thirdPartyFilterMap.get(thirdParty);
    pairFilteredIncluded[0] = !pairFilteredIncluded[0];
    this.filter();
    this.postFiltering(expanded);
  }

  isThirdPartyFiltered(thirdParty: string): boolean {
    return this.thirdPartyFilterMap.get(thirdParty)[0];
  }

  isThirdPartyIncluded(thirdParty: string): boolean {
    return this.thirdPartyFilterMap.get(thirdParty)[1];
  }


  getFilterableStates(): SparePartState[] {
    return Array.from(this.stateFilterMap.keys());
  }

  clickStateFilter(state: SparePartState): void {
    if (!this.isStateIncluded(state)) {
      return;
    }
    const expanded = this.preFiltering();

    const pairFilteredIncluded = this.stateFilterMap.get(state);
    pairFilteredIncluded[0] = !pairFilteredIncluded[0];
    this.filter();
    this.postFiltering(expanded);
  }

  isStateFiltered(state: SparePartState): boolean {
    return this.stateFilterMap.get(state)[0];
  }

  isStateIncluded(state: SparePartState): boolean {
    return this.stateFilterMap.get(state)[1];
  }

  getFilterableQualities(): string[] {
    const qualities = Array.from(this.qualityFilterMap.keys());
    qualities.sort();
    return qualities;
  }

  clickQualityFilter(quality: string): void {
    if (!this.isQualityIncluded(quality)) {
      return;
    }
    const expanded = this.preFiltering();

    const pairFilteredIncluded = this.qualityFilterMap.get(quality);
    pairFilteredIncluded[0] = !pairFilteredIncluded[0]
    this.filter();
    this.postFiltering(expanded);
  }

  isQualityFiltered(quality: string): boolean {
    return this.qualityFilterMap.get(quality)[0];
  }
  isQualityIncluded(quality: string): boolean {
    return this.qualityFilterMap.get(quality)[1];
  }

  private preFiltering(): string[] {
    return this.filteredAndSorted.filter(wrapper => wrapper.data.selected).map(wrapper => wrapper.data.guideNumber);
  }

  private filter() {
    const includedSuppliers = Array.from(this.supplierFilterMap.keys()).filter(s => this.supplierFilterMap.get(s)[0]);
    const includedThirdParties = Array.from(this.thirdPartyFilterMap.keys()).filter(p => this.thirdPartyFilterMap.get(p)[0]);
    const includedStates = this.getFilterableStates().filter(s => this.stateFilterMap.get(s)[0]);
    const includedQualities = Array.from(this.qualityFilterMap.keys()).filter(q => this.qualityFilterMap.get(q)[0]);

    this.filteredAndSorted = [];
    const filtered = this.sparePartsUnfiltered
      .filter(row => includedSuppliers.includes(row.supplierName)
        && (includedThirdParties.includes(row.thirdPartySupplier) || !row.thirdPartySupplier || row.thirdPartySupplier.trim() === '')
        && includedStates.includes(row.state)
        && (includedQualities.includes(row.quality) || !row.quality || row.quality.trim() === '')
        && (this.yearFilterOptions.disabled || (!this.yearFilterOptions.disabled && Math.abs(row.year-this.vehicle.year) <= this.yearFilterValue))
        && (this.kmFilterOptions.disabled || (!this.kmFilterOptions.disabled && Math.abs(row.km-this.vehicle.km) <= this.kmFilterValue*1000))
      );
    filtered.forEach(row => this.addToFilteredAndSorted(row));
  }

  private postFiltering(expanded: string[]) {
    expanded.forEach(leadingNumber =>
      this.filteredAndSorted
        .filter(wrapper => wrapper.hasMultipleData() && wrapper.data.guideNumber === leadingNumber)
        .forEach(wrapper => wrapper.data.selected = true)
    );
    this.filteredAndSorted.sort(this.getSortFunction(this.sortedBy, this.sortAscending));

    const unwrapped = this.getFilteredUnwrapped();

    Array.from(this.supplierFilterMap.keys()).forEach(s => this.supplierFilterMap.get(s)[1] = !this.supplierFilterMap.get(s)[0] || unwrapped.findIndex(d => d.supplierName === s) >= 0);
    Array.from(this.thirdPartyFilterMap.keys()).forEach(p => this.thirdPartyFilterMap.get(p)[1] = !this.thirdPartyFilterMap.get(p)[0] || unwrapped.findIndex(d => d.thirdPartySupplier === p) >= 0);
    this.getFilterableStates().forEach(s => this.stateFilterMap.get(s)[1] = !this.stateFilterMap.get(s)[0] || unwrapped.findIndex(d => d.state === s) >= 0);
    Array.from(this.qualityFilterMap.keys()).forEach(q => this.qualityFilterMap.get(q)[1] = !this.qualityFilterMap.get(q)[0] || unwrapped.findIndex(d => d.quality === q) >= 0);
  }

  private getFilteredUnwrapped(): SparePartMatchDTO[] {
    return this.filteredAndSorted.filter(wrapper => !wrapper.hasMultipleData()).map(wrapper => wrapper.data).concat(
        this.filteredAndSorted.filter(wrapper => wrapper.hasMultipleData()).flatMap(wrapper => wrapper.sameGuideNumber));
  }

  public getTotalSavings(): number {
    let sum = 0;
    this.sparePartsUnfiltered.filter(row => row.selected).forEach(selected => sum += selected.indicativePrice - selected.price);
    return sum;
  }

  filterYearAndKilometer() {
    const expanded = this.preFiltering();
    this.filter();
    this.postFiltering(expanded);
  }

  isYearAndKmApplicable(): boolean {
    const stateFilterUsed = this.stateFilterMap.get(SparePartState.BM);
    return (!!stateFilterUsed && stateFilterUsed[0] && stateFilterUsed[1]) || !this.yearFilterOptions.disabled || !this.kmFilterOptions.disabled;
  }

  clickYearFilter() {
    if (this.isYearAndKmApplicable()) {
      const newOptions: Options = Object.assign({}, this.yearFilterOptions);
      newOptions.disabled = !this.yearFilterOptions.disabled
      this.yearFilterOptions = newOptions;

      const expanded = this.preFiltering()
      this.filter();
      this.postFiltering(expanded)
    }
  }

  clickKmFilter() {
    if (this.isYearAndKmApplicable()) {
      const newOptions: Options = Object.assign({}, this.kmFilterOptions);
      newOptions.disabled = !this.kmFilterOptions.disabled
      this.kmFilterOptions = newOptions;

      const expanded = this.preFiltering()
      this.filter();
      this.postFiltering(expanded)
    }
  }
}
