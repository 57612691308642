import {Component, HostListener, Input, OnInit} from '@angular/core';
import {StorageService} from '../../service/storage.service';
import {PrintViewerConfig} from './print-viewer-config.model';

@Component({
  selector: 'lc-print-viewer',
  templateUrl: './print-viewer.component.html',
  styleUrls: [
    'print-viewer.scss'
  ]
})
export class PrintViewerComponent implements OnInit {
  private defaultFontSize = 1.4;
  fontSize: number;

  allowCopyPrintAsText;
  _print: string;

  @Input()
  get print(): string {
    return this._print;
  }

  set print(print: string) {
    //remove possible trailing whitespaces as they tamper with the ability to visually center the print
    let lines = print.split('\n');
    lines = lines.map(value => value.replace(/\s*$/, ''));
    this._print = lines.join('\n');
  }

  @HostListener('contextmenu', ['$event'])
  @HostListener('click', ['$event'])
  @HostListener('copy', ['$event'])
  @HostListener('cut', ['$event'])
  @HostListener('mousedown', ['$event'])
  @HostListener('mousemove', ['$event'])
  onRightClick(event: any): any {
    if (!this.allowCopyPrintAsText) {
      // extra measures in addition to "disable-text-selection" making print hard to get.
      event.preventDefault();
    }
  }

  constructor(private storageService: StorageService) {
    this.allowCopyPrintAsText = storageService.getAllowCopyPrintAsText();
  }

  ngOnInit(): void {
    const printViewerConfig = this.storageService.getPrintViewerConfig();
    if (printViewerConfig) {
      this.fontSize = printViewerConfig.fontSize;
    } else {
      this.fontSize = this.defaultFontSize;
    }
  }

  private saveCurrentConfig(): void {
    const printViewerConfig = new PrintViewerConfig();
    printViewerConfig.fontSize = this.fontSize;
    this.storageService.storePrintViewerConfig(printViewerConfig);
  }

  increaseFontsize(): void {
    this.fontSize = Math.min(this.fontSize + 0.1, 1.4);
    this.saveCurrentConfig()
  }

  decreaseFontsize(): void {
    this.fontSize = Math.max(this.fontSize - 0.1, 0.5);
    this.saveCurrentConfig()
  }
}
