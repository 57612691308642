<lc-bottombar [menu]="draftMenu" [iconClass]="this.bottomMenuIconClass" [title]="this.bottomMenuTitle"
              [shortTitle]="this.bottomMenuShortTitle"></lc-bottombar>
<div class="text-center" *ngIf="loading">
    <i class="fa fa-circle-o-notch fa-spin fop-page-spinner"></i>
</div>
<router-outlet></router-outlet>
<div class="row" *ngIf="!loading">
    <div class="col-md-12">
        <h1>Opret ny sag</h1>
        <div *ngIf="reportTypeViewModel.loading" class="text-center">
            <i class="fa fa-circle-o-notch fa-spin fop-page-spinner"></i>
        </div>
        <lc-report-type
            *ngIf="!reportTypeViewModel.loading && reportTypeViewModel.hasMultiSelection()"></lc-report-type>
        <lc-draft-qapter-create *ngIf="this.isQapterAutotaksFlow()" [createViewModel]="viewmodel"
                                [details]="viewmodel.clientState"></lc-draft-qapter-create>
        <lc-draft-qapter-create *ngIf="this.isVKQapterGlastaksFlow()" [explicitReportCategory]="reportTypeViewModel.selectedType"
                                [createViewModel]="viewmodel"
                                [details]="viewmodel.clientState"></lc-draft-qapter-create>
        <lc-draft-skavu-create *ngIf="this.isUnitaksFlow()" [explicitReportCategory]="reportTypeViewModel.selectedType"
                               [createViewModel]="viewmodel"
                               [details]="viewmodel.clientState"></lc-draft-skavu-create>
        <lc-draft-skavu-create *ngIf="this.isStortaksFlow()" [explicitReportCategory]="reportTypeViewModel.selectedType"
                               [createViewModel]="viewmodel"
                               [details]="viewmodel.clientState" [taksArtKode]="'L'"></lc-draft-skavu-create>
        <lc-draft-skavu-create *ngIf="this.isMCtaksFlow()" [explicitReportCategory]="reportTypeViewModel.selectedType"
                               [createViewModel]="viewmodel"
                               [details]="viewmodel.clientState" [taksArtKode]="'M'"></lc-draft-skavu-create>
        <lc-draft-skavu-create *ngIf="this.isCamptaksFlow()" [explicitReportCategory]="reportTypeViewModel.selectedType"
                               [createViewModel]="viewmodel"
                               [details]="viewmodel.clientState" [taksArtKode]="'D'"></lc-draft-skavu-create>
        <div *ngIf="!reportTypeViewModel.loading && !reportTypeViewModel.hasMultiSelection()">
            <p>For at kunne opgøre skader i forsi, skal du vælge et eller flere Opgørelsessystemer.</p>
            <p>Dette kan du gøre under <a routerLink="/settings">'Indstillinger' -> 'Funktioner'</a></p>
        </div>
    </div>
</div>
<div class="row mb-4">
    <!-- Room for floating action buttons -->
    <div class="col-md-12 mb-4"></div>
</div>
