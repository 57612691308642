<lc-bottombar [menu]="draftMenu" [iconClass]="menuIconClass"
              [title]="menuBottomTitle"
              [shortTitle]="menuBottomShortTitle"></lc-bottombar>
<div class="text-center" *ngIf="loading">
    <i class="fa fa-circle-o-notch fa-spin fop-page-spinner"></i>
</div>
<ng-container *ngIf="!loading">
    <h2>diff clientstate</h2>
    <lc-print-diff-viewer #diff [reportDiffResult]="reportDiffResult"></lc-print-diff-viewer>
</ng-container>

