import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UserType} from '../../../shared/dto/user-type.model';
import {UpdateReportAcceptStatusDTO} from '../../model/update-report-accept-status-dto.model';
import {ReportAcceptStatus} from '../../model/report-accept-status';
import {isNullOrUndefined} from '../../../shared/utils/object-utils';
import {StringUtils} from '../../../shared/utils/string-utils';

@Component({
  templateUrl: './report-accept-respond-popup.component.html',
  styleUrls: ['report-accept-respond-popup.scss']
})
export class ReportAcceptRespondPopupComponent {
  @Input() userType: UserType.TAKS | UserType.VK;
  public reportAcceptStatus: UpdateReportAcceptStatusDTO = new UpdateReportAcceptStatusDTO();
  public rejectMessage: string;

  constructor(private activeModal: NgbActiveModal) {
  }

  public acceptValue(): ReportAcceptStatus.ACCEPTED {
    return ReportAcceptStatus.ACCEPTED;
  }

  public rejectValue(): ReportAcceptStatus.REJECTED {
    return ReportAcceptStatus.REJECTED;
  }

  public isRejected(): boolean {
    return this.reportAcceptStatus.reportAcceptStatus === ReportAcceptStatus.REJECTED;
  }

  public disableAnswerButton(): boolean {
    return isNullOrUndefined(this.reportAcceptStatus.reportAcceptStatus) || (this.isRejected() && StringUtils.isEmpty(this.rejectMessage));
  }

  public close(): void {
    this.activeModal.dismiss('cancel');
  }

  public didClickRespond(): void {
    if (this.isRejected()) {
      this.reportAcceptStatus.messageText = this.rejectMessage;
      this.reportAcceptStatus.sendMessage = true;
    } else {
      this.reportAcceptStatus.sendMessage = false;
    }
    this.activeModal.close(this.reportAcceptStatus);
  }
}
