import {Injectable} from '@angular/core';
import {BroadcastDTO} from '../dto/broadcast-dto.model';
import {VersionDTO} from '../../settings/model/version-dto.model';
import {PrintLayoutConfigurationDTO} from '../../settings/model/print-layout-configuration-dto.model';
import {DeliveryNoteConfigurationDTO} from '../../settings/model/delivery-note-configuration-dto.model';
import {FunctionConfigurationDTO} from '../../settings/model/function-configuration-dto.model';
import {PostboxConfigurationDTO} from '../../settings/model/postbox-configuration-dto.model';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SelectedLanguageDTO} from '../../settings/model/language-configuration-dto.model';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private broadcastUrl = 'broadcast';
  private versionUrl = 'open/rest/version';
  private printLayoutUrl = 'clientconfig/printlayout';
  private deliveryNoteUrl = 'clientconfig/deliverynote';
  private functionUrl = 'clientconfig/function';
  private postboxUrl = 'clientconfig/postbox';
  private languageUrl = 'clientconfig/calculationlanguage';

  constructor(private http: HttpClient) {
  }

  public getAllBroadcasts(): Observable<BroadcastDTO[]> {
    return this.http.get<BroadcastDTO[]>(this.broadcastUrl);
  }

  public deleteBroadcast(id: string): Observable<any> {
    return this.http.delete(this.broadcastUrl + '/' + id);
  }

  public getHasNewBroadcasts(): Observable<boolean> {
    return this.http.get<boolean>(this.broadcastUrl + '/hasnew');
  }

  public getVersion(): Observable<VersionDTO> {
    return this.http.get<VersionDTO>(this.versionUrl);
  }

  public getPrintLayout(): Observable<PrintLayoutConfigurationDTO> {
    return this.http.get<PrintLayoutConfigurationDTO>(this.printLayoutUrl);
  }

  public savePrintLayout(printLayout: PrintLayoutConfigurationDTO): Observable<PrintLayoutConfigurationDTO> {
    return this.http.post<PrintLayoutConfigurationDTO>(this.printLayoutUrl, printLayout);
  }

  public getDeliveryNoteConfiguration(): Observable<DeliveryNoteConfigurationDTO> {
    return this.http.get<DeliveryNoteConfigurationDTO>(this.deliveryNoteUrl);
  }

  public saveDeliveryNoteConfiguration(deliveryNoteConfiguration: DeliveryNoteConfigurationDTO): Observable<DeliveryNoteConfigurationDTO> {
    return this.http.post<DeliveryNoteConfigurationDTO>(this.deliveryNoteUrl, deliveryNoteConfiguration);
  }

  public getFunctionConfiguration(): Observable<FunctionConfigurationDTO> {
    return this.http.get<FunctionConfigurationDTO>(this.functionUrl);
  }

  public saveFunctionConfiguration(functionConfiguration: FunctionConfigurationDTO): Observable<FunctionConfigurationDTO> {
    return this.http.post<FunctionConfigurationDTO>(this.functionUrl, functionConfiguration);
  }

  public getPostboxConfiguration(): Observable<PostboxConfigurationDTO> {
    return this.http.get<PostboxConfigurationDTO>(this.postboxUrl);
  }

  public savePostboxConfiguration(functionConfiguration: PostboxConfigurationDTO): Observable<PostboxConfigurationDTO> {
    return this.http.post<PostboxConfigurationDTO>(this.postboxUrl, functionConfiguration);
  }

  public getLanguageConfiguration(): Observable<SelectedLanguageDTO> {
    return this.http.get<SelectedLanguageDTO>(this.languageUrl);
  }

  public setLanguageConfiguration(selectedLanguageDTO: SelectedLanguageDTO): Observable<SelectedLanguageDTO> {
    return this.http.put<SelectedLanguageDTO>(this.languageUrl, selectedLanguageDTO);
  }

}
