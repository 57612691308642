<div class="row">
    <div class="col-lg-4">
        <ng-container *ngFor="let e of entries; let i = index">
            <p *ngIf="(i % 3 === 0)">
                <lc-display-property [objectPropertyEntry]="e"></lc-display-property>
            </p>
        </ng-container>
    </div>
    <div class="col-lg-4">
        <ng-container *ngFor="let e of entries; let i = index">
            <p *ngIf="(i % 3 === 1)">
                <lc-display-property [objectPropertyEntry]="e"></lc-display-property>
            </p>
        </ng-container>
    </div>
    <div class="col-lg-4">
        <ng-container *ngFor="let e of entries; let i = index">
            <p *ngIf="(i % 3 === 2)">
                <lc-display-property [objectPropertyEntry]="e"></lc-display-property>
            </p>
        </ng-container>
    </div>
</div>