import {Injectable} from '@angular/core';

import {HttpClient, HttpParams} from '@angular/common/http';
import {AttachmentDTO} from 'app/shared/dto/attachment-dto.model';
import {Observable, of, Subject} from 'rxjs';
import {Cacheable} from 'ts-cacheable';
import {AttachmentMetadataDTO} from '../../dto/attachment-metadata-dto.model';
import {AttachmentMetadata} from '../../dto/attachment-metadata.model';
import {AttachmentMetadataSummary} from '../../dto/attachment-metadata-summary.model';
import {AttachmentPostProcessingDTO} from '../../dto/attachment-post-processing-dto.model';

const cacheBuster$ = new Subject<void>();
const maxAge = 5000;

@Injectable()
export class AttachmentService {
  private attachmentUrl = 'attachment';

  constructor(private http: HttpClient) {
  }

  clearCache(): void {
    cacheBuster$.next();
  }

  getAttachmentPostProcessing(attachmentId: number, accessToken: string): Observable<AttachmentPostProcessingDTO> {
    const params = new HttpParams().set('accessToken', accessToken);
    return this.http.get<AttachmentPostProcessingDTO>(this.attachmentUrl + '/' + attachmentId + '/post-processing', {params});
  }


  getAttachmentMetaData(attachmentId: number, accessToken: string): Observable<string> {
    const params = new HttpParams().set('accessToken', accessToken);
    return this.http.get<string>(this.attachmentUrl + '/' + attachmentId + '/metadata', {params});
  }

  @Cacheable({cacheBusterObserver: cacheBuster$, maxAge: maxAge})
  getAttachmentMetaDataBatch(attachments: AttachmentDTO[]): Observable<AttachmentMetadataDTO[]> {
    if (attachments && attachments.length > 0) {
      return this.http.post<AttachmentMetadataDTO[]>(this.attachmentUrl + '/metadata-batch', attachments);
    } else {
      return of([]);
    }
  }

  @Cacheable({cacheBusterObserver: cacheBuster$, maxAge: maxAge})
  generateMetadataSummary(data: AttachmentMetadataDTO[]): Observable<AttachmentMetadataSummary> {
    const summary = new AttachmentMetadataSummary();
    data.forEach(attachmentMetadataDTO => {
      const model = AttachmentMetadata.convertFromAttachmentMetadataDTO(attachmentMetadataDTO);
      
      if (summary.baseline == null) {
        model.distanceToBaseline = 0;
      } else {
        model.distanceToBaseline = this.calculateDistance(summary.baseline, model);
      }
      summary.addAttachmentMetadata(model);

    });
    
    return of(summary);
  }
  
  private calculateDistance(baseline: AttachmentMetadata, attachmentMetadata: AttachmentMetadata): number {
      const lon1 = baseline.longitude * (Math.PI / 180);
      const lon2 = attachmentMetadata.longitude * (Math.PI / 180);
      const lat1 = baseline.latitude * (Math.PI / 180);
      const lat2 = attachmentMetadata.latitude * (Math.PI / 180);

      // Haversine formula  
      const dlon = lon2 - lon1;
      const dlat = lat2 - lat1;
      const a = Math.pow(Math.sin(dlat / 2), 2)
      + Math.cos(lat1) * Math.cos(lat2)
      * Math.pow(Math.sin(dlon / 2), 2);

      const c = 2 * Math.asin(Math.sqrt(a));
      
      // calculate the result based on Radius of earth in meters is 6371000.
      return c * 6371000;
  }

  downloadAttachmentBlob(attachment: AttachmentDTO): Observable<Blob> {
    return this.http.get('attachment/' + attachment.id + '?thumbnail=false&download=false&accessToken=' + attachment.accessToken, {responseType: 'blob'});
  }

}
