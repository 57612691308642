<div class="modal-header" style="text-align: center">
    <h3 class="text-truncate">
        Nyt login til Autotaks

    </h3>
</div>
<div class="modal-body">

    <div class="row">
        <div class="col">
            <p>
                F&P ønsker at styrke sikkerheden ved login på forsi.dk/Autotaks og indfører 2 faktor login.
                Du opretter dig ved at klikke på "Gå videre", hvor du vil få mere information
                eller du kan vente til næste gang du logger dig på. Dette gøres ved at klikke på "Vent til senere".
                Du kan maksimalt vente i 7 dage.
            </p>
            <p>
                Det betyder, at vi ændrer dit brugernavn fra det du kender i dag, til at være din mailadresse.
                Du bedes have følgende klar inden du klikker på "Gå videre":
            </p>

            <h4>Email</h4>
            <p>
                F&P anbefaler at du benytter den mailadresse, der er givet til dig som ansat i dit selskab / firma.
            </p>

            <h4>Mobilnummer</h4>
            <p>
                F&P anbefaler at du benytter det mobilnummer, der er givet til dig som ansat i dit selskab-/firma.
            </p>
            <p>
                Efter du har indtastet din mailadresse og klikket på "Gå videre" vil du modtage en mail, der vil
                forklare det næste skridt.
            </p>
        </div>
    </div>
    <div class="pb-3">
        <lc-b2c-email-input (mail)="b2cUser.email = $event"></lc-b2c-email-input>
    </div>
</div>
<div class="modal-footer">
    <div class="pull-right">
        <button class="btn btn-primary" [disabled]="!this.b2cUser.email" (click)="startB2C()">Gå videre</button>
    </div>
    <div class="pull-right" [ngbTooltip]="canPostpone() ? '' : 'Du kan maksimalt vente 7 dage'">
        <button class="btn btn-secondary" [disabled]="!canPostpone()" (click)="postponeB2C()">Vent til senere</button>
    </div>
</div>
