<fieldset ngForm="f" #f="ngForm">
    <h2>Skadeomfang</h2>
    <div class="card mt-1">
        <div class="card-body">
            <h5>Uspecificeret</h5>

            <div class="row">
                <div class="col-sm-3">
                    <label class="col-form-label" for="arbejds">Arbejdsløn</label>
                    <input type="tel" class="form-control" lcInputMask="int6" id="arbejds" name="arbejdslon"
                           [(ngModel)]="details.skavu.unspecifiedSaleryAmount">
                </div>
                <div class="col-sm-3">
                    <label class="col-form-label" for="spareparts">Reservedele ifølge bilag</label>
                    <input type="tel" class="form-control" lcInputMask="int6" id="spareparts" name="reservedele"
                           [(ngModel)]="details.skavu.unspecifiedSparepartsAmount">
                </div>
                <div class="col-sm-3">
                    <label class="col-form-label" for="licenseplate">Nummerplader (momsfrit)</label>
                    <input type="text" class="form-control" lcInputMask="int6_2RightAlign" id="licenseplate"
                           name="nummerpladder"
                           [(ngModel)]="details.skavu.unspecifiedLicensplatesAmount">
                </div>
                <div class="col-sm-3">
                    <label class="col-form-label" for="lak">Aftalt lakpris</label>
                    <input type="tel" class="form-control" lcInputMask="int6" id="lak" [(ngModel)]="lak" name="lak">
                </div>
            </div>

        </div>
    </div>
    <div class="card mt-1">
        <div class="card-body">
            <h5>Specificeret</h5>
            <div class="row">
                <!-- Left part -->
                <div class="col-md-1">
                    <button (click)="onCreateNew(null)" class="btn btn-primary" type="button">Ny</button>
                </div>
                <div class="col-md-4">
                    <lc-autocomplete id="positions" name="positions" class="custom-control"
                                     [disabled]="showLak || showAllSelected"
                                     [items]="positions" [ngModel]="position" debugInfo="positions"
                                     itemKey="id" itemText="text"
                                     (ngModelChange)="selectPositions($event,subPosition)"></lc-autocomplete>

                </div>
                <div class="col-md-4">
                    <lc-autocomplete id="subpositions" name="subpositions" class="custom-control"
                                     [disabled]="showLak || showAllSelected"
                                     [items]="subPositions" [ngModel]="subPosition" debugInfo="subpositions"
                                     itemKey="id" itemText="text"
                                     (ngModelChange)="selectPositions(position,$event)"></lc-autocomplete>
                </div>
                <div class="col-md-3">
                    <div class="row ms-1">
                        <div class="col-md-6 col-12 form-check">
                            <input id="showSelected" class="form-check-input" type="checkbox" [(ngModel)]="filter"
                                   name="showSelected"
                                   [disabled]="showLak || showAllSelected || !position"
                                   (ngModelChange)="onFilterChange()">
                            <label class="form-check-label" for="showSelected">Vis kun valgte</label>
                        </div>
                        <div class="col-md-6 col-12 form-check">
                            <input id="showLak" class="form-check-input" type="checkbox" [(ngModel)]="showLak"
                                   name="showallak"
                                   (ngModelChange)="onShowSelectedChange(true,$event)">
                            <label class="form-check-label" for="showLak">Vis alle lak</label>
                        </div>
                    </div>
                </div>

            </div>
            <div class="row">
                <div class="col-12 table-responsive">

                </div>
            </div>

            <div class="row">
                <div class="col-12 table-responsive">
                    <table class="table">
                        <thead>
                        <tr class="d-none d-md-table-row">
                            <th scope="col">&nbsp;</th>
                            <th scope="col">&nbsp;</th>
                            <th scope="col">Arbejdstekst</th>
                            <th scope="col">Ekstratekst</th>
                            <th scope="col">Timer</th>
                            <th scope="col">Arbejdsbeløb</th>
                            <th scope="col">Reservedelspris</th>
                            <th scope="col">NfG(%)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <ng-container
                            *ngFor="let unmatchedSelectedSkavuPositionValue of unmatchedSelectedSkavuPositionValues">
                            <ng-container
                                *ngTemplateOutlet="unmatchedpositionvalue;context:{$implicit:{'positionvalue':unmatchedSelectedSkavuPositionValue}}"></ng-container>
                        </ng-container>
                        <ng-container
                            *ngTemplateOutlet="aposition;context:{$implicit:{'position':position}}"></ng-container>
                        <ng-container
                            *ngTemplateOutlet="aposition;context:{$implicit:{'position':subPosition}}"></ng-container>
                        <ng-container *ngFor="let subPositionNode of subSubPositions">
                            <ng-container
                                *ngTemplateOutlet="aposition;context:{$implicit:{'position':subPositionNode}}"></ng-container>
                        </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <ng-template #aposition let-ctx>
        <ng-container *ngIf="ctx.position && hasAnyCheckedValues(ctx.position)">
            <ng-container *ngFor="let value of ctx.position.values">
                <ng-container
                    *ngTemplateOutlet="apositionvalue;context:{$implicit:{'position':ctx.position,'positionvalue':value}}"></ng-container>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="ctx.position && !hasAnyCheckedValues(ctx.position)">
            <ng-container
                *ngTemplateOutlet="apositionnovalue;context:{$implicit:{'position':ctx.position}}"></ng-container>
        </ng-container>
    </ng-template>

    <ng-template #apositionnovalue let-ctx>
        <tr *ngIf="!filter && ctx.position.follow">
            <td class="table-button">&nbsp;
            </td>
            <td class="form-check"><input type="checkbox" (change)="onCheckboxChanged(ctx.position,null, true)"
                                          class="form-check-input">
            </td>
            <td>{{ctx.position.position.arbejdsTekst}}</td>
            <td>&nbsp;</td>
            <td class="d-none d-md-table-cell">&nbsp;</td>
            <td class="d-none d-md-table-cell">&nbsp;</td>
            <td class="d-none d-md-table-cell">&nbsp;</td>
            <td class="d-none d-md-table-cell">&nbsp;</td>
        </tr>
    </ng-template>

    <ng-template #apositionvalue let-ctx>
        <tr *ngIf="doShow(ctx.positionvalue) && ctx.position.follow">
            <td class="table-button">
                <div *ngIf="ctx.positionvalue.position == 1 && ctx.positionvalue.checked"
                     class="table-button" (click)="onCreateNew(ctx.position)">
                    <i class="fa fa-plus fa-2x"></i>
                </div>
            </td>
            <td class="form-check"><input type="checkbox" [ngModel]="ctx.positionvalue && ctx.positionvalue.checked"
                                          name="positionCheck"
                                          (ngModelChange)="onCheckboxChanged(ctx.position, ctx.positionvalue, $event)"
                                          class="form-check-input">
            </td>

            <td>
                <div class="d-md-none d-sm-block">Arbejdstekst</div>
                <input type="text" maxlength="39"
                       [name]="'worktext' + ctx.positionvalue.nr + ctx.positionvalue.position" class="form-control text-uppercase"
                       (keydown.tab)="toggleExtratext(ctx.positionvalue, $event)"
                       [(ngModel)]="ctx.positionvalue.workText" [disabled]="!ctx.positionvalue.checked"
                       placeholder="Arbejdstekst">
            </td>
            <td>
                <div class="d-md-none d-sm-block ">Ekstratekst</div>
                <div class="pt-2 ">
                    <a>
                        <i class="fa fa-pencil"
                           tabindex="0" (click)="toggleExtratext(ctx.positionvalue,$event)"></i>
                        <ng-container *ngFor="let extraText of ctx.positionvalue.extraTexts">
                            {{extraText}}
                        </ng-container>
                    </a>
                </div>
            </td>
            <td class="d-none d-md-table-cell">
                <input type="text" lcInputMask="int6_1"
                       [name]="'hours' + ctx.positionvalue.nr + ctx.positionvalue.position" class="form-control"
                       [(ngModel)]="ctx.positionvalue.hoursStr" [disabled]="ctx.positionvalue.hoursDisabled"
                       [ngModelOptions]="{ updateOn: 'blur' }"
                       placeholder="Timer">
            <td class="d-none d-md-table-cell">
                <input type="tel" lcInputMask="int6"
                       [name]="'amount' + ctx.positionvalue.nr + ctx.positionvalue.position" class="form-control"
                       [(ngModel)]="ctx.positionvalue.amountStr" [disabled]="ctx.positionvalue.amountDisabled"
                       [ngModelOptions]="{ updateOn: 'blur' }"
                       placeholder="Arbejdsbeløb">
            </td>
            <td class="d-none d-md-table-cell">
                <input type="tel" lcInputMask="int6"
                       [name]="'sparepartprice' + ctx.positionvalue.nr + ctx.positionvalue.position"
                       class="form-control"
                       [(ngModel)]="ctx.positionvalue.sparePartPriceStr" [disabled]="!ctx.positionvalue.checked"
                       placeholder="Reservedelspris">
            </td>
            <td class="d-none d-md-table-cell">
                <input type="tel" lcInputMask="int6" [name]="'nfg' + ctx.positionvalue.nr + ctx.positionvalue.position"
                       class="form-control"
                       [(ngModel)]="ctx.positionvalue.nfgPctStr" [disabled]="!ctx.positionvalue.checked"
                       placeholder="NfG(%)">
            </td>
        </tr>
        <tr *ngIf="doShow(ctx.positionvalue) && ctx.position.follow" class="d-md-none d-sm-table-row">
            <td style="border-top: 0px;">&nbsp;</td>
            <td style="border-top: 0px;">&nbsp;</td>
            <td style="border-top: 0px;">
                <div>Timer</div>
                <input type="text" lcInputMask="int6_1"
                       [name]="'hours2' + ctx.positionvalue.nr + ctx.positionvalue.position" class="form-control"
                       [(ngModel)]="ctx.positionvalue.hoursStr" [disabled]="ctx.positionvalue.hoursDisabled"
                       [ngModelOptions]="{ updateOn: 'blur' }"
                       placeholder="Timer">
            <td style="border-top: 0px; width: 40%;">
                <div>Arbejdsløn</div>
                <input type="tel" lcInputMask="int6"
                       [name]="'amount2' + ctx.positionvalue.nr + ctx.positionvalue.position" class="form-control"
                       [(ngModel)]="ctx.positionvalue.amountStr" [disabled]="ctx.positionvalue.amountDisabled"
                       [ngModelOptions]="{ updateOn: 'blur' }"
                       placeholder="Arbejdsbeløb">
            </td>
        </tr>
        <tr *ngIf="doShow(ctx.positionvalue) && ctx.position.follow" class="d-md-none d-sm-table-row">
            <td style="border-top: 0px;">&nbsp;</td>
            <td style="border-top: 0px;">&nbsp;</td>
            <td style="border-top: 0px;">
                <div>Reservedelspris</div>
                <input type="tel" lcInputMask="int6"
                       [name]="'sparepartprice2' + ctx.positionvalue.nr + ctx.positionvalue.position"
                       class="form-control"
                       [(ngModel)]="ctx.positionvalue.sparePartPriceStr" [disabled]="!ctx.positionvalue.checked"
                       placeholder="Reservedelspris">
            </td>
            <td style="border-top: 0px;">
                <div>NfG(%)</div>
                <input type="tel" lcInputMask="int6" [name]="'nfg2' + ctx.positionvalue.nr + ctx.positionvalue.position"
                       class="form-control"
                       [(ngModel)]="ctx.positionvalue.nfgPctStr" [disabled]="!ctx.positionvalue.checked"
                       placeholder="NfG(%)">
            </td>
        </tr>
        <tr *ngIf="ctx.positionvalue === expandedValue">
            <td colspan="8">
                <ul class="list-unstyled" (click)="clickedInside($event)">
                    <li *ngFor="let extraText of extraTexts">
                        <lc-multiselect-item [(model)]="extraText.selected"
                                             [missing]="false"
                                             [disabled]="disableExtratext(extraText.code)">
                            {{extraText.text}}
                        </lc-multiselect-item>
                    </li>
                </ul>
            </td>
        </tr>
    </ng-template>

    <ng-template #unmatchedpositionvalue let-ctx>
        <tr *ngIf="doShow(ctx.positionvalue)">
            <td>&nbsp;</td>
            <td class="form-check"><input type="checkbox" [ngModel]="ctx.positionvalue && ctx.positionvalue.checked"
                                          name="positionCheck"
                                          (ngModelChange)="onUnmatchedCheckboxPositionValueChanged(ctx.positionvalue, $event)"
                                          class="form-check-input">
            </td>

            <td>
                <div class="d-md-none d-sm-block">Arbejdstekst</div>
                <input type="text" maxlength="39"
                       [name]="'worktext' + ctx.positionvalue.nr + ctx.positionvalue.position" class="form-control text-uppercase"
                       (keydown.tab)="toggleExtratext(ctx.positionvalue, $event)"
                       [(ngModel)]="ctx.positionvalue.workText" [disabled]="!ctx.positionvalue.checked"
                       placeholder="Arbejdstekst">
            </td>

            <td>
                <div class="d-md-none d-sm-block">Ekstratekst</div>
                <div class="pt-2">
                    <a
                        (click)="toggleExtratext(ctx.positionvalue,$event)" tabindex="0">
                        <i class="fa fa-pencil"></i>
                        <ng-container *ngFor="let extraText of ctx.positionvalue.extraTexts">
                            {{extraText}}
                        </ng-container>
                    </a>
                </div>
            </td>
            <td class="d-none d-md-table-cell">
                <input type="text" lcInputMask="int6_1"
                       [name]="'hours' + ctx.positionvalue.nr + ctx.positionvalue.position" class="form-control"
                       [(ngModel)]="ctx.positionvalue.hoursStr" [disabled]="ctx.positionvalue.hoursDisabled"
                       [ngModelOptions]="{ updateOn: 'blur' }"
                       placeholder="Timer">
            <td class="d-none d-md-table-cell">
                <input type="tel" lcInputMask="int6"
                       [name]="'amount' + ctx.positionvalue.nr + ctx.positionvalue.position" class="form-control"
                       [(ngModel)]="ctx.positionvalue.amountStr" [disabled]="ctx.positionvalue.amountDisabled"
                       [ngModelOptions]="{ updateOn: 'blur' }"
                       placeholder="Arbejdsbeløb">
            </td>
            <td class="d-none d-md-table-cell">
                <input type="tel" lcInputMask="int6"
                       [name]="'sparepartprice' + ctx.positionvalue.nr + ctx.positionvalue.position"
                       class="form-control"
                       [(ngModel)]="ctx.positionvalue.sparePartPriceStr" [disabled]="!ctx.positionvalue.checked"
                       placeholder="Reservedelspris">
            </td>
            <td class="d-none d-md-table-cell">
                <input type="tel" lcInputMask="int6" [name]="'nfg' + ctx.positionvalue.nr + ctx.positionvalue.position"
                       class="form-control"
                       [(ngModel)]="ctx.positionvalue.nfgPctStr" [disabled]="!ctx.positionvalue.checked"
                       placeholder="NfG(%)">
            </td>
        </tr>
        <tr *ngIf="doShow(ctx.positionvalue)" class="d-md-none d-sm-table-row">
            <td style="border-top: 0px;">&nbsp;</td>
            <td style="border-top: 0px;">&nbsp;</td>
            <td style="border-top: 0px;">
                <div>Timer</div>
                <input type="text" lcInputMask="int6_1"
                       [name]="'hours2' + ctx.positionvalue.nr + ctx.positionvalue.position" class="form-control"
                       [(ngModel)]="ctx.positionvalue.hoursStr" [disabled]="ctx.positionvalue.hoursDisabled"
                       [ngModelOptions]="{ updateOn: 'blur' }"
                       placeholder="Timer">
            <td style="border-top: 0px; width: 40%;">
                <div>Arbejdsløn</div>
                <input type="tel" lcInputMask="int6"
                       [name]="'amount2' + ctx.positionvalue.nr + ctx.positionvalue.position" class="form-control"
                       [(ngModel)]="ctx.positionvalue.amountStr" [disabled]="ctx.positionvalue.amountDisabled"
                       [ngModelOptions]="{ updateOn: 'blur' }"
                       placeholder="Arbejdsbeløb">
            </td>
        </tr>
        <tr *ngIf="doShow(ctx.positionvalue)" class="d-md-none d-sm-table-row">
            <td style="border-top: 0px;">&nbsp;</td>
            <td style="border-top: 0px;">&nbsp;</td>
            <td style="border-top: 0px;">
                <div>Reservedelspris</div>
                <input type="tel" lcInputMask="int6"
                       [name]="'sparepartprice2' + ctx.positionvalue.nr + ctx.positionvalue.position"
                       class="form-control"
                       [(ngModel)]="ctx.positionvalue.sparePartPriceStr" [disabled]="!ctx.positionvalue.checked"
                       placeholder="Reservedelspris">
            </td>
            <td style="border-top: 0px;">
                <div>NfG(%)</div>
                <input type="tel" lcInputMask="int6" [name]="'nfg2' + ctx.positionvalue.nr + ctx.positionvalue.position"
                       class="form-control"
                       [(ngModel)]="ctx.positionvalue.nfgPctStr" [disabled]="!ctx.positionvalue.checked"
                       placeholder="NfG(%)">
            </td>
        </tr>
        <tr *ngIf="ctx.positionvalue === expandedValue">
            <td colspan="8">
                <ul class="list-unstyled" (click)="clickedInside($event)">
                    <li *ngFor="let extraText of extraTexts">
                        <lc-multiselect-item [(model)]="extraText.selected"
                                             [missing]="false"
                                             [disabled]="disableExtratext(extraText.code)">
                            {{extraText.text}}
                        </lc-multiselect-item>
                    </li>
                </ul>
            </td>
        </tr>
    </ng-template>

    <h2>Diverse</h2>
    <div class="card mt-1">
        <div class="card-body">
            <div class="row">
                <div class="col-md-6">

                </div>
            </div>
            <div class="row">
                <div class="col-12 text-start">
                    <button class="btn btn-primary" (click)="createCustomValue()">Ny</button>
                </div>
            </div>
            <div class="row">
                <div class="col-12 table-responsive">
                    <table class="table">
                        <thead>
                        <tr>
                            <th scope="col">&nbsp;</th>
                            <th scope="col">Arbejdstekst</th>
                            <th scope="col">Beløb</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let customvalue of details.skavu.customValues, let i = index">
                            <td>
                                <div class="form-check"><input type="checkbox" class="form-check-input"
                                                               [(ngModel)]="customvalue.checked"
                                                               name="checkboxcustompos"></div>
                            </td>
                            <td><input type="text" [name]="'hours' + i" class="form-control text-uppercase"
                                       [(ngModel)]="customvalue.workText">
                            </td>
                            <td><input type="tel" [name]="'amount' + i" class="form-control text-uppercase"
                                       [(ngModel)]="customvalue.amountStr"></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</fieldset>
