import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {VERSION_CHECK_URL, VERSION} from '../../app.constants';
import {BehaviorSubject, Subscription} from 'rxjs';
import {AppVisibilityService} from './core/app-visibility.service';

@Injectable({
  providedIn: 'root',
})
export class VersionCheckService {

  private checkUrl = VERSION_CHECK_URL;
  private versionUpdated = false;
  public versionUpdatedSub: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isChecking = false;
  private versionCheckerSubscription: Subscription;

  constructor(private http: HttpClient, private appVisibilityService: AppVisibilityService ) {
  }

  /**
   * Checks in every set frequency the version of frontend application
   *
   * @param {number} frequency - in milliseconds, defaults to 30 minutes
   */
  public startVersionCheck(frequency = 1000 * 60 * 30): void {
    if (!this.versionCheckerSubscription) {
      this.versionCheckerSubscription = this.appVisibilityService.forsiVisibilityInterval(frequency, frequency).subscribe(() => {
        this.checkVersion();
      });
    }
  }

  /**
   * Will do the call and check if the hash has changed or not
   */
  public checkVersion(): void {
    if (!this.versionUpdated && !this.isChecking) {
      console.log('Checking Version... ');
      this.isChecking = true;
      // timestamp these requests to invalidate caches
      this.http.get(this.checkUrl + '?t=' + new Date().getTime()).subscribe(
        (response: any) => {
          const newVersion = response.version;
          if (VERSION !== newVersion) {
            this.versionUpdated = true;
            this.versionUpdatedSub.next(this.versionUpdated);
          }
        },
        (err: any) => {
        },
        () => {
          this.isChecking = false;
        }
      );
    }
  }

}
