<div class="card">
    <div class="card-body">
        <form class="col mt-3" (submit)="toggleSearchOptions()">
            <div class="row pb-3">
                <div class="col">
                    <label class="form-label">Type af søgning</label>
                    <select [(ngModel)]="chosenSearchType" class="form-select" id="chosenSearchType"
                            name="chosenSearchType" (change)="onSearchTypeChanged()">
                        <option *ngFor="let chosenSearchType of searchTypes.getTypes()" [ngValue]="chosenSearchType">
                            {{chosenSearchType.getLabel()}}
                        </option>
                    </select>
                    <div *ngIf="enableOnlyDraftCheckBox()" class="form-check ms-1 mt-1">
                        <input type="checkbox" class="form-check-input"
                               [(ngModel)]="onlyDrafts"
                               (change)="emitOnDraftsOnlyChanged()"/>
                        <label class="form-check-label">
                            Kun kladder
                        </label>
                    </div>
                </div>
            </div>
        </form>
        <form (submit)="search()">
            <fieldset class="col-12 mb-2" ngForm="f" #f="ngForm" (submit)="toggleSearchOptions()">
                <ng-container *ngIf="isSearchTypeTaksRapp()">
                    <div class="row pb-3">
                        <div class="col">
                            <label class="form-label">
                                Arbejdsgiver
                            </label>
                            <input name="arbgiv" type="text" minlength="2" maxlength=2 size="2" class="form-control"
                                   [(ngModel)]="rappNr.companyId" required/>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <div class="col">
                            <label class="form-label">
                                Taksator-/vkNr
                            </label>
                            <input name="taksnr" type="text" minlength="1" maxlength=5 size="5"
                                   class="form-control col-12" [(ngModel)]="rappNr.tvNr" required/>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <div class="col">
                            <label class="form-label">
                                År
                            </label>
                            <input name="taksrapyear" type="text" minlength="2" maxlength=2 size="2"
                                   class="form-control col-12" [(ngModel)]="rappNr.year" required/>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <div class="col">
                            <label class="form-label">
                                Rapportnr
                            </label>
                            <input name="taksrapnr" type="text" minlength="1" maxlength=4 size="4"
                                   class="form-control col-12" [(ngModel)]="rappNr.reportNr" required/>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <div class="col">
                            <label class="form-label">
                                Type
                            </label>
                            <input name="taksraptype" type="text" minlength="1" maxlength=2 size="2"
                                   class="form-control col-12" [(ngModel)]="rappNr.reportType" required/>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="isSearchTypeProformaInvoiceNr()">
                    <div class="row pb-3">
                        <div class="col">
                            <label class="form-label">Rapport prefix</label>
                            <input name="vkrapprefix" type="text" maxlength=2 size="2" class="form-control col-12"
                                   [(ngModel)]="vkNr.companyId" readonly/>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <div class="col">
                            <label class="form-label">Vk nr.</label>
                            <input name="vknr" type="text" maxlength=5 size="5" class="form-control col-12"
                                   [(ngModel)]="vkNr.tvNr" required/>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <div class="col">
                            <label class="form-label">År</label>
                            <input name="vkrapyear" type="text" maxlength=2 size="2" class="form-control col-12"
                                   [(ngModel)]="vkNr.year" required/>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <div class="col">
                            <label class="form-label">Rapportnr</label>
                            <input name="vkrapnr" type="text" maxlength=4 size="4" class="form-control col-12"
                                   [(ngModel)]="vkNr.reportNr" required/>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <div class="col">
                            <label class="form-label">Type</label>
                            <input name="vkraptype" type="text" maxlength=2 size="2" class="form-control col-12"
                                   [(ngModel)]="vkNr.reportType" readonly/>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="isSearchTypeRegNr()" class="col-12">
                    <div class="row pb-3">
                        <div class="col">
                            <label class="form-label">Regnr</label>
                            <input name="regnr" type="text" minlength="2" maxlength=10 size="10"
                                   lcInputMask="regNr"
                                   oninput="this.value = this.value.toUpperCase()" [(ngModel)]="regnr"
                                   class="form-control col-12" required/>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="isSearchTypeStelnummer()" class="col-12">
                    <div class="row pb-3">
                        <div class="col">
                            <label class="form-label">Stelnummer</label>
                            <input name="stelnummer" type="text" maxlength=20 size="20"
                                   lcInputMask="stelNr"
                                   oninput="this.value = this.value.toUpperCase()" [(ngModel)]="stelnummer"
                                   class="form-control col-12" required/>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="isSearchTypeSkadenummer()" class="col-12">
                    <div class="row pb-3">
                        <div class="col">
                            <label class="form-label">Skadenummer</label>
                            <input name="skadenummer" type="text" maxlength=35 size="35"
                                   oninput="this.value = this.value.toUpperCase()" [(ngModel)]="skadenummer"
                                   class="form-control col-12" required/>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="isSearchTypeAssessorCases()" class="col-12">
                    <div class="row pb-3">
                        <div class="col">
                            <label class="form-label">Model</label>
                            <input name="assessorCasesModel" type="text" maxlength=10 size="10" class="form-control col-12"
                                   oninput="this.value = this.value.toUpperCase()"
                                   [(ngModel)]="searchAssessorCases.model"
                                   [required]="assessorCaseSearchPropIsRequired('assessorCasesModel')"/>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <div class="col">
                            <label class="form-label">Fabrikat</label>
                            <input name="assessorCasesFabrikat" type="text" maxlength=26 size="10"
                                   class="form-control col-12" oninput="this.value = this.value.toUpperCase()"
                                   [(ngModel)]="searchAssessorCases.fabrikat"
                                   [required]="assessorCaseSearchPropIsRequired('assessorCasesFabrikat')"/>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <div class="col">
                            <label class="form-label">Taksator</label>
                            <select [(ngModel)]="selectedUser" class="form-select" id="selectedUser"
                                    name="selectedUser"
                                    [required]="assessorCaseSearchPropIsRequired('assessorCasesAssessorNr')"
                                    (change)="onAssessorChosen()">
                                <option *ngFor="let selectedUser of users" class="select-items" [ngValue]="selectedUser">
                                    {{selectedUser.name}} - {{getZeroPaddedAssessorNr(selectedUser.atClientNumber)}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <div class="col">
                            <label class="form-label">Værksted</label>
                            <input name="assessorCasesWorkshop" type="tel" maxlength=5 size="5" class="form-control col-12"
                                   [(ngModel)]="searchAssessorCases.vkNummer"
                                   [required]="assessorCaseSearchPropIsRequired('assessorCasesWorkshop')"/>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <div class="col">
                            <label class="form-label">Regnr</label>
                            <input name="assessorCasesRegnr" type="text" maxlength=10 size="10" class="form-control col-12"
                                   oninput="this.value = this.value.toUpperCase()"
                                   [(ngModel)]="searchAssessorCases.regnr"
                                   [required]="assessorCaseSearchPropIsRequired('assessorCasesRegnr')"/>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <div class="col">
                            <label class="form-label">Stelnr</label>
                            <input name="assessorCasesVin" type="text" maxlength=20 size="20" class="form-control col-12"
                                   [(ngModel)]="searchAssessorCases.vin"
                                   [required]="assessorCaseSearchPropIsRequired('assessorCasesVin')"/>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <div class="col">
                            <label class="form-label">Fra</label>
                            <div class="input-group">
                                <input type="tel" class="me-1 mb-1 col-10 form-control"
                                       id="fraDato" name="fraDato"
                                       ngbDatepicker #fraDatoDP="ngbDatepicker" lcInputMask="date"
                                       [minDate]="{ year: 1900, month: 1, day: 1 }"
                                       [(ngModel)]="searchAssessorCases.ngFromDate"
                                       [required]="assessorCaseSearchPropIsRequired('dateFrom')"
                                />
                                <button class="input-group-addon clickable btn col-2" (click)="fraDatoDP.toggle()"
                                        type="button">
                                    <i class="fa fa-calendar fa-lg"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="row pb-3">
                        <div class="col">
                            <label class="form-label">Til</label>
                            <div class="input-group">
                                <input type="tel" class="me-1 mb-1 col-10 form-control"
                                       id="tilDato" name="tilDato"
                                       ngbDatepicker #tilDatoDP="ngbDatepicker" lcInputMask="date"
                                       [minDate]=searchAssessorCases.ngFromDate
                                       [(ngModel)]="searchAssessorCases.ngToDate"
                                       [required]="assessorCaseSearchPropIsRequired('dateTo')"
                                />
                                <button class="input-group-addon clickable btn col-2" (click)="tilDatoDP.toggle()"
                                        type="button">
                                    <i class="fa fa-calendar fa-lg"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </fieldset>
            <button type="submit" style="display:none">hidden submit</button>
        </form>
    </div>
</div>
