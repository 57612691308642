export class VideoRequestDTO {
  reportId: number;
  clientStateId: number;
  state: VideoRequestState;
  requestedAt: number;
  uploadedAt: number;
  message: string;
}

export enum VideoRequestState {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED'
}
