import {AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {PrincipalService} from '../../../shared';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ChangePasswordModalComponent} from './changepassword/change-password-modal.component';
import {SettingsService} from '../../../shared/service/settings.service';
import {NewBroadcastsPopupComponent} from '../../../shared/modals/new-broadcasts/new-broadcasts-popup.component';
import {StorageService} from '../../../shared/service/storage.service';
import {BehaviorSubject, combineLatest, fromEvent, iif, of, Subject} from 'rxjs';
import {distinctUntilChanged, filter, map, mergeMap, takeUntil} from 'rxjs/operators';
import {isNullOrUndefined} from '../../../shared/utils/object-utils';
import {OAuthAuthenticationService} from '../../../shared/o-auth/forsi/o-auth-authentication-service';
import {LoadingSpinnerUtil} from '../../../shared/utils/loading-spinner-util';
import {B2C_FLOW, LOGIN_FLOW} from '../../../shared/o-auth/forsi/model/o-auth-login-flow-dto.model';
import {MsalBroadcastService, MsalService} from '@azure/msal-angular';
import {EventType, InteractionStatus, RedirectRequest} from '@azure/msal-browser';
import {B2CConfigurationService} from '../../../shared/service/b2c-configuration.service';

@Component({
  selector: 'lc-login-modal',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
  private unsubscribe$ = new Subject<void>();
  @ViewChild('usernameInput', {static: true}) usernameInput;
  @ViewChild('passwordInput', {static: true}) passwordInput;

  spinnerUtil = new LoadingSpinnerUtil();
  isLoading: boolean;
  authenticationMessage: string;
  providerInfoMessage: string;
  showGeneralProviderInfo: boolean;
  password: string;
  username: string;
  credentials: any;
  loginFlow: LOGIN_FLOW;
  b2cFlow: B2C_FLOW;
  b2cInProgress = false;
  b2cStatus: InteractionStatus;

  $capslockEnabled = new BehaviorSubject<boolean>(false);
  @Output('onLogin') onLogin: EventEmitter<string> = new EventEmitter<string>();

  constructor(private router: Router,
              private oAuthAuthenticationService: OAuthAuthenticationService,
              private storageService: StorageService,
              private principalService: PrincipalService,
              private modalService: NgbModal,
              private settingsService: SettingsService,
              private msalService: MsalService,
              private msalBroadcastService: MsalBroadcastService,
              private b2CConfigurationService: B2CConfigurationService) {
    this.credentials = {};
    this.isLoading = false;
  }

  ngOnInit(): void {
    this.username = this.storageService.getLastLoggedInUsername();
    //update capslock detection
    fromEvent(document, 'keyup').pipe(
      filter(event =>
        //when the browser prefills passwords from previously saved passwords
        //an keyup event is fired, but the getModifierState doesn't exist
         typeof event['getModifierState'] === 'function'
      ),
      map((key: KeyboardEvent) => {
        const pressedCapsKey = key.key === 'CapsLock';
        const modifiedByCapslock = key.getModifierState('CapsLock');
        return pressedCapsKey ? !modifiedByCapslock : modifiedByCapslock;
      }),
      distinctUntilChanged(),
      takeUntil(this.unsubscribe$)
    ).subscribe(this.$capslockEnabled);

    this.msalBroadcastService.msalSubject$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      eventMessage => {
        if (eventMessage.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
          console.log('failed to acquire token. Will call logoutRedirect to ensure stored token is removed.', eventMessage);
          this.msalService.logoutRedirect();
        }
      }
    )
    this.msalBroadcastService.inProgress$
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe((status) => {
        this.b2cInProgress = true;
        this.b2cStatus = status;
        if (status === InteractionStatus.None || status === InteractionStatus.Startup) {
          this.checkAndSetActiveAccount();
          if (!!this.msalService.instance?.getActiveAccount()) {
            this.oAuthAuthenticationService.authenticateB2C().pipe(takeUntil(this.unsubscribe$)).subscribe({
              next:  () => this.onSuccessfulLogin(),
              error: (error) => {
                this.authenticationMessage = `Login fejl: ${error.message}`;
                console.log('Failed to login', {error});
                this.msalService.logoutRedirect();
                this.b2cInProgress = false;
              }
            });
          } else {
            this.b2cInProgress = false;
          }
        }
      })
  }

  checkAndSetActiveAccount(): void {
    const activeAccount = this.msalService.instance.getActiveAccount();
    const allAccounts = this.msalService.instance.getAllAccounts();
    if (!activeAccount && allAccounts.length > 0) {
      this.msalService.instance.setActiveAccount(allAccounts[0]);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  ngAfterViewInit(): void {
    this.updateFocus();
  }

  private updateFocus(): void {
    setTimeout(() => {
      if (!this.loginFlow && this.usernameInput) {
        this.usernameInput.nativeElement.focus();
      } else if (this.passwordInput) {
        this.passwordInput.nativeElement.focus();
      }
    }, 1)
  }

  cancel(): void {
    this.credentials = {
      username: null,
      password: null
    };

    this.authenticationMessage = null;
    this.providerInfoMessage = null;
    this.showGeneralProviderInfo = false;
  }

  login(clearFlow?: boolean): void {
    if (clearFlow) {
      this.loginFlow = null;
      this.username = null;
      this.updateFocus();
      return;
    }
    this.isLoading = true;
    this.authenticationMessage = null;
    this.providerInfoMessage = null;
    this.showGeneralProviderInfo = false;
    const username = this.username;
    if (!this.loginFlow && !!username) {
      this.oAuthAuthenticationService.getLoginFlow(username).pipe(takeUntil(this.unsubscribe$)).subscribe((response) => {
        this.loginFlow = response.flow;
        if (LOGIN_FLOW.B2C === response.flow) {
          this.b2cFlow = this.getB2CFlow()
        }
        this.isLoading = false;
      }, error => this.isLoading = false)
    } else if (this.loginFlow === LOGIN_FLOW.B2C) {
      this.storageService.setLastLoggedInUsername(username);
      this.b2cInProgress = true;
      this.setB2CFlow(this.b2cFlow)
      this.msalService.loginRedirect(this.getLoginRedirectRequest()).pipe(takeUntil(this.unsubscribe$)).subscribe({
        error: err => {
          console.log('Failed msal login redirect', {err});
          this.authenticationMessage = `Login fejl: ${err.message}`;
          this.b2cInProgress = false;
          this.loginFlow = null;
        }
      });
    } else if (this.loginFlow === LOGIN_FLOW.LOCAL && this.password) {
      this.oAuthAuthenticationService.authenticate(
        username,
        this.password
      ).pipe(takeUntil(this.unsubscribe$)).subscribe(next => {
        this.onSuccessfulLogin()
      }, error => {
        this.oAuthAuthenticationService.removeAuthentication().pipe(
          takeUntil(this.unsubscribe$)
        ).subscribe(() => {
          this.router.navigate(['']);
          this.isLoading = false;
          this.b2cInProgress = false;
          if (error.status === 401) {
            this.password = '';
            if (error.error.code !== null && error.error.code === 103) {
              this.showChangePassword(username, error.error.message);
            } else {
              this.authenticationMessage = error.error.message;
              //see code translations in atlight
              const appendProviderInfoIfAvailable = error.error.code !== null && error.error.code === 102;
              if (appendProviderInfoIfAvailable) {
                const providerDetails = this.storageService.getProviderDetails(username);
                if (providerDetails !== null) {
                  this.providerInfoMessage = 'Kontakt: ' + providerDetails;
                } else {
                  this.showGeneralProviderInfo = true;
                }
              }
            }
          } else {
            this.authenticationMessage = 'Login fejl';
          }
        });
      });
    } else {
      this.isLoading = false;
    }
  }

  private onSuccessfulLogin(): void {
    //update possible locally stored providerInfo to present later when a locked account state happens
    const currentUser = this.principalService.getUsername();
    const lastUser = this.storageService.getLastLoggedInUsername();
    if (currentUser !== lastUser) {
      this.storageService.clearAllSesionStorage();
    }

    this.storageService.storeProviderDetails(this.principalService.getUsername(), this.principalService.getProviderInfo());
    this.storageService.setLastLoggedInUsername(currentUser);

    // previousState was set in the authExpiredInterceptor before being redirected to login modal.
    // since login is successful, go to stored previousState and clear previousState
    const redirect: string = this.storageService.getUrl();
    this.storageService.clearUrl();

    const canReceiveBroadcasts: boolean = this.principalService.isVK() || this.principalService.isTaksator();
    // 1. look for possible new broadcasts if user can receive such
    // 2. if user had new broadcasts, present those
    // 3. if user requested specific URL, navigate to that
    iif(() => canReceiveBroadcasts, this.settingsService.getHasNewBroadcasts(), of(false)).pipe(
      mergeMap(hasNewBroadcasts => {
        const navigateTo: string = !isNullOrUndefined(redirect) ? redirect : undefined;
        return combineLatest([of(hasNewBroadcasts), of(navigateTo)]);
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe(([hasNewBroadcasts, navigateTo]) => {
      if (hasNewBroadcasts) {
        this.modalService.open(NewBroadcastsPopupComponent, {size: 'lg', windowClass: 'lc-fullscreen'});
      }
      this.isLoading = false;
      this.b2cInProgress = false;
      this.onLogin.next(navigateTo);
    });
  }

  onChangePasswordClicked(): void {
    this.password = '';
    this.showChangePassword(this.username);
  }

  showChangePassword(username?: string, feedback?: string): void {
    const modalRef = this.modalService.open(ChangePasswordModalComponent);
    if (username) {
      modalRef.componentInstance.username = username;
    }
    if (feedback) {
      modalRef.componentInstance.changepasswordErrorFeedback = feedback;
    }
  }

  private getB2CFlow(): B2C_FLOW {
    return this.storageService.getFromLocalStorage(this.username + '-b2c-flow') as B2C_FLOW;
  }

  private setB2CFlow(b2cFlow: B2C_FLOW): void {
    this.storageService.setInLocalStorage(this.username + '-b2c-flow', b2cFlow);
  }

  private getLoginRedirectRequest(): RedirectRequest {
    const loginRequest: RedirectRequest = {
      loginHint: this.username,
      scopes: this.b2CConfigurationService.flowAuthorities.scopes || []
    } as RedirectRequest;
    if (this.b2cFlow === B2C_FLOW.SMS_CALL) {
      loginRequest.authority = this.b2CConfigurationService.flowAuthorities.signInSmsCall
    } else if (this.b2cFlow === B2C_FLOW.EMAIL) {
      loginRequest.authority = this.b2CConfigurationService.flowAuthorities.signInEmail
    } else if (this.b2cFlow === B2C_FLOW.APP) {
      loginRequest.authority = this.b2CConfigurationService.flowAuthorities.signInApp
    }
    return loginRequest;
  }
}

