import {ValidationErrors} from '@angular/forms';
import {isNullOrUndefined} from '../../utils/object-utils';

export abstract class LcInputValidator {
  static appendErrors(current: ValidationErrors, toAppend: ValidationErrors): ValidationErrors {
    let result;
    if (isNullOrUndefined(toAppend)) {
      result = current;
    } else {
      if (isNullOrUndefined(current)) {
        result = toAppend;
      } else {
        result = Object.assign({}, current, toAppend)
      }
    }
    return result;
  }

  abstract validate(value: string): ValidationErrors;
}
