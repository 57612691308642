    <div *ngIf="isVersionUpdated" class="alert alert-warning text-center fixed-top version-update-bar">
        <i class="fa fa-exclamation-circle update-version-pulse text-danger me-2"></i>  <a (click)="reload()">Ny version klar. Klik her for at opdatere</a>
    </div>
    <div *ngIf="this.notificationService.onNotificationChangedSub | async as notification" class="notification-message text-center text-truncate align-middle p-2 position-fixed">
        <a *ngIf="notification.link" target="_blank"
           href="{{notification.link}}">{{notification.text}}</a>
        <span *ngIf="!notification.link">{{notification.text}}</span>
    </div>
    <div *ngIf="showNavigationMenu()" [class.notification-active]="notificationService.onNotificationChangedSub | async">
        <lc-topbar (closeSidebarMenu)="toggleSidebarMenu(true)" class="z-3 position-fixed"></lc-topbar>
        <lc-sidebar (toggleSidebarMenu)="toggleSidebarMenu()" [sidebarHidden]="sidebarHidden" class="position-fixed h-100" style="z-index: 1040"></lc-sidebar>
    </div>

    <lc-bootstrap-growl [alertCount]="4" [autoClose]="10000"></lc-bootstrap-growl>

    <div class="container-fluid" [class.app-content]="showNavigationMenu()"
         [class.sidebar-inactive]="sidebarHidden"
         [class.sidebar-active]="!sidebarHidden"
         [class.transition-enabled]="enableTransition"
         [class.notification-active]="notificationService.onNotificationChangedSub | async"
    >
        <lc-breadcrumb *ngIf="this.showNavigationMenu()" [class.transition-enabled]="enableTransition"></lc-breadcrumb>
        <router-outlet></router-outlet>
        <router-outlet name="popup"></router-outlet>
    </div>

    <lc-cookie></lc-cookie>
