import {Routes} from '@angular/router';

import {UserRouteAccessService} from 'app/shared/service/auth/user-route-access.service';
import {SearchComponent} from './list/search.component';
import {AppRole} from '../shared/service/auth/app-roles.model';

export const SEARCH_ROUTES: Routes = [
  {
    path: 'search',
    data: {
      authorities: [AppRole.TAKS],
      breadcrumb: 'Søg'
    },
    canActivate: [UserRouteAccessService],
    children: [
      {
        path: '',
        component: SearchComponent,
        data: {
          authorities: [AppRole.TAKS],
          pageTitle: 'Søg'
        },
        canActivate: [UserRouteAccessService],
      },
      {
        path: ':searchType',
        component: SearchComponent,
        data: {
          authorities: [AppRole.TAKS],
          pageTitle: 'Søg spec'
        },
        canActivate: [UserRouteAccessService],
      }
    ]
  }
];
