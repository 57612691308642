import {PrincipalService} from '../../shared';
import {ClientConfigService} from '../../shared/service/client-config.service';
import {Observable, ReplaySubject, Subscription} from 'rxjs';
import {Injectable} from '@angular/core';
import {filter, map} from 'rxjs/operators';
import {ReportCategory} from '../../shared/model/report-category.model';
import {isNullOrUndefined} from '../../shared/utils/object-utils';

@Injectable()
export class ReportTypeViewModel {

  private _selectedTypeSubject: ReplaySubject<ReportCategory>;
  private _selectedType: ReportCategory = null;
  loading = true;
  public types = [];
  private draftTypeSubscription: Subscription;

  constructor(private clientConfigService: ClientConfigService, private principalService: PrincipalService) {
    this._selectedTypeSubject = new ReplaySubject<ReportCategory>(0);
  }

  public loadReportTypes(): void {
    const vkDraftTypeWhitelist = [ReportCategory.AUTOTAKS, ReportCategory.AUTOTAKS_LORRY, ReportCategory.GLASTAKS, ReportCategory.STORTAKS, ReportCategory.MCTAKS, ReportCategory.CAMPTAKS];
    const taksDraftTypeWhitelist = [ReportCategory.AUTOTAKS, ReportCategory.AUTOTAKS_LORRY, ReportCategory.SKAVUTAKS];
    const whiteList = this.principalService.isVK() ? vkDraftTypeWhitelist : (this.principalService.isTaksator() ? taksDraftTypeWhitelist : []);
    this.draftTypeSubscription = this.clientConfigService.reportCategories$().pipe(
      filter(value => !isNullOrUndefined(value)),
      map(draftTypes => draftTypes.filter(value => whiteList.filter(v => v === value).length === 1))).subscribe(draftTypes => {
      this.types = draftTypes;
      if (this.types.length === 1) {
        this.selectedType = this.types[0];
      }
      this.loading = false;
    }, error => {
      console.log('Error while loading drafttypes', error);
      this.loading = false;
    });
  }

  public destroy(): void {
    if (!isNullOrUndefined(this.draftTypeSubscription)) {
      this.draftTypeSubscription.unsubscribe();
      this.draftTypeSubscription = null;
    }
  }

  public selectedTypeObs(): Observable<ReportCategory> {
    return this._selectedTypeSubject.asObservable();
  }

  get selectedType(): ReportCategory {
    return this._selectedType;
  }

  set selectedType(value: ReportCategory) {
    this._selectedTypeSubject.next(value);
    this._selectedType = value;
  }

  public hasMultiSelection(): boolean {
    return this.types.length > 1;
  }
}

