import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {RoadmapComponent} from './roadmap.component';
import {ROADMAP_ROUTES} from './roadmap.route';
import {SharedCommonModule} from '../shared/shared-common.module';
import {SharedModule} from 'app/shared/shared.module';

@NgModule({
  imports: [
    SharedModule,
    SharedCommonModule,
    RouterModule.forRoot(ROADMAP_ROUTES, {useHash: true})
  ],
  declarations: [
    RoadmapComponent,
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class RoadmapModule {
}
